import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IStatus, IStatusState } from './status.interfaces';
import { getSystemStatusThunk } from './status.thunks';

const initialState: IStatusState = {
  status: null,
};

const statusSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getSystemStatusThunk.fulfilled,
      (state, action: PayloadAction<{ status: IStatus }>) => {
        state.status = action.payload.status;
      }
    );
  },
});

export const { reducer: statusRTKReducer } = statusSlice;
