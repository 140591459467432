export const enum ReservationType {
  // eslint-disable-next-line no-unused-vars
  Subscription = 'subscription',
  // eslint-disable-next-line no-unused-vars
  Bonus = 'bonus',
  // eslint-disable-next-line no-unused-vars
  DeploymentEnv = 'deployment-env',
  // eslint-disable-next-line no-unused-vars
  Member = 'member',
  // eslint-disable-next-line no-unused-vars
  PayOverdue = 'pay-overdue',
}

export const activationSteps = (type: ReservationType) => {
  switch (type) {
    case ReservationType.Subscription:
      return [
        {
          id: 1,
          text: 'Creating reservation for subscription',
          subtext:
            // eslint-disable-next-line max-len
            'We are creating a reservation for your subscription, which might take some time. Please hang on',
        },
        {
          id: 2,
          text: 'Creating transaction on Solana',
          subtext:
            // eslint-disable-next-line max-len
            'We are creating your transaction on Solana. Please approve the payment on your wallet',
        },
        {
          id: 3,
          text: 'Verifying transaction on Solana',
          subtext:
            // eslint-disable-next-line max-len
            'We are verifying your transaction on Solana, which might take a while. Please hang on',
        },
      ];
    case ReservationType.Bonus:
      return [
        {
          id: 1,
          text: 'Creating reservation for bonus',
          subtext:
            // eslint-disable-next-line max-len
            'We are creating a reservation for your bonus, which might take some time. Please hang on',
        },
        {
          id: 2,
          text: 'Creating transaction on Solana',
          subtext:
            // eslint-disable-next-line max-len
            'We are creating your transaction on Solana. Please approve the payment on your wallet',
        },
        {
          id: 3,
          text: 'Verifying transaction on Solana',
          subtext:
            // eslint-disable-next-line max-len
            'We are verifying your transaction on Solana, which might take a while. Please hang on',
        },
      ];
    case ReservationType.DeploymentEnv:
      return [
        {
          id: 1,
          text: 'Creating reservation for deployment environment',
          subtext:
            // eslint-disable-next-line max-len
            'We are creating a reservation for your deployment environment, which might take some time. Please hang on',
        },
        {
          id: 2,
          text: 'Creating transaction on Solana',
          subtext:
            // eslint-disable-next-line max-len
            'We are creating your transaction on Solana. Please approve the payment on your wallet',
        },
        {
          id: 3,
          text: 'Verifying transaction on Solana',
          subtext:
            // eslint-disable-next-line max-len
            'We are verifying your transaction on Solana, which might take a while. Please hang on',
        },
      ];
    case ReservationType.Member:
      return [
        {
          id: 1,
          text: 'Creating reservation for members',
          subtext:
            // eslint-disable-next-line max-len
            'We are creating a reservation for selected member addition, which might take some time. Please hang on',
        },
        {
          id: 2,
          text: 'Creating transaction on Solana',
          subtext:
            // eslint-disable-next-line max-len
            'We are creating your transaction on Solana. Please approve the payment on your wallet',
        },
        {
          id: 3,
          text: 'Verifying transaction on Solana',
          subtext:
            // eslint-disable-next-line max-len
            'We are verifying your transaction on Solana, which might take a while. Please hang on',
        },
      ];
    case ReservationType.PayOverdue:
      return [
        {
          id: 1,
          text: 'Creating reservation for overdue payment',
          subtext:
            // eslint-disable-next-line max-len
            'We are creating a reservation for your overdue payment, which might take some time. Please hang on',
        },
        {
          id: 2,
          text: 'Creating transaction on Solana',
          subtext:
            // eslint-disable-next-line max-len
            'We are creating your transaction on Solana. Please approve the payment on your wallet',
        },
        {
          id: 3,
          text: 'Verifying transaction on Solana',
          subtext:
            // eslint-disable-next-line max-len
            'We are verifying your transaction on Solana, which might take a while. Please hang on',
        },
      ];
    default:
      return [
        {
          id: 1,
          text: 'Creating reservation',
          subtext:
            // eslint-disable-next-line max-len
            'We are creating a reservation, which might take some time. Please hang on',
        },
        {
          id: 2,
          text: 'Creating transaction on Solana',
          subtext:
            // eslint-disable-next-line max-len
            'We are creating your transaction on Solana. Please approve the payment on your wallet',
        },
        {
          id: 3,
          text: 'Verifying transaction on Solana',
          subtext:
            // eslint-disable-next-line max-len
            'We are verifying your transaction on Solana, which might take a while. Please hang on',
        },
      ];
  }
};
