import request, { gql } from 'graphql-request';
import config from '../../../config';
import { requestPipeline } from '../root-utils';
import { IResponseError } from '../combined-reducer.interface';
import {
  ICreateNewOrganisationResponse,
  IInviteUserPayloadDto,
  IOrganisationSettingsPayloadDto,
  IRemoveWalletPayloadDto,
  IFetchUserRolesResponse,
  IRemoveInvitedMemberResponse,
  IChangeUserRolePayloadDto,
  IChangeUserRoleResponse,
  IInvitationPayloadDto,
  IUpdateInvitePayloadDto,
  IAddCryptoWalletPayloadDto,
  IAddCreditCardPayloadDto,
  IRemoveWalletResponsePayloadDto,
  IOrgWalletsResponsePayloadDto,
  IOrgWallet,
  IOrganisationBillingDetailsResponsePayloadDto,
  IOrganisationBillingDetailsPayloadDto,
  ISpheronCredits,
  IMasterOrganisationPayloadDto,
} from './organisation.interfaces';
import {
  IOrganization,
  IWallet as IAddWalletResponse,
} from '../combined-state.interface';
import {
  ICoupon,
  IExpireCoupon,
  IOrganisationSpecializationPayloadDto,
  ISubscriptionUsage,
} from '../subscription/subscription.interfaces';

export const createOrganisationService = async (
  organization: IOrganisationSettingsPayloadDto
): Promise<ICreateNewOrganisationResponse> => {
  return requestPipeline({
    url: `organization`,
    method: 'POST',
    body: JSON.stringify(organization),
  });
};

export const createMasterOrganisationService = async (
  organization: IMasterOrganisationPayloadDto
): Promise<ICreateNewOrganisationResponse | IResponseError> => {
  return requestPipeline({
    url: `organization`,
    method: 'POST',
    body: JSON.stringify(organization),
  });
};

export const fetchOrganisationById = async (
  organisationId: string
): Promise<Partial<IOrganization>> => {
  return requestPipeline({
    url: `organization/${organisationId}`,
    method: 'GET',
    isPublic: true,
  });
};

export const updateOrganisationService = async (payload: {
  data: IOrganisationSettingsPayloadDto;
  id: string;
}): Promise<boolean | IResponseError> => {
  return requestPipeline({
    url: `organization/${payload.id}`,
    method: 'PUT',
    body: JSON.stringify(payload.data),
  });
};

export const updateMasterOrganisationService = async (payload: {
  data: IMasterOrganisationPayloadDto;
  id: string;
}): Promise<boolean | IResponseError> => {
  return requestPipeline({
    url: `organization/${payload.id}`,
    method: 'PUT',
    body: JSON.stringify(payload.data),
  });
};

export const sendMemberInviteService = async (
  invite: IInviteUserPayloadDto
): Promise<IResponseError> => {
  return requestPipeline({
    url: `organization/${invite.organizationId}/invites`,
    method: 'POST',
    body: JSON.stringify(invite),
  });
};

export const removeInvitedMemberService = async (
  payload: IInvitationPayloadDto
): Promise<IRemoveInvitedMemberResponse | IResponseError> => {
  return requestPipeline({
    url: `organization/${payload.organisationId}/invites/${payload.inviteId}`,
    method: 'DELETE',
    body: JSON.stringify({
      inviteId: payload.inviteId,
      organizationId: payload.organisationId,
    }),
  });
};

export const removeMemberService = async (
  userId: string,
  orgId: string
): Promise<IResponseError> => {
  const deleteObj = { userId, orgId, organizationId: orgId };

  return requestPipeline({
    url: `organization/${orgId}/member`,
    method: 'DELETE',
    body: JSON.stringify(deleteObj),
  });
};

export const fetchUsersRole = async (
  orgId: string
): Promise<IFetchUserRolesResponse> => {
  return requestPipeline({
    url: `role/getUsersWithRoleGroups/${orgId}`,
    method: 'GET',
    isPublic: true,
  });
};

export const changeMemberRoleService = async (
  payload: IChangeUserRolePayloadDto
): Promise<IChangeUserRoleResponse | IResponseError> => {
  return requestPipeline({
    url: `role/setRoleGroupPermissions`,
    method: 'POST',
    body: JSON.stringify(payload),
  });
};

export const addWalletService = async (
  payload: IAddCryptoWalletPayloadDto | IAddCreditCardPayloadDto
): Promise<IAddWalletResponse | Partial<IResponseError>> => {
  return requestPipeline({
    url: `wallet`,
    method: 'POST',
    body: JSON.stringify(payload),
  });
};

export const removeWalletService = async (
  wallet: IRemoveWalletPayloadDto | { id: string; organizationId: string }
): Promise<IRemoveWalletResponsePayloadDto | Partial<IResponseError>> => {
  return requestPipeline({
    url: `wallet`,
    method: 'DELETE',
    body: JSON.stringify(wallet),
  });
};

export const updateInviteService = async (
  inviteReply: IUpdateInvitePayloadDto
): Promise<IResponseError> => {
  return requestPipeline({
    url: `organization/${inviteReply.organizationId}/invites/${inviteReply.inviteId}`,
    method: 'PUT',
    body: JSON.stringify({
      id: inviteReply.inviteId,
      status: inviteReply.status,
    }),
  });
};

export const getOrganisationWallet = async (
  organizationId: string
): Promise<IOrgWalletsResponsePayloadDto> => {
  return requestPipeline({
    url: `wallet/${organizationId}/all`,
    method: 'GET',
  });
};

export const getAllClustersService = async (
  organizationId: string,
  topupReport: string,
  skip: number,
  limit: number
): Promise<any> => {
  return requestPipeline({
    // eslint-disable-next-line max-len
    url: `organization/${organizationId}/clusters?skip=${skip}&limit=${limit}&topupReport=${topupReport}&instanceCountReport=y`,
    method: 'GET',
  });
};

export const getComputeProjectInstanceReportService = async (
  orgId: string
): Promise<any> => {
  return requestPipeline({
    url: `organization/${orgId}/compute/usage`,
    method: 'GET',
  });
};

export const getComputeProjectsCountService = async (
  orgId: string
): Promise<any> => {
  return requestPipeline({
    url: `organization/${orgId}/compute-project/count`,
    method: 'GET',
  });
};

export const getSubscriptionUsageService = async (
  payload: IOrganisationSpecializationPayloadDto
): Promise<ISubscriptionUsage | IResponseError> => {
  return requestPipeline({
    // eslint-disable-next-line max-len
    url: `organization/${payload.organizationId}/subscription-usage/specialization/${payload.specialization}`,
    method: 'GET',
  });
};

export const getSpecificSubscriptionUsageService = async (payload: {
  organizationId: string;
  subscriptionId: string;
}): Promise<ISubscriptionUsage | IResponseError> => {
  return requestPipeline({
    // eslint-disable-next-line max-len
    url: `organization/${payload.organizationId}/subscription-usage/${payload.subscriptionId}`,
    method: 'GET',
  });
};

const _buildRegistryListQuery = (registryContract: string) => {
  return gql`
    {
      registries(
        where: { registry: "${registryContract.toLowerCase()}" }
      ) {
        id
        deployer
        registry
        createdAt
        name
      }
      domains(
        where: { registry: "${registryContract.toLowerCase()}" }
      ) {
        id
        domain
        registry {
          id
          deployer
          registry
          createdAt
          name
        }
        latestUpdate {
          id
          CID
          domain {
            id
            domain
          }
          updateTimestamp
          deploymentLink
        }
      }
      deployers(
        where: { registry: "${registryContract.toLowerCase()}" }
      ){
        id
        addedTimestamp
        address
        roleGranter
      }
      admins(
        where: { registry: "${registryContract.toLowerCase()}" }
      ){
        id
        addedTimestamp
        address
        roleGranter
      }
    }
  `;
};

export const getRegistryDetailsFromContract = async (
  registryContract: string
) => {
  const result = await request(
    config.registry.REGISTRY_SUBGRAPH_QUERY_URL,
    _buildRegistryListQuery(registryContract)
  );
  const registryMeta = result.registries[0];
  const allDomains = result.domains;
  const registryAdmins = result.admins;
  const registryManagers = result.deployers;
  if (!registryMeta) {
    return null;
  }
  return {
    id: registryMeta.id,
    address: registryMeta.registry,
    name: registryMeta.name,
    createdOn: registryMeta.createdAt,
    domains: allDomains.map((d: any) => ({
      id: d.id,
      domain: d.domain,
      contentHash: d.latestUpdate.CID,
      lastedUpdated: d.latestUpdate.updateTimestamp,
      deployedLink: d.latestUpdate.deploymentLink,
    })),
    admins: registryAdmins,
    managers: registryManagers,
  };
};

export const deleteOrganisationService = async (organisationId: string) => {
  return requestPipeline({
    url: `organization/${organisationId}`,
    method: 'DELETE',
  });
};

export const updateDefaultPaymentMethodIdService = async (payload: {
  organisationId: string;
  newPaymentMethodId: string;
}): Promise<IOrgWallet | IResponseError> => {
  return requestPipeline({
    url: `wallet/${payload.organisationId}/${payload.newPaymentMethodId}/set-as-primary`,
    method: 'PUT',
  });
};

export const publicAccessOrgService = async (payload: {
  organisationId: string;
  publiclyAccessible: boolean;
}): Promise<any> => {
  return requestPipeline({
    url: `organization/${payload.organisationId}/public-access`,
    method: 'PATCH',
    body: JSON.stringify({ publiclyAccessible: payload.publiclyAccessible }),
  });
};

export const fetchOrganisationByUsername = async (
  orgUsername: string
): Promise<Partial<IOrganization>> => {
  return requestPipeline({
    url: `organization?username=${orgUsername}`,
    method: 'GET',
    isPublic: true,
  });
};

export const getBillingDetailsService = (
  payload: string
): Promise<IOrganisationBillingDetailsPayloadDto | IResponseError> => {
  return requestPipeline({
    url: `invoice-additional-info/${payload}`,
    method: 'GET',
  });
};

export const addBillingDetailsService = (payload: {
  organizationId: string;
  details: IOrganisationBillingDetailsPayloadDto;
}): Promise<IOrganisationBillingDetailsResponsePayloadDto | IResponseError> => {
  return requestPipeline({
    url: `invoice-additional-info/`,
    method: 'POST',
    body: JSON.stringify({
      organizationId: payload.organizationId,
      ...payload.details,
    }),
  });
};

export const updateBillingDetailsService = (payload: {
  organizationId: string;
  details: IOrganisationBillingDetailsPayloadDto;
}): Promise<IOrganisationBillingDetailsResponsePayloadDto | IResponseError> => {
  return requestPipeline({
    url: `invoice-additional-info/`,
    method: 'PUT',
    body: JSON.stringify({
      organizationId: payload.organizationId,
      ...payload.details,
    }),
  });
};

export const getSpheronCredits = async (
  organizationId: string
): Promise<{ credits: ISpheronCredits[] } | IResponseError> => {
  return requestPipeline({
    url: `organization/${organizationId}/credits`,
    method: 'GET',
  });
};

export const deleteSpheronCredits = async ({
  couponCode,
  organizationId,
}: IExpireCoupon): Promise<ICoupon | IResponseError> => {
  return requestPipeline({
    url: `organization/${organizationId}/credits?creditCoupon=${couponCode}`,
    method: 'DELETE',
  });
};

export const fetchOrgNotificationConfigService = async (
  orgId: string
): Promise<
  | {
      notificationCategories: { notificationCategory: string }[];
    }
  | IResponseError
> => {
  return requestPipeline({
    url: `organization/${orgId}/notification-config`,
    method: 'GET',
    isPublic: true,
  });
};

export const updateOrgNotificationConfigService = async (payload: {
  organisationId: string;
  newNotificationCategories: string[];
}): Promise<
  | {
      config: {
        notificationCategories: { notificationCategory: string }[];
      };
    }
  | IResponseError
> => {
  return requestPipeline({
    url: `organization/${payload.organisationId}/notification-config`,
    method: 'PATCH',
    body: JSON.stringify({
      newNotificationCategories: payload.newNotificationCategories,
    }),
  });
};
