import { IResponseError } from '../combined-reducer.interface';
import { IUser } from '../combined-state.interface';
import { requestPipeline } from '../root-utils';
import { IThemeUpdateResponse } from '../theme/theme.interfaces';
import {
  IAPIToken,
  IAdditionalInfoPayloadDto,
  IBannerResponse,
  ICreateApiTokenPayloadDto,
  IReferralWithdrawalVerify,
  IRequestReferralWithdrawal,
  IUpdateUserPayloadDto,
  IUserNotification,
} from './user.interfaces';

export const fetchUser = async (): Promise<{ user: IUser }> => {
  return requestPipeline({
    url: 'profile',
    method: 'GET',
  });
};

export const updateUserDefaultOrganisationService = async (
  defaultOrganization: string
): Promise<IResponseError> => {
  return requestPipeline({
    url: 'profile',
    method: 'PATCH',
    body: JSON.stringify({
      defaultOrganization,
    }),
  });
};

export const fetchIntercomHash = async (): Promise<{
  intercomHash: string;
}> => {
  return requestPipeline({
    url: 'profile/intercom-hash',
    method: 'GET',
  });
};

export const updateUserProfileData = async (
  user: IUpdateUserPayloadDto
): Promise<IResponseError> => {
  return requestPipeline({
    url: 'profile',
    method: 'PUT',
    body: JSON.stringify(user),
  });
};

export const getUserNotifications = async (
  skip: number,
  limit: number
): Promise<IUserNotification[]> => {
  return requestPipeline({
    url: `user-notification/paginated?skip=${skip}&limit=${limit}`,
    method: 'GET',
  });
};

export const changeNotificationStatus = async (
  notificationId: string
): Promise<Partial<IResponseError>> => {
  return requestPipeline({
    url: `user-notification/read/${notificationId}`,
    method: 'PUT',
  });
};

export const markAllNotificationsAsRead = async (): Promise<
  Partial<IResponseError>
> => {
  return requestPipeline({
    url: 'user-notification/read/all',
    method: 'PUT',
  });
};

export const removeProviderService = async (payload: {
  provider: string;
}): Promise<IResponseError> => {
  return requestPipeline({
    url: `auth/${payload.provider}/login-connection`,
    method: 'DELETE',
  });
};

export const getTokensService = async (): Promise<any> => {
  return requestPipeline({
    url: 'api-keys',
    method: 'GET',
  });
};

export const createTokenService = async (
  tokenDetails: ICreateApiTokenPayloadDto
): Promise<IAPIToken | IResponseError> => {
  return requestPipeline({
    url: 'api-keys',
    method: 'POST',
    body: JSON.stringify(tokenDetails),
  });
};

export const deleteTokensService = async (
  apiKeyIds: string[]
): Promise<IResponseError> => {
  return requestPipeline({
    url: 'api-keys',
    method: 'DELETE',
    body: JSON.stringify({ apiKeyIds }),
  });
};

export const seenBannerService = async (bannedId: string): Promise<boolean> => {
  return requestPipeline({
    url: `banner/visited/${bannedId}`,
    method: 'POST',
  });
};

export const getBannerService = async (): Promise<IBannerResponse> => {
  return requestPipeline({
    url: 'banner/toSee',
    method: 'GET',
  });
};

export const updateUserPreferredTheme = async (
  theme: string
): Promise<IThemeUpdateResponse | IResponseError> => {
  return requestPipeline({
    url: 'profile/preferences',
    method: 'PATCH',
    body: JSON.stringify({ theme }),
  });
};

export const getReferralDetailsService = async () => {
  return requestPipeline({
    url: 'referral',
    method: 'GET',
  });
};

export const getReferralCodeService = async () => {
  return requestPipeline({
    url: 'referral/code',
    method: 'GET',
  });
};

export const setReferralCodeService = async (referralCode: string) => {
  return requestPipeline({
    url: 'referral',
    method: 'PATCH',
    body: JSON.stringify({ code: referralCode }),
  });
};

export const getReferralCreditsService = async () => {
  return requestPipeline({
    url: 'spheron-wallet',
    method: 'GET',
  });
};

export const getReferralWithdrawalsService = async () => {
  return requestPipeline({
    url: 'withdrawal',
    method: 'GET',
  });
};

export const requestReferralWithdrawalService = async (
  withdrawDetails: IRequestReferralWithdrawal
) => {
  return requestPipeline({
    url: 'withdrawal',
    method: 'POST',
    body: JSON.stringify(withdrawDetails),
  });
};

export const verifyWithdrawalWalletService = async (
  verifyDetails: IReferralWithdrawalVerify
) => {
  return requestPipeline({
    url: 'wallet/verify-address',
    method: 'POST',
    body: JSON.stringify(verifyDetails),
  });
};

export const getRaiseRequestLimitService = async () => {
  return requestPipeline({
    url: 'withdrawal/limit',
    method: 'GET',
  });
};

export const updateUserOnboardingDetailsService = async (payload: {
  additionalInfo: IAdditionalInfoPayloadDto;
}) => {
  return requestPipeline({
    url: `profile`,
    method: 'PATCH',
    body: JSON.stringify(payload),
  });
};
