import React, { useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { Toast as NotificationToast } from '@spheron/ui-library';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { notificationTimeoutRtk } from '../../../redux/compute/notification/notification.slice';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import { NotificationType } from '../../../redux/compute/notification/notification.interfaces';

const NotificationBanner = () => {
  const dispatch = useDispatch<AppDispatch>();

  const originalNotificationArray = useSelector(
    (state: RootState) => state.notification.notificationArray
  );

  const notificationArray = useMemo(() => {
    return originalNotificationArray.filter((notif) => {
      const notifDate = dayjs(notif.timestamp);
      const nowDate = dayjs(Date.now());
      const timeDiff = nowDate.diff(notifDate, 's');
      return !!notif.message && timeDiff < 5;
    });
  }, [originalNotificationArray]);

  const title = (notification: NotificationType) => {
    switch (notification) {
      case NotificationType.Error:
        return 'Error Found';
      case NotificationType.Info:
        return 'New Info';
      case NotificationType.Success:
        return 'Successful';
      case NotificationType.Warning:
        return 'Warning';
      default: {
        return 'New Info';
      }
    }
  };

  useEffect(() => {
    if (notificationArray.length) {
      // eslint-disable-next-line prefer-destructuring
      const length = notificationArray.length;
      const notification = notificationArray[length - 1];
      let toastMethod;
      switch (notification.type) {
        case 'error':
          toastMethod = toast.error;
          break;
        case 'success':
          toastMethod = toast.success;
          break;
        case 'info':
          toastMethod = toast.info;
          break;
        case 'warning':
          toastMethod = toast.warning;
          break;
        default:
          toastMethod = toast.info;
      }
      toastMethod(
        <NotificationToast
          toastType={notification.type}
          title={notification.title || title(notification.type)}
          subTitle={notification.message || ''}
          showClose={false}
          date={dayjs(notification.timestamp).format('YYYY-MM-DD')}
          time={dayjs(notification.timestamp).format('hh:mm A')}
        />,
        {
          toastId: `notif_${notification.timestamp}`,
          autoClose: 5000,
          hideProgressBar: true,
          theme: 'colored',
          icon: false,
          onClose: () => {
            dispatch(notificationTimeoutRtk());
          },
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, notificationArray.length]);

  return <></>;
};

export default NotificationBanner;
