import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Dropdown, OptionType, SectionHeading } from '@spheron/ui-library';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import PlanUsageCard from '../../../../components/Compute/Cards/plan-usage-card';
import {
  ISubscriptionHistory,
  ISubscriptionUsage,
  SubscriptionState,
} from '../../../../redux/compute/subscription/subscription.interfaces';
import { ICurrentApp } from '../../../../redux/compute/organisation/organisation.interfaces';
import { mapCurrentAppToSpecialization } from '../../../../redux/compute/organisation/organisation.utils';
import {
  getSubscriptionHistoryThunk,
  getSubscriptionUsageThunk,
} from '../../../../redux/compute/subscription/subscription.thunks';
import { AppDispatch, RootState } from '../../../../redux/compute/store';
import { unixTimestampToMonthYearDate } from '../../../../redux/compute/subscription/subscription.utils';
import { ReactComponent as StarBoldIcon } from '../../../../assets/compute/icons/star-bold.svg';

interface ICustomUsageProps {
  [key: string]: string;
}

const PlanUsage = () => {
  const dispatchRtk = useDispatch<AppDispatch>();

  const currentApp = useSelector(
    (state: RootState) => state.organisation.currentApp
  );
  const selectedOrganisation = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation
  );
  const selectedOrganisationLoading: boolean = useSelector(
    (state: RootState) => state.organisation.selectedOrganisationLoading
  );
  const subscriptionUsageLoading: boolean = useSelector(
    (state: RootState) => state.subscription.subscriptionUsageLoading
  );
  const usage = useSelector(
    (state: RootState) => state.subscription.subscriptionUsage
  );
  const subscriptionLoading: boolean = useSelector(
    (state: RootState) => state.subscription.subscriptionsLoading
  );
  const subscriptionPlanHistory = useSelector(
    (state: RootState) => state.subscription.subscriptionPlanHistory
  );
  const selectedOrganisationId = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation._id
  );

  const [selectedPlan, setSelectedPlan] = useState<string>(
    'Current Subscription'
  );

  const obj: ICustomUsageProps[] = [];

  Object.keys(usage).forEach((key) => {
    const temp: any = {};
    temp.lastDeploymentDate = usage.lastDeploymentDate;
    if (key.substring(key.length - 5, key.length) === 'Limit') {
      temp.limit = usage[key as keyof ISubscriptionUsage];

      const valueKey: any = Object.keys(usage).find((t: any) => {
        return (
          t.toUpperCase().substring(4, t.length) ===
          key.slice(0, key.length - 5).toUpperCase()
        );
      });

      temp.used = usage[valueKey as keyof ISubscriptionUsage];
      temp.name = key;
      obj.push(temp);
    }
    if (
      key === 'usedNumberOfRequests' ||
      key === 'usedIpfsNumberOfRequests' ||
      key === 'usedIpfsGatewayNumberOfRequests'
    ) {
      temp.limit = 'unlimited';
      temp.used = usage[key as keyof ISubscriptionUsage];
      const tempName = key.slice(4, -16).concat('RequestsLimit');
      temp.name = tempName.charAt(0).toLowerCase() + tempName.slice(1);
      obj.push(temp);
    }
  });

  const withLoader = (
    loading: boolean,
    loading2: boolean,
    loading3: boolean,
    elements: JSX.Element,
    loader: JSX.Element
  ): JSX.Element => {
    if (loading || loading2 || loading3) {
      return loader;
    }
    return <>{elements}</>;
  };

  const filterPlanUsage = () => {
    if (currentApp === ICurrentApp.COMPUTE)
      return obj.filter(
        (t: any) =>
          t.name === 'domainsLimit' ||
          t.name === 'requestsLimit' ||
          t.name === 'bandwidthLimit'
      );

    return [];
  };

  const membersLimit = obj.find((bonus) => bonus.name === 'membersLimit');

  useEffect(() => {
    if (selectedOrganisation._id) {
      dispatchRtk(getSubscriptionHistoryThunk(selectedOrganisation!._id));
    }
  }, [dispatchRtk, selectedOrganisation]);

  const refreshWalletUsage = () => {
    if (selectedOrganisation) {
      setSelectedPlan('Current Subscription');
      dispatchRtk(getSubscriptionHistoryThunk(selectedOrganisation!._id));
      dispatchRtk(
        getSubscriptionUsageThunk({
          organizationId: selectedOrganisation!._id,
          specialization: mapCurrentAppToSpecialization(ICurrentApp.COMPUTE),
        })
      );
    }
  };

  const planHistoryList = subscriptionPlanHistory?.map((subscription) => ({
    id: subscription._id,
    startDate: subscription.dateOfIssue,
    endDate: subscription.dateOfExpiration,
    current: subscription.state === SubscriptionState.ACTIVE,
    planName: (subscription.subscriptionPackageId as ISubscriptionHistory)
      .displayName,
  }));

  const subscriptionOptions = [
    {
      id: -2,
      optionType: 'subheading' as OptionType,
      label: 'CURRENT SUBSCRIPTION',
      value: 'null',
      disable: true,
    },
    ...(planHistoryList
      ?.filter((plan) => plan.current)
      .map((plan, index) => ({
        id: index,
        optionType: 'primary' as OptionType,
        label: (
          <div className="flex flex-row items-center gap-4 justify-between w-full">
            <span>{plan.planName}</span>
            <span>
              {unixTimestampToMonthYearDate(plan.startDate)} -
              {unixTimestampToMonthYearDate(plan.endDate)}
            </span>
          </div>
        ),
        value: plan.id,
        leftIcon: <StarBoldIcon />,
      })) || []),
    {
      id: -1,
      optionType: 'subheading' as OptionType,
      label: 'PREVIOUS SUBSCRIPTION',
      value: 'null',
      disable: true,
    },
    ...(planHistoryList
      ?.filter((plan) => !plan.current)
      .map((plan, index) => ({
        id: index,
        optionType: 'primary' as OptionType,
        label: (
          <div className="flex flex-row items-center gap-4 justify-between w-full">
            <span>{plan.planName}</span>
            <span>
              {unixTimestampToMonthYearDate(plan.startDate)} -
              {unixTimestampToMonthYearDate(plan.endDate)}
            </span>
          </div>
        ),
        value: plan.id,
      })) || []),
  ];

  const handlePlanClick = (id: string) => {
    if (id !== 'null') {
      setSelectedPlan(
        planHistoryList?.find((plan) => plan.id === id)?.planName || ''
      );
      dispatchRtk(
        getSubscriptionUsageThunk({
          organizationId: selectedOrganisationId,
          subscriptionId: id,
        })
      );
    }
  };

  return (
    <div className="flex flex-col gap-y-4">
      <SectionHeading
        showRefresh
        showSwitch={false}
        subHeading=""
        heading="Plan Usage"
        handleIconClick={() => {}}
        handleRefreshClick={refreshWalletUsage}
        handleSwitchClick={() => {}}
        switchDisable={false}
        handleClick={() => {}}
        loading={subscriptionUsageLoading}
        time=""
        refreshType={'default'}
        showText={false}
        toggleId=""
        isChecked={false}
        handleChange={() => {}}
      />
      <div className="grid grid-cols-3 gap-6">
        <Dropdown
          dropdownSize="compact"
          dropdownSectionClassName=""
          dropdownType="default"
          placeholder={selectedPlan}
          filled
          onSelected={(plan) => handlePlanClick(plan.value || '')}
          subText=""
          defaultSelected=""
          options={subscriptionOptions}
          disable={subscriptionUsageLoading || selectedOrganisationLoading}
        />
      </div>
      <div className="grid grid-cols-4 gap-6">
        {withLoader(
          selectedOrganisationLoading,
          subscriptionUsageLoading,
          subscriptionLoading,
          <>
            {/* MEMBERS CARD */}
            {membersLimit && (
              <PlanUsageCard
                key={membersLimit.name}
                date={dayjs(membersLimit.lastDeploymentDate).format('MMM YYYY')}
                name="MEMBERS"
                total={membersLimit.limit}
                completed={selectedOrganisation.users.length.toString()}
              />
            )}
            {filterPlanUsage().map((info: ICustomUsageProps) => {
              if (info.limit) {
                return (
                  <PlanUsageCard
                    key={info.name}
                    date={dayjs(info.lastDeploymentDate).format('MMM YYYY')}
                    name={info?.name
                      ?.slice(0, info?.name.length - 5)
                      .toUpperCase()}
                    total={info.limit}
                    completed={info.used}
                  />
                );
              }
              return <></>;
            })}
          </>,
          <>
            {Array.from(Array(4).keys()).map(() => (
              <Skeleton
                height={102}
                width="100%"
                duration={2}
                borderRadius={8}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default PlanUsage;
