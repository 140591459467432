import React from 'react';
import { ConfirmationModal, Modal } from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import Styles from '../../../styles/compute/modal.module.scss';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import { closeInstanceThunk } from '../../../redux/compute/instance/instance.thunks';
import ModalWrapper from './ModalWrapper';

interface IProps {
  isOpen: boolean;
  instanceId: string;
}

function CloseInstance({ isOpen, instanceId }: IProps) {
  const dispatchRtk = useDispatch<AppDispatch>();

  const closingInstance: boolean = useSelector(
    (state: RootState) => state.instance.closingInstance
  );
  const closingInstanceId: string = useSelector(
    (state: RootState) => state.instance.closingInstanceId
  );

  const closeModal = () => {
    dispatchRtk(toggleModalShowRtk({ modalShow: false }));
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={() => {
        dispatchRtk(toggleModalShowRtk({ modalShow: false }));
      }}
      classname={`${Styles.modal__outer__con} modal fixed inset-0 overflow-y-auto`}
    >
      <div className={Styles.modal__inner__content}>
        <Modal
          footer
          header
          subtitle=""
          modalContent={
            <span className="font-normal text-sm leading-5">
              Do you really want to close the instance?
            </span>
          }
          headerContent={<>Close Instance</>}
          primaryButtonContent="Continue"
          secondaryButtonContent="Cancel"
          primaryButton
          primaryButtonLoading={
            closingInstance && closingInstanceId === instanceId
          }
          secondaryButtonDisabled={false}
          secondaryButton
          onPrimaryButtonClick={() =>
            dispatchRtk(closeInstanceThunk(instanceId))
          }
          onSecondaryButtonClick={closeModal}
          confirmationStatus={ConfirmationModal.ERROR}
        />
      </div>
    </ModalWrapper>
  );
}

export default CloseInstance;
