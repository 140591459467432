import React from 'react';

interface IProps {
  colorBg?: string;
  colorFill?: string;
  rotate?: number;
}

const ClusterStatisticIcon = ({ colorBg, colorFill, rotate }: IProps) => {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform={`rotate(${rotate})`}
    >
      <rect width="36" height="36" rx="18" fill={colorBg} />
      <g clipPath="url(#clip0_628_54754)">
        <path
          // eslint-disable-next-line max-len
          d="M22 12L24.29 14.29L19.41 19.17L15.41 15.17L8 22.59L9.41 24L15.41 18L19.41 22L25.71 15.71L28 18V12H22Z"
          fill={colorFill}
        />
      </g>
      <defs>
        <clipPath id="clip0_628_54754">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(6 6)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

ClusterStatisticIcon.defaultProps = {
  colorBg: 'currentColor',
  colorFill: 'currentColor',
  rotate: 0,
};

export default ClusterStatisticIcon;
