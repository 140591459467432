import React from 'react';
import { ConfirmationModal, Modal } from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import Styles from '../../../styles/compute/modal.module.scss';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import ModalWrapper from './ModalWrapper';

interface IProps {
  isOpen: boolean;
  handleClick: () => void;
  resource: string;
}

const DeleteResourceConfirm = ({ isOpen, handleClick, resource }: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();

  const deleteInstanceLoading = useSelector(
    (state: RootState) => state.instance.deleteInstanceLoading
  );

  const removeCardLoading = useSelector(
    (state: RootState) => state.stripe.creditCardsLoading.length > 0
  );

  const removingSubscription = useSelector(
    (state: RootState) => state.subscription.removingSubscription
  );

  const removingResourceLoading = (resource: string): boolean => {
    switch (resource.toLowerCase()) {
      case 'instance':
        return deleteInstanceLoading;
      case 'credit card':
        return removeCardLoading;
      case 'upcoming subscription':
        return removingSubscription;
      default:
        return false;
    }
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={() => dispatchRtk(toggleModalShowRtk({ modalShow: false }))}
      classname={`${Styles.modal__outer__con} w-430 modal fixed inset-0 overflow-y-auto`}
    >
      <div className={Styles.modal__inner__content}>
        <Modal
          footer
          header
          subtitle=""
          modalContent={
            <span className="font-normal text-sm leading-5">
              Are you sure you want to remove the {resource}?
            </span>
          }
          headerContent={<>Remove {resource}</>}
          primaryButtonContent="Remove"
          secondaryButtonContent="Cancel"
          primaryButton
          primaryButtonLoading={false}
          primaryButtonDisabled={removingResourceLoading(resource)}
          secondaryButtonDisabled={false}
          secondaryButton
          onPrimaryButtonClick={handleClick}
          onSecondaryButtonClick={() =>
            dispatchRtk(toggleModalShowRtk({ modalShow: false }))
          }
          confirmationStatus={ConfirmationModal.ERROR}
        />
      </div>
    </ModalWrapper>
  );
};

export default DeleteResourceConfirm;
