import { ISpecialization, IUser } from '../combined-state.interface';
import { ICurrentApp } from './organisation.interfaces';

export const mapRoleToUsers = (users: IUser[], payload: any) => {
  return users.map((user: IUser) => {
    const payloadUser = payload?.find(
      (value: { userId: string }) => value?.userId === user._id
    );
    return {
      ...user,
      platformProfile: {
        ...user.platformProfile,
        role: payloadUser?.roleGroup,
      },
    };
  });
};

export const mapRoleToUsersCommunity = (users: IUser[]) => {
  return users.map((user: IUser) => {
    return {
      ...user,
      platformProfile: {
        ...user.platformProfile,
        role: 'member',
      },
    };
  });
};

export const getCurrentAppState = (path: string) => {
  switch (path) {
    case 'app':
      return ICurrentApp.APP;
    case 'compute':
      return ICurrentApp.COMPUTE;
    case 'storage':
      return ICurrentApp.STORAGE;
    default:
      return ICurrentApp.APP;
  }
};

export const getUserRole = (
  organisationUsers: IUser[],
  currentUser: IUser
): string => {
  return (
    organisationUsers?.find((user: IUser) => user?._id === currentUser?._id)
      ?.platformProfile?.role || 'member'
  );
};

export const hasNoOrganisations = (
  currentApp: ICurrentApp,
  webappOrganisationsLength: number,
  computeOrganisationsLength: number,
  storageOrganisationsLength: number
): boolean =>
  (currentApp === ICurrentApp.COMPUTE && computeOrganisationsLength === 0) ||
  (currentApp === ICurrentApp.APP && webappOrganisationsLength === 0) ||
  (currentApp === ICurrentApp.STORAGE && storageOrganisationsLength === 0);

export const mapCurrentAppToSpecialization = (
  currentApp: ICurrentApp
): ISpecialization => {
  switch (currentApp) {
    case ICurrentApp.APP:
      return ISpecialization.waGlobal;
    case ICurrentApp.COMPUTE:
      return ISpecialization.cAkash;
    case ICurrentApp.STORAGE:
      return ISpecialization.storage;
    default:
      return ISpecialization.waGlobal;
  }
};

export const isUserInOrg = (user: IUser): boolean => {
  return !!user.organizations.find(
    (org) => org.profile.username === sessionStorage.getItem('communityOrgName')
  );
};

export const isCommunityOrgRoute = (pathname: string): boolean => {
  const allRoutePaths = [
    'app',
    'storage',
    'signup',
    'login',
    'post-login',
    'chooseapp',
    'create-new-organisation',
    'create-master-organisation',
    'invite',
    'user',
    'callback',
    'connection-callback',
    'notifications',
    'payment',
    'crypto-payment-callback',
    'marketplace',
    '',
  ];
  return !allRoutePaths.includes(pathname);
};

export const removeCommunityLocalVariables = (
  skipCommunityFlowRemove: boolean = false
) => {
  sessionStorage.removeItem('orgNotFound');
  sessionStorage.removeItem('communityOrgName');
  sessionStorage.removeItem('communityOrgAppType');
  sessionStorage.removeItem('isPublicAccessible');
  sessionStorage.removeItem('communityUserAccess');
  if (!skipCommunityFlowRemove) sessionStorage.removeItem('communityFlow');
};
