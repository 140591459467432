import React from 'react';

interface IProps {
  network: string;
  token: string;
}

const NetworkTokenSet = ({ network, token }: IProps) => {
  return (
    <div className="flex justify-start relative w-8">
      {network && (
        <img src={network} alt="network" className="w-5 h-5 rounded-full" />
      )}
      {token && (
        <img
          src={token}
          alt="token"
          className="w-5 h-5 left-3.5 absolute z-40 rounded-full"
        />
      )}
    </div>
  );
};

export default NetworkTokenSet;
