import { IResponseError } from '../combined-reducer.interface';
import { ScalingType } from '../instance/instance-creation/instance-creation.interfaces';
import { requestPipeline } from '../root-utils';
import {
  IClusterTemplate,
  IClusterUsageResponseDto,
  ProcessingType,
} from './cluster.interfaces';

export const getClusterDetailsService = async (
  clusterId: string
): Promise<any> => {
  return requestPipeline({
    url: `cluster/${clusterId}`,
    method: 'GET',
    isPublic: true,
  });
};

export const getClusterInstancesCountService = async (
  clusterId: string
): Promise<any> => {
  return requestPipeline({
    url: `cluster/${clusterId}/instances/count`,
    method: 'GET',
    isPublic: true,
  });
};

export const getClusterFundUsageService = async (
  clusterId: string
): Promise<any> => {
  return requestPipeline({
    url: `cluster/${clusterId}/funds-usage`,
    method: 'GET',
    isPublic: true,
  });
};

export const getWalletUsageService = async (
  orgId: string
): Promise<IClusterUsageResponseDto | IResponseError> => {
  return requestPipeline({
    url: `organization/${orgId}/compute/wallet/usage`,
    method: 'GET',
    isPublic: true,
  });
};

export const deleteCluster = async (clusterId: string): Promise<any> => {
  return requestPipeline({
    url: `cluster/${clusterId}`,
    method: 'DELETE',
  });
};

export const getClusterTemplateCategoriesService = async (): Promise<any> => {
  return requestPipeline({
    url: `cluster-templates/categories`,
    method: 'GET',
    isPublic: true,
  });
};

export const getClusterTemplatesService = async (
  search: string,
  category: string,
  processingType: ProcessingType,
  organisationId: string
): Promise<any> => {
  return requestPipeline({
    // eslint-disable-next-line max-len
    url: `cluster-templates?name=${search}&category=${category}&processingType=${processingType}&organizationId=${organisationId}`,
    method: 'GET',
    isPublic: true,
  });
};

export const getClusterTemplateInformationService = async (
  templateId: string,
  orgId: string
): Promise<{ clusterTemplate: IClusterTemplate }> => {
  return requestPipeline({
    url: `cluster-templates/${templateId}?organizationId=${orgId}`,
    method: 'GET',
    isPublic: true,
  });
};

export function searchCharacters(
  search: string,
  limit: number,
  skip: number,
  payload: {
    region: string;
    scalingType: ScalingType;
    provider: string;
  }
) {
  return requestPipeline({
    url: `compute-machine-image?&skip=${skip}&limit=${limit}&name=${search}`,
    method: 'POST',
    isPublic: true,
    body: JSON.stringify({ ...payload }),
  });
}

export const getTemplateVideoMetadataService = async (
  tutorialVideoId: string
): Promise<{ title: string }> => {
  try {
    const response = await fetch(
      `https://www.youtube.com/oembed?url=https://youtu.be/${tutorialVideoId}&format=json`
    );
    return await response.json();
  } catch (error) {
    console.error(error);
    return { title: '' };
  }
};
