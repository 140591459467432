import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Outlet, useParams } from 'react-router-dom';
import { Button, Feedback, Tabs } from '@spheron/ui-library';
import { useSelector } from 'react-redux';
import OverviewStyle from '../../../../styles/compute/overview.module.scss';
import GlobalStyles from '../../../../styles/compute/global.module.scss';
import { RootState } from '../../../../redux/rtk-store';
import { ReactComponent as GoogleIcon } from '../../../../assets/compute/icons/google-icon.svg';
import { openProviderAppAuth } from '../../../../redux/user/user.utils';

const DashboardMainView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams<{ orgUsername: string }>();
  const canDeploy = useSelector((state: RootState) => state.user.canDeploy);
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const tabDashboardRoutes = [
    {
      id: 0,
      label: 'Dashboard',
      url: `/${params.orgUsername}/dashboard`,
      slug: 'projects',
    },
    {
      id: 1,
      label: 'Billing',
      url: `/${params.orgUsername}/billing/plan`,
      slug: 'billing',
    },
  ];

  const handleTabClick = (id: number) => {
    setSelectedTab(id);
    navigate(tabDashboardRoutes[id].url);
  };

  useEffect(() => {
    tabDashboardRoutes.forEach((tab) => {
      if (tab.url.split('/')[2] === location.pathname.split('/')[2]) {
        setSelectedTab(tab.id);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <div
      className={`${OverviewStyle.overview__outer__con} flex flex-col justify-center`}
    >
      <div
        className={`dark:border-dark-base-border bg-base-fg dark:bg-dark-base-fg
        ${GlobalStyles.inner__tabs} w-full z-50 mx-auto flex items-center justify-center`}
      >
        <Tabs
          tabList={tabDashboardRoutes}
          tabListPosition="left"
          selectedTab={selectedTab}
          tabListClassname={`pt-4 pb-2 max-w-[1400px] mx-auto w-[95%]`}
          onClick={(id: number) => handleTabClick(id)}
        />
      </div>
      {!canDeploy && (
        <div className="sticky top-[112px] z-50">
          <Feedback
            feedbackType="info"
            title=""
            position="center"
            subTitle={
              <div className="flex items-center justify-between gap-x-2">
                <div className="text-sm text-feedback-info-text dark:text-dark-feedback-info-text font-400">
                  Please connect your google account to deploy using the
                  platform
                </div>
                <Button
                  buttonType="ghost"
                  onClick={() => openProviderAppAuth('google')}
                  label="Connect"
                  size="small"
                  rightIcon={<GoogleIcon />}
                />
              </div>
            }
            showClose={false}
            size="compact"
            customClassName="!rounded-none"
          />
        </div>
      )}

      <div
        className={`${GlobalStyles.screen__width} !pt-0 !w-[100%] !max-w-[100%]`}
      >
        <Outlet />
        {/* <Route
          exact
          path={`/compute/dashboard`}
          render={() => <ComputeDashboard />}
        />
        <Route
          exact
          path={`/compute/project/:projectId`}
          render={() => <ComputeDashboard />}
        />

        <Route exact path={`/compute/billing`} render={() => <Billing />} />
        <Route
          exact
          path={`/compute/billing/:slug`}
          render={() => <Billing />}
        />
        <Route
          exact
          path={`/compute/billing/:slug1/:slug2`}
          render={() => <Billing />}
        /> */}
      </div>
    </div>
  );
};

export default DashboardMainView;
