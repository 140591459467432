import React from 'react';
import InputStyle from '../../../styles/compute/input.module.scss';

interface IProps {
  type: string;
  className: string;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}

const StripeTextInput = ({ type, className, value, setValue }: IProps) => {
  return (
    <div className="flex flex-col items-start">
      <span className={InputStyle.stripe__label}>Name</span>
      <input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder="John Doe"
        type={type}
        className={`${InputStyle.stripe__text__input} ${className || ''}`}
      />
    </div>
  );
};

export default StripeTextInput;
