import React from 'react';
import { ReactComponent as Dash } from '@spheron/ui-library/dist/assets/minus-circle.svg';
import { ReactComponent as ClockIcon } from '@spheron/ui-library/dist/assets/clock-circle.svg';
import { ReactComponent as Tick } from '../../assets/compute/icons/checkbox-circle-fill.svg';
import { TransactionState } from '../../redux/subscription/subscription.interfaces';

interface IProps {
  type: TransactionState;
}

const BillingTag = ({ type }: IProps) => {
  const getClassFromTransaction = (type: TransactionState) => {
    switch (type) {
      case TransactionState.SUCCEEDED:
        return 'bg-feedback-success-bg text-feedback-success-text';
      case TransactionState.DECLINED:
        return 'bg-error-6 text-feedback-error-text';
      case TransactionState.PENDING:
        return 'bg-feedback-warning-bg text-feedback-warning-text';
      default:
        return 'bg-feedback-warning-bg';
    }
  };

  const getIconFromTransaction = (type: TransactionState): JSX.Element => {
    switch (type) {
      case TransactionState.SUCCEEDED:
        return <Tick className="w-4 h-4 text-feedback-success-text" />;
      case TransactionState.DECLINED:
        return <Dash className="w-4 h-4 text-feedback-error-text" />;
      case TransactionState.PENDING:
        return <ClockIcon className="w-4 h-4 text-feedback-warning-text" />;
      default:
        return <>Succeded</>;
    }
  };
  return (
    <div
      className={`flex items-center justify-start px-1.5 py-1 rounded 
     ${getClassFromTransaction(type)} text-xs font-medium
    w-min`}
    >
      {getIconFromTransaction(type)}
      <span className="capitalize text-inherit px-1.5">{type}</span>
    </div>
  );
};

export default BillingTag;
