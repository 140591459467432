/* eslint-disable max-len */
import React from 'react';
import { ReactComponent as AddIcon } from '@spheron/ui-library/dist/assets/spheron-specific/add.svg';
import { ReactComponent as PaymentFailedIcon } from '@spheron/ui-library/dist/assets/spheron-specific/payment-failed.svg';
import { Button, Dropdown, IOptions } from '@spheron/ui-library';

interface IProps {
  options: IOptions[];
}

const NoCard = ({ options }: IProps) => {
  return (
    <div
      className="p-5 bg-base-fg dark:bg-dark-base-fg
          flex flex-col items-center justify-center"
    >
      <PaymentFailedIcon className="w-[142px] h-[129px] px-5" />
      <div
        className="mb-5 text-4 font-600 leading-5
          text-base-heading-text-color dark:text-dark-base-heading-text-color"
      >
        No Payment Method added yet!
      </div>
      {options.length > 0 && (
        <Dropdown
          dropdownType="button"
          dropdownSize="default"
          dropdownClassname="!left-full !right-auto !z-10 !w-[256px] !min-w-64 !mt-0"
          bordersNone
          buttonImage={
            <Button
              buttonType="ghost"
              leftIcon={
                <AddIcon className="w-5 h-5 text-action-ghost-text dark:text-dark-action-ghost-text" />
              }
              label="Add Payment Method"
              classname="!bg-base-bg dark:!bg-dark-base-bg hover:bg-base-fg hover:dark:!bg-dark-base-fg"
              size="small"
              onClick={() => null}
            />
          }
          options={options}
        />
      )}
    </div>
  );
};

export default NoCard;
