import React, { useEffect } from 'react';
import { AttachedPaymentMethodCard, OptionType } from '@spheron/ui-library';
import makeBlockie from 'ethereum-blockies-base64';
import { useDispatch, useSelector } from 'react-redux';
import { largeCutText } from '../../../hooks/useShortAddress';
import {
  getDecimalFromTokenAddress,
  getNetworkFromName,
  getTokenNameFromAddress,
  isNonSubgraphChainId,
} from '../../../redux/compute/web3/web3.utils';
import {
  INetworkType,
  IOrganisationWallet,
  ITokenType,
} from '../../../redux/compute/web3/web3.interfaces';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import { updateDefaultPaymentThunk } from '../../../redux/compute/organisation/organisation.thunks';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import { fetchWalletBalanceThunk } from '../../../redux/compute/web3/web3.thunks';
import { setMaxDepositedWallet } from '../../../redux/compute/web3/web3.slice';
import { ICurrentApp } from '../../../redux/compute/organisation/organisation.interfaces';

interface IProps {
  disabled: boolean;
  wallet: IOrganisationWallet;
}

const CryptoCardInfo = ({ disabled, wallet }: IProps) => {
  const isPublicAccessible =
    sessionStorage.getItem('isPublicAccessible') === 'true';
  const communityUserAccess =
    sessionStorage.getItem('communityUserAccess') === 'true';

  const dispatchRtk = useDispatch<AppDispatch>();
  const selectedOrganisationId = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation._id
  );

  // const removeWalletsLoading = useSelector(
  //   (state: RootState) => state.web3.removeWalletsLoading
  // );
  const disconnectWalletLoading: boolean = useSelector(
    (state: RootState) => state.web3.disconnectWalletLoading
  );
  const currentAddress = useSelector(
    (state: RootState) => state.web3.currentAccount
  );
  const currentApp = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation.appType
  );

  const token = wallet.details.tokenDetails;
  const network = wallet.details.networkDetails;
  const isSolana = wallet.details.networkDetails?.chainName === 'solana';
  const { balance } = wallet;
  const address = wallet?.details?.address || '';

  const displayBalance =
    isSolana || isNonSubgraphChainId(Number(network?.chainId))
      ? Number(balance?.amount).toFixed(4)
      : `${(
          Number(balance?.amount) /
          10 ** getDecimalFromTokenAddress(balance?.token || '')
        ).toFixed(4)}`;

  useEffect(() => {
    // TODO - Currently only optimised for stablecoins, needs to be added for others
    if (Number(displayBalance) >= 15 && token && network)
      dispatchRtk(
        setMaxDepositedWallet({ balance: displayBalance, token, network })
      );
  }, [dispatchRtk, displayBalance, network, token]);

  useEffect(() => {
    if (balance?.token === '' && currentAddress) {
      if (isNonSubgraphChainId(Number(network?.chainId))) {
        dispatchRtk(
          fetchWalletBalanceThunk({
            walletId: wallet._id,
            address,
            networkDetails: network as INetworkType,
            tokenDetails: token as ITokenType,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAddress]);

  const options = [
    {
      id: 1,
      label: 'View Wallet',
      handleClick: () =>
        dispatchRtk(
          toggleModalShowRtk({
            modalShow: true,
            modalType: 'viewWallet',
            options: {
              walletId: wallet._id,
            },
          })
        ),
      optionType: 'primary' as OptionType,
    },
    //  Disabled Web3 Payment for Compute Services
    ...(currentApp === ICurrentApp.COMPUTE
      ? []
      : [
          {
            id: 2,
            label: 'Deposit Funds',
            handleClick: () =>
              dispatchRtk(
                toggleModalShowRtk({
                  modalShow: true,
                  modalType: 'funds',
                  options: {
                    walletId: wallet._id,
                    type: 'deposit',
                  },
                })
              ),
            optionType: 'primary' as OptionType,
          },
        ]),

    // {
    //   id: 3,
    //   label: 'Withdraw Funds',
    //   handleClick: () =>
    //     dispatchRtk(
    //       toggleModalShowRtk({
    //         modalShow: true,
    //         modalType: 'funds',
    //         options: {
    //           type: 'withdraw',
    //           walletId: id,
    //         },
    //       })
    //     ),
    // optionType: 'primary' as OptionType,
    // },
    {
      id: 4,
      label: disconnectWalletLoading ? 'Disconnecting' : 'Disconnect',
      handleClick: () => {
        dispatchRtk(
          toggleModalShowRtk({
            modalShow: true,
            modalType: 'disconnectWallet',
            options: { id: wallet._id },
          })
        );
      },
      optionType: 'primary' as OptionType,
    },
  ];

  const solanaOptions = [
    {
      id: 1,
      label: 'View Wallet',
      handleClick: () =>
        dispatchRtk(
          toggleModalShowRtk({
            modalShow: true,
            modalType: 'viewPhantomWallet',
            options: {
              id: wallet._id,
            },
          })
        ),
      optionType: 'primary' as OptionType,
    },
    {
      id: 2,
      label: disconnectWalletLoading ? 'Disconnecting' : 'Disconnect',
      handleClick: () => {
        dispatchRtk(
          toggleModalShowRtk({
            modalShow: true,
            modalType: 'disconnectPhantomWallet',
            options: {
              walletAddress: wallet?.details?.address || '',
              id: wallet._id,
            },
          })
        );
      },
      optionType: 'primary' as OptionType,
    },
  ];

  const dropdownOptions = isSolana ? solanaOptions : options;

  const dropdownOptionWithDisable =
    (!wallet.primary && !isPublicAccessible) ||
    (isPublicAccessible && communityUserAccess)
      ? [
          ...dropdownOptions,
          {
            id: 5,
            label: 'Make as Primary',
            handleClick: () =>
              dispatchRtk(
                updateDefaultPaymentThunk({
                  organisationId: selectedOrganisationId,
                  newPaymentMethodId: wallet._id,
                })
              ),
            optionType: 'primary' as OptionType,
          },
        ]
      : dropdownOptions;

  return (
    <AttachedPaymentMethodCard
      id={wallet._id}
      cardType="crypto"
      icon={
        <img
          src={makeBlockie(wallet?.details?.address || '0x1')}
          alt="Blockie"
          className="w-8 h-8 rounded-full"
        />
      }
      isPrimary={wallet.primary}
      title={largeCutText(wallet?.details?.address || '')}
      subtitle={`${
        !wallet.balance.token
          ? 'Balance: NA'
          : `Deposit: ${displayBalance} ${getTokenNameFromAddress(
              wallet.balance?.token || ''
            )}`
      }`}
      networkIcon={
        <img
          src={wallet.details.networkDetails?.logo}
          alt="icon"
          className="rounded-full mr-2 w-5 h-5"
        />
      }
      networkName={
        getNetworkFromName(Number(wallet.details.networkDetails.chainId)).split(
          ' '
        )[0]
      }
      tokenIcon={
        <img
          src={wallet.details.tokenDetails?.logoURI}
          alt="icon"
          className="rounded-full mr-2 w-5 h-5"
        />
      }
      tokenName={wallet.details.tokenDetails.name}
      hasDropdown
      dropdownOptions={disabled ? [] : dropdownOptionWithDisable}
      hasWalletSwitchDropdown={false}
      walletDropdownOptions={[]}
    />
  );
};

export default CryptoCardInfo;
