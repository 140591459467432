import React, { useEffect, useState } from 'react';
import { Modal, TextInput } from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import Styles from '../../../styles/compute/modal.module.scss';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import { sendMemberInviteThunk } from '../../../redux/compute/organisation/organisation.thunks';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import ModalWrapper from './ModalWrapper';

interface IProps {
  isOpen: boolean;
  organisationId: string;
  organisationName: string;
}

const InviteMembersModal = ({
  isOpen,
  organisationId,
  organisationName,
}: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();
  const inviteMemberLoading: boolean = useSelector(
    (state: RootState) => state.organisation.inviteMemberLoading
  );

  const [email, setEmail] = useState<string>('');
  const [validateEmail, setValidateEmail] = useState<boolean>(false);

  useEffect(() => {
    if (email.match('^([a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+,?)*$') && email !== '') {
      setValidateEmail(true);
    } else {
      setValidateEmail(false);
    }
  }, [email]);

  const sendInvite = () => {
    const members = email.split(',').map((member) => member.trim());
    const invites = members.map((member) => ({
      organization: organisationId,
      organizationId: organisationId,
      orgId: organisationId,
      orgName: organisationName,
      userEmail: member,
    }));
    dispatchRtk(sendMemberInviteThunk(invites));
  };

  const closeModal = () => {
    dispatchRtk(toggleModalShowRtk({ modalShow: false }));
    setEmail('');
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={closeModal}
      classname={`${Styles.modal__outer__con} w-430 modal fixed inset-0 overflow-y-auto`}
    >
      <div className={Styles.modal__inner__content}>
        <Modal
          footer
          header
          subtitle=""
          modalContent={
            <div className="flex flex-col gap-y-5 w-full mb-6">
              <div className="text-sm text-base-para-text-color dark:text-dark-base-para-text-color">
                Effortlessly Invite, Manage Roles, and Enhance Collaboration
                within Your Organization
              </div>
              <TextInput
                type="email"
                label="Email of the new members"
                onChange={(value) => setEmail(value as string)}
                value={email}
                placeholder="Email address"
              />
            </div>
          }
          headerContent={<>Invite Team Member</>}
          primaryButtonContent="Send"
          secondaryButtonContent="Cancel"
          primaryButton
          primaryButtonDisabled={email === '' || !validateEmail}
          primaryButtonLoading={inviteMemberLoading}
          onPrimaryButtonClick={sendInvite}
          onSecondaryButtonClick={closeModal}
          secondaryButton
        />
      </div>
    </ModalWrapper>
  );
};

export default InviteMembersModal;
