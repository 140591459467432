/* eslint-disable max-len */
import React from 'react';

interface IProps {
  color?: string;
}

const RefreshIcon = ({ color }: IProps) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8939 2C6.63453 2 3.14703 5.35156 2.91578 9.55625C2.89703 9.84375 3.03297 10.1187 3.27359 10.2781C3.51422 10.4359 3.82203 10.4531 4.07828 10.3219C4.33453 10.1906 4.50172 9.93125 4.51265 9.64375C4.69859 6.27031 7.47047 3.6 10.8939 3.6C12.7283 3.6 14.3736 4.37031 15.5377 5.6H14.4939C14.2048 5.59531 13.9377 5.74688 13.7923 5.99688C13.6455 6.24531 13.6455 6.55469 13.7923 6.80312C13.9377 7.05312 14.2048 7.20469 14.4939 7.2H16.9986C17.0892 7.21563 17.1814 7.21563 17.2736 7.2H18.4939V3.2C18.497 2.98438 18.4127 2.77656 18.2595 2.62344C18.1064 2.47031 17.8986 2.38594 17.6814 2.38906C17.2408 2.39531 16.8877 2.75781 16.8939 3.2V4.71094C15.4267 3.04844 13.2798 2 10.8939 2ZM18.1064 9.58906C17.6642 9.57031 17.2923 9.91406 17.2752 10.3562C17.0892 13.7297 14.3173 16.4 10.8939 16.4C9.05953 16.4 7.41578 15.6297 6.25015 14.4H7.2939C7.58297 14.4047 7.85015 14.2531 7.99547 14.0031C8.14234 13.7547 8.14234 13.4453 7.99547 13.1969C7.85015 12.9469 7.58297 12.7953 7.2939 12.8H4.7814C4.69703 12.7875 4.61265 12.7875 4.52828 12.8H3.2939V16.8C3.28922 17.0891 3.44078 17.3563 3.69078 17.5016C3.93922 17.6484 4.24859 17.6484 4.49703 17.5016C4.74703 17.3563 4.89859 17.0891 4.8939 16.8V15.2891C6.36109 16.9516 8.50797 18 10.8939 18C15.1533 18 18.6408 14.6484 18.872 10.4438C18.8877 10.2281 18.8142 10.0156 18.6705 9.85469C18.5252 9.69375 18.322 9.59844 18.1064 9.58906Z"
        fill={color}
      />
    </svg>
  );
};

RefreshIcon.defaultProps = {
  color: 'currentColor',
};

export default RefreshIcon;
