import React from 'react';
import { Modal } from '@spheron/ui-library';
import { useDispatch } from 'react-redux';
import Styles from '../../../styles/compute/modal.module.scss';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import ModalWrapper from './ModalWrapper';
import { AppDispatch } from '../../../redux/compute/store';

interface IProps {
  isOpen: boolean;
}

const BracketSystemInfo = ({ isOpen }: IProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const closeModal = () => {
    dispatch(toggleModalShowRtk({ modalShow: false }));
  };

  const priceData = [
    { id: 1, price: '$15', cpu: '8', ram: '32', storage: '512' },
    { id: 2, price: '$15 - $45', cpu: '24', ram: '96', storage: '1536' },
    { id: 3, price: '$45 - $100', cpu: '56', ram: '224', storage: '3584' },
    { id: 4, price: '$100 - $500', cpu: '128', ram: '512', storage: '5760' },
  ];

  const bulletTexts = [
    {
      id: 1,
      // eslint-disable-next-line max-len
      text: ' Balance-Driven Compute Allocation: The platform now operates on a bracket system where the amount available in your wallet determines the level of compute resources you can use.',
    },
    {
      id: 2,
      // eslint-disable-next-line max-len
      text: 'Tailored Compute Options: Depending on your wallet balance, the available compute resources have been categorized into brackets. Following are the details:',
    },
  ];

  const tableHeaders = [
    {
      id: 1,
      text: 'Price Range',
    },
    {
      id: 2,
      text: 'CPU',
    },
    {
      id: 3,
      text: 'RAM(in GB)',
    },
    {
      id: 4,
      text: 'Storage(in GB)',
    },
  ];

  const exampleText =
    // eslint-disable-next-line max-len
    'For example: If you have $30 in your compute balance then as per the above bracket, you can deploy compute instances with specifications as 24 CPUs, 96 GB RAM & 1536 GB storage.';

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={closeModal}
      classname={`${Styles.modal__outer__con} w-430 modal fixed inset-0 overflow-y-auto`}
    >
      <div className={Styles.modal__inner__content}>
        <Modal
          footer
          header
          subtitle=""
          modalContent={
            <div className="flex flex-col gap-y-6 w-full mb-6">
              <ul>
                {bulletTexts.map((item) => (
                  <li
                    className="flex flex-row gap-2 text-sm leading-6 font-400"
                    key={item.id}
                  >
                    <span>&#x2022;</span>
                    <span>{item.text}</span>
                  </li>
                ))}
              </ul>
              <table className="table">
                <thead>
                  <tr
                    className="h-[52px] text-left bg-base-fg dark:bg-dark-base-fg
                  text-base-heading-text-color dark:text-dark-base-heading-text-color
                  text-[11px] leading-3 font-700"
                  >
                    {tableHeaders.map((header) => (
                      <th
                        className={`w-1/4 ${header.id === 1 ? 'pl-4' : ''}`}
                        key={header.id}
                      >
                        {header.text}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {priceData.map((item) => (
                    <tr
                      key={item.id}
                      className="h-[52px] border-b border-base-border dark:border-dark-base-border text-sm font-400"
                    >
                      <td className="pl-4">{item.price}</td>
                      <td>{item.cpu}</td>
                      <td>{item.ram}</td>
                      <td>{item.storage}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <span className="text-sm leading-6 font-400">{exampleText}</span>
            </div>
          }
          headerContent={<>How the Bracket System Works</>}
          primaryButtonContent="Got it"
          secondaryButtonContent=""
          primaryButton
          onPrimaryButtonClick={closeModal}
          onSecondaryButtonClick={closeModal}
          secondaryButton={false}
        />
      </div>
    </ModalWrapper>
  );
};

export default BracketSystemInfo;
