import React from 'react';
import { ReactComponent as CheckSuccess } from '../../../../assets/compute/icons/check-circle.svg';
import { useQuery } from '../../../../hooks/useQuery';
import GlobalStyles from '../../../../styles/global.module.scss';

const CliLogin = () => {
  const emailSearchParams = useQuery().get('email');

  return (
    <div
      className={`${GlobalStyles.screen__width}
      flex items-center flex-col`}
    >
      <div className="flex flex-col gap-6 text-center items-center mt-20">
        <CheckSuccess className="h-14 w-14 text-action-primary-default" />
        <h1 className="font-bold text-3xl">CLI Login Success!</h1>
        <h3 className="text-text-darkGray">
          Congratulations! Spheron CLI was successfully authenticated as:
        </h3>
        {!!emailSearchParams && (
          <span
            className="px-4 py-0.5 break-all max-w-full font-medium 
        rounded-full text-text-cliLogin bg-bg-cliLogin"
          >
            {emailSearchParams}
          </span>
        )}
        <h3 className="text-text-darkGray mt-2">
          You can now close this tab and return to Spheron CLI.
        </h3>
      </div>
    </div>
  );
};

export default CliLogin;
