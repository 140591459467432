import { requestPipeline } from '../root-utils';
import { IResponseError } from '../combined-reducer.interface';
import {
  ICoupon,
  ICreateSubscription,
  IBonus,
  ISubscriptionPayments,
  IExpireCoupon,
  ISubscriptionResponse,
  ICouponValidityResponse,
  ITokenPriceResponse,
  ICreateBonusPayloadDto,
  IOrganisationSpecializationPayloadDto,
  IAllowedBonusesResponse,
  IPlanPriceResponse,
  IDefaultPlanResponse,
  IActiveSubscriptionResponse,
  ISubscription,
  IScheduleBonusPayloadDto,
  IScheduleBonusResponseDto,
  ICreateBonusReservationDto,
} from './subscription.interfaces';
import { mapTokenToId } from './subscription.utils';
import {
  ICurrentApp,
  IOrganisationCurrentAppPayloadDto,
  IOrganizationOverdueResponse,
  IPayOverdueResponse,
} from '../organisation/organisation.interfaces';
import { ISpecialization } from '../combined-state.interface';

export const getDefaultPlans = async (
  currentApp: ICurrentApp
): Promise<IDefaultPlanResponse[] | IResponseError> => {
  return requestPipeline({
    url: `subscriptionPackage/${currentApp}`,
    method: 'GET',
  });
};

export const createSubscription = async (
  data: ICreateSubscription
): Promise<ISubscriptionResponse | IResponseError> => {
  return requestPipeline({
    url: `subscription/subscribe`,
    method: 'POST',
    body: JSON.stringify(data),
  });
};

export const getActiveSubscription = async (
  id: string
): Promise<IActiveSubscriptionResponse[] | IResponseError> => {
  return requestPipeline({
    url: `subscription/getAcitveIfExists/${id}`,
    method: 'GET',
  });
};

export const changeSubscriptionPlan = async ({
  subscriptionId,
  organizationId,
}: any): Promise<any> => {
  return requestPipeline({
    url: `subscription/changeSubscriptionPackage`,
    method: 'POST',
    body: JSON.stringify({
      subscriptionPackageId: subscriptionId,
      organizationId,
    }),
  });
};

export const cancelSubscription = async (
  organizationId: string,
  specialization: ISpecialization
): Promise<ISubscriptionResponse | IResponseError> => {
  return requestPipeline({
    url: `subscription/cancelSubscription`,
    method: 'POST',
    body: JSON.stringify({
      organizationId,
      specialization,
    }),
  });
};
// BONUS_PACKAGE

export const calculateBonusPrice = async (
  bonusNumberOfDeployments: string | number,
  bonusBuildTime: string | number
): Promise<any> => {
  return requestPipeline({
    url: `bonus/calculatePrice`,
    method: 'POST',
    body: JSON.stringify({ bonusNumberOfDeployments, bonusBuildTime }),
  });
};

export const getAllBonusPayments = async (id: string): Promise<any> => {
  return requestPipeline({
    url: `bonus/payments/wallet/${id}`,
    method: 'GET',
  });
};

export const createBonusForOrganization = async ({
  walletId,
  organizationId,
  bonusParameterNames,
  bonusParameterValues,
  specialization,
  renewValues,
}: ICreateBonusPayloadDto): Promise<IResponseError> => {
  const indexOfBuildExecution = bonusParameterNames.indexOf(
    'BONUS_BUILD_EXECUTION'
  );
  const indexOfComputeBuildExecution = bonusParameterNames.indexOf(
    'BONUS_CLUSTER_BUILD_EXECUTION'
  );
  const indexOfArweaveStorage = bonusParameterNames.indexOf(
    'BONUS_STORAGE_ARWEAVE'
  );
  const indexOfIPFSStorage = bonusParameterNames.indexOf('BONUS_STORAGE_IPFS');
  bonusParameterValues[indexOfBuildExecution] *= 60;
  bonusParameterValues[indexOfComputeBuildExecution] *= 60;
  bonusParameterValues[indexOfArweaveStorage] *= 1024;
  bonusParameterValues[indexOfIPFSStorage] *= 1024;
  return requestPipeline({
    url: `bonus/create-package`,
    method: 'POST',
    body: JSON.stringify({
      walletId,
      organizationId,
      bonusParameterNames,
      bonusParameterValues,
      specialization,
      renewValues,
    }),
  });
};

export const getActiveBonusPackage = async (
  payload: IOrganisationSpecializationPayloadDto
): Promise<IBonus[] | IResponseError> => {
  return requestPipeline({
    url: `bonus/active/${payload.organizationId}/specialization/${payload.specialization}`,
    method: 'GET',
  });
};

export const calculatePackagePrice = async (
  members: string,
  packageName: string
): Promise<IPlanPriceResponse> => {
  return requestPipeline({
    url: `subscriptionPackage/price?members=${members}&packageName=${packageName}`,
    method: 'GET',
  });
};

export const getSubscriptionInvoices = async (
  organizationId: string
): Promise<ISubscriptionPayments | IResponseError> => {
  return requestPipeline({
    url: `subscription/invoices/${organizationId}`,
    method: 'GET',
  });
};

export const removeActiveBonus = async (
  bonusId: string,
  organizationId: string
): Promise<IResponseError> => {
  return requestPipeline({
    url: `bonus/cancel/${bonusId}`,
    method: 'PUT',
    body: JSON.stringify({
      organizationId,
    }),
  });
};

export const getTokenLivePrice = async (
  token: string
): Promise<ITokenPriceResponse> => {
  return requestPipeline({
    url: `price/liveTokenPrice/${mapTokenToId(token)}`,
    method: 'GET',
    isPublic: true,
  });
};

export const addCouponService = async (payload: {
  couponCode: string;
  organizationId: string;
  captchaToken: string;
}): Promise<ICoupon | IResponseError> => {
  return requestPipeline({
    url: `organization/${payload.organizationId}/coupons/activate`,
    method: 'POST',
    body: JSON.stringify({
      couponActivationCode: payload.couponCode,
      captchaToken: payload.captchaToken,
    }),
  });
};

export const expireCouponService = async ({
  couponCode,
  organizationId,
}: IExpireCoupon): Promise<ICoupon | IResponseError> => {
  return requestPipeline({
    url: `organization/${organizationId}/coupons/deactivate/${couponCode}`,
    method: 'POST',
  });
};

export const checkCouponValidityService = async (
  couponCode: string,
  organizationId: string
): Promise<ICouponValidityResponse> => {
  return requestPipeline({
    url: `organization/${organizationId}/coupons/valid?couponActivationCode=${couponCode}`,
    method: 'GET',
  });
};

export const getAllowedBonusesService = async (
  organizationId: string,
  specialization: ISpecialization
): Promise<IAllowedBonusesResponse> => {
  return requestPipeline({
    url: `bonus/allowed/${organizationId}/${specialization}`,
    method: 'GET',
  });
};

export const organisationOverdueService = async (
  payload: IOrganisationCurrentAppPayloadDto
): Promise<IOrganizationOverdueResponse> => {
  return requestPipeline({
    url: `organization/${payload.organizationId}/overdue`,
    method: 'GET',
  });
};

export const payOverdueForOrganisationService = async (
  walletId: string,
  organisationId: string,
  specialization: ISpecialization
): Promise<IPayOverdueResponse | IResponseError> => {
  return requestPipeline({
    url: `subscription/overdue/pay/${organisationId}/specialization/${specialization}`,
    method: 'POST',
    body: JSON.stringify({ organisationId }),
  });
};

export const getSubscriptionHistoryService = async (
  payload: string
): Promise<{ subscriptions: ISubscription[] } | IResponseError> => {
  return requestPipeline({
    url: `subscription/${payload}`,
    method: 'GET',
  });
};

export const scheduleBonusService = async (
  payload: IScheduleBonusPayloadDto
): Promise<IScheduleBonusResponseDto | IResponseError> => {
  return requestPipeline({
    url: `bonus/schedule`,
    method: 'POST',
    body: JSON.stringify(payload),
  });
};

export const updateBonusAmountService = async ({
  bonusId,
  value,
  organizationId,
}: {
  organizationId: string;
  bonusId: string;
  value: number;
}) => {
  return requestPipeline({
    url: `bonus/update-renew-parameter-value`,
    method: 'PUT',
    body: JSON.stringify({ bonusId, value, organizationId }),
  });
};

export const createBonusReservationService = async (
  payload: ICreateBonusReservationDto
): Promise<
  | {
      success: boolean;
      reservationId: string;
      additionalInformation: any;
      description: string;
    }
  | IResponseError
> => {
  return requestPipeline({
    url: `bonus/reservation/create`,
    method: 'POST',
    body: JSON.stringify(payload),
  });
};
