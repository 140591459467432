import { PublicKey } from '@solana/web3.js';
import config from '../../config';
import { IResponseError } from '../combined-reducer.interface';
import { requestPipeline } from '../root-utils';
import {
  IOrganisationSpecializationPayloadDto,
  ISubscriptionState,
} from '../subscription/subscription.interfaces';
import {
  IActivateReservationDto,
  ICancelBonusReservationDto,
  ICancelReservationDto,
  ICreateReservationDto,
  ISolanaReserveResponse,
} from './solana.interfaces';

export const phantomConnectWalletService = async () => {
  try {
    const { phantom } = window as any;

    if (!phantom) {
      return {
        error: true,
        errorMessage: 'Phantom not installed, please install!',
        account: '',
      };
    }
    const provider = (window as any).phantom?.solana;
    const resp = await provider.connect();

    return {
      error: false,
      errorMessage: '',
      account: resp.publicKey.toString(),
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error in phantomConnectWalletService --> ', error);
    return { error: true, errorMessage: (error as Error).message, account: '' };
  }
};

export const getSolTokenBalanceService = async (walletAddress: string) => {
  try {
    const accountPublicKey = new PublicKey(walletAddress);
    const network = config.web3.solana.SOLANA_RPC;
    const { Connection, LAMPORTS_PER_SOL } = await import('@solana/web3.js');
    const connection = new Connection(network);
    const res = await connection.getAccountInfo(accountPublicKey);
    const sol = (res?.lamports || 0) / LAMPORTS_PER_SOL;
    return { error: false, errorMessage: '', balance: sol };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error in getSolTokenBalanceService --> ', error);
    return { error: true, errorMessage: (error as Error).message, balance: 0 };
  }
};

export const phantomSignAndSendTransactionService = async (
  resId: string,
  description: string,
  amount: number
) => {
  try {
    if (!(window as any).phantom)
      throw new Error('No crypto wallet found. Please install it.');
    const provider = (window as any).phantom?.solana;
    const network = config.web3.solana.SOLANA_RPC;
    const {
      Connection,
      LAMPORTS_PER_SOL,
      PublicKey,
      SystemProgram,
      Transaction,
      TransactionInstruction,
    } = await import('@solana/web3.js');
    const connection = new Connection(network);
    const resp = await provider.connect();
    const transaction = new Transaction().add(
      SystemProgram.transfer({
        fromPubkey: resp.publicKey,
        toPubkey: new PublicKey(config.web3.solana.SOLANA_TREASURY),
        lamports: Math.floor(amount * LAMPORTS_PER_SOL),
      })
    );
    // add transaction details - params and units
    transaction.add(
      new TransactionInstruction({
        keys: [{ pubkey: resp.publicKey, isSigner: true, isWritable: true }],
        data: Buffer.from(`${resId}|${description}`, 'utf-8'),
        programId: new PublicKey('MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr'),
      })
    );

    const { blockhash } = await connection.getLatestBlockhash('finalized');
    transaction.feePayer = await resp.publicKey;
    transaction.recentBlockhash = await blockhash;

    const { signature } = await provider.signAndSendTransaction(transaction);
    await connection.getSignatureStatus(signature);
    return { error: false, errorMessage: '', signature };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error in signMessageService --> ', error);
    return { error: true, errorMessage: (error as Error).message };
  }
};

export const phantomSignMessageService = async (message: string) => {
  try {
    if (!(window as any).phantom)
      throw new Error('No crypto wallet found. Please install it.');

    const provider = (window as any).phantom?.solana;

    const encodedMessage = new TextEncoder().encode(message);
    const signedMessage = await provider.signMessage(encodedMessage, 'utf8');
    return {
      error: false,
      errorMessage: '',
      signedMessage,
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error in phantomSignMessageService --> ', error);
    return { error: true, errorMessage: (error as Error).message };
  }
};

export const createReservationService = async (
  payload: ICreateReservationDto
): Promise<ISolanaReserveResponse | IResponseError> => {
  return requestPipeline({
    url: `subscription/reservation/create`,
    method: 'POST',
    body: JSON.stringify(payload),
  });
};

export const activateReservationService = async (
  payload: IActivateReservationDto,
  type: string
): Promise<{ message: string; success: boolean } | IResponseError> => {
  return requestPipeline({
    url: `${type}/reservation/activate`,
    method: 'PATCH',
    body: JSON.stringify(payload),
  });
};

export const cancelReservationService = async (
  payload: ICancelReservationDto
): Promise<{ message: string; success: boolean } | IResponseError> => {
  return requestPipeline({
    url: `subscription/reservation/cancel`,
    method: 'PATCH',
    body: JSON.stringify(payload),
  });
};

export const cancelBonusReservationService = async (
  payload: ICancelBonusReservationDto
): Promise<{ message: string; success: boolean } | IResponseError> => {
  return requestPipeline({
    url: `bonus/reservation/cancel`,
    method: 'PATCH',
    body: JSON.stringify(payload),
  });
};

export const getStuckReservedSubscriptionService = async (
  payload: string
): Promise<ISubscriptionState | IResponseError> => {
  return requestPipeline({
    url: `subscription/getAcitveIfExists/${payload}`,
    method: 'GET',
  });
};

export const createPayOverdueReservationService = async (
  payload: IOrganisationSpecializationPayloadDto
): Promise<{
  success: boolean;
  reservationId: string;
  description: string | IResponseError;
}> => {
  return requestPipeline({
    // eslint-disable-next-line max-len
    url: `subscription/reservation/overdue/${payload.organizationId}/specialization/${payload.specialization}`,
    method: 'POST',
  });
};

export const activatePayOverdueReservationService = async (
  payload: IActivateReservationDto
): Promise<{ message: string; success: boolean } | IResponseError> => {
  return requestPipeline({
    url: `subscription/reservation/overdue/activate`,
    method: 'PATCH',
    body: JSON.stringify(payload),
  });
};

export const cancelPayOverdueReservationService = async (payload: {
  organizationId: string;
  reservationId: string;
}): Promise<{ message: string; success: boolean } | IResponseError> => {
  return requestPipeline({
    url: `subscription/reservation/cancel-all`,
    method: 'PATCH',
    body: JSON.stringify(payload),
  });
};
