import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  ISolanaReservationResponseDto,
  ISolanaSetStep,
  ISolanaState,
} from './solana.interfaces';
// eslint-disable-next-line import/no-cycle
import {
  createActivationThunk,
  createBonusReservationThunk,
  createReservationThunk,
  createTransactionThunk,
} from './solana.thunks';

export const initialState: ISolanaState = {
  reservationId: '',
  description: '',
  transactionHash: '',
  solanaPayLoading: false,
  solanaPayStepOne: {
    error: false,
    success: false,
    loading: false,
    message: '',
  },
  solanaPayStepTwo: {
    error: false,
    success: false,
    loading: false,
    message: '',
  },
  solanaPayStepThree: {
    error: false,
    success: false,
    loading: false,
    message: '',
  },
  cancelBonusReservationLoading: false,
  payOverdueBill: -1,
};

const solanaSlice = createSlice({
  name: 'solana',
  initialState,
  reducers: {
    setSolanaPayStepInfo(state, action: PayloadAction<ISolanaSetStep>) {
      switch (action.payload.step) {
        case 3:
          state.solanaPayStepThree = action.payload.value!;
          break;
        case 2:
          state.solanaPayStepTwo = action.payload.value!;
          break;
        case 1:
          state.solanaPayStepOne = action.payload.value!;
          break;
        case 0: {
          state.solanaPayStepOne = {
            error: false,
            success: false,
            loading: false,
            message: '',
          };
          state.solanaPayStepTwo = {
            error: false,
            success: false,
            loading: false,
            message: '',
          };
          state.solanaPayStepThree = {
            error: false,
            success: false,
            loading: false,
            message: '',
          };
          break;
        }
        default:
          break;
      }
    },
    setSolanaPayOverdueBill(state, action: PayloadAction<number>) {
      state.payOverdueBill = action.payload;
    },
    setSolanaReservation(
      state,
      action: PayloadAction<ISolanaReservationResponseDto>
    ) {
      state.reservationId = action.payload.reservationId;
      state.description = action.payload.description;
    },

    resetSolanaState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createActivationThunk.pending, (state) => {
      state.solanaPayLoading = true;
    });

    builder.addCase(createActivationThunk.fulfilled, (state) => {
      state.solanaPayLoading = false;
    });

    builder.addCase(createActivationThunk.rejected, (state) => {
      state.solanaPayLoading = false;
    });

    builder.addCase(createTransactionThunk.pending, (state) => {
      state.solanaPayLoading = true;
      state.transactionHash = '';
    });

    builder.addCase(
      createTransactionThunk.fulfilled,
      (state, action: PayloadAction<string>) => {
        state.solanaPayLoading = false;
        state.transactionHash = action.payload;
      }
    );

    builder.addCase(createTransactionThunk.rejected, (state) => {
      state.solanaPayLoading = false;
    });

    builder.addCase(createReservationThunk.pending, (state) => {
      state.solanaPayLoading = true;
    });

    builder.addCase(
      createReservationThunk.fulfilled,
      (state, action: PayloadAction<ISolanaReservationResponseDto>) => {
        state.solanaPayLoading = false;
        state.reservationId = action.payload.reservationId;
        state.description = action.payload.description;
      }
    );

    builder.addCase(createReservationThunk.rejected, (state) => {
      state.solanaPayLoading = false;
    });

    builder.addCase(createBonusReservationThunk.pending, (state) => {
      state.solanaPayLoading = true;
    });

    builder.addCase(
      createBonusReservationThunk.fulfilled,
      (state, action: PayloadAction<ISolanaReservationResponseDto>) => {
        state.solanaPayLoading = false;
        state.reservationId = action.payload.reservationId;
        state.description = action.payload.description;
      }
    );

    builder.addCase(createBonusReservationThunk.rejected, (state) => {
      state.solanaPayLoading = false;
    });
  },
});

export const { reducer: solanaRTKReducer } = solanaSlice;

export const {
  setSolanaPayStepInfo: setSolanaPayStepInfoRtk,
  setSolanaPayOverdueBill: setSolanaPayOverdueBillRtk,
  setSolanaReservation: setSolanaReservationRtk,
  resetSolanaState: resetSolanaStateRtk,
} = solanaSlice.actions;
