import React from 'react';

interface IProps {
  icon: string;
  name: string;
  textClassName?: string;
  imageClassName?: string;
  divClassName?: string;
}

const ProviderNetworkTab = ({
  icon,
  name,
  textClassName,
  imageClassName,
  divClassName,
}: IProps) => {
  return (
    <div
      className={`py-1 px-[5px]
      border border-base-border dark:border-dark-base-border
      rounded-md flex items-center justify-between ${divClassName}`}
    >
      <img
        src={icon}
        alt="icon"
        className={imageClassName || 'rounded-full mr-2 w-5 h-5'}
      />
      <div
        className={`${
          textClassName && textClassName
        } text-xs font-semibold leading-3.5
         `}
      >
        {name}
      </div>
    </div>
  );
};

ProviderNetworkTab.defaultProps = {
  textClassName: '',
  imageClassName: '',
  divClassName: '',
};

export default ProviderNetworkTab;
