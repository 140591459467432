import React from 'react';
import { ReactComponent as ArrowLeft } from '@spheron/ui-library/dist/assets/arrow-left-1.svg';

interface IProps {
  handleClick: () => void;
}

const BackNavigation = ({ handleClick }: IProps) => {
  return (
    <div
      role="presentation"
      onClick={handleClick}
      className="flex items-center justify-start cursor-pointer hover:text-black
      dark:hover:text-white transition ease-in-out font-semibold text-sm w-min"
    >
      <ArrowLeft className="w-4.5 h-4.5 text-inherit mr-2" />
      Back
    </div>
  );
};

export default BackNavigation;
