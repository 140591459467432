import React from 'react';
import Skeleton from 'react-loading-skeleton';

const MetricsLoading = () => {
  return (
    <div
      className="border border-cards-border dark:border-dark-cards-border 
    bg-cards-bg dark:bg-dark-cards-bg rounded-lg p-3 w-full"
    >
      <div className="grid grid-cols-5 gap-x-2 items-center justify-between">
        <div className="grid grid-cols-5 gap-x-2 col-span-3 w-full">
          <Skeleton height={22} width="100%" duration={2} />
          <div className="col-span-4">
            <Skeleton height={22} width="80%" duration={2} />
          </div>
        </div>
        <div className="grid grid-cols-5 items-center justify-start col-span-2">
          <div className="col-span-2">
            <Skeleton height={18} width="100%" duration={2} />
          </div>
          <div className="flex justify-center">/</div>
          <div className="col-span-2">
            <Skeleton height={18} width="100%" duration={2} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MetricsLoading;
