import React from 'react';
import GlobalStyle from '../../../styles/compute/global.module.scss';

interface IProps {
  icon: JSX.Element;
  handleClick?: () => void;
  className?: string;
}

const HoverIcon = ({ icon, handleClick, className }: IProps) => {
  return (
    <div className={`${className || `${GlobalStyle.icon_hover}`}`}>
      <span
        role="presentation"
        className="flex items-center justify-center"
        onKeyDown={() => {}}
        onClick={handleClick}
      >
        {icon}
      </span>
    </div>
  );
};

HoverIcon.defaultProps = {
  className: '',
  handleClick: () => {},
};

export default HoverIcon;
