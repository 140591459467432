import React from 'react';
import { ReactComponent as Exclaimation } from '@spheron/ui-library/dist/assets/spheron-specific/warning.svg';

interface IProps {
  text: string;
  link: string;
}

const ComputeDisclaimer = ({ text, link }: IProps): JSX.Element => {
  return (
    <div
      className="p-3 bg-base-fg dark:bg-dark-base-fg dark:border-dark-base-border
     border border-base-border rounded"
    >
      <div className="flex justify-start items-center">
        <Exclaimation className="h-14 w-32 text-action-primary-default" />

        <p className="ml-3 mt-0.5 text-sm ">
          {text}{' '}
          {!!link && (
            <a
              href={link}
              rel="noreferrer"
              target="_blank"
              className="font-semibold"
            >
              Learn more
            </a>
          )}
        </p>
      </div>
    </div>
  );
};

export default ComputeDisclaimer;
