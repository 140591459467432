import React, { useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { Button, Refresh, TextInput } from '@spheron/ui-library';
import { ReactComponent as ExportIcon } from '@spheron/ui-library/dist/assets/spheron-specific/export.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from '../../../../hooks/useDebounce';
import {
  IInstanceDeployLogs,
  InstanceEvent,
  InstanceLogs,
  InstanceStatus,
} from '../../../../redux/compute/instance/instance.interfaces';
import { getDeploymentLogsService } from '../../../../redux/compute/instance/instance.services';
import GlobalStyle from '../../../../styles/compute/global.module.scss';
import {
  setShowLoadLogsRtk,
  setInstanceDeploymentLogsRtk,
  setFetchInstanceEventType,
} from '../../../../redux/compute/instance/instance.slice';
import {
  fetchInstanceEventsThunk,
  loadMoreDeploymentLogsThunk,
} from '../../../../redux/compute/instance/instance.thunks';
import { AppDispatch, RootState } from '../../../../redux/compute/store';
import LogsCard from '../../../../components/Compute/Cards/logs-card';
import LogsContainer from '../../../../components/Compute/Cards/logs-container';
import { withLoader } from '../../../../redux/compute/root-utils';
import { ReactComponent as SearchIcon } from '../../../../assets/compute/icons/search.svg';

const InstanceLogCard = () => {
  const isPublicAccessible =
    sessionStorage.getItem('isPublicAccessible') === 'true';
  const communityUserAccess =
    sessionStorage.getItem('communityUserAccess') === 'true';

  const dispatchRtk = useDispatch<AppDispatch>();

  const params = useParams<{ slug2: string; logType: string }>();

  const selectedInstance = useSelector(
    (state: RootState) => state.instance.selectedInstance
  );
  const activeDeployment = useSelector(
    (state: RootState) => state.instance.activeDeployment
  );
  const activeDeploymentLoading = useSelector(
    (state: RootState) => state.instance.activeDeploymentLoading
  );
  const selectedInstanceComputeLogs: string[] = useSelector(
    (state: RootState) => state.instance.selectedInstanceComputeLogs
  );
  const selectedInstanceComputeEvents: string[] = useSelector(
    (state: RootState) => state.instance.selectedInstanceComputeEvents
  );
  const selectedInstanceLogsLoading: boolean = useSelector(
    (state: RootState) => state.instance.selectedInstanceLogsLoading
  );
  const selectedInstanceComputeLogsLoading: boolean = useSelector(
    (state: RootState) => state.instance.selectedInstanceComputeLogsLoading
  );
  const selectedInstanceComputeEventsLoading: boolean = useSelector(
    (state: RootState) => state.instance.selectedInstanceComputeEventsLoading
  );
  const selectedInstanceStreamLogs: IInstanceDeployLogs[] = useSelector(
    (state: RootState) => state.instance.selectedInstanceStreamLogs
  );
  const totalComputeEventLogs: number = useSelector(
    (state: RootState) =>
      state.instance.selectedInstanceComputeEventsPagination.total
  );
  const totalComputeLogs: number = useSelector(
    (state: RootState) =>
      state.instance.selectedInstanceComputeLogsPagination.total
  );
  const moreSelectedInstanceComputeLogsLoading: boolean = useSelector(
    (state: RootState) => state.instance.moreSelectedInstanceComputeLogsLoading
  );
  const moreSelectedInstanceComputeEventsLoading: boolean = useSelector(
    (state: RootState) =>
      state.instance.moreSelectedInstanceComputeEventsLoading
  );
  const showLoadLogs: boolean = useSelector(
    (state: RootState) => state.instance.showLoadLogs
  );
  const fetchInstanceEventServiceLoading: boolean = useSelector(
    (state: RootState) => state.instance.fetchInstanceHealthCheckLoading
  );
  const selectedInstanceLoading = useSelector(
    (state: RootState) => state.instance.selectedInstanceLoading
  );

  const [searching, setSearching] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedtab, setSelectedTab] = useState<InstanceLogs>(
    InstanceLogs.COMPUTE_LOGS
  );
  const debouncedSearchTerm = useDebounce(searchValue, 750);
  const componentIsMounted = useRef(true);

  useEffect(() => {
    if (activeDeployment) {
      setSearching(true);
      getDeploymentLogsService(
        activeDeployment._id || '',
        selectedtab,
        1,
        debouncedSearchTerm as string
      )
        .then((response) => {
          if (componentIsMounted.current) {
            dispatchRtk(
              setInstanceDeploymentLogsRtk({
                deployment: response.deployment,
                type: selectedtab,
              })
            );
          }
          setSearching(false);
        })
        .catch(() => {
          if (componentIsMounted.current) {
            setSearching(false);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const withLogsLoading = (
    loading: boolean,
    content: JSX.Element
  ): JSX.Element => {
    if (loading)
      return (
        <>
          {Array.from(Array(4).keys()).map((i) => {
            return (
              <tr className="py-1 px-6" id={`instance-logs-items-${i}`}>
                <Skeleton height={26} duration={2} />
              </tr>
            );
          })}
        </>
      );
    return content;
  };

  useEffect(() => {
    if (
      activeDeployment?.status! === InstanceStatus.PENDING ||
      activeDeployment?.status! === InstanceStatus.QUEUED ||
      activeDeployment?.status! === InstanceStatus.FAILED
    ) {
      setSelectedTab(InstanceLogs.LOGS);
    } else {
      setSelectedTab(InstanceLogs.COMPUTE_LOGS);
    }
  }, [activeDeployment?.status]);

  useEffect(() => {
    setSearchValue('');
  }, [activeDeployment]);

  useEffect(() => {
    if (
      (!selectedInstanceLogsLoading ||
        !selectedInstanceComputeEventsLoading ||
        !selectedInstanceComputeLogsLoading) &&
      showLoadLogs
    )
      dispatchRtk(setShowLoadLogsRtk(false));
  }, [
    dispatchRtk,
    selectedInstanceComputeEventsLoading,
    selectedInstanceComputeLogsLoading,
    selectedInstanceLogsLoading,
    showLoadLogs,
  ]);

  const loadPreviousLogs = (logType: InstanceLogs) => {
    dispatchRtk(
      loadMoreDeploymentLogsThunk({
        deploymentId: activeDeployment?._id || '',
        type: logType,
        search: searchValue,
      })
    );
  };

  const displayLoadMore = () => {
    switch (selectedtab) {
      case InstanceLogs.LOGS:
        return false;

      case InstanceLogs.COMPUTE_LOGS:
        return (
          selectedInstanceComputeLogs.length < totalComputeLogs &&
          !selectedInstanceComputeLogsLoading
        );

      case InstanceLogs.COMPUTE_EVENTS:
        return (
          selectedInstanceComputeEvents.length < totalComputeEventLogs &&
          !selectedInstanceComputeEventsLoading
        );

      default:
        return false;
    }
  };

  const logsDisplay = (status: string | undefined): string => {
    if (status) {
      switch (status) {
        case InstanceStatus.QUEUED:
        case InstanceStatus.PENDING:
          return selectedtab === InstanceLogs.LOGS
            ? 'Please wait while we prepare an environment for your deployment.'
            : // eslint-disable-next-line max-len
              'Please wait till your instance is provisioned. If it is already provisioned, please wait till we sync your logs from the instance.';
        default:
          return `We cannot find any ${
            selectedtab === InstanceLogs.COMPUTE_EVENTS ? `events` : `logs`
            // eslint-disable-next-line max-len
          } for the specific deployment. Please refresh the page or try again later. Don't hesitate to get in touch with the team if the issue persists.`;
      }
    } else {
      return 'Something went wrong...';
    }
  };

  const showFetchLatestLogs =
    activeDeployment?.status === InstanceStatus.DEPLOYED &&
    !selectedInstanceComputeLogsLoading;

  const logsUpdatedAt = dayjs(activeDeployment?.updatedAt).fromNow(true);

  const elementRef = useRef<any>();

  useEffect(() => {
    setSearchValue('');
    setTimeout(() => {
      if (elementRef.current) {
        elementRef.current.scrollTop = 400;
        elementRef.current.style['overflow-y'] = 'auto';
      }
    }, 8000);
  }, []);

  const handleEventLogs = (
    eventType: InstanceLogs,
    fetchType: InstanceEvent
  ) => {
    if (
      selectedInstance &&
      activeDeployment &&
      !fetchInstanceEventServiceLoading
    ) {
      dispatchRtk(setFetchInstanceEventType(eventType));
      dispatchRtk(
        fetchInstanceEventsThunk({
          instanceId: selectedInstance?._id!,
          type: fetchType,
          topicId: activeDeployment?.topic!,
        })
      );
    }
  };

  const isComputeLogAvailable =
    !!selectedInstanceComputeLogs?.length &&
    !!selectedInstanceComputeLogs?.filter((log) => log !== '')?.length;

  const isComputeEventsAvailable =
    !!selectedInstanceComputeEvents?.length &&
    selectedInstanceComputeEvents?.filter((log) => log !== '')?.length;

  const { logType } = params;

  return (
    <>
      {logType === 'instance-logs' && (
        <div>
          <div
            className="flex flex-row justify-between items-center mb-4
                  text-gray-600 dark:text-gray-300 
                  sticky top-0 w-full"
          >
            <div className="flex flex-row items-center gap-x-3">
              {withLoader(
                activeDeploymentLoading ||
                  (selectedInstanceLogsLoading &&
                    selectedInstanceComputeEventsLoading &&
                    selectedInstanceComputeLogsLoading) ||
                  showLoadLogs,
                <Skeleton height={42} width={600} duration={2} />,
                <div className="w-[600px]">
                  <TextInput
                    inputSize="compact"
                    leftIcon={<SearchIcon className="text-inherit" />}
                    className="w-full"
                    value={searchValue}
                    onChange={(value) => setSearchValue(value as string)}
                    placeholder="Search Logs"
                    disabled={
                      (!isComputeLogAvailable && !searchValue?.length) ||
                      selectedInstanceComputeLogsLoading ||
                      searching
                    }
                  />
                </div>
              )}

              {withLoader(
                activeDeploymentLoading,
                <Skeleton height={42} width={111} duration={2} />,
                <Button
                  buttonType="ghost"
                  label="Export"
                  size="small"
                  onClick={() =>
                    handleEventLogs(
                      InstanceLogs.COMPUTE_LOGS,
                      InstanceEvent.DOWNLOAD_LOGS
                    )
                  }
                  leftIcon={
                    <ExportIcon className="w-6 h-6 text-action-ghost-text dark:text-dark-action-ghost-text" />
                  }
                  leftIconClassname="!w-6 !h-6"
                />
              )}
            </div>

            {withLoader(
              activeDeploymentLoading,
              <Skeleton height={24} width={200} duration={2} />,
              <>
                {!(
                  selectedInstanceComputeLogsLoading &&
                  showLoadLogs &&
                  showFetchLatestLogs
                ) &&
                  (!isPublicAccessible || communityUserAccess) && (
                    <Refresh
                      showText={isComputeLogAvailable}
                      handleClick={() => {
                        handleEventLogs(
                          InstanceLogs.COMPUTE_LOGS,
                          InstanceEvent.LOGS
                        );
                      }}
                      loading={selectedInstanceComputeLogsLoading}
                      refreshType="default"
                      time=""
                      customText={`Updated ${logsUpdatedAt} ago`}
                    />
                  )}
              </>
            )}
          </div>
          <div>
            <LogsContainer
              isLogAvailable={isComputeLogAvailable}
              displayLoadMore={displayLoadMore()}
              handleLoadMoreClick={() =>
                loadPreviousLogs(InstanceLogs.COMPUTE_LOGS)
              }
              loadMoreLoading={
                selectedInstanceComputeLogsLoading ||
                moreSelectedInstanceComputeLogsLoading
              }
              logsLoading={
                selectedInstanceComputeLogsLoading || activeDeploymentLoading
              }
              placeholderText={logsDisplay(activeDeployment?.status)}
              logs={selectedInstanceComputeLogs
                ?.filter((log) => log !== '')
                ?.join('\n')}
            />
          </div>
        </div>
      )}
      {logType === 'instance-events' && (
        <>
          <div
            className="flex flex-row justify-between mb-4 items-center
                  text-gray-600 dark:text-gray-300 
                  sticky top-0"
          >
            <div className="flex flex-row items-center gap-x-3">
              {withLoader(
                activeDeploymentLoading ||
                  (selectedInstanceLogsLoading &&
                    selectedInstanceComputeEventsLoading) ||
                  showLoadLogs,
                <Skeleton height={42} width={600} duration={2} />,
                <div className="w-[600px]">
                  <TextInput
                    inputSize="compact"
                    leftIcon={<SearchIcon className="text-inherit" />}
                    className="w-full"
                    value={searchValue}
                    onChange={(value) => setSearchValue(value as string)}
                    placeholder="Search Events"
                    disabled={
                      (!isComputeEventsAvailable && !searchValue?.length) ||
                      selectedInstanceComputeEventsLoading ||
                      searching
                    }
                  />
                </div>
              )}
            </div>

            {withLoader(
              activeDeploymentLoading,
              <Skeleton height={24} width={200} duration={2} />,
              <>
                {!(selectedInstanceComputeLogsLoading && showLoadLogs) && (
                  <Refresh
                    showText={!!isComputeEventsAvailable}
                    handleClick={() => {
                      handleEventLogs(
                        InstanceLogs.COMPUTE_EVENTS,
                        InstanceEvent.LOGS
                      );
                    }}
                    loading={selectedInstanceComputeEventsLoading}
                    refreshType="default"
                    time=""
                    customText={`Updated ${logsUpdatedAt} ago`}
                  />
                )}
              </>
            )}
          </div>
          <div>
            <LogsContainer
              isLogAvailable={Boolean(isComputeEventsAvailable)}
              displayLoadMore={displayLoadMore()}
              handleLoadMoreClick={() =>
                loadPreviousLogs(InstanceLogs.COMPUTE_EVENTS)
              }
              loadMoreLoading={
                selectedInstanceComputeEventsLoading ||
                moreSelectedInstanceComputeEventsLoading
              }
              logsLoading={
                selectedInstanceComputeEventsLoading || activeDeploymentLoading
              }
              placeholderText={logsDisplay(activeDeployment?.status)}
              logs={selectedInstanceComputeEvents.join('\n')}
            />
          </div>
        </>
      )}

      {logType === 'deployment-logs' && (
        <div
          className={
            !selectedInstanceLogsLoading || !showLoadLogs
              ? `dark:bg-dark-base-fg
              bg-base-fg 
              rounded-lg py-6 text-gray-900 text-sm font-medium`
              : ''
          }
        >
          <div className={`relative ${GlobalStyle.bubble__box__min__height}`}>
            {selectedInstanceStreamLogs &&
            selectedInstanceStreamLogs[0]?.log ? (
              <div className="flex flex-col gap-3 max-h-96 overflow-y-scroll">
                {withLogsLoading(
                  selectedInstanceLogsLoading ||
                    activeDeploymentLoading ||
                    selectedInstanceLoading,
                  <>
                    <LogsCard
                      logs={`${selectedInstanceStreamLogs
                        ?.filter((log) => log.log !== '')
                        ?.map((log) => ` ${log.log}`)
                        ?.join('\n')}`}
                    />
                  </>
                )}
              </div>
            ) : (
              <div
                className="text-base-para-text-color dark:text-dark-base-para-text-color
               text-sm px-6 font-normal"
              >
                {withLogsLoading(
                  selectedInstanceLogsLoading ||
                    activeDeploymentLoading ||
                    selectedInstanceLoading,
                  <>{logsDisplay(activeDeployment?.status)}</>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default InstanceLogCard;
