import React, { useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Tab } from '@headlessui/react';
import classNames from 'classnames';
import { useLocation, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { Refresh } from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from '../../../hooks/useDebounce';
import {
  IInstanceDeployLogs,
  InstanceLogs,
  InstanceStatus,
} from '../../../redux/instance/instance.interfaces';
import { getDeploymentLogsService } from '../../../redux/compute/instance/instance.services';
import SearchInput from '../InputFields/search-input';
import GlobalStyle from '../../../styles/compute/global.module.scss';
import {
  setShowLoadLogsRtk,
  setInstanceDeploymentLogsRtk,
} from '../../../redux/compute/instance/instance.slice';
import {
  getDeploymentLogsThunk,
  loadMoreDeploymentLogsThunk,
} from '../../../redux/compute/instance/instance.thunks';
import LogsCard from './logs-card';
import { withLoader } from '../../../redux/compute/root-utils';
import LogsContainer from './logs-container';
import { AppDispatch, RootState } from '../../../redux/compute/store';

interface IProps {
  deploymentId: string;
}

const InstanceLogCard = ({ deploymentId }: IProps) => {
  const isPublicAccessible =
    sessionStorage.getItem('isPublicAccessible') === 'true';
  const communityUserAccess =
    sessionStorage.getItem('communityUserAccess') === 'true';

  const dispatchRtk = useDispatch<AppDispatch>();
  const params = useParams<{ serviceName: string }>();
  const location = useLocation();

  const selectedDeployment = useSelector((state: RootState) =>
    state.instance.selectedInstanceActivity.find(
      (activity) => activity._id === deploymentId
    )
  );
  const selectedInstanceComputeLogs: string[] = useSelector(
    (state: RootState) => state.instance.selectedInstanceComputeLogs
  );
  const selectedInstanceComputeEvents: string[] = useSelector(
    (state: RootState) => state.instance.selectedInstanceComputeEvents
  );
  const selectedInstanceLogsLoading: boolean = useSelector(
    (state: RootState) => state.instance.selectedInstanceLogsLoading
  );
  const selectedInstanceComputeLogsLoading: boolean = useSelector(
    (state: RootState) => state.instance.selectedInstanceComputeLogsLoading
  );
  const selectedInstanceComputeEventsLoading: boolean = useSelector(
    (state: RootState) => state.instance.selectedInstanceComputeEventsLoading
  );
  const selectedInstanceStreamLogs: IInstanceDeployLogs[] = useSelector(
    (state: RootState) => state.instance.selectedInstanceStreamLogs
  );
  const totalComputeEventLogs: number = useSelector(
    (state: RootState) =>
      state.instance.selectedInstanceComputeEventsPagination.total
  );
  const totalComputeLogs: number = useSelector(
    (state: RootState) =>
      state.instance.selectedInstanceComputeLogsPagination.total
  );
  const moreSelectedInstanceComputeLogsLoading: boolean = useSelector(
    (state: RootState) => state.instance.moreSelectedInstanceComputeLogsLoading
  );
  const moreSelectedInstanceComputeEventsLoading: boolean = useSelector(
    (state: RootState) =>
      state.instance.moreSelectedInstanceComputeEventsLoading
  );
  const showLoadLogs: boolean = useSelector(
    (state: RootState) => state.instance.showLoadLogs
  );
  const selectedInstanceLoading = useSelector(
    (state: RootState) => state.instance.selectedInstanceLoading
  );

  const [searching, setSearching] = useState<boolean>(false);
  const [defaultTab, setDefaultTab] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedtab, setSelectedTab] = useState<InstanceLogs>(
    InstanceLogs.COMPUTE_LOGS
  );
  const debouncedSearchTerm = useDebounce(searchValue, 750);
  const componentIsMounted = useRef(true);

  const searchUsedRef = useRef<boolean>(false);

  useEffect(() => {
    if (deploymentId && searchUsedRef.current === true) {
      setSearching(true);
      getDeploymentLogsService(
        deploymentId,
        selectedtab,
        1,
        debouncedSearchTerm as string
      )
        .then((response: any) => {
          if (componentIsMounted.current) {
            dispatchRtk(
              setInstanceDeploymentLogsRtk({
                deployment: response.deployment,
                type: selectedtab,
              })
            );
          }
          setSearching(false);
        })
        .catch(() => {
          if (componentIsMounted.current) {
            setSearching(false);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const instanceLogTabs = [
    {
      title: 'Instance Logs',
      value: 'selectedInstanceLogs',
    },
    {
      title: 'Instance Events',
      value: 'instanceEvents',
    },
    {
      title: 'Deploy Logs',
      value: 'serviceEvents',
    },
  ];

  const withLogsLoading = (
    loading: boolean,
    content: JSX.Element
  ): JSX.Element => {
    if (loading)
      return (
        <>
          {Array.from(Array(4).keys()).map((i) => {
            return (
              <tr className="py-1 px-6" id={`instance-logs-items-${i}`}>
                <Skeleton height={26} duration={2} />
              </tr>
            );
          })}
        </>
      );
    return content;
  };

  useEffect(() => {
    setSearchValue('');
  }, [selectedDeployment]);

  useEffect(() => {
    if (
      (!selectedInstanceLogsLoading ||
        !selectedInstanceComputeEventsLoading ||
        !selectedInstanceComputeLogsLoading) &&
      showLoadLogs
    )
      dispatchRtk(setShowLoadLogsRtk(false));
  }, [
    dispatchRtk,
    selectedInstanceComputeEventsLoading,
    selectedInstanceComputeLogsLoading,
    selectedInstanceLogsLoading,
    showLoadLogs,
  ]);
  const acitvityRoute = location.pathname.includes('/activity-details');

  const loadPreviousLogs = (logType: InstanceLogs) => {
    dispatchRtk(
      loadMoreDeploymentLogsThunk({
        deploymentId,
        type: logType as any,
        search: searchValue,
      })
    );
  };

  useEffect(() => {
    if (deploymentId) {
      dispatchRtk(
        getDeploymentLogsThunk({
          deploymentId,
          type: InstanceLogs.COMPUTE_LOGS as any,
        })
      );

      dispatchRtk(
        getDeploymentLogsThunk({
          deploymentId,
          type: InstanceLogs.LOGS as any,
        })
      );

      dispatchRtk(
        getDeploymentLogsThunk({
          deploymentId,
          type: InstanceLogs.COMPUTE_EVENTS as any,
        })
      );
    }
  }, [deploymentId, dispatchRtk]);

  const displayLoadMore = () => {
    switch (selectedtab) {
      case InstanceLogs.LOGS:
        return false;

      case InstanceLogs.COMPUTE_LOGS:
        return (
          selectedInstanceComputeLogs.length < totalComputeLogs &&
          !selectedInstanceComputeLogsLoading
        );

      case InstanceLogs.COMPUTE_EVENTS:
        return (
          selectedInstanceComputeEvents.length < totalComputeEventLogs &&
          !selectedInstanceComputeEventsLoading
        );
      case InstanceLogs.SHELL:
        return false;
      default:
        return false;
    }
  };

  const logsDisplay = (status: string | undefined): string => {
    if (status) {
      switch (status) {
        case InstanceStatus.QUEUED:
        case InstanceStatus.PENDING:
          return selectedtab === InstanceLogs.LOGS
            ? 'Please wait while we prepare an environment for your deployment.'
            : // eslint-disable-next-line max-len
              'Please wait till your instance is provisioned. If it is already provisioned, please wait till we sync your logs from the instance.';
        default:
          return `We cannot find any ${
            selectedtab === InstanceLogs.COMPUTE_EVENTS ? `events` : `logs`
            // eslint-disable-next-line max-len
          } for the specific deployment. Please refresh the page or try again later. Don't hesitate to get in touch with the team if the issue persists.`;
      }
    } else {
      return '';
    }
  };

  const handleTabClick = (index: number) => {
    setDefaultTab(index);
    setSearchValue('');
    switch (index) {
      case 0:
        setSelectedTab(InstanceLogs.COMPUTE_LOGS);
        break;
      case 1:
        setSelectedTab(InstanceLogs.COMPUTE_EVENTS);
        break;
      case 2:
        setSelectedTab(InstanceLogs.LOGS);
        break;
      case 3:
        setSelectedTab(InstanceLogs.SHELL);
        break;
      default:
        break;
    }
  };

  const showFetchLatestLogs =
    selectedDeployment?.status === InstanceStatus.DEPLOYED &&
    !selectedInstanceComputeLogsLoading;

  const logsUpdatedAt = dayjs(selectedDeployment?.updatedAt).fromNow(true);

  const elementRef = useRef<any>();

  useEffect(() => {
    setSearchValue('');
    setTimeout(() => {
      if (elementRef.current) {
        elementRef.current.scrollTop = 400;
        elementRef.current.style['overflow-y'] = 'auto';
      }
    }, 8000);
  }, []);

  const refreshLogs = (logType: InstanceLogs) => {
    dispatchRtk(
      getDeploymentLogsThunk({
        deploymentId,
        type: logType as any,
        search: searchValue,
      })
    );
  };

  const isComputeLogAvailable =
    !!selectedInstanceComputeLogs?.length &&
    !!selectedInstanceComputeLogs?.filter((log) => log !== '')?.length;

  const isComputeEventsAvailable =
    !!selectedInstanceComputeEvents?.length &&
    selectedInstanceComputeEvents?.filter((log) => log !== '')?.length;
  return (
    <>
      <Tab.Group
        selectedIndex={defaultTab}
        onChange={(index) => handleTabClick(index)}
      >
        <div className="flex flex-col md:flex-row gap-4 items-center">
          {withLoader(
            selectedInstanceLoading,
            <div className="flex w-full md:w-1/2 xl:w-5/12 my-6">
              <Skeleton
                height={42}
                width={550}
                duration={2}
                borderRadius={8}
                className="w-full"
              />
            </div>,
            <Tab.List
              id="instance-update-list"
              className="flex
                        gap-x-8 items-center justify-start"
            >
              {instanceLogTabs.map((tab) => {
                if (
                  (tab.value === 'shellCommands' &&
                    selectedDeployment?.status !== InstanceStatus.DEPLOYED) ||
                  (acitvityRoute && tab.value === 'shellCommands')
                ) {
                  return null;
                }
                return (
                  <Tab
                    key={tab.value}
                    className={({ selected }) =>
                      classNames(
                        // eslint-disable-next-line max-len
                        'w-min text-sm font-semibold whitespace-nowrap leading-6 focus:outline-none',
                        selected
                          ? `border-b-2 border-form-selected text-base-para-text-color 
                    dark:text-dark-base-para-text-color`
                          : `text-base-sub-text-color 
                                      dark:text-dark-base-sub-text-color`
                      )
                    }
                  >
                    {tab.title}
                  </Tab>
                );
              })}
            </Tab.List>
          )}
        </div>
        <Tab.Panels className="mt-5">
          <Tab.Panel>
            <>
              <div
                className="flex flex-row justify-between items-center mb-4
                  text-gray-600 dark:text-gray-300 
                  sticky top-0 w-full"
              >
                {withLoader(
                  selectedInstanceLoading ||
                    (selectedInstanceLogsLoading &&
                      selectedInstanceComputeEventsLoading &&
                      selectedInstanceComputeLogsLoading) ||
                    showLoadLogs,
                  <Skeleton height={42} width={730} duration={2} />,
                  <SearchInput
                    placeholder="Search logs"
                    array={[]}
                    type="repository"
                    onChange={() => null}
                    disabled={
                      (!isComputeLogAvailable && !searchValue?.length) ||
                      selectedInstanceComputeLogsLoading
                    }
                    value={searchValue}
                    setValue={(value: string) => {
                      setSearchValue(value as string);
                      searchUsedRef.current = true;
                    }}
                    className={GlobalStyle.log__search__bar__con}
                    inputClassName={GlobalStyle.log__search__bar}
                    loading={searching}
                  />
                )}

                {withLoader(
                  selectedInstanceLoading,
                  <Skeleton height={24} width={180} duration={2} />,
                  <>
                    {!(
                      selectedInstanceComputeLogsLoading &&
                      showLoadLogs &&
                      showFetchLatestLogs
                    ) &&
                      (!isPublicAccessible || communityUserAccess) && (
                        <Refresh
                          showText={isComputeLogAvailable}
                          handleClick={() => refreshLogs(selectedtab)}
                          loading={selectedInstanceComputeLogsLoading}
                          refreshType="default"
                          time=""
                          customText={`Updated ${logsUpdatedAt} ago`}
                        />
                      )}
                  </>
                )}
              </div>
              <LogsContainer
                isLogAvailable={isComputeLogAvailable}
                displayLoadMore={displayLoadMore()}
                handleLoadMoreClick={() => loadPreviousLogs(selectedtab)}
                loadMoreLoading={
                  selectedInstanceComputeLogsLoading ||
                  moreSelectedInstanceComputeLogsLoading
                }
                logsLoading={
                  selectedInstanceComputeLogsLoading || selectedInstanceLoading
                }
                placeholderText={logsDisplay(selectedDeployment?.status)}
                logs={selectedInstanceComputeLogs
                  ?.filter((log) => log.includes(`[${params.serviceName}]`))
                  ?.filter((log) => log !== '')
                  ?.map((log) =>
                    log.slice(
                      (params?.serviceName?.length || 0) + 2,
                      log.length
                    )
                  )
                  ?.join('\n')}
              />
            </>
          </Tab.Panel>
          <Tab.Panel>
            <>
              <div
                className="flex flex-row justify-between mb-4 items-center
                  text-gray-600 dark:text-gray-300 
                  sticky top-0"
              >
                {withLoader(
                  selectedInstanceLoading ||
                    (selectedInstanceLogsLoading &&
                      selectedInstanceComputeEventsLoading) ||
                    showLoadLogs,
                  <Skeleton height={42} width={930} duration={2} />,
                  <SearchInput
                    placeholder="Search events"
                    array={[]}
                    type="repository"
                    onChange={() => null}
                    value={searchValue}
                    setValue={(value: string) => {
                      setSearchValue(value as string);
                      searchUsedRef.current = true;
                    }}
                    className={GlobalStyle.log__search__bar__con}
                    inputClassName={GlobalStyle.log__search__bar}
                    disabled={
                      (!isComputeEventsAvailable && !searchValue?.length) ||
                      selectedInstanceComputeEventsLoading
                    }
                    loading={searching}
                  />
                )}
                {withLoader(
                  selectedInstanceLoading,
                  <Skeleton height={24} width={180} duration={2} />,
                  <>
                    <>
                      {!(
                        selectedInstanceComputeLogsLoading && showLoadLogs
                      ) && (
                        <Refresh
                          showText={Boolean(isComputeEventsAvailable)}
                          handleClick={() => refreshLogs(selectedtab)}
                          loading={selectedInstanceComputeEventsLoading}
                          refreshType="default"
                          time=""
                          customText={`Updated ${logsUpdatedAt} ago`}
                        />
                      )}
                    </>
                  </>
                )}
              </div>
              <LogsContainer
                isLogAvailable={Boolean(isComputeEventsAvailable)}
                displayLoadMore={displayLoadMore()}
                handleLoadMoreClick={() => loadPreviousLogs(selectedtab)}
                loadMoreLoading={
                  selectedInstanceComputeEventsLoading ||
                  moreSelectedInstanceComputeEventsLoading
                }
                logsLoading={
                  selectedInstanceComputeEventsLoading ||
                  selectedInstanceLoading
                }
                placeholderText={logsDisplay(selectedDeployment?.status)}
                logs={selectedInstanceComputeEvents.join('\n')}
              />
            </>
          </Tab.Panel>

          <Tab.Panel>
            <div
              className={
                !selectedInstanceLogsLoading || !showLoadLogs
                  ? `dark:bg-dark-base-fg
              bg-base-fg 
              rounded-lg py-6 text-gray-900 text-sm font-medium`
                  : ``
              }
            >
              <div
                className={`relative ${GlobalStyle.bubble__box__min__height}`}
              >
                {selectedInstanceStreamLogs &&
                selectedInstanceStreamLogs[0]?.log ? (
                  <div className="flex flex-col gap-3 max-h-96 overflow-y-scroll">
                    {withLogsLoading(
                      selectedInstanceLogsLoading,
                      <>
                        <LogsCard
                          logs={`${selectedInstanceStreamLogs
                            ?.filter((log) => log.log !== '')
                            ?.map((log) => ` ${log.log}`)
                            ?.join('\n')}`}
                        />
                      </>
                    )}
                  </div>
                ) : (
                  <span className="dark:text-gray-300 px-6">
                    {withLogsLoading(
                      selectedInstanceLogsLoading || selectedInstanceLoading,
                      <>{logsDisplay(selectedDeployment?.status)}</>
                    )}
                  </span>
                )}
              </div>
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </>
  );
};

export default InstanceLogCard;
