import React from 'react';
import CLI from '../Misc/cli';

const CreateNewMultiServiceInstance = () => {
  const steps = [
    {
      id: 1,
      heading: 'Install Spheron CLI',
      preCliText:
        // eslint-disable-next-line max-len
        'Start off by install Spheron CLI on your machine. Use the following command:',
      postCliText:
        // eslint-disable-next-line max-len
        'This command installs Spheron CLI on your local MAC Machine. For Windows Machine, please run your terminal in admin mode',
      cliText: 'sudo npm install -g @spheron/cli',
    },
    {
      id: 2,
      heading: 'Intialize Spheron Configuration',
      preCliText:
        // eslint-disable-next-line max-len
        'Begin by initializing the Spheron configuration to include all the services you plan to deploy. Use the following command:',
      postCliText:
        // eslint-disable-next-line max-len
        'This command creates a spheron.yaml file containing all the services imported from your docker-compose.yaml file and any applications from the marketplace you wish to include.',
      cliText:
        'spheron init [--import <DOCKER COMPOSE PATH / DOCKERFILE PATH>] [--marketplace <APP ID>]',
    },
    {
      id: 3,
      heading: 'Build and Push Services to Dockerhub',
      preCliText:
        // eslint-disable-next-line max-len
        'Next, build any services you have on your local machine and push them to Dockerhub. Execute the command:',
      postCliText:
        // eslint-disable-next-line max-len
        'This command builds all the services detailed in your local spheron.yaml file and pushes them to Dockerhub using the image name provided in the file.',
      cliText: 'spheron build --dockerhub -u <username> -p <pass>',
    },
    {
      id: 4,
      heading: 'Deploy Services to a New Instance',
      preCliText:
        // eslint-disable-next-line max-len
        'Once building and pushing is complete, deploy all your services to a new instance with the following command:',
      postCliText:
        // eslint-disable-next-line max-len
        'This deploys all your services within an instance. Congratulations, your server is now live within a couple of minutes!',
      cliText: 'spheron deploy',
    },
    {
      id: 5,
      heading: 'View Your Instances',
      preCliText:
        // eslint-disable-next-line max-len
        'After deployment, you can view all your instances either through the Spheron Dashboard or locally by using the following command:',
      postCliText:
        // eslint-disable-next-line max-len
        'To understand the full capabilities of the Spheron CLI or troubleshoot any issues, refer to the Spheron documentation or community support forums.',
      cliText: 'spheron instances',
    },
  ];
  return (
    <div className="flex flex-col gap-y-5">
      <h5
        className="text-base-heading-text-color
       dark:text-dark-base-heading-text-color text-sm font-normal"
      >
        Here are the steps to deploy your instance:
      </h5>
      <div className="flex flex-col gap-y-8">
        {steps.map((step) => (
          <div key={step.id} className="flex flex-col gap-y-2">
            <h4
              className="text-cards-text font-semibold
            dark:text-dark-base-heading-text-color text-sm"
            >
              {step.id}. {step.heading}
            </h4>
            <div className="pl-5 flex flex-col gap-y-2">
              <p
                className="text-sm text-base-sub-text-color
             dark:text-dark-base-sub-text-color"
              >
                {step.preCliText}
              </p>
              <CLI text={step.cliText} showCopyIcon />
              <p
                className="text-base-sub-text-color dark:text-dark-base-sub-text-color 
            text-sm font-400 w-9/12"
              >
                {step.postCliText}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CreateNewMultiServiceInstance;
