import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IModalState } from './modal.interfaces';

export const initialState: IModalState = {
  modals: [],
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    toggleModalShow(state, action: PayloadAction<any>) {
      // If there comes a modal with modalShow: True -> then it will get added to the stack along
      // with its options else it will popped
      if (action.payload.modalShow) {
        state.modals = [
          ...state.modals,
          {
            modalType: action.payload.modalType as string,
            options: action.payload.options,
          },
        ];
      } else if (
        !action.payload.modalShow &&
        state.modals.find(
          (modal) => modal.modalType === action.payload.modalType
        )
      ) {
        state.modals = [...state.modals].filter(
          (modal) => action.payload.modalType === modal.modalType
        );
      } else {
        state.modals = [...state.modals].slice(0, -1);
      }
    },
    clearAllModal(state) {
      state.modals = [];
    },
  },
});

export const { reducer: modalRTKReducer } = modalSlice;
export const {
  toggleModalShow: toggleModalShowRtk,
  clearAllModal: clearAllModalRtk,
} = modalSlice.actions;
