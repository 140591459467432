/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Modal, TextInput } from '@spheron/ui-library';
import GithubRegistry from '@spheron/ui-library/dist/assets/crypto-social/GitHub.svg';
import { useDispatch, useSelector } from 'react-redux';
import Styles from '../../../styles/compute/modal.module.scss';
import {
  updatePrivateCredentialIconRtk,
  updatePrivateCredentialNameRtk,
  updatePrivateCredentialPasswordRtk,
  updatePrivateCredentialUrlRtk,
} from '../../../redux/compute/instance/instance-creation/instance-creation.slice';
import ModalWrapper from './ModalWrapper';
import { ConfirmRedeploySwitchType } from '../../../redux/compute/instance/instance-creation/instance-creation.interfaces';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import { RootState } from '../../../redux/compute/store';
import CredentialLoading from '../Icons/credential-loading';
import AwsRegistry from '../../../assets/compute/icons/aws-registry.svg';
import AzureRegistry from '../../../assets/compute/icons/azure-registry.svg';
import GoogleCloudeRegistry from '../../../assets/compute/icons/google-cloud-registry.svg';
import DefaultRegistry from '../../../assets/compute/icons/default-registry.svg';
import DockerRegistry from '../../../assets/compute/icons/docker-credential-icon.svg';

interface IProps {
  isOpen: boolean;
}

const PrivateDockerCredentials = ({ isOpen }: IProps) => {
  const dispatchRtk = useDispatch();
  const icon = useSelector(
    (state: RootState) => state.instanceCreation.privateCredentialIcon
  );
  const [name, setName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [nameError, setNameError] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [registryUrl, setRegistryUrl] = useState<string>('');
  const [registryUrlError, setRegistryUrlError] = useState<boolean>(false);

  const closeModal = () => {
    dispatchRtk(
      toggleModalShowRtk({
        modalShow: false,
        type: ConfirmRedeploySwitchType.DEFAULT,
      })
    );
  };
  const handleConfirm = () => {
    dispatchRtk(updatePrivateCredentialNameRtk(name));
    dispatchRtk(updatePrivateCredentialPasswordRtk(password));
    dispatchRtk(updatePrivateCredentialUrlRtk(registryUrl));
    dispatchRtk(
      toggleModalShowRtk({
        modalShow: false,
        type: ConfirmRedeploySwitchType.DEFAULT,
      })
    );
  };

  const handleInputChange = (fieldName: string, value: string) => {
    switch (fieldName) {
      case 'name':
        setName(value);
        setNameError(false);
        break;
      case 'password':
        setPassword(value);
        setPasswordError(false);
        break;
      case 'registry':
        setRegistryUrl(value);
        setRegistryUrlError(false);
        break;
      default:
        break;
    }
  };

  const [showLoading, setShowLoading] = useState<boolean>(false);

  useEffect(() => {
    if (registryUrl !== '') {
      setShowLoading(true);
      let registryIcon;
      switch (true) {
        case String(registryUrl).includes('amazonaws.com'):
          registryIcon = AwsRegistry;
          break;
        case String(registryUrl).includes('ghcr.io'):
          registryIcon = GithubRegistry;
          break;
        case String(registryUrl).includes('gcr.io'):
          registryIcon = AzureRegistry;
          break;
        case String(registryUrl).includes('azurecr.io'):
          registryIcon = GoogleCloudeRegistry;
          break;
        case String(registryUrl).includes('docker.io'):
          registryIcon = DockerRegistry;
          break;
        default:
          registryIcon = DefaultRegistry;
      }
      dispatchRtk(updatePrivateCredentialIconRtk(registryIcon));
      setTimeout(() => {
        setShowLoading(false);
      }, 3000);
    }
  }, [registryUrl, dispatchRtk]);

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={() => {
        dispatchRtk(toggleModalShowRtk({ modalShow: false }));
      }}
      classname={`${Styles.modal__outer__con} modal fixed inset-0 overflow-y-auto`}
    >
      <div className={Styles.modal__inner__content}>
        <Modal
          footer
          header
          modalContent={
            <div className="flex flex-col space-y-6 !w-full">
              <TextInput
                value={registryUrl}
                label="REGISTRY"
                onChange={(value) =>
                  handleInputChange('registry', value as string)
                }
                type="string"
                className="!w-full"
                required
                placeholder="Enter registry URL"
                error={registryUrlError}
                leftIcon={
                  <>
                    {icon && registryUrl !== '' && (
                      <>
                        {showLoading ? (
                          <CredentialLoading />
                        ) : (
                          <img
                            src={icon}
                            alt="registry"
                            width={24}
                            height={24}
                          />
                        )}
                      </>
                    )}
                  </>
                }
              />
              <TextInput
                value={name}
                label="USERNAME"
                onChange={(value) => handleInputChange('name', value as string)}
                type="string"
                required
                placeholder="Enter unique username"
                error={nameError}
              />
              <TextInput
                value={password}
                label="PASSWORD"
                onChange={(value) =>
                  handleInputChange('password', value as string)
                }
                type="string"
                required
                placeholder="Enter access token"
                error={passwordError}
              />
            </div>
          }
          headerContent={<>Add Credentials</>}
          subtitle={`You can provide password or an Access Token with read privileges for us to pull the image.\nTo generate a token on Docker Hub for instance you can visit Account Setting -> Security. For other registry providers check out our docs.`}
          primaryButtonContent="Add Credential"
          secondaryButtonContent="Cancel"
          primaryButton
          primaryButtonLoading={false}
          primaryButtonDisabled={
            name === '' || password === '' || registryUrl === ''
          }
          secondaryButtonDisabled={false}
          secondaryButton
          onPrimaryButtonClick={handleConfirm}
          onSecondaryButtonClick={closeModal}
        />
      </div>
    </ModalWrapper>
  );
};

export default PrivateDockerCredentials;
