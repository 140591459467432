/* eslint-disable max-len */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ButtonType, Feedback, FeedbackType, Tabs } from '@spheron/ui-library';
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as TickCircleIcon } from '@spheron/ui-library/dist/assets/tick-circle.svg';
import { ReactComponent as DangerIcon } from '@spheron/ui-library/dist/assets/danger.svg';
import Sticky from 'react-stickynode';
import { ReactComponent as Headphone } from '@spheron/ui-library/dist/assets/headphone.svg';
import { ReactComponent as WalletIcon } from '@spheron/ui-library/dist/assets/wallet.svg';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../redux/compute/store';
import GlobalStyles from '../../../../../styles/compute/global.module.scss';
import ChooseClusterSection from '../ChooseClusterSection';
import SelectTemplateSection from '../SelectTemplateSection';
import ProvideImage from '../ProvideImage';
import {
  IClusterTemplate,
  IClusterTemplateVariable,
} from '../../../../../redux/compute/cluster/cluster.interfaces';
import {
  getInstancePlansEndRtk,
  resetPersistentValuesRtk,
  setIpLeaseType,
  setScalingNameRtk,
  setScalingTypeRtk,
  setSelectedService,
  setUpdatedCustomPriceRtk,
  updateComputeStepRtk,
  updateCustomCpuRtk,
  updateCustomPlanRtk,
  updateCustomRamRtk,
  updateCustomStorageRtk,
  updateLoadMoreCurrentPageRtk,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.slice';
import { useQuery } from '../../../../../hooks/useQuery';
import BackNavigation from '../../../../../components/Compute/Buttons/back-navigation';
import SelectScaling from '../SelectScaling';
import ChooseRegion from '../ChooseRegion';
import ChooseDeploymentType from '../ChooseDeploymentType/choose-deployment-type';
import {
  mapBuildEnv,
  mapPortToConfig,
} from '../../../../../redux/compute/instance/instance.utils';
import CreateNewMultiServiceInstanceSection from '../CreateMultiServiceInstanceSection/create-multiservice-instance-section';
import { toggleShouldFetchComputeProjectInstances } from '../../../../../redux/compute/project/project.slice';
import {
  toggleShouldFetchInstanceDetails,
  resetInstanceState,
} from '../../../../../redux/compute/instance/instance.slice';
import MultiServicePlanGrid from '../MultiServicePlanGrid';
import {
  IAutoscalingRequestPayload,
  IClusterTemplateConfig,
} from '../../../../../redux/compute/instance/instance.interfaces';
import { startTemplateInstanceDeploymentThunk } from '../../../../../redux/compute/instance/instance.thunks';
import SelectedPlanLoading from '../../../../../components/Compute/Loaders/selected-plan-loader';
import config from '../../../../../config';
import {
  ClusterType,
  IInstancePlan,
  IInstancePlans,
  InstanceDeploymentType,
  IPLeaseType,
  ResourcesAvailable,
  ScalingType,
  ScalingTypeName,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.interfaces';
import { isScalable } from '../../../../../redux/compute/instance/instance-creation/instance-creation.utils';
import { ISpheronWallet } from '../../../../../redux/compute/organisation/organisation.interfaces';
import { CustomStorageOption } from '../CustomStorage/custom-storage';
import {
  getAvailableInventoryThunk,
  getClusterRegionThunk,
  getInstanceUnitPricesThunk,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.thunks';
import { setSelectedTemplateRtk } from '../../../../../redux/compute/cluster/cluster.slice';
import MultiServicePriceCard from '../MultiServicePriceCard';
import PlanGrid from '../PlanGrid';
import { createUsername } from '../../../../../redux/compute/root-utils';
import ChooseInstanceName from '../ChooseInstanceName';
import { toggleModalShowRtk } from '../../../../../redux/compute/modal/modal.slice';

const ComputePricing = () => {
  const dispatchRtk = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const queryParams = useQuery();
  const provider = queryParams.get('provider');
  const type = queryParams.get('type');
  const params = useParams<{ orgUsername: string; computeProjectId: string }>();
  const [selectedMSInstancePlans, setSelectedMSInstancePlans] = useState<any>(
    []
  );
  const [selectedInstancePlan, setSelectedInstancePlan] = useState<any>();
  const [planSearchValue, setPlanSearchValue] = useState<string | null>(null);
  const [cardClicked, setCardClicked] = useState<boolean>(false);
  const [updatedStep, setUpdatedStep] = useState<number>(1);
  const [updatedTemplate, setUpdatedTemplate] =
    useState<IClusterTemplate | null>(null);

  const [advanceConfigRecord, setAdvanceConfigRecord] = useState<
    Record<string, any>
  >({});

  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [instanceName, setInstanceName] = useState<string>('');
  const [totalResources, setTotalResources] = useState<{
    cpu: number;
    ram: number;
  }>({ cpu: 0, ram: 0 });

  const computeStep = useSelector(
    (state: RootState) => state.instanceCreation.computeStep
  );
  const clusterType = useSelector(
    (state: RootState) => state.instanceCreation.clusterType
  );
  const deploymentType = useSelector(
    (state: RootState) => state.instanceCreation.deploymentType
  );
  const selectedOrganisation = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation
  );
  const selectedTemplate = useSelector(
    (state: RootState) => state.cluster.selectedTemplate
  );
  const selectedClusterRegion = useSelector(
    (state: RootState) => state.instanceCreation.clusterRegion
  );
  const multiserviceInstanceCreation = useSelector(
    (state: RootState) => state.instanceCreation.multiserviceInstanceCreation
  );
  const clusterScaling = useSelector(
    (state: RootState) => state.instanceCreation.clusterScaling
  );
  const clusterScalingName = useSelector(
    (state: RootState) => state.instanceCreation.clusterScalingName
  );
  const instancePlansLoading = useSelector(
    (state: RootState) => state.instanceCreation.instancePlanLoading
  );
  const recommendedPlanLoading = useSelector(
    (state: RootState) => state.instanceCreation.recommendedPlanLoading
  );
  const currentApp = useSelector(
    (state: RootState) => state.organisation.currentApp
  );
  const spheronWallet = useSelector(
    (state: RootState) => state.organisation.spheronWallet
  );
  const clusterWalletUsage = useSelector(
    (state: RootState) => state.cluster.clusterWalletUsage
  );
  const clusterWalletUsageLoading: boolean = useSelector(
    (state: RootState) => state.cluster.clusterWalletUsageLoading
  );
  const persistentStorageAvailable = useSelector(
    (state: RootState) => state.instanceCreation.persistentStorageAvailable
  );
  const storageAvailable = useSelector(
    (state: RootState) => state.instanceCreation.storageAvailable
  );
  const planAvailable = useSelector(
    (state: RootState) => state.instanceCreation.planAvailable
  );
  const selectedInstance = useSelector(
    (state: RootState) => state.instance.selectedInstance
  );
  const instancePlans: IInstancePlans = useSelector(
    (state: RootState) => state.instanceCreation.instancePlans
  );
  const instanceDeploymentLoading: boolean = useSelector(
    (state: RootState) => state.instance.deployingInstance
  );
  const subscriptionUsage = useSelector(
    (state: RootState) => state.subscription.subscriptionUsage
  );
  const subscriptionUsageLoading = useSelector(
    (state: RootState) => state.subscription.subscriptionUsageLoading
  );

  useEffect(() => {
    if (provider === null) {
      dispatchRtk(updateComputeStepRtk(1));
    } else {
      dispatchRtk(updateComputeStepRtk(3));
    }
  }, [clusterType, provider, dispatchRtk, deploymentType]);

  const planGridRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (cardClicked && planGridRef.current) {
      const topOffset = 90;
      const planGridTop = planGridRef.current.offsetTop - topOffset;
      window.scrollTo({
        top: planGridTop,
        behavior: 'smooth',
      });
      setCardClicked(false);
    }
  }, [cardClicked, planGridRef]);

  useEffect(() => {
    if (provider) {
      setUpdatedStep(3);
      dispatchRtk(updateComputeStepRtk(updatedStep));
    } else {
      setUpdatedStep(1);
      dispatchRtk(updateComputeStepRtk(updatedStep));
    }
  }, [provider, clusterType, dispatchRtk, updatedStep]);

  useEffect(() => {
    if (updatedTemplate) {
      setUpdatedStep(3);
      dispatchRtk(updateComputeStepRtk(updatedStep));
    }
  }, [dispatchRtk, updatedTemplate, clusterType, updatedStep]);

  useEffect(() => {
    const resetCustomPlan = () => {
      dispatchRtk(updateCustomCpuRtk(''));
      dispatchRtk(updateCustomRamRtk(''));
      dispatchRtk(updateCustomStorageRtk(0));
      dispatchRtk(setUpdatedCustomPriceRtk(0));
      dispatchRtk(updateCustomPlanRtk({}));
      dispatchRtk(setIpLeaseType(IPLeaseType.SHARED));
      if (!provider && !updatedTemplate) {
        dispatchRtk(
          getInstancePlansEndRtk({ totalCount: 0, akashMachineImages: [] })
        );
      }
      if (provider !== 'template' && !updatedTemplate) {
        dispatchRtk(setSelectedTemplateRtk(null));
      }
    };
    resetCustomPlan();
    return () => {
      resetCustomPlan();
    };
  }, [dispatchRtk, provider, updatedTemplate]);

  useEffect(() => {
    if (deploymentType === InstanceDeploymentType.ACCELERATE) {
      dispatchRtk(setScalingTypeRtk(ScalingType.NO));
      dispatchRtk(setScalingNameRtk(ScalingTypeName.NO));
    } else {
      dispatchRtk(setScalingTypeRtk(ScalingType.MANUAL));
      dispatchRtk(setScalingNameRtk(ScalingTypeName.MANUAL));
    }
    dispatchRtk(getClusterRegionThunk());
    dispatchRtk(
      getInstancePlansEndRtk({ totalCount: 0, akashMachineImages: [] })
    );
    setUpdatedStep(2);
    dispatchRtk(updateComputeStepRtk(2));
    dispatchRtk(updateLoadMoreCurrentPageRtk(0));
  }, [deploymentType, dispatchRtk, selectedOrganisation, type]);

  useEffect(() => {
    dispatchRtk(toggleShouldFetchComputeProjectInstances(true));
    dispatchRtk(toggleShouldFetchComputeProjectInstances(true));
    dispatchRtk(toggleShouldFetchInstanceDetails(true));
  }, [dispatchRtk]);

  const referralBalance = (spheronWallet?.details as ISpheronWallet)
    ?.referralBalance;

  const creditsBalance = (spheronWallet?.details as ISpheronWallet)
    ?.creditsBalance;
  const balance = clusterWalletUsage?.balance
    ? (clusterWalletUsage?.balance).toFixed(
        config.dollarDecimalRound.DOLLAR_DECIMAL_ROUND
      )
    : 0;

  const configAvailable =
    planAvailable === ResourcesAvailable.NOT_AVAILABLE ||
    storageAvailable === ResourcesAvailable.NOT_AVAILABLE ||
    persistentStorageAvailable === ResourcesAvailable.NOT_AVAILABLE;

  useEffect(() => {
    if (selectedMSInstancePlans && selectedMSInstancePlans.length > 0) {
      let cpuSum = 0;
      let ramSum = 0;
      selectedMSInstancePlans.forEach((plan: any) => {
        if (plan.instancePlan) {
          cpuSum += Number(plan.instancePlan.cpu);
          ramSum += Number(plan.instancePlan.memory.slice(0, -2));
        }
      });
      setTotalResources({ cpu: cpuSum, ram: ramSum });
    }
  }, [selectedMSInstancePlans]);

  const insufficientBalanceCheck =
    Object.keys(subscriptionUsage).length > 0 &&
    (subscriptionUsage.cpuLimit - subscriptionUsage.cpu < totalResources.cpu ||
      subscriptionUsage.memoryLimit - subscriptionUsage.memory <
        totalResources.ram);

  const outOfStockMessage = [
    {
      id: 1,
      feedbackCardType: 'info',
      condition: configAvailable,
      heading: 'Out of Stock',
      subHeading:
        'Selected Compute Specifications Unavailable. Please Contact Support for Assistance.',
      buttonLabel: 'Contact Team',
      buttonIcon: <Headphone className="w-5 h-5" />,
      messageClass:
        // eslint-disable-next-line max-len
        'bg-feedback-info-bg dark:bg-dark-feedback-info-bg text-feedback-info-text dark:text-dark-feedback-info-text',
      buttonType: 'primary' as ButtonType,
    },
    {
      id: 2,
      feedbackCardType: 'error',
      condition:
        !clusterWalletUsageLoading &&
        !subscriptionUsageLoading &&
        (Number(balance) <= 0 || insufficientBalanceCheck),
      heading: 'Insufficient Balance: Limited Range',
      // eslint-disable-next-line max-len
      subHeading: `Your balance might not cover plans ranging from ${totalResources.cpu}CPU, ${totalResources.ram}Gi RAM. Supercharge your plan by adding funds.`,
      buttonLabel: 'Go to Billing',
      buttonIcon: <WalletIcon className="text-white w-5 h-5" />,
      messageClass:
        // eslint-disable-next-line max-len
        'bg-feedback-error-bg dark:bg-dark-feedback-error-bg text-feedback-error-text dark:text-dark-feedback-error-text',
      buttonType: 'error' as ButtonType,
      secondaryId: 2.1,
      secondaryButtonLabel: 'View all Range',
      secondaryButtonType: 'ghost' as ButtonType,
    },
    {
      id: 3,
      feedbackCardType: 'info',
      condition:
        clusterScaling === ScalingType.NO &&
        Number((referralBalance + creditsBalance).toFixed(2)) > 0,
      heading: 'Go to On-Demand Scaling',
      subHeading:
        // eslint-disable-next-line max-len
        'The organization has a payment method associated with Spheron Credits. Please note that the provisioning of SPOT compute deployments is restricted when utilizing this payment method.',
      buttonLabel: 'Try On-Demand',
      buttonIcon: <span>🚀</span>,
      messageClass:
        // eslint-disable-next-line max-len
        'bg-feedback-info-bg dark:bg-dark-feedback-info-bg text-feedback-info-text dark:text-dark-feedback-info-text',
      buttonType: 'primary' as ButtonType,
    },
  ];

  const [disabledTabRecord, setDisabledTabRecord] = useState<
    Record<string, { disabled: boolean; name: string }>
  >({});

  const [toggleTabError, setToggleTabError] = useState<boolean>(false);

  useEffect(() => {
    setDisabledTabRecord({});
  }, [selectedTemplate]);

  const handleDeployDisabled = (serviceId: string, disabled: boolean) => {
    const foundServiceName = selectedTemplate?.services.find(
      (service) => service._id === serviceId
    )?.metadata.name;
    const tempDisabledRecord = disabledTabRecord;
    tempDisabledRecord[serviceId] = {
      disabled,
      name: foundServiceName || '',
    };
    setDisabledTabRecord(tempDisabledRecord);
    setToggleTabError(!toggleTabError);
  };

  const tabsServices = useMemo(
    () =>
      selectedTemplate?.services?.map((item, index) => {
        const tabError = instanceDeploymentLoading
          ? false
          : disabledTabRecord[item._id]?.disabled;
        return {
          id: index,
          tabId: item._id,
          serviceItem: item,
          serviceId: item._id,
          label: item.metadata.name || `Service ${index + 1}`,
          error: tabError,
          leftIcon: tabError ? (
            <DangerIcon className="w-5 h-5 text-action-error-default dark:text-dark-action-error-default" />
          ) : (
            <TickCircleIcon className="w-5 h-5 text-base-icon dark:text-dark-base-icon" />
          ),
        };
      }) || [
        {
          id: 0,
          label: 'Service 1',
          serviceItem: null,
          serviceId: '0',
        },
      ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toggleTabError, selectedTemplate?.services]
  );

  const startInstanceDeployment = () => {
    const uniqueTopicId = uuidv4();
    // template instance deploy configuration
    const clusterTemplateConfig: IClusterTemplateConfig = {
      name: instanceName || null,
      computeProjectId: params.computeProjectId,
      organizationId: selectedOrganisation._id,
      uniqueTopicId,

      region: selectedClusterRegion || 'any',
      scalable: isScalable(clusterScaling),

      services: selectedTemplate!.services?.map((service) => {
        const {
          customStorage,
          serviceCount,
          customSsdStorage,
          customHddStorage,
          customNvmStorage,
          customPersistentStorage,
          mountPointValue,
          customPlan,
        } = multiserviceInstanceCreation![service._id];
        const {
          portMapping,
          buildArgs,
          buildCommands,
          buildEnv,
          secretBuildEnv,
          templateEnv,
          autoScalingPolicy,
          showCustomAutoScaling,
        } = advanceConfigRecord[service._id];

        const envVars = templateEnv.map((env: IClusterTemplateVariable) => ({
          key: env.name,
          value: env.defaultValue.trim(),
          isSecret: false,
        }));
        const newBuildEnv = buildEnv.concat(secretBuildEnv).concat(envVars);

        const persistentStorage =
          (customSsdStorage > 0 && customSsdStorage) ||
          (customHddStorage > 0 && customHddStorage) ||
          (customNvmStorage > 0 && customNvmStorage);

        const customPlanSelected: boolean = selectedMSInstancePlans
          .find((plan: any) => plan.serviceId === service._id)
          ?.instancePlan?._id.includes('custom-id');

        const customInstanceSpecs: any =
          customPlan?._id?.includes('custom-id') && customPlanSelected
            ? {
                cpu: Number(customPlan.cpu),
                memory: `${customPlan.memory}`,
                storage: `${customStorage.toString()}Gi`,
              }
            : {
                storage: `${customStorage.toString()}Gi`,
              };

        let persistentStorageClass = 'beta2';

        if (customPersistentStorage) {
          switch (customPersistentStorage) {
            case CustomStorageOption.CUSTOMHDDSTORAGE:
              persistentStorageClass = 'beta1';
              break;
            case CustomStorageOption.CUSTOMSSDSTORAGE:
              persistentStorageClass = 'beta2';
              break;
            case CustomStorageOption.CUSTOMNVMSTORAGE:
              persistentStorageClass = 'beta3';
              break;
            default:
              break;
          }
        }
        if (persistentStorage && customPersistentStorage) {
          customInstanceSpecs.persistentStorage = {
            size: `${persistentStorage.toString()}Gi`,
            class: persistentStorageClass,
            mountPoint: mountPointValue.trim(),
          };
        }
        const autoScalingRules =
          clusterScalingName === ScalingTypeName.AUTO && !showCustomAutoScaling
            ? {
                plan: autoScalingPolicy.plan,
                maximumInstances: autoScalingPolicy.maximumInstances,
                minimumInstances: autoScalingPolicy.minimumInstances,
              }
            : {
                ...autoScalingPolicy,
              };

        const machineImageName = selectedMSInstancePlans.find(
          (plan: any) => plan.serviceId === service._id
        )?.instancePlan?.name;

        return {
          name: createUsername(service.metadata.name || service.dockerImage),
          tag: service.dockerImageTag,
          templateId: service._id,
          image: service.dockerImage,
          serviceCount,
          akashMachineImageName: machineImageName,
          ports: mapPortToConfig(portMapping),

          env: newBuildEnv.length !== 0 ? mapBuildEnv(newBuildEnv) : [],
          command:
            buildCommands.length !== 0
              ? buildCommands.map((build: string) => build.trim())
              : [],
          args:
            buildArgs.length !== 0
              ? buildArgs.map((args: string) => args.trim())
              : [],
          customServiceSpecs: customInstanceSpecs,
          autoscalingRules:
            clusterScalingName === ScalingTypeName.AUTO
              ? (autoScalingRules as IAutoscalingRequestPayload)
              : null,
        };
      }),
    };

    if (clusterType === ClusterType.TEMPLATE)
      dispatchRtk(
        startTemplateInstanceDeploymentThunk({
          clusterTemplateConfig,
        })
      );
  };

  const handleButtonClick = (id: number) => {
    switch (id) {
      case 1:
        window.open('https://b4t4v7fj3cd.typeform.com/to/kM6t7oRm');
        break;
      case 2:
        navigate(`/${currentApp}/billing/plan`);
        break;
      case 2.1:
        dispatchRtk(
          toggleModalShowRtk({
            modalShow: true,
            modalType: 'bracketSystemInfo',
          })
        );
        break;
      case 3:
        dispatchRtk(setScalingNameRtk(ScalingTypeName.MANUAL));
        dispatchRtk(setScalingTypeRtk(ScalingType.MANUAL));
        dispatchRtk(resetPersistentValuesRtk());
        break;
      default:
        break;
    }
  };

  const isManuallyScaled = selectedInstance?.scalable;

  useEffect(() => {
    dispatchRtk(resetInstanceState());
    dispatchRtk(getInstanceUnitPricesThunk());
    dispatchRtk(getAvailableInventoryThunk());
  }, [dispatchRtk]);

  useEffect(() => {
    if (selectedTemplate && instancePlans) {
      const initialSelectedPlans = selectedTemplate.services.map(
        (service: { _id: string; defaultAkashMachineImageId: string }) => {
          const foundCustomPlan = instancePlans.akashMachineImages.find(
            (image) => image._id.slice(10) === service._id
          );
          if (foundCustomPlan)
            return {
              serviceId: service._id,
              instancePlan: foundCustomPlan,
            };
          const foundPlan = instancePlans.akashMachineImages.find(
            (image) => image._id === service.defaultAkashMachineImageId
          );
          return {
            serviceId: service._id,
            instancePlan: foundPlan,
          };
        }
      );
      setSelectedMSInstancePlans(initialSelectedPlans);
    }
  }, [instancePlans, selectedTemplate]);

  const handleInstancePlanClick = (plan: {
    serviceId: string;
    selectedInstancePlan: IInstancePlan | null;
  }) => {
    if (selectedMSInstancePlans?.length > 0) {
      const objectIndex = selectedMSInstancePlans?.findIndex(
        (selectedPlan: any) => selectedPlan.serviceId === plan.serviceId
      );
      if (objectIndex !== -1 && objectIndex !== null) {
        const updatedArray = [...selectedMSInstancePlans];
        updatedArray[objectIndex] = {
          serviceId: plan.serviceId,
          instancePlan: plan.selectedInstancePlan,
        };

        setSelectedMSInstancePlans(updatedArray);
      }
    }
  };

  const handleSetAdvanceConfig = (advanceConfig: any) => {
    const tempRecord = advanceConfigRecord;
    tempRecord[advanceConfig.serviceId] = advanceConfig;

    setAdvanceConfigRecord(tempRecord);
  };

  const sections = [
    <div className="flex flex-col gap-y-[52px]">
      <ChooseDeploymentType />
      {deploymentType === InstanceDeploymentType.MULTISERVICE ? (
        <CreateNewMultiServiceInstanceSection />
      ) : (
        <>
          {deploymentType === InstanceDeploymentType.COMPUTE && (
            <SelectScaling />
          )}
          <ChooseClusterSection
            setSelectedInstancePlan={setSelectedMSInstancePlans}
            setUpdatedTemplate={setUpdatedTemplate}
          />
          {clusterType === ClusterType.TEMPLATE ? (
            <SelectTemplateSection
              setSelectedInstancePlan={setSelectedMSInstancePlans}
              setCardClicked={setCardClicked}
              updatedTemplate={updatedTemplate}
              setUpdatedTemplate={setUpdatedTemplate}
            />
          ) : (
            <ProvideImage setCardClicked={setCardClicked} />
          )}

          {computeStep >= 3 && (
            <>
              <div ref={planGridRef}>
                <ChooseInstanceName
                  isUpdate={false}
                  instanceName={instanceName}
                  setInstanceName={setInstanceName}
                />
                <ChooseRegion
                  setSelectedInstancePlan={setSelectedMSInstancePlans}
                  isUpdate={false}
                />
              </div>

              {selectedTemplate && selectedTemplate?.services?.length > 1 ? (
                <>
                  <div
                    className="space-y-2 pt-5 -my-5 bg-base-bg dark:bg-dark-base-bg
                   sticky top-[72px] z-10"
                  >
                    <h1
                      className="text-base-heading-text-color dark:text-dark-base-heading-text-color
                   font-500 text-5 leading-6"
                    >
                      Personalize Your Compute Services
                    </h1>
                    <h1
                      className="text-base-sub-text-color dark:text-dark-base-sub-text-color
                      font-400 text-[14px] leading-4"
                    >
                      Shape your deployment by specifying regions, plans, and
                      configurations for a seamless multi-services experience.
                    </h1>
                    <div className="pt-3 grid gap-x-6 grid-cols-4 ll:grid-cols-4 ll:w-full">
                      <div
                        className="dark:border-dark-base-border bg-base-fg dark:bg-dark-base-fg rounded-lg
                    px-5 col-span-3"
                      >
                        <Tabs
                          tabList={tabsServices}
                          tabListPosition="left"
                          selectedTab={selectedTab}
                          tabListClassname="pt-4 pb-2 mx-auto text-red"
                          onClick={(id: number) => {
                            setSelectedTab(id);
                            dispatchRtk(
                              setSelectedService(tabsServices[id].serviceId)
                            );
                          }}
                        />
                      </div>
                      <hr className="border border-base-border dark:border-dark-base-border col-span-3 mt-5" />
                    </div>
                  </div>

                  <div className="grid grid-cols-4 gap-4 w-full">
                    <div className="col-span-3 w-full">
                      {tabsServices.map((service) => (
                        <div
                          className={
                            selectedTab === service.id ? 'block' : 'hidden'
                          }
                        >
                          <MultiServicePlanGrid
                            isUpdate={false}
                            id={service.serviceId}
                            serviceItem={service.serviceItem}
                            selectedInstancePlan={selectedMSInstancePlans}
                            setSelectedInstancePlan={handleInstancePlanClick}
                            selectedGpuInstancePlan={selectedMSInstancePlans}
                            setSelectedGpuInstancePlan={
                              setSelectedMSInstancePlans
                            }
                            setDisabled={handleDeployDisabled}
                            setUpdateDisabled={() => null}
                            setAdvanceConfig={handleSetAdvanceConfig}
                            searchValue={planSearchValue}
                            setSearchValue={setPlanSearchValue}
                          />
                        </div>
                      ))}
                    </div>

                    <div className="z-20">
                      {(isManuallyScaled || true) && (
                        <Sticky top={80}>
                          {selectedMSInstancePlans &&
                          !instancePlansLoading &&
                          !recommendedPlanLoading ? (
                            <>
                              <MultiServicePriceCard
                                isUpdate={false}
                                updateDisabled={false}
                                selectedInstancePlan={selectedMSInstancePlans}
                                startInstanceDeployment={
                                  startInstanceDeployment
                                }
                                disabledRecord={disabledTabRecord}
                                insufficientBalance={insufficientBalanceCheck}
                              />
                              {outOfStockMessage.map((message) => {
                                return (
                                  <div
                                    className="mt-4 gap-y-4"
                                    key={message.id}
                                  >
                                    {message.condition && (
                                      <>
                                        <Feedback
                                          title={message.heading}
                                          subTitle={message.subHeading}
                                          feedbackType={
                                            message.feedbackCardType as FeedbackType
                                          }
                                          showClose={false}
                                          ctaDetails={{
                                            buttonType: message.buttonType,
                                            size: 'small',
                                            onClick: () =>
                                              handleButtonClick(message.id),
                                            label: message.buttonLabel,
                                            leftIcon: message.buttonIcon,
                                          }}
                                          secondaryCtaDetails={
                                            message.secondaryButtonType
                                              ? {
                                                  buttonType:
                                                    message.secondaryButtonType,
                                                  size: 'small',
                                                  onClick: () =>
                                                    handleButtonClick(
                                                      message.secondaryId
                                                    ),
                                                  label:
                                                    message.secondaryButtonLabel,
                                                }
                                              : undefined
                                          }
                                        />
                                      </>
                                    )}
                                  </div>
                                );
                              })}
                            </>
                          ) : (
                            <SelectedPlanLoading />
                          )}
                        </Sticky>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <PlanGrid
                  isUpdate={false}
                  selectedInstancePlan={selectedInstancePlan}
                  instanceName={instanceName}
                  setSelectedInstancePlan={setSelectedInstancePlan}
                />
              )}
            </>
          )}
        </>
      )}
    </div>,
  ];

  const visibleSections = sections;

  const handleBack = () => {
    navigate(`/${params.orgUsername}/project/${params.computeProjectId}`);
  };

  return (
    <div className={GlobalStyles.screen__width}>
      <>
        <div className="mt-4">
          <BackNavigation handleClick={handleBack} />
        </div>

        {visibleSections}
      </>
    </div>
  );
};

export default ComputePricing;
