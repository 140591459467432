/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
import React, { useEffect, Fragment, useState, useMemo } from 'react';
import makeBlockie from 'ethereum-blockies-base64';
import { useNavigate } from 'react-router-dom';
import {
  AttachedPaymentMethodCard,
  Button,
  Link,
  Modal,
} from '@spheron/ui-library';
import { ReactComponent as RefreshIcon } from '@spheron/ui-library/dist/assets/refresh-2.svg';
import Skeleton from 'react-loading-skeleton';
import { ReactComponent as CopyIcon } from '@spheron/ui-library/dist/assets/copy.svg';
import { ReactComponent as LinkIcon } from '@spheron/ui-library/dist/assets/connected.svg';
import { ReactComponent as ClockIcon } from '@spheron/ui-library/dist/assets/clock-circle.svg';
import { ReactComponent as DisconnectIcon } from '@spheron/ui-library/dist/assets/login.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useShortAddress } from '../../../hooks/useShortAddress';
import {
  getNetworkFromName,
  getBlockExplorerName,
  isNonSubgraphChain,
  getDecimalFromTokenAddress,
  getTokenNameFromAddress,
} from '../../../redux/compute/web3/web3.utils';
import { IModalFooter } from '../../../redux/combined-state.interface';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import Styles from '../../../styles/compute/modal.module.scss';
import { copyToClipboard } from '../../../redux/compute/user/user.utils';
import {
  INetworkType,
  IOrganisationWallet,
  ITokenType,
} from '../../../redux/compute/web3/web3.interfaces';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import { withLoader } from '../../../redux/root-utils';
import {
  connectWalletThunk,
  disconnectWalletThunk,
  fetchWalletAvailableBalanceThunk,
} from '../../../redux/compute/web3/web3.thunks';
import { getTokenLivePrice } from '../../../redux/compute/subscription/subscription.services';
import ModalWrapper from './ModalWrapper';
import { ICurrentApp } from '../../../redux/compute/organisation/organisation.interfaces';
import { fetchSolBalanceThunk } from '../../../redux/solana/solana.thunks';
import WalletBalance from '../Balance';
import ProviderNetworkTab from '../../WalletInfo/provider-network-tab';

interface IProps {
  isOpen: boolean;
  walletId: string;
}
const ViewWallet = ({ isOpen, walletId }: IProps) => {
  const web3Wallets = useSelector((state: RootState) => state.web3.wallets);

  const disconnectWalletLoading: boolean = useSelector(
    (state: RootState) => state.web3.disconnectWalletLoading
  );

  const currentApp = useSelector(
    (state: RootState) => state.organisation.currentApp
  );

  const balanceLoading = useSelector(
    (state: RootState) => state.web3.escrowBalanceLoading
  );

  const balance = useSelector((state: RootState) => state.web3.balance);

  const selectedTokenAvailableBalanceLoading = useSelector(
    (state: RootState) => state.web3.selectedTokenAvailableBalanceLoading
  );

  const selectedTokenAvailableBalance = useSelector(
    (state: RootState) => state.web3.selectedTokenAvailableBalance
  );

  const [selectedWallet, setSelectedWallet] = useState<IOrganisationWallet>();
  const [copied, setCopied] = useState<boolean>(false);
  const [liveTokenPrice, setLiveTokenPrice] = useState<number>(0);
  const [liveTokenPriceLoading, setLiveTokenPriceLoading] =
    useState<boolean>(true);
  const [showAvailableBalance, setShowAvailableBalance] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const dispatchRtk = useDispatch<AppDispatch>();

  const displayBalance = useMemo(() => {
    return selectedWallet && isNonSubgraphChain(selectedWallet)
      ? Number(balance?.amount || 0).toFixed(4)
      : (
          Number(balance?.amount || 0) /
          10 ** getDecimalFromTokenAddress(balance?.token?.toLowerCase() || '')
        ).toFixed(4);
  }, [balance, selectedWallet]);

  useEffect(() => {
    if (walletId) {
      setSelectedWallet(web3Wallets.find((wallet) => wallet._id === walletId));
    }
  }, [walletId, web3Wallets]);

  useEffect(() => {
    return () => {
      setCopied(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // eslint-disable-next-line valid-typeof
    if (typeof selectedTokenAvailableBalance !== null) {
      setShowAvailableBalance(true);
    }
  }, [selectedTokenAvailableBalance]);

  useEffect(() => {
    if (selectedWallet) {
      setLiveTokenPriceLoading(true);
      getTokenLivePrice(selectedWallet.details.tokenDetails.symbol).then(
        (res) => {
          setLiveTokenPrice(res.price);
          setLiveTokenPriceLoading(false);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWallet?._id]);

  const modalFooterElements: IModalFooter[] = [
    {
      id: 1,
      label: copied ? 'Copied' : 'Copy',
      icon: (
        <CopyIcon className="w-3 h-3 text-base-link dark:text-dark-base-link" />
      ),
      onClick: () => {
        copyToClipboard(selectedWallet?.details.address || '');
        setCopied(true);
      },
    },
    {
      id: 2,
      label: getBlockExplorerName(
        Number(selectedWallet?.details.networkDetails!.chainId)
      ),
      icon: (
        <LinkIcon className="w-3 h-3 text-base-link dark:text-dark-base-link" />
      ),
      onClick: () => {
        window.open(
          // eslint-disable-next-line max-len
          `${
            selectedWallet?.details.networkDetails?.blockExplorerUrls![0] ||
            'https://mumbai.polygonscan.com/'
          }address/${selectedWallet?.details.address}`,
          '_blank'
        );
      },
    },
    {
      id: 3,
      label: 'History',
      icon: (
        <ClockIcon className="w-3 h-3 text-base-link dark:text-dark-base-link" />
      ),
      onClick: () => {
        dispatchRtk(toggleModalShowRtk({ modalShow: false }));
        navigate(`/${currentApp}/billing/payment-history`);
      },
    },
    {
      id: 4,
      label: disconnectWalletLoading ? 'Disconnecting' : 'Disconnect',
      icon: (
        <DisconnectIcon className="w-3 h-3 text-base-link dark:text-dark-base-link" />
      ),
      onClick: () => {
        dispatchRtk(
          disconnectWalletThunk({
            id: walletId,
            address: selectedWallet!.details.address,
            networkDetails: selectedWallet!.details.networkDetails,
            tokenDetails: selectedWallet!.details.tokenDetails,
          })
        );
      },
    },
  ];

  const shortAddress = useShortAddress(selectedWallet?.details.address || '');

  const handleWalletBalanceRefresh = () => {
    dispatchRtk(
      fetchWalletAvailableBalanceThunk({
        address: selectedWallet?.details.address || '',
        tokenDetails: selectedWallet?.details.tokenDetails as ITokenType,
        networkDetails: selectedWallet?.details.networkDetails as INetworkType,
      })
    );
  };

  const handleClick = (type: string, walletId: string) => {
    dispatchRtk(
      toggleModalShowRtk({
        modalShow: true,
        modalType: 'funds',
        options: {
          type,
          walletId,
        },
      })
    );
  };

  const closeModal = () => {
    dispatchRtk(
      toggleModalShowRtk({
        modalShow: false,
      })
    );
  };

  useEffect(() => {
    if ((window as any)?.ethereum)
      if (selectedWallet) {
        const nonSubgraphChainPayload = {
          walletId: selectedWallet._id,
          tokenDetails: selectedWallet.details.tokenDetails,
          address: selectedWallet.details.address,
          networkDetails: selectedWallet.details.networkDetails,
        };
        const {
          tokenDetails: { address: tokenAddress },
          networkDetails: { chainId, chainName },
          address,
        } = selectedWallet.details;

        const fetchBalancePayload = {
          token: tokenAddress,
          address,
          chainId: Number(chainId),
        };
        if (chainName === 'solana') {
          dispatchRtk(fetchSolBalanceThunk(fetchBalancePayload));
          return;
        }
        if (selectedWallet && !selectedTokenAvailableBalanceLoading) {
          dispatchRtk(connectWalletThunk()).then(() =>
            dispatchRtk(
              fetchWalletAvailableBalanceThunk(nonSubgraphChainPayload)
            )
          );
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWallet, (window as any).ethereum]);

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={() => dispatchRtk(toggleModalShowRtk({ modalShow: false }))}
      classname={`${Styles.modal__outer__con} modal fixed inset-0 overflow-y-auto`}
    >
      <div className={`${Styles.modal__inner__content} !max-w-[530px]`}>
        <Modal
          footer={false}
          header
          subtitle=""
          modalContent={
            <div className="flex flex-col gap-y-3 w-full">
              <div className="py-3 bg-base-fg dark:bg-dark-base-fg rounded-lg">
                <div className="flex items-center justify-center flex-col gap-y-1">
                  <h5
                    className="flex items-center justify-start text-base
                             font-semibold text-base-heading-text-color 
                            dark:text-dark-base-heading-text-color gap-x-3"
                  >
                    <span>Available Deposit: </span>
                    <div className="flex items-center justify-start gap-x-1">
                      {withLoader(
                        liveTokenPriceLoading || balanceLoading,
                        <>
                          <Skeleton height={18} width={40} duration={2} />
                        </>,
                        <>
                          $
                          {(Number(displayBalance) * liveTokenPrice).toFixed(2)}
                        </>
                      )}

                      <div className="text-[10px] font-normal flex items-center">
                        {!balanceLoading && '('}
                        {withLoader(
                          balanceLoading,
                          <>
                            <>
                              <Skeleton height={10} width={40} duration={2} />
                            </>
                          </>,
                          <>
                            <WalletBalance selectedWallet={selectedWallet} />
                          </>
                        )}

                        {!balanceLoading && ')'}
                      </div>
                    </div>
                  </h5>
                </div>
              </div>
              <>
                <div className="w-full relative">
                  <AttachedPaymentMethodCard
                    id="0"
                    cardType="card"
                    icon={
                      selectedWallet ? (
                        <img
                          src={makeBlockie(
                            selectedWallet?.details?.address || ''
                          )}
                          alt="address-blockie"
                          className="w-8 h-8 rounded-full mr-2.5"
                        />
                      ) : (
                        <></>
                      )
                    }
                    isPrimary={false}
                    title={shortAddress}
                    subtitle={
                      showAvailableBalance
                        ? `Balance: ${
                            selectedWallet && selectedTokenAvailableBalance
                              ? Number(
                                  selectedTokenAvailableBalance || 0
                                ).toFixed(4)
                              : (
                                  Number(selectedTokenAvailableBalance || 0) /
                                  10 **
                                    getDecimalFromTokenAddress(
                                      selectedWallet?.details.tokenDetails.address.toLowerCase() ||
                                        ''
                                    )
                                ).toFixed(4)
                          } ${getTokenNameFromAddress(
                            selectedWallet?.details.tokenDetails.address.toLowerCase() ||
                              ''
                          )}`
                        : ''
                    }
                    hasDropdown={false}
                    dropdownOptions={[]}
                    hasWalletSwitchDropdown={false}
                    walletDropdownOptions={[]}
                  />
                  <RefreshIcon
                    className="w-6 h-6 text-base-icon dark:text-dark-base-icon absolute right-2 top-2 cursor-pointer"
                    onClick={handleWalletBalanceRefresh}
                  />
                </div>
              </>
              <>
                <div
                  className="flex justify-between p-3 bg-base-fg dark:bg-dark-base-fg
                         rounded-lg items-center gap-x-3 relative"
                >
                  {modalFooterElements.map((item) => (
                    <Link
                      text={item.label}
                      leftIcon={item.icon}
                      size="small"
                      type="primary"
                      isBold={false}
                      onClick={() => item.onClick()}
                    />
                  ))}
                </div>
              </>
              {/* Disabled Web3 Payment for Compute Services */}
              {currentApp !== ICurrentApp.COMPUTE && (
                <div className="flex flex-col items-center justify-center gap-2">
                  <Button
                    buttonType="primary"
                    label="Deposit funds"
                    size="small"
                    onClick={() =>
                      handleClick('deposit', selectedWallet?._id || '')
                    }
                  />
                  <Link
                    text="No Thanks"
                    size="small"
                    type="secondary"
                    isBold={false}
                    onClick={closeModal}
                  />
                </div>
              )}
            </div>
          }
          headerContent={
            <div className="flex items-center justify-start gap-x-3">
              <span
                className="text-lg font-semibold 
                          text-base-heading-text-color dark:text-dark-base-heading-text-color"
              >
                View Wallet
              </span>
              <ProviderNetworkTab
                icon={selectedWallet?.details.networkDetails?.logo}
                name={getNetworkFromName(
                  Number(selectedWallet?.details.networkDetails!.chainId)
                )}
              />
              <ProviderNetworkTab
                icon={selectedWallet?.details.tokenDetails.logoURI!}
                name={selectedWallet?.details.tokenDetails.symbol || ''}
              />
            </div>
          }
          primaryButtonContent="Disconnect"
          secondaryButtonContent="Cancel"
          primaryButton={false}
          primaryButtonLoading={false}
          primaryButtonDisabled={false}
          secondaryButtonDisabled={disconnectWalletLoading}
          secondaryButton={false}
          onPrimaryButtonClick={() => {}}
          onSecondaryButtonClick={() => {}}
        />
      </div>
    </ModalWrapper>
  );
};

export default ViewWallet;
