import { requestPipeline } from '../root-utils';

export const getFilecoinPinDetails = async (id: any): Promise<any> => {
  return requestPipeline({
    url: `pinning-data/filecoin/pins/${id}`,
    method: 'GET',
  });
};

export const getIpfsPinDetails = async (id: any): Promise<any> => {
  return requestPipeline({
    url: `pinning-data/ipfs/pins/${id}`,
    method: 'GET',
  });
};
