import React from 'react';
import SpheronLogo from '../../../assets/compute/global/spheron-logo.svg';
import LoaderStyles from '../../../styles/compute/spheron-loader.module.scss';

interface IProps {
  height?: number;
  width?: number;
}

const SpheronLoader = ({ height, width }: IProps) => {
  return (
    <div className={LoaderStyles.loader__screen}>
      <div className={LoaderStyles.loader__screen__logo}>
        <img
          src={SpheronLogo}
          alt="Spheron Logo"
          height={height}
          width={width}
        />
      </div>
    </div>
  );
};

SpheronLoader.defaultProps = {
  height: 400,
  width: 400,
};

export default SpheronLoader;
