import React, { useEffect, useState } from 'react';
import { Modal } from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import { ISolanaStepInfo } from '../../../redux/solana/solana.interfaces';
import {
  activationSteps,
  ReservationType,
} from '../../../redux/solana/solana.utils';
import Styles from '../../../styles/compute/modal.module.scss';
import SolanaPayStep from '../../Misc/solana-pay-step';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import ModalWrapper from './ModalWrapper';

interface IProps {
  isOpen: boolean;
  amount: number;
  type: ReservationType;
  walletAddress: string;
}

function SolanaPay({ isOpen, amount, type, walletAddress }: IProps) {
  const dispatchRtk = useDispatch<AppDispatch>();

  const solanaStepOne: ISolanaStepInfo = useSelector(
    (state: RootState) => state.solana.solanaPayStepOne
  );
  const solanaStepTwo: ISolanaStepInfo = useSelector(
    (state: RootState) => state.solana.solanaPayStepTwo
  );
  const solanaStepThree: ISolanaStepInfo = useSelector(
    (state: RootState) => state.solana.solanaPayStepThree
  );

  const [stepDetails, setStepDetails] = useState<{
    id: number;
    text: string;
    subtext: string;
  }>({ id: 0, text: '', subtext: '' });

  const steps = activationSteps(type);

  useEffect(() => {
    if (
      (solanaStepTwo.loading || solanaStepTwo.error || solanaStepTwo.success) &&
      (solanaStepThree.loading ||
        solanaStepThree.error ||
        solanaStepThree.success)
    )
      setStepDetails(steps[2]);
    else if (
      solanaStepTwo.loading ||
      solanaStepTwo.error ||
      solanaStepTwo.success
    )
      setStepDetails(steps[1]);
    else setStepDetails(steps[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [solanaStepOne, solanaStepTwo, solanaStepThree]);

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={() => dispatchRtk(toggleModalShowRtk({ modalShow: false }))}
      classname={`${Styles.modal__outer__con} w-430 modal fixed inset-0 overflow-y-auto`}
    >
      <div className={Styles.modal__inner__content}>
        <Modal
          footer={false}
          header={false}
          subtitle=""
          modalContent={
            <div className={Styles.modal__inner__con__small}>
              <h1>{stepDetails?.text}</h1>
              <h2>{stepDetails?.subtext}</h2>
              <div className="flex flex-col gap-4 w-full">
                <SolanaPayStep
                  walletAddress={walletAddress}
                  text={steps[0].text}
                  stepInfo={solanaStepOne}
                  step={1}
                  amount={amount}
                  type={type}
                />
                <SolanaPayStep
                  walletAddress={walletAddress}
                  text={steps[1].text}
                  stepInfo={solanaStepTwo}
                  step={2}
                  amount={amount}
                  type={type}
                />
                <SolanaPayStep
                  walletAddress={walletAddress}
                  text={steps[2].text}
                  stepInfo={solanaStepThree}
                  step={3}
                  amount={amount}
                  type={type}
                />
              </div>
            </div>
          }
          headerContent={<></>}
          primaryButtonContent="Update"
          secondaryButtonContent="Cancel"
          primaryButton
          primaryButtonLoading={false}
          secondaryButtonDisabled={false}
          secondaryButton
          onPrimaryButtonClick={() => {}}
          onSecondaryButtonClick={() => {
            dispatchRtk(toggleModalShowRtk({ modalShow: false }));
          }}
        />
      </div>
    </ModalWrapper>
  );
}

export default SolanaPay;
