import React, { Fragment } from 'react';
import { ConfirmationModal, Modal } from '@spheron/ui-library';
import { useDispatch } from 'react-redux';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import Styles from '../../../styles/compute/modal.module.scss';
import ModalWrapper from './ModalWrapper';
import { AppDispatch } from '../../../redux/compute/store';

interface IProps {
  isOpen: boolean;
  text: string;
  handleProceed: () => void;
  loading: boolean;
}

const Confirmation = ({ isOpen, text, handleProceed, loading }: IProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const handleCloseModal = () => {
    dispatch(toggleModalShowRtk({ modalShow: false }));
  };
  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={handleCloseModal}
      classname={`${Styles.modal__outer__con} w-430 modal fixed inset-0 overflow-y-auto`}
    >
      <div className={Styles.modal__inner__content}>
        <Modal
          footer
          header
          subtitle=""
          modalContent={
            <span className="font-normal text-sm leading-5 ">{text}</span>
          }
          headerContent={<>Are you sure?</>}
          primaryButtonContent="Delete"
          secondaryButtonContent="Cancel"
          primaryButton
          primaryButtonLoading={loading}
          primaryButtonDisabled={false}
          secondaryButtonDisabled={false}
          secondaryButton
          onPrimaryButtonClick={handleProceed}
          onSecondaryButtonClick={handleCloseModal}
          confirmationStatus={ConfirmationModal.ERROR}
        />
      </div>
    </ModalWrapper>
  );
};

export default Confirmation;
