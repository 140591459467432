import React from 'react';
import { ConfirmationModal, Modal } from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import Styles from '../../../styles/compute/modal.module.scss';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import { deleteSpheronCreditThunk } from '../../../redux/compute/subscription/subscription.thunks';
import ModalWrapper from './ModalWrapper';

interface IProps {
  isOpen: boolean;
  creditCoupon: string;
  couponName: string;
}

const DeleteSpheronCredit = ({ isOpen, creditCoupon, couponName }: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();

  const selectedOrganisation = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation
  );

  const couponLoading = useSelector(
    (state: RootState) => state.subscription.couponLoading
  );

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={() => {
        dispatchRtk(toggleModalShowRtk({ modalShow: false }));
      }}
      classname={`${Styles.modal__outer__con} modal fixed inset-0 overflow-y-auto`}
    >
      <div className={Styles.modal__inner__content}>
        <Modal
          footer
          header
          subtitle=""
          modalContent={
            <span className="font-normal text-sm leading-5">
              Do you want to delete <b>{couponName}</b>?
            </span>
          }
          headerContent={<>Delete Coupon</>}
          primaryButtonContent="Continue"
          secondaryButtonContent="Cancel"
          primaryButton
          primaryButtonLoading={couponLoading}
          secondaryButtonDisabled={false}
          secondaryButton
          onPrimaryButtonClick={() =>
            dispatchRtk(
              deleteSpheronCreditThunk({
                couponCode: creditCoupon,
                organizationId: selectedOrganisation._id,
              })
            )
          }
          onSecondaryButtonClick={() =>
            dispatchRtk(
              toggleModalShowRtk({
                modalShow: false,
              })
            )
          }
          confirmationStatus={ConfirmationModal.ERROR}
        />
      </div>
    </ModalWrapper>
  );
};

export default DeleteSpheronCredit;
