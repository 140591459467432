import React from 'react';
import Skeleton from 'react-loading-skeleton';

function SelectedPlanLoading() {
  const repeatSkeletonBlock = (count: number) => {
    const skeletonBlocks = [];
    let index = 0;
    while (index < count) {
      const uniqueKey = `skeleton-${index}`;
      skeletonBlocks.push(
        <div className="flex items-center justify-between mt-6" key={uniqueKey}>
          <Skeleton
            height={22}
            width={100}
            duration={2}
            containerClassName="flex"
          />
          <Skeleton
            height={22}
            width={70}
            duration={2}
            containerClassName="flex"
          />
        </div>
      );
      index += 1;
    }
    return skeletonBlocks;
  };
  return (
    <>
      <div
        className="bg-white dark:bg-dark-base-bg
         border-bg-lightGray mb-5 mt-6 shadow-selectedCard rounded-lg 
      px-4 py-5 dark:border dark:border-dark-base-border"
      >
        <Skeleton
          height={30}
          width={160}
          duration={2}
          containerClassName="flex"
        />
        <div
          className="border mt-4 relative px-4 py-4 rounded-lg 
      w-full flex items-center 
      dark:border-dark-base-border min-h-12"
        >
          <div className="flex flex-row gap-3 items-center">
            <Skeleton
              width={32}
              height={32}
              borderRadius={24}
              duration={2}
              containerClassName="flex"
            />
            <Skeleton
              height={23}
              width={150}
              duration={2}
              containerClassName="flex"
            />

            <span className="px-1 py-0.5 rounded absolute right-2 bottom-2">
              <Skeleton
                height={18}
                width={65}
                duration={2}
                containerClassName="flex"
              />
            </span>
          </div>
        </div>
        {repeatSkeletonBlock(3)}

        <div className="flex items-center justify-between mt-6">
          <Skeleton
            height={30}
            width={100}
            duration={2}
            containerClassName="flex"
          />
          <Skeleton
            height={30}
            width={70}
            duration={2}
            containerClassName="flex"
          />
        </div>
        <div className="rounded-lg mt-6">
          <Skeleton
            height={45}
            width={320}
            duration={2}
            containerClassName="flex"
          />
        </div>
        <div className="mt-4">
          <Skeleton
            height={20}
            width={320}
            duration={2}
            containerClassName="flex"
          />
        </div>
      </div>
    </>
  );
}

export default SelectedPlanLoading;
