import React from 'react';
import Styles from '../../../../styles/compute/compute-pricing.module.scss';

interface IProps {
  placeholder?: string;
  id?: string;
  value: string | number;
  name?: string;
  type?: string;
  disabled?: boolean;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: string) => void;
  error: boolean;
  errorMessage: string;
  onBlur?: () => void;
}

const TextInput = ({
  placeholder,
  value,
  id,
  type,
  name,
  disabled,
  onChange,
  error,
  errorMessage,
  onBlur,
}: IProps) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const updatedValue =
      typeof inputValue === 'string' ? inputValue.trimStart() : inputValue;
    onChange(updatedValue);
  };

  return (
    <>
      <input
        id={id}
        name={name}
        className={`flex items-center w-full px-4 py-2 rounded-lg 
        border mt-2 
        text-text-darkGray text-base font-normal shadow-searchShadow
        dark:bg-dark-base-bg
         ${
           error
             ? `border-feedback-error-text ${Styles.search__input__error}`
             : `border-searchBorder dark:border-dark-base-border ${Styles.search__input}`
         } ${disabled ? 'cursor-not-allowed' : ''}`}
        type={type || 'text'}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={handleInputChange}
        onBlur={onBlur}
      />
      {error && (
        <span className="text-feedback-error-text text-xs mt-2 ml-1">
          {errorMessage}
        </span>
      )}
    </>
  );
};

TextInput.defaultProps = {
  placeholder: '',
  name: '',
  type: 'text',
  id: null,
  disabled: false,
  onBlur: undefined,
};

export default TextInput;
