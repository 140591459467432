import React from 'react';
import Skeleton from 'react-loading-skeleton';

const ServiceCardLoader = () => {
  return (
    <div
      role="presentation"
      className="rounded-lg
      bg-cards-bg dark:bg-dark-cards-bg
       border border-base-border dark:border-dark-base-border relative min-w-fit
     max-w-lg"
    >
      <div className="p-3">
        <div className="flex flex-col gap-y-3 w-full">
          <div className="flex flex-col gap-y-1">
            <div className="flex items-center justify-start gap-x-3">
              <div className="w-8 h-8 rounded-full flex items-center justify-center">
                <Skeleton
                  height={32}
                  width={32}
                  containerClassName="rounded-full"
                  borderRadius={32}
                  duration={2}
                />
              </div>
              <div className="flex items-center justify-start gap-x-2">
                <Skeleton height={16} width={80} duration={2} />

                <Skeleton
                  height={16}
                  width={50}
                  borderRadius={4}
                  duration={2}
                />
              </div>
            </div>

            <Skeleton height={14} width={250} duration={2} />
          </div>

          <div className="flex items-center justify-start gap-x-5 w-full">
            <>
              {[0, 1, 2].map((detail) => (
                <div
                  key={detail}
                  className="flex items-center justify-start gap-x-1"
                >
                  <Skeleton
                    height={24}
                    width={24}
                    borderRadius={24}
                    duration={2}
                  />
                  <Skeleton
                    height={12}
                    width={50}
                    duration={2}
                    containerClassName="mt-1"
                  />
                </div>
              ))}
            </>
          </div>

          <Skeleton height={16} width={120} duration={2} />
        </div>
      </div>
    </div>
  );
};

export default ServiceCardLoader;
