import React, { useEffect, useReducer } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Navigate } from 'react-router-dom';
import {
  Button,
  TextInput,
  Dropdown,
  OptionType,
  SectionHeading,
} from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCountries,
  uniqueArray,
  withLoader,
} from '../../../../redux/compute/root-utils';
import {
  getBillingDetailsThunk,
  updateBillingDetailsThunk,
} from '../../../../redux/compute/organisation/organisation.thunks';
import { AppDispatch, RootState } from '../../../../redux/compute/store';

const PlanInvoiceSettings = () => {
  const dispatchRtk = useDispatch<AppDispatch>();
  const organisationId = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation._id
  );
  const billingDetails = useSelector(
    (state: RootState) => state.organisation.billingDetails
  );
  const selectedOrganisationLoading = useSelector(
    (state: RootState) => state.organisation.selectedOrganisationLoading
  );

  const billingDetailsLoading = useSelector(
    (state: RootState) => state.organisation.billingDetailsLoading
  );
  const updatingOrganisation = useSelector(
    (state: RootState) => state.organisation.updateOrganizationLoading
  );
  const allUsers = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation.users
  );
  const currentApp = useSelector(
    (state: RootState) => state.organisation.currentApp
  );

  const user = useSelector((state: RootState) => state.user.user);
  const userRoleLoading = useSelector(
    (state: RootState) => state.organisation.userRoleLoading
  );
  const billingDetailsFetched = useSelector(
    (state: RootState) => state.organisation.billingDetailsFetched
  );

  const INITIAL_STATE = {
    companyName: '',
    taxId: '',
    invoiceEmailRecipient: '',
    addressLine1: '',
    addressLine2: '',
    country: '',
    city: '',
    postalCode: '',
    state: '',
  };

  const reducer = (
    state: typeof INITIAL_STATE,
    action: { type: string; value: string; field: string }
  ) => {
    switch (action.type) {
      case 'HANDLE_INPUT_CHANGE':
        return {
          ...state,
          [action.field]: action.value,
        };
      case 'RESET_TO_DEFAULT':
        return INITIAL_STATE;
      default:
        return state;
    }
  };

  const isMember =
    allUsers?.find((u) => u?._id === user?._id)?.platformProfile?.role ===
      'member' && user !== null;

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  useEffect(() => {
    if (billingDetails) {
      Object.entries(billingDetails).map(([key, value]) =>
        dispatch({
          type: 'HANDLE_INPUT_CHANGE',
          field: key,
          value,
        })
      );
    } else {
      dispatch({
        type: 'RESET_TO_DEFAULT',
        value: '',
        field: '',
      });
    }
  }, [billingDetails, dispatch]);

  useEffect(() => {
    if (
      !selectedOrganisationLoading &&
      !userRoleLoading &&
      organisationId &&
      allUsers?.length > 0 &&
      user &&
      !billingDetailsFetched
    ) {
      if (!isMember) {
        dispatchRtk(getBillingDetailsThunk(organisationId));
      }
    }
  }, [
    billingDetails,
    organisationId,
    isMember,
    allUsers,
    selectedOrganisationLoading,
    billingDetailsFetched,
    user,
    dispatchRtk,
    userRoleLoading,
  ]);

  const shouldDisabled = (state: typeof INITIAL_STATE) => {
    return (
      !state.companyName ||
      (billingDetails !== null &&
        JSON.stringify({ ...state }) === JSON.stringify({ ...billingDetails }))
    );
  };

  const InvoiceDetails = [
    {
      id: 1,
      label: 'Company Name',
      value: state.companyName,
      disabled: isMember,
      placeholder: 'Spheron',
      tooltipText: `By default, your team name is shown on your invoice. 
        If you want to show a custom name instead, please choose`,
      handleChange: (value: string) =>
        dispatch({
          type: 'HANDLE_INPUT_CHANGE',
          value,
          field: 'companyName',
        }),
    },
    {
      id: 2,
      label: 'Invoice Email Recipient',
      value: state.invoiceEmailRecipient,
      disabled: isMember,
      placeholder: 'accounts@spheron.network',
      tooltipText: `By default, all your invoices will be sent to the email address of the 
      creator of your team. If you want to use a custom email address specifically
       for receiving invoices, enter it here.`,
      type: 'email',
      handleChange: (value: string) =>
        dispatch({
          type: 'HANDLE_INPUT_CHANGE',
          value,
          field: 'invoiceEmailRecipient',
        }),
    },
  ];

  const BillingAddress = [
    {
      id: 1,
      label: 'Address 1',
      value: state.addressLine1,
      placeholder: 'Street 203-HB803',

      disabled: isMember,
      handleChange: (value: string) =>
        dispatch({
          type: 'HANDLE_INPUT_CHANGE',
          value,
          field: 'addressLine1',
        }),
    },
    {
      id: 2,
      label: 'Address 2',
      value: state.addressLine2,
      placeholder: 'New Super Town',
      disabled: isMember,
      handleChange: (value: string) =>
        dispatch({
          type: 'HANDLE_INPUT_CHANGE',
          value,
          field: 'addressLine2',
        }),
    },
  ];

  const BillingAddress2 = [
    {
      id: 3,
      label: 'State',
      value: state.state,
      placeholder: 'New Super State',

      disabled: isMember,
      handleChange: (value: string) =>
        dispatch({
          type: 'HANDLE_INPUT_CHANGE',
          value,
          field: 'state',
        }),
    },
    {
      id: 4,
      label: 'City',
      value: state.city,
      placeholder: 'New Super City',
      disabled: isMember,
      handleChange: (value: string) =>
        dispatch({
          type: 'HANDLE_INPUT_CHANGE',
          value,
          field: 'city',
        }),
    },
  ];

  const handleSubmit = () => {
    dispatchRtk(
      updateBillingDetailsThunk({
        isEdit: !!billingDetails,
        organizationId: organisationId,
        details: {
          companyName: state.companyName,
          invoiceEmailRecipient: state.invoiceEmailRecipient,
          taxId: state.taxId,
          billingAddress: {
            addressLine1: state.addressLine1,
            addressLine2: state.addressLine2,
            country: state.country,
            city: state.city,
            postalCode: state.postalCode,
            state: state.state,
          },
        },
      })
    );
  };

  if (isMember) {
    return <Navigate to={`/${currentApp}/billing`} />;
  }

  const billingHeaderTitle = (text: string) => (
    <div
      className="text-3 leading-3 font-600 uppercase
          text-base-heading-text-color dark:text-dark-base-heading-text-color
          bg-base-fg dark:bg-dark-base-fg
          px-3 py-2 w-full"
    >
      {text}
    </div>
  );

  return (
    <div className="flex flex-col gap-y-10">
      <div className="flex flex-col gap-y-6">
        <SectionHeading
          showRefresh={false}
          showSwitch={false}
          subHeading={''}
          heading="Invoice Details"
          handleIconClick={() => {}}
          handleRefreshClick={() => {}}
          handleSwitchClick={() => {}}
          switchDisable={false}
          handleClick={() => {}}
          loading={false}
          time={''}
          refreshType={'default'}
          showText={false}
          toggleId={''}
          isChecked={false}
          handleChange={() => {}}
        />
        <div className="flex flex-col gap-y-5">
          {billingHeaderTitle('Basic Core Info')}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-y-7 gap-x-12">
            {billingDetailsLoading ? (
              <>
                {Array.from(Array(2)).map((key: any) => (
                  <Skeleton
                    key={key}
                    containerClassName="w-full mt-5"
                    height={42}
                    duration={2}
                  />
                ))}
              </>
            ) : (
              <>
                {InvoiceDetails.map((invoice) => (
                  <>
                    <TextInput
                      type={invoice?.type || ''}
                      disabled={invoice.disabled}
                      placeholder={invoice.placeholder}
                      key={invoice.id}
                      value={invoice.value}
                      onChange={(value) =>
                        invoice.handleChange(value as string)
                      }
                      label={invoice.label}
                    />
                  </>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-y-5">
        {billingHeaderTitle('Billing Address')}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-y-7 gap-x-12">
          {billingDetailsLoading ? (
            <>
              {Array.from(Array(BillingAddress?.length)).map((key: any) => (
                <Skeleton
                  key={key}
                  containerClassName="w-full mt-5"
                  height={42}
                  duration={2}
                />
              ))}
            </>
          ) : (
            <>
              {BillingAddress.map((billingInput) => (
                <TextInput
                  disabled={billingInput.disabled}
                  placeholder={billingInput.placeholder}
                  key={billingInput.id}
                  value={billingInput.value}
                  onChange={(value) =>
                    billingInput.handleChange(value as string)
                  }
                  label={billingInput.label}
                />
              ))}
            </>
          )}

          {billingDetailsLoading ? (
            <>
              {Array.from(Array(4)).map((key: any) => (
                <Skeleton
                  key={key}
                  containerClassName="w-full mt-6"
                  height={42}
                  duration={2}
                />
              ))}
            </>
          ) : (
            <>
              <div className="-mt-3">
                <Dropdown
                  dropdownSize="default"
                  dropdownType="default"
                  filled
                  onSelected={(selected) => {
                    dispatch({
                      type: 'HANDLE_INPUT_CHANGE',
                      field: 'country',
                      value: selected.value as string,
                    });
                  }}
                  defaultSelected={state.country}
                  options={uniqueArray(
                    Object.entries(getCountries()).map(
                      ([, value]) => value as string
                    )
                  )
                    .map((value: string) => ({
                      value: value as string,
                      label: value as string,
                      optionType: 'primary' as OptionType,
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label))}
                  label="COUNTRY"
                  placeholder="Choose country"
                  disable={isMember}
                />
              </div>
              {BillingAddress2.map((billingInput) => (
                <TextInput
                  disabled={billingInput.disabled}
                  placeholder={billingInput.placeholder}
                  key={billingInput.id}
                  value={billingInput.value}
                  onChange={(value) =>
                    billingInput.handleChange(value as string)
                  }
                  label={billingInput.label}
                />
              ))}

              <TextInput
                placeholder="2242341"
                key={5}
                disabled={isMember}
                type="number"
                label="Postal Code"
                value={state.postalCode}
                onChange={(value) => {
                  if (Number(value) >= 0) {
                    dispatch({
                      type: 'HANDLE_INPUT_CHANGE',
                      value: String(value),
                      field: 'postalCode',
                    });
                  }
                }}
              />
            </>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-y-5">
        {billingHeaderTitle('Tax Details')}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-y-7 gap-x-12">
          <div>
            {withLoader(
              billingDetailsLoading,
              <Skeleton
                height={42}
                containerClassName="w-full mt-5"
                duration={2}
              />,
              <TextInput
                placeholder="ED000000483"
                key={4}
                disabled={isMember}
                label="Tax ID"
                value={state.taxId || ''}
                onChange={(value) =>
                  dispatch({
                    type: 'HANDLE_INPUT_CHANGE',
                    value: String(value),
                    field: 'taxId',
                  })
                }
              />
            )}
          </div>
        </div>
      </div>

      <div className="flex items-center justify-start">
        {withLoader(
          billingDetailsLoading,
          <Skeleton height={44} width={130} borderRadius={8} duration={2} />,
          <Button
            buttonType="primary"
            label="Save"
            size="medium"
            onClick={handleSubmit}
            disabled={
              shouldDisabled(state) ||
              billingDetailsLoading ||
              updatingOrganisation ||
              isMember
            }
            loading={updatingOrganisation}
          />
        )}
      </div>
    </div>
  );
};

export default PlanInvoiceSettings;
