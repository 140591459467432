import React, { useState, useEffect } from 'react';
import { SectionHeading, Tabs } from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import PaymentHistoryTable from '../../../../components/Compute/TableItems/payment-history-table';
import {
  IOrganisationWallet,
  IPaymentHistoryEnum,
} from '../../../../redux/web3/web3.interfaces';
import BillingInvoice from '../../../../components/Compute/Sections/billing-invoice';
import { fetchPaymentHistoryThunk } from '../../../../redux/compute/web3/web3.thunks';
import { togglePaymentHistoryLoadingRtk } from '../../../../redux/compute/web3/web3.slice';
import { getSubscriptionInvoicesThunk } from '../../../../redux/compute/subscription/subscription.thunks';
import { isNonSubgraphChain } from '../../../../redux/compute/web3/web3.utils';
import { AppDispatch, RootState } from '../../../../redux/compute/store';

const PaymentHistory = () => {
  const dispatchRtk = useDispatch<AppDispatch>();

  const selectedOrganisationId = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation._id
  );

  // const selectedOrganisationLoading = useSelector(
  //   (state: RootState) => state.organisation.selectedOrganisationLoading
  // );
  const deposits = useSelector(
    (state: RootState) => state.web3.paymentHistory.deposits
  );
  // const withdrawals = useSelector(
  //   (state: RootState) => state.web3.paymentHistory.withdrawals
  // );
  const paymentHistoryLoading = useSelector(
    (state: RootState) => state.web3.paymentHistoryLoading
  );
  const subscriptionInvoiceLoading = useSelector(
    (state: RootState) => state.subscription.subscriptionInvoicesLoading
  );

  const web3Wallets = useSelector((state: RootState) => state.web3.wallets);
  const web3WalletsLoading = useSelector(
    (state: RootState) => state.web3.walletLoading
  );

  const [selectedWallet, setSelectedWallet] =
    useState<IOrganisationWallet | null>(null);

  useEffect(() => {
    if (selectedWallet) setSelectedWallet(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganisationId]);

  useEffect(() => {
    if (web3Wallets.length > 0)
      setSelectedWallet(
        web3Wallets.filter(
          (wallet: IOrganisationWallet) => !isNonSubgraphChain(wallet)
        )[0]
      );
  }, [web3Wallets, web3WalletsLoading]);

  useEffect(() => {
    if (selectedWallet && !isNonSubgraphChain(selectedWallet)) {
      dispatchRtk(
        fetchPaymentHistoryThunk({
          address: selectedWallet.details.address,
          networkDetails: selectedWallet.details.networkDetails,
          tokenDetails: selectedWallet.details.tokenDetails,
        })
      );
    } else {
      dispatchRtk(togglePaymentHistoryLoadingRtk(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchRtk, selectedWallet?._id, web3WalletsLoading]);

  const handleRefresh = () => {
    (window as any).invoicePromise.abort();
    (window as any).invoicePromise = dispatchRtk(
      getSubscriptionInvoicesThunk(selectedOrganisationId)
    );
    if (selectedWallet && !isNonSubgraphChain(selectedWallet)) {
      dispatchRtk(
        fetchPaymentHistoryThunk({
          address: selectedWallet.details.address,
          networkDetails: selectedWallet.details.networkDetails,
          tokenDetails: selectedWallet.details.tokenDetails,
        })
      );
    }
  };

  const tabRoutes = [
    {
      id: 0,
      label: 'Invoices',
      item: <BillingInvoice />,
    },
    {
      id: 1,
      label: 'Deposit',
      item: (
        <PaymentHistoryTable
          selectedWallet={selectedWallet as IOrganisationWallet}
          setSelectedWallet={setSelectedWallet}
          transactions={deposits}
          type={IPaymentHistoryEnum.DEPOSIT}
        />
      ),
    },
  ];

  const [selectedTab, setSelectedTab] = useState<number>(0);

  const handleTabClick = (index: number) => {
    switch (index) {
      case 0:
      default:
        setSelectedTab(0);
        break;
      case 1:
        setSelectedTab(1);
        break;
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <SectionHeading
        showRefresh
        showSwitch={false}
        subHeading=""
        heading="Payment History"
        handleIconClick={() => {}}
        handleRefreshClick={handleRefresh}
        handleSwitchClick={() => {}}
        switchDisable={false}
        handleClick={() => {}}
        loading={paymentHistoryLoading || subscriptionInvoiceLoading}
        time=""
        refreshType={'default'}
        showText={false}
        toggleId=""
        isChecked={false}
        handleChange={() => {}}
      />

      <Tabs
        tabList={tabRoutes}
        tabListPosition="left"
        defaultTab={0}
        selectedTab={selectedTab}
        tabListClassname="pb-4"
        onClick={(id: number) => handleTabClick(id)}
      />
      {tabRoutes.map((tab) => (
        <div className={selectedTab === tab.id ? 'block' : 'hidden'}>
          {tab.item}
        </div>
      ))}
    </div>
  );
};

export default PaymentHistory;
