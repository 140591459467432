/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Button, OptionType, TextInput, Tooltip } from '@spheron/ui-library';
import { ReactComponent as InfoIcon } from '@spheron/ui-library/dist/assets/info-circle.svg';
import { ReactComponent as PlusIcon } from '@spheron/ui-library/dist/assets/plus.svg';
import { useDispatch, useSelector } from 'react-redux';
import AddBuildArguments from '../../../../../components/Compute/InputFields/add-build-arguments';
import AddBuildCommands from '../../../../../components/Compute/InputFields/add-build-commands';
import AddInstanceEnv from '../../../../../components/Compute/Misc/add-instance-env';
import {
  IClusterTemplateVariable,
  IPorts,
} from '../../../../../redux/compute/cluster/cluster.interfaces';
import ComputeInput from '../../../../../components/Compute/InputFields/newUI/text-input';
import { AppDispatch, RootState } from '../../../../../redux/compute/store';
import { setClusterDeploymentProgressRtk } from '../../../../../redux/compute/cluster/cluster.slice';
import { updateMultiServiceInstanceValue } from '../../../../../redux/compute/instance/instance-creation/instance-creation.slice';
import { isAccelerateDeployment } from '../../../../../redux/compute/instance/instance-creation/instance-creation.utils';
import { IAutoscalingRequestPayload } from '../../../../../redux/compute/instance/instance.interfaces';
import AutoScalingConfig from '../AutoScalingConfiguration';
import ShowMoreSwitch from '../../../../../components/Compute/Misc/show-more-switch';
import { getClusterRegionThunk } from '../../../../../redux/compute/instance/instance-creation/instance-creation.thunks';
import {
  ClusterType,
  ScalingTypeName,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.interfaces';
import AddMultiserviceInstancePort from '../../../../../components/Compute/Misc/add-multiservice-instance-port';

interface IProps {
  isUpdate: boolean;
  templateEnv: IClusterTemplateVariable[];
  // eslint-disable-next-line no-unused-vars
  setTemplateEnv: (templateEnv: IClusterTemplateVariable[]) => void;
  healthUrl: string;
  // eslint-disable-next-line no-unused-vars
  setHealthUrl: (healthUrl: string) => void;
  healthPort: string;
  // eslint-disable-next-line no-unused-vars
  setHealthPort: (healthPort: string) => void;
  portMapping: IPorts[];
  setPortMapping: (
    // eslint-disable-next-line no-unused-vars
    portMapping: IPorts[]
  ) => void;
  buildEnv: { key: string; value: string; isSecret: boolean }[];
  setBuildEnv: (
    // eslint-disable-next-line no-unused-vars
    buildEnv: { key: string; value: string; isSecret: boolean }[]
  ) => void;
  secretBuildEnv: { key: string; value: string; isSecret: boolean }[];
  setSecretBuildEnv: (
    // eslint-disable-next-line no-unused-vars
    secretBuildEnv: { key: string; value: string; isSecret: boolean }[]
  ) => void;
  buildArgs: string[];
  // eslint-disable-next-line no-unused-vars
  setBuildArgs: (buildArgs: string[]) => void;
  buildCommands: string[];
  // eslint-disable-next-line no-unused-vars
  setBuildCommands: (buildCommands: string[]) => void;
  tag: string; // infer better type from usage
  // template: string | null;
  isDisable: boolean;
  // eslint-disable-next-line no-unused-vars
  setTag: any;
  autoScalingPolicy: IAutoscalingRequestPayload;
  // eslint-disable-next-line no-unused-vars
  setAutoScalingPolicy: (autoScalingPolicy: IAutoscalingRequestPayload) => void;
  showCustomAutoScaling: boolean;
  setShowCustomAutoScaling: React.Dispatch<React.SetStateAction<boolean>>;

  selectedService: any;
}
const MultiServiceAdvanceConfigurationSection = ({
  isUpdate,
  templateEnv,
  setTemplateEnv,
  healthUrl,
  setHealthUrl,
  healthPort,
  setHealthPort,
  portMapping,
  setPortMapping,
  buildEnv,
  setBuildEnv,
  secretBuildEnv,
  setSecretBuildEnv,
  buildArgs,
  setBuildArgs,
  buildCommands,
  setBuildCommands,
  tag,
  isDisable,
  setTag,
  autoScalingPolicy,
  setAutoScalingPolicy,
  showCustomAutoScaling,
  setShowCustomAutoScaling,
  selectedService,
}: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();
  const clusterType = useSelector(
    (state: RootState) => state.instanceCreation.clusterType
  );
  const instanceValue = useSelector(
    (state: RootState) =>
      state.instanceCreation.multiserviceInstanceCreation![selectedService._id]
        .serviceCount
  );
  const regions = useSelector(
    (state: RootState) => state.instanceCreation.clusterRegions
  );
  const selectedTemplate = useSelector(
    (state: RootState) => state.cluster.selectedTemplate
  );
  const clusterScalingName = useSelector(
    (state: RootState) => state.instanceCreation.clusterScalingName
  );
  const deploymentType = useSelector(
    (state: RootState) => state.instanceCreation.deploymentType
  );

  const [showAdvanceConfig, setShowAdvanceConfig] = useState<boolean>(false);
  const [showHealthConfig, setShowHealthConfig] = useState<boolean>(false);

  const [templatePorts, setTemplatePorts] = useState<IPorts[]>([]);
  const addMultiPortMapping = () => {
    setPortMapping([
      ...portMapping,
      {
        containerPort: '',
        exposedPort: '',
        endpoint: '',
        endpointExposedPort: '',
      },
    ]);
  };

  const addBuildEnv = () => {
    setBuildEnv([...buildEnv, { key: '', value: '', isSecret: false }]);
  };
  const addBuildEnvSecret = () => {
    setSecretBuildEnv([
      ...secretBuildEnv,
      { key: '', value: '', isSecret: true },
    ]);
  };

  const addBuildCommand = () => {
    setBuildCommands([...buildCommands, '']);
  };

  const addBuildArgument = () => {
    setBuildArgs([...buildArgs, '']);
  };

  useEffect(() => {
    dispatchRtk(setClusterDeploymentProgressRtk(2));
    if (regions.length === 0 && isUpdate) {
      dispatchRtk(getClusterRegionThunk());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fillTemplateEnvValue = (value: string, id: number) => {
    setTemplateEnv(
      templateEnv.map((item, i) =>
        i === id
          ? {
              // variables put in sequence for compare in update disable
              label: item.label,
              name: item.name,
              defaultValue: value,
              required: item.required,
            }
          : item
      )
    );
  };

  useEffect(() => {
    setShowAdvanceConfig(buildArgs.length > 0 || buildCommands.length > 0);
  }, [buildArgs.length, buildCommands.length]);

  const handleCountValue = (e: number) => {
    if (e >= 1) {
      dispatchRtk(
        updateMultiServiceInstanceValue({
          serviceId: selectedService._id,
          value: e,
        })
      );
    }
  };

  const handleHealthPort = (e: string) => {
    const numericValue = parseInt(e, 10);
    let updatedValue = e;
    if (Number.isNaN(numericValue) || numericValue <= 0) {
      updatedValue = '';
    }
    setHealthPort(updatedValue);
  };

  const defaultDropdownOptions = [
    {
      label: 'Select exposed port',
      value: '',
      optionType: 'primary' as OptionType,
    },
    {
      label: '80',
      value: '80',
      optionType: 'primary' as OptionType,
    },
    {
      label: 'Random Port',
      value: 'random',
      optionType: 'primary' as OptionType,
    },
  ];
  useEffect(() => {
    if (
      selectedTemplate &&
      clusterType === ClusterType.TEMPLATE &&
      portMapping &&
      portMapping.length > 0
    ) {
      setPortMapping([]);
      const updatedPort: IPorts[] = [];
      selectedService.ports.forEach((port: any) => {
        const updatedExposedPort =
          port.exposedPort === 80
            ? defaultDropdownOptions[1].value
            : defaultDropdownOptions[2].value;
        const newPort = {
          containerPort: port.containerPort,
          exposedPort: updatedExposedPort,
          endpoint: port.endpoint,
          endpointExposedPort: port.exposedPort,
        };
        updatedPort.unshift(newPort);
      });
      setTemplatePorts(updatedPort);
      setPortMapping(updatedPort);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplate, clusterType]);

  const stepNumber = isAccelerateDeployment(deploymentType) ? '9.' : '10.';
  const dockerStepNumber = isAccelerateDeployment(deploymentType) ? '7.' : '8.';

  return (
    <div className="mt-14">
      <h3
        className="text-5 leading-6 dark:text-dark-base-heading-text-color
                           font-semibold text-base-heading-text-color"
      >
        {!isUpdate
          ? `${
              clusterType === ClusterType.TEMPLATE
                ? stepNumber
                : dockerStepNumber
            } `
          : ''}
        Configuration
      </h3>
      <div>
        <div
          className="mt-7 bg-white border
                border-searchBorder dark:border-dark-base-border dark:bg-transparent
                  px-7 py-6 rounded-xl"
        >
          {clusterType === ClusterType.TEMPLATE && (
            <>
              {templateEnv?.length > 0 && (
                <div
                  className="text-base-heading-text-color dark:text-dark-base-heading-text-color
                  text-xl font-bold pb-6"
                >
                  Template Configuration
                </div>
              )}
              <div className="flex flex-wrap gap-6">
                {templateEnv?.map((variable, i) => (
                  <>
                    <div
                      key={variable.label}
                      className="font-medium w-6/12 ll:w-[450px] max-w-1/2"
                    >
                      <div
                        className="text-text-filterBadge text-sm font-medium 
                    ml-1 dark:text-white uppercase flex items-center mb-2"
                      >
                        <span
                          className="uppercase text-xs leading-3 font-semibold
                           text-form-text tracking-[1px] dark:text-dark-form-text"
                        >
                          {variable.label}
                        </span>

                        <span> {variable.required && '*'}</span>

                        <Tooltip
                          position="top"
                          text={`This config is for env variable ${variable.name}`}
                          type="float"
                          wrapText
                        >
                          <InfoIcon
                            className="w-5 h-5 text-text-filterBadge transition
                               duration-300 ease-in-out dark:hover:text-gray-200 
                               hover:text-gray-900 ml-2"
                          />
                        </Tooltip>
                      </div>
                      <div>
                        <TextInput
                          type="text"
                          value={variable.defaultValue}
                          onChange={(e) => fillTemplateEnvValue(e as string, i)}
                          placeholder="e.g. value"
                          error={
                            isDisable ||
                            (variable.required &&
                              variable.defaultValue.length === 0)
                          }
                          helpText={
                            isDisable &&
                            variable.required &&
                            variable.defaultValue === ''
                              ? `${variable.label} cannot be empty`
                              : ''
                          }
                        />
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </>
          )}
          {templateEnv?.length > 0 && (
            <div
              className="bg-base-border h-px w-full my-12
                 dark:bg-dark-base-fg"
            />
          )}
          <>
            <div className="w-6/12 ll:w-[450px]">
              <TextInput
                type="number"
                label="Replicas"
                value={String(instanceValue)}
                onChange={(e) => handleCountValue(parseInt(e as string, 10))}
                placeholder="Replicas"
                error={false}
                helpText=""
              />
            </div>
          </>

          {isUpdate && (
            <div className="w-6/12 ll:w-[450px] mt-7">
              <TextInput
                label="Tag"
                type="text"
                value={tag}
                onChange={(e) => setTag(e)}
                placeholder="Tag"
                error={false}
                helpText=""
              />
            </div>
          )}

          <>
            <>
              {!isUpdate && (
                <div className="mt-12">
                  <span
                    className="text-text-filterBadge text-sm font-medium ml-1 
                  dark:text-white uppercase flex items-center mb-2 space-x-2"
                  >
                    <span
                      className="uppercase text-xs leading-3 font-semibold
                      text-form-text tracking-[1px] dark:text-dark-form-text"
                    >
                      Port Policy Mapping
                    </span>
                    <Button
                      buttonType="primary"
                      label=""
                      size="small"
                      classname="justify-center !w-5 !h-5"
                      onClick={addMultiPortMapping}
                      leftIcon={<PlusIcon className="w-5 h-5 text-white" />}
                      disabled={false}
                    />
                  </span>
                  {portMapping?.length > 0 && (
                    <>
                      <AddMultiserviceInstancePort
                        multiState={portMapping}
                        setMultiState={setPortMapping}
                        disableRemove={false}
                        defaultDropdownOptions={defaultDropdownOptions}
                        templatePorts={templatePorts}
                        selectedServiceId={selectedService._id}
                      />
                    </>
                  )}
                </div>
              )}
            </>

            <div className="mt-12">
              <>
                <span
                  className="text-text-filterBadge text-sm font-medium ml-1 
                      dark:text-white uppercase flex items-center mb-2 space-x-2"
                >
                  <span
                    className="uppercase text-xs leading-3 font-semibold
                    text-form-text tracking-[1px] dark:text-dark-form-text"
                  >
                    Environment Variable
                  </span>
                  <Button
                    buttonType="primary"
                    label=""
                    size="small"
                    classname="justify-center !w-5 !h-5"
                    onClick={addBuildEnv}
                    leftIcon={<PlusIcon className="w-5 h-5 text-white" />}
                    disabled={false}
                  />
                </span>

                {buildEnv?.length > 0 && (
                  <>
                    <AddInstanceEnv
                      buildEnv={buildEnv}
                      setBuildEnv={setBuildEnv}
                      isUpdate={isUpdate}
                    />
                  </>
                )}
              </>
            </div>
            <div className="mt-12">
              <>
                <span
                  className="text-text-filterBadge text-sm font-medium ml-1 
                      dark:text-white uppercase flex items-center mb-2 space-x-2"
                >
                  <span
                    className="uppercase text-xs leading-3 font-semibold
                    text-form-text tracking-[1px] dark:text-dark-form-text"
                  >
                    Secret Environment Variable
                  </span>
                  <Button
                    buttonType="primary"
                    label=""
                    size="small"
                    classname="justify-center !w-5 !h-5"
                    onClick={addBuildEnvSecret}
                    leftIcon={<PlusIcon className="w-5 h-5 text-white" />}
                    disabled={false}
                  />
                </span>

                {secretBuildEnv?.length > 0 && (
                  <>
                    <AddInstanceEnv
                      buildEnv={secretBuildEnv}
                      setBuildEnv={setSecretBuildEnv}
                      isUpdate={isUpdate}
                    />
                  </>
                )}
              </>
            </div>

            {clusterScalingName === ScalingTypeName.AUTO && !isUpdate && (
              <>
                <div
                  className="bg-base-border h-px w-full my-12
                 dark:bg-dark-base-fg"
                />
                <ShowMoreSwitch
                  state={showCustomAutoScaling}
                  id="autoscaling-toggle"
                  buttonText="Auto Scaling"
                  setState={setShowCustomAutoScaling}
                  subHeading="Custom Plan / Specification"
                />
                <AutoScalingConfig
                  showCustomAutoScalingPolicy={showCustomAutoScaling}
                  setAutoScalingPolicy={setAutoScalingPolicy}
                  autoScalingPolicy={autoScalingPolicy}
                  isUpdate={false}
                />
              </>
            )}
            <div
              className="bg-base-border h-px w-full my-12
                 dark:bg-dark-base-fg"
            />
            <>
              <ShowMoreSwitch
                state={showAdvanceConfig}
                id={`advance-toggle-${selectedService?._id}`}
                buttonText="Advanced Configuration"
                setState={setShowAdvanceConfig}
              />

              {showAdvanceConfig && (
                <>
                  <div className="mt-8 ll:w-[450px]">
                    <div>
                      <span
                        className="text-text-filterBadge text-sm font-medium ml-1 
                           dark:text-white uppercase flex items-center mb-2 space-x-2"
                      >
                        <span
                          className="uppercase text-xs leading-3 font-semibold
                          text-form-text tracking-[1px] dark:text-dark-form-text"
                        >
                          Commands
                        </span>
                        <Button
                          buttonType="primary"
                          label=""
                          size="small"
                          classname="justify-center !w-5 !h-5"
                          onClick={addBuildCommand}
                          leftIcon={<PlusIcon className="w-5 h-5 text-white" />}
                          disabled={false}
                        />
                      </span>

                      {buildCommands.length > 0 && (
                        <>
                          <AddBuildCommands
                            buildCommands={buildCommands}
                            setBuildCommands={setBuildCommands}
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div className="mt-12 ll:w-[450px]">
                    <div>
                      <span
                        className="text-text-filterBadge text-sm font-medium ml-1 
                           dark:text-white uppercase flex items-center mb-2 space-x-2"
                      >
                        <span
                          className="uppercase text-xs leading-3 font-semibold
                           text-form-text tracking-[1px] dark:text-dark-form-text"
                        >
                          Arguments
                        </span>
                        <Button
                          buttonType="primary"
                          label=""
                          size="small"
                          classname="justify-center !w-5 !h-5"
                          onClick={addBuildArgument}
                          leftIcon={<PlusIcon className="w-5 h-5 text-white" />}
                          disabled={false}
                        />
                      </span>

                      {buildArgs.length > 0 && (
                        <>
                          <AddBuildArguments
                            buildArgs={buildArgs}
                            setBuildArgs={setBuildArgs}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
              {clusterType === ClusterType.DOCKER && (
                <>
                  {!isUpdate && (
                    <div
                      className="bg-base-border h-px w-full 
                    my-12 dark:bg-dark-base-fg"
                    />
                  )}
                  {!isUpdate && (
                    <>
                      <ShowMoreSwitch
                        state={showHealthConfig}
                        id="health-toggle"
                        buttonText="Health Checkup"
                        setState={setShowHealthConfig}
                      />
                      {showHealthConfig && (
                        <div className="mt-8">
                          <div>
                            <span
                              className="text-text-filterBadge text-sm font-medium ml-1 
                          uppercase flex items-center"
                            >
                              Health Check Path
                            </span>

                            <div className="w-6/12 ll:w-[450px] flex flex-row gap-4">
                              <ComputeInput
                                type="text"
                                value={healthUrl}
                                onChange={setHealthUrl}
                                error={false}
                                errorMessage=""
                              />
                            </div>
                          </div>
                          <div className="mt-6">
                            <span
                              className="text-text-filterBadge text-sm font-medium ml-1 
                         uppercase flex items-center"
                            >
                              Health Check Port
                            </span>

                            <div className="w-6/12 ll:w-[450px] flex flex-row gap-4">
                              <ComputeInput
                                type="number"
                                value={healthPort}
                                onChange={(e) => handleHealthPort(e)}
                                error={false}
                                errorMessage=""
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          </>
        </div>
      </div>
    </div>
  );
};

export default MultiServiceAdvanceConfigurationSection;
