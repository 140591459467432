import {
  InstanceCardScalingType,
  InstanceCardState,
} from '@spheron/ui-library';
import { IInstanceReport } from './project.interfaces';
import { getUserRole } from '../../organisation/organisation.utils';
import { IUser } from '../../combined-state.interface';

export const getInstanceState = (state: string): InstanceCardState => {
  switch (state?.toLowerCase()) {
    case 'starting':
      return InstanceCardState.PROVISIONING;
    case 'active':
    case 'provisioned':
    case 'deployed':
      return InstanceCardState.PROVISIONED;
    case 'failed-start':
    case 'failed':
      return InstanceCardState.FAILED;
    case 'closed':
    default:
      return InstanceCardState.CLOSED;
    case 'deprecated':
      return InstanceCardState.DEPRECATED;
  }
};

export const getInstanceScalingType = (
  scaling: string
): InstanceCardScalingType => {
  switch (scaling?.toLowerCase()) {
    case 'spot':
      return InstanceCardScalingType.SPOT;
    case 'on_demand':
      return InstanceCardScalingType.ONDEMAND;
    case 'auto':
      return InstanceCardScalingType.AUTO;
    default:
      return InstanceCardScalingType.ONDEMAND;
  }
};

export const canDeleteProject = (
  currentUser: IUser,
  selectedOrganisationMembers: IUser[],
  instanceReport: IInstanceReport
): boolean => {
  if (currentUser) {
    return (
      (getUserRole(selectedOrganisationMembers, currentUser as IUser) ===
        'admin' ||
        getUserRole(selectedOrganisationMembers, currentUser as IUser) ===
          'owner') &&
      instanceReport?.active === 0
    );
  }
  return false;
};

export const validateDomain = (domainName: string): boolean => {
  const domainRegex = /^([a-z0-9]+(-[a-z0-9]+){0,62})\.[a-z]{2,63}$/;
  const lowerName = domainName.toLowerCase();
  if (!domainRegex.test(lowerName)) return false;
  return true;
};

export const validateSubdomain = (
  subdomainName: string,
  allowWildcard: boolean = false
): boolean => {
  const subdomainRegex = allowWildcard
    ? /^(\*\.|[a-z0-9]([-a-z0-9]){0,62}\.){2,}[a-z]{2,63}$/
    : /^(([a-z0-9]+(-[a-z0-9]+){0,62})\.){2,}[a-z]{2,63}$/;
  const lowerName = subdomainName.toLowerCase();
  if (!subdomainRegex.test(lowerName)) return false;
  return true;
};
