import React from 'react';
import { ConfirmationModal, Modal } from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import Styles from '../../../styles/compute/modal.module.scss';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import { removeInvitedMemberThunk } from '../../../redux/compute/organisation/organisation.thunks';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import ModalWrapper from './ModalWrapper';

interface IProps {
  isOpen: boolean;
  memberId: string;
  memberEmail: string;
  organisationId: string;
}

const RemoveInvitedMember = ({
  isOpen,
  memberId,
  memberEmail,
  organisationId,
}: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();

  const removingInvitedUserFromOrganisation = useSelector(
    (state: RootState) => state.organisation.removingInvitedUserFromOrganisation
  );

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={() => {
        dispatchRtk(toggleModalShowRtk({ modalShow: false }));
      }}
      classname={`${Styles.modal__outer__con} modal fixed inset-0 overflow-y-auto`}
    >
      <div className="w-500">
        <Modal
          header
          headerContent={<>Remove Invited Member</>}
          footer
          modalContent={
            <div className="flex flex-col gap-y-3">
              <p className="text-sm font-normal leading-5 mb-3 text-center">
                Do you really want to remove invited member <b>{memberEmail}</b>{' '}
                from your organization?
              </p>
            </div>
          }
          onPrimaryButtonClick={() =>
            dispatchRtk(
              removeInvitedMemberThunk({
                inviteId: memberId,
                organisationId,
              })
            )
          }
          primaryButton
          secondaryButton
          onSecondaryButtonClick={() =>
            dispatchRtk(toggleModalShowRtk({ modalShow: false }))
          }
          primaryButtonContent="Remove"
          primaryButtonLoading={removingInvitedUserFromOrganisation}
          secondaryButtonContent="Cancel"
          subtitle={''}
          confirmationStatus={ConfirmationModal.ERROR}
        />
      </div>
    </ModalWrapper>
  );
};

export default RemoveInvitedMember;
