export interface IStatusState {
  status: IStatus | null;
}

export interface IStatus {
  indicator: IStatusIndicator;
  description: string;
}

export enum IStatusIndicator {
  // eslint-disable-next-line no-unused-vars
  NONE = 'none',
  // eslint-disable-next-line no-unused-vars
  MINOR = 'minor',
  // eslint-disable-next-line no-unused-vars
  MAJOR = 'major',
  // eslint-disable-next-line no-unused-vars
  CRITICAL = 'critical',
}
