import React, { useEffect, useState } from 'react';
import {
  TextInput,
  Dropdown,
  OptionType,
  Modal,
  Feedback,
  Tooltip,
} from '@spheron/ui-library';
import { ReactComponent as InfoIcon } from '@spheron/ui-library/dist/assets/info-circle.svg';
import { ReactComponent as CopyIcon } from '@spheron/ui-library/dist/assets/copy.svg';
import { useDispatch, useSelector } from 'react-redux';
import Styles from '../../../styles/compute/modal.module.scss';
import {
  convertLocalDateToUTC,
  getDateAhead,
} from '../../../redux/compute/user/user.utils';
import { IOrganization } from '../../../redux/compute/combined-state.interface';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import { createTokensThunk } from '../../../redux/compute/user/user.thunks';
import { setTokenCreationStateRtk } from '../../../redux/compute/user/user.slice';
import ModalWrapper from './ModalWrapper';
import { truncateText } from '../../../redux/compute/root-utils';

interface IProps {
  isOpen: boolean;
  isOrgSelected: boolean;
}

const CreateTokenModal = ({ isOpen, isOrgSelected = false }: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();

  const durations = [
    { label: '1 Day', value: 1 },
    { label: '7 Days', value: 7 },
    { label: '30 Days', value: 30 },
    { label: '60 Days', value: 60 },
    { label: '90 Days', value: 90 },
    { label: '180 Days', value: 180 },
    { label: '1 Year', value: 1 },
    { label: 'No Expiration', value: 'never' },
  ];

  const tokenCreated = useSelector(
    (state: RootState) => state.user.tokenCreated.created
  );

  const tokenValue = useSelector(
    (state: RootState) => state.user.tokenCreated.value
  );

  const creatingToken = useSelector(
    (state: RootState) => state.user.creatingToken
  );

  const computeOrganisations = useSelector(
    (state: RootState) => state.organisation.computeOrganisations
  );

  const selectedOrganisationLoading = useSelector(
    (state: RootState) => state.organisation.selectedOrganisationLoading
  );

  const selectedOrganisation = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation
  );

  const [name, setToken] = useState<string>('');
  const [selectedOrg, setSelectedOrg] = useState<string>('');
  const [selectedDuration, setSelectedDuration] = useState<string>('0');
  const [selectedDurationLabel, setSelectedDurationLabel] =
    useState<string>('Select Duration');
  const [selectedDurationType, setSelectedDurationType] = useState<string>('');
  const [formatedDate, setFormatedDate] = useState<string>();
  const [dateUTC, setDateUTC] = useState<string>();
  const [tokenCopied, setTokenCopied] = useState(false);
  const [okayDisabled, setOkayDisabled] = useState(true);

  useEffect(() => {
    if (!selectedOrganisationLoading) {
      setSelectedOrg(selectedOrganisation._id);
    }
  }, [selectedOrganisation._id, selectedOrganisationLoading]);

  const handleCreateToken = () => {
    dispatchRtk(
      createTokensThunk({
        name,
        organizationId: selectedOrg,
        expiresAt: selectedDurationType !== 'never' ? dateUTC : null,
      })
    );
  };

  const copyToken = (value: string) => {
    navigator.clipboard.writeText(value);
    setTokenCopied(true);
    setOkayDisabled(false);
  };

  const formatType = (type: string | 'label') => {
    let formatedType = '';
    if (type.includes('Day')) {
      formatedType = 'day';
    }
    if (type.includes('Year')) {
      formatedType = 'year';
    }
    if (type.includes('No Expiration')) {
      formatedType = 'never';
    }
    return formatedType;
  };

  const formatDuration = (duration: string) => {
    return parseInt(duration?.replace(/[^0-9]/g, ''), 10);
  };

  const handleOnSelect = (label: string, value: string) => {
    setSelectedDurationLabel(label);
    setSelectedDuration(value);
    setSelectedDurationType(formatType(label));
  };

  useEffect(() => {
    setTimeout(() => {
      setTokenCopied(false);
    }, 3000);
  }, [tokenCopied]);

  useEffect(() => {
    setFormatedDate(
      getDateAhead(selectedDurationType, formatDuration(selectedDuration))
    );
    setDateUTC(
      convertLocalDateToUTC(
        selectedDurationType,
        formatDuration(selectedDuration)
      )
    );
  }, [selectedDurationType, selectedDuration]);

  const handleCreateTokenDisable = () =>
    !name || !selectedOrg || selectedDurationLabel === 'Select Duration';

  const closeModal = () => {
    dispatchRtk(toggleModalShowRtk({ modalShow: false }));
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={() => {
        dispatchRtk(toggleModalShowRtk({ modalShow: false }));
        dispatchRtk(setTokenCreationStateRtk());
      }}
      classname={`${Styles.modal__outer__con} modal fixed inset-0 overflow-y-auto`}
    >
      <div className={Styles.modal__inner__content}>
        <Modal
          footer
          header
          subtitle=""
          modalContent={
            <div className="flex flex-col gap-4 w-full">
              <Feedback
                title={
                  tokenCreated
                    ? 'Make sure you copy it before closing the modal else you will lose it forever.'
                    : // eslint-disable-next-line max-len
                      'Create API tokens and please make sure you copy it before closing the modal else you will lose it forever.'
                }
                feedbackType="info"
                icon={<InfoIcon />}
                showClose={false}
              />
              {tokenCreated ? (
                <>
                  <div
                    className="text-form-text dark:text-dark-form-text text-sm font-normal dark:bg-dark-base-bg 
                     bg-bg-textbox dark:border-dark-form-border 
                     border-form-border border-[1.5px] relative rounded-lg flex 
                     justify-between items-center 
                     p-3 h-12 w-full outline-none"
                  >
                    {truncateText(tokenValue, 30, 30)}
                    <Tooltip
                      position="top"
                      text={tokenCopied ? `Token Copied` : `Copy Token`}
                      type="float"
                      wrapText
                    >
                      <CopyIcon
                        className="w-5 h-5 cursor-pointer text-base-icon dark:text-dark-base-icon
                      opacity-80 hover:opacity-100"
                        onClick={() => copyToken(tokenValue)}
                      />
                    </Tooltip>
                  </div>
                </>
              ) : (
                <div className="flex flex-col w-full">
                  <div className="mt-5">
                    <TextInput
                      label="Token Name"
                      onChange={(value) => setToken(value as string)}
                      value={name}
                      placeholder="Ex: Website Builder"
                    />
                  </div>
                  {!isOrgSelected && (
                    <>
                      <div className="mt-5">
                        <Dropdown
                          dropdownSize="default"
                          dropdownType="default"
                          label="Scope"
                          placeholder="Select a scope"
                          filled
                          onSelected={(selected) =>
                            setSelectedOrg(selected.value as string)
                          }
                          defaultSelected={selectedOrg}
                          options={[
                            ...computeOrganisations.map(
                              (organisation: IOrganization) => ({
                                img: organisation.profile.image,
                                label: organisation.profile.name,
                                value: organisation._id,
                                optionType: 'primary' as OptionType,
                              })
                            ),
                          ]}
                        />
                      </div>
                    </>
                  )}
                  <div className="mt-5 flex flex-row items-center">
                    <div className="w-12/12 sm:w-4/12 ">
                      <Dropdown
                        dropdownSize="default"
                        dropdownType="default"
                        label="Expiration"
                        placeholder="Select Duration"
                        filled
                        onSelected={(selected) =>
                          handleOnSelect(
                            selected.label as string,
                            selected.value as string
                          )
                        }
                        defaultSelected={selectedDurationLabel}
                        options={[
                          ...durations.map((option) => ({
                            label: option.label,
                            value: option.label,
                            optionType: 'primary' as OptionType,
                          })),
                        ]}
                      />
                    </div>
                    {selectedDurationLabel !== 'Select Duration' && (
                      <h6 className="ml-3 text-sm">
                        {selectedDurationType !== 'never'
                          ? `Your token will expire on ${formatedDate}`
                          : 'This token will never expire!'}
                      </h6>
                    )}
                  </div>
                </div>
              )}
            </div>
          }
          headerContent={
            <>{tokenCreated ? 'Hurray! Token is created!' : 'Create Token'}</>
          }
          primaryButtonContent={tokenCreated ? 'Okay' : 'Create'}
          secondaryButtonContent="Cancel"
          primaryButton
          primaryButtonLoading={creatingToken}
          primaryButtonDisabled={
            tokenCreated ? okayDisabled : handleCreateTokenDisable()
          }
          secondaryButtonDisabled={creatingToken}
          secondaryButton={!tokenCreated}
          onPrimaryButtonClick={() => {
            if (!tokenCreated) handleCreateToken();
            else {
              dispatchRtk(toggleModalShowRtk({ modalShow: false }));
              dispatchRtk(setTokenCreationStateRtk());
            }
          }}
          onSecondaryButtonClick={closeModal}
        />
      </div>
    </ModalWrapper>
  );
};

export default CreateTokenModal;
