import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IService } from '../../instance/instance.interfaces';
// eslint-disable-next-line import/no-cycle
import { loadMoreServiceLogsThunk } from './service.thunks';
import { IServiceState } from './service.interfaces';

const initialState: IServiceState = {
  selectedService: null,
  selectedServiceLoading: true,
  serviceLogs: [],
  serviceLogsLoading: false,
  serviceMetrics: '',
  logsLength: 0,
  loadMoreLogsLoading: false,
  logsLastUpdated: null,
  selectedServiceComputeLogsPagination: {
    currentPage: 1,
    total: 0,
  },
};

const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    setSelectedService(state, action: PayloadAction<IService | null>) {
      state.selectedService = action.payload;
      state.selectedServiceLoading = false;
    },
    toggleSelectedServiceLogsLoading(state, action: PayloadAction<boolean>) {
      state.serviceLogsLoading = action.payload;
    },
    setServiceLogs(state, action: PayloadAction<any>) {
      state.serviceLogs = action.payload.logs;
      state.logsLength =
        action.payload.logsLength || action.payload.logs.length;
    },
    setLogsLastUpdated(state, action: PayloadAction<string>) {
      state.logsLastUpdated = action.payload;
    },
    resetServiceState(state) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadMoreServiceLogsThunk.pending, (state) => {
      state.loadMoreLogsLoading = true;
    });

    builder.addCase(
      loadMoreServiceLogsThunk.fulfilled,
      (state, action: PayloadAction<string[]>) => {
        state.loadMoreLogsLoading = false;
        state.serviceLogs = [...action.payload, ...state.serviceLogs];
        state.selectedServiceComputeLogsPagination = {
          ...state.selectedServiceComputeLogsPagination,
          currentPage:
            state.selectedServiceComputeLogsPagination.currentPage + 1,
        };
      }
    );

    builder.addCase(loadMoreServiceLogsThunk.rejected, (state) => {
      state.loadMoreLogsLoading = false;
    });
  },
});

export const { reducer: serviceRTKReducer } = serviceSlice;

export const {
  setSelectedService: setSelectedServiceRtk,
  setServiceLogs,
  resetServiceState,
  setLogsLastUpdated,
  toggleSelectedServiceLogsLoading,
} = serviceSlice.actions;
