/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  Button,
  Link,
  Modal,
  SectionHeading,
  Tooltip,
} from '@spheron/ui-library';
import { ReactComponent as ClockIcon } from '@spheron/ui-library/dist/assets/clock-circle.svg';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as PrintIcon } from '../../../assets/compute/icons/print.svg';
import { ReactComponent as CreditIcon } from '../../../assets/compute/icons/credit-icon.svg';
import InvoiceTableHeader from '../../TableItems/invoice-table-header';
import InvoiceTableItem from '../../TableItems/invoice-table-item';
import { ReactComponent as SpheronText } from '../../../assets/compute/global/spheron-text.svg';
import BillingTag from '../../Misc/billing-tag';
import Styles from '../../../styles/compute/modal.module.scss';
import {
  IBonusPaymentInvoice,
  INewMemberPaymentInvoice,
  IPendingBonus,
  IProcessor,
  ISubscriptionInvoice,
  ISubscriptionPaymentInvoice,
  TransactionState,
} from '../../../redux/compute/subscription/subscription.interfaces';
import {
  calculateAmount,
  invoicesParser,
} from '../../../redux/compute/subscription/subscription.utils';
import {
  getBlockExplorerIcon,
  getTokenNameFromAddress,
  networks,
} from '../../../redux/compute/web3/web3.utils';
import TableStyle from '../../../styles/compute/table.module.scss';
import { ReactComponent as Coupon } from '../../../assets/compute/icons/coupon.svg';
import { getPaymentLogo } from '../../../redux/compute/stripe/stripe.utils';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import { PaymentMethod } from '../../../redux/combined-state.interface';
import { downloadFile } from '../../../redux/root-utils';
import ModalWrapper from './ModalWrapper';
import HoverIcon from '../Icons/hover-icon';

interface IProps {
  isOpen: boolean;
  invoiceId: string;
}
const Invoice = ({ isOpen, invoiceId }: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();

  const subscriptionInvoices = useSelector(
    (state: RootState) => state.subscription.subscriptionInvoices
  );

  const [selectedInvoice, setSelectedInvoice] =
    useState<ISubscriptionInvoice>();

  const [subscriptionInvoice, setSubscriptionInvoice] =
    useState<ISubscriptionPaymentInvoice | null>();

  const [bonusInvoice, setBonusInvoice] = useState<IBonusPaymentInvoice[]>([]);

  const [newMemberInvoices, setNewMemberInvoices] = useState<
    INewMemberPaymentInvoice[]
  >([]);

  const [pendingBonusInvoice, setPendingBonusInvoice] = useState<
    IPendingBonus[]
  >([]);

  const newMemberNetworkTokenMap = new Map<string, number>();

  const headerItems = [
    {
      id: 1,
      text: (
        <div
          className="
           
         font-semibold text-sm py-2 text-left pl-3.5"
        >
          Item
        </div>
      ),
    },
    {
      id: 2,
      text: (
        <div
          className="
         font-semibold text-sm py-2 text-left"
        >
          Usage
        </div>
      ),
    },
    {
      id: 3,
      text: (
        <div
          className="
         font-semibold text-sm py-2 text-left"
        >
          Unit Price
        </div>
      ),
    },
    {
      id: 4,
      text: (
        <div
          className="
         font-semibold text-sm py-2 text-left"
        >
          Chosen Token
        </div>
      ),
    },
    {
      id: 5,
      text: (
        <div
          className="
         font-semibold text-sm py-2 text-right pr-3.5"
        >
          Amount
        </div>
      ),
    },
  ];

  const closeModal = () => {
    dispatchRtk(
      toggleModalShowRtk({
        modalShow: false,
      })
    );
  };

  useEffect(() => {
    const selectedInvoice = subscriptionInvoices.find(
      (invoice) => invoice.subscription._id === invoiceId
    );

    const {
      subscriptionInvoice,
      bonusInvoices,
      newMemberInvoices,
      pendingBonusInvoice,
    } = invoicesParser(selectedInvoice as ISubscriptionInvoice);
    setSelectedInvoice(selectedInvoice);
    setBonusInvoice(bonusInvoices);
    setSubscriptionInvoice(subscriptionInvoice);
    setNewMemberInvoices(newMemberInvoices);
    setPendingBonusInvoice(pendingBonusInvoice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceId, subscriptionInvoices]);

  const getCouponCode = (
    invoice:
      | ISubscriptionPaymentInvoice
      | IBonusPaymentInvoice
      | INewMemberPaymentInvoice
  ) => {
    return (
      <>
        {invoice?.coupon ? (
          <Tooltip
            position="top"
            text={`This payment was made with ${invoice.coupon.couponName} coupon`}
            type="float"
          >
            <HoverIcon icon={<Coupon />} className="hover:shadow-none" />
          </Tooltip>
        ) : (
          <>
            {invoice?.paymentMethod === PaymentMethod.CRYPTO ? (
              <a
                href={
                  (invoice as IBonusPaymentInvoice)?.paymentProvider ===
                  IProcessor.COPPERX
                    ? (invoice as IBonusPaymentInvoice).redirectUrl
                    : `${networks[invoice?.chainId]?.blockExplorerUrls[0]}/tx/${
                        invoice?.transactionHash
                      }`
                }
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={getBlockExplorerIcon(invoice?.chainId) || ''}
                  alt="ether"
                  className="ml-2 w-4 h-4"
                />
              </a>
            ) : (
              <>
                {invoice?.paymentMethod === PaymentMethod.SPHERONWALLET ? (
                  <CreditIcon className="w-8 h-8 text-base-text dark:text-dark-base-text" />
                ) : (
                  <Tooltip
                    position="top"
                    text={`This payment was made with ${
                      (invoice as IBonusPaymentInvoice)?.walletDetails?.info
                        ?.last4
                    } ${
                      (invoice as IBonusPaymentInvoice)?.walletDetails?.info
                        ?.brand
                    } ${
                      (invoice as IBonusPaymentInvoice)?.walletDetails?.info
                        ?.brand === 'mastercard'
                        ? ''
                        : 'card'
                    } `}
                    type="float"
                  >
                    <HoverIcon
                      icon={
                        <img
                          src={getPaymentLogo(
                            (invoice as IBonusPaymentInvoice)?.walletDetails
                              ?.info?.brand || ''
                          )}
                          alt="payment-logo"
                          className="w-8 h-5"
                        />
                      }
                    />
                  </Tooltip>
                )}
              </>
            )}
          </>
        )}
      </>
    );
  };

  newMemberInvoices.forEach((newMember: INewMemberPaymentInvoice) => {
    if (newMember.paymentMethod === PaymentMethod.CRYPTO) {
      newMemberNetworkTokenMap.set(newMember.token, newMember.chainId);
    } else if (newMember.paymentMethod === PaymentMethod.CREDITCARD) {
      newMemberNetworkTokenMap.set('credit_card', -1);
    } else if (newMember.paymentMethod === PaymentMethod.SPHERONWALLET) {
      newMemberNetworkTokenMap.set('spheron_wallet', 1);
    } else {
      newMemberNetworkTokenMap.set('coupon', 0);
    }
  });

  const getNewMemberInvoice = (newMemberInvoice: INewMemberPaymentInvoice) => [
    {
      id: 5,
      text: (
        <div
          className=" 
                 font-normal text-sm py-4
               text-left pl-3.5 flex items-center"
        >
          <span className="mr-3">{getCouponCode(newMemberInvoice)}</span>
          New Member
        </div>
      ),
    },
    {
      id: 6,
      text: (
        <div
          className=" 
               font-normal text-sm py-2 text-left"
        >
          {newMemberInvoice?.units} member
          {newMemberInvoice?.units > 1 ? 's' : ''}
        </div>
      ),
    },
    {
      id: 7,
      text: (
        <div
          className=" 
               font-normal text-sm py-2 text-left"
        >
          ${subscriptionInvoice?.unitPrice?.toFixed(2)}
        </div>
      ),
    },
    {
      id: 8,
      text: (
        <div
          className=" font-normal text-sm py-2 text-left
               flex items-center justify-start gap-x-1"
        >
          {newMemberInvoice?.token &&
          newMemberInvoice?.paymentMethod === PaymentMethod.CRYPTO ? (
            <>{`${getTokenNameFromAddress(newMemberInvoice.token)}`}</>
          ) : (
            <>NA</>
          )}
        </div>
      ),
    },
    {
      id: 9,
      text: (
        <div className=" font-semibold text-sm py-2 text-right pr-3.5">
          ${newMemberInvoice.totalDollarPrice.toFixed(2)}
        </div>
      ),
    },
  ];

  const subscriptionTableItems = [
    {
      id: 1,
      text: (
        <div
          className=" 
         font-normal text-sm py-4 text-left pl-3.5 flex items-center"
        >
          <span className="mr-3">
            {getCouponCode(subscriptionInvoice as ISubscriptionPaymentInvoice)}
          </span>
          {subscriptionInvoice?.name}
        </div>
      ),
    },
    {
      id: 2,
      text: (
        <div
          className="  font-normal 
                                text-sm py-4 text-left"
        >
          {(subscriptionInvoice?.includedMembers || 0) > 0 && (
            <div>
              {subscriptionInvoice?.includedMembers}{' '}
              {`member${
                (subscriptionInvoice?.includedMembers || 0) > 1 ? 's' : ''
              }`}
            </div>
          )}

          {(subscriptionInvoice?.units || 0) > 0 && (
            <div>
              <>{subscriptionInvoice?.units}</>
              {` member${(subscriptionInvoice?.units || 0) > 1 ? 's' : ''}`}
            </div>
          )}
        </div>
      ),
    },
    {
      id: 3,
      text: (
        <div
          className="  font-normal
                                 text-sm py-4 text-left"
        >
          {subscriptionInvoice?.unitPriceUnderLimit && (
            <>
              ${subscriptionInvoice?.unitPriceUnderLimit?.toFixed(2)}
              <br />
            </>
          )}

          {(subscriptionInvoice?.units || 0) > 0 && (
            <>${subscriptionInvoice?.unitPrice?.toFixed(2)}</>
          )}
        </div>
      ),
    },
    {
      id: 4,
      text: (
        <div className="  font-normal text-sm py-4 text-left">
          {subscriptionInvoice?.token &&
          subscriptionInvoice?.paymentMethod === PaymentMethod.CRYPTO
            ? getTokenNameFromAddress(subscriptionInvoice.token)
            : 'NA'}
        </div>
      ),
    },
    {
      id: 5,
      text: (
        <div
          className="  font-semibold
                                 text-sm py-4 text-right pr-3.5"
        >
          $ {subscriptionInvoice?.totalDollarPrice?.toFixed(2)}
        </div>
      ),
    },
  ];

  const getBonusTableItems = (bonus: IBonusPaymentInvoice) => {
    const isStorageBonus: boolean = bonus.name.split(' ')[1] === 'Storage';

    return [
      {
        id: 1,
        text: (
          <div
            className="  font-normal
                                 text-sm py-4 text-left pl-3.5 flex
                                  items-center"
          >
            <span className="mr-3">{getCouponCode(bonus)}</span>
            {bonus.name}
          </div>
        ),
      },
      {
        id: 2,
        text: (
          <div
            className="  font-normal 
                                text-sm py-4 text-left"
          >
            {isStorageBonus ? `${Number(bonus.value) / 1024} GB` : bonus.usage}
          </div>
        ),
      },
      {
        id: 3,
        text: (
          <div
            className="  font-normal
                                 text-sm py-4 text-left"
          >
            {bonus.rate}
          </div>
        ),
      },
      {
        id: 4,
        text: (
          <div
            className="  font-normal
                                     text-sm py-4 text-left"
          >
            {bonus?.token && bonus?.paymentMethod === PaymentMethod.CRYPTO
              ? getTokenNameFromAddress(bonus?.token)
              : 'NA'}
          </div>
        ),
      },
      {
        id: 5,
        text: (
          <div
            className="  font-semibold
                                 text-sm py-4 text-right pr-3.5"
          >
            $ {bonus?.totalDollarPrice?.toFixed(2)}
          </div>
        ),
      },
    ];
  };

  const getPendingBonusTableItems = (bonus: IPendingBonus) => {
    const isStorageBonus: boolean = bonus.name.split(' ')[1] === 'Storage';
    return [
      {
        id: 1,
        text: (
          <div
            className="  font-normal
                                 max-w-140 min-w-140 text-sm py-4 text-left pl-3.5 flex
                                  items-center"
          >
            <span className="mr-3">
              <ClockIcon className="w-4 h-4 text-feedback-warning-text dark:text-dark-feedback-warning-text" />
            </span>
            {bonus.name}
          </div>
        ),
      },
      {
        id: 2,
        text: (
          <div
            className="  font-normal 
                                text-sm py-4 text-left"
          >
            {isStorageBonus ? `${Number(bonus?.value) / 1024} GB` : bonus.usage}
          </div>
        ),
      },
      {
        id: 3,
        text: (
          <div
            className="  font-normal
                                 text-sm py-4 text-left"
          >
            {bonus.rate}
          </div>
        ),
      },
      {
        id: 4,
        text: (
          <div
            className="  font-normal
                                     text-sm py-4 text-left"
          >
            NA
          </div>
        ),
      },
      {
        id: 5,
        text: (
          <div
            className="  font-semibold
                                 text-sm py-4 text-right pr-3.5"
          >
            NA
          </div>
        ),
      },
    ];
  };

  const showInvoiceDownload =
    (selectedInvoice?.subscription?.invoices?.length || 0) > 0;

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={() => {
        dispatchRtk(toggleModalShowRtk({ modalShow: false }));
      }}
      classname={`${Styles.modal__outer__con} modal fixed inset-0 overflow-y-auto`}
    >
      <div className={`${Styles.modal__inner__content} !w-[900px]`}>
        <Modal
          footer
          header
          title=""
          subtitle=""
          modalContent={
            <div className="max-h-500 overflow-x-scroll">
              <div
                className="bg-base-bg dark:bg-dark-base-bg p-6
                  border-base-border dark:border-dark-base-border rounded-lg"
              >
                <div className="flex justify-between items-center relative">
                  <SectionHeading
                    showRefresh={false}
                    showSwitch={false}
                    subHeading={dayjs(
                      selectedInvoice?.subscription.createdAt
                    ).format('YYYY-MM-DD HH:mm')}
                    heading="Subscription & Bonuses"
                    handleIconClick={() => {}}
                    handleRefreshClick={() => {}}
                    handleSwitchClick={() => {}}
                    switchDisable={false}
                    handleClick={() => {}}
                    loading={false}
                    time={''}
                    refreshType={'default'}
                    showText={false}
                    toggleId={''}
                    isChecked={false}
                    handleChange={() => {}}
                  />
                  {showInvoiceDownload && (
                    <Button
                      buttonType="ghost"
                      label="Download Invoice"
                      size="small"
                      onClick={() =>
                        selectedInvoice?.subscription?.invoices.forEach(
                          (invoice) => {
                            if (invoice !== null) downloadFile(invoice);
                          }
                        )
                      }
                      leftIcon={
                        <PrintIcon className="text-base-icon dark:text-dark-base-icon w-5 h-5" />
                      }
                    />
                  )}
                </div>

                {subscriptionInvoice && (
                  <div className="subscription__table mt-6">
                    <span className="text-base-link dark:text-dark-base-link font-semibold text-sm">
                      Subscription
                    </span>
                    <table
                      className={`table-fixed table mt-4 w-full ${TableStyle.invoice__table}`}
                    >
                      <InvoiceTableHeader items={headerItems} />
                      <tbody>
                        <InvoiceTableItem
                          className=""
                          handleClick={() => {}}
                          items={subscriptionTableItems}
                        />
                        {newMemberInvoices.map((newMember) => (
                          <InvoiceTableItem
                            className=""
                            handleClick={() => {}}
                            items={getNewMemberInvoice(newMember)}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                {(bonusInvoice.length > 0 ||
                  pendingBonusInvoice.length > 0) && (
                  <div className="bonus__table mt-6">
                    <span className="text-base-link dark:text-dark-base-link font-semibold text-sm">
                      Bonuses
                    </span>
                    <table
                      className={`table-fixed table mt-4 w-full ${TableStyle.invoice__table}`}
                    >
                      <InvoiceTableHeader items={headerItems} />
                      <tbody>
                        {bonusInvoice.map((bonus) => {
                          return (
                            <InvoiceTableItem
                              key={bonus.id}
                              className=""
                              handleClick={() => {}}
                              items={getBonusTableItems(bonus)}
                            />
                          );
                        })}

                        {pendingBonusInvoice.map((bonus) => {
                          return (
                            <InvoiceTableItem
                              key={bonus.id}
                              className=""
                              handleClick={() => {}}
                              items={getPendingBonusTableItems(bonus)}
                            />
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
                {subscriptionInvoice && (
                  <div
                    className="table__footer mt-11 flex items-center 
                  justify-between font-bold text-xl uppercase"
                  >
                    <h3 className="dark:text-white">Total Amount</h3>
                    <div className="flex items-center gap-x-3">
                      <BillingTag
                        type={
                          pendingBonusInvoice?.length > 0
                            ? TransactionState.PENDING
                            : TransactionState.SUCCEEDED
                        }
                      />{' '}
                      $
                      {calculateAmount(
                        subscriptionInvoice,
                        bonusInvoice,
                        newMemberInvoices
                      )?.toFixed(2)}
                    </div>
                  </div>
                )}
              </div>
            </div>
          }
          requiredMessage
          requiredMessageContent={
            <div
              className="flex items-center justify-between  
                font-normal px-6 py-5 text-sm !w-full"
            >
              <div className="flex items-center gap-x-1">
                Questions about your order? Contact us at:{' '}
                <Link
                  type="secondary"
                  size="default"
                  text="info@spheron.network"
                  isBold={false}
                  onClick={() => window.open('mailto:info@spheron.network')}
                />
              </div>
              <SpheronText />
            </div>
          }
          headerContent={<>Receipt</>}
          primaryButtonContent=""
          secondaryButtonContent=""
          primaryButton={false}
          primaryButtonLoading={false}
          primaryButtonDisabled={false}
          secondaryButtonDisabled={false}
          secondaryButton={false}
          onPrimaryButtonClick={() => {}}
          onSecondaryButtonClick={closeModal}
        />
      </div>
    </ModalWrapper>
  );
};

export default Invoice;
