import React, { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import {
  IBalance,
  IOrganisationWallet,
} from '../../../redux/web3/web3.interfaces';
import {
  getDecimalFromTokenAddress,
  getTokenNameFromAddress,
  isNonSubgraphChain,
} from '../../../redux/web3/web3.utils';
import { RootState } from '../../../redux/rtk-store';
import {
  fetchBalanceThunk,
  fetchWalletBalanceThunk,
} from '../../../redux/web3/web3.thunks';
import { fetchSolBalanceThunk } from '../../../redux/solana/solana.thunks';
import { withLoader } from '../../../redux/root-utils';
import { toggleHasDeposited } from '../../../redux/web3/web3.slice';
import { AppDispatch } from '../../../redux/compute/store';

interface IProps {
  selectedWallet: IOrganisationWallet | undefined;
}

const WalletBalance = ({ selectedWallet }: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();
  const balance: IBalance = useSelector(
    (state: RootState) => state.web3.balance
  );
  const escrowBalanceLoading: boolean = useSelector(
    (state: RootState) => state.web3.escrowBalanceLoading
  );
  const hasDeposited = useSelector(
    (state: RootState) => state.web3.hasDeposited
  );

  useEffect(() => {
    if ((window as any)?.ethereum)
      if (
        (window as any).ethereum.selectedAddress &&
        selectedWallet &&
        (selectedWallet.details.address !== balance.address ||
          balance?.token?.toLowerCase() !==
            selectedWallet.details.tokenDetails?.address?.toLowerCase() ||
          hasDeposited)
      ) {
        dispatchRtk(toggleHasDeposited(false));
        const nonSubgraphChainPayload = {
          walletId: selectedWallet._id,
          tokenDetails: selectedWallet.details.tokenDetails,
          address: selectedWallet.details.address,
          networkDetails: selectedWallet.details.networkDetails,
        };
        const {
          tokenDetails: { address: tokenAddress },
          networkDetails: { chainId, chainName },
          address,
        } = selectedWallet.details;

        const fetchBalancePayload = {
          token: tokenAddress,
          address,
          chainId: Number(chainId),
        };
        if (chainName === 'solana') {
          dispatchRtk(fetchSolBalanceThunk(fetchBalancePayload));
          return;
        }
        if (isNonSubgraphChain(selectedWallet)) {
          dispatchRtk(fetchWalletBalanceThunk(nonSubgraphChainPayload));
          return;
        }

        dispatchRtk(
          fetchBalanceThunk({
            walletId: selectedWallet._id,
            balance: fetchBalancePayload,
          })
        );
      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWallet?._id]);

  return (
    <div>
      {withLoader(
        escrowBalanceLoading,
        <>
          <Skeleton height={16} width={50} duration={2} />
        </>,
        <div className="flex items-center justify-center gap-x-1">
          <div className="truncate max-w-[9ch] whitespace-nowrap">
            {selectedWallet && isNonSubgraphChain(selectedWallet)
              ? Number(balance?.amount || 0).toFixed(4)
              : (
                  Number(balance?.amount || 0) /
                  10 **
                    getDecimalFromTokenAddress(
                      balance?.token?.toLowerCase() || ''
                    )
                ).toFixed(4)}{' '}
          </div>
          {getTokenNameFromAddress(balance?.token?.toLowerCase() || '')}
        </div>
      )}
    </div>
  );
};

export default WalletBalance;
