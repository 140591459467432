/* eslint-disable import/no-cycle */
import { ClusterInstanceDomain, IDiscount } from '../combined-state.interface';
import { IInstancePlan } from '../instance/instance-creation/instance-creation.interfaces';
import {
  IAgreedMachineImage,
  IAutoscalingRequestPayload,
} from '../instance/instance.interfaces';

/* eslint-disable no-unused-vars */
export interface IClusterState {
  clustersLoading: boolean;
  moreClustersLoading: boolean;
  allClusters: ICluster[];
  totalClusters: number;
  allClustersBilling: ICluster[];
  totalClustersBilling: number;
  totalInstances: number;
  totalInstancesBilling: number;
  selectedCluster: ISelectedCluster | undefined;
  selectedTemplate: IClusterTemplate | null;
  clusterPagination: IClusterPagination;
  clusterPaginationBilling: IClusterPagination;
  clusterDetails: IClusterDetails | null;
  clusterInstanceReport: IInstanceReport | null;
  clusterInstanceReportLoading: boolean;
  clusterFundUsage: ITopupReport | null;
  clusterFundUsageLoading: boolean;
  clusterDetailsLoading: boolean;
  totalClustersTopup: number;
  clusterWalletUsage: IClusterUsageResponseDto | null;
  clusterWalletUsageLoading: boolean;
  clusterWalletInstanceReportLoading: boolean;
  clusterCount: number;
  clusterCountLoading: boolean;
  deleteClusterLoading: boolean;
  clusterTemplateCategories: string[];
  selectedClusterTemplateCategory: string;
  clusterTemplateCategoriesLoading: boolean;
  cpuClusterTemplates: IClusterTemplate[];
  gpuClusterTemplates: IClusterTemplate[];
  allClusterTemplates: IClusterTemplate[];
  clusterTemplatesLoading: boolean;
  clusterDeploymentProgress: number;
  clusterTemplateDetailsLoading: boolean;
  selectedClusterTemplateDetails: IClusterTemplate | null;
  templateVideoTitle: string;
}

export interface ICluster {
  _id: string;
  state: string;
  name: string;
  url: string;
  provider: string;
  organization: string;
  createdAt: string;
  updatedAt: string;
  instanceReport: IInstanceReport;
  topupReport: ITopupReport | null;
  clusterTemplate: IClusterTemplate;
}

export interface ITopupReport {
  dailyUsage: number;
  usedTillNow: number;
  retrievableAkt: number;
}

export interface IClusterPagination {
  currentPage: number;
  numbersPerPage: number;
}

export interface ISelectedCluster {
  repository: string;
  tags: string;
  name: string;
  provider: string;
}

export interface ISelectedTemplate {
  image: string;
  name: string;
  subtext: string;
  type: string;
}

export enum HealthCheckStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  UNKNOWN = 'Unknown',
}

export interface IHealthCheck {
  port: {
    exposedPort: number;
    containerPort: number;
  };
  status: HealthCheckStatus;
  timestamp: Date;
  url: string;
}

export interface IInstance {
  _id: string;
  state: InstanceState;
  orders: string[];
  cluster: string;
  activeOrder: string;
  createdAt: Date;
  updatedAt: Date;
  topupReport: ITopupReport;
  agreedMachineImageType: IAgreedMachineImageType;
  latestUrlPreview: string;
  retrievableAkt: number;
  withdrawnAkt: number;
  healthCheck: IHealthCheck;
  defaultDailyTopup: number;
  tag: string;
  scalable: boolean;
  price: number;
  scalableRunningCost: number;
  scalableRunningCostUpdatedAt: string;
  autoscalingRules?: IAutoscalingRequestPayload;
  discount: {
    type: string;
    activationDate: string;
    expirationDate: string;
    discountPercent: number;
  };
}
export interface IClusterDetails {
  clusterTemplate: string;
  state: string;
  _id: string;
  hookId: number;
  name: string;
  url: string;
  organization: string;
  provider: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface IInstanceReport {
  active: number;
  starting: number;
  failedToStart: number;
  closed: number;
  total: number;
  lastTimeInstanceActivated: string;
  lastTimeInstanceClosed: string;
  lastTimeInstanceFailedToStart: string;
  lastTimeInstanceStarted: string;
}

export interface IAgreedMachineImageType {
  agreementDate: number;
  machineType: string;
}

export interface IClusterWalletUsage {
  usedUpWalletAkt: number;
  walletCapacity: number;
}

export interface IClusterWalletInstanceReport {
  dailyUsageSum: number;
  usedTillNow: number;
}

export const enum InstanceState {
  STARTING = 'Starting',
  FAILED_START = 'Failed-start',
  ACTIVE = 'Active',
  CLOSED = 'Closed',
  ALL_INSTANCES = 'All Instances',
}

export const enum ClusterTemplateError {
  NOT_FOUND = 'Cluster Template not found.',
}

export const enum ClusterTemplateOverviewType {
  TEXT = 'Text',
  BULLET_POINT = 'BulletPoint',
}

export const enum ClusterTemplateCategory {
  DATABASE = 'Database',
  MONITORING = 'Monitoring',
  CODE = 'Code',
  COMMERCE = 'Commerce',
  CMS = 'CMS',
  DEV_TOOLS = 'DevTools',
  Messaging = 'Messaging',
}

export interface IClusterTemplateOverview {
  type: ClusterTemplateOverviewType;
  content: string[];
}

export interface IClusterTemplateVariable {
  label: string;
  defaultValue: string;
  required: boolean;
  name: string;
  regionMap?: Record<string, string>;
}

export interface IClusterTemplatePort {
  containerValue: string;
  defaultExposedValue: string;
}

export interface IServiceData {
  args: string[];
  commands: string[];
  defaultAkashMachineImage: IInstancePlan;
  defaultAkashMachineImageId: string;
  dockerImage: string;
  dockerImageTag: string;
  provider: string;
  variables: IClusterTemplateVariable[];
  ports: IPorts[];
  customTemplateSpecs: ICustomTemplateSpecs;
  _id: string;
  metadata: {
    icon: string;
    name: string;
  };
  agreedMachineImage: IAgreedMachineImage;
}

export interface IClusterTemplate {
  name: string;
  _id: string;
  metadata: {
    description: string;
    icon: string;
    image: string;
    docsLink: string;
    websiteLink: string;
    category: string;
    overview: IClusterTemplateOverview[];
    usage: number;
    processingType: ProcessingType;
    tutorialVideoLink?: string;
  };
  services: IServiceData[];
  discount: IDiscount;
  serviceData: IServiceData;
}

export interface ICustomTemplateSpecs {
  storage: string;
  persistentStorage: IPersistentStorage;
}

export interface IPersistentStorage {
  size: string;
  class: string;
  mountPoint: string;
}

export interface IPorts {
  containerPort: string;
  exposedPort: string;
  isChecked?: boolean;
  endpoint?: string;
  endpointExposedPort?: string;
}

export interface IDeployTemplateDto {
  templateId: string;
  environmentVariables: { name: string; value: string }[];
  ports: IClusterTemplatePort[];
  organizationId: string;
  akashImageId: string;
}

export interface IClusterDomains {
  createdAt: Date;
  deploymentEnvironmentIds: string[];
  link: string;
  name: string;
  projectId: string;
  type: ClusterInstanceDomain;
  updatedAt: Date;
  verified: boolean;
  version: string;
  _id: string;
  clusterInstance: string;
}

export interface ICreateClusterInstanceDomainPayloadDto {
  link: string | undefined;
  type: string;
  name: string;
  organizationId?: string;
}

export interface IClusterInstanceDomainIdPayloadDto {
  instanceId: string;
  domainId: string;
  organizationId?: string;
}

export interface IDeleteClusterInstanceDomainPayloadDto
  extends IClusterInstanceDomainIdPayloadDto {
  domainType: string;
}

export interface ICreateClusterInstanceDomainResponse {
  createdAt: string;
  deploymentEnvironmentIds?: [];
  link: string;
  name: string;
  projectId: string;
  type: string;
  updatedAt: Date;
  verified: boolean;
  version: string;
  _id: string;
}

export const enum InstanceCategory {
  PROVISIONED = 'Provisioned',
  PROVISIONING = 'Provisioning',
  CLOSED = 'Closed',
  FAILED = 'Failed',
  QUEUED = 'Queued',
  CLOSED_BY_SPHERON = 'Closed By Spheron',
  CLOSED_BY_AKASH_PROVIDER = 'Closed By Akash Provider',
}

export interface IGetClustersResponse {
  clusters: ICluster[];
  totalClusters: number;
  totalInstances: number;
}

export interface IClusterTemplatesDto {
  search: string;
  category: string;
  isUrl: boolean;
  processingType: ProcessingType;
  isSearch?: boolean;
}

export interface IGetClustersDto {
  clusterPagination?: IClusterPagination;
  organisationId: string;
  topupReport: string;
}
export interface ISearchInstance {
  search: string;
  limit: number;
  skip: number;
}

export interface IClusterUsageResponseDto {
  spent: number;
  dailyUsage: number;
  balance: number;
  monthlyTotal: number;
  remainingDays: number | null;
  total: number;
}

export const enum ProcessingType {
  CPU = 'cpu',
  GPU = 'gpu',
  ALL = '',
}

export const enum RegionMap {
  EU_EAST = 'eu-east',
  US_EAST = 'us-east',
  US_WEST = 'us-west',
}
