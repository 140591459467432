import React from 'react';
import { TextInput } from '@spheron/ui-library';
import { ReactComponent as CrossIcon } from '../../../assets/compute/icons/cross-icon.svg';

interface IProps {
  buildEnv: { key: string; value: string; isSecret: boolean }[];
  setBuildEnv: (
    // eslint-disable-next-line no-unused-vars
    buildEnv: { key: string; value: string; isSecret: boolean }[]
  ) => void;
  isUpdate: boolean;
}

const AddInstanceEnv = ({ buildEnv, setBuildEnv, isUpdate }: IProps) => {
  const fillEnvKey = (value: string, id: number) => {
    setBuildEnv(
      buildEnv.map((item, i) =>
        i === id
          ? { key: value.trim(), value: item.value, isSecret: item.isSecret }
          : item
      )
    );
  };
  const fillEnvValue = (value: string, id: number) => {
    setBuildEnv(
      buildEnv.map((item, i) =>
        i === id ? { key: item.key, value, isSecret: item.isSecret } : item
      )
    );
  };

  const removeBuildEnvItem = (id: number) => {
    setBuildEnv(buildEnv.filter((item, i) => i !== id));
  };
  const secretType = buildEnv[0].isSecret ? 'password' : 'text';
  return (
    <div className="flex flex-col gap-y-4">
      {buildEnv.map((env, i) => (
        <>
          {/* TODO: Add correct key prop here */}
          <div className="w-full relative">
            <div
              className={`${
                isUpdate ? 'w-[91%]' : 'w-full'
              } flex flex-col ll:flex-row gap-6`}
            >
              <div className="w-6/12 ll:w-[450px]">
                <TextInput
                  type="text"
                  value={env.key}
                  onChange={(e) => fillEnvKey(e as string, i)}
                  placeholder="Key"
                  error={false}
                  helpText=""
                />
              </div>
              <div className="w-6/12 ll:w-[450px]">
                <TextInput
                  type={secretType}
                  value={env.value}
                  onChange={(e) => fillEnvValue(e as string, i)}
                  placeholder="Value"
                  error={false}
                  helpText=""
                />
              </div>
            </div>
            <span
              role="presentation"
              onClick={() => removeBuildEnvItem(i)}
              className="cursor-pointer absolute top-12 ll:top-6 ll:left-auto left-[55%] ll:right-4"
            >
              <CrossIcon
                className="text-text-darkGray ml-4
            hover:opacity-70 ease-in duration-75"
              />
            </span>
          </div>
        </>
      ))}
    </div>
  );
};

export default AddInstanceEnv;
