import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ICreditCard,
  PaymentMethod,
} from '../../redux/compute/combined-state.interface';
import { IOrganisationWallet } from '../../redux/compute/web3/web3.interfaces';
import { RootState } from '../../redux/compute/store';
import { IOrgWallet } from '../../redux/compute/organisation/organisation.interfaces';

export const usePaymentMethod = (paymentMethodId: string | null) => {
  const web3Wallets = useSelector((state: RootState) => state.web3.wallets);

  const creditCards = useSelector(
    (state: RootState) => state.stripe.creditCards
  );

  const spheronWallet = useSelector(
    (state: RootState) => state.organisation.spheronWallet
  );

  const [tempPaymentMethod, setTempPaymentMethod] = useState<
    ICreditCard | IOrganisationWallet | IOrgWallet | null
  >(null);

  const [paymentMethodType, setPaymentMethodType] =
    useState<PaymentMethod | null>(null);

  useEffect(() => {
    if (paymentMethodId) {
      const creditCard = creditCards.find(
        (creditCard) => creditCard._id === paymentMethodId
      );
      const web3Wallet = web3Wallets.find(
        (wallet) => wallet._id === paymentMethodId
      );
      if (creditCard) {
        setPaymentMethodType(PaymentMethod.CREDITCARD);
        setTempPaymentMethod(creditCard);
      } else if (web3Wallet) {
        setPaymentMethodType(PaymentMethod.CRYPTO);
        setTempPaymentMethod(web3Wallet);
      } else if (spheronWallet?._id === paymentMethodId) {
        setPaymentMethodType(PaymentMethod.SPHERONWALLET);
        setTempPaymentMethod(spheronWallet);
      } else {
        setPaymentMethodType(null);
        setTempPaymentMethod(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditCards, paymentMethodId, spheronWallet, web3Wallets.length]);

  return { tempPaymentMethod, paymentMethodType };
};
