import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InstanceScalingCard, {
  ScalingCardTag,
} from '../../../../../components/Compute/Cards/instance-scaling-card';
import { RootState } from '../../../../../redux/compute/store';
import ScalingLoading from '../../../../../components/Compute/Loaders/scaling-loading';
import ComputeDisclaimer from '../../../../../components/Cards/compute-disclaimer';
import {
  resetPersistentValuesRtk,
  setScalingNameRtk,
  setScalingTypeRtk,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.slice';
import {
  InstanceDeploymentType,
  ScalingType,
  ScalingTypeName,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.interfaces';
// eslint-disable-next-line max-len
import { isAccelerateDeployment } from '../../../../../redux/compute/instance/instance-creation/instance-creation.utils';

const SelectScaling = () => {
  const dispatchRtk = useDispatch();
  const selectedOrganisationLoading = useSelector(
    (state: RootState) => state.organisation.selectedOrganisationLoading
  );
  const activeBonusesLoading = useSelector(
    (state: RootState) => state.subscription.activeBonusLoading
  );
  const selectedScalingType = useSelector(
    (state: RootState) => state.instanceCreation.clusterScalingName
  );

  const isCustomPlanOpen = useSelector(
    (state: RootState) => state.instance.isCustomPlanOpen
  );

  const deploymentType = useSelector(
    (state: RootState) => state.instanceCreation.deploymentType
  );

  const instancePlanLoading = useSelector(
    (state: RootState) => state.instanceCreation.instancePlanLoading
  );

  const scalingCards = [
    {
      id: 1,
      type: ScalingType.MANUAL,
      cardTitle: 'On Demand 🚀',
      cardDescription: 'Resources can be manually scaled.',
      // eslint-disable-next-line no-nested-ternary
      cardTag:
        deploymentType === InstanceDeploymentType.ACCELERATE
          ? 'Coming Soon'
          : 'recommended',
      link: 'https://docs.spheron.network/compute/type/on-demand/',
      linkText: 'Learn more',
      isSelected: selectedScalingType === ScalingTypeName.MANUAL,
      handleClick: () => {
        dispatchRtk(setScalingNameRtk(ScalingTypeName.MANUAL));
        dispatchRtk(setScalingTypeRtk(ScalingType.MANUAL));
        dispatchRtk(resetPersistentValuesRtk());
      },
      disabled: isCustomPlanOpen || instancePlanLoading,
    },
    {
      id: 2,
      type: ScalingType.AUTO,
      cardTitle: 'Autoscale 🤖',
      cardDescription: 'Resources are automatically scaled.',
      // eslint-disable-next-line no-nested-ternary
      cardTag:
        deploymentType === InstanceDeploymentType.ACCELERATE
          ? 'Coming Soon'
          : 'recommended',
      link: 'https://docs.spheron.network/compute/type/autoscale/',
      linkText: 'Learn more',
      isSelected: selectedScalingType === ScalingTypeName.AUTO,
      handleClick: () => {
        dispatchRtk(setScalingNameRtk(ScalingTypeName.AUTO));
        dispatchRtk(setScalingTypeRtk(ScalingType.MANUAL));
        dispatchRtk(resetPersistentValuesRtk());
      },
      disabled: isCustomPlanOpen || instancePlanLoading,
    },
    {
      id: 3,
      type: ScalingType.NO,
      cardTitle: 'Spot 😢',
      cardDescription: `Resources can't be scaled after the deployment.`,
      cardTag: '',
      link: 'https://docs.spheron.network/compute/type/spot/',
      linkText: 'Learn more',
      isSelected: selectedScalingType === ScalingTypeName.NO,
      handleClick: () => {
        dispatchRtk(setScalingNameRtk(ScalingTypeName.NO));
        dispatchRtk(setScalingTypeRtk(ScalingType.NO));
        dispatchRtk(resetPersistentValuesRtk());
      },
      // TODO: Will be handled using bonus flag
      disabled: isAccelerateDeployment(deploymentType)
        ? isCustomPlanOpen || instancePlanLoading
        : true,
      tooltipMessage:
        'Please contact our team to enable spot instance deployments',
    },
  ];

  return (
    <div>
      <div className="flex flex-col items-start gap-y-6">
        <div className="flex items-center justify-start">
          <h3
            className="text-base-heading-text-color dark:text-dark-base-heading-text-color
            font-bold text-xl"
          >
            2. Compute Type
          </h3>
          <span className=" font-normal text-sm ml-3">
            (Scale Hardware Resources)
          </span>
        </div>
        <div className="xl:w-9/12 grid grid-cols-3 gap-x-6">
          {activeBonusesLoading || selectedOrganisationLoading ? (
            Array.from(Array(3).keys()).map((key) => (
              <ScalingLoading key={key} />
            ))
          ) : (
            <>
              {scalingCards.map((card) => (
                <InstanceScalingCard
                  key={card.id}
                  type={card.type}
                  link={card.link}
                  linkText={card.linkText}
                  cardTitle={card.cardTitle}
                  cardDescription={card.cardDescription}
                  cardTag={card.cardTag as ScalingCardTag}
                  isSelected={card.isSelected}
                  handleClick={card.handleClick}
                  disabled={card.disabled}
                  tooltipMessage={card.tooltipMessage || ''}
                />
              ))}
            </>
          )}
        </div>
        {selectedScalingType === ScalingTypeName.NO &&
          !(activeBonusesLoading || selectedOrganisationLoading) && (
            <div className="w-9/12">
              <ComputeDisclaimer
                link="https://docs.spheron.network/compute/type/spot/"
                text="
Spot compute isn't covered by the Spheron SLA because it's managed by the Akash network, not us. 
We can't guarantee its continuous uptime. While rare, downtimes may occur. 
Avoid using spot compute for production or sensitive data.
 For production, use on-demand or autoscale compute."
              />
            </div>
          )}
      </div>
    </div>
  );
};

export default SelectScaling;
