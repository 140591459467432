/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as StorageIcon } from '@spheron/ui-library/dist/assets/spheron-specific/storage.svg';
import { ReactComponent as ComputeIcon } from '@spheron/ui-library/dist/assets/spheron-specific/compute.svg';
import { ReactComponent as WebappIcon } from '@spheron/ui-library/dist/assets/upload.svg';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as SpheronLogo } from '../../../../assets/compute/global/spheron-logo.svg';
import BlueIconContainer from '../../../../components/Misc/blue-icon-container';
import GlobalStyle from '../../../../styles/global.module.scss';
import { ICurrentApp } from '../../../../redux/organisation/organisation.interfaces';
import ChooseAppCard from '../../../../components/Cards/choose-app-card';
import { switchCurrentAppThunk } from '../../../../redux/organisation/organisation.thunks';
import { AppDispatch, RootState } from '../../../../redux/compute/store';

interface IPlatformItem {
  id: number;
  heading: string;
  description: string;
  icon: JSX.Element;
  handleClick: () => void;
  newTag: boolean;
}

const ChooseApp = () => {
  const navigate = useNavigate();
  const dispatchRtk = useDispatch<AppDispatch>();

  const organizations = useSelector(
    (state: RootState) => state.user.user?.organizations
  );

  const handleAppPush = (appType: ICurrentApp) => {
    navigate(`/create-new-organisation`);
    dispatchRtk(switchCurrentAppThunk(appType));
  };

  const platforms: IPlatformItem[] = [
    {
      id: 1,
      heading: 'Static Site',
      description:
        'Select web platform to deploy your static site to the decentralized cloud at scale.',
      icon: (
        <WebappIcon className="w-5 h-5 text-base-icon dark:text-dark-base-icon" />
      ),
      handleClick: () => handleAppPush(ICurrentApp.APP),
      newTag: false,
    },
    {
      id: 2,
      heading: 'Compute',
      description:
        // eslint-disable-next-line max-len
        'Select compute platform to deploy and manage your server on the decentralized network at scale.',
      icon: (
        <ComputeIcon className="w-5 h-5 text-base-icon dark:text-dark-base-icon" />
      ),
      handleClick: () => handleAppPush(ICurrentApp.COMPUTE),
      newTag: false,
    },
    {
      id: 3,
      heading: 'Storage',
      description:
        // eslint-disable-next-line max-len
        'Select storage platform to upload, store, pin, & fetch files from IPFS in an easy to use and performant way',
      icon: (
        <StorageIcon className="w-5 h-5 text-base-icon dark:text-dark-base-icon" />
      ),
      handleClick: () => handleAppPush(ICurrentApp.STORAGE),
      newTag: true,
    },
  ];

  useEffect(() => {
    if (organizations?.length !== 0) {
      navigate(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="absolute -z-10 dark:from-dark-feedback-default-bg dark:to-dark-feedback-default-bg 
    bg-gradient-to-b from-globalBlueBg to-white w-full h-full"
    >
      <div className={`${GlobalStyle.nav__fix}`}>
        <div className="flex flex-col items-center justify-center mt-20">
          <BlueIconContainer
            className="p-2.5 bg-bg-lightIconContainerBg w-14 h-14"
            iconElement={<SpheronLogo className="h-14 w-14" />}
          />
          <div className="mt-4">
            <h1
              className="text-center font-bold text-2xl text-text-onboardingHeading
           leading-10"
            >
              Welcome to Spheron
            </h1>
            <div
              className="mt-3 text-sm font-normal text-text-onboardingText
           dark:text-dark-gray-text"
            >
              Please choose services and start deploying your dApps
            </div>
          </div>
          <div
            className="grid grid-cols-1 md:grid-cols-2 gap-y-5 gap-x-7 
        mt-11 w-10/12 mx-auto max-w-2xl"
          >
            {platforms.map((platform) => (
              <ChooseAppCard
                key={platform.id}
                heading={platform.heading}
                description={platform.description}
                newTag={platform.newTag}
                handleClick={platform.handleClick}
                icon={platform.icon}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseApp;
