import React, { useState, useRef } from 'react';
import { Dropdown, OptionType, Badge } from '@spheron/ui-library';
import Skeleton from 'react-loading-skeleton';
import { ReactComponent as Chevron } from '@spheron/ui-library/dist/assets/arrow-right-3.svg';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as TripleDot } from '../../../assets/compute/icons/triple-light-dot.svg';
import { withLoader } from '../../../redux/compute/root-utils';
import SelectPaymentMethodCard from '../Dropdown/select-payment-method-card';
import { updateDefaultPaymentThunk } from '../../../redux/compute/organisation/organisation.thunks';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import { removeCreditCardThunk } from '../../../redux/compute/stripe/stripe.thunks';
import { RootState, AppDispatch } from '../../../redux/compute/store';
import { getPaymentLogo } from '../../../redux/compute/stripe/stripe.utils';
import UseOnClickOutside from '../../../hooks/useOnClickOutside';

interface IProps {
  id: string;
  cardType: string;
  cardNumber: string;
  expiry: string;
  isPrimary: boolean;
  loading: boolean;
  showDropdown: boolean;
  disabled: boolean;
}

const CardInfo = ({
  id,
  cardType,
  cardNumber,
  expiry,
  isPrimary,
  loading,
  showDropdown,
  disabled,
}: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();
  const selectedOrganisationId = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation._id
  );

  const [showSwitchDropdown, setShowSwitchDropdown] = useState<boolean>(false);

  const dropdownRef = useRef(null);

  UseOnClickOutside(dropdownRef, () => setShowSwitchDropdown(false));

  const dropdownOptions = [
    {
      id: 2,
      label: 'Remove',
      handleClick: () =>
        dispatchRtk(
          toggleModalShowRtk({
            modalShow: true,
            modalType: 'deleteResourceConfirm',
            options: {
              resource: 'Credit Card',
              handleClick: () =>
                dispatchRtk(
                  removeCreditCardThunk({
                    id,
                    organizationId: selectedOrganisationId,
                  })
                ),
            },
          })
        ),
      optionType: 'primary' as OptionType,
    },
  ];

  const dropdownOptionWithDisable = !isPrimary
    ? [
        ...dropdownOptions,
        {
          id: 5,
          label: 'Make as Primary',
          handleClick: () =>
            dispatchRtk(
              updateDefaultPaymentThunk({
                organisationId: selectedOrganisationId,
                newPaymentMethodId: id,
              })
            ),
          optionType: 'primary' as OptionType,
        },
      ]
    : dropdownOptions;

  return (
    <div
      className={`p-3 border border-base-border rounded-lg dark:border-dark-base-border
      min-w-325 flex flex-col h-full ${
        disabled
          ? `bg-action-primary-disable dark:bg-dark-action-primary-disable
        text-action-primary-text-disable dark:text-dark-action-primary-text-disable
        cursor-not-allowed`
          : 'dark:bg-dark-base-bg'
      }`}
    >
      <div className="flex justify-between items-start">
        <div className="flex items-start justify-start space-x-3">
          {withLoader(
            loading,
            <Skeleton duration={2} height={28} width={40} />,
            <img
              src={getPaymentLogo(cardType)}
              alt="Visa icon"
              className="w-9 h-6"
            />
          )}

          <div className="flex flex-col">
            <div className="flex flex-col ">
              <h3
                className="text-base text-base-heading-text-color
                dark:text-dark-base-heading-text-color tracking-tight font-semibold leading-6"
              >
                {withLoader(
                  loading,
                  <Skeleton duration={2} height={24} width={120} />,
                  <div className="flex items-center justify-start -mt-1 pb-1">
                    <span className="capitalize">{cardType} </span> ****{' '}
                    {cardNumber}{' '}
                    {showDropdown && !disabled && (
                      <div ref={dropdownRef} className="relative">
                        <div
                          role="presentation"
                          className="ml-4 relative flex items-center justify-between
                       bg-base-fg text-action-primary-default text-xs font-semibold px-1.5
                       py-0.5 rounded gap-x-1 cursor-pointer"
                          onClick={() => {
                            setShowSwitchDropdown(!showSwitchDropdown);
                          }}
                        >
                          Change wallet
                          <Chevron
                            className={`transform cursor-pointer w-5 h-5
                       text-action-primary-default hover:text-action-primary-default 
                        dark:hover: ${
                          showSwitchDropdown ? '-rotate-90' : 'rotate-90'
                        }`}
                          />
                        </div>
                        {showSwitchDropdown && (
                          <div className="absolute top-[60%] left-3/4">
                            <SelectPaymentMethodCard
                              setShowDropdown={setShowSwitchDropdown}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </h3>
              <h5 className="text-sm ">
                {withLoader(
                  loading,
                  <Skeleton duration={2} height={14} width={80} />,
                  <div className="flex items-center justify-between">
                    Expiry {expiry}{' '}
                  </div>
                )}
              </h5>
            </div>
          </div>
        </div>
        {withLoader(
          loading,
          <Skeleton duration={2} height={24} width={24} />,
          <>
            {showDropdown ? (
              <></>
            ) : (
              <div className="-mt-3 -mr-4">
                <Dropdown
                  dropdownSize="compact"
                  dropdownType="button"
                  buttonImage={<TripleDot />}
                  filled
                  options={disabled ? [] : dropdownOptionWithDisable}
                  bordersNone
                />
              </div>
            )}
          </>
        )}{' '}
      </div>{' '}
      <div className="flex items-end justify-end w-full h-full -mb-1">
        {withLoader(
          loading,
          <Skeleton duration={2} height={20} width={80} />,
          <Badge
            badgeType={isPrimary ? 'info' : 'default'}
            solid={false}
            isBold
            size="large"
            text={isPrimary ? 'Primary' : 'Backup'}
          />
        )}
      </div>
    </div>
  );
};

export default CardInfo;
