import React from 'react';
import BlueIconContainer from '../Misc/blue-icon-container';
import { ReactComponent as ChevronRight } from '../../assets/compute/icons/right-icon.svg';

interface IProps {
  heading: string;
  description: string;
  newTag: boolean;
  handleClick: () => void;
  icon: JSX.Element;
}

const ChooseAppCard = ({
  heading,
  description,
  newTag,
  handleClick,
  icon,
}: IProps) => {
  return (
    <div
      className={`rounded-lg pb-6 pt-7 pl-6 pr-10 cursor-pointer 
      relative hover:shadow-sm dark:border-dark-base-border dark:bg-dark-base-fg
     dark:hover:border-transparent`}
      onClick={handleClick}
      role="presentation"
    >
      <div className="flex items-center justify-start space-x-3">
        <BlueIconContainer
          className="p-1.5 bg-bg-lightBlueInfoBg text-action-primary-default"
          iconElement={icon}
        />
        <h3 className="font-semibold text-sm">{heading}</h3>
        {newTag && (
          <div
            className="text-white bg-bg-onboardingBlue 
           py-1 px-1.5 text-xs font-medium rounded-md"
          >
            New
          </div>
        )}
      </div>
      <div className="mt-4">
        <p
          className="text-left text-xs font-normal leading-5
         dark:text-dark-gray-text"
        >
          {description}
        </p>
      </div>
      <div className="mt-4 absolute bottom-5 left-6 w-8/12">
        <button
          type="button"
          onClick={handleClick}
          className="py-1 px-1.5 pr-3
          flex bg-base-fg dark:bg-opacity-100  
          items-center justify-between
          rounded-3xl text-xs font-semibold w-full dark:hover:bg-opacity-60"
        >
          <div className="flex items-center justify-start">
            <BlueIconContainer
              className="p-1.5 dark:bg-dark-feedback-error-bg bg-feedback-error-bg
              mr-1.5"
              iconElement={icon}
            />
            Start with {heading}
          </div>
          <ChevronRight className="text-white" />
        </button>
      </div>
    </div>
  );
};

export default ChooseAppCard;
