import React from 'react';

interface ITab {
  openTab: number;
  text: string;
}

interface IProps {
  tabs: ITab[];
  openTab: number;
  // eslint-disable-next-line no-unused-vars
  setOpenTab: (openTab: number) => void;
  disable: boolean;
}

const TabComponent = ({ tabs, openTab, setOpenTab, disable }: IProps) => {
  const handleToggleTab = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    tab: number
  ) => {
    e.preventDefault();
    setOpenTab(tab);
  };

  return (
    <div className="flex mt-10 justify-center">
      {tabs.map((tab, index) => (
        <span
          className={`flex flex-col  ${
            disable ? 'cursor-not-allowed' : 'cursor-pointer'
          }`}
          key={tab.openTab}
        >
          <span
            className={`pb-2 min-w-48 flex justify-center uppercase 
            text-base font-medium leading-4 ${
              disable ? 'pointer-events-none' : 'pointer-events-auto'
            } ${
              tab.openTab === openTab
                ? 'text-base-para-text-color dark:text-dark-base-para-text-color'
                : 'text-base-sub-text-color dark:text-dark-base-sub-text-color'
            } ${index > 0 ? 'ml-14' : ''}`}
            role="presentation"
            onClick={(e) => handleToggleTab(e, tab.openTab)}
          >
            {tab.text}
          </span>
          <span
            className={`h-1 w-full inline-block rounded-3xl ${
              index > 0 ? 'ml-14' : ''
            } ${
              tab.openTab === openTab ? 'bg-form-selected' : 'bg-transparent'
            } `}
            style={{
              width: `${tab.text.length * 12}px`,
            }}
          />
        </span>
      ))}
    </div>
  );
};

export default TabComponent;
