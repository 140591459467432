import React, { Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ActivityCard,
  ActivityStickyCard,
  ActivityStickyCardStatus,
  EmptyState,
  SectionHeading,
} from '@spheron/ui-library';
import { Disclosure } from '@headlessui/react';
import Skeleton from 'react-loading-skeleton';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { ReactComponent as DockerCredentialIcon } from '../../../assets/compute/icons/docker-credential-icon.svg';
import { RootState } from '../../../redux/compute/store';
import { sliceStringFromEnd } from '../../../redux/compute/subscription/subscription.utils';
import { withLoader } from '../../../redux/compute/root-utils';

const InstanceActivity = () => {
  const navigate = useNavigate();
  const params = useParams<{ projectId: string; instanceId: string }>();
  const activeDeployment = useSelector(
    (state: RootState) => state.instance.activeDeployment
  );
  const activeDeploymentLoading = useSelector(
    (state: RootState) => state.instance.activeDeploymentLoading
  );

  const selectedInstanceActivity = useSelector(
    (state: RootState) => state.instance.selectedInstanceActivity
  );

  const selectedInstanceActivityLoading = useSelector(
    (state: RootState) => state.instance.selectedInstanceActivityLoading
  );

  const updatedSelectedInstanceActivity = [...selectedInstanceActivity];
  const sortedInstanceActivity = updatedSelectedInstanceActivity?.sort(
    (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
  );
  const { projectId, instanceId } = params;
  return (
    <div className="flex flex-col gap-y-5">
      <div className="flex flex-col gap-y-2">
        <SectionHeading
          showRefresh={false}
          showSwitch={false}
          subHeading={''}
          heading="Recent Activity"
          handleIconClick={() => {}}
          handleRefreshClick={() => {}}
          handleSwitchClick={() => {}}
          switchDisable={false}
          handleClick={() => {}}
          loading={false}
          time={''}
          refreshType={'default'}
          showText={false}
          toggleId={''}
          isChecked={false}
          handleChange={() => {}}
        />
      </div>
      <div className="flex flex-col gap-y-4 relative">
        {selectedInstanceActivity?.length > 0 && (
          <div
            className="w-[1.5px] bg-base-border dark:bg-dark-base-border
          absolute mt-4.5 left-0 transform translate-x-[5px]"
            style={{ height: 'calc(100% - 38px)' }}
          />
        )}

        {withLoader(
          activeDeploymentLoading || selectedInstanceActivityLoading,
          <div className="flex items-center justify-start gap-x-3">
            <span
              className={`sticky z-30 dark:bg-dark-base-border
                     border-2 bg-base-bg border-base-border 
                      dark:border-dark-base-border w-3 h-3 flex rounded-xl`}
            />

            <div className="w-full">
              <Skeleton height={42} duration={2} containerClassName="w-full" />
            </div>
          </div>,
          <>
            {sortedInstanceActivity?.length > 0 ? (
              <>
                {' '}
                {sortedInstanceActivity?.map((activity) => {
                  const iconsSet = new Set<string>();
                  activity.services.forEach((service) => {
                    if (
                      activeDeployment?.services.find(
                        (activeService) =>
                          activeService.name?.toLowerCase() ===
                          service.name?.toLowerCase()
                      )?.template
                    ) {
                      iconsSet.add(
                        activeDeployment?.services.find(
                          (activeService) =>
                            activeService.name?.toLowerCase() ===
                            service.name?.toLowerCase()
                        )?.template?.metadata.icon || ''
                      );
                    } else {
                      iconsSet.add('docker');
                    }
                  });

                  return (
                    <Fragment key={activity._id}>
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <div className="flex items-center justify-start gap-x-3">
                              <span
                                className={`sticky z-30 ${
                                  open
                                    ? 'bg-action-neutral-default dark:bg-dark-action-neutral-default'
                                    : 'bg-base-bg dark:bg-dark-base-border'
                                }
                     border-2 bg-base-bg border-base-border 
                      dark:border-dark-base-border w-3 h-3 flex rounded-xl`}
                              />

                              <Disclosure.Button className="w-full">
                                <ActivityStickyCard
                                  status={
                                    activity.status as ActivityStickyCardStatus
                                  }
                                  timestamp={dayjs(
                                    String(activity.createdAt)
                                  ).format('MMM DD, YYYY hh:mm a')}
                                  services={activity.services.map(
                                    (service) => service.name
                                  )}
                                  isOpen={open}
                                  onClick={() => {}}
                                  icons={Array.from(iconsSet).map((icon) => {
                                    if (icon !== 'docker') {
                                      return (
                                        <img
                                          src={icon}
                                          alt="service"
                                          className="max-h-6 max-w-6 rounded-full"
                                        />
                                      );
                                    }
                                    return (
                                      <DockerCredentialIcon className="rounded-full" />
                                    );
                                  })}
                                  id={''}
                                />
                              </Disclosure.Button>
                            </div>

                            <Disclosure.Panel className="ml-1.5 flex flex-col gap-y-4">
                              {activity.services.map((service) => (
                                <div
                                  className="flex items-center justify-start gap-x-3"
                                  key={service.name}
                                >
                                  <div
                                    className="w-6 h-0.5 bg-base-border
                       dark:bg-dark-base-border rounded-full"
                                  />
                                  <ActivityCard
                                    name={service.name}
                                    imageTag={service.tag}
                                    cpu={Number(service.agreedMachineImage.cpu)}
                                    ram={Number(
                                      sliceStringFromEnd(
                                        service.agreedMachineImage.memory,
                                        2
                                      )
                                    )}
                                    storage={Number(
                                      sliceStringFromEnd(
                                        service.agreedMachineImage.storage,
                                        2
                                      )
                                    )}
                                    replica={1}
                                    onClick={() =>
                                      navigate(
                                        // eslint-disable-next-line max-len
                                        `/compute/${projectId}/${instanceId}/${activity._id}/${service.name}/activity-details`
                                      )
                                    }
                                    id={''}
                                  />
                                </div>
                              ))}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </Fragment>
                  );
                })}
              </>
            ) : (
              <div className="h-72">
                <EmptyState
                  entityTitle="No instance activity"
                  showButton={false}
                  buttonDisabled={false}
                  onClick={() => {}}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default InstanceActivity;
