// eslint-disable-next-line import/no-cycle
import { PaymentMethod } from '../combined-state.interface';
// eslint-disable-next-line import/no-cycle
import { ISelectedOrganisationWalletConfig } from '../organisation/organisation.interfaces';

export interface INetworkType {
  name?: string;
  chainId: string;
  chainName: string;
  nativeCurrency?: { name: string; symbol: string; decimals: number };
  rpcUrls: string[];
  blockExplorerUrls: string[];
  logo?: any;
  networkId?: number;
}

export interface ITokenType {
  id: number;
  name: string;
  symbol: string;
  decimals: number;
  address: string;
  logoURI: string;
}

export interface IAvailableToken {
  id: number;
  name: string;
  symbol: string;
  decimals: number;
  logoURI: string;
}

export interface IUpdaterPayload {
  registry: string;
  address: string;
  type: string;
}

export interface IAddDomainRegistryPayload {
  registry: string;
  domain: string;
  siteLink: string;
  contentHash: string;
}

export interface IWeb3State {
  currentAccount: string;
  selectedNetwork: INetworkType | null;
  selectedToken: ITokenType | null;
  selectedWallet: string;
  selectedTokenBalance: number | null;
  selectedTokenAllowance: number;
  signature: string;
  walletLoading: boolean;
  escrowBalanceLoading: boolean;
  tokenBalanceLoaded: boolean;
  tokenAllowanceLoading: boolean;
  tokenAllowanceLoaded: boolean;
  switchNetworkLoading: boolean;
  approveLoading: boolean;
  connectWalletLoading: boolean;
  disconnectWalletLoading: boolean;
  ensContentHashLoading: boolean;
  toggleTokenLoading: boolean;
  paymentHistory: IPaymentHistory;
  balance: IBalance;
  transactionOngoing: boolean;
  toggleWalletApprovalLoaded: boolean;
  paymentHistoryLoading: boolean;
  createRegistryLoading: boolean;
  addDomainRegistryLoading: boolean;
  addRegistryUpdaterLoading: boolean;
  removeRegistryUpdaterLoading: boolean;
  wallets: IOrganisationWallet[];
  removeWalletsLoading: string[];
  walletBalanceLoading: string[];
  signatureLoading: boolean;
  selectedTokenAvailableBalanceLoading: boolean;
  selectedTokenAvailableBalance: number | null;
  hasDeposited: boolean;
  maxDepositedWallet: {
    balance: string;
    network: INetworkType;
    token: ITokenType;
  } | null;
}

export interface IOrganisationWallet {
  connectionState: string;
  primary: boolean;
  _id: string;
  paymentMethod: PaymentMethod;
  details: {
    address: string;
    networkDetails: INetworkType;
    tokenDetails: ITokenType;
    signature: string;
  };
  organizationId: string;
  createdAt: string;
  updatedAt: string;
  balance: IBalance;
}

export interface IWalletNetworkDetails {
  chainId: number;
  name: string;
}

export interface IWalletTokenDetails {
  name: string;
  address: string;
  decimals: number;
}

export interface ITransaction {
  wallet: ISelectedOrganisationWalletConfig;
  amount: string;
  createdAt: string;
  id: string;
  token: string;
}

export interface IBalance {
  amount: string;
  token: string;
  address?: string;
}

export interface IPaymentHistory {
  deposits: ITransaction[];
  withdrawals: ITransaction[];
}

export interface IPaymentHistoryDto {
  deposit: ITransaction[];
  withdraw: ITransaction[];
}

export interface IError {
  message: string;
  data: {
    message: string;
  };
}

export interface IGetBalance {
  address: string;
  token: string | ITokenType;
  chainId: number | INetworkType;
}

export interface IConfigureWalletPayloadDto {
  selectedNetwork: INetworkType;
  selectedToken: ITokenType;
  selectedWallet: string;
}

export interface IConfigureWalletPayloadDtoRtk {
  selectedNetwork: INetworkType;
  selectedToken: ITokenType;
  selectedWallet: string;
  selectedOrganisationId: string;
}

export interface IWalletConfiguredPayloadDto {
  signature: string;
  tokenBalance: number;
  walletAddress: string;
}

export enum IPaymentHistoryEnum {
  // eslint-disable-next-line no-unused-vars
  DEPOSIT = 'deposit',
  // eslint-disable-next-line no-unused-vars
  WITHDRAW = 'withdraw',
}

export interface IGetPaymentHistoryPayloadDto {
  address: string;
  chainId: number;
}

export interface IUpdateWalletBalance {
  id: string;
  balance: IBalance;
}
// eslint-disable-next-line no-unused-vars
export enum NonSubgraphsChainName {
  // eslint-disable-next-line no-unused-vars
  FILECOIN = 'filecoin',
  // eslint-disable-next-line no-unused-vars
  MANTLE = 'mantle',
  // eslint-disable-next-line no-unused-vars
  SOLANA = 'solana',
  // eslint-disable-next-line no-unused-vars
  LINEA = 'linea',
}
