import React, { Dispatch, SetStateAction, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CreditCardPayment } from '../../../assets/compute/icons/credit-card.svg';
import { ReactComponent as Web3PaymentIcon } from '../../../assets/compute/icons/web3wallet.svg';
import { ReactComponent as CaretIcon } from '../../../assets/compute/icons/right-icon.svg';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { PaymentMethod } from '../../../redux/combined-state.interface';
import { addCreditCardThunk } from '../../../redux/stripe/stripe.thunks';
import { toggleModalShowRtk } from '../../../redux/modal/modal.slice';
import { toggleAddingPaymentMethodRtk } from '../../../redux/organisation/organisation.slice';
import { RootState } from '../../../redux/rtk-store';
import { ICurrentApp } from '../../../redux/organisation/organisation.interfaces';
import { AppDispatch } from '../../../redux/compute/store';

interface IProps {
  setShowAddPaymentMethod: Dispatch<SetStateAction<boolean>>;
}

const AddPaymentMethod = ({ setShowAddPaymentMethod }: IProps) => {
  const isPublicAccessible =
    sessionStorage.getItem('isPublicAccessible') === 'true';
  const communityUserAccess =
    sessionStorage.getItem('communityUserAccess') === 'true';

  const organisationId = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation._id
  );
  const currentApp = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation.appType
  );
  const dispatchRtk = useDispatch<AppDispatch>();
  const dropdownRef = useRef(null);

  const paymentMethods = [
    ...(!isPublicAccessible || communityUserAccess
      ? [
          {
            id: 1,
            title: 'Credit / Debit Card',
            icon: (
              <>
                <CreditCardPayment />
              </>
            ),
            handleClick: () => {
              setShowAddPaymentMethod(false);
              dispatchRtk(toggleAddingPaymentMethodRtk(true));
              dispatchRtk(
                addCreditCardThunk({
                  organizationId: organisationId,
                  details: {
                    cardPaymentMethodType: 'card',
                    cardService: 'stripe',
                  },
                  paymentMethod: PaymentMethod.CREDITCARD,
                })
              );
            },
          },
        ]
      : []),
    //  Disabled Web3 Payment for Compute Services
    ...(currentApp === ICurrentApp.COMPUTE
      ? []
      : [
          {
            id: 2,
            title: 'Web3 Payment',
            icon: (
              <>
                <Web3PaymentIcon className="dark:text-white" />
              </>
            ),
            handleClick: () => {
              setShowAddPaymentMethod(false);
              dispatchRtk(
                toggleModalShowRtk({
                  modalType: 'connectWalletParameters',
                  modalShow: true,
                })
              );
            },
          },
        ]),
  ];

  useOnClickOutside(dropdownRef, () => {
    setShowAddPaymentMethod(false);
  });

  return (
    <div
      className="p-4.5 rounded-lg dark:bg-dark-base-bg dark:border-dark-base-border
       bg-white border border-base-border shadow-sm"
      ref={dropdownRef}
    >
      <div className="flex flex-col items-center justify-center space-y-4.5">
        {paymentMethods.map((paymentMethod) => (
          <div
            role="presentation"
            onClick={paymentMethod.handleClick}
            key={paymentMethod.id}
            className="p-2 border dark:border-dark-base-border
             border-base-border rounded-lg flex items-center justify-between
             min-w-300 hover:bg-cards-hover cursor-pointer transition ease-in-out
            dark:bg-dark-base-bg dark:hover:bg-dark-base-fg"
          >
            <div className="flex items-center justify-start space-x-3">
              {paymentMethod.icon}
              <div
                className="text-base-heading-text-color dark:text-dark-base-heading-text-color
                font-semibold text-base"
              >
                {paymentMethod.title}
              </div>
            </div>
            <CaretIcon
              className="text-base-heading-text-color
              dark:text-dark-base-heading-text-color"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AddPaymentMethod;
