import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BreadcrumbsBar,
  Button,
  SectionHeading,
  TextInput,
} from '@spheron/ui-library';
import { withLoader } from '../../../redux/compute/root-utils';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import { ReactComponent as SearchIcon } from '../../../assets/compute/icons/search.svg';
import ComputeDashboardBreadcrumbLoading from '../Loaders/compute-dashboard-breadcrumb-loading';
import { resetComputeProjectPaginationState } from '../../../redux/compute/project/project.slice';
import { getAllComputeProjectsThunk } from '../../../redux/compute/project/project.thunks';

interface IProps {
  searchValue: string;
  // eslint-disable-next-line no-unused-vars
  handleSearch: (value: string) => void;
  handleCreateNewProject: () => void;
}

const ProjectDashboardBreadcrumbs = ({
  searchValue,
  handleSearch,
  handleCreateNewProject,
}: IProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const selectedOrganisation = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation
  );
  const projectsLoading = useSelector(
    (state: RootState) => state.computeProject.projectsLoading
  );
  const selectedOrganisationLoading = useSelector(
    (state: RootState) => state.organisation.selectedOrganisationLoading
  );
  const projects = useSelector(
    (state: RootState) => state.computeProject.projects
  );
  const allProjectsCount = useSelector(
    (state: RootState) => state.computeProject.allProjectsCount
  );

  const handleRefresh = () => {
    dispatch(resetComputeProjectPaginationState());
    dispatch(
      getAllComputeProjectsThunk({
        organisationId: selectedOrganisation._id,
        topupReport: 'n',
      })
    ) as any;
  };
  return (
    <>
      {withLoader(
        projectsLoading || selectedOrganisationLoading,
        <ComputeDashboardBreadcrumbLoading isInstanceDashboard={false} />,
        <>
          {' '}
          {(!projectsLoading || projects.length > 0) && (
            <div className="bg-base-bg dark:bg-dark-base-bg">
              <div className="w-[95%] mx-auto max-w-[1400px]">
                <BreadcrumbsBar
                  links={[]}
                  pageHeading={
                    <SectionHeading
                      showRefresh
                      customText="Refresh"
                      showSwitch={false}
                      subHeading=""
                      heading={`Projects ${
                        !projectsLoading ? `(${allProjectsCount || 1})` : ''
                      }`}
                      handleIconClick={() => {}}
                      handleRefreshClick={handleRefresh}
                      handleSwitchClick={() => {}}
                      switchDisable={false}
                      handleClick={() => {}}
                      loading={projectsLoading}
                      time=""
                      refreshType="default"
                      showText
                      toggleId=""
                      isChecked={false}
                      handleChange={() => {}}
                    />
                  }
                  activeLink={window.location.pathname
                    .split('/')
                    .slice(0, 4)
                    .join('/')}
                  type="default"
                  actions={[
                    {
                      id: 1,
                      action: (
                        <div>
                          {projects.length > 0 && (
                            <TextInput
                              inputSize="compact"
                              leftIcon={
                                <SearchIcon
                                  className="text-base-para-text-color
                        dark:text-dark-base-para-text-color"
                                />
                              }
                              placeholder="Search"
                              value={searchValue}
                              onChange={(value) =>
                                handleSearch(value as string)
                              }
                            />
                          )}
                        </div>
                      ),
                    },
                    {
                      id: 3,
                      action: (
                        <div>
                          <Button
                            buttonType="primary"
                            size="medium"
                            label="Create New Project"
                            onClick={handleCreateNewProject}
                            disabled
                          />
                        </div>
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ProjectDashboardBreadcrumbs;
