/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
import { IClusterTemplate, IPorts } from '../cluster/cluster.interfaces';
import {
  ICdnRecords,
  IOrganization,
  ClusterInstanceDomain,
  IDiscount,
} from '../combined-state.interface';
import { HealthCheckStatus, IInstance } from '../project/project.interfaces';

export interface IInstanceState {
  deployingInstance: boolean;
  closingInstance: boolean;
  activeDeployment: IDeployment | null;
  activeDeploymentLoading: boolean;
  selectedInstance: any | null;
  selectedInstanceParent: Partial<IInstance> | null;
  selectedInstanceLoading: boolean;
  selectedInstanceComputeLogs: string[];
  selectedInstanceComputeEvents: string[];
  selectedInstanceLogsLoading: boolean;
  selectedInstanceComputeLogsLoading: boolean;
  selectedInstanceComputeEventsLoading: boolean;
  selectedInstanceActivity: IActivityDetails[];
  selectedInstanceActivityLoading: boolean;
  selectedInstanceStreamLogs: IInstanceDeployLogs[];
  moreSelectedInstanceComputeLogsLoading: boolean;
  moreSelectedInstanceComputeEventsLoading: boolean;
  selectedInstanceComputeLogsPagination: {
    currentPage: number;
    total: number;
  };
  selectedInstanceComputeEventsPagination: {
    currentPage: number;
    total: number;
  };
  deleteInstanceLoading: boolean;
  successfullyDeployedInstances: IInstance[] | null;
  successfullyDeployedInstancesLoading: boolean;
  fetchInstanceEventServiceLoading: boolean;
  fetchInstanceHealthCheckLoading: boolean;
  fetchInstanceEventService: IFetchInstanceServiceResponse;
  domains: {
    instanceId: string;
    domains: IInstanceDomains[];
    subDomains: IInstanceDomains[];
  };
  domainsLoading: boolean;
  createDomainLoading: boolean;
  verifyDomainLoading: string[];
  deleteDomainLoading: string[];
  updateDomainLoading: string[];
  cdnRecords: { projectId: string; cdnRecord: ICdnRecords };
  healthCheckUpdateLoading: boolean;
  showLoadLogs: boolean;
  showInstanceUpdate: boolean;
  shellCommand: string;
  shellCommandLoading: boolean;
  shellCommandResponse: Array<string>;
  isCustomPlanOpen: boolean;
  metrics: IInstanceMetrics[];
  metricsLoading: boolean;
  updatingAutoScalingRules: boolean;
  metricsChart: IInstanceMetricsChart[];
  metricsChartLoading: boolean;
  shouldFetchInstanceDetails: boolean;
  shouldFetchDeploymentDetails: boolean;
  removeMultipleInstancesLoading: boolean;
  closeMultipleInstanceLoading: boolean;
  shouldFetchLogs: boolean;
  editNameLoading: boolean;
  closingInstanceId: string;
  fetchInstanceEventType: InstanceLogs | null;
}
export interface IInstanceConfiguration {
  _id: string;
  branch: string;
  folderName: string;
  protocol: string;
  image: string;
  instanceCount: number;
  buildImage: boolean;
  ports: { _id?: string; containerPort: string; exposedPort: string }[];
  agreedMachineImage: IAgreedMachineImage;
  env: {
    value: string;
    isSecret: boolean;
  }[];
  args: string[];
  command: string[];
  variables: {
    label: string;
    defaultValue: string;
    required: boolean;
    regionMap?: Record<string, string>;
  }[];
  tag: string;
  region: string;
}

export interface IAgreedMachineImage {
  _id?: string;
  cpu: number;
  memory: string;
  storage: string;
  machineType: number;
  maxPricePerBlock: number;
  defaultDailyTopUp: number;
  topupThreashold: number;
  price: number;
  persistentStorage: IPersistentStorage;
  machineId: string;
  gpu?: IGPU | IGPUSpecs;
}

export interface IAgreedGpuMachineImage extends IAgreedMachineImage {
  name: string;
}

export interface IAkashWallet extends Document {
  address: string;
  organizationId: IOrganization;
  creationStatus: string;
  encryptedMnemonic: string;
  createdCert: boolean;
  encryptedCertificate: string;
  lockedAkt: number;
}

export interface IInstanceLogs {
  log: string[];
  time: string;
  id?: string;
}
export interface IInstancesPagination {
  currentPage: number;
  numbersPerPage: number;
}

export const enum InstanceStatus {
  FAILED = 'Failed',
  DEPLOYED = 'Deployed',
  DEPRECATED = 'Deprecated',
  PENDING = 'Pending',
  QUEUED = 'Queued',
  DEPRECATED_PROVIDER = 'Deprecated-Provider',
  PROVISIONED = 'PROVISIONED',
}

export const enum InstanceLogs {
  LOGS = 'logs',
  COMPUTE_LOGS = 'instanceLogs',
  COMPUTE_EVENTS = 'instanceEvents',
  SHELL = 'shellCommands',
}

export const enum InstanceEvent {
  HEALTH_CHECK = 'instance-health-check',
  LOGS = 'fetch-logs',
  DOWNLOAD_LOGS = 'download-logs',
}

export interface IInstanceDeployment {
  projectId: string;
  clusterConfig: IClusterConfig;
  instanceDeployment: IInstanceDeploymentConfig;
}

export interface IClusterConfig {
  projectId?: string;
  branch: string | null;
  folderName: string;
  protocol: string;
  image: string | undefined;
  tag: string;
  instanceCount: number;
  buildImage: boolean;
  ports: IPorts[];
  akashMachineImageName: string | undefined;
  env: {
    value: string;
    isSecret: boolean;
  }[];
  command: string[];
  args: string[];
  healthCheckUrl?: string;
  healthCheckPort?: string;
  region: string;
  organizationId: string;
  customInstanceSpecs?: ICustomInstanceSpecs;
  scalable: boolean;
  privateImageConfig?: IPrivateImageConfig;
  autoScalingRules?: Partial<IAutoscalingRequestPayload>;
}

export interface ICustomInstanceSpecs {
  cpu?: number;
  memory?: string;
  storage: string;
  persistentStorage?: IPersistentStorage;
}

export interface IPersistentStorage {
  size: string;
  class: string;
  mountPoint: string;
}

export interface ICustomConfiguration {
  ports: IPorts[];
  env: {
    value: string;
    isSecret: boolean;
  }[];
  command: Array<string>;
  args: Array<string>;
}
export interface IClusterTemplateConfig {
  name?: string | null; // null passed to BE if name field is empty
  organizationId: string;
  computeProjectId?: string;
  instanceId?: string;
  uniqueTopicId: string;

  services: ITemplateServiceVariables[];

  region: string;
  scalable: boolean;
}

export interface ITemplateServiceVariables {
  name: string;
  image: string;
  tag: string;
  serviceCount: number;
  ports?: IPorts[];
  env: { value: string; isSecret: boolean }[];
  command: Array<string>;
  args: Array<string>;
  akashMachineImageName: string | undefined;
  customServiceSpecs?: ICustomInstanceSpecs;
  templateId: string;
  autoscalingRules?: IAutoscalingRequestPayload | null;
}

export interface IPrivateImageConfig {
  dockerRegistryURL: string;
  dockerUserName: string;
  dockerPasswordOrToken: string;
}

export interface IInstanceDeploymentConfig {
  uniqueTopicId: string;
  healthCheckUrl?: string;
  healthCheckPort?: string;
}

export interface IActivityDetails {
  _id: string;
  status: string;
  type: string;
  createdAt: Date;
  updatedAt: Date;
  services: {
    agreedMachineImage: IServiceAgreedMachineImage;
    image: string;
    name: string;
    tag: string;
  }[];
  instanceCount: number;
  tag: string;
}

export interface IUpdateInstance {
  instanceId?: string;
  branch: string | null;
  folderName: string;
  image: string;
  containerPort: string;
  exposedPort: string;
  env: string[];
  uniqueTopicId: string;
  organizationId?: string;
}

export interface IInstanceDeployLogs {
  log: string;
  time: string | null;
  id?: string;
}

export interface IProtocolData {
  dseq?: number;
  provider?: string;
  providerHost: string;
}

export interface IOrder {
  clusterInstance: string;
  clusterInstanceConfiguration: IInstanceConfiguration[];
  logs: IInstanceDeployLogs[];
  logsLength: number;
  protocolData: IProtocolData;
  status: InstanceStatus;
  type: string;
  urlPrewiew: string;
  _id: string;
}

export interface IGetClusterInstancePayloadDto {
  clusterId: string;
  topupReport: string;
}

export interface IGetClusterInstanceResponsePayloadDto {
  extendedInstances: IInstance[];
  success: boolean;
}
export interface IFetchInstanceServiceResponse {
  topicId: string;
  message: string;
  url?: string;
}

export interface IGetRestartResponsePayloadDto {
  success: boolean;
  message: string;
  topic: string;
  computeDeploymentId: string;
  computeInstanceId: string;
  computeProjectId: string;
}
export interface IInstanceDomains {
  createdAt: Date;
  deploymentEnvironmentIds: string[];
  link: string;
  name: string;
  projectId: string;
  type: ClusterInstanceDomain;
  updatedAt: Date;
  verified: boolean;
  version: string;
  _id: string;
  clusterInstance: string;
}

export interface ICreateInstanceDomainPayloadDto {
  link: string | undefined;
  type: string;
  name: string;
  organizationId?: string;
}

export interface IInstanceDomainIdPayloadDto {
  instanceId: string;
  domainId: string;
  organizationId?: string;
}

export interface IDeleteInstanceDomainPayloadDto
  extends IInstanceDomainIdPayloadDto {
  domainType: string;
}

export interface ICreateInstanceDomainResponse {
  createdAt: string;
  deploymentEnvironmentIds?: [];
  link: string;
  name: string;
  projectId: string;
  type: string;
  updatedAt: Date;
  verified: boolean;
  version: string;
  _id: string;
}
export interface IUpdateHealthCheck {
  instanceId: string;
  healthCheckUrl: string;
  healthCheckPort: string;
  organizationId: string;
  serviceName: string;
}

export interface IFetchInstanceServiceDto {
  instanceId: string;
  type: string;
  topicId: string;
}

export interface IParams {
  clusterId: string;
  orgId: string;
  slug1: string;
  slug2: string;
  slug3: string;
  instanceId: string;
  projectId: string;
  serviceId: string;
}

export interface IInstanceSocketUpdate {
  status: InstanceStatus;
  providerHost: string;
  ports: IConfigPort[] | null;
  latestUrlPreview: string;
}

export interface IConfigPort {
  _id: string;
  containerPort: string;
  exposedPort: string;
}

export interface ISetDomainLoading {
  loading: boolean;
  domainId: string;
}

export interface ISetInstanceLogLoading {
  loading: boolean;
  type: string;
}

export interface IUpdateInstanceDto {
  instanceId: string;
  domainId: string;
  organizationId: string;
  body: ICreateInstanceDomainPayloadDto;
}

export interface IGetInstanceOrderResponse {
  deployment: IDeployment;
  parentInstance: IInstance;
  success: boolean;
}

export interface IRequestForUpdatingInstance {
  env: { value: string; isSecret: boolean }[];
  environmentVariables: { name: string; value: string }[];
  instanceId: string;
  organizationId: string;
  uniqueTopicId: string;
  akashMachineImageName?: string;
  tag: string;
  command: Array<string>;
  args: Array<string>;
  customInstanceSpecs: ICustomInstanceSpecs;
  instanceCount: number;
  clusterInstanceOrderId: string;
}

export interface IRequestForRestartInstance {
  instanceId: string;
  organizationId: string;
  uniqueTopicId: string;
}

export interface IShellResponse {
  error?: boolean;
  success?: boolean;
  message: string;
  logs: string;
}

export interface IInstanceMetrics {
  cpu: number;
  memory: number;
  timestamp: Date;
}

export interface IInstanceMetricsResponsePayloadDto {
  metrics: IInstanceMetrics[];
}

export enum AutoscalingPlanEnum {
  CUSTOM = 'custom',
  RELAX = 'relax',
  STANDARD = 'standard',
  AGGRESSIVE = 'aggressive',
}

export enum AutoscalingTimeWindowEnum {
  WINDOW_60 = '60', // 1min
  WINDOW_120 = '120', // 2min
  WINDOW_300 = '300', // 5min
  WINDOW_600 = '600', // 10min
}

export enum AutoscalingCooldownEnum {
  MINUTE_5 = '300',
  MINUTE_10 = '600',
  MINUTE_15 = '900',
  MINUTE_20 = '1200',
}

export interface IAutoscalingRulesThreshold {
  cpuThreshold: {
    step: number;
    utilizationPercentage: number;
  };
  memoryThreshold: {
    step: number;
    utilizationPercentage: number;
  };
}

export interface IAutoscalingRequestPayload {
  minimumInstances: number;
  maximumInstances: number;
  plan: string | AutoscalingPlanEnum;
  timeWindow?: string | AutoscalingTimeWindowEnum | null;
  scaleUp?: IAutoscalingRulesThreshold | null;
  scaleDown?: IAutoscalingRulesThreshold | null;
  cooldown?: string | AutoscalingCooldownEnum | null;
  cancelAutoscaling?: boolean;
}
export interface IGPU {
  units: number;
  vendor: string;
  model: string;
  totalUnits: number;
  availableUnits: number;
}

export interface IGPUSpecs {
  units: number;
  models: Vendor;
}

export interface Vendor {
  [key: string]: GPUModel[];
}

export interface GPUModel {
  model: string;
}
export interface IInstanceMetricsChart {
  totalCpu: number;
  totalMemory: number;
  time: Date;
}

export interface IInstanceMetricsCharResponsePayloadDto {
  metrics: IInstanceMetricsChart[];
}

export interface IInstanceMetricsChartPayloadDto {
  serviceName: string;
  instanceId: string;
  monitoringPointTime: string;
  monitoringTime: number;
  monitoringType: string;
}

export enum MonitoringTime {
  // eslint-disable-next-line no-unused-vars
  HOURLY = 'hourly',
  // eslint-disable-next-line no-unused-vars
  DAILY = 'daily',
  // eslint-disable-next-line no-unused-vars
  WEEKLY = 'weekly',
  // eslint-disable-next-line no-unused-vars
  MONTHLY = 'monthly',
}

export interface IInstanceCost {
  value: string | number;
  unit: string;
}

export interface IHealthCheck {
  port: {
    exposedPort: number;
    containerPort: number;
  };
  status: HealthCheckStatus;
  timestamp: Date;
  path: string;
}

export interface IAgreedServiceMachineImage {
  machineId: string;
  machineType: string;
  agreementDate: number;
  cpu: number;
  memory: string;
  storage: string;
  persistentStorage?: IPersistentStorage;
  gpu: IGPUSpecs;
  maxPricePerBlock: number;
  leasePricePerBlock: number;
  defaultDailyTopUp: number;
  topupThreashold: number;
}

export interface IDeploymentServiceConfiguration {
  name: string;
  protocol: string; // ex: akash
  image: string; // name of the docker image if it is not located in repo
  tag: string; // name of the docker image if it is not located in repo
  serviceCount: number;
  buildImage: boolean; // if container should build the image, or if user has already defined it
  ports: { _id?: string; containerPort: string; exposedPort: string }[];
  env: any[];
  command: string[];
  args: string[];
  region: string;
  customServiceSpecs: any;
  agreedMachineImage: IAgreedServiceMachineImage;
  privateImageConfig?: IPrivateImageConfig;
  scalable: boolean;
  autoscalingRules: Partial<IAutoscalingRequestPayload>;
  healthCheck: IHealthCheck;
}

export interface IDeployment {
  buildTime: number;
  _id: string;
  type: string;
  status: string;
  topic: string;
  computeInstance: string;
  computeProject: string;
  services: IService[];
  akashWalletId: string;
  deploymentInitiator: string;
  topups: any[];
  createdAt: string;
  updatedAt: string;
  protocolData: {
    dseq: number;
    provider: string;
    providerHost: string;
  };
}

export interface IServiceAgreedMachineImage {
  machineId: string;
  machineType: string;
  agreementDate: number;
  cpu: number;
  memory: string;
  storage: string;
  persistentStorage?: IPersistentStorage;
  gpu: IGPUSpecs;
  maxPricePerBlock: number;
  leasePricePerBlock: number;
  defaultDailyTopUp: number;
  topupThreashold: number;
}

export interface IService {
  agreedMachineImage: IServiceAgreedMachineImage;
  name: string;
  viewName: string;
  protocol: string; // ex: akash
  image: string; // name of the docker image if it is not located in repo
  tag: string; // name of the docker image if it is not located in repo
  serviceCount: number;
  buildImage: boolean; // if container should build the image, or if user has already defined it
  ports: any[];
  env: any[];
  command: any[];
  args: any[];
  region: string;
  discount?: IDiscount;
  customServiceSpecs: any;
  privateImageConfig?: IPrivateImageConfig;
  scalable: boolean;
  autoscalingRules: IAutoscalingRequestPayload;
  healthCheck: IHealthCheck;
  template?: IClusterTemplate;
  variables?: {
    label: string;
    name: string;
    defaultValue: string;
    required: boolean;
  }[];
  urlPreview: string;
}

export interface ICloseMultipleInstanceResponseDto {
  success: boolean;
  message: string;
  closedInstanceIds: string[];
}

export interface IServicePorts {
  containerPort: number;
  exposeTo?: string[];
  exposedPort: number;
  global: boolean;
}

export interface ICdnRecordsResponse {
  message: any;
  error: boolean;
  records: ICdnRecords;
}
