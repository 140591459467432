import React from 'react';
import Skeleton from 'react-loading-skeleton';

const InstanceCardLoading = () => {
  return (
    <div
      role="presentation"
      className="rounded-lg bg-cards-bg dark:bg-dark-cards-bg
      border border-base-border dark:border-dark-base-border relative
      w-full group"
    >
      <div className="absolute top-1 right-1 p-2 cursor-pointer z-10">
        <Skeleton height={16} width={16} duration={2} />
      </div>

      <div className="py-5 pl-3 pr-20">
        <div className="flex items-center justify-between">
          <div className="flex items-start justify-start gap-x-3">
            <div>
              <Skeleton height={26} width={26} duration={2} />
            </div>
            <div className="flex flex-col gap-y-1">
              <div className="flex items-center justify-start gap-x-2">
                <h5
                  className="text-base font-semibold leading-5 text-base-heading-text-color
                 dark:text-dark-base-heading-text-color"
                >
                  <Skeleton height={20} width={110} duration={2} />
                </h5>
                <Skeleton height={24} width={50} duration={2} />{' '}
                <Skeleton height={24} width={50} duration={2} />
              </div>
              <div
                className="flex flex-col gap-y-1 text-sm font-normal 
              text-base-para-text-color dark:text-dark-base-para-text-color"
              >
                <p>
                  {' '}
                  <Skeleton height={16} width={250} duration={2} />
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between gap-y-4 w-[55%]">
            <div className="flex items-center justify-start space-x-8">
              {[0, 1, 2].map((specification) => (
                <div
                  key={specification}
                  className="flex items-center justify-start gap-x-1"
                >
                  <div
                    className="h-7 w-7 rounded-full items-center justify-center p-1
                    bg-base-border dark:bg-dark-base-border flex"
                  >
                    <Skeleton
                      height={28}
                      width={28}
                      borderRadius={28}
                      duration={2}
                    />
                  </div>
                  <span
                    className="!text-sm font-normal text-base-heading-text-color 
                  dark:text-dark-base-heading-text-color whitespace-nowrap"
                  >
                    <Skeleton height={16} width={110} duration={2} />
                  </span>
                </div>
              ))}
            </div>

            <div
              className="flex flex-col gap-y-0.5 text-base-heading-text-color
             dark:text-dark-base-heading-text-color"
            >
              <h5 className="text-base font-semibold">
                <Skeleton height={20} width={60} duration={2} />
              </h5>
              <p className="text-[11px] font-normal">
                <Skeleton height={20} width={40} duration={2} />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstanceCardLoading;
