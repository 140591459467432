import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { InstanceCardState } from '@spheron/ui-library';
// eslint-disable-next-line import/no-cycle
import {
  createNewProjectThunk,
  deleteComputeProjectThunk,
  getAllComputeProjectsThunk,
  getComputeProjectDetailsThunk,
  getComputeProjectFundsUsageThunk,
  getComputeProjectInstanceCountThunk,
  getComputeProjectInstancesThunk,
  getComputeProjectsCountThunk,
  getComputeProjectsWalletInstanceReportThunk,
  loadMoreComputeProjectInstancesThunk,
  loadMoreComputeProjectsThunk,
} from './project.thunks';
import {
  IComputeProject,
  IComputeProjectInitialState,
  IComputeProjectWalletInstanceReport,
  ICreateNewProjectResponseDto,
  IGetAllComputeProjectsResponseDto,
  IGetComputeProjectInstancesResponseDto,
} from './project.interfaces';

const initialState: IComputeProjectInitialState = {
  projects: [],
  projectsLoading: true,
  selectedProject: null,
  selectedProjectLoading: true,
  deleteProjectLoading: false,
  selectedProjectInstances: [],
  allBillingProjectsCount: null,
  loadMoreBillingProjectsLoading: false,
  pagination: {
    currentPage: 1,
    numbersPerPage: 6,
  },
  billingPagination: {
    currentPage: 1,
    numbersPerPage: 4,
  },
  billingProjects: [],
  billingProjectsLoading: true,
  totalInstances: 0,
  billingTotalInstances: 0,
  allProjectsCount: null,
  allProjectsCountLoading: true,
  selectedProjectInstanceCount: null,
  selectedProjectInstanceCountLoading: false,
  loadMoreProjectsLoading: false,
  selectedProjectFundsUsage: null,
  selectedProjectFundsUsageLoading: true,
  selectedProjectInstancesLoading: true,
  loadMoreSelectedProjectInstancesLoading: false,
  selectedProjectInstancePagination: {
    currentPage: 1,
    numbersPerPage: 6,
  },
  walletInstanceReport: null,
  walletInstanceReportLoading: true,
  walletUsage: null,
  walletUsageLoading: true,
  createProjectLoading: false,
  shouldFetchComputeProjectInstances: true,
  selectedProjectName: null,
};

const projectSlice = createSlice({
  name: 'computeProject',
  initialState,
  reducers: {
    setSelectedProject(state, action: PayloadAction<IComputeProject>) {
      state.selectedProject = action.payload;
      state.selectedProjectLoading = false;
    },
    toggleSelectedProjectLoading(state, action: PayloadAction<boolean>) {
      state.selectedProjectLoading = action.payload;
    },
    setSelectedComputeProjectInstances(state, action: PayloadAction<any>) {
      state.selectedProjectInstances = action.payload;
    },
    toggleShouldFetchComputeProjectInstances(
      state,
      action: PayloadAction<boolean>
    ) {
      state.shouldFetchComputeProjectInstances = action.payload;
    },
    setSelectedComputeProjectInstancesClose(
      state,
      action: PayloadAction<string[]>
    ) {
      state.selectedProjectInstances = state.selectedProjectInstances.map(
        (project) => {
          if (action.payload.indexOf(project._id) !== -1) {
            return {
              ...project,
              state: InstanceCardState.CLOSED,
            };
          }
          return project;
        }
      );
    },
    setSelectedComputeProjectInstancesDelete(
      state,
      action: PayloadAction<string[]>
    ) {
      state.selectedProjectInstances = state.selectedProjectInstances.filter(
        (project) => {
          return action.payload.indexOf(project._id) === -1;
        }
      );
      state.allProjectsCount = (state.allProjectsCount || 1) - 1;
    },
    setSelectedProjectName(state, action: PayloadAction<string>) {
      state.selectedProjectName = action.payload;
    },

    setUpdatedProjectInstanceName(
      state,
      action: PayloadAction<{ name: string; instanceId: string }>
    ) {
      state.selectedProjectInstances = state.selectedProjectInstances.map(
        (instance) => {
          if (instance._id === action.payload.instanceId) {
            return {
              ...instance,
              name: action.payload.name,
            };
          }
          return instance;
        }
      );
    },

    resetProjectState(state) {
      Object.assign(state, initialState);
    },
    resetComputeProjectInstancePaginationState(state) {
      state.selectedProjectInstancePagination =
        initialState.selectedProjectInstancePagination;
    },
    resetComputeProjectPaginationState(state) {
      state.pagination = initialState.pagination;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllComputeProjectsThunk.pending, (state, action) => {
      if (action.meta.arg.topupReport === 'n') {
        state.projectsLoading = true;
      } else {
        state.billingProjectsLoading = true;
        state.allProjectsCountLoading = true;
      }
    });

    builder.addCase(getAllComputeProjectsThunk.rejected, (state) => {
      state.projectsLoading = false;
      state.allProjectsCountLoading = false;
      state.projects = [];
      state.allProjectsCount = 0;
    });

    builder.addCase(
      getAllComputeProjectsThunk.fulfilled,
      (state, action: PayloadAction<IGetAllComputeProjectsResponseDto>) => {
        state.allProjectsCountLoading = false;
        if ((action as any).meta.arg.topupReport === 'n') {
          state.projects = action.payload.computeProjects;
          state.projectsLoading = false;
          state.allProjectsCount = action.payload.totalComputeProjects;
          state.totalInstances = action.payload.totalInstances;
        } else {
          state.billingProjects = action.payload.computeProjects;
          state.billingProjectsLoading = false;
          state.allBillingProjectsCount = action.payload.totalComputeProjects;
          state.billingTotalInstances = action.payload.totalInstances;
        }
      }
    );

    builder.addCase(loadMoreComputeProjectsThunk.pending, (state, action) => {
      if (action.meta.arg.topupReport === 'n') {
        state.loadMoreProjectsLoading = true;
      } else {
        state.loadMoreBillingProjectsLoading = true;
      }
    });

    builder.addCase(loadMoreComputeProjectsThunk.rejected, (state, action) => {
      if (action.meta.arg.topupReport === 'n') {
        state.loadMoreProjectsLoading = false;
      } else {
        state.loadMoreBillingProjectsLoading = false;
      }
    });

    builder.addCase(
      loadMoreComputeProjectsThunk.fulfilled,
      (state, action: PayloadAction<IGetAllComputeProjectsResponseDto>) => {
        if ((action as any).meta.arg.topupReport === 'n') {
          state.loadMoreProjectsLoading = false;
          state.pagination = {
            ...state.pagination,
            currentPage: state.pagination.currentPage + 1,
          };
          state.projects = [
            ...state.projects,
            ...action.payload.computeProjects,
          ];
        } else {
          state.loadMoreBillingProjectsLoading = false;
          state.billingPagination = {
            ...state.pagination,
            currentPage: state.billingPagination.currentPage + 1,
          };
          state.billingProjects = [
            ...state.billingProjects,
            ...action.payload.computeProjects,
          ];
        }
      }
    );

    builder.addCase(getComputeProjectInstanceCountThunk.pending, (state) => {
      state.selectedProjectInstanceCountLoading = true;
    });

    builder.addCase(
      getComputeProjectInstanceCountThunk.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.selectedProjectInstanceCountLoading = false;
        state.selectedProjectInstanceCount = action.payload;
      }
    );

    builder.addCase(getComputeProjectInstanceCountThunk.rejected, (state) => {
      state.selectedProjectInstanceCountLoading = false;
      state.selectedProjectInstanceCount = null;
    });

    builder.addCase(getComputeProjectDetailsThunk.pending, (state) => {
      state.selectedProjectLoading = true;
    });

    builder.addCase(
      getComputeProjectDetailsThunk.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.selectedProject = action.payload;
        state.selectedProjectLoading = false;
      }
    );

    builder.addCase(getComputeProjectDetailsThunk.rejected, (state) => {
      state.selectedProject = null;
      state.selectedProjectLoading = true;
    });

    builder.addCase(getComputeProjectFundsUsageThunk.pending, (state) => {
      state.selectedProjectFundsUsageLoading = true;
    });

    builder.addCase(
      getComputeProjectFundsUsageThunk.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.selectedProjectFundsUsageLoading = false;
        state.selectedProjectFundsUsage = action.payload;
      }
    );

    builder.addCase(getComputeProjectFundsUsageThunk.rejected, (state) => {
      state.selectedProjectFundsUsageLoading = false;
      state.selectedProjectFundsUsage = null;
    });

    builder.addCase(deleteComputeProjectThunk.pending, (state) => {
      state.deleteProjectLoading = true;
    });

    builder.addCase(
      deleteComputeProjectThunk.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.deleteProjectLoading = false;
        state.projects = state.projects.filter(
          (project) => project._id !== action.payload
        );
        state.allProjectsCount! -= 1;
        state.selectedProject = null;
        state.selectedProjectInstanceCount = null;
        state.selectedProjectFundsUsage = null;
      }
    );

    builder.addCase(deleteComputeProjectThunk.rejected, (state) => {
      state.deleteProjectLoading = false;
    });

    builder.addCase(getComputeProjectInstancesThunk.pending, (state) => {
      state.selectedProjectInstancesLoading = true;
      state.selectedProjectInstanceCount = null;
    });

    builder.addCase(
      getComputeProjectInstancesThunk.fulfilled,
      (
        state,
        action: PayloadAction<IGetComputeProjectInstancesResponseDto>
      ) => {
        state.selectedProjectInstancesLoading = false;
        state.selectedProjectInstances = action.payload.extendedInstances;
        state.selectedProjectInstanceCount = action.payload.totalInstances;
      }
    );

    builder.addCase(getComputeProjectInstancesThunk.rejected, (state) => {
      state.selectedProjectInstancesLoading = false;
      state.selectedProjectInstances = [];
      state.selectedProjectInstanceCount = null;
    });

    builder.addCase(loadMoreComputeProjectInstancesThunk.pending, (state) => {
      state.loadMoreSelectedProjectInstancesLoading = true;
    });

    builder.addCase(
      loadMoreComputeProjectInstancesThunk.fulfilled,
      (
        state,
        action: PayloadAction<IGetComputeProjectInstancesResponseDto>
      ) => {
        state.selectedProjectInstances = [
          ...state.selectedProjectInstances,
          ...action.payload.extendedInstances,
        ];
        state.loadMoreSelectedProjectInstancesLoading = false;
        state.selectedProjectInstancePagination = {
          ...state.selectedProjectInstancePagination,
          currentPage: state.selectedProjectInstancePagination.currentPage + 1,
        };
      }
    );

    builder.addCase(loadMoreComputeProjectInstancesThunk.rejected, (state) => {
      state.loadMoreSelectedProjectInstancesLoading = false;
    });

    builder.addCase(
      getComputeProjectsWalletInstanceReportThunk.pending,
      (state) => {
        state.walletInstanceReportLoading = true;
      }
    );
    builder.addCase(
      getComputeProjectsWalletInstanceReportThunk.fulfilled,
      (state, action: PayloadAction<IComputeProjectWalletInstanceReport>) => {
        state.walletInstanceReportLoading = false;
        state.walletInstanceReport = action.payload;
      }
    );
    builder.addCase(
      getComputeProjectsWalletInstanceReportThunk.rejected,
      (state) => {
        state.walletInstanceReportLoading = false;
      }
    );

    builder.addCase(createNewProjectThunk.pending, (state) => {
      state.createProjectLoading = true;
    });

    builder.addCase(createNewProjectThunk.rejected, (state) => {
      state.createProjectLoading = false;
    });

    builder.addCase(
      createNewProjectThunk.fulfilled,
      (state, action: PayloadAction<ICreateNewProjectResponseDto>) => {
        const response = {
          _id: action.payload._id,
          name: action.payload.name,
          description: action.payload.description,
          state: action.payload.state,
          provider: action.payload.provider,
        };
        state.createProjectLoading = false;
        state.allProjectsCount = (state.allProjectsCount || 0) + 1;
        state.projects = [
          {
            ...response,
            createdAt: '',
            updatedAt: '',
            topupReport: null,
            instanceReport: {
              closed: 0,
              failedToStart: 0,
              starting: 0,
              total: 0,
              active: 0,
              lastTimeInstanceActivated: '',
              lastTimeInstanceClosed: '',
              lastTimeInstanceFailedToStart: '',
              lastTimeInstanceStarted: '',
            },
          },
          ...state.projects,
        ];
      }
    );

    builder.addCase(getComputeProjectsCountThunk.pending, (state) => {
      state.allProjectsCountLoading = true;
    });

    builder.addCase(
      getComputeProjectsCountThunk.fulfilled,
      (state, action: PayloadAction<number>) => {
        state.allProjectsCountLoading = false;
        state.allProjectsCount = action.payload;
      }
    );
    builder.addCase(getComputeProjectsCountThunk.rejected, (state) => {
      state.allProjectsCountLoading = false;
      state.allProjectsCount = 99;
    });
  },
});

export const { reducer: computeProjectRTKReducer } = projectSlice;
export const {
  setSelectedProject: setSelectedComputeProjectRtk,
  toggleSelectedProjectLoading: toggleSelectedComputeProjectLoading,
  setSelectedComputeProjectInstances: setSelectedComputeProjectInstancesRtk,
  toggleShouldFetchComputeProjectInstances,
  setSelectedComputeProjectInstancesClose,
  setSelectedComputeProjectInstancesDelete,
  setSelectedProjectName,
  setUpdatedProjectInstanceName,
  resetProjectState,
  resetComputeProjectInstancePaginationState,
  resetComputeProjectPaginationState,
} = projectSlice.actions;
