import React from 'react';
import { Switch } from '@spheron/ui-library';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/compute/store';
import {
  ClusterType,
  InstanceDeploymentType,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.interfaces';
// eslint-disable-next-line max-len
import { isAccelerateDeployment } from '../../../../../redux/compute/instance/instance-creation/instance-creation.utils';

interface IProps {
  toggleOn: boolean;
  handleChange: () => void;
  isUpdate: boolean;
  serviceId: string;
}
const SelectPlan = ({
  isUpdate,
  toggleOn,
  handleChange,
  serviceId,
}: IProps) => {
  const clusterType = useSelector(
    (state: RootState) => state.instanceCreation.clusterType
  );
  const deploymentType = useSelector(
    (state: RootState) => state.instanceCreation.deploymentType
  );
  const stepNumber = isAccelerateDeployment(deploymentType) ? '6.' : '7.';
  const dockerStepNumber = isAccelerateDeployment(deploymentType) ? '4.' : '5.';

  return (
    <div>
      <div
        className="flex lg:flex-row flex-col
         items-center"
      >
        <span
          className="text-base-heading-text-color dark:text-dark-base-heading-text-color
          text-xl font-bold"
        >
          {!isUpdate
            ? `${`${
                clusterType === ClusterType.TEMPLATE
                  ? stepNumber
                  : dockerStepNumber
              }`} Select Instance Plan`
            : 'Change Plan'}
        </span>
        {deploymentType !== InstanceDeploymentType.ACCELERATE && (
          <div className="text-text-darkGray text-sm font-normal flex flex-1 ml-5 items-center">
            Create Custom Plan{' '}
            <div className="ml-3">
              <Switch
                toggleId={`toggle-${serviceId || 0}`}
                isChecked={toggleOn}
                handleChange={handleChange}
              />
            </div>
          </div>
        )}

        {/* {!isUpdate && (
          <div
            className="text-sm text-text-filterBadge flex items-center bg-bg-protocolHoverBg
         py-2 px-3 rounded-lg dark:bg-dark-base-bg
         dark:border dark:border-dark-base-border dark:text-text-darkGray"
          >
            <Info className={Styles.info__icon} />
            <span className="ml-2 text-base-heading-text-color font-semibold mr-1">
              ~97%
            </span>
            cheaper than AWS <span className="bg-base-border h-5 w-px mx-2" />
            <span className="text-base-heading-text-color font-semibold mr-1">
              ~97%
            </span>
            cheaper than GCP <span className="bg-base-border h-5 w-px mx-2" />
            <span className="text-base-heading-text-color font-semibold mr-1">
              ~96%
            </span>
            cheaper than Digital Ocean
            <span
              className="ml-4 text-form-selected font-semibold underline"
              onClick={() =>
                dispatchRtk(
                  toggleModalShowRtk({
                    modalShow: true,
                    modalType: 'compareFeatures',
                  })
                )
              }
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === 'Space')
                  dispatchRtk(
                    toggleModalShowRtk({
                      modalShow: true,
                      modalType: 'CompareFeatures',
                    })
                  );
              }}
              role="button"
              tabIndex={0}
            >
              Compare all features
            </span>
            <div
              className="ml-2 bg-bg-logoBg flex items-center justify-center p-1.5 rounded-2xl 
          dark:bg-dark-base-fg"
            >
              <img src={SpheronLogo} alt="logo" />
            </div>
          </div>
        )} */}
        {/* Add it when we will have this value from backend */}
      </div>
    </div>
  );
};

export default SelectPlan;
