import React from 'react';
import { Navbar } from '@spheron/ui-library';
import { Link } from 'react-router-dom';
import LoginStyle from '../../../styles/compute/log-in.module.scss';
import GlobalStyle from '../../../styles/compute/global.module.scss';
import SpheronBlueLogo from '../../../assets/compute/global/spheron-logo.svg';
import OutlinedPrimaryButton from '../Buttons/outlined-primary';

interface IProps {
  children: JSX.Element;
}

const NavCliLogin = ({ children }: IProps) => (
  <>
    <Navbar
      logoIcon={
        <img
          src={SpheronBlueLogo}
          alt="Spheron Logo"
          className={GlobalStyle.spheron__logo}
        />
      }
      infoElements={
        <div className="flex flex-1 justify-end items-center space-x-5">
          <button
            type="button"
            className={LoginStyle.contact}
            onClick={() =>
              window.open('https://discord.gg/ahxuCtm', '_blank', 'noopener')
            }
          >
            Contact
          </button>
          <Link to="/login">
            <OutlinedPrimaryButton
              className={`${LoginStyle.button} px-6 py-2`}
              title="Login"
            />
          </Link>
        </div>
      }
    />
    {children}
  </>
);

export default NavCliLogin;
