import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { BillingHeaderCard } from '@spheron/ui-library';
import dayjs from 'dayjs';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import GlobalStyles from '../../../../styles/compute/global.module.scss';
import { ISubscription } from '../../../../redux/compute/subscription/subscription.interfaces';
import SidebarDropdownRow, {
  OptionType,
} from '../../../../components/Compute/Sidebar/sidebar-dropdown-row';
import { clearAllModalRtk } from '../../../../redux/compute/modal/modal.slice';
import { getWalletUsageThunk } from '../../../../redux/compute/cluster/cluster.thunks';
import { getSubscriptionUsageThunk } from '../../../../redux/compute/subscription/subscription.thunks';
import { mapCurrentAppToSpecialization } from '../../../../redux/compute/organisation/organisation.utils';
import { withLoader } from '../../../../redux/compute/root-utils';
import { AppDispatch, RootState } from '../../../../redux/compute/store';
import { ICurrentApp } from '../../../../redux/compute/organisation/organisation.interfaces';
import { avatarImages } from '../CreateMasterOrganisation/organisation-avatars';

function Billing() {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const { orgUsername } = useParams<{ orgUsername: string }>();

  const currentApp = useSelector(
    (state: RootState) => state.organisation.currentApp
  );
  const selectedOrganisationLoading = useSelector(
    (state: RootState) => state.organisation.selectedOrganisationLoading
  );

  const selectedOrganisationId: string = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation._id
  );

  const activeSubscription: ISubscription | null = useSelector(
    (state: RootState) => state.subscription.activeSubscription
  );

  const selectedMasterOrganisation = useSelector(
    (state: RootState) => state.organisation.selectedMasterOrganisation
  );

  const selectedOrganisationName = useSelector(
    (state: RootState) =>
      state.organisation?.selectedOrganisation?.profile?.name
  );

  const user = useSelector((state: RootState) => state.user.user);

  const allUsers = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation.users
  );

  const isMember =
    allUsers?.find((u) => u?._id === user?._id)?.platformProfile?.role ===
      'member' && user !== null;

  const [defaultSidebar, setDefaultSidebar] = useState<string>('');

  useEffect(() => {
    const bc = new BroadcastChannel('copperx_success');
    if (selectedOrganisationId) {
      bc.onmessage = (msg: any) => {
        if (msg.data.data === 'payment_successful') {
          dispatch(getWalletUsageThunk(selectedOrganisationId));
          dispatch(
            getSubscriptionUsageThunk({
              organizationId: selectedOrganisationId,
              specialization: mapCurrentAppToSpecialization(
                ICurrentApp.COMPUTE
              ),
            })
          );
          dispatch(clearAllModalRtk());
        } else if (msg.data.data === 'payment_unsuccessful') {
          dispatch(clearAllModalRtk());
        }
      };
    }
    return () => {
      bc.close();
    };
  }, [currentApp, dispatch, selectedOrganisationId]);

  const defaultTabRoutes = [
    {
      id: 0,
      label: 'Plan & Billing Details',
      value: 'plan',
      optionType: 'primary' as OptionType,
      url: `/${orgUsername}/billing/plan`,
      slug: 'plan',
    },
    {
      id: 1,
      label: 'Payment History',
      value: 'payment-history',
      url: `/${orgUsername}/billing/payment-history`,
      optionType: 'primary' as OptionType,
      slug: 'payment-history',
    },
  ];

  const tabRoutes = [
    ...defaultTabRoutes,
    ...(!isMember
      ? [
          {
            id: 2,
            label: 'Invoice Settings',
            value: 'invoice-settings',
            url: `/${orgUsername}/billing/invoice-settings`,
            optionType: 'primary' as OptionType,
            slug: 'invoice-settings',
          },
          {
            id: 3,
            label: 'Coupon Credit',
            value: 'spheron-credit',
            url: `/${orgUsername}/billing/spheron-credit`,
            optionType: 'primary' as OptionType,
            slug: 'spheron-credit',
          },
        ]
      : []),
    ...(activeSubscription
      ? [
          {
            id: 4,
            label: 'Plan Usage',
            value: 'plan-usage',
            url: `/${orgUsername}/billing/plan-usage`,
            optionType: 'primary' as OptionType,
            slug: 'plan-usage',
          },
        ]
      : []),
  ];

  const tabComputeRoutes = [
    ...(activeSubscription
      ? [
          ...tabRoutes,
          {
            id: 5,
            label: 'Compute Usage',
            value: 'compute-usage',
            url: `/${orgUsername}/billing/compute-usage`,
            optionType: 'primary' as OptionType,
            slug: 'compute-usage',
          },
        ]
      : tabRoutes),
  ].sort((route1, route2) => route1.id - route2.id);

  useEffect(() => {
    setDefaultSidebar(location.pathname.split('/')[3]);
  }, [location.pathname]);

  const defaultOrgAvatar = avatarImages[0];

  const [avatarSrc, setAvatarSrc] = useState<string>();

  useEffect(() => {
    setAvatarSrc(selectedMasterOrganisation?.profile?.image);
  }, [selectedMasterOrganisation?.profile?.image]);

  return (
    <div className={`${GlobalStyles.screen__width} !pt-[22px]`}>
      <>
        {withLoader(
          selectedOrganisationLoading,
          <Skeleton duration={2} height={78} />,
          <BillingHeaderCard
            icon={
              <img
                src={avatarSrc}
                className="w-[52px] h-[52px] rounded-full"
                onError={() => setAvatarSrc(defaultOrgAvatar.src)}
                alt="avatar"
              />
            }
            orgName={selectedOrganisationName || ''}
            date={dayjs(activeSubscription?.dateOfExpiration).format(
              'DD MMM YYYY'
            )}
            handleLinkClick={() =>
              window.open('https://docs.spheron.network/billing/')
            }
          />
        )}
      </>

      <div className={`${GlobalStyles.screen__inner__con} mt-16`}>
        <SidebarDropdownRow
          heading=""
          options={tabComputeRoutes}
          defaultSelected={defaultSidebar}
        />

        <div
          className={`${GlobalStyles.right__con} ${GlobalStyles.ignore__margin__top}
          dark:border-dark-base-border`}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Billing;
