import React, { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Button } from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../redux/compute/store';
import DropdownMenu from '../DropdownMenu';
import { CustomSelectOption } from '../DropdownMenu/dropdown-menu';
import {
  HardwareTypeEnum,
  IInstancePlan,
  LoadingType,
  ScalingType,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.interfaces';
import {
  getInstancePlansEndRtk,
  setUpdatedCustomPriceRtk,
  updateCustomCpuRtk,
  updateCustomPlanRtk,
  updateCustomRamRtk,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.slice';
import { calculatePriceThunk } from '../../../../../redux/compute/instance/instance-creation/instance-creation.thunks';
// eslint-disable-next-line max-len
import { isAccelerateDeployment } from '../../../../../redux/compute/instance/instance-creation/instance-creation.utils';
import { setIsCustomPlanOpenRtk } from '../../../../../redux/compute/instance/instance.slice';
import { IInstanceCost } from '../../../../../redux/compute/instance/instance.interfaces';

interface IProps {
  isUpdate: boolean;
  // eslint-disable-next-line no-unused-vars
  setToggleOn: (isToggleOn: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  setIsCancel: (isCancel: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  setSelectedInstancePlan: (selectedInstancePlan: IInstancePlan) => void;
  // eslint-disable-next-line no-unused-vars
  setIsConfirm: (isConfirm: boolean) => void;
}
const PlanOne = ({
  isUpdate,
  setToggleOn,
  setIsCancel,
  setSelectedInstancePlan,
  setIsConfirm,
}: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();

  const instancePlans = useSelector(
    (state: RootState) => state.instanceCreation.instancePlans
  );
  const customPlan = useSelector(
    (state: RootState) => state.instanceCreation.customPlan
  );
  const selectedCpu = useSelector(
    (state: RootState) => state.instanceCreation.customCpu
  );
  const selectedRam = useSelector(
    (state: RootState) => state.instanceCreation.customRam
  );
  const isUpdatedPrice = useSelector(
    (state: RootState) => state.instanceCreation.updatedCustomPrice
  );
  const defaultCustomPlanValue = useSelector(
    (state: RootState) => state.instanceCreation.defaultCustomPlansValue
  );
  const priceLoading = useSelector(
    (state: RootState) => state.instanceCreation.priceLoading
  );
  const priceLoadingType = useSelector(
    (state: RootState) => state.instanceCreation.priceLoadingType
  );
  const cpuValues = defaultCustomPlanValue?.find(
    (item: { type: HardwareTypeEnum }) => item.type === HardwareTypeEnum.CPU
  );
  const memoryValues = defaultCustomPlanValue?.find(
    (item: { type: HardwareTypeEnum }) => item.type === HardwareTypeEnum.MEMORY
  );
  const scalingType = useSelector(
    (state: RootState) => state.instanceCreation.clusterScaling
  );
  const deploymentType = useSelector(
    (state: RootState) => state.instanceCreation.deploymentType
  );
  const monthlyCostUSD = (isUpdatedPrice * 30 || 0).toFixed(2);
  const hourlyCostUSD = (isUpdatedPrice / 24).toFixed(2);
  let primaryCost: IInstanceCost;
  let secondaryCost: IInstanceCost;
  if (isAccelerateDeployment(deploymentType)) {
    primaryCost = {
      value: hourlyCostUSD,
      unit: 'hour',
    };
    secondaryCost = {
      value: monthlyCostUSD,
      unit: 'month',
    };
  } else {
    primaryCost = {
      value: monthlyCostUSD,
      unit: 'month',
    };
    secondaryCost = {
      value: hourlyCostUSD,
      unit: 'hour',
    };
  }
  const handleCancel = () => {
    dispatchRtk(updateCustomCpuRtk(''));
    dispatchRtk(updateCustomRamRtk(''));
    dispatchRtk(setUpdatedCustomPriceRtk(0));
    setIsCancel(true);
    setToggleOn(false);
  };
  const handleConfirm = () => {
    if (
      selectedCpu &&
      selectedRam &&
      selectedCpu !== '' &&
      selectedRam !== '' &&
      isUpdatedPrice &&
      customPlan
    ) {
      const customPlanIndex = instancePlans.akashMachineImages.findIndex(
        (plan) => plan._id === 'custom-id'
      );

      let updatedInstancePlans;
      if (customPlanIndex !== -1) {
        let updatedAkashMachineImages;
        if (isUpdate) {
          updatedAkashMachineImages = instancePlans.akashMachineImages.filter(
            (image) => image._id !== 'custom-id'
          );
          updatedAkashMachineImages = [
            customPlan,
            ...updatedAkashMachineImages,
          ];
        } else {
          updatedAkashMachineImages = [
            ...instancePlans.akashMachineImages.slice(0, customPlanIndex),
            customPlan,
            ...instancePlans.akashMachineImages.slice(customPlanIndex + 1),
          ];
        }
        updatedInstancePlans = {
          ...instancePlans,
          akashMachineImages: updatedAkashMachineImages,
        };
      } else {
        const updatedAkashMachineImages = [
          customPlan,
          ...instancePlans.akashMachineImages,
        ];
        updatedInstancePlans = {
          ...instancePlans,
          totalCount: instancePlans.totalCount + 1,
          akashMachineImages: updatedAkashMachineImages,
        };
      }
      // infer better type
      const updatedCustomPlan: any =
        updatedInstancePlans?.akashMachineImages.find(
          (plan) => plan._id === 'custom-id'
        );
      dispatchRtk(updateCustomPlanRtk(customPlan));
      dispatchRtk(getInstancePlansEndRtk(updatedInstancePlans));
      setSelectedInstancePlan(updatedCustomPlan);
      setToggleOn(false);
      setIsCancel(false);
      setIsConfirm(true);
    }
  };
  useEffect(() => {
    if (
      selectedCpu &&
      selectedRam &&
      selectedCpu !== '' &&
      selectedRam !== ''
    ) {
      dispatchRtk(calculatePriceThunk(LoadingType.CUSTOM_PLAN));
    }
  }, [selectedCpu, selectedRam, dispatchRtk, isUpdate]);

  useEffect(() => {
    dispatchRtk(setIsCustomPlanOpenRtk(true));
    dispatchRtk(updateCustomCpuRtk(''));
    dispatchRtk(updateCustomRamRtk(''));
  }, [dispatchRtk]);

  return (
    <>
      <div className="grid grid-cols-4 gap-x-0 mb-12">
        <div
          className={`bg-bg-newPlan border
            mt-8 ll:min-h-28 h-auto ll:py-0 py-4 rounded-xl 
            w-max ${isUpdate ? 'px-6' : 'px-5 ll:px-10'}
            dark:bg-dark-base-bg dark:border dark:border-dark-base-border`}
        >
          <div className="flex flex-col">
            <div className="flex justify-center my-4">
              <div
                className={`flex ll:items-center ${
                  isUpdate
                    ? 'flex-col gap-5'
                    : 'll:flex-row flex-col ll:gap-0 gap-5'
                }`}
              >
                <div className="flex">
                  <div>
                    <DropdownMenu
                      isUpdate={isUpdate}
                      label="CPU"
                      options={cpuValues?.values}
                      value={CustomSelectOption.SELECTEDCPU}
                      widthClassName="w-56"
                      defaultOption="Select"
                    />
                  </div>
                  <div className="ll:ml-10 ml-3">
                    <DropdownMenu
                      isUpdate={isUpdate}
                      label="RAM"
                      options={memoryValues?.values}
                      value={CustomSelectOption.SELECTEDRAM}
                      widthClassName="w-56"
                      defaultOption="Select"
                    />
                  </div>
                  <div className="flex flex-col ll:ml-11 ml-3 h-16">
                    <span
                      className="font-semibold text-sm text-text-filterBadge 
                leading-5 dark:text-text-darkGray"
                    >
                      {scalingType === ScalingType.MANUAL ? '' : 'Avg '}Cost/
                      {primaryCost.unit}
                    </span>
                    {selectedCpu !== '' && selectedRam !== '' ? (
                      <>
                        {priceLoading &&
                        priceLoadingType === LoadingType.CUSTOM_PLAN ? (
                          <Skeleton height={40} duration={4} />
                        ) : (
                          <>
                            <span
                              className="text-base font-bold text-text-templateCard leading-5 mt-2
                            text-base-heading-text-color dark:text-dark-base-heading-text-color"
                            >
                              ${primaryCost.value}/{primaryCost.unit}
                            </span>
                            <span className="leading-4 text-xs font-normal text-text-darkGray mt-1">
                              ${secondaryCost.value}{' '}
                              {scalingType === ScalingType.MANUAL ? '' : 'avg'}{' '}
                              {`${secondaryCost.unit}ly`} cost
                            </span>
                          </>
                        )}
                      </>
                    ) : (
                      <span className="text-text-filterBadge flex items-center h-full">
                        --
                      </span>
                    )}
                  </div>
                </div>
                <div
                  className={`flex flex-row gap-x-4 ${
                    isUpdate ? 'w-full justify-start' : 'll:ml-11'
                  }`}
                >
                  <Button
                    buttonType="ghost"
                    label="Cancel"
                    size="medium"
                    onClick={handleCancel}
                  />
                  <div className="ll:ml-4 ml-3">
                    <Button
                      buttonType="primary"
                      label="Good to go"
                      size="medium"
                      onClick={handleConfirm}
                      disabled={!selectedCpu || !selectedRam}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlanOne;
