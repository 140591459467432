import React from 'react';
import {
  createStoreHook,
  createDispatchHook,
  createSelectorHook,
  TypedUseSelectorHook,
} from 'react-redux';
import { AppDispatch, RootState } from './rtk-store';

const rtkContext: any = React.createContext(null);

export const useStore = createStoreHook(rtkContext);
const useDispatch = createDispatchHook(rtkContext);
const useSelector = createSelectorHook(rtkContext);

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default rtkContext;
