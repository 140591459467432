// eslint-disable-next-line import/no-cycle
import { ICreditCard } from '../combined-state.interface';

export interface IStripeState {
  savingCreditCard: boolean;
  fetchingCreditCards: boolean;
  creditCardsLoading: string[];
  creditCards: ICreditCard[];
}

export enum ConnectionState {
  // eslint-disable-next-line no-unused-vars
  WAITING_FOR_CONFIRMATION = 'waiting_for_confirmation',
  // eslint-disable-next-line no-unused-vars
  CONNECTED = 'connected',
}

export interface ICreditCardInfo {
  brand: string;
  country: string;
  // eslint-disable-next-line camelcase
  expiration_month: string;
  // eslint-disable-next-line camelcase
  expiration_year: string;
  last4: string;
}
