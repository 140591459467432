import {
  ICdnRecords,
  IConfiguration,
  IDeploymentEnvironment,
  IDomain,
  IProject,
} from '../combined-state.interface';

export interface IProjectState {
  selectedProjectLoading: boolean;
  selectedProject: IProject | undefined;
  projectsLoading: boolean;
  allProjects: IProject[];
  totalMaintainedProjects: number;
  totalArchivedProjects: number;
  moreMaintainedProjectsLoading: boolean;
  maintainedProjectsPagination: IProjectsPagination;
  archivedProjects: IProject[];
  domains: IDomainState;
  domainLoading: boolean;
  editDomainLoading: Array<string>;
  deleteDomainLoading: Array<string>;
  verifyDomainLoading: Array<string>;
  cdnRecords: ICdnRecords;
  envVariableLoading: string;
  archivedProjectsLoading: boolean;
  moreArchivedProjectsLoading: boolean;
  archivedProjectsPagination: IProjectsPagination;
  updateBuildConfigLoading: boolean;
  createDeployEnvLoading: boolean;
  deleteDeployEnvLoading: boolean;
  deactivateDeployEnvLoading: boolean;
  removeEnvVariableLoading: boolean;
  deployEnvLoading: boolean;
  deleteProjectLoading: boolean;
  securityLoading: boolean;
  securitySuccess: boolean;
}

export interface IProjectsPagination {
  currentPage: number;
  numbersPerPage: number;
}

export interface IDomainState {
  domains: IDomain[];
  subdomains: IDomain[];
  handshakeDomains: IDomain[];
  handshakeSubdomains: IDomain[];
  ensDomains: IDomain[];
}

export interface IInputEnvironmentVariable {
  name: string;
  value: string;
  environments: string[];
}

export interface IMultiSelectOption {
  label: string;
  value: string;
}

export interface IDomainDtoPayload {
  projectId: string;
  domainId: string;
  organizationId: string;
  version: string;
  name: string;
  link: string;
  isLatest: boolean;
  type: string;
  deploymentEnvironments: string[];
}

export interface IDomainLoading {
  domainId: string;
  loading: boolean;
}

export interface IDomainResponse {
  success: boolean;
  message: string;
}

export interface ICdnRecordsResponse {
  message: any;
  error: boolean;
  records: ICdnRecords;
}

export interface IProjectCountResponse {
  count: number;
}

export interface IGetAllProjectsResponse {
  projects: IProject[];
}

export interface IProjectResponse extends IProject {
  createdBy: string;
  hookId: string;
}
export interface IUpdateEnvironmentVariableResponse {
  updated: {
    deploymentEnvironments: IDeploymentEnvironment[];
    name: string;
    value: string;
    _id: string;
  };
}

export interface IDeployEnvConfig {
  branches: string[];
  name: string;
  protocol: string;
  viaReservation?: boolean;
  walletId?: string;
  walletAddress?: string;
}
export interface IAddUpdateDeleteProjectDeploymentEnvDtoPayload {
  id: string;
  projectId: string;
  config: IDeployEnvConfig;
  amount?: number;
  walletId?: string;
}

export interface IProjectStatusDeploymentEnvDtoPayload {
  id: string;
  projectId: string;
  envName?: string;
}

export interface IProjectDeploymentEnvResponse {
  branches: string[];
  createdAt: Date;
  name: string;
  protocol: string;
  status: string;
  updatedAt: Date;
  _id: string;
}

export interface ICreateProjectDeploymentEnvResponse {
  newEnvironment: {
    branches: string[];
  };
  status: string;
  _id: string;
  protocol: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface IDeployEnvActionPayloadDto {
  id: string;
  projectId: string;
}
export interface IUpdateBuildConfigPayloadDto {
  configuration: Partial<IConfiguration>;
  id: string;
  projectId: string;
}

export enum ProjectType {
  // eslint-disable-next-line no-unused-vars
  MAINTAINED = 'MAINTAINED',
  // eslint-disable-next-line no-unused-vars
  ARCHIVED = 'ARCHIVED',
}

export interface IUpdateProjectStatePayloadDto {
  projectName: string | undefined;
  body: {
    id: string | undefined;
    body: {
      organizationId: string;
    };
  };
}

export interface IUpdateEnvPayloadDto {
  projectId: string;
  envId: string;
  updateEnvBody: {
    projectId: string;
    name: string;
    environments: string[];
    value: string;
  };
}

export interface IAddEnvPayloadDto {
  projectId: string;
  environmentVariables: IInputEnvironmentVariable[];
}

export interface IRemoveEnvPayloadDto {
  projectId: string;
  envId: string;
}

export interface IDropdownOption {
  label: string;
  value: string;
  environment?: string;
}

export interface IProjectSaveSecurityPayload {
  projectId: string | undefined;
  body: {
    password: string | null;
  };
}

export interface IProjectRemoveSecurityPayload {
  projectId: string | undefined;
}

export interface IProjectCountResponseDto {
  id: string;
  type: string;
}

export interface IUpdateBuildConfigDto {
  id?: string;
  projectId: string;
  configuration: IConfiguration;
}
