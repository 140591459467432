/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Button, Link, Tooltip, OptionType } from '@spheron/ui-library';
import { ReactComponent as CopyIcon } from '@spheron/ui-library/dist/assets/copy.svg';
import { ReactComponent as CircleCheck } from '@spheron/ui-library/dist/assets/tick-circle.svg';
import { ReactComponent as CircleQuestion } from '@spheron/ui-library/dist/assets/spheron-specific/question.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  ICreateInstanceDomainPayloadDto,
  IDeleteInstanceDomainPayloadDto,
} from '../../../redux/instance/instance.interfaces';
import TableStyles from '../../../styles/compute/table.module.scss';
import AddClusterInstanceDomain from '../Forms/add-cluster-domain';
import {
  validateDomain,
  validateSubdomain,
} from '../../../redux/compute/project/project.utils';
import { copyToClipboard } from '../../../redux/compute/user/user.utils';
import {
  updateDomainThunk,
  deleteDomainThunk,
  verifyDomainThunk,
} from '../../../redux/compute/instance/instance.thunks';
import { ClusterInstanceDomain } from '../../../redux/compute/combined-state.interface';
import { truncateText } from '../../../redux/compute/root-utils';
import { AppDispatch, RootState } from '../../../redux/compute/store';

interface IProps {
  index: number;
  type: string;
  domainId: string;
  domain: string;
  link: string;
  isSubdomain: boolean;
  ownerVerified: boolean;
  domainType: string;
  isPublicAccessible: boolean;
  dropdownOptions: { label: string; value: string; optionType: OptionType }[];
}

const InstanceDomainCard: React.FC<IProps> = ({
  index,
  type,
  domainId,
  domain,
  link,
  isSubdomain,
  ownerVerified,
  domainType,
  isPublicAccessible,
  dropdownOptions,
}) => {
  const dispatchRtk = useDispatch<AppDispatch>();

  const activeDeployment = useSelector(
    (state: RootState) => state.instance.activeDeployment
  );

  const deleteComputeDomainLoading = useSelector(
    (state: RootState) => state.instance.deleteDomainLoading
  );

  const verifyComputeDomainLoading = useSelector(
    (state: RootState) => state.instance.verifyDomainLoading
  );

  const updateComputeDomainLoading = useSelector(
    (state: RootState) => state.instance.updateDomainLoading
  );

  const instanceCdnRecords = useSelector(
    (state: RootState) => state.instance.cdnRecords
  );

  const selectedOrgId: string = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation._id
  );

  const activeDeploymentLoading: boolean = useSelector(
    (state: RootState) => state.instance.activeDeploymentLoading
  );

  const selectedInstanceDomainsLoading = useSelector(
    (state: RootState) => state.instance.domainsLoading
  );

  const selectedInstance = useSelector(
    (state: RootState) => state.instance.selectedInstance
  );

  const [editMode, setEditMode] = useState<boolean>(false);
  const [editDomainName, setEditDomainName] = useState<string>(domain);
  const [deployedSite, setDeployedSite] = useState<string>('');
  const [isValidDomain, setIsValidDomain] = useState<boolean>(true);
  const [type1, setType1] = useState<string>('');
  const [name1, setName1] = useState<string>('');
  const [value1, setValue1] = useState<string>('');
  const [copyUrlTooltip, setCopyUrlTooltip] = useState<string>('');

  const deleteComputeDomain = () => {
    const payload: IDeleteInstanceDomainPayloadDto = {
      domainId,
      instanceId: selectedInstance?._id || '',
      organizationId: selectedOrgId,
      domainType: isSubdomain
        ? ClusterInstanceDomain.subdomain
        : ClusterInstanceDomain.domain,
    };
    dispatchRtk(deleteDomainThunk(payload));
  };
  const verifyComputeDomain = () => {
    const payload: IDeleteInstanceDomainPayloadDto = {
      domainId,
      instanceId: selectedInstance!._id,
      organizationId: selectedOrgId,
      domainType: isSubdomain
        ? ClusterInstanceDomain.subdomain
        : ClusterInstanceDomain.domain,
    };
    dispatchRtk(verifyDomainThunk(payload));
  };
  const updateComputeDomain = () => {
    const payload: {
      instanceId: string;
      domainId: string;
      organizationId: string;
      body: ICreateInstanceDomainPayloadDto;
    } = {
      instanceId: selectedInstance!._id,
      domainId,
      organizationId: selectedOrgId,
      body: {
        type: domainType,
        link: deployedSite,
        name: editDomainName,
      },
    };
    if (isSubdomain) {
      if (validateSubdomain(editDomainName)) {
        setIsValidDomain(true);
        dispatchRtk(updateDomainThunk(payload)).then(() => {
          setEditMode(false);
        });
      } else {
        setIsValidDomain(false);
      }
      return;
    }
    if (validateDomain(editDomainName)) {
      setIsValidDomain(true);
      dispatchRtk(updateDomainThunk(payload)).then(() => {
        setEditMode(false);
      });
    } else {
      setIsValidDomain(false);
    }
  };

  const getRecord = (isSubdomain: boolean) => {
    if (isSubdomain) {
      return {
        type: 'CNAME',
        value: instanceCdnRecords.cdnRecord.recordCnameV2,
      };
    }
    return {
      type: 'A',
      value: instanceCdnRecords.cdnRecord.recordIpv4V2,
    };
  };

  useEffect(() => {
    if (copyUrlTooltip) {
      setTimeout(() => {
        setCopyUrlTooltip('');
      }, 2000);
    }
  }, [copyUrlTooltip]);

  const setTableVal = () => {
    const { type, value } = getRecord(isSubdomain);
    setType1(type);
    setName1(domain);
    setValue1(value);
  };

  useEffect(() => {
    setTableVal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDeployment, instanceCdnRecords]);

  useEffect(() => {
    if (link) {
      setDeployedSite(link);
    }
  }, [link]);

  const editDomainLoading = updateComputeDomainLoading.indexOf(domainId) !== -1;
  const deleteDomainLoading =
    deleteComputeDomainLoading.indexOf(domainId) !== -1;
  const verifyDomainLoading =
    verifyComputeDomainLoading.indexOf(domainId) !== -1;

  return (
    <div
      key={index}
      className="border border-base-border rounded-xl dark:border-dark-base-border mb-8"
    >
      {index !== 0 && <div className="dark:bg-dark-base-fg" />}

      {type === 'filled' && (
        <div
          className={`w-auto rounded-md flex flex-col
         
            dark:text-gray-100`}
        >
          <div
            className={`grid grid-cols-12 flex-wrap gap-3 
             border-base-border dark:border-dark-base-border px-6 py-5
             content-between ${!ownerVerified && 'border-b'}`}
          >
            <div
              className="flex items-center justify-between flex-wrap gap-3 col-span-3
              "
            >
              <div className="flex flex-row space-x-2 flex-wrap items-center">
                <div className="">
                  <div className="font-normal text-sm text-text-darkGray">
                    <span className="uppercase">{domainType}</span> NAME
                  </div>
                  <div className="flex flex-row space-x-2 flex-wrap items-center ">
                    <Link
                      type="primary"
                      size="default"
                      text={truncateText(domain)}
                      isBold
                      onClick={() => window.open(`https://${domain}`, '_blank')}
                    />

                    {ownerVerified ? (
                      <CircleCheck className="text-green-600 w-5 h-5" />
                    ) : (
                      <CircleQuestion className="text-red-500 w-5 h-5" />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col col-span-5">
              {!editMode && (
                <>
                  {deployedSite && deployedSite !== 'undefined' && (
                    <>
                      <div className="font-normal text-sm text-text-darkGray">
                        <span className="uppercase">{domainType}</span> POINTS
                        TO
                      </div>
                      <div className="flex flex-row space-x-2 flex-wrap items-center">
                        <Link
                          type="primary"
                          size="default"
                          text={deployedSite}
                          isBold
                          onClick={() =>
                            window.open(`https://${deployedSite}`, '_blank')
                          }
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
            <div className="col-span-4">
              <div
                className="flex justify-end flex-wrap items-center gap-2 
                hover:border-border-btnHover
                hover:text-border-btnHover duration-200"
              >
                {!ownerVerified && (
                  <Button
                    buttonType="primary"
                    label="Verify"
                    size="small"
                    onClick={verifyComputeDomain}
                    loading={verifyDomainLoading}
                    disabled={verifyDomainLoading || isPublicAccessible}
                  />
                )}
                <Button
                  buttonType="ghost"
                  label="Edit"
                  size="small"
                  onClick={() => setEditMode(!editMode)}
                  disabled={isPublicAccessible}
                />
                <Button
                  buttonType="ghost"
                  label="Remove"
                  size="small"
                  onClick={deleteComputeDomain}
                  loading={deleteDomainLoading}
                  disabled={deleteDomainLoading || isPublicAccessible}
                />
              </div>
            </div>
          </div>
          {editMode && (
            <>
              <AddClusterInstanceDomain
                type={ClusterInstanceDomain.domain}
                domainName={editDomainName}
                setDomainName={setEditDomainName}
                isValidDomain={isValidDomain}
                dropdownOptions={dropdownOptions}
                dropdownLoading={
                  activeDeploymentLoading || selectedInstanceDomainsLoading
                }
                handleAddClick={updateComputeDomain}
                buttonDisabled={
                  !editDomainName ||
                  !deployedSite ||
                  (domain === editDomainName && deployedSite === link)
                }
                buttonLoading={editDomainLoading}
                deployedSite={deployedSite}
                setTransaction={setDeployedSite}
                isEditMode
                setIsEditMode={setEditMode}
              />
            </>
          )}

          <div
            className={` border-base-border
           dark:border-dark-base-border ${
             !ownerVerified && 'py-3 border-b'
           } px-6`}
          >
            {!ownerVerified ? (
              <div className="flex flex-row items-center text-sm">
                <span
                  className="text-feedback-error-text
                  py-1 rounded-2xl text-sm bg-bg-verifyDomainBg
                  dark:bg-bg-dark-verifyBg uppercase"
                >
                  Verify your <span className="uppercase">{domainType}</span>
                </span>
                <span className="ml-4 font-normal text-text-darkGray">
                  Set the following record on your DNS provider and click on
                  verify to configure your {domainType}
                </span>
              </div>
            ) : null}
          </div>
          {!ownerVerified && (
            <div
              className="border-b dark:border-dark-base-border border-base-border 
            rounded-b-xl"
            >
              <div
                className={`${TableStyles.domain_table_container} overflow-x-auto
                    dark:border-dark-base-border bg-base-bg dark:bg-dark-base-bg
                      font-medium text-sm`}
              >
                <table className="overflow-x-scroll w-full">
                  <thead
                    className="dark:bg-dark-base-fg bg-base-fg dark:border-dark-base-border
                   border-b border-transparent"
                  >
                    <tr className="bg-bg-offWhiteBg dark:bg-transparent">
                      <th
                        scope="col"
                        className={`${TableStyles.compute_domain_card_type} 
                              px-6 py-3 text-left text-xs font-medium dark:bg-transparent 
                              text-text-darkGray
                              `}
                      >
                        TYPE
                      </th>

                      <th
                        scope="col"
                        className={`${TableStyles.domain_card_name} 
                              px-4 py-3 text-left text-xs font-medium dark:bg-transparent 
                              text-text-darkGray`}
                      >
                        NAME
                      </th>
                      <th
                        scope="col"
                        className={`${TableStyles.domain_card_value} 
                              px-4 py-3 text-left text-xs font-medium dark:bg-transparent 
                              text-text-darkGray`}
                      >
                        VALUE
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        className={`${TableStyles.compute_domain_card_type} 
                              px-6 py-5 whitespace-nowrap text-base`}
                      >
                        <div className="flex items-center">
                          <div
                            className="text-base font-medium
                            text-action-primary-default"
                          >
                            {type1}
                          </div>
                          <div className="text-base text-gray-500" />
                        </div>
                      </td>
                      <td
                        className={`${TableStyles.domain_card_name} 
                              px-4 py-5 whitespace-nowrap text-base-link text-base`}
                      >
                        <a
                          href={`https://${name1}`}
                          target="_blank"
                          rel="noreferrer"
                          className="cursor-pointer"
                        >
                          {name1}
                        </a>
                      </td>
                      <td
                        className={`${TableStyles.domain_card_value} 
                              px-4 py-5 whitespace-nowrap`}
                      >
                        <div className="text-base text-text-darkGray flex">
                          {value1}
                          <div className="flex flex-col-reverse ml-4">
                            <Tooltip
                              position="right"
                              text={copyUrlTooltip}
                              type="float"
                              wrapText
                            >
                              <CopyIcon
                                className="w-5 h-5 cursor-pointer text-base-icon dark:text-dark-base-icon
                                opacity-80 hover:opacity-100"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  copyToClipboard(value1);
                                  setCopyUrlTooltip('Copied');
                                }}
                              />
                            </Tooltip>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      )}
      {type === 'skeleton' && (
        <div className="p-5 my-4 sm:rounded-md dark:bg-dark-base-bg">
          <div className="flex justify-between items-center flex-wrap gap-4">
            <div className="flex items-center">
              <Skeleton
                width={200}
                height={24}
                duration={2}
                containerClassName="flex"
              />
            </div>
            <div className="flex gap-4 items-center">
              <Skeleton
                className="lg:ml-7"
                width={90}
                height={36}
                duration={2}
                containerClassName="flex"
              />
              <Skeleton
                className="lg:ml-7"
                width={90}
                height={36}
                duration={2}
                containerClassName="flex"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InstanceDomainCard;
