import { requestPipeline } from '../../root-utils';
import { IResponseError } from '../../combined-reducer.interface';
import { IAgreedGpuMachineImage } from '../instance.interfaces';
import {
  IAvailableInventoryResponse,
  IClusterRegionResponse,
  IPlanByIdService,
  IPricingTargetsResponse,
  ScalingType,
} from './instance-creation.interfaces';

export const getClusterRegionService = async (
  machineType: number
): Promise<IClusterRegionResponse> => {
  return requestPipeline({
    url: `cluster-available-hardware/regions?spheronComputeType=${machineType}`,
    method: 'GET',
    isPublic: true,
  });
};

export const getInstancePlansService = async (
  skip: number,
  limit: number,
  searchParams: string,
  payload: {
    region: string;
    scalingType: ScalingType;
    provider: string;
    machineType: number;
  }
): Promise<IAgreedGpuMachineImage[] | IResponseError> => {
  return requestPipeline({
    // eslint-disable-next-line max-len
    url: `compute-machine-image?&skip=${skip}&limit=${limit}&name=${searchParams}&region=${payload.region}&scalingMode=${payload.scalingType}&provider=${payload.provider}&machineImageType=${payload.machineType}`,
    method: 'GET',
  });
};

export const getPlanByIdService = async (
  id: string,
  payload: {
    region: string;
    scalingType: ScalingType;
    provider: string;
    machineType: number;
  }
): Promise<IPlanByIdService> => {
  return requestPipeline({
    // eslint-disable-next-line max-len
    url: `compute-machine-image/id/${id}?region=${payload.region}&scalingMode=${payload.scalingType}&provider=${payload.provider}&machineImageType=${payload.machineType}`,
    method: 'GET',
  });
};

// Infer better type from usage
export const customPriceService = async (payload: {
  customCpu: string;
  customRam: string;
  customStorage: number;
  customHddStorage: number;
  customSddStorage: number;
  customNvmStorage: number;
  region: string;
  scalingMode: ScalingType;
  organisationId: string;
  templateId: string;
}): Promise<any> => {
  return requestPipeline({
    url: `compute-machine-image/getPriceForResources`,
    method: 'POST',
    body: JSON.stringify({
      cpuRequested: payload.customCpu,
      memoryRequested: payload.customRam,
      ephemeralStorageRequested: payload.customStorage,
      hddPersStorageRequested: payload.customHddStorage,
      ssdPersStorageRequested: payload.customSddStorage,
      nvmePersStorageRequested: payload.customNvmStorage,
      endpointsRequested: 0,
      ipsRequested: 0,
      region: payload.region,
      provider: '',
      scalingMode: payload.scalingMode,
      availableCheck: true,
      organizationId: payload.organisationId,
      templateId: payload.templateId,
    }),
  });
};

export const getDefaultCustomPlans = async (payload: {
  region: string;
  provider: string;
  scalingMode: ScalingType;
}): Promise<any> => {
  return requestPipeline({
    // eslint-disable-next-line max-len
    url: `cluster-available-hardware?region=${payload.region}&provider=${
      payload.provider
    }&scalingMode=${payload.scalingMode || ScalingType.NO}`,
    method: 'GET',
  });
};

export const getInstanceUnitPrices = async (): Promise<
  IPricingTargetsResponse | IResponseError
> => {
  return requestPipeline({
    url: 'pricing-targets',
    method: 'GET',
  });
};

export const getAvailableInventory = async (): Promise<
  IAvailableInventoryResponse | IResponseError
> => {
  return requestPipeline({
    url: 'cluster-available-hardware/providers',
    method: 'GET',
  });
};
