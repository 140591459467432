import React from 'react';
import { Button } from '@spheron/ui-library';

interface IProps {
  memberCount: number;
  memberPrice: number;
  handleClick: () => void;
  disableButton: boolean;
}

const BonusMembers = ({
  memberCount,
  memberPrice,
  handleClick,
  disableButton,
}: IProps) => {
  return (
    <div className="bg-bg-addMember py-9 px-10 relative overflow-hidden rounded-2xl">
      <div className="flex items-center justify-between">
        <div className=" w-8/12">
          <h3
            className="text-xl font-semibold
            text-base-heading-text-color dark:text-dark-base-heading-text-color"
          >
            Buy more members seat for your team
          </h3>
          <div className="mt-3 font-normal text-sm">
            Your organisation have{' '}
            <span className="font-bold">{memberCount} members</span> seat.
            Invite your team to collaborate on your product together!
          </div>
          <div className="mt-6">
            <Button
              buttonType="primary"
              label="Add members"
              size="medium"
              onClick={handleClick}
              disabled={disableButton}
            />
          </div>
        </div>
        <div className="flex flex-col items-end justify-center z-20">
          <h1 className="text-4xl font-bold text-white">${memberPrice}</h1>
          <h5 className="text-white font-normal text-sm mt-4">per member</h5>
        </div>
      </div>

      <div
        className="absolute -top-12 -right-20 w-72 h-72 rounded-full z-10
        bg-base-fg dark:bg-dark-base-fg"
      />
      <div
        className="absolute -bottom-64 -right-4 w-96 h-96 rounded-full
                bg-bg-addMemberCircle dark:bg-dark-black-site"
      />
    </div>
  );
};

export default BonusMembers;
