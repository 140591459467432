import React, { Fragment, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import { IUserNotification } from '../../../redux/compute/user/user.interfaces';
import {
  loadMoreUserNotificationsThunk,
  readAllNotificationsThunk,
} from '../../../redux/compute/user/user.thunks';
import { AppDispatch, RootState } from '../../../redux/compute/store';

interface IProps {
  menuButton: JSX.Element;
  menuItems: JSX.Element[];
  isUnreadNotification: boolean;
}

export default function NotificationDropdown({
  menuButton,
  menuItems,
  isUnreadNotification,
}: IProps) {
  const dispatchRtk = useDispatch<AppDispatch>();

  const userNotifications: IUserNotification[] = useSelector(
    (state: RootState) => state.user.notifications
  );

  const [prevNotificationLength, setPrevNotificationLength] =
    useState<number>();
  const [showLoadMore, setShowLoadMore] = useState<boolean>(true);

  const loadMoreNotification = () => {
    setPrevNotificationLength(userNotifications?.length);
    dispatchRtk(loadMoreUserNotificationsThunk());
  };

  useEffect(() => {
    if (prevNotificationLength === userNotifications?.length)
      setShowLoadMore(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userNotifications]);

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div className="flex">
        <Menu.Button>{menuButton}</Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          style={{ maxHeight: '60vh' }}
          className="origin-top-right absolute right-0 mt-2 w-full min-w-max
          overflow-y-scroll
          rounded-lg shadow-lg
          bg-base-bg dark:bg-dark-base-bg text-base-text dark:text-dark-base-text
          border border-cards-border dark:border-dark-cards-border
          ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <div className="pt-1 mt-1.5">
            {isUnreadNotification && (
              <div
                role="presentation"
                onKeyDown={() => {}}
                onClick={() => {
                  dispatchRtk(readAllNotificationsThunk());
                }}
                className="cursor-pointer capitalize 
                text-base-link dark:text-dark-base-link
                flex justify-end px-3 pb-3 text-xs -mb-2 font-medium"
              >
                Mark all as read
              </div>
            )}

            {(menuItems as any).map((item: JSX.Element, index: number) => (
              <Menu.Item key={item.key}>
                {({ active }) => (
                  <div
                    role="button"
                    tabIndex={index}
                    onKeyDown={() => {}}
                    className={`${
                      active &&
                      'hover:bg-cards-hover dark:hover:bg-dark-cards-hover'
                    }`}
                  >
                    <div className="px-4 py-2 hover:bg-cards-hover dark:hover:bg-dark-cards-hover">
                      {' '}
                      {item}
                    </div>
                    <div
                      className={`border-gray-200
                      dark:border-gray-700 dark:border-opacity-50 ${
                        index !== userNotifications?.length - 1 && 'border-b'
                      }`}
                    />
                  </div>
                )}
              </Menu.Item>
            ))}
            {showLoadMore && (
              <div className="flex justify-end items-center py-2 px-3">
                <div
                  role="presentation"
                  onKeyDown={() => {}}
                  onClick={loadMoreNotification}
                  className="cursor-pointer capitalize text-base-link dark:text-dark-base-link
                  text-xs font-medium"
                >
                  Load More
                </div>
              </div>
            )}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
