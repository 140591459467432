import React from 'react';
import Skeleton from 'react-loading-skeleton';

interface IProps {
  isInstanceDashboard: boolean;
}

const ComputeDashboardBreadcrumbLoading = ({ isInstanceDashboard }: IProps) => {
  return (
    <div className="w-[95%] mx-auto max-w-[1400px] mt-1.5">
      <div className="flex items-center justify-between">
        <Skeleton height={28} width={120} duration={2} />
        <div className="flex items-center justify-start gap-x-4">
          <Skeleton height={40} width={180} duration={2} />
          {isInstanceDashboard && (
            <div className="flex items-center justify-between gap-x-3">
              <Skeleton height={16} width={40} duration={2} />
              <Skeleton height={42} width={50} duration={2} />
            </div>
          )}
          <Skeleton height={44} width={200} duration={2} />
        </div>
      </div>
    </div>
  );
};

export default ComputeDashboardBreadcrumbLoading;
