import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { upload } from '@spheron/browser-upload';
import {
  getAllBuckets,
  getAllBucketsCount,
  getAllBucketsNames,
} from '../organisation/organisation.services';
import {
  getBucketUploads,
  getBucketUploadsCount,
  initiateUpload,
  getBucketIpnsRecords,
  publishBucketIpnsRecord,
  updateBucketIpnsRecord,
  updateBucketState,
  getSelectedBucket,
  pinBucketUpload,
  unpinBucketUpload,
  getBucketUploadsCids,
  addBucketDomains,
  getBucketDomains,
  getBucketDomainsEnabled,
  getBucketUploadsLinks,
  updateBucketDomains,
  verifyBucketDomains,
  deleteBucketDomains,
  deleteSelectedBucket,
  deleteBucketUpload,
  deleteBucketIpnsRecord,
  fetchBucketCdnRecords,
  initiateCidUpload,
} from './storage.services';
import {
  BucketType,
  IBucket,
  IBucketPayload,
  IGetAllBucketsPayload,
  IGetBucketUploadsPayload,
  IBucketIpnsPayload,
  IBucketDomainsPayload,
  IStorage,
} from './storage.interfaces';
import { addNotificationRtk } from '../notification/notification.slice';
import { NotificationType } from '../notification/notification.interfaces';
import { IResponseError } from '../combined-reducer.interface';
import { toggleModalShowRtk } from '../modal/modal.slice';
import config from '../../config';
// eslint-disable-next-line import/no-cycle
import {
  setCurrentlyUploaded,
  setShowUploadCard,
  toggleShowUploadModalLoading,
} from './storage.slice';

export const getAllBucketsThunk = createAsyncThunk(
  'storage/getAllBuckets',
  async (
    payload: Partial<IGetAllBucketsPayload>,
    { dispatch, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const { organisationId, skip, limit, state, name, id, search, loadMore } =
        payload;
      const response: any = await getAllBuckets(
        organisationId as string,
        skip,
        limit,
        state,
        name,
        id,
        search
      );
      if (response?.error) {
        dispatch(
          addNotificationRtk({
            message: (response as IResponseError).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      return fulfillWithValue({
        buckets: response?.buckets,
        loadMore: !!loadMore,
      });
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getAllBucketsCountThunk = createAsyncThunk(
  'storage/getAllBucketsCount',
  async (
    payload: Partial<IGetAllBucketsPayload>,
    { dispatch, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const { organisationId, state, search } = payload;
      const response: any = await getAllBucketsCount(
        organisationId as string,
        state,
        search
      );
      if (response?.error) {
        dispatch(
          addNotificationRtk({
            message: (response as IResponseError).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      return fulfillWithValue(response?.count);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getAllBucketsNamesThunk = createAsyncThunk(
  'storage/getAllBucketsNames',
  async (
    payload: Partial<IGetAllBucketsPayload>,
    { dispatch, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const { organisationId, state } = payload;
      const response: any = await getAllBucketsNames(
        organisationId as string,
        state
      );
      if (response?.error) {
        dispatch(
          addNotificationRtk({
            message: (response as IResponseError).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      return fulfillWithValue(response.bucketNames);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const loadBucketCdnRecordsThunk = createAsyncThunk(
  'project/loadCdnRecords',
  async (payload: string, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const bucketId = payload;
      if (!bucketId) {
        throw new Error('Bucket ID is undefined');
      }
      const response = await fetchBucketCdnRecords(bucketId);
      if (!response.error) {
        return fulfillWithValue(response.records);
      }
      dispatch(
        addNotificationRtk({
          message: (response as IResponseError).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getSelectedBucketThunk = createAsyncThunk(
  'storage/getSelectedBucket',
  async (payload: string, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const response: any = await getSelectedBucket(payload);
      if (response?.error) {
        dispatch(
          addNotificationRtk({
            message: (response as IResponseError).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      dispatch(loadBucketCdnRecordsThunk(payload));
      return fulfillWithValue(response);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const updateBucketStateThunk = createAsyncThunk(
  'storage/updateBucketState',
  async (
    payload: Partial<IBucketPayload>,
    { dispatch, getState, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const { bucketId, state } = payload;
      const response: any = await updateBucketState(bucketId as string, state);
      if (response?.error) {
        dispatch(
          addNotificationRtk({
            message: (response as IResponseError).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        dispatch(
          toggleModalShowRtk({
            modalShow: false,
          })
        );
        return rejectWithValue({});
      }
      const { organisation } = getState() as {
        organisation: { selectedOrganisation?: { _id: string } };
      };
      const organisationId = organisation.selectedOrganisation?._id;
      dispatch(
        getAllBucketsCountThunk({
          organisationId,
          state: BucketType.MAINTAINED,
        })
      );
      dispatch(
        getAllBucketsThunk({
          organisationId,
          state: BucketType.MAINTAINED,
        })
      );
      dispatch(
        getAllBucketsCountThunk({
          organisationId,
          state: BucketType.ARCHIVED,
        })
      );
      dispatch(
        getAllBucketsThunk({
          organisationId,
          state: BucketType.ARCHIVED,
        })
      );
      dispatch(
        addNotificationRtk({
          message: `Successfully ${
            state === BucketType.MAINTAINED ? 'unarchived' : 'archived'
          } bucket: ${response?.bucket.name}`,
          timestamp: Date.now(),
          type: NotificationType.Success,
        })
      );
      dispatch(
        toggleModalShowRtk({
          modalShow: false,
        })
      );
      return fulfillWithValue(response?.bucket);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const deleteBucketThunk = createAsyncThunk(
  'storage/deleteBucket',
  async (
    payload: Partial<IBucketPayload>,
    { dispatch, getState, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const { bucketId, bucketName, limit, state } = payload;
      const response: any = await deleteSelectedBucket(bucketId as string);
      if (response?.error) {
        dispatch(
          addNotificationRtk({
            message: (response as IResponseError).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        dispatch(
          toggleModalShowRtk({
            modalShow: false,
          })
        );
        return rejectWithValue({});
      }
      const { organisation } = getState() as {
        organisation: { selectedOrganisation?: { _id: string } };
      };
      const organisationId = organisation.selectedOrganisation?._id;
      dispatch(
        addNotificationRtk({
          message: `Successfully deleted bucket: ${bucketName}`,
          timestamp: Date.now(),
          type: NotificationType.Success,
        })
      );
      dispatch(
        toggleModalShowRtk({
          modalShow: false,
        })
      );
      dispatch(
        getAllBucketsCountThunk({
          organisationId,
          state,
        })
      );
      dispatch(
        getAllBucketsThunk({
          organisationId,
          limit,
          state,
        })
      );
      return fulfillWithValue(response?.bucket);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getBucketUploadsThunk = createAsyncThunk(
  'storage/getBucketUploads',
  async (
    payload: Partial<IGetBucketUploadsPayload>,
    { dispatch, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const { bucketId, skip, limit, status, loadMore } = payload;
      const response: any = await getBucketUploads(
        bucketId as string,
        skip,
        limit,
        status
      );
      if (response?.error) {
        dispatch(
          addNotificationRtk({
            message: (response as IResponseError).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      return fulfillWithValue({
        uploads: response,
        loadMore: !!loadMore,
      });
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getBucketUploadsCountThunk = createAsyncThunk(
  'storage/getBucketUploadsCount',
  async (payload: string, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const response: any = await getBucketUploadsCount(payload);
      if (response?.error) {
        dispatch(
          addNotificationRtk({
            message: (response as IResponseError).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      return fulfillWithValue(response?.count);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getBucketUploadsCidsThunk = createAsyncThunk(
  'storage/getBucketUploadsCids',
  async (payload: string, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const response: any = await getBucketUploadsCids(payload);
      if (response?.error) {
        dispatch(
          addNotificationRtk({
            message: (response as IResponseError).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      return fulfillWithValue(response?.uploadCidMap);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getBucketUploadsLinksThunk = createAsyncThunk(
  'storage/getBucketUploadsLinks',
  async (payload: string, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const response: any = await getBucketUploadsLinks(payload);
      if (response?.error) {
        dispatch(
          addNotificationRtk({
            message: (response as IResponseError).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      return fulfillWithValue(response?.links);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const uploadBucketThunk = createAsyncThunk(
  'storage/uploadBucket',
  async (
    payload: any,
    { dispatch, getState, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      dispatch(toggleShowUploadModalLoading(true));

      const { orgId, bucketName, protocol, files, callbackFn } = payload;

      const { organisation } = getState() as {
        organisation: { selectedOrganisation?: { _id: string } };
      };
      const { bucketsNames } = (
        getState() as { storage: { bucketsNames: string[] | null } }
      ).storage;
      const organisationId = organisation.selectedOrganisation?._id;
      const isExistingBucket = bucketsNames?.includes(bucketName);

      const response: any = await initiateUpload(orgId, bucketName, protocol);
      if (response?.singleUseToken) {
        dispatch(
          toggleModalShowRtk({
            modalShow: false,
          })
        );
        dispatch(toggleShowUploadModalLoading(false));
        dispatch(setShowUploadCard(true));

        if (isExistingBucket) {
          const { selectedBucket } = (
            getState() as { storage: { selectedBucket: IBucket } }
          ).storage;
          if (selectedBucket) {
            dispatch(getBucketUploadsCountThunk(selectedBucket._id));
            dispatch(
              getBucketUploadsThunk({
                bucketId: selectedBucket?._id,
              })
            );
          }
        } else {
          dispatch(
            getAllBucketsCountThunk({
              organisationId,
            })
          );
          dispatch(
            getAllBucketsThunk({
              organisationId,
            })
          );
        }
        const { storage } = getState() as {
          storage: IStorage;
        };
        let tempUploaded = storage.uploadFileStatus.currentlyUploaded;

        const uploadResponse = await upload(files, {
          token: response.singleUseToken,
          apiUrl: config.urls.API_UPLOAD_URL,
          onChunkUploaded: (uploadedSize, totalSize) => {
            tempUploaded += uploadedSize;
            dispatch(
              setCurrentlyUploaded({
                currentlyUploaded: tempUploaded,
                totalSize,
              })
            );
          },
        });

        if (isExistingBucket) {
          const { selectedBucket } = (
            getState() as { storage: { selectedBucket: IBucket } }
          ).storage;
          if (selectedBucket) {
            dispatch(getBucketUploadsCountThunk(selectedBucket._id));
            dispatch(
              getBucketUploadsThunk({
                bucketId: selectedBucket?._id,
              })
            );
          }
        } else {
          dispatch(
            getAllBucketsCountThunk({
              organisationId,
            })
          );
          dispatch(
            getAllBucketsThunk({
              organisationId,
            })
          );
        }
        callbackFn?.();

        dispatch(
          toggleModalShowRtk({
            modalShow: false,
          })
        );
        dispatch(
          addNotificationRtk({
            message: isExistingBucket
              ? `Upload Successful for bucket: ${bucketName}`
              : `Successfully created bucket: ${bucketName}`,
            timestamp: Date.now(),
            type: NotificationType.Success,
          })
        );
        return fulfillWithValue({
          response: uploadResponse,
          id: payload.id,
        });
      }
      dispatch(
        addNotificationRtk({
          message: (response as IResponseError).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({ id: payload.id });
    } catch (error) {
      dispatch(
        addNotificationRtk({
          message: (error as Error).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      Sentry.captureException(error);
      return rejectWithValue({ id: payload.id });
    }
  }
);

export const pinCidBucketThunk = createAsyncThunk(
  'storage/pinCidBucket',
  async (
    payload: { orgId: string; bucketName: string; cid: string },
    { dispatch, getState, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const { orgId, bucketName, cid } = payload;
      const response: any = await initiateCidUpload(orgId, bucketName, cid);
      if (!response.error) {
        const { organisation } = getState() as {
          organisation: { selectedOrganisation?: { _id: string } };
        };
        const { bucketsNames } = (
          getState() as { storage: { bucketsNames: string[] | null } }
        ).storage;
        const organisationId = organisation.selectedOrganisation?._id;
        const isExistingBucket = bucketsNames?.includes(bucketName);
        if (isExistingBucket) {
          const { selectedBucket } = (
            getState() as { storage: { selectedBucket: IBucket } }
          ).storage;
          if (selectedBucket) {
            dispatch(getBucketUploadsCountThunk(selectedBucket._id));
            dispatch(
              getBucketUploadsThunk({
                bucketId: selectedBucket?._id,
              })
            );
          }
        } else {
          dispatch(
            getAllBucketsCountThunk({
              organisationId,
            })
          );
          dispatch(
            getAllBucketsThunk({
              organisationId,
            })
          );
        }
        dispatch(
          toggleModalShowRtk({
            modalShow: false,
          })
        );
        dispatch(
          addNotificationRtk({
            message: isExistingBucket
              ? `Pinning Successful for bucket: ${bucketName}`
              : `Successfully created bucket: ${bucketName}`,
            timestamp: Date.now(),
            type: NotificationType.Success,
          })
        );
        return fulfillWithValue({});
      }
      dispatch(
        addNotificationRtk({
          message: (response as IResponseError).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      dispatch(
        addNotificationRtk({
          message: (error as Error).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const pinBucketUploadThunk = createAsyncThunk(
  'storage/pinBucketUpload',
  async (
    payload: string,
    { dispatch, getState, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response: any = await pinBucketUpload(payload);
      const { selectedBucket } = (
        getState() as { storage: { selectedBucket: IBucket } }
      ).storage;
      if (!response.error) {
        dispatch(
          addNotificationRtk({
            message: `Pinning Upload ID: ${response.upload._id}`,
            timestamp: Date.now(),
            type: NotificationType.Success,
          })
        );
        dispatch(
          toggleModalShowRtk({
            modalShow: false,
          })
        );
        dispatch(
          getBucketUploadsThunk({
            bucketId: selectedBucket?._id,
          })
        );
        return fulfillWithValue(response);
      }
      dispatch(
        addNotificationRtk({
          message: (response as IResponseError).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      dispatch(
        toggleModalShowRtk({
          modalShow: false,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const unpinBucketUploadThunk = createAsyncThunk(
  'storage/unpinBucketUpload',
  async (
    payload: string,
    { dispatch, getState, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response: any = await unpinBucketUpload(payload);
      const { selectedBucket } = (
        getState() as { storage: { selectedBucket: IBucket } }
      ).storage;
      if (!response.error) {
        dispatch(
          addNotificationRtk({
            message: `Unpinning Upload ID: ${response.upload._id}`,
            timestamp: Date.now(),
            type: NotificationType.Success,
          })
        );
        dispatch(
          toggleModalShowRtk({
            modalShow: false,
          })
        );
        dispatch(
          getBucketUploadsThunk({
            bucketId: selectedBucket?._id,
          })
        );
        return fulfillWithValue(response);
      }
      dispatch(
        addNotificationRtk({
          message: (response as IResponseError).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      dispatch(
        toggleModalShowRtk({
          modalShow: false,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const deleteBucketUploadThunk = createAsyncThunk(
  'storage/deleteBucketUpload',
  async (
    payload: string,
    { dispatch, getState, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response: any = await deleteBucketUpload(payload);
      const { selectedBucket } = (
        getState() as { storage: { selectedBucket: IBucket } }
      ).storage;
      if (!response?.error) {
        dispatch(
          addNotificationRtk({
            message: `Deleted Upload ID: ${payload}`,
            timestamp: Date.now(),
            type: NotificationType.Success,
          })
        );
        dispatch(
          toggleModalShowRtk({
            modalShow: false,
          })
        );
        dispatch(
          getBucketUploadsThunk({
            bucketId: selectedBucket?._id,
          })
        );
        dispatch(getBucketUploadsCountThunk(selectedBucket?._id));
        return fulfillWithValue(response);
      }
      dispatch(
        addNotificationRtk({
          message: (response as IResponseError).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      dispatch(
        toggleModalShowRtk({
          modalShow: false,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getBucketIpnsRecordsThunk = createAsyncThunk(
  'storage/getBucketIpnsRecords',
  async (payload: string, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const response: any = await getBucketIpnsRecords(payload);
      if (response?.error) {
        dispatch(
          addNotificationRtk({
            message: (response as IResponseError).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      return fulfillWithValue(response.ipnsRecords);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const publishBucketIpnsRecordThunk = createAsyncThunk(
  'storage/publishBucketIpnsRecord',
  async (
    payload: Partial<IBucketIpnsPayload>,
    { dispatch, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response: any = await publishBucketIpnsRecord(
        payload.bucketId as string,
        payload.uploadConfig
      );
      if (response?.error) {
        dispatch(
          addNotificationRtk({
            message: (response as IResponseError).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      dispatch(getBucketIpnsRecordsThunk(payload.bucketId as string));
      return fulfillWithValue(response);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const updateBucketIpnsRecordThunk = createAsyncThunk(
  'storage/updateBucketIpnsRecord',
  async (
    payload: IBucketIpnsPayload,
    { dispatch, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response: any = await updateBucketIpnsRecord(
        payload.bucketId,
        payload.ipnsRecordId,
        payload.uploadConfig
      );
      if (response?.error) {
        dispatch(
          addNotificationRtk({
            message: (response as IResponseError).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      dispatch(getBucketIpnsRecordsThunk(payload.bucketId));
      return fulfillWithValue(response);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const deleteBucketIpnsRecordThunk = createAsyncThunk(
  'storage/deleteBucketIpnsRecord',
  async (
    payload: Partial<IBucketIpnsPayload>,
    { dispatch, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response: any = await deleteBucketIpnsRecord(
        payload.bucketId as string,
        payload.ipnsRecordId as string
      );
      if (response?.error) {
        dispatch(
          addNotificationRtk({
            message: (response as IResponseError).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        dispatch(
          toggleModalShowRtk({
            modalShow: false,
          })
        );
        return rejectWithValue({});
      }
      dispatch(
        addNotificationRtk({
          message: `Successfully deleted IPNS Record: ${payload.ipnsRecordId}`,
          timestamp: Date.now(),
          type: NotificationType.Success,
        })
      );
      dispatch(
        toggleModalShowRtk({
          modalShow: false,
        })
      );
      dispatch(getBucketIpnsRecordsThunk(payload.bucketId as string));
      return fulfillWithValue(response);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getBucketDomainsEnabledThunk = createAsyncThunk(
  'storage/getBucketDomainsEnabled',
  async (payload: string, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const response: any = await getBucketDomainsEnabled(payload);
      if (response?.error) {
        dispatch(
          addNotificationRtk({
            message: (response as IResponseError).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      return fulfillWithValue(response.enabled);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getBucketDomainsThunk = createAsyncThunk(
  'storage/getBucketDomains',
  async (payload: string, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const response: any = await getBucketDomains(payload);
      if (response?.error) {
        dispatch(
          addNotificationRtk({
            message: (response as IResponseError).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      return fulfillWithValue(response.domains);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const addBucketDomainsThunk = createAsyncThunk(
  'storage/addBucketDomains',
  async (
    payload: Partial<IBucketDomainsPayload>,
    { dispatch, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const { bucketId, domainConfig } = payload;
      const response: any = await addBucketDomains(
        bucketId as string,
        domainConfig
      );
      if (response?.error) {
        dispatch(
          addNotificationRtk({
            message: (response as IResponseError).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      dispatch(getBucketDomainsThunk(bucketId as string));
      return fulfillWithValue(response);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const updateBucketDomainsThunk = createAsyncThunk(
  'storage/updateBucketDomains',
  async (
    payload: Partial<IBucketDomainsPayload>,
    { dispatch, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const { bucketId, domainId, domainConfig } = payload;
      const response: any = await updateBucketDomains(
        bucketId as string,
        domainId as string,
        domainConfig
      );
      if (response?.error) {
        dispatch(
          addNotificationRtk({
            message: (response as IResponseError).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      dispatch(getBucketDomainsThunk(bucketId as string));
      return fulfillWithValue(response);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const verifyBucketDomainsThunk = createAsyncThunk(
  'storage/verifyBucketDomains',
  async (
    payload: Partial<IBucketDomainsPayload>,
    { dispatch, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const { bucketId, domainId } = payload;
      const response: any = await verifyBucketDomains(
        bucketId as string,
        domainId as string
      );
      if (response?.error) {
        dispatch(
          addNotificationRtk({
            message: (response as IResponseError).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      dispatch(getBucketDomainsThunk(bucketId as string));
      return fulfillWithValue(response);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const deleteBucketDomainsThunk = createAsyncThunk(
  'storage/deleteBucketDomains',
  async (
    payload: Partial<IBucketDomainsPayload>,
    { dispatch, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const { bucketId, domainId } = payload;
      const response: any = await deleteBucketDomains(
        bucketId as string,
        domainId as string
      );
      if (response?.error) {
        dispatch(
          addNotificationRtk({
            message: (response as IResponseError).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      dispatch(getBucketDomainsThunk(bucketId as string));
      return fulfillWithValue(response);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);
