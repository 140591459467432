import React from 'react';
import { Modal } from '@spheron/ui-library';
import { useDispatch } from 'react-redux';
import Styles from '../../../styles/compute/modal.module.scss';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import ModalWrapper from './ModalWrapper';
import { AppDispatch } from '../../../redux/rtk-store';

interface IProps {
  isOpen: boolean;
  applicableFor: string;
}

const SpheronCreditApplicableList = ({ isOpen, applicableFor }: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={() => dispatchRtk(toggleModalShowRtk({ modalShow: false }))}
      classname={`${Styles.modal__outer__con} w-430 modal fixed inset-0 overflow-y-auto`}
    >
      <div className={Styles.modal__inner__content}>
        <Modal
          footer
          header
          subtitle=""
          modalContent={
            <div className="flex capitalize">&#x2022; {applicableFor}</div>
          }
          headerContent={<>Applicable Credit Amount</>}
          primaryButtonContent="Got it"
          secondaryButtonContent="Cancel"
          primaryButton
          primaryButtonLoading={false}
          secondaryButtonDisabled={false}
          secondaryButton={false}
          onPrimaryButtonClick={() =>
            dispatchRtk(toggleModalShowRtk({ modalShow: false }))
          }
          onSecondaryButtonClick={() => {}}
        />
      </div>
    </ModalWrapper>
  );
};

export default SpheronCreditApplicableList;
