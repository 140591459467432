/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from '@spheron/ui-library';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import CreateNewMultiServiceInstance from '../../../../../components/Compute/Sections/create-new-multiservice-instance';

const CreateNewMultiServiceInstanceSection = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-y-5">
      <div className="flex flex-col gap-y-3">
        <h3
          className="text-base-heading-text-color dark:text-dark-base-heading-text-color
            text-xl font-medium leading-6"
        >
          2. Spheron CLI
        </h3>
        <p className="text-base-sub-text-color dark:text-dark-base-sub-text-color text-sm">
          Deploy multiple services at once within your instance using the CLI
          for more flexibility and control.
        </p>
      </div>
      <CreateNewMultiServiceInstance />
      <Link
        type="primary"
        size="default"
        text="View all your deployed instances"
        isBold={false}
        onClick={() => navigate(`/compute/dashboard`)}
      />
    </div>
  );
};

export default CreateNewMultiServiceInstanceSection;
