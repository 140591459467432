import {
  Dropdown,
  EmptyState,
  InfiniteScroll,
  OptionType,
  ProjectCard,
} from '@spheron/ui-library';
import React, { Fragment, useEffect, useMemo, useRef } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as MoreIcon } from '@spheron/ui-library/dist/assets/more.svg';
import { ReactComponent as NoSearchFound } from '../../../../assets/compute/icons/no-search-found.svg';
import ComputeDashboardLoading from '../../../../components/Compute/Loaders/compute-dashboard-loading';
import LoadMore from '../../../../components/Compute/LoadMore/load-more';
import { withLoader } from '../../../../redux/compute/root-utils';
import { IComputeProject } from '../../../../redux/compute/project/project.interfaces';
import { AppDispatch, RootState } from '../../../../redux/compute/store';
import {
  deleteComputeProjectThunk,
  loadMoreComputeProjectsThunk,
} from '../../../../redux/compute/project/project.thunks';
import {
  resetComputeProjectInstancePaginationState,
  setSelectedComputeProjectRtk,
  setSelectedProjectName,
  toggleSelectedComputeProjectLoading,
  toggleShouldFetchComputeProjectInstances,
} from '../../../../redux/compute/project/project.slice';
import { loadCdnRecordsThunk } from '../../../../redux/instance/instance.thunks';
import {
  toggleShouldFetchDeploymentDetails,
  toggleShouldFetchInstanceDetails,
} from '../../../../redux/compute/instance/instance.slice';
import { toggleModalShowRtk } from '../../../../redux/compute/modal/modal.slice';
import { canDeleteProject } from '../../../../redux/compute/project/project.utils';
import {
  IInstanceReport,
  IUser,
} from '../../../../redux/compute/combined-state.interface';
import DashboardCardsLoading from '../../../../components/Compute/Loaders/dashboard-cards-loading';

interface IProps {
  searchValue: string;
  // eslint-disable-next-line no-unused-vars
  setSelectedInstance: (instances: []) => void;
  handleNewProjectClick: () => void;
}

const ProjectDashboard = ({
  searchValue,
  setSelectedInstance,
  handleNewProjectClick,
}: IProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { orgUsername } = useParams<{ orgUsername: string }>();
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const currentUser = useSelector((state: RootState) => state.user.user);
  const selectedOrganisation = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation
  );
  const selectedOrganisationLoading = useSelector(
    (state: RootState) => state.organisation.selectedOrganisationLoading
  );
  const projectsLoading = useSelector(
    (state: RootState) => state.computeProject.projectsLoading
  );
  const projects = useSelector(
    (state: RootState) => state.computeProject.projects
  );

  const loadMoreProjectsLoading = useSelector(
    (state: RootState) => state.computeProject.loadMoreProjectsLoading
  );

  const allProjectsCount = useSelector(
    (state: RootState) => state.computeProject.allProjectsCount
  );

  const allProjectsCountLoading = useSelector(
    (state: RootState) => state.computeProject.allProjectsCountLoading
  );

  const handleLoadMore = () => {
    dispatch(
      loadMoreComputeProjectsThunk({
        organisationId: selectedOrganisation._id,
        topupReport: 'n',
      })
    ) as any;
  };

  const handleProjectCardClick = (computeProjectId: string) => {
    dispatch(resetComputeProjectInstancePaginationState());
    const selectedProject = projects.find(
      (project: { _id: string }) => project._id === computeProjectId
    )!;
    dispatch(setSelectedComputeProjectRtk(selectedProject));
    dispatch(setSelectedProjectName(selectedProject.name));
    dispatch(toggleSelectedComputeProjectLoading(false));
    dispatch(loadCdnRecordsThunk(computeProjectId));
    navigate(`/${orgUsername}/project/${computeProjectId}`);
  };

  useEffect(() => {
    setSelectedInstance([]);
    dispatch(toggleShouldFetchComputeProjectInstances(true));
    dispatch(toggleShouldFetchInstanceDetails(true));
    dispatch(toggleShouldFetchDeploymentDetails(true));
  }, [dispatch, setSelectedInstance]);

  const projectDropdownOptions = (
    name: string,
    id: string,
    instanceReport: IInstanceReport
  ) => {
    return canDeleteProject(
      currentUser as IUser,
      selectedOrganisation.users,
      instanceReport
    )
      ? [
          {
            label: 'Delete Project',
            handleClick: () => {
              dispatch(
                toggleModalShowRtk({
                  modalShow: true,
                  modalType: 'deleteResource',
                  options: {
                    handleClick: () => {
                      dispatch(deleteComputeProjectThunk(id));
                    },
                    resourceType: 'Compute Project',
                    resource: name,
                  },
                })
              );
            },
            optionType: 'primary' as OptionType,
          },
        ]
      : [];
  };

  const emptyStateTitle = useMemo(() => {
    if (searchValue) {
      return 'Oops! No matching Projects';
    }
    return `You don't have any Projects yet!`;
  }, [searchValue]);

  const projectsToShow = useMemo(() => {
    if (searchValue) {
      return projects.filter((project) =>
        project.name.toLowerCase().includes(searchValue.toLowerCase())
      );
    }
    return projects;
  }, [projects, searchValue]);

  return (
    <div className="flex flex-col gap-y-5">
      <>
        {projectsLoading || selectedOrganisationLoading ? (
          <div className="grid grid-cols-12 gap-x-5 max-w-[1400px] justify-between">
            <div className="flex flex-col gap-y-5 w-full col-span-9">
              {[0, 1, 2].map((key) => (
                <Fragment key={key}>
                  <ComputeDashboardLoading />
                </Fragment>
              ))}
            </div>

            <div className="col-span-3 w-[320px]">
              <DashboardCardsLoading />
            </div>
          </div>
        ) : (
          <>
            {projectsToShow.length > 0 ? (
              <InfiniteScroll
                fetchFn={handleLoadMore}
                loader={
                  <div className="mt-4">
                    <LoadMore loading handleClick={() => {}} />
                  </div>
                }
                hasMoreItems={
                  searchValue === '' &&
                  projects.length < Number(allProjectsCount) &&
                  !allProjectsCountLoading
                }
                loading={loadMoreProjectsLoading}
              >
                <div>
                  {' '}
                  <div className="flex flex-col gap-y-5 w-full">
                    {projectsToShow.map((card) => {
                      return (
                        <Fragment key={card._id}>
                          {withLoader(
                            projectsLoading,
                            <ComputeDashboardLoading />,
                            <div className="relative">
                              <ProjectCard
                                onClick={() => handleProjectCardClick(card._id)}
                                heading={(card as IComputeProject).name}
                                updatedAt={dayjs(
                                  (card as IComputeProject).createdAt
                                ).format('DD MMM YYYY')}
                                subHeading={
                                  (card as IComputeProject).description
                                }
                                closed={
                                  (card as IComputeProject).instanceReport
                                    ?.closed
                                }
                                provisioned={
                                  (card as IComputeProject).instanceReport
                                    ?.active
                                }
                                failed={
                                  (card as IComputeProject).instanceReport
                                    ?.failedToStart
                                }
                                starting={
                                  (card as IComputeProject).instanceReport
                                    ?.starting
                                }
                              />
                              {projectDropdownOptions(
                                card.name,
                                card._id,
                                (card as IComputeProject).instanceReport
                              )?.length > 0 && (
                                <div
                                  className="absolute top-0 right-0"
                                  ref={dropdownRef}
                                >
                                  <Dropdown
                                    dropdownType="button"
                                    dropdownSize="compact"
                                    bordersNone
                                    buttonImage={
                                      <MoreIcon
                                        className="rotate-90 w-4 h-4 
                                          text-base-icon dark:text-dark-base-icon"
                                      />
                                    }
                                    options={projectDropdownOptions(
                                      card.name,
                                      card._id,
                                      (card as IComputeProject).instanceReport
                                    )}
                                  />
                                </div>
                              )}
                            </div>
                          )}
                        </Fragment>
                      );
                    })}
                  </div>
                </div>
              </InfiniteScroll>
            ) : (
              <div className="mt-10">
                <div className="h-[530px]">
                  <div className="h-full">
                    <EmptyState
                      icon={<NoSearchFound className="w-56 h-56" />}
                      buttonDisabled={false}
                      entityTitle={emptyStateTitle}
                      showButton={searchValue?.length === 0}
                      buttonText="Create New Project"
                      onClick={handleNewProjectClick}
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </>
    </div>
  );
};

export default ProjectDashboard;
