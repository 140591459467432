/* eslint-disable import/no-cycle */
import * as Sentry from '@sentry/react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { NotificationType } from '../notification/notification.interfaces';
import {
  deleteCluster,
  getClusterDetailsService,
  getClusterFundUsageService,
  getClusterInstancesCountService,
  getClusterTemplateCategoriesService,
  getClusterTemplateInformationService,
  getClusterTemplatesService,
  getTemplateVideoMetadataService,
  getWalletUsageService,
  searchCharacters,
} from './cluster.services';
import { addNotificationRtk } from '../notification/notification.slice';
import {
  IClusterState,
  IClusterTemplate,
  IClusterTemplatesDto,
  IClusterUsageResponseDto,
  IGetClustersDto,
  ProcessingType,
} from './cluster.interfaces';
import {
  getAllClustersService,
  getComputeProjectInstanceReportService,
  getComputeProjectsCountService,
} from '../organisation/organisation.services';
import { IResponseError } from '../combined-reducer.interface';
import { setSelectedTemplateRtk } from './cluster.slice';
import {
  getInstancePlansEndRtk,
  setInstancePlanSearchParamsRtk,
  toggleInstancePlanLoadingRtk,
} from '../instance/instance-creation/instance-creation.slice';
import { loadCdnRecordsThunk } from '../instance/instance.thunks';
import { toggleModalShowRtk } from '../modal/modal.slice';
import {
  IInstanceCreationState,
  IInstancePlan,
} from '../instance/instance-creation/instance-creation.interfaces';
import { IOrganisationState } from '../organisation/organisation.interfaces';
import { RootState } from '../rtk-store';

export const getAllClustersThunk = createAsyncThunk(
  'cluster/getAllClusters',
  async (
    payload: IGetClustersDto,
    { fulfillWithValue, rejectWithValue, getState }
  ) => {
    const { cluster } = getState() as { cluster: IClusterState };
    try {
      const skip = 0;
      const limit =
        payload.topupReport === 'y'
          ? cluster.clusterPaginationBilling.numbersPerPage
          : cluster.clusterPagination.numbersPerPage;
      const response = await getAllClustersService(
        payload.organisationId,
        payload.topupReport,
        skip,
        limit
      );
      if (!(response as IResponseError).error) {
        return fulfillWithValue(response);
      }
      Sentry.captureMessage((response as IResponseError).message);
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const loadMoreClustersThunk = createAsyncThunk(
  'cluster/loadMoreClusters',
  async (
    payload: IGetClustersDto,
    { fulfillWithValue, rejectWithValue, getState }
  ) => {
    const { cluster } = getState() as { cluster: IClusterState };
    try {
      const limit =
        payload.topupReport === 'y'
          ? cluster.clusterPaginationBilling.numbersPerPage
          : cluster.clusterPagination.numbersPerPage;
      const skip =
        payload.topupReport === 'y'
          ? (cluster.clusterPaginationBilling.currentPage + 1) * limit
          : (cluster.clusterPagination.currentPage + 1) * limit;
      const response = await getAllClustersService(
        payload.organisationId,
        payload.topupReport,
        skip,
        limit
      );
      if (!(response as IResponseError).error) {
        return fulfillWithValue(response);
      }
      Sentry.captureMessage((response as IResponseError).message);
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getClusterInstanceCountThunk = createAsyncThunk(
  'cluster/getClusterInstanceCount',
  async (payload: string, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getClusterInstancesCountService(payload);
      if (!(response as IResponseError).error) {
        return fulfillWithValue(response);
      }
      Sentry.captureMessage((response as IResponseError).message);
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getClusterDetailsThunk = createAsyncThunk(
  'cluster/getClusterDetails',
  async (payload: string, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      dispatch(getClusterInstanceCountThunk(payload));
      dispatch(loadCdnRecordsThunk(payload));
      const response = await getClusterDetailsService(payload);
      if (!(response as IResponseError).error) {
        return fulfillWithValue(response.cluster);
      }
      Sentry.captureMessage((response as IResponseError).message);
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getClusterFundUsageThunk = createAsyncThunk(
  'cluster/getClusterFundUsage',
  async (payload: string, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getClusterFundUsageService(payload);
      if (!(response as IResponseError).error) {
        return fulfillWithValue(response);
      }
      Sentry.captureMessage((response as IResponseError).message);
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getWalletUsageThunk = createAsyncThunk(
  'cluster/getWalletUsage',
  async (payload: string, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await getWalletUsageService(payload);
      if (!(response as IResponseError).error) {
        return fulfillWithValue(response as IClusterUsageResponseDto);
      }
      dispatch(
        addNotificationRtk({
          message: (response as IResponseError).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getClusterInstanceReportThunk = createAsyncThunk(
  'computeProject/getComputeProjectInstanceReport',
  async (payload: string, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await getComputeProjectInstanceReportService(payload);
      if (!(response as IResponseError).error) {
        return fulfillWithValue(response);
      }
      dispatch(
        addNotificationRtk({
          message: (response as IResponseError).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getClusterCountThunk = createAsyncThunk(
  'cluster/getClusterCount',
  async (payload: string, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getComputeProjectsCountService(payload);
      if (!(response as IResponseError).error) {
        return fulfillWithValue(response.count);
      }
      Sentry.captureMessage((response as IResponseError).message);
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const deleteClusterThunk = createAsyncThunk(
  'cluster/deleteCluster',
  async (payload: string, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      await deleteCluster(payload);
      window.open(`${window.location.origin}/compute/dashboard`, '_self');
      dispatch(
        addNotificationRtk({
          message: 'Cluster deleted successfully',
          timestamp: Date.now(),
          type: NotificationType.Success,
        })
      );
      dispatch(
        toggleModalShowRtk({
          modalShow: false,
        })
      );
      return fulfillWithValue(payload);
    } catch (error) {
      Sentry.captureException(error);
      dispatch(
        addNotificationRtk({
          message: (error as Error).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    }
  }
);

export const getClusterTemplateCategoriesThunk = createAsyncThunk(
  'cluster/getClusterTemplateCategories',
  async (payload: void, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getClusterTemplateCategoriesService();
      if (!(response as IResponseError).error) {
        return fulfillWithValue(
          response.categories.filter(
            (category: string) => category !== 'Node' && category !== 'AI'
          )
        );
      }
      Sentry.captureMessage((response as IResponseError).message);
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getClusterTemplatesThunk = createAsyncThunk(
  'cluster/getClusterTemplates',
  async (
    payload: IClusterTemplatesDto,
    { rejectWithValue, fulfillWithValue, dispatch, getState }
  ) => {
    try {
      const {
        organisation: { selectedOrganisation },
      } = getState() as { organisation: IOrganisationState };
      const organisationId = selectedOrganisation?._id || '';
      const { search, category, processingType } = payload;
      const response = await getClusterTemplatesService(
        search,
        category,
        processingType,
        organisationId
      );
      if (!(response as IResponseError).error) {
        const clusterTemplates = response.templates
          ?.filter((clusterTemplate: IClusterTemplate) => {
            if (
              processingType !== ProcessingType.GPU &&
              processingType !== ProcessingType.ALL
            ) {
              return (
                clusterTemplate.metadata.category !== 'Node' &&
                clusterTemplate.metadata.category !== 'AI'
              );
            }
            return true;
          })
          ?.map((clusterTemplate: IClusterTemplate) => {
            const { services } = clusterTemplate;
            const { defaultAkashMachineImage } = services[0];
            const newClusterTemplate = {
              ...clusterTemplate,
              serviceData: {
                ...services[0],
                defaultAkashMachineImage: {
                  ...defaultAkashMachineImage,
                  price: Number(defaultAkashMachineImage.defaultDailyTopUp),
                },
              },
            };
            return newClusterTemplate;
          });
        if (payload.isUrl) {
          dispatch(setSelectedTemplateRtk(clusterTemplates[0]));
        }
        return fulfillWithValue({
          clusterTemplates,
          processingType,
        });
      }
      Sentry.captureMessage((response as IResponseError).message);
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  },
  {
    condition: (payload: IClusterTemplatesDto, { getState }) => {
      const {
        cluster: { cpuClusterTemplates, gpuClusterTemplates },
      } = getState() as {
        cluster: IClusterState;
      };
      if (
        cpuClusterTemplates.length &&
        gpuClusterTemplates.length &&
        !payload.isSearch
      ) {
        return false;
      }
      return true;
    },
  }
);

export const getClusterTemplateDetailsThunk = createAsyncThunk(
  'cluster/getClusterTemplateDetails',
  async (payload: string, { rejectWithValue, fulfillWithValue, getState }) => {
    try {
      const { organisation } = getState() as RootState;
      const response = await getClusterTemplateInformationService(
        payload,
        organisation.selectedOrganisation._id
      );
      if (!(response as unknown as IResponseError).error) {
        return fulfillWithValue(response);
      }
      Sentry.captureMessage((response as unknown as IResponseError).message);
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const searchInstancePlansThunk = createAsyncThunk(
  'cluster/searchInstancePlans',
  async (
    payload: {
      search: string;
      limit: number;
      skip: number;
    }, // infer better type
    { dispatch, getState }
  ) => {
    try {
      const { instanceCreation } = getState() as {
        instanceCreation: IInstanceCreationState;
      };

      dispatch(toggleInstancePlanLoadingRtk(true));
      const results = await searchCharacters(
        payload.search,
        payload.limit,
        payload.skip,
        {
          region: instanceCreation.clusterRegion || '',
          scalingType: instanceCreation.clusterScaling,
          provider: '',
        }
      );
      const newAkashMachinImages = [...results.akashMachineImages];
      if (
        instanceCreation.customCpu !== '' &&
        instanceCreation.customRam !== ''
      ) {
        newAkashMachinImages.unshift(instanceCreation.customPlan);
      }
      const instancePlans = newAkashMachinImages?.map(
        (instancePlan: IInstancePlan) => {
          return {
            ...instancePlan,
            price: Number(instancePlan.defaultDailyTopUp),
          };
        }
      );

      dispatch(
        getInstancePlansEndRtk({
          totalCount: results.totalCount,
          akashMachineImages: instancePlans,
        })
      );
      dispatch(setInstancePlanSearchParamsRtk(payload.search));
    } catch (error) {
      Sentry.captureException(error);
    }
  }
);

export const getTemplateVideoMetadataThunk = createAsyncThunk(
  'cluster/getTemplateVideoMetadata',
  async (payload: string, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await getTemplateVideoMetadataService(payload);
      return fulfillWithValue(response);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue(error);
    }
  }
);
