import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { BroadcastChannel } from 'broadcast-channel';
import { useSelector } from 'react-redux';
import Loader from '../../../../components/Compute/Loaders/loader';
import { isDarkTheme } from '../../../../redux/compute/theme/theme.utils';
import { RootState } from '../../../../redux/compute/store';

const LoginConnectionCallback = () => {
  const location = useLocation();
  const theme = useSelector((state: RootState) => state.theme.theme);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const success = query.get('success');
    const error = query.get('error');
    const bc = new BroadcastChannel('signin_channel');
    if (success) {
      bc.postMessage('login_successful');
      window.close();
    } else {
      bc.postMessage(error);
      window.close();
    }

    return () => {
      bc.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`h-screen w-screen ${
        isDarkTheme(theme) && 'bg-feedback-default-bg'
      }`}
    >
      <Loader />
    </div>
  );
};

export default LoginConnectionCallback;
