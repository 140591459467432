import config from '../../../config';
import { IStatus, IStatusIndicator } from './status.interfaces';

export const getSystemStatus = async (): Promise<{ status: IStatus }> => {
  try {
    const response = await fetch(config.urls.STATUS_API_URL);
    return await response.json();
  } catch (error) {
    return {
      status: {
        description: 'Error ocurred',
        indicator: IStatusIndicator.MAJOR,
      },
    };
  }
};
