import React, { useEffect, useState } from 'react';
import { Tooltip } from '@spheron/ui-library';
import { ReactComponent as InfoIcon } from '@spheron/ui-library/dist/assets/info-circle.svg';
import { useDispatch, useSelector } from 'react-redux';
import TemplateCard from '../TemplateCard';
import {
  ClusterType,
  HardwareTypeEnum,
  LoadingType,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.interfaces';
import ComputeInput from '../../../../../components/Compute/InputFields/newUI/text-input';
import { AppDispatch, RootState } from '../../../../../redux/compute/store';
import {
  updateMultiServiceCustomHddStorage,
  updateMultiServiceCustomNvmStorage,
  updateMultiServiceCustomSsdStorage,
  updateMultiServiceMountPointValue,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.slice';
// eslint-disable-next-line max-len
import { multiserviceCalculatePriceThunk } from '../../../../../redux/compute/instance/instance-creation/instance-creation.thunks';
import { sliceStringFromEnd } from '../../../../../redux/compute/subscription/subscription.utils';
import StorageCardLoading from '../../../../../components/Compute/Loaders/storage-card-loader';
import {
  getPersistentStorageName,
  isAccelerateDeployment,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.utils';
import { IServiceData } from '../../../../../redux/compute/cluster/cluster.interfaces';
import MultiServiceDropdownMenu from '../MultiServiceDropdownMenu';
import { CustomSelectOption } from '../MultiServiceDropdownMenu/multiservice-dropdown-menu';
import MultiServiceCustomStorage from '../MultiServiceCustomStorage';
import { CustomStorageOption } from '../MultiServiceCustomStorage/multiservice-custom-storage';

interface IProps {
  isUpdate: boolean;
  selectedService: IServiceData;
}

export enum StorageType {
  // eslint-disable-next-line no-unused-vars
  TEMPLATE = 'template',
  // eslint-disable-next-line no-unused-vars
  CUSTOM = 'custom',
  // eslint-disable-next-line no-unused-vars
  PERSISTENT = 'persistent',
  // eslint-disable-next-line no-unused-vars
  PERSISTENTTEMPLATE = 'persistentTemplate',
}

const MultiServiceSelectPersistentStorage = ({
  isUpdate,
  selectedService,
}: IProps) => {
  const serviceId = selectedService._id;

  const dispatchRtk = useDispatch<AppDispatch>();

  const [selectedCard, setSelectedCard] = useState<number | string | null>(
    null
  );
  const [selectedValue, setSelectedValue] = useState<any>();
  const [mountError, setMountError] = useState<boolean>(false);
  const {
    customHddStorage,
    customSsdStorage,
    customNvmStorage,
    customPersistentStorage,
    mountPointValue,
  } = useSelector(
    (state: RootState) =>
      state.instanceCreation.multiserviceInstanceCreation![serviceId]
  );

  const defaultCustomPlansValue = useSelector(
    (state: RootState) => state.instanceCreation.defaultCustomPlansValue
  );
  const activeDeploymentLoading = useSelector(
    (state: RootState) => state.instance.activeDeploymentLoading
  );
  const clusterType = useSelector(
    (state: RootState) => state.instanceCreation.clusterType
  );
  const hddValues = defaultCustomPlansValue?.find(
    (item: { type: HardwareTypeEnum }) =>
      item.type === HardwareTypeEnum.PERSISTENT_STORAGE_HDD
  );
  const ssdValues = defaultCustomPlansValue?.find(
    (item: { type: HardwareTypeEnum }) =>
      item.type === HardwareTypeEnum.PERSISTENT_STORAGE_SSD
  );
  const nvmValues = defaultCustomPlansValue?.find(
    (item: { type: HardwareTypeEnum }) =>
      item.type === HardwareTypeEnum.PERSISTENT_STORAGE_NVME
  );
  const storageLoading = useSelector(
    (state: RootState) => state.instanceCreation.storageLoading
  );
  const instancePlanLoading = useSelector(
    (state: RootState) => state.instanceCreation.instancePlanLoading
  );
  const deploymentType = useSelector(
    (state: RootState) => state.instanceCreation.deploymentType
  );

  const storageOptions = [
    {
      key: 1,
      name: 'HDD',
      suffix: '',
      subtitle: '',
      disable: !hddValues?.values,
    },
    {
      key: 2,
      name: 'SSD',
      suffix: '',
      subtitle: '',
      disable: !ssdValues?.values,
    },
    {
      key: 3,
      name: 'NVMe',
      suffix: '',
      subtitle: '',
      disable: !nvmValues?.values,
    },
  ];

  const selectedPersistentStorage = isUpdate
    ? selectedService?.agreedMachineImage.persistentStorage
    : selectedService?.customTemplateSpecs.persistentStorage;

  const hasPersistentStorage =
    Object.keys(selectedPersistentStorage || {}).length > 0;

  useEffect(() => {
    if (isUpdate && !activeDeploymentLoading) {
      dispatchRtk(
        updateMultiServiceMountPointValue({
          serviceId: selectedService._id,
          value: selectedPersistentStorage.mountPoint || '',
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchRtk, isUpdate, activeDeploymentLoading]);

  useEffect(() => {
    let selectedStorageData: any;
    const recommendedPersistentStorage = Number(
      sliceStringFromEnd(selectedPersistentStorage?.size || '', 2)
    );
    if (ssdValues || hddValues || nvmValues) {
      switch (customPersistentStorage) {
        case CustomStorageOption.CUSTOMHDDSTORAGE:
          setSelectedValue(hddValues);
          if (hasPersistentStorage) {
            selectedStorageData = hddValues?.values.findIndex(
              (v) => v.value === recommendedPersistentStorage
            ) as number;
            dispatchRtk(
              updateMultiServiceCustomHddStorage({
                serviceId: selectedService._id,
                value: recommendedPersistentStorage,
              })
            );
          }
          break;
        case CustomStorageOption.CUSTOMSSDSTORAGE:
          setSelectedValue(ssdValues);
          if (hasPersistentStorage) {
            selectedStorageData = ssdValues?.values.findIndex(
              (v) => v.value === recommendedPersistentStorage
            ) as number;
            dispatchRtk(
              updateMultiServiceCustomSsdStorage({
                serviceId: selectedService._id,
                value: recommendedPersistentStorage,
              })
            );
          }
          break;
        case CustomStorageOption.CUSTOMNVMSTORAGE:
          setSelectedValue(nvmValues);
          if (hasPersistentStorage) {
            selectedStorageData = nvmValues?.values?.findIndex(
              (v) => v.value === recommendedPersistentStorage
            ) as number;
            dispatchRtk(
              updateMultiServiceCustomNvmStorage({
                serviceId: selectedService._id,
                value: recommendedPersistentStorage,
              })
            );
          }
          break;
        default:
          break;
      }
      if (selectedStorageData && selectedStorageData !== -1) {
        setSelectedCard(selectedStorageData);
      } else {
        setSelectedCard(StorageType.PERSISTENT);
      }
      dispatchRtk(
        multiserviceCalculatePriceThunk({
          serviceId: selectedService._id,
          loadingType: LoadingType.PERSISTENT_STORAGE,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customPersistentStorage,
    hddValues,
    ssdValues,
    nvmValues,
    selectedPersistentStorage,
    hasPersistentStorage,
    isUpdate,
    dispatchRtk,
  ]);
  const persistentStorage =
    customSsdStorage > 0 || customHddStorage > 0 || customNvmStorage > 0;
  useEffect(() => {
    if (!persistentStorage && !isUpdate) {
      setSelectedCard(null);
    }
  }, [persistentStorage, isUpdate]);

  const handleCardClick = (index: number) => {
    setSelectedCard(index);
    const selectedStorage = selectedValue?.values[index];
    if (customPersistentStorage === CustomStorageOption.CUSTOMHDDSTORAGE) {
      dispatchRtk(
        updateMultiServiceCustomHddStorage({
          serviceId: selectedService._id,
          value: selectedStorage.value,
        })
      );
    } else if (
      customPersistentStorage === CustomStorageOption.CUSTOMSSDSTORAGE
    ) {
      dispatchRtk(
        updateMultiServiceCustomSsdStorage({
          serviceId: selectedService._id,
          value: selectedStorage.value,
        })
      );
    } else if (
      customPersistentStorage === CustomStorageOption.CUSTOMNVMSTORAGE
    ) {
      dispatchRtk(
        updateMultiServiceCustomNvmStorage({
          serviceId: selectedService._id,
          value: selectedStorage.value,
        })
      );
    }
    dispatchRtk(
      multiserviceCalculatePriceThunk({
        serviceId: selectedService._id,
        loadingType: LoadingType.PERSISTENT_STORAGE,
      })
    );
  };

  const handleMountInput = (inputValue: string) => {
    dispatchRtk(
      updateMultiServiceMountPointValue({
        serviceId: selectedService._id,
        value: inputValue,
      })
    );
    setMountError(false);
  };
  const handleBlur = () => {
    if (mountPointValue === '') {
      setMountError(true);
    } else {
      setMountError(false);
    }
  };

  const stepNumber = isAccelerateDeployment(deploymentType) ? '7.1' : '8.1';
  const dockerStepNumber = isAccelerateDeployment(deploymentType)
    ? '5.1'
    : '6.1';

  let defaultOption = 'Select Storage';

  if (isUpdate && selectedPersistentStorage) {
    defaultOption = getPersistentStorageName(
      selectedPersistentStorage.class || ''
    );
  } else if (selectedPersistentStorage && hasPersistentStorage && !isUpdate) {
    const storageName = getPersistentStorageName(
      selectedPersistentStorage.class || ''
    );
    const disableOption = storageOptions.find(
      (option) => storageName === option.name
    );
    if (!disableOption?.disable) {
      defaultOption = storageName;
    }
  }
  return (
    <div className="mt-14">
      <span
        className="text-base-heading-text-color dark:text-dark-base-heading-text-color
        text-xl font-bold"
      >
        {!isUpdate
          ? `${
              clusterType === ClusterType.TEMPLATE
                ? stepNumber
                : dockerStepNumber
            } `
          : ''}
        Select Persistent Storage
      </span>
      <p className="text-text-darkGray text-sm font-normal leading-5 mt-2">
        The storage is persistent, will not be erased when instance is restarted
        or updated, only when it is closed.
      </p>
      <div className="flex ll:flex-row flex-col mt-4">
        <div>
          <div
            className="text-text-filterBadge text-sm font-medium ml-1
          flex items-center"
          >
            <span className="mr-1">Mount Point *</span>
            <Tooltip
              position="top"
              text="The location in a file system where a persistent volume is
                    attached and accessed."
              type="float"
              wrapText
            >
              <InfoIcon
                className="w-5 h-5 text-text-filterBadge transition
                               duration-300 ease-in-out dark:hover:text-gray-200 
                               hover:text-gray-900 ml-2"
              />
            </Tooltip>
          </div>
          <div className="ml-px w-320">
            <ComputeInput
              type="text"
              value={mountPointValue}
              onChange={(e) => handleMountInput(e)}
              placeholder="/etc/data"
              error={mountError || mountPointValue?.length === 0}
              errorMessage="This is a required field"
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="ll:ml-8 ll:mt-0 mt-4 ml-0">
          <MultiServiceDropdownMenu
            isUpdate={isUpdate}
            label="Type of Storage *"
            options={storageOptions}
            value={CustomSelectOption.SELECTEDSTORAGE}
            widthClassName="w-320"
            defaultOption={defaultOption}
            selectedService={selectedService}
          />
        </div>
      </div>
      {customPersistentStorage && (
        <>
          <div
            className={`mt-8 ${
              customPersistentStorage
                ? 'pointer-events-auto'
                : 'pointer-events-none'
            }`}
          >
            {!storageLoading ||
            (!instancePlanLoading && defaultCustomPlansValue!.length > 0) ? (
              <>
                <div
                  className={`grid gap-6 ${
                    isUpdate
                      ? 'grid-cols-2'
                      : 'md:grid-cols-2 grid-cols-1 xl:grid-cols-3'
                  }`}
                >
                  {selectedValue?.values?.map((data: any, index: number) => (
                    <div key={data.value}>
                      <TemplateCard
                        title={`Storage ${data.value} GB`}
                        imageShow={false}
                        image=""
                        badgeContent=""
                        subtitle={`${data.price}/${
                          isAccelerateDeployment(deploymentType)
                            ? 'hour'
                            : 'month'
                        }`}
                        isSelected={selectedCard === index}
                        onSelect={() => handleCardClick(index)}
                        isSelectedTemplate={false}
                      />
                    </div>
                  ))}
                </div>{' '}
                <div
                  className={`mt-8 ${
                    customPersistentStorage
                      ? 'pointer-events-auto'
                      : 'pointer-events-none'
                  }`}
                >
                  <div
                    className={`grid md:grid-cols-2 grid-cols-1 ${
                      isUpdate ? 'xl:grid-cols-2' : 'xl:grid-cols-3'
                    } gap-6`}
                  >
                    <MultiServiceCustomStorage
                      serviceId={selectedService._id}
                      isUpdate={isUpdate}
                      isSelected={selectedCard === StorageType.PERSISTENT}
                      value={CustomStorageOption.CUSTOMPERSISTENTSTORAGE}
                      onSelect={() => setSelectedCard(StorageType.PERSISTENT)}
                    />
                  </div>
                </div>{' '}
              </>
            ) : (
              <div
                className={`grid  gap-6 mt-5
        mr-8  ${
          isUpdate ? 'grid-cols-2' : 'md:grid-cols-2 grid-cols-1 xl:grid-cols-3'
        }`}
              >
                {Array.from(Array(6).keys()).map((item) => (
                  <StorageCardLoading key={item} />
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default MultiServiceSelectPersistentStorage;
