import React from 'react';
import Skeleton from 'react-loading-skeleton';

const ServiceDetailsCardLoading = () => {
  return (
    <div
      className="rounded-lg hover:cursor-pointer hover:bg-cards-hover bg-cards-bg 
      dark:bg-dark-cards-bg hover:dark:bg-dark-cards-hover border border-base-border 
      dark:border-dark-base-border relative min-w-fit
     w-full"
    >
      <div className="p-3">
        <div className="flex items-start justify-between w-full">
          <div className="flex items-center justify-start gap-x-40">
            <div className="flex flex-col gap-y-3">
              <div className="flex items-center justify-start gap-x-2">
                <Skeleton height={20} width={100} duration={2} />
              </div>
              <div className="flex items-center justify-start gap-x-3">
                <Skeleton height={24} width={24} duration={2} />
                <Skeleton height={20} width={100} duration={2} />
              </div>
            </div>
            <div className="flex flex-col gap-y-2">
              <Skeleton height={20} width={100} duration={2} />

              <Skeleton height={20} width={150} duration={2} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetailsCardLoading;
