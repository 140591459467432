import React from 'react';
import ReactDOM from 'react-dom';
// eslint-disable-next-line import/no-unresolved
import './index.scss';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';
import ComputeApp from './ComputeApp';
import store from './redux/compute/store';
import packageJson from '../package.json';
import { NODE_ENV } from './config';
import reportWebVitals from './reportWebVitals';

if (NODE_ENV === 'production' || NODE_ENV === 'development') {
  Sentry.init({
    // eslint-disable-next-line max-len
    dsn: 'https://4eb0831b2e61413f94c247e5259ea17a@o4504639674515456.ingest.sentry.io/4504921447071744',
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 1.0,
    release: `frontend@${packageJson.version}`,
    environment: NODE_ENV,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ToastContainer newestOnTop limit={2} />
      <ComputeApp />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
