import React, { useEffect, useState } from 'react';
import { DropdownRow, OptionType as RowOptionType } from '@spheron/ui-library';
import { useNavigate } from 'react-router-dom';
import GlobalStyles from '../../../styles/compute/global.module.scss';

export type OptionType = RowOptionType;

export interface IOptions {
  optionType: OptionType;
  id?: number;
  label?: string | JSX.Element;
  value?: string;
  rightIcon?: JSX.Element;
  disable?: boolean;
  count?: number;
  number?: number;
  url?: string;
  handleClick?: () => void;
}

interface IProps {
  heading: string;
  options: {
    label: string;
    value: string;
    url: string;
    slug?: string;
    optionType: OptionType;
  }[];
  defaultSelected: string;
}

const SidebarDropdownRow = ({ heading, options, defaultSelected }: IProps) => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState<IOptions | undefined>();

  useEffect(() => {
    setSelectedOption(options?.find((op) => op.value === defaultSelected));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSelected]);

  const handleSelected = (option: IOptions) => {
    setSelectedOption(option);
    navigate(option.url!);
  };

  return (
    <div className={GlobalStyles.sidebar}>
      {!!heading && <h1 className="text-2xl font-bold">{heading}</h1>}
      <div className="dark:text-dark-gray-text space-y-1">
        {options.map((option) => (
          <DropdownRow
            option={option}
            dropdownType="default"
            handleSelected={(option) => handleSelected(option)}
            selectedOption={selectedOption}
          />
        ))}
      </div>
    </div>
  );
};

export default SidebarDropdownRow;
