/* eslint-disable jsx-a11y/anchor-is-valid */
import dayjs from 'dayjs';
import React from 'react';
import {
  Badge,
  EmptyState,
  Link,
  Table,
  TableCellProps,
} from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import { ReactComponent as CashIcon } from '../../../assets/compute/icons/cash-icon.svg';
import { SpheronCreditType } from '../../../redux/compute/organisation/organisation.interfaces';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import TableLoader from '../Loaders/table-loader';

interface IProps {
  type: SpheronCreditType;
}

const SpheronCreditsTableItem = ({ type }: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();

  const spheronCredits = useSelector(
    (state: RootState) => state.organisation.spheronCredits
  );

  const spheronCreditsLoading = useSelector(
    (state: RootState) => state.organisation.spheronCreditsLoading
  );

  const { selectedOrganisationLoading } = useSelector(
    (state: RootState) => state.organisation
  );

  const selectedCredits = spheronCredits?.filter(
    (credit) => credit.status === type
  );

  const tableHeaders: TableCellProps[] = [
    {
      id: 0,
      title: 'Credit Name',
      tableCellType: 'header',
    },
    { id: 1, title: 'Activated on', tableCellType: 'header' },
    {
      id: 2,
      title: 'Amount Used',
      tableCellType: 'header',
    },
    {
      id: 3,
      title: 'Amount Remaining',
      tableCellType: 'header',
    },
    {
      id: 4,
      title: 'Expiration Date',
      tableCellType: 'header',
    },
    {
      id: 5,
      title: 'Applicable For',
      tableCellType: 'header',
    },
    {
      id: 6,
      title: 'Action',
      tableCellType: 'header',
    },
  ];

  return (
    <>
      {!(spheronCreditsLoading || selectedOrganisationLoading) ? (
        <>
          {selectedCredits && selectedCredits?.length > 0 ? (
            <Table
              tableHeader={{
                tableRowType: 'record',
                tableRow: tableHeaders,
              }}
              tableRows={selectedCredits.map((credit, i: number) => ({
                id: i,
                tableRowType: 'record',
                tableRow: [
                  {
                    id: 0,
                    title: credit.couponName,
                    tableCellType: 'title',
                  },
                  {
                    id: 1,
                    title: dayjs(credit.activatedOn).format('MMMM D, YYYY'),
                    tableCellType: 'title',
                  },
                  {
                    id: 2,
                    title: `$${Number(
                      (credit.totalAmount - credit.remainingAmount).toFixed(2)
                    )}`,
                    tableCellType: 'title',
                  },
                  {
                    id: 3,
                    title: `$${Number(credit.remainingAmount.toFixed(2))}`,
                    tableCellType: 'title',
                  },
                  {
                    id: 4,
                    content: (
                      <Badge
                        badgeType={
                          type === SpheronCreditType.EXPIRED ? 'error' : 'info'
                        }
                        text={`${
                          type === SpheronCreditType.EXPIRED ? 'Expired on' : ''
                        } ${dayjs(credit.expiresOn).format('MMM D, YYYY')}`}
                        size="large"
                        solid={false}
                      />
                    ),
                    tableCellType: 'default',
                  },
                  {
                    id: 5,
                    content: (
                      <Link
                        type="secondary"
                        size="default"
                        text="See list"
                        isBold={false}
                        onClick={() =>
                          dispatchRtk(
                            toggleModalShowRtk({
                              modalShow: true,
                              modalType: 'spheronCreditApplicableList',
                              options: {
                                applicableFor: credit.appType,
                              },
                            })
                          )
                        }
                      />
                    ),
                    tableCellType: 'default',
                  },
                  {
                    id: 4,
                    tableCellType: 'action',
                    actionButtons: [
                      {
                        actionButtonId: 0,
                        actionType: 'delete',
                        onClick: () =>
                          dispatchRtk(
                            toggleModalShowRtk({
                              modalShow: true,
                              modalType: 'deleteSpheronCredit',
                              options: {
                                creditCoupon: credit.creditCoupon,
                                couponName: credit.couponName,
                              },
                            })
                          ),
                      },
                    ],
                  },
                ],
              }))}
            />
          ) : (
            <div className="h-72">
              <EmptyState
                entityTitle="You dont have any credits yet!"
                subText=""
                buttonDisabled={false}
                showButton={false}
                onClick={() => {}}
                icon={<CashIcon />}
              />
            </div>
          )}
        </>
      ) : (
        <TableLoader
          headers={tableHeaders.map((header) => header.title || '')}
        />
      )}
    </>
  );
};

export default SpheronCreditsTableItem;
