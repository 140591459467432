import * as Sentry from '@sentry/react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { addNotificationRtk } from '../../notification/notification.slice';
import { IResponseError } from '../../combined-reducer.interface';
import { NotificationType } from '../../notification/notification.interfaces';
import {
  IComputeProjectInitialState,
  IGetAllComputeProjectsResponseDto,
  IGetComputeProjectInstancesResponseDto,
  IGetComputeProjectDto,
  IGetComputeProjectInstancesPayloadDto,
  IInstance,
  ICreateNewProjectPayloadDto,
  ICreateNewProjectResponseDto,
} from './project.interfaces';
import {
  createNewProjectService,
  deleteComputeProjectService,
  getAllComputeProjectsService,
  getComputeProjectDetailsService,
  getComputeProjectInstancesCount,
  getComputeProjectInstancesService,
  getComputeProjectsFundUsageService,
} from './project.services';
import {
  getComputeProjectInstanceReportService,
  getComputeProjectsCountService,
} from '../../organisation/organisation.services';
import { toggleModalShowRtk } from '../../modal/modal.slice';
import config from '../../../config';
import { getTokenLivePrice } from '../../subscription/subscription.services';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../store';
import {
  resetComputeProjectPaginationState,
  setSelectedProjectName,
} from './project.slice';

export const getAllComputeProjectsThunk = createAsyncThunk(
  'computeProject/getAllComputeProjects',
  async (
    payload: IGetComputeProjectDto,
    { fulfillWithValue, rejectWithValue, dispatch, getState }
  ) => {
    const { computeProject } = getState() as {
      computeProject: IComputeProjectInitialState;
    };
    dispatch(resetComputeProjectPaginationState());
    try {
      const skip = 0;
      const limit = computeProject.pagination.numbersPerPage;
      const response = await getAllComputeProjectsService(
        payload.organisationId,
        payload.topupReport,
        skip,
        limit
      );
      if (!(response as IResponseError).error) {
        return fulfillWithValue(response as IGetAllComputeProjectsResponseDto);
      }
      dispatch(
        addNotificationRtk({
          message: (response as IResponseError).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const loadMoreComputeProjectsThunk = createAsyncThunk(
  'computeProject/loadMoreComputeProjects',
  async (
    payload: IGetComputeProjectDto,
    { fulfillWithValue, rejectWithValue, dispatch, getState }
  ) => {
    const { computeProject } = getState() as {
      computeProject: IComputeProjectInitialState;
    };
    try {
      const limit = computeProject.pagination.numbersPerPage;
      const skip = computeProject.pagination.currentPage * limit;
      const response = await getAllComputeProjectsService(
        payload.organisationId,
        payload.topupReport,
        skip,
        limit
      );
      if (!(response as IResponseError).error) {
        return fulfillWithValue(response as IGetAllComputeProjectsResponseDto);
      }
      dispatch(
        addNotificationRtk({
          message: (response as IResponseError).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getComputeProjectInstanceCountThunk = createAsyncThunk(
  'computeProject/getComputeProjectInstanceCount',
  async (
    computeProjectId: string,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const response = await getComputeProjectInstancesCount(computeProjectId);
      if (!(response as IResponseError).error) {
        return fulfillWithValue(response);
      }
      dispatch(
        addNotificationRtk({
          message: (response as IResponseError).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getComputeProjectDetailsThunk = createAsyncThunk(
  'computeProject/getComputeProjectDetails',
  async (payload: string, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      dispatch(getComputeProjectInstanceCountThunk(payload));
      const response = await getComputeProjectDetailsService(payload);
      if (!(response as IResponseError).error) {
        return fulfillWithValue(response.computeProjects);
      }
      dispatch(
        addNotificationRtk({
          message: (response as IResponseError).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getComputeProjectFundsUsageThunk = createAsyncThunk(
  'computeProject/getComputeProjectFundsUsage',
  async (payload: string, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await getComputeProjectsFundUsageService(payload);
      if (!(response as IResponseError).error) {
        return fulfillWithValue(response);
      }
      dispatch(
        addNotificationRtk({
          message: (response as IResponseError).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getComputeProjectsCountThunk = createAsyncThunk(
  'computeProject/getComputeProjectsCount',
  async (payload: string, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await getComputeProjectsCountService(payload);
      if (!(response as IResponseError).error) {
        return fulfillWithValue(response.count);
      }
      dispatch(
        addNotificationRtk({
          message: (response as IResponseError).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const deleteComputeProjectThunk = createAsyncThunk(
  'computeProject/deleteComputeProject',
  async (
    payload: string,
    { rejectWithValue, fulfillWithValue, dispatch, getState }
  ) => {
    try {
      const { organisation } = getState() as RootState;
      const orgUsername = organisation.selectedOrganisation.profile.username;
      await deleteComputeProjectService(payload);
      (window as any)?.rrNavigate(`/${orgUsername}/dashboard`);
      dispatch(
        addNotificationRtk({
          message: 'Project deleted successfully',
          timestamp: Date.now(),
          type: NotificationType.Success,
        })
      );
      dispatch(
        toggleModalShowRtk({
          modalShow: false,
        })
      );
      return fulfillWithValue(payload);
    } catch (error) {
      Sentry.captureException(error);
      dispatch(
        addNotificationRtk({
          message: (error as Error).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    }
  }
);

export const getComputeProjectInstancesThunk = createAsyncThunk(
  'computeProject/getComputeProjectInstances',
  async (
    payload: IGetComputeProjectInstancesPayloadDto,
    { rejectWithValue, fulfillWithValue, dispatch, getState }
  ) => {
    try {
      const { computeProject } = getState() as RootState;
      const paginationDetails =
        computeProject.selectedProjectInstancePagination;
      const response = await getComputeProjectInstancesService(
        payload.computeProjectId,
        payload.topupReport,
        0,
        paginationDetails.numbersPerPage,
        ''
      );
      if (response.success) {
        const liveTokenPrice = await getTokenLivePrice('AKT');
        const responseWithPrice = {
          ...response,
          extendedInstances: (
            response as IGetComputeProjectInstancesResponseDto
          ).extendedInstances.map((instance: IInstance) => ({
            ...instance,
            price:
              liveTokenPrice.price *
              (instance.defaultDailyTopup / config.akashToken.microAKT),
          })),
          totalInstances: (response as IGetComputeProjectInstancesResponseDto)
            .totalInstances,
        };
        dispatch(
          setSelectedProjectName(
            (response as IGetComputeProjectInstancesResponseDto).computeProject
              .name || ''
          )
        );
        return fulfillWithValue(
          responseWithPrice as IGetComputeProjectInstancesResponseDto
        );
      }
      dispatch(
        addNotificationRtk({
          message: (response as IResponseError).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue([]);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({
        instances: [],
        error,
      });
    }
  }
);

export const loadMoreComputeProjectInstancesThunk = createAsyncThunk(
  'computeProject/loadMoreComputeProjectInstances',
  async (
    payload: {
      computeProjectId: string;
      state: string;
      skip?: number;
    },
    { rejectWithValue, fulfillWithValue, dispatch, getState }
  ) => {
    try {
      const { computeProject } = getState() as RootState;
      const paginationDetails =
        computeProject.selectedProjectInstancePagination;
      const limit = paginationDetails.numbersPerPage;
      const skip = payload.skip
        ? payload.skip
        : limit * paginationDetails.currentPage;

      const response = await getComputeProjectInstancesService(
        payload.computeProjectId,
        'n',
        skip,
        limit,
        payload.state
      );
      if (response.success) {
        return fulfillWithValue(
          response as IGetComputeProjectInstancesResponseDto
        );
      }
      dispatch(
        addNotificationRtk({
          message: (response as IResponseError).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue([]);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({
        instances: [],
        error,
      });
    }
  }
);

export const getComputeProjectsWalletInstanceReportThunk = createAsyncThunk(
  'computeProject/getComputeProjectInstanceReport',
  async (payload: string, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await getComputeProjectInstanceReportService(payload);
      if (!(response as IResponseError).error) {
        return fulfillWithValue(response);
      }
      dispatch(
        addNotificationRtk({
          message: (response as IResponseError).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const createNewProjectThunk = createAsyncThunk(
  'computeProject/createNewProject',
  async (
    payload: ICreateNewProjectPayloadDto,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const response = await createNewProjectService(payload);
      if (!(response as IResponseError).error) {
        dispatch(toggleModalShowRtk({ modalShow: false }));
        dispatch(
          addNotificationRtk({
            message: 'Project Created!',
            timestamp: Date.now(),
            type: NotificationType.Success,
          })
        );
        return fulfillWithValue(response as ICreateNewProjectResponseDto);
      }
      dispatch(
        addNotificationRtk({
          message: (response as IResponseError).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);
