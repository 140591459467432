import React from 'react';

interface IItem {
  id: number;
  text: JSX.Element;
}
interface IProps {
  items: IItem[];
}

const InvoiceTableHeader = ({ items }: IProps) => {
  return (
    <thead className="w-full">
      <tr
        className="border-t border-b dark:border-dark-base-border 
      dark:bg-dark-feedback-default-bg w-full border-base-border space-x-5"
      >
        {items.map((item) => (
          <th key={item.id}>{item.text}</th>
        ))}
      </tr>
    </thead>
  );
};

export default InvoiceTableHeader;
