/* eslint-disable import/no-cycle */
import { AnyAction, combineReducers } from '@reduxjs/toolkit';
import { modalRTKReducer } from './modal/modal.slice';
import { notificationRTKReducer } from './notification/notification.slice';
import { organisationRTKReducer } from './organisation/organisation.slice';
import { subscriptionRTKReducer } from './subscription/subscription.slice';
import { userRTKReducer } from './user/user.slice';
import { web3RTKReducer } from './web3/web3.slice';
import { solanaRTKReducer } from './solana/solana.slice';
import { themeRTKReducer } from './theme/theme.slice';
import { clusterRTKReducer } from './cluster/cluster.slice';
import { instanceRTKReducer } from './instance/instance.slice';
import { instanceCreationRTKReducer } from './instance/instance-creation/instance-creation.slice';
import { stripeRTKReducer } from './stripe/stripe.slice';
import { computeProjectRTKReducer } from './project/project.slice';
import { serviceRTKReducer } from './service/service.slice';
import { statusRTKReducer } from './status/status.slice';

const combinedReducer = combineReducers({
  web3: web3RTKReducer,
  user: userRTKReducer,
  organisation: organisationRTKReducer,
  modal: modalRTKReducer,
  notification: notificationRTKReducer,
  subscription: subscriptionRTKReducer,
  solana: solanaRTKReducer,
  theme: themeRTKReducer,
  cluster: clusterRTKReducer,
  instance: instanceRTKReducer,
  instanceCreation: instanceCreationRTKReducer,
  stripe: stripeRTKReducer,
  computeProject: computeProjectRTKReducer,
  computeService: serviceRTKReducer,
  status: statusRTKReducer,
});

const rootReducer = (state: any, action: AnyAction) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'user/logoutUser') {
    const themeMain = localStorage.getItem('theme');
    localStorage.clear();
    if (themeMain) {
      localStorage.setItem('theme', themeMain);
    }
    return combinedReducer(undefined, action);
  }
  if (action.type === 'user/userSessionExpired') {
    return combinedReducer(undefined, action);
  }
  return combinedReducer(state, action);
};

export default rootReducer;
