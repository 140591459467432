/* eslint-disable no-unused-vars */

export enum NotificationCategory {
  PROJECT_CREATED = 'PROJECT_CREATED',
  PROJECT_DEPLOYMENT_ENV_CREATED = 'PROJECT_DEPLOYMENT_ENV_CREATED',
  PROJECT_DEPLOYMENT_ENV_UPDATED = 'PROJECT_DEPLOYMENT_ENV_UPDATED',
  PROJECT_DEPLOYMENT_ENV_DELETED = 'PROJECT_DEPLOYMENT_ENV_DELETED',
  PROJECT_DEPLOYMENT_ENV_ACTIVATED = 'PROJECT_DEPLOYMENT_ENV_ACTIVATED',
  PROJECT_DEPLOYMENT_ENV_DEACTIVATED = 'PROJECT_DEPLOYMENT_ENV_DEACTIVATED',
  PROJECT_SETTINGS_CHANGED = 'PROJECT_SETTINGS_CHANGED',
  PROJECT_ENV_VAR = 'PROJECT_ENV_VAR',
  PROJECT_ARCHIVED = 'PROJECT_ARCHIVED',
  PROJECT_UNARCHIVED = 'PROJECT_UNARCHIVED',
  PROJECT_DOMAIN_ATTACH = 'PROJECT_DOMAIN_ATTACH',
  PROJECT_DOMAIN_DELETE = 'PROJECT_DOMAIN_DELETE',
  PROJECT_DOMAIN_UPDATE = 'PROJECT_DOMAIN_UPDATE',
  PROJECT_DOMAIN_VERIFY = 'PROJECT_DOMAIN_VERIFY',
  PROJECT_DOMAIN_VERIFIED = 'PROJECT_DOMAIN_VERIFIED',

  DEPLOYMENT_INITIATED = 'DEPLOYMENT_INITIATED',
  DEPLOYMENT_SUCCESS = 'DEPLOYMENT_SUCCESS',
  DEPLOYMENT_FIRST_SUCCESS = 'DEPLOYMENT_FIRST_SUCCESS',
  DEPLOYMENT_FAIL = 'DEPLOYMENT_FAIL',
  DEPLOYMENT_TIMEOUT = 'DEPLOYMENT_TIMEOUT',
  DEPLOYMENT_CANCELED = 'DEPLOYMENT_CANCELED',
  DEPLOYMENT_AUTH = 'DEPLOYMENT_AUTH',

  MEMBER_JOINED = 'MEMBER_JOINED',
  MEMBER_INVITE = 'MEMBER_INVITE',
  MEMBER_REJECTED = 'MEMBER_REJECTED',
  MEMBER_REMOVED = 'MEMBER_REMOVED',

  BILLING_PLAN_INITIATED = 'BILLING_PLAN_INITIATED',
  BILLING_PLAN_APPROVED = 'BILLING_PLAN_APPROVED',
  BILLING_PLAN_FAILED = 'BILLING_PLAN_FAILED',
  BILLING_BONUS_INITIATED = 'BILLING_BONUS_INITIATED',
  BILLING_BONUS_APPROVED = 'BILLING_BONUS_APPROVED',
  BILLING_BONUS_FAILED = 'BILLING_BONUS_FAILED',
  BILLING_PLAN_DEPLETION = 'BILLING_PLAN_DEPLETION',
  BILLING_ORG_OVERDUE = 'BILLING_ORG_OVERDUE',
  BILLING_COUPON_ACTIVATED = 'BILLING_COUPON_ACTIVATED',
  BILLING_COUPON_DEACTIVATED = 'BILLING_COUPON_DEACTIVATED',
  BILLING_INVOICE_GENERATED = 'BILLING_INVOICE_GENERATED',

  COMPUTE_CLUSTER_CREATED = 'COMPUTE_CLUSTER_CREATED',
  COMPUTE_CLUSTER_INSTANCE_CREATED = 'COMPUTE_CLUSTER_INSTANCE_CREATED',
  COMPUTE_CLUSTER_INSTANCE_INITIATED = 'COMPUTE_CLUSTER_INSTANCE_INITIATED',
  COMPUTE_CLUSTER_INSTANCE_UPDATED = 'COMPUTE_CLUSTER_INSTANCE_UPDATED',
  COMPUTE_CLUSTER_INSTANCE_CLOSED = 'COMPUTE_CLUSTER_INSTANCE_CLOSED',
  COMPUTE_CLUSTER_INSTANCE_CLOSED_PROVIDER = 'COMPUTE_CLUSTER_INSTANCE_CLOSED_PROVIDER',

  COMPUTE_DEPLETION_WARN = 'COMPUTE_DEPLETION_WARN',
  COMPUTE_INSTANCE_TOPUP = 'COMPUTE_INSTANCE_TOPUP',
}

export const projectNotificationList = [
  {
    key: NotificationCategory.PROJECT_CREATED,
    value: 'Project Created',
  },
  {
    key: NotificationCategory.PROJECT_DEPLOYMENT_ENV_CREATED,
    value: 'Deployment Env Created',
  },
  {
    key: NotificationCategory.PROJECT_DEPLOYMENT_ENV_UPDATED,
    value: 'Deployment Env Updated',
  },
  {
    key: NotificationCategory.PROJECT_DEPLOYMENT_ENV_DELETED,
    value: 'Deployment Env Deleted',
  },
  {
    key: NotificationCategory.PROJECT_DEPLOYMENT_ENV_ACTIVATED,
    value: 'Deployment Env Activated',
  },
  {
    key: NotificationCategory.PROJECT_DEPLOYMENT_ENV_DEACTIVATED,
    value: 'Deployment Env Deactivated',
  },
  {
    key: NotificationCategory.PROJECT_SETTINGS_CHANGED,
    value: 'Settings Changed',
  },
  {
    key: NotificationCategory.PROJECT_ARCHIVED,
    value: 'Project Archived',
  },
  {
    key: NotificationCategory.PROJECT_UNARCHIVED,
    value: 'Project Unarchived',
  },
  {
    key: NotificationCategory.PROJECT_DOMAIN_ATTACH,
    value: 'Domain Attached',
  },
  {
    key: NotificationCategory.PROJECT_DOMAIN_DELETE,
    value: 'Domain Deleted',
  },
  {
    key: NotificationCategory.PROJECT_DOMAIN_UPDATE,
    value: 'Domain Updated',
  },
  {
    key: NotificationCategory.PROJECT_DOMAIN_VERIFY,
    value: 'Verify Domain',
  },
  {
    key: NotificationCategory.PROJECT_DOMAIN_VERIFIED,
    value: 'Domain Verified',
  },
];

export const deploymentNotificationList = [
  {
    key: NotificationCategory.DEPLOYMENT_INITIATED,
    value: 'Deployment Initiated',
  },
  {
    key: NotificationCategory.DEPLOYMENT_SUCCESS,
    value: 'Deployment Successful',
  },
  {
    key: NotificationCategory.DEPLOYMENT_FAIL,
    value: 'Deployment Failed',
  },
  {
    key: NotificationCategory.DEPLOYMENT_TIMEOUT,
    value: 'Deployment Timed Out',
  },
];

export const billingNotificationList = [
  {
    key: NotificationCategory.BILLING_PLAN_INITIATED,
    value: 'Plan Payment Initiated',
  },
  {
    key: NotificationCategory.BILLING_PLAN_APPROVED,
    value: 'Plan Payment Approved',
  },
  {
    key: NotificationCategory.BILLING_PLAN_FAILED,
    value: 'Plan Payment Failed',
  },
  {
    key: NotificationCategory.BILLING_BONUS_INITIATED,
    value: 'Bonus Payment Initiated',
  },
  {
    key: NotificationCategory.BILLING_BONUS_APPROVED,
    value: 'Bonus Payment Approved',
  },
  {
    key: NotificationCategory.BILLING_BONUS_FAILED,
    value: 'Bonus Payment Failed',
  },
  {
    key: NotificationCategory.BILLING_ORG_OVERDUE,
    value: 'Payment Overdue',
  },
  {
    key: NotificationCategory.BILLING_COUPON_ACTIVATED,
    value: 'Coupon Activated',
  },
  {
    key: NotificationCategory.BILLING_COUPON_DEACTIVATED,
    value: 'Coupon Deactivated',
  },
  {
    key: NotificationCategory.BILLING_INVOICE_GENERATED,
    value: 'Invoice Generated',
  },
];

export const computeNotificationList = [
  {
    key: NotificationCategory.COMPUTE_CLUSTER_INSTANCE_CREATED,
    value: 'Instance Created',
  },
  {
    key: NotificationCategory.COMPUTE_CLUSTER_INSTANCE_UPDATED,
    value: 'Instance Updated',
  },
  {
    key: NotificationCategory.COMPUTE_CLUSTER_INSTANCE_CLOSED,
    value: 'Instance Closed',
  },
  {
    key: NotificationCategory.COMPUTE_CLUSTER_INSTANCE_CLOSED_PROVIDER,
    value: 'Instance Closed by Provider',
  },
  {
    key: NotificationCategory.COMPUTE_DEPLETION_WARN,
    value: 'Compute Balance Warning',
  },
];

export enum NotificationCategoryList {
  COMPUTE = 'Compute',
  BILLING = 'Billing',
  DEPLOYMENT = 'Deployment',
  PROJECT = 'Project',
}

export const notificationCategoryList = [
  {
    key: 0,
    label: NotificationCategoryList.COMPUTE,
    list: computeNotificationList,
  },
  {
    key: 1,
    label: NotificationCategoryList.BILLING,
    list: billingNotificationList,
  },
  {
    key: 2,
    label: NotificationCategoryList.DEPLOYMENT,
    list: deploymentNotificationList,
  },
  {
    key: 3,
    label: NotificationCategoryList.PROJECT,
    list: projectNotificationList,
  },
];
