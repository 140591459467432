import React from 'react';
import GlobalStyles from '../../../styles/compute/global.module.scss';

interface IProps {
  used: number;
  total: number;
}

const SpheronCreditProgressBar = ({ used, total }: IProps) => {
  const amountUsed = Math.round((used / total) * 100);

  return (
    <div className="rounded-lg w-full flex justify-center flex-col">
      <div className="flex justify-between w-full mb-3">
        <span
          className="text-base-heading-text-color dark:text-dark-base-heading-text-color
        text-3 leading-3 font-600 uppercase"
        >
          Amount used : {amountUsed}%
        </span>
      </div>
      <div className="bg-feedback-info-cross dark:bg-dark-feedback-info-cross w-full rounded-full h-2">
        <div
          className={GlobalStyles.spheron_credit_progress_bar_usage}
          style={{ width: `${amountUsed}%` }}
        />
      </div>
    </div>
  );
};

export default SpheronCreditProgressBar;
