import React from 'react';

interface IProps {
  headingClassName: string;
}

const SelectPaymentMethodHeading = ({ headingClassName }: IProps) => {
  return (
    <div className="plan__heading flex flex-col">
      <h1
        className={`text-base-heading-text-color dark:text-dark-base-heading-text-color 
                    ${headingClassName || 'font-bold text-xl'} tracking-tight `}
      >
        Select Payment Method
      </h1>
      <h5 className=" text-sm font-normal">
        Select a payment method - credit card / web3 wallet for this team
        account.
      </h5>
    </div>
  );
};

export default SelectPaymentMethodHeading;
