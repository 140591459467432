/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useRef } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {
  Dropdown,
  // Link,
  LoginButton,
  OptionType,
  SectionHeading,
} from '@spheron/ui-library';
// eslint-disable-next-line max-len
// import { ReactComponent as ExternalLinkIcon } from '@spheron/ui-library/dist/assets/spheron-specific/external-link.svg';
import { ReactComponent as MoreIcon } from '@spheron/ui-library/dist/assets/more.svg';
import { ReactComponent as TickCircle } from '@spheron/ui-library/dist/assets/tick-circle.svg';
import { ReactComponent as GithubIcon } from '@spheron/ui-library/dist/assets/crypto-social/GitHub.svg';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as BitbucketIcon } from '../../../../assets/compute/icons/bitbucket-icon.svg';
import { ReactComponent as GitlabIcon } from '../../../../assets/compute/icons/gitlab-icon.svg';
import { ReactComponent as GoogleIcon } from '../../../../assets/compute/icons/google-icon.svg';
import { toggleModalShowRtk } from '../../../../redux/compute/modal/modal.slice';
import { AppDispatch, RootState } from '../../../../redux/compute/store';
import {
  ProviderName,
  ProviderType,
} from '../../../../redux/compute/combined-state.interface';
import { openProviderAppAuth } from '../../../../redux/compute/user/user.utils';

dayjs.extend(relativeTime);

const UserSettingConnections = () => {
  const dispatchRtk = useDispatch<AppDispatch>();

  const user = useSelector((state: RootState) => state.user.user);

  const userLoading = useSelector((state: RootState) => state.user.userLoading);

  const dropdownRef = useRef(null);

  const providerButtons = [
    {
      id: 1,
      name: 'Github',
      type: ProviderType.GITHUB,
      image: <GithubIcon className="w-5 h-5" />,
      alt: 'Github',
      onClick: () => openProviderAppAuth('github'),
      showBtnForWeb3: false,
    },
    {
      id: 2,
      name: 'Gitlab',
      type: ProviderType.GITLAB,
      image: <GitlabIcon className="w-5 h-5" />,
      alt: 'Gitlab',
      onClick: () => openProviderAppAuth('gitlab'),
      showBtnForWeb3: false,
    },
    {
      id: 3,
      name: 'Bitbucket',
      type: ProviderType.BITBUCKET,
      image: <BitbucketIcon className="w-5 h-5" />,
      alt: 'Bitbucket',
      onClick: () => openProviderAppAuth('bitbucket'),
      showBtnForWeb3: false,
    },
    {
      id: 4,
      name: 'Google',
      type: ProviderType.GOOGLE,
      image: <GoogleIcon className="w-5 h-5" />,
      alt: 'Google',
      onClick: () => openProviderAppAuth('google'),
      showBtnForWeb3: true,
    },
  ];

  const filteredProviderButtons = useMemo(
    () =>
      providerButtons.filter(
        (button) =>
          !user?.providerProfiles
            .map((provider) => provider.providerName)
            .includes(button.type)
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user?.providerProfiles]
  );

  const providerIcon = (provider: ProviderType) => {
    switch (provider) {
      case ProviderType.GITHUB:
        return <GithubIcon className="w-5 h-5" />;

      case ProviderType.GITLAB:
        return <GitlabIcon className="w-5 h-5" />;

      case ProviderType.BITBUCKET:
        return <BitbucketIcon className="w-5 h-5" />;

      case ProviderType.GOOGLE:
        return <GoogleIcon className="w-5 h-5" />;

      default:
        return <></>;
    }
  };

  return (
    <div className="space-y-8">
      <>
        <SectionHeading
          heading="Login Connections"
          // eslint-disable-next-line max-len
          subHeading="Connect your Personal Account on Spheron with a third-party service to use it for login. One Login Connection can be added per third-party service."
          showRefresh={false}
          showSwitch={false}
          handleIconClick={() => {}}
          handleRefreshClick={() => {}}
          handleSwitchClick={() => {}}
          switchDisable={false}
          handleClick={() => {}}
          loading={false}
          time={''}
          refreshType={'default'}
          showText={false}
          toggleId={''}
          isChecked={false}
          handleChange={() => {}}
        />
        {/* TODO No specific reference in docs found for login connection */}
        {/* <Link
          type="primary"
          size="default"
          text="Learn more"
          isBold={false}
          onClick={() => window.open('https://docs.spheron.network', '_blank')}
          rightIcon={
            <ExternalLinkIcon className="w-5 h-5 text-base-link dark:text-dark-base-link" />
          }
        /> */}
      </>

      {!userLoading && (
        <>
          {user?.providerProfiles.map((provider) => (
            <div className="relative">
              <LoginButton
                key={provider.id}
                label={
                  provider.providerName.charAt(0).toUpperCase() +
                  provider.providerName.slice(1)
                }
                sublabel={provider.name}
                linkTitle={
                  provider.providerName === ProviderType.GOOGLE
                    ? ''
                    : `@${provider.username}`
                }
                linkUrl={provider.html_url}
                socialIcon={providerIcon(provider.providerName as ProviderType)}
                handleClick={() => null}
                classname="!w-full"
                rightText={`Connected ${dayjs().to(
                  dayjs(new Date(`${provider?.createdAt}`))
                )}`}
                rightIcon={
                  <TickCircle className="text-base-icon dark:text-dark-base-icon w-6 h-6" />
                }
              />
              {user?.providerProfiles.length > 1 &&
                provider.providerName !== ProviderName.GOOGLE && (
                  <div className="absolute top-0 right-2" ref={dropdownRef}>
                    <Dropdown
                      dropdownType="button"
                      dropdownSize="compact"
                      bordersNone
                      buttonImage={
                        <MoreIcon
                          className="rotate-90 w-4 h-4 
                                          text-base-icon dark:text-dark-base-icon"
                        />
                      }
                      options={[
                        {
                          id: 1,
                          label: 'Disconnect',
                          handleClick: () => {
                            dispatchRtk(
                              toggleModalShowRtk({
                                modalShow: true,
                                modalType: 'disconnectLoginConnection',
                                options: {
                                  provider: provider.providerName,
                                },
                              })
                            );
                          },
                          optionType: 'primary' as OptionType,
                        },
                      ]}
                    />
                  </div>
                )}
            </div>
          ))}
        </>
      )}
      <div className="grid grid-cols-4 gap-8 w-full">
        {filteredProviderButtons.map((authItem) => (
          <>
            {(authItem.showBtnForWeb3 ||
              user?.provider.name !== ProviderName.WEB3) && (
              <LoginButton
                key={authItem.id}
                disabled={userLoading}
                label={authItem.name}
                socialIcon={authItem.image}
                handleClick={authItem.onClick}
                classname="!w-full col-span-1"
              />
            )}
          </>
        ))}
      </div>
    </div>
  );
};

export default UserSettingConnections;
