import { requestPipeline } from '../root-utils';
import { IGatewayDetails } from './gateway.interfaces';

export const getGatewaysService = async (
  organizationId: string
): Promise<any> => {
  return requestPipeline({
    url: `organization/${organizationId}/domains`,
    method: 'GET',
  });
};

export const createGatewayService = async (
  gatewayDetails: IGatewayDetails
): Promise<any> => {
  return requestPipeline({
    url: `organization/${gatewayDetails.organizationId}/domains`,
    method: 'POST',
    body: JSON.stringify(gatewayDetails),
  });
};

export const deleteGatewayService = async (
  gatewayDetails: IGatewayDetails
): Promise<any> => {
  return requestPipeline({
    url: `organization/${gatewayDetails.organizationId}/domains/${gatewayDetails.id}`,
    method: 'DELETE',
    body: JSON.stringify({ name: gatewayDetails.name }),
  });
};
