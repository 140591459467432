import React, { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Button } from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import {
  ClusterType,
  IInstancePlan,
  IPLeaseType,
  LoadingType,
  ResourcesAvailable,
  ScalingType,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.interfaces';
import TemplateCard from '../TemplateCard';
import { AppDispatch, RootState } from '../../../../../redux/compute/store';
import { togglePriceLoadingRtk } from '../../../../../redux/compute/instance/instance-creation/instance-creation.slice';
import { useQuery } from '../../../../../hooks/useQuery';
import { setSelectedTemplateRtk } from '../../../../../redux/compute/cluster/cluster.slice';
// eslint-disable-next-line max-len
import { isAccelerateDeployment } from '../../../../../redux/compute/instance/instance-creation/instance-creation.utils';
import { ISpheronWallet } from '../../../../../redux/compute/organisation/organisation.interfaces';
import config from '../../../../../config';
import { addNotificationRtk } from '../../../../../redux/compute/notification/notification.slice';
import { NotificationType } from '../../../../../redux/compute/notification/notification.interfaces';

interface IProps {
  isUpdate: boolean;
  selectedInstancePlan: IInstancePlan | null;
  isDisable: boolean;
  startInstanceDeployment: any;
  updateDisabled: boolean;
  insufficientBalance: boolean;
}

const SelectedPlan = ({
  isUpdate,
  selectedInstancePlan,
  isDisable,
  startInstanceDeployment,
  updateDisabled,
  insufficientBalance,
}: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();
  const queryParams = useQuery();
  const provider = queryParams.get('provider');
  const type = queryParams.get('type');
  const selectedTemplate = useSelector(
    (state: RootState) => state.cluster.selectedTemplate
  );
  const customStorageInput = useSelector(
    (state: RootState) => state.instanceCreation.customStorageInput
  );
  const selectedStorage = useSelector(
    (state: RootState) => state.instanceCreation.customStorage
  );
  const updatePersistentStorage = useSelector(
    (state: RootState) => state.instanceCreation.customPersistentStorage
  );
  const updateHddStorage = useSelector(
    (state: RootState) => state.instanceCreation.customHddStorage
  );
  const updateSsdStorage = useSelector(
    (state: RootState) => state.instanceCreation.customSsdStorage
  );
  const updateNvmStorage = useSelector(
    (state: RootState) => state.instanceCreation.customNvmStorage
  );
  const customStoragePrice = useSelector(
    (state: RootState) => state.instanceCreation.updatedStoragePrice
  );
  const customPersistentStorage = useSelector(
    (state: RootState) => state.instanceCreation.updatedPersistentStoragePrice
  );
  const clusterType = useSelector(
    (state: RootState) => state.instanceCreation.clusterType
  );
  const instanceDeploymentLoading = useSelector(
    (state: RootState) => state.instance.deployingInstance
  );
  const priceLoading = useSelector(
    (state: RootState) => state.instanceCreation.priceLoading
  );
  const priceLoadingType = useSelector(
    (state: RootState) => state.instanceCreation.priceLoadingType
  );
  const instanceValue = useSelector(
    (state: RootState) => state.instanceCreation.instanceValue
  );
  const defaultCustomPlanValue = useSelector(
    (state: RootState) => state.instanceCreation.defaultCustomPlansValue
  );
  const storageLoading = useSelector(
    (state: RootState) => state.instanceCreation.storageLoading
  );
  const deploymentType = useSelector(
    (state: RootState) => state.instanceCreation.deploymentType
  );
  const persistentStorageAvailable = useSelector(
    (state: RootState) => state.instanceCreation.persistentStorageAvailable
  );
  const storageAvailable = useSelector(
    (state: RootState) => state.instanceCreation.storageAvailable
  );
  const planAvailable = useSelector(
    (state: RootState) => state.instanceCreation.planAvailable
  );
  const spheronWallet = useSelector(
    (state: RootState) => state.organisation.spheronWallet
  );
  const clusterWalletUsage = useSelector(
    (state: RootState) => state.cluster.clusterWalletUsage
  );
  const clusterScaling = useSelector(
    (state: RootState) => state.instanceCreation.clusterScaling
  );
  const ipLeaseType = useSelector(
    (state: RootState) => state.instanceCreation.ipLeaseType
  );
  const ipLeasePrice = useSelector(
    (state: RootState) => state.instanceCreation.ipLeasePrice
  );
  const activeDeployment = useSelector(
    (state: RootState) => state.instance.activeDeployment
  );
  const activePorts = activeDeployment?.services[0]?.ports.some(
    (port) => port.endpoint || port.type === IPLeaseType.STATIC
  );
  const canDeploy = useSelector((state: RootState) => state.user.canDeploy);
  const discountCpuMemory = selectedTemplate?.discount;
  const discountStorage = selectedTemplate?.discount;
  const discountPersistentStorage = selectedTemplate?.discount;
  const discountIp = selectedTemplate?.discount;

  const configAvailable =
    planAvailable === ResourcesAvailable.NOT_AVAILABLE ||
    storageAvailable === ResourcesAvailable.NOT_AVAILABLE ||
    persistentStorageAvailable === ResourcesAvailable.NOT_AVAILABLE;
  const storage = isUpdate
    ? customStorageInput || selectedStorage || 20
    : selectedStorage || 20;
  const persistentStorage =
    (updateSsdStorage > 0 && updateSsdStorage) ||
    (updateHddStorage > 0 && updateHddStorage) ||
    (updateNvmStorage > 0 && updateNvmStorage);

  const offerCustomStoragePrice = discountStorage
    ? ((100 - discountStorage.discountPercent) * customStoragePrice) / 100
    : 0;
  const offerCustomPersistentStoragePrice = discountPersistentStorage
    ? ((100 - discountPersistentStorage.discountPercent) *
        customPersistentStorage) /
      100
    : 0;
  const offerIpLeasePrice = discountIp
    ? ((100 - discountIp.discountPercent) * ipLeasePrice) / 100
    : 0;
  const offerUpdatedIpLeasePriceHourly: string =
    offerIpLeasePrice &&
    (ipLeaseType === IPLeaseType.DEDICATED || (isUpdate && activePorts))
      ? (offerIpLeasePrice / 24).toFixed(4)
      : '0';
  const offerUpdatedIpLeasePriceMonthly: string =
    offerIpLeasePrice &&
    (ipLeaseType === IPLeaseType.DEDICATED || (isUpdate && activePorts))
      ? (offerIpLeasePrice * 30).toFixed(2)
      : '0';
  const updatedIpLeasePriceHourly: string =
    ipLeasePrice &&
    (ipLeaseType === IPLeaseType.DEDICATED || (isUpdate && activePorts))
      ? (ipLeasePrice / 24).toFixed(4)
      : '0';
  const updatedIpLeasePriceMonthly: string =
    ipLeasePrice &&
    (ipLeaseType === IPLeaseType.DEDICATED || (isUpdate && activePorts))
      ? (ipLeasePrice * 30).toFixed(2)
      : '0';
  const updatedStoragePriceHourly: string = customStoragePrice
    ? (customStoragePrice / 24).toFixed(4)
    : '0';
  const updatedStoragePriceMonthly: string = customStoragePrice
    ? (customStoragePrice * 30).toFixed(2)
    : '0';
  const updatedPersistentStoragePriceHourly: string = customPersistentStorage
    ? (customPersistentStorage / 24).toFixed(4)
    : '0';
  const updatedPersistentStoragePriceMonthly: string = customPersistentStorage
    ? (customPersistentStorage * 30).toFixed(2)
    : '0';

  const offerUpdatedStoragePriceHourly: string = offerCustomStoragePrice
    ? (offerCustomStoragePrice / 24).toFixed(4)
    : '0';
  const offerUpdatedStoragePriceMonthly: string = offerCustomStoragePrice
    ? (offerCustomStoragePrice * 30).toFixed(2)
    : '0';
  const offerUpdatedPersistentStoragePriceHourly: string =
    offerCustomPersistentStoragePrice
      ? (offerCustomPersistentStoragePrice / 24).toFixed(4)
      : '0';
  const offerUpdatedPersistentStoragePriceMonthly: string =
    offerCustomPersistentStoragePrice
      ? (offerCustomPersistentStoragePrice * 30).toFixed(2)
      : '0';

  const offerCpuMemoryPrice = discountCpuMemory
    ? ((selectedInstancePlan?.price || 0) *
        (100 - discountCpuMemory.discountPercent)) /
      100
    : 0;
  const hourlyCostUSD = ((selectedInstancePlan?.price || 0) / 24 || 0).toFixed(
    4
  );
  const monthlyCostUSD = ((selectedInstancePlan?.price || 0) * 30 || 0).toFixed(
    2
  );
  const offerHourlyCostUSD = ((offerCpuMemoryPrice || 0) / 24 || 0).toFixed(4);
  const offerMonthlyCostUSD = ((offerCpuMemoryPrice || 0) * 30 || 0).toFixed(2);

  const totalPriceHourly = (
    parseFloat(hourlyCostUSD) +
    parseFloat(updatedStoragePriceHourly) +
    parseFloat(updatedPersistentStoragePriceHourly) +
    parseFloat(updatedIpLeasePriceHourly)
  ).toFixed(2);
  const totalPriceMonthly = (
    parseFloat(monthlyCostUSD) +
    parseFloat(updatedStoragePriceMonthly) +
    parseFloat(updatedPersistentStoragePriceMonthly) +
    parseFloat(updatedIpLeasePriceMonthly)
  ).toFixed(2);
  const offerTotalPriceHourly = (
    parseFloat(offerHourlyCostUSD) +
    parseFloat(offerUpdatedStoragePriceHourly) +
    parseFloat(offerUpdatedPersistentStoragePriceHourly) +
    parseFloat(offerUpdatedIpLeasePriceHourly)
  ).toFixed(2);
  const offerTotalPriceMonthly = (
    parseFloat(offerMonthlyCostUSD) +
    parseFloat(offerUpdatedStoragePriceMonthly) +
    parseFloat(offerUpdatedPersistentStoragePriceMonthly) +
    parseFloat(offerUpdatedIpLeasePriceMonthly)
  ).toFixed(2);
  let finalCostUSD: string;
  let updatedStoragePrice: string;
  let updatedPersistentStoragePrice: string;
  let totalPrice: string;
  let offerFinalCostUSD: string;
  let offerUpdatedStoragePrice: string;
  let offerUpdatedPersistentStoragePrice: string;
  let offerTotalPrice: string;
  let costUnit: string;
  let updatedIpLeasePrice: string;
  let offerUpdatedIpLeasePrice: string;
  if (isAccelerateDeployment(deploymentType)) {
    finalCostUSD = hourlyCostUSD;
    updatedStoragePrice = updatedStoragePriceHourly;
    updatedPersistentStoragePrice = updatedPersistentStoragePriceHourly;
    totalPrice = totalPriceHourly;
    updatedIpLeasePrice = updatedIpLeasePriceHourly;
    offerUpdatedIpLeasePrice = offerUpdatedIpLeasePriceHourly;
    offerFinalCostUSD = offerHourlyCostUSD;
    offerUpdatedStoragePrice = offerUpdatedStoragePriceHourly;
    offerUpdatedPersistentStoragePrice =
      offerUpdatedPersistentStoragePriceHourly;
    offerTotalPrice = offerTotalPriceHourly;

    costUnit = 'hourly';
  } else {
    finalCostUSD = monthlyCostUSD;
    updatedStoragePrice = updatedStoragePriceMonthly;
    updatedPersistentStoragePrice = updatedPersistentStoragePriceMonthly;
    totalPrice = totalPriceMonthly;
    updatedIpLeasePrice = updatedIpLeasePriceMonthly;
    offerUpdatedIpLeasePrice = offerUpdatedIpLeasePriceMonthly;
    offerFinalCostUSD = offerMonthlyCostUSD;
    offerUpdatedStoragePrice = offerUpdatedStoragePriceMonthly;
    offerUpdatedPersistentStoragePrice =
      offerUpdatedPersistentStoragePriceMonthly;
    offerTotalPrice = offerTotalPriceMonthly;

    costUnit = 'monthly';
  }
  const instancePrice = (parseFloat(totalPrice) * instanceValue).toFixed(2);
  const offerInstancePrice = (
    parseFloat(offerTotalPrice) * instanceValue
  ).toFixed(2);
  useEffect(() => {
    if (updatedStoragePrice === '0') {
      dispatchRtk(
        togglePriceLoadingRtk({
          priceLoading: true,
          priceLoadingType: LoadingType.STORAGE,
        })
      );
    }
  }, [priceLoading, updatedStoragePrice, dispatchRtk]);

  useEffect(() => {
    if (provider !== 'template' && type === 'redeploy') {
      dispatchRtk(setSelectedTemplateRtk(null));
    }
  }, [dispatchRtk, provider, type]);
  const referralBalance = (spheronWallet?.details as ISpheronWallet)
    ?.referralBalance;

  const creditsBalance = (spheronWallet?.details as ISpheronWallet)
    ?.creditsBalance;
  const balance = clusterWalletUsage?.balance
    ? (clusterWalletUsage?.balance).toFixed(
        config.dollarDecimalRound.DOLLAR_DECIMAL_ROUND
      )
    : 0;

  const isDisableConditions =
    isDisable ||
    configAvailable ||
    priceLoading ||
    (clusterScaling === ScalingType.NO &&
      Number((referralBalance + creditsBalance).toFixed(2)) > 0) ||
    Number(balance) <= 0;
  return (
    <div className="mt-5">
      <div
        className="bg-base-bg dark:bg-dark-base-bg border-base-border
        shadow-selectedCard rounded-lg 
        px-4 py-5 border dark:border-dark-base-border"
      >
        <div
          className="text-base-heading-text-color dark:text-dark-base-heading-text-color
          text-xl font-bold"
        >
          {selectedInstancePlan?._id === 'custom-id'
            ? 'Custom Plan'
            : selectedInstancePlan?.name}
        </div>
        {clusterType === ClusterType.TEMPLATE && (
          <>
            {selectedTemplate && (
              <div className="mt-4">
                <TemplateCard
                  title={selectedTemplate.name}
                  imageShow
                  image={selectedTemplate.metadata.icon}
                  badgeContent={selectedTemplate.metadata.category}
                  subtitle=""
                  isSelected
                  onSelect={() => null}
                  isSelectedTemplate
                  discount={
                    clusterScaling === ScalingType.NO
                      ? null
                      : selectedTemplate.discount
                  }
                />
              </div>
            )}
          </>
        )}
        <div
          className="flex items-center mt-4 border-b border-bg-lighGray 
        pb-3 justify-between dark:border-dark-base-border"
        >
          <span className="text-text-darkGray text-sm font-normal dark:text-text-darkGray">
            {selectedInstancePlan?._id === 'custom-id'
              ? 'Custom Plan'
              : selectedInstancePlan?.name}
          </span>
          <div className="flex flex-col gap-0.5">
            {discountCpuMemory && (
              <span
                className="text-feedback-error-text
                text-base font-bold"
              >
                {selectedInstancePlan?.price && `$${offerFinalCostUSD}`}
              </span>
            )}
            <span
              className={`${discountCpuMemory ? 'line-through' : ''}
              text-base-heading-text-color dark:text-dark-base-heading-text-color
              text-base font-bold`}
            >
              {selectedInstancePlan?.price && `$${finalCostUSD}`}
            </span>
          </div>
        </div>
        <div
          className="flex items-center mt-3 border-b border-bg-lighGray pb-3 
        justify-between dark:border-dark-base-border"
        >
          <span className="text-text-darkGray text-sm font-normal dark:text-text-darkGray">
            {storage} GB SSD Storage
          </span>
          <div className="flex flex-col gap-0.5">
            {discountStorage && (
              <span
                className="text-feedback-error-text
                text-base font-bold"
              >
                {(priceLoading && priceLoadingType === LoadingType.STORAGE) ||
                defaultCustomPlanValue.length === 0 ||
                (storageLoading && isUpdate) ? (
                  <Skeleton
                    height={20}
                    width={50}
                    duration={5}
                    containerClassName="flex"
                  />
                ) : (
                  `$${offerUpdatedStoragePrice}`
                )}
              </span>
            )}
            <span
              className={`${discountStorage ? 'line-through' : ''}
              text-base-heading-text-color dark:text-dark-base-heading-text-color
              text-base font-bold`}
            >
              {(priceLoading && priceLoadingType === LoadingType.STORAGE) ||
              defaultCustomPlanValue.length === 0 ||
              (storageLoading && isUpdate) ? (
                <Skeleton
                  height={20}
                  width={50}
                  duration={5}
                  containerClassName="flex"
                />
              ) : (
                `$${updatedStoragePrice}`
              )}
            </span>
          </div>
        </div>
        {customPersistentStorage &&
        persistentStorage &&
        updatePersistentStorage ? (
          <div
            className="flex items-center mt-3 border-b border-bg-lighGray pb-3 
        justify-between dark:border-dark-base-border"
          >
            <span className="text-text-darkGray text-sm font-normal dark:text-text-darkGray">
              {persistentStorage} GB {updatePersistentStorage} Persistent
              Storage
            </span>
            <div className="flex flex-col gap-0.5">
              {discountPersistentStorage && (
                <span
                  className="text-feedback-error-text
                  text-base font-bold"
                >
                  {(priceLoading &&
                    priceLoadingType === LoadingType.PERSISTENT_STORAGE) ||
                  (isUpdate && storageLoading) ? (
                    <Skeleton
                      height={20}
                      width={50}
                      duration={5}
                      containerClassName="flex"
                    />
                  ) : (
                    `$${offerUpdatedPersistentStoragePrice}`
                  )}
                </span>
              )}
              <span
                className={`${discountPersistentStorage ? 'line-through' : ''}
                text-base-heading-text-color dark:text-dark-base-heading-text-color
                text-base font-bold`}
              >
                {(priceLoading &&
                  priceLoadingType === LoadingType.PERSISTENT_STORAGE) ||
                (isUpdate && storageLoading) ? (
                  <Skeleton
                    height={20}
                    width={50}
                    duration={5}
                    containerClassName="flex"
                  />
                ) : (
                  `$${updatedPersistentStoragePrice}`
                )}
              </span>
            </div>
          </div>
        ) : null}
        {ipLeaseType === IPLeaseType.DEDICATED || (isUpdate && activePorts) ? (
          <div
            className="flex items-center mt-3 border-b border-bg-lighGray pb-3 
        justify-between dark:border-dark-base-border"
          >
            <span className="text-text-darkGray text-sm font-normal dark:text-text-darkGray">
              Dedicated IP
            </span>
            <div className="flex flex-col gap-0.5">
              {discountIp && (
                <span
                  className="text-feedback-error-text
                text-base font-bold"
                >
                  {`$${offerUpdatedIpLeasePrice}`}
                </span>
              )}
              <span
                className={`text-base-heading-text-color dark:text-dark-base-heading-text-color
                text-base font-bold ${discountIp ? 'line-through' : ''}`}
              >
                {`$${updatedIpLeasePrice}`}
              </span>
            </div>
          </div>
        ) : null}
        <div
          className="flex items-center mt-3 border-b border-bg-lighGray pb-3 
        justify-between dark:border-dark-base-border"
        >
          <span
            className="text-base-heading-text-color dark:text-dark-base-heading-text-color
            text-sm font-bold"
          >
            Sub-Total
          </span>
          <span
            className="text-base-heading-text-color dark:text-dark-base-heading-text-color
            text-base font-bold
            flex flex-row items-center"
          >
            {(priceLoading &&
              (priceLoadingType === LoadingType.STORAGE ||
                priceLoadingType === LoadingType.PERSISTENT_STORAGE)) ||
            defaultCustomPlanValue.length === 0 ||
            (storageLoading && isUpdate) ? (
              <Skeleton
                height={20}
                width={50}
                duration={5}
                containerClassName="flex"
              />
            ) : (
              selectedInstancePlan?.price && (
                <>
                  <span className="flex flex-col gap-0.5">
                    {discountCpuMemory && (
                      <span className="text-feedback-error-text">
                        ${offerTotalPrice}
                      </span>
                    )}
                    <span
                      className={`${discountCpuMemory ? 'line-through' : ''}`}
                    >
                      ${totalPrice}
                    </span>
                  </span>
                  <span
                    className="ml-1 text-base font-bold
                    text-base-sub-text-color dark:text-dark-base-sub-text-color"
                  >
                    x{instanceValue}
                  </span>
                </>
              )
            )}
          </span>
        </div>
        <div className="flex items-center mt-3 justify-between">
          <span
            className="text-base-heading-text-color dark:text-dark-base-heading-text-color
            text-sm font-bold"
          >
            Total
          </span>
          <div className="flex flex-col gap-0.5">
            {discountCpuMemory && (
              <div
                className="text-feedback-error-text
                text-xl font-bold
                flex flex-row gap-2 items-center"
              >
                {(priceLoading &&
                  (priceLoadingType === LoadingType.STORAGE ||
                    priceLoadingType === LoadingType.PERSISTENT_STORAGE)) ||
                defaultCustomPlanValue.length === 0 ||
                (isUpdate && storageLoading) ? (
                  <Skeleton
                    height={20}
                    width={50}
                    duration={5}
                    containerClassName="flex"
                  />
                ) : (
                  selectedInstancePlan?.price && `$${offerInstancePrice}`
                )}
                <span
                  className="text-base-sub-text-color dark:text-dark-base-sub-text-color
                  text-xs font-normal"
                >
                  {costUnit}
                </span>
              </div>
            )}
            <div
              className={`${discountCpuMemory ? 'line-through' : ''}
              text-base-heading-text-color dark:text-dark-base-heading-text-color
              text-xl font-bold
              flex flex-row gap-2 items-center`}
            >
              {(priceLoading &&
                (priceLoadingType === LoadingType.STORAGE ||
                  priceLoadingType === LoadingType.PERSISTENT_STORAGE)) ||
              defaultCustomPlanValue.length === 0 ||
              (isUpdate && storageLoading) ? (
                <Skeleton
                  height={20}
                  width={50}
                  duration={5}
                  containerClassName="flex"
                />
              ) : (
                selectedInstancePlan?.price && `$${instancePrice}`
              )}
              <span
                className="text-base-sub-text-color dark:text-dark-base-sub-text-color
                text-xs font-normal"
              >
                {costUnit}
              </span>
            </div>
          </div>
        </div>
        {isUpdate ? (
          <div className="flex items-center justify-center gap-x-4 my-4">
            <Button
              buttonType="primary"
              label="UPDATE"
              size="medium"
              fillWidth
              disabled={
                configAvailable ||
                priceLoading ||
                insufficientBalance ||
                updateDisabled ||
                true
              }
              onClick={startInstanceDeployment}
            />
          </div>
        ) : (
          <>
            <Button
              buttonType="primary"
              label="Deploy"
              size="medium"
              classname="my-4 w-full flex justify-center"
              onClick={() => {
                if (canDeploy) {
                  startInstanceDeployment();
                } else {
                  dispatchRtk(
                    addNotificationRtk({
                      message:
                        'Please verify your email using Google to continue',
                      timestamp: Date.now(),
                      type: NotificationType.Error,
                    })
                  );
                }
              }}
              disabled={isDisableConditions || insufficientBalance || true}
              loading={instanceDeploymentLoading}
            />
            {isDisable && !instanceDeploymentLoading && (
              <p className="mt-3 text-feedback-error-text text-sm font-normal leading-5">
                <span className="text-feedback-error-text">*</span> Ensure all
                required fields are filled.
              </p>
            )}
          </>
        )}
        {!isUpdate && (
          <p
            className="text-text-darkGray mt-3 text-sm font-normal leading-5
          dark:text-text-darkGray"
          >
            {/* The prices here are just estimates, they will be different past
            deployment */}
          </p>
        )}
      </div>
    </div>
  );
};

export default SelectedPlan;
