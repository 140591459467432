import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation, Outlet } from 'react-router-dom';
import {
  BreadcrumbsBar,
  InnerNavigation,
  InstanceCardScalingType,
  Refresh,
  Tabs,
} from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import GlobalStyles from '../../../../styles/compute/global.module.scss';
import {
  InstanceLogs,
  InstanceStatus,
} from '../../../../redux/compute/instance/instance.interfaces';
// eslint-disable-next-line max-len
import { HealthCheckStatus as HealthCheckStatusEnum } from '../../../../redux/compute/cluster/cluster.interfaces';
import {
  downloadLogsThunk,
  getComputeInstanceDetailsThunk,
  getDeploymentDetailsThunk,
  getDeploymentLogsThunk,
  getInstanceActivityThunk,
  getInstanceMetricsChartThunk,
} from '../../../../redux/compute/instance/instance.thunks';
import { AppDispatch, RootState } from '../../../../redux/compute/store';
import {
  resetServiceState,
  setLogsLastUpdated,
  setSelectedServiceRtk,
  setServiceLogs,
  toggleSelectedServiceLogsLoading,
} from '../../../../redux/compute/service/service.slice';
import { IInstance } from '../../../../redux/compute/project/project.interfaces';
import { getComputeProjectDetailsThunk } from '../../../../redux/compute/project/project.thunks';
import { callSSE } from '../../../../libs/Compute/sse';
import { EventSourceMessage } from '../../../../libs/Compute/fetchEventSource';
import {
  getAvailableInventoryThunk,
  getInstanceUnitPricesThunk,
} from '../../../../redux/compute/instance/instance-creation/instance-creation.thunks';

const ServiceMainView = () => {
  const navigate = useNavigate();
  const dispatchRtk = useDispatch<AppDispatch>();
  const params = useParams<{
    orgUsername: string;
    projectId: string;
    instanceId: string;
    serviceId: string;
    slug3: string;
  }>();
  const location = useLocation();

  const selectedOrganisationLoading = useSelector(
    (state: RootState) => state.organisation.selectedOrganisationLoading
  );

  const activeDeployment = useSelector(
    (state: RootState) => state.instance.activeDeployment
  );
  const activeDeploymentLoading = useSelector(
    (state: RootState) => state.instance.activeDeploymentLoading
  );

  const selectedInstanceLoading: boolean = useSelector(
    (state: RootState) => state.instance.selectedInstanceLoading
  );

  const selectedProject = useSelector(
    (state: RootState) => state.computeProject.selectedProject
  );

  const selectedInstance = useSelector(
    (state: RootState) => state.instance.selectedInstance
  );

  const instanceComputeLogs = useSelector(
    (state: RootState) => state.instance.selectedInstanceComputeLogs
  );

  const totalInstanceComputeLogsCount = useSelector(
    (state: RootState) =>
      state.instance.selectedInstanceComputeLogsPagination.total
  );

  const selectedService = useSelector(
    (state: RootState) => state.computeService.selectedService
  );

  const selectedComputeProjectName = useSelector(
    (state: RootState) => state.computeProject.selectedProjectName
  );

  const unitPrices = useSelector(
    (state: RootState) => state.instanceCreation.unitPrices
  );

  const availableInventory = useSelector(
    (state: RootState) => state.instanceCreation.availableInventory
  );

  // eslint-disable-next-line no-unused-vars
  const [showHealthCheckBar, setShowHealthCheckBar] = useState<boolean>(true);

  useEffect(() => {
    if (params.projectId && !selectedProject) {
      dispatchRtk(getComputeProjectDetailsThunk(params.projectId));
    }
    return () => {
      dispatchRtk(resetServiceState());
      dispatchRtk(setSelectedServiceRtk(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (params.instanceId && !selectedInstance) {
      dispatchRtk(getComputeInstanceDetailsThunk(params.instanceId));
    }
  }, [dispatchRtk, params.instanceId, selectedInstance]);

  useEffect(() => {
    const serviceSseController: AbortController = new AbortController();
    const { signal }: { signal: AbortSignal } = serviceSseController;

    if (activeDeployment) {
      callSSE({
        msgCb: (msg: EventSourceMessage) => {
          const stream = JSON.parse(msg?.data);

          switch (stream.type) {
            case 'log-events': {
              dispatchRtk(toggleSelectedServiceLogsLoading(true));
              dispatchRtk(
                getDeploymentLogsThunk({
                  deploymentId: stream.objectId,
                  type: InstanceLogs.COMPUTE_LOGS,
                  serviceName: params.serviceId,
                })
              );
              break;
            }
            case 'download-logs': {
              dispatchRtk(
                downloadLogsThunk({
                  url: stream.url,
                  id: params?.serviceId || '',
                })
              );
              break;
            }
            default:
              break;
          }
        },
        errCb: (error: any) => {
          console.error('Error in service SSE -> ', (error as Error).message);
        },
        sessionId: activeDeployment.topic?.trim(),
        signal,
      });
    }

    return () => {
      if (serviceSseController) {
        serviceSseController.abort();
      }
    };
  }, [dispatchRtk, activeDeployment, selectedInstance, params.serviceId]);

  useEffect(() => {
    if (selectedInstance?.deployments) {
      dispatchRtk(
        getDeploymentLogsThunk({
          deploymentId:
            selectedInstance?.activeDeployment ||
            (selectedInstance as IInstance).deployments[
              selectedInstance.deployments.length - 1
            ],
          type: InstanceLogs.COMPUTE_LOGS,
          serviceName: params.serviceId,
        })
      );
    }
  }, [
    dispatchRtk,
    params.serviceId,
    selectedInstance,
    selectedInstance?.deployments,
  ]);

  useEffect(() => {
    if (selectedInstance?.deployments && !activeDeployment) {
      dispatchRtk(
        getDeploymentDetailsThunk(
          (selectedInstance as IInstance)?.activeDeployment ||
            (selectedInstance as IInstance).deployments[
              selectedInstance.deployments.length - 1
            ]
        )
      );
      dispatchRtk(getInstanceActivityThunk(selectedInstance._id!));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInstance, selectedInstance?.deployments]);

  useEffect(() => {
    if (params.serviceId && activeDeployment) {
      dispatchRtk(
        setSelectedServiceRtk(
          activeDeployment.services.find(
            (service) => service.name === params.serviceId
          )! as any
        )
      );
    }
  }, [activeDeployment, dispatchRtk, params.serviceId]);

  useEffect(() => {
    if (
      instanceComputeLogs &&
      selectedService &&
      totalInstanceComputeLogsCount &&
      selectedInstance
    ) {
      dispatchRtk(
        setServiceLogs({
          logs: instanceComputeLogs.filter(
            (log) =>
              log !== '' &&
              log.slice(0, (selectedService?.name?.length || 0) + 2) ===
                `[${selectedService?.name}]`
          ),
          logsLength: totalInstanceComputeLogsCount,
        })
      );
      dispatchRtk(setLogsLastUpdated(selectedInstance.lastLogUpdate));
    }
  }, [
    totalInstanceComputeLogsCount,
    dispatchRtk,
    instanceComputeLogs,
    selectedService,
    selectedInstance,
  ]);

  const isMonitoringRoute =
    params.slug3 === 'monitoring' || params.slug3 === 'activity-details';

  const isScaled =
    selectedInstance && (selectedInstance as IInstance)?.scalable;

  const isDeployed: boolean =
    activeDeployment?.status === InstanceStatus.DEPLOYED ||
    activeDeployment?.status === InstanceStatus.PROVISIONED;

  const hasHealthCheck: boolean = !!(activeDeployment as any)?.healthCheck;

  const isHealthUnknown: boolean =
    (activeDeployment as any)?.healthCheck?.status ===
    HealthCheckStatusEnum.UNKNOWN;

  const showHealthCheck =
    isDeployed &&
    hasHealthCheck &&
    !isHealthUnknown &&
    showHealthCheckBar &&
    !selectedInstanceLoading;

  const breadcrumbItems = [
    {
      handleClick: () => navigate(`/${params.orgUsername}/dashboard`),
      id: 1,
      linkName: 'Projects',
      urlPath: `/${params.orgUsername}/dashboard`,
    },
    {
      handleClick: () =>
        navigate(`/${params.orgUsername}/project/${params.projectId}`),
      id: 2,
      linkName: `${
        selectedComputeProjectName || selectedInstance?.computeProject.name
      }`,
      urlPath: `/${params.orgUsername}/project/${params.projectId}`,
    },
    {
      handleClick: () =>
        navigate(
          `/${params.orgUsername}/${params.projectId}/${params.instanceId}`
        ),
      id: 3,
      linkName: `${selectedInstance?.name}`,
      urlPath: `/${params.orgUsername}/${params.projectId}/${params.instanceId}`,
    },
    {
      handleClick: () =>
        navigate(
          `/${params.orgUsername}/${params.projectId}/${params.instanceId}/service/${params.serviceId}/overview`
        ),
      id: 3,
      linkName: `${params.serviceId}`,
      urlPath: `/${params.orgUsername}/${params.projectId}/${params.instanceId}/service/${params.serviceId}/overview`,
    },
  ];

  const handleRefresh = () => {
    dispatchRtk(getComputeInstanceDetailsThunk(params?.instanceId || '')).then(
      () =>
        dispatchRtk(
          getDeploymentDetailsThunk(
            (selectedInstance as IInstance)?.activeDeployment ||
              (selectedInstance as IInstance).deployments[
                selectedInstance.deployments.length - 1
              ]
          )
        )
    );
  };

  const isRefreshLoading =
    activeDeploymentLoading ||
    selectedInstanceLoading ||
    selectedOrganisationLoading;

  const breadcrumbActions = [
    {
      id: 0,
      action: (
        <div>
          <Refresh
            showText
            handleClick={() => {
              handleRefresh();
            }}
            loading={isRefreshLoading}
            refreshType="default"
            time=""
            customText="Refresh"
          />
        </div>
      ),
    },
  ];

  const defaultNavItems = [
    {
      id: '1',
      // eslint-disable-next-line max-len
      link: `/${params.orgUsername}/${params.projectId}/${params.instanceId}/service/${params.serviceId}/overview`,
      label: 'Service Overview',
      onClick: () =>
        navigate(
          `/${params.orgUsername}/${params.projectId}/${params.instanceId}/service/${params.serviceId}/overview`
        ),
    },
    ...(isDeployed
      ? [
          {
            id: '2',
            label: 'Shell Command',
            link: `/${params.orgUsername}/${params.projectId}/${params.instanceId}/service/${params.serviceId}/shell`,
            onClick: () =>
              navigate(
                `/${params.orgUsername}/${params.projectId}/${params.instanceId}/service/${params.serviceId}/shell`
              ),
          },
        ]
      : []),
  ];

  const navItems =
    isScaled && isDeployed
      ? [
          ...defaultNavItems,
          {
            id: '3',
            label: 'Metrics',
            // eslint-disable-next-line max-len
            link: `/${params.orgUsername}/${params.projectId}/${params.instanceId}/service/${params.serviceId}/metrics`,
            onClick: () =>
              navigate(
                // eslint-disable-next-line max-len
                `/${params.orgUsername}/${params.projectId}/${params.instanceId}/service/${params.serviceId}/metrics`
              ),
          },
        ]
      : defaultNavItems;

  const tabDashboardRoutes = [
    {
      id: 0,
      label: 'Dashboard',
      url: `/${params.orgUsername}/dashboard`,
      slug: 'projects',
    },
    {
      id: 1,
      label: 'Billing',
      url: `/${params.orgUsername}/billing`,
      slug: 'billing',
    },
  ];

  const handleTabClick = (id: number) => {
    navigate(tabDashboardRoutes[id].url);
  };

  useEffect(() => {
    if (
      activeDeployment &&
      isDeployed &&
      (selectedInstance as IInstance).computeType !==
        InstanceCardScalingType.SPOT
    ) {
      dispatchRtk(
        getInstanceMetricsChartThunk({
          serviceName: params?.serviceId || '',
          instanceId: selectedInstance?._id,
          monitoringPointTime: '5m',
          monitoringTime: 10,
          monitoringType: 'average',
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchRtk, params.serviceId, isDeployed, activeDeployment]);

  useEffect(() => {
    if (!unitPrices?.length) {
      dispatchRtk(getInstanceUnitPricesThunk());
    }
    if (!availableInventory?.length) {
      dispatchRtk(getAvailableInventoryThunk());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchRtk]);

  return (
    <>
      <div
        className={`dark:border-dark-base-border bg-base-fg dark:bg-dark-base-fg
        ${GlobalStyles.inner__tabs} w-full z-50 mx-auto flex items-center justify-center`}
      >
        <Tabs
          tabList={tabDashboardRoutes}
          tabListPosition="left"
          selectedTab={0}
          tabListClassname={`pt-4 pb-2 max-w-[1400px] mx-auto w-[95%]`}
          onClick={(id: number) => handleTabClick(id)}
        />
      </div>
      <div className="mx-auto" role="article">
        <div
          className="bg-base-bg border-base-border 
        dark:bg-dark-base-bg dark:border-dark-base-border mt-5"
        >
          {selectedInstance && (
            <div
              className={`${GlobalStyles.screen__width} !min-h-[0px]
             !p-0 mx-auto !mb-0`}
            >
              <BreadcrumbsBar
                links={breadcrumbItems}
                activeLink={window.location.pathname
                  .split('/')
                  .slice(0, 7)
                  .join('/')}
                type="default"
                actions={breadcrumbActions}
              />
            </div>
          )}
        </div>
        <div
          className={`${
            !isMonitoringRoute &&
            `${GlobalStyles.screen__width} !p-0 ${showHealthCheck && ''}`
          }`}
        >
          <div className="flex flex-col mt-5 gap-y-3">
            <div className="grid grid-cols-12 gap-x-5">
              <div className="col-span-3">
                <InnerNavigation
                  navItems={navItems}
                  activeItem={
                    navItems.find((item) => item.link === location.pathname)
                      ?.label || ''
                  }
                />
              </div>
              <div className="col-span-9">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceMainView;
