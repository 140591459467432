/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ICreditCard,
  IOrganization,
  IOverdue,
  IWallet,
  PaymentMethod,
} from '../combined-state.interface';
import { mapNetworkToWallet, mapTokenToWallet } from '../web3/web3.utils';
import {
  IChangeUserRolePayloadDto,
  IChangeUserRoleResponseDto,
  ICreateNewOrganisationResponse,
  ICurrentApp,
  IOrganisation,
  IOrganisationBillingDetails,
  IOrganisationBillingDetailsResponsePayloadDto,
  IOrganisationSettingsPayloadDto,
  IOrganisationState,
  IOrgWallet,
  IRegistryDetails,
  ISpheronCredits,
  IWalletDetailsCrypto,
} from './organisation.interfaces';
// eslint-disable-next-line import/no-cycle
import {
  changeMemberRoleThunk,
  createOrganisationThunk,
  deleteOrganisationThunk,
  fetchRegistryDetailsThunk,
  getBillingDetailsThunk,
  getOrganisationMembersThunk,
  getSpheronCreditsThunk,
  getSelectedOrganisationByNameThunk,
  getSelectedOrganisationThunk,
  getSelectedOrgWalletThunk,
  getUserRoleThunk,
  publicAccessOrgThunk,
  removeInvitedMemberThunk,
  removeOrganisationMemberThunk,
  sendMemberInviteThunk,
  updateBillingDetailsThunk,
  updateInvitationThunk,
  updateOrganisationThunk,
  createMasterOrganisationThunk,
  updateMasterOrganisationThunk,
  fetchOrganisationNotificationConfigThunk,
  updateOrganisationNotificationConfigThunk,
} from './organisation.thunks';
import { mapRoleToUsers, mapRoleToUsersCommunity } from './organisation.utils';
// eslint-disable-next-line import/no-cycle
import { getUserDetailsThunk } from '../user/user.thunks';
import { IOrganisationWallet } from '../web3/web3.interfaces';
import { createUsername } from '../root-utils';

export const organisationInitialState: IOrganisation = {
  overduePaymentLoading: false,
  overdueLoading: true,
  selectedOrganisationLoading: true,
  deleteOrganisationLoading: false,
  selectedOrganisation: {
    ...({} as IOrganization),
    overdue: {
      ...({} as IOverdue),
      overdue: false,
    },
  } as IOrganization,
  selectedMasterOrganisation: null,
  userRoleLoading: false,
  removingInvitedUserFromOrganisation: false,
  removedInvitedUserFromOrganisation: false,
  removingUserFromOrganisation: false,
  creatingOrganisation: false,
  creatingMasterOrganisation: false,
  createMasterOrganisationFailed: false,
  removedUserFromOrganisation: false,
  changingMemberRole: false,
  changedMemberRole: false,
  inviteMemberLoading: false,
  selectedOrgWalletConfig: {
    _id: '',
    address: '',
    networkDetails: null,
    tokenDetails: null,
  },
  inviteCallbackLoading: false,
  inviteCallbackStatus: '',
  removeLoginConnectionLoading: false,
  updateOrganizationLoading: false,
  updateMasterOrganizationLoading: false,
  registryDetailsLoading: true,
  registryDetailList: [],
  getMembersLoading: false,
  primaryPaymentMethodLoading: false,
  primaryPaymentMethodId: null,
  tempPaymentMethodId: null,
  selectedOrgCreditCardConfig: null,
  selectedOrgSpheronWalletConfig: null,
  addingPaymentMethod: false,
  isPublicAccessible: false,
  billingDetails: null,
  billingDetailsLoading: true,
  billingDetailsFetched: false,
  communityOrgNotFound: false,
  spheronWallet: null,
  spheronCredits: null,
  spheronCreditsLoading: false,
  preferredAppType: ICurrentApp.APP,
  orgNotificationConfigCategory: {},
  orgNotificationConfigCategoryLoading: false,
  updateOrgNotificationConfigCategoryLoading: false,
};

export const initialState: IOrganisationState = {
  organisationsLoading: true,
  currentApp: ICurrentApp.APP,
  webappOrganisations: [],
  computeOrganisations: [],
  storageOrganisations: [],
  ...organisationInitialState,
};

const organisationSlice = createSlice({
  name: 'organisation',
  initialState,
  reducers: {
    organisationsLoaded(state, action: PayloadAction<any[]>) {
      state.organisationsLoading = false;
      const computeOrgs: IOrganization[] = [];
      const siteOrgs: IOrganization[] = [];
      const storageOrgs: IOrganization[] = [];
      action.payload.forEach((org) => {
        computeOrgs.push(org.compute);
        siteOrgs.push(org.site);
        storageOrgs.push(org.storage);
      });
      state.computeOrganisations = computeOrgs;
      state.webappOrganisations = siteOrgs;
      state.storageOrganisations = storageOrgs;
    },

    disconnectLoginConnectionLoading(state, action: PayloadAction<boolean>) {
      state.removeLoginConnectionLoading = action.payload;
    },

    setOrganisationToDefault(state) {
      // eslint-disable-next-line no-unused-vars, no-param-reassign
      state = initialState;
    },

    setCurrentAppType(state, action: PayloadAction<ICurrentApp>) {
      state.currentApp = action.payload;
    },

    updateWallet(state, action: PayloadAction<IWallet>) {
      state.selectedOrgWalletConfig = {
        _id: action.payload._id,
        address: action.payload.address || '',
        networkDetails: mapNetworkToWallet(
          action.payload.networkDetails.chainId || -1
        ),
        tokenDetails: mapTokenToWallet(
          action.payload.networkDetails.name,
          action.payload.tokenDetails.name
        ),
      };
    },

    resetWallet(state) {
      state.selectedOrgCreditCardConfig = null;
      state.primaryPaymentMethodId = null;
      state.selectedOrgWalletConfig = {
        _id: '',
        address: '',
        networkDetails: null,
        tokenDetails: null,
      };
    },

    setInviteLoading(state, action: PayloadAction<boolean>) {
      state.inviteCallbackLoading = action.payload;
    },

    setDisconnectLoginConnectionLoading(state, action: PayloadAction<boolean>) {
      state.removeLoginConnectionLoading = action.payload;
    },

    setSelectedOrg(state, action: PayloadAction<any>) {
      state.selectedOrganisation = action.payload as IOrganization;
      state.selectedOrganisationLoading = false;
    },

    setPrimaryPaymentMethodId(state, action: PayloadAction<string | null>) {
      state.primaryPaymentMethodId = action.payload;
      state.primaryPaymentMethodLoading = false;
    },

    setTempPaymentMethodId(state, action: PayloadAction<string | null>) {
      state.tempPaymentMethodId = action.payload;
    },

    updateDefaultPaymentMethod: (state, action: PayloadAction<IOrgWallet>) => {
      switch (action.payload?.paymentMethod) {
        case PaymentMethod.CREDITCARD:
          state.primaryPaymentMethodId = action.payload._id;
          state.selectedOrgCreditCardConfig =
            action.payload as unknown as ICreditCard;
          state.selectedOrgWalletConfig = {
            _id: '',
            address: '',
            networkDetails: null,
            tokenDetails: null,
          };
          state.selectedOrgSpheronWalletConfig = null;
          break;
        case PaymentMethod.CRYPTO:
          state.primaryPaymentMethodId = action.payload._id;
          state.selectedOrgCreditCardConfig = null;
          state.selectedOrgSpheronWalletConfig = null;
          state.selectedOrgWalletConfig = {
            _id: action.payload._id,
            address: (action.payload.details as IWalletDetailsCrypto).address,
            networkDetails: mapNetworkToWallet(
              parseInt(
                String(
                  (action.payload.details as IWalletDetailsCrypto)
                    .networkDetails.networkId ||
                    (action.payload.details as IWalletDetailsCrypto)
                      .networkDetails.chainId
                ),
                10
              ) || -1
            ),
            tokenDetails: mapTokenToWallet(
              (action.payload?.details as IWalletDetailsCrypto)?.networkDetails
                ?.chainName ||
                (action.payload?.details as IWalletDetailsCrypto)
                  ?.networkDetails?.name ||
                '',
              (action.payload?.details as IWalletDetailsCrypto)?.tokenDetails
                ?.symbol ||
                (action.payload?.details as IWalletDetailsCrypto)?.tokenDetails
                  ?.name ||
                ''
            ),
          };
          break;
        case PaymentMethod.SPHERONWALLET:
          state.primaryPaymentMethodId = action.payload._id;
          state.selectedOrgSpheronWalletConfig = state.spheronWallet;
          state.selectedOrgCreditCardConfig = null;
          state.selectedOrgWalletConfig = {
            _id: '',
            address: '',
            networkDetails: null,
            tokenDetails: null,
          };
          break;
        default:
          state.primaryPaymentMethodId = null;
          state.selectedOrgCreditCardConfig = null;
          state.selectedOrgWalletConfig = {
            _id: '',
            address: '',
            networkDetails: null,
            tokenDetails: null,
          };
      }
    },

    toggleAddingPaymentMethod: (state, action: PayloadAction<boolean>) => {
      state.addingPaymentMethod = action.payload;
    },

    setPublicAccessibleOrg: (state, action: PayloadAction<boolean>) => {
      state.isPublicAccessible = action.payload;
    },

    setUserRoleCommunity: (state) => {
      state.userRoleLoading = false;
      state.selectedOrganisation = {
        ...state.selectedOrganisation,
        users: mapRoleToUsersCommunity(state.selectedOrganisation?.users || []),
      };
    },

    toggleBillingDetailsLoading: (state, action: PayloadAction<boolean>) => {
      state.billingDetailsLoading = action.payload;
    },

    setBillingDetails: (
      state,
      action: PayloadAction<IOrganisationBillingDetails | null>
    ) => {
      state.billingDetails = action.payload;
    },
    setCommunityOrgNotFound: (state, action: PayloadAction<boolean>) => {
      state.communityOrgNotFound = action.payload;
    },
    addSpheronWallet: (state, action: PayloadAction<IOrgWallet>) => {
      state.spheronWallet = action.payload;
      state.selectedOrgSpheronWalletConfig = action.payload;
    },
    updateSpheronWalletPrimaryPayment: (
      state,
      action: PayloadAction<{ id: string }>
    ) => {
      state.spheronWallet =
        state.spheronWallet?._id === action.payload.id
          ? { ...state.spheronWallet, primary: true }
          : state.spheronWallet;
      state.selectedOrgSpheronWalletConfig =
        state.selectedOrgSpheronWalletConfig?._id === action.payload.id
          ? { ...state.selectedOrgSpheronWalletConfig, primary: true }
          : state.selectedOrgSpheronWalletConfig;
    },

    resetSpheronCredits: (state) => {
      state.spheronCredits = null;
    },

    toggleMasterOrgLoading: (state, action: PayloadAction<boolean>) => {
      state.creatingMasterOrganisation = action.payload;
    },

    setSelectedMasterOrg: (state, action: PayloadAction<IOrganization>) => {
      state.selectedMasterOrganisation = action.payload as any;
      localStorage.setItem('selected-master-org', action.payload._id);
    },
    setPreferredAppType: (state, action: PayloadAction<ICurrentApp>) => {
      state.preferredAppType = action.payload;
    },
    setOrgNotificationConfigCategoryLoading: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.orgNotificationConfigCategoryLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSelectedOrganisationThunk.pending, (state) => {
      state.selectedOrganisationLoading = true;
      state.tempPaymentMethodId = null;
    });

    builder.addCase(
      getSelectedOrganisationThunk.fulfilled,
      (state, action: PayloadAction<Partial<IOrganization>>) => {
        state.selectedOrganisation = action.payload as IOrganization;
        state.selectedOrganisationLoading = false;
        state.billingDetailsFetched = false;
      }
    );

    builder.addCase(getSelectedOrganisationThunk.rejected, (state) => {
      state.selectedOrganisation = {} as IOrganization;
      state.selectedOrganisationLoading = false;
    });

    builder.addCase(createOrganisationThunk.pending, (state) => {
      state.creatingOrganisation = true;
    });
    builder.addCase(
      createOrganisationThunk.fulfilled,
      (state, action: PayloadAction<ICreateNewOrganisationResponse>) => {
        state.creatingOrganisation = false;
        state.organisationsLoading = false;
        switch (action.payload.organization.appType) {
          case ICurrentApp.COMPUTE:
            state.computeOrganisations.push(action.payload.organization);
            break;
          case ICurrentApp.APP:
            state.webappOrganisations.push(action.payload.organization);
            break;
          case ICurrentApp.STORAGE:
            state.storageOrganisations.push(action.payload.organization);
            break;
          default:
            break;
        }
      }
    );
    builder.addCase(createOrganisationThunk.rejected, (state) => {
      state.creatingOrganisation = false;
    });

    builder.addCase(createMasterOrganisationThunk.pending, (state) => {
      state.creatingMasterOrganisation = true;
      state.createMasterOrganisationFailed = false;
    });
    builder.addCase(createMasterOrganisationThunk.fulfilled, (state) => {
      state.organisationsLoading = false;
      state.creatingMasterOrganisation = false;
    });
    builder.addCase(createMasterOrganisationThunk.rejected, (state) => {
      state.creatingMasterOrganisation = false;
      state.createMasterOrganisationFailed = true;
    });

    builder.addCase(updateOrganisationThunk.pending, (state) => {
      state.updateOrganizationLoading = true;
    });

    builder.addCase(
      updateOrganisationThunk.fulfilled,
      (
        state,
        action: PayloadAction<{
          id: string;
          data: IOrganisationSettingsPayloadDto;
        }>
      ) => {
        state.updateOrganizationLoading = false;
        state.selectedOrganisationLoading = false;
        state.selectedOrganisation = {
          ...state.selectedOrganisation!,
          profile: {
            username: action.payload.data.username,
            name: action.payload.data.name,
            image: action.payload.data.avatar || '',
          },
        };
      }
    );

    builder.addCase(updateMasterOrganisationThunk.rejected, (state) => {
      state.updateMasterOrganizationLoading = false;
    });

    builder.addCase(updateMasterOrganisationThunk.pending, (state) => {
      state.updateMasterOrganizationLoading = true;
    });

    builder.addCase(
      updateMasterOrganisationThunk.fulfilled,
      (state, action) => {
        state.updateMasterOrganizationLoading = false;
        state.selectedMasterOrganisation = {
          ...state.selectedMasterOrganisation,
          preferedAppType: action.payload.data.preferedAppType,
          profile: {
            ...state.selectedMasterOrganisation?.profile,
            name: action.payload.data.name || '',
            username: createUsername(String(action.payload.data.name)),
          },
        };
      }
    );

    builder.addCase(updateOrganisationThunk.rejected, (state) => {
      state.updateOrganizationLoading = false;
    });

    builder.addCase(sendMemberInviteThunk.pending, (state) => {
      state.inviteMemberLoading = true;
    });

    builder.addCase(sendMemberInviteThunk.fulfilled, (state) => {
      state.inviteMemberLoading = false;
    });

    builder.addCase(sendMemberInviteThunk.rejected, (state) => {
      state.inviteMemberLoading = false;
    });

    builder.addCase(removeInvitedMemberThunk.pending, (state) => {
      state.removedInvitedUserFromOrganisation = true;
      state.selectedOrganisationLoading = false;
    });

    builder.addCase(removeInvitedMemberThunk.fulfilled, (state) => {
      state.removingInvitedUserFromOrganisation = false;
      state.removedUserFromOrganisation = true;
      state.selectedOrganisationLoading = false;
    });

    builder.addCase(removeInvitedMemberThunk.rejected, (state) => {
      state.removingInvitedUserFromOrganisation = false;
      state.removedInvitedUserFromOrganisation = false;
      state.selectedOrganisationLoading = false;
    });

    builder.addCase(removeOrganisationMemberThunk.pending, (state) => {
      state.removingUserFromOrganisation = true;
      state.selectedOrganisationLoading = false;
    });

    builder.addCase(getUserRoleThunk.pending, (state) => {
      state.userRoleLoading = true;
    });

    builder.addCase(
      getUserRoleThunk.fulfilled,
      (state, action: PayloadAction<IChangeUserRoleResponseDto[]>) => {
        state.userRoleLoading = false;
        state.selectedOrganisation = {
          ...state.selectedOrganisation,
          users: mapRoleToUsers(
            state.selectedOrganisation?.users || [],
            action.payload
          ),
        };
      }
    );

    builder.addCase(getUserRoleThunk.rejected, (state) => {
      state.userRoleLoading = false;
    });

    builder.addCase(changeMemberRoleThunk.pending, (state) => {
      state.changingMemberRole = true;
    });

    builder.addCase(
      changeMemberRoleThunk.fulfilled,
      (state, action: PayloadAction<IChangeUserRolePayloadDto>) => {
        const updatedUser = state.selectedOrganisation.users.find(
          (user) => user._id === action.payload.userId
        )!;

        state.changingMemberRole = false;

        state.selectedOrganisation = {
          ...state.selectedOrganisation,
          users: [
            ...state.selectedOrganisation.users.filter(
              (user) => user._id !== action.payload.userId
            ),
            {
              ...updatedUser,
              platformProfile: {
                ...updatedUser.platformProfile,
                role: action.payload.roleGroupName,
              },
            },
          ],
        };
      }
    );

    builder.addCase(changeMemberRoleThunk.rejected, (state) => {
      state.changingMemberRole = false;
    });

    builder.addCase(updateInvitationThunk.pending, (state) => {
      state.inviteCallbackLoading = true;
    });

    builder.addCase(
      updateInvitationThunk.fulfilled,
      (state, action: PayloadAction<string>) => {
        state.inviteCallbackLoading = false;
        state.inviteCallbackStatus = action.payload;
      }
    );

    builder.addCase(updateInvitationThunk.rejected, (state) => {
      state.inviteCallbackLoading = false;
    });

    builder.addCase(
      getSelectedOrgWalletThunk.fulfilled,
      (state, action: PayloadAction<any>) => {
        const primaryPaymentMethod = action.payload.wallets.find(
          (method: IOrganisationWallet | ICreditCard | IOrgWallet) =>
            method.primary
        );
        switch (primaryPaymentMethod?.paymentMethod) {
          case PaymentMethod.CREDITCARD:
            // eslint-disable-next-line no-unused-expressions, no-sequences
            state.selectedOrgCreditCardConfig = primaryPaymentMethod;
            state.primaryPaymentMethodId = primaryPaymentMethod._id;
            state.selectedOrgWalletConfig = {
              _id: '',
              address: '',
              networkDetails: null,
              tokenDetails: null,
            };
            break;
          case PaymentMethod.CRYPTO:
            // eslint-disable-next-line no-unused-expressions, no-sequences
            state.primaryPaymentMethodId = primaryPaymentMethod._id;
            state.selectedOrgCreditCardConfig = null;
            state.selectedOrgWalletConfig = {
              _id: primaryPaymentMethod._id,
              address: primaryPaymentMethod.details.address,
              networkDetails: mapNetworkToWallet(
                parseInt(
                  primaryPaymentMethod.details.networkDetails.chainId,
                  10
                ) || -1
              ),
              tokenDetails: mapTokenToWallet(
                primaryPaymentMethod.details.networkDetails.name || '',
                primaryPaymentMethod.details.tokenDetails.name || ''
              ),
            };
            break;
          case PaymentMethod.SPHERONWALLET:
            state.primaryPaymentMethodId = primaryPaymentMethod._id;
            state.selectedOrgSpheronWalletConfig = state.spheronWallet;
            state.selectedOrgCreditCardConfig = null;
            state.selectedOrgWalletConfig = {
              _id: '',
              address: '',
              networkDetails: null,
              tokenDetails: null,
            };
            break;
          default:
            break;
        }
      }
    );

    builder.addCase(fetchRegistryDetailsThunk.pending, (state) => {
      state.registryDetailsLoading = true;
    });

    builder.addCase(
      fetchRegistryDetailsThunk.fulfilled,
      (state, action: PayloadAction<IRegistryDetails[]>) => {
        state.registryDetailsLoading = false;
        state.registryDetailList = action.payload;
      }
    );

    builder.addCase(fetchRegistryDetailsThunk.rejected, (state) => {
      state.registryDetailsLoading = false;
    });

    builder.addCase(getUserDetailsThunk.pending, (state) => {
      state.organisationsLoading = true;
    });

    builder.addCase(deleteOrganisationThunk.pending, (state) => {
      state.deleteOrganisationLoading = true;
    });

    builder.addCase(
      deleteOrganisationThunk.fulfilled,
      (
        state,
        action: PayloadAction<{
          organisationId: string;
          organisationType: ICurrentApp;
        }>
      ) => {
        state.deleteOrganisationLoading = false;
        state.overdueLoading = true;
        switch (action.payload.organisationType) {
          case ICurrentApp.COMPUTE:
            state.organisationsLoading = false;
            state.computeOrganisations = state.computeOrganisations.filter(
              (organisation) =>
                organisation._id !== action.payload.organisationId
            );
            break;
          case ICurrentApp.STORAGE:
            state.organisationsLoading = false;
            state.storageOrganisations = state.storageOrganisations.filter(
              (organisation) =>
                organisation._id !== action.payload.organisationId
            );
            break;
          case ICurrentApp.APP:
          default:
            state.organisationsLoading = false;
            state.webappOrganisations = state.webappOrganisations.filter(
              (organisation) =>
                organisation._id !== action.payload.organisationId
            );
        }
      }
    );

    builder.addCase(deleteOrganisationThunk.rejected, (state) => {
      state.deleteOrganisationLoading = false;
    });

    builder.addCase(publicAccessOrgThunk.pending, (state) => {
      state.deleteOrganisationLoading = true;
    });

    builder.addCase(
      publicAccessOrgThunk.fulfilled,
      (state, action: PayloadAction<{ publiclyAccessible: boolean }>) => {
        state.selectedOrganisation = {
          ...state.selectedOrganisation,
          publiclyAccessible: action.payload.publiclyAccessible,
        };
        state.selectedMasterOrganisation = {
          ...state.selectedMasterOrganisation,
          publiclyAccessible: action.payload.publiclyAccessible,
        };
        if (action.payload.publiclyAccessible) {
          sessionStorage.setItem(
            'isPublicAccessible',
            String(action.payload.publiclyAccessible)
          );
          sessionStorage.setItem('communityUserAccess', 'true');
        }
        state.deleteOrganisationLoading = false;
      }
    );

    builder.addCase(publicAccessOrgThunk.rejected, (state) => {
      state.deleteOrganisationLoading = false;
    });

    builder.addCase(getOrganisationMembersThunk.pending, (state) => {
      state.getMembersLoading = true;
    });

    builder.addCase(
      getOrganisationMembersThunk.fulfilled,
      (state, action: PayloadAction<Partial<IOrganization>>) => {
        state.selectedOrganisation = {
          ...state.selectedOrganisation,
          invitedMembers: action.payload.invitedMembers,
          users: action.payload.users,
        } as IOrganization;
        state.getMembersLoading = false;
      }
    );

    builder.addCase(getOrganisationMembersThunk.rejected, (state) => {
      state.selectedOrganisation = {} as IOrganization;
      state.getMembersLoading = false;
    });

    builder.addCase(getSelectedOrganisationByNameThunk.pending, (state) => {
      state.organisationsLoading = true;
    });
    builder.addCase(getSelectedOrganisationByNameThunk.fulfilled, (state) => {
      state.organisationsLoading = false;
      state.selectedOrganisationLoading = false;
    });
    builder.addCase(getSelectedOrganisationByNameThunk.rejected, (state) => {
      state.organisationsLoading = false;
      state.selectedOrganisationLoading = false;
    });

    builder.addCase(getBillingDetailsThunk.pending, (state) => {
      state.billingDetailsLoading = true;
    });

    builder.addCase(getBillingDetailsThunk.rejected, (state) => {
      state.billingDetailsLoading = false;
      state.billingDetails = null;
      state.billingDetailsFetched = true;
    });

    builder.addCase(
      getBillingDetailsThunk.fulfilled,
      (
        state,
        action: PayloadAction<IOrganisationBillingDetailsResponsePayloadDto>
      ) => {
        state.billingDetailsFetched = true;
        state.billingDetailsLoading = false;
        state.billingDetails = action.payload
          ? {
              companyName: action.payload.companyName,
              taxId: action.payload.taxId,
              invoiceEmailRecipient: action.payload.invoiceEmailRecipient,
              ...action.payload.billingAddress,
            }
          : null;
      }
    );

    builder.addCase(updateBillingDetailsThunk.pending, (state) => {
      state.updateOrganizationLoading = true;
    });

    builder.addCase(updateBillingDetailsThunk.rejected, (state) => {
      state.updateOrganizationLoading = false;
    });
    builder.addCase(updateBillingDetailsThunk.fulfilled, (state, action) => {
      state.billingDetails = {
        companyName: action.meta.arg.details.companyName,
        taxId: action.meta.arg.details.taxId,
        invoiceEmailRecipient: action.meta.arg.details.invoiceEmailRecipient,
        ...action.meta.arg.details.billingAddress,
      };
      state.updateOrganizationLoading = false;
    });

    builder.addCase(getSpheronCreditsThunk.pending, (state) => {
      state.spheronCreditsLoading = true;
    });
    builder.addCase(getSpheronCreditsThunk.fulfilled, (state, action) => {
      state.spheronCredits = (
        action.payload as { credits: ISpheronCredits[] }
      ).credits;
      state.spheronCreditsLoading = false;
    });
    builder.addCase(getSpheronCreditsThunk.rejected, (state) => {
      state.spheronCreditsLoading = false;
      state.spheronCredits = null;
    });

    builder.addCase(
      fetchOrganisationNotificationConfigThunk.pending,
      (state) => {
        state.orgNotificationConfigCategoryLoading = true;
      }
    );
    builder.addCase(
      fetchOrganisationNotificationConfigThunk.fulfilled,
      (state, action: PayloadAction<{ notificationCategory: string }[]>) => {
        const tempNotificationConfigCategory: { [key: string]: boolean } = {};
        action.payload.forEach((notification) => {
          tempNotificationConfigCategory[notification.notificationCategory] =
            true;
        });
        state.orgNotificationConfigCategory = tempNotificationConfigCategory;
        state.orgNotificationConfigCategoryLoading = false;
      }
    );
    builder.addCase(
      fetchOrganisationNotificationConfigThunk.rejected,
      (state) => {
        state.orgNotificationConfigCategoryLoading = false;
        state.orgNotificationConfigCategory = {};
      }
    );
    builder.addCase(
      updateOrganisationNotificationConfigThunk.pending,
      (state) => {
        state.updateOrgNotificationConfigCategoryLoading = true;
      }
    );
    builder.addCase(
      updateOrganisationNotificationConfigThunk.fulfilled,
      (state, action: PayloadAction<{ notificationCategory: string }[]>) => {
        state.updateOrgNotificationConfigCategoryLoading = false;

        const tempNotificationConfigCategory: { [key: string]: boolean } = {};
        action.payload.forEach((notification) => {
          tempNotificationConfigCategory[notification.notificationCategory] =
            true;
        });
        state.orgNotificationConfigCategory = tempNotificationConfigCategory;
      }
    );
    builder.addCase(
      updateOrganisationNotificationConfigThunk.rejected,
      (state) => {
        state.updateOrgNotificationConfigCategoryLoading = false;
      }
    );
  },
});

export const {
  organisationsLoaded: organisationsLoadedRtk,
  disconnectLoginConnectionLoading: disconnectLoginConnectionLoadingRtk,
  setOrganisationToDefault: setOrganisationToDefaultRtk,
  setCurrentAppType,
  updateWallet: updateWalletRtk,
  setInviteLoading: setInviteLoadingRtk,
  setDisconnectLoginConnectionLoading: setDisconnectLoginConnectionLoadingRtk,
  setSelectedOrg: setSelectedOrgRtk,
  resetWallet: resetWalletRtk,
  setPrimaryPaymentMethodId: setPrimaryPaymentMethodIdRtk,
  updateDefaultPaymentMethod: updateDefaultPaymentMethodRtk,
  setTempPaymentMethodId: setTempPaymentMethodIdRtk,
  toggleAddingPaymentMethod: toggleAddingPaymentMethodRtk,
  setPublicAccessibleOrg: setPublicAccessibleOrgRtk,
  setUserRoleCommunity: setUserRoleCommunityRtk,
  toggleBillingDetailsLoading: toggleBillingDetailsLoadingRtk,
  setBillingDetails: setBillingDetailsRtk,
  setCommunityOrgNotFound: setCommunityOrgNotFoundRtk,
  addSpheronWallet: addSpheronWalletRtk,
  updateSpheronWalletPrimaryPayment: updateSpheronWalletPrimaryPaymentRtk,
  resetSpheronCredits: resetSpheronCreditsRtk,
  toggleMasterOrgLoading,
  setSelectedMasterOrg,
  setPreferredAppType,
  setOrgNotificationConfigCategoryLoading,
} = organisationSlice.actions;

export const { reducer: organisationRTKReducer } = organisationSlice;
