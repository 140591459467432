import React from 'react';
import { RefreshIcon } from '../Icons';

interface IProps {
  loading1: boolean;
  loading2?: boolean;
  color?: string;
  backgroundColor?: string;
  classname?: string;
  // eslint-disable-next-line no-unused-vars
  handleClick: (e: React.ChangeEvent<unknown>) => void;
}

const SpinLoader = ({
  loading1,
  loading2,
  color,
  backgroundColor,
  classname,
  handleClick,
}: IProps) => {
  return (
    <button
      onClick={handleClick}
      type="button"
      disabled={loading1 || loading2}
      className={`${
        (loading1 || loading2) && 'cursor-not-allowed'
      } rounded-lg duration-200 p-1 cursor-pointer bg-transparent
      dark:hover:bg-dark-black-hover flex items-center justify-center ${backgroundColor}
      ${classname}`}
    >
      <div
        className={`${
          (loading1 || loading2) && 'cursor-not-allowed animate-spin'
        }`}
      >
        <RefreshIcon color={color} />
      </div>
    </button>
  );
};

SpinLoader.defaultProps = {
  loading2: false,
  color: '#838EAD',
  backgroundColor: 'dark:bg-dark-base-fg',
  classname: '',
};

export default SpinLoader;
