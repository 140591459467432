import {
  arbitrum,
  arbitrumGoerli,
  aurora,
  auroraTestnet,
  avalanche,
  avalancheFuji,
  baseGoerli,
  boba,
  bronos,
  bronosTestnet,
  bscTestnet,
  canto,
  celo,
  celoAlfajores,
  celoCannoli,
  cronos,
  crossbell,
  dfk,
  dogechain,
  evmos,
  evmosTestnet,
  fantom,
  fantomTestnet,
  filecoin,
  filecoinCalibration,
  filecoinHyperspace,
  flare,
  flareTestnet,
  foundry,
  iotex,
  iotexTestnet,
  goerli,
  gnosis,
  gnosisChiado,
  haqqMainnet,
  haqqTestedge2,
  hardhat,
  harmonyOne,
  klaytn,
  lineaTestnet,
  localhost,
  mainnet,
  metis,
  metisGoerli,
  moonbaseAlpha,
  moonbeam,
  moonriver,
  nexi,
  okc,
  optimism,
  optimismGoerli,
  polygon,
  polygonMumbai,
  polygonZkEvm,
  polygonZkEvmTestnet,
  pulsechain,
  pulsechainV4,
  scrollTestnet,
  sepolia,
  skaleBlockBrawlers,
  skaleCalypso,
  skaleCalypsoTestnet,
  skaleChaosTestnet,
  skaleCryptoBlades,
  skaleCryptoColosseum,
  skaleEuropa,
  skaleEuropaTestnet,
  skaleExorde,
  skaleHumanProtocol,
  skaleNebula,
  skaleNebulaTestnet,
  skaleRazor,
  skaleTitan,
  skaleTitanTestnet,
  songbird,
  songbirdTestnet,
  shardeumSphinx,
  syscoin,
  taraxa,
  taraxaTestnet,
  telos,
  telosTestnet,
  thunderTestnet,
  wanchain,
  wanchainTestnet,
  xdc,
  xdcTestnet,
  zhejiang,
  zkSync,
  zkSyncTestnet,
} from 'wagmi/chains';
import { configureChains } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';

export const { chains, publicClient } = configureChains(
  [
    arbitrum,
    arbitrumGoerli,
    aurora,
    auroraTestnet,
    avalanche,
    avalancheFuji,
    baseGoerli,
    boba,
    bronos,
    bronosTestnet,
    bscTestnet,
    canto,
    celo,
    celoAlfajores,
    celoCannoli,
    cronos,
    crossbell,
    dfk,
    dogechain,
    evmos,
    evmosTestnet,
    fantom,
    fantomTestnet,
    filecoin,
    filecoinCalibration,
    filecoinHyperspace,
    flare,
    flareTestnet,
    foundry,
    iotex,
    iotexTestnet,
    goerli,
    gnosis,
    gnosisChiado,
    haqqMainnet,
    haqqTestedge2,
    hardhat,
    harmonyOne,
    klaytn,
    lineaTestnet,
    localhost,
    mainnet,
    metis,
    metisGoerli,
    moonbaseAlpha,
    moonbeam,
    moonriver,
    nexi,
    okc,
    optimism,
    optimismGoerli,
    polygon,
    polygonMumbai,
    polygonZkEvm,
    polygonZkEvmTestnet,
    pulsechain,
    pulsechainV4,
    scrollTestnet,
    sepolia,
    skaleBlockBrawlers,
    skaleCalypso,
    skaleCalypsoTestnet,
    skaleChaosTestnet,
    skaleCryptoBlades,
    skaleCryptoColosseum,
    skaleEuropa,
    skaleEuropaTestnet,
    skaleExorde,
    skaleHumanProtocol,
    skaleNebula,
    skaleNebulaTestnet,
    skaleRazor,
    skaleTitan,
    skaleTitanTestnet,
    songbird,
    songbirdTestnet,
    shardeumSphinx,
    syscoin,
    taraxa,
    taraxaTestnet,
    telos,
    telosTestnet,
    thunderTestnet,
    wanchain,
    wanchainTestnet,
    xdc,
    xdcTestnet,
    zhejiang,
    zkSync,
    zkSyncTestnet,
  ],
  [publicProvider()]
);
