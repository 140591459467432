/* eslint-disable import/no-cycle */
import * as Sentry from '@sentry/react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  IInstanceDeployment,
  InstanceLogs,
  IClusterTemplateConfig,
  IFetchInstanceServiceDto,
  ICreateInstanceDomainPayloadDto,
  IInstanceDomainIdPayloadDto,
  IDeleteInstanceDomainPayloadDto,
  IFetchInstanceServiceResponse,
  IGetInstanceOrderResponse,
  IRequestForUpdatingInstance,
  IAutoscalingRequestPayload,
  IInstanceMetricsCharResponsePayloadDto,
  IInstanceMetricsChartPayloadDto,
  IRequestForRestartInstance,
  IGetRestartResponsePayloadDto,
  InstanceEvent,
} from './instance.interfaces';
import {
  createClusterInstanceService,
  getInstanceActivityService,
  updateInstanceService,
  closeInstanceService,
  deleteInstanceService,
  deployTemplateService,
  getAllSuccessfullyDeployedInstancesService,
  fetchLatestLogsService,
  updateHealthCheckService,
  updateInstanceDomainService,
  createInstanceDomainService,
  verifyInstanceDomainService,
  deleteInstanceDomainService,
  getInstanceDomainsService,
  shellCommandService,
  updateAutoScalingRulesService,
  getInstanceMetricsChartService,
  getDeploymentDetailsService,
  getDeploymentLogsService,
  getComputeInstanceDetailsService,
  closeMultipleInstancesService,
  restartInstanceService,
  deleteMultipleInstanceService,
  editInstanceNameService,
  fetchCdnRecords,
} from './instance.services';
import {
  setDeleteDomainLoadingRtk,
  setInstanceEventsServiceLoadingRtk,
  setInstanceDeploymentLogsRtk,
  setLoadMoreLogsLoadingRtk,
  setSelectedInstanceLogsLoadingRtk,
  setUpdateDomainLoadingRtk,
  setVerifyDomainLoadingRtk,
  resetInstanceDeployLogsRtk,
  toggleShouldFetchLogs,
  setClosingInstanceIdRtk,
  toggleShouldFetchInstanceDetails,
  toggleShouldFetchDeploymentDetails,
} from './instance.slice';
import { RootState } from '../store';
import { addNotificationRtk } from '../notification/notification.slice';
import { NotificationType } from '../notification/notification.interfaces';
import { toggleModalShowRtk } from '../modal/modal.slice';
import { IResponseError } from '../combined-reducer.interface';
import { capitalizeFirstLetter } from '../root-utils';
import { setSelectedClusterRtk } from '../cluster/cluster.slice';
import {
  setSelectedComputeProjectInstancesClose,
  setSelectedComputeProjectInstancesDelete,
  setSelectedComputeProjectRtk,
  setSelectedProjectName,
  setUpdatedProjectInstanceName,
} from '../project/project.slice';
import { IInstance } from '../project/project.interfaces';
import {
  setServiceLogs,
  toggleSelectedServiceLogsLoading,
} from '../service/service.slice';

export const getInstanceMetricsChartThunk = createAsyncThunk(
  'instance/getInstanceMetricsChart',
  async (
    payload: IInstanceMetricsChartPayloadDto,
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const monitoringTimeInMinutes = payload.monitoringTime;
      const currentTimeUnixTimestamp = Math.floor(new Date().valueOf() / 1000);
      const startTimeInMiliSeconds =
        currentTimeUnixTimestamp - monitoringTimeInMinutes * 60;
      const startTimeUnixTimestamp = Math.floor(startTimeInMiliSeconds);
      const response = await getInstanceMetricsChartService(
        payload.serviceName,
        payload.instanceId,
        startTimeUnixTimestamp,
        currentTimeUnixTimestamp,
        payload.monitoringPointTime,
        payload.monitoringType,
        'all'
      );
      if ((response as IResponseError).error) {
        Sentry.captureMessage((response as IResponseError).message);
        return rejectWithValue([]);
      }
      return fulfillWithValue(
        (response as IInstanceMetricsCharResponsePayloadDto).metrics
      );
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue([]);
    }
  }
);
export const getDeploymentLogsThunk = createAsyncThunk(
  'instance/getDeploymentLogs',
  async (
    payload: {
      deploymentId: string;
      type: InstanceLogs;
      search?: string;
      serviceName?: string;
    },
    { rejectWithValue, fulfillWithValue, dispatch, getState }
  ) => {
    try {
      dispatch(
        setSelectedInstanceLogsLoadingRtk({
          loading: true,
          type: payload.type,
        })
      );

      const { computeService } = getState() as RootState;

      const response = await getDeploymentLogsService(
        payload.deploymentId,
        payload.type,
        1,
        payload.search || '',
        payload?.serviceName || ''
      );
      if (response.success) {
        if (
          payload?.serviceName &&
          computeService?.selectedService &&
          computeService?.selectedService?.name === payload?.serviceName
        ) {
          dispatch(toggleSelectedServiceLogsLoading(false));
          dispatch(
            setSelectedInstanceLogsLoadingRtk({
              loading: false,
              type: payload.type,
            })
          );
          dispatch(
            setServiceLogs({
              logs: response.deployment.logs,
              logsLength: response.deployment.logsLength,
            })
          );
        } else {
          dispatch(
            setSelectedInstanceLogsLoadingRtk({
              loading: false,
              type: payload.type,
            })
          );
          dispatch(
            setInstanceDeploymentLogsRtk({
              deployment: response.deployment,
              type: payload.type,
            })
          );
        }
        return fulfillWithValue({});
      }
      dispatch(
        setSelectedInstanceLogsLoadingRtk({
          loading: false,
          type: payload.type,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const loadMoreDeploymentLogsThunk = createAsyncThunk(
  'instance/loadMoreDeploymentLogsThunk',
  async (
    payload: {
      deploymentId: string;
      type: InstanceLogs;
      search: string;
    },
    { rejectWithValue, fulfillWithValue, dispatch, getState }
  ) => {
    try {
      const { instance } = getState() as RootState;
      const computeEventCurrentPage =
        instance.selectedInstanceComputeEventsPagination.currentPage;
      const computeCurrentPage =
        instance.selectedInstanceComputeLogsPagination.currentPage;

      dispatch(
        setLoadMoreLogsLoadingRtk({ loading: true, type: payload.type })
      );
      const response = await getDeploymentLogsService(
        payload.deploymentId,
        payload.type,
        (payload.type === InstanceLogs.COMPUTE_EVENTS
          ? computeEventCurrentPage
          : computeCurrentPage) + 1,
        payload.search
      );
      if (response.success) {
        dispatch(
          setLoadMoreLogsLoadingRtk({ loading: false, type: payload.type })
        );
        return fulfillWithValue({
          deployment: response.deployment,
          type: payload.type,
        });
      }
      dispatch(
        setLoadMoreLogsLoadingRtk({ loading: false, type: payload.type })
      );
      dispatch(
        addNotificationRtk({
          message: response.message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const startInstanceDeploymentThunk = createAsyncThunk(
  'instance/startInstanceDeployment',
  async (
    payload: IInstanceDeployment,
    { rejectWithValue, fulfillWithValue, dispatch, getState }
  ) => {
    try {
      const { cluster, organisation } = getState() as RootState;
      const orgUsername = organisation.selectedOrganisation.profile.username;
      const organizationId = organisation.selectedOrganisation._id;
      const clusterUrl = cluster.selectedCluster?.repository;
      const clusterProvider = cluster.selectedCluster?.provider;
      const clusterName = cluster.selectedCluster?.name;
      const { scalable } = payload.clusterConfig;

      const instanceConfig = {
        ...payload.instanceDeployment,
        organizationId,
        clusterUrl,
        clusterProvider,
        clusterName,
        scalable,
        projectId: payload.projectId,
        configuration: payload.clusterConfig,
      };

      const response = await createClusterInstanceService(instanceConfig);
      if (!response.error) {
        dispatch(
          setSelectedClusterRtk({
            name: '',
            repository: '',
            provider: '',
            tags: '',
          })
        );
        (window as any)?.rrNavigate(
          // eslint-disable-next-line max-len
          `/${orgUsername}/${response.computeProjectId}/${response.computeInstanceId}/logs/deployment-logs`
        );
        return fulfillWithValue(null);
      }
      dispatch(
        addNotificationRtk({
          message: response.message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const startTemplateInstanceDeploymentThunk = createAsyncThunk(
  'instance/startTemplateInstanceDeployment',
  async (
    payload: {
      clusterTemplateConfig: IClusterTemplateConfig;
      autoscalingRules?: IAutoscalingRequestPayload | null;
    },
    { rejectWithValue, fulfillWithValue, dispatch, getState }
  ) => {
    try {
      const { organisation } = getState() as RootState;
      const orgUsername = organisation.selectedOrganisation.profile.username;
      const response = await deployTemplateService(
        payload.clusterTemplateConfig,
        payload?.autoscalingRules || null
      );
      if (!response.error) {
        dispatch(
          setSelectedClusterRtk({
            name: '',
            repository: '',
            provider: '',
            tags: '',
          })
        );
        (window as any)?.rrNavigate(
          // eslint-disable-next-line max-len
          `/${orgUsername}/${response.computeProjectId}/${response.computeInstanceId}/logs/deployment-logs`
        );
        return fulfillWithValue(null);
      }
      dispatch(
        addNotificationRtk({
          message: response.message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const updateInstanceThunk = createAsyncThunk(
  'instance/updateInstance',
  async (
    payload: IRequestForUpdatingInstance,
    { rejectWithValue, fulfillWithValue, dispatch, getState }
  ) => {
    try {
      const { organisation, instance } = getState() as RootState;
      const orgUsername = organisation.selectedOrganisation.profile.username;
      const response = await updateInstanceService(payload);
      if (!response.error) {
        dispatch(
          toggleModalShowRtk({
            modalShow: false,
          })
        );
        if (response.success) {
          dispatch(
            addNotificationRtk({
              message: response.message,
              timestamp: Date.now(),
              type: NotificationType.Success,
            })
          );
          (window as any)?.rrNavigate(
            `/${orgUsername}/${
              (instance.selectedInstance as IInstance).computeProject._id
            }/${instance.selectedInstance._id}/logs/deployment-logs`
          );
          dispatch(resetInstanceDeployLogsRtk());
          dispatch(
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            getComputeInstanceDetailsThunk(instance.selectedInstance._id)
          ).then(() => {
            dispatch(
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              getDeploymentDetailsThunk(response.computeDeploymentId)
            ).then(() => {
              dispatch(
                getDeploymentLogsThunk({
                  deploymentId: response.computeDeploymentId || '',
                  type: InstanceLogs.COMPUTE_LOGS,
                })
              );

              dispatch(
                getDeploymentLogsThunk({
                  deploymentId: response.computeDeploymentId || '',
                  type: InstanceLogs.LOGS,
                })
              );

              dispatch(
                getDeploymentLogsThunk({
                  deploymentId: response.computeDeploymentId || '',
                  type: InstanceLogs.COMPUTE_EVENTS,
                })
              );
            });
          });
        } else {
          dispatch(
            addNotificationRtk({
              message: response.message,
              timestamp: Date.now(),
              type: NotificationType.Error,
            })
          );
        }
        return fulfillWithValue(null);
      }
      dispatch(
        addNotificationRtk({
          message: response.message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const restartInstanceThunk = createAsyncThunk(
  'instance/restartInstance',
  async (
    payload: IRequestForRestartInstance,
    { rejectWithValue, fulfillWithValue, dispatch, getState }
  ) => {
    try {
      const { organisation } = getState() as RootState;
      const orgUsername = organisation.selectedOrganisation.profile.username;
      dispatch(toggleShouldFetchDeploymentDetails(true));
      dispatch(toggleShouldFetchInstanceDetails(true));
      const response = await restartInstanceService(payload);
      if (!(response as IResponseError).error) {
        dispatch(
          toggleModalShowRtk({
            modalShow: false,
          })
        );
        if (response.success) {
          dispatch(
            addNotificationRtk({
              message: response.message,
              timestamp: Date.now(),
              type: NotificationType.Success,
            })
          );
          const restartResponse = response as IGetRestartResponsePayloadDto;
          (window as any)?.rrNavigate(
            // eslint-disable-next-line max-len
            `/${orgUsername}/${restartResponse.computeProjectId}/${restartResponse.computeInstanceId}/logs/deployment-logs`
          );
          dispatch(resetInstanceDeployLogsRtk());
          dispatch(
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            getComputeInstanceDetailsThunk(restartResponse.computeInstanceId)
          ).then(() => {
            dispatch(
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              getDeploymentDetailsThunk(restartResponse.computeDeploymentId)
            ).then(() => {
              dispatch(
                getDeploymentLogsThunk({
                  deploymentId: restartResponse.computeDeploymentId || '',
                  type: InstanceLogs.COMPUTE_LOGS,
                })
              );

              dispatch(
                getDeploymentLogsThunk({
                  deploymentId: restartResponse.computeDeploymentId || '',
                  type: InstanceLogs.LOGS,
                })
              );

              dispatch(
                getDeploymentLogsThunk({
                  deploymentId: restartResponse.computeDeploymentId || '',
                  type: InstanceLogs.COMPUTE_EVENTS,
                })
              );
            });
          });
        } else {
          dispatch(
            addNotificationRtk({
              message: response.message,
              timestamp: Date.now(),
              type: NotificationType.Error,
            })
          );
        }
        return fulfillWithValue(null);
      }
      dispatch(
        addNotificationRtk({
          message: response.message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getInstanceActivityThunk = createAsyncThunk(
  'instance/getInstanceActivity',
  async (payload: string, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getInstanceActivityService(payload);
      if (response.success) {
        return fulfillWithValue(response);
      }
      Sentry.captureMessage(response.message);
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const closeInstanceThunk = createAsyncThunk(
  'instance/closeInstance',
  async (
    payload: string,
    { rejectWithValue, fulfillWithValue, dispatch, getState }
  ) => {
    try {
      const { organisation } = getState() as RootState;
      const organizationId = organisation.selectedOrganisation._id;
      const clusterInstanceId = payload;
      dispatch(setClosingInstanceIdRtk(clusterInstanceId));
      const response = await closeInstanceService(
        organizationId,
        clusterInstanceId || ''
      );
      if (response.success) {
        dispatch(
          addNotificationRtk({
            message: response.message,
            timestamp: Date.now(),
            type: NotificationType.Success,
          })
        );
        dispatch(
          toggleModalShowRtk({
            modalShow: false,
          })
        );
        dispatch(setSelectedComputeProjectInstancesClose([clusterInstanceId]));
        return fulfillWithValue(clusterInstanceId || '');
      }
      dispatch(
        toggleModalShowRtk({
          modalShow: false,
        })
      );
      dispatch(
        addNotificationRtk({
          message: response.message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );

      return rejectWithValue({});
    } catch (error) {
      dispatch(
        toggleModalShowRtk({
          modalShow: false,
        })
      );
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const deleteInstanceThunk = createAsyncThunk(
  'instance/deleteInstance',
  async (
    payload: string,
    { rejectWithValue, fulfillWithValue, dispatch, getState }
  ) => {
    try {
      const { computeProject, organisation } = getState() as RootState;
      const orgUsername = organisation.selectedOrganisation.profile.username;
      const projectId = computeProject.selectedProject?._id;
      const response = await deleteInstanceService(payload);
      if (response?.error) {
        dispatch(
          addNotificationRtk({
            message: response.message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        Sentry.captureMessage(response.message);
        return rejectWithValue({});
      }
      dispatch(
        toggleModalShowRtk({
          modalShow: false,
        })
      );
      dispatch(
        toggleModalShowRtk({
          modalShow: true,
          modalType: 'deletedResource',
          options: {
            resource: 'Instance',
          },
        })
      );
      if (!projectId) {
        (window as any)?.rrNavigate(`/${orgUsername}/dashboard`);
      } else {
        (window as any)?.rrNavigate(`/${orgUsername}/project/${projectId}`);
      }
      dispatch(
        addNotificationRtk({
          message: 'Instance successfully deleted',
          timestamp: Date.now(),
          type: NotificationType.Success,
        })
      );
      return fulfillWithValue(payload);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getSuccessfullyDeployedClusterInstancesThunk = createAsyncThunk(
  'instance/getSuccessfullyDeployedClusterInstances',
  async (payload: string, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getAllSuccessfullyDeployedInstancesService(
        payload
      );
      if (response.success) {
        return fulfillWithValue(response);
      }
      Sentry.captureMessage(response.message);
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const loadCdnRecordsThunk = createAsyncThunk(
  'instance/loadCdnRecords',
  async (payload: string, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await fetchCdnRecords(payload);
      if (!response.error) {
        return fulfillWithValue({
          projectId: payload,
          cdnRecord: response.records,
        });
      }
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const downloadLogsThunk = createAsyncThunk(
  'instance/downloadLogs',
  async (
    payload: {
      url: string;
      id: string;
    },
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      const downloadLogsResponse = await fetch(payload.url);
      if (downloadLogsResponse.ok) {
        const fileContent = await downloadLogsResponse.text();
        const downloadedLogs = new Blob([fileContent], {
          type: 'text/plain',
        });
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(downloadedLogs);
        downloadLink.download = `service-logs-${payload.id}.log`;
        downloadLink.click();
      } else
        dispatch(
          addNotificationRtk({
            message: 'Error exporting logs',
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
      return fulfillWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const fetchInstanceEventsThunk = createAsyncThunk(
  'instance/fetchInstanceEvents',
  async (
    payload: IFetchInstanceServiceDto,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      dispatch(
        setInstanceEventsServiceLoadingRtk({
          type: payload.type,
          loading: true,
        })
      );
      const response = await fetchLatestLogsService(payload);
      if (!(response as IResponseError).error) {
        // case - export logs - open download url if url present in the response
        if (
          payload.type === InstanceEvent.DOWNLOAD_LOGS &&
          (response as IFetchInstanceServiceResponse).url
        ) {
          dispatch(
            downloadLogsThunk({
              url: (response as IFetchInstanceServiceResponse).url!,
              id: payload.instanceId,
            })
          );
        }

        dispatch(
          setInstanceEventsServiceLoadingRtk({
            type: payload.type,
            loading: false,
          })
        );
        dispatch(
          addNotificationRtk({
            message: response.message,
            timestamp: Date.now(),
            type: NotificationType.Info,
          })
        );
        return fulfillWithValue(response as IFetchInstanceServiceResponse);
      }
      dispatch(
        setInstanceEventsServiceLoadingRtk({
          type: payload.type,
          loading: false,
        })
      );
      Sentry.captureMessage(response.message);
      return rejectWithValue({});
    } catch (error) {
      dispatch(
        setInstanceEventsServiceLoadingRtk({
          type: payload.type,
          loading: false,
        })
      );
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getDomainsThunk = createAsyncThunk(
  'instance/getDomains',
  async (payload: string, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getInstanceDomainsService(payload);
      if (!(response as IResponseError).error) {
        return fulfillWithValue({ instanceId: payload, domain: response });
      }
      Sentry.captureMessage((response as IResponseError).message);
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getDeploymentDetailsThunk = createAsyncThunk(
  'instance/getDeploymentDetails',
  async (
    instanceId: string,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const response = await getDeploymentDetailsService(instanceId);
      if (response.success) {
        dispatch(toggleShouldFetchLogs(true));

        return fulfillWithValue(response as IGetInstanceOrderResponse);
      }
      dispatch(
        addNotificationRtk({
          message: (response as IResponseError).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue(null);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({
        instance: null,
        error,
      });
    }
  }
);

export const createDomainThunk = createAsyncThunk(
  'instance/createDomain',
  async (
    payload: {
      instanceId: string;
      body: ICreateInstanceDomainPayloadDto;
    },
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const response = await createInstanceDomainService(
        payload.instanceId,
        payload.body
      );
      if (!response.error) {
        dispatch(
          addNotificationRtk({
            message: `${capitalizeFirstLetter(
              payload.body.type
            )} created successfully`,
            timestamp: Date.now(),
            type: NotificationType.Success,
          })
        );
        return fulfillWithValue(response);
      }
      dispatch(
        addNotificationRtk({
          message: response.message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const updateDomainThunk = createAsyncThunk(
  'instance/updateDomain',
  async (
    payload: {
      instanceId: string;
      domainId: string;
      organizationId: string;
      body: Partial<ICreateInstanceDomainPayloadDto>;
    },
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    const { instanceId, domainId, organizationId } = payload;
    try {
      dispatch(
        setUpdateDomainLoadingRtk({
          loading: true,
          domainId,
        })
      );
      const response = await updateInstanceDomainService(
        {
          instanceId,
          domainId,
        },
        { ...payload.body, organizationId }
      );
      if (!response.error) {
        dispatch(
          addNotificationRtk({
            message: `${capitalizeFirstLetter(
              response.domain.type
            )} updated successfully`,
            timestamp: Date.now(),
            type: NotificationType.Success,
          })
        );
        dispatch(
          setUpdateDomainLoadingRtk({
            loading: false,
            domainId,
          })
        );
        return fulfillWithValue(response.domain);
      }
      dispatch(
        addNotificationRtk({
          message: response.message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      dispatch(
        setUpdateDomainLoadingRtk({
          loading: false,
          domainId,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      dispatch(
        setUpdateDomainLoadingRtk({
          loading: false,
          domainId,
        })
      );
      return rejectWithValue({});
    }
  }
);

export const verifyDomainThunk = createAsyncThunk(
  'instance/verifyDomain',
  async (
    payload: IInstanceDomainIdPayloadDto,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    const { instanceId, domainId, organizationId } = payload;
    try {
      dispatch(
        setVerifyDomainLoadingRtk({
          loading: true,
          domainId: payload.domainId,
        })
      );
      const response = await verifyInstanceDomainService({
        instanceId,
        domainId,
        organizationId,
      });
      if (!response.error) {
        dispatch(
          addNotificationRtk({
            message: `${capitalizeFirstLetter(
              response.domain.type
            )} updated successfully`,
            timestamp: Date.now(),
            type: NotificationType.Success,
          })
        );
        dispatch(
          setVerifyDomainLoadingRtk({
            loading: false,
            domainId: payload.domainId,
          })
        );
        return fulfillWithValue(response.domain);
      }
      dispatch(
        setVerifyDomainLoadingRtk({
          loading: false,
          domainId: payload.domainId,
        })
      );
      dispatch(
        addNotificationRtk({
          message: response.message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      dispatch(
        setVerifyDomainLoadingRtk({
          loading: false,
          domainId: payload.domainId,
        })
      );
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const deleteDomainThunk = createAsyncThunk(
  'instance/deleteDomain',
  async (
    payload: IDeleteInstanceDomainPayloadDto,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    const { instanceId, domainId, organizationId, domainType } = payload;
    try {
      dispatch(
        setDeleteDomainLoadingRtk({
          loading: true,
          domainId,
        })
      );
      const response = await deleteInstanceDomainService({
        instanceId,
        domainId,
        organizationId,
      });
      if (!(response as IResponseError).error) {
        dispatch(
          addNotificationRtk({
            message: `${capitalizeFirstLetter(
              domainType
            )} removed successfully`,
            timestamp: Date.now(),
            type: NotificationType.Success,
          })
        );
        dispatch(
          setDeleteDomainLoadingRtk({
            loading: false,
            domainId,
          })
        );
        return fulfillWithValue({
          domainId,
          domainType,
        });
      }
      dispatch(
        setDeleteDomainLoadingRtk({
          loading: false,
          domainId,
        })
      );
      dispatch(
        addNotificationRtk({
          message: (response as IResponseError).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      dispatch(
        setDeleteDomainLoadingRtk({
          loading: false,
          domainId,
        })
      );
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const shellCommandThunk = createAsyncThunk(
  'instance/shellCommand',
  async (
    payload: {
      shellCommand: string;
      instanceId: string;
    },
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const response = await shellCommandService(
        payload.shellCommand,
        payload.instanceId
      );
      if (!response.error) {
        return fulfillWithValue(response.logs);
      }
      dispatch(
        addNotificationRtk({
          message: response.message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const updateInstanceAutoScalingRulesThunk = createAsyncThunk(
  'instance/updateInstanceAutoScalingRules',
  async (
    payload: {
      autoScalingRules: IAutoscalingRequestPayload;
      instanceId: string;
    },
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const response = await updateAutoScalingRulesService(
        payload.autoScalingRules,
        payload.instanceId
      );
      if (!response.error) {
        dispatch(
          addNotificationRtk({
            message: `Successfully update Autoscaling rules.
             Please refresh to see the latest changes`,
            timestamp: Date.now(),
            type: NotificationType.Success,
          })
        );
        return fulfillWithValue(
          payload.autoScalingRules?.cancelAutoscaling
            ? null
            : payload.autoScalingRules
        );
      }
      dispatch(
        addNotificationRtk({
          message: response.message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getComputeInstanceDetailsThunk = createAsyncThunk(
  'instance/getComputeInstanceDetails',
  async (payload: string, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await getComputeInstanceDetailsService(payload);
      if (!response.error) {
        dispatch(
          setSelectedComputeProjectRtk(response.instance.computeProject)
        );
        dispatch(setSelectedProjectName(response.instance.computeProject.name));
        return fulfillWithValue(response);
      }
      dispatch(
        addNotificationRtk({
          message: response.message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const closeMultipleInstancesThunk = createAsyncThunk(
  'instance/closeMultipleInstances',
  async (
    payload: string[],
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const response = await closeMultipleInstancesService(payload);
      if (response.success) {
        dispatch(
          addNotificationRtk({
            message: response.message,
            timestamp: Date.now(),
            type: NotificationType.Success,
          })
        );
        dispatch(
          setSelectedComputeProjectInstancesClose(response.closedInstanceIds)
        );
        return fulfillWithValue({});
      }
      dispatch(
        addNotificationRtk({
          message: response.message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const removeMultipleInstancesThunk = createAsyncThunk(
  'instance/removeMultipleInstances',
  async (
    payload: string[],
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const response = await deleteMultipleInstanceService(payload);
      if (response?.error) {
        dispatch(
          addNotificationRtk({
            message: response.message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      dispatch(
        addNotificationRtk({
          message: 'Instances Removed!',
          timestamp: Date.now(),
          type: NotificationType.Success,
        })
      );
      dispatch(setSelectedComputeProjectInstancesDelete(payload));
      return fulfillWithValue({});
    } catch (error) {
      dispatch(
        addNotificationRtk({
          message: (error as Error).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const editInstanceNameThunk = createAsyncThunk(
  'instance/editInstanceName',
  async (
    payload: {
      name: string;
      instanceId: string;
    },
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      await editInstanceNameService(payload.name, payload.instanceId);
      dispatch(
        addNotificationRtk({
          message: 'Name updated!',
          timestamp: Date.now(),
          type: NotificationType.Success,
        })
      );
      dispatch(setUpdatedProjectInstanceName(payload));
      return fulfillWithValue(payload);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const updateHealthCheckThunk = createAsyncThunk(
  'instance/updateHealthCheck',
  async (
    payload: any,
    { rejectWithValue, fulfillWithValue, dispatch, getState }
  ) => {
    try {
      const response = await updateHealthCheckService(
        payload.healthCheckConfig
      );
      if (!response.error) {
        dispatch(
          toggleModalShowRtk({
            modalShow: false,
          })
        );
        dispatch(
          addNotificationRtk({
            message: response.message,
            timestamp: Date.now(),
            type: NotificationType.Success,
          })
        );

        const { instance } = getState() as RootState;
        dispatch(
          getDeploymentDetailsThunk(
            (instance.selectedInstance as IInstance)?.activeDeployment ||
              (instance.selectedInstance as IInstance).deployments[
                instance.selectedInstance.deployments.length - 1
              ]
          )
        );
        return fulfillWithValue({});
      }
      dispatch(
        toggleModalShowRtk({
          modalShow: false,
        })
      );
      dispatch(
        addNotificationRtk({
          message: response.message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      Sentry.captureMessage(response.message);
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);
