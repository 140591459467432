import React, { KeyboardEvent } from 'react';
import { Badge, Button } from '@spheron/ui-library';
import { ReactComponent as Check } from '../../../../../assets/compute/icons/checkbox-template-card.svg';
import Styles from '../../../../../styles/compute/compute-pricing.module.scss';
import {
  IGPU,
  IInstanceCost,
} from '../../../../../redux/instance/instance.interfaces';
import { ScalingType } from '../../../../../redux/instance/instance-creation/instance-creation.interfaces';

interface ICostSectionProps {
  primaryCost: IInstanceCost;
  secondaryCost: IInstanceCost;
}

const CostSection = ({ primaryCost, secondaryCost }: ICostSectionProps) => {
  return (
    <>
      <div className="mt-2 flex items-center">
        <span
          className="text-base-heading-text-color dark:text-dark-base-heading-text-color
          font-bold text-2xl"
        >
          ${primaryCost.value}
        </span>
        <span className="ml-2 text-text-darkGray text-xs font-normal">
          {primaryCost.unit}
        </span>
      </div>
      <div className="mt-2 text-text-darkGray text-sm font-normal lowercase">
        ${secondaryCost.value} {secondaryCost.unit} cost
      </div>
    </>
  );
};
interface IProps {
  title: string;
  monthPrice: string | number;
  hourlyPrice: string | number;
  ramGb: string;
  cpuQuantity: number;
  isSelected: boolean;
  onSelect: () => void;
  isCustomStyle: boolean;
  recommended: boolean;
  icon: string | null;
  disabled: boolean;
  gpu: IGPU | null;
  scalingType: ScalingType;
}

const PlanTile = ({
  title,
  monthPrice,
  hourlyPrice,
  ramGb,
  cpuQuantity,
  isSelected,
  onSelect,
  isCustomStyle,
  recommended,
  disabled,
  icon,
  gpu,
  scalingType,
}: IProps) => {
  const handleClick = () => {
    if (!disabled) {
      onSelect();
    }
  };
  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      if (!disabled) {
        onSelect();
      }
    }
  };
  let primaryCost: IInstanceCost;
  let secondaryCost: IInstanceCost;
  if (gpu) {
    primaryCost = {
      value: hourlyPrice,
      unit: scalingType === ScalingType.MANUAL ? 'hourly' : 'Avg hourly',
    };
    secondaryCost = {
      value: monthPrice,
      unit: scalingType === ScalingType.MANUAL ? 'monthly' : 'Avg monthly',
    };
  } else {
    primaryCost = {
      value: monthPrice,
      unit: scalingType === ScalingType.MANUAL ? 'monthly' : 'Avg monthly',
    };
    secondaryCost = {
      value: hourlyPrice,
      unit: scalingType === ScalingType.MANUAL ? 'hourly' : 'Avg hourly',
    };
  }

  return (
    <div
      role="button"
      className={`rounded-lg px-4 py-3 w-full min-w-[232px] relative border overflow-hidden
          dark:bg-dark-base-bg bg-base-bg ease-in duration-75
          ${
            isSelected
              ? 'border-form-selected dark:border-dark-form-selected'
              : 'border-base-border dark:border-dark-base-border'
          } ${
        disabled
          ? 'cursor-not-allowed'
          : `cursor-pointer hover:border-form-selected
          dark:hover:border-dark-form-selected`
      }`}
      onClick={handleClick}
      tabIndex={0}
      onKeyDown={handleKeyDown}
    >
      {disabled && (
        <div
          className="absolute h-full w-full z-50 bg-white transform 
      -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 bg-opacity-70
      cursor-not-allowed"
        >
          <div className="flex flex-col relative items-center justify-center h-full w-full">
            <Button
              buttonType="primary"
              label="Contact Team"
              size="small"
              onClick={() =>
                window.open('https://b4t4v7fj3cd.typeform.com/to/YsTtgJ6H')
              }
            />
            <div className="absolute bottom-2 right-2">
              <Badge
                badgeType="default"
                solid={false}
                size="medium"
                text="Out of Stock"
              />
            </div>
          </div>
        </div>
      )}
      <div className="flex items-center justify-start">
        {!!icon && <img src={icon} alt="gpu" className="mr-1.5" />}
        <span
          className={`text-base font-semibold bg-clip-text ${
            isCustomStyle
              ? `${Styles.custom__title__gradient}`
              : 'text-base-heading-text-color dark:text-dark-base-heading-text-color'
          }`}
        >
          {title}
        </span>
      </div>
      <CostSection primaryCost={primaryCost} secondaryCost={secondaryCost} />
      <ul
        className="mt-3 list-disc ml-4
        text-base-heading-text-color dark:text-dark-base-heading-text-color
        text-sm font-medium
        space-y-2"
      >
        {gpu && (
          <li className="capitalize">
            {gpu.vendor} {gpu.model}
          </li>
        )}
        <li>{cpuQuantity} CPU</li>
        <li>{ramGb} RAM</li>
      </ul>
      <>
        {isSelected && (
          <span className="absolute right-2 top-2">
            <Check className={Styles.check__icon} />
          </span>
        )}
      </>
      {recommended && (
        <div className="flex items-center justify-end">
          <Badge
            badgeType="info"
            solid={false}
            size="medium"
            text="Recommended"
          />
        </div>
      )}
    </div>
  );
};

export default PlanTile;
