import React from 'react';
import { TextInput } from '@spheron/ui-library';
import { ReactComponent as CrossIcon } from '../../../assets/compute/icons/cross-icon.svg';

interface IProps {
  buildCommands: string[];
  // eslint-disable-next-line no-unused-vars
  setBuildCommands: (buildCommands: string[]) => void;
  disabled?: boolean;
}

const AddBuildCommands = ({
  buildCommands,
  setBuildCommands,
  disabled,
}: IProps) => {
  const fillCommand = (value: string, id: number) => {
    setBuildCommands(buildCommands.map((item, i) => (i === id ? value : item)));
  };

  const removeBuildCommandItem = (id: number) => {
    setBuildCommands(buildCommands.filter((command, i) => i !== id));
  };
  return (
    <div className="relative flex flex-col gap-y-4">
      {/* TODO: Add correct key prop here */}
      {buildCommands.map((command, i) => (
        <div>
          <div className="ll:w-[450px] w-6/12">
            <TextInput
              type="text"
              value={command}
              disabled={disabled}
              onChange={(e) => fillCommand(e as string, i)}
              error={false}
              helpText=""
              placeholder="Enter Commands"
            />
          </div>
          {!disabled && (
            <div
              className="absolute -mt-7 ll:left-auto left-[55%] ll:-right-7"
              onClick={() => removeBuildCommandItem(i)}
              role="presentation"
            >
              <CrossIcon
                className="text-text-darkGray
              hover:opacity-70 ease-in duration-75"
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

AddBuildCommands.defaultProps = {
  disabled: false,
};

export default AddBuildCommands;
