import { useRef } from 'react';

const useScrollTo = () => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const scrollToElement = () => {
    if (scrollRef.current) {
      window.scrollTo({
        behavior: 'smooth',
        top: scrollRef.current.offsetTop - 150,
      });
    }
  };

  return { scrollRef, scrollToElement };
};

export default useScrollTo;
