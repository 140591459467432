import React from 'react';
import { ReactComponent as NotificationIcon } from '@spheron/ui-library/dist/assets/notification.svg';
import { useSelector } from 'react-redux';
import NotificationDropdown from './notification-dropdown';
import { IUserNotification } from '../../../redux/compute/user/user.interfaces';
import NotificationItem from './notification-item';
import { RootState } from '../../../redux/compute/store';

const Notifications = () => {
  const userNotifications: IUserNotification[] = useSelector(
    (state: RootState) => state.user.notifications
  );
  const unreadNotifications: IUserNotification[] = userNotifications
    .filter((notification) => notification.read === false)
    .sort((a, b) => {
      return Number(b.createdAt) - Number(a.createdAt);
    });

  return (
    <NotificationDropdown
      isUnreadNotification={unreadNotifications.length > 0}
      menuButton={
        <div className="relative overflow">
          <NotificationIcon className="opacity-90 hover:opacity-100 w-6 h-6" />
          {unreadNotifications.length > 0 && (
            <div className="absolute -top-2 -right-3 flex h-4 w-4">
              <span
                className="animate-ping absolute inline-flex h-full w-full 
                    rounded-full bg-blue-400 opacity-75"
              />
              <span
                style={{ fontSize: '10px' }}
                className="text-center 
                      text-white relative inline-flex justify-center 
                      items-center font-bold rounded-full h-4 w-4 bg-blue-500"
              >
                {unreadNotifications.length}
              </span>
            </div>
          )}
        </div>
      }
      menuItems={
        userNotifications.length > 0
          ? userNotifications.map((notification) => (
              <React.Fragment key={notification._id}>
                <NotificationItem
                  title={notification.title}
                  createdAt={notification.createdAt}
                  description={notification.description}
                  externalLink={notification.externalLink}
                  read={notification.read}
                  id={notification._id}
                  noNotification={false}
                />
              </React.Fragment>
            ))
          : Array.from({ length: 1 }).map((element: any) => (
              <>
                <NotificationItem
                  key={element}
                  noNotification
                  title="No Notifications"
                  description="You've no notifications yet"
                />
              </>
            ))
      }
    />
  );
};

export default Notifications;
