/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Button, Link, Modal, Tooltip } from '@spheron/ui-library';
import { ReactComponent as CopyIcon } from '@spheron/ui-library/dist/assets/copy.svg';
import { useDispatch } from 'react-redux';
import { ReactComponent as PublicIcon } from '../../../assets/compute/icons/public.svg';
import { ReactComponent as PrivateIcon } from '../../../assets/compute/icons/private.svg';
import Styles from '../../../styles/compute/modal.module.scss';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import ModalWrapper from './ModalWrapper';
import { copyToClipboard } from '../../../redux/compute/user/user.utils';
import { AppDispatch } from '../../../redux/compute/store';

interface IProps {
  isOpen: boolean;
  visibilityAction: string;
  link: string;
  organisationName: string;
}

const ChangedOrgVisibilityConfirmed = ({
  isOpen,
  visibilityAction,
  link,
  organisationName,
}: IProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [dataCopied, setDataCopied] = useState<boolean>(false);
  const closeModal = () => {
    dispatch(toggleModalShowRtk({ modalShow: false }));
  };

  const isPublic = visibilityAction?.toLowerCase() === 'public';

  const icon = !isPublic ? (
    <PrivateIcon className="dark:text-dark-base-icon text-base-icon" />
  ) : (
    <PublicIcon className="dark:text-dark-base-icon text-base-icon" />
  );

  const confirmationText = !isPublic
    ? `By switching to Private, only members can have access
                  to this organization.`
    : `By switching to Public, you've provided read-only access to anyone with the shared link.`;

  const handleCopyClick = (data: string) => {
    copyToClipboard(data);
    setDataCopied(true);
    setTimeout(() => {
      setDataCopied(false);
    }, 3000);
  };
  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={closeModal}
      classname={`${Styles.modal__outer__con} w-430 modal fixed inset-0 overflow-y-auto`}
    >
      <div className="w-[500px]">
        <Modal
          modalContent={
            <div className="flex flex-col items-center gap-y-3">
              {icon}
              <div className="flex flex-col gap-y-5 items-center">
                <h4 className="text-cards-text dark:text-dark-cards-text font-500 leading-6 text-5">
                  {organisationName} is now{' '}
                  <span className="capitalize">{visibilityAction}</span>
                </h4>
                <p className="text-sm font-normal leading-5 mb-3 text-center">
                  Success! Your organization, <b>{organisationName}</b> is now{' '}
                  {visibilityAction}. {confirmationText}
                </p>
                {isPublic && (
                  <div className="flex items-center justify-center gap-x-1">
                    <Link
                      type="primary"
                      size="default"
                      text={link || ''}
                      isBold={false}
                      onClick={() => {}}
                    />
                    <Tooltip
                      position="top"
                      text={dataCopied ? `Link Copied` : `Copy Link`}
                      type="float"
                      wrapText
                    >
                      <CopyIcon
                        className="w-5 h-5 cursor-pointer text-base-icon dark:text-dark-base-icon
                      opacity-80 hover:opacity-100"
                        onClick={() => handleCopyClick(link)}
                      />
                    </Tooltip>
                  </div>
                )}

                <Button
                  buttonType="primary"
                  onClick={closeModal}
                  label="Okay"
                />
              </div>
            </div>
          }
          primaryButtonContent={''}
          secondaryButtonContent={''}
          subtitle={''}
        />
      </div>
    </ModalWrapper>
  );
};

export default ChangedOrgVisibilityConfirmed;
