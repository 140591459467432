import React from 'react';
import { TextInput } from '@spheron/ui-library';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/compute/store';
import { ClusterType } from '../../../../../redux/compute/instance/instance-creation/instance-creation.interfaces';
// eslint-disable-next-line max-len
import { isAccelerateDeployment } from '../../../../../redux/compute/instance/instance-creation/instance-creation.utils';

interface IProps {
  isUpdate: boolean;
  instanceName: string;
  // eslint-disable-next-line no-unused-vars
  setInstanceName: (name: string) => void;
}

const ChooseInstanceName = ({
  isUpdate,
  instanceName,
  setInstanceName,
}: IProps) => {
  const clusterType = useSelector(
    (state: RootState) => state.instanceCreation.clusterType
  );
  const deploymentType = useSelector(
    (state: RootState) => state.instanceCreation.deploymentType
  );
  const stepNumber = isAccelerateDeployment(deploymentType) ? '4.' : '5.';

  return (
    <>
      {!isUpdate && clusterType === ClusterType.TEMPLATE ? (
        <div className="flex flex-col items-start gap-y-6 mb-10">
          <div className="flex flex-col items-start justify-start">
            <h3
              className="text-base-heading-text-color dark:text-dark-base-heading-text-color
                    font-bold text-xl"
            >
              {stepNumber} Choose Instance Name
            </h3>
            <p className="text-text-darkGray text-sm font-normal leading-5 mt-2">
              Select a unique name for your instance to easily identify and
              manage it within your environment.
            </p>
          </div>
          <div className="w-1/4">
            <TextInput
              type="text"
              className="w-full"
              inputSize="compact"
              value={instanceName}
              placeholder="e.g. myMongoDBInstance"
              onChange={(e: any) => setInstanceName(e)}
              maxLength={64}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ChooseInstanceName;
