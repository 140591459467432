import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { ReactComponent as CopyIcon } from '@spheron/ui-library/dist/assets/copy.svg';
import { Tooltip } from '@spheron/ui-library';
import GlobalStyles from '../../styles/compute/global.module.scss';
import { withLoader } from '../../redux/root-utils';
import { copyToClipboard } from '../../redux/user/user.utils';

interface IProps {
  data: string;
  message: string;
  loading: boolean;
  title: string;
  isCompact: boolean;
}

function CopyIdCard({ data, message, loading, title, isCompact }: IProps) {
  const [couponCopied, setDataCopied] = useState(false);

  const handleCopyClick = () => {
    copyToClipboard(data);
    setDataCopied(true);
    setTimeout(() => {
      setDataCopied(false);
    }, 3000);
  };

  return (
    <div className="flex flex-col gap-2 items-start">
      {title.length > 0 && (
        <div
          className="uppercase text-xs leading-3 font-semibold
             text-form-text tracking-[1px] dark:text-dark-form-text"
        >
          {title}
        </div>
      )}
      {withLoader(
        loading,
        <Skeleton
          height={48}
          duration={2}
          containerClassName="flex w-full rounded-lg"
        />,
        <div
          className={`text-form-text dark:text-dark-form-text text-sm font-normal dark:bg-dark-base-bg 
          bg-bg-textbox dark:border-dark-form-border 
          border-form-border border-[1.5px] relative rounded-lg flex 
          justify-between items-center w-full
          ${isCompact ? 'h-10 py-2 px-3' : 'p-3 h-12'} outline-none dark:${
            GlobalStyles.dark_boxShadow
          }`}
        >
          {data}
          <Tooltip
            position="top"
            text={couponCopied ? `${message} Copied` : `Copy ${message}`}
            type="float"
            wrapText
          >
            <CopyIcon
              className="w-5 h-5 cursor-pointer text-base-icon dark:text-dark-base-icon
                      opacity-80 hover:opacity-100"
              onClick={handleCopyClick}
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
}

export default CopyIdCard;
