import React from 'react';
import { Modal } from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import Styles from '../../../styles/compute/modal.module.scss';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import { changeMemberRoleThunk } from '../../../redux/compute/organisation/organisation.thunks';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import ModalWrapper from './ModalWrapper';

interface IProps {
  isOpen: boolean;
  memberId: string;
  memberName: string;
  currentRole: string;
  role: string;
  // eslint-disable-next-line no-unused-vars
  setDropdownRole: (role: string) => void;
}

function ChangeMemberRole({
  isOpen,
  memberId,
  memberName,
  currentRole,
  role,
  setDropdownRole,
}: IProps) {
  const dispatchRtk = useDispatch<AppDispatch>();

  const changingMemberRole = useSelector(
    (state: RootState) => state.organisation.changingMemberRole
  );
  const selectedOrganisation = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation
  );

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={() => {
        dispatchRtk(toggleModalShowRtk({ modalShow: false }));
      }}
      classname={`${Styles.modal__outer__con} modal fixed inset-0 overflow-y-auto`}
    >
      <div className="w-500">
        <Modal
          header
          footer
          headerContent={<>Change Member&apos;s Role</>}
          modalContent={
            <div className="flex flex-col gap-y-3">
              <p className="text-sm font-normal leading-5 mb-3 text-center">
                Do you want to change <b>{memberName}</b> current role{' '}
                <b className="capitalize">{currentRole}</b> to{' '}
                <b className="capitalize">{role}</b>?
              </p>
            </div>
          }
          onPrimaryButtonClick={() =>
            dispatchRtk(
              changeMemberRoleThunk({
                organizationId: selectedOrganisation?._id,
                userId: memberId,
                roleGroupName: role,
              })
            ).then(() => {
              setDropdownRole(role);
            })
          }
          onSecondaryButtonClick={() =>
            dispatchRtk(toggleModalShowRtk({ modalShow: false }))
          }
          primaryButtonLoading={changingMemberRole}
          primaryButtonContent="Change"
          secondaryButtonContent="Cancel"
          subtitle={''}
          primaryButton
          secondaryButton
        />
      </div>
    </ModalWrapper>
  );
}

export default ChangeMemberRole;
