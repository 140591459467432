import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { INotification, INotificationState } from './notification.interfaces';

export const initialState: INotificationState = {
  notificationArray: [],
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    notificationTimeout(state) {
      const newNotificationArray = [...state.notificationArray];
      newNotificationArray.shift();
      state.notificationArray = [...newNotificationArray];
    },
    addNotification(state, action: PayloadAction<INotification>) {
      state.notificationArray = [...state.notificationArray, action.payload];
    },
  },
});

export const {
  notificationTimeout: notificationTimeoutRtk,
  addNotification: addNotificationRtk,
} = notificationSlice.actions;
export const { reducer: notificationRTKReducer } = notificationSlice;
