import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

interface IProps {
  isOpen: boolean;
  children: JSX.Element;
  onClose: () => void;
  classname: string;
}
const ModalWrapper = ({ isOpen, children, onClose, classname }: IProps) => {
  return (
    <Transition.Root appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        style={{ zIndex: 9999 }}
        className={classname}
        open={isOpen}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-25" />
        </Transition.Child>
        {children}
      </Dialog>
    </Transition.Root>
  );
};

export default ModalWrapper;
