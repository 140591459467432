import React from 'react';
import Skeleton from 'react-loading-skeleton';
import GlobalStyle from '../../styles/compute/global.module.scss';

interface IProps {
  className?: string;
  placeholder?: string;
  inputClassName?: string;
  id?: string;
  label?: string;
  value: string | number;
  name?: string;
  type?: string;
  maxAmount?: number;
  minAmount?: number | string;
  maxLength?: number;
  loading?: boolean;
  disabled?: boolean;
  error?: boolean;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: string) => void;
}

const TextInput = ({
  className,
  placeholder,
  inputClassName,
  value,
  // secondary,
  id,
  type,
  label,
  name,
  maxAmount,
  minAmount,
  maxLength,
  loading,
  disabled,
  error,
  onChange,
}: IProps) => {
  return (
    <div className={className && className}>
      {label && (
        <label
          htmlFor={id}
          className="text-xs uppercase font-medium mb-3 inline-block"
        >
          {label}
        </label>
      )}
      {!loading ? (
        <input
          id={id}
          name={name}
          className={`border-2
           px-4 py-1.5 w-full outline-none transition dark:bg-dark-base-bg
           dark:focus:bg-dark-text_input dark:text-dark-gray-text
            dark:focus:text-white focus:border-form-selected 
           focus:outline-none focus:ring-2 focus:ring-form-selected focus:ring-opacity-10
            ${
              error
                ? 'border-feedback-error-text'
                : 'border-base-border dark:border-dark-base-border'
            }
            dark:${GlobalStyle.dark_boxShadow} ${
            !!inputClassName && inputClassName
          } ${disabled && 'cursor-not-allowed'} `}
          type={type || 'text'}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          max={maxAmount}
          min={minAmount}
          maxLength={maxLength}
          onChange={(e) => onChange(e.target.value)}
        />
      ) : (
        <Skeleton height={40} containerClassName="flex" />
      )}
    </div>
  );
};

TextInput.defaultProps = {
  className: '',
  placeholder: '',
  inputClassName: 'rounded-xl text-text-textbox font-medium',
  name: '',
  type: 'text',
  // secondary: () => {},
  label: null,
  id: null,
  loading: null,
  disabled: false,
  error: false,
  maxAmount: null,
  minAmount: null,
  maxLength: 400,
};

export default TextInput;
