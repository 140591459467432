/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Badge, Link, Tooltip } from '@spheron/ui-library';
import { ReactComponent as InfoIcon } from '@spheron/ui-library/dist/assets/info-circle.svg';
import { ReactComponent as MemoryIcon } from '@spheron/ui-library/dist/assets/compute/ram.svg';
import { ReactComponent as CPUIcon } from '@spheron/ui-library/dist/assets/compute/cpu.svg';
import { ReactComponent as RightArrowIcon } from '@spheron/ui-library/dist/assets/arrow-right-1.svg';
import { useDispatch, useSelector } from 'react-redux';
import ComputePriceCard from './compute-price-card';
import ComputeBalanceCard from './compute-balance-card';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import config from '../../../config';
import { withLoader } from '../../../redux/compute/root-utils';
import { IOrganization } from '../../../redux/compute/combined-state.interface';
import { getWalletUsageThunk } from '../../../redux/compute/cluster/cluster.thunks';
import { ICurrentApp } from '../../../redux/compute/organisation/organisation.interfaces';
import Metrics from './metrics';
import MetricsLoading from '../Loaders/metrics-loading';
import { getSubscriptionUsageThunk } from '../../../redux/compute/subscription/subscription.thunks';
import { mapCurrentAppToSpecialization } from '../../../redux/compute/organisation/organisation.utils';

dayjs.extend(relativeTime);

const ComputeUsage = () => {
  const navigate = useNavigate();
  const dispatchRtk = useDispatch<AppDispatch>();

  const currentApp = useSelector(
    (state: RootState) => state.organisation.currentApp
  );

  const clusterWalletUsage = useSelector(
    (state: RootState) => state.cluster.clusterWalletUsage
  );

  const clusterWalletUsageLoading: boolean = useSelector(
    (state: RootState) => state.cluster.clusterWalletUsageLoading
  );

  const totalInstances: number = useSelector(
    (state: RootState) => state.computeProject.billingTotalInstances
  );
  const billingProjectsLoading: boolean = useSelector(
    (state: RootState) => state.computeProject.billingProjectsLoading
  );

  const selectedOrganisationLoading = useSelector(
    (state: RootState) => state.organisation.selectedOrganisationLoading
  );

  const notifications = useSelector(
    (state: RootState) => state.user.notifications
  );

  const selectedOrganisation: IOrganization | null = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation
  );

  const subscriptionUsage = useSelector(
    (state: RootState) => state.subscription.subscriptionUsage
  );

  const subscriptionUsageLoading = useSelector(
    (state: RootState) => state.subscription.subscriptionUsageLoading
  );

  const resourceUsage = [
    {
      id: 0,
      resource: 'CPU',
      unit: 'CPU',
      used: subscriptionUsage.cpu,
      limit: subscriptionUsage.cpuLimit,
    },
    {
      id: 1,
      resource: 'RAM',
      unit: 'GB',
      used: subscriptionUsage.memory,
      limit: subscriptionUsage.memoryLimit,
    },
    {
      id: 2,
      resource: 'Storage',
      unit: 'GB',
      used: subscriptionUsage.storage,
      limit: subscriptionUsage.storageLimit,
    },
  ];

  const currentDate = new Date();

  const expirationDate =
    currentDate.getTime() +
    (clusterWalletUsage?.remainingDays || 0) * 24 * 60 * 60 * 1000;

  const timeLeft = clusterWalletUsage?.remainingDays
    ? clusterWalletUsage?.remainingDays > 10000
      ? 'Long time left'
      : `${dayjs().to(expirationDate).slice(3)} left`
    : 'Extremely Low';

  const showDaysRemainingBadge =
    clusterWalletUsageLoading || selectedOrganisationLoading;

  const isAverageUsageNull = !clusterWalletUsage?.dailyUsage;

  const instanceReport = [
    {
      id: 1,
      badge: null,
      title: 'Total Spent',
      value: `$${clusterWalletUsage?.total
        ?.toFixed(config.dollarDecimalRound.DOLLAR_DECIMAL_ROUND)
        .toString()}`,
      loading: clusterWalletUsageLoading,
      className: 'w-[31%]',
      tooltiptext:
        'Since 15th Jan. It may take some time for consumption data to synchronize',
    },
    {
      id: 2,
      // eslint-disable-next-line no-nested-ternary
      badge: showDaysRemainingBadge ? null : !isAverageUsageNull ? (
        <div className="absolute right-0 top-0">
          <Tooltip
            position="right"
            text={
              clusterWalletUsage?.remainingDays
                ? 'This indicated the time remaining to top up your credits which is calculated based on the hourly usage and the total credit balance left'
                : 'Please topup your wallet before complete depletion to continue using compute'
            }
            type="arrow"
            wrapText
          >
            <Badge
              badgeType={clusterWalletUsage?.remainingDays ? 'info' : 'error'}
              size="medium"
              text={timeLeft}
              solid={false}
              rightIcon={
                <InfoIcon className="cursor-pointer w-3 h-3 text-feedback-info-text dark:text-dark-feedback-info-text" />
              }
            />
          </Tooltip>
        </div>
      ) : null,
      title: 'Hourly Cost',
      value: `$${(clusterWalletUsage?.dailyUsage! / 24)
        ?.toFixed(config.dollarDecimalRound.DOLLAR_DECIMAL_ROUND)
        .toString()}`,
      loading: clusterWalletUsageLoading,
      className: 'w-[38%]',
      tooltiptext: '',
    },
    {
      id: 3,
      badge: null,
      title: 'Total Instances Deployed',
      value: totalInstances.toString(),
      loading: billingProjectsLoading,
      className: 'w-[31%]',
      tooltiptext: '',
    },
  ];

  const notificationTitle = 'Add-on package payment was successful';

  useEffect(() => {
    const notificationTitleCheck = notifications.find(
      (notification) => notification.title === notificationTitle
    );

    if (
      notificationTitleCheck &&
      selectedOrganisation?._id &&
      currentApp === ICurrentApp.COMPUTE &&
      !selectedOrganisationLoading
    ) {
      dispatchRtk(getWalletUsageThunk(selectedOrganisation?._id));
      dispatchRtk(
        getSubscriptionUsageThunk({
          organizationId: selectedOrganisation?._id,
          specialization: mapCurrentAppToSpecialization(currentApp),
        })
      );
    }
  }, [
    notifications,
    dispatchRtk,
    selectedOrganisation?._id,
    selectedOrganisationLoading,
    currentApp,
  ]);

  return (
    <div>
      <div className="flex justify-between mt-8 gap-4">
        <div className="flex flex-col w-full gap-y-3">
          <div className="grid grid-cols-3 gap-5">
            {instanceReport.map((report) => (
              <ComputePriceCard
                key={report.id}
                title={report.title}
                price={report.value}
                loading={report.loading || selectedOrganisationLoading}
                badge={report.badge}
                className=""
                tooltiptext={report.tooltiptext}
              />
            ))}
          </div>
          <div className="flex justify-between w-full gap-x-5 gap-y-4">
            {withLoader(
              subscriptionUsageLoading || selectedOrganisationLoading,
              <>
                {Array.from(Array(3).keys()).map((i) => (
                  <MetricsLoading key={i} />
                ))}
              </>,
              <>
                {resourceUsage.map((resource) => (
                  <div className="w-full" key={resource.id}>
                    <Metrics
                      resource={resource.resource}
                      text=""
                      maxValue={resource.limit}
                      usedValue={resource.used}
                      icon={
                        resource.resource === 'CPU' ? (
                          <CPUIcon className="text-base-icon dark:text-dark-base-icon" />
                        ) : (
                          <MemoryIcon className="text-base-icon dark:text-dark-base-icon" />
                        )
                      }
                      unit={resource.unit}
                    />
                  </div>
                ))}
              </>
            )}
          </div>
        </div>

        <ComputeBalanceCard
          total={clusterWalletUsage?.balance!}
          loading={clusterWalletUsageLoading || selectedOrganisationLoading}
        />
      </div>
      {withLoader(
        selectedOrganisationLoading,
        <div>
          <Skeleton height={20} width={200} duration={2} />
        </div>,
        <div className="mt-2">
          <Link
            type="primary"
            size="default"
            text="To check compute usage page"
            isBold={false}
            onClick={() => navigate('/compute/billing/compute-usage')}
            rightIcon={
              <RightArrowIcon className="text-base-link dark:text-dark-base-link h-3 w-3" />
            }
          />
        </div>
      )}
    </div>
  );
};

export default ComputeUsage;
