import React, { useEffect } from 'react';
import { BroadcastChannel } from 'broadcast-channel';
import { useSelector } from 'react-redux';
import Loader from '../../../../components/Compute/Loaders/loader';
import { isDarkTheme } from '../../../../redux/theme/theme.utils';
import { RootState } from '../../../../redux/rtk-store';
import { useQuery } from '../../../../hooks/useQuery';

const CryptoPaymentCallback = () => {
  const searchParams = useQuery();
  const theme = useSelector((state: RootState) => state.theme.theme);

  useEffect(() => {
    const bc = new BroadcastChannel('copperx_success');
    const copperxSuccess = searchParams.get('copperx_success');
    if (copperxSuccess === 'true') {
      bc.postMessage('payment_successful');
      window.close();
    } else if (copperxSuccess === 'false') {
      bc.postMessage('payment_unsuccessful');
      window.close();
    }

    return () => {
      bc.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`h-screen w-screen ${
        isDarkTheme(theme) && 'bg-feedback-default-bg'
      }`}
    >
      <Loader />
    </div>
  );
};

export default CryptoPaymentCallback;
