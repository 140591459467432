import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ButtonType, Feedback, FeedbackType, Tabs } from '@spheron/ui-library';
import { v4 as uuidv4 } from 'uuid';
import Sticky from 'react-stickynode';
import { ReactComponent as TickCircleIcon } from '@spheron/ui-library/dist/assets/tick-circle.svg';
import { ReactComponent as DangerIcon } from '@spheron/ui-library/dist/assets/danger.svg';
import { ReactComponent as Headphone } from '@spheron/ui-library/dist/assets/headphone.svg';
import { ReactComponent as WalletIcon } from '@spheron/ui-library/dist/assets/wallet.svg';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../redux/compute/store';
import {
  IInstancePlan,
  ResourcesAvailable,
  ScalingType,
  ScalingTypeName,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.interfaces';
import { useDebounce } from '../../../../../hooks/useDebounce';
import {
  IClusterTemplateVariable,
  IServiceData,
} from '../../../../../redux/compute/cluster/cluster.interfaces';
import {
  IClusterTemplateConfig,
  IAutoscalingRequestPayload,
} from '../../../../../redux/compute/instance/instance.interfaces';
import { CustomStorageOption } from '../CustomStorage/custom-storage';
import SelectedPlanLoading from '../../../../../components/Compute/Loaders/selected-plan-loader';
import { getInstancePlanThunk } from '../../../../../redux/compute/instance/instance-creation/instance-creation.thunks';
import {
  resetPersistentValuesRtk,
  updateCustomCpuRtk,
  updateCustomNvmStorageRtk,
  updateCustomPersistentStorageRtk,
  updateCustomRamRtk,
  updateCustomStorageRtk,
  updateLoadMoreCurrentPageRtk,
  selectClusterRegionRtk,
  setScalingNameRtk,
  setScalingTypeRtk,
  setSelectedService,
  updateMultiServiceCustomCpu,
  updateMultiServiceCustomRam,
  updateMultiServiceCustomPlan,
  updateMultiServiceInstanceValue,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.slice';
import { mapPortToConfig } from '../../../../../redux/compute/instance/instance.utils';
import { ISpheronWallet } from '../../../../../redux/compute/organisation/organisation.interfaces';
import config from '../../../../../config';
import MultiServicePriceCard from '../MultiServicePriceCard';
import MultiServicePlanGrid from '../MultiServicePlanGrid';
import { createUsername } from '../../../../../redux/compute/root-utils';
import { toggleModalShowRtk } from '../../../../../redux/compute/modal/modal.slice';
import { sliceStringFromEnd } from '../../../../../redux/compute/subscription/subscription.utils';
import {
  calculateInstancePrice,
  isScalable,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.utils';

interface IProps {
  isUpdate: boolean;
}

const MultiServiceUpdateSection = ({ isUpdate }: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const params = useParams<{ computeProjectId: string }>();
  const [searchValue, setSearchValue] = useState<string | null>(null);

  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [planSearchValue, setPlanSearchValue] = useState<string | null>(null);
  const [selectedInstancePlans, setSelectedInstancePlans] = useState<any>([]);
  const [advanceConfigRecord, setAdvanceConfigRecord] = useState<
    Record<string, any>
  >({});
  const [disabledTabRecord, setDisabledTabRecord] = useState<
    Record<string, { disabled: boolean; name: string }>
  >({});
  const [disableUpdateRecord, setDisableUpdateRecord] = useState<
    Record<string, { disabled: boolean; name: string }>
  >({});
  const [updateDisabled, setUpdateDisabled] = useState<boolean>(false);
  const [totalResources, setTotalResources] = useState<{
    cpu: number;
    ram: number;
  }>({ cpu: 0, ram: 0 });

  const selectedInstance = useSelector(
    (state: RootState) => state.instance.selectedInstance
  );
  const activeDeployment = useSelector(
    (state: RootState) => state.instance.activeDeployment
  );
  const activeDeploymentLoading = useSelector(
    (state: RootState) => state.instance.activeDeploymentLoading
  );
  const selectedOrganisation = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation
  );
  const selectedClusterRegion = useSelector(
    (state: RootState) => state.instanceCreation.clusterRegion
  );
  const instancePlans = useSelector(
    (state: RootState) => state.instanceCreation.instancePlans
  );
  const instancePlansLoading = useSelector(
    (state: RootState) => state.instanceCreation.instancePlanLoading
  );
  const recommendedPlanLoading = useSelector(
    (state: RootState) => state.instanceCreation.recommendedPlanLoading
  );
  const clusterScaling = useSelector(
    (state: RootState) => state.instanceCreation.clusterScaling
  );
  const clusterRegion = useSelector(
    (state: RootState) => state.instanceCreation.clusterRegion
  );
  const unitPrices = useSelector(
    (state: RootState) => state.instanceCreation.unitPrices
  );
  const planSearch = useSelector(
    (state: RootState) => state.instanceCreation.planSearch
  );
  const clusterScalingName = useSelector(
    (state: RootState) => state.instanceCreation.clusterScalingName
  );
  const persistentStorageAvailable = useSelector(
    (state: RootState) => state.instanceCreation.persistentStorageAvailable
  );
  const storageAvailable = useSelector(
    (state: RootState) => state.instanceCreation.storageAvailable
  );
  const planAvailable = useSelector(
    (state: RootState) => state.instanceCreation.planAvailable
  );
  const currentApp = useSelector(
    (state: RootState) => state.organisation.currentApp
  );
  const spheronWallet = useSelector(
    (state: RootState) => state.organisation.spheronWallet
  );
  const clusterWalletUsage = useSelector(
    (state: RootState) => state.cluster.clusterWalletUsage
  );
  const clusterWalletUsageLoading: boolean = useSelector(
    (state: RootState) => state.cluster.clusterWalletUsageLoading
  );
  const multiserviceInstanceCreation = useSelector(
    (state: RootState) => state.instanceCreation.multiserviceInstanceCreation
  );
  const instanceDeploymentLoading: boolean = useSelector(
    (state: RootState) => state.instance.deployingInstance
  );
  const subscriptionUsage = useSelector(
    (state: RootState) => state.subscription.subscriptionUsage
  );
  const subscriptionUsageLoading = useSelector(
    (state: RootState) => state.subscription.subscriptionUsageLoading
  );

  const debouncedSearchTerm = useDebounce(searchValue, 500);
  const mapBuildEnv = (
    buildEnv: { key: string; value: string; isSecret: boolean }[]
  ) => {
    const buildEnvObj: {
      value: string;
      isSecret: boolean;
    }[] = [];
    buildEnv.forEach((env) => {
      buildEnvObj.push({
        value: `${env.key}=${env.value.trim()}`,
        isSecret: env.isSecret,
      });
    });
    return buildEnvObj;
  };

  useEffect(() => {
    if (activeDeployment && instancePlans) {
      const initialSelectedPlans = activeDeployment.services.map((service) => {
        const selectedServiceId = service.template?._id;
        const foundCustomPlan = instancePlans.akashMachineImages.find(
          (image) => image._id === `custom-id-${selectedServiceId}`
        );
        if (foundCustomPlan)
          return {
            serviceId: service.template?._id,
            instancePlan: foundCustomPlan,
          };
        const foundPlan = instancePlans.akashMachineImages.find(
          (image) => image._id === service.agreedMachineImage.machineId
        );
        if (foundPlan)
          return {
            serviceId: service.template?._id,
            instancePlan: foundPlan,
          };
        const foundSelectedPlan = selectedInstancePlans?.find(
          (plan: any) => plan?.serviceId === selectedServiceId
        );
        if (foundSelectedPlan) return foundSelectedPlan;
        const unitPrice = unitPrices?.find((unitPrice) => {
          return (
            unitPrice.region === clusterRegion &&
            unitPrice.scalingMode === clusterScaling
          );
        })!;
        const cpuMemoryPrice = calculateInstancePrice({
          cpu: unitPrice?.cpu / 30,
          numCpu: Number(service.agreedMachineImage.cpu),
          memory: unitPrice?.memory / 30,
          numMemory: Number(
            sliceStringFromEnd(service.agreedMachineImage.memory as string, 2)
          ),
        });

        const customPlan: IInstancePlan = {
          cpu: service.agreedMachineImage.cpu,
          defaultDailyTopUp: cpuMemoryPrice,
          maxPricePerBlock: service.agreedMachineImage.maxPricePerBlock,
          memory: service.agreedMachineImage.memory,
          name: 'Custom Plan',
          _id: `custom-id-${selectedServiceId}`,
          price: cpuMemoryPrice,
          storage: service.agreedMachineImage.storage,
          topupThreashold: service.agreedMachineImage.topupThreashold,
          recommendedPlan: false,
        };
        dispatchRtk(
          updateMultiServiceCustomCpu({
            serviceId: selectedServiceId || '',
            value: String(service.agreedMachineImage.cpu),
          })
        );
        dispatchRtk(
          updateMultiServiceCustomRam({
            serviceId: selectedServiceId || '',
            value: String(
              sliceStringFromEnd(service.agreedMachineImage.memory as string, 2)
            ),
          })
        );
        dispatchRtk(
          updateMultiServiceCustomPlan({
            serviceId: selectedServiceId || '',
            value: customPlan,
          })
        );
        dispatchRtk(
          updateMultiServiceInstanceValue({
            serviceId: selectedServiceId || '',
            value: service.serviceCount,
          })
        );
        return {
          serviceId: service.template?._id,
          instancePlan: customPlan,
        };
      });
      setSelectedInstancePlans(initialSelectedPlans);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDeployment, dispatchRtk, instancePlans]);

  useEffect(() => {
    if (activeDeployment) {
      activeDeployment.services.forEach((service) => {
        const selectedServiceId = service.template?._id;
        dispatchRtk(
          updateMultiServiceInstanceValue({
            serviceId: selectedServiceId || '',
            value: service.serviceCount,
          })
        );
      });
    }
  }, [activeDeployment, dispatchRtk]);

  const handleInstancePlanClick = (plan: {
    serviceId: string;
    selectedInstancePlan: IInstancePlan | null;
  }) => {
    if (selectedInstancePlans?.length > 0) {
      const objectIndex = selectedInstancePlans?.findIndex(
        (selectedPlan: any) => selectedPlan.serviceId === plan.serviceId
      );
      if (objectIndex !== -1 && objectIndex !== null) {
        const updatedArray = [...selectedInstancePlans];
        updatedArray[objectIndex] = {
          serviceId: plan.serviceId,
          instancePlan: plan.selectedInstancePlan,
        };

        setSelectedInstancePlans(updatedArray);
      }
    }
  };

  const startInstanceUpdate = () => {
    const uniqueTopicId = uuidv4();
    const clusterTemplateConfig: IClusterTemplateConfig = {
      computeProjectId: params.computeProjectId,
      organizationId: selectedOrganisation._id,
      uniqueTopicId,
      instanceId: activeDeployment?.computeInstance || '',

      region: selectedClusterRegion || 'any',
      scalable: isScalable(clusterScaling),

      services: activeDeployment!.services?.map((service) => {
        const {
          customStorage,
          serviceCount,
          customSsdStorage,
          customHddStorage,
          customNvmStorage,
          customPersistentStorage,
          mountPointValue,
          customPlan,
        } = multiserviceInstanceCreation![service.template?._id!];
        const {
          portMapping,
          buildArgs,
          buildCommands,
          buildEnv,
          secretBuildEnv,
          templateEnv,
          autoScalingPolicy,
          showCustomAutoScaling,
        } = advanceConfigRecord[service.template?._id!];

        const envVars = templateEnv.map((env: IClusterTemplateVariable) => ({
          key: env.name,
          value: env.defaultValue.trim(),
          isSecret: false,
        }));
        const newBuildEnv = buildEnv.concat(secretBuildEnv).concat(envVars);

        const persistentStorage =
          (customSsdStorage > 0 && customSsdStorage) ||
          (customHddStorage > 0 && customHddStorage) ||
          (customNvmStorage > 0 && customNvmStorage);

        const customPlanSelected: boolean = selectedInstancePlans
          .find((plan: any) => plan.serviceId === service.template?._id!)
          ?.instancePlan?._id.includes('custom-id');

        const customInstanceSpecs: any = customPlanSelected
          ? {
              cpu: Number(customPlan.cpu),
              memory: customPlan.memory,
              storage: `${customStorage.toString()}Gi`,
            }
          : {
              storage: `${customStorage.toString()}Gi`,
            };

        let persistentStorageClass = 'beta2';

        if (customPersistentStorage) {
          switch (customPersistentStorage) {
            case CustomStorageOption.CUSTOMHDDSTORAGE:
              persistentStorageClass = 'beta1';
              break;
            case CustomStorageOption.CUSTOMSSDSTORAGE:
              persistentStorageClass = 'beta2';
              break;
            case CustomStorageOption.CUSTOMNVMSTORAGE:
              persistentStorageClass = 'beta3';
              break;
            default:
              break;
          }
        }
        if (persistentStorage && customPersistentStorage) {
          customInstanceSpecs.persistentStorage = {
            size: `${persistentStorage.toString()}Gi`,
            class: persistentStorageClass,
            mountPoint: mountPointValue.trim(),
          };
        }
        const autoScalingRules =
          clusterScalingName === ScalingTypeName.AUTO && !showCustomAutoScaling
            ? {
                plan: autoScalingPolicy.plan,
                maximumInstances: autoScalingPolicy.maximumInstances,
                minimumInstances: autoScalingPolicy.minimumInstances,
              }
            : {
                ...autoScalingPolicy,
              };

        const machineImageName = selectedInstancePlans.find(
          (plan: any) => plan.serviceId === service.template?._id!
        )?.instancePlan?.name;

        return {
          name: createUsername(service.name),
          tag: service.tag,
          templateId: service.template?._id!,
          image: service.image,
          serviceCount,
          akashMachineImageName: machineImageName,
          ports: mapPortToConfig(portMapping),

          env: newBuildEnv.length !== 0 ? mapBuildEnv(newBuildEnv) : [],
          command:
            buildCommands.length !== 0
              ? buildCommands.map((build: string) => build.trim())
              : [],
          args:
            buildArgs.length !== 0
              ? buildArgs.map((args: string) => args.trim())
              : [],
          customServiceSpecs: customInstanceSpecs,
          autoscalingRules:
            clusterScalingName === ScalingTypeName.AUTO
              ? (autoScalingRules as IAutoscalingRequestPayload)
              : null,
        };
      }),
    };
    dispatchRtk(
      toggleModalShowRtk({
        modalShow: true,
        modalType: 'saveInstanceConfig',
        options: {
          instanceConfig: clusterTemplateConfig,
        },
      })
    );
  };

  useEffect(() => {
    if (debouncedSearchTerm !== null) {
      dispatchRtk(
        getInstancePlanThunk({
          search: debouncedSearchTerm as string,
        })
      );
    }
  }, [debouncedSearchTerm, dispatchRtk]);

  useEffect(() => {
    if (planSearch === '' && searchValue && searchValue.trim() !== '') {
      setSearchValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planSearch]);

  useEffect(() => {
    if (isUpdate && activeDeployment && !activeDeploymentLoading) {
      dispatchRtk(
        selectClusterRegionRtk(activeDeployment?.services[0]?.region || '')
      );
    }
  }, [
    isUpdate,
    dispatchRtk,
    activeDeployment,
    activeDeploymentLoading,
    instancePlans.akashMachineImages?.length,
  ]);

  useEffect(() => {
    dispatchRtk(updateLoadMoreCurrentPageRtk(0));
    return () => {
      dispatchRtk(updateCustomPersistentStorageRtk(''));
      dispatchRtk(updateCustomCpuRtk(''));
      dispatchRtk(updateCustomNvmStorageRtk(0));
      dispatchRtk(updateCustomRamRtk(''));
      dispatchRtk(updateCustomStorageRtk(0));
    };
  }, [dispatchRtk]);

  const configAvailable =
    planAvailable === ResourcesAvailable.NOT_AVAILABLE ||
    storageAvailable === ResourcesAvailable.NOT_AVAILABLE ||
    persistentStorageAvailable === ResourcesAvailable.NOT_AVAILABLE;

  const isManuallyScaled = isUpdate && selectedInstance?.scalable;

  const referralBalance = (spheronWallet?.details as ISpheronWallet)
    ?.referralBalance;

  const creditsBalance = (spheronWallet?.details as ISpheronWallet)
    ?.creditsBalance;
  const balance = clusterWalletUsage?.balance
    ? (clusterWalletUsage?.balance).toFixed(
        config.dollarDecimalRound.DOLLAR_DECIMAL_ROUND
      )
    : 0;

  useEffect(() => {
    if (selectedInstancePlans && selectedInstancePlans.length > 0) {
      let cpuSum = 0;
      let ramSum = 0;
      selectedInstancePlans.forEach((plan: any) => {
        if (plan.instancePlan) {
          cpuSum += Number(plan.instancePlan.cpu);
          ramSum += Number(plan.instancePlan.memory.slice(0, -2));
        }
      });
      setTotalResources({ cpu: cpuSum, ram: ramSum });
    }
  }, [selectedInstancePlans]);

  const insufficientBalanceCheck =
    Object.keys(subscriptionUsage).length > 0 &&
    (subscriptionUsage.cpuLimit - subscriptionUsage.cpu < totalResources.cpu ||
      subscriptionUsage.memoryLimit - subscriptionUsage.memory <
        totalResources.ram);

  const outOfStockMessage = [
    {
      id: 1,
      feedbackCardType: 'info',
      condition: configAvailable,
      heading: 'Out of Stock',
      subHeading:
        'Selected Compute Specifications Unavailable. Please Contact Support for Assistance.',
      buttonLabel: 'Contact Team',
      buttonIcon: <Headphone className="w-5 h-5" />,
      messageClass:
        // eslint-disable-next-line max-len
        'bg-feedback-info-bg dark:bg-dark-feedback-info-bg text-feedback-info-text dark:text-dark-feedback-info-text',
      buttonType: 'primary' as ButtonType,
    },
    {
      id: 2,
      feedbackCardType: 'error',
      condition:
        !clusterWalletUsageLoading &&
        !subscriptionUsageLoading &&
        (Number(balance) <= 0 || insufficientBalanceCheck),
      heading: 'Insufficient Balance: Limited Range',
      // eslint-disable-next-line max-len
      subHeading: `Your balance might not cover plans ranging from ${totalResources.cpu}CPU, ${totalResources.ram}Gi RAM. Supercharge your plan by adding funds.`,
      buttonLabel: 'Go to Billing',
      buttonIcon: <WalletIcon className="text-white w-5 h-5" />,
      messageClass:
        // eslint-disable-next-line max-len
        'bg-feedback-error-bg dark:bg-dark-feedback-error-bg text-feedback-error-text dark:text-dark-feedback-error-text',
      buttonType: 'error' as ButtonType,
      secondaryId: 2.1,
      secondaryButtonLabel: 'View all Range',
      secondaryButtonType: 'ghost' as ButtonType,
    },
    {
      id: 3,
      feedbackCardType: 'info',
      condition:
        clusterScaling === ScalingType.NO &&
        Number((referralBalance + creditsBalance).toFixed(2)) > 0,
      heading: 'Go to On-Demand Scaling',
      subHeading:
        // eslint-disable-next-line max-len
        'The organization has a payment method associated with Spheron Credits. Please note that the provisioning of SPOT compute deployments is restricted when utilizing this payment method.',
      buttonLabel: 'Try On-Demand',
      buttonIcon: <span>🚀</span>,
      messageClass:
        // eslint-disable-next-line max-len
        'bg-feedback-info-bg dark:bg-dark-feedback-info-bg text-feedback-info-text dark:text-dark-feedback-info-text',
      buttonType: 'primary' as ButtonType,
    },
  ];
  const handleButtonClick = (id: number) => {
    switch (id) {
      case 1:
        window.open('https://b4t4v7fj3cd.typeform.com/to/kM6t7oRm');
        break;
      case 2:
        navigate(`/${currentApp}/billing/plan`);
        break;
      case 2.1:
        dispatchRtk(
          toggleModalShowRtk({
            modalShow: true,
            modalType: 'bracketSystemInfo',
          })
        );
        break;
      case 3:
        dispatchRtk(setScalingNameRtk(ScalingTypeName.MANUAL));
        dispatchRtk(setScalingTypeRtk(ScalingType.MANUAL));
        dispatchRtk(resetPersistentValuesRtk());
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setDisabledTabRecord({});
  }, [activeDeployment]);

  const [toggleTabError, setToggleTabError] = useState<boolean>(false);

  const handleDeployDisabled = (serviceId: string, disabled: boolean) => {
    const foundServiceName = activeDeployment?.services.find(
      (service) => service.template?._id === serviceId
    )?.template?.name;
    const tempDisabledRecord = disabledTabRecord;
    tempDisabledRecord[serviceId] = {
      disabled,
      name: foundServiceName || '',
    };
    setDisabledTabRecord(tempDisabledRecord);
    setToggleTabError(!toggleTabError);
  };

  const handleUpdateDisabled = (serviceId: string, disabled: boolean) => {
    const foundServiceName = activeDeployment?.services.find(
      (service) => service.template?._id === serviceId
    )?.template?.name;
    const tempDisabledRecord = disableUpdateRecord;
    tempDisabledRecord[serviceId] = {
      disabled,
      name: foundServiceName || '',
    };
    setDisableUpdateRecord(tempDisabledRecord);
    const allDisabled = Object.values(disableUpdateRecord).every(
      (item) => item.disabled === true
    );
    setUpdateDisabled(allDisabled);
  };

  const tabsServices = useMemo(
    () =>
      activeDeployment?.services?.map((item, index) => {
        const error = instanceDeploymentLoading
          ? false
          : disabledTabRecord[item.template?._id!]?.disabled;
        const tempServiceItem: any = {
          ...item,
          defaultAkashMachineImage: item.agreedMachineImage,
          defaultAkashMachineImageId: item.agreedMachineImage.machineId,
          _id: item.template?._id || '',
          customTemplateSpecs: {
            storage: item.agreedMachineImage.storage,
            persistentStorage: item.agreedMachineImage.persistentStorage!,
          },
        };
        const updatedServiceItem: IServiceData = tempServiceItem;
        return {
          id: index,
          tabId: item.template?._id!,
          serviceItem: updatedServiceItem,
          serviceId: item.template?._id!,
          label: item?.template?.name || `Service ${index + 1}`,
          error,
          leftIcon: error ? (
            <DangerIcon className="w-5 h-5 text-action-error-default dark:text-dark-action-error-default" />
          ) : (
            <TickCircleIcon className="w-5 h-5 text-base-icon dark:text-dark-base-icon" />
          ),
        };
      }) || [
        {
          id: 0,
          label: 'Service 1',
          serviceItem: null,
          serviceId: '0',
        },
      ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeDeployment?.services, toggleTabError]
  );

  const handleSetAdvanceConfig = (advanceConfig: any) => {
    const tempRecord = advanceConfigRecord;
    tempRecord[advanceConfig.serviceId] = advanceConfig;

    setAdvanceConfigRecord(tempRecord);
  };

  return (
    <>
      <div
        className="space-y-2 -my-5 bg-base-bg dark:bg-dark-base-bg
                   sticky top-[116px] z-10"
      >
        <div className="pt-3 grid gap-x-6 grid-cols-3 ll:w-full">
          <div
            className="dark:border-dark-base-border bg-base-fg dark:bg-dark-base-fg rounded-lg
                    px-5 col-span-2"
          >
            <Tabs
              tabList={tabsServices}
              tabListPosition="left"
              selectedTab={selectedTab}
              tabListClassname="pt-4 pb-2 mx-auto text-red"
              onClick={(id: number) => {
                setSelectedTab(id);
                dispatchRtk(setSelectedService(tabsServices[id].id));
              }}
            />
          </div>
          <hr className="border border-base-border dark:border-dark-base-border col-span-2 mt-4" />
        </div>
      </div>

      <div className="grid grid-cols-3 gap-x-6 w-full mt-10">
        <div className="col-span-2 w-full">
          {tabsServices.map((service) => (
            <div className={selectedTab === service.id ? 'block' : 'hidden'}>
              <MultiServicePlanGrid
                key={service.id}
                isUpdate
                id={service.serviceId}
                serviceItem={service.serviceItem}
                selectedInstancePlan={selectedInstancePlans}
                setSelectedInstancePlan={handleInstancePlanClick}
                selectedGpuInstancePlan={selectedInstancePlans}
                setSelectedGpuInstancePlan={setSelectedInstancePlans}
                setDisabled={handleDeployDisabled}
                setUpdateDisabled={handleUpdateDisabled}
                setAdvanceConfig={handleSetAdvanceConfig}
                searchValue={planSearchValue}
                setSearchValue={setPlanSearchValue}
              />
            </div>
          ))}
        </div>

        <div className="z-20">
          {(isManuallyScaled || true) && (
            <Sticky top={120}>
              {selectedInstancePlans &&
              !instancePlansLoading &&
              !recommendedPlanLoading ? (
                <>
                  <MultiServicePriceCard
                    isUpdate
                    updateDisabled={updateDisabled}
                    selectedInstancePlan={selectedInstancePlans}
                    startInstanceDeployment={startInstanceUpdate}
                    disabledRecord={disabledTabRecord}
                    insufficientBalance={insufficientBalanceCheck}
                  />
                  {outOfStockMessage.map((message) => {
                    return (
                      <div className="mt-4 gap-y-4" key={message.id}>
                        {message.condition && (
                          <>
                            <Feedback
                              title={message.heading}
                              subTitle={message.subHeading}
                              feedbackType={
                                message.feedbackCardType as FeedbackType
                              }
                              showClose={false}
                              ctaDetails={{
                                buttonType: message.buttonType,
                                size: 'small',
                                onClick: () => handleButtonClick(message.id),
                                label: message.buttonLabel,
                                leftIcon: message.buttonIcon,
                              }}
                              secondaryCtaDetails={
                                message.secondaryButtonType
                                  ? {
                                      buttonType: message.secondaryButtonType,
                                      size: 'small',
                                      onClick: () =>
                                        handleButtonClick(message.secondaryId),
                                      label: message.secondaryButtonLabel,
                                    }
                                  : undefined
                              }
                            />
                          </>
                        )}
                      </div>
                    );
                  })}
                </>
              ) : (
                <SelectedPlanLoading />
              )}
            </Sticky>
          )}
        </div>
      </div>
    </>
  );
};

export default MultiServiceUpdateSection;
