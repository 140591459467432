import React from 'react';

interface IProps {
  resource: string;
  text: string;
  maxValue: number;
  usedValue: number;
  icon: JSX.Element;
  unit: string | JSX.Element;
}

const Metrics = ({
  resource,
  text,
  maxValue,
  usedValue,
  icon,
  unit,
}: IProps) => {
  return (
    <div
      className="border border-cards-border dark:border-dark-cards-border 
    bg-cards-bg dark:bg-dark-cards-bg rounded-lg p-3"
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-start gap-x-1">
          <span>{icon}</span>
          <p
            className="text-sm text-base-sub-text-color dark:text-dark-base-sub-text-color 
          font-400"
          >
            {resource}
            {text && ` | ${text}`}
          </p>
        </div>
        <div className="text-sm font-semibold">
          {typeof usedValue === 'number'
            ? Number(Number(usedValue).toFixed(2))
            : 'N.A'}{' '}
          /{' '}
          {typeof maxValue === 'number'
            ? Number(Number(maxValue).toFixed(2))
            : 'N.A'}{' '}
          {unit}
        </div>
      </div>
    </div>
  );
};

export default Metrics;
