import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { addCreditCardThunk, removeCreditCardThunk } from './stripe.thunks';
import { ICreditCard } from '../combined-state.interface';
import { IStripeState } from './stripe.interfaces';

export const initialState: IStripeState = {
  savingCreditCard: false,
  fetchingCreditCards: true,
  creditCards: [],
  creditCardsLoading: [],
};

const stripeSlice = createSlice({
  name: 'stripe',
  initialState,
  reducers: {
    updateCreditCardPrimaryPayment: (
      state,
      action: PayloadAction<{ id: string }>
    ) => {
      state.creditCards = state.creditCards.find(
        (card) => card._id === action.payload.id
      )
        ? [
            {
              ...state.creditCards.find(
                (creditCard) => creditCard._id === action.payload.id
              )!!,
              primary: true,
            },
            ...state.creditCards
              .filter((card) => card._id !== action.payload.id)
              .map((card) => {
                return {
                  ...card,
                  primary: false,
                };
              }),
          ]
        : [
            ...state.creditCards.map((card) => {
              return {
                ...card,
                primary: false,
              };
            }),
          ];
    },
    addCreditCard: (state, action: PayloadAction<ICreditCard[]>) => {
      state.creditCards = action.payload;
    },

    toggleFetchingCreditCard: (state, action: PayloadAction<boolean>) => {
      state.fetchingCreditCards = action.payload;
    },
    resetStripeState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addCreditCardThunk.fulfilled, (state) => {
      state.savingCreditCard = false;
    });
    builder.addCase(addCreditCardThunk.pending, (state) => {
      state.savingCreditCard = true;
    });
    builder.addCase(addCreditCardThunk.rejected, (state) => {
      state.savingCreditCard = false;
    });

    builder.addCase(removeCreditCardThunk.pending, (state, action) => {
      state.creditCardsLoading = [
        ...state.creditCardsLoading,
        action.meta.arg.id,
      ];
    });

    builder.addCase(
      removeCreditCardThunk.fulfilled,
      (state, action: PayloadAction<{ id: string }>) => {
        state.creditCardsLoading = state.creditCardsLoading.filter(
          (id) => id !== action.payload.id
        );
        state.creditCards = state.creditCards.filter(
          (card) => card._id !== action.payload.id
        );
      }
    );

    builder.addCase(removeCreditCardThunk.rejected, (state, action) => {
      state.creditCardsLoading = state.creditCardsLoading.filter(
        (id) => id !== action.meta.arg.id
      );
    });
  },
});

export const { reducer: stripeRTKReducer } = stripeSlice;

export const {
  updateCreditCardPrimaryPayment,
  addCreditCard: addCreditCardRTK,
  toggleFetchingCreditCard: toggleFetchingCreditCardRTK,
  resetStripeState: resetStripeStateRTK,
} = stripeSlice.actions;
