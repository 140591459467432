/* eslint-disable max-len */
import React, { useMemo } from 'react';
import { Dropdown, Navbar, OptionType } from '@spheron/ui-library';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import Logo from '../../../assets/global/spheron-logo-two.svg';
import DarkLogo from '../../../assets/global/spheron-logo-two-dark.svg';
import GlobalStyle from '../../../styles/compute/global.module.scss';
import { logoutUserRtk } from '../../../redux/compute/user/user.slice';
import { isDarkTheme } from '../../../redux/compute/theme/theme.utils';

interface IProps {
  children?: JSX.Element;
}

interface IMenuDropdownItem {
  label: string;
  id: number;
  handleClick: () => void;
  optionType: OptionType;
  borderBottom?: boolean;
}

const NavAfterLogin = ({ children }: IProps) => {
  const navigate = useNavigate();
  const dispatchRtk = useDispatch<AppDispatch>();
  const userLoading = useSelector((state: RootState) => state.user.userLoading);
  const theme = useSelector((state: RootState) => state.theme.theme);
  const user = useSelector((state: RootState) => state.user.user);

  const userMenuDropdownItems: IMenuDropdownItem[] = [
    {
      id: 1,
      label: 'Docs',
      handleClick: () => window.open('https://docs.spheron.network'),
      optionType: 'primary' as OptionType,
    },
    {
      id: 2,
      label: 'Report Bugs',
      handleClick: () =>
        window.open('https://github.com/spheronFdn/beta-testing'),
      optionType: 'primary' as OptionType,
    },
    {
      id: 3,
      label: 'Blogs',
      handleClick: () => window.open('https://spheron.medium.com/'),
      optionType: 'primary' as OptionType,
    },
    {
      id: 4,
      label: 'Contact Us',
      handleClick: () => window.open('https://discord.com/invite/ahxuCtm'),
      optionType: 'primary' as OptionType,
      borderBottom: true,
    },
    {
      id: 5,
      label: 'Logout',
      handleClick: () => {
        dispatchRtk(logoutUserRtk());
        navigate('/login');
      },
      optionType: 'primary' as OptionType,
    },
  ];

  const isJwtPresent = useMemo(() => {
    return !!localStorage.getItem('jwt-token');
  }, []);

  return (
    <>
      <Navbar
        logoIcon={
          <img
            src={isDarkTheme(theme) ? DarkLogo : Logo}
            alt="logo"
            className="w-[116px]"
          />
        }
        infoElements={
          <div className="flex flex-1 justify-end items-center space-x-5">
            {isJwtPresent && (
              <div className="lg:ml-6 hidden lg:flex">
                {!userLoading && user ? (
                  <Dropdown
                    dropdownType="button"
                    dropdownSize="default"
                    buttonImage={
                      <img
                        src={user?.platformProfile.avatar}
                        alt="User"
                        className={`${GlobalStyle.profile__pic} rounded-full`}
                      />
                    }
                    bordersNone
                    options={userMenuDropdownItems}
                  />
                ) : (
                  <Skeleton
                    width={40}
                    height={40}
                    borderRadius={9999}
                    duration={2}
                    containerClassName="flex"
                  />
                )}
              </div>
            )}
          </div>
        }
      />
      {children}
    </>
  );
};
NavAfterLogin.defaultProps = {
  children: <></>,
};
export default NavAfterLogin;
