import React, { useState } from 'react';
import { Badge } from '@spheron/ui-library';
import { ReactComponent as ExternalLinkIcon } from '@spheron/ui-library/dist/assets/spheron-specific/external-link.svg';
import { ReactComponent as Check } from '../../../../../assets/compute/icons/checkbox-template-card.svg';
import Styles from '../../../../../styles/compute/compute-pricing.module.scss';
import { IDiscount } from '../../../../../redux/compute/combined-state.interface';

interface IProps {
  id?: string;
  title: string;
  imageShow: boolean;
  image: string;
  badgeContent: string;
  subtitle: string;
  isSelected: boolean;
  onSelect: () => void;
  isSelectedTemplate: boolean;
  discount?: IDiscount | null;
  computeProjectId?: string;
}

const TemplateCard = ({
  id,
  title,
  image,
  badgeContent,
  subtitle,
  imageShow,
  isSelected,
  onSelect,
  isSelectedTemplate,
  discount,
  computeProjectId,
}: IProps) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handleClick = () => {
    onSelect();
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <button
      type="button"
      tabIndex={0}
      className={`border relative rounded-lg px-3 py-4 
        w-full flex items-center cursor-pointer dark:bg-dark-base-bg 
        dark:border-dark-base-border min-h-12 text-left
        hover:border-form-selected dark:hover:border-dark-form-selected duration-75 ease-in
        ${
          isSelected
            ? 'bg-base-fg border-form-selected dark:border-dark-form-selected'
            : 'border-base-border bg-white dark:border-dark-base-border'
        }`}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="flex items-center max-w-full gap-x-2">
        {imageShow && (
          <img
            className="max-w-100 max-h-4 rounded-3xl"
            src={image}
            alt="icon"
          />
        )}
        <div
          className={`flex flex-col items-start max-w-full truncate ${
            !imageShow ? 'text-left' : ''
          }`}
        >
          <div
            className="text-neutral-1 text-base font-semibold dark:text-white 
               flex flex-row items-center gap-2 max-w-full "
          >
            <span className="flex-1 truncate w-min">{title}</span>
            <div className="flex flex-row gap-2 items-center">
              {(isSelected && isSelectedTemplate) ||
              (isHovered && isSelectedTemplate) ? (
                <ExternalLinkIcon
                  role="presentation"
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(
                      `/marketplace?template=${title}&templateId=${id}&projectId=${computeProjectId}`,
                      '_blank'
                    );
                  }}
                  className="w-5 h-5 text-base-icon dark:text-dark-base-icon hover:text-form-selected"
                />
              ) : null}
            </div>
          </div>
          {subtitle.length > 0 && (
            <span className="text-sm font-normal">{subtitle}</span>
          )}
        </div>
      </div>

      <div className="absolute right-1.5 bottom-1 flex flex-row gap-x-1">
        {discount &&
          new Date().getTime() <
            new Date(discount.expirationDate).getTime() && (
            <Badge
              badgeType="error"
              size="small"
              solid
              text={`${discount.discountPercent}% off`}
            />
          )}
        {badgeContent.length > 0 && (
          <Badge
            badgeType="default"
            size="small"
            solid={false}
            text={badgeContent}
          />
        )}
      </div>

      <>
        {isSelected && (
          <span className="absolute right-1 top-1">
            <Check className={Styles.check__icon} />
          </span>
        )}
      </>
    </button>
  );
};

export default TemplateCard;

TemplateCard.defaultProps = {
  id: '',
  discount: null,
  computeProjectId: '',
};
