import React from 'react';

interface IProps {
  icon?: string;
  iconElement?: JSX.Element;
  className?: string;
  iconClassName?: string;
}

const BlueIconContainer = ({
  icon,
  className,
  iconClassName,
  iconElement,
}: IProps) => {
  return (
    <div
      className={`${className} bg-base-fg dark:bg-dark-base-fg
       flex items-center justify-center rounded-full p-1`}
    >
      {!!iconElement && (
        <div
          className={`flex items-center justify-center
      ${iconClassName}`}
        >
          {iconElement}
        </div>
      )}
      {!!icon && <img src={icon} alt="icon" className={`${iconClassName}`} />}
    </div>
  );
};

BlueIconContainer.defaultProps = {
  className: '',
  iconClassName: 'h-6 w-6',
  icon: '',
  iconElement: null,
};

export default BlueIconContainer;
