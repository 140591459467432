import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Button, EmptyState, Table } from '@spheron/ui-library';
import { ReactComponent as NoDepositIcon } from '@spheron/ui-library/dist/assets/request-money.svg';
import { ReactComponent as Chevron } from '@spheron/ui-library/dist/assets/arrow-right-3.svg';
import { ReactComponent as DownArrow } from '@spheron/ui-library/dist/assets/arrow-down-1.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  IOrganisationWallet,
  IPaymentHistoryEnum,
  ITransaction,
} from '../../../redux/compute/web3/web3.interfaces';
import { ReactComponent as BankLineCard } from '../../../assets/compute/icons/bank-card-line.svg';
import TailwindDropdown from '../Dropdown/tailwind-drop-down';
import {
  getDate,
  getDecimalFromTokenAddress,
  getHash,
  getNetworkIcon,
  getTokenFromSymbol,
  getTokenNameFromAddress,
  isNonSubgraphChain,
  networks,
  shortenString,
  shortenTxHash,
} from '../../../redux/compute/web3/web3.utils';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import NetworkTokenSet from '../Misc/network-token-set';
import { withLoader } from '../../../redux/compute/root-utils';
import { ICurrentApp } from '../../../redux/compute/organisation/organisation.interfaces';

interface IProps {
  transactions: ITransaction[];
  type: IPaymentHistoryEnum;
  selectedWallet: IOrganisationWallet;
  // eslint-disable-next-line no-unused-vars
  setSelectedWallet: (wallet: IOrganisationWallet) => void;
}
const PaymentHistoryTable = ({
  transactions,
  type,
  selectedWallet,
  setSelectedWallet,
}: IProps) => {
  const isPublicAccessible =
    sessionStorage.getItem('isPublicAccessible') === 'true';
  const communityUserAccess =
    sessionStorage.getItem('communityUserAccess') === 'true';
  const dispatchRtk = useDispatch<AppDispatch>();

  const paymentHistoryLoading = useSelector(
    (state: RootState) => state.web3.paymentHistoryLoading
  );

  const web3Wallets = useSelector(
    (state: RootState) => state.web3.wallets
  ).filter(
    (wallet) =>
      wallet.details.networkDetails.chainName !== 'solana' &&
      wallet.details.networkDetails.chainName !== 'filecoin'
  );

  const selectedOrganisationLoading: boolean = useSelector(
    (state: RootState) => state.organisation?.selectedOrganisationLoading
  );

  const web3WalletsLoading = useSelector(
    (state: RootState) => state.web3.walletLoading
  );

  const allUsers = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation.users
  );
  const user = useSelector((state: RootState) => state.user.user);

  const currentApp = useSelector(
    (state: RootState) => state.organisation.currentApp
  );

  const isMember =
    allUsers?.find((u) => u?._id === user?._id)?.platformProfile?.role ===
      'member' && user !== null;

  const handleWalletConnect = () => {
    dispatchRtk(
      toggleModalShowRtk({
        modalShow: true,
        modalType: 'connectWalletParameters',
      })
    );
  };

  const shouldShowDropdown =
    web3Wallets.filter(
      (wallet: IOrganisationWallet) =>
        wallet._id !== selectedWallet?._id && !isNonSubgraphChain(wallet)
    )?.length > 0;

  const dropdownButton = (showArrow: boolean) => {
    return (
      <div
        className="py-1.5 px-2 border border-base-border rounded-lg dark:border-dark-base-border
            font-medium flex items-center justify-start text-sm gap-x-2
           dark:bg-dark-base-bg w-min"
      >
        <BankLineCard />
        <div className=" flex items-center">
          {shortenString(selectedWallet?.details.address || '')}{' '}
        </div>
        {showArrow && <Chevron className="ml-2 w-4 h-4 transform rotate-90" />}
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-y-4">
      {selectedWallet && !isNonSubgraphChain(selectedWallet) && (
        <div className="flex items-center justify-between">
          {shouldShowDropdown ? (
            <TailwindDropdown
              label={dropdownButton(true)}
              listItems={web3Wallets
                .filter(
                  (wallet: IOrganisationWallet) =>
                    wallet._id !== selectedWallet?._id &&
                    !isNonSubgraphChain(wallet)
                )
                .map((wallet: IOrganisationWallet) => {
                  return {
                    label: shortenString(wallet.details.address),
                    id: Number(wallet._id),
                    handleClick: () => setSelectedWallet(wallet),
                    icon: (
                      <NetworkTokenSet
                        network={wallet.details.networkDetails.logo}
                        token={wallet.details.tokenDetails.logoURI}
                      />
                    ),
                  };
                })}
            />
          ) : (
            <>{dropdownButton(false)}</>
          )}
          <div className="flex items-center space-x-2">
            <div className="flex items-center space-x-2">
              {!web3WalletsLoading && web3Wallets.length === 0 && (
                <Button
                  buttonType="primary"
                  label="Attach Wallet"
                  size="medium"
                  onClick={() =>
                    dispatchRtk(
                      toggleModalShowRtk({
                        modalShow: true,
                        modalType: 'connectWalletParameters',
                      })
                    )
                  }
                  disabled={
                    isMember || (isPublicAccessible && !communityUserAccess)
                  }
                />
              )}
              {withLoader(
                paymentHistoryLoading || web3WalletsLoading,
                <div className="flex items-center justify-start gap-x-2">
                  <Skeleton
                    height={40}
                    width={150}
                    duration={2}
                    borderRadius={4}
                  />
                  <Skeleton
                    height={40}
                    width={150}
                    duration={2}
                    borderRadius={4}
                  />
                </div>,
                <>
                  {!paymentHistoryLoading &&
                    !web3WalletsLoading &&
                    web3Wallets.length !== 0 &&
                    //  Disabled Web3 Payment for Compute Services
                    currentApp !== ICurrentApp.COMPUTE && (
                      <>
                        <Button
                          buttonType="ghost"
                          label="Deposit"
                          size="medium"
                          onClick={() =>
                            dispatchRtk(
                              toggleModalShowRtk({
                                modalShow: true,
                                modalType: 'funds',
                                options: {
                                  type: 'deposit',
                                  walletId: selectedWallet?._id,
                                },
                              })
                            )
                          }
                          leftIcon={<DownArrow className="w-5 h-5" />}
                          disabled={isMember}
                        />
                        {/* <Button
                          buttonType="ghost"
                          label="Withdraw"
                          size="medium"
                          onClick={() =>
                            dispatchRtk(
                              toggleModalShowRtk({
                                modalShow: true,
                                modalType: 'funds',
                                options: {
                                  type: 'withdraw',
                                  walletId: selectedWallet?._id,
                                },
                              })
                            )
                          }
                          rightIcon={
                            <DownArrow className="transform rotate-180 w-5 h-5" />
                          }
                          disabled={isMember}
                        /> */}
                      </>
                    )}
                </>
              )}
            </div>
          </div>
        </div>
      )}

      {withLoader(
        paymentHistoryLoading ||
          web3WalletsLoading ||
          selectedOrganisationLoading,
        <Skeleton height={200} />,
        <>
          {web3Wallets.length === 0 ? (
            <div className="h-72">
              <EmptyState
                entityTitle={`You don't have any ${type} yet`}
                icon={<NoDepositIcon className="w-48 h-48" />}
                showButton={currentApp !== ICurrentApp.COMPUTE}
                buttonText="Attach Wallet"
                buttonDisabled={
                  isMember || (isPublicAccessible && !communityUserAccess)
                }
                onClick={handleWalletConnect}
              />
            </div>
          ) : (
            <Table
              tableHeader={{
                tableRowType: 'record',
                tableRow: [
                  {
                    id: 0,
                    title: 'Transaction',
                    tableCellType: 'header',
                  },
                  {
                    id: 1,
                    title: 'Method',
                    tableCellType: 'header',
                  },
                  {
                    id: 2,
                    title: 'Amount',
                    tableCellType: 'header',
                  },
                  {
                    id: 3,
                    title: 'Creation Date',
                    tableCellType: 'header',
                  },
                ],
              }}
              tableRows={transactions
                ?.filter(
                  (transaction: ITransaction) =>
                    transaction.token.toLowerCase() ===
                    selectedWallet?.details?.tokenDetails?.address?.toLowerCase()
                )
                ?.map((transaction: ITransaction) => ({
                  id: 1,
                  tableRowType: 'record',
                  tableRow: [
                    {
                      id: 0,
                      content: (
                        <a
                          href={`${
                            networks[
                              Number(transaction.wallet.networkDetails!.chainId)
                            ].blockExplorerUrls[0]
                          }tx/${
                            type === IPaymentHistoryEnum.DEPOSIT
                              ? getHash(12, 78, transaction.id)
                              : getHash(13, 79, transaction.id)
                          }`}
                          target="_blank"
                          rel="noreferrer"
                          className="  text-sm cursor-pointer underline"
                        >
                          {type === IPaymentHistoryEnum.DEPOSIT
                            ? shortenTxHash(12, 78, transaction.id)
                            : shortenTxHash(13, 79, transaction.id)}
                        </a>
                      ),
                      tableCellType: 'default',
                    },
                    {
                      id: 1,
                      content: (
                        <NetworkTokenSet
                          token={getTokenFromSymbol(
                            transaction.wallet.networkDetails!.chainName,
                            getTokenNameFromAddress(transaction.token)
                          )}
                          network={getNetworkIcon(
                            Number(transaction.wallet.networkDetails?.chainId)
                          )}
                        />
                      ),
                      tableCellType: 'default',
                    },
                    {
                      id: 2,
                      title: `${
                        Number(transaction.amount) /
                        10 ** getDecimalFromTokenAddress(transaction.token)
                      }${' '}
                              ${getTokenNameFromAddress(transaction.token)}`,
                      tableCellType: 'title',
                    },
                    {
                      id: 3,
                      title: getDate(transaction.createdAt),
                      tableCellType: 'title',
                    },
                  ],
                }))}
            />
          )}
        </>
      )}
    </div>
  );
};

export default PaymentHistoryTable;
