/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Badge,
  Button,
  HealthCheck,
  InstanceCardScalingType,
  Link,
  Refresh,
  ServiceDetailsCard,
  TextInput,
} from '@spheron/ui-library';
import { ReactComponent as ExportIcon } from '@spheron/ui-library/dist/assets/spheron-specific/export.svg';
import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import dayjs from 'dayjs';
import { ReactComponent as MemoryIcon } from '@spheron/ui-library/dist/assets/compute/ram.svg';
import { ReactComponent as CPUIcon } from '@spheron/ui-library/dist/assets/compute/cpu.svg';
import { ReactComponent as ExternalIcon } from '@spheron/ui-library/dist/assets/spheron-specific/external-link.svg';
// eslint-disable-next-line max-len
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as DockerCredentialIcon } from '../../../../assets/compute/icons/docker-credential-icon.svg';
import Metrics from '../../../../components/Compute/Cards/metrics';
import PortPolicyTable from '../../../../components/Compute/Misc/port-policy-table';
import LogsContainer from '../../../../components/Compute/Cards/logs-container';
import { RootState, AppDispatch } from '../../../../redux/compute/store';
import {
  getHealthCheckStatus,
  getServiceStatus,
  isHyperlink,
} from '../../../../redux/compute/service/service.utils';
import { getScalingType } from '../../../../redux/compute/instance/instance.utils';
import {
  InstanceEvent,
  InstanceLogs,
} from '../../../../redux/compute/instance/instance.interfaces';
import { withLoader } from '../../../../redux/compute/root-utils';
import MetricsLoading from '../../../../components/Compute/Loaders/metrics-loading';
import ServiceDetailsCardLoading from '../../../../components/Compute/Loaders/service-details-card-loading';
import { sliceStringFromEnd } from '../../../../redux/compute/subscription/subscription.utils';
import { ReactComponent as SearchIcon } from '../../../../assets/compute/icons/search.svg';
import { getDeploymentLogsService } from '../../../../redux/compute/instance/instance.services';
import { useDebounce } from '../../../../hooks/useDebounce';
import {
  setServiceLogs,
  toggleSelectedServiceLogsLoading,
} from '../../../../redux/compute/service/service.slice';
import { loadMoreServiceLogsThunk } from '../../../../redux/compute/service/service.thunks';
import {
  fetchInstanceEventsThunk,
  getInstanceMetricsChartThunk,
} from '../../../../redux/compute/instance/instance.thunks';
import { IInstance } from '../../../../redux/compute/project/project.interfaces';
import { setFetchInstanceEventType } from '../../../../redux/compute/instance/instance.slice';
import { IPLeaseType } from '../../../../redux/compute/instance/instance-creation/instance-creation.interfaces';

const ServiceOverview = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const componentIsMounted = useRef(true);
  const totalSelectedServiceLogs = useSelector(
    (state: RootState) => state.computeService.logsLength
  );
  const selectedServiceLogs = useSelector(
    (state: RootState) => state.computeService.serviceLogs
  );
  const activeDeployment = useSelector(
    (state: RootState) => state.instance.activeDeployment
  );
  const activeDeploymentLoading = useSelector(
    (state: RootState) => state.instance.activeDeploymentLoading
  );
  const service = useSelector(
    (state: RootState) => state.computeService.selectedService
  );
  const serviceLoading = useSelector(
    (state: RootState) => state.computeService.selectedServiceLoading
  );
  const selectedInstance = useSelector(
    (state: RootState) => state.instance.selectedInstance
  );
  const metricsChart = useSelector(
    (state: RootState) => state.instance.metricsChart
  );
  const metricChartLoading = useSelector(
    (state: RootState) => state.instance.metricsChartLoading
  );
  const serviceLoadMoreLogsLoading = useSelector(
    (state: RootState) => state.computeService.loadMoreLogsLoading
  );
  const logsLastUpdated = useSelector(
    (state: RootState) => state.computeService.logsLastUpdated
  );
  const serviceLogsLoading = useSelector(
    (state: RootState) => state.computeService.serviceLogsLoading
  );

  const [searchValue, setSearchValue] = useState<string>('');
  const [searching, setSearching] = useState<boolean>(false);

  const isDeployed =
    activeDeployment?.status?.toLowerCase() === 'deployed' ||
    activeDeployment?.status?.toLowerCase() === 'active';

  const showPortPolicy =
    isDeployed && service?.ports && service?.ports.length > 0;

  const showHealthCheck =
    !serviceLoading &&
    service?.healthCheck &&
    Object.keys(service?.healthCheck.port).length > 0;

  const trimmedHost = activeDeployment?.protocolData?.providerHost?.trim();

  const debouncedSearchTerm = useDebounce(searchValue, 750);

  const searchUsedRef = useRef<boolean>(false);

  useEffect(() => {
    if (service && activeDeployment && searchUsedRef.current) {
      setSearching(true);
      getDeploymentLogsService(
        activeDeployment._id,
        InstanceLogs.COMPUTE_LOGS,
        1,
        debouncedSearchTerm as string,
        service.name
      )
        .then((response: any) => {
          if (componentIsMounted.current) {
            dispatch(toggleSelectedServiceLogsLoading(false));
            dispatch(
              setServiceLogs({
                logs: response.deployment.logs,
                logsLength: response.deployment.logsLength,
              })
            );
          }
          setSearching(false);
        })
        .catch(() => {
          if (componentIsMounted.current) {
            setSearching(false);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const metricsRefresh = async () => {
    if (selectedInstance?._id) {
      dispatch(
        getInstanceMetricsChartThunk({
          serviceName: params?.serviceId || '',
          instanceId: selectedInstance?._id,
          monitoringPointTime: '5m',
          monitoringTime: 10,
          monitoringType: 'average',
        })
      );
    }
  };

  const handleEventLogs = (type: InstanceEvent) => {
    if (selectedInstance && activeDeployment) {
      dispatch(setFetchInstanceEventType(null));
      dispatch(
        fetchInstanceEventsThunk({
          instanceId: selectedInstance?._id!,
          type,
          topicId: activeDeployment?.topic!,
        })
      );
    }
  };

  return (
    <div className="flex flex-col gap-y-10">
      <div className="flex flex-col gap-y-3">
        {service && showHealthCheck && (
          <HealthCheck
            state={getHealthCheckStatus(service?.healthCheck?.status || '')}
            refreshTime={dayjs(service?.healthCheck.timestamp).fromNow()}
            onClick={() => {}}
            loading={false}
          />
        )}
        {withLoader(
          serviceLoading,
          <ServiceDetailsCardLoading />,
          <>
            {!serviceLoading && service && (
              <ServiceDetailsCard
                tag={service.tag}
                planDetails={[
                  {
                    label: 'CPU',
                    value: String(service.agreedMachineImage.cpu),
                  },
                  {
                    label: 'Memory',
                    value: service.agreedMachineImage.memory,
                  },
                  {
                    label: 'Storage',
                    value: service.agreedMachineImage.storage,
                  },
                  ...(Object.keys(
                    service.agreedMachineImage.persistentStorage || {}
                  ).length
                    ? [
                        {
                          label: 'Persistent Storage',
                          value:
                            service.agreedMachineImage.persistentStorage
                              ?.size || '',
                        },
                      ]
                    : []),
                  ...(service.ports.some(
                    (port) => port.endpoint || port.type === IPLeaseType.STATIC
                  )
                    ? [
                        {
                          label: 'Dedicated IP',
                          value: '',
                        },
                      ]
                    : []),
                ]}
                handleIconClick={() => {
                  if (isHyperlink(service.image)) {
                    window.open(`https://${service.image}`, '_blank');
                  } else {
                    window.open(
                      `https://hub.docker.com/r/${service.image}`,
                      '_blank'
                    );
                  }
                }}
                isActive
                onClick={() => {}}
                serviceName={service?.viewName}
                sourceName={service?.image}
                deploymentType={getScalingType(
                  service as any,
                  selectedInstance
                )}
                status={getServiceStatus(
                  String(
                    activeDeployment?.status?.toLowerCase() ||
                      selectedInstance?.state?.toLowerCase()
                  )
                )}
                planName={service?.agreedMachineImage.machineType}
                replica={service?.serviceCount}
                icon={
                  service?.template ? (
                    <img
                      className="rounded-full w-8 h-8"
                      src={service.template.metadata.icon}
                      alt={service.template.name}
                    />
                  ) : (
                    <DockerCredentialIcon className="rounded-full w-8 h-8" />
                  )
                }
                discountBadgeText={
                  service?.discount
                    ? `${service.discount.discountPercent}% OFF`
                    : ''
                }
                planPrice={`$${String(
                  (
                    service?.agreedMachineImage?.defaultDailyTopUp * 30
                  )?.toFixed(2)
                )}/month`}
                planPriceSecond={`$${String(
                  (
                    service?.agreedMachineImage?.defaultDailyTopUp / 24
                  )?.toFixed(2)
                )}/hour`}
              />
            )}
          </>
        )}
      </div>
      {isDeployed && (
        <div className="flex flex-col gap-y-3">
          <div className="flex flex-row justify-between">
            <div className="flex items-center justify-start gap-x-2">
              {withLoader(
                !activeDeployment || serviceLoading || !service,
                <Skeleton
                  height={16}
                  width={70}
                  duration={2}
                  containerClassName="my-0"
                />,
                <>
                  <h5
                    className="text-base-heading-text-color dark:text-dark-base-heading-text-color
        text-xs font-semibold leading-3 uppercase"
                  >
                    Metrics
                  </h5>
                  <Link
                    type="secondary"
                    size="small"
                    text="View Detailed Metrics"
                    isBold={false}
                    onClick={() =>
                      navigate(
                        // eslint-disable-next-line max-len
                        `/compute/${params.projectId}/${params.instanceId}/service/${params.serviceId}/metrics`
                      )
                    }
                    rightIcon={
                      <ExternalIcon className="text-base-para-text-color dark:text-dark-base-para-text-color" />
                    }
                  />
                  <div>
                    <Badge
                      badgeType="error"
                      size="medium"
                      solid={false}
                      text="BETA"
                    />
                  </div>
                </>
              )}
            </div>
            {activeDeployment &&
              isDeployed &&
              (selectedInstance as IInstance).computeType !==
                InstanceCardScalingType.SPOT && (
                <div>
                  <Refresh
                    showText={false}
                    handleClick={() => {
                      metricsRefresh();
                    }}
                    loading={metricChartLoading}
                    refreshType="default"
                    time=""
                    customText={
                      metricsChart.length
                        ? `Updated ${dayjs(
                            metricsChart[metricsChart.length - 1].time
                          ).fromNow()}`
                        : 'Refresh'
                    }
                  />
                </div>
              )}
          </div>
          <div className="grid grid-cols-2 gap-x-6 gap-y-4">
            {withLoader(
              !activeDeployment ||
                serviceLoading ||
                !service ||
                (metricChartLoading && metricsChart?.length > 0),
              <>
                <MetricsLoading />
                <MetricsLoading />
              </>,
              <>
                <Metrics
                  resource="CPU"
                  text="Monitor Instance Usage"
                  maxValue={Number(service?.agreedMachineImage.cpu)}
                  usedValue={metricsChart[0]?.totalCpu}
                  icon={
                    <CPUIcon className="text-base-icon dark:text-dark-base-icon" />
                  }
                  unit="CPU"
                />
                <Metrics
                  resource="Memory"
                  text="Monitor Instance Usage"
                  maxValue={Number(
                    sliceStringFromEnd(
                      service?.agreedMachineImage.memory || '',
                      2
                    )
                  )}
                  usedValue={metricsChart[0]?.totalMemory}
                  icon={
                    <MemoryIcon className="text-base-icon dark:text-dark-base-icon" />
                  }
                  unit="GB"
                />
              </>
            )}
          </div>
        </div>
      )}

      {showPortPolicy && (
        <div className="flex flex-col gap-y-3">
          <div className="flex items-center justify-start gap-x-2">
            <h5
              className="text-base-heading-text-color dark:text-dark-base-heading-text-color
        text-xs font-semibold leading-3 uppercase"
            >
              PORT POLICY
            </h5>
          </div>
          <div>
            <PortPolicyTable
              tableRows={
                service?.ports.map((port) => {
                  const trimmedExposedPort: string = port?.exposedPort
                    ?.toString()
                    ?.trim();
                  const trimmedIpPort = port?.ip?.trim();
                  /* eslint-disable no-nested-ternary */
                  const hostConnectionUrl =
                    trimmedIpPort && port.endpoint
                      ? `http://${trimmedIpPort}:${trimmedExposedPort}`
                      : port.type === IPLeaseType.STATIC
                      ? `http://${port?.url}:${trimmedExposedPort}`
                      : port.global && trimmedHost
                      ? `http://${trimmedHost}:${trimmedExposedPort}`
                      : '';
                  const connectionUrl =
                    trimmedExposedPort === '80' && !port?.ip
                      ? `http://${service.urlPreview || port?.url}`
                      : hostConnectionUrl;
                  return {
                    id: String(port.containerPort),
                    internalPort: port.containerPort,
                    externalPort: trimmedExposedPort,
                    isPublic: port.global,
                    exposedTo:
                      (port?.exposeTo || [])?.length > 0
                        ? (port.exposeTo || []).join(',')
                        : 'Global',
                    connectionUrl,
                  };
                }) || []
              }
            />
          </div>
        </div>
      )}
      {service && (
        <div className="flex flex-col gap-y-3">
          <h5
            className="text-base-heading-text-color dark:text-dark-base-heading-text-color
        text-xs font-semibold leading-3 uppercase"
          >
            Service Logs
          </h5>
          <div
            className="flex flex-row justify-between items-center 
                  text-gray-600 dark:text-gray-300 
                  sticky top-0 w-full"
          >
            <div className="flex flex-row items-center gap-x-3">
              {withLoader(
                activeDeploymentLoading,
                <Skeleton height={42} width={600} duration={2} />,
                <div className="w-[600px]">
                  <TextInput
                    inputSize="compact"
                    leftIcon={<SearchIcon className="text-inherit" />}
                    className="w-full"
                    value={searchValue}
                    onChange={(value) => {
                      setSearchValue(value as string);
                      searchUsedRef.current = true;
                    }}
                    placeholder="Search Logs"
                    disabled={searching}
                  />
                </div>
              )}
              {withLoader(
                activeDeploymentLoading,
                <Skeleton height={42} width={111} duration={2} />,
                <Button
                  buttonType="ghost"
                  label="Export"
                  size="small"
                  onClick={() => handleEventLogs(InstanceEvent.DOWNLOAD_LOGS)}
                  disabled={!isDeployed}
                  leftIcon={
                    <ExportIcon className="w-6 h-6 text-action-ghost-text dark:text-dark-action-ghost-text" />
                  }
                  leftIconClassname="!w-6 !h-6"
                />
              )}
            </div>

            {withLoader(
              activeDeploymentLoading,
              <Skeleton height={24} width={200} duration={2} />,
              <>
                {isDeployed && (
                  <Refresh
                    showText={!!logsLastUpdated}
                    handleClick={() => {
                      handleEventLogs(InstanceEvent.LOGS);
                    }}
                    loading={false}
                    refreshType="default"
                    time=""
                    customText={`Updated ${dayjs(logsLastUpdated).fromNow()}`}
                  />
                )}
              </>
            )}
          </div>
          <LogsContainer
            isLogAvailable={
              !!activeDeployment && selectedServiceLogs?.length > 0
            }
            displayLoadMore={
              selectedServiceLogs?.length < totalSelectedServiceLogs
            }
            handleLoadMoreClick={() =>
              dispatch(
                loadMoreServiceLogsThunk({
                  deploymentId: activeDeployment?._id || '',
                  type: InstanceLogs.COMPUTE_LOGS as any,
                  search: '',
                  serviceName: service?.name,
                })
              )
            }
            loadMoreLoading={serviceLoadMoreLogsLoading}
            logsLoading={serviceLogsLoading}
            placeholderText="Wait for logs to appear"
            logs={selectedServiceLogs.join(' \n')}
          />
        </div>
      )}
    </div>
  );
};

export default ServiceOverview;
