import React, { useEffect, useState } from 'react';
import { Tab } from '@headlessui/react';
import classNames from 'classnames';
import { SectionHeading } from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/compute/store';
import InstanceDomains from '../InstanceDomains';
import InstanceSubdomains from '../InstanceSubdomains';
import { InstanceStatus } from '../../../../redux/compute/instance/instance.interfaces';
import { addNotificationRtk } from '../../../../redux/compute/notification/notification.slice';
import { NotificationType } from '../../../../redux/compute/notification/notification.interfaces';

interface IInstanceDomainTags {
  id: number;
  title: string;
  value: string;
}

const InstanceDomainMainView = () => {
  const dispatch = useDispatch<AppDispatch>();
  const activeDeployment = useSelector(
    (state: RootState) => state.instance.activeDeployment
  );
  const [selectedTab, setSelectedTab] = useState<number>(0);

  useEffect(() => {
    if (
      activeDeployment &&
      activeDeployment?.status !== InstanceStatus.DEPLOYED &&
      activeDeployment?.status !== InstanceStatus.FAILED &&
      activeDeployment?.status !== InstanceStatus.DEPRECATED
    ) {
      dispatch(
        addNotificationRtk({
          message:
            'To attach a new domain or subdomain, you must wait for the provision of the instance.',
          timestamp: Date.now(),
          type: NotificationType.Info,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDeployment]);

  const instanceDomainTags: IInstanceDomainTags[] = [
    {
      id: 0,
      title: 'Domains',
      value: 'domains',
    },
    {
      id: 1,
      title: 'Subdomains',
      value: 'subdomains',
    },
  ];

  return (
    <>
      <SectionHeading
        showRefresh={false}
        showSwitch={false}
        subHeading={''}
        heading="Configure Domains"
        handleIconClick={() => {}}
        handleRefreshClick={() => {}}
        handleSwitchClick={() => {}}
        switchDisable={false}
        handleClick={() => {}}
        loading={false}
        time={''}
        refreshType={'default'}
        showText={false}
        toggleId={''}
        isChecked={false}
        handleChange={() => {}}
      />
      <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
        <Tab.List className="flex items-center justify-start gap-x-8 mt-4">
          {instanceDomainTags.map((tab) => (
            <Tab
              key={tab.id}
              className={({ selected }) =>
                classNames(
                  'w-min text-sm font-semibold leading-6 focus:outline-none',
                  selected
                    ? `border-b-2 border-form-selected text-base-para-text-color 
                    dark:text-dark-base-para-text-color`
                    : 'text-base-sub-text-color dark:text-dark-base-sub-text-color'
                )
              }
            >
              {tab.title}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel className="mt-5">
            <InstanceDomains />
          </Tab.Panel>
          <Tab.Panel className="mt-5">
            <InstanceSubdomains />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </>
  );
};

export default InstanceDomainMainView;
