/* eslint-disable import/no-cycle */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  // activateDeploymentEnvThunk,
  addDomainThunk,
  // addEnvThunk,
  createDeploymentEnvThunk,
  deactivateDeploymentEnvThunk,
  deleteDeploymentEnvThunk,
  deleteProjectThunk,
  fetchAllProjectsThunk,
  fetchSelectedProjectThunk,
  fetchTotalProjectsThunk,
  // loadCdnRecordsThunk,
  loadMoreProjectsThunk,
  projectArchiveThunk,
  projectMaintainThunk,
  projectSecurityRemoveThunk,
  projectSecurityThunk,
  removeEnvThunk,
  updateBuildConfigThunk,
  updateDeploymentEnvThunk,
  // updateEnvThunk,
} from './project.thunks';
import {
  IDomainLoading,
  IProjectState,
  ProjectType,
} from './project.interfaces';
import { ICdnRecords, IProject } from '../combined-state.interface';
import { toggleListLoading } from './project.utils';

export const initialState: IProjectState = {
  selectedProjectLoading: true,
  selectedProject: undefined,
  projectsLoading: true,
  allProjects: [],
  totalMaintainedProjects: 0,
  totalArchivedProjects: 0,
  moreMaintainedProjectsLoading: false,
  maintainedProjectsPagination: {
    currentPage: 0,
    numbersPerPage: 6,
  },
  domains: {
    domains: [],
    subdomains: [],
    handshakeDomains: [],
    handshakeSubdomains: [],
    ensDomains: [],
  },
  domainLoading: false,
  editDomainLoading: [],
  deleteDomainLoading: [],
  verifyDomainLoading: [],
  cdnRecords: {
    recordIpv4V2: '',
    recordCnameV2: '',
  },
  envVariableLoading: '-1',
  archivedProjectsLoading: false,
  archivedProjects: [],
  moreArchivedProjectsLoading: false,
  archivedProjectsPagination: {
    currentPage: 0,
    numbersPerPage: 6,
  },
  updateBuildConfigLoading: false,
  createDeployEnvLoading: false,
  deleteDeployEnvLoading: false,
  deactivateDeployEnvLoading: false,
  removeEnvVariableLoading: false,
  deployEnvLoading: false,
  deleteProjectLoading: false,
  securityLoading: false,
  securitySuccess: false,
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    toggleEnvVariableLoading: (
      state: IProjectState,
      action: PayloadAction<string>
    ) => {
      state.envVariableLoading = action.payload;
    },
    cdnRecordsLoaded: (
      state: IProjectState,
      action: PayloadAction<ICdnRecords>
    ) => {
      state.cdnRecords = action.payload;
    },
    toggleDeleteProjectLoading: (
      state: IProjectState,
      action: PayloadAction<boolean>
    ) => {
      state.deleteProjectLoading = action.payload;
    },
    deleteProjectEnd: (state: IProjectState, action: PayloadAction<string>) => {
      state.allProjects = state.allProjects.filter(
        (project) => project._id !== action.payload
      );
      state.archivedProjects = state.archivedProjects.filter(
        (project) => project._id !== action.payload
      );
    },
    toggleDomainLoading: (
      state: IProjectState,
      action: PayloadAction<boolean>
    ) => {
      state.domainLoading = action.payload;
    },
    toggleDeleteDomainLoading: (
      state: IProjectState,
      action: PayloadAction<IDomainLoading>
    ) => {
      state.deleteDomainLoading = toggleListLoading(
        state.deleteDomainLoading,
        action.payload
      );
    },
    toggleEditDomainLoading: (
      state: IProjectState,
      action: PayloadAction<IDomainLoading>
    ) => {
      state.editDomainLoading = toggleListLoading(
        state.editDomainLoading,
        action.payload
      );
    },
    toggleVerifyDomainLoading: (
      state: IProjectState,
      action: PayloadAction<IDomainLoading>
    ) => {
      state.verifyDomainLoading = toggleListLoading(
        state.verifyDomainLoading,
        action.payload
      );
    },
    setSelectedProject: (state: IProjectState, action: PayloadAction<any>) => {
      state.selectedProject = action.payload;
    },
    completedProjectSecurity: (
      state: IProjectState,
      action: PayloadAction<boolean>
    ) => {
      state.securityLoading = false;
      state.securitySuccess = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllProjectsThunk.pending, (state: IProjectState) => {
      state.projectsLoading = true;
      state.allProjects = [];
    });

    builder.addCase(
      fetchAllProjectsThunk.fulfilled,
      (state: IProjectState, action: any) => {
        switch (action.payload.type) {
          case ProjectType.ARCHIVED: {
            state.archivedProjects = action.payload.projects;
            state.archivedProjectsLoading = false;
            state.archivedProjectsPagination = {
              currentPage: 0,
              numbersPerPage: 6,
            };
            break;
          }
          case ProjectType.MAINTAINED: {
            state.allProjects = action.payload.projects;
            state.projectsLoading = false;
            state.maintainedProjectsPagination = {
              currentPage: 0,
              numbersPerPage: 6,
            };
            break;
          }
          default:
            break;
        }
      }
    );

    builder.addCase(fetchAllProjectsThunk.rejected, (state: IProjectState) => {
      state.projectsLoading = false;
      state.allProjects = [];
    });

    builder.addCase(
      loadMoreProjectsThunk.pending,
      (state: IProjectState, action: any) => {
        switch (action.meta.arg) {
          case ProjectType.ARCHIVED: {
            state.moreArchivedProjectsLoading = true;
            break;
          }
          case ProjectType.MAINTAINED: {
            state.moreMaintainedProjectsLoading = true;
            break;
          }
          default:
            break;
        }
      }
    );

    builder.addCase(
      loadMoreProjectsThunk.fulfilled,
      (state: IProjectState, action: PayloadAction<any>) => {
        switch (action.payload.type) {
          case ProjectType.ARCHIVED: {
            state.archivedProjects = [
              ...state.archivedProjects,
              ...action.payload.projects,
            ];
            state.moreArchivedProjectsLoading = false;
            state.archivedProjectsPagination = {
              currentPage: state.archivedProjectsPagination.currentPage + 1,
              numbersPerPage: 6,
            };
            break;
          }
          case ProjectType.MAINTAINED: {
            state.allProjects = [
              ...state.allProjects,
              ...action.payload.projects,
            ];
            state.moreMaintainedProjectsLoading = false;
            state.maintainedProjectsPagination = {
              ...state.maintainedProjectsPagination,
              currentPage: state.maintainedProjectsPagination.currentPage + 1,
            };
            break;
          }
          default:
            break;
        }
      }
    );

    builder.addCase(
      loadMoreProjectsThunk.rejected,
      (state: IProjectState, action: PayloadAction<any>) => {
        switch (action.payload) {
          case ProjectType.ARCHIVED: {
            state.moreArchivedProjectsLoading = false;
            break;
          }
          case ProjectType.MAINTAINED: {
            state.moreMaintainedProjectsLoading = false;
            break;
          }
          default:
            break;
        }
      }
    );

    builder.addCase(fetchTotalProjectsThunk.pending, (state: IProjectState) => {
      state.totalMaintainedProjects = 0;
      state.totalArchivedProjects = 0;
    });

    builder.addCase(
      fetchTotalProjectsThunk.fulfilled,
      (state: IProjectState, action: PayloadAction<any>) => {
        switch (action.payload.type) {
          case ProjectType.ARCHIVED:
            state.totalArchivedProjects = action.payload.count;
            break;
          case ProjectType.MAINTAINED:
            state.totalMaintainedProjects = action.payload.count;
            break;
          default:
            break;
        }
      }
    );

    builder.addCase(
      fetchTotalProjectsThunk.rejected,
      (state: IProjectState, action: PayloadAction<any>) => {
        switch (action.payload.type) {
          case ProjectType.ARCHIVED:
            state.totalArchivedProjects = 0;
            break;
          case ProjectType.MAINTAINED:
            state.totalMaintainedProjects = 0;
            break;
          default:
            break;
        }
      }
    );

    builder.addCase(
      fetchSelectedProjectThunk.pending,
      (state: IProjectState) => {
        state.selectedProjectLoading = true;
      }
    );

    builder.addCase(
      fetchSelectedProjectThunk.fulfilled,
      (state: IProjectState, action: PayloadAction<any>) => {
        state.selectedProjectLoading = false;
        state.selectedProject = action.payload;
        state.domains = {
          domains: (action.payload as IProject).domains.filter(
            (d) => d.type === 'domain'
          ),
          subdomains: (action.payload as IProject).domains.filter(
            (d) => d.type === 'subdomain'
          ),
          handshakeDomains: (action.payload as IProject).domains.filter(
            (d) => d.type === 'handshake-domain'
          ),
          handshakeSubdomains: (action.payload as IProject).domains.filter(
            (d) => d.type === 'handshake-subdomain'
          ),
          ensDomains: (action.payload as IProject).domains.filter(
            (d) => d.type === 'ens-domain'
          ),
        };
      }
    );

    builder.addCase(
      fetchSelectedProjectThunk.rejected,
      (state: IProjectState) => {
        state.selectedProjectLoading = false;
        state.selectedProject = undefined;
      }
    );

    builder.addCase(addDomainThunk.pending, (state: IProjectState) => {
      state.domainLoading = true;
    });

    builder.addCase(addDomainThunk.fulfilled, (state: IProjectState) => {
      state.domainLoading = false;
    });

    builder.addCase(addDomainThunk.rejected, (state: IProjectState) => {
      state.domainLoading = false;
    });

    // builder.addCase(loadCdnRecordsThunk.pending, (state: IProjectState) => {
    //   state.loadCdnRecords = true;
    // });

    // builder.addCase(loadCdnRecordsThunk.fulfilled, (state: IProjectState) => {
    //   state.loadCdnRecords = false;
    // });

    // builder.addCase(loadCdnRecordsThunk.rejected, (state: IProjectState) => {
    //   state.loadCdnRecords = false;
    // });

    // builder.addCase(addEnvThunk.pending, (state: IProjectState) => {
    //   state.addEnv = true;
    // });

    // builder.addCase(addEnvThunk.fulfilled, (state: IProjectState) => {
    //   state.addEnv = false;
    // });

    // builder.addCase(addEnvThunk.rejected, (state: IProjectState) => {
    //   state.addEnv = false;
    // });

    // builder.addCase(updateEnvThunk.pending, (state: IProjectState) => {
    //   state.updateEnv = true;
    // });

    // builder.addCase(updateEnvThunk.fulfilled, (state: IProjectState) => {
    //   state.updateEnv = false;
    // });

    // builder.addCase(updateEnvThunk.rejected, (state: IProjectState) => {
    //   state.updateEnv = false;
    // });

    builder.addCase(removeEnvThunk.pending, (state: IProjectState) => {
      state.removeEnvVariableLoading = true;
    });

    builder.addCase(removeEnvThunk.fulfilled, (state: IProjectState) => {
      state.removeEnvVariableLoading = false;
    });

    builder.addCase(removeEnvThunk.rejected, (state: IProjectState) => {
      state.removeEnvVariableLoading = false;
    });

    builder.addCase(updateBuildConfigThunk.pending, (state: IProjectState) => {
      state.updateBuildConfigLoading = true;
    });

    builder.addCase(
      updateBuildConfigThunk.fulfilled,
      (state: IProjectState) => {
        state.updateBuildConfigLoading = false;
      }
    );

    builder.addCase(updateBuildConfigThunk.rejected, (state: IProjectState) => {
      state.updateBuildConfigLoading = false;
    });

    builder.addCase(
      createDeploymentEnvThunk.pending,
      (state: IProjectState) => {
        state.createDeployEnvLoading = true;
      }
    );

    builder.addCase(
      createDeploymentEnvThunk.fulfilled,
      (state: IProjectState) => {
        state.createDeployEnvLoading = false;
      }
    );

    builder.addCase(
      createDeploymentEnvThunk.rejected,
      (state: IProjectState) => {
        state.createDeployEnvLoading = false;
      }
    );

    builder.addCase(
      updateDeploymentEnvThunk.pending,
      (state: IProjectState) => {
        state.deployEnvLoading = true;
      }
    );

    builder.addCase(
      updateDeploymentEnvThunk.fulfilled,
      (state: IProjectState) => {
        state.deployEnvLoading = false;
      }
    );

    builder.addCase(
      updateDeploymentEnvThunk.rejected,
      (state: IProjectState) => {
        state.deployEnvLoading = false;
      }
    );

    builder.addCase(
      deleteDeploymentEnvThunk.pending,
      (state: IProjectState) => {
        state.deleteDeployEnvLoading = true;
      }
    );

    builder.addCase(
      deleteDeploymentEnvThunk.fulfilled,
      (state: IProjectState) => {
        state.deleteDeployEnvLoading = false;
      }
    );

    builder.addCase(
      deleteDeploymentEnvThunk.rejected,
      (state: IProjectState) => {
        state.deleteDeployEnvLoading = false;
      }
    );

    // builder.addCase(
    //   activateDeploymentEnvThunk.pending,
    //   (state: IProjectState) => {
    //     state.activateDeploymentEnv = true;
    //   }
    // );

    // builder.addCase(
    //   activateDeploymentEnvThunk.fulfilled,
    //   (state: IProjectState) => {
    //     state.activateDeploymentEnv = false;
    //   }
    // );

    // builder.addCase(
    //   activateDeploymentEnvThunk.rejected,
    //   (state: IProjectState) => {
    //     state.activateDeploymentEnv = false;
    //   }
    // );

    builder.addCase(
      deactivateDeploymentEnvThunk.pending,
      (state: IProjectState) => {
        state.deactivateDeployEnvLoading = true;
      }
    );

    builder.addCase(
      deactivateDeploymentEnvThunk.fulfilled,
      (state: IProjectState) => {
        state.deactivateDeployEnvLoading = false;
      }
    );

    builder.addCase(
      deactivateDeploymentEnvThunk.rejected,
      (state: IProjectState) => {
        state.deactivateDeployEnvLoading = false;
      }
    );

    builder.addCase(projectArchiveThunk.pending, (state: IProjectState) => {
      state.archivedProjectsLoading = true;
    });

    builder.addCase(projectArchiveThunk.fulfilled, (state: IProjectState) => {
      state.archivedProjectsLoading = false;
    });

    builder.addCase(projectArchiveThunk.rejected, (state: IProjectState) => {
      state.archivedProjectsLoading = false;
    });

    builder.addCase(projectMaintainThunk.pending, (state: IProjectState) => {
      state.archivedProjectsLoading = true;
    });

    builder.addCase(projectMaintainThunk.fulfilled, (state: IProjectState) => {
      state.archivedProjectsLoading = false;
    });

    builder.addCase(projectMaintainThunk.rejected, (state: IProjectState) => {
      state.archivedProjectsLoading = false;
    });

    builder.addCase(deleteProjectThunk.pending, (state: IProjectState) => {
      state.deleteProjectLoading = true;
    });

    builder.addCase(deleteProjectThunk.fulfilled, (state: IProjectState) => {
      state.deleteProjectLoading = false;
    });

    builder.addCase(deleteProjectThunk.rejected, (state: IProjectState) => {
      state.deleteProjectLoading = false;
    });

    builder.addCase(projectSecurityThunk.pending, (state: IProjectState) => {
      state.securityLoading = true;
    });

    builder.addCase(projectSecurityThunk.fulfilled, (state: IProjectState) => {
      state.securityLoading = false;
    });

    builder.addCase(projectSecurityThunk.rejected, (state: IProjectState) => {
      state.securityLoading = false;
    });

    builder.addCase(
      projectSecurityRemoveThunk.pending,
      (state: IProjectState) => {
        state.securityLoading = true;
      }
    );

    builder.addCase(
      projectSecurityRemoveThunk.fulfilled,
      (state: IProjectState, action: PayloadAction<any>) => {
        state.securityLoading = true;
        state.securitySuccess = action.payload;
      }
    );

    builder.addCase(
      projectSecurityRemoveThunk.rejected,
      (state: IProjectState) => {
        state.securityLoading = false;
      }
    );
  },
});

export const {
  toggleEnvVariableLoading,
  cdnRecordsLoaded,
  toggleDeleteProjectLoading,
  deleteProjectEnd,
  toggleDomainLoading,
  toggleDeleteDomainLoading,
  toggleEditDomainLoading,
  toggleVerifyDomainLoading,
  setSelectedProject,
  completedProjectSecurity,
} = projectSlice.actions;

export const { reducer: projectRTKReducer } = projectSlice;
