import React from 'react';
import { ConfirmationModal, Modal } from '@spheron/ui-library';
import { useDispatch } from 'react-redux';
import Styles from '../../../styles/compute/modal.module.scss';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import ModalWrapper from './ModalWrapper';
import { AppDispatch } from '../../../redux/compute/store';

interface IProps {
  isOpen: boolean;
  resource: string;
}

const DeletedResource = ({ isOpen, resource }: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={() => dispatchRtk(toggleModalShowRtk({ modalShow: false }))}
      classname={`${Styles.modal__outer__con} w-430 modal fixed inset-0 overflow-y-auto`}
    >
      <div className={Styles.modal__inner__content}>
        <Modal
          footer
          header
          subtitle=""
          modalContent={
            <span className="font-normal text-sm leading-5">
              {resource} is sucessfully deleted! You can create a new {resource}{' '}
              anytime.
            </span>
          }
          headerContent={<>{resource} deleted successfully!</>}
          primaryButtonContent="Okay"
          secondaryButtonContent="Cancel"
          primaryButton
          primaryButtonLoading={false}
          secondaryButtonDisabled={false}
          secondaryButton={false}
          onPrimaryButtonClick={() =>
            dispatchRtk(toggleModalShowRtk({ modalShow: false }))
          }
          confirmationStatus={ConfirmationModal.INFO}
        />
      </div>
    </ModalWrapper>
  );
};

export default DeletedResource;
