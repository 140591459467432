import React, { Fragment, useState } from 'react';
import { Modal } from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import Styles from '../../../styles/compute/modal.module.scss';
import {
  networks,
  wallets,
  providedTokens,
  getNetworkFromName,
  getNetworkConfig,
  showSelectedNetworks,
  showSelectedCommunityNetworks,
  isChainDisabled,
  isTokenDisabled,
} from '../../../redux/compute/web3/web3.utils';
import {
  INetworkType,
  ITokenType,
  IConfigureWalletPayloadDtoRtk,
} from '../../../redux/compute/web3/web3.interfaces';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import { configurePhantomWalletThunk } from '../../../redux/compute/solana/solana.thunks';
import { configureWalletThunk } from '../../../redux/compute/web3/web3.thunks';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import ModalWrapper from './ModalWrapper';
import ConnectWalletItem, {
  WalletItemType,
} from '../../Cards/connect-wallet-item';

interface IProps {
  isOpen: boolean;
}
const ConnectWalletParameterModal = ({ isOpen }: IProps) => {
  const isPublicAccessible =
    sessionStorage.getItem('isPublicAccessible') === 'true';

  const dispatchRtk = useDispatch<AppDispatch>();

  const connectWalletLoading = useSelector(
    (state: RootState) => state.web3.connectWalletLoading
  );

  const selectedOrganisationId = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation._id
  );

  const [selectedWallet, setSelectedWallet] = useState<string>('');
  const [selectedNetwork, setSelectedNetwork] = useState<INetworkType | null>(
    null
  );
  const [selectedToken, setSelectedToken] = useState<ITokenType | null>(null);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const showWallet = () => {
    if (selectedNetwork?.chainName === 'solana')
      return wallets.filter((item) => item.name === 'Phantom');
    if (selectedNetwork === null && !isPublicAccessible) return wallets;
    return wallets.filter((item) => item.name !== 'Phantom');
  };

  const shownNetworks = Object.keys(networks)
    .filter(
      (key) =>
        (isPublicAccessible
          ? showSelectedCommunityNetworks
          : showSelectedNetworks
        ).indexOf(Number(key)) !== -1
    )
    .map((key: string) => networks[Number(key)]);

  const closeModal = () => {
    dispatchRtk(
      toggleModalShowRtk({
        modalShow: false,
      })
    );
  };

  const handleNetWorkSwitching = async () => {
    if (selectedWallet === 'Metamask')
      dispatchRtk(
        configureWalletThunk({
          selectedNetwork,
          selectedToken,
          selectedWallet,
          selectedOrganisationId,
        } as IConfigureWalletPayloadDtoRtk)
      );
    else if (
      selectedWallet === 'Phantom' &&
      !!selectedToken &&
      !!selectedNetwork
    )
      dispatchRtk(
        configurePhantomWalletThunk({
          selectedNetwork,
          selectedToken,
          selectedWallet,
          selectedOrganisationId,
        })
      );
  };

  const selectnetwork = (item: INetworkType) => {
    setCurrentStep(1);
    setSelectedNetwork(item);
    setSelectedWallet('');
    setSelectedToken(null);
  };

  // TODO: Add disable mechanism on chain object

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={() => {
        dispatchRtk(toggleModalShowRtk({ modalShow: false }));
      }}
      classname={`${Styles.modal__outer__con} modal fixed inset-0 overflow-y-auto`}
    >
      <div className={`${Styles.modal__inner__content}`}>
        <Modal
          footer
          header
          title="Attach Web3 Payment Method"
          subtitle=""
          modalContent={
            <div className={`${Styles.max__height} overflow-y-scroll`}>
              <div className="upper">
                <div
                  className="font-semibold text-sm
                        text-base-heading-text-color dark:text-dark-base-heading-text-color"
                >
                  1. Choose Network
                </div>
                <div>
                  <div
                    className="grid grid-cols-4 mt-3.5 gap-3.5
                           w-11/12"
                  >
                    {shownNetworks.map((item) => (
                      <>
                        <ConnectWalletItem
                          key={item.chainId}
                          image={item.logo}
                          title={getNetworkFromName(Number(item.chainId))}
                          isActive={selectedNetwork?.chainId === item.chainId}
                          disabled={isChainDisabled(item.chainName)}
                          handleClick={() => selectnetwork(item)}
                          type={WalletItemType.NETWORK}
                          currentStep={currentStep}
                        />
                      </>
                    ))}
                  </div>
                </div>
              </div>
              <div className="middle mt-8">
                <div
                  className="font-semibold text-sm
                        text-base-heading-text-color dark:text-dark-base-heading-text-color"
                >
                  2. Choose Token
                </div>
                <div>
                  <div
                    className="grid grid-cols-4 mt-3.5 gap-3.5
                           w-11/12"
                  >
                    {providedTokens[selectedNetwork?.chainName || 'matic'][
                      getNetworkConfig(selectedNetwork?.chainId || 42161).TYPE
                    ].map((item) => (
                      <>
                        <ConnectWalletItem
                          key={item.id}
                          image={item.logoURI}
                          title={item.symbol}
                          isActive={selectedToken?.symbol === item.symbol}
                          disabled={
                            selectedNetwork === null ||
                            isTokenDisabled(
                              selectedNetwork?.chainName,
                              item.symbol
                            )
                          }
                          handleClick={() => {
                            setCurrentStep(2);
                            setSelectedToken(item);
                          }}
                          type={WalletItemType.TOKEN}
                          currentStep={currentStep}
                        />
                      </>
                    ))}
                  </div>
                </div>
              </div>
              <div className="bottom mt-8">
                <div
                  className="font-semibold text-sm
                        text-base-heading-text-color dark:text-dark-base-heading-text-color"
                >
                  3. Choose Wallet
                </div>
                <div>
                  <div
                    className="grid grid-cols-4 mt-3.5 gap-3.5
                           w-11/12"
                  >
                    {showWallet().map((item) => (
                      <>
                        <ConnectWalletItem
                          key={item.id}
                          image={item.image}
                          title={item.name}
                          isActive={selectedWallet === item.name}
                          disabled={
                            selectedNetwork === null || selectedToken === null
                          }
                          handleClick={() => {
                            setCurrentStep(3);
                            setSelectedWallet(item.name);
                          }}
                          type={WalletItemType.WALLET}
                          currentStep={currentStep}
                        />
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          }
          headerContent={<>Attach Web3 Payment Method</>}
          primaryButtonContent="Add"
          secondaryButtonContent="Cancel"
          primaryButton
          primaryButtonLoading={connectWalletLoading}
          primaryButtonDisabled={
            !(
              selectedToken?.symbol &&
              selectedNetwork?.chainName &&
              selectedWallet
            )
          }
          secondaryButtonDisabled={false}
          secondaryButton
          onPrimaryButtonClick={() => handleNetWorkSwitching()}
          onSecondaryButtonClick={closeModal}
        />
      </div>
    </ModalWrapper>
  );
};

export default ConnectWalletParameterModal;
