import React from 'react';
import { ConfirmationModal, Modal } from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import Styles from '../../../styles/compute/modal.module.scss';
import { IAPIToken } from '../../../redux/compute/user/user.interfaces';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import { deleteTokensThunk } from '../../../redux/compute/user/user.thunks';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import ModalWrapper from './ModalWrapper';

interface IProps {
  isOpen: boolean;
  tokens: IAPIToken[];
}

const DeleteToken = ({ isOpen, tokens }: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();

  const deletingToken = useSelector(
    (state: RootState) => state.user.deletingToken
  );

  const closeModal = () => {
    dispatchRtk(toggleModalShowRtk({ modalShow: false }));
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={closeModal}
      classname={`${Styles.modal__outer__con} w-430 modal fixed inset-0 overflow-y-auto`}
    >
      <div className={Styles.modal__inner__content}>
        <Modal
          footer
          header
          subtitle=""
          modalContent={
            <span>
              Do you really want to delete{' '}
              <b>{tokens?.map((m) => m.name).join(', ')}</b> from your list of
              tokens?
            </span>
          }
          headerContent={<>Delete Token</>}
          primaryButtonContent="Delete"
          secondaryButtonContent="Cancel"
          primaryButton
          primaryButtonLoading={deletingToken}
          primaryButtonDisabled={deletingToken}
          secondaryButtonDisabled={deletingToken}
          secondaryButton
          onPrimaryButtonClick={() => {
            dispatchRtk(deleteTokensThunk(tokens?.map((t) => t.id)));
          }}
          onSecondaryButtonClick={closeModal}
          confirmationStatus={ConfirmationModal.ERROR}
        />
      </div>
    </ModalWrapper>
  );
};

export default DeleteToken;
