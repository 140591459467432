import React from 'react';
import Skeleton from 'react-loading-skeleton';

interface IProps {
  className: string;
}

const NetworkTokenSetLoading = ({ className }: IProps) => {
  return (
    <div
      className={`flex justify-start items-center relative ${className || ''}`}
    >
      <Skeleton height={20} width={20} duration={2} borderRadius={20} />
      <Skeleton
        height={20}
        width={20}
        duration={2}
        borderRadius={20}
        className="left-3.5 absolute top-1 z-40"
      />
    </div>
  );
};

export default NetworkTokenSetLoading;
