import React, { useEffect, useMemo, useRef } from 'react';
import { useNavigate, useParams, useLocation, Outlet } from 'react-router-dom';
// import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as MoreIcon } from '@spheron/ui-library/dist/assets/more.svg';
import {
  BreadcrumbsBar,
  Dropdown,
  InnerNavigation,
  InstanceCard,
  InstanceCardState,
  InstanceCardType,
  InstanceDeploymentType,
  OptionType,
  Refresh,
  Tabs,
} from '@spheron/ui-library';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import GlobalStyles from '../../../../styles/compute/global.module.scss';
import {
  InstanceEvent,
  InstanceLogs,
  InstanceStatus,
} from '../../../../redux/compute/instance/instance.interfaces';
import {
  toggleShouldFetchInstanceDetails,
  toggleShouldFetchDeploymentDetails,
  setInstanceStreamLogsRtk,
  setInstanceSocketDetailsRtk,
  setInstanceEventsServiceLoadingRtk,
  toggleShouldFetchLogs,
} from '../../../../redux/compute/instance/instance.slice';
import {
  deleteInstanceThunk,
  downloadLogsThunk,
  getComputeInstanceDetailsThunk,
  getDeploymentDetailsThunk,
  getDeploymentLogsThunk,
  getDomainsThunk,
  getInstanceActivityThunk,
  loadCdnRecordsThunk,
} from '../../../../redux/compute/instance/instance.thunks';
import { AppDispatch, RootState } from '../../../../redux/compute/store';
import { IInstance } from '../../../../redux/compute/project/project.interfaces';
import { withLoader } from '../../../../redux/compute/root-utils';
import InstanceCardLoading from '../../../../components/Compute/Loaders/instance-card-loading';
import {
  getInstanceScalingType,
  getInstanceState,
} from '../../../../redux/compute/project/project.utils';
import { EventSourceMessage } from '../../../../libs/fetchEventSource';
import { callSSE } from '../../../../libs/Compute/sse';
import { toggleModalShowRtk } from '../../../../redux/compute/modal/modal.slice';
import { canDeleteInstance } from '../../../../redux/compute/instance/instance.utils';
import { IUser } from '../../../../redux/compute/combined-state.interface';
import { getClusterTemplateDetailsThunk } from '../../../../redux/compute/cluster/cluster.thunks';
import { setSelectedTemplateRtk } from '../../../../redux/compute/cluster/cluster.slice';
import {
  chooseClusterTypeRtk,
  setInitialMultiserviceState,
} from '../../../../redux/compute/instance/instance-creation/instance-creation.slice';
import { ClusterType } from '../../../../redux/compute/instance/instance-creation/instance-creation.interfaces';
import { getComputeProjectInstancesThunk } from '../../../../redux/compute/project/project.thunks';
import {
  getAvailableInventoryThunk,
  getInstanceUnitPricesThunk,
} from '../../../../redux/compute/instance/instance-creation/instance-creation.thunks';
// eslint-disable-next-line max-len
import { generateInitialMultiserviceMap } from '../../../../redux/compute/instance/instance-creation/instance-creation.utils';

const InstanceDetails = () => {
  const communityOrgName = sessionStorage.getItem('communityOrgName');
  const isPublicAccessibleLocalStorage =
    sessionStorage.getItem('isPublicAccessible') === 'true';
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatchRtk = useDispatch<AppDispatch>();
  const params = useParams<{
    orgUsername: string;
    instanceId: string;
    projectId: string;
    slug3: string;
  }>();
  // const uniqueTopicId = uuidv4();
  const currentUser = useSelector((state: RootState) => state.user.user);
  const selectedOrganisation = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation
  );
  const selectedOrganisationLoading = useSelector(
    (state: RootState) => state.organisation.selectedOrganisationLoading
  );

  const activeDeployment = useSelector(
    (state: RootState) => state.instance.activeDeployment
  );
  const activeDeploymentLoading = useSelector(
    (state: RootState) => state.instance.activeDeploymentLoading
  );
  const selectedInstanceLoading: boolean = useSelector(
    (state: RootState) => state.instance.selectedInstanceLoading
  );
  const selectedInstance = useSelector(
    (state: RootState) => state.instance.selectedInstance
  );

  const shouldFetchInstanceDetails = useSelector(
    (state: RootState) => state.instance.shouldFetchInstanceDetails
  );

  const shouldFetchDeploymentDetails = useSelector(
    (state: RootState) => state.instance.shouldFetchDeploymentDetails
  );

  const fetchInstanceEventServiceLoading: boolean = useSelector(
    (state: RootState) => state.instance.fetchInstanceHealthCheckLoading
  );
  const fetchInstanceHealthCheckLoading: boolean = useSelector(
    (state: RootState) => state.instance.fetchInstanceHealthCheckLoading
  );

  const isPublicAccessible = useSelector(
    (state: RootState) => state.organisation.isPublicAccessible
  );

  const selectedProjectName = useSelector(
    (state: RootState) => state.computeProject.selectedProjectName
  );

  const shouldFetchLogs = useSelector(
    (state: RootState) => state.instance.shouldFetchLogs
  );

  const editNameLoading = useSelector(
    (state: RootState) => state.instance.editNameLoading
  );

  const unitPrices = useSelector(
    (state: RootState) => state.instanceCreation.unitPrices
  );

  const availableInventory = useSelector(
    (state: RootState) => state.instanceCreation.availableInventory
  );

  const fetchInstanceEventType = useSelector(
    (state: RootState) => state.instance.fetchInstanceEventType
  );

  const instanceCdnRecords = useSelector(
    (state: RootState) => state.instance.cdnRecords
  );

  const domains = useSelector((state: RootState) => state.instance.domains);

  const startInstanceDeployment = () => {
    let provider = 'DOCKERHUB';
    const latestDeploymentType = selectedInstance.type;
    if (activeDeployment?.services[0]?.template) provider = 'template';
    const searchParam = new URLSearchParams({
      name: activeDeployment?.services[0]?.name || '',
      provider,
      repository: activeDeployment?.services[0]?.image || '',
    });
    const templateSearchParam = new URLSearchParams({
      provider,
      template: activeDeployment?.services[0]?.template?.name || '',
    });
    const redeploySearchParam = new URLSearchParams({
      type: 'redeploy',
    });
    const deploymentType = new URLSearchParams({
      deploymentType: latestDeploymentType,
    });
    navigate({
      pathname: `/${params.orgUsername}/${params.projectId}/new-instance/`,
      search: `?${redeploySearchParam.toString()}&${
        provider === 'template'
          ? templateSearchParam.toString()
          : searchParam.toString()
      }&${deploymentType}`,
    });
  };

  const instanceDropdownOptions = (
    name: string,
    id: string,
    state: InstanceCardState
  ) => {
    return [
      {
        label: 'Rename Instance',
        handleClick: () => {
          dispatchRtk(
            toggleModalShowRtk({
              modalShow: true,
              modalType: 'editName',
              options: {
                resource: 'Instance',
                id: selectedInstance?._id || '',
                loading: editNameLoading,
              },
            })
          );
        },
        optionType: 'primary' as OptionType,
      },
      ...(String(state) !== 'queued' && String(state) !== 'pending'
        ? [
            // {
            //   label: 'Restart Instance',
            //   handleClick: () => {
            //     dispatchRtk(
            //       toggleModalShowRtk({
            //         modalShow: true,
            //         modalType: 'restartInstance',
            //         options: {
            //           instanceConfig: {
            //             instanceId: id,
            //             organizationId: selectedOrganisation?._id,
            //             uniqueTopicId,
            //           },
            //         },
            //       })
            //     );
            //   },
            //   optionType: 'primary' as OptionType,
            // },
          ]
        : []),
      {
        label: 'Clone Instance',
        handleClick: () => {
          startInstanceDeployment();
        },
        optionType: 'primary' as OptionType,
      },
      ...(String(state) === 'Active' || String(state) === 'Deployed'
        ? [
            {
              label: 'Close Instance',
              handleClick: () => {
                dispatchRtk(
                  toggleModalShowRtk({
                    modalShow: true,
                    modalType: 'closeInstance',
                    options: {
                      instanceId: id,
                    },
                  })
                );
              },
              optionType: 'primary' as OptionType,
            },
          ]
        : []),
      ...(canDeleteInstance(
        currentUser as IUser,
        selectedOrganisation.users,
        state?.toLowerCase(),
        selectedInstanceLoading
      )
        ? [
            {
              label: 'Delete Instance',
              handleClick: () => {
                dispatchRtk(
                  toggleModalShowRtk({
                    modalShow: true,
                    modalType: 'deleteResource',
                    options: {
                      handleClick: () => {
                        dispatchRtk(deleteInstanceThunk(id));
                      },
                      resourceType: 'Instance',
                      resource: name,
                    },
                  })
                );
              },
              optionType: 'primary' as OptionType,
            },
          ]
        : []),
    ];
  };

  useEffect(() => {
    if (selectedInstance && shouldFetchDeploymentDetails) {
      dispatchRtk(
        getDeploymentDetailsThunk(
          selectedInstance?.activeDeployment ||
            selectedInstance?.deployments[
              selectedInstance?.deployments?.length - 1
            ]
        )
      ).finally(() => dispatchRtk(toggleShouldFetchDeploymentDetails(false)));
    }
  }, [dispatchRtk, selectedInstance, shouldFetchDeploymentDetails]);

  useEffect(() => {
    if (activeDeployment && activeDeployment?.services?.length > 1) {
      const templateServiceIds = activeDeployment.services.map(
        (service) => service.template?._id!
      );

      const generatedMap = generateInitialMultiserviceMap(templateServiceIds);

      dispatchRtk(setInitialMultiserviceState(generatedMap));
    }
  }, [activeDeployment, dispatchRtk]);

  useEffect(() => {
    if (
      !selectedInstance &&
      !selectedInstanceLoading &&
      shouldFetchInstanceDetails
    ) {
      dispatchRtk(
        getComputeInstanceDetailsThunk(params?.instanceId || '')
      ).finally(() => dispatchRtk(toggleShouldFetchInstanceDetails(false)));
    }
  }, [
    shouldFetchInstanceDetails,
    params.instanceId,
    selectedInstance,
    selectedInstanceLoading,
    dispatchRtk,
  ]);

  useEffect(() => {
    if (activeDeployment) {
      dispatchRtk(getInstanceActivityThunk(activeDeployment?.computeInstance!));
    }
  }, [activeDeployment, dispatchRtk]);

  useEffect(() => {
    if (activeDeployment && !selectedOrganisationLoading) {
      if (activeDeployment?.services[0]?.template) {
        dispatchRtk(
          getClusterTemplateDetailsThunk(
            activeDeployment.services[0].template._id
          )
        ).then((action) => {
          dispatchRtk(chooseClusterTypeRtk(ClusterType.TEMPLATE));
          if ((action.payload as any)?.clusterTemplate) {
            dispatchRtk(
              setSelectedTemplateRtk((action.payload as any).clusterTemplate)
            );
          } else {
            const templateDetails = action.payload;
            const templateServiceData = (templateDetails as any).services[0];
            const finalTemplateDetails = {
              ...(templateDetails as any),
              serviceData: {
                ...templateServiceData,
              },
            };
            dispatchRtk(setSelectedTemplateRtk(finalTemplateDetails as any));
          }
        });
      }
    }
  }, [activeDeployment, dispatchRtk, selectedOrganisationLoading]);

  useEffect(() => {
    if (activeDeployment && shouldFetchLogs) {
      dispatchRtk(
        getDeploymentLogsThunk({
          deploymentId: activeDeployment?._id || '',
          type: InstanceLogs.COMPUTE_LOGS,
        })
      );

      dispatchRtk(
        getDeploymentLogsThunk({
          deploymentId: activeDeployment?._id || '',
          type: InstanceLogs.LOGS,
        })
      );

      dispatchRtk(
        getDeploymentLogsThunk({
          deploymentId: activeDeployment?._id || '',
          type: InstanceLogs.COMPUTE_EVENTS,
        })
      );
      dispatchRtk(toggleShouldFetchLogs(false));
    }
  }, [activeDeployment, dispatchRtk, shouldFetchLogs]);

  useEffect(() => {
    if (
      selectedInstance &&
      selectedInstance?.state?.toLowerCase() === 'starting'
    ) {
      navigate(
        `/${params.orgUsername}/${params.projectId}/${params.instanceId}/logs/deployment-logs`
      );
    }
  }, [
    navigate,
    params.instanceId,
    params.orgUsername,
    params.projectId,
    selectedInstance,
  ]);

  useEffect(() => {
    const instanceSseController: AbortController = new AbortController();
    const { signal }: { signal: AbortSignal } = instanceSseController;
    let stream: any;
    if (activeDeployment) {
      callSSE({
        msgCb: (msg: EventSourceMessage) => {
          stream = JSON.parse(msg?.data);

          switch (stream.type) {
            case 1: {
              dispatchRtk(
                setInstanceStreamLogsRtk({
                  streamData: stream.data as string,
                  topicId: activeDeployment.topic?.trim(),
                })
              );
              break;
            }
            case 2: {
              const {
                providerHost,
                deploymentStatus,
                latestUrlPreview,
                ports,
              } = stream.data;
              dispatchRtk(
                setInstanceSocketDetailsRtk({
                  status: deploymentStatus,
                  providerHost,
                  latestUrlPreview:
                    Object.keys(latestUrlPreview).length > 0
                      ? latestUrlPreview
                      : '',
                  ports: Object.keys(ports).length > 0 ? ports : null,
                })
              );
              dispatchRtk(
                getComputeProjectInstancesThunk({
                  computeProjectId: params?.projectId || '',
                  topupReport: 'n',
                })
              );
              navigate(
                `/${params.orgUsername}/${params.projectId}/${params.instanceId}/overview`
              );
              break;
            }
            case 3: {
              dispatchRtk(
                setInstanceSocketDetailsRtk({
                  status: InstanceStatus.FAILED,
                  providerHost: '',
                  latestUrlPreview: '',
                  ports: null,
                })
              );
              navigate(
                `/${params.orgUsername}/${params.projectId}/${params.instanceId}/overview`
              );
              break;
            }
            case 'log-events': {
              dispatchRtk(
                setInstanceEventsServiceLoadingRtk({
                  type: InstanceEvent.LOGS,
                  loading: false,
                })
              );
              switch (fetchInstanceEventType as any) {
                case InstanceLogs.COMPUTE_EVENTS: {
                  dispatchRtk(
                    getDeploymentLogsThunk({
                      deploymentId: stream.objectId as string,
                      type: InstanceLogs.COMPUTE_EVENTS,
                    })
                  );
                  break;
                }
                case InstanceLogs.COMPUTE_LOGS: {
                  dispatchRtk(
                    getDeploymentLogsThunk({
                      deploymentId: stream.objectId as string,
                      type: InstanceLogs.COMPUTE_LOGS,
                    })
                  );
                  break;
                }
                default: {
                  dispatchRtk(
                    getDeploymentLogsThunk({
                      deploymentId: stream.objectId as string,
                      type: InstanceLogs.COMPUTE_LOGS,
                    })
                  );
                  dispatchRtk(
                    getDeploymentLogsThunk({
                      deploymentId: stream.objectId as string,
                      type: InstanceLogs.COMPUTE_EVENTS,
                    })
                  );
                  break;
                }
              }

              break;
            }
            case 'download-logs': {
              dispatchRtk(
                downloadLogsThunk({
                  url: stream.url,
                  id: params?.instanceId || '',
                })
              );
              break;
            }
            case 'health-check':
            case 'status-check': {
              dispatchRtk(
                setInstanceEventsServiceLoadingRtk({
                  type: InstanceEvent.HEALTH_CHECK,
                  loading: false,
                })
              );
              dispatchRtk(getInstanceActivityThunk(stream.objectId));
              break;
            }
            default:
              break;
          }
        },
        errCb: (error: any) => {
          console.error('Error in instance SSE -> ', (error as Error).message);
        },
        sessionId: activeDeployment.topic?.trim(),
        signal,
      });
    }

    return () => {
      if (instanceSseController) {
        instanceSseController.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchRtk, activeDeployment, params.instanceId]);

  useEffect(() => {
    // timeout for loading if fetching logs take much time
    if (fetchInstanceHealthCheckLoading)
      setTimeout(
        () =>
          dispatchRtk(
            setInstanceEventsServiceLoadingRtk({
              type: 'health-check',
              loading: false,
            })
          ),
        15000
      );
    if (fetchInstanceEventServiceLoading)
      setTimeout(
        () =>
          dispatchRtk(
            setInstanceEventsServiceLoadingRtk({
              type: 'logs',
              loading: false,
            })
          ),
        15000
      );
  }, [
    dispatchRtk,
    fetchInstanceEventServiceLoading,
    fetchInstanceHealthCheckLoading,
  ]);
  const breadcrumbItems = [
    {
      id: 1,
      linkName: 'Projects',
      handleClick: () => navigate(`/${params.orgUsername}/dashboard`),
      urlPath: `/${params.orgUsername}/dashboard`,
    },
    {
      id: 2,
      linkName: `${
        selectedProjectName || selectedInstance?.computeProject?.name
      }`,
      handleClick: () =>
        navigate(`/${params.orgUsername}/project/${params.projectId}`),
      urlPath: `/${params.orgUsername}/project/${params.projectId}`,
    },
    {
      id: 3,
      linkName: `${selectedInstance?.name}`,
      handleClick: () =>
        navigate(
          `/${params.orgUsername}/${params.projectId}/${params.instanceId}`
        ),
      urlPath: `/${params.orgUsername}/${params.projectId}/${params.instanceId}`,
    },
  ];

  const handleRefresh = () => {
    dispatchRtk(getComputeInstanceDetailsThunk(params?.instanceId || '')).then(
      () =>
        dispatchRtk(
          getDeploymentDetailsThunk(
            (selectedInstance as IInstance)?.activeDeployment ||
              (selectedInstance as IInstance).deployments[
                selectedInstance.deployments.length - 1
              ]
          )
        )
    );
  };

  const isRefreshLoading =
    activeDeploymentLoading ||
    selectedInstanceLoading ||
    selectedOrganisationLoading;

  const breadcrumbActions = [
    {
      id: 0,
      action: (
        <div>
          <Refresh
            showText
            handleClick={() => {
              handleRefresh();
            }}
            loading={isRefreshLoading}
            refreshType="default"
            time=""
            customText="Refresh"
          />
        </div>
      ),
    },
  ];

  const navItems = [
    {
      id: '1',
      link: `/${params.orgUsername}/${params.projectId}/${params.instanceId}/overview`,
      label: 'Overview',
      onClick: () =>
        navigate(
          `/${params.orgUsername}/${params.projectId}/${params.instanceId}/overview`
        ),
    },
    {
      id: '2',
      label: 'Instance Logs',
      link: `/${params.orgUsername}/${params.projectId}/${params.instanceId}/logs/instance-logs`,
      onClick: () =>
        navigate(
          `/${params.orgUsername}/${params.projectId}/${params.instanceId}/logs/instance-logs`
        ),
    },
    {
      id: '3',
      label: 'Instance Events',
      link: `/${params.orgUsername}/${params.projectId}/${params.instanceId}/logs/instance-events`,
      onClick: () =>
        navigate(
          `/${params.orgUsername}/${params.projectId}/${params.instanceId}/logs/instance-events`
        ),
    },
    {
      id: '4',
      label: 'Deployment Logs',
      link: `/${params.orgUsername}/${params.projectId}/${params.instanceId}/logs/deployment-logs`,
      onClick: () =>
        navigate(
          `/${params.orgUsername}/${params.projectId}/${params.instanceId}/logs/deployment-logs`
        ),
    },
    {
      id: '5',
      label: 'Activity',
      link: `/${params.orgUsername}/${params.projectId}/${params.instanceId}/activity`,
      onClick: () =>
        navigate(
          `/${params.orgUsername}/${params.projectId}/${params.instanceId}/activity`
        ),
    },
    {
      id: '6',
      label: 'Domains',
      link: `/${params.orgUsername}/${params.projectId}/${params.instanceId}/domains`,
      onClick: () =>
        navigate(
          `/${params.orgUsername}/${params.projectId}/${params.instanceId}/domains`
        ),
    },
    {
      id: '7',
      label: 'Settings',
      link: `/${params.orgUsername}/${params.projectId}/${params.instanceId}/settings`,
      onClick: () =>
        navigate(
          `/${params.orgUsername}/${params.projectId}/${params.instanceId}/settings`
        ),
    },
  ];

  // eslint-disable-next-line no-unused-vars
  const orgParams =
    isPublicAccessible || isPublicAccessibleLocalStorage
      ? communityOrgName
      : 'compute';

  const isMonitoringRoute =
    params.slug3 === 'monitoring' || params.slug3 === 'activity-details';

  const tabDashboardRoutes = [
    {
      id: 0,
      label: 'Dashboard',
      url: `/${params.orgUsername}/dashboard`,
      slug: 'projects',
    },
    {
      id: 1,
      label: 'Billing',
      url: `/${params.orgUsername}/billing`,
      slug: 'billing',
    },
  ];

  const handleTabClick = (id: number) => {
    navigate(tabDashboardRoutes[id].url);
  };

  useEffect(() => {
    if (!unitPrices?.length) {
      dispatchRtk(getInstanceUnitPricesThunk());
    }
    if (!availableInventory?.length) {
      dispatchRtk(getAvailableInventoryThunk());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchRtk]);

  useMemo(() => {
    if (activeDeployment) {
      if (domains.instanceId !== params.instanceId)
        dispatchRtk(getDomainsThunk(activeDeployment?.computeInstance));
      if (instanceCdnRecords.projectId !== params.projectId)
        dispatchRtk(loadCdnRecordsThunk(activeDeployment?.computeProject));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDeployment, dispatchRtk]);

  return (
    <>
      <div
        className={`dark:border-dark-base-border bg-base-fg dark:bg-dark-base-fg
        ${GlobalStyles.inner__tabs} w-full z-50 mx-auto flex items-center justify-center`}
      >
        <Tabs
          tabList={tabDashboardRoutes}
          tabListPosition="left"
          selectedTab={0}
          tabListClassname={`pt-4 pb-2 max-w-[1400px] mx-auto w-[95%]`}
          onClick={(id: number) => handleTabClick(id)}
        />
      </div>
      <div className="mx-auto" role="article">
        <div className="bg-base-bg border-base-border dark:border-dark-base-border dark:bg-dark-base-bg mt-5">
          {selectedInstance && (
            <div
              className={`${GlobalStyles.screen__width} !min-h-[0px] !mb-0 !p-0 mx-auto`}
            >
              <BreadcrumbsBar
                links={breadcrumbItems}
                activeLink={window.location.pathname
                  .split('/')
                  .slice(0, 4)
                  .join('/')}
                type="default"
                actions={breadcrumbActions}
              />
            </div>
          )}
        </div>
        <div
          className={`${
            !isMonitoringRoute && `${GlobalStyles.screen__width} !p-0 `
          }`}
        >
          <div className="flex flex-col mt-5 gap-y-3">
            {withLoader(
              selectedInstanceLoading || !selectedInstance,
              <InstanceCardLoading />,
              <div className="relative">
                <InstanceCard
                  detailsPage
                  name={selectedInstance?.name || ''}
                  updatedAt={dayjs(
                    `${
                      selectedInstance?.updateByUserAt ||
                      selectedInstance?.updatedAt
                    }`
                  ).format('MMMM DD, YYYY')}
                  domainName=""
                  region={selectedInstance?.region || ''}
                  instanceCardType={InstanceCardType.SIDEBYSIDE}
                  id={selectedInstance?._id || ''}
                  scalingType={getInstanceScalingType(
                    (selectedInstance as IInstance)?.computeType
                  )}
                  instanceState={getInstanceState(
                    (selectedInstance as IInstance)?.state
                  )}
                  instanceType={
                    ((
                      selectedInstance as unknown as IInstance
                    )?.type?.toLocaleLowerCase() as InstanceDeploymentType) ||
                    ''
                  }
                  totalPrice={
                    (selectedInstance as IInstance)?.state.toLowerCase() ===
                      'failed' ||
                    (selectedInstance as IInstance)?.state.toLowerCase() ===
                      'failed-start' ||
                    (selectedInstance as IInstance)?.state.toLowerCase() ===
                      'closed' ||
                    (selectedInstance as IInstance)?.state.toLowerCase() ===
                      'deprecated' ||
                    (selectedInstance as IInstance)?.state.toLowerCase() ===
                      'starting'
                      ? undefined
                      : (selectedInstance as IInstance)?.defaultDailyTopUp * 30
                  }
                  priceUnit="/mo"
                  onClick={() => {}}
                  onCheckboxClick={() => {}}
                  isSelected={false}
                  discountBadgeText={`${
                    selectedInstance?.discount
                      ? `${selectedInstance.discount.discountPercent}% OFF`
                      : ''
                  }`}
                />
                {selectedInstance && currentUser && (
                  <div className="absolute top-0 right-0" ref={dropdownRef}>
                    <Dropdown
                      dropdownType="button"
                      dropdownSize="compact"
                      bordersNone
                      buttonImage={
                        <MoreIcon
                          className="rotate-90 w-4 h-4 
                                          text-base-icon dark:text-dark-base-icon"
                        />
                      }
                      options={instanceDropdownOptions(
                        selectedInstance.name,
                        selectedInstance._id,
                        selectedInstance.state as any
                      )}
                    />
                  </div>
                )}
              </div>
            )}

            <div className="grid grid-cols-12 gap-x-5">
              <div className="col-span-3">
                <InnerNavigation
                  navItems={navItems}
                  activeItem={
                    navItems.find((item) => item.link === location.pathname)
                      ?.label || ''
                  }
                />
              </div>
              <div className="col-span-9">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InstanceDetails;
