import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import dayjs from 'dayjs';
import Skeleton from 'react-loading-skeleton';

interface IProps {
  icon: JSX.Element;
  text: string;
  content: JSX.Element;
  borderColor: string;
  label: Date[];
  dataOptions: number[];
  contentLoading: boolean;
  chartLoading: boolean;
  dataSuffix: string;
}

const MonitoringChartCard = ({
  icon,
  text,
  content,
  borderColor,
  label,
  dataOptions,
  contentLoading,
  chartLoading,
  dataSuffix,
}: IProps) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  const dateObjects = label.map((timestamp) => new Date(timestamp));

  const hasMultipleDates = (dates: Date[]): boolean => {
    const uniqueDates = Array.from(
      new Set(dates.map((date) => date.toDateString()))
    );
    return uniqueDates.length > 3;
  };

  const multipleDates = hasMultipleDates(dateObjects);

  const formattedData = label.map((timestamp) => {
    const date = dayjs(timestamp);
    const formattedDate = multipleDates
      ? date.format('DD/MM')
      : date.format('DD/MM HH:mm');
    return formattedDate;
  });

  const data = {
    labels: formattedData,
    datasets: [
      {
        data: dataOptions,
        borderColor,
        backgroundColor: borderColor,
        pointRadius: 0,
      },
    ],
  };

  const yAxisTickCallback = (value: number | string) => {
    return `${Number(value).toFixed(3)} ${dataSuffix}`;
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        backgroundColor: '#101828',
        callbacks: {
          title: () => {
            return '';
          },
          label: (context: any) => {
            if (context.dataset) {
              const yValue = context.parsed.y;
              return ` ${yValue.toFixed(2)} ${text}`;
            }
            return '';
          },
        },
      },
    },
    scales: {
      y: {
        ticks: {
          callback: yAxisTickCallback,
        },
      },
    },
  };
  const isOverlayVisible = dataOptions.length === 0 && label.length === 0;
  return (
    <div
      className="border border-base-border rounded-lg py-4 bg-bg-graphBg
     dark:bg-dark-base-bg dark:border-gray-800"
    >
      <div className="flex items-center ml-4">
        {' '}
        <div
          className="flex items-center justify-center
          w-6 h-6 rounded-full bg-feedback-info-bg dark:bg-dark-base-fg"
        >
          {icon}
        </div>
        <span className="text-base font-semibold ml-2">{text}</span>
      </div>
      <div className="text-3xl font-medium mt-3 ml-4">
        {contentLoading ? (
          <div className="flex items-center">
            <Skeleton width={180} height={30} containerClassName="flex" />
          </div>
        ) : (
          content
        )}
      </div>
      <span
        className="text-xs font-normal mt-1 ml-4
        text-base-heading-text-color dark:text-dark-base-heading-text-color"
      >
        Total Usage
      </span>
      <div className="bg-base-bg h-0.5 my-5 w-full dark:bg-dark-base-bg" />
      <div
        className={`relative ${
          isOverlayVisible ? 'opacity-40' : 'opacity-100'
        }`}
      >
        <div className="ml-4">
          {chartLoading ? (
            <Skeleton width={655} height={300} containerClassName="flex" />
          ) : (
            <Line options={options} data={data} />
          )}
        </div>

        <div className="flex items-center my-6 ml-10">
          <span className="flex flex-1 items-center">
            <span
              className="w-7 h-3"
              style={{ backgroundColor: borderColor }}
            />
            <span className="ml-4 font-normal text-xs">{text}</span>
          </span>
        </div>
        {isOverlayVisible && (
          <div
            className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center
          w-full h-full"
          >
            <div
              className="w-full h-full justify-center items-center flex opacity-100
              text-base-heading-text-color dark:text-dark-base-heading-text-color"
            >
              No Data Point yet!
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MonitoringChartCard;
