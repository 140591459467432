import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loader from '../../../../components/Compute/Loaders/loader';
import { isDarkTheme } from '../../../../redux/compute/theme/theme.utils';
import { RootState } from '../../../../redux/compute/store';

const LoginConnection = () => {
  const params = useParams<{ provider: string; userId: string }>();
  const theme = useSelector((state: RootState) => state.theme.theme);
  const loginConnectionWithProvider = async () => {
    import('../../../../config').then((config: any) => {
      // eslint-disable-next-line max-len
      const loginConnectionUrl = `${config.default.urls.API_URL}/auth/${
        params.provider
      }/new-login-connection?token=${localStorage.getItem('jwt-token')}`;
      window.open(loginConnectionUrl, '_self');
    });
  };

  useEffect(() => {
    loginConnectionWithProvider();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`h-screen w-screen ${
        isDarkTheme(theme) && 'bg-feedback-default-bg'
      }`}
    >
      <Loader />
    </div>
  );
};

export default LoginConnection;
