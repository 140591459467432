/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { EmptyState } from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../redux/compute/store';
import {
  ClusterType,
  IInstancePlan,
  IInstancePlans,
  LoadingType,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.interfaces';
import PlanTile from '../PlanTile';
import {
  getInstancePlansEndRtk,
  updateComputeStepRtk,
  updateCustomCpuRtk,
  updateCustomRamRtk,
  updateMultiServiceCustomCpu,
  updateMultiServiceCustomRam,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.slice';
import PlanTileLoading from '../../../../../components/Compute/Loaders/plan-tile-loading';
import { useQuery } from '../../../../../hooks/useQuery';
import {
  calculatePriceThunk,
  multiserviceCalculatePriceThunk,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.thunks';
import { sliceStringFromEnd } from '../../../../../redux/compute/subscription/subscription.utils';
import ShowMore from '../../../../../components/Compute/Buttons/show-more';
import { ReactComponent as Empty } from '../../../../../assets/compute/global/no-invite.svg';
// eslint-disable-next-line max-len
import { calculateInstancePrice } from '../../../../../redux/compute/instance/instance-creation/instance-creation.utils';

interface IProps {
  serviceId: string;
  isCancel: boolean;
  isUpdate: boolean;
  isConfirm: boolean;
  selectedInstancePlan: { serviceId: string; instancePlan: IInstancePlan };
  setSelectedInstancePlan: (plan: {
    serviceId: string;
    selectedInstancePlan: IInstancePlan | null;
  }) => void;
  // eslint-disable-next-line no-unused-vars
  setIsConfirm: (isConfirm: boolean) => void;
}

const MultiServicePlanTwo = ({
  serviceId,
  isCancel,
  isUpdate,
  selectedInstancePlan,
  setSelectedInstancePlan,
  isConfirm,
  setIsConfirm,
}: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();
  const queryParams = useQuery();
  const type = queryParams.get('type');
  const [recommendedPlan, setRecommendedPlan] = useState<IInstancePlan | null>(
    null
  );

  const { customCpu, customRam, customPlan } = useSelector(
    (state: RootState) =>
      state.instanceCreation.multiserviceInstanceCreation![serviceId]
  );

  const selectedOrganisationLoading = useSelector(
    (state: RootState) => state.organisation.selectedOrganisationLoading
  );
  const clusterScaling = useSelector(
    (state: RootState) => state.instanceCreation.clusterScaling
  );
  const unitPrices = useSelector(
    (state: RootState) => state.instanceCreation.unitPrices
  );
  const instancePlans: IInstancePlans = useSelector(
    (state: RootState) => state.instanceCreation.instancePlans
  );
  const instancePlanLoading = useSelector(
    (state: RootState) => state.instanceCreation.instancePlanLoading
  );
  const selectedTemplate = useSelector(
    (state: RootState) => state.cluster.selectedTemplate
  );
  const clusterType = useSelector(
    (state: RootState) => state.instanceCreation.clusterType
  );
  const activeDeployment = useSelector(
    (state: RootState) => state.instance.activeDeployment
  );
  const clusterRegion = useSelector(
    (state: RootState) => state.instanceCreation.clusterRegion
  );

  const scalingType = useSelector(
    (state: RootState) => state.instanceCreation.clusterScaling
  );

  const activeBonusLoading = useSelector(
    (state: RootState) => state.subscription.activeBonusLoading
  );

  const recommendedPlanById = useSelector(
    (state: RootState) => state.instanceCreation.recommendedPlan
  );
  const recommendedPlanLoading = useSelector(
    (state: RootState) => state.instanceCreation.recommendedPlanLoading
  );
  const instancePlanPaginationLoading = useSelector(
    (state: RootState) => state.instanceCreation.instancePlanPaginationLoading
  );

  const [shownNoOfPlans, setShownNoOfPlans] = useState<number>(7);

  const loadMoreInstances = () => {
    // dispatchRtk(loadMoreInstancePlanThunk());
    setShownNoOfPlans(shownNoOfPlans + 7);
  };

  const monthlyCostUSD = (price: number) => {
    return (Number(price) * 30 || 0).toFixed(2);
  };

  const hourlyCostUSD = (price: number) => {
    return (Number(price) / 24 || 0).toFixed(2);
  };

  const handleTileClick = (plan: IInstancePlan) => {
    setSelectedInstancePlan({ serviceId, selectedInstancePlan: plan });
    dispatchRtk(
      updateMultiServiceCustomCpu({
        serviceId,
        value: String(plan.cpu),
      })
    );
    dispatchRtk(
      updateMultiServiceCustomRam({
        serviceId,
        value: String(sliceStringFromEnd(plan.memory as string, 2)),
      })
    );

    dispatchRtk(
      multiserviceCalculatePriceThunk({
        serviceId,
        loadingType: LoadingType.CUSTOM_PLAN,
      })
    );
    dispatchRtk(updateComputeStepRtk(4));
  };
  const allPricesNotZero = instancePlans?.akashMachineImages?.every(
    (plan) => plan.price !== 0
  );
  useEffect(() => {
    if (
      !activeDeployment &&
      clusterType === ClusterType.TEMPLATE &&
      !isCancel &&
      instancePlans &&
      selectedInstancePlan
    ) {
      setSelectedInstancePlan({
        serviceId,
        selectedInstancePlan:
          instancePlans?.akashMachineImages?.find(
            (image) => image._id === selectedInstancePlan?.instancePlan?._id
          ) || instancePlans?.akashMachineImages[0],
      });

      dispatchRtk(
        updateMultiServiceCustomCpu({
          serviceId,
          value: String(instancePlans.akashMachineImages[0]?.cpu),
        })
      );
      dispatchRtk(
        updateMultiServiceCustomRam({
          serviceId,
          value: String(
            sliceStringFromEnd(
              instancePlans.akashMachineImages[0]?.memory as string,
              2
            )
          ),
        })
      );
      dispatchRtk(calculatePriceThunk(LoadingType.CUSTOM_PLAN));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clusterType, recommendedPlanById]);
  useEffect(() => {
    if (isCancel) {
      // NEED TO TEST OUT THIS CASE
      // setSelectedInstancePlan(null);
      setRecommendedPlan(null);
    }
    if (
      !isUpdate &&
      !instancePlanLoading &&
      !isCancel &&
      !activeBonusLoading &&
      !recommendedPlanLoading
    ) {
      if (
        customCpu === '' &&
        customRam === '' &&
        customPlan &&
        customPlan.price &&
        customPlan.price.toFixed(2) > '0.00' &&
        customPlan.memory !== '' &&
        !isCancel &&
        isConfirm
      ) {
        setSelectedInstancePlan({
          serviceId,
          selectedInstancePlan: customPlan,
        });
        setRecommendedPlan(customPlan);
        setIsConfirm(false);
      }
      if (
        !selectedInstancePlan &&
        !instancePlanLoading &&
        !activeBonusLoading &&
        !recommendedPlanLoading &&
        clusterType === ClusterType.DOCKER
      ) {
        if (instancePlans.akashMachineImages[0]?.price !== 0) {
          setSelectedInstancePlan({
            serviceId,
            selectedInstancePlan: instancePlans.akashMachineImages[0],
          });
          dispatchRtk(
            updateCustomCpuRtk(String(instancePlans.akashMachineImages[0]?.cpu))
          );
          if (instancePlans.akashMachineImages[0]?.memory) {
            dispatchRtk(
              updateCustomRamRtk(
                String(
                  sliceStringFromEnd(
                    instancePlans.akashMachineImages[0]?.memory as string,
                    2
                  )
                )
              )
            );
          }
          dispatchRtk(calculatePriceThunk(LoadingType.CUSTOM_PLAN));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeBonusLoading,
    instancePlanLoading,
    recommendedPlanLoading,
    selectedTemplate,
    customCpu,
    customRam,
    customPlan,
    instancePlans,
    isCancel,
    clusterType,
    allPricesNotZero,
    clusterRegion,
    scalingType,
    recommendedPlanById,
    isConfirm,
  ]);

  useEffect(() => {
    if (
      !isUpdate &&
      !instancePlanLoading &&
      !recommendedPlanLoading &&
      !selectedInstancePlan
    ) {
      if (clusterType === ClusterType.TEMPLATE) {
        const id = selectedTemplate?.services.find(
          (service) => service._id === serviceId
        )?._id;
        const foundPlan = instancePlans.akashMachineImages.find(
          (plan) => plan._id === id
        );
        setSelectedInstancePlan({
          serviceId,
          selectedInstancePlan: foundPlan || null,
        });
      } else {
        setSelectedInstancePlan({
          serviceId,
          selectedInstancePlan: instancePlans.akashMachineImages[0],
        });
      }
    }
  }, [
    clusterType,
    instancePlanLoading,
    recommendedPlanLoading,
    instancePlans.akashMachineImages,
    isUpdate,
    recommendedPlan,
    selectedInstancePlan,
    selectedTemplate,
    setSelectedInstancePlan,
    serviceId,
  ]);

  const recommendedId = selectedTemplate?.services?.find(
    (service) => service._id === serviceId
  )?.defaultAkashMachineImageId;

  const sortedInstancePlans = () => {
    const filteredInstanceImages = instancePlans?.akashMachineImages.filter(
      (image) => {
        if (
          image._id.includes('custom-id') &&
          image._id.slice(10) !== serviceId
        )
          return false;
        return true;
      }
    );

    const index = filteredInstanceImages?.findIndex(
      (image) => image?._id === recommendedId
    );
    const foundElement = filteredInstanceImages?.find(
      (image) => image?._id === recommendedId
    )!;
    const tempInstancePlan = [...filteredInstanceImages];

    if (index !== -1) {
      tempInstancePlan.splice(index, 1);

      tempInstancePlan.unshift(foundElement);
      return tempInstancePlan;
    }
    return [...filteredInstanceImages];
  };

  const customPlanIndex = sortedInstancePlans().findIndex((plan) =>
    plan._id.includes('custom-id')
  );

  if (
    customPlan &&
    customCpu === '' &&
    customRam === '' &&
    customPlan.price !== undefined &&
    customPlan.price.toFixed(2) > '0.00' &&
    customPlan.memory !== '' &&
    !isCancel &&
    isConfirm
  ) {
    if (customPlanIndex !== -1) {
      sortedInstancePlans().splice(customPlanIndex, 1);
    } else {
      sortedInstancePlans().unshift(customPlan);
    }
    setIsConfirm(false);
  }

  if (
    customPlan &&
    customCpu !== '' &&
    customRam !== '' &&
    customPlan.price !== undefined &&
    customPlan.price.toFixed(2) > '0.00' &&
    customPlan.memory !== '' &&
    !isCancel &&
    isConfirm
  ) {
    sortedInstancePlans().splice(customPlanIndex, 1);
    sortedInstancePlans().unshift(customPlan);
    setIsConfirm(false);
  }

  const isLoadingCondition =
    type === 'redeploy'
      ? instancePlanLoading || instancePlans.totalCount === 0
      : instancePlanLoading && instancePlans.totalCount === 0;

  useEffect(() => {
    const foundService = activeDeployment?.services.find(
      (service) => service.template?._id === serviceId
    );
    if (isUpdate && !isCancel && foundService) {
      const addedCustomPlan = instancePlans.akashMachineImages.find(
        (plan: IInstancePlan) => plan._id === `custom-id-${serviceId}`
      );
      if (activeDeployment?.updatedAt) {
        if (
          !foundService.agreedMachineImage.machineId &&
          !instancePlanLoading &&
          !addedCustomPlan
        ) {
          dispatchRtk(
            updateMultiServiceCustomCpu({
              serviceId,
              value: String(foundService.agreedMachineImage.cpu),
            })
          );
          dispatchRtk(
            updateMultiServiceCustomRam({
              serviceId,
              value: String(
                sliceStringFromEnd(
                  foundService.agreedMachineImage.memory as string,
                  2
                )
              ),
            })
          );
          const unitPrice = unitPrices?.find((unitPrice) => {
            return (
              unitPrice.region === clusterRegion &&
              unitPrice.scalingMode === clusterScaling
            );
          })!;
          const cpuMemoryPrice = calculateInstancePrice({
            cpu: unitPrice?.cpu / 30,
            numCpu: Number(foundService.agreedMachineImage.cpu),
            memory: unitPrice?.memory / 30,
            numMemory: Number(
              sliceStringFromEnd(
                foundService.agreedMachineImage.memory as string,
                2
              )
            ),
          });

          const customPlan: IInstancePlan = {
            cpu: foundService.agreedMachineImage.cpu,
            defaultDailyTopUp: cpuMemoryPrice,
            maxPricePerBlock: foundService.agreedMachineImage.maxPricePerBlock,
            memory: foundService.agreedMachineImage.memory,
            name: 'Custom Plan',
            _id: `custom-id-${serviceId}`,
            price: cpuMemoryPrice,
            storage: foundService.agreedMachineImage.storage,
            topupThreashold: foundService.agreedMachineImage.topupThreashold,
            recommendedPlan: false,
          };
          const updatedAkashMachineImages = [
            customPlan,
            ...instancePlans.akashMachineImages,
          ];
          const updatedInstancePlans = {
            totalCount: updatedAkashMachineImages.length,
            akashMachineImages: updatedAkashMachineImages,
          };
          dispatchRtk(getInstancePlansEndRtk(updatedInstancePlans));
          const updatedCustomPlan =
            updatedInstancePlans?.akashMachineImages.find((plan) =>
              plan._id.includes('custom-id')
            );
          setSelectedInstancePlan({
            serviceId,
            selectedInstancePlan: customPlan as IInstancePlan,
          });
          dispatchRtk(
            multiserviceCalculatePriceThunk({
              serviceId,
              loadingType: LoadingType.CUSTOM_PLAN,
            })
          );
        } else if (
          !instancePlanLoading &&
          !recommendedPlanLoading &&
          !Object.keys(customPlan).length
        ) {
          const findPlan = instancePlans.akashMachineImages.find(
            (image) => image._id === foundService.agreedMachineImage.machineId
          );
          setSelectedInstancePlan({
            serviceId,
            selectedInstancePlan:
              findPlan || instancePlans.akashMachineImages[0],
          });
          const planIndex = instancePlans.akashMachineImages.findIndex(
            (plan) => plan._id === findPlan?._id
          );
          if (shownNoOfPlans < planIndex) {
            setShownNoOfPlans((prev) => prev + 7);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clusterRegion,
    activeDeployment,
    instancePlanLoading,
    recommendedPlan,
    recommendedPlanLoading,
    instancePlans,
  ]);

  return (
    <div>
      {selectedOrganisationLoading ||
      isLoadingCondition ||
      instancePlanLoading ||
      recommendedPlanLoading ||
      activeBonusLoading ||
      recommendedPlanLoading ? (
        <div
          className={`grid grid-cols-1  
           gap-6 mt-6 ${
             isUpdate
               ? 'grid-cols-2'
               : 'll:grid-cols-4 xl:grid-cols-3 md:grid-cols-2 col-span-3'
           }`}
        >
          {[...Array(8)].map((item) => (
            <PlanTileLoading key={item} />
          ))}
        </div>
      ) : (
        <div className="mt-6">
          {!instancePlanLoading && instancePlans.totalCount === 0 ? (
            <EmptyState
              entityTitle="Sorry No Match Found!"
              // eslint-disable-next-line max-len
              subText="Please select another plan that suits your organization's needs or contact the Spheron team."
              showButton={false}
              buttonDisabled={false}
              onClick={() => {}}
              icon={<Empty />}
            />
          ) : (
            <div
              className={`mt-6 grid gap-6 ${
                isUpdate
                  ? 'grid-cols-2'
                  : 'll:grid-cols-4 xl:grid-cols-3 md:grid-cols-2 col-span-3'
              }`}
            >
              {sortedInstancePlans()
                ?.slice(0, shownNoOfPlans)
                .map((plan) => {
                  if (Object.keys(plan).length > 0) {
                    return (
                      <PlanTile
                        gpu={null}
                        icon={null}
                        key={plan._id}
                        title={`${
                          plan._id.includes('custom-id')
                            ? 'Custom Plan'
                            : plan.name
                        }`}
                        monthPrice={monthlyCostUSD(plan.price)}
                        hourlyPrice={hourlyCostUSD(plan.price)}
                        ramGb={plan.memory}
                        cpuQuantity={plan.cpu}
                        isSelected={
                          plan._id === selectedInstancePlan?.instancePlan?._id
                        }
                        onSelect={() => handleTileClick(plan)}
                        isCustomStyle={plan._id.includes('custom-id')}
                        recommended={plan._id === recommendedId}
                        disabled={false}
                        scalingType={scalingType}
                      />
                    );
                  }
                  return null;
                })}
            </div>
          )}
        </div>
      )}
      {shownNoOfPlans < instancePlans.totalCount && (
        <ShowMore
          title="Show More"
          handleClick={() => loadMoreInstances()}
          loading={instancePlanPaginationLoading}
        />
      )}
    </div>
  );
};

export default MultiServicePlanTwo;
