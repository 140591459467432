import * as Sentry from '@sentry/react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFilecoinPinDetails, getIpfsPinDetails } from './pinning.services';

export const fetchFilecoinPiningDetailsThunk = createAsyncThunk(
  'pinning/fetchFilecoinPiningDetails',
  async (payload: string, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await getFilecoinPinDetails(payload);
      if (!response.error) {
        return fulfillWithValue(response);
      }
      return rejectWithValue({ cid: payload, isPinned: false });
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({ cid: payload, isPinned: false });
    }
  }
);

export const fetchIpfsPiningDetailsThunk = createAsyncThunk(
  'pinning/fetchIpfsPiningDetails',
  async (payload: string, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await getIpfsPinDetails(payload);
      if (!response.error) {
        return fulfillWithValue(response);
      }
      return rejectWithValue({ cid: payload, isPinned: false });
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({ cid: payload, isPinned: false });
    }
  }
);
