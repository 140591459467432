import config from '../../config';
import { NodeVersion } from './combined-state.interface';

export type RequestMethods = 'GET' | 'PUT' | 'PATCH' | 'POST' | 'DELETE';

export const requestPipeline = async ({
  url,
  method,
  body = undefined,
  isPublic = false,
  isUploadApi = false,
}: {
  url: string;
  body?: string;
  method: RequestMethods;
  isPublic?: boolean;
  isUploadApi?: boolean;
}) => {
  if (
    localStorage.getItem('jwt-token') ||
    isPublic ||
    sessionStorage.getItem('isPublicAccessible') === 'true'
  ) {
    const headers: any = {
      'Content-Type': 'application/json',
    };
    if (localStorage.getItem('jwt-token')) {
      headers.Authorization = `Bearer ${localStorage.getItem('jwt-token')}`;
    }
    const res = await fetch(
      `${
        isUploadApi ? config.urls.API_UPLOAD_URL : config.urls.API_URL
      }/${url}`,
      {
        method,
        headers,
        body,
      }
    );
    if (res.status === 204) {
      return;
    }
    if (res.status === 401 && !isPublic) {
      localStorage.removeItem('jwt-token');
      localStorage.setItem('referrer', 'true');
      window.open(`${window.location.origin}/login`, '_self');
    }
    // eslint-disable-next-line consistent-return
    return res.json();
  }
};

export const capitalizeFirstLetter = (str: string): string => {
  return str ? str[0].toUpperCase() + str.slice(1) : '';
};

export const withLoader = (
  loading: boolean,
  loader: JSX.Element,
  element: JSX.Element
): JSX.Element => {
  if (loading) {
    return loader;
  }
  return element;
};

export const delay = (miliseconds: number): Promise<void> => {
  return new Promise((resolve) =>
    setTimeout(() => {
      return resolve();
    }, miliseconds)
  );
};

export const getCountries = (lang = 'en') => {
  const A = 65;
  const Z = 90;
  const countryName = new Intl.DisplayNames([lang], { type: 'region' });
  const countries: any = {};
  for (let i = A; i <= Z; i += 1) {
    for (let j = A; j <= Z; j += 1) {
      const code = String.fromCharCode(i) + String.fromCharCode(j);
      const name = countryName.of(code);
      if (code !== name) {
        countries[code] = name;
      }
    }
  }
  return countries;
};

export const downloadFile = (file: string) => {
  fetch(file)
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', file.substring(file.lastIndexOf('/') + 1));
      link.click();
    });
};

export const parseNodeVersion = (nodeVersion: string) => {
  switch (nodeVersion) {
    case 'V_12':
      return NodeVersion.V_12;
    case 'V_16':
      return NodeVersion.V_16;
    case 'V_18':
      return NodeVersion.V_18;
    case 'V_14':
    default:
      return NodeVersion.V_14;
  }
};

export const nodeVersionDropdownOptions = [
  { label: 'Node V12 LTS', value: 'V_12' },
  { label: 'Node V14 LTS', value: 'V_14' },
  { label: 'Node V16 LTS', value: 'V_16' },
  { label: 'Node V18 LTS', value: 'V_18' },
];

export const createUsername = (orgName: string) => {
  return orgName?.replace(/\W+/g, '-').toLowerCase() || '';
};

export const validateEmail = (email: string) => {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,6}$/;
  return regex.test(email);
};

export const deepEqual = (obj1: any, obj2: any): boolean => {
  if (obj1 === obj2) {
    return true;
  }

  if (
    typeof obj1 !== 'object' ||
    obj1 === null ||
    typeof obj2 !== 'object' ||
    obj2 === null
  ) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) {
    return false;
  }

  return keys1.every((key: string) => {
    return keys2.includes(key) && deepEqual(obj1[key], obj2[key]);
  });
};

export const uniqueArray = (originalArray: string[]) =>
  originalArray.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });

export const toggleListLoading = (
  list: string[],
  payload: {
    loading: boolean;
    domainId: string;
  }
) => {
  return payload.loading === true
    ? [...list, payload.domainId]
    : list.filter((d) => d !== payload.domainId);
};

export const truncateText = (
  text: string,
  firstHalfLength?: number,
  lastHalfLength?: number
) => {
  if (text?.length <= 20) {
    return text;
  }
  return `${text?.slice(0, firstHalfLength || 20)}....${text?.slice(
    -(lastHalfLength || 4)
  )}`;
};
