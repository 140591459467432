/* eslint-disable max-len */
import React from 'react';
import { ConfirmationModal, Modal } from '@spheron/ui-library';
import { useDispatch } from 'react-redux';
import Styles from '../../../styles/compute/modal.module.scss';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import ModalWrapper from './ModalWrapper';
import { AppDispatch } from '../../../redux/compute/store';

interface IProps {
  isOpen: boolean;
}

function ReferralRules({ isOpen }: IProps) {
  const dispatchRtk = useDispatch<AppDispatch>();

  const referralRules = [
    {
      id: 1,
      heading: 'Referral Bonus Structure',
      bulletPoints: [
        {
          id: 1,
          title: 'Get Rewarded for Referring',
          content:
            'When you refer a new user to our platform, you are eligible for a referral bonus.',
          list: null,
          link: null,
        },
        {
          id: 2,
          title: 'How Much Can You Earn?',
          content:
            'The referral bonus is 10% of the amount the referred person spends on their first purchase, with a maximum bonus of $10.',
          list: [
            {
              id: 1,
              title: 'Example 1',
              content: 'If your referral spends $100, you receive a $10 bonus.',
            },
            {
              id: 2,
              title: 'Example 2',
              content:
                'For a $200 spend by your referral, you still receive a $10 bonus (since the bonus caps at $10).',
            },
            {
              id: 3,
              title: 'Example 3',
              content:
                'If your referral spends $20, your bonus is $2 (10% of $20).',
            },
          ],
          link: null,
        },
      ],
    },
    {
      id: 2,
      heading: 'Usage of Bonus',
      bulletPoints: [
        {
          id: 1,
          title: 'Exclusively for Compute Credits',
          content:
            "The bonus you earn can only be used to purchase or top up compute credits on our platform. It's a great way to save on your future uses of our protocols.",
          list: null,
          link: null,
        },
        {
          id: 2,
          title: 'Non-Transferable',
          content:
            'The bonus is credited to your account and cannot be transferred to cash or other forms of credits outside of our platform.',
          list: null,
          link: null,
        },
      ],
    },
    {
      id: 3,
      heading: 'For the Referred Person',
      bulletPoints: [
        {
          id: 1,
          title: 'No Direct Reward',
          content:
            "It's important to note that the person who is referred does not receive a bonus or discount on their first purchase.",
          list: null,
          link: null,
        },
        {
          id: 2,
          title: 'Benefit of Joining',
          content:
            "While they don't receive a direct reward, they get access to our quality services and the opportunity to participate in the referral program themselves once they are an established user.",
          list: null,
          link: null,
        },
      ],
    },
    {
      id: 4,
      heading: 'How to Participate',
      bulletPoints: [
        {
          id: 1,
          title: 'Refer a Friend',
          content:
            'Share your unique referral code or link with your friends or network.',
          list: null,
          link: null,
        },
        {
          id: 2,
          title: 'They Sign Up & Purchase',
          content:
            'Your referral needs to sign up and make their first purchase.',
          list: null,
          link: null,
        },
        {
          id: 3,
          title: 'Earn Your Bonus',
          content:
            'After their purchase, the 10% bonus (up to $10) is credited to your account.',
          list: null,
          link: null,
        },
      ],
    },
    {
      id: 5,
      heading: 'Why Participate?',
      bulletPoints: [
        {
          id: 1,
          title: 'Save on Compute Credits',
          content:
            'By accumulating bonuses, you can significantly save on your future purchases of compute credits.',
          list: null,
          link: null,
        },
        {
          id: 2,
          title: 'Support and Grow the Community',
          content:
            'Referring new users helps grow the community, enhancing the overall experience and service for everyone.',
          list: null,
          link: null,
        },
      ],
    },
  ];

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={() => dispatchRtk(toggleModalShowRtk({ modalShow: false }))}
      classname={`${Styles.modal__outer__con} w-430 modal fixed inset-0 overflow-y-auto`}
    >
      <div className={Styles.modal__inner__content}>
        <Modal
          footer
          header
          subtitle=""
          modalContent={
            <ul className="flex flex-col gap-4 max-h-[520px] overflow-y-scroll">
              <h1 className="text-left">
                Our Referral Program is designed to reward our loyal customers
                for spreading the word about our protocol. Here&apos;s how it
                works:
              </h1>
              {referralRules.map((rule) => (
                <li key={rule.id}>
                  <p className="font-700 text-lg">{rule.heading}</p>
                  {rule.bulletPoints && (
                    <ul className="mt-2 ml-8 flex flex-col gap-4">
                      {rule.bulletPoints.map((list) => (
                        <li key={list.id}>
                          &#8226; <b>{list.title}: </b>
                          {list.content}
                          {list.list && (
                            <ul className="mt-3 ml-6 flex flex-col gap-3">
                              {list.list.map((list) => (
                                <li key={list.id} className="text-md">
                                  &#9702; <b>{list.title}: </b>
                                  {list.content}
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
              <h1 className="text-left mt-2">
                We believe this program is a great way for our users to benefit
                from sharing their positive experiences with others. We
                appreciate your support and are excited to see our community
                grow!
              </h1>
            </ul>
          }
          headerContent={<>Spheron Referral Program</>}
          primaryButtonContent="Got it!"
          secondaryButtonContent=""
          primaryButton
          primaryButtonLoading={false}
          secondaryButtonDisabled={false}
          secondaryButton={false}
          onPrimaryButtonClick={() =>
            dispatchRtk(toggleModalShowRtk({ modalShow: false }))
          }
          confirmationStatus={ConfirmationModal.INFO}
        />
      </div>
    </ModalWrapper>
  );
}

export default ReferralRules;
