import React from 'react';
import dayjs from 'dayjs';
import { Badge, EmptyState, Table } from '@spheron/ui-library';
import { ReactComponent as NoInvoiceIcon } from '@spheron/ui-library/dist/assets/invoice-3.svg';
import Skeleton from 'react-loading-skeleton';
import { ReactComponent as Dash } from '@spheron/ui-library/dist/assets/minus-circle.svg';
import { ReactComponent as ClockIcon } from '@spheron/ui-library/dist/assets/clock-circle.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  IBonusPaymentInvoice,
  INewMemberPaymentInvoice,
  TransactionState,
} from '../../../redux/compute/subscription/subscription.interfaces';
import {
  calculateAmount,
  invoicesParser,
} from '../../../redux/compute/subscription/subscription.utils';
import {
  getNetworkConfig,
  getNetworkIcon,
  mapNetworkToWallet,
  providedTokens,
} from '../../../redux/compute/web3/web3.utils';
import CouponIcon from '../../../assets/compute/icons/coupon.svg';
import { ReactComponent as Tick } from '../../../assets/compute/icons/checkbox-circle-fill.svg';
import { ReactComponent as CreditIcon } from '../../../assets/compute/icons/credit-icon.svg';
import NetworkTokenSet from '../Misc/network-token-set';
import { PaymentMethod } from '../../../redux/compute/combined-state.interface';
import { getPaymentLogo } from '../../../redux/compute/stripe/stripe.utils';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';

const BillingInvoice = () => {
  const dispatch = useDispatch<AppDispatch>();
  const subscriptionInvoices = useSelector(
    (state: RootState) => state.subscription.subscriptionInvoices
  );

  const subscriptionInvoiceLoading = useSelector(
    (state: RootState) => state.subscription.subscriptionInvoicesLoading
  );

  const selectedOrganisationLoading = useSelector(
    (state: RootState) => state.organisation.selectedOrganisationLoading
  );

  const getIconFromTransaction = (type: TransactionState): JSX.Element => {
    switch (type) {
      case TransactionState.SUCCEEDED:
        return <Tick className="w-4 h-4 text-feedback-success-text" />;
      case TransactionState.DECLINED:
        return <Dash className="w-4 h-4 text-feedback-error-text" />;
      case TransactionState.PENDING:
        return <ClockIcon className="w-4 h-4 text-feedback-warning-text" />;
      default:
        return <>Succeded</>;
    }
  };

  return (
    <>
      {subscriptionInvoiceLoading || selectedOrganisationLoading ? (
        <Skeleton height={200} />
      ) : (
        <>
          {subscriptionInvoices.length > 0 ? (
            <Table
              tableHeader={{
                tableRowType: 'record',
                tableRow: [
                  {
                    id: 0,
                    title: 'Method',
                    tableCellType: 'header',
                  },
                  {
                    id: 1,
                    title: 'Amount',
                    tableCellType: 'header',
                  },
                  {
                    id: 2,
                    title: 'Status',
                    tableCellType: 'header',
                  },
                  {
                    id: 3,
                    title: 'Creation Date',
                    tableCellType: 'header',
                  },
                ],
              }}
              tableRows={subscriptionInvoices.map((invoice) => {
                const networkSet = new Set<number>();
                const paymentMethodSet = new Set<string>();
                const tokenNetworkMap = new Map<string, number>();
                const {
                  subscriptionInvoice,
                  bonusInvoices,
                  newMemberInvoices,
                  pendingBonusInvoice,
                } = invoicesParser(invoice);
                if (
                  subscriptionInvoice?.paymentMethod === PaymentMethod.CRYPTO
                ) {
                  networkSet.add(subscriptionInvoice.chainId);
                  tokenNetworkMap.set(
                    subscriptionInvoice.token,
                    subscriptionInvoice.chainId
                  );
                } else if (
                  subscriptionInvoice?.paymentMethod === PaymentMethod.COUPON
                ) {
                  tokenNetworkMap.set('coupon', 0);
                } else if (
                  subscriptionInvoice?.paymentMethod ===
                  PaymentMethod.SPHERONWALLET
                ) {
                  tokenNetworkMap.set('spheron_wallet', 1);
                } else if (
                  subscriptionInvoice?.paymentMethod ===
                  PaymentMethod.CREDITCARD
                ) {
                  paymentMethodSet.add(
                    subscriptionInvoice?.walletDetails?.info?.brand || ''
                  );
                  tokenNetworkMap.set('credit_card', -1);
                }

                bonusInvoices.forEach((bonus: IBonusPaymentInvoice) => {
                  if (bonus.paymentMethod === PaymentMethod.CRYPTO) {
                    networkSet.add(bonus.chainId);
                    tokenNetworkMap.set(bonus.token, bonus.chainId);
                  } else if (bonus.paymentMethod === PaymentMethod.COUPON) {
                    tokenNetworkMap.set('coupon', 0);
                  } else if (
                    bonus.paymentMethod === PaymentMethod.SPHERONWALLET
                  ) {
                    tokenNetworkMap.set('spheron_wallet', 1);
                  } else if (bonus.paymentMethod === PaymentMethod.CREDITCARD) {
                    paymentMethodSet.add(
                      bonus?.walletDetails?.info?.brand || ''
                    );
                    tokenNetworkMap.set('credit_card', -1);
                  }
                });

                newMemberInvoices.forEach(
                  (newMember: INewMemberPaymentInvoice) => {
                    if (newMember.paymentMethod === PaymentMethod.CRYPTO) {
                      networkSet.add(newMember.chainId);
                      tokenNetworkMap.set(newMember.token, newMember.chainId);
                    } else if (
                      newMember.paymentMethod === PaymentMethod.COUPON
                    ) {
                      tokenNetworkMap.set('coupon', 0);
                    } else if (
                      newMember.paymentMethod === PaymentMethod.SPHERONWALLET
                    ) {
                      tokenNetworkMap.set('spheron_wallet', 1);
                    } else if (
                      newMember.paymentMethod === PaymentMethod.CREDITCARD
                    ) {
                      paymentMethodSet.add(newMember?.cardDetails?.brand || '');
                      tokenNetworkMap.set('credit_card', -1);
                    }
                  }
                );
                return {
                  id: parseInt(invoice.subscription._id, 10),
                  tableRowType: 'record',
                  handleRowClick: () =>
                    dispatch(
                      toggleModalShowRtk({
                        modalType: 'invoices',
                        modalShow: true,
                        options: {
                          invoiceId: invoice.subscription._id,
                        },
                      })
                    ),

                  tableRow: [
                    {
                      id: 0,
                      content: (
                        <div
                          className="py-4 flex items-center justify-start 
                                       space-x-1 flex-wrap"
                        >
                          {Array.from(paymentMethodSet).map((payment) => {
                            return (
                              <img
                                key={payment}
                                src={getPaymentLogo(payment)}
                                alt="payment"
                                className="h-6 w-9"
                              />
                            );
                          })}
                          {Object.keys(Object.fromEntries(tokenNetworkMap)).map(
                            (tokenId) => {
                              if (tokenNetworkMap.get(tokenId) === 0) {
                                return (
                                  <img
                                    src={CouponIcon}
                                    alt="coupon-logo"
                                    className="h-5"
                                  />
                                );
                              }
                              if (tokenNetworkMap.get(tokenId) === 1) {
                                return (
                                  <CreditIcon
                                    className="w-8 h-8
                                        text-base-text dark:text-dark-base-text"
                                  />
                                );
                              }
                              if (tokenNetworkMap.get(tokenId) !== -1) {
                                return (
                                  <NetworkTokenSet
                                    network={getNetworkIcon(
                                      tokenNetworkMap.get(tokenId) || 80001
                                    )}
                                    token={
                                      providedTokens[
                                        mapNetworkToWallet(
                                          tokenNetworkMap.get(tokenId) || 80001
                                        )?.chainName || ''
                                      ][
                                        getNetworkConfig(
                                          mapNetworkToWallet(
                                            tokenNetworkMap.get(tokenId) ||
                                              80001
                                          )?.chainName || ''
                                        )?.TYPE
                                      ]?.find(
                                        (token) => token.address === tokenId
                                      )?.logoURI as string
                                    }
                                  />
                                );
                              }
                              return <></>;
                            }
                          )}
                        </div>
                      ),
                      tableCellType: 'default',
                    },
                    {
                      id: 1,
                      title: `$${calculateAmount(
                        subscriptionInvoice,
                        bonusInvoices,
                        newMemberInvoices
                      ).toFixed(2)}`,
                      tableCellType: 'title',
                    },
                    {
                      id: 2,
                      content: (
                        <Badge
                          badgeType={
                            pendingBonusInvoice.length > 0
                              ? 'warning'
                              : 'success'
                          }
                          solid={false}
                          size="large"
                          text={
                            pendingBonusInvoice.length > 0
                              ? 'Pending'
                              : 'Succeeded'
                          }
                          leftIcon={getIconFromTransaction(
                            pendingBonusInvoice.length > 0
                              ? TransactionState.PENDING
                              : TransactionState.SUCCEEDED
                          )}
                        />
                      ),
                      tableCellType: 'default',
                    },
                    {
                      id: 3,
                      title: dayjs(invoice.subscription.createdAt).format(
                        'MMM DD YYYY'
                      ),
                      tableCellType: 'title',
                    },
                  ],
                };
              })}
            />
          ) : (
            <div className="h-72">
              <EmptyState
                entityTitle="You don't have any Invoices yet"
                subText=""
                showButton={false}
                icon={<NoInvoiceIcon className="w-48 h-48" />}
                onClick={() => {}}
                buttonDisabled={false}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default BillingInvoice;
