/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../redux/compute/store';
import {
  ClusterType,
  IInstancePlan,
  InstanceDeploymentType,
  IPLeaseType,
  LoadingType,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.interfaces';
// import SearchComponent from '../SearchComponent';
import { useDebounce } from '../../../../../hooks/useDebounce';
import {
  IClusterTemplateVariable,
  IPorts,
  IServiceData,
} from '../../../../../redux/compute/cluster/cluster.interfaces';
import { IAutoscalingRequestPayload } from '../../../../../redux/compute/instance/instance.interfaces';
import { useQuery } from '../../../../../hooks/useQuery';
import SelectPlan from '../SelectPlan';
import {
  fetchCustomPlanThunk,
  getInstancePlanThunk,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.thunks';
import {
  chooseClusterTypeRtk,
  selectClusterRegionRtk,
  updateCustomCpuRtk,
  updateCustomNvmStorageRtk,
  updateCustomPersistentStorageRtk,
  updateCustomRamRtk,
  updateCustomStorageRtk,
  updateInstanceValueRtk,
  updateLoadMoreCurrentPageRtk,
  updateMultiServiceCustomCpu,
  updateMultiServiceCustomRam,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.slice';
import { mapBuildEnv } from '../../../../../redux/compute/instance/instance.utils';
import { setIsCustomPlanOpenRtk } from '../../../../../redux/compute/instance/instance.slice';
import ChooseGpuPlan from '../ChooseGpuPlan';
import { sliceStringFromEnd } from '../../../../../redux/compute/subscription/subscription.utils';
import MultiServiceAdvanceConfigurationSection from '../MultiServiceAdvanceConfigurationSection';
import MultiServiceSelectStorage from '../MultiServiceSelectStorage';
import MultiServicePlanOne from '../MultiServicePlanOne';
import MultiServicePlanTwo from '../MultiServicePlanTwo';
import MultiServiceIpLease from '../MultiServiceIpLease';
import { deepEqual } from '../../../../../redux/compute/root-utils';

interface IProps {
  id: string;
  isUpdate: boolean;
  selectedInstancePlan: { serviceId: string; instancePlan: IInstancePlan }[];
  setSelectedInstancePlan: (plan: {
    serviceId: string;
    selectedInstancePlan: IInstancePlan | null;
  }) => void;
  selectedGpuInstancePlan: IInstancePlan;
  setSelectedGpuInstancePlan: (
    selectedInstancePlan: IInstancePlan | null
  ) => void;
  setDisabled: (serviceId: string, isDisable: boolean) => void;
  setUpdateDisabled: (serviceId: string, isDisable: boolean) => void;
  serviceItem: IServiceData | null;
  setAdvanceConfig: (advanceConfig: any) => void;
  searchValue: string | null;
  setSearchValue: (searchValue: string | null) => void;
}

const MultiServicePlanGrid = ({
  id,
  isUpdate,
  selectedInstancePlan,
  setSelectedInstancePlan,
  selectedGpuInstancePlan,
  setSelectedGpuInstancePlan,
  setDisabled,
  setUpdateDisabled,
  serviceItem,
  setAdvanceConfig,
  searchValue,
  setSearchValue,
}: IProps) => {
  const newInstancePlan = useMemo(() => {
    return selectedInstancePlan?.find((plan) => plan.serviceId === id);
  }, [id, selectedInstancePlan]);

  const dispatchRtk = useDispatch<AppDispatch>();
  const queryParams = useQuery();
  const searchParams = new URLSearchParams(queryParams);
  const tag = searchParams.get('tag');
  const [templateEnv, setTemplateEnv] = useState<IClusterTemplateVariable[]>(
    []
  );
  const [healthUrl, setHealthUrl] = useState<string>('');
  const [healthPort, setHealthPort] = useState<string>('');
  const [portMapping, setPortMapping] = useState<IPorts[]>([
    {
      containerPort: '',
      exposedPort: '',
    },
  ]);
  const [buildEnv, setBuildEnv] = useState<
    { key: string; value: string; isSecret: boolean }[]
  >([]);
  const [secretBuildEnv, setSecretBuildEnv] = useState<
    { key: string; value: string; isSecret: boolean }[]
  >([]);
  const [buildArgs, setBuildArgs] = useState<string[]>([]);
  const [buildCommands, setBuildCommands] = useState<string[]>([]);
  const [toggleOn, setToggleOn] = useState<boolean>(false);
  const [isCancel, setIsCancel] = useState<boolean>(false);
  const [isConfirm, setIsConfirm] = useState<boolean>(false);
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const [imageTag, setImageTag] = useState<string>('');
  const [persistentToggleOn, setPersistentToggleOn] = useState<boolean>(false);
  const [showCustomAutoScaling, setShowCustomAutoScaling] =
    useState<boolean>(false);
  const [autoScalingPolicy, setAutoScalingPolicy] =
    useState<IAutoscalingRequestPayload>({
      maximumInstances: 5,
      minimumInstances: 1,
      plan: '',
      timeWindow: '',
      cooldown: '',
      scaleUp: null,
      scaleDown: null,
    });

  useMemo(() => {
    const advanceConfig = {
      serviceId: serviceItem?._id,
      buildArgs,
      buildCommands,
      templateEnv,
      portMapping,
      healthUrl,
      healthPort,
      buildEnv,
      secretBuildEnv,
      autoScalingPolicy,
      showCustomAutoScaling,
    };
    setAdvanceConfig(advanceConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    buildArgs,
    buildCommands,
    buildEnv,
    healthPort,
    healthUrl,
    portMapping,
    secretBuildEnv,
    serviceItem,
    templateEnv,
    autoScalingPolicy,
    showCustomAutoScaling,
  ]);

  const handleToggleChange = () => {
    setToggleOn(!toggleOn);
    setIsCancel(true);
  };
  const selectedInstance = useSelector(
    (state: RootState) => state.instance.selectedInstance
  );
  const activeDeployment = useSelector(
    (state: RootState) => state.instance.activeDeployment
  );
  const activeDeploymentLoading = useSelector(
    (state: RootState) => state.instance.activeDeploymentLoading
  );
  const selectedTemplate = useSelector(
    (state: RootState) => state.cluster.selectedTemplate
  );
  const computeStep = useSelector(
    (state: RootState) => state.instanceCreation.computeStep
  );
  const selectedTags = useSelector(
    (state: RootState) => state.cluster.selectedCluster?.tags
  );
  const clusterType = useSelector(
    (state: RootState) => state.instanceCreation.clusterType
  );
  const multiserviceInstance = useSelector(
    (state: RootState) =>
      state.instanceCreation.multiserviceInstanceCreation![id]
  );
  const instanceDeploymentLoading = useSelector(
    (state: RootState) => state.instance.deployingInstance
  );
  const instancePlans = useSelector(
    (state: RootState) => state.instanceCreation.instancePlans
  );
  const isTemplate = useSelector(
    (state: RootState) => state.cluster.clusterDetails?.clusterTemplate || true
  );
  const deploymentType = useSelector(
    (state: RootState) => state.instanceCreation.deploymentType
  );
  const planSearch = useSelector(
    (state: RootState) => state.instanceCreation.planSearch
  );
  const clusterScalingName = useSelector(
    (state: RootState) => state.instanceCreation.clusterScalingName
  );
  const clusterRegion = useSelector(
    (state: RootState) => state.instanceCreation.clusterRegion
  );
  const persistentStorage =
    (multiserviceInstance?.customSsdStorage > 0 &&
      multiserviceInstance?.customSsdStorage) ||
    (multiserviceInstance?.customHddStorage > 0 &&
      multiserviceInstance?.customHddStorage) ||
    (multiserviceInstance?.customNvmStorage > 0 &&
      multiserviceInstance?.customNvmStorage);

  const debouncedSearchTerm = useDebounce(searchValue, 500);

  useEffect(() => {
    if (debouncedSearchTerm !== null) {
      dispatchRtk(
        getInstancePlanThunk({
          search: debouncedSearchTerm as string,
        })
      );
    }
  }, [debouncedSearchTerm, dispatchRtk]);

  useEffect(() => {
    if (planSearch === '' && searchValue && searchValue.trim() !== '') {
      setSearchValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planSearch]);

  const foundService = activeDeployment?.services.find(
    (service) => service.template?._id === id
  );

  useEffect(() => {
    if (isUpdate && foundService && !activeDeploymentLoading) {
      dispatchRtk(selectClusterRegionRtk(foundService?.region || ''));
    }
  }, [
    isUpdate,
    dispatchRtk,
    activeDeploymentLoading,
    instancePlans.akashMachineImages.length,
    foundService,
  ]);

  const initEnvMap = useRef<any>(null);
  useEffect(() => {
    if (isUpdate && foundService) {
      if (foundService?.serviceCount) {
        dispatchRtk(updateInstanceValueRtk(foundService?.serviceCount));
      }
      if (foundService?.tag) {
        setImageTag(foundService?.tag);
      }
      if (isTemplate || isUpdate) {
        dispatchRtk(chooseClusterTypeRtk(ClusterType.TEMPLATE));
        setTemplateEnv(
          foundService?.env.map((variable: any) => ({
            ...variable,
            name: variable.name,
          }))
        );
        if (foundService?.ports.length > 0) {
          setPortMapping(foundService?.ports);
        }
        if (foundService?.env) {
          const envMap = foundService?.env.map(
            (env: { value: string; isSecret: boolean }) => {
              // regex to split only at the first occurrence of '='
              const envKeyValue = env?.value?.split(/=(.*)/s);
              return {
                key: envKeyValue[0],
                value: envKeyValue[1],
                isSecret: env.isSecret,
              };
            }
          );
          const secretEnv = envMap.filter((env: any) => env.isSecret);
          const nonSecretEnv = envMap.filter((env: any) => !env.isSecret);
          setSecretBuildEnv(secretEnv);
          setBuildEnv(nonSecretEnv);
        }
        if (foundService.variables) {
          setTemplateEnv(
            foundService?.variables.map((variable) => ({
              ...variable,
              name: variable.name,
            }))
          );
        }
        if (foundService.command) {
          setBuildCommands(foundService.command);
        }
        if (foundService.args) {
          setBuildArgs(foundService.args);
        }
      } else {
        dispatchRtk(chooseClusterTypeRtk(ClusterType.DOCKER));
        if (foundService.ports.length > 0) {
          setPortMapping(foundService.ports);
        }
        if (foundService.env) {
          initEnvMap.current = foundService.env?.map(
            (env: { value: string; isSecret: boolean }) => {
              // regex to split only at the first occurence of '='
              const envKeyValue = env?.value?.split(/=(.*)/s);
              return {
                key: envKeyValue[0],
                value: envKeyValue[1],
                isSecret: env.isSecret,
              };
            }
          );

          setBuildEnv(initEnvMap.current);
        }
        if (foundService.command) {
          setBuildCommands(foundService.command);
        }

        if (foundService.args) {
          setBuildArgs(foundService.args);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTemplate, isUpdate, activeDeployment]);

  useEffect(() => {
    if (!isUpdate && selectedTemplate && clusterType === ClusterType.TEMPLATE) {
      const { variables, args, commands } =
        selectedTemplate.services?.find((service) => service._id === id) ||
        selectedTemplate.services[0];
      setTemplateEnv(
        variables.map((variable: any) => ({
          ...variable,
          defaultValue:
            variable.regionMap && clusterRegion !== 'any' && clusterRegion
              ? variable.regionMap[clusterRegion]
              : variable.defaultValue,
        }))
      );
      setBuildArgs(args);
      setBuildCommands(commands);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplate, clusterRegion]);

  useEffect(() => {
    dispatchRtk(updateLoadMoreCurrentPageRtk(0));
    return () => {
      dispatchRtk(updateCustomPersistentStorageRtk(''));
      dispatchRtk(updateCustomCpuRtk(''));
      dispatchRtk(updateCustomNvmStorageRtk(0));
      dispatchRtk(updateCustomRamRtk(''));
      dispatchRtk(updateCustomStorageRtk(0));
    };
  }, [dispatchRtk]);

  useEffect(() => {
    let hasEmptyFields = false;
    if (persistentToggleOn) {
      if (
        !multiserviceInstance?.customPersistentStorage ||
        multiserviceInstance?.mountPointValue === '' ||
        !persistentStorage
      ) {
        hasEmptyFields = true;
      }
    }
    if (!isUpdate) {
      const hasEmptyPort = portMapping.some(
        (item) => item.containerPort === '' || item.exposedPort === ''
      );
      hasEmptyFields = hasEmptyPort || hasEmptyFields;
      const hasEmptyPortCheck = portMapping.every(
        (item) => typeof item.isChecked === 'boolean' && !item.isChecked
      );

      const hasEmptyEndpointFields = portMapping.some(
        (item) =>
          typeof item.endpointExposedPort !== 'undefined' &&
          item.endpointExposedPort !== ''
      );
      if (
        hasEmptyPortCheck &&
        multiserviceInstance?.ipLeaseType === IPLeaseType.DEDICATED &&
        !hasEmptyEndpointFields
      ) {
        hasEmptyFields = true;
      }
    }
    if (clusterType === ClusterType.TEMPLATE) {
      const hasEmptyDefaultValue = Object.values(templateEnv).some(
        (object) => object.required && object.defaultValue === ''
      );
      hasEmptyFields = hasEmptyDefaultValue || hasEmptyFields;
    } else if (selectedTags === undefined || selectedTags === '') {
      hasEmptyFields = true;
    }
    if (instanceDeploymentLoading) {
      hasEmptyFields = true;
    }
    if (clusterScalingName === 'auto') {
      if (
        !showCustomAutoScaling &&
        (autoScalingPolicy.maximumInstances <
          autoScalingPolicy.minimumInstances ||
          autoScalingPolicy.plan === '' ||
          autoScalingPolicy.plan === 'custom' ||
          !autoScalingPolicy.maximumInstances ||
          !autoScalingPolicy.minimumInstances)
      ) {
        hasEmptyFields = true;
      } else if (
        showCustomAutoScaling &&
        (autoScalingPolicy.timeWindow === '' ||
          autoScalingPolicy.cooldown === '' ||
          !autoScalingPolicy?.scaleUp?.cpuThreshold.utilizationPercentage ||
          !autoScalingPolicy?.scaleUp?.cpuThreshold.step ||
          !autoScalingPolicy?.scaleUp?.memoryThreshold.utilizationPercentage ||
          !autoScalingPolicy?.scaleUp?.memoryThreshold.step ||
          !autoScalingPolicy?.scaleDown?.cpuThreshold.utilizationPercentage ||
          !autoScalingPolicy?.scaleDown?.cpuThreshold.step ||
          !autoScalingPolicy?.scaleDown?.memoryThreshold
            .utilizationPercentage ||
          !autoScalingPolicy?.scaleDown?.memoryThreshold.step)
      ) {
        hasEmptyFields = true;
      }
    }
    setIsDisable(hasEmptyFields);
    setDisabled(id, hasEmptyFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    templateEnv,
    clusterType,
    portMapping,
    multiserviceInstance?.mountPointValue,
    isDisable,
    instanceDeploymentLoading,
    selectedTags,
    persistentToggleOn,
    multiserviceInstance?.customPersistentStorage,
    persistentStorage,
    autoScalingPolicy,
    clusterScalingName,
    showCustomAutoScaling,
  ]);

  useEffect(() => {
    const foundSelectedPlan = selectedInstancePlan?.find(
      (plan) => plan.serviceId === id
    );

    if (isUpdate) {
      let updateDisabled = true;
      if (
        foundService?.agreedMachineImage.cpu !==
          foundSelectedPlan?.instancePlan.cpu ||
        foundService?.agreedMachineImage.memory !==
          foundSelectedPlan?.instancePlan.memory ||
        foundService?.agreedMachineImage.storage !==
          `${multiserviceInstance.customStorage}Gi`
      )
        updateDisabled = false;

      if (foundService?.serviceCount !== multiserviceInstance.serviceCount)
        updateDisabled = false;

      if (
        (foundService?.agreedMachineImage.persistentStorage?.mountPoint ||
          (multiserviceInstance.customSsdStorage !== 0 &&
            multiserviceInstance.mountPointValue.length > 0)) &&
        (foundService?.agreedMachineImage.persistentStorage?.mountPoint !==
          multiserviceInstance.mountPointValue ||
          foundService?.agreedMachineImage.persistentStorage?.size !==
            `${multiserviceInstance.customSsdStorage}Gi`)
      )
        updateDisabled = false;

      if (!deepEqual(foundService?.variables, templateEnv))
        updateDisabled = false;

      if (imageTag !== foundService?.tag) updateDisabled = false;

      if (
        !deepEqual(
          [
            ...(buildEnv.length !== 0 ? mapBuildEnv(buildEnv) : []),
            ...(secretBuildEnv.length !== 0 ? mapBuildEnv(secretBuildEnv) : []),
          ],
          foundService?.env
        )
      )
        updateDisabled = false;

      if (!deepEqual(buildArgs, foundService?.args)) updateDisabled = false;

      if (!deepEqual(buildCommands, foundService?.command))
        updateDisabled = false;

      setUpdateDisabled(id, updateDisabled);
    }
  }, [
    buildArgs,
    buildCommands,
    buildEnv,
    foundService,
    id,
    imageTag,
    isUpdate,
    multiserviceInstance,
    secretBuildEnv,
    selectedInstancePlan,
    setUpdateDisabled,
    templateEnv,
  ]);

  useEffect(() => {
    const selectedId = instancePlans.akashMachineImages.find(
      (plan) => plan._id === 'custom-id'
    );
    if (!toggleOn) {
      dispatchRtk(setIsCustomPlanOpenRtk(false));
      if (selectedId) {
        dispatchRtk(
          updateMultiServiceCustomCpu({
            serviceId: id,
            value: String(serviceItem?.defaultAkashMachineImage.cpu),
          })
        );
        dispatchRtk(
          updateMultiServiceCustomRam({
            serviceId: id,
            value: String(
              sliceStringFromEnd(
                serviceItem?.defaultAkashMachineImage.memory!,
                2
              )
            ),
          })
        );
        if (serviceItem?.defaultAkashMachineImage?.memory) {
          dispatchRtk(
            updateCustomRamRtk(
              String(
                sliceStringFromEnd(
                  serviceItem?.defaultAkashMachineImage?.memory,
                  2
                )
              )
            )
          );
        }
        dispatchRtk(fetchCustomPlanThunk(LoadingType.CUSTOM_PLAN));
      }
    } else {
      dispatchRtk(setIsCustomPlanOpenRtk(true));
    }
    return () => {
      dispatchRtk(setIsCustomPlanOpenRtk(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchRtk, toggleOn]);

  const isManuallyScaled = isUpdate && selectedInstance?.scalable;

  const isGPUInstance = deploymentType === InstanceDeploymentType.ACCELERATE;

  return (
    <div>
      {(!isUpdate || isManuallyScaled) && (
        <>
          <SelectPlan
            isUpdate={isUpdate}
            toggleOn={toggleOn}
            handleChange={handleToggleChange}
            serviceId={serviceItem?._id || '0'}
          />
          {/* Search plan temporarily removed from multiservice template feature
          {!toggleOn && (
            <div className="mt-6 ml-1">
              {selectedOrganisationLoading ? (
                <Skeleton
                  height={45}
                  width={320}
                  duration={2}
                  containerClassName="flex"
                />
              ) : (
                <SearchComponent
                  value={searchValue || ''}
                  placeholder="e.g. prime..."
                  setValue={setSearchValue as any}
                />
              )}
            </div>
          )} */}
        </>
      )}

      <div
        className={`gap-y-4 gap-x-0 w-full ${
          !isUpdate || isManuallyScaled
            ? 'col-span-2 ll:col-span-3'
            : 'll:col-span-4 col-span-3'
        }`}
      >
        {(!isUpdate || isManuallyScaled) && (
          <div>
            {isGPUInstance ? (
              <ChooseGpuPlan
                isUpdate={isUpdate}
                selectedInstancePlan={selectedGpuInstancePlan}
                setSelectedInstancePlan={setSelectedGpuInstancePlan}
              />
            ) : (
              <>
                {toggleOn ? (
                  <MultiServicePlanOne
                    serviceId={id}
                    selectedService={serviceItem!}
                    isUpdate={isUpdate}
                    setToggleOn={setToggleOn}
                    setIsCancel={setIsCancel}
                    setSelectedInstancePlan={setSelectedInstancePlan}
                    setIsConfirm={setIsConfirm}
                  />
                ) : (
                  <MultiServicePlanTwo
                    serviceId={id}
                    isUpdate={isUpdate}
                    isCancel={isCancel}
                    selectedInstancePlan={newInstancePlan!}
                    setSelectedInstancePlan={setSelectedInstancePlan}
                    isConfirm={isConfirm}
                    setIsConfirm={setIsConfirm}
                  />
                )}
              </>
            )}
          </div>
        )}

        {!toggleOn && (
          <>
            {(computeStep >= 3 || isUpdate) && (
              <>
                {(!isUpdate || isManuallyScaled) && (
                  <MultiServiceSelectStorage
                    isUpdate={isUpdate}
                    selectedService={serviceItem}
                    persistentToggleOn={persistentToggleOn}
                    setPersistentToggleOn={setPersistentToggleOn}
                  />
                )}
                {!isUpdate && (
                  <MultiServiceIpLease
                    isUpdate={isUpdate}
                    selectedService={serviceItem}
                    portMapping={portMapping}
                  />
                )}
                <MultiServiceAdvanceConfigurationSection
                  selectedService={serviceItem}
                  isUpdate={isUpdate}
                  templateEnv={templateEnv}
                  setTemplateEnv={setTemplateEnv}
                  healthUrl={healthUrl}
                  setHealthUrl={setHealthUrl}
                  healthPort={healthPort}
                  setHealthPort={setHealthPort}
                  portMapping={portMapping}
                  setPortMapping={setPortMapping}
                  buildEnv={buildEnv}
                  setBuildEnv={setBuildEnv}
                  secretBuildEnv={secretBuildEnv}
                  setSecretBuildEnv={setSecretBuildEnv}
                  buildArgs={buildArgs}
                  setBuildArgs={setBuildArgs}
                  buildCommands={buildCommands}
                  setBuildCommands={setBuildCommands}
                  setTag={isUpdate ? setImageTag : null}
                  tag={isUpdate ? imageTag : tag || ''}
                  autoScalingPolicy={autoScalingPolicy}
                  setAutoScalingPolicy={setAutoScalingPolicy}
                  showCustomAutoScaling={showCustomAutoScaling}
                  setShowCustomAutoScaling={setShowCustomAutoScaling}
                  isDisable={false} // template={template}
                />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MultiServicePlanGrid;
