import { IAgreedMachineImage } from '../instance.interfaces';
import {
  IInstancePlan,
  IPLeaseType,
  IPersistentStorageClass,
  IResourceInfo,
  InstanceDeploymentType,
  PersistentStorageOption,
  ScalingType,
} from './instance-creation.interfaces';
import Nvidia from '../../../assets/compute/icons/nvidia.svg';

export const isScalable = (scalingMode: ScalingType): boolean => {
  switch (scalingMode) {
    case ScalingType.AUTO:
    case ScalingType.MANUAL:
      return true;
    case ScalingType.NO:
      return false;
    default:
      return false;
  }
};

export const getGpuIcon = (provider: string) => {
  switch (provider) {
    case 'nvidia':
      return Nvidia;
    default:
      return Nvidia;
  }
};

export const machineTypeMap = new Map([
  [InstanceDeploymentType.ACCELERATE, 1],
  [InstanceDeploymentType.COMPUTE, 0],
]);

export const isAccelerateDeployment = (
  deploymentType: InstanceDeploymentType
) => deploymentType === InstanceDeploymentType.ACCELERATE;

export const parsePlanService = (plan: IInstancePlan) => {
  return [
    {
      value: plan.storage.toString().substring(0, plan.storage.length - 2),
      label: 'GB SSD',
      color: '#49CFF9',
    },
    { value: plan.cpu.toString(), label: 'CPU', color: '#498FF9' },
    {
      value: plan.memory.toString().substring(0, plan.memory.length - 2),
      label: 'GB RAM',
      color: '#3261E3',
    },
  ];
};

export const getPersistentStorageName = (persistentStorageType: string) => {
  let updatedStorage;
  switch (persistentStorageType) {
    case 'beta1':
      updatedStorage = PersistentStorageOption.HDD;
      break;
    case 'beta2':
      updatedStorage = PersistentStorageOption.SSD;
      break;
    case 'beta3':
      updatedStorage = PersistentStorageOption.NVM;
      break;
    default:
      updatedStorage = '';
      break;
  }
  return updatedStorage;
};

export const planServices = (plan: IAgreedMachineImage | undefined) => {
  let updatedStorage;
  if (plan?.persistentStorage) {
    switch (plan?.persistentStorage.class) {
      case 'beta1':
        updatedStorage = PersistentStorageOption.HDD;
        break;
      case 'beta2':
        updatedStorage = PersistentStorageOption.SSD;
        break;
      case 'beta3':
        updatedStorage = PersistentStorageOption.NVM;
        break;
      default:
        break;
    }
  }
  return [
    {
      value: plan?.cpu?.toString() || '',
      label: 'CPU',
      color: '#3261E3',
    },
    {
      value: plan?.memory?.toString().slice(0, -2) || '',
      label: 'GB Memory',
      color: '#498FF9',
    },
    {
      value: plan?.storage?.toString().slice(0, -2) || '',
      label: 'GB SSD',
      color: '#49CFF9',
    },
    {
      value: plan?.persistentStorage?.size?.toString().slice(0, -2) || '',
      label: `GB ${updatedStorage} Persistent`,
      color: '#49CFF9',
    },
  ];
};

export const calculateInstancePrice = ({
  cpu = 0,
  memory = 0,
  hdEphemeral = 0,
  persistentStorageHDD = 0,
  persistentStorageNVME = 0,
  persistentStorageSSD = 0,
  ip = 0,
  numCpu = 0,
  numMemory = 0,
  numHdEphemeral = 0,
  numPersistentStorageHDD = 0,
  numPersistentStorageNVME = 0,
  numPersistentStorageSSD = 0,
  numIp = 1,
}: {
  cpu?: number;
  memory?: number;
  hdEphemeral?: number;
  persistentStorageHDD?: number;
  persistentStorageNVME?: number;
  persistentStorageSSD?: number;
  ip?: number;
  numCpu?: number;
  numMemory?: number;
  numHdEphemeral?: number;
  numPersistentStorageHDD?: number;
  numPersistentStorageNVME?: number;
  numPersistentStorageSSD?: number;
  numIp?: number;
}): number => {
  return (
    cpu * numCpu +
    memory * numMemory +
    hdEphemeral * numHdEphemeral +
    persistentStorageHDD * numPersistentStorageHDD +
    persistentStorageNVME * numPersistentStorageNVME +
    persistentStorageSSD * numPersistentStorageSSD +
    ip * numIp
  );
};

export function checkIfDemandFulfilled(
  demand: any,
  nodeResources: Array<IResourceInfo>
): IResourceInfo | undefined {
  return nodeResources.find((node) => {
    const cpuPossible = node.cpu / 1000 >= demand.cpuRequested;
    if (!cpuPossible) {
      return false;
    }
    const memoryPossible =
      node.memory / (1024 * 1024 * 1024) >= demand.memoryRequested;
    if (!memoryPossible) {
      return false;
    }

    const storageEphemeralPossible =
      node.storageEphemeral / (1024 * 1024 * 1024) >=
      demand.ephemeralStorageRequested;
    if (!storageEphemeralPossible) {
      return false;
    }

    if (demand.gpu && demand.gpu.units > 0) {
      const gpuPossible = node.gpu >= demand.gpu.units;
      if (!gpuPossible) {
        return false;
      }
    }

    if (demand.hddPersStorageRequested) {
      const hddCombined = node.storage?.reduce((acc, a) => {
        return acc + (a.class === IPersistentStorageClass.BETA1 ? a.size : 0);
      }, 0);
      if (
        !hddCombined ||
        hddCombined / (1024 * 1024 * 1024) < demand.hddPersStorageRequested
      ) {
        return false;
      }
    }

    if (demand.ssdPersStorageRequested) {
      const ssdCombined = node.storage?.reduce((acc, a) => {
        return acc + (a.class === IPersistentStorageClass.BETA2 ? a.size : 0);
      }, 0);
      if (
        !ssdCombined ||
        ssdCombined / (1024 * 1024 * 1024) < demand.ssdPersStorageRequested
      ) {
        return false;
      }
    }

    if (demand.nvmePersStorageRequested) {
      const nvmeCombined = node.storage?.reduce((acc, a) => {
        return acc + (a.class === IPersistentStorageClass.BETA3 ? a.size : 0);
      }, 0);
      if (
        !nvmeCombined ||
        nvmeCombined / (1024 * 1024 * 1024) < demand.nvmePersStorageRequested
      ) {
        return false;
      }
    }

    return true;
  });
}

export const generateInitialMultiserviceMap = (serviceIds: string[]) => {
  const map: Record<string, any> = {};
  const initialPlanState = {
    customPlan: {} as IInstancePlan,
    updatedCustomPrice: 0,
    updatedStoragePrice: 0,
    updatedPersistentStoragePrice: 0,
    customCpu: '',
    customRam: '',
    customStorage: 20,
    customStorageInput: 20,
    customHddStorage: 0,
    customSsdStorage: 0,
    customNvmStorage: 0,
    customHddStorageInput: 0,
    customSsdStorageInput: 0,
    customNvmStorageInput: 0,
    customPersistentStorage: null,
    customStorageInputPrice: null,
    customHddInputPrice: null,
    customSsdInputPrice: null,
    customNvmInputPrice: null,
    defaultCustomPlansValue: [],
    priceLoading: false,
    mountPointValue: '',
    recommendedPlan: null,
    recommendedPlanLoading: false,
    serviceCount: 1,
    ipLeaseType: IPLeaseType.SHARED,
    isDedicatedClicked: false,
  };
  serviceIds.forEach((id) => {
    map[id] = { ...initialPlanState };
  });
  return map;
};
