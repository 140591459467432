import React from 'react';
import { Button } from '@spheron/ui-library';
import { useNavigate, useParams } from 'react-router-dom';
import GlobalStyle from '../../../../styles/compute/global.module.scss';
import NavWithLogin from '../../../../components/Compute/Navbars/nav-with-login';
import GenericErrorIcon from '../../../../assets/compute/icons/generic-error.svg';

interface IProps {
  error: any;
  resetError: () => void;
}

const ErrorFallback = ({ error, resetError }: IProps) => {
  const navigate = useNavigate();
  const { orgUsername } = useParams<{ orgUsername: string }>();
  return (
    <NavWithLogin>
      <div
        className={`${GlobalStyle.error__fallback} h-full w-full flex flex-col items-center mx-auto
        justify-center`}
      >
        <div className="flex flex-col justify-center">
          <span className="mb-4 flex justify-center">
            <img src={GenericErrorIcon} alt="error icon" />
          </span>
          <p className="font-semibold text-4xl text-center dark:text-gray-200">
            Something went wrong.
          </p>
          <p className="font-semibold text-4xl text-center dark:text-gray-200 mt-2">
            Please try again after some time.
          </p>
          <pre
            className="px-2 py-4 mt-8 text-center font-medium whitespace-pre-wrap
            text-red-700 dark:text-red-600 bg-red-50 rounded-3xl"
          >
            {error.message}
          </pre>
          <Button
            buttonType="primary"
            label="Go to Home"
            size="medium"
            classname="mt-8 self-center"
            onClick={() => {
              resetError();
              navigate(`/${orgUsername}/dashboard`);
              window.location.reload();
            }}
          />
          <div className="mt-12 flex flex-col gap-8">
            <div className="text-gray-500 text-center">
              <p>
                Please view our docs or leave a message on Discord for help.
              </p>
              <p>Our team will reach-out to you soon.</p>
            </div>
            <div
              className="flex flex-row gap-4 justify-center items-center
              font-semibold"
            >
              <Button
                buttonType="neutral"
                label="View Docs"
                size="medium"
                onClick={() => {
                  window.open('https://docs.spheron.network/', '_self');
                }}
              />
              <Button
                buttonType="neutral"
                label="Join Discord"
                size="medium"
                onClick={() => {
                  window.open('https://discord.gg/HTMqYAm', '_self');
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </NavWithLogin>
  );
};

export default ErrorFallback;
