/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Button } from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../redux/compute/store';
import {
  HardwareTypeEnum,
  IInstancePlan,
  LoadingType,
  ScalingType,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.interfaces';
import {
  getInstancePlansEndRtk,
  setMultiServiceUpdatedCustomPrice,
  updateMultiServiceCustomCpu,
  updateMultiServiceCustomPlan,
  updateMultiServiceCustomRam,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.slice';
// eslint-disable-next-line max-len
import { multiserviceCalculatePriceThunk } from '../../../../../redux/compute/instance/instance-creation/instance-creation.thunks';
import { setIsCustomPlanOpenRtk } from '../../../../../redux/compute/instance/instance.slice';
// eslint-disable-next-line max-len
import { isAccelerateDeployment } from '../../../../../redux/compute/instance/instance-creation/instance-creation.utils';
import { IInstanceCost } from '../../../../../redux/compute/instance/instance.interfaces';
import MultiServiceDropdownMenu from '../MultiServiceDropdownMenu';
import { CustomSelectOption } from '../MultiServiceDropdownMenu/multiservice-dropdown-menu';
import { IServiceData } from '../../../../../redux/compute/cluster/cluster.interfaces';

interface IProps {
  serviceId: string;
  isUpdate: boolean;
  setToggleOn: (isToggleOn: boolean) => void;
  setIsCancel: (isCancel: boolean) => void;
  setSelectedInstancePlan: (plan: {
    serviceId: string;
    selectedInstancePlan: IInstancePlan;
  }) => void;
  setIsConfirm: (isConfirm: boolean) => void;
  selectedService: IServiceData;
}
const MultiServicePlanOne = ({
  serviceId,
  isUpdate,
  setToggleOn,
  setIsCancel,
  setSelectedInstancePlan,
  setIsConfirm,
  selectedService,
}: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();

  const instancePlans = useSelector(
    (state: RootState) => state.instanceCreation.instancePlans
  );
  const { customCpu, customRam, updatedCustomPrice, customPlan } = useSelector(
    (state: RootState) =>
      state.instanceCreation.multiserviceInstanceCreation![selectedService?._id]
  );
  const defaultCustomPlanValue = useSelector(
    (state: RootState) => state.instanceCreation.defaultCustomPlansValue
  );
  const priceLoading = useSelector(
    (state: RootState) => state.instanceCreation.priceLoading
  );
  const priceLoadingType = useSelector(
    (state: RootState) => state.instanceCreation.priceLoadingType
  );
  const cpuValues = defaultCustomPlanValue?.find(
    (item: { type: HardwareTypeEnum }) => item.type === HardwareTypeEnum.CPU
  );
  const memoryValues = defaultCustomPlanValue?.find(
    (item: { type: HardwareTypeEnum }) => item.type === HardwareTypeEnum.MEMORY
  );
  const scalingType = useSelector(
    (state: RootState) => state.instanceCreation.clusterScaling
  );
  const deploymentType = useSelector(
    (state: RootState) => state.instanceCreation.deploymentType
  );
  const monthlyCostUSD = (updatedCustomPrice * 30 || 0).toFixed(2);
  const hourlyCostUSD = (updatedCustomPrice / 24).toFixed(2);
  let primaryCost: IInstanceCost;
  let secondaryCost: IInstanceCost;
  if (isAccelerateDeployment(deploymentType)) {
    primaryCost = {
      value: hourlyCostUSD,
      unit: 'hour',
    };
    secondaryCost = {
      value: monthlyCostUSD,
      unit: 'month',
    };
  } else {
    primaryCost = {
      value: monthlyCostUSD,
      unit: 'month',
    };
    secondaryCost = {
      value: hourlyCostUSD,
      unit: 'hour',
    };
  }
  const handleCancel = () => {
    dispatchRtk(updateMultiServiceCustomCpu({ serviceId, value: '' }));
    dispatchRtk(updateMultiServiceCustomRam({ serviceId, value: '' }));
    dispatchRtk(setMultiServiceUpdatedCustomPrice({ serviceId, value: 0 }));
    setIsCancel(true);
    setToggleOn(false);
  };
  const handleConfirm = () => {
    if (
      customCpu &&
      customRam &&
      customCpu !== '' &&
      customRam !== ''
      // updatedCustomPrice
      // customPlan
    ) {
      const customPlanIndex = instancePlans.akashMachineImages.findIndex(
        (plan) => plan._id === `custom-id-${serviceId}`
      );

      let updatedInstancePlans;
      if (customPlanIndex !== -1) {
        let updatedAkashMachineImages;
        if (isUpdate) {
          updatedAkashMachineImages = instancePlans.akashMachineImages.filter(
            (image) => image._id !== `custom-id-${serviceId}`
          );
          updatedAkashMachineImages = [
            customPlan,
            ...updatedAkashMachineImages,
          ];
        } else {
          updatedAkashMachineImages = [
            ...instancePlans.akashMachineImages.slice(0, customPlanIndex),
            customPlan,
            ...instancePlans.akashMachineImages.slice(customPlanIndex + 1),
          ];
        }
        updatedInstancePlans = {
          ...instancePlans,
          akashMachineImages: updatedAkashMachineImages,
        };
      } else {
        const updatedAkashMachineImages = [
          customPlan,
          ...instancePlans.akashMachineImages,
        ];
        updatedInstancePlans = {
          ...instancePlans,
          totalCount: instancePlans.totalCount + 1,
          akashMachineImages: updatedAkashMachineImages,
        };
      }
      const updatedCustomPlan: any =
        updatedInstancePlans?.akashMachineImages.find(
          (plan) => plan._id === `custom-id-${serviceId}`
        );

      dispatchRtk(
        updateMultiServiceCustomPlan({ serviceId, value: customPlan })
      );
      dispatchRtk(getInstancePlansEndRtk(updatedInstancePlans));
      setSelectedInstancePlan({
        serviceId,
        selectedInstancePlan: updatedCustomPlan,
      });
      setToggleOn(false);
      setIsCancel(false);
      setIsConfirm(true);
    }
  };
  useEffect(() => {
    if (customCpu && customRam && customCpu !== '' && customRam !== '') {
      dispatchRtk(
        multiserviceCalculatePriceThunk({
          serviceId,
          loadingType: LoadingType.CUSTOM_PLAN,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customCpu, customRam, dispatchRtk, isUpdate]);

  useEffect(() => {
    dispatchRtk(setIsCustomPlanOpenRtk(true));
    dispatchRtk(updateMultiServiceCustomCpu({ serviceId, value: '' }));
    dispatchRtk(updateMultiServiceCustomRam({ serviceId, value: '' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchRtk]);

  return (
    <>
      <div className="grid grid-cols-3 gap-x-0 mb-12">
        <div
          className={`bg-bg-newPlan border
            mt-8 ll:min-h-28 h-auto ll:py-0 py-4 rounded-xl 
            w-full ${
              isUpdate
                ? 'px-6 col-span-4'
                : 'px-5 ll:w-1000 w-auto ll:px-11 col-span-3'
            }
            dark:bg-dark-base-bg dark:border dark:border-dark-base-border`}
        >
          <div className="flex justify-center my-4">
            <div
              className={`flex ll:items-center ${
                isUpdate
                  ? 'flex-col gap-5 w-full'
                  : 'll:flex-row flex-col ll:gap-0 gap-5'
              }`}
            >
              <div className="flex gap-10 w-full">
                <MultiServiceDropdownMenu
                  isUpdate={isUpdate}
                  label="CPU"
                  options={cpuValues?.values}
                  value={CustomSelectOption.SELECTEDCPU}
                  widthClassName="w-56"
                  defaultOption="Select"
                  selectedService={selectedService}
                />
                <MultiServiceDropdownMenu
                  isUpdate={isUpdate}
                  label="RAM"
                  options={memoryValues?.values}
                  value={CustomSelectOption.SELECTEDRAM}
                  widthClassName="w-56"
                  selectedService={selectedService}
                  defaultOption="Select"
                />
                <div className="flex flex-col h-16">
                  <span
                    className="font-semibold text-sm text-text-filterBadge 
                    leading-5 dark:text-text-darkGray"
                  >
                    {scalingType === ScalingType.MANUAL ? '' : 'Avg '}Cost/
                    {primaryCost.unit}
                  </span>
                  {customCpu !== '' && customRam !== '' ? (
                    <>
                      {priceLoading &&
                      priceLoadingType === LoadingType.CUSTOM_PLAN ? (
                        <Skeleton height={40} duration={4} />
                      ) : (
                        <>
                          <span
                            className="text-base font-bold text-text-templateCard leading-5 mt-2
                            text-base-heading-text-color dark:text-dark-base-heading-text-color"
                          >
                            ${primaryCost.value}/{primaryCost.unit}
                          </span>
                          <span className="leading-4 text-xs font-normal text-text-darkGray mt-1">
                            ${secondaryCost.value}{' '}
                            {scalingType === ScalingType.MANUAL ? '' : 'avg'}{' '}
                            {`${secondaryCost.unit}ly`} cost
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <span className="text-text-filterBadge flex items-center h-full">
                      --
                    </span>
                  )}
                </div>
              </div>

              <div
                className={`flex flex-row gap-x-4 w-full ${
                  isUpdate ? 'justify-start' : 'ml-11'
                }`}
              >
                <Button
                  buttonType="ghost"
                  label="Cancel"
                  size="medium"
                  onClick={handleCancel}
                />
                <div className="ll:ml-4 ml-3">
                  <Button
                    buttonType="primary"
                    label="Good to go"
                    size="medium"
                    onClick={handleConfirm}
                    disabled={!customRam || !customCpu}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MultiServicePlanOne;
