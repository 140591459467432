import { ICdnRecords } from '../combined-state.interface';

export interface IStorage {
  buckets: IBucket[] | null;
  archivedBuckets: IBucket[] | null;
  bucketsLoading: boolean;
  bucketsLoadMoreLoading: boolean;
  bucketsCount: number;
  archivedBucketsCount: number;
  bucketsCountLoading: boolean;
  bucketsNames: string[] | null;
  bucketsNamesLoading: boolean;
  bucketStateUpdateLoading: boolean;
  deleteBucketLoading: boolean;
  uploadBucketLoading: boolean;
  pinBucketUploadLoading: boolean;
  uploads: IUpload[] | null;
  uploadsLoading: boolean;
  uploadsLoadMoreLoading: boolean;
  uploadsCount: number;
  uploadsCountLoading: boolean;
  uploadsCidsMap: IUploadCidsMap;
  uploadsCidsMapLoading: boolean;
  availableLinks: AvailableLink[];
  availableLinksLoading: boolean;
  ipnsRecords: IIpnsRecord[] | null;
  ipnsRecordsLoading: boolean;
  publishIpnsRecordsLoading: boolean;
  updateIpnsRecordsLoading: boolean;
  ipnsRecordCreated: IIpnsRecord | null;
  selectedBucketLoading: boolean;
  selectedBucket: IBucket | null;
  selectedUpload: IUpload | null;
  domainsEnabled: boolean;
  domainsLoading: boolean;
  domains: IBucketDomainState;
  addDomainLoading: boolean;
  editDomainLoading: Array<any>;
  deleteDomainLoading: Array<any>;
  verifyDomainLoading: Array<any>;
  bucketActionConfirmationLoading: boolean;
  cdnRecords: ICdnRecords;
  uploadFileStatus: { currentlyUploaded: number; totalSize: number };
  showFileUploadCard: boolean;
  fileUploadList: IFileUploadList[] | null;
  showUploadModalLoading: boolean;
}

export interface IBucket {
  state: string;
  usedProtocols: string[];
  _id: string;
  name: string;
  organization: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  lastUploadDate: Date;
}

export interface IUpload {
  status: string;
  memoryUsed: number;
  _id: string;
  bucket: string;
  uploadInitiator: string;
  protocol: string;
  uploadDirectory: IUploadDirectory[];
  createdAt: string;
  updatedAt: string;
  protocolLink: string;
  cid: string;
}

export interface IUploadDirectory {
  _id: string;
  fileName: string;
  fileSize: number;
}

export interface IIpnsRecord {
  _id: string;
  memoryUsed: number;
  bucket: string;
  createdAt: string;
  updatedAt: string;
  ipnsLink: string;
  keyId: string;
  keyName: string;
  targetCid: string;
}

export interface IBucketCountResponse {
  count: number;
}

export interface IUploadInitiateResponse {
  uploadId: string;
  parallelUploadCount: number;
  payloadSize: number;
  singleUseToken: string;
}

export enum BucketType {
  // eslint-disable-next-line no-unused-vars
  MAINTAINED = 'MAINTAINED',
  // eslint-disable-next-line no-unused-vars
  ARCHIVED = 'ARCHIVED',
}

export enum UploadType {
  // eslint-disable-next-line no-unused-vars
  UPLOADFILES = 'uploadFiles',
  // eslint-disable-next-line no-unused-vars
  PINCID = 'pinCid',
}

export enum BucketStateType {
  // eslint-disable-next-line no-unused-vars
  NEWBUCKET = 'newBucket',
  // eslint-disable-next-line no-unused-vars
  EXISTINGBUCKET = 'existingBucket',
}

export interface IBucketDomain {
  _id: string;
  name: string;
  link: string;
  type: string;
  verified: boolean;
  bucketId: string;
}

export interface IBucketDomainState {
  domains: IBucketDomain[];
  subdomains: IBucketDomain[];
  handshakeDomains: IBucketDomain[];
  handshakeSubdomains: IBucketDomain[];
  ensDomains: IBucketDomain[];
}

export interface IUploadCidMap {
  cid: string;
  createdAt: string;
}

export type IUploadCidsMap = Record<string, IUploadCidMap>;

export interface ICdnRecordsResponse {
  message: any;
  error: boolean;
  records: ICdnRecords;
}

export enum IUploadStatus {
  // eslint-disable-next-line no-unused-vars
  InProgress = 'InProgress',
  // eslint-disable-next-line no-unused-vars
  InPinningQueue = 'InPinningQueue',
  // eslint-disable-next-line no-unused-vars
  Canceled = 'Canceled',
  // eslint-disable-next-line no-unused-vars
  Failed = 'Failed',
  // eslint-disable-next-line no-unused-vars
  TimedOut = 'TimedOut',
  // eslint-disable-next-line no-unused-vars
  Unpinned = 'Unpinned',
  // eslint-disable-next-line no-unused-vars
  Uploaded = 'Uploaded',
  // eslint-disable-next-line no-unused-vars
  Pinned = 'Pinned',
}

export interface IGetAllBucketsPayload {
  organisationId: string;
  skip: number;
  limit: number;
  state: BucketType;
  name: string;
  id: string;
  search: string;
  loadMore: boolean | undefined;
}

export interface IBucketPayload {
  bucketId: string;
  bucketName: string;
  state: BucketType;
  skip: number;
  limit: number;
}

export interface IGetBucketUploadsPayload {
  bucketId: string;
  skip: number;
  limit: number;
  status: string;
  loadMore: boolean | undefined;
}

export interface IBucketIpnsPayload {
  bucketId: string;
  ipnsRecordId: string;
  uploadConfig: any;
}

export interface IBucketDomainsPayload {
  bucketId: string;
  domainId: string;
  domainConfig: any;
}

export enum AvailableLinkType {
  // eslint-disable-next-line no-unused-vars
  UploadLink = 'UploadLink',
  // eslint-disable-next-line no-unused-vars
  IpnsLink = 'IpnsLink',
}

export interface AvailableLink {
  id: string;
  type: AvailableLinkType;
  link: string;
}

export interface IFileUploadList {
  id: number;
  fileNames: string[];
  uploadLoading: boolean;
  error: boolean;
}
