import React from 'react';
import Styles from '../../../styles/compute/compute-pricing.module.scss';
import { ReactComponent as Arrow } from '../../../assets/compute/icons/arrow-drop-icon.svg';

interface IProps {
  loading: boolean;
  handleClick: () => void;
  title: string;
}

const ShowMore = ({ title, handleClick, loading }: IProps) => {
  return (
    <button
      type="button"
      className="cursor-pointer bg-white border border-lightGray rounded px-4 py-2 
        flex items-center text-text-filterBadge text-sm font-medium mt-12 
        dark:bg-dark-base-bg dark:border-gray-700"
      onClick={handleClick}
    >
      {loading ? 'Loading...' : <>{title}</>}
      {!loading && (
        <Arrow
          className={`${Styles.arrow__icon} ml-2 
${Styles.rotate0}`}
        />
      )}
    </button>
  );
};

export default ShowMore;
