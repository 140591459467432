/* eslint-disable max-len */
import React from 'react';

interface IProps {
  height: number;
  width: number;
  fill?: string;
  stroke?: number;
}
const ExtLinkIcon: React.FC<IProps> = ({ height, width, fill, stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={fill}
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={stroke}
      height={height}
      width={width}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
      />
    </svg>
  );
};

ExtLinkIcon.defaultProps = {
  fill: 'none',
  stroke: 1,
};

export default ExtLinkIcon;
