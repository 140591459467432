import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  AvailableLink,
  BucketType,
  IBucket,
  IBucketDomain,
  IFileUploadList,
  IIpnsRecord,
  IStorage,
  IUpload,
  IUploadCidsMap,
} from './storage.interfaces';
// eslint-disable-next-line import/no-cycle
import {
  getAllBucketsCountThunk,
  getAllBucketsThunk,
  getSelectedBucketThunk,
  updateBucketStateThunk,
  uploadBucketThunk,
  getBucketUploadsThunk,
  getBucketUploadsCountThunk,
  getBucketIpnsRecordsThunk,
  publishBucketIpnsRecordThunk,
  updateBucketIpnsRecordThunk,
  getAllBucketsNamesThunk,
  getBucketUploadsCidsThunk,
  pinBucketUploadThunk,
  getBucketDomainsEnabledThunk,
  getBucketDomainsThunk,
  addBucketDomainsThunk,
  getBucketUploadsLinksThunk,
  updateBucketDomainsThunk,
  verifyBucketDomainsThunk,
  deleteBucketDomainsThunk,
  unpinBucketUploadThunk,
  deleteBucketIpnsRecordThunk,
  deleteBucketUploadThunk,
  deleteBucketThunk,
  loadBucketCdnRecordsThunk,
  pinCidBucketThunk,
} from './storage.thunks';
import { toggleListLoading } from './storage.utils';
import { ICdnRecords } from '../combined-state.interface';

const initialState: IStorage = {
  buckets: null,
  archivedBuckets: null,
  bucketsLoading: false,
  bucketsLoadMoreLoading: false,
  bucketsCount: 0,
  archivedBucketsCount: 0,
  bucketsCountLoading: false,
  bucketsNames: null,
  bucketsNamesLoading: false,
  bucketStateUpdateLoading: false,
  deleteBucketLoading: false,
  uploadBucketLoading: false,
  pinBucketUploadLoading: false,
  uploads: null,
  uploadsLoading: false,
  uploadsLoadMoreLoading: false,
  uploadsCount: 0,
  uploadsCountLoading: false,
  uploadsCidsMap: {},
  uploadsCidsMapLoading: false,
  availableLinks: [],
  availableLinksLoading: false,
  ipnsRecords: null,
  ipnsRecordsLoading: false,
  publishIpnsRecordsLoading: false,
  updateIpnsRecordsLoading: false,
  ipnsRecordCreated: null,
  selectedBucketLoading: false,
  selectedBucket: null,
  selectedUpload: null,
  domainsEnabled: false,
  domainsLoading: false,
  domains: {
    domains: [],
    subdomains: [],
    handshakeDomains: [],
    handshakeSubdomains: [],
    ensDomains: [],
  },
  addDomainLoading: false,
  editDomainLoading: [],
  deleteDomainLoading: [],
  verifyDomainLoading: [],
  bucketActionConfirmationLoading: false,
  cdnRecords: {
    recordIpv4V2: '',
    recordCnameV2: '',
  },
  uploadFileStatus: { currentlyUploaded: 0, totalSize: 0 },
  showFileUploadCard: false,
  fileUploadList: null,
  showUploadModalLoading: false,
};

// TODO: Correct Types needs to be added for action payload
const storageSlice = createSlice({
  name: 'storage',
  initialState,
  reducers: {
    setBuckets: (state, action: PayloadAction<IBucket[] | null>) => {
      state.buckets = action.payload;
    },
    setBucketsNames: (state, action: PayloadAction<string[] | null>) => {
      state.bucketsNames = action.payload;
    },
    setSelectedBucket: (state, action: PayloadAction<IBucket | null>) => {
      state.selectedBucket = action.payload;
    },
    setSelectedUpload: (state, action: PayloadAction<IUpload | null>) => {
      state.selectedUpload = action.payload;
    },
    setUploads: (state, action: PayloadAction<IUpload[] | null>) => {
      state.uploads = action.payload;
    },
    setIpnsRecordCreated: (
      state,
      action: PayloadAction<IIpnsRecord | null>
    ) => {
      state.ipnsRecordCreated = action.payload;
    },
    resetStorageState: (state) => {
      // eslint-disable-next-line no-param-reassign, no-unused-vars
      state = initialState;
    },
    toggleBucketsLoading: (state, action: PayloadAction<boolean>) => {
      state.bucketsLoading = action.payload;
    },
    setCurrentlyUploaded: (
      state,
      action: PayloadAction<{
        currentlyUploaded: number;
        totalSize: number;
      }>
    ) => {
      state.uploadFileStatus = action.payload;
    },
    setShowUploadCard: (state, action: PayloadAction<boolean>) => {
      state.showFileUploadCard = action.payload;
      if (!action.payload) {
        state.uploadFileStatus = initialState.uploadFileStatus;
      }
    },
    setFileUploadList: (
      state,
      action: PayloadAction<IFileUploadList[] | null>
    ) => {
      state.fileUploadList = action.payload;
    },

    toggleShowUploadModalLoading: (state, action: PayloadAction<boolean>) => {
      state.showUploadModalLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getAllBucketsThunk.pending,
      (state, action: PayloadAction<undefined, string, any>) => {
        if (!action.meta.arg.loadMore) {
          state.bucketsLoading = true;
        } else {
          state.bucketsLoadMoreLoading = true;
        }
      }
    );
    builder.addCase(
      getAllBucketsThunk.fulfilled,
      (
        state,
        action: PayloadAction<
          { buckets: IBucket[]; loadMore: boolean },
          string,
          any
        >
      ) => {
        state.bucketsLoading = false;
        state.bucketsLoadMoreLoading = false;
        if (!action.payload.loadMore) {
          if (action.meta.arg.state === BucketType.ARCHIVED) {
            state.archivedBuckets = action.payload.buckets;
          } else {
            state.buckets = action.payload.buckets;
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (action.meta.arg.state === BucketType.ARCHIVED) {
            state.archivedBuckets = [
              ...state.archivedBuckets!,
              ...action.payload.buckets,
            ];
          } else {
            state.buckets = [...state.buckets!, ...action.payload.buckets];
          }
        }
      }
    );
    builder.addCase(getAllBucketsThunk.rejected, (state) => {
      state.bucketsLoading = false;
      state.bucketsLoadMoreLoading = false;
    });

    builder.addCase(getSelectedBucketThunk.pending, (state) => {
      state.selectedBucketLoading = true;
    });
    builder.addCase(
      getSelectedBucketThunk.fulfilled,
      (state, action: PayloadAction<IBucket>) => {
        state.selectedBucketLoading = false;
        state.selectedBucket = action.payload;
      }
    );
    builder.addCase(getSelectedBucketThunk.rejected, (state) => {
      state.selectedBucketLoading = false;
    });

    builder.addCase(getAllBucketsCountThunk.pending, (state) => {
      state.bucketsCountLoading = true;
    });
    builder.addCase(
      getAllBucketsCountThunk.fulfilled,
      (state, action: PayloadAction<number, string, any>) => {
        state.bucketsCountLoading = false;
        if (action.meta.arg.state === BucketType.ARCHIVED) {
          state.archivedBucketsCount = action.payload;
        } else {
          state.bucketsCount = action.payload;
        }
      }
    );
    builder.addCase(getAllBucketsCountThunk.rejected, (state) => {
      state.bucketsCountLoading = false;
    });

    builder.addCase(getAllBucketsNamesThunk.pending, (state) => {
      state.bucketsNamesLoading = true;
    });
    builder.addCase(
      getAllBucketsNamesThunk.fulfilled,
      (state, action: PayloadAction<string[]>) => {
        state.bucketsNamesLoading = false;
        state.bucketsNames = action.payload;
      }
    );
    builder.addCase(getAllBucketsNamesThunk.rejected, (state) => {
      state.bucketsNamesLoading = false;
    });

    builder.addCase(updateBucketStateThunk.pending, (state) => {
      state.bucketStateUpdateLoading = true;
      state.bucketActionConfirmationLoading = true;
    });
    builder.addCase(updateBucketStateThunk.fulfilled, (state) => {
      state.bucketStateUpdateLoading = false;
      state.bucketActionConfirmationLoading = false;
    });
    builder.addCase(updateBucketStateThunk.rejected, (state) => {
      state.bucketStateUpdateLoading = false;
      state.bucketActionConfirmationLoading = false;
    });

    builder.addCase(deleteBucketThunk.pending, (state) => {
      state.deleteBucketLoading = true;
    });
    builder.addCase(deleteBucketThunk.fulfilled, (state) => {
      state.deleteBucketLoading = false;
    });
    builder.addCase(deleteBucketThunk.rejected, (state) => {
      state.deleteBucketLoading = false;
    });

    builder.addCase(uploadBucketThunk.pending, (state) => {
      state.uploadBucketLoading = true;
    });
    builder.addCase(
      uploadBucketThunk.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.uploadBucketLoading = false;
        if (state.fileUploadList) {
          const index = state.fileUploadList.findIndex(
            (file) => file.id === action.payload.id
          );

          state.fileUploadList[index] = {
            ...state.fileUploadList[index],
            uploadLoading: false,
          };
        }
      }
    );
    builder.addCase(
      uploadBucketThunk.rejected,
      (state, action: PayloadAction<any>) => {
        state.uploadBucketLoading = false;
        if (state.fileUploadList) {
          const index = state.fileUploadList.findIndex(
            (file) => file.id === action.payload.id
          );

          state.fileUploadList[index] = {
            ...state.fileUploadList[index],
            uploadLoading: false,
            error: true,
          };
        }
      }
    );

    builder.addCase(pinCidBucketThunk.pending, (state) => {
      state.uploadBucketLoading = true;
    });
    builder.addCase(pinCidBucketThunk.fulfilled, (state) => {
      state.uploadBucketLoading = false;
    });
    builder.addCase(pinCidBucketThunk.rejected, (state) => {
      state.uploadBucketLoading = false;
    });

    builder.addCase(
      getBucketUploadsThunk.pending,
      (state, action: PayloadAction<undefined, string, any>) => {
        if (!action.meta.arg.loadMore) {
          state.uploadsLoading = true;
        } else {
          state.uploadsLoadMoreLoading = true;
        }
      }
    );
    builder.addCase(
      getBucketUploadsThunk.fulfilled,
      (
        state,
        action: PayloadAction<{ uploads: IUpload[]; loadMore: boolean }>
      ) => {
        state.uploadsLoading = false;
        state.uploadsLoadMoreLoading = false;
        if (!action.payload.loadMore) {
          state.uploads = action.payload.uploads;
        } else {
          state.uploads = [...state.uploads!, ...action.payload.uploads];
        }
      }
    );
    builder.addCase(getBucketUploadsThunk.rejected, (state) => {
      state.uploadsLoading = false;
      state.uploadsLoadMoreLoading = false;
    });

    builder.addCase(getBucketUploadsCountThunk.pending, (state) => {
      state.uploadsCountLoading = true;
    });
    builder.addCase(
      getBucketUploadsCountThunk.fulfilled,
      (state, action: PayloadAction<number>) => {
        state.uploadsCountLoading = false;
        state.uploadsCount = action.payload;
      }
    );
    builder.addCase(getBucketUploadsCountThunk.rejected, (state) => {
      state.uploadsCountLoading = false;
    });

    builder.addCase(getBucketUploadsCidsThunk.pending, (state) => {
      state.uploadsCidsMapLoading = true;
    });
    builder.addCase(
      getBucketUploadsCidsThunk.fulfilled,
      (state, action: PayloadAction<IUploadCidsMap>) => {
        state.uploadsCidsMapLoading = false;
        state.uploadsCidsMap = action.payload;
      }
    );
    builder.addCase(getBucketUploadsCidsThunk.rejected, (state) => {
      state.uploadsCidsMapLoading = false;
    });

    builder.addCase(getBucketUploadsLinksThunk.pending, (state) => {
      state.availableLinksLoading = true;
    });
    builder.addCase(
      getBucketUploadsLinksThunk.fulfilled,
      (state, action: PayloadAction<AvailableLink[]>) => {
        state.availableLinksLoading = false;
        state.availableLinks = action.payload;
      }
    );
    builder.addCase(getBucketUploadsLinksThunk.rejected, (state) => {
      state.availableLinksLoading = false;
    });

    builder.addCase(deleteBucketUploadThunk.pending, (state) => {
      state.bucketActionConfirmationLoading = true;
    });
    builder.addCase(deleteBucketUploadThunk.fulfilled, (state) => {
      state.bucketActionConfirmationLoading = false;
    });
    builder.addCase(deleteBucketUploadThunk.rejected, (state) => {
      state.bucketActionConfirmationLoading = false;
    });

    builder.addCase(pinBucketUploadThunk.pending, (state) => {
      state.pinBucketUploadLoading = true;
      state.bucketActionConfirmationLoading = true;
    });
    builder.addCase(pinBucketUploadThunk.fulfilled, (state) => {
      state.pinBucketUploadLoading = false;
      state.bucketActionConfirmationLoading = false;
    });
    builder.addCase(pinBucketUploadThunk.rejected, (state) => {
      state.pinBucketUploadLoading = false;
      state.bucketActionConfirmationLoading = false;
    });

    builder.addCase(unpinBucketUploadThunk.pending, (state) => {
      state.pinBucketUploadLoading = true;
      state.bucketActionConfirmationLoading = true;
    });
    builder.addCase(unpinBucketUploadThunk.fulfilled, (state) => {
      state.pinBucketUploadLoading = false;
      state.bucketActionConfirmationLoading = false;
    });
    builder.addCase(unpinBucketUploadThunk.rejected, (state) => {
      state.pinBucketUploadLoading = false;
      state.bucketActionConfirmationLoading = false;
    });

    builder.addCase(getBucketIpnsRecordsThunk.pending, (state) => {
      state.ipnsRecordsLoading = true;
    });
    builder.addCase(
      getBucketIpnsRecordsThunk.fulfilled,
      (state, action: PayloadAction<IIpnsRecord[]>) => {
        state.ipnsRecordsLoading = false;
        state.ipnsRecords = action.payload;
      }
    );
    builder.addCase(getBucketIpnsRecordsThunk.rejected, (state) => {
      state.ipnsRecordsLoading = false;
    });

    builder.addCase(publishBucketIpnsRecordThunk.pending, (state) => {
      state.publishIpnsRecordsLoading = true;
    });
    builder.addCase(
      publishBucketIpnsRecordThunk.fulfilled,
      (state, action: PayloadAction<{ ipnsRecord: IIpnsRecord }>) => {
        state.ipnsRecordCreated = action.payload.ipnsRecord;
        state.publishIpnsRecordsLoading = false;
      }
    );
    builder.addCase(publishBucketIpnsRecordThunk.rejected, (state) => {
      state.publishIpnsRecordsLoading = false;
    });

    builder.addCase(updateBucketIpnsRecordThunk.pending, (state) => {
      state.updateIpnsRecordsLoading = true;
    });
    builder.addCase(
      updateBucketIpnsRecordThunk.fulfilled,
      (state, action: PayloadAction<{ ipnsRecord: IIpnsRecord }>) => {
        state.ipnsRecordCreated = action.payload.ipnsRecord;
        state.updateIpnsRecordsLoading = false;
      }
    );
    builder.addCase(updateBucketIpnsRecordThunk.rejected, (state) => {
      state.updateIpnsRecordsLoading = false;
    });

    builder.addCase(deleteBucketIpnsRecordThunk.pending, (state) => {
      state.bucketActionConfirmationLoading = true;
    });
    builder.addCase(deleteBucketIpnsRecordThunk.fulfilled, (state) => {
      state.bucketActionConfirmationLoading = false;
    });
    builder.addCase(deleteBucketIpnsRecordThunk.rejected, (state) => {
      state.bucketActionConfirmationLoading = false;
    });

    builder.addCase(
      getBucketDomainsEnabledThunk.fulfilled,
      (state, action: PayloadAction<boolean>) => {
        state.domainsEnabled = action.payload;
      }
    );

    builder.addCase(getBucketDomainsThunk.pending, (state) => {
      state.domainsLoading = true;
    });
    builder.addCase(
      getBucketDomainsThunk.fulfilled,
      (state, action: PayloadAction<IBucketDomain[]>) => {
        state.domains = {
          domains: action.payload.filter(
            (d: IBucketDomain) => d.type === 'domain'
          ),
          subdomains: action.payload.filter(
            (d: IBucketDomain) => d.type === 'subdomain'
          ),
          handshakeDomains: action.payload.filter(
            (d: IBucketDomain) => d.type === 'handshake-domain'
          ),
          handshakeSubdomains: action.payload.filter(
            (d: IBucketDomain) => d.type === 'handshake-subdomain'
          ),
          ensDomains: action.payload.filter(
            (d: IBucketDomain) => d.type === 'ens-domain'
          ),
        };
        state.domainsLoading = false;
      }
    );
    builder.addCase(getBucketDomainsThunk.rejected, (state) => {
      state.domainsLoading = false;
    });

    builder.addCase(addBucketDomainsThunk.pending, (state) => {
      state.addDomainLoading = true;
    });
    builder.addCase(addBucketDomainsThunk.fulfilled, (state) => {
      state.addDomainLoading = false;
    });
    builder.addCase(addBucketDomainsThunk.rejected, (state) => {
      state.addDomainLoading = false;
    });

    builder.addCase(
      updateBucketDomainsThunk.pending,
      (state, action: PayloadAction<any, string, any>) => {
        state.editDomainLoading = toggleListLoading(state.editDomainLoading, {
          loading: true,
          domainId: action.meta.arg.domainId,
        });
      }
    );
    builder.addCase(
      updateBucketDomainsThunk.fulfilled,
      (state, action: PayloadAction<any, string, any>) => {
        state.editDomainLoading = toggleListLoading(state.editDomainLoading, {
          loading: false,
          domainId: action.meta.arg.domainId,
        });
      }
    );
    builder.addCase(
      updateBucketDomainsThunk.rejected,
      (state, action: PayloadAction<any, string, any>) => {
        state.editDomainLoading = toggleListLoading(state.editDomainLoading, {
          loading: false,
          domainId: action.meta.arg.domainId,
        });
      }
    );

    builder.addCase(
      verifyBucketDomainsThunk.pending,
      (state, action: PayloadAction<any, string, any>) => {
        state.verifyDomainLoading = toggleListLoading(
          state.verifyDomainLoading,
          {
            loading: true,
            domainId: action.meta.arg.domainId,
          }
        );
      }
    );
    builder.addCase(
      verifyBucketDomainsThunk.fulfilled,
      (state, action: PayloadAction<any, string, any>) => {
        state.verifyDomainLoading = toggleListLoading(
          state.verifyDomainLoading,
          {
            loading: false,
            domainId: action.meta.arg.domainId,
          }
        );
      }
    );
    builder.addCase(
      verifyBucketDomainsThunk.rejected,
      (state, action: PayloadAction<any, string, any>) => {
        state.verifyDomainLoading = toggleListLoading(
          state.verifyDomainLoading,
          {
            loading: false,
            domainId: action.meta.arg.domainId,
          }
        );
      }
    );

    builder.addCase(
      deleteBucketDomainsThunk.pending,
      (state, action: PayloadAction<any, string, any>) => {
        state.deleteDomainLoading = toggleListLoading(
          state.deleteDomainLoading,
          {
            loading: true,
            domainId: action.meta.arg.domainId,
          }
        );
      }
    );
    builder.addCase(
      deleteBucketDomainsThunk.fulfilled,
      (state, action: PayloadAction<any, string, any>) => {
        state.deleteDomainLoading = toggleListLoading(
          state.deleteDomainLoading,
          {
            loading: false,
            domainId: action.meta.arg.domainId,
          }
        );
      }
    );
    builder.addCase(
      deleteBucketDomainsThunk.rejected,
      (state, action: PayloadAction<any, string, any>) => {
        state.deleteDomainLoading = toggleListLoading(
          state.deleteDomainLoading,
          {
            loading: false,
            domainId: action.meta.arg.domainId,
          }
        );
      }
    );
    builder.addCase(
      loadBucketCdnRecordsThunk.fulfilled,
      (state, action: PayloadAction<ICdnRecords>) => {
        state.cdnRecords = action.payload;
      }
    );
  },
});

export const {
  setBuckets,
  setBucketsNames,
  setSelectedBucket,
  setSelectedUpload,
  setUploads,
  setIpnsRecordCreated,
  resetStorageState,
  toggleBucketsLoading,
  setCurrentlyUploaded,
  setShowUploadCard,
  setFileUploadList,
  toggleShowUploadModalLoading,
} = storageSlice.actions;
export const { reducer: storageRTKReducer } = storageSlice;
