import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import { Feedback } from '@spheron/ui-library';
// eslint-disable-next-line max-len
// import { ReactComponent as MaintainanceIcon } from '@spheron/ui-library/dist/assets/spheron-specific/maintainance.svg';
// import { ReactComponent as InfoIcon } from '@spheron/ui-library/dist/assets/info-circle.svg';
import { useDispatch, useSelector } from 'react-redux';
import LoginStyle from '../../../../styles/compute/log-in.module.scss';
import ProviderButton from '../../../../components/Compute/Buttons/provider-login';
import Loader from '../../../../components/Compute/Loaders/loader';
import { userSessionExpiredRtk } from '../../../../redux/compute/user/user.slice';
import { addNotificationRtk } from '../../../../redux/compute/notification/notification.slice';
import { NotificationType } from '../../../../redux/compute/notification/notification.interfaces';
import { RootState } from '../../../../redux/compute/store';
import SpheronDarkLogo from '../../../../assets/global/spheron-logo-two-dark.svg';
import SpheronLogo from '../../../../assets/global/spheron-logo-two.svg';
import TabComponent from '../../../../components/Compute/TabComponent/tab-component';
import CustomConnectButton from '../../../../components/Compute/Buttons/custom-connect';
import SignatureLoading from '../../../../components/Compute/Loaders/signature-loading';
// eslint-disable-next-line max-len
import RightTestimonialsComponent from '../../../../components/Compute/RightTestimonialsComponent/right-testimonials-component';
import { isDarkTheme } from '../../../../redux/compute/theme/theme.utils';
import { ProviderType } from '../../../../redux/compute/combined-state.interface';
// import { ANNOUNCEMENT_DATA } from '../../../config';

const Login = () => {
  const dispatchRtk = useDispatch();
  const [openTab, setOpenTab] = useState<number>(1);
  const tabs = [
    { openTab: 1, text: 'Web3' },
    { openTab: 2, text: 'Web2' },
  ];
  const signupCallbackLoading: boolean | null = useSelector(
    (state: RootState) => state.user.signupCallbackLoading
  );
  const signatureLoading: boolean = useSelector(
    (state: RootState) => state.web3.signatureLoading
  );
  const theme = useSelector((state: RootState) => state.theme.theme);

  useEffect(() => {
    if (localStorage.getItem('referrer') && !localStorage.getItem('logout')) {
      dispatchRtk(userSessionExpiredRtk());
      dispatchRtk(
        addNotificationRtk({
          title: 'Session Expired',
          message:
            'Your session has expired please login to continue from where you left',
          timestamp: Date.now(),
          type: NotificationType.Info,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (signupCallbackLoading) {
    return (
      <div className="h-screen w-screen">
        <Loader />
      </div>
    );
  }

  const loginButtonOneData = [
    {
      id: 1,
      text: 'Google',
      provider: ProviderType.GOOGLE,
      disable: false,
    },
    {
      id: 2,
      text: 'GitHub',
      provider: ProviderType.GITHUB,
      disable: false,
    },
    {
      id: 3,
      text: 'Gitlab',
      provider: ProviderType.GITLAB,
      disable: false,
    },
    {
      id: 4,
      text: 'Bitbucket',
      provider: ProviderType.BITBUCKET,
      disable: false,
    },
  ];

  const loginButtonTwoData = [
    {
      id: 1,
      text: 'Ethereum',
      provider: ProviderType.METAMASK,
      disable: false,
    },
    {
      id: 2,
      text: 'Phantom',
      provider: ProviderType.PHANTOM,
      disable: true,
    },
    {
      id: 3,
      text: 'Lens',
      provider: ProviderType.LENS,
      disable: true,
    },
  ];

  // const announcementData = JSON.parse(ANNOUNCEMENT_DATA);

  // const showAnnouncementBar =
  //   new Date(announcementData.expiry).getTime() > new Date().getTime();

  return (
    <div
      className={`${LoginStyle.light__blue__bg} overflow-hidden bg-base-bg 
      dark:bg-dark-base-bg grid grid-cols-2`}
    >
      {/* <div className="fixed top-0 left-0 w-full z-[999]">
        {showAnnouncementBar && (
          <Feedback
            feedbackType={announcementData.type}
            icon={<InfoIcon />}
            subTitle={
              <div className="text-[11px]">
                {announcementData.text}{' '}
                <a
                  // eslint-disable-next-line max-len
                  // href=""
                  target="_blank"
                  className="font-600 hover:cursor-pointer hover:underline"
                  rel="noreferrer"
                >
                  View Details
                </a>
              </div>
            }
            title=""
            showClose={false}
            withShadow={false}
            position="center"
            size="compact"
          />
        )}
      </div> */}
      <div
        className="flex flex-col justify-center items-center 
          border-r border-base-border dark:border-dark-base-border"
      >
        <div className="flex flex-col items-center flex-1 justify-center">
          <img
            src={
              theme === null || isDarkTheme(theme)
                ? SpheronDarkLogo
                : SpheronLogo
            }
            alt="spheron"
          />
          <h2
            className="mt-6 tracking-tighter
            text-base-para-text-color dark:text-dark-base-para-text-color
            font-bold text-4xl"
          >
            Welcome back Spheronian 👩🏻‍🚀
          </h2>
          <div className="relative">
            <TabComponent
              tabs={tabs}
              openTab={openTab}
              setOpenTab={setOpenTab}
              disable={signatureLoading}
            />
          </div>
          <div className="flex flex-col gap-8">
            {openTab === 1 && (
              <div id="link-two" className="h-72">
                <>
                  {signatureLoading ? (
                    <SignatureLoading />
                  ) : (
                    <>
                      {loginButtonTwoData.map((btn) => (
                        <div key={btn.id}>
                          <CustomConnectButton
                            className="mt-6"
                            text={`Continue with ${btn.text}`}
                            provider={btn.provider}
                            type="login"
                            disable={btn.disable}
                          />
                        </div>
                      ))}
                    </>
                  )}
                </>
              </div>
            )}
            {openTab === 2 && (
              <div id="link-one" className="h-72">
                {loginButtonOneData.map((btn) => (
                  <div key={btn.id}>
                    <ProviderButton
                      className="mt-6"
                      text={`Continue with ${btn.text}`}
                      provider={btn.provider}
                      type="login"
                      disable={btn.disable}
                    />
                  </div>
                ))}
              </div>
            )}
            <div
              className="flex justify-center mt-8 text-base font-normal 
          text-base-para-text-color dark:text-dark-base-para-text-color"
            >
              <p>
                Don’t have an account?{' '}
                <span
                  className={
                    signatureLoading ? 'cursor-not-allowed' : 'cursor-pointer'
                  }
                >
                  <Link
                    to="/signup"
                    className={
                      signatureLoading
                        ? 'pointer-events-none'
                        : 'pointer-events-auto'
                    }
                  >
                    <span className="inline-block hover:underline text-dark-base-link">
                      Sign Up
                    </span>
                  </Link>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center mb-6">
          <p
            className="text-xs text-center font-normal leading-5 
          text-base-sub-text-color dark:text-dark-base-sub-text-color ll:w-[40rem]"
          >
            Join a vast community of trusted developers relying on us for Web3
            project management and deployment.
            <br />
            Log in now to access your personalized dashboard, form multiple
            teams, and deploy projects seamlessly with Spheron Infra.
          </p>
        </div>
      </div>
      <RightTestimonialsComponent />
    </div>
  );
};

export default Login;
