import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as ComputeIcon } from '@spheron/ui-library/dist/assets/compute/compute.svg';
import { ReactComponent as MultiserviceIcon } from '@spheron/ui-library/dist/assets/compute/multi-service.svg';
import { ReactComponent as AccelerateIcon } from '@spheron/ui-library/dist/assets/compute/accelerate.svg';
import { useDispatch, useSelector } from 'react-redux';
import CheckboxButton from '../../../../../components/Compute/Buttons/checkbox-button';
import { AppDispatch, RootState } from '../../../../../redux/compute/store';
import {
  setDeploymentTypeRtk,
  setRecommendedPlanRtk,
  setScalingNameRtk,
  setScalingTypeRtk,
  updateComputeStepRtk,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.slice';
import { setSelectedTemplateRtk } from '../../../../../redux/compute/cluster/cluster.slice';
import { ProcessingType } from '../../../../../redux/compute/cluster/cluster.interfaces';
import { useQuery } from '../../../../../hooks/useQuery';
import {
  ConfirmRedeploySwitchType,
  InstanceDeploymentType,
  ScalingType,
  ScalingTypeName,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.interfaces';
import { toggleModalShowRtk } from '../../../../../redux/compute/modal/modal.slice';
import { getClusterTemplatesThunk } from '../../../../../redux/compute/cluster/cluster.thunks';

const ChooseDeploymentType = () => {
  const navigate = useNavigate();
  const dispatchRtk = useDispatch<AppDispatch>();
  const params = useParams();
  const selectedDeploymentType = useSelector(
    (state: RootState) => state.instanceCreation.deploymentType
  );
  const gpuClusterTemplates = useSelector(
    (state: RootState) => state.cluster.gpuClusterTemplates
  );
  const queryParams = useQuery();
  const redeployDeploymentType = queryParams.get('deploymentType');
  const type = queryParams.get('type');
  const handleButtonClick = (deploymentType: InstanceDeploymentType) => {
    const handleSwitch = () => {
      dispatchRtk(setDeploymentTypeRtk(deploymentType));
      dispatchRtk(updateComputeStepRtk(2));
      dispatchRtk(setSelectedTemplateRtk(null));
      if (deploymentType === InstanceDeploymentType.ACCELERATE) {
        dispatchRtk(setScalingNameRtk(ScalingTypeName.NO));
        dispatchRtk(setScalingTypeRtk(ScalingType.NO));
        if (gpuClusterTemplates.length === 0) {
          dispatchRtk(
            getClusterTemplatesThunk({
              search: '',
              category: '',
              isUrl: false,
              processingType: ProcessingType.GPU,
            })
          );
        }
      } else {
        dispatchRtk(setScalingNameRtk(ScalingTypeName.MANUAL));
        dispatchRtk(setScalingTypeRtk(ScalingType.MANUAL));
      }
    };
    if (type === 'redeploy') {
      dispatchRtk(
        toggleModalShowRtk({
          modalShow: true,
          modalType: 'confirmRedeploySwitch',
          options: {
            redeploySwitchType: ConfirmRedeploySwitchType.DEFAULT,
            confirmCallback: () => {
              navigate(`/compute/${params.computeProjectId}/new-instance`);
              handleSwitch();
            },
          },
        })
      );
    } else {
      handleSwitch();
    }
  };
  const deploymentTypes = [
    {
      id: 1,
      heading: 'Compute',
      subHeading: 'Run containers on CPU-based instance',
      icon: (
        <ComputeIcon className="w-8 h-8 text-base-icon dark:text-dark-base-icon" />
      ),
      isSelected: selectedDeploymentType === InstanceDeploymentType.COMPUTE,
      handleClick: () => {
        handleButtonClick(InstanceDeploymentType.COMPUTE);
      },
    },
    {
      id: 2,
      heading: 'Accelerate',
      subHeading: 'GPU-Powered Computation Boost',
      icon: (
        <AccelerateIcon className="w-8 h-8 text-base-icon dark:text-dark-base-icon" />
      ),
      isSelected: selectedDeploymentType === InstanceDeploymentType.ACCELERATE,
      handleClick: () => {
        handleButtonClick(InstanceDeploymentType.ACCELERATE);
      },
    },
    {
      id: 3,
      heading: 'Multiservice',
      subHeading: 'Run multiple services in an instance',
      icon: (
        <MultiserviceIcon className="w-8 h-8 text-base-icon dark:text-dark-base-icon" />
      ),
      isSelected:
        selectedDeploymentType === InstanceDeploymentType.MULTISERVICE,
      handleClick: () => {
        handleButtonClick(InstanceDeploymentType.MULTISERVICE);
      },
    },
  ];

  useEffect(() => {
    if (redeployDeploymentType) {
      dispatchRtk(
        setDeploymentTypeRtk(redeployDeploymentType as InstanceDeploymentType)
      );
    }
    dispatchRtk(setRecommendedPlanRtk(null));
  }, [dispatchRtk, redeployDeploymentType]);

  return (
    <div className="flex flex-col gap-y-6 mt-7">
      <h3 className=" font-bold text-xl">1. What are you looking for?</h3>
      <div className="flex justify-start gap-x-6 items-center w-full">
        {deploymentTypes.map((eachDeloymentType) => (
          <CheckboxButton
            key={eachDeloymentType.id}
            heading={eachDeloymentType.heading}
            icon={eachDeloymentType.icon}
            subHeading={eachDeloymentType.subHeading}
            isSelected={eachDeloymentType.isSelected}
            handleClick={eachDeloymentType.handleClick}
            disabled={false}
          />
        ))}
      </div>
    </div>
  );
};

export default ChooseDeploymentType;
