import React from 'react';
import Skeleton from 'react-loading-skeleton';

const TextInputLoader = () => {
  return (
    <div className="flex flex-col gap-1 items-start">
      <Skeleton
        height={15}
        duration={2}
        className="w-1/3"
        containerClassName="flex w-full rounded-lg"
      />
      <Skeleton
        height={48}
        duration={2}
        containerClassName="flex w-full rounded-lg"
      />
    </div>
  );
};

export default TextInputLoader;
