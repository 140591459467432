import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { BroadcastChannel } from 'broadcast-channel';
import { useSelector } from 'react-redux';
import Loader from '../../../../components/Compute/Loaders/loader';
import { isDarkTheme } from '../../../../redux/compute/theme/theme.utils';
import { RootState } from '../../../../redux/compute/store';

const ProviderCallback = () => {
  const params = useParams<{ provider: string }>();
  const theme = useSelector((state: RootState) => state.theme.theme);
  useEffect(() => {
    const bc = new BroadcastChannel(`provider_app_auth`);
    bc.postMessage(`authorized-${params.provider}`);
    window.close();

    return () => {
      bc.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`h-screen w-screen ${
        isDarkTheme(theme) && 'bg-feedback-default-bg'
      }`}
    >
      <Loader />
    </div>
  );
};

export default ProviderCallback;
