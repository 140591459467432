import React, { useEffect, useState } from 'react';
import { NetworkCard } from '@spheron/ui-library';

export enum WalletItemType {
  // eslint-disable-next-line no-unused-vars
  NETWORK = 'network',
  // eslint-disable-next-line no-unused-vars
  TOKEN = 'token',
  // eslint-disable-next-line no-unused-vars
  WALLET = 'wallet',
}

interface IProps {
  image: string;
  title: string;
  isActive: boolean;
  disabled: boolean;
  handleClick: () => void;
  type: WalletItemType;
  currentStep: number;
}

const ConnectWalletItem = ({
  image,
  title,
  isActive,
  disabled,
  handleClick,
  currentStep,
  type,
}: IProps) => {
  const [updatedDisableCondition, setUpdatedDisableCondition] =
    useState<boolean>(false);

  useEffect(() => {
    switch (currentStep) {
      case 0:
        setUpdatedDisableCondition(
          !disabled ||
            type === WalletItemType.TOKEN ||
            type === WalletItemType.WALLET
        );
        break;
      case 1:
      case 2:
        setUpdatedDisableCondition(!disabled || type === WalletItemType.WALLET);
        break;
      case 3:
        setUpdatedDisableCondition(!disabled);
        break;
      default:
        break;
    }
  }, [currentStep, disabled, type]);

  return (
    <>
      {updatedDisableCondition && (
        <NetworkCard
          icon={
            <img
              src={image}
              alt="network-icon"
              className="w-6 h-6 rounded-full"
            />
          }
          title={title}
          selected={isActive}
          handleClick={handleClick}
          disabled={disabled}
        />
      )}
    </>
  );
};

export default ConnectWalletItem;
