import React from 'react';

interface IProps {
  logoImage: string;
  infoImage: string;
  infoText: string;
}
const ResponsiveView = ({ logoImage, infoImage, infoText }: IProps) => {
  return (
    <div
      className="flex flex-col items-center z-[7777] 
    w-[100vw] h-[100vh] relative bg-base-bg 
      dark:bg-dark-base-bg pt-10"
    >
      <span className="mb-32">
        <img src={logoImage} alt="logo" />
      </span>
      <div className="flex items-center flex-col h-full">
        <span>
          <img src={infoImage} alt="info" />
        </span>
        <span
          className="text-base-heading-text-color dark:text-dark-base-heading-text-color
          font-bold text-xl mt-4 text-center w-64"
        >
          {infoText}
        </span>
      </div>
    </div>
  );
};

export default ResponsiveView;
