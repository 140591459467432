import React, { useMemo, useState } from 'react';
import { Dropdown, OptionType } from '@spheron/ui-library';
import { useDispatch } from 'react-redux';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import { capitalizeFirstLetter } from '../../../redux/compute/root-utils';
import { AppDispatch } from '../../../redux/compute/store';

interface Props {
  currentUserRole: string;
  role: string;
  modalOption: object;
  isCurrentUser: boolean;
}

function Role({ currentUserRole, role, modalOption, isCurrentUser }: Props) {
  const [dropdownRole, setDropdownRole] = useState<string>(role);
  const isPublicAccessible =
    sessionStorage.getItem('isPublicAccessible') === 'true';
  const communityUserAccess =
    sessionStorage.getItem('communityUserAccess') === 'true';
  const dispatchRtk = useDispatch<AppDispatch>();

  const dropdownOptions = useMemo(() => {
    const options = ['Admin', 'Member'];
    return options.map((option) => ({
      label: option,
      disable: option.toLowerCase() === role,
      value: option.toLowerCase(),
      optionType: 'primary' as OptionType,
    }));
  }, [role]);

  if (
    role === 'owner' ||
    currentUserRole === 'member' ||
    isCurrentUser ||
    !currentUserRole ||
    (isPublicAccessible && !communityUserAccess)
  )
    return (
      <div className="w-[100px]">
        <Dropdown
          dropdownSize="compact"
          dropdownType="default"
          label=""
          filled
          defaultSelected={role}
          disable
          placeholder={role}
        />
      </div>
    );

  return (
    <div className="w-[100px] absolute">
      <Dropdown
        dropdownSize="compact"
        dropdownType="default"
        filled
        placeholder={capitalizeFirstLetter(dropdownRole)}
        onSelected={(selected) => {
          dispatchRtk(
            toggleModalShowRtk({
              modalShow: true,
              modalType: 'changeMemberRole',
              options: {
                ...modalOption,
                currentRole: role,
                role: selected.value as string,
                setDropdownRole,
              },
            })
          );
        }}
        defaultSelected={dropdownRole}
        options={dropdownOptions}
      />
    </div>
  );
}

export default Role;
