import React, { useEffect, Fragment, useState } from 'react';
import makeBlockie from 'ethereum-blockies-base64';
import { Button, Modal } from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Spheron } from '../../../assets/compute/global/spheron-logo.svg';
import { useShortAddress } from '../../../hooks/useShortAddress';
import { getNetworkFromName } from '../../../redux/compute/web3/web3.utils';
import Styles from '../../../styles/compute/modal.module.scss';
import {
  IBalance,
  IOrganisationWallet,
} from '../../../redux/compute/web3/web3.interfaces';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import {
  disconnectPhantomWalletThunk,
  fetchSolBalanceThunk,
} from '../../../redux/solana/solana.thunks';
import ModalWrapper from './ModalWrapper';
import SpinLoader from '../../Loaders/spin-loader';
import WalletBalance from '../Balance';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';

interface IProps {
  isOpen: boolean;
  id: string;
}
const ViewPhantomWallet = ({ isOpen, id }: IProps) => {
  const web3Wallets = useSelector((state: RootState) => state.web3.wallets);

  const disconnectWalletLoading: boolean = useSelector(
    (state: RootState) => state.web3.disconnectWalletLoading
  );

  const escrowBalanceLoading: boolean = useSelector(
    (state: RootState) => state.web3.escrowBalanceLoading
  );

  const balance: IBalance = useSelector(
    (state: RootState) => state.web3.balance
  );
  const [selectedWallet, setSelectedWallet] = useState<IOrganisationWallet>();

  useEffect(() => {
    if (id) {
      setSelectedWallet(web3Wallets.find((wallet) => wallet._id === id));
    }
  }, [id, web3Wallets]);

  const dispatchRtk = useDispatch<AppDispatch>();

  const shortAddress = useShortAddress(selectedWallet?.details.address || '');

  const refreshSolanaBalance = (): void => {
    dispatchRtk(
      fetchSolBalanceThunk({
        token: selectedWallet?.details.tokenDetails!.address as string,
        address: selectedWallet?.details.address as string,
        chainId: Number(selectedWallet?.details.networkDetails!.chainId),
      })
    );
  };

  useEffect(() => {
    if (selectedWallet) {
      dispatchRtk(
        fetchSolBalanceThunk({
          token: selectedWallet?.details.tokenDetails!.address as string,
          address: selectedWallet?.details.address as string,
          chainId: Number(selectedWallet?.details.networkDetails!.chainId),
        })
      );
    }
  }, [selectedWallet, dispatchRtk]);

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={() => dispatchRtk(toggleModalShowRtk({ modalShow: false }))}
      classname={`${Styles.modal__outer__con} w-430 modal fixed inset-0 overflow-y-auto`}
    >
      <div className={Styles.modal__inner__content}>
        <Modal
          footer
          header
          subtitle=""
          modalContent={
            <>
              <>
                <div
                  className="flex items-center lg:items-start space-y-6 flex-col md:flex-row
                           justify-between md:space-y-0 md:items-center flex-wrap w-full"
                >
                  <Spheron className="w-11 h-12" />
                  <div className="flex flex-col lg:items-start items-center">
                    <div className="dark:text-gray-100 text-gray-500 font-normal text-xs">
                      Network
                    </div>
                    <div className="flex items-center justify-start mt-3">
                      <img
                        src={selectedWallet?.details?.networkDetails?.logo}
                        alt={selectedWallet?.details.networkDetails?.chainName}
                        className="mr-3 h-5 w-5"
                      />
                      <div className="dark:text-gray-200 font-bold text-gray-600">
                        {getNetworkFromName(
                          Number(
                            selectedWallet?.details.networkDetails?.chainId
                          )
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col lg:items-start items-center">
                    <div className="dark:text-gray-100 text-gray-500 text-xs font-normal">
                      Token
                    </div>
                    <div className="flex items-center justify-start mt-3">
                      <img
                        src={selectedWallet?.details.tokenDetails?.logoURI}
                        alt="Polygon"
                        className="mr-3 h-5 w-5"
                      />
                      <div className="font-bold dark:text-gray-200 text-gray-600">
                        {selectedWallet?.details.tokenDetails?.symbol}
                      </div>
                    </div>
                  </div>
                </div>
              </>
              <div
                className="flex flex-col space-y-4
                           md:space-y-0 md:flex-row justify-between items-center mt-6"
              >
                {selectedWallet?.details.address && (
                  <div className="flex">
                    <div
                      className="text-sm 
                           font-semibold text-gray-500 flex items-center justify-center"
                    >
                      <img
                        src={makeBlockie(selectedWallet?.details.address)}
                        alt="address-blockie"
                        className="w-8 h-8 rounded-full mr-2.5"
                      />
                      {shortAddress}
                    </div>
                    <WalletBalance selectedWallet={selectedWallet} />
                    <div className="ml-4 flex items-center">
                      {balance.amount !== '' ? (
                        <SpinLoader
                          loading1={escrowBalanceLoading}
                          handleClick={refreshSolanaBalance}
                        />
                      ) : (
                        <Button
                          buttonType="primary"
                          label="Fetch Balance"
                          size="medium"
                          onClick={refreshSolanaBalance}
                          loading={escrowBalanceLoading}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </>
          }
          headerContent={<>Account</>}
          primaryButtonContent="Disconnect"
          secondaryButtonContent="Cancel"
          primaryButton
          primaryButtonLoading={disconnectWalletLoading}
          onPrimaryButtonClick={() =>
            dispatchRtk(
              disconnectPhantomWalletThunk({
                id: selectedWallet?._id || '',
                address: '',
              })
            )
          }
          onSecondaryButtonClick={() =>
            dispatchRtk(toggleModalShowRtk({ modalShow: false }))
          }
          secondaryButton
        />
      </div>
    </ModalWrapper>
  );
};

export default ViewPhantomWallet;
