import React, { useEffect } from 'react';
import { BroadcastChannel } from 'broadcast-channel';
import { useDispatch } from 'react-redux';
import { addNotificationRtk } from '../../../redux/compute/notification/notification.slice';
import { NotificationType } from '../../../redux/compute/notification/notification.interfaces';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import {
  getBannersThunk,
  getUserDetailsThunk,
} from '../../../redux/compute/user/user.thunks';
import { toggleSignupCallbackLoadingRtk } from '../../../redux/compute/user/user.slice';
import { isCommunityOrgRoute } from '../../../redux/compute/organisation/organisation.utils';
import { AppDispatch } from '../../../redux/compute/store';

const SignupCallback = () => {
  const dispatchRtk = useDispatch<AppDispatch>();
  useEffect(() => {
    const bc = new BroadcastChannel('signin_channel');
    const shouldFetchUser =
      window.location.pathname.indexOf('/signup/') !== -1 ||
      window.location.pathname.indexOf('/login/') !== -1 ||
      window.location.pathname.indexOf('/callback/') !== -1 ||
      window.location.pathname.indexOf('/auth') !== -1 ||
      window.location.pathname.indexOf('/callback/auth') !== -1 ||
      window.location.pathname.indexOf('/new-login-connection') !== -1 ||
      window.location.pathname.indexOf('/connection-callback') !== -1 ||
      window.location.pathname.indexOf('/crypto-payment-callback') !== -1;

    bc.onmessage = (msg) => {
      if (msg === 'signedup' || msg === 'login_successful') {
        const isReferred = localStorage.getItem('referrer');
        const referredFrom = localStorage.getItem('referredFrom');
        if (isReferred) {
          if (isCommunityOrgRoute(window.location.pathname.split('/')[1])) {
            sessionStorage.setItem('communityFlow', 'true');
            sessionStorage.setItem(
              'communityOrgName',
              window.location.pathname.split('/')[1]
            );
          }
          if (!shouldFetchUser) {
            dispatchRtk(getUserDetailsThunk(''));
          }
          setTimeout(() => {
            if (referredFrom) {
              dispatchRtk(getBannersThunk());
              // (window as any)?.rrNavigate(`/${referredFrom}`);
              window.open(`${window.location.origin}${referredFrom}`, '_self');
              localStorage.removeItem('referrer');
              localStorage.removeItem('referredFrom');
            } else {
              window.open(
                `${window.location.origin}/compute/dashboard`,
                '_self'
              );
              localStorage.removeItem('referrer');
            }
          }, 100);
          localStorage.removeItem('logout');
          localStorage.removeItem('referralCode');
        } else {
          dispatchRtk(getBannersThunk());
          // fetch user data
          if (!shouldFetchUser) {
            dispatchRtk(getUserDetailsThunk(''));
          }
        }
      } else if (msg === 'user_not_found') {
        window.open(`${window.location.origin}/signup`, '_self');
        dispatchRtk(
          addNotificationRtk({
            message:
              // eslint-disable-next-line max-len
              "You don't have an account in Spheron. Please sign up to create a new spheron account.",
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
      } else if (msg === 'ask_admin_to_provide_access_to_dev_env') {
        dispatchRtk(
          addNotificationRtk({
            message:
              // eslint-disable-next-line max-len
              "You don't have access to the dev environment. please ask your administrator to provide access to the dev environment.",
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
      } else if (msg === 'waiting_for_access_to_dev_env') {
        dispatchRtk(
          addNotificationRtk({
            message:
              // eslint-disable-next-line max-len
              "You don't have access to the dev environment. please wait for your administrator to provide access to the dev environment.",
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
      } else if (msg === 'account_with_git_email_exists') {
        dispatchRtk(
          addNotificationRtk({
            message:
              'There is already an account associated with your Git email address.',
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
      } else if (msg === 'email_already_exists_with_another_account') {
        dispatchRtk(
          addNotificationRtk({
            message:
              'There is already an account associated with your email address.',
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
      } else if (msg === 'new_connection_email_mismatch') {
        dispatchRtk(
          addNotificationRtk({
            message:
              'The new provider connection uses a different email address.',
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
      } else if (msg === 'user_already_has_github_connection') {
        dispatchRtk(
          addNotificationRtk({
            message: 'Github connection already exists.',
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
      } else if (msg === 'user_already_has_gitlab_connection') {
        dispatchRtk(
          addNotificationRtk({
            message: 'Gitlab connection already exists.',
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
      } else if (msg === 'user_already_has_bitbucket_connection') {
        dispatchRtk(
          addNotificationRtk({
            message: 'Bitbucket connection already exists.',
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
      } else if (msg === 'security_check' || msg === 'banned') {
        dispatchRtk(
          toggleModalShowRtk({
            modalType: 'security_check',
            modalShow: true,
          })
        );
      }
      setTimeout(() => {
        dispatchRtk(toggleSignupCallbackLoadingRtk(false));
      }, 3000);
    };
    return () => {
      dispatchRtk(toggleSignupCallbackLoadingRtk(false));
      bc.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isJWTPresent = localStorage.getItem('jwt-token');
    const shouldFetchUser =
      window.location.pathname.indexOf('/signup/') !== -1 ||
      window.location.pathname.indexOf('/login/') !== -1 ||
      window.location.pathname.indexOf('/callback/') !== -1 ||
      window.location.pathname.indexOf('/auth') !== -1 ||
      window.location.pathname.indexOf('/callback/auth') !== -1 ||
      window.location.pathname.indexOf('/new-login-connection') !== -1 ||
      window.location.pathname.indexOf('/connection-callback') !== -1 ||
      window.location.pathname.indexOf('/crypto-payment-callback') !== -1;
    if (isJWTPresent && !shouldFetchUser) {
      // fetch user data
      dispatchRtk(getUserDetailsThunk(''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default SignupCallback;
