import { ITheme } from './theme.interfaces';

export const mapStringToThemeEnum = (theme: string) => {
  switch (theme) {
    case 'light':
      return ITheme.LIGHT;
    case 'dark':
      return ITheme.DARK;
    default:
      return ITheme.LIGHT;
  }
};

export const isDarkTheme = (theme: string | null): boolean => {
  return !!theme && theme === 'dark';
};
