import { Modal } from '@spheron/ui-library';
import React, { useEffect, useState } from 'react';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useDispatch } from 'react-redux';
import Styles from '../../../styles/compute/modal.module.scss';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import ModalWrapper from './ModalWrapper';
import config from '../../../config';
import { toggleAddingPaymentMethodRtk } from '../../../redux/compute/organisation/organisation.slice';
import StripeCheckout from '../Checkout/stripe-checkout';
import { AppDispatch } from '../../../redux/compute/store';

interface IProps {
  isOpen: boolean;
}

const StripePaymentWrapper = ({ isOpen }: IProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const closeModal = () => {
    dispatch(toggleModalShowRtk({ modalShow: false }));
  };

  const [stripePromise, setStripePromise] = useState<Stripe | null>(null);

  const clientSecret = localStorage.getItem('client-secret');

  const loadStriplePromise = async () => {
    setStripePromise(await loadStripe(config.stripe.PUBLIC_KEY));
  };
  useEffect(() => {
    loadStriplePromise();
    return () => {
      dispatch(toggleAddingPaymentMethodRtk(false));
      localStorage.removeItem('client-secret');
      localStorage.removeItem('redirectUrl');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const appearance = {
    variables: {
      colorPrimary: '#0570de',
      colorBackground: '#ffffff',
      colorText: '#667085',
      colorDanger: '#df1b41',
      fontFamily: 'Inter',
      spacingUnit: '2px',
      borderRadius: '4px',
    },
    rules: {
      '.Label': {
        paddingTop: '8px',
        fontSize: '14px',
      },
      '.Input': {
        paddingTop: '12px',
        paddingLeft: '6px',
        paddingBottom: '12px',
        fontSize: '16px',
      },
    },
  };
  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={closeModal}
      classname={`${Styles.modal__outer__con} modal fixed inset-0 overflow-y-auto`}
    >
      <div className="w-min">
        <Modal
          modalContent={
            <div className="flex flex-col items-center gap-y-3">
              {stripePromise && clientSecret && (
                <div className="overflow-scroll rounded-lg">
                  <Elements
                    stripe={stripePromise}
                    options={{ clientSecret, appearance }}
                  >
                    <StripeCheckout stripePromise={stripePromise} />
                  </Elements>
                </div>
              )}
            </div>
          }
          primaryButtonContent={''}
          secondaryButtonContent={''}
          subtitle={''}
        />
      </div>
    </ModalWrapper>
  );
};

export default StripePaymentWrapper;
