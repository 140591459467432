import { PayloadAction, createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import {
  addSpheronCreditThunk,
  createBonusSubscriptionThunk,
  createSecurityBonusSubscriptionThunk,
  createSubscriptionsThunk,
  deleteSpheronCreditThunk,
  getActiveBonusThunk,
  getActiveSubscriptionsThunk,
  getAllDefaultPackagesThunk,
  getAllowedBonusesThunk,
  getCouponValidityThunk,
  getSubscriptionHistoryThunk,
  getSubscriptionInvoicesThunk,
  getSubscriptionUsageThunk,
  removeActiveBonusThunk,
  removePendingSubscriptionThunk,
  scheduleBonusThunk,
  updateBonusAmountThunk,
} from './subscription.thunks';
import {
  IBonus,
  IBonusIterator,
  IDefaultPlanResponse,
  IScheduleBonusResponseDto,
  ISubscription,
  ISubscriptionInvoice,
  ISubscriptionState,
  ISubscriptionUsage,
} from './subscription.interfaces';
import { PaymentMethod } from '../combined-state.interface';

export const initialState: ISubscriptionState = {
  purchasingSubscription: false,
  removingSubscription: false,
  subscriptionsLoading: true,
  subscriptionUsageLoading: true,
  subscriptionUpdating: false,
  subscriptionInvoicesLoading: true,
  defaultPackagesLoading: true,
  activeIsDefaultPackage: true,
  allowedBonusesLoading: true,
  activeSubscription: null,
  demandedSubscription: null,
  pendingSubscription: null,
  reservedSubscription: null,
  defaultSubscription: [],
  subscriptionInvoices: [],
  activeBonus: [],
  selectedSubscription: null,
  subscriptionUsage: {} as ISubscriptionUsage,
  subscriptionPurchaseProgress: 0,
  couponLoading: false,
  isCouponValid: false,
  couponValidationLoading: false,
  activeBonusLoading: true,
  allowedBonus: [],
  allowedBonusIterator: [],
  poll: false,
  purchasingBonus: false,
  subscriptionPlanHistory: null,
  subscriptionPlanHistoryLoading: false,
  scheduleBonusLoading: false,
  updatingBonusAmountLoading: false,
  computeTopupSelectedPaymentMethod: null,
};

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    createSelectedSubscription(state, action: PayloadAction<any>) {
      state.selectedSubscription = action.payload;
    },
    toggleSubscriptionLoading(state, action: PayloadAction<boolean>) {
      state.subscriptionsLoading = action.payload;
    },
    getSubscriptionUsageEnd(state, action: PayloadAction<ISubscriptionUsage>) {
      state.subscriptionUsage = action.payload;
    },
    setSubscriptionPurchaseProgress(state, action: PayloadAction<number>) {
      state.subscriptionPurchaseProgress = action.payload;
    },
    toggleIsCouponValid(state, action: PayloadAction<boolean>) {
      state.isCouponValid = action.payload;
    },
    setPoll(state, action: PayloadAction<boolean>) {
      state.poll = action.payload;
    },
    setSubscriptionToDefault(state) {
      Object.assign(state, initialState);
    },
    setComputeTopupSelectedPaymentMethod(
      state,
      action: PayloadAction<PaymentMethod | null>
    ) {
      state.computeTopupSelectedPaymentMethod = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllDefaultPackagesThunk.pending, (state) => {
      state.defaultPackagesLoading = true;
    });
    builder.addCase(
      getAllDefaultPackagesThunk.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.defaultSubscription = action.payload.map(
          (defaultPackage: IDefaultPlanResponse) => {
            return {
              ...defaultPackage.params,
              span: defaultPackage.span,
              default: defaultPackage.default,
              createdAt: defaultPackage.createdAt,
              updatedAt: defaultPackage.updatedAt,
              _id: defaultPackage._id,
              priceFirst: defaultPackage.priceFirst,
              price: defaultPackage.price,
              name: defaultPackage.name,
              priority: defaultPackage.priority,
              displayName: defaultPackage.displayName,
              description: defaultPackage.description,
            };
          }
        );
        state.defaultPackagesLoading = false;
      }
    );
    builder.addCase(getAllDefaultPackagesThunk.rejected, (state) => {
      state.defaultPackagesLoading = false;
    });

    builder.addCase(getActiveSubscriptionsThunk.pending, (state) => {
      state.subscriptionsLoading = true;
    });
    builder.addCase(
      getActiveSubscriptionsThunk.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.activeIsDefaultPackage = action.payload[0].activeIsDefaultPackage;
        state.pendingSubscription = action.payload[0].pendingSubscription;
        state.demandedSubscription = action.payload[0].demandedSubscription;
        state.activeSubscription = action.payload[0].activeSubscription;
        state.reservedSubscription = action.payload[0].reservedSubscription;
        state.subscriptionsLoading = false;
      }
    );
    builder.addCase(getActiveSubscriptionsThunk.rejected, (state) => {
      state.subscriptionsLoading = false;
    });

    builder.addCase(getSubscriptionInvoicesThunk.pending, (state) => {
      state.subscriptionInvoicesLoading = true;
    });
    builder.addCase(
      getSubscriptionInvoicesThunk.fulfilled,
      (state, action: PayloadAction<ISubscriptionInvoice[]>) => {
        state.subscriptionInvoices = action.payload;
        state.subscriptionInvoicesLoading = false;
      }
    );
    builder.addCase(getSubscriptionInvoicesThunk.rejected, (state) => {
      state.subscriptionInvoicesLoading = false;
    });

    builder.addCase(removePendingSubscriptionThunk.fulfilled, (state) => {
      state.removingSubscription = false;
      state.pendingSubscription = null;
    });

    builder.addCase(removePendingSubscriptionThunk.rejected, (state) => {
      state.removingSubscription = false;
    });

    builder.addCase(removePendingSubscriptionThunk.pending, (state) => {
      state.removingSubscription = true;
    });

    builder.addCase(createBonusSubscriptionThunk.pending, (state) => {
      state.purchasingBonus = true;
    });
    builder.addCase(createBonusSubscriptionThunk.fulfilled, (state) => {
      state.purchasingBonus = false;
    });
    builder.addCase(createBonusSubscriptionThunk.rejected, (state) => {
      state.purchasingBonus = false;
    });

    builder.addCase(createSecurityBonusSubscriptionThunk.pending, (state) => {
      state.purchasingBonus = true;
    });
    builder.addCase(createSecurityBonusSubscriptionThunk.fulfilled, (state) => {
      state.purchasingBonus = false;
    });
    builder.addCase(createSecurityBonusSubscriptionThunk.rejected, (state) => {
      state.purchasingBonus = false;
    });

    builder.addCase(getActiveBonusThunk.pending, (state) => {
      state.activeBonusLoading = true;
    });
    builder.addCase(
      getActiveBonusThunk.fulfilled,
      (state, action: PayloadAction<IBonus[]>) => {
        state.activeBonus = action.payload || [];
        state.activeBonusLoading = false;
      }
    );
    builder.addCase(getActiveBonusThunk.rejected, (state) => {
      state.activeBonusLoading = false;
    });

    builder.addCase(createSubscriptionsThunk.pending, (state) => {
      state.purchasingSubscription = true;
    });
    builder.addCase(createSubscriptionsThunk.fulfilled, (state) => {
      state.purchasingSubscription = false;
    });
    builder.addCase(createSubscriptionsThunk.rejected, (state) => {
      state.purchasingSubscription = false;
    });

    builder.addCase(getSubscriptionUsageThunk.pending, (state) => {
      state.subscriptionUsageLoading = true;
    });
    builder.addCase(
      getSubscriptionUsageThunk.fulfilled,
      (state, action: PayloadAction<ISubscriptionUsage>) => {
        state.subscriptionUsage = action.payload;
        state.subscriptionUsageLoading = false;
      }
    );
    builder.addCase(getSubscriptionUsageThunk.rejected, (state) => {
      state.subscriptionUsage = {} as ISubscriptionUsage;
      state.subscriptionUsageLoading = false;
    });

    builder.addCase(removeActiveBonusThunk.pending, (state) => {
      state.subscriptionUpdating = true;
    });
    builder.addCase(
      removeActiveBonusThunk.fulfilled,
      (state, action: PayloadAction<any>) => {
        const newBonuses: any = state.activeBonus.filter(
          (bonus) => bonus._id !== action.payload
        );

        state.activeBonus = [
          ...newBonuses,
          {
            ...state.activeBonus.find((bonus) => bonus._id === action.payload),
            renew: false,
          },
        ];

        state.subscriptionUpdating = false;
      }
    );
    builder.addCase(removeActiveBonusThunk.rejected, (state) => {
      state.subscriptionUpdating = false;
    });

    builder.addCase(deleteSpheronCreditThunk.pending, (state) => {
      state.couponLoading = true;
    });
    builder.addCase(deleteSpheronCreditThunk.fulfilled, (state) => {
      state.couponLoading = false;
      state.subscriptionsLoading = false;
    });
    builder.addCase(deleteSpheronCreditThunk.rejected, (state) => {
      state.couponLoading = false;
      state.subscriptionsLoading = false;
    });

    builder.addCase(addSpheronCreditThunk.pending, (state) => {
      state.subscriptionsLoading = true;
      state.couponLoading = true;
    });
    builder.addCase(addSpheronCreditThunk.fulfilled, (state) => {
      state.couponLoading = false;
      state.subscriptionsLoading = false;
    });
    builder.addCase(addSpheronCreditThunk.rejected, (state) => {
      state.couponLoading = false;
      state.subscriptionsLoading = false;
    });

    builder.addCase(getCouponValidityThunk.pending, (state) => {
      state.couponValidationLoading = true;
    });
    builder.addCase(
      getCouponValidityThunk.fulfilled,
      (state, action: PayloadAction<boolean>) => {
        state.isCouponValid = action.payload;
        state.couponValidationLoading = false;
      }
    );
    builder.addCase(getCouponValidityThunk.rejected, (state) => {
      state.couponValidationLoading = false;
    });

    builder.addCase(getAllowedBonusesThunk.pending, (state) => {
      state.allowedBonusesLoading = true;
    });
    builder.addCase(
      getAllowedBonusesThunk.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.allowedBonusIterator = action.payload;
        state.allowedBonus = action.payload?.map(
          (bonus: IBonusIterator) => bonus.name
        );
        state.allowedBonusesLoading = false;
      }
    );
    builder.addCase(getAllowedBonusesThunk.rejected, (state) => {
      state.allowedBonusesLoading = false;
    });

    builder.addCase(getSubscriptionHistoryThunk.pending, (state) => {
      state.subscriptionUsageLoading = true;
    });
    builder.addCase(
      getSubscriptionHistoryThunk.fulfilled,
      (state, action: PayloadAction<ISubscription[]>) => {
        state.subscriptionPlanHistory = action.payload;
        state.subscriptionUsageLoading = false;
      }
    );
    builder.addCase(getSubscriptionHistoryThunk.rejected, (state) => {
      state.subscriptionUsageLoading = false;
    });

    builder.addCase(scheduleBonusThunk.pending, (state) => {
      state.scheduleBonusLoading = true;
    });

    builder.addCase(
      scheduleBonusThunk.fulfilled,
      (state, action: PayloadAction<IScheduleBonusResponseDto>) => {
        state.scheduleBonusLoading = false;
        state.activeBonus = [
          ...state.activeBonus,
          action.payload.bonus as unknown as IBonus,
        ];
      }
    );

    builder.addCase(scheduleBonusThunk.rejected, (state) => {
      state.scheduleBonusLoading = false;
    });

    builder.addCase(updateBonusAmountThunk.pending, (state) => {
      state.updatingBonusAmountLoading = true;
    });

    builder.addCase(
      updateBonusAmountThunk.fulfilled,
      (state, action: PayloadAction<{ bonusId: string; value: number }>) => {
        state.updatingBonusAmountLoading = false;
        state.activeBonus = [
          {
            ...state.activeBonus.find(
              (bonus) => bonus._id === action.payload.bonusId
            )!,
            value: action.payload.value,
          },
          ...state.activeBonus,
        ];
      }
    );

    builder.addCase(updateBonusAmountThunk.rejected, (state) => {
      state.updatingBonusAmountLoading = false;
    });
  },
});

export const {
  createSelectedSubscription: createSelectedSubscriptionRtk,
  toggleSubscriptionLoading: toggleSubscriptionLoadingRtk,
  getSubscriptionUsageEnd: getSubscriptionUsageEndRtk,
  setSubscriptionPurchaseProgress: setSubscriptionPurchaseProgressRtk,
  toggleIsCouponValid: toggleIsCouponValidRtk,
  setPoll: setPollRtk,
  setSubscriptionToDefault: setSubscriptionToDefaultRtk,
  setComputeTopupSelectedPaymentMethod,
} = subscriptionSlice.actions;
export const { reducer: subscriptionRTKReducer } = subscriptionSlice;
