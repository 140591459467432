import React, { useMemo, useState } from 'react';
import { OptionType } from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import AddClusterDomains from '../../../../components/Compute/Forms/add-cluster-domain';
import { ClusterInstanceDomain } from '../../../../redux/compute/combined-state.interface';
import { validateDomain } from '../../../../redux/compute/project/project.utils';
import {
  ICreateInstanceDomainPayloadDto,
  InstanceStatus,
} from '../../../../redux/compute/instance/instance.interfaces';
import InstanceDomainCard from '../../../../components/Compute/Cards/instance-domain-card';
import { AppDispatch, RootState } from '../../../../redux/compute/store';
import { createDomainThunk } from '../../../../redux/compute/instance/instance.thunks';

interface Option {
  label: string;
  value: string;
  optionType: OptionType;
}

const InstanceDomain = () => {
  const isPublicAccessible =
    sessionStorage.getItem('isPublicAccessible') === 'true';
  const communityUserAccess =
    sessionStorage.getItem('communityUserAccess') === 'true';

  const dispatchRtk = useDispatch<AppDispatch>();

  const [domainName, setDomainName] = useState<string>('');
  const [isValidDomain, setIsValidDomain] = useState<boolean>(true);
  const [deployedSite, setDeployedSite] = useState<string>('');

  const selectedOrganisationId = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation._id
  );

  const domainsLoading: boolean = useSelector(
    (state: RootState) => state.instance.domainsLoading
  );

  const domains = useSelector((state: RootState) => state.instance.domains);

  const activeDeploymentLoading = useSelector(
    (state: RootState) => state.instance.activeDeploymentLoading
  );

  const activeDeployment = useSelector(
    (state: RootState) => state.instance.activeDeployment
  );

  const selectedInstance = useSelector(
    (state: RootState) => state.instance.selectedInstance
  );

  const selectedInstanceLoading = useSelector(
    (state: RootState) => state.instance.selectedInstanceLoading
  );

  const createDomainLoading = useSelector(
    (state: RootState) => state.instance.createDomainLoading
  );
  const [multiservicePorts, setMultiservicePorts] = useState<Option[]>([]);

  useMemo(() => {
    let tempServicePorts: Option[] = [];
    activeDeployment?.services.forEach((service) => {
      const servicePorts: Option[] =
        service.ports
          ?.filter((port) => Number(port?.exposedPort) !== 80)
          .map((port) => ({
            label: `${activeDeployment?.protocolData?.providerHost}:${port?.exposedPort}`,
            value: `${activeDeployment?.protocolData?.providerHost}:${port?.exposedPort}`,
            optionType: 'primary' as OptionType,
          })) || [];
      tempServicePorts = [
        ...tempServicePorts,
        ...servicePorts,
        ...(service.urlPreview
          ? [
              {
                label: `${service?.urlPreview}`,
                value: `${service?.urlPreview}`,
                optionType: 'primary' as OptionType,
              },
            ]
          : []),
      ];
    });
    setMultiservicePorts(tempServicePorts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDeployment]);

  const editDropdownOptions: Option[] = [
    ...(selectedInstance?.latestUrlPreview?.length
      ? [
          ...(multiservicePorts || []),
          {
            label: selectedInstance?.latestUrlPreview,
            value: selectedInstance?.latestUrlPreview,
            optionType: 'primary' as OptionType,
          },
        ]
      : [
          ...(activeDeployment?.protocolData?.providerHost
            ? [
                {
                  label: `${activeDeployment?.protocolData?.providerHost}`,
                  value: `${activeDeployment?.protocolData?.providerHost}`,
                  optionType: 'primary' as OptionType,
                },
                ...(multiservicePorts || []),
              ]
            : multiservicePorts),
        ]),
  ];

  const domainDropdownOptions: Option[] = [
    {
      label: 'Select Instance',
      value: '',
      optionType: 'primary' as OptionType,
    },
    ...(activeDeployment?.status === InstanceStatus.DEPLOYED
      ? [...editDropdownOptions]
      : []),
  ];

  const addDomainDetails = () => {
    if (validateDomain(domainName)) {
      const payload: ICreateInstanceDomainPayloadDto = {
        name: domainName,
        link: deployedSite,
        type: ClusterInstanceDomain.domain,
        organizationId: selectedOrganisationId,
      };
      dispatchRtk(
        createDomainThunk({
          instanceId: selectedInstance?._id || '',
          body: payload,
        })
      );
      setDeployedSite('');
      setDomainName('');
      setIsValidDomain(true);
    } else setIsValidDomain(false);
  };
  const setTransaction = (tx: string) => {
    setDeployedSite(tx);
  };

  const buttonDisabled = !domainName || !deployedSite;

  return (
    <>
      <>
        <AddClusterDomains
          type={ClusterInstanceDomain.domain}
          domainName={domainName}
          setDomainName={setDomainName}
          isValidDomain={isValidDomain}
          dropdownOptions={domainDropdownOptions}
          dropdownLoading={selectedInstanceLoading}
          handleAddClick={addDomainDetails}
          buttonDisabled={buttonDisabled}
          buttonLoading={domainsLoading || createDomainLoading}
          deployedSite={deployedSite}
          setTransaction={setTransaction}
          isEditMode={false}
          setIsEditMode={() => {}}
        />
      </>
      <div className="mt-6">
        {!selectedInstanceLoading &&
          !activeDeploymentLoading &&
          !domainsLoading &&
          (domains?.domains?.length
            ? domains?.domains?.map((domain, index) => (
                <div>
                  <InstanceDomainCard
                    index={index}
                    type="filled"
                    domainId={`${domain._id}`}
                    domain={`${domain.name}`}
                    link={`${domain.link}`}
                    isSubdomain={false}
                    ownerVerified={domain.verified}
                    dropdownOptions={domainDropdownOptions}
                    domainType={domain.type}
                    isPublicAccessible={
                      isPublicAccessible && !communityUserAccess
                    }
                  />
                </div>
              ))
            : null)}
        {(selectedInstanceLoading ||
          domainsLoading ||
          activeDeploymentLoading) && (
          <InstanceDomainCard
            index={1}
            type="skeleton"
            domainId=""
            domain=""
            link=""
            isSubdomain={false}
            dropdownOptions={[]}
            ownerVerified
            domainType=""
            isPublicAccessible={false}
          />
        )}
      </div>
    </>
  );
};

export default InstanceDomain;
