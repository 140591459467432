/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import request, { gql } from 'graphql-request';
import { INetworkType, ITokenType } from './web3.interfaces';
import {
  networks,
  getNetworkConfig,
  getNetworknameForSetup,
} from './web3.utils';
import REGISTRY_FACTORY_ABI from '../../../assets/abis/registry-factory.json';
import DOMAIN_REGISTRY_ABI from '../../../assets/abis/domain-registry.json';
import config from '../../../config';
import { requestPipeline } from '../root-utils';

export const connectWalletService = async () => {
  try {
    const { ethereum } = window as any;

    if (!ethereum) {
      return {
        error: true,
        errorMessage: 'MetaMask not installed, please install!',
        account: '',
      };
    }
    const { Web3Provider } = await import('@ethersproject/providers');
    const provider = new Web3Provider(ethereum);
    await provider.send('eth_requestAccounts', []);
    const signer = provider.getSigner();
    const address = await signer.getAddress();

    return { error: false, errorMessage: '', account: address };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error in connectWalletService --> ', error);
    return { error: true, errorMessage: (error as Error).message, account: '' };
  }
};

export const changeNetworkService = async (chainId: number) => {
  try {
    const network = { ...networks[chainId] };
    delete network.logo;
    delete network.networkId;
    network.chainName = getNetworknameForSetup(chainId);
    try {
      await (window as any).ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: network.chainId }],
      });
    } catch (switchError: any) {
      if (switchError.code === 4902) {
        try {
          await (window as any).ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [network],
          });
          return { error: false, errorMessage: '' };
        } catch (addError) {
          // eslint-disable-next-line no-console
          console.log('Error in changeNetworkService --> ', addError);
          return { error: true, errorMessage: (addError as Error).message };
        }
      }
      // eslint-disable-next-line no-console
      console.log('Error in changeNetworkService --> ', switchError);
      return { error: true, errorMessage: (switchError as Error).message };
    }
    return { error: false, errorMessage: '' };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error in changeNetworkService --> ', error);
    return { error: true, errorMessage: (error as Error).message };
  }
};

export const getTokenBalanceService = async (
  walletAddress: string,
  selectedToken: { address: string; decimals: number },
  chainId: string
) => {
  try {
    const { Web3Provider } = await import('@ethersproject/providers');
    const paymentLib = await import('@spheron/desub-js');
    const provider = new Web3Provider((window as any).ethereum);

    const vendor: import('@spheron/desub-js').Vendor = new paymentLib.Vendor(
      provider,
      provider.getSigner(),
      getNetworkConfig(Number(chainId)).BICONOMY_KEY
    );
    const subscription: import('@spheron/desub-js').Subscription =
      new paymentLib.Subscription(vendor);

    subscription.subscriptionAt(
      getNetworkConfig(Number(chainId))
        ?.SUBSCRIPTION_CONTRACT_ADDRESS as string, // subscriptionPayment contact
      getNetworkConfig(Number(chainId))
        ?.SUBSCRIPTION_DATA_CONTRACT_ADDRESS as string, // subscriptionData contract
      selectedToken.address, // selected token address
      selectedToken.decimals // decimals
    );

    const balance = await subscription.getUserTokenBalance(walletAddress);

    return { error: false, errorMessage: '', balance };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error in getTokenBalanceService --> ', error);
    return { error: true, errorMessage: (error as Error).message, balance: 0 };
  }
};

export const getUserAvailableBalance = async (
  walletAddress: string,
  selectedToken: { address: string; decimals: number },
  chainId: string
) => {
  try {
    const { Web3Provider } = await import('@ethersproject/providers');
    const paymentLib = await import('@spheron/desub-js');
    const provider = new Web3Provider((window as any).ethereum);

    const vendor: import('@spheron/desub-js').Vendor = new paymentLib.Vendor(
      provider,
      provider.getSigner(),
      getNetworkConfig(Number(chainId)).BICONOMY_KEY
    );
    const subscription: import('@spheron/desub-js').Subscription =
      new paymentLib.Subscription(vendor);

    subscription.subscriptionAt(
      getNetworkConfig(Number(chainId))
        ?.SUBSCRIPTION_CONTRACT_ADDRESS as string, // subscriptionPayment contact
      getNetworkConfig(Number(chainId))
        ?.SUBSCRIPTION_DATA_CONTRACT_ADDRESS as string, // subscriptionData contract
      selectedToken.address, // selected token address
      selectedToken.decimals // decimals
    );

    const balance = await subscription.getUserBalance(walletAddress);

    return { error: false, errorMessage: '', balance };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error in getTokenBalanceService --> ', error);
    return { error: true, errorMessage: (error as Error).message, balance: 0 };
  }
};

export const depositTokenServices = async (
  amount: string,
  selectedToken: ITokenType,
  selectedNetwork: INetworkType
) => {
  try {
    const { Web3Provider } = await import('@ethersproject/providers');
    const paymentLib = await import('@spheron/desub-js');
    const provider = new Web3Provider((window as any).ethereum);
    const vendor: import('@spheron/desub-js').Vendor = new paymentLib.Vendor(
      provider,
      provider.getSigner(),
      getNetworkConfig(Number(selectedNetwork.chainId)).BICONOMY_KEY
    );

    const subscription: import('@spheron/desub-js').Subscription =
      new paymentLib.Subscription(vendor);

    await subscription.subscriptionAt(
      getNetworkConfig(Number(selectedNetwork.chainId))
        ?.SUBSCRIPTION_CONTRACT_ADDRESS as string, // subscriptionPayment contact
      getNetworkConfig(Number(selectedNetwork.chainId))
        ?.SUBSCRIPTION_DATA_CONTRACT_ADDRESS as string, // subscriptionData contract
      selectedToken.address, // argo token
      selectedToken.decimals // decimals
    );
    // Check if the selected token is filecoin
    const amountString = amount.toString();
    let tx;
    if (Number(selectedNetwork.chainId) === config.web3.filecoin.CHAIN_ID) {
      tx = await subscription.userDepositNative(amountString);
    } else {
      tx = await subscription.approveAndDeposit(amountString);
    }
    await tx.wait();
    return { error: false, errorMessage: '', txHash: tx.hash };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error in depositTokenServices --> ', error);
    return {
      error: true,
      errorMessage: (error as Error).message,
    };
  }
};

export const withdrawTokenServices = async (
  amount: string,
  selectedToken: ITokenType,
  selectedNetwork: INetworkType
) => {
  try {
    const { Web3Provider } = await import('@ethersproject/providers');
    const paymentLib = await import('@spheron/desub-js');
    const provider = new Web3Provider((window as any).ethereum);
    const vendor: import('@spheron/desub-js').Vendor = new paymentLib.Vendor(
      provider,
      provider.getSigner(),
      getNetworkConfig(Number(selectedNetwork.chainId)).BICONOMY_KEY
    );

    const subscription: import('@spheron/desub-js').Subscription =
      new paymentLib.Subscription(vendor);

    await subscription.subscriptionAt(
      getNetworkConfig(Number(selectedNetwork.chainId))
        ?.SUBSCRIPTION_CONTRACT_ADDRESS as string, // subscriptionPayment contact
      getNetworkConfig(Number(selectedNetwork.chainId))
        ?.SUBSCRIPTION_DATA_CONTRACT_ADDRESS as string, // subscriptionData contract
      selectedToken.address, // argo token
      selectedToken.decimals // decimals
    );

    const tx = await subscription.userWithdraw(amount.toString());
    await tx.wait();

    return { error: false, errorMessage: '', txHash: tx.hash };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error in withdrawTokenServices --> ', error);
    return {
      error: true,
      errorMessage: (error as Error).message,
    };
  }
};

export const getTokenAllowanceService = async (
  walletAddress: string,
  selectedToken: ITokenType,
  selectedNetwork: INetworkType
) => {
  try {
    const { Web3Provider } = await import('@ethersproject/providers');
    const paymentLib = await import('@spheron/desub-js');
    const provider = new Web3Provider((window as any).ethereum);
    const vendor: import('@spheron/desub-js').Vendor = new paymentLib.Vendor(
      provider,
      provider.getSigner(),
      getNetworkConfig(Number(selectedNetwork.chainId))?.BICONOMY_KEY
    );
    const subscription: import('@spheron/desub-js').Subscription =
      new paymentLib.Subscription(vendor);
    subscription.subscriptionAt(
      getNetworkConfig(Number(selectedNetwork.chainId))
        ?.SUBSCRIPTION_CONTRACT_ADDRESS as string, // subscriptionPayment contact
      getNetworkConfig(Number(selectedNetwork.chainId))
        ?.SUBSCRIPTION_DATA_CONTRACT_ADDRESS as string, // subscriptionData contract
      selectedToken.address, // argo token
      selectedToken.decimals // decimals
    );

    const allowance = await subscription.getApprovalAmount(walletAddress);
    return { error: false, errorMessage: '', allowance };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error in getTokenAllowanceService --> ', error);
    return {
      error: true,
      errorMessage: (error as Error).message,
    };
  }
};

export const signMessageService = async (
  message: string,
  selectedNetwork: INetworkType
) => {
  try {
    if (!(window as any).ethereum)
      throw new Error('No crypto wallet found. Please install it.');

    const { Web3Provider } = await import('@ethersproject/providers');
    const paymentLib = await import('@spheron/desub-js');
    const provider = new Web3Provider((window as any).ethereum);

    const vendor: import('@spheron/desub-js').Vendor = new paymentLib.Vendor(
      provider,
      provider.getSigner(),
      getNetworkConfig(Number(selectedNetwork!.chainId)).BICONOMY_KEY
    );

    const signature = await vendor.signMessage(message);
    return { error: false, errorMessage: '', signature };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error in signMessageService --> ', error);
    return { error: true, errorMessage: (error as Error).message };
  }
};

export const setTokenAllowanceService = async (
  amount: string,
  selectedToken: ITokenType,
  selectedNetwork: INetworkType
) => {
  try {
    const { Web3Provider } = await import('@ethersproject/providers');
    const paymentLib = await import('@spheron/desub-js');
    const provider = new Web3Provider((window as any).ethereum);
    const vendor: import('@spheron/desub-js').Vendor = new paymentLib.Vendor(
      provider,
      provider.getSigner(),
      getNetworkConfig(Number(selectedNetwork.chainId)).BICONOMY_KEY
    );

    const subscription: import('@spheron/desub-js').Subscription =
      new paymentLib.Subscription(vendor);

    await subscription.subscriptionAt(
      getNetworkConfig(Number(selectedNetwork.chainId))
        ?.SUBSCRIPTION_CONTRACT_ADDRESS as string, // subscriptionPayment contact
      getNetworkConfig(Number(selectedNetwork.chainId))
        ?.SUBSCRIPTION_DATA_CONTRACT_ADDRESS as string, // subscriptionData contract
      selectedToken.address, // argo token
      selectedToken.decimals // decimals
    );

    const tx = await subscription.setNewApprovals(amount.toString());
    await tx.wait();
    return { error: false, errorMessage: '', txHash: tx.hash };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error in setTokenAllowanceService --> ', error);
    return {
      error: true,
      errorMessage: (error as Error).message,
    };
  }
};

export const changeNetworkEthService = async () => {
  try {
    if ((window as any).ethereum.networkVersion !== '1') {
      await (window as any).ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x1' }],
      });
    }
    return { error: false, errorMessage: '' };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error in changeNetworkEthService --> ', error);
    return { error: true, errorMessage: (error as Error).message };
  }
};

export const updateEnsContentHash = async (
  domain: string,
  contentHash: string
) => {
  try {
    // eslint-disable-next-line import/no-unresolved
    // const { setupENS } = await import('@spheron/ens-ui');
    // const { ens } = await setupENS();
    // const tx = await ens.setContenthash(domain, contentHash);
    // await tx.wait();
    return { error: false, errorMessage: '', txHash: '' };
    // eslint-disable-next-line no-unreachable
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error in updateEnsContentHash --> ', error);
    return { error: true, errorMessage: (error as Error).message };
  }
};

export const getPaymentHistory = async (address: string, chainId: number) => {
  try {
    const query = gql`
      {
        user(id: "${address.toLowerCase()}") {
          balance {
            token,
            amount
          },
          deposit(orderBy: createdAt, orderDirection: desc) {
            id,
            amount,
            createdAt,
            token
          },
          withdraw(orderBy: createdAt, orderDirection: desc) {
            id,
            amount,
            createdAt,
            token
          }
        }
      }
    `;
    const data = await request(
      getNetworkConfig(chainId).DESUB_GRAPHQL_URL,
      query
    );
    return { error: false, errorMessage: '', data };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error in getPaymentHistory --> ', error);
    return {
      error: true,
      errorMessage: (error as Error).message,
    };
  }
};
export const createDomainRegistry = async (name: string) => {
  try {
    const { Web3Provider } = await import('@ethersproject/providers');
    const { Contract } = await import('@ethersproject/contracts');
    const provider = new Web3Provider((window as any).ethereum);
    const contract = new Contract(
      config.registry.CONTRACT_ADDRESS,
      REGISTRY_FACTORY_ABI,
      provider.getSigner()
    );
    const tx = await contract.createRegistry(name);
    const receipt = await tx.wait();
    // eslint-disable-next-line no-console
    console.log('createDomainRegistry -> ', receipt);
    return { error: false, errorMessage: '', tx: receipt };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error in createDomainRegistry --> ', error);
    return {
      error: true,
      errorMessage: (error as Error).message,
    };
  }
};

export const getBalanceService = async (
  address: string,
  token: string,
  chainId: number
) => {
  try {
    const query = gql`
      {
        balance(
          id: "${address.toLowerCase()}-${token.toLowerCase()}"
        ) {
          amount,
          token
        }
      }
    `;
    const data = await request(
      getNetworkConfig(chainId).DESUB_GRAPHQL_URL,
      query
    );
    return { error: false, errorMessage: '', data };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error in getBalanceService --> ', error);
    return {
      error: true,
      errorMessage: (error as Error).message,
    };
  }
};

export const addDomainToRegistry = async (
  registryAddress: string,
  domain: string,
  siteLink: string,
  contentHash: string
) => {
  try {
    const { Web3Provider } = await import('@ethersproject/providers');
    const { Contract } = await import('@ethersproject/contracts');
    const provider = new Web3Provider((window as any).ethereum);
    const contract = new Contract(
      registryAddress,
      DOMAIN_REGISTRY_ABI,
      provider.getSigner()
    );
    const tx = await contract.addUpdate(domain, contentHash, siteLink);
    const receipt = await tx.wait();
    // eslint-disable-next-line no-console
    console.log('addDomainToRegistry -> ', receipt);
    return { error: false, errorMessage: '', tx: receipt };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error in addDomainToRegistry --> ', error);
    return {
      error: true,
      errorMessage: (error as Error).message,
    };
  }
};

export const addUpdaterToRegistry = async (
  registryAddress: string,
  address: string,
  type: string
) => {
  try {
    const { Web3Provider } = await import('@ethersproject/providers');
    const { Contract } = await import('@ethersproject/contracts');
    const provider = new Web3Provider((window as any).ethereum);
    const contract = new Contract(
      registryAddress,
      DOMAIN_REGISTRY_ABI,
      provider.getSigner()
    );
    const tx = await (type === 'managers'
      ? contract.addDeployerRole(address)
      : contract.addAdmin(address));
    const receipt = await tx.wait();
    // eslint-disable-next-line no-console
    console.log('addAdminToRegistry -> ', receipt);
    return { error: false, errorMessage: '', tx: receipt };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error in addAdminToRegistry --> ', error);
    return {
      error: true,
      errorMessage: (error as any).data.message,
    };
  }
};

export const removeUpdaterFromRegistry = async (
  registryAddress: string,
  address: string,
  type: string
) => {
  try {
    const { Web3Provider } = await import('@ethersproject/providers');
    const { Contract } = await import('@ethersproject/contracts');
    const provider = new Web3Provider((window as any).ethereum);
    const contract = new Contract(
      registryAddress,
      DOMAIN_REGISTRY_ABI,
      provider.getSigner()
    );
    const tx = await (type === 'managers'
      ? contract.removeDeployerRole(address)
      : contract.removeAdmin(address));
    const receipt = await tx.wait();
    // eslint-disable-next-line no-console
    console.log('removeAdminFromRegistry -> ', receipt);
    return { error: false, errorMessage: '', tx: receipt };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error in removeAdminFromRegistry --> ', error);
    return {
      error: true,
      errorMessage: (error as any).data.message,
    };
  }
};

// infer better type
export const web3NonceService = async (): Promise<any> => {
  return requestPipeline({
    url: 'auth/web3/nonce',
    method: 'POST',
    isPublic: true,
  });
};
