import React, { useCallback, useMemo, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { InstanceDeploymentType, OptionType } from '@spheron/ui-library';
import Sticky from 'react-stickynode';
import { ReactComponent as DiscountIcon } from '@spheron/ui-library/dist/assets/discount.svg';
import { useDispatch, useSelector } from 'react-redux';
import OverviewStyle from '../../../../styles/overview.module.scss';
import SpheronResourceCard from '../../../../components/Compute/Cards/spheron-resource-card';
import { ComputeDashboard } from '../../../../redux/compute/combined-state.interface';
import { RootState, AppDispatch } from '../../../../redux/compute/store';
import { ICreateNewProjectResponseDto } from '../../../../redux/compute/project/project.interfaces';
import { toggleModalShowRtk } from '../../../../redux/compute/modal/modal.slice';
import {
  resetInstanceDeployLogsRtk,
  resetInstanceState,
} from '../../../../redux/compute/instance/instance.slice';
import { resetInstanceValues } from '../../../../redux/compute/instance/instance-creation/instance-creation.slice';
import SideMarketingCard from '../../../../components/Compute/Cards/side-marketing-card';
import ReferralTextInput from '../../../../components/Compute/InputFields/referral-text-input';
import { MARKETING_DATA, SPHERON_RESOURCES } from '../../../../config';
import InstanceDashboard from '../InstanceDashboard';
import ProjectDashboard from '../ProjectDashboard';
import InstanceDashboardBreadcrumbs from '../../../../components/Compute/Misc/instance-dashboard-breadcrumbs';
import ProjectDashboardBreadcrumbs from '../../../../components/Compute/Misc/project-dashboard-breadcrumbs';

const Dashboard = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();

  const [selectedInstances, setSelectedInstances] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [dropdownOption, setDropdownOption] = useState<string>('all');
  const { projectId, orgUsername } = useParams<{
    projectId: string;
    orgUsername: string;
  }>();

  const projectsState = useSelector(
    (state: RootState) => state.computeProject.projects
  );
  const instancesState = useSelector(
    (state: RootState) => state.computeProject.selectedProjectInstances
  );
  const instancesLoading = useSelector(
    (state: RootState) => state.computeProject.selectedProjectInstancesLoading
  );
  const projectsLoading = useSelector(
    (state: RootState) => state.computeProject.projectsLoading
  );
  const selectedOrganisationLoading = useSelector(
    (state: RootState) => state.organisation.selectedOrganisationLoading
  );

  const referralCode = useSelector(
    (state: RootState) => state.user.referralCode
  );
  const referralCodeLoading = useSelector(
    (state: RootState) => state.user.referralCodeLoading
  );

  const handleNewProjectClick = useCallback(() => {
    dispatch(
      toggleModalShowRtk({
        modalShow: true,
        modalType: 'createNewComputeProject',
      })
    );
  }, [dispatch]);

  const handleNewInstanceClick = useCallback(() => {
    dispatch(resetInstanceDeployLogsRtk());
    dispatch(resetInstanceValues());
    dispatch(resetInstanceState());
    navigate(`/${orgUsername}/${projectId}/new-instance`);
  }, [dispatch, navigate, orgUsername, projectId]);

  const getComputeDashboardType = useMemo(() => {
    if (!projectId) return ComputeDashboard.PROJECT;
    return ComputeDashboard.INSTANCE;
  }, [projectId]);

  const configureResource = useCallback(
    (dashboard: ComputeDashboard) => {
      switch (dashboard) {
        case ComputeDashboard.PROJECT:
        default:
          return {
            loading: projectsLoading,
            resource: projectsState,
          };
        case ComputeDashboard.INSTANCE:
          return {
            loading: instancesLoading,
            resource: instancesState,
          };
      }
    },
    [projectsLoading, projectsState, instancesLoading, instancesState]
  );

  const showSpheronResource = JSON.parse(SPHERON_RESOURCES).show;

  const spheronResource = [...JSON.parse(SPHERON_RESOURCES || '')?.data].map(
    (content) => {
      return {
        ...content,
        icon: (
          <>
            <img
              src={content?.iconPath}
              alt={content?.heading}
              className="h-8 w-8 rounded"
            />
          </>
        ),
        onClick: () => window.open(content.link),
      };
    }
  );

  const spheronResourceSection = {
    id: 2,
    title: 'Spheron Resource',
    content: spheronResource,
  };

  const { resource, loading } = configureResource(getComputeDashboardType);

  const handleSearch = (searchValue: string): void => {
    if (searchValue) {
      setSearchValue(searchValue);
      return;
    }
    setSearchValue('');
  };

  const instanceFilterOptions = useMemo(() => {
    return [
      {
        id: 1,
        optionType: 'primary' as OptionType,
        label: 'All',
        value: 'all',
      },
      {
        id: 2,
        optionType: 'active' as OptionType,
        label: 'Active',
        value: 'active',
      },
      {
        id: 3,
        optionType: 'closed' as OptionType,
        label: 'Closed',
        value: 'closed',
      },
      {
        id: 4,
        optionType: 'starting' as OptionType,
        label: 'Starting',
        value: 'starting',
      },
    ];
  }, []);

  const marketingData = JSON.parse(MARKETING_DATA || '');

  const videoCardDetails = {
    show: marketingData.show,
    title: marketingData?.data?.title || '',
    description: marketingData?.data?.description || '',
    button: marketingData?.data?.buttonText || '',
    iframe: marketingData?.data?.iframe || '',
    img: marketingData?.data?.img || '',
    onClick: () => {
      if (projectsState.length === 0) {
        dispatch(
          toggleModalShowRtk({
            modalShow: true,
            modalType: 'createNewComputeProject',
            options: {
              followUpFn: (res: { payload: ICreateNewProjectResponseDto }) => {
                navigate(
                  `/${orgUsername}/${
                    (res.payload as ICreateNewProjectResponseDto)._id
                  }/new-instance?type=redeploy&provider=template&template=${
                    marketingData?.data?.template || ''
                  }&deploymentType=${
                    marketingData?.data?.isGpu
                      ? InstanceDeploymentType.ACCELERATE
                      : InstanceDeploymentType.COMPUTE
                  }`
                );
              },
            },
          })
        );
      } else {
        navigate(
          `/${orgUsername}/${
            projectsState[0]._id
          }/new-instance?type=redeploy&provider=template&template=${
            marketingData?.data?.template || ''
          }&deploymentType=${
            marketingData?.data?.isGpu
              ? InstanceDeploymentType.ACCELERATE
              : InstanceDeploymentType.COMPUTE
          }`
        );
      }
    },
  };

  const finalContent = [spheronResourceSection];

  const isLoading = loading || selectedOrganisationLoading;

  return (
    <div
      className={`${OverviewStyle.overview__outer__con} flex flex-col
       justify-start !min-h-screen gap-y-2.5 mb-8 overflow-y-scroll !pt-0`}
    >
      {projectId ? (
        <InstanceDashboardBreadcrumbs
          searchValue={searchValue}
          selectedInstances={selectedInstances}
          dropdownOption={dropdownOption}
          instanceFilterOptions={instanceFilterOptions}
          setDropdownOption={setDropdownOption}
          handleSearch={handleSearch}
          handleCreateNewInstance={handleNewInstanceClick}
        />
      ) : (
        <ProjectDashboardBreadcrumbs
          searchValue={searchValue}
          handleSearch={handleSearch}
          handleCreateNewProject={handleNewProjectClick}
        />
      )}
      <div className="mx-auto w-[95%] justify-between max-w-[1400px]">
        <div className="flex flex-col">
          <div className="grid grid-cols-12 gap-x-5 overflow-y-auto">
            <div
              className={isLoading || projectId ? 'col-span-12' : 'col-span-9'}
            >
              <div className="w-full pb-16">
                {projectId ? (
                  <InstanceDashboard
                    searchValue={searchValue}
                    setSelectedInstances={setSelectedInstances}
                    selectedInstances={selectedInstances}
                    dropdownOption={dropdownOption}
                    handleNewInstanceClick={handleNewInstanceClick}
                  />
                ) : (
                  <ProjectDashboard
                    searchValue={searchValue}
                    setSelectedInstance={setSelectedInstances}
                    handleNewProjectClick={handleNewProjectClick}
                  />
                )}
              </div>
            </div>
            {!selectedOrganisationLoading && !loading && !projectId && (
              <Sticky top={120} className="col-span-3">
                <div
                  className={`flex-col flex gap-y-4 min-w-[320px] ${
                    resource.length === 0 ? 'mt-10' : 'mt-0'
                  }`}
                >
                  {videoCardDetails.show && (
                    <SideMarketingCard
                      title={videoCardDetails.title}
                      description={videoCardDetails.description}
                      buttonText={videoCardDetails.button}
                      onClick={videoCardDetails.onClick}
                      image={videoCardDetails.img}
                      iframe={
                        videoCardDetails.iframe ? (
                          <>
                            <iframe
                              width="711"
                              height="400"
                              className="rounded-lg"
                              src={videoCardDetails.iframe}
                              title="YouTube video player"
                              allow="accelerometer; autoplay; clipboard-write;
                           encrypted-media; gyroscope; picture-in-picture; web-share"
                              referrerPolicy="strict-origin-when-cross-origin"
                              allowFullScreen
                            />
                          </>
                        ) : null
                      }
                    />
                  )}

                  <div className="bg-feedback-info-bg dark:bg-dark-feedback-info-bg p-5 rounded-lg">
                    <span
                      className="text-sm text-base-heading-text-color dark:text-dark-base-heading-text-color 
              font-semibold flex items-center mb-4"
                    >
                      <DiscountIcon className="mr-2 text-base-icon dark:text-dark-base-icon w-6 h-6" />
                      Refer a Dev, Earn up to $1000
                    </span>
                    <ReferralTextInput
                      title={`${window.location.origin}/signup?referral=${referralCode}`}
                      type="text"
                      value={`${window.location.origin}/signup?referral=${referralCode}`}
                      onChange={() => {}}
                      initialValue={referralCode}
                      showCopy
                      editable={false}
                      editLoading={referralCodeLoading}
                      maxLength={100}
                    />
                  </div>
                  {showSpheronResource && (
                    <div className="items-start justify-between gap-x-20">
                      {finalContent.map((section) => (
                        <div key={section.id}>
                          {section.content.length > 0 && (
                            <h5
                              className="text-base font-semibold text-base-para-text-color
            dark:text-dark-base-para-text-color"
                            >
                              {section.title}
                            </h5>
                          )}
                          <div className="mt-3 flex flex-col items-start gap-y-2 min-w-80">
                            {section.content.map((content) => (
                              <SpheronResourceCard
                                key={content.id}
                                onClick={content.onClick}
                                heading={content.heading}
                                subHeading={content.subHeading}
                                icon={content.icon}
                              />
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </Sticky>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
