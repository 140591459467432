import React from 'react';
import { ConfirmationModal, Modal } from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import Styles from '../../../styles/compute/modal.module.scss';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import { removeActiveBonusThunk } from '../../../redux/compute/subscription/subscription.thunks';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import ModalWrapper from './ModalWrapper';

interface IProps {
  isOpen: boolean;
  bonusId: string;
  bonusName: string;
}

const RemoveActiveBonus = ({ isOpen, bonusId, bonusName }: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();

  const subscriptionUpdating = useSelector(
    (state: RootState) => state.subscription.subscriptionUpdating
  );
  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={() => {
        dispatchRtk(toggleModalShowRtk({ modalShow: false }));
      }}
      classname={`${Styles.modal__outer__con} modal fixed inset-0 overflow-y-auto`}
    >
      <div className="w-500">
        <Modal
          header
          headerContent={<>Remove {bonusName}</>}
          footer
          modalContent={
            <div className="flex flex-col gap-y-3">
              <p className="text-sm font-normal leading-5 mb-3 text-center">
                Do you really want to remove <b>{bonusName}</b> from your
                organization?
              </p>
            </div>
          }
          onPrimaryButtonClick={() =>
            dispatchRtk(removeActiveBonusThunk(bonusId))
          }
          primaryButton
          secondaryButton
          onSecondaryButtonClick={() =>
            dispatchRtk(toggleModalShowRtk({ modalShow: false }))
          }
          primaryButtonContent="Remove"
          primaryButtonLoading={subscriptionUpdating}
          secondaryButtonContent="Cancel"
          subtitle={''}
          confirmationStatus={ConfirmationModal.ERROR}
        />
      </div>
    </ModalWrapper>
  );
};

export default RemoveActiveBonus;
