import React, { useEffect, useState } from 'react';
import { Button, Checkbox, SectionHeading } from '@spheron/ui-library';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import {
  NotificationCategory,
  NotificationCategoryList,
  notificationCategoryList,
} from './email-notification-category';
import { AppDispatch, RootState } from '../../../../redux/compute/store';
import {
  fetchOrganisationNotificationConfigThunk,
  updateOrganisationNotificationConfigThunk,
} from '../../../../redux/compute/organisation/organisation.thunks';
// eslint-disable-next-line max-len
import { setOrgNotificationConfigCategoryLoading } from '../../../../redux/compute/organisation/organisation.slice';
import { withLoader } from '../../../../redux/compute/root-utils';

const OrganisationSettingsEmailPreferences = () => {
  const isPublicAccessible =
    sessionStorage.getItem('isPublicAccessible') === 'true';
  const communityUserAccess =
    sessionStorage.getItem('communityUserAccess') === 'true';

  const dispatchRtk = useDispatch<AppDispatch>();

  const selectedOrganisationId = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation._id
  );

  const orgNotificationConfigCategory = useSelector(
    (state: RootState) => state.organisation.orgNotificationConfigCategory
  );

  const orgNotificationConfigCategoryLoading = useSelector(
    (state: RootState) =>
      state.organisation.orgNotificationConfigCategoryLoading
  );

  const updateOrgNotificationConfigCategoryLoading = useSelector(
    (state: RootState) =>
      state.organisation.updateOrgNotificationConfigCategoryLoading
  );

  const [newNotificationConfigCategory, setNewNotificationConfigCategory] =
    useState<Record<string, boolean>>({});

  const [categoryCheck, setCategoryCheck] = useState<boolean[]>(
    Array(notificationCategoryList.length).fill(false)
  );

  useEffect(() => {
    setNewNotificationConfigCategory(orgNotificationConfigCategory);
  }, [orgNotificationConfigCategory]);

  useEffect(() => {
    notificationCategoryList.forEach((list) => {
      let temp = true;
      list.list.forEach((value) => {
        if (!newNotificationConfigCategory[value.key]) {
          temp = false;
        }
      });

      setCategoryCheck((prevArray) => {
        const newArray = [...prevArray];
        newArray[list.key] = temp;
        return newArray;
      });
    });
  }, [newNotificationConfigCategory]);

  useEffect(() => {
    dispatchRtk(setOrgNotificationConfigCategoryLoading(true));
    if (selectedOrganisationId)
      dispatchRtk(
        fetchOrganisationNotificationConfigThunk(selectedOrganisationId)
      );
  }, [dispatchRtk, selectedOrganisationId]);

  const handleCheckboxChange = (config: NotificationCategory) => {
    if (newNotificationConfigCategory[config]) {
      setNewNotificationConfigCategory({
        ...newNotificationConfigCategory,
        ...{
          [config]: false,
        },
      });
    } else {
      setNewNotificationConfigCategory({
        ...newNotificationConfigCategory,
        ...{
          [config]: true,
        },
      });
    }
  };

  const handleCategoryCheckboxChange = (
    config: NotificationCategoryList,
    key: number
  ) => {
    const valuesToUpdate = notificationCategoryList.filter(
      (category) => category.label === config
    )[0].list;
    const updatedRecord = { ...newNotificationConfigCategory };
    valuesToUpdate.forEach((value) => {
      updatedRecord[value.key] = !categoryCheck[key];
    });
    setNewNotificationConfigCategory(updatedRecord);

    setCategoryCheck((prevArray) => {
      const newArray = [...prevArray];
      newArray[key] = !newArray[key];
      return newArray;
    });
  };

  const handleSaveClick = () => {
    const notificationConfigArray = Object.keys(
      newNotificationConfigCategory
    ).filter((key) => {
      return newNotificationConfigCategory[key];
    });
    dispatchRtk(
      updateOrganisationNotificationConfigThunk({
        organisationId: selectedOrganisationId,
        newNotificationCategories: notificationConfigArray,
      })
    );
  };

  const saveDisabled = () => {
    const notificationConfigArray = Object.keys(
      newNotificationConfigCategory
    ).filter((key) => {
      return newNotificationConfigCategory[key];
    });
    if (
      notificationConfigArray.length !==
      Object.keys(orgNotificationConfigCategory).length
    ) {
      return false;
    }
    if (
      JSON.stringify(notificationConfigArray.slice().sort()) !==
      JSON.stringify(Object.keys(orgNotificationConfigCategory).slice().sort())
    ) {
      return false;
    }

    return true;
  };

  return (
    <>
      <div className="flex flex-row justify-between w-full items-center">
        <div className="w-8/12">
          <SectionHeading
            showRefresh={false}
            showSwitch={false}
            subHeading="Adjust your organization's email preferences to receive updates and notifications 
          that matter most to you and your team. Customize the type of emails to enhance your Spheron experience."
            heading="Tailor Your Communication Experience"
            handleIconClick={() => {}}
            handleRefreshClick={() => {}}
            handleSwitchClick={() => {}}
            switchDisable={false}
            handleClick={() => {}}
            loading={false}
            time={''}
            refreshType={'default'}
            showText={false}
            toggleId={''}
            isChecked={false}
            handleChange={() => {}}
          />
        </div>
        <Button
          buttonType="primary"
          label="Save Preferences"
          size="small"
          onClick={() => handleSaveClick()}
          loading={updateOrgNotificationConfigCategoryLoading}
          disabled={
            (isPublicAccessible && !communityUserAccess) || saveDisabled()
          }
        />
      </div>
      {withLoader(
        orgNotificationConfigCategoryLoading,
        <div className="font-semibold text-lg mt-10">
          <Skeleton height={40} width={120} duration={2} />
          <div className="grid grid-cols-3 gap-y-10 mt-8">
            {Array.from(Array(9).keys()).map((item) => (
              <Skeleton key={item} height={30} width="80%" duration={2} />
            ))}
          </div>
        </div>,
        <>
          {notificationCategoryList.map((category) => (
            <div key={category.key} className="font-semibold text-lg mt-10">
              <div className="flex flex-row gap-3 items-center">
                <Checkbox
                  id={String(category.key)}
                  checked={categoryCheck[category.key]}
                  onClick={() =>
                    handleCategoryCheckboxChange(
                      category.label as NotificationCategoryList,
                      category.key
                    )
                  }
                />
                {category.label}
              </div>

              <div className="grid grid-cols-5 gap-y-10 mt-8 ml-10">
                {category.list.map((notification) => (
                  <div key={notification.key} className="w-max capitalize">
                    <div className="flex flex-row gap-1">
                      <Checkbox
                        id={notification.key}
                        checked={
                          newNotificationConfigCategory[notification.key]
                        }
                        label={notification.value}
                        onClick={() => handleCheckboxChange(notification.key)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default OrganisationSettingsEmailPreferences;
