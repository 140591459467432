import * as Sentry from '@sentry/react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  addWalletService,
  removeWalletService,
} from '../organisation/organisation.services';
import { IResponseError } from '../combined-reducer.interface';
import { addNotificationRtk } from '../notification/notification.slice';
import { NotificationType } from '../notification/notification.interfaces';
import { IWallet } from '../combined-state.interface';
import { IStripeState } from './stripe.interfaces';
import {
  IOrgWallet,
  IRemoveWalletResponsePayloadDto,
} from '../organisation/organisation.interfaces';
import { IWeb3State } from '../web3/web3.interfaces';
import { toggleModalShowRtk } from '../modal/modal.slice';
// eslint-disable-next-line import/no-cycle
import { updateDefaultPaymentMethodRtk } from '../organisation/organisation.slice';
import { updateCreditCardPrimaryPayment } from './stripe.slice';
import { updateCryptoWalletPrimaryPaymentRtk } from '../web3/web3.slice';

export const addCreditCardThunk = createAsyncThunk(
  'stripe/addCard',
  async (payload: any, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await addWalletService(payload);
      if ((response as IResponseError).error) {
        dispatch(
          addNotificationRtk({
            message: (response as IResponseError).message,
            type: NotificationType.Error,
            timestamp: Date.now(),
          })
        );
        return rejectWithValue({});
      }
      localStorage.setItem(
        'client-secret',
        (response as IWallet).connectionSecret || ''
      );
      localStorage.setItem('redirectUrl', window.location.href);
      dispatch(
        toggleModalShowRtk({ modalShow: true, modalType: 'stripePayment' })
      );
      return fulfillWithValue({});
    } catch (error) {
      dispatch(
        addNotificationRtk({
          message: (error as Error).message,
          type: NotificationType.Error,
          timestamp: Date.now(),
        })
      );
      return rejectWithValue({});
    }
  }
);

export const removeCreditCardThunk = createAsyncThunk(
  'stripe/removeCreditCard',
  async (
    payload: {
      id: string;
      organizationId: string;
    },
    { rejectWithValue, fulfillWithValue, dispatch, getState }
  ) => {
    const { stripe } = getState() as { stripe: IStripeState };
    const { web3 } = getState() as { web3: IWeb3State };

    try {
      const response = await removeWalletService(payload);
      if ((response as IResponseError).error) {
        dispatch(
          addNotificationRtk({
            message: (response as IResponseError).message,
            type: NotificationType.Error,
            timestamp: Date.now(),
          })
        );
        return rejectWithValue({});
      }
      const primaryCreditCard = stripe.creditCards.find(
        (creditCard) =>
          creditCard._id ===
          (response as IRemoveWalletResponsePayloadDto)?.primaryWallet
      );

      const cryptoWallet = web3.wallets.find(
        (wallet) =>
          wallet._id ===
          (response as IRemoveWalletResponsePayloadDto)?.primaryWallet
      );

      dispatch(
        updateDefaultPaymentMethodRtk(
          (primaryCreditCard || cryptoWallet) as unknown as IOrgWallet
        )
      );
      if (cryptoWallet) {
        dispatch(updateCryptoWalletPrimaryPaymentRtk({ id: cryptoWallet._id }));
      }
      if (primaryCreditCard) {
        dispatch(updateCreditCardPrimaryPayment({ id: primaryCreditCard._id }));
      }
      dispatch(toggleModalShowRtk({ modalShow: false }));
      //   yield put(setPrimaryPaymentMethodId(response.primaryWallet));
      return fulfillWithValue({ id: payload.id });
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);
