import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { ReactComponent as CopyIcon } from '@spheron/ui-library/dist/assets/copy.svg';
import { ReactComponent as StarIcon } from '@spheron/ui-library/dist/assets/star.svg';
import { Badge, Tooltip } from '@spheron/ui-library';
import { useSelector } from 'react-redux';
import { ISpheronCredits } from '../../../redux/compute/organisation/organisation.interfaces';
import SpheronCreditProgressBar from '../ProgressBar/spheron-credit-progress-bar';
import { RootState } from '../../../redux/compute/store';

interface IProps {
  credit: ISpheronCredits;
}

const ActiveSpheronCreditCard = ({ credit }: IProps) => {
  const [couponCopied, setCouponCopied] = useState(false);

  const copyCouponCode = (value: string) => {
    navigator.clipboard.writeText(value);
    setCouponCopied(true);
    setTimeout(() => {
      setCouponCopied(false);
    }, 3000);
  };

  const selectedOrganisationLoading = useSelector(
    (state: RootState) => state.organisation.selectedOrganisationLoading
  );

  const spheronCreditsLoading = useSelector(
    (state: RootState) => state.organisation.spheronCreditsLoading
  );

  const creditsLoading = selectedOrganisationLoading || spheronCreditsLoading;

  const { activatedOn, activationCode, totalAmount, remainingAmount } = credit;

  return (
    <div
      className="w-full h-max rounded-xl bg-feedback-default-bg dark:bg-dark-feedback-default-bg
    border border-base-border dark:border-dark-base-border px-6 py-4
    flex md:flex-row flex-col gap-6 justify-between"
    >
      <div className="w-full flex flex-wrap gap-4 h-min justify-between items-center">
        <div>
          <div className="flex flex-row gap-6">
            <span
              className="text-base-heading-text-color dark:text-dark-base-heading-text-color
            text-5 leading-6 font-500"
            >
              Credit Usage
            </span>
            {!creditsLoading && (
              <Badge
                badgeType="success"
                solid={false}
                size="large"
                text={activationCode}
                leftIcon={
                  <StarIcon className="text-feedback-success-text dark:text-dark-feedback-success-text w-5 h-5" />
                }
                rightIcon={
                  <Tooltip
                    position="top"
                    text={
                      couponCopied ? 'Coupon Code Copied!' : 'Copy Coupon Code'
                    }
                    type="arrow"
                    wrapText
                  >
                    <CopyIcon
                      className="text-feedback-success-text dark:text-dark-feedback-success-text
                        w-5 h-5 cursor-pointer"
                      onClick={() => copyCouponCode(activationCode)}
                    />
                  </Tooltip>
                }
              />
            )}
          </div>

          {!creditsLoading ? (
            <span className="flex items-center mt-1">
              <span
                className="text-base-para-text-color dark:text-dark-base-para-text-color
               text-[14px] font-400 leading-5"
              >
                Activated on:{' '}
                {new Date(activatedOn).toLocaleDateString('en-us', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })}
              </span>
            </span>
          ) : (
            <Skeleton height={20} width="100%" duration={2} />
          )}
        </div>
      </div>
      <SpheronCreditProgressBar
        used={totalAmount - remainingAmount}
        total={totalAmount}
      />
    </div>
  );
};

export default ActiveSpheronCreditCard;
