import { ConfirmationModal, Modal, TextInput } from '@spheron/ui-library';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Styles from '../../../styles/compute/modal.module.scss';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import { editInstanceNameThunk } from '../../../redux/compute/instance/instance.thunks';
import ModalWrapper from './ModalWrapper';
import { AppDispatch } from '../../../redux/compute/store';

interface IProps {
  isOpen: boolean;
  loading: boolean;
  resource: string;
  id: string;
}

export const EditName = ({ isOpen, loading, resource, id }: IProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const handleCloseModal = () => {
    dispatch(toggleModalShowRtk({ modalShow: false }));
  };
  const [name, setName] = useState<string>('');

  const handleProceed = () => {
    dispatch(editInstanceNameThunk({ name, instanceId: id })).then(() => {
      dispatch(toggleModalShowRtk({ modalShow: false }));
    });
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={handleCloseModal}
      classname={`${Styles.modal__outer__con} modal fixed inset-0 overflow-y-auto`}
    >
      <div className="min-w-500">
        <Modal
          header
          headerContent={<>Change name of the {resource}</>}
          footer
          modalContent={
            <TextInput
              label="Enter Name"
              placeholder="Enter a cool name"
              value={name}
              onChange={(e) => setName(e as string)}
            />
          }
          onPrimaryButtonClick={handleProceed}
          primaryButton
          secondaryButton
          onSecondaryButtonClick={handleCloseModal}
          primaryButtonContent="Proceed"
          primaryButtonDisabled={name?.length === 0}
          primaryButtonLoading={loading}
          secondaryButtonContent="Cancel"
          subtitle={''}
          confirmationStatus={ConfirmationModal.ERROR}
        />
      </div>
    </ModalWrapper>
  );
};
