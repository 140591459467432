import React, { useEffect, Dispatch, SetStateAction } from 'react';
import { Button, Dropdown, OptionType, TextInput } from '@spheron/ui-library';
import { ClusterInstanceDomain } from '../../../redux/compute/combined-state.interface';
import { ReactComponent as GlobeIcon } from '../../../assets/icons/globe-icon.svg';

interface IProps {
  type: ClusterInstanceDomain;
  domainName: string;
  setDomainName: Dispatch<SetStateAction<string>>;
  isValidDomain: boolean;
  dropdownOptions: { label: string; value: string; optionType: OptionType }[];
  dropdownLoading: boolean;
  handleAddClick: () => void;
  buttonDisabled: boolean;
  buttonLoading: boolean;
  deployedSite: string;
  // eslint-disable-next-line no-unused-vars
  setTransaction: (selected: string) => void;
  isEditMode: boolean;
  // eslint-disable-next-line no-unused-vars
  setIsEditMode: (mode: boolean) => void;
}

const AddClusterInstanceDomain = ({
  // eslint-disable-next-line no-unused-vars
  type,
  domainName,
  setDomainName,
  isValidDomain,
  dropdownOptions,
  dropdownLoading,
  handleAddClick,
  buttonDisabled,
  buttonLoading,
  deployedSite,
  setTransaction,
  isEditMode,
  setIsEditMode,
}: IProps) => {
  const isPublicAccessible =
    sessionStorage.getItem('isPublicAccessible') === 'true';
  const communityUserAccess =
    sessionStorage.getItem('communityUserAccess') === 'true';

  useEffect(() => {
    setTransaction(deployedSite);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>
        <div
          className={`grid grid-cols-1 gap-x-4 gap-y-2 mt-3
        dark:bg-transparent ${
          isEditMode ? 'lg:grid-cols-10 px-5' : 'lg:grid-cols-11 '
        }`}
        >
          <div className="form-group col-span-5 w-full">
            <div className="flex items-center w-full mt-2">
              <TextInput
                label="Select Site"
                placeholder="Enter URL"
                value={domainName}
                onChange={(value) => setDomainName(value as string)}
                error={!isValidDomain}
                className="w-full"
                disabled={
                  isEditMode || (isPublicAccessible && !communityUserAccess)
                }
                maxLength={64}
                leftIcon={<GlobeIcon className="w-5 h-5" />}
              />
            </div>
          </div>
          <div className="form-group col-span-5">
            <div className="relative dark:bg-transparent w-full">
              <Dropdown
                dropdownSize="default"
                dropdownType="default"
                filled
                label={`${type.toUpperCase()} POINTS TO`}
                onSelected={(selected) =>
                  setTransaction(selected.value as string)
                }
                defaultSelected={deployedSite}
                options={dropdownOptions}
                loading={dropdownLoading}
                disable={isPublicAccessible && !communityUserAccess}
                dropdownSectionClassName="!mt-1"
              />
            </div>
          </div>

          <div
            className={`mt-6 flex items-center gap-3 ${
              isEditMode ? 'col-span-2' : 'col-span-1'
            }`}
          >
            <Button
              buttonType="primary"
              label={isEditMode ? 'Update' : 'Add'}
              size="medium"
              onClick={handleAddClick}
              disabled={
                buttonDisabled || (isPublicAccessible && !communityUserAccess)
              }
              loading={buttonLoading}
            />
            {isEditMode && (
              <>
                <Button
                  buttonType="ghost"
                  label="Cancel"
                  size="medium"
                  onClick={() => setIsEditMode(false)}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddClusterInstanceDomain;
