/* eslint-disable import/no-cycle */
import * as Sentry from '@sentry/react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IResponseError } from '../combined-reducer.interface';
import { toggleModalShowRtk } from '../modal/modal.slice';
import { addNotificationRtk } from '../notification/notification.slice';
import { NotificationType } from '../notification/notification.interfaces';
import {
  confirmDomain,
  postDomain,
  removeDomain,
  reviseDomain,
  updateProjectEnv,
  getSelectedProject,
  getAllProjects,
  updateBuildConfigService,
  createDeploymentEnvService,
  updateDeploymentEnvService,
  deleteDeploymentEnvService,
  activateDeploymentEnvService,
  deactivateDeploymentEnvService,
  addProjectEnv,
  removeProjectEnv,
  fetchCdnRecords,
  getTotalMaintainedProjectsService,
  updateProjectStatusService,
  deleteProjectService,
  saveProjectSecurity,
  removeProjectSecurity,
} from './project.services';
import { getPaginationDetails } from './project.utils';
import {
  IAddEnvPayloadDto,
  IAddUpdateDeleteProjectDeploymentEnvDtoPayload,
  IDomainDtoPayload,
  IProjectCountResponseDto,
  IProjectRemoveSecurityPayload,
  IProjectSaveSecurityPayload,
  IProjectStatusDeploymentEnvDtoPayload,
  IRemoveEnvPayloadDto,
  IUpdateBuildConfigDto,
  IUpdateEnvPayloadDto,
  IUpdateProjectStatePayloadDto,
  ProjectType,
} from './project.interfaces';
import {
  toggleEnvVariableLoading,
  cdnRecordsLoaded,
  toggleDeleteProjectLoading,
  deleteProjectEnd,
  toggleDomainLoading,
  toggleDeleteDomainLoading,
  toggleEditDomainLoading,
  toggleVerifyDomainLoading,
  setSelectedProject,
} from './project.slice';
import {
  fetchAllDeploymentsDetailsThunk,
  fetchAllDeploymentsThunk,
  fetchSuccessfulSitePreviewsThunk,
} from '../deployment/deployment.thunks';

export const fetchAllProjectsThunk = createAsyncThunk(
  'project/fetchAllProjects',
  async (
    payload: { id: string; type: ProjectType },
    { fulfillWithValue, rejectWithValue, getState }
  ) => {
    const { project } = getState() as {
      project: any;
    };
    try {
      const limit = getPaginationDetails(
        payload.type,
        project.maintainedProjectsPagination,
        project.archivedProjectsPagination
      ).numbersPerPage;
      const skip = 0;
      const projectsRes: any = await getAllProjects(
        payload.id,
        skip,
        limit,
        payload.type
      );
      if (!projectsRes.error) {
        return fulfillWithValue({
          projects: projectsRes.projects,
          type: payload.type,
        });
      }
      return fulfillWithValue({
        projects: [],
        type: payload.type,
      });
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const loadMoreProjectsThunk = createAsyncThunk(
  'project/loadMoreProjects',
  async (
    payload: ProjectType,
    { getState, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const { organisation } = getState() as {
        organisation: { selectedOrganisation?: { _id: string } };
      };
      const organisationId = organisation.selectedOrganisation?._id;
      if (!organisationId) {
        throw new Error('Org ID is undefined');
      }
      const { project } = getState() as {
        project: any;
      };
      const limit = getPaginationDetails(
        payload,
        project.maintainedProjectsPagination,
        project.archivedProjectsPagination
      ).numbersPerPage;
      const skip =
        limit *
        (1 +
          getPaginationDetails(
            payload,
            project.archivedProjectsPagination,
            project.maintainedProjectsPagination
          ).currentPage);
      const projects: any = await getAllProjects(
        organisationId,
        skip,
        limit,
        payload
      );
      return fulfillWithValue({ projects: projects.projects, type: payload });
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const loadCdnRecordsThunk = createAsyncThunk(
  'project/loadCdnRecords',
  async (
    payload: any,
    { dispatch, getState, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const { project } = getState() as {
        project: { selectedProject?: { _id: string } };
      };
      const projectId = project.selectedProject?._id;
      if (!projectId) {
        throw new Error('Project ID is undefined');
      }
      const response = await fetchCdnRecords(projectId);
      if (!response.error) {
        dispatch(cdnRecordsLoaded(response.records));
        return fulfillWithValue({});
      }
      dispatch(
        addNotificationRtk({
          message: (response as IResponseError).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const fetchSelectedProjectThunk = createAsyncThunk(
  'project/fetchSelectedProjects',
  async (payload: string, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      dispatch(fetchSuccessfulSitePreviewsThunk(payload));
      dispatch(fetchAllDeploymentsThunk(payload));
      dispatch(fetchAllDeploymentsDetailsThunk(payload));
      const project = await getSelectedProject(payload);
      dispatch(setSelectedProject(project));
      dispatch(loadCdnRecordsThunk({}));
      return fulfillWithValue(project);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const fetchTotalProjectsThunk = createAsyncThunk(
  'project/fetchTotalProjects',
  async (
    payload: IProjectCountResponseDto,
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await getTotalMaintainedProjectsService(
        payload.id,
        payload.type
      );
      if ('count' in response) {
        return fulfillWithValue({ count: response.count, type: payload.type });
      }
      return rejectWithValue({ count: 0, type: payload.type });
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({ count: 0, type: payload.type });
    }
  }
);

export const addDomainThunk = createAsyncThunk(
  'project/addDomain',
  async (
    payload: Partial<IDomainDtoPayload>,
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      const response = await postDomain(payload);
      if ((response as IResponseError).error) {
        dispatch(
          addNotificationRtk({
            message: response.message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        dispatch(toggleDomainLoading(false));
        return rejectWithValue({});
      }
      dispatch(fetchSelectedProjectThunk(payload.projectId as string));
      dispatch(toggleDomainLoading(false));
      return fulfillWithValue({});
    } catch (error) {
      dispatch(toggleDomainLoading(false));
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const deleteDomainThunk = createAsyncThunk(
  'project/deleteDomain',
  async (
    payload: Partial<IDomainDtoPayload>,
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(
        toggleDeleteDomainLoading({
          domainId: payload.domainId as string,
          loading: true,
        })
      );
      const response = await removeDomain(payload);
      if ((response as IResponseError).error) {
        dispatch(
          addNotificationRtk({
            message: (response as IResponseError).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        dispatch(
          toggleDeleteDomainLoading({
            domainId: payload.domainId as string,
            loading: false,
          })
        );
        return rejectWithValue({});
      }
      dispatch(fetchSelectedProjectThunk(payload.projectId as string));
      dispatch(
        toggleDeleteDomainLoading({
          domainId: payload.domainId as string,
          loading: false,
        })
      );
      return fulfillWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      dispatch(
        toggleDeleteDomainLoading({
          domainId: payload.domainId as string,
          loading: false,
        })
      );
      return rejectWithValue({});
    }
  }
);

export const editDomainThunk = createAsyncThunk(
  'project/editDomain',
  async (
    payload: Partial<IDomainDtoPayload>,
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(
        toggleEditDomainLoading({
          domainId: payload.domainId as string,
          loading: true,
        })
      );
      const response = await reviseDomain(payload);
      if ((response as IResponseError).error) {
        dispatch(
          addNotificationRtk({
            message: response.message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        dispatch(
          toggleEditDomainLoading({
            domainId: payload.domainId as string,
            loading: false,
          })
        );
        return rejectWithValue({});
      }
      dispatch(fetchSelectedProjectThunk(payload.projectId as string));
      dispatch(
        toggleEditDomainLoading({
          domainId: payload.domainId as string,
          loading: false,
        })
      );
      return fulfillWithValue({});
    } catch (error) {
      dispatch(
        toggleEditDomainLoading({
          domainId: payload.domainId as string,
          loading: false,
        })
      );
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const verifyDomainThunk = createAsyncThunk(
  'project/verifyDomain',
  async (
    payload: Partial<IDomainDtoPayload>,
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(
        toggleVerifyDomainLoading({
          domainId: payload.domainId as string,
          loading: true,
        })
      );
      const response = await confirmDomain(payload.domainId as string, {
        ...payload,
      });
      if ((response as IResponseError).error) {
        dispatch(
          addNotificationRtk({
            message: response.message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        dispatch(
          toggleVerifyDomainLoading({
            domainId: payload.domainId as string,
            loading: false,
          })
        );
        return rejectWithValue({});
      }
      dispatch(fetchSelectedProjectThunk(payload.projectId as string));
      dispatch(
        toggleVerifyDomainLoading({
          domainId: payload.domainId as string,
          loading: false,
        })
      );
      return fulfillWithValue({});
    } catch (error) {
      dispatch(
        toggleVerifyDomainLoading({
          domainId: payload.domainId as string,
          loading: false,
        })
      );
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const addEnvThunk = createAsyncThunk(
  'project/addEnv',
  async (
    payload: IAddEnvPayloadDto,
    { dispatch, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await addProjectEnv(payload);
      if ('error' in response && response.error) {
        dispatch(
          addNotificationRtk({
            message: (response as IResponseError).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
      } else {
        dispatch(fetchSelectedProjectThunk(payload.projectId));
        return fulfillWithValue({});
      }
      return rejectWithValue({});
    } catch (error) {
      dispatch(toggleModalShowRtk({ modalShow: false }));
      Sentry.captureException(error);
      return rejectWithValue({});
    } finally {
      dispatch(toggleModalShowRtk({ modalShow: false }));
      dispatch(toggleEnvVariableLoading('-1'));
    }
  }
);

export const updateEnvThunk = createAsyncThunk(
  'project/updateEnv',
  async (
    payload: IUpdateEnvPayloadDto,
    { dispatch, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await updateProjectEnv(payload);
      if ('error' in response && response.error) {
        dispatch(
          addNotificationRtk({
            message: (response as IResponseError).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
      } else {
        dispatch(fetchSelectedProjectThunk(payload.updateEnvBody.projectId));
        return fulfillWithValue({});
      }
      dispatch(toggleEnvVariableLoading('-1'));
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    } finally {
      dispatch(toggleModalShowRtk({ modalShow: false }));
      dispatch(toggleEnvVariableLoading('-1'));
    }
  }
);

export const removeEnvThunk = createAsyncThunk(
  'project/removeEnv',
  async (
    payload: IRemoveEnvPayloadDto,
    { dispatch, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await removeProjectEnv(payload);
      dispatch(toggleModalShowRtk({ modalShow: false }));
      if (response.error) {
        dispatch(
          addNotificationRtk({
            message: (response as IResponseError).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
      } else {
        dispatch(fetchSelectedProjectThunk(payload.projectId));
        return fulfillWithValue({});
      }
      return rejectWithValue({});
    } catch (error) {
      dispatch(toggleModalShowRtk({ modalShow: false }));
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const updateBuildConfigThunk = createAsyncThunk(
  'project/updateBuildConfig',
  async (
    payload: IUpdateBuildConfigDto,
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      const response: any = await updateBuildConfigService(
        payload.projectId,
        payload.configuration
      );
      if (!response.error) {
        dispatch(fetchSelectedProjectThunk(payload.projectId));
        return fulfillWithValue({});
      }
      dispatch(
        addNotificationRtk({
          message: (response as IResponseError).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const createDeploymentEnvThunk = createAsyncThunk(
  'project/createDeploymentEnv',
  async (
    payload: Partial<IAddUpdateDeleteProjectDeploymentEnvDtoPayload>,
    { dispatch, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response: any = await createDeploymentEnvService(payload);
      if (!response.error) {
        dispatch(fetchSelectedProjectThunk(payload.projectId as string));
        dispatch(toggleModalShowRtk({ modalShow: false }));
        return fulfillWithValue({});
      }
      dispatch(
        addNotificationRtk({
          message: (response as IResponseError).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      dispatch(toggleModalShowRtk({ modalShow: false }));
      return rejectWithValue({});
    } catch (error) {
      dispatch(toggleModalShowRtk({ modalShow: false }));
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const updateDeploymentEnvThunk = createAsyncThunk(
  'project/updateDeploymentEnv',
  async (
    payload: IAddUpdateDeleteProjectDeploymentEnvDtoPayload,
    { dispatch, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response: any = await updateDeploymentEnvService(payload);
      if (!response.error) {
        dispatch(fetchSelectedProjectThunk(payload.projectId));
        dispatch(toggleModalShowRtk({ modalShow: false }));
        return fulfillWithValue({});
      }
      dispatch(
        addNotificationRtk({
          message: (response as IResponseError).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      dispatch(toggleModalShowRtk({ modalShow: false }));
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const deleteDeploymentEnvThunk = createAsyncThunk(
  'project/deleteDeploymentEnv',
  async (
    payload: Partial<IAddUpdateDeleteProjectDeploymentEnvDtoPayload>,
    { dispatch, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await deleteDeploymentEnvService(payload);
      if (!response.error) {
        dispatch(toggleModalShowRtk({ modalShow: false }));
        dispatch(fetchSelectedProjectThunk(payload.projectId as string));
        return fulfillWithValue({});
      }
      dispatch(
        addNotificationRtk({
          message: (response as IResponseError).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      dispatch(toggleModalShowRtk({ modalShow: false }));
      return rejectWithValue({});
    } catch (error) {
      dispatch(toggleModalShowRtk({ modalShow: false }));
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const activateDeploymentEnvThunk = createAsyncThunk(
  'project/activateDeploymentEnv',
  async (
    payload: IProjectStatusDeploymentEnvDtoPayload,
    { dispatch, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response: any = await activateDeploymentEnvService(payload);
      if (!response.error) {
        dispatch(fetchSelectedProjectThunk(payload.projectId as string));
        return fulfillWithValue({});
      }
      dispatch(
        addNotificationRtk({
          message: (response as IResponseError).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const deactivateDeploymentEnvThunk = createAsyncThunk(
  'project/deactivateDeploymentEnv',
  async (
    payload: IProjectStatusDeploymentEnvDtoPayload,
    { dispatch, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response: any = await deactivateDeploymentEnvService(payload);
      if (!response.error) {
        dispatch(fetchSelectedProjectThunk(payload.projectId));
        dispatch(toggleModalShowRtk({ modalShow: false }));
        return fulfillWithValue({});
      }
      dispatch(
        addNotificationRtk({
          message: (response as IResponseError).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      dispatch(toggleModalShowRtk({ modalShow: false }));
      return rejectWithValue({});
    } catch (error) {
      dispatch(toggleModalShowRtk({ modalShow: false }));
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const projectArchiveThunk = createAsyncThunk(
  'project/projectArchive',
  async (
    payload: IUpdateProjectStatePayloadDto,
    { dispatch, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await updateProjectStatusService(
        payload.body as { id: string },
        'archived'
      );
      if (!response.error) {
        dispatch(fetchSelectedProjectThunk(payload.body.id as string));
        dispatch(
          toggleModalShowRtk({
            modalShow: true,
            modalType: 'archivePrompt',
            options: {
              projectName: payload.projectName,
              type: 'archive',
            },
          })
        );
        return fulfillWithValue({});
      }
      dispatch(
        addNotificationRtk({
          message: (response as IResponseError).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const projectMaintainThunk = createAsyncThunk(
  'project/projectMaintain',
  async (
    payload: IUpdateProjectStatePayloadDto,
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      const response = await updateProjectStatusService(
        payload.body as { id: string },
        'maintained'
      );
      if (!response.error) {
        dispatch(fetchSelectedProjectThunk(payload.body.id as string));
        dispatch(
          toggleModalShowRtk({
            modalShow: true,
            modalType: 'archivePrompt',
            options: {
              projectName: payload.projectName,
              type: 'maintain',
            },
          })
        );
        return fulfillWithValue({});
      }
      dispatch(
        addNotificationRtk({
          message: response.message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const deleteProjectThunk = createAsyncThunk(
  'project/deleteProject',
  async (payload: string, { fulfillWithValue, rejectWithValue, dispatch }) => {
    dispatch(toggleDeleteProjectLoading(true));
    try {
      const response = await deleteProjectService(payload);
      if (response?.error) {
        dispatch(
          addNotificationRtk({
            message: (response as IResponseError).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
      } else {
        dispatch(deleteProjectEnd(payload));
        dispatch(
          toggleModalShowRtk({
            modalShow: false,
          })
        );
        dispatch(
          toggleModalShowRtk({
            modalShow: true,
            modalType: 'deletedResource',
            options: {
              resource: 'Project',
            },
          })
        );
        window.open(`${window.location.origin}/app/dashboard`, '_self');
        return fulfillWithValue({});
      }
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const projectSecurityThunk = createAsyncThunk(
  'project/projectSecurity',
  async (
    payload: IProjectSaveSecurityPayload,
    { dispatch, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await saveProjectSecurity(payload);
      if (!response.error) {
        dispatch(fetchSelectedProjectThunk(payload.projectId as string));
        return fulfillWithValue({});
      }
      dispatch(toggleModalShowRtk({ modalShow: false }));
      dispatch(
        addNotificationRtk({
          message: response.message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      dispatch(toggleModalShowRtk({ modalShow: false }));
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const projectSecurityRemoveThunk = createAsyncThunk(
  'project/projectSecurityRemove',
  async (
    payload: IProjectRemoveSecurityPayload,
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      const response = await removeProjectSecurity(payload);
      if (!response.error) {
        dispatch(fetchSelectedProjectThunk(payload.projectId as string));
        return fulfillWithValue({});
      }
      dispatch(toggleModalShowRtk({ modalShow: false }));
      dispatch(
        addNotificationRtk({
          message: response.message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      dispatch(toggleModalShowRtk({ modalShow: false }));
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);
