import { SectionHeading } from '@spheron/ui-library';
import React from 'react';
import ShellCommand from '../../../../components/Compute/Cards/shell-command';

const ServiceShell = () => {
  return (
    <div className="flex flex-col gap-y-7">
      <SectionHeading
        showRefresh={false}
        showSwitch={false}
        subHeading=""
        heading="Shell Command"
        handleIconClick={() => {}}
        handleRefreshClick={() => {}}
        handleSwitchClick={() => {}}
        switchDisable={false}
        handleClick={() => {}}
        loading={false}
        time={''}
        refreshType={'default'}
        showText={false}
        toggleId={''}
        isChecked={false}
        handleChange={() => {}}
      />
      <ShellCommand />
    </div>
  );
};

export default ServiceShell;
