import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Dropdown, IOptions, OptionType } from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Check } from '../../../../../assets/compute/icons/checkbox-template-card.svg';
import Styles from '../../../../../styles/compute/compute-pricing.module.scss';
import { AppDispatch, RootState } from '../../../../../redux/compute/store';
import {
  updateMultiServiceCustomStorageInputPrice,
  updateMultiServiceCustomHddStorageInputPrice,
  updateMultiServiceCustomSsdStorageInputPrice,
  updateMultiServiceCustomNvmStorageInputPrice,
  updateMultiServiceCustomStorageInput,
  updateMultiServiceCustomHddStorage,
  updateMultiServiceCustomSsdStorage,
  updateMultiServiceCustomNvmStorage,
  updateMultiServiceCustomStorage,
  updateMultiServiceCustomHddStorageInput,
  updateMultiServiceCustomSsdStorageInput,
  updateMultiServiceCustomNvmStorageInput,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.slice';
import {
  LoadingType,
  PersistentStorageOption,
  ScalingType,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.interfaces';
import { sliceStringFromEnd } from '../../../../../redux/compute/subscription/subscription.utils';
import { useDebounce } from '../../../../../hooks/useDebounce';
import { ICustomTemplateSpecs } from '../../../../../redux/compute/cluster/cluster.interfaces';
import {
  getPersistentStorageName,
  isAccelerateDeployment,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.utils';
// eslint-disable-next-line max-len
import { multiserviceCalculatePriceThunk } from '../../../../../redux/compute/instance/instance-creation/instance-creation.thunks';

// custom hook for getting previous prop/state
export const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

// eslint-disable-next-line no-unused-vars
export enum CustomStorageOption {
  // eslint-disable-next-line no-unused-vars
  CUSTOMSTORAGE = 'customStorage',
  // eslint-disable-next-line no-unused-vars
  CUSTOMPERSISTENTSTORAGE = 'customPersistentStorage',
  // eslint-disable-next-line no-unused-vars
  CUSTOMHDDSTORAGE = 'HDD',
  // eslint-disable-next-line no-unused-vars
  CUSTOMSSDSTORAGE = 'SSD',
  // eslint-disable-next-line no-unused-vars
  CUSTOMNVMSTORAGE = 'NVMe',
}

interface IProps {
  serviceId: string;
  value: CustomStorageOption;
  isSelected: boolean;
  onSelect: () => void;
  isUpdate: boolean;
}

const MultiServiceCustomStorage = ({
  serviceId,
  isUpdate,
  value,
  isSelected,
  onSelect,
}: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();
  const selectedInstance = useSelector(
    (state: RootState) => state.instance.selectedInstance
  );
  const activeDeployment = useSelector(
    (state: RootState) => state.instance.activeDeployment
  );
  const {
    customStorageInput,
    customHddStorageInput,
    customSsdStorageInput,
    customNvmStorageInput,
    customPersistentStorage,
    updatedStoragePrice,
    updatedPersistentStoragePrice,
  } = useSelector(
    (state: RootState) =>
      state.instanceCreation.multiserviceInstanceCreation![serviceId]
  );
  const selectedTemplate = useSelector(
    (state: RootState) => state.cluster.selectedTemplate
  );
  const scalingType = useSelector(
    (state: RootState) => state.instanceCreation.clusterScaling
  );
  const deploymentType = useSelector(
    (state: RootState) => state.instanceCreation.deploymentType
  );
  const { persistentStorage, storage: recommendedStorage } =
    (selectedTemplate?.services.find((service) => service._id === serviceId)
      ?.customTemplateSpecs as ICustomTemplateSpecs) || {};

  const hasPersistentStorage = Object.keys(persistentStorage || {}).length > 0;
  const prevUpdatePersistentStorage = usePrevious(customPersistentStorage);
  const updatedCustomPersistentStoragePriceHourly = (
    updatedPersistentStoragePrice / 24
  ).toFixed(4);
  const updatedCustomPersistentStoragePriceMonthly = (
    updatedPersistentStoragePrice * 30
  ).toFixed(2);
  let selectedStorage = customStorageInput;
  let selectedAvgCost: string | number | null = '--';
  const updatedStoragePriceHourly: string = updatedStoragePrice
    ? (updatedStoragePrice / 24).toFixed(4)
    : '0';
  const updatedStoragePriceMonthly: string = updatedStoragePrice
    ? (updatedStoragePrice * 30).toFixed(2)
    : '0';
  let storagePriceupdated: string;
  let updatedCustomPersistentStoragePrice: string;
  let costUnit: string;
  if (isAccelerateDeployment(deploymentType)) {
    storagePriceupdated = updatedStoragePriceHourly;
    updatedCustomPersistentStoragePrice =
      updatedCustomPersistentStoragePriceHourly;
    costUnit = 'hour';
  } else {
    storagePriceupdated = updatedStoragePriceMonthly;
    updatedCustomPersistentStoragePrice =
      updatedCustomPersistentStoragePriceMonthly;
    costUnit = 'month';
  }
  if (value === CustomStorageOption.CUSTOMSTORAGE) {
    selectedStorage = customStorageInput;
    selectedAvgCost = storagePriceupdated;
  } else if (value === CustomStorageOption.CUSTOMPERSISTENTSTORAGE) {
    if (customPersistentStorage === CustomStorageOption.CUSTOMHDDSTORAGE) {
      selectedStorage = customHddStorageInput;
      selectedAvgCost = updatedCustomPersistentStoragePrice;
    } else if (
      customPersistentStorage === CustomStorageOption.CUSTOMSSDSTORAGE
    ) {
      selectedStorage = customSsdStorageInput;
      selectedAvgCost = updatedCustomPersistentStoragePrice;
    } else if (
      customPersistentStorage === CustomStorageOption.CUSTOMNVMSTORAGE
    ) {
      selectedStorage = customNvmStorageInput;
      selectedAvgCost = updatedCustomPersistentStoragePrice;
    }
  }

  const [inputValue, setInputValue] = useState<number | ''>(selectedStorage);
  const avgCostPerMonth = isSelected ? `${selectedAvgCost}` : '--';
  const [showError, setShowError] = useState<boolean>(false);

  const storageUnitOptions = [
    {
      id: 1,
      optionType: 'primary' as OptionType,
      label: 'GB',
      value: '1',
    },
    {
      id: 2,
      optionType: 'primary' as OptionType,
      label: 'TB',
      value: '1000',
    },
  ];

  const [selectedStorageUnit, setSelectedStorageUnit] = useState<IOptions>(
    storageUnitOptions[0]
  );

  useEffect(() => {
    if (isSelected && selectedStorage !== inputValue) {
      if (
        value === CustomStorageOption.CUSTOMSTORAGE &&
        selectedStorage &&
        updatedStoragePrice
      ) {
        dispatchRtk(
          updateMultiServiceCustomStorageInputPrice({
            serviceId,
            value: `$${(updatedStoragePrice / 24).toFixed(2)}`,
          })
        );
      } else if (
        value === CustomStorageOption.CUSTOMPERSISTENTSTORAGE &&
        selectedStorage &&
        updatedPersistentStoragePrice &&
        prevUpdatePersistentStorage === customPersistentStorage
      ) {
        if (customPersistentStorage === CustomStorageOption.CUSTOMHDDSTORAGE) {
          dispatchRtk(
            updateMultiServiceCustomHddStorageInputPrice({
              serviceId,
              value: `$${updatedCustomPersistentStoragePrice}`,
            })
          );
        } else if (
          customPersistentStorage === CustomStorageOption.CUSTOMSSDSTORAGE
        ) {
          dispatchRtk(
            updateMultiServiceCustomSsdStorageInputPrice({
              serviceId,
              value: `$${updatedCustomPersistentStoragePrice}`,
            })
          );
        } else if (
          customPersistentStorage === CustomStorageOption.CUSTOMNVMSTORAGE
        ) {
          dispatchRtk(
            updateMultiServiceCustomNvmStorageInputPrice({
              serviceId,
              value: `$${updatedCustomPersistentStoragePrice}`,
            })
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedStorage,
    inputValue,
    isSelected,
    dispatchRtk,
    value,
    updatedStoragePrice,
    updatedPersistentStoragePrice,
    customPersistentStorage,
    prevUpdatePersistentStorage,
  ]);

  useEffect(() => {
    if (
      value === CustomStorageOption.CUSTOMPERSISTENTSTORAGE &&
      selectedStorage &&
      prevUpdatePersistentStorage !== customPersistentStorage
    ) {
      setInputValue(selectedStorage);
    }
  }, [
    selectedStorage,
    customPersistentStorage,
    prevUpdatePersistentStorage,
    value,
  ]);

  const setValueForUpdate = useCallback(() => {
    let persistentStorageData;
    let storageData;
    if (isUpdate && activeDeployment) {
      const foundService = activeDeployment.services.find(
        (service) => service.template?._id === serviceId
      );
      persistentStorageData =
        foundService?.agreedMachineImage?.persistentStorage;
      storageData = foundService?.agreedMachineImage.storage;
    } else if (
      (persistentStorage && hasPersistentStorage) ||
      recommendedStorage
    ) {
      persistentStorageData = persistentStorage;
      storageData = recommendedStorage;
    }
    if (value === CustomStorageOption.CUSTOMSTORAGE) {
      const customInputStorage = sliceStringFromEnd(storageData || '', 2);
      dispatchRtk(
        multiserviceCalculatePriceThunk({
          serviceId,
          loadingType: LoadingType.STORAGE,
        })
      ).then(() => {
        dispatchRtk(
          updateMultiServiceCustomStorageInput({
            serviceId,
            value: Number(customInputStorage),
          })
        );
      });
      return Number(customInputStorage);
    }
    if (
      value === CustomStorageOption.CUSTOMPERSISTENTSTORAGE &&
      persistentStorageData
    ) {
      const customPersistentStorage = Number(
        sliceStringFromEnd(persistentStorageData.size || '', 2)
      );
      switch (getPersistentStorageName(persistentStorageData.class || '')) {
        case PersistentStorageOption.HDD:
          dispatchRtk(
            updateMultiServiceCustomHddStorage({
              serviceId,
              value: customPersistentStorage,
            })
          );
          break;
        case PersistentStorageOption.SSD:
          dispatchRtk(
            updateMultiServiceCustomSsdStorage({
              serviceId,
              value: customPersistentStorage,
            })
          );
          break;
        case PersistentStorageOption.NVM:
          dispatchRtk(
            updateMultiServiceCustomNvmStorage({
              serviceId,
              value: customPersistentStorage,
            })
          );
          break;
        default:
          break;
      }
      return Number(sliceStringFromEnd(persistentStorageData?.size || '', 2));
    }
    return '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatchRtk,
    selectedInstance,
    selectedTemplate?.serviceData.customTemplateSpecs,
    hasPersistentStorage,
  ]);

  useEffect(() => {
    if (
      (isUpdate && selectedInstance) ||
      (persistentStorage &&
        hasPersistentStorage &&
        value === CustomStorageOption.CUSTOMPERSISTENTSTORAGE) ||
      (recommendedStorage && value === CustomStorageOption.CUSTOMSTORAGE)
    ) {
      setInputValue(setValueForUpdate());
      dispatchRtk(
        multiserviceCalculatePriceThunk({
          serviceId,
          loadingType: LoadingType.STORAGE,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isUpdate,
    selectedInstance,
    selectedTemplate?.serviceData.customTemplateSpecs,
    hasPersistentStorage,
  ]);
  const debouncedSearchTerm = useDebounce(inputValue.toString(), 200);
  const handleInputChange = (targetValue: number) => {
    onSelect();
    const updateValue = targetValue;
    setInputValue(updateValue);
  };
  useEffect(() => {
    if (debouncedSearchTerm && isSelected) {
      const updateValue =
        Number(selectedStorageUnit.value) * Number(debouncedSearchTerm);
      if (value === CustomStorageOption.CUSTOMSTORAGE) {
        dispatchRtk(
          updateMultiServiceCustomStorage({
            serviceId,
            value: updateValue,
          })
        );
        dispatchRtk(
          multiserviceCalculatePriceThunk({
            serviceId,
            loadingType: LoadingType.STORAGE,
          })
        ).then(() => {
          dispatchRtk(
            updateMultiServiceCustomStorageInput({
              serviceId,
              value: updateValue,
            })
          );
        });
      } else if (value === CustomStorageOption.CUSTOMPERSISTENTSTORAGE) {
        if (customPersistentStorage === CustomStorageOption.CUSTOMHDDSTORAGE) {
          dispatchRtk(
            updateMultiServiceCustomHddStorage({
              serviceId,
              value: updateValue,
            })
          );
        } else if (
          customPersistentStorage === CustomStorageOption.CUSTOMSSDSTORAGE
        ) {
          dispatchRtk(
            updateMultiServiceCustomSsdStorage({
              serviceId,
              value: updateValue,
            })
          );
        } else if (
          customPersistentStorage === CustomStorageOption.CUSTOMNVMSTORAGE
        ) {
          dispatchRtk(
            updateMultiServiceCustomNvmStorage({
              serviceId,
              value: updateValue,
            })
          );
        }
        dispatchRtk(
          multiserviceCalculatePriceThunk({
            serviceId,
            loadingType: LoadingType.PERSISTENT_STORAGE,
          })
        ).then(() => {
          if (
            customPersistentStorage === CustomStorageOption.CUSTOMHDDSTORAGE
          ) {
            dispatchRtk(
              updateMultiServiceCustomHddStorageInput({
                serviceId,
                value: updateValue,
              })
            );
          } else if (
            customPersistentStorage === CustomStorageOption.CUSTOMSSDSTORAGE
          ) {
            dispatchRtk(
              updateMultiServiceCustomSsdStorageInput({
                serviceId,
                value: updateValue,
              })
            );
          } else if (
            customPersistentStorage === CustomStorageOption.CUSTOMNVMSTORAGE
          ) {
            dispatchRtk(
              updateMultiServiceCustomNvmStorageInput({
                serviceId,
                value: updateValue,
              })
            );
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedSearchTerm,
    dispatchRtk,
    value,
    isSelected,
    selectedStorageUnit.value,
  ]);
  const handleClick = () => {
    if (selectedStorage < 0.1 || Number.isNaN(selectedStorage)) {
      setShowError(true);
      if (value === CustomStorageOption.CUSTOMSTORAGE) {
        handleInputChange(20);
        setShowError(false);
      } else if (value === CustomStorageOption.CUSTOMPERSISTENTSTORAGE) {
        handleInputChange(4);
        setShowError(false);
      }
    }
    if (selectedStorage >= 0.1 && !isSelected) {
      if (value === CustomStorageOption.CUSTOMSTORAGE) {
        dispatchRtk(
          updateMultiServiceCustomStorage({
            serviceId,
            value: selectedStorage,
          })
        );
        dispatchRtk(
          multiserviceCalculatePriceThunk({
            serviceId,
            loadingType: LoadingType.STORAGE,
          })
        );
      } else if (value === CustomStorageOption.CUSTOMPERSISTENTSTORAGE) {
        if (customPersistentStorage === CustomStorageOption.CUSTOMHDDSTORAGE) {
          dispatchRtk(
            updateMultiServiceCustomHddStorage({
              serviceId,
              value: selectedStorage,
            })
          );
        } else if (
          customPersistentStorage === CustomStorageOption.CUSTOMSSDSTORAGE
        ) {
          dispatchRtk(
            updateMultiServiceCustomSsdStorage({
              serviceId,
              value: selectedStorage,
            })
          );
        } else if (
          customPersistentStorage === CustomStorageOption.CUSTOMNVMSTORAGE
        ) {
          dispatchRtk(
            updateMultiServiceCustomNvmStorage({
              serviceId,
              value: selectedStorage,
            })
          );
        }
        dispatchRtk(
          multiserviceCalculatePriceThunk({
            serviceId,
            loadingType: LoadingType.PERSISTENT_STORAGE,
          })
        );
      }
      onSelect();
    }
  };

  return (
    <>
      <button
        type="button"
        tabIndex={0}
        className={`border relative px-4 py-4 rounded-lg min-h-12 
      cursor-pointer dark:bg-dark-base-bg 
      border-base-border dark:border-dark-base-border
      hover:border-border-form-selected
      dark:hover:border-dark-form-selected ease-in duration-75
       ${
         isSelected
           ? 'border-border-form-selected dark:border-dark-form-selected'
           : 'border-base-border dark:border-dark-base-border'
       }`}
        onClick={handleClick}
      >
        <span
          className="text-base-heading-text-color dark:text-dark-base-heading-text-color
          text-base font-semibold text-left flex justify-start"
        >
          Custom Storage
        </span>
        <div className="flex items-center justify-between ll:gap-x-6 mt-2">
          <Dropdown
            dropdownSize="compact"
            classname="w-[30%]"
            dropdownType="default"
            label=""
            placeholder="Storage Unit"
            filled
            onSelected={(value) => {
              setSelectedStorageUnit(value);
            }}
            subText=""
            defaultSelected={selectedStorageUnit?.value as string}
            options={storageUnitOptions}
            disable={!isSelected}
          />
          <input
            className={`flex justify-start bg-white border border-searchBorder 
            shadow-searchShadow pl-4 pr-2 h-10 w-[40%]
            rounded-lg text-text-filterBadge text-base font-normal 
            dark:bg-dark-base-bg max-w-[50%]
            dark:border-dark-base-border
            ${Styles.custom__input} ${showError ? '!border-red-400' : ''}`}
            placeholder="e.g 40 GB"
            value={`${selectedAvgCost && isSelected ? inputValue : 0}`}
            onChange={(e) => handleInputChange(Number(e.target.value))}
            min={0}
            type="number"
            onBlur={() => {
              if (selectedStorage < 0.1 || Number.isNaN(selectedStorage)) {
                if (value === CustomStorageOption.CUSTOMSTORAGE) {
                  handleInputChange(20);
                } else if (
                  value === CustomStorageOption.CUSTOMPERSISTENTSTORAGE
                ) {
                  handleInputChange(4);
                }
              }
            }}
          />
          <div className="w-[30%] flex flex-col text-left">
            <span className="text-text-instanceLightGray text-tiny ll:text-sm font-normal">
              {scalingType === ScalingType.MANUAL ? '' : 'Avg '}Cost/{costUnit}
            </span>
            <span className="text-text-filterBadge text-sm font-bold">
              ${avgCostPerMonth}
            </span>
          </div>
        </div>
        <>
          {isSelected && (
            <span className="absolute right-2 top-2">
              <Check className={Styles.check__icon} />
            </span>
          )}
        </>
      </button>
    </>
  );
};

export default MultiServiceCustomStorage;
