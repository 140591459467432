import { IResponseError } from '../combined-reducer.interface';
import { requestPipeline } from '../root-utils';
import {
  ICreateInstanceDomainPayloadDto,
  IInstanceDomainIdPayloadDto,
  IInstanceDomains,
  IUpdateHealthCheck,
  IFetchInstanceServiceResponse,
  IFetchInstanceServiceDto,
  IClusterTemplateConfig,
  IRequestForUpdatingInstance,
  IShellResponse,
  IAutoscalingRequestPayload,
  IInstanceMetricsCharResponsePayloadDto,
  ICloseMultipleInstanceResponseDto,
  IRequestForRestartInstance,
  IGetRestartResponsePayloadDto,
} from './instance.interfaces';

// TODO: USE DTOs here for the types of the payloads for each service

export const createClusterInstanceService = async (
  instance: any
): Promise<any> => {
  return requestPipeline({
    url: `cluster-instance/create`,
    method: 'POST',
    body: JSON.stringify(instance),
  });
};

export const getDeploymentDetailsService = async (
  deploymentId: string
): Promise<any | IResponseError> => {
  return requestPipeline({
    url: `compute-instance/deployment/${deploymentId}`,
    method: 'GET',
    isPublic: true,
  });
};

export const getDeploymentLogsService = async (
  deploymentId: string,
  type: string,
  currentPage: number,
  search: string,
  serviceName?: string
): Promise<any> => {
  return requestPipeline({
    url: `compute-instance/deployment/${deploymentId}/logs?from=${
      currentPage * -20
    }&to=20&logType=${type}&search=${search}&serviceName=${serviceName || ''}`,
    method: 'GET',
    isPublic: true,
  });
};

export const getInstanceActivityService = async (
  instanceId: string
): Promise<any> => {
  return requestPipeline({
    url: `compute-instance/${instanceId}`,
    method: 'GET',
    isPublic: true,
  });
};

export const updateInstanceService = async (
  instance: IRequestForUpdatingInstance
): Promise<any> => {
  return requestPipeline({
    url: `compute-instance/${instance.instanceId}/update`,
    method: 'PATCH',
    body: JSON.stringify(instance),
  });
};

export const restartInstanceService = async (
  instance: IRequestForRestartInstance
): Promise<IGetRestartResponsePayloadDto | IResponseError> => {
  return requestPipeline({
    url: `cluster-instance/${instance.instanceId}/restart`,
    method: 'POST',
    body: JSON.stringify({
      uniqueTopicId: instance.uniqueTopicId,
      organizationId: instance.organizationId,
    }),
  });
};

export const closeInstanceService = async (
  organizationId: string,
  instanceId: string
): Promise<any> => {
  return requestPipeline({
    url: `compute-instance/${instanceId}/close`,
    method: 'POST',
    body: JSON.stringify({
      organizationId,
      instanceId,
    }),
  });
};

export const deleteInstanceService = async (
  instanceId: string
): Promise<any | IResponseError> => {
  return requestPipeline({
    url: `compute-instance/${instanceId}`,
    method: 'DELETE',
  });
};

export const deployTemplateService = async (
  clusterTemplateConfig: IClusterTemplateConfig,
  autoscalingRules: IAutoscalingRequestPayload | null
): Promise<any> => {
  return requestPipeline({
    url: `compute-instance/create`,
    method: 'POST',
    body: JSON.stringify({ ...clusterTemplateConfig, autoscalingRules }),
  });
};

export const getAllSuccessfullyDeployedInstancesService = async (
  payload: string
): Promise<any> => {
  return requestPipeline({
    url: `cluster/${payload}/instances?state=Active`,
    method: 'GET',
    isPublic: true,
  });
};

export const fetchLatestLogsService = async (
  payload: IFetchInstanceServiceDto
): Promise<IFetchInstanceServiceResponse | IResponseError> => {
  return requestPipeline({
    // eslint-disable-next-line max-len
    url: `compute-instance/${payload.instanceId}/trigger/${payload.type}?topicId=${payload.topicId}`,
    method: 'POST',
  });
};

export const getInstanceDomainsService = async (
  instanceId: string
): Promise<IInstanceDomains | IResponseError> => {
  return requestPipeline({
    url: `compute-instance/${instanceId}/domains`,
    method: 'GET',
    isPublic: true,
  });
};

export const createInstanceDomainService = async (
  instanceId: string,
  payload: ICreateInstanceDomainPayloadDto
): Promise<any> => {
  return requestPipeline({
    url: `compute-instance/${instanceId}/domains`,
    method: 'POST',
    body: JSON.stringify(payload),
  });
};

export const verifyInstanceDomainService = async (
  payload: IInstanceDomainIdPayloadDto
): Promise<any> => {
  return requestPipeline({
    url: `compute-instance/${payload.instanceId}/domains/${payload.domainId}/verify`,
    method: 'PATCH',
    body: JSON.stringify(payload),
  });
};
export const updateHealthCheckService = async (
  payload: IUpdateHealthCheck
): Promise<any> => {
  return requestPipeline({
    url: `compute-instance/${payload.instanceId}/update/health-check?serviceName=${payload.serviceName}`,
    method: 'PATCH',
    body: JSON.stringify(payload),
  });
};

export const updateInstanceDomainService = async (
  idPayload: IInstanceDomainIdPayloadDto,
  payload: Partial<ICreateInstanceDomainPayloadDto>
): Promise<any> => {
  return requestPipeline({
    url: `compute-instance/${idPayload.instanceId}/domains/${idPayload.domainId}`,
    method: 'PATCH',
    body: JSON.stringify(payload),
  });
};

export const deleteInstanceDomainService = async (
  payload: IInstanceDomainIdPayloadDto
): Promise<void | IResponseError> => {
  return requestPipeline({
    url: `compute-instance/${payload.instanceId}/domains/${payload.domainId}`,
    method: 'DELETE',
    body: JSON.stringify(payload),
  });
};

export const shellCommandService = async (
  shellCommand: string,
  instanceId: string
): Promise<IShellResponse> => {
  return requestPipeline({
    url: `compute-instance/${instanceId}/exec-command`,
    method: 'POST',
    body: JSON.stringify({ command: shellCommand }),
  });
};

export const updateAutoScalingRulesService = async (
  autoScalingRules: IAutoscalingRequestPayload,
  instanceId: string
): Promise<IResponseError> => {
  return requestPipeline({
    url: `compute-instance/${instanceId}/update/autoscaling-rules`,
    method: 'PATCH',
    body: JSON.stringify(autoScalingRules),
  });
};

export const getInstanceMetricsChartService = async (
  serviceName: string,
  instanceId: string,
  startDate: number,
  endDate: number,
  pointTimeSpan: string,
  filter: string,
  type: string
): Promise<IInstanceMetricsCharResponsePayloadDto | IResponseError> => {
  return requestPipeline({
    // eslint-disable-next-line max-len
    url: `compute-instance/${instanceId}/metrics/charts?service=${serviceName}&startDate=${startDate}&endDate=${endDate}&pointTimeSpan=${pointTimeSpan}&filter=${filter}&param=${type}`,
    method: 'GET',
  });
};

export const getComputeInstanceDetailsService = async (
  instanceId: string
): Promise<any> => {
  return requestPipeline({
    url: `compute-instance/${instanceId}?topupReport=n`,
    method: 'GET',
  });
};

export const closeMultipleInstancesService = async (
  instanceIds: string[]
): Promise<ICloseMultipleInstanceResponseDto> => {
  return requestPipeline({
    url: 'compute-instance/close',
    method: 'POST',
    body: JSON.stringify({ instanceIds }),
  });
};

export const deleteMultipleInstanceService = async (
  instanceIds: string[]
): Promise<any | IResponseError> => {
  return requestPipeline({
    url: `compute-instance`,
    method: 'DELETE',
    body: JSON.stringify({ instanceIds }),
  });
};

export const editInstanceNameService = async (
  name: string,
  instanceId: string
) => {
  return requestPipeline({
    url: `compute-instance/${instanceId}/update-metadata`,
    method: 'PATCH',
    body: JSON.stringify({ name }),
  });
};
