/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState, Fragment, useRef, useEffect, useMemo } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import makeBlockie from 'ethereum-blockies-base64';
import {
  AttachedPaymentMethodCard,
  Button,
  Feedback,
  Link,
  Modal,
  TextInput,
} from '@spheron/ui-library';
import Skeleton from 'react-loading-skeleton';
import { ReactComponent as RefreshIcon } from '@spheron/ui-library/dist/assets/refresh-2.svg';
import { useDispatch, useSelector } from 'react-redux';
import ProviderNetworkTab from '../../WalletInfo/provider-network-tab';
import { useShortAddress } from '../../../hooks/useShortAddress';
import {
  getDecimalFromTokenAddress,
  getNetworkFromName,
  getTokenNameFromAddress,
  isChainDisabled,
  isNonSubgraphChain,
  isTokenDisabled,
} from '../../../redux/compute/web3/web3.utils';
import {
  INetworkType,
  IOrganisationWallet,
  ITokenType,
} from '../../../redux/compute/web3/web3.interfaces';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import {
  connectWalletThunk,
  depositTokenThunk,
  fetchWalletAvailableBalanceThunk,
  switchNetworkThunk,
  // withdrawTokenThunk,
} from '../../../redux/compute/web3/web3.thunks';
import Styles from '../../../styles/compute/modal.module.scss';
import { getTokenLivePrice } from '../../../redux/compute/subscription/subscription.services';
import { withLoader } from '../../../redux/root-utils';
import { ICurrentApp } from '../../../redux/compute/organisation/organisation.interfaces';
import config from '../../../config';
import ModalWrapper from './ModalWrapper';
import { fetchSolBalanceThunk } from '../../../redux/solana/solana.thunks';
import WalletBalance from '../Balance';

interface IProps {
  isOpen: boolean;
  type: string;
  walletId: string;
}

// eslint-disable-next-line no-unused-vars
enum TxnType {
  // eslint-disable-next-line no-unused-vars
  DEPOSIT = 'deposit',
  // eslint-disable-next-line no-unused-vars
  WITHDRAW = 'withdraw',
}

const FundsModal = ({ isOpen, type, walletId }: IProps): JSX.Element => {
  const dispatchRtk = useDispatch<AppDispatch>();
  const [progress, setProgress] = useState<number>(0);
  const [liveTokenPrice, setLiveTokenPrice] = useState<number>(0);
  const [liveTokenPriceLoading, setLiveTokenPriceLoading] =
    useState<boolean>(true);

  const [showAvailableBalance, setShowAvailableBalance] =
    useState<boolean>(false);

  const currentApp = useSelector(
    (state: RootState) => state.organisation.currentApp
  );

  const transactionOngoing = useSelector(
    (state: RootState) => state.web3.transactionOngoing
  );

  const currentAccount = useSelector(
    (state: RootState) => state.web3.currentAccount
  );

  const connectWalletLoading = useSelector(
    (state: RootState) => state.web3.connectWalletLoading
  );

  const switchNetworkLoading = useSelector(
    (state: RootState) => state.web3.switchNetworkLoading
  );

  const balanceLoading = useSelector(
    (state: RootState) => state.web3.escrowBalanceLoading
  );

  const selectedTokenAvailableBalanceLoading = useSelector(
    (state: RootState) => state.web3.selectedTokenAvailableBalanceLoading
  );

  const selectedTokenAvailableBalance = useSelector(
    (state: RootState) => state.web3.selectedTokenAvailableBalance
  );

  const balance = useSelector((state: RootState) => state.web3.balance);

  const web3Wallets = useSelector((state: RootState) => state.web3.wallets);

  const [transactionValue, setTransactionValue] = useState<string>('');

  const cancelButtonRef = useRef(null);

  const closeModal = () => {
    dispatchRtk(
      toggleModalShowRtk({
        modalShow: false,
      })
    );
  };

  const selectedWallet = useMemo(() => {
    return web3Wallets?.find((wallet) => wallet._id === walletId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [web3Wallets?.length, walletId]);

  const shortAddress = useShortAddress(selectedWallet?.details?.address || '');

  useEffect(() => {
    if (selectedWallet && (window as any).ethereum) {
      if (
        String(selectedWallet?.details.networkDetails.chainId) ===
        (window as any).ethereum.chainId
      ) {
        setProgress(2);
      } else {
        setProgress(1);
      }
    }

    return () => setProgress(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [switchNetworkLoading, selectedWallet?._id]);

  useEffect(() => {
    if (selectedWallet) {
      setLiveTokenPriceLoading(true);
      getTokenLivePrice(selectedWallet.details.tokenDetails.symbol).then(
        (res) => {
          setLiveTokenPrice(res.price);
          setLiveTokenPriceLoading(false);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWallet?._id]);

  useEffect(() => {
    // eslint-disable-next-line valid-typeof
    if (typeof selectedTokenAvailableBalance !== null) {
      setShowAvailableBalance(true);
    }
  }, [selectedTokenAvailableBalance]);

  (window as any)?.ethereum?.on('networkChanged', (network: any) => {
    if (selectedWallet?.details.networkDetails.networkId !== Number(network)) {
      setProgress(1);
      return;
    }
    setProgress(2);
  });

  const handleSwitchNetwork = () => {
    dispatchRtk(switchNetworkThunk(selectedWallet?.details.networkDetails!));
  };

  const isCompute = currentApp === ICurrentApp.COMPUTE;

  const displayBalance =
    selectedWallet && isNonSubgraphChain(selectedWallet)
      ? Number(balance?.amount || 0).toFixed(4)
      : (
          Number(balance?.amount || 0) /
          10 ** getDecimalFromTokenAddress(balance?.token?.toLowerCase() || '')
        ).toFixed(4);

  const slippage = () => {
    if (!liveTokenPriceLoading) {
      const slippage =
        Number(transactionValue) * liveTokenPrice * config.web3.TOKEN_SLIPPAGE;

      if (currentApp === ICurrentApp.COMPUTE) return slippage;
      return 0;
    }
    return 0;
  };

  const isError =
    !selectedTokenAvailableBalanceLoading &&
    !liveTokenPriceLoading &&
    (Number(transactionValue) + Number(slippage())) / liveTokenPrice >=
      Number(selectedTokenAvailableBalance) &&
    transactionValue.length > 0;

  const inputHelpText = () => {
    if (isError) return 'Insufficient Balance';
    if (currentApp === ICurrentApp.COMPUTE)
      return '1% slippage will be added to your entered amount.';
    return '';
  };

  const slippagePercentage = config.web3.TOKEN_SLIPPAGE * 100;

  const paymentSummary = transactionValue
    ? [
        {
          id: 1,
          heading: false,
          label: 'Total',
          value: <>${Number(transactionValue)?.toFixed(2)}</>,
        },
        ...(currentApp === ICurrentApp.COMPUTE
          ? [
              {
                id: 5,
                heading: false,
                label: `Slippage (${slippagePercentage}%)`,
                value: (
                  <>
                    {liveTokenPriceLoading ? (
                      <Skeleton
                        height={15}
                        width={90}
                        containerClassName="flex"
                      />
                    ) : (
                      <> ~${Number(slippage())?.toFixed(2)} </>
                    )}
                  </>
                ),
              },
            ]
          : []),
        {
          id: 2,
          heading: false,
          label: 'Chosen Token',
          value: (
            <>
              {selectedWallet &&
                (selectedWallet as IOrganisationWallet).details.tokenDetails
                  .symbol}
            </>
          ),
        },
        {
          id: 3,
          heading: false,
          label: 'Market Price',
          value: (
            <>
              {liveTokenPriceLoading ? (
                <Skeleton height={15} width={90} containerClassName="flex" />
              ) : (
                <>
                  {' '}
                  ${Number(liveTokenPrice)?.toFixed(2)}USD /{' '}
                  {
                    (selectedWallet as IOrganisationWallet).details.tokenDetails
                      .symbol
                  }
                </>
              )}
            </>
          ),
        },
        {
          id: 4,
          heading: true,
          label: 'Total Value',
          value: (
            <>
              {liveTokenPriceLoading ? (
                <Skeleton height={15} width={90} containerClassName="flex" />
              ) : (
                <>
                  {(
                    (Number(transactionValue) + Number(slippage())) /
                    liveTokenPrice
                  ).toFixed(2)}{' '}
                  {
                    (selectedWallet as IOrganisationWallet).details.tokenDetails
                      .symbol
                  }
                </>
              )}
            </>
          ),
        },
      ]
    : [];

  const isChainOrTokenDisabled =
    (selectedWallet &&
      isChainDisabled(selectedWallet?.details.networkDetails.chainName)) ||
    isTokenDisabled(
      selectedWallet?.details.networkDetails.chainName || '',
      selectedWallet?.details.tokenDetails.symbol || ''
    );

  const handleTransaction = () => {
    if (isError) {
      return;
    }
    if (type === 'deposit') {
      dispatchRtk(
        depositTokenThunk({
          address: selectedWallet!.details.address,
          transactionValue: Number(Number(transactionValue) + slippage())
            .toFixed(2)
            .toString(),
          networkDetails: selectedWallet!.details.networkDetails,
          tokenDetails: selectedWallet!.details.tokenDetails,
        })
      );
    } else {
      // dispatchRtk(
      //   withdrawTokenThunk({
      //     walletId: selectedWallet?._id || '',
      //     address: selectedWallet!.details.address,
      //     transactionValue,
      //     networkDetails: selectedWallet!.details.networkDetails,
      //     tokenDetails: selectedWallet!.details.tokenDetails,
      //   })
      // );
    }
  };

  const handleRefresh = () => {
    dispatchRtk(
      fetchWalletAvailableBalanceThunk({
        address: selectedWallet?.details.address || '',
        tokenDetails: selectedWallet?.details.tokenDetails as ITokenType,
        networkDetails: selectedWallet?.details.networkDetails as INetworkType,
      })
    );
  };

  const minAmount = config.compute.MINIMUM_DEPOSIT;

  const isInsufficientToDeployCompute =
    isCompute &&
    !liveTokenPriceLoading &&
    Number(displayBalance) < minAmount &&
    (Number(transactionValue) + Number(slippage())) / liveTokenPrice +
      Number(displayBalance) <
      minAmount;

  const showSlippageLink = isCompute && !!transactionValue && progress === 2;

  useEffect(() => {
    if ((window as any)?.ethereum)
      if (selectedWallet) {
        const nonSubgraphChainPayload = {
          walletId: selectedWallet._id,
          tokenDetails: selectedWallet.details.tokenDetails,
          address: selectedWallet.details.address,
          networkDetails: selectedWallet.details.networkDetails,
        };
        const {
          tokenDetails: { address: tokenAddress },
          networkDetails: { chainId, chainName },
          address,
        } = selectedWallet.details;

        const fetchBalancePayload = {
          token: tokenAddress,
          address,
          chainId: Number(chainId),
        };
        if (chainName === 'solana') {
          dispatchRtk(fetchSolBalanceThunk(fetchBalancePayload));
          return;
        }
        if (selectedWallet && !selectedTokenAvailableBalanceLoading) {
          dispatchRtk(connectWalletThunk()).then(() =>
            dispatchRtk(
              fetchWalletAvailableBalanceThunk(nonSubgraphChainPayload)
            )
          );
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWallet, (window as any).ethereum]);

  return (
    <>
      <ModalWrapper
        isOpen={isOpen}
        onClose={() => {
          dispatchRtk(toggleModalShowRtk({ modalShow: false }));
        }}
        classname={`${Styles.modal__outer__con} modal fixed inset-0 overflow-y-auto`}
      >
        <div className={`${Styles.modal__inner__content} !max-w-[530px]`}>
          <Modal
            footer
            header
            subtitle=""
            modalContent={
              <div>
                <div className="text-left">
                  <div className="flex flex-col gap-y-3">
                    <div className="py-3 bg-base-fg dark:bg-dark-base-fg rounded-lg">
                      <div className="flex items-center justify-center flex-col gap-y-1">
                        <h5
                          className="flex items-center justify-start text-base
                             font-semibold text-base-heading-text-color 
                            dark:text-dark-base-heading-text-color gap-x-3"
                        >
                          <span>Available Deposit: </span>
                          <div className="flex items-center justify-start gap-x-1">
                            {withLoader(
                              liveTokenPriceLoading || balanceLoading,
                              <>
                                <Skeleton height={24} width={40} duration={2} />
                              </>,
                              <>
                                $
                                {(
                                  Number(displayBalance) * liveTokenPrice
                                ).toFixed(2)}
                              </>
                            )}

                            <div className="text-[10px] font-normal flex items-center">
                              {!balanceLoading && '('}
                              {withLoader(
                                balanceLoading,
                                <>
                                  <>
                                    <Skeleton
                                      height={10}
                                      width={40}
                                      duration={2}
                                    />
                                  </>
                                </>,
                                <>
                                  <WalletBalance
                                    selectedWallet={selectedWallet}
                                  />
                                </>
                              )}

                              {!balanceLoading && ')'}
                            </div>
                          </div>
                        </h5>

                        <p
                          className="text-base-sub-text-color 
                              dark:text-dark-base-sub-text-color 
                          text-[11px] leading-3 font-normal px-2 text-center"
                        >
                          {isCompute
                            ? `Compute services require a minimum credit of $${minAmount}`
                            : `To make sure your subscription is renewed every month, 
                              we ask you to deposit some funds to Spheron escrow contract.`}
                        </p>
                      </div>
                    </div>
                    <>
                      <div className="w-full relative">
                        <AttachedPaymentMethodCard
                          id="0"
                          cardType="card"
                          icon={
                            selectedWallet ? (
                              <img
                                src={makeBlockie(
                                  selectedWallet?.details?.address || ''
                                )}
                                alt="address-blockie"
                                className="w-8 h-8 rounded-full mr-2.5"
                              />
                            ) : (
                              <></>
                            )
                          }
                          isPrimary={false}
                          title={shortAddress}
                          subtitle={
                            showAvailableBalance
                              ? `Balance: ${
                                  selectedWallet &&
                                  selectedTokenAvailableBalance
                                    ? Number(
                                        selectedTokenAvailableBalance || 0
                                      ).toFixed(4)
                                    : (
                                        Number(
                                          selectedTokenAvailableBalance || 0
                                        ) /
                                        10 **
                                          getDecimalFromTokenAddress(
                                            selectedWallet?.details.tokenDetails.address.toLowerCase() ||
                                              ''
                                          )
                                      ).toFixed(4)
                                } ${getTokenNameFromAddress(
                                  selectedWallet?.details.tokenDetails.address.toLowerCase() ||
                                    ''
                                )}`
                              : ''
                          }
                          hasDropdown={false}
                          dropdownOptions={[]}
                          hasWalletSwitchDropdown={false}
                          walletDropdownOptions={[]}
                        />
                        <RefreshIcon
                          className="w-6 h-6 text-base-icon dark:text-dark-base-icon
                          absolute right-2 top-2 cursor-pointer"
                          onClick={handleRefresh}
                        />
                      </div>
                    </>

                    {currentAccount &&
                    currentAccount?.toLowerCase() ===
                      selectedWallet?.details.address?.toLowerCase() ? (
                      <>
                        <>
                          <div className="w-full">
                            <div
                              className="w-full mx-auto dark:bg-dark-base-bg 
                                  dark:border-dark-base-border border-gray-300"
                            >
                              <>
                                {progress === 1 && (
                                  <div
                                    className="text-base font-semibold
                                text-base-heading-text-color dark:text-dark-base-heading-text-color"
                                  >
                                    To Complete {`${type}ing`} funds, Switch
                                    your network to{' '}
                                    {getNetworkFromName(
                                      Number(
                                        selectedWallet?.details.networkDetails!
                                          .chainId
                                      )
                                    )}{' '}
                                    network.{' '}
                                  </div>
                                )}
                              </>

                              <div>
                                {progress === 2 && (
                                  <>
                                    <div>
                                      <TextInput
                                        label="Enter deposit amount (USD)"
                                        // eslint-disable-next-line max-len
                                        placeholder={`10.0 ${selectedWallet?.details?.tokenDetails?.symbol}`}
                                        value={transactionValue}
                                        onChange={(value) =>
                                          setTransactionValue(String(value))
                                        }
                                        disabled={isChainOrTokenDisabled}
                                        inputSize="compact"
                                        type="number"
                                        error={!!isError}
                                        helpText={inputHelpText()}
                                      />
                                    </div>
                                    {!!transactionValue && (
                                      <div>
                                        <div
                                          className="mt-3 bg-base-fg dark:bg-dark-base-fg
                                                  space-y-1 p-2 rounded-lg"
                                        >
                                          {paymentSummary?.map((payment) => (
                                            <div
                                              key={payment.id}
                                              className="flex items-center 
                                                        justify-between 
                                                         text-sm font-semibold"
                                            >
                                              <h3 className="tracking-tight ">
                                                {payment.label}
                                              </h3>
                                              <div>{payment.value}</div>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </>

                        {isChainOrTokenDisabled ? (
                          <>
                            <div>
                              <Feedback
                                actionDetails={{
                                  isBold: false,
                                  type: 'primary',
                                  size: 'default',
                                  text: 'Attach new payment method',
                                  onClick: () => {
                                    dispatchRtk(
                                      toggleModalShowRtk({
                                        modalShow: true,
                                        modalType: 'connectWalletParameters',
                                      })
                                    );
                                  },
                                }}
                                title="We are no longer accepting deposits
                                 using this chain and token.
                                 To continue making deposits, please link a new payment method."
                                feedbackType="error"
                                showClose={false}
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            {isInsufficientToDeployCompute &&
                              transactionValue.length !== 0 && (
                                <div>
                                  <Feedback
                                    // eslint-disable-next-line max-len
                                    title={`Please make sure to have atleast $${minAmount} to deploy compute.`}
                                    feedbackType="error"
                                    showClose={false}
                                  />
                                </div>
                              )}
                          </>
                        )}
                        {showSlippageLink && (
                          <div
                            className={`mt-5 w-full flex items-center 
                          ${
                            showSlippageLink ? 'justify-between' : 'justify-end'
                          }`}
                          >
                            <Link
                              type="primary"
                              size="default"
                              text="What is slippage amount?"
                              isBold={false}
                              onClick={() =>
                                dispatchRtk(
                                  toggleModalShowRtk({
                                    modalShow: true,
                                    modalType: 'slippageAmount',
                                  })
                                )
                              }
                            />
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {currentAccount &&
                          currentAccount !==
                            selectedWallet?.details.address && (
                            <Feedback
                              feedbackType="error"
                              title={`Sorry you can't ${type} funds unless your
                                current account is same as the organisation
                                wallet. Please change your account and connect
                                again.`}
                              showClose={false}
                            />
                          )}
                        <div className="flex flex-col items-center justify-center">
                          <div
                            className="my-5 text-base font-semibold text-base-heading-text-color 
                            dark:text-dark-base-heading-text-color"
                          >
                            Please attach your wallet to continue
                          </div>
                          <div className="flex items-center justify-center gap-x-4">
                            <Button
                              buttonType="primary"
                              label="Connect"
                              size="medium"
                              onClick={() => dispatchRtk(connectWalletThunk())}
                              loading={connectWalletLoading}
                            />
                            <Button
                              buttonType="ghost"
                              label="Cancel"
                              size="medium"
                              onClick={() => {
                                dispatchRtk(
                                  toggleModalShowRtk({
                                    modalShow: false,
                                  })
                                );
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            }
            headerContent={
              <div className="flex justify-start items-center gap-x-2.5">
                <span
                  className="text-lg font-semibold 
                          text-base-heading-text-color dark:text-dark-base-heading-text-color"
                >
                  {type === TxnType.DEPOSIT
                    ? 'Deposit Funds'
                    : 'Withdraw Funds'}
                </span>
                <div className="flex items-center justify-start gap-x-3">
                  <ProviderNetworkTab
                    icon={selectedWallet?.details.networkDetails?.logo}
                    name={getNetworkFromName(
                      Number(selectedWallet?.details.networkDetails!.chainId)
                    )}
                  />
                  <ProviderNetworkTab
                    icon={selectedWallet?.details.tokenDetails.logoURI!}
                    name={selectedWallet?.details.tokenDetails.symbol || ''}
                  />
                </div>
              </div>
            }
            primaryButtonContent={
              progress === 1
                ? 'Switch'
                : `${type === TxnType.WITHDRAW ? 'Withdraw' : 'Deposit'}`
            }
            secondaryButtonContent="Cancel"
            primaryButton
            primaryButtonLoading={
              progress === 2 ? transactionOngoing : switchNetworkLoading
            }
            primaryButtonDisabled={
              progress === 2 &&
              (transactionValue.length === 0 ||
                transactionOngoing ||
                !!isError ||
                isChainOrTokenDisabled ||
                (isCompute && liveTokenPriceLoading))
            }
            secondaryButtonDisabled={false}
            secondaryButton
            onPrimaryButtonClick={
              progress === 1 ? handleSwitchNetwork : handleTransaction
            }
            onSecondaryButtonClick={() => {
              dispatchRtk(
                toggleModalShowRtk({
                  modalShow: false,
                })
              );
            }}
          />
        </div>
      </ModalWrapper>
    </>
  );
};

export default FundsModal;
