import React from 'react';
import { Navbar } from '@spheron/ui-library';
import { Link } from 'react-router-dom';
import LoginStyle from '../../../styles/compute/log-in.module.scss';
import OutlinedPrimaryButton from '../Buttons/outlined-primary';
import GlobalStyle from '../../../styles/compute/global.module.scss';
import Logo from '../../../assets/compute/global/spheron-logo.svg';

interface IProps {
  children?: JSX.Element;
}

const TemplateNavbar = ({ children }: IProps) => {
  return (
    <>
      <Navbar
        logoIcon={
          <img src={Logo} alt="logo" className={GlobalStyle.spheron__logo} />
        }
        infoElements={
          <Link to="/signup">
            <OutlinedPrimaryButton
              className={`${LoginStyle.button} px-7 py-3`}
              title="Sign Up"
            />
          </Link>
        }
      />
      {children}
    </>
  );
};

TemplateNavbar.defaultProps = {
  children: <></>,
};

export default TemplateNavbar;
