import { useEffect } from 'react';

const usePageTracking = () => {
  useEffect(() => {
    (window as any).gtag('event', 'page_view', {
      page_path: `${window.location.pathname}`,
      page_location: window.location.href,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);
};

export default usePageTracking;
