/* eslint-disable import/no-cycle */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IDeployment, IOrganization } from '../combined-state.interface';
import {
  DeploymentStatus,
  IBuildTime,
  IDeployConfig,
  IDeploymentState,
  IFetchDeploymentRepoBranchResponse,
  IRepoOwner,
  IRepository,
} from './deployment.interfaces';
import {
  authorizeDeploymentThunk,
  cancelDeploymentThunk,
  deleteDeploymentThunk,
  fetchAllDeploymentsDetailsThunk,
  fetchAllDeploymentsThunk,
  fetchSuccessfulSitePreviewsThunk,
  fetchDeploymentThunk,
  fetchGithubRepoBranchesThunk,
  frameworkSuggestionThunk,
  getThirdPartyRepoInfoThunk,
  loadMoreDeploymentsThunk,
  setStartDeploymentThunk,
  triggerSpecificDeploymentThunk,
  getDeploymentProviders,
} from './deployment.thunks';
import {
  checkDeploymentBuildTime,
  checkDeploymentStatus,
  mapDeploymentStatusToEnum,
  setProcessedData,
  setStreamData,
} from './deployment.utils';

export const initialState: IDeploymentState = {
  successfulSitePreviewsLoading: true,
  successfulSitePreviews: [],
  deploymentsLoading: true,
  allDeployments: [],
  updatingDeploymentLoading: false,
  moreDeploymentsLoading: false,
  deploymentsPagination: {
    currentPage: 0,
    numbersPerPage: 5,
  },
  allDeploymentsDetails: {
    total: 0,
    successful: 0,
    failed: 0,
    pending: 0,
    authorizationneeded: 0,
    timedOut: 0,
  },
  fetchAllDeploymentDetailsLoading: false,
  // authorized providers
  authorizedProviders: [],
  authorizedProvidersLoaded: false,
  // selected deployment
  startDeploymentLoading: false,
  selectedDeployment: undefined,
  selectedDeploymentLoading: false,
  selectedDeploymentConfig: undefined,
  selectedDeploymentStatus: DeploymentStatus.PreQueue,
  selectedDeploymentLogs: [],
  selectedDeploymentBuildTime: {
    min: 0,
    sec: 0,
  },
  selectedDeploymentDeployedLink: '',
  // providers info
  selectedProvider: '',
  selectedRepoOwner: undefined,
  selectedRepository: undefined,
  selectedDeploymentProtocol: '',
  selectedDeploymentOrganization: undefined,
  selectedDeploymentRepoBranches: [],
  selectedDeploymentRepoBranchesLoading: false,
  selectedDeploymentBranch: 'master',
  redeployProjectSettings: null,
  repositoryDropdown: false,
  authorizingDeployment: '',
  authorizingDeploymentLoading: false,
  authorizedDeployment: true,
  pullRequestId: '',
  suggestedFramework: 'react',
  suggestedFrameworkLoading: false,

  // clone template
  cloneRepoTopicId: '',
  thirdPartyRepoDetails: {
    name: '',
    description: '',
    owner: '',
    avatarUrl: '',
  },
  clonedRepository: {
    clonedToNamespace: '',
    cloneRepositoryName: '',
  },
};

const deploymentSlice = createSlice({
  name: 'deployment',
  initialState,
  reducers: {
    setDeploymentConfig(state, action: PayloadAction<IDeployConfig>) {
      state.selectedDeploymentConfig = action.payload;
    },

    // to set the streamed logs through SSE
    setStreamLogs(state, action: PayloadAction<any>) {
      state.selectedDeploymentLogs = setStreamData(
        state.selectedDeploymentLogs,
        action.payload.streamData,
        action.payload.topicId
      );
    },

    // to set the processed logs
    setProcessedLogs(state, action: PayloadAction<any>) {
      state.selectedDeploymentLogs = setProcessedData(
        action.payload.logs,
        action.payload.topicId
      );
    },

    getDeployment(
      state
      // action: PayloadAction<any>
    ) {
      state.selectedDeploymentLoading = true;
    },

    loadDeployment(state, action: PayloadAction<any>) {
      state.selectedDeployment = {
        ...action.payload,
        cancellingDeployment: false,
      };
      state.selectedDeploymentLoading = false;
    },

    toggleDeploymentLoading(state, action: PayloadAction<boolean>) {
      state.selectedDeploymentLoading = action.payload;
    },

    setDeploymentProviders(state, action: PayloadAction<string[]>) {
      state.authorizedProviders = action.payload;
      state.authorizedProvidersLoaded = true;
    },

    setDeploymentOrganization(state, action: PayloadAction<IOrganization>) {
      state.selectedDeploymentOrganization = action.payload;
    },

    // set deployment status -- queue || pending || failed || published
    setDeploymentStatus(
      state,
      action: PayloadAction<{
        status: DeploymentStatus;
        topicId: string;
      }>
    ) {
      state.selectedDeploymentStatus = checkDeploymentStatus(
        state.selectedDeployment?.topic || '',
        state.selectedDeployment?.status || '',
        action.payload.topicId,
        action.payload.status
      );
    },

    setDeploymentLink(
      state,
      action: PayloadAction<{
        link: string;
        topicId: string;
      }>
    ) {
      state.selectedDeploymentDeployedLink =
        state.selectedDeployment?.topic === action.payload.topicId
          ? action.payload.link
          : state.selectedDeploymentDeployedLink;
    },

    setDeploymentBuildTime(
      state,
      action: PayloadAction<{
        time: IBuildTime;
        topicId: string;
      }>
    ) {
      state.selectedDeploymentBuildTime = checkDeploymentBuildTime(
        state.selectedDeployment?.topic || '',
        state.selectedDeploymentBuildTime,
        action.payload.topicId,
        action.payload.time
      );
    },

    setRepositoryDropdownAction(state, action: PayloadAction<boolean>) {
      state.repositoryDropdown = action.payload;
    },

    authorizeDeployment(state, action: PayloadAction<string>) {
      state.authorizingDeployment = action.payload;
    },

    authorizedDeployment(state, action: PayloadAction<boolean>) {
      state.authorizedDeployment = action.payload;
    },

    toggleUpdatingDeploymentLoading(state, action: PayloadAction<boolean>) {
      state.updatingDeploymentLoading = action.payload;
    },

    setSelectedProvider(state, action: PayloadAction<string>) {
      state.selectedProvider = action.payload;
    },
    setDeploymentProtocol(state, action: PayloadAction<string>) {
      state.selectedDeploymentProtocol = action.payload;
    },
    setSelectedRepository(
      state,
      action: PayloadAction<IRepository | undefined>
    ) {
      state.selectedRepository = action.payload;
    },
    setSelectedRepoOwner(state, action: PayloadAction<IRepoOwner | undefined>) {
      state.selectedRepoOwner = action.payload;
    },
    setDeploymentProvider(state, action: PayloadAction<any>) {
      state.authorizedProviders = action.payload;
      state.authorizedProvidersLoaded = true;
    },
    toggleDeploymentState(state: any, action: PayloadAction<any>) {
      const foundDeployment = state.allDeployments.find(
        (deployment: IDeployment) =>
          deployment._id === action.payload.deploymentId
      );
      const newDeployments = state.allDeployments.filter(
        (deployment: IDeployment) =>
          deployment._id !== action.payload.deploymentId
      );
      state.selectedDeploymentStatus = mapDeploymentStatusToEnum(
        action.payload.status
      );
      state.allDeployments = [
        ...newDeployments,
        {
          ...foundDeployment,
          cancellingDeployment: action.payload.state,
          status: action.payload.status,
        },
      ];
    },
    setClonedRepositoryDetails(state, action: PayloadAction<any>) {
      state.clonedRepository = {
        clonedToNamespace: action.payload.namespace,
        cloneRepositoryName: action.payload.repository,
      };
    },
    setCloneRepositoryTopicId(state, action: PayloadAction<string>) {
      state.cloneRepoTopicId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDeploymentProviders.pending, (state) => {
      state.authorizedProvidersLoaded = false;
    });
    builder.addCase(
      getDeploymentProviders.fulfilled,
      (state, action: PayloadAction<string[]>) => {
        state.authorizedProviders = action.payload;
        state.authorizedProvidersLoaded = true;
      }
    );
    builder.addCase(getDeploymentProviders.rejected, (state) => {
      state.authorizedProvidersLoaded = true;
    });
    builder.addCase(fetchAllDeploymentsThunk.pending, (state) => {
      state.deploymentsLoading = true;
      state.allDeployments = [];
    });
    builder.addCase(
      fetchAllDeploymentsThunk.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.allDeployments = action.payload.map((deployment: any) => {
          return { ...deployment, cancellingDeployment: false };
        });
        state.deploymentsLoading = false;
        state.deploymentsPagination = {
          currentPage: 0,
          numbersPerPage: 5,
        };
      }
    );
    builder.addCase(fetchAllDeploymentsThunk.rejected, (state) => {
      state.deploymentsLoading = false;
    });
    builder.addCase(fetchSuccessfulSitePreviewsThunk.pending, (state) => {
      state.successfulSitePreviewsLoading = true;
      state.successfulSitePreviews = [];
    });
    builder.addCase(
      fetchSuccessfulSitePreviewsThunk.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.successfulSitePreviewsLoading = false;
        state.successfulSitePreviews = action.payload;
      }
    );
    builder.addCase(fetchSuccessfulSitePreviewsThunk.rejected, (state) => {
      state.successfulSitePreviewsLoading = false;
      state.successfulSitePreviews = [];
    });
    builder.addCase(loadMoreDeploymentsThunk.pending, (state) => {
      state.moreDeploymentsLoading = true;
    });
    builder.addCase(
      loadMoreDeploymentsThunk.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.allDeployments = [...state.allDeployments, ...action.payload];
        state.moreDeploymentsLoading = false;
        state.deploymentsPagination = {
          ...state.deploymentsPagination,
          currentPage: state.deploymentsPagination.currentPage + 1,
        };
      }
    );
    builder.addCase(loadMoreDeploymentsThunk.rejected, (state) => {
      state.moreDeploymentsLoading = false;
    });
    builder.addCase(fetchAllDeploymentsDetailsThunk.pending, (state) => {
      state.allDeploymentsDetails = {
        total: 0,
        successful: 0,
        failed: 0,
        pending: 0,
      };
      state.fetchAllDeploymentDetailsLoading = true;
    });
    builder.addCase(
      fetchAllDeploymentsDetailsThunk.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.allDeploymentsDetails = action.payload;
        state.fetchAllDeploymentDetailsLoading = false;
      }
    );
    builder.addCase(fetchAllDeploymentsDetailsThunk.rejected, (state) => {
      state.fetchAllDeploymentDetailsLoading = false;
    });
    builder.addCase(fetchGithubRepoBranchesThunk.pending, (state) => {
      state.selectedDeploymentRepoBranchesLoading = true;
    });
    builder.addCase(
      fetchGithubRepoBranchesThunk.fulfilled,
      (state, action: PayloadAction<IFetchDeploymentRepoBranchResponse>) => {
        state.selectedDeploymentRepoBranchesLoading = false;
        state.selectedDeploymentRepoBranches = action.payload.branches;
      }
    );
    builder.addCase(fetchGithubRepoBranchesThunk.rejected, (state) => {
      state.selectedDeploymentRepoBranchesLoading = false;
      state.selectedDeploymentRepoBranches = [];
    });
    builder.addCase(setStartDeploymentThunk.pending, (state) => {
      state.startDeploymentLoading = true;
      state.selectedDeploymentConfig = undefined;
      state.selectedDeploymentLogs = [];
      state.selectedDeploymentStatus = DeploymentStatus.PreQueue;
      state.selectedDeploymentLoading = false;
      state.selectedDeploymentBuildTime = {
        min: 0,
        sec: 0,
      };
      state.selectedDeploymentDeployedLink = '';
      state.selectedDeployment = undefined;
    });
    builder.addCase(setStartDeploymentThunk.fulfilled, (state) => {
      state.startDeploymentLoading = false;
    });
    builder.addCase(setStartDeploymentThunk.rejected, (state) => {
      state.startDeploymentLoading = false;
    });
    builder.addCase(fetchDeploymentThunk.pending, (state) => {
      state.selectedDeploymentLoading = true;
    });
    builder.addCase(
      fetchDeploymentThunk.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.selectedDeployment = {
          ...action.payload,
          cancellingDeployment: false,
        };
        state.selectedDeploymentLoading = false;
      }
    );
    builder.addCase(fetchDeploymentThunk.rejected, (state) => {
      state.selectedDeploymentLoading = false;
    });
    builder.addCase(authorizeDeploymentThunk.pending, (state) => {
      state.authorizingDeploymentLoading = true;
    });
    builder.addCase(authorizeDeploymentThunk.fulfilled, (state) => {
      state.authorizingDeploymentLoading = false;
    });
    builder.addCase(authorizeDeploymentThunk.rejected, (state) => {
      state.authorizingDeploymentLoading = false;
    });
    builder.addCase(cancelDeploymentThunk.pending, (state) => {
      state.updatingDeploymentLoading = true;
    });
    builder.addCase(cancelDeploymentThunk.fulfilled, (state) => {
      state.updatingDeploymentLoading = false;
    });
    builder.addCase(cancelDeploymentThunk.rejected, (state) => {
      state.updatingDeploymentLoading = false;
    });
    builder.addCase(frameworkSuggestionThunk.pending, (state) => {
      state.suggestedFrameworkLoading = true;
    });
    builder.addCase(
      frameworkSuggestionThunk.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.suggestedFrameworkLoading = false;
        state.suggestedFramework = action.payload.suggestedFramework;
      }
    );
    builder.addCase(frameworkSuggestionThunk.rejected, (state) => {
      state.suggestedFrameworkLoading = false;
      state.suggestedFramework = '';
    });
    builder.addCase(triggerSpecificDeploymentThunk.pending, (state) => {
      state.updatingDeploymentLoading = true;
      state.suggestedFrameworkLoading = true;
    });
    builder.addCase(triggerSpecificDeploymentThunk.fulfilled, (state) => {
      state.updatingDeploymentLoading = false;
      state.updatingDeploymentLoading = false;
    });
    builder.addCase(triggerSpecificDeploymentThunk.rejected, (state) => {
      state.updatingDeploymentLoading = false;
      state.suggestedFrameworkLoading = false;
    });
    builder.addCase(getThirdPartyRepoInfoThunk.pending, (state) => {
      state.thirdPartyRepoDetails = {
        name: '',
        description: '',
        owner: '',
        avatarUrl: '',
      };
    });
    builder.addCase(
      getThirdPartyRepoInfoThunk.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.thirdPartyRepoDetails = {
          name: action.payload.name,
          description: action.payload.description,
          owner: action.payload.owner,
          avatarUrl: action.payload.avatar_url,
        };
      }
    );
    builder.addCase(getThirdPartyRepoInfoThunk.rejected, (state) => {
      state.thirdPartyRepoDetails = {
        name: '',
        description: '',
        owner: '',
        avatarUrl: '',
      };
    });
    builder.addCase(deleteDeploymentThunk.pending, (state) => {
      state.updatingDeploymentLoading = true;
    });
    builder.addCase(
      deleteDeploymentThunk.fulfilled,
      (state, action: PayloadAction<string>) => {
        state.updatingDeploymentLoading = false;
        state.successfulSitePreviews = state.successfulSitePreviews.filter(
          (successfulSitePreview) =>
            successfulSitePreview._id !== action.payload
        );
        state.allDeployments = state.allDeployments.filter(
          (deployment: IDeployment) => deployment._id !== action.payload
        );
        state.selectedRepoOwner = undefined;
        state.selectedRepository = undefined;
        state.selectedDeploymentProtocol = '';
        state.selectedDeploymentOrganization = undefined;
        state.selectedDeploymentRepoBranches = [];
        state.selectedDeploymentRepoBranchesLoading = false;
        state.selectedDeployment = undefined;
        state.selectedDeploymentLoading = false;
        state.selectedDeploymentConfig = undefined;
        state.selectedDeploymentStatus = DeploymentStatus.PreQueue;
        state.selectedDeploymentLogs = [];
        state.selectedDeploymentBuildTime = {
          min: 0,
          sec: 0,
        };
        state.selectedDeploymentDeployedLink = '';
      }
    );
    builder.addCase(deleteDeploymentThunk.rejected, (state) => {
      state.updatingDeploymentLoading = false;
    });
  },
});

export const { reducer: deployRTKReducer } = deploymentSlice;
export const {
  setDeploymentProtocol: setDeploymentProtocolRtk,
  setSelectedProvider: setSelectedProviderRtk,
  setSelectedRepository: setSelectedRepositoryRtk,
  setSelectedRepoOwner: setSelectedRepoOwnerRtk,
  setDeploymentProvider: setDeploymentProviderRtk,
  setDeploymentConfig: setDeploymentConfigRtk,
  loadDeployment: loadDeploymentRtk,
  setRepositoryDropdownAction: setRepositoryDropdownActionRtk,
  setStreamLogs: setStreamLogsRtk,
  setProcessedLogs: setProcessedLogsRtk,
  setDeploymentStatus: setDeploymentStatusRtk,
  setDeploymentLink: setDeploymentLinkRtk,
  setDeploymentBuildTime: setDeploymentBuildTimeRtk,
  authorizedDeployment: authorizedDeploymentRtk,
  toggleDeploymentState: toggleDeploymentStateRtk,
  setClonedRepositoryDetails: setClonedRepositoryDetailsRtk,
  setCloneRepositoryTopicId: setCloneRepositoryTopicIdRtk,
} = deploymentSlice.actions;
