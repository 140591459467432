import React from 'react';
import Skeleton from 'react-loading-skeleton';

const ComputeDashboardLoading = () => {
  return (
    <>
      <div
        className="relative border w-full dark:border-dark-cards-border
        border-cards-border dark:bg-dark-cards-bg bg-cards-bg rounded-lg"
      >
        <div className="flex items-center space-between py-5 pl-3 pr-20">
          <div className="flex flex-col items-start w-full gap-y-0.5">
            <h5
              className="font-semibold leading-5 text-base-heading-text-color 
            dark:text-dark-base-heading-text-color"
            >
              <Skeleton height={20} width={100} duration={2} />
            </h5>
            <p
              className="text-base-para-text-color dark:text-dark-base-para-text-color
             text-sm font-normal"
            >
              <Skeleton height={14} width={250} duration={2} />
            </p>
            <Skeleton height={18} width={300} duration={2} />
          </div>
          <div className="flex items-center justify-between gap-x-20">
            {[1, 2, 3, 4].map((stat) => (
              <div
                key={stat}
                className="flex flex-col items-center justify-center gap-y-0.5 w-1/4"
              >
                <h5
                  className="font-semibold leading-5 text-base text-base-heading-text-color
                 dark:text-dark-base-heading-text-color"
                >
                  <Skeleton height={16} width={50} duration={2} />
                </h5>
                <p
                  className="text-base-para-text-color dark:text-dark-base-para-text-color 
                text-sm font-normal"
                >
                  <Skeleton height={20} width={20} duration={2} />
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="absolute top-1 right-1 p-2 flex items-center justify-center z-10">
          <Skeleton height={20} width={20} duration={2} />
        </div>
      </div>
    </>
  );
};

export default ComputeDashboardLoading;
