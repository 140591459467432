import React, { useState } from 'react';
import { ConfirmationModal, Modal, TextInput } from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import ModalWrapper from './ModalWrapper';
import Styles from '../../../styles/compute/modal.module.scss';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import { AppDispatch, RootState } from '../../../redux/compute/store';

interface IProps {
  isOpen: boolean;
  visibilityAction: string;
  handleClick: () => void;
  organisationName: string;
}

const OrgVisibilityToggle = ({
  isOpen,
  visibilityAction,
  handleClick,
  organisationName,
}: IProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const [orgName, setOrgName] = useState<string>('');

  //   Visibility Action coming from props is opposite
  const wasPrevStatePrivate = visibilityAction?.toLowerCase() === 'public';

  const changeVisibilityLoading = useSelector(
    (state: RootState) => state.organisation.deleteOrganisationLoading
  );

  const closeModal = () => {
    dispatch(toggleModalShowRtk({ modalShow: false }));
  };
  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={closeModal}
      classname={`${Styles.modal__outer__con} w-430 modal fixed inset-0 overflow-y-auto`}
    >
      <div className="w-[600px]">
        <Modal
          footer
          header
          title="Organizational Privacy Settings"
          requiredMessage
          requiredMessageContent={<span>Please fill in all the fields</span>}
          subtitle=""
          modalContent={
            <div className="flex flex-col gap-y-5">
              <div className="text-base-para-text-color dark:text-dark-base-para-text-color font-normal text-sm">
                Control who sees your organization “<b>{organisationName}</b>”.
                It&apos;s currently set to{' '}
                {wasPrevStatePrivate ? 'Private' : 'Public'}. To make it{' '}
                {visibilityAction}, kindly confirm by entering your organization
                name.
              </div>
              <TextInput
                label="Enter the organization name"
                value={orgName}
                onChange={(value) => setOrgName(value as string)}
                helpText={`Input the organization name you want to make ${visibilityAction}`}
              />
            </div>
          }
          headerContent={
            <div className="flex flex-col gap-y-3">
              <span>Organizational Privacy Settings</span>
            </div>
          }
          primaryButtonContent={`Make ${visibilityAction}`}
          secondaryButtonContent="Cancel"
          primaryButton
          primaryButtonLoading={changeVisibilityLoading}
          primaryButtonDisabled={orgName !== organisationName}
          onPrimaryButtonClick={handleClick}
          onSecondaryButtonClick={closeModal}
          secondaryButton
          confirmationStatus={ConfirmationModal.ERROR}
        />
      </div>
    </ModalWrapper>
  );
};

export default OrgVisibilityToggle;
