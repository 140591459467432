import {
  INetworkType,
  IOrganisationWallet,
  ITokenType,
  NonSubgraphsChainName,
} from './web3.interfaces';
import config, { NODE_ENV } from '../../config';
import Polygon from '../../assets/compute/icons/web3/polygon-network.svg';
import Arbitrum from '../../assets/compute/icons/web3/arbitrum.svg';
import Mantle from '../../assets/compute/icons/web3/mantle.svg';
import Metamask from '../../assets/compute/icons/web3/metamask.svg';
import Phantom from '../../assets/compute/icons/web3/phantom.svg';
import Solana from '../../assets/compute/icons/web3/solana.svg';
import Etherscan from '../../assets/compute/icons/web3/ether-scan.svg';
import BSC from '../../assets/compute/icons/web3/bsc.svg';
import Gnosis from '../../assets/compute/icons/web3/gnosis.svg';
import Optimism from '../../assets/compute/icons/web3/optimism.svg';
import Avalanche from '../../assets/compute/icons/web3/avalanche.svg';
import Filecoin from '../../assets/compute/icons/web3/filecoin.svg';
import UsdtIcon from '../../assets/compute/icons/web3/tokens/usdt.svg';
import WmntIcon from '../../assets/compute/icons/web3/tokens/wmnt.svg';
import UsdcIcon from '../../assets/compute/icons/web3/tokens/usdc.svg';
import DaiIcon from '../../assets/compute/icons/web3/tokens/dai.svg';
import MaticIcon from '../../assets/compute/icons/web3/tokens/matic.svg';
import WethIcon from '../../assets/compute/icons/web3/tokens/weth.svg';
import WxdaiIcon from '../../assets/compute/icons/web3/tokens/wxdai.svg';
import FilIcon from '../../assets/compute/icons/web3/tokens/fil.svg';
import WnbIcon from '../../assets/compute/icons/web3/tokens/wnb.svg';
import WavaxIcon from '../../assets/compute/icons/web3/tokens/wavax.svg';
import Base from '../../assets/compute/icons/web3/base-logo-in-blue.svg';
import Linea from '../../assets/compute/icons/web3/linea.svg';
import ZkSync from '../../assets/compute/icons/web3/zksync-era.svg';

export const networks: { [id: number]: INetworkType } = {
  80001: {
    chainId: `0x${Number(80001).toString(16)}`,
    chainName: 'matic',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: [
      'https://polygon-mumbai.g.alchemy.com/v2/i0JIYxK_EGtBX5aGG1apX4KuoH7j_7dq',
    ],
    blockExplorerUrls: ['https://mumbai.polygonscan.com/'],
    logo: Polygon,
    networkId: 80001,
  },
  421611: {
    chainId: `0x${Number(421611).toString(16)}`,
    chainName: 'arbitrum',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://rinkeby.arbitrum.io/rpc'], // needs to be changed before deploying to alchemy
    blockExplorerUrls: ['https://testnet.arbiscan.io/'],
    logo: Arbitrum,
    networkId: 421611,
  },
  421613: {
    chainId: `0x${Number(421613).toString(16)}`,
    chainName: 'arbitrum',
    nativeCurrency: {
      name: 'AGOR',
      symbol: 'AGOR',
      decimals: 18,
    },
    rpcUrls: ['https://goerli-rollup.arbitrum.io/rpc/'],
    blockExplorerUrls: ['https://goerli.arbiscan.io/'],
    logo: Arbitrum,
    networkId: 421613,
  },
  137: {
    chainId: `0x${Number(137).toString(16)}`,
    chainName: 'matic',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: ['https://rpc-mainnet.maticvigil.com/'],
    blockExplorerUrls: ['https://polygonscan.com/'],
    logo: Polygon,
    networkId: 137,
  },
  42161: {
    chainId: `0x${Number(42161).toString(16)}`,
    chainName: 'arbitrum',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://arb1.arbitrum.io/rpc'],
    blockExplorerUrls: ['https://arbiscan.io/'],
    logo: Arbitrum,
    networkId: 42161,
  },
  10000: {
    chainId: `0x${Number(10000).toString(16)}`,
    chainName: 'solana',
    nativeCurrency: {
      name: 'SOLANA',
      symbol: 'SOLANA',
      decimals: 18,
    },
    rpcUrls: ['https://api.mainnet-beta.solana.com/'],
    blockExplorerUrls: ['https://explorer.solana.com/'],
    logo: Solana,
    networkId: 10000,
  },
  10500: {
    chainId: `0x${Number(10500).toString(16)}`,
    chainName: 'solana',
    nativeCurrency: {
      name: 'SOLANA',
      symbol: 'SOLANA',
      decimals: 18,
    },
    rpcUrls: ['https://api.devnet.solana.com/'],
    blockExplorerUrls: ['https://explorer.solana.com/?cluster=devnet'],
    logo: Solana,
    networkId: 10500,
  },
  56: {
    chainId: `0x${Number(56).toString(16)}`,
    chainName: 'bsc',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://rpc.ankr.com/bsc'],
    blockExplorerUrls: ['https://bscscan.com/'],
    logo: BSC,
    networkId: 56,
  },
  97: {
    chainId: `0x${Number(97).toString(16)}`,
    chainName: 'bsc',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://data-seed-prebsc-1-s3.binance.org:8545'],
    blockExplorerUrls: ['https://testnet.bscscan.com/'],
    logo: BSC,
    networkId: 97,
  },
  420: {
    chainId: `0x${Number(420).toString(16)}`,
    chainName: 'optimism',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://goerli.optimism.io/'],
    blockExplorerUrls: ['https://goerli-optimism.etherscan.io/'],
    logo: Optimism,
    networkId: 420,
  },
  10: {
    chainId: `0x${Number(10).toString(16)}`,
    chainName: 'optimism',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://mainnet.optimism.io/'],
    blockExplorerUrls: ['https://optimistic.etherscan.io/'],
    logo: Optimism,
    networkId: 10,
  },
  100: {
    chainId: `0x${Number(100).toString(16)}`,
    chainName: 'gnosis',
    nativeCurrency: {
      name: 'xDAI',
      symbol: 'xDAI',
      decimals: 18,
    },
    rpcUrls: ['https://rpc.ankr.com/gnosis'],
    blockExplorerUrls: ['https://gnosisscan.io/'],
    logo: Gnosis,
    networkId: 100,
  },
  43113: {
    chainId: `0x${Number(43113).toString(16)}`,
    chainName: 'avalanche',
    nativeCurrency: {
      name: 'Avalanche Fuji Testnet',
      symbol: 'AVAX',
      decimals: 18,
    },
    rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://testnet.snowtrace.io/'],
    logo: Avalanche,
    networkId: 43113,
  },
  43114: {
    chainId: `0x${Number(43114).toString(16)}`,
    chainName: 'avalanche',
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18,
    },
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://snowtrace.io/'],
    logo: Avalanche,
    networkId: 43114,
  },
  314159: {
    chainId: `0x${Number(314159).toString(16)}`,
    chainName: 'filecoin',
    nativeCurrency: {
      name: 'Filecoin',
      symbol: 'tFIL',
      decimals: 18,
    },
    rpcUrls: ['https://rpc.ankr.com/filecoin_testnet'],
    blockExplorerUrls: ['https://calibration.filfox.info/en/'],
    logo: Filecoin,
    networkId: 314159,
  },
  314: {
    chainId: `0x${Number(314).toString(16)}`,
    chainName: 'filecoin',
    nativeCurrency: {
      name: 'Filecoin',
      symbol: 'FIL',
      decimals: 18,
    },
    rpcUrls: ['https://rpc.ankr.com/filecoin'],
    blockExplorerUrls: ['https://filfox.info/en/'],
    logo: Filecoin,
    networkId: 314,
  },
  8453: {
    chainId: `0x${Number(8453).toString(16)}`,
    chainName: 'base',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://mainnet.base.org'],
    blockExplorerUrls: ['https://basescan.org//'],
    logo: Base,
    networkId: 8453,
  },
  84531: {
    chainId: `0x${Number(84531).toString(16)}`,
    chainName: 'base',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://goerli.base.org'],
    blockExplorerUrls: ['https://goerli.basescan.org//'],
    logo: Base,
    networkId: 84531,
  },
  5001: {
    chainId: `0x${Number(5001).toString(16)}`,
    chainName: 'mantle',
    nativeCurrency: {
      name: 'MNT',
      symbol: 'MNT',
      decimals: 18,
    },
    rpcUrls: ['https://rpc.testnet.mantle.xyz/'],
    blockExplorerUrls: ['https://explorer.testnet.mantle.xyz/'],
    logo: WmntIcon,
    networkId: 5001,
  },
  5000: {
    chainId: `0x${Number(5000).toString(16)}`,
    chainName: 'mantle',
    nativeCurrency: {
      name: 'MNT',
      symbol: 'MNT',
      decimals: 18,
    },
    rpcUrls: ['https://rpc.mantle.xyz/'],
    blockExplorerUrls: ['https://explorer.mantle.xyz/'],
    logo: WmntIcon,
    networkId: 5000,
  },
  59144: {
    chainId: `0x${Number(59144).toString(16)}`,
    chainName: 'linea',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://rpc.linea.build'],
    blockExplorerUrls: ['https://explorer.goerli.linea.build/'],
    logo: Linea,
    networkId: 59144,
  },
  59140: {
    chainId: `0x${Number(59140).toString(16)}`,
    chainName: 'linea',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://rpc.goerli.linea.build'],
    blockExplorerUrls: ['https://explorer.linea.build/'],
    logo: Linea,
    networkId: 59140,
  },
  324: {
    chainId: `0x${Number(324).toString(16)}`,
    chainName: 'zksync',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://mainnet.era.zksync.io'],
    blockExplorerUrls: ['https://explorer.zksync.io/'],
    logo: ZkSync,
    networkId: 324,
  },
  280: {
    chainId: `0x${Number(280).toString(16)}`,
    chainName: 'zksync',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://testnet.era.zksync.dev'],
    blockExplorerUrls: ['https://goerli.explorer.zksync.io/'],
    logo: ZkSync,
    networkId: 280,
  },
};

export const providedTokens: {
  [id: string]: { [id: string]: Array<ITokenType> };
} = {
  matic: {
    testnet: [
      {
        name: 'Tether USD Test Token',
        symbol: 'USDT',
        decimals: 6,
        address: '0x36fEe18b265FBf21A89AD63ea158F342a7C64abB',
        logoURI: UsdtIcon,
        id: 1,
      },
      {
        name: 'Wrapped Matic Test Token',
        symbol: 'WMATIC',
        decimals: 18,
        address: '0x960d7D3aD51CbFe74CF61a5c882C9020DF50a18d',
        logoURI: MaticIcon,
        id: 2,
      },
      {
        name: 'DAI Test Token',
        symbol: 'DAI',
        decimals: 18,
        address: '0xf0728Bfe68B96Eb241603994de44aBC2412548bE',
        logoURI: DaiIcon,
        id: 3,
      },
      {
        name: 'Wrapped ETH Test Token',
        symbol: 'WETH',
        decimals: 18,
        address: '0xB20Ca4FD8C23B0ff8259EcDF6F3232A589562CdC',
        logoURI: WethIcon,
        id: 4,
      },
    ],
    mainnet: [
      {
        name: 'Tether USD',
        symbol: 'USDT',
        decimals: 6,
        address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
        logoURI: UsdtIcon,
        id: 1,
      },
      {
        name: 'Wrapped Matic',
        symbol: 'WMATIC',
        decimals: 18,
        address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
        logoURI: MaticIcon,
        id: 2,
      },
      {
        name: 'Dai - PoS',
        symbol: 'DAI',
        decimals: 18,
        address: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
        logoURI: DaiIcon,
        id: 3,
      },
      {
        name: 'Wrapped Ether',
        symbol: 'WETH',
        decimals: 18,
        address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
        logoURI: WethIcon,
        id: 4,
      },
    ],
  },
  arbitrum: {
    testnet: [
      {
        name: 'Tether USD Test Token',
        symbol: 'USDT',
        decimals: 6,
        address: '0x1fFC5C93E3037aa387D3A988e9aCF2Cd11C8B782',
        logoURI: UsdtIcon,
        id: 1,
      },
      {
        name: 'TEST Token USD Coin',
        symbol: 'USDC',
        decimals: 6,
        address: '0x01e65Ad8F4824037Ed8b3111A6c232D1b8458Bea',
        logoURI: UsdcIcon,
        id: 2,
      },
      {
        name: 'DAI Test Token',
        symbol: 'DAI',
        decimals: 18,
        address: '0xBB7C4363092fD156a3EE45a7F0F702e14cf237c3',
        logoURI: DaiIcon,
        id: 3,
      },
      {
        name: 'Wrapped ETH Test Token',
        symbol: 'WETH',
        decimals: 18,
        address: '0xB28aA1E5074AE0222cf764e2934182775Ee7AB42',
        logoURI: WethIcon,
        id: 4,
      },
    ],
    mainnet: [
      {
        name: 'Tether USD',
        symbol: 'USDT',
        decimals: 6,
        address: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
        logoURI: UsdtIcon,
        id: 1,
      },
      {
        name: 'USD Coin',
        symbol: 'USDC',
        decimals: 6,
        address: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
        logoURI: UsdcIcon,
        id: 2,
      },
      {
        name: 'Dai Stablecoin',
        symbol: 'DAI',
        decimals: 18,
        address: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
        logoURI: DaiIcon,
        id: 3,
      },
      {
        name: 'Wrapped Ether',
        symbol: 'WETH',
        decimals: 18,
        address: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
        logoURI: WethIcon,
        id: 4,
      },
    ],
  },
  solana: {
    testnet: [
      {
        name: 'Solana',
        symbol: 'SOL',
        decimals: 18,
        address: 'So11111111111111111111111111111111111111112',
        logoURI: Solana,
        id: 1,
      },
    ],
    mainnet: [
      {
        name: 'Solana',
        symbol: 'SOL',
        decimals: 18,
        address: 'So11111111111111111111111111111111111111112',
        logoURI: Solana,
        id: 1,
      },
    ],
  },
  bsc: {
    testnet: [
      {
        name: 'DAI',
        symbol: 'DAI',
        decimals: 18,
        address: '0x0662e85f14d1EB8d55341E1de35834e899fB535E',
        logoURI: DaiIcon,
        id: 1,
      },
      {
        name: 'WBNB',
        symbol: 'WBNB',
        decimals: 18,
        address: '0x45d8a95DcE6b69aAbbBecc94B7541C765d4BA21c',
        logoURI: WnbIcon,
        id: 2,
      },
    ],
    mainnet: [
      {
        name: 'USDT',
        symbol: 'USDT',
        decimals: 18,
        address: '0x55d398326f99059fF775485246999027B3197955',
        logoURI: UsdtIcon,
        id: 1,
      },
      {
        name: 'USDC',
        symbol: 'USDC',
        decimals: 18,
        address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
        logoURI: UsdcIcon,
        id: 2,
      },
      {
        name: 'DAI',
        symbol: 'DAI',
        decimals: 18,
        address: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
        logoURI: DaiIcon,
        id: 3,
      },
      {
        name: 'WBNB',
        symbol: 'WBNB',
        decimals: 18,
        address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
        logoURI: WnbIcon,
        id: 4,
      },
    ],
  },
  optimism: {
    testnet: [
      {
        name: 'USDT',
        symbol: 'USDT',
        decimals: 6,
        address: '0xcF5F08686dBE94B88b93FB2E8E5482A7f432C892',
        logoURI: UsdtIcon,
        id: 1,
      },
      {
        name: 'USDC',
        symbol: 'USDC',
        decimals: 6,
        address: '0xB52329A11333462D192110357Be2da470B79B13e',
        logoURI: UsdcIcon,
        id: 2,
      },
      {
        name: 'DAI',
        symbol: 'DAI',
        decimals: 18,
        address: '0x87D3440372293aCf9149552546F7141AAe05Be91',
        logoURI: DaiIcon,
        id: 3,
      },
      {
        name: 'WETH',
        symbol: 'WETH',
        decimals: 18,
        address: '0x7fDE3748e8AE27CaF506A47601acAf3B6AEaC34f',
        logoURI: WethIcon,
        id: 4,
      },
    ],
    mainnet: [
      {
        name: 'USDT',
        symbol: 'USDT',
        decimals: 6,
        address: '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
        logoURI: UsdtIcon,
        id: 1,
      },
      {
        name: 'USDC',
        symbol: 'USDC',
        decimals: 6,
        address: '0x0b2c639c533813f4aa9d7837caf62653d097ff85',
        logoURI: UsdcIcon,
        id: 2,
      },
      {
        name: 'DAI',
        symbol: 'DAI',
        decimals: 18,
        address: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
        logoURI: DaiIcon,
        id: 3,
      },
      {
        name: 'WETH',
        symbol: 'WETH',
        decimals: 18,
        address: '0x4200000000000000000000000000000000000006',
        logoURI: WethIcon,
        id: 4,
      },
    ],
  },
  gnosis: {
    testnet: [
      {
        name: 'USDT',
        symbol: 'USDT',
        decimals: 6,
        address: '0x4ECaBa5870353805a9F068101A40E0f32ed605C6',
        logoURI: UsdtIcon,
        id: 1,
      },
      {
        name: 'USDC',
        symbol: 'USDC',
        decimals: 6,
        address: '0xDDAfbb505ad214D7b80b1f830fcCc89B60fb7A83',
        logoURI: UsdcIcon,
        id: 2,
      },
      {
        name: 'DAI',
        symbol: 'DAI',
        decimals: 18,
        address: '0x44fA8E6f47987339850636F88629646662444217',
        logoURI: DaiIcon,
        id: 3,
      },
      {
        name: 'WXDAI',
        symbol: 'WXDAI',
        decimals: 18,
        address: '0xe91D153E0b41518A2Ce8Dd3D7944Fa863463a97d',
        logoURI: WxdaiIcon,
        id: 4,
      },
    ],
    mainnet: [
      {
        name: 'USDT',
        symbol: 'USDT',
        decimals: 6,
        address: '0x4ECaBa5870353805a9F068101A40E0f32ed605C6',
        logoURI: UsdtIcon,
        id: 1,
      },
      {
        name: 'USDC',
        symbol: 'USDC',
        decimals: 6,
        address: '0xDDAfbb505ad214D7b80b1f830fcCc89B60fb7A83',
        logoURI: UsdcIcon,
        id: 2,
      },
      {
        name: 'DAI',
        symbol: 'DAI',
        decimals: 18,
        address: '0x44fA8E6f47987339850636F88629646662444217',
        logoURI: DaiIcon,
        id: 3,
      },
      {
        name: 'WXDAI',
        symbol: 'WXDAI',
        decimals: 18,
        address: '0xe91D153E0b41518A2Ce8Dd3D7944Fa863463a97d',
        logoURI: WxdaiIcon,
        id: 4,
      },
    ],
  },
  avalanche: {
    testnet: [
      {
        name: 'USDT',
        symbol: 'USDT',
        decimals: 6,
        address: '0x76e593392523243ACD38ceD87c2007F14483a86B',
        logoURI: UsdtIcon,
        id: 1,
      },
      {
        name: 'USDC',
        symbol: 'USDC',
        decimals: 6,
        address: '0xd74C08f8ffDF88C807367813Ad64a960618f4dcC',
        logoURI: UsdcIcon,
        id: 2,
      },
      {
        name: 'DAI',
        symbol: 'DAI',
        decimals: 18,
        address: '0x032666197A5d9329e717800FC90E8C951bA12290',
        logoURI: DaiIcon,
        id: 3,
      },
      {
        name: 'WAVAX',
        symbol: 'WAVAX',
        decimals: 18,
        address: '0x816413630c39780677E1584D4a6DbAFd76b161F7',
        logoURI: WavaxIcon,
        id: 4,
      },
    ],
    mainnet: [
      {
        name: 'USDT',
        symbol: 'USDT',
        decimals: 6,
        address: '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
        logoURI: UsdtIcon,
        id: 1,
      },
      {
        name: 'USDC',
        symbol: 'USDC',
        decimals: 6,
        address: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
        logoURI: UsdcIcon,
        id: 2,
      },
      {
        name: 'DAI',
        symbol: 'DAI',
        decimals: 18,
        address: '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70',
        logoURI: DaiIcon,
        id: 3,
      },
      {
        name: 'WAVAX',
        symbol: 'WAVAX',
        decimals: 18,
        address: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
        logoURI: WavaxIcon,
        id: 4,
      },
    ],
  },
  filecoin: {
    testnet: [
      {
        name: 'tFIL',
        symbol: 'tFIL',
        decimals: 18,
        address: '0x5FEAa6344DbFE38aC9838E48C2f9E4ce8b7Efe8a',
        logoURI: FilIcon,
        id: 1,
      },
    ],
    mainnet: [
      {
        name: 'FIL',
        symbol: 'FIL',
        decimals: 18,
        address: '0x4F9337B261136E8c06ed4F543e28AbeEe08D889E',
        logoURI: FilIcon,
        id: 1,
      },
    ],
  },
  base: {
    testnet: [
      {
        name: 'Wrapped Ether',
        symbol: 'WETH',
        decimals: 18,
        address: '0xB52329A11333462D192110357Be2da470B79B13e',
        logoURI: WethIcon,
        id: 1,
      },
    ],
    mainnet: [
      {
        name: 'Wrapped Ether',
        symbol: 'WETH',
        decimals: 18,
        address: '0x4200000000000000000000000000000000000006',
        logoURI: WethIcon,
        id: 1,
      },
      {
        name: 'USD Base Coin',
        symbol: 'USDbC',
        decimals: 6,
        address: '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA',
        logoURI: UsdcIcon,
        id: 2,
      },
      {
        name: 'Dai Stablecoin',
        symbol: 'DAI',
        decimals: 18,
        address: '0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb',
        logoURI: DaiIcon,
        id: 3,
      },
    ],
  },
  mantle: {
    testnet: [
      {
        name: 'Wrapped MNT',
        symbol: 'WMNT',
        decimals: 18,
        address: '0xA440ca6123C6d472F85c1f3930395664BCcd3d01',
        logoURI: WmntIcon,
        id: 1,
      },
    ],
    mainnet: [
      {
        name: 'Tether USD',
        symbol: 'USDT',
        decimals: 6,
        address: '0x201EBa5CC46D216Ce6DC03F6a759e8E766e956aE',
        logoURI: UsdtIcon,
        id: 1,
      },
      {
        name: 'USD Coin',
        symbol: 'USDC',
        decimals: 6,
        address: '0x09Bc4E0D864854c6aFB6eB9A9cdF58aC190D0dF9',
        logoURI: UsdcIcon,
        id: 2,
      },
      {
        name: 'Wrapped MNT',
        symbol: 'WMNT',
        decimals: 18,
        address: '0x78c1b0C915c4FAA5FffA6CAbf0219DA63d7f4cb8',
        logoURI: WmntIcon,
        id: 4,
      },
    ],
  },
  linea: {
    testnet: [
      {
        name: 'Wrapped Ether',
        symbol: 'WETH',
        decimals: 18,
        address: '0xcF5F08686dBE94B88b93FB2E8E5482A7f432C892',
        logoURI: WethIcon,
        id: 1,
      },
    ],
    mainnet: [
      {
        name: 'Wrapped Ether',
        symbol: 'WETH',
        decimals: 18,
        address: '0xe5D7C2a44FfDDf6b295A15c148167daaAf5Cf34f',
        logoURI: WethIcon,
        id: 1,
      },
      {
        name: 'USD Coin',
        symbol: 'USDC',
        decimals: 6,
        address: '0x176211869cA2b568f2A7D4EE941E073a821EE1ff',
        logoURI: UsdcIcon,
        id: 2,
      },
      {
        name: 'Dai Stablecoin',
        symbol: 'DAI',
        decimals: 18,
        address: '0x4AF15ec2A0BD43Db75dd04E62FAA3B8EF36b00d5',
        logoURI: DaiIcon,
        id: 3,
      },
      {
        name: 'USDT Stablecoin',
        symbol: 'USDT',
        decimals: 6,
        address: '0xA219439258ca9da29E9Cc4cE5596924745e12B93',
        logoURI: DaiIcon,
        id: 4,
      },
    ],
  },
  zksync: {
    testnet: [
      {
        name: 'DAI',
        symbol: 'DAI',
        decimals: 18,
        address: '0x3e7676937A7E96CFB7616f255b9AD9FF47363D4b',
        logoURI: DaiIcon,
        id: 1,
      },
      {
        name: 'USDC',
        symbol: 'USDC',
        decimals: 6,
        address: '0x0faF6df7054946141266420b43783387A78d82A9',
        logoURI: UsdcIcon,
        id: 2,
      },
    ],
    mainnet: [
      {
        name: 'USDT',
        symbol: 'USDT',
        decimals: 6,
        address: '0x493257fD37EDB34451f62EDf8D2a0C418852bA4C',
        logoURI: UsdtIcon,
        id: 1,
      },
      {
        name: 'USDC',
        symbol: 'USDC',
        decimals: 6,
        address: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
        logoURI: UsdcIcon,
        id: 2,
      },
      {
        name: 'Dai Stablecoin',
        symbol: 'DAI',
        decimals: 18,
        address: '0x4B9eb6c0b6ea15176BBF62841C6B2A8a398cb656',
        logoURI: DaiIcon,
        id: 3,
      },
    ],
  },
};

export const wallets = [
  { name: 'Metamask', image: Metamask, id: 1 },
  { name: 'Phantom', image: Phantom, id: 2 },
];

export const getNetworkConfig = (network: number | string) => {
  switch (network) {
    case 80001:
    case 137:
    case 'matic':
      return config.web3.matic;
    case 421611:
    case 42161:
    case 421613:
    case 'arbitrum':
      return config.web3.arbitrum;
    case 10000:
    case 10500:
    case 'solana':
      return config.web3.solana;
    case 56:
    case 97:
    case 'bsc':
      return config.web3.bsc;
    case 420:
    case 10:
    case 'optimism':
      return config.web3.optimism;
    case 100:
    case 'gnosis':
      return config.web3.gnosis;
    case 43113:
    case 43114:
    case 'avalanche':
      return config.web3.avalanche;
    case 314159:
    case 314:
    case 'filecoin':
      return config.web3.filecoin;
    case 5001:
    case 5000:
    case 'mantle':
      return config.web3.mantle;
    case 84531:
    case 8453:
    case 'base':
      return config.web3.base;
    case 59140:
    case 59144:
    case 'linea':
      return config.web3.linea;
    case 324:
    case 280:
    case 'zksync':
      return config.web3.zksync;
    default:
      return config.web3.matic;
  }
};

export const mapNetworkToWallet = (chainId: number): INetworkType | null => {
  let selectedNetwork = null;
  Object.keys(networks).forEach((network: any) => {
    if (networks[network].networkId === chainId) {
      selectedNetwork = networks[network];
    }
  });
  return selectedNetwork;
};

export const mapTokenToWallet = (network: string, tokenSymbol: string) => {
  let selectedToken = null;
  providedTokens[network || 'matic'][
    getNetworkConfig(network || 'matic').TYPE
  ].forEach((token: ITokenType) => {
    if (token.symbol === tokenSymbol) {
      selectedToken = token;
    }
  });
  return selectedToken;
};

export const getNetworkFromName = (network: number): string => {
  switch (network) {
    case 80001:
      return 'Polygon Mumbai';
    case 421611:
    case 421613:
      return 'Arbitrum Testnet';
    case 137:
      return 'Polygon Mainnet';
    case 42161:
      return 'Arbitrum One';
    case 10000:
    case 10500:
      return 'Solana';
    case 56:
      return 'BSC Mainnet';
    case 97:
      return 'BSC Testnet';
    case 420:
      return 'Optimism Testnet';
    case 10:
      return 'Optimism Mainnet';
    case 100:
      return 'Gnosis Mainnet';
    case 43113:
      return 'Avalanche Testnet';
    case 43114:
      return 'Avalanche Mainnet';
    case 314159:
      return 'Filecoin Testnet';
    case 314:
      return 'Filecoin Mainnet';
    case 8453:
      return 'Base Mainnet';
    case 84531:
      return 'Base Goerli';
    case 5001:
      return 'Mantle Testnet';
    case 5000:
      return 'Mantle Mainnet';
    case 59144:
      return 'Linea Mainnet';
    case 59140:
      return 'Linea Goerli';
    case 324:
      return 'ZkSync Mainnet';
    case 280:
      return 'ZkSync Goerli';
    default:
      return 'Polygon Mumbai';
  }
};

export const getTokenNameFromAddress = (tokenAddress: string): string => {
  if (tokenAddress) {
    const tokens = new Map<string, string>();

    // Polygon Mumbai
    tokens.set(
      '0x36fEe18b265FBf21A89AD63ea158F342a7C64abB'.toLowerCase(),
      'USDT'
    );
    tokens.set(
      '0x960d7D3aD51CbFe74CF61a5c882C9020DF50a18d'.toLowerCase(),
      'WMATIC'
    );
    tokens.set(
      '0xB20Ca4FD8C23B0ff8259EcDF6F3232A589562CdC'.toLowerCase(),
      'WETH'
    );
    tokens.set(
      '0xf0728Bfe68B96Eb241603994de44aBC2412548bE'.toLowerCase(),
      'DAI'
    );

    // Arbitrum Goerli
    tokens.set(
      '0x1fFC5C93E3037aa387D3A988e9aCF2Cd11C8B782'.toLowerCase(),
      'USDT'
    );
    tokens.set(
      '0x01e65Ad8F4824037Ed8b3111A6c232D1b8458Bea'.toLowerCase(),
      'USDC'
    );
    tokens.set(
      '0xB28aA1E5074AE0222cf764e2934182775Ee7AB42'.toLowerCase(),
      'WETH'
    );
    tokens.set(
      '0xBB7C4363092fD156a3EE45a7F0F702e14cf237c3'.toLowerCase(),
      'DAI'
    );

    // ARBITRUM Rinkeby
    tokens.set(
      '0xcF5F08686dBE94B88b93FB2E8E5482A7f432C892'.toLowerCase(),
      'USDT'
    );
    tokens.set(
      '0xd74c08f8ffdf88c807367813ad64a960618f4dcc'.toLowerCase(),
      'USDC'
    );
    tokens.set(
      '0x96Ca5955F25648FB44C5D772BAdB4313000C66Fe'.toLowerCase(),
      'WETH'
    );
    tokens.set('0x816413630c39780677e1584d4a6dbafd76b161f7', 'DAI');

    // MAINNET MATIC
    tokens.set(
      '0xc2132D05D31c914a87C6611C10748AEb04B58e8F'.toLowerCase(),
      'USDT'
    );
    tokens.set(
      '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270'.toLowerCase(),
      'WMATIC'
    );
    tokens.set(
      '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063'.toLowerCase(),
      'DAI'
    );
    tokens.set(
      '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619'.toLowerCase(),
      'WETH'
    );

    // ARBITRUM MAINNET
    tokens.set(
      '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9'.toLowerCase(),
      'USDT'
    );
    tokens.set(
      '0xaf88d065e77c8cC2239327C5EDb3A432268e5831'.toLowerCase(),
      'USDC'
    );
    tokens.set(
      '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1'.toLowerCase(),
      'DAI'
    );
    tokens.set(
      '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1'.toLowerCase(),
      'WETH'
    );

    // SOLANA
    tokens.set(
      'So11111111111111111111111111111111111111112'.toLowerCase(),
      'SOL'
    );

    // OPTIMISM Goerli
    tokens.set(
      '0xcF5F08686dBE94B88b93FB2E8E5482A7f432C892'.toLowerCase(),
      'USDT'
    );
    tokens.set(
      '0xB52329A11333462D192110357Be2da470B79B13e'.toLowerCase(),
      'USDC'
    );
    tokens.set(
      '0x87D3440372293aCf9149552546F7141AAe05Be91'.toLowerCase(),
      'DAI'
    );
    tokens.set(
      '0x7fDE3748e8AE27CaF506A47601acAf3B6AEaC34f'.toLowerCase(),
      'WETH'
    );
    // Avalanche Fuji Testnet
    tokens.set(
      '0x76e593392523243ACD38ceD87c2007F14483a86B'.toLowerCase(),
      'USDT'
    );
    tokens.set(
      '0xd74C08f8ffDF88C807367813Ad64a960618f4dcC'.toLowerCase(),
      'USDC'
    );
    tokens.set(
      '0x032666197A5d9329e717800FC90E8C951bA12290'.toLowerCase(),
      'DAI'
    );
    tokens.set(
      '0x816413630c39780677E1584D4a6DbAFd76b161F7'.toLowerCase(),
      'WAVAX'
    );

    // OPTIMISM MAINNET

    tokens.set(
      '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58'.toLowerCase(),
      'USDT'
    );
    tokens.set(
      '0x0b2c639c533813f4aa9d7837caf62653d097ff85'.toLowerCase(),
      'USDC'
    );
    tokens.set(
      '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1'.toLowerCase(),
      'DAI'
    );
    // BASE MAINNET & OPTIMISM MAINNET
    tokens.set(
      '0x4200000000000000000000000000000000000006'.toLowerCase(),
      'WETH'
    );
    // AVALANCHE MAINNET

    tokens.set(
      '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7'.toLowerCase(),
      'USDT'
    );
    tokens.set(
      '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E'.toLowerCase(),
      'USDC'
    );
    tokens.set(
      '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70'.toLowerCase(),
      'DAI'
    );
    tokens.set(
      '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7'.toLowerCase(),
      'WAVAX'
    );
    tokens.set('', '');

    // BSC TESTNET
    tokens.set(
      '0x0662e85f14d1EB8d55341E1de35834e899fB535E'.toLowerCase(),
      'DAI'
    );

    tokens.set(
      '0x45d8a95DcE6b69aAbbBecc94B7541C765d4BA21c'.toLowerCase(),
      'WBNB'
    );

    // BSC MAINNET
    tokens.set(
      '0x55d398326f99059fF775485246999027B3197955'.toLowerCase(),
      'USDT'
    );
    tokens.set(
      '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d'.toLowerCase(),
      'USDC'
    );
    tokens.set(
      '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3'.toLowerCase(),
      'DAI'
    );
    tokens.set(
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'.toLowerCase(),
      'WBNB'
    );

    // Gnosis MAINNET
    tokens.set(
      '0x4ECaBa5870353805a9F068101A40E0f32ed605C6'.toLowerCase(),
      'USDT'
    );
    tokens.set(
      '0xDDAfbb505ad214D7b80b1f830fcCc89B60fb7A83'.toLowerCase(),
      'USDC'
    );
    tokens.set(
      '0x44fA8E6f47987339850636F88629646662444217'.toLowerCase(),
      'DAI'
    );
    tokens.set(
      '0xe91D153E0b41518A2Ce8Dd3D7944Fa863463a97d'.toLowerCase(),
      'WXDAI'
    );

    // Filecoin Testnet
    tokens.set(
      '0x5FEAa6344DbFE38aC9838E48C2f9E4ce8b7Efe8a'.toLowerCase(),
      'tFIL'
    );
    // Filecoin Mainnet
    tokens.set(
      '0x4F9337B261136E8c06ed4F543e28AbeEe08D889E'.toLowerCase(),
      'FIL'
    );
    // Base Testnet
    tokens.set(
      '0xB52329A11333462D192110357Be2da470B79B13e'.toLowerCase(),
      'WETH'
    );
    // Base Mainnet
    tokens.set(
      '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA'.toLowerCase(),
      'USDbC'
    );

    tokens.set(
      '0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb'.toLowerCase(),
      'DAI'
    );
    // Mantle Testnet
    tokens.set(
      '0xA440ca6123C6d472F85c1f3930395664BCcd3d01'.toLowerCase(),
      'WMNT'
    );

    tokens.set(
      '0xA440ca6123C6d472F85c1f3930395664BCcd3d01'.toLowerCase(),
      'WMNT'
    );

    // Mantle Mainnet
    tokens.set(
      '0x201EBa5CC46D216Ce6DC03F6a759e8E766e956aE'.toLowerCase(),
      'USDT'
    );
    tokens.set(
      '0x09Bc4E0D864854c6aFB6eB9A9cdF58aC190D0dF9'.toLowerCase(),
      'USDC'
    );
    // linea Testnet
    tokens.set(
      '0xcF5F08686dBE94B88b93FB2E8E5482A7f432C892'.toLowerCase(),
      'WETH'
    );
    // Linea Mainnet
    tokens.set(
      '0xe5D7C2a44FfDDf6b295A15c148167daaAf5Cf34f'.toLowerCase(),
      'WETH'
    );
    tokens.set(
      '0x176211869cA2b568f2A7D4EE941E073a821EE1ff'.toLowerCase(),
      'USDC'
    );
    tokens.set(
      '0x4AF15ec2A0BD43Db75dd04E62FAA3B8EF36b00d5'.toLowerCase(),
      'DAI'
    );
    tokens.set(
      '0xA219439258ca9da29E9Cc4cE5596924745e12B93'.toLowerCase(),
      'USDT'
    );
    // ZkSync Mainnet
    tokens.set(
      '0x493257fD37EDB34451f62EDf8D2a0C418852bA4C'.toLowerCase(),
      'USDT'
    );
    tokens.set(
      '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4'.toLowerCase(),
      'USDC'
    );
    tokens.set(
      '0x4B9eb6c0b6ea15176BBF62841C6B2A8a398cb656'.toLowerCase(),
      'DAI'
    );
    // ZkSync Testnet
    tokens.set(
      '0x0faF6df7054946141266420b43783387A78d82A9'.toLowerCase(),
      'USDC'
    );
    tokens.set(
      '0x3e7676937A7E96CFB7616f255b9AD9FF47363D4b'.toLowerCase(),
      'DAI'
    );
    return String(tokens.get(tokenAddress.toLowerCase()) || '');
  }
  return 'NA';
};

export const getDecimalFromTokenAddress = (tokenAddress: string): number => {
  const decimalSet = new Map<string, number>();
  // Polygon Mumbai
  decimalSet.set('0x36fEe18b265FBf21A89AD63ea158F342a7C64abB'.toLowerCase(), 6);
  decimalSet.set(
    '0x960d7D3aD51CbFe74CF61a5c882C9020DF50a18d'.toLowerCase(),
    18
  );
  decimalSet.set(
    '0xB20Ca4FD8C23B0ff8259EcDF6F3232A589562CdC'.toLowerCase(),
    18
  );
  decimalSet.set(
    '0xf0728Bfe68B96Eb241603994de44aBC2412548bE'.toLowerCase(),
    18
  );

  // Arbitrum Goerli
  decimalSet.set('0x1fFC5C93E3037aa387D3A988e9aCF2Cd11C8B782'.toLowerCase(), 6);
  decimalSet.set('0x01e65Ad8F4824037Ed8b3111A6c232D1b8458Bea'.toLowerCase(), 6);
  decimalSet.set(
    '0xB28aA1E5074AE0222cf764e2934182775Ee7AB42'.toLowerCase(),
    18
  );
  decimalSet.set(
    '0xBB7C4363092fD156a3EE45a7F0F702e14cf237c3'.toLowerCase(),
    18
  );

  // Arbitrum Rinkeby
  decimalSet.set('0xcF5F08686dBE94B88b93FB2E8E5482A7f432C892'.toLowerCase(), 6);
  decimalSet.set('0xd74c08f8ffdf88c807367813ad64a960618f4dcc'.toLowerCase(), 6);
  decimalSet.set(
    '0x96Ca5955F25648FB44C5D772BAdB4313000C66Fe'.toLowerCase(),
    18
  );
  decimalSet.set('0x816413630c39780677e1584d4a6dbafd76b161f7', 18);

  // POLYGON Mainnet
  decimalSet.set('0xc2132D05D31c914a87C6611C10748AEb04B58e8F'.toLowerCase(), 6);
  decimalSet.set(
    '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270'.toLowerCase(),
    18
  );
  decimalSet.set(
    '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063'.toLowerCase(),
    18
  );
  decimalSet.set(
    '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619'.toLowerCase(),
    18
  );

  //  ARBITRUM Mainnet
  decimalSet.set('0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9'.toLowerCase(), 6);
  decimalSet.set('0xaf88d065e77c8cC2239327C5EDb3A432268e5831'.toLowerCase(), 6);
  decimalSet.set(
    '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1'.toLowerCase(),
    18
  );
  decimalSet.set(
    '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1'.toLowerCase(),
    18
  );

  // SOLANA

  decimalSet.set(
    'So11111111111111111111111111111111111111112'.toLowerCase(),
    18
  );

  // Optimism Goerli
  decimalSet.set('0xcF5F08686dBE94B88b93FB2E8E5482A7f432C892'.toLowerCase(), 6);
  decimalSet.set('0xB52329A11333462D192110357Be2da470B79B13e'.toLowerCase(), 6);
  decimalSet.set(
    '0x87D3440372293aCf9149552546F7141AAe05Be91'.toLowerCase(),
    18
  );
  decimalSet.set(
    '0x7fDE3748e8AE27CaF506A47601acAf3B6AEaC34f'.toLowerCase(),
    18
  );
  // Avalanche Fuji Testnet
  decimalSet.set('0x76e593392523243ACD38ceD87c2007F14483a86B'.toLowerCase(), 6);
  decimalSet.set('0xd74C08f8ffDF88C807367813Ad64a960618f4dcC'.toLowerCase(), 6);
  decimalSet.set(
    '0x032666197A5d9329e717800FC90E8C951bA12290'.toLowerCase(),
    18
  );
  decimalSet.set(
    '0x816413630c39780677E1584D4a6DbAFd76b161F7'.toLowerCase(),
    18
  );

  // Optimism Mainnet

  decimalSet.set('0x94b008aA00579c1307B0EF2c499aD98a8ce58e58'.toLowerCase(), 6);
  decimalSet.set('0x0b2c639c533813f4aa9d7837caf62653d097ff85'.toLowerCase(), 6);
  decimalSet.set(
    '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1'.toLowerCase(),
    18
  );
  decimalSet.set(
    '0x4200000000000000000000000000000000000006'.toLowerCase(),
    18
  );
  // Avalanche Mainnet

  decimalSet.set('0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7'.toLowerCase(), 6);
  decimalSet.set('0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E'.toLowerCase(), 6);
  decimalSet.set(
    '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70'.toLowerCase(),
    18
  );
  decimalSet.set(
    '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7'.toLowerCase(),
    18
  );
  decimalSet.set('', 1);

  // BSC Testnet
  decimalSet.set(
    '0x0662e85f14d1EB8d55341E1de35834e899fB535E'.toLowerCase(),
    18
  );
  decimalSet.set(
    '0x45d8a95DcE6b69aAbbBecc94B7541C765d4BA21c'.toLowerCase(),
    18
  );

  // BSC MAINNET
  decimalSet.set(
    '0x55d398326f99059fF775485246999027B3197955'.toLowerCase(),
    18
  );
  decimalSet.set(
    '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d'.toLowerCase(),
    18
  );
  decimalSet.set(
    '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3'.toLowerCase(),
    18
  );
  decimalSet.set(
    '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'.toLowerCase(),
    18
  );

  // Gnosis MAINNET
  decimalSet.set(
    '0x44fA8E6f47987339850636F88629646662444217'.toLowerCase(),
    18
  );
  decimalSet.set('0x4ECaBa5870353805a9F068101A40E0f32ed605C6'.toLowerCase(), 6);
  decimalSet.set('0xDDAfbb505ad214D7b80b1f830fcCc89B60fb7A83'.toLowerCase(), 6);
  decimalSet.set(
    '0xe91D153E0b41518A2Ce8Dd3D7944Fa863463a97d'.toLowerCase(),
    18
  );
  decimalSet.set(
    '0x5FEAa6344DbFE38aC9838E48C2f9E4ce8b7Efe8a'.toLowerCase(),
    18
  );
  decimalSet.set(
    '0x4F9337B261136E8c06ed4F543e28AbeEe08D889E'.toLowerCase(),
    18
  );
  // Base Testnet
  decimalSet.set(
    '0xB52329A11333462D192110357Be2da470B79B13e'.toLowerCase(),
    18
  );
  // Base Mainnet
  decimalSet.set(
    '0x4200000000000000000000000000000000000006'.toLowerCase(),
    18
  );
  decimalSet.set(
    '0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb'.toLowerCase(),
    18
  );
  decimalSet.set('0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA'.toLowerCase(), 6);
  // Mantle Testnet
  decimalSet.set(
    '0xA440ca6123C6d472F85c1f3930395664BCcd3d01'.toLowerCase(),
    18
  );
  // Mantle Mainnet
  decimalSet.set(
    '0x78c1b0C915c4FAA5FffA6CAbf0219DA63d7f4cb8'.toLowerCase(),
    18
  );
  decimalSet.set('0x201EBa5CC46D216Ce6DC03F6a759e8E766e956aE'.toLowerCase(), 6);
  decimalSet.set('0x09Bc4E0D864854c6aFB6eB9A9cdF58aC190D0dF9'.toLowerCase(), 6);
  // Linea Testnet
  decimalSet.set(
    '0xcF5F08686dBE94B88b93FB2E8E5482A7f432C892'.toLowerCase(),
    18
  );
  // Linea Mainnet
  decimalSet.set(
    '0xe5D7C2a44FfDDf6b295A15c148167daaAf5Cf34f'.toLowerCase(),
    18
  );
  decimalSet.set(
    '0x4AF15ec2A0BD43Db75dd04E62FAA3B8EF36b00d5'.toLowerCase(),
    18
  );
  decimalSet.set('0x176211869cA2b568f2A7D4EE941E073a821EE1ff'.toLowerCase(), 6);
  decimalSet.set('0xA219439258ca9da29E9Cc4cE5596924745e12B93'.toLowerCase(), 6);
  // ZkSync Mainnet
  decimalSet.set(
    '0x4B9eb6c0b6ea15176BBF62841C6B2A8a398cb656'.toLowerCase(),
    18
  );
  decimalSet.set('0x493257fD37EDB34451f62EDf8D2a0C418852bA4C'.toLowerCase(), 6);
  decimalSet.set('0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4'.toLowerCase(), 6);
  // ZkSync Testnet
  decimalSet.set(
    '0x3e7676937A7E96CFB7616f255b9AD9FF47363D4b'.toLowerCase(),
    18
  );
  decimalSet.set('0x0faF6df7054946141266420b43783387A78d82A9'.toLowerCase(), 6);

  return decimalSet.get(tokenAddress.toLowerCase()) || 18;
};

export const getNetworkIcon = (network: number): string => {
  switch (network) {
    case 80001:
    case 137:
      return Polygon;
    case 421611:
    case 421613:
    case 42161:
      return Arbitrum;
    case 10000:
    case 10500:
      return Solana;
    case 56:
    case 97:
      return BSC;
    case 420:
    case 10:
      return Optimism;
    case 100:
      return Gnosis;
    case 43113:
    case 43114:
      return Avalanche;
    case 3134:
    case 314:
      return Filecoin;
    case 84531:
    case 8453:
      return Base;
    case 5001:
    case 5000:
      return Mantle;
    case 59140:
    case 59144:
      return Linea;
    case 324:
    case 280:
      return ZkSync;
    default:
      return Polygon;
  }
};

export const getBlockExplorerIcon = (network: number): string => {
  switch (network) {
    case 80001:
    case 137:
      return Polygon;
    case 421611:
    case 421613:
    case 42161:
      return Arbitrum;
    case 10000:
    case 10500:
      return Solana;
    case 56:
    case 97:
      return BSC;
    case 420:
    case 10:
      return Optimism;
    case 100:
      return Gnosis;
    case 43113:
    case 43114:
      return Avalanche;
    case 314159:
    case 314:
      return Filecoin;
    case 84531:
    case 8453:
      return Base;
    case 5001:
    case 5000:
      return Mantle;
    case 59140:
    case 59144:
      return Linea;
    case 324:
    case 280:
      return ZkSync;
    default:
      return Etherscan;
  }
};

export const getBlockExplorerName = (network: number) => {
  switch (network) {
    case 80001:
    case 137:
      return 'Polygonscan';
    case 421611:
    case 421613:
    case 42161:
      return 'Arbiscan';
    case 56:
    case 97:
      return 'BSCscan';
    case 420:
    case 10:
      return 'Optimism';
    case 100:
      return 'Gnosis';
    case 43113:
    case 43114:
      return 'Snowtrace';
    case 314159:
    case 314:
      return 'Filfox';
    case 324:
    case 280:
      return 'Block Explorer';
    default:
      return 'Etherscan';
  }
};

export const getNetworknameForSetup = (chainId: number) => {
  switch (chainId) {
    case 80001:
      return 'matic';
    case 137:
      return 'Polygon Mainnet';
    case 421611:
      return 'arbitrum';
    case 10000:
    case 10500:
      return 'solana';
    case 421613:
      return 'Arbitrum Goerli';
    case 42161:
      return 'Arbitrum One';
    case 56:
      return 'BSC Mainnet';
    case 97:
      return 'BSC Testnet';
    case 420:
      return 'Optimism Testnet';
    case 10:
      return 'Optimism Mainnet';
    case 100:
      return 'Gnosis Mainnet';
    case 43113:
      return 'Avalanche Testnet';
    case 43114:
      return 'Avalanche Mainnet';
    case 314159:
      return 'Filecoin Testnet';
    case 314:
      return 'Filecoin Mainnet';
    case 84531:
      return 'Base Goerli';
    case 8453:
      return 'Base Mainnet';
    case 5001:
      return 'Mantle Testnet';
    case 5000:
      return 'Mantle Mainnet';
    case 59140:
      return 'Linea Goerli';
    case 59144:
      return 'Linea Mainnet';
    case 280:
      return 'ZkSync Goerli';
    case 324:
      return 'ZkSync Mainnet';
    default:
      return 'matic';
  }
};

export const formatAmount = (token: string, amount: string) => {
  const formatedAmpount = amount;
  const network = getTokenNameFromAddress(token);
  if (network === 'WMATIC') {
    return `${formatedAmpount.substring(0, formatedAmpount.length - 18)}`;
  }
  return `${formatedAmpount.substring(0, formatedAmpount.length - 6)}`;
};

export const getHash = (startFrom: number, endAt: number, text: string) => {
  return text.substring(startFrom, endAt);
};

export const getDate = (bigInt: string) => {
  const d = new Date(0);
  d.setUTCSeconds(Number(bigInt));
  return new Date(d).toLocaleDateString('en-us', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const shortenTxHash = (
  startFrom: number,
  endAt: number,
  text: string
) => {
  const start = getHash(startFrom, endAt, text).substring(0, 5);
  const end = getHash(startFrom, endAt, text).substring(
    getHash(startFrom, endAt, text).length - 5
  );
  return `${start}...${end}`;
};
export const shortenString = (text: string) => {
  const start = text.substring(0, 5);
  const end = text.substring(text.length - 5);
  return `${start}...${end}`;
};

export const shortenAddress = (address: string) => {
  return address === ''
    ? ''
    : `${address.substring(0, 8)}...${address.substring(address.length - 8)}`;
};

export const shortenAddressSmall = (address: string) => {
  return address === ''
    ? ''
    : `${address.substring(0, 6)}...${address.substring(address.length - 4)}`;
};

export const truncateBig = (content: string) => {
  return content.length > 35 ? `${content.substring(0, 32)}...` : content;
};

export const truncateSmall = (content: string) => {
  return content.length > 43 ? `${content.substring(0, 40)}...` : content;
};

export const getTokenFromSymbol = (network: string, tokenSymbol: string) => {
  let selectedToken = null;
  providedTokens[network || 'matic'][
    getNetworkConfig(network || 'matic').TYPE
  ].forEach((token: ITokenType) => {
    if (token.symbol === tokenSymbol) {
      selectedToken = token;
    }
  });
  return (selectedToken as unknown as ITokenType)?.logoURI || '';
};

export const isNonSubgraphChain = (wallet: IOrganisationWallet): boolean =>
  wallet.details.networkDetails?.chainName === NonSubgraphsChainName.SOLANA ||
  wallet.details.networkDetails?.chainName === NonSubgraphsChainName.FILECOIN ||
  wallet.details.networkDetails?.chainName === NonSubgraphsChainName.MANTLE;

export const isNonSubgraphChainId = (chainId: number): boolean => {
  return (
    chainId === config.web3.filecoin.CHAIN_ID ||
    chainId === config.web3.mantle.CHAIN_ID ||
    chainId === config.web3.linea.CHAIN_ID
  );
};

export const showSelectedCommunityNetworks: number[] = [
  config.web3.matic.CHAIN_ID,
  config.web3.arbitrum.CHAIN_ID,
  config.web3.bsc.CHAIN_ID,
  config.web3.optimism.CHAIN_ID,
  config.web3.gnosis.CHAIN_ID,
  config.web3.avalanche.CHAIN_ID,
  config.web3.filecoin.CHAIN_ID,
  config.web3.base.CHAIN_ID,
  config.web3.mantle.CHAIN_ID,
  config.web3.linea.CHAIN_ID,
  config.web3.zksync.CHAIN_ID,
];

export const showSelectedNetworks: number[] = [
  ...showSelectedCommunityNetworks,
  config.web3.solana.CHAIN_ID,
];

export const isChainDisabled = (chainName: string): boolean => {
  return (
    (NODE_ENV !== 'production' && chainName === 'gnosis') ||
    chainName === 'linea' ||
    chainName === 'mantle' ||
    chainName === 'base' ||
    chainName === 'solana' ||
    chainName === 'zksync' ||
    chainName === 'filecoin'
  );
};

export const isTokenDisabled = (chainName: string, tokenSymbol: string) => {
  if (chainName === 'arbitrum' || chainName === 'optimism') {
    return tokenSymbol === 'USDC' || tokenSymbol === 'WETH';
  }
  return (
    tokenSymbol === 'WETH' ||
    tokenSymbol === 'WMATIC' ||
    tokenSymbol === 'WBNB' ||
    tokenSymbol === 'WAVAX' ||
    tokenSymbol === 'WMNT' ||
    tokenSymbol === 'SOL' ||
    tokenSymbol === 'tFIL' ||
    tokenSymbol === 'FIL'
  );
};
