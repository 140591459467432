import React, { useState } from 'react';
import { BounceLoader } from 'react-spinners';
import { ReactComponent as CopyIcon } from '@spheron/ui-library/dist/assets/copy.svg';
import { ReactComponent as PencilIcon } from '@spheron/ui-library/dist/assets/pencil.svg';
import { useDispatch } from 'react-redux';
import GlobalStyle from '../../../styles/compute/global.module.scss';
import { ReactComponent as TickIcon } from '../../../assets/compute/icons/check-icon.svg';
import { ReactComponent as CrossIcon } from '../../../assets/compute/icons/cross-icon.svg';
import { copyToClipboard } from '../../../redux/compute/user/user.utils';
import { setReferralCodeThunk } from '../../../redux/compute/user/user.thunks';
import { AppDispatch } from '../../../redux/compute/store';

interface IProps {
  value: string;
  initialValue: string;
  type: string;
  maxLength: number;
  showCopy: boolean;
  editable: boolean;
  editLoading: boolean;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: string) => void;
  // eslint-disable-next-line react/require-default-props
  title?: string;
}

const ReferralTextInput = ({
  value,
  initialValue,
  type,
  maxLength,
  showCopy,
  editable,
  editLoading,
  onChange,
  title = '',
}: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();

  const [copied, setCopied] = useState<boolean>(false);
  const [editEnable, setEditEnable] = useState<boolean>(false);

  const setReferralCode = () => {
    dispatchRtk(setReferralCodeThunk(value));
  };

  return (
    <div>
      <div
        title={title}
        className={`border dark:border-dark-base-border
          flex flex-row items-center gap-1.5 px-4 rounded-lg dark:bg-dark-base-bg bg-white`}
      >
        <input
          className={`
           py-1.5 w-full outline-none transition dark:bg-dark-base-bg
           dark:text-dark-gray-text ${editEnable ? '' : 'caret-transparent'}
             dark:${GlobalStyle.dark_boxShadow} text-text-textbox`}
          type={type || 'text'}
          value={value}
          maxLength={maxLength}
          onChange={(e) => {
            if (editEnable) onChange(e.target.value);
          }}
        />
        {editable && (
          <>
            {editEnable ? (
              <div className="flex flex-row gap-1.5 items-center">
                <button
                  type="button"
                  className="flex-shrink-0"
                  onClick={() => setEditEnable(false)}
                >
                  <CrossIcon />
                </button>
                {editLoading ? (
                  <BounceLoader size={20} color="#8494BB" loading />
                ) : (
                  <button
                    type="button"
                    className={`flex-shrink-0 ${
                      value === initialValue || !value
                        ? 'cursor-not-allowed'
                        : 'cursor-pointer'
                    }`}
                    onClick={setReferralCode}
                    disabled={value === initialValue || !value}
                  >
                    <TickIcon className="text-feedback-success-text" />
                  </button>
                )}
              </div>
            ) : (
              <button
                type="button"
                className="flex-shrink-0"
                onClick={() => setEditEnable(true)}
              >
                <PencilIcon className="w-5 h-5 cursor-pointer text-base-icon dark:text-dark-base-icon" />
              </button>
            )}
          </>
        )}
        {showCopy && (
          <>
            {copied ? (
              <div
                role="presentation"
                className="flex items-center text-green-500 font-medium text-sm cursor-default"
                onClick={() => setCopied(!copied)}
              >
                Copied!
              </div>
            ) : (
              <button
                type="button"
                onClick={() => {
                  copyToClipboard(String(value));
                  setCopied(!copied);
                }}
              >
                <CopyIcon className="w-5 h-5 cursor-pointer text-base-icon dark:text-dark-base-icon" />
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ReferralTextInput;
