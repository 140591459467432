import {
  HealthCheckState,
  ServiceDetailsCardStatus,
} from '@spheron/ui-library';
import { IDiscount } from '../../combined-state.interface';

export const getServiceStatus = (status: string) => {
  switch (status) {
    case 'starting':
      return ServiceDetailsCardStatus.STARTING;
    case 'queued':
      return ServiceDetailsCardStatus.QUEUED;
    case 'running':
    case 'deployed':
    case 'provisioned':
      return ServiceDetailsCardStatus.RUNNING;
    case 'closed':
    case 'stopped':
    case 'deprecated':
    default:
      return ServiceDetailsCardStatus.STOPPED;
    case 'failed':
      return ServiceDetailsCardStatus.FAILED;
  }
};

export const getHealthCheckStatus = (status: string): HealthCheckState => {
  switch (status?.toLowerCase()) {
    case 'active':
      return 'healthy';
    case 'inactive':
      return 'unhealthy';
    default:
      return 'unhealthy';
  }
};

export const getDiscountedPrice = (
  price: number,
  discountPercentage: number
) => {
  return (price * (100 - discountPercentage)) / 100;
};

export const isDiscountEligible = (discount: IDiscount) => {
  return (
    discount &&
    new Date(discount.expirationDate).getTime() > new Date().getTime()
  );
};

const hyperlinkPattern =
  '^(https?:\\/\\/)?([a-zA-Z0-9-]+)\\.?([a-zA-Z0-9-]+)\\.([a-zA-Z]{2,})(\\/\\S*)?$';
const hyperlinkRegex = new RegExp(hyperlinkPattern);

export const isHyperlink = (str: string): boolean => {
  return hyperlinkRegex.test(str);
};
