import React from 'react';
import { useDispatch } from 'react-redux';
import ModalWrapper from './ModalWrapper';
import Styles from '../../../styles/compute/modal.module.scss';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import { AppDispatch } from '../../../redux/compute/store';

interface IProps {
  isOpen: boolean;
  iFrame: JSX.Element;
}

// eslint-disable-next-line no-unused-vars
const VideoPopup = ({ isOpen, iFrame }: IProps) => {
  const dispatch = useDispatch<AppDispatch>();
  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={() => dispatch(toggleModalShowRtk({ modalShow: false }))}
      classname={`${Styles.modal__outer__con} modal fixed inset-0 overflow-y-auto`}
    >
      <div className={`${Styles.modal__inner__con} !w-auto`}>
        {iFrame && iFrame}
      </div>
    </ModalWrapper>
  );
};

export default VideoPopup;
