import React, { useMemo } from 'react';
import makeBlockie from 'ethereum-blockies-base64';
import { useSelector, useDispatch } from 'react-redux';
import { largeCutText } from '../../../hooks/useShortAddress';
import { getPaymentLogo } from '../../../redux/stripe/stripe.utils';
import { ConnectionState } from '../../../redux/stripe/stripe.interfaces';
import { setTempPaymentMethodIdRtk } from '../../../redux/organisation/organisation.slice';
import NetworkTokenSet from '../Misc/network-token-set';
import { ISpheronWallet } from '../../../redux/organisation/organisation.interfaces';
import { ReactComponent as CreditIcon } from '../../../assets/compute/icons/credit-icon.svg';
import { PaymentMethod } from '../../../redux/combined-state.interface';
import {
  getDecimalFromTokenAddress,
  isNonSubgraphChainId,
} from '../../../redux/web3/web3.utils';
import { AppDispatch, RootState } from '../../../redux/compute/store';

interface IProps {
  // eslint-disable-next-line no-unused-vars
  setShowDropdown: (state: boolean) => void;
}

const SelectPaymentMethodCard = ({ setShowDropdown }: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();

  const creditCards = useSelector(
    (state: RootState) => state.stripe.creditCards
  ).filter((card) => card.connectionState === ConnectionState.CONNECTED);

  const web3Wallets = useSelector((state: RootState) => state.web3.wallets);

  const spheronWallet = useSelector(
    (state: RootState) => state.organisation.spheronWallet
  );

  const computeTopupSelectedPaymentMethod = useSelector(
    (state: RootState) => state.subscription.computeTopupSelectedPaymentMethod
  );

  const getWalletBalance = (
    amount: string,
    token: string,
    chainId: string,
    chainName: string
  ) => {
    return chainName === 'solana' || isNonSubgraphChainId(Number(chainId))
      ? Number(Number(amount).toFixed(4))
      : Number(
          (Number(amount) / 10 ** getDecimalFromTokenAddress(token)).toFixed(4)
        );
  };

  const filteredWeb3Wallets = useMemo(
    () =>
      web3Wallets?.filter(
        (wallet) =>
          getWalletBalance(
            wallet.balance.amount,
            wallet.balance.token,
            wallet.details.networkDetails.chainId,
            wallet.details.networkDetails.chainName
          ) >= 15
      ),
    [web3Wallets]
  );

  const getPaymentTypeClass = (paymentType: boolean): string => {
    if (paymentType) {
      return 'bg-base-fg text-action-primary-default';
    }
    return 'bg-base-fg text-base-sub-text-color';
  };

  const handleTempPaymentClick = () => {
    if (spheronWallet)
      dispatchRtk(setTempPaymentMethodIdRtk(spheronWallet._id));
    setShowDropdown(false);
  };

  const showCreditCards =
    (creditCards.length > 0 && !computeTopupSelectedPaymentMethod) ||
    (creditCards.length > 0 &&
      computeTopupSelectedPaymentMethod === PaymentMethod.CREDITCARD);
  const showWeb3Wallets =
    (filteredWeb3Wallets.length > 0 && !computeTopupSelectedPaymentMethod) ||
    (filteredWeb3Wallets.length > 0 &&
      computeTopupSelectedPaymentMethod === PaymentMethod.CRYPTO);
  const showSpheronWallet =
    (!!spheronWallet && !computeTopupSelectedPaymentMethod) ||
    (!!spheronWallet &&
      computeTopupSelectedPaymentMethod === PaymentMethod.SPHERONWALLET);

  return (
    <div
      className="py-3 bg-white rounded-lg w-96 dark:border-dark-base-border
      dark:bg-dark-feedback-default-bg border-base-border border max-h-400 
      overflow-y-scroll z-20 absolute mt-4"
    >
      {showSpheronWallet && (
        <>
          <div className="text-xs  font-semibold px-3">SPHERON CREDIT</div>
          <div className="my-3 space-y-3.5">
            <div
              className="flex items-center justify-between cursor-pointer hover:bg-base-fg 
              py-1.5 px-3 dark:hover:bg-dark-base-bg"
              role="presentation"
              onClick={() => handleTempPaymentClick()}
            >
              <div className="flex items-center justify-start">
                <CreditIcon className="w-8 h-8 mr-3 text-base-text dark:text-dark-base-text" />

                <span
                  className="text-sm font-semibold
                  text-base-heading-text-color dark:text-dark-base-heading-text-color"
                >
                  <span className="capitalize">Spheron Credit: </span>$
                  {Number(
                    (
                      (spheronWallet.details as ISpheronWallet)
                        .referralBalance +
                      (spheronWallet.details as ISpheronWallet).creditsBalance
                    ).toFixed(2)
                  )}
                </span>
              </div>
              <div
                className={`py-0.5 px-1.5 rounded ${getPaymentTypeClass(
                  spheronWallet.primary
                )}`}
              >
                <span className="capitalize text-sm font-semibold">
                  {spheronWallet.primary ? 'Primary' : 'Backup'}
                </span>
              </div>
            </div>
          </div>
        </>
      )}
      {showCreditCards && (
        <>
          <div className="text-xs  font-semibold px-3">CARDS</div>
          <div className="mt-3 space-y-3.5">
            {creditCards.map((creditCard) => (
              <div
                key={creditCard._id}
                className="flex items-center justify-between cursor-pointer hover:bg-base-fg 
          py-1.5 px-3 dark:hover:bg-dark-base-bg"
                role="presentation"
                onClick={() => {
                  dispatchRtk(setTempPaymentMethodIdRtk(creditCard._id));
                  setShowDropdown(false);
                }}
              >
                <div className="flex items-center justify-start">
                  <img
                    src={getPaymentLogo(creditCard.details?.info?.brand || '')}
                    alt="brand"
                    className="w-8 h-5 mr-3"
                  />

                  <span
                    className="text-sm font-semibold
                    text-base-heading-text-color dark:text-dark-base-heading-text-color"
                  >
                    <span className="capitalize">
                      {creditCard.details.info?.brand}
                    </span>
                    ****
                    {creditCard.details.info?.last4}
                  </span>
                </div>
                <div
                  className={`py-0.5 px-1.5 rounded ${getPaymentTypeClass(
                    creditCard.primary
                  )}`}
                >
                  <span className="capitalize text-sm font-semibold">
                    {creditCard.primary ? 'Primary' : 'Backup'}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {showWeb3Wallets && (
        <>
          <hr
            className={`${
              showCreditCards
                ? 'my-3 bg-base-fg dark:bg-dark-feedback-default-bg dark:border-dark-base-border'
                : 'hidden'
            } `}
          />
          <div className="px-3 text-xs  font-semibold">WEB3 PAYMENTS</div>
          <div className="mt-3 space-y-3.5">
            {filteredWeb3Wallets?.map((wallet) => (
              <div
                key={wallet._id}
                className="flex items-center justify-between cursor-pointer hover:bg-base-fg 
                 py-1.5 px-3 dark:hover:bg-dark-base-bg"
                role="presentation"
                onClick={() => {
                  dispatchRtk(setTempPaymentMethodIdRtk(wallet._id));
                  setShowDropdown(false);
                }}
              >
                <div
                  className="flex items-center justify-start text-sm
                  text-base-heading-text-color dark:text-dark-base-heading-text-color font-semibold"
                >
                  <img
                    src={makeBlockie(wallet.details.address)}
                    className="w-8 h-8 mr-3 rounded-full"
                    alt="blockie"
                  />
                  <span>{largeCutText(wallet.details.address)}</span>
                  <div className="ml-2">
                    <NetworkTokenSet
                      token={wallet.details.tokenDetails?.logoURI}
                      network={wallet.details.networkDetails?.logo}
                    />
                  </div>
                </div>
                <div
                  className={`py-0.5 px-1.5 rounded ${getPaymentTypeClass(
                    wallet.primary
                  )}`}
                >
                  <span className="capitalize text-sm font-semibold">
                    {wallet.primary ? 'Primary' : 'Backup'}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default SelectPaymentMethodCard;
