import React, { useCallback, useRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Feedback } from '@spheron/ui-library';
import { ReactComponent as InfoIcon } from '@spheron/ui-library/dist/assets/info-circle.svg';
import 'xterm/css/xterm.css';
import { XTerm, XTermRefType } from '../../../libs/Compute/XTerm';
import config from '../../../config';

export enum LeaseShellCode {
  // eslint-disable-next-line no-unused-vars
  LeaseShellCodeStdout = 100,
  // eslint-disable-next-line no-unused-vars
  LeaseShellCodeStderr = 101,
  // eslint-disable-next-line no-unused-vars
  LeaseShellCodeResult = 102,
  // eslint-disable-next-line no-unused-vars
  LeaseShellCodeFailure = 103,
  // eslint-disable-next-line no-unused-vars
  LeaseShellCodeStdin = 104,
  // eslint-disable-next-line no-unused-vars
  LeaseShellCodeTerminalResize = 105,
}

const ShellCommand = () => {
  const [showArrowAndTabWarning, setShowArrowAndTabWarning] =
    useState<boolean>(false);
  const [exitCodeWarning, setExitCodeWarning] = useState<boolean>(false);
  const { serviceId, instanceId } = useParams<{
    serviceId: string;
    instanceId: string;
  }>();
  const socketRef = useRef<WebSocket | null>(null);
  const terminalRef: any = useRef<XTermRefType>(null);
  const jwtToken = localStorage.getItem('jwt-token');
  const getEncodedWsShellData = (data: string) => {
    // Data needs to be sent as a byte array
    const encoder = new TextEncoder();
    const _data = encoder.encode(data);
    const content = new Uint8Array(_data.length + 1);
    const stdin = new Uint8Array([LeaseShellCode.LeaseShellCodeStdin]);
    // Set first byte as Stdin code
    content.set(stdin);
    // Set the rest of the bytes of the input
    content.set(_data, 1);
    return content;
  };
  const initCommand = () => {
    socketRef.current?.send(
      JSON.stringify({
        action: 'instance-shell',
        command: 'INIT_COMMAND',
        token: jwtToken,
        serviceName: serviceId,
        instanceId,
      })
    );
  };
  useEffect(() => {
    socketRef.current = new WebSocket(config.urls.WEBSOCKET_URL);
    socketRef.current.onopen = () => {
      // eslint-disable-next-line no-console
      console.log('Connected to WebSocket server');
      initCommand();
    };
    socketRef.current.onmessage = (event: MessageEvent) => {
      const jsonData = JSON.parse(event.data);
      const message = jsonData?.data;
      if (message) {
        const parsedData = Buffer.from(message).toString('utf-8', 1);
        const arrowKeyPattern = /\^\[\[[A-D]/;
        if (arrowKeyPattern.test(parsedData)) {
          setShowArrowAndTabWarning(true);
        }
        if (parsedData.includes('exit_code')) {
          setExitCodeWarning(true);
        }
        terminalRef.current?.write(parsedData);
      }
    };

    return () => {
      socketRef.current?.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTerminalKey = useCallback(
    (event: { key: string; domEvent: KeyboardEvent }) => {
      const data = getEncodedWsShellData(event.key);
      socketRef.current?.send(
        JSON.stringify({
          action: 'instance-shell',
          command: data.toString(),
          token: jwtToken,
          serviceName: serviceId,
          instanceId,
        })
      );
    },
    [jwtToken, serviceId, instanceId]
  );
  const onTerminalPaste = useCallback(
    (value: string) => {
      const data = getEncodedWsShellData(value);
      socketRef.current?.send(
        JSON.stringify({
          action: 'instance-shell',
          command: data.toString(),
          token: jwtToken,
          serviceName: serviceId,
          instanceId,
        })
      );
    },
    [jwtToken, serviceId, instanceId]
  );
  const scrollToTopSmooth = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (showArrowAndTabWarning || exitCodeWarning) {
      scrollToTopSmooth();
    }
  }, [showArrowAndTabWarning, exitCodeWarning]);

  return (
    <>
      {showArrowAndTabWarning && (
        <div className="mb-1">
          <Feedback
            feedbackType="warning"
            icon={<InfoIcon />}
            subTitle="Arrow and TAB autocompletion not working for this service"
            showClose={false}
            size="compact"
          />
        </div>
      )}
      {exitCodeWarning && (
        <div className="mb-1">
          <Feedback
            feedbackType="warning"
            icon={<InfoIcon />}
            subTitle="Websocket connection is lost"
            showClose={false}
            size="compact"
          />
        </div>
      )}
      <div className="bg-base-fg dark:bg-dark-base-fg p-4">
        <XTerm
          customRef={terminalRef}
          onKey={onTerminalKey}
          onTerminalPaste={onTerminalPaste}
        />
      </div>
    </>
  );
};

export default ShellCommand;
