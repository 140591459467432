import React from 'react';
import { Switch } from '@spheron/ui-library';

interface IProps {
  buttonText: string;
  state: boolean;
  // eslint-disable-next-line no-unused-vars
  setState: (state: boolean) => void;
  id: string;
  subHeading?: string;
}

const ShowMoreSwitch = ({
  buttonText,
  state,
  setState,
  id,
  subHeading,
}: IProps) => {
  return (
    <button
      className={`flex items-center justify-start cursor-pointer ${
        !subHeading ? 'space-x-2' : ''
      }`}
      type="button"
    >
      <div
        className="text-base-heading-text-color dark:text-dark-base-heading-text-color
        text-xl font-bold"
      >
        {buttonText}
      </div>
      {subHeading && (
        <div className="text-sm font-normal ml-5 mt-0.5">{subHeading}</div>
      )}
      <div className="ml-3">
        <Switch
          toggleId={id}
          isChecked={state}
          handleChange={() => setState(!state)}
        />
      </div>
    </button>
  );
};

ShowMoreSwitch.defaultProps = {
  subHeading: '',
};

export default ShowMoreSwitch;
