import { Dispatch } from 'redux';
import {
  IConfiguration,
  IDeployment,
  IOrganization,
  IScreenshot,
  IUser,
  NodeVersion,
} from '../combined-state.interface';
import { IProjectResponse } from '../project/project.interfaces';

export interface IDeploymentState {
  successfulSitePreviewsLoading: boolean;
  successfulSitePreviews: ISuccessfulSitePreview[];
  deploymentsLoading: boolean;
  allDeployments: IDeployment[];
  updatingDeploymentLoading: boolean;
  moreDeploymentsLoading: boolean;
  deploymentsPagination: IDeploymentsPagination;
  allDeploymentsDetails: IAllDeploymentDetails;
  fetchAllDeploymentDetailsLoading: boolean;
  authorizedProviders: Array<string>;
  authorizedProvidersLoaded: boolean;
  selectedDeploymentConfig: IDeployConfig | undefined;
  selectedDeploymentLogs: Array<IProjectDeployLogs>;
  selectedRepoOwner: IRepoOwner | undefined;
  selectedDeploymentStatus: DeploymentStatus;
  selectedDeploymentLoading: boolean;
  selectedDeploymentBuildTime: IBuildTime;
  selectedDeploymentDeployedLink: string;
  selectedDeployment: IDeployment | undefined;
  selectedProvider: string;
  selectedRepository: IRepository | undefined;
  selectedDeploymentProtocol: string;
  selectedDeploymentOrganization: IOrganization | undefined;
  selectedDeploymentRepoBranches: Array<IRepoBranches>;
  selectedDeploymentRepoBranchesLoading: boolean;
  selectedDeploymentBranch: string;
  redeployProjectSettings: IRedeployProject | null;
  startDeploymentLoading: boolean;
  repositoryDropdown: boolean;
  authorizingDeployment: string;
  authorizingDeploymentLoading: boolean;
  authorizedDeployment: boolean;
  pullRequestId: string;
  suggestedFramework: string;
  suggestedFrameworkLoading: boolean;
  cloneRepoTopicId: string;
  clonedRepository: IClonedRepositoryDetails;
  thirdPartyRepoDetails: ISetRepositoryDetailsPayloadDto;
}

export interface IClonedRepositoryDetails {
  clonedToNamespace: string;
  cloneRepositoryName: string;
}

export interface IAllDeploymentDetails {
  total: number;
  successful: number;
  failed: number;
  pending: number;
  authorizationneeded?: number;
  timedOut?: number;
}

export interface IRedeployProject {
  url: string | null;
  name: string | null;
  branch: string | null;
  framework: string | null;
  publishDir: string | null;
  packageManager: string | null;
  buildCommand: string | null;
  workspace: string | null;
  protocol: string | null;
  provider: string | null;
}

export interface ISuccessfulSitePreview {
  _id: string;
  sitePreview: string;
  deploymentEnvironmentName: string;
  contentHash: string;
}

export interface IDeploymentsPagination {
  currentPage: number;
  numbersPerPage: number;
}

export interface IBuildTime {
  min: number;
  sec: number;
}

export interface IProjectDeployLogs {
  log: string;
  time: string;
  id?: string;
}

export interface IDeployConfig {
  url: string;
  branch: string;
  createdAt: any;
  updatedAt: any;
  protocol: string;
  commitHash: string;
  commitMessage: string;
  provider: string;
  configuration: IConfiguration;
}

export interface IRepoBranches {
  name: string;
}

export interface IRepoOwner {
  name: string;
  avatar: string;
  providerId: string;
  type: string;
}

export interface IRepository {
  owner: string;
  cloneUrl: string;
  branchUrl: string;
  name: string;
  fullName: string;
  private: boolean;
  repositoryId: string;
  provider: string;
  updatedAt: Date;
  language: string;
  defaultBranch: string;
}

export const enum DeploymentStatus {
  // eslint-disable-next-line no-unused-vars
  Queued = 'queued',
  // eslint-disable-next-line no-unused-vars
  Pending = 'pending',
  // eslint-disable-next-line no-unused-vars
  Failed = 'failed',
  // eslint-disable-next-line no-unused-vars
  Deployed = 'deployed',
  // eslint-disable-next-line no-unused-vars
  AuthorizationNeeded = 'authorizationneeded',
  // eslint-disable-next-line no-unused-vars
  Killing = 'killing',
  // eslint-disable-next-line no-unused-vars
  Canceled = 'canceled',
  // eslint-disable-next-line no-unused-vars
  PreQueue = 'prequeue',
  // eslint-disable-next-line no-unused-vars
  TimedOut = 'timedout',
}

export const enum FrameworkOptions {
  // eslint-disable-next-line no-unused-vars
  Static = 'static',
  // eslint-disable-next-line no-unused-vars
  React = 'react',
  // eslint-disable-next-line no-unused-vars
  Vue = 'vue',
  // eslint-disable-next-line no-unused-vars
  Angular = 'angular',
  // eslint-disable-next-line no-unused-vars
  Next = 'next',
  // eslint-disable-next-line no-unused-vars
  Preact = 'preact',
  // eslint-disable-next-line no-unused-vars
  Nuxt2 = 'nuxt2',
  // eslint-disable-next-line no-unused-vars
  Docusaurus = 'docusaurus',
  // eslint-disable-next-line no-unused-vars
  Hugo = 'hugo',
  // eslint-disable-next-line no-unused-vars
  Eleventy = 'eleventy',
  // eslint-disable-next-line no-unused-vars
  Svelte = 'svelte',
  // eslint-disable-next-line no-unused-vars
  Gatsby = 'gatsby',
  // eslint-disable-next-line no-unused-vars
  Sanity = 'sanity',
  // eslint-disable-next-line no-unused-vars
  IonicReact = 'ionicreact',
  // eslint-disable-next-line no-unused-vars
  Vite = 'vite',
  // eslint-disable-next-line no-unused-vars
  Scully = 'scully',
  // eslint-disable-next-line no-unused-vars
  Stencil = 'stencil',
  // eslint-disable-next-line no-unused-vars
  Brunch = 'brunch',
  // eslint-disable-next-line no-unused-vars
  IonicAngular = 'ionicangular',
  // eslint-disable-next-line no-unused-vars
  Astro = 'astro',
  // eslint-disable-next-line no-unused-vars
  VuePress = 'vuepress',
}

export interface IDeploymentStartDtoPayload {
  branch: string;
  configuration: IConfiguration;
  buildCommand: string;
  framework: string;
  installCommand: string;
  nodeVersion: NodeVersion;
  packageManager: string;
  publishDir: string;
  workspace: string;
  createDefaultWebhook: true;
  env: {
    [key: string]: string;
  };
  repoName: string;
  gitUrl: string;
  orgId: string;
  organizationId: string;
  protocol: string;
  provider: string;
  uniqueTopicId: string;
}

export interface IDeploymentBody {
  branch: string;
  buildCommand: string;
  commitId: string | null;
  deploymentId: string;
  env: {
    [key: string]: string;
  };
  repoName: string;
  framework: string;
  githubUrl: string;
  installCommand: string;
  isWorkspace: boolean;
  logsToCapture: { key: string; value: string }[];
  packageManager: string;
  paidViaSubscription: boolean;
  protocol: string;
  publishDirectory: string;
  topic: string;
  workspace: string;
}
export interface IDeploymentStartResponse {
  body: IDeploymentBody;
  deploymentId: string;
  message: string;
  projectId: string;
  success: boolean;
  topic: string;
}

export interface IStopDeploymentResponse {
  canceled: boolean;
  killing: boolean;
  message: string;
}

export interface ISuccessfulSitePreviewResponse {
  contentHash: string;
  deploymentEnvironmentName: string;
  sitePreview: string;
  _id: string;
}

export interface ISuccessfulDeploymentResponse {
  branch: string;
  buildDirectory: string[];
  buildTime: number;
  commitId: string;
  commitMessage: string;
  configuration: IConfiguration;
  contentHash: string;
  createdAt: string;
  deploymentEnvironmentName: string;
  deploymentInitiator: IUser;
  externalRepositoryName: string | null;
  failedMessage: string;
  isFromRequest: boolean;
  memoryUsed: number;
  paymentId: string | null;
  project: IProjectResponse;
  protocol: string;
  screenshot: IScreenshot;
  sitePreview: string;
  status: string;
  topic: string;
  updatedAt: string;
  _id: string;
}

export interface IFetchRepositories {
  repositories: IRepository[];
  totalCount: number;
}

export interface IBranch {
  commit: {
    sha: string;
    url: string;
  };
  name: string;
  protected: boolean;
  protection: {
    enabled: boolean;
    // eslint-disable-next-line camelcase
    required_status_check: {
      check: string[];
      context: string[];
      // eslint-disable-next-line camelcase
      enforcement_level: string;
    };
    // eslint-disable-next-line camelcase
    protection_url: string;
  };
}

export interface ISuggestFrameworkPayloadDto {
  branch: string;
  owner: string;
  provider: string;
  repo: string;
  root: string;
}

export interface ISuggestFrameworkResponse {
  suggestedFramework: string;
}

export interface IFetchDeploymentRepoBranchPayloadDto {
  provider: string;
  projectUrl: string;
  organizationId: string;
  name?: string;
}

export interface IFetchDeploymentRepoBranchResponse {
  branches: IBranch[];
  success: boolean;
}

export interface IGetDeploymentPayloadDto {
  deploymentId: string;
  dispatch: Dispatch<any>;
}

export interface ILogs {
  log: string;
  time: string;
  _id: string;
}

export interface IGetDeploymentRes extends ISuccessfulDeploymentResponse {
  logs?: ILogs[];
}

export interface IGetDeploymentResponse {
  deployment: IGetDeploymentRes;
  liveLogs?: string | null;
}

export interface IDeploymentOrganisationRequestPayloadDto {
  organizationId: string;
  deploymentId: string;
}

export interface IToggleDeploymentState {
  state: boolean;
  deploymentId: string;
  status: DeploymentStatus;
}

export interface IStopDeploymentPayloadDto {
  deploymentId: string;
  isSelectedDeployment?: boolean;
}

export interface ISetStartDeploymentPayloadDto {
  configuration: Partial<IConfiguration>;
  deployment: Partial<IDeployment>;
  orgId: string;
}

export interface ITriggerSpecificDeploymentEndResponseDto {
  result: IDeploymentStartResponse;
  previousDeploymentId: string;
}

export interface IAuthorizeDeploymentResponse {
  message: string;
  success: boolean;
  topic: string;
  deploymentId: string;
  projectId: string;
}

export interface IStartCloneDeploymentPayloadDto {
  cloneConfig: ICloneDeploymentPayloadDto;
  callbackFn: () => void;
}

export interface ICloneDeploymentPayloadDto {
  sourceGitUrl: string | null;
  targetProvider: string;
  newRepoNamespace: string | undefined;
  newRepoName: string;
  visibility: string;
  topicId: string;
  // visibility: 'public' | 'private';
}
export interface ICloneDeploymentResponse {
  success: boolean;
  topicId: string;
  message: string;
}

// change and remove null here and in ICloneDeploymentPayloadDto
export interface IGetRepoDetailsPayloadDto {
  url: string | null;
}

export interface ISetRepositoryDetailsPayloadDto {
  name: string;
  description: string;
  owner: string;
  avatarUrl: string;
}
