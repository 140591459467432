import React from 'react';
import BounceLoader from 'react-spinners/BounceLoader';
import { useSelector } from 'react-redux';
import SearchIcon from '../../../assets/compute/icons/search-icon.svg';
import { ICluster } from '../../../redux/cluster/cluster.interfaces';
import { IMemberInvite, IUser } from '../../../redux/combined-state.interface';
import { isDarkTheme } from '../../../redux/theme/theme.utils';
import GlobalStyle from '../../../styles/compute/global.module.scss';
import { RootState } from '../../../redux/compute/store';

interface IProps {
  className?: string;
  placeholder?: string;
  inputClassName?: string;
  onChange: any;
  setValue: any;
  value: string;
  type: string;
  array: any;
  maxLength?: number;
  loading?: boolean;
  disabled?: boolean;
}
const SearchInput = ({
  className,
  placeholder,
  inputClassName,
  onChange,
  value,
  type,
  setValue,
  array,
  maxLength,
  loading,
  disabled,
}: IProps) => {
  const theme = useSelector((state: RootState) => state.theme.theme);
  return (
    <div
      className={`${GlobalStyle.search__bar} ${!!className && className} ${
        disabled ? `${GlobalStyle.search__bar__disabled}` : 'cursor-pointer'
      }`}
    >
      <img src={SearchIcon} alt="search" />
      <input
        className={`${
          !!inputClassName && `${inputClassName}`
        } dark:bg-transparent truncate ${
          disabled ? `${GlobalStyle.search__bar__disabled}` : 'cursor-pointer'
        }`}
        type="text"
        value={value}
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={disabled}
        onChange={(e) => {
          setValue(e.target.value);
          if (type === 'overview') {
            onChange(
              array?.filter((element: { name: string }) =>
                element?.name?.toLowerCase().includes(e.target.value)
              )
            );
          } else if (type === 'repository') {
            onChange(
              array?.filter((element: { name: string }) =>
                element.name
                  ?.toLowerCase()
                  .includes(e.target.value.toLowerCase())
              )
            );
          } else if (type === 'users') {
            onChange(
              array?.filter(
                (element: IUser) =>
                  element.platformProfile.username
                    ?.toLowerCase()
                    .includes(e.target.value) ||
                  element.platformProfile.name
                    ?.toLowerCase()
                    .includes(e.target.value) ||
                  element.platformProfile.email
                    ?.toLowerCase()
                    .includes(e.target.value)
              )
            );
          } else if (type === 'invited-users') {
            onChange(
              array?.filter((element: IMemberInvite) =>
                element.userEmail?.toLowerCase().includes(e.target.value)
              )
            );
          } else if (type === 'clusters') {
            onChange(
              array?.filter((element: ICluster) =>
                element.name?.toLowerCase().includes(e.target.value)
              )
            );
          }
        }}
      />
      <BounceLoader
        size={15}
        color={`${!isDarkTheme(theme) ? '#8494BB' : '#fff'}`}
        loading={loading}
      />
    </div>
  );
};

SearchInput.defaultProps = {
  className: '',
  placeholder: '',
  inputClassName: '',
  maxLength: 400,
  loading: false,
  disabled: false,
};

export default SearchInput;
