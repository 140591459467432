import React, { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Modal, TextInput } from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import Styles from '../../../styles/compute/modal.module.scss';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import { addSpheronCreditThunk } from '../../../redux/compute/subscription/subscription.thunks';
import config from '../../../config';
import ModalWrapper from './ModalWrapper';

interface IProps {
  isOpen: boolean;
}

const AddCredit = ({ isOpen }: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();

  const [couponCode, setCouponCode] = useState<string>('');
  const [captchaSuccess, setCaptchaSuccess] = useState<boolean>(false);

  const organizationId = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation?._id
  );

  const couponLoading = useSelector(
    (state: RootState) => state.subscription.couponLoading
  );

  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const handleRedeemCredit = async () => {
    const captchaToken = await recaptchaRef.current?.getValue();
    if (captchaToken)
      dispatchRtk(
        addSpheronCreditThunk({
          couponCode: couponCode.trim(),
          organizationId,
          captchaToken,
        })
      );
  };

  const closeModal = () => {
    dispatchRtk(
      toggleModalShowRtk({
        modalShow: false,
      })
    );
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={() => {
        dispatchRtk(toggleModalShowRtk({ modalShow: false }));
      }}
      classname={`${Styles.modal__outer__con} modal fixed inset-0 overflow-y-auto`}
    >
      <div className={`${Styles.modal__inner__content} !max-w-[400px]`}>
        <Modal
          footer
          header
          title="Add Credit"
          subtitle=""
          modalContent={
            <div className="flex flex-col gap-6 w-full">
              <span className="text-base-para-text-color dark:text-dark-base-para-text-color font-400 text-sm">
                Have a credit coupon code, please add it here
              </span>
              <TextInput
                type="text"
                onChange={(value) => setCouponCode(value as string)}
                value={couponCode}
                placeholder="Enter Code"
                label="ENTER PROMOTION CODE"
              />
              <ReCAPTCHA
                sitekey={config.referral.RECAPTCHA_SITE_KEY}
                ref={recaptchaRef}
                onChange={() => setCaptchaSuccess(true)}
              />
              <span
                className="text-base-para-text-color dark:text-dark-base-para-text-color
              font-400 text-[11px] leading-3"
              >
                By clicking &quot;Redeem Credit&quot;, you indicatre that you
                read and agree to the terms and conditions.
              </span>
            </div>
          }
          headerContent={<>Add Credit</>}
          primaryButtonContent="Redeem Credit"
          secondaryButtonContent="Cancel"
          primaryButton
          primaryButtonLoading={couponLoading}
          primaryButtonDisabled={!captchaSuccess || !couponCode}
          secondaryButtonDisabled={couponLoading}
          secondaryButton
          onPrimaryButtonClick={handleRedeemCredit}
          onSecondaryButtonClick={closeModal}
        />
      </div>
    </ModalWrapper>
  );
};

export default AddCredit;
