import React from 'react';
import { ConfirmationModal, Modal } from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import Styles from '../../../styles/compute/modal.module.scss';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import { removeProviderThunk } from '../../../redux/compute/user/user.thunks';
import { setDisconnectLoginConnectionLoadingRtk } from '../../../redux/compute/organisation/organisation.slice';
import ModalWrapper from './ModalWrapper';

interface IProps {
  isOpen: boolean;
  provider: string;
}

const DisconnectLoginConnection = ({ isOpen, provider }: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();

  const removeLoginConnectionLoading: boolean = useSelector(
    (state: RootState) => state.organisation.removeLoginConnectionLoading
  );

  const closeModal = () => {
    dispatchRtk(toggleModalShowRtk({ modalShow: false }));
  };

  const providerName = (provider: string) => {
    if (provider === 'github') return 'GitHub';
    if (provider === 'gitlab') return 'GitLab';
    if (provider === 'bitbucket') return 'Bitbucket';
    return '';
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={closeModal}
      classname={`${Styles.modal__outer__con} w-430 modal fixed inset-0 overflow-y-auto`}
    >
      <div className={Styles.modal__inner__content}>
        <Modal
          footer
          header
          subtitle=""
          modalContent={
            <span>
              Are you sure you want to disconnect your {providerName(provider)}{' '}
              account?
            </span>
          }
          headerContent={<>Disconnect {providerName(provider)}</>}
          primaryButtonContent="Disconnect"
          secondaryButtonContent="Cancel"
          primaryButton
          primaryButtonLoading={removeLoginConnectionLoading}
          primaryButtonDisabled={removeLoginConnectionLoading}
          secondaryButtonDisabled={removeLoginConnectionLoading}
          secondaryButton
          onPrimaryButtonClick={() => {
            dispatchRtk(setDisconnectLoginConnectionLoadingRtk(true));
            dispatchRtk(removeProviderThunk(provider));
          }}
          onSecondaryButtonClick={closeModal}
          confirmationStatus={ConfirmationModal.ERROR}
        />
      </div>
    </ModalWrapper>
  );
};

export default DisconnectLoginConnection;
