import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { getSystemStatus } from './status.services';

export const getSystemStatusThunk = createAsyncThunk(
  'status/getSystemStatus',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await getSystemStatus();
      return fulfillWithValue(response);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue(error);
    }
  }
);
