import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TemplateCard from '../TemplateCard';
import SelectTemplate from '../../../../../assets/compute/icons/select-template.svg';
import Docker from '../../../../../assets/compute/icons/docker.svg';
import { IClusterTemplate } from '../../../../../redux/compute/cluster/cluster.interfaces';
import { setSelectedTemplateRtk } from '../../../../../redux/compute/cluster/cluster.slice';
import { useQuery } from '../../../../../hooks/useQuery';
import { AppDispatch, RootState } from '../../../../../redux/compute/store';
import { toggleModalShowRtk } from '../../../../../redux/compute/modal/modal.slice';
import {
  chooseClusterTypeRtk,
  setIpLeaseType,
  toggleConfirmRedeploySwitchRtk,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.slice';
import {
  ClusterType,
  ConfirmRedeploySwitchType,
  IInstancePlan,
  IPLeaseType,
  LoadingType,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.interfaces';
import { calculatePriceThunk } from '../../../../../redux/compute/instance/instance-creation/instance-creation.thunks';
// eslint-disable-next-line max-len
import { isAccelerateDeployment } from '../../../../../redux/compute/instance/instance-creation/instance-creation.utils';
import TemplateCardLoading from '../../../../../components/Compute/Loaders/template-card-loader';

interface IProps {
  // eslint-disable-next-line no-unused-vars
  setSelectedInstancePlan: (selectedInstancePlan: IInstancePlan | null) => void;
  // eslint-disable-next-line no-unused-vars
  setUpdatedTemplate: (updatedTemplate: IClusterTemplate | null) => void;
}

const ChooseClusterSection = ({
  setSelectedInstancePlan,
  setUpdatedTemplate,
}: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const queryParams = useQuery();
  const provider = queryParams.get('provider');
  const type = queryParams.get('type');
  const params = useParams();
  const [selectedCard, setSelectedCard] = useState<ClusterType>(
    ClusterType.TEMPLATE
  );
  const [selectedIndex, setSelectedIndex] = useState<string>();
  const clusterType = useSelector(
    (state: RootState) => state.instanceCreation.clusterType
  );
  const confirmRedeploySwitch = useSelector(
    (state: RootState) => state.instanceCreation.confirmRedeploySwitch
  );
  const confirmRedeploySwitchType = useSelector(
    (state: RootState) => state.instanceCreation.confirmRedeploySwitchType
  );
  const activeBonusLoading = useSelector(
    (state: RootState) => state.subscription.activeBonusLoading
  );
  const selectedOrganisationLoading = useSelector(
    (state: RootState) => state.organisation.selectedOrganisationLoading
  );
  const deploymentType = useSelector(
    (state: RootState) => state.instanceCreation.deploymentType
  );
  useEffect(() => {
    if (provider === null && type !== 'redeploy') {
      if (selectedIndex !== ClusterType.DOCKER) {
        setSelectedCard(ClusterType.TEMPLATE);
        dispatchRtk(chooseClusterTypeRtk(ClusterType.TEMPLATE));
      } else {
        setSelectedCard(ClusterType.DOCKER);
        dispatchRtk(chooseClusterTypeRtk(ClusterType.DOCKER));
      }
    } else if (provider === 'DOCKERHUB') {
      setSelectedCard(ClusterType.DOCKER);
      dispatchRtk(chooseClusterTypeRtk(ClusterType.DOCKER));
    } else if (provider === 'template') {
      setSelectedCard(ClusterType.TEMPLATE);
      dispatchRtk(chooseClusterTypeRtk(ClusterType.TEMPLATE));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider]);

  useEffect(() => {
    if (
      confirmRedeploySwitch &&
      confirmRedeploySwitchType === ConfirmRedeploySwitchType.CLUSTER &&
      type === 'redeploy' &&
      provider === 'template' &&
      selectedIndex === ClusterType.DOCKER
    ) {
      navigate(`/compute/${params?.computeProjectId}/new-instance/`);
      setSelectedCard(ClusterType.DOCKER);
      dispatchRtk(chooseClusterTypeRtk(ClusterType.DOCKER));
      dispatchRtk(
        toggleConfirmRedeploySwitchRtk({
          state: false,
          type: ConfirmRedeploySwitchType.CLUSTER,
        })
      );
    } else if (
      confirmRedeploySwitch &&
      type === 'redeploy' &&
      provider === 'DOCKERHUB' &&
      selectedIndex === ClusterType.TEMPLATE
    ) {
      navigate(`/compute/${params?.computeProjectId}/new-instance/`);
      setSelectedCard(ClusterType.TEMPLATE);
      dispatchRtk(chooseClusterTypeRtk(ClusterType.TEMPLATE));
      dispatchRtk(
        toggleConfirmRedeploySwitchRtk({
          state: false,
          type: ConfirmRedeploySwitchType.DEFAULT,
        })
      );
    }
  }, [
    confirmRedeploySwitch,
    confirmRedeploySwitchType,
    type,
    provider,
    selectedCard,
    dispatchRtk,
    navigate,
    selectedIndex,
    params?.computeProjectId,
  ]);

  useEffect(() => {
    if (type === 'redeploy' && provider) {
      setSelectedCard(provider as ClusterType);
      setSelectedIndex(provider);
      dispatchRtk(
        chooseClusterTypeRtk(
          provider === 'DOCKERHUB' ? ClusterType.DOCKER : ClusterType.TEMPLATE
        )
      );
    }
  }, [type, provider, dispatchRtk]);

  const clusterSwitch = (index: ClusterType) => {
    setSelectedIndex(index);
    setSelectedCard(index);
    dispatchRtk(chooseClusterTypeRtk(index));
  };

  const handleCardClick = (index: ClusterType) => {
    setSelectedInstancePlan(null);
    setUpdatedTemplate(null);
    dispatchRtk(setIpLeaseType(IPLeaseType.SHARED));
    dispatchRtk(calculatePriceThunk(LoadingType.IP_LEASE));
    if (
      (type === 'redeploy' &&
        provider === 'template' &&
        index === ClusterType.DOCKER) ||
      (type === 'redeploy' &&
        provider === 'DOCKERHUB' &&
        index === ClusterType.TEMPLATE)
    ) {
      dispatchRtk(
        toggleModalShowRtk({
          modalShow: true,
          modalType: 'confirmRedeploySwitch',
          options: {
            redeploySwitchType: ConfirmRedeploySwitchType.CLUSTER,
            index,
            confirmCallback: () => {
              clusterSwitch(index);
            },
          },
        })
      );
    } else {
      clusterSwitch(index);
    }
  };

  useEffect(() => {
    if (clusterType === ClusterType.DOCKER) {
      dispatchRtk(setSelectedTemplateRtk(null));
    }
  }, [dispatchRtk, clusterType]);

  const templateCard = [
    {
      id: 1,
      title: 'Start from marketplace app',
      image: SelectTemplate,
      selected: clusterType === ClusterType.TEMPLATE,
      onSelect: ClusterType.TEMPLATE,
    },
    {
      id: 2,
      title: 'Import from Docker Registry',
      image: Docker,
      selected: clusterType === ClusterType.DOCKER,
      onSelect: ClusterType.DOCKER,
    },
  ];
  return (
    <div>
      <span
        className="text-base-heading-text-color dark:text-dark-base-heading-text-color
        text-xl font-bold"
      >
        {isAccelerateDeployment(deploymentType) ? '2.' : '3.'}
        <span className="ml-1">Create a new Cluster</span>
      </span>
      <div className="flex sm:flex-row md:gap-0 gap-5 flex-col items-center mt-8">
        {activeBonusLoading || selectedOrganisationLoading ? (
          <div className="grid grid-cols-4 gap-6 w-full">
            {Array.from(Array(2).keys()).map((item) => (
              <TemplateCardLoading showBadge={false} key={item} />
            ))}
          </div>
        ) : (
          <div className="flex flex-row md:gap-6 gap-0">
            {templateCard.map((template) => (
              <div key={template.id}>
                <TemplateCard
                  title={template.title}
                  imageShow
                  image={template.image}
                  subtitle=""
                  badgeContent=""
                  isSelected={template.selected}
                  onSelect={() => handleCardClick(template.onSelect)}
                  isSelectedTemplate={false}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChooseClusterSection;
