import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import GlobalStyles from '../../../../styles/compute/global.module.scss';
import { updateInvitationThunk } from '../../../../redux/organisation/organisation.thunks';
import { setInviteLoadingRtk } from '../../../../redux/compute/organisation/organisation.slice';
import { AppDispatch, RootState } from '../../../../redux/compute/store';

const InviteCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatchRtk = useDispatch<AppDispatch>();
  const { search } = useLocation();
  const inviter =
    new URLSearchParams(search).get('orgName') ||
    localStorage.getItem('orgName');

  const inviteCallbackLoading = useSelector(
    (state: RootState) => state.organisation.inviteCallbackLoading
  );

  const organizationId = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation._id
  );

  useEffect(() => {
    const jwtToken = localStorage.getItem('jwt-token');
    if (!jwtToken) {
      const query = new URLSearchParams(location.search);
      const ref = query.get('ref');
      const orgName = query.get('orgName');
      localStorage.setItem('inviteRef', `${ref}`);
      localStorage.setItem('orgName', `${orgName}`);
      navigate('/signup');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendInvitation = (pValue: string) => {
    if (pValue === 'accepts') {
      dispatchRtk(setInviteLoadingRtk(true));
    }
    const query = new URLSearchParams(location.search);
    const ref = query.get('ref') || localStorage.getItem('inviteRef');
    const inviteReply = {
      inviteId: ref as string,
      status: pValue,
      organizationId,
    };
    const payload = { inviteReply };
    dispatchRtk(updateInvitationThunk(payload));
  };

  return (
    <div className={GlobalStyles.screen__width__invite}>
      <div className="h-[60%] w-full flex justify-center items-center text-center">
        <div className="w-3/5">
          <div>
            <h2 className="font-semibold text-2xl text-text-createOrgHeader">
              Accept the invitation
            </h2>
            <span className="text-text-createOrgText text-sm">
              Your teammates want to add you to their Spheron Organisation
            </span>
          </div>
          <div className="mt-8">
            <div className="font-semibold text-md text-text-createOrgHeader">
              Invitation from {inviter}
            </div>
            <div className="text-text-createOrgText my-2 text-sm">
              You have been invited to join the {inviter} on Spheron.
            </div>
            <div className="text-text-createOrgText my-2 text-sm">
              By joining, your name, email address and username will be visible
              to other members of the organization. You will also be able to
              access all the projects on the Organization.
            </div>
          </div>
          <div className="buttons my-4 mt-8 flex justify-center items-center">
            <Button
              buttonType="primary"
              label="Accept"
              size="medium"
              onClick={() => {
                sendInvitation('accepts');
              }}
              loading={inviteCallbackLoading}
              classname="md:mr-4"
            />
            <Button
              buttonType="ghost"
              label="Decline"
              size="medium"
              onClick={() => {
                sendInvitation('decline');
              }}
              loading={inviteCallbackLoading}
              classname="md:mr-4"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteCallback;
