import React, { useState, useEffect, useRef } from 'react';
import { Badge, Dropdown, OptionType } from '@spheron/ui-library';
import makeBlockie from 'ethereum-blockies-base64';
import Skeleton from 'react-loading-skeleton';
import { ReactComponent as Chevron } from '@spheron/ui-library/dist/assets/arrow-right-3.svg';
import {
  useAppSelector as useSelectorRtk,
  useAppDispatch as useDispatchRtk,
} from '../../redux/rtk-context';
import { ReactComponent as TripleDot } from '../../assets/compute/icons/triple-light-dot.svg';
import { useShortAddress } from '../../hooks/useShortAddress';
import {
  getDecimalFromTokenAddress,
  getNetworkFromName,
  getTokenNameFromAddress,
  isNonSubgraphChainId,
} from '../../redux/web3/web3.utils';
import {
  IBalance,
  INetworkType,
  ITokenType,
} from '../../redux/web3/web3.interfaces';
import { withLoader } from '../../redux/root-utils';
import SelectPaymentMethodCard from '../Compute/Dropdown/select-payment-method-card';
import { toggleModalShowRtk } from '../../redux/modal/modal.slice';
import { updateDefaultPaymentThunk } from '../../redux/organisation/organisation.thunks';
import { RootState } from '../../redux/rtk-store';
import NetworkTokenSetLoading from '../Compute/Loaders/network-token-set-loading';
import {
  connectWalletThunk,
  fetchWalletBalanceThunk,
} from '../../redux/web3/web3.thunks';
import UseOnClickOutside from '../../hooks/useOnClickOutside';
import ProviderNetworkTab from '../WalletInfo/provider-network-tab';
import { setMaxDepositedWallet } from '../../redux/web3/web3.slice';
import { ICurrentApp } from '../../redux/organisation/organisation.interfaces';

interface IProps {
  address: string;
  isSolana: boolean;
  network: INetworkType | null;
  token: ITokenType | null;
  balance: IBalance | null;
  balanceLoading: boolean;
  disconnectWalletLoading: boolean;
  isPrimary: boolean;
  id: string;
  showDropdown: boolean;
  disabled: boolean;
}

const CryptoCard = ({
  address,
  isSolana,
  token,
  network,
  balanceLoading,
  disconnectWalletLoading,
  balance,
  isPrimary,
  id,
  showDropdown,
  disabled,
}: IProps) => {
  const isPublicAccessible =
    sessionStorage.getItem('isPublicAccessible') === 'true';
  const communityUserAccess =
    sessionStorage.getItem('communityUserAccess') === 'true';

  const dispatchRtk = useDispatchRtk();
  const selectedOrganisationId = useSelectorRtk(
    (state: RootState) => state.organisation.selectedOrganisation._id
  );

  const removeWalletsLoading = useSelectorRtk(
    (state: RootState) => state.web3.removeWalletsLoading
  );
  const currentAddress = useSelectorRtk(
    (state: RootState) => state.web3.currentAccount
  );
  const currentApp = useSelectorRtk(
    (state: RootState) => state.organisation.selectedOrganisation.appType
  );
  const [showSwitchDropdown, setShowSwitchDropdown] = useState<boolean>(false);

  const isCardLoading = removeWalletsLoading.indexOf(id) !== -1;

  const shortAddress = useShortAddress(address);
  const dropdownRef = useRef(null);
  UseOnClickOutside(dropdownRef, () => setShowSwitchDropdown(false));

  const displayBalance =
    isSolana || isNonSubgraphChainId(Number(network?.chainId))
      ? Number(balance?.amount).toFixed(4)
      : `${(
          Number(balance?.amount) /
          10 ** getDecimalFromTokenAddress(balance?.token || '')
        ).toFixed(4)}`;

  useEffect(() => {
    // TODO - Currently only optimised for stablecoins, needs to be added for others
    if (Number(displayBalance) >= 15 && token && network)
      dispatchRtk(
        setMaxDepositedWallet({ balance: displayBalance, token, network })
      );
  }, [dispatchRtk, displayBalance, network, token]);

  useEffect(() => {
    if (balance?.token === '' && currentAddress) {
      if (isNonSubgraphChainId(Number(network?.chainId))) {
        dispatchRtk(
          fetchWalletBalanceThunk({
            walletId: id,
            address,
            networkDetails: network as INetworkType,
            tokenDetails: token as ITokenType,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAddress]);

  const options = [
    {
      id: 1,
      label: 'View Wallet',
      handleClick: () =>
        dispatchRtk(
          toggleModalShowRtk({
            modalShow: true,
            modalType: 'viewWallet',
            options: {
              walletId: id,
            },
          })
        ),
      optionType: 'primary' as OptionType,
    },
    //  Disabled Web3 Payment for Compute Services
    ...(currentApp === ICurrentApp.COMPUTE
      ? []
      : [
          {
            id: 2,
            label: 'Deposit Funds',
            handleClick: () =>
              dispatchRtk(
                toggleModalShowRtk({
                  modalShow: true,
                  modalType: 'funds',
                  options: {
                    walletId: id,
                    type: 'deposit',
                  },
                })
              ),
            optionType: 'primary' as OptionType,
          },
        ]),

    // {
    //   id: 3,
    //   label: 'Withdraw Funds',
    //   handleClick: () =>
    //     dispatchRtk(
    //       toggleModalShowRtk({
    //         modalShow: true,
    //         modalType: 'funds',
    //         options: {
    //           type: 'withdraw',
    //           walletId: id,
    //         },
    //       })
    //     ),
    // optionType: 'primary' as OptionType,
    // },
    {
      id: 4,
      label: disconnectWalletLoading ? 'Disconnecting' : 'Disconnect',
      handleClick: () => {
        dispatchRtk(
          toggleModalShowRtk({
            modalShow: true,
            modalType: 'disconnectWallet',
            options: { id },
          })
        );
      },
      optionType: 'primary' as OptionType,
    },
  ];

  const solanaOptions = [
    {
      id: 1,
      label: 'View Wallet',
      handleClick: () =>
        dispatchRtk(
          toggleModalShowRtk({
            modalShow: true,
            modalType: 'viewPhantomWallet',
            options: {
              id,
            },
          })
        ),
      optionType: 'primary' as OptionType,
    },
    {
      id: 2,
      label: disconnectWalletLoading ? 'Disconnecting' : 'Disconnect',
      handleClick: () => {
        dispatchRtk(
          toggleModalShowRtk({
            modalShow: true,
            modalType: 'disconnectPhantomWallet',
            options: { walletAddress: address, id },
          })
        );
      },
      optionType: 'primary' as OptionType,
    },
  ];

  const dropdownOptions = isSolana ? solanaOptions : options;

  const dropdownOptionWithDisable =
    (!isPrimary && !isPublicAccessible) ||
    (isPublicAccessible && communityUserAccess)
      ? [
          ...dropdownOptions,
          {
            id: 5,
            label: 'Make as Primary',
            handleClick: () =>
              dispatchRtk(
                updateDefaultPaymentThunk({
                  organisationId: selectedOrganisationId,
                  newPaymentMethodId: id,
                })
              ),
            optionType: 'primary' as OptionType,
          },
        ]
      : dropdownOptions;

  return (
    <div
      className={`p-3 border border-base-border rounded-lg dark:border-dark-base-border
      min-w-325 ${
        isCardLoading ? 'border-base-border cursor-not-allowed' : ''
      } ${
        disabled
          ? `bg-action-primary-disable dark:bg-dark-action-primary-disable
        text-action-primary-text-disable dark:text-dark-action-primary-text-disable
        cursor-not-allowed`
          : 'dark:bg-dark-base-bg'
      }`}
    >
      <div className="flex items-start justify-between h-full space-x-3">
        <div className="flex justify-start space-x-3 items-start">
          {withLoader(
            address.length === 0,
            <Skeleton duration={2} height={32} width={32} borderRadius={32} />,
            <img
              src={makeBlockie(address || '0x1')}
              alt="Blockie"
              className="w-8 h-8 rounded-full"
            />
          )}

          <div className="h-full flex flex-col space-y-4 justify-between items-between">
            <div className="flex flex-col ">
              {withLoader(
                address.length === 0,
                <Skeleton duration={2} height={24} width={50} />,
                <div className="flex items-center justify-start">
                  <h3
                    className="text-base text-base-heading-text-color
                    dark:text-dark-base-heading-text-color tracking-tight font-semibold leading-6"
                  >
                    {shortAddress}
                  </h3>{' '}
                  {showDropdown && !disabled && (
                    <div ref={dropdownRef} className="relative">
                      <div
                        role="presentation"
                        className="ml-4 relative flex items-center justify-between
                       bg-base-fg dark:bg-dark-base-fg text-feedback-info-text 
                       text-xs font-semibold px-1.5 dark:text-dark-feedback-info-text
                       py-0.5 rounded gap-x-1 cursor-pointer"
                        onClick={() =>
                          setShowSwitchDropdown(!showSwitchDropdown)
                        }
                      >
                        Change Wallet
                        <Chevron
                          className={`transform cursor-pointer w-5 h-5
                       text-feedback-info-text dark:text-dark-feedback-info-text
                        dark:hover: ${
                          showSwitchDropdown ? '-rotate-90' : 'rotate-90'
                        }`}
                        />
                      </div>
                      {showSwitchDropdown && (
                        <div className="absolute top-[60%] left-3/4">
                          <SelectPaymentMethodCard
                            setShowDropdown={setShowSwitchDropdown}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}

              {withLoader(
                address.length === 0 || balanceLoading,
                <Skeleton
                  duration={2}
                  height={14}
                  width={100}
                  containerClassName="flex mt-1"
                />,
                <>
                  {!balance?.token ? (
                    <h5 className="text-sm  flex items-center justify-start">
                      Balance: NA{' '}
                      <div
                        role="presentation"
                        className="text-tiny cursor-pointer ml-1 hover:underline"
                        onClick={() => dispatchRtk(connectWalletThunk())}
                      >
                        (Connect Wallet)
                      </div>
                    </h5>
                  ) : (
                    <h5 className="text-sm  flex items-center justify-start">
                      Deposit: {displayBalance}{' '}
                      {getTokenNameFromAddress(balance?.token || '')}
                    </h5>
                  )}
                </>
              )}
            </div>
            <div className="h-full flex items-center justify-between">
              <div className="flex items-center justify-start">
                {withLoader(
                  address.length === 0,
                  <NetworkTokenSetLoading className="-mt-1.5" />,
                  <div className="flex items-center justify-start gap-x-4">
                    <ProviderNetworkTab
                      icon={network?.logo}
                      name={
                        getNetworkFromName(Number(network?.chainId)).split(
                          ' '
                        )[0]
                      }
                      divClassName="!border-0 py-0 !px-0 mr-1"
                    />
                    <ProviderNetworkTab
                      icon={token?.logoURI || ''}
                      name={token?.symbol || ''}
                      divClassName="!border-0 py-0 !px-0 mr-0"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="h-full flex flex-col justify-between items-end">
          {withLoader(
            address.length === 0,
            <Skeleton duration={2} height={24} width={24} />,
            <>
              {showDropdown ? (
                <></>
              ) : (
                <div className="-mt-3 -mr-4">
                  <Dropdown
                    dropdownSize="compact"
                    dropdownType="button"
                    buttonImage={<TripleDot />}
                    filled
                    options={disabled ? [] : dropdownOptionWithDisable}
                    bordersNone
                  />
                </div>
              )}
            </>
          )}
          <div className="-mb-1 self-end">
            <div className="flex items-center justify-end w-full">
              {withLoader(
                address.length === 0,
                <Skeleton duration={2} height={20} width={80} />,
                <Badge
                  badgeType={isPrimary ? 'info' : 'default'}
                  solid={false}
                  isBold
                  size="medium"
                  text={isPrimary ? 'Primary' : 'Backup'}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CryptoCard;
