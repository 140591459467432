import React, { useEffect, useState } from 'react';
import { Checkbox, OptionType } from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import MappingTextInput from '../InputFields/mapping-text-input';
import { ReactComponent as CrossIcon } from '../../../assets/compute/icons/cross-icon.svg';
import { IPorts } from '../../../redux/cluster/cluster.interfaces';
import { RootState } from '../../../redux/rtk-store';
import {
  ClusterType,
  IPLeaseType,
  LoadingType,
} from '../../../redux/instance/instance-creation/instance-creation.interfaces';
import { setMultiServicesIpLeaseType } from '../../../redux/instance/instance-creation/instance-creation.slice';
import { toggleModalShowRtk } from '../../../redux/modal/modal.slice';
import { multiserviceCalculatePriceThunk } from '../../../redux/instance/instance-creation/instance-creation.thunks';
import { AppDispatch } from '../../../redux/compute/store';

interface IProps {
  disableContainerPort?: boolean;
  disableRemove?: boolean;
  multiState: IPorts[];

  setMultiState: (
    // eslint-disable-next-line no-unused-vars
    multiState: IPorts[]
  ) => void;

  defaultDropdownOptions: {
    label: string;
    value: string;
    optionType: OptionType;
  }[];
  templatePorts: IPorts[];
  selectedServiceId: string;
}

const AddMultiserviceInstancePort = ({
  multiState,
  setMultiState,
  disableContainerPort,
  disableRemove,
  defaultDropdownOptions,
  templatePorts,
  selectedServiceId,
}: IProps) => {
  const [modalShown, setModalShown] = useState<boolean>(false);
  const [updatedMultiState, setUpdatedMultiState] = useState<IPorts[]>([]);
  const [handleCheckClicked, setHandleCheckClicked] = useState<boolean>(false);
  const [handleUnCheckClick, setHandleUnCheckClick] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string[]>([]);
  const [dropdownValue, setDropdownValue] = useState<string[]>([]);

  const dispatchRtk = useDispatch<AppDispatch>();
  const ipLeaseType = useSelector(
    (state: RootState) =>
      state.instanceCreation.multiserviceInstanceCreation![selectedServiceId]
        .ipLeaseType
  );
  const isDedicatedClicked = useSelector(
    (state: RootState) =>
      state.instanceCreation.multiserviceInstanceCreation![selectedServiceId]
        .isDedicatedClicked
  );
  const clusterType = useSelector(
    (state: RootState) => state.instanceCreation.clusterType
  );
  const hasEndpointPorts = multiState.some(
    (item) =>
      typeof item.endpointExposedPort !== 'undefined' &&
      item.endpointExposedPort !== ''
  );
  const hasNoDedicatedPorts = multiState.every((item) => !item.isChecked);
  const hasAllEnpointPorts = multiState.every(
    (item) =>
      typeof item.endpointExposedPort !== 'undefined' &&
      item.endpointExposedPort !== ''
  );
  const hasEndpointAdded = multiState.some(
    (item) => item.endpoint === 'endpoint'
  );
  const fillContainerPort = (value: string, id: number) => {
    const numericValue = parseInt(value, 10);
    let updatedValue = value;
    if (Number.isNaN(numericValue) || numericValue <= 0) {
      updatedValue = '';
    }
    setMultiState(
      multiState.map((item, i) =>
        i === id
          ? {
              containerPort: updatedValue.trim(),
              exposedPort: item.exposedPort,
              isChecked: item?.isChecked,
            }
          : item
      )
    );
  };
  const fillDropdownExposedPort = (value: string, id: number) => {
    setDropdownValue((prevState) => {
      const updatedValue = [...prevState];
      updatedValue[id] = value || '';
      return updatedValue;
    });
    setMultiState(
      multiState.map((item, i) =>
        i === id
          ? {
              containerPort: item.containerPort,
              exposedPort: value,
              isChecked: item?.isChecked,
            }
          : item
      )
    );
  };
  const fillInputExposedPort = (value: string, id: number) => {
    setInputValue((prevState) => {
      const updatedValue = [...prevState];
      updatedValue[id] = value.trim() || '';
      return updatedValue;
    });
    setMultiState(
      multiState.map((item, i) =>
        i === id
          ? {
              containerPort: item.containerPort,
              exposedPort: value.trim(),
              isChecked: item?.isChecked,
            }
          : item
      )
    );
  };
  const removeMultiStateItem = (id: number) => {
    if (multiState.length > 1) {
      if (!disableRemove)
        setMultiState(multiState.filter((item, i) => i !== id));
    }
    if (
      id === multiState.length - 1 &&
      ipLeaseType === IPLeaseType.DEDICATED &&
      hasNoDedicatedPorts
    ) {
      if (hasEndpointPorts && clusterType === ClusterType.TEMPLATE) {
        dispatchRtk(
          setMultiServicesIpLeaseType({
            serviceId: selectedServiceId,
            value: IPLeaseType.DEDICATED,
          })
        );
        dispatchRtk(
          multiserviceCalculatePriceThunk({
            serviceId: selectedServiceId,
            loadingType: LoadingType.IP_LEASE,
          })
        );
      } else {
        dispatchRtk(
          setMultiServicesIpLeaseType({
            serviceId: selectedServiceId,
            value: IPLeaseType.SHARED,
          })
        );
        dispatchRtk(
          multiserviceCalculatePriceThunk({
            serviceId: selectedServiceId,
            loadingType: LoadingType.IP_LEASE,
          })
        );
      }
    }
  };
  const showRemoveButton = multiState.length > 1;
  const handleDedicatedPort = (id: number) => {
    const item = multiState[id];
    const isTemplatePort = templatePorts.some(
      (templatePort) =>
        templatePort.containerPort === item.containerPort &&
        templatePort.exposedPort === item.exposedPort
    );
    const updatedMultiState = multiState.map((item, i) => {
      if (i === id) {
        if (!isTemplatePort) {
          return {
            ...item,
            exposedPort: item.isChecked
              ? dropdownValue[id] || ''
              : inputValue[id] || '',
            isChecked: !item.isChecked,
          };
        }
        return {
          ...item,
          isChecked: !item.isChecked,
        };
      }
      return item;
    });

    setHandleCheckClicked(true);
    setUpdatedMultiState(updatedMultiState);
    const isCheckedForFirstTime =
      !item.isChecked && updatedMultiState[id].isChecked;
    const allUnchecked = updatedMultiState.every((item) => !item.isChecked);

    if (ipLeaseType === IPLeaseType.SHARED && modalShown) {
      dispatchRtk(
        setMultiServicesIpLeaseType({
          serviceId: selectedServiceId,
          value: IPLeaseType.DEDICATED,
        })
      );
      dispatchRtk(
        multiserviceCalculatePriceThunk({
          serviceId: selectedServiceId,
          loadingType: LoadingType.IP_LEASE,
        })
      );
    }
    if (
      isCheckedForFirstTime &&
      !modalShown &&
      hasNoDedicatedPorts &&
      !hasEndpointAdded &&
      ipLeaseType !== IPLeaseType.DEDICATED
    ) {
      dispatchRtk(
        toggleModalShowRtk({
          modalShow: true,
          modalType: 'dedicatedIpConfirmation',
          options: {
            serviceId: selectedServiceId,
            multiservice: true,
          },
        })
      );
      setModalShown(true);
    } else if (allUnchecked && ipLeaseType === IPLeaseType.DEDICATED) {
      setHandleUnCheckClick(true);
      dispatchRtk(
        setMultiServicesIpLeaseType({
          serviceId: selectedServiceId,
          value: IPLeaseType.SHARED,
        })
      );
      dispatchRtk(
        multiserviceCalculatePriceThunk({
          serviceId: selectedServiceId,
          loadingType: LoadingType.IP_LEASE,
        })
      );
    }
  };

  useEffect(() => {
    if (
      !hasEndpointPorts &&
      ipLeaseType === IPLeaseType.DEDICATED &&
      clusterType === ClusterType.TEMPLATE &&
      multiState.length === templatePorts.length
    ) {
      setMultiState([
        ...multiState,
        { containerPort: '', exposedPort: '', isChecked: true },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ipLeaseType, hasEndpointPorts, clusterType]);

  useEffect(() => {
    if (ipLeaseType === IPLeaseType.DEDICATED) {
      setModalShown(true);
    } else if (ipLeaseType === IPLeaseType.SHARED) {
      const updatedMultiState = multiState.map((item) => ({
        ...item,
        isChecked: false,
      }));
      setMultiState(updatedMultiState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ipLeaseType]);
  useEffect(() => {
    if (
      (ipLeaseType === IPLeaseType.DEDICATED && handleCheckClicked) ||
      handleUnCheckClick
    ) {
      setMultiState(updatedMultiState);
      setHandleCheckClicked(false);
      setHandleUnCheckClick(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ipLeaseType, handleCheckClicked, handleUnCheckClick]);
  useEffect(() => {
    if (hasEndpointPorts) {
      const updatedMultiState = multiState.map((item) => {
        if (
          typeof item.endpointExposedPort !== 'undefined' &&
          item.endpointExposedPort !== '' &&
          item.endpoint
        ) {
          return {
            ...item,
            isChecked: true,
          };
        }
        return item;
      });
      setMultiState(updatedMultiState);
      if (hasEndpointAdded) {
        dispatchRtk(
          setMultiServicesIpLeaseType({
            serviceId: selectedServiceId,
            value: IPLeaseType.DEDICATED,
          })
        );
      } else {
        dispatchRtk(
          setMultiServicesIpLeaseType({
            serviceId: selectedServiceId,
            value: IPLeaseType.SHARED,
          })
        );
      }
      dispatchRtk(
        multiserviceCalculatePriceThunk({
          serviceId: selectedServiceId,
          loadingType: LoadingType.IP_LEASE,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchRtk, hasEndpointPorts, hasEndpointAdded]);
  useEffect(() => {
    if (hasAllEnpointPorts && isDedicatedClicked) {
      const updatedMultiState = multiState.map((item) => {
        if (
          typeof item.endpointExposedPort !== 'undefined' &&
          item.endpointExposedPort !== ''
        ) {
          return {
            ...item,
            isChecked: true,
          };
        }
        return item;
      });
      setMultiState(updatedMultiState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAllEnpointPorts, isDedicatedClicked]);
  return (
    <>
      <div className="flex flex-row">
        <div className="flex flex-col gap-x-6 gap-y-4 w-full">
          {multiState.map((item, i) => {
            const isTemplatePort = templatePorts.some(
              (templatePort) =>
                templatePort.containerPort === item.containerPort &&
                templatePort.exposedPort === item.exposedPort
            );
            // const shouldRenderCheckbox =
            //   !isTemplatePort ||
            //   (isTemplatePort && typeof item.endpoint !== 'undefined');
            const shouldRenderCheckbox = true;
            return (
              <>
                <div className="relative">
                  <MappingTextInput
                    typeFirst="number"
                    valueFirst={item.containerPort}
                    valueSecond={item.exposedPort}
                    placeholderFirst="Container Port"
                    disabledFirst={disableContainerPort}
                    onChangeFirst={(e) => fillContainerPort(e, i)}
                    onChangeSecond={(e: string) =>
                      item.isChecked
                        ? fillInputExposedPort(e, i)
                        : fillDropdownExposedPort(e, i)
                    }
                    dropdownOptions={defaultDropdownOptions}
                    disablePort={isTemplatePort}
                    endpointPort={item.endpointExposedPort}
                    isChecked={!!item.isChecked}
                  />
                  {!disableRemove && showRemoveButton && !isTemplatePort ? (
                    <span
                      role="presentation"
                      onClick={() => removeMultiStateItem(i)}
                      className="cursor-pointer absolute top-12 ll:top-4
                    ll:left-auto left-[55%] ll:right-4"
                    >
                      <CrossIcon
                        className="text-text-darkGray ml-4 
                hover:opacity-70 ease-in duration-75"
                      />
                    </span>
                  ) : null}
                </div>
                {shouldRenderCheckbox && (
                  <Checkbox
                    checked={item.isChecked || false}
                    onClick={() => handleDedicatedPort(i)}
                    label="Select Dedicated Port"
                    error={
                      ipLeaseType === IPLeaseType.DEDICATED &&
                      hasNoDedicatedPorts
                    }
                    errorText="Dedicated Port Required"
                    id={`multiservice-dedicated-port-${selectedServiceId}-${i}`}
                  />
                )}
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

AddMultiserviceInstancePort.defaultProps = {
  disableContainerPort: false,
  disableRemove: false,
};

export default AddMultiserviceInstancePort;
