import { requestPipeline } from '../root-utils';
import { IResponseError } from '../combined-reducer.interface';
import {
  IAuthorizeDeploymentResponse,
  ICloneDeploymentPayloadDto,
  ICloneDeploymentResponse,
  IDeploymentOrganisationRequestPayloadDto,
  IDeploymentStartDtoPayload,
  IDeploymentStartResponse,
  IFetchDeploymentRepoBranchPayloadDto,
  IFetchDeploymentRepoBranchResponse,
  IFetchRepositories,
  IGetDeploymentResponse,
  IGetRepoDetailsPayloadDto,
  IStopDeploymentResponse,
  ISuggestFrameworkPayloadDto,
  ISuggestFrameworkResponse,
} from './deployment.interfaces';
import { IDeployment } from '../combined-state.interface';

export const getAllDeploymentProviders = async (): Promise<any> => {
  return requestPipeline({
    url: `git-meta/providers`,
    method: 'GET',
  });
};

export const getAllProviderRepository = async (
  provider: string,
  appID: string,
  owner: string,
  type: string,
  skip: number,
  limit: number | '',
  nameFilter: string = ''
): Promise<IFetchRepositories> => {
  return requestPipeline({
    // eslint-disable-next-line max-len
    url: `git-meta/${provider}/app/${appID}/repositories?owner=${owner}&type=${type}&skip=${skip}&limit=${limit}&name=${nameFilter}`,
    method: 'GET',
  });
};

export const getSingleProviderRepository = async (
  provider: string,
  appID: string,
  owner: string,
  type: string,
  skip: number,
  limit: number | '',
  nameFilter: string = ''
): Promise<IFetchRepositories> => {
  return requestPipeline({
    // eslint-disable-next-line max-len
    url: `git-meta/${provider}/app/${appID}/repositories?owner=${owner}&type=${type}&skip=${skip}&limit=${limit}&name=${nameFilter}`,
    method: 'GET',
  });
};

export const getRepositoryBranches = async (
  payload: IFetchDeploymentRepoBranchPayloadDto
): Promise<IFetchDeploymentRepoBranchResponse> => {
  return requestPipeline({
    // eslint-disable-next-line max-len
    url: `git-meta/${payload.provider}/repo/branch/${
      payload.organizationId
    }?projectUrl=${payload.projectUrl}&name=${payload.name || ''}`,
    method: 'GET',
  });
};

export const getAllProviderAppOwners = async (
  provider: string
): Promise<any> => {
  return requestPipeline({
    url: `git-meta/${provider}/app`,
    method: 'GET',
  });
};

export const getAllDeployments = async (
  projectId: string,
  skip: number,
  limit: number
): Promise<IDeployment[] | IResponseError> => {
  return requestPipeline({
    url: `project/${projectId}/deployments?skip=${skip}&limit=${limit}`,
    method: 'GET',
    isPublic: true,
  });
};

export const getAllSuccessfulSitePreviews = async (
  projectId: string
): Promise<any> => {
  return requestPipeline({
    url: `project/${projectId}/links`,
    method: 'GET',
    isPublic: true,
  });
};

export const getDeployment = async (
  deploymentId: string
): Promise<IGetDeploymentResponse> => {
  return requestPipeline({
    url: `deployment/${deploymentId}`,
    method: 'GET',
    isPublic: true,
  });
};

export const startDeployment = async (
  deployment: IDeploymentStartDtoPayload
): Promise<IDeploymentStartResponse | IResponseError> => {
  return requestPipeline({
    url: `deployment`,
    method: 'POST',
    body: JSON.stringify(deployment),
  });
};

export const getAllDeploymentsDetailsService = async (
  projectId: string
): Promise<any> => {
  return requestPipeline({
    url: `project/${projectId}/deployments/count`,
    method: 'GET',
    isPublic: true,
  });
};

export const authorizeDeploymentService = async (
  deploymentId: string
): Promise<IAuthorizeDeploymentResponse | IResponseError> => {
  return requestPipeline({
    url: `deployment/${deploymentId}/authorize`,
    method: 'POST',
    body: JSON.stringify({ deploymentId }),
  });
};

export const stopDeploymentService = async (payload: {
  deploymentId: string;
  organizationId: string;
}): Promise<IStopDeploymentResponse | IResponseError> => {
  return requestPipeline({
    url: `deployment/${payload.deploymentId}/cancel`,
    method: 'POST',
    body: JSON.stringify(payload),
  });
};

export const triggerSpecificDeploymentService = async (
  payload: IDeploymentOrganisationRequestPayloadDto
): Promise<IDeploymentStartResponse | IResponseError> => {
  return requestPipeline({
    url: `deployment/${payload.deploymentId}/redeploy`,
    method: 'POST',
    body: JSON.stringify(payload),
  });
};

export const suggestFrameworkService = async (
  payload: ISuggestFrameworkPayloadDto
): Promise<ISuggestFrameworkResponse | IResponseError> => {
  return requestPipeline({
    // eslint-disable-next-line max-len
    url: `deployment/framework/suggestion?owner=${payload.owner}&branch=${payload.branch}&provider=${payload.provider}&repo=${payload.repo}&root=${payload.root}`,
    method: 'GET',
  });
};

export const cloneRepositoryService = async (
  payload: ICloneDeploymentPayloadDto
): Promise<ICloneDeploymentResponse | IResponseError> => {
  return requestPipeline({
    url: `project-template/clone/third-party`,
    method: 'POST',
    body: JSON.stringify(payload),
  });
};

export const getThirdPartyRepoInfoService = async (
  payload: IGetRepoDetailsPayloadDto
): Promise<any> => {
  return requestPipeline({
    url: `project-template/info`,
    method: 'POST',
    body: JSON.stringify(payload),
  });
};

export const deleteDeploymentService = async (id: string): Promise<any> => {
  return requestPipeline({
    url: `deployment/${id}`,
    method: 'DELETE',
  });
};
