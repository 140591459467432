import { PayloadAction, createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import {
  deleteClusterThunk,
  getAllClustersThunk,
  getClusterCountThunk,
  getClusterDetailsThunk,
  getClusterFundUsageThunk,
  getClusterInstanceCountThunk,
  getClusterTemplateCategoriesThunk,
  getClusterTemplateDetailsThunk,
  getClusterTemplatesThunk,
  getTemplateVideoMetadataThunk,
  getWalletUsageThunk,
  loadMoreClustersThunk,
} from './cluster.thunks';
import {
  IClusterDetails,
  IClusterState,
  IClusterTemplate,
  IClusterUsageResponseDto,
  IGetClustersResponse,
  IInstanceReport,
  ISelectedCluster,
  ITopupReport,
  ProcessingType,
} from './cluster.interfaces';

export const initialState: IClusterState = {
  clustersLoading: false,
  allClusters: [],
  totalClusters: 0,
  allClustersBilling: [],
  totalClustersBilling: 0,
  totalInstances: 0,
  totalInstancesBilling: 0,
  moreClustersLoading: false,
  selectedCluster: undefined,
  selectedTemplate: null,
  clusterPagination: {
    currentPage: 0,
    numbersPerPage: 6,
  },
  clusterPaginationBilling: {
    currentPage: 0,
    numbersPerPage: 6,
  },
  clusterDetails: null,
  clusterInstanceReport: null,
  clusterInstanceReportLoading: false,
  clusterFundUsage: null,
  clusterFundUsageLoading: false,
  clusterDetailsLoading: false,
  totalClustersTopup: 0,
  clusterWalletInstanceReportLoading: false,
  clusterCount: -1,
  clusterCountLoading: false,
  clusterWalletUsage: null,
  clusterWalletUsageLoading: true,
  deleteClusterLoading: false,
  clusterTemplateCategories: [],
  selectedClusterTemplateCategory: '',
  clusterTemplateCategoriesLoading: false,
  cpuClusterTemplates: [],
  gpuClusterTemplates: [],
  allClusterTemplates: [],
  clusterTemplatesLoading: false,
  clusterDeploymentProgress: 0,
  clusterTemplateDetailsLoading: false,
  selectedClusterTemplateDetails: null,
  templateVideoTitle: '',
};

const clusterSlice = createSlice({
  name: 'cluster',
  initialState,
  reducers: {
    setSelectedCluster(state, action: PayloadAction<ISelectedCluster>) {
      state.selectedCluster = action.payload;
      state.selectedClusterTemplateDetails = null;
      state.clusterDetails = null;
    },
    setSelectedTemplate(state, action: PayloadAction<IClusterTemplate | null>) {
      state.selectedTemplate = action.payload;
    },
    setSelectedTemplateCategory(state, action: PayloadAction<string>) {
      state.selectedClusterTemplateCategory = action.payload;
    },
    setClusterDeploymentProgress(state, action: PayloadAction<number>) {
      state.clusterDeploymentProgress = action.payload;
    },
    resetClusterState(state) {
      Object.assign(state, initialState);
    },
  },

  extraReducers: (builder) => {
    // GET ALL CLUSTERS
    builder.addCase(getAllClustersThunk.pending, (state) => {
      state.clustersLoading = true;
      state.clusterPagination = {
        currentPage: 0,
        numbersPerPage: 6,
      };
      state.clusterPaginationBilling = {
        currentPage: 0,
        numbersPerPage: 6,
      };
    });
    builder.addCase(
      getAllClustersThunk.fulfilled,
      (state, action: PayloadAction<IGetClustersResponse, string, any>) => {
        state.clustersLoading = false;
        if (action.meta.arg.topupReport === 'y') {
          state.allClustersBilling = action.payload.clusters;
          state.totalClustersBilling = action.payload.totalClusters;
          state.totalInstancesBilling = action.payload.totalInstances;
        } else {
          state.allClusters = action.payload.clusters;
          state.totalClusters = action.payload.totalClusters;
          state.totalInstances = action.payload.totalInstances;
        }
      }
    );
    builder.addCase(getAllClustersThunk.rejected, (state) => {
      state.clustersLoading = false;
    });

    // LOAD MORE CLUSTERS
    builder.addCase(loadMoreClustersThunk.pending, (state) => {
      state.moreClustersLoading = true;
    });
    builder.addCase(
      loadMoreClustersThunk.fulfilled,
      (state, action: PayloadAction<IGetClustersResponse, string, any>) => {
        state.moreClustersLoading = false;
        if (action.meta.arg.topupReport === 'y') {
          state.allClustersBilling = [
            ...state.allClustersBilling,
            ...action.payload.clusters,
          ];
          state.clusterPaginationBilling = {
            ...state.clusterPaginationBilling,
            currentPage: state.clusterPaginationBilling.currentPage + 1,
          };
        } else {
          state.allClusters = [
            ...state.allClusters,
            ...action.payload.clusters,
          ];
          state.clusterPagination = {
            ...state.clusterPagination,
            currentPage: state.clusterPagination.currentPage + 1,
          };
        }
      }
    );
    builder.addCase(loadMoreClustersThunk.rejected, (state) => {
      state.moreClustersLoading = false;
    });

    // GET CLUSTER INSTANCE COUNT
    builder.addCase(getClusterInstanceCountThunk.pending, (state) => {
      state.clusterInstanceReportLoading = true;
    });
    builder.addCase(
      getClusterInstanceCountThunk.fulfilled,
      (state, action: PayloadAction<IInstanceReport>) => {
        state.clusterInstanceReportLoading = false;
        state.clusterInstanceReport = action.payload;
      }
    );
    builder.addCase(getClusterInstanceCountThunk.rejected, (state) => {
      state.clusterInstanceReportLoading = false;
    });

    // GET CLUSTER DETAILS
    builder.addCase(getClusterDetailsThunk.pending, (state) => {
      state.clusterDetailsLoading = true;
    });
    builder.addCase(
      getClusterDetailsThunk.fulfilled,
      (state, action: PayloadAction<IClusterDetails | null>) => {
        state.clusterDetailsLoading = false;
        state.clusterDetails = action.payload;
        state.selectedClusterTemplateDetails = null;
      }
    );
    builder.addCase(getClusterDetailsThunk.rejected, (state) => {
      state.clusterDetailsLoading = false;
    });

    // GET CLUSTER FUND USAGE
    builder.addCase(getClusterFundUsageThunk.pending, (state) => {
      state.clusterFundUsageLoading = true;
    });
    builder.addCase(
      getClusterFundUsageThunk.fulfilled,
      (state, action: PayloadAction<ITopupReport>) => {
        state.clusterFundUsageLoading = false;
        state.clusterFundUsage = action.payload;
      }
    );
    builder.addCase(getClusterFundUsageThunk.rejected, (state) => {
      state.clusterFundUsageLoading = false;
    });

    // GET CLUSTER WALLET USAGE
    builder.addCase(getWalletUsageThunk.pending, (state) => {
      state.clusterWalletUsageLoading = true;
    });
    builder.addCase(
      getWalletUsageThunk.fulfilled,
      (state, action: PayloadAction<IClusterUsageResponseDto>) => {
        state.clusterWalletUsageLoading = false;
        state.clusterWalletUsage = action.payload;
      }
    );
    builder.addCase(getWalletUsageThunk.rejected, (state) => {
      state.clusterWalletUsageLoading = false;
    });

    // GET CLUSTER COUNT
    builder.addCase(getClusterCountThunk.pending, (state) => {
      state.clusterCountLoading = true;
    });
    builder.addCase(
      getClusterCountThunk.fulfilled,
      (state, action: PayloadAction<number>) => {
        state.clusterCountLoading = false;
        state.clusterCount = action.payload;
      }
    );
    builder.addCase(getClusterCountThunk.rejected, (state) => {
      state.clusterCountLoading = false;
    });

    // DELETE CLUSTER
    builder.addCase(deleteClusterThunk.pending, (state) => {
      state.deleteClusterLoading = true;
    });
    builder.addCase(
      deleteClusterThunk.fulfilled,
      (state, action: PayloadAction<string>) => {
        state.deleteClusterLoading = false;
        state.totalClusters -= 1;
        state.allClusters = state.allClusters.filter(
          (cluster) => cluster._id !== action.payload
        );
        state.selectedCluster = undefined;
        state.clusterDetails = null;
        state.clusterInstanceReport = null;
        state.clusterFundUsage = null;
        state.clusterDetailsLoading = false;
        state.totalClustersTopup = 0;
        state.clusterWalletInstanceReportLoading = false;
        state.clusterWalletUsage = null;
        state.clusterWalletUsageLoading = false;
      }
    );
    builder.addCase(deleteClusterThunk.rejected, (state) => {
      state.deleteClusterLoading = false;
    });

    // GET TEMPLATE CATEGORIES
    builder.addCase(getClusterTemplateCategoriesThunk.pending, (state) => {
      state.clusterTemplateCategoriesLoading = true;
    });
    builder.addCase(
      getClusterTemplateCategoriesThunk.fulfilled,
      (state, action: PayloadAction<string[]>) => {
        state.clusterTemplateCategoriesLoading = false;
        state.clusterTemplateCategories = action.payload;
      }
    );
    builder.addCase(getClusterTemplateCategoriesThunk.rejected, (state) => {
      state.clusterTemplateCategoriesLoading = false;
    });

    // GET CLUSTER TEMPLATES
    builder.addCase(getClusterTemplatesThunk.pending, (state) => {
      state.clusterTemplatesLoading = true;
    });
    builder.addCase(
      getClusterTemplatesThunk.fulfilled,
      (
        state,
        action: PayloadAction<{
          clusterTemplates: IClusterTemplate[];
          processingType: ProcessingType;
        }>
      ) => {
        const { clusterTemplates, processingType } = action.payload;
        if (processingType === ProcessingType.CPU)
          state.cpuClusterTemplates = clusterTemplates;
        else if (processingType === ProcessingType.GPU)
          state.gpuClusterTemplates = clusterTemplates;
        else state.allClusterTemplates = clusterTemplates;
        state.clusterTemplatesLoading = false;
      }
    );
    builder.addCase(getClusterTemplatesThunk.rejected, (state) => {
      state.clusterTemplatesLoading = false;
    });

    // GET TEMPLATE DETAILS
    builder.addCase(getClusterTemplateDetailsThunk.pending, (state) => {
      state.clusterTemplateDetailsLoading = true;
    });
    builder.addCase(
      getClusterTemplateDetailsThunk.fulfilled,
      (state, action: PayloadAction<{ clusterTemplate: IClusterTemplate }>) => {
        state.clusterTemplateDetailsLoading = false;
        state.selectedClusterTemplateDetails = action.payload.clusterTemplate;
      }
    );
    builder.addCase(getClusterTemplateDetailsThunk.rejected, (state) => {
      state.clusterTemplateDetailsLoading = false;
    });
    builder.addCase(
      getTemplateVideoMetadataThunk.fulfilled,
      (state, action: PayloadAction<{ title: string }>) => {
        state.templateVideoTitle = action.payload.title;
      }
    );
  },
});

export const { reducer: clusterRTKReducer } = clusterSlice;

export const {
  setSelectedCluster: setSelectedClusterRtk,
  setSelectedTemplate: setSelectedTemplateRtk,
  setSelectedTemplateCategory: setSelectedTemplateCategoryRtk,
  setClusterDeploymentProgress: setClusterDeploymentProgressRtk,
  resetClusterState: resetClusterStateRtk,
} = clusterSlice.actions;
