import dayjs from 'dayjs';
import {
  Badge,
  BadgeType,
  NextPrevType,
  Pagination,
  PaginationShape,
  Table,
  TableCellProps,
} from '@spheron/ui-library';
import { ReactComponent as ExternalLinkIcon } from '@spheron/ui-library/dist/assets/spheron-specific/external-link.svg';
import React, { useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import config from '../../../config';
import { addNotificationRtk } from '../../../redux/compute/notification/notification.slice';
import { NotificationType } from '../../../redux/compute/notification/notification.interfaces';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import {
  IGetComputeProjectInstancesResponseDto,
  IInstance,
} from '../../../redux/compute/project/project.interfaces';
import { setSelectedComputeProjectInstancesRtk } from '../../../redux/compute/project/project.slice';
import { getComputeProjectInstancesService } from '../../../redux/compute/project/project.services';
import TableLoader from '../Loaders/table-loader';
import {
  InstanceCategory,
  InstanceState,
} from '../../../redux/compute/cluster/cluster.interfaces';

interface IProps {
  searchInstances: any;
  // eslint-disable-next-line no-unused-vars
  setSearchInstances: (instance: any) => void;
  searchValue: string;
}

const ClusterBillingTableCard = ({
  searchInstances,
  setSearchInstances,
  searchValue,
}: IProps) => {
  const communityOrgName = sessionStorage.getItem('communityOrgName');
  const isPublicAccessible =
    sessionStorage.getItem('isPublicAccessible') === 'true';
  const navigate = useNavigate();
  const params = useParams<{ clusterId: string }>();
  const dispatchRtk = useDispatch<AppDispatch>();
  const LIMIT = 4;

  const clusterInstanceReport = useSelector(
    (state: RootState) => state.computeProject.selectedProjectInstanceCount
  );

  const instances = useSelector(
    (state: RootState) => state.computeProject.selectedProjectInstances
  );

  const instancesLoading: boolean = useSelector(
    (state: RootState) => state.computeProject.selectedProjectInstancesLoading
  );

  const selectedOrganisation = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation
  );
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [morePageLoading, setMorePageLoading] = useState<boolean>(false);

  useEffect(() => {
    if (instances) {
      setSearchInstances([...instances.slice(0, 4)]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instances]);

  useEffect(() => {
    if (searchValue.length === 0) {
      setSearchInstances([...instances.slice(0, 4)]);
    } else {
      const filteredInstances = instances.filter((instance: IInstance) =>
        instance?._id.toLowerCase().includes(searchValue.toLowerCase())
      );
      setSearchInstances(filteredInstances);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const openInstance = (instanceId: string) => {
    if (!isPublicAccessible) {
      navigate(`/compute/${params.clusterId}/${instanceId}`);
      return;
    }
    navigate(
      `/${isPublicAccessible ? communityOrgName : 'compute'}/org/${
        selectedOrganisation?._id
      }/cluster/${params.clusterId}/instance/${instanceId}`
    );
  };

  const componentIsMounted = useRef(true);

  useEffect(() => {
    componentIsMounted.current = true;

    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const loadMoreComputeProjectInstance = (page: number) => {
    setMorePageLoading(true);
    getComputeProjectInstancesService(
      params?.clusterId || '',
      'y',
      page * 3,
      LIMIT,
      ''
    )
      .then((response) => {
        if (componentIsMounted.current) {
          dispatchRtk(
            setSelectedComputeProjectInstancesRtk(
              (response as IGetComputeProjectInstancesResponseDto)
                .extendedInstances
            )
          );
          setMorePageLoading(false);
        }
      })
      .catch((error: Error) => {
        dispatchRtk(
          addNotificationRtk({
            message: (error as Error).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
      });
  };

  const tableHeaders: TableCellProps[] = [
    {
      id: 0,
      title: 'Instance ID',
      tableCellType: 'header',
    },
    {
      id: 1,
      title: 'Date Created',
      tableCellType: 'header',
    },
    {
      id: 2,
      title: 'Last Updated',
      tableCellType: 'header',
    },
    {
      id: 3,
      title: 'Monthly Cost',
      tableCellType: 'header',
    },
    {
      id: 4,
      title: 'Total Spent',
      tableCellType: 'header',
    },
    {
      id: 5,
      title: 'Status',
      tableCellType: 'header',
    },
  ];

  const instanceBadgeType = (status: InstanceState) => {
    switch (status) {
      case InstanceState.STARTING:
        return { badgeType: 'info', badgeText: InstanceCategory.PROVISIONING };
      case InstanceState.CLOSED:
        return { badgeType: 'error', badgeText: InstanceCategory.CLOSED };
      case InstanceState.FAILED_START:
        return { badgeType: 'error', badgeText: InstanceCategory.FAILED };
      case InstanceState.ACTIVE:
        return {
          badgeType: 'success',
          badgeText: InstanceCategory.PROVISIONED,
        };
      default:
        return {
          badgeType: 'default',
          badgeText: InstanceCategory.PROVISIONING,
        };
    }
  };

  return (
    <>
      {instancesLoading || morePageLoading ? (
        <TableLoader
          headers={tableHeaders.map((header) => header.title || '')}
        />
      ) : (
        <Table
          tableHeader={{
            tableRowType: 'record',
            tableRow: tableHeaders,
          }}
          tableRows={searchInstances.map((instance: IInstance, i: number) => ({
            id: i,
            tableRowType: 'record',
            tableRow: [
              {
                id: 0,
                content: (
                  <div className="flex flex-row items-center justify-between gap-4">
                    <span
                      className="text-base-para-text-color dark:text-dark-base-para-text-color
                      text-[14px] leading-5 font-400"
                    >
                      {instance?._id}
                    </span>
                    {!instance?.deletedAt && (
                      <span
                        className="text-action-primary-default text-sm font-medium
                            cursor-pointer hover:underline"
                        onClick={() => openInstance(instance?._id)}
                        role="presentation"
                      >
                        <ExternalLinkIcon
                          className="w-4 h-4 text-base-para-text-color 
                          dark:text-dark-base-para-text-color"
                        />
                      </span>
                    )}
                  </div>
                ),
                tableCellType: 'default',
              },
              {
                id: 1,
                title: `${dayjs(`${instance?.createdAt}`).format(
                  'MMMM DD, YYYY h:mm A'
                )}`,
                tableCellType: 'title',
              },
              {
                id: 2,
                title: `${dayjs(`${instance?.updatedAt}`).format(
                  'MMMM DD, YYYY h:mm A'
                )}`,
                tableCellType: 'title',
              },
              {
                id: 3,
                title: `$
                      ${(instance?.topupReport?.dailyUsage * 30)?.toFixed(
                        config.dollarDecimalRound.DOLLAR_DECIMAL_ROUND
                      )}`,
                tableCellType: 'title',
              },
              {
                id: 4,
                title: `$
                      ${instance?.topupReport?.usedTillNow?.toFixed(
                        config.dollarDecimalRound.DOLLAR_DECIMAL_ROUND
                      )}`,
                tableCellType: 'title',
              },
              {
                id: 4,
                content: (
                  <Badge
                    badgeType={
                      instanceBadgeType(
                        instance?.state as unknown as InstanceState
                      ).badgeType as BadgeType
                    }
                    text={
                      instanceBadgeType(
                        instance?.state as unknown as InstanceState
                      ).badgeText
                    }
                    size="large"
                    solid={false}
                  />
                ),
                tableCellType: 'default',
              },
            ],
          }))}
        />
      )}
      <div className="flex flex-col lg:flex-row justify-between items-center mt-3">
        {!instancesLoading ? (
          <div className="text-gray-400 text-sm">
            Page {currentPage} - {Math.ceil(clusterInstanceReport! / 4)}
          </div>
        ) : (
          <Skeleton
            width={120}
            height={20}
            containerClassName="flex"
            duration={2}
          />
        )}
        <div className="flex justify-center items-center">
          {!instancesLoading ? (
            <>
              {clusterInstanceReport! > 4 && (
                <Pagination
                  paginationList={clusterInstanceReport!}
                  numberPerPage={4}
                  handlePrevClick={(i) => {
                    loadMoreComputeProjectInstance(i);
                    setCurrentPage(i);
                  }}
                  handleNextClick={(i) => {
                    loadMoreComputeProjectInstance(i);
                    setCurrentPage(i);
                  }}
                  handleClick={(i) => {
                    loadMoreComputeProjectInstance(i);
                    setCurrentPage(i);
                  }}
                  shape={PaginationShape.SQUARE}
                  actionType={NextPrevType.TEXT}
                />
              )}
            </>
          ) : (
            <>
              <Skeleton
                width={140}
                height={20}
                containerClassName="flex"
                duration={2}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ClusterBillingTableCard;
