import React from 'react';
import {
  BreadcrumbsBar,
  Button,
  Dropdown,
  OptionType,
  Refresh,
  TextInput,
} from '@spheron/ui-library';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { withLoader } from '../../../redux/compute/root-utils';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import { ReactComponent as SearchIcon } from '../../../assets/compute/icons/search.svg';
import ComputeDashboardBreadcrumbLoading from '../Loaders/compute-dashboard-breadcrumb-loading';
import { resetComputeProjectInstancePaginationState } from '../../../redux/compute/project/project.slice';
import { getComputeProjectInstancesThunk } from '../../../redux/compute/project/project.thunks';

interface IProps {
  searchValue: string;
  selectedInstances: string[];
  dropdownOption: string;
  instanceFilterOptions: {
    id: number;
    optionType: OptionType;
    label: string;
    value: string;
  }[];
  // eslint-disable-next-line no-unused-vars
  setDropdownOption: (value: string) => void;
  // eslint-disable-next-line no-unused-vars
  handleSearch: (value: string) => void;
  handleCreateNewInstance: () => void;
}

const InstanceDashboardBreadcrumbs = ({
  searchValue,
  selectedInstances,
  dropdownOption,
  instanceFilterOptions,
  handleSearch,
  setDropdownOption,
  handleCreateNewInstance,
}: IProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { projectId, orgUsername } = useParams<{
    projectId: string;
    orgUsername: string;
  }>();
  const selectedComputeProjectName = useSelector(
    (state: RootState) => state.computeProject.selectedProjectName
  );
  const instancesLoading = useSelector(
    (state: RootState) => state.computeProject.selectedProjectInstancesLoading
  );
  const selectedOrganisationLoading = useSelector(
    (state: RootState) => state.organisation.selectedOrganisationLoading
  );
  const instances = useSelector(
    (state: RootState) => state.computeProject.selectedProjectInstances
  );

  const breadcrumbItems = [
    {
      id: 1,
      handleClick: () => navigate(`/compute/dashboard`),
      linkName: 'Projects',
      urlPath: `/${orgUsername}/dashboard`,
    },
    {
      id: 2,
      handleClick: () => navigate(`/compute/project/${projectId}`),
      linkName: `${selectedComputeProjectName}`,
      urlPath: `/${orgUsername}/project/${projectId}`,
    },
  ];

  const handleRefresh = () => {
    dispatch(resetComputeProjectInstancePaginationState());
    dispatch(
      getComputeProjectInstancesThunk({
        computeProjectId: projectId || '',
        topupReport: 'n',
      })
    ) as any;
  };

  return (
    <>
      {withLoader(
        instancesLoading ||
          (instances.length === 0 &&
            projectId &&
            !selectedComputeProjectName) ||
          selectedOrganisationLoading,
        <ComputeDashboardBreadcrumbLoading isInstanceDashboard={!!projectId} />,
        <>
          {' '}
          {(!instancesLoading || instances.length > 0) && (
            <div className="bg-base-bg dark:bg-dark-base-bg">
              <div className="w-[95%] mx-auto max-w-[1400px]">
                <BreadcrumbsBar
                  links={breadcrumbItems}
                  pageHeading=""
                  activeLink={window.location.pathname
                    .split('/')
                    .slice(0, 4)
                    .join('/')}
                  type="default"
                  actions={
                    selectedInstances?.length === 0
                      ? [
                          {
                            id: 0,
                            action: (
                              <div>
                                {instances.length > 0 && projectId && (
                                  <Refresh
                                    showText
                                    handleClick={() => {
                                      handleRefresh();
                                    }}
                                    loading={instancesLoading}
                                    refreshType="default"
                                    time=""
                                    customText="Refresh"
                                  />
                                )}
                              </div>
                            ),
                          },
                          {
                            id: 1,
                            action: (
                              <div>
                                {instances.length > 0 && (
                                  <TextInput
                                    inputSize="compact"
                                    leftIcon={
                                      <SearchIcon
                                        className="text-base-para-text-color
                        dark:text-dark-base-para-text-color"
                                      />
                                    }
                                    placeholder="Search"
                                    value={searchValue}
                                    onChange={(value) =>
                                      handleSearch(value as string)
                                    }
                                  />
                                )}
                              </div>
                            ),
                          },
                          {
                            id: 2,
                            action: (
                              <div>
                                {instances.length > 0 && (
                                  <div className="flex items-center justify-start gap-x-4">
                                    {projectId && (
                                      <div className="flex items-center justify-start gap-x-3">
                                        Filter:
                                        <Dropdown
                                          dropdownSize="default"
                                          className="w-300"
                                          dropdownSectionClassName="min-w-[94px]"
                                          dropdownType="button"
                                          label=""
                                          filled
                                          onSelected={(value: any) => {
                                            setDropdownOption(
                                              value.value as string
                                            );
                                          }}
                                          subText=""
                                          defaultSelected={dropdownOption}
                                          options={instanceFilterOptions}
                                        />
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            ),
                          },
                          {
                            id: 3,
                            action: (
                              <div>
                                <Button
                                  buttonType="primary"
                                  size="medium"
                                  label="Create New Instance"
                                  onClick={handleCreateNewInstance}
                                  disabled
                                />
                              </div>
                            ),
                          },
                        ]
                      : []
                  }
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default InstanceDashboardBreadcrumbs;
