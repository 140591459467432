export interface IThemeState {
  theme: ITheme | null;
}

export enum ITheme {
  // eslint-disable-next-line no-unused-vars
  LIGHT = 'light',
  // eslint-disable-next-line no-unused-vars
  DARK = 'dark',
}

export interface IThemeUpdateResponse {
  preferences: {
    theme: string;
    referralEnabled: boolean;
  };
}
