import React, { useState, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  Button,
  SectionHeading,
  Dropdown,
  IOptions,
  OptionType,
  AttachedPaymentMethodCard,
} from '@spheron/ui-library';
import { ReactComponent as PlusIcon } from '@spheron/ui-library/dist/assets/plus.svg';
import { ReactComponent as ArrowRight } from '@spheron/ui-library/dist/assets/arrow-right-3.svg';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Web3PaymentIcon } from '../../../../assets/compute/icons/web3wallet.svg';
import { ReactComponent as CreditCardPayment } from '../../../../assets/compute/icons/credit-card.svg';
import {
  ICurrentApp,
  ISpheronWallet,
} from '../../../../redux/compute/organisation/organisation.interfaces';
import { ISubscription } from '../../../../redux/compute/subscription/subscription.interfaces';
import {
  IOrganization,
  PaymentMethod,
} from '../../../../redux/compute/combined-state.interface';
import { mapCurrentAppToSpecialization } from '../../../../redux/compute/organisation/organisation.utils';
import BonusMembers from '../../../../components/Compute/Sections/bonus-members';
import { ConnectionState } from '../../../../redux/compute/stripe/stripe.interfaces';
import { toggleModalShowRtk } from '../../../../redux/compute/modal/modal.slice';
import { getSubscriptionUsageThunk } from '../../../../redux/compute/subscription/subscription.thunks';
import { updateDefaultPaymentThunk } from '../../../../redux/compute/organisation/organisation.thunks';
import { AppDispatch, RootState } from '../../../../redux/compute/store';
import ComputeUsage from '../../../../components/Compute/Cards/compute-usage';
import {
  getClusterCountThunk,
  getWalletUsageThunk,
} from '../../../../redux/compute/cluster/cluster.thunks';
import { getAllComputeProjectsThunk } from '../../../../redux/compute/project/project.thunks';
import RecurringTopup from '../../../../components/Compute/Cards/recurring-topup';
import UseOnClickOutside from '../../../../hooks/useOnClickOutside';
import { ReactComponent as CreditIcon } from '../../../../assets/compute/icons/credit-icon.svg';
import NoCard from '../../../../components/Compute/EmptyList/no-card';
import CryptoCardInfo from '../../../../components/Compute/Cards/crypto-card-info';
import CardInfo from '../../../../components/Compute/Cards/card-info';
import { toggleAddingPaymentMethodRtk } from '../../../../redux/compute/organisation/organisation.slice';
import { addCreditCardThunk } from '../../../../redux/compute/stripe/stripe.thunks';

const CurrentPlan = () => {
  const isPublicAccessible =
    sessionStorage.getItem('isPublicAccessible') === 'true';
  const communityUserAccess =
    sessionStorage.getItem('communityUserAccess') === 'true';

  const dispatchRtk = useDispatch<AppDispatch>();
  const [showAddPaymentMethod, setShowAddPaymentMethod] =
    useState<boolean>(false);

  const reservedBonus = useSelector((state: RootState) =>
    state.subscription.activeBonus.filter((bonus) => bonus.state === 'RESERVED')
  );

  const activeIsDefaultPackage: boolean = useSelector(
    (state: RootState) => state.subscription.activeIsDefaultPackage
  );

  const selectedOrganisation: IOrganization | null = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation
  );

  const activeSubscription: ISubscription | null = useSelector(
    (state: RootState) => state.subscription.activeSubscription
  );

  const selectedOrganisationLoading = useSelector(
    (state: RootState) => state.organisation.selectedOrganisationLoading
  );
  const subscriptionLoading: boolean = useSelector(
    (state: RootState) => state.subscription.subscriptionsLoading
  );

  const subscriptionUsage = useSelector(
    (state: RootState) => state.subscription.subscriptionUsage
  );
  const allUsers = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation.users
  );
  const user = useSelector((state: RootState) => state.user.user);
  const organisationId = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation._id
  );

  const selectedToken = useSelector(
    (state: RootState) =>
      state.organisation.selectedOrgWalletConfig?.tokenDetails?.symbol
  );

  const currentApp = useSelector(
    (state: RootState) => state.organisation.currentApp
  );

  const activeBonusLoading: boolean = useSelector(
    (state: RootState) => state.subscription.activeBonusLoading
  );

  // const savingCreditCard: boolean = useSelector(
  //   (state: RootState) => state.stripe.savingCreditCard
  // );

  const creditCards = useSelector(
    (state: RootState) => state.stripe.creditCards
  ).filter((card) => card.connectionState === ConnectionState.CONNECTED);

  const fetchingCreditCards = useSelector(
    (state: RootState) => state.stripe.fetchingCreditCards
  );

  const web3WalletsLoading = useSelector(
    (state: RootState) => state.web3.walletLoading
  );

  const web3Wallets = useSelector((state: RootState) => state.web3.wallets);

  const spheronWallet = useSelector(
    (state: RootState) => state.organisation.spheronWallet
  );

  const selectedOrgSpheronWalletConfig = useSelector(
    (state: RootState) => state.organisation.selectedOrgSpheronWalletConfig
  );

  const referralBalance = (spheronWallet?.details as ISpheronWallet)
    ?.referralBalance;

  const creditsBalance = (spheronWallet?.details as ISpheronWallet)
    ?.creditsBalance;

  const selectedOrgWalletConfig = useSelector(
    (state: RootState) => state.organisation.selectedOrgWalletConfig
  );

  // const walletBalanceLoading = useSelector(
  //   (state: RootState) => state.web3.walletBalanceLoading
  // );

  const clusterWalletInstanceReportLoading: boolean = useSelector(
    (state: RootState) => state.cluster.clusterWalletInstanceReportLoading
  );

  const clusterWalletUsageLoading: boolean = useSelector(
    (state: RootState) => state.cluster.clusterWalletUsageLoading
  );

  const computeProjectPagination = useSelector(
    (state: RootState) => state.computeProject.billingPagination
  );

  const dropdownRef = useRef(null);

  UseOnClickOutside(dropdownRef, () => setShowAddPaymentMethod(false));

  const isMember =
    allUsers?.find((u) => u?._id === user?._id)?.platformProfile?.role ===
      'member' && user !== null;

  const refreshCurrentPlanData = () => {
    if (selectedOrganisation) {
      dispatchRtk(getClusterCountThunk(selectedOrganisation?._id));

      dispatchRtk(getWalletUsageThunk(selectedOrganisation?._id));
      dispatchRtk(
        getSubscriptionUsageThunk({
          organizationId: selectedOrganisation?._id,
          specialization: mapCurrentAppToSpecialization(ICurrentApp.COMPUTE),
        })
      );
      const payload = {
        organisationId: selectedOrganisation._id,
        topupReport: 'y',
        computeProjectPagination,
      };
      dispatchRtk(getAllComputeProjectsThunk(payload));
    }
  };

  // const refreshPaymentMethods = () => {
  //   dispatchRtk(getSelectedOrgWalletThunk(selectedOrganisation._id));
  // };

  const isCompute: boolean = currentApp === ICurrentApp.COMPUTE;

  const handleAddMember = () => {
    if (
      !(
        selectedOrganisationLoading ||
        subscriptionLoading ||
        activeBonusLoading ||
        isMember
      )
    )
      dispatchRtk(
        toggleModalShowRtk({
          modalShow: true,
          modalType: 'addMembers',
          options: {
            walletAddress: selectedOrgWalletConfig?.address,
          },
        })
      );
  };

  const showAddMember = () => {
    return (
      !(selectedOrganisationLoading || subscriptionLoading) &&
      activeSubscription?.subscriptionPackage.name === 'PRO' &&
      selectedToken === 'SOL'
    );
  };

  const refreshLoading =
    currentApp === ICurrentApp.COMPUTE
      ? clusterWalletInstanceReportLoading || clusterWalletUsageLoading
      : subscriptionLoading;

  const handleAddMemberDisable =
    selectedOrganisationLoading ||
    subscriptionLoading ||
    activeBonusLoading ||
    isMember ||
    (isPublicAccessible && !communityUserAccess);

  const paymentMethods: IOptions[] = [
    ...(!isPublicAccessible || communityUserAccess
      ? [
          {
            id: 1,
            label: 'Credit / Debit Card',
            value: 'Credit / Debit Card',
            leftIcon: <CreditCardPayment className="w-5 h-5" />,
            rightIcon: <ArrowRight className="w-4 h-4" />,
            optionType: 'primary' as OptionType,
            handleClick: () => {
              setShowAddPaymentMethod(false);
              dispatchRtk(toggleAddingPaymentMethodRtk(true));
              dispatchRtk(
                addCreditCardThunk({
                  organizationId: organisationId,
                  details: {
                    cardPaymentMethodType: 'card',
                    cardService: 'stripe',
                  },
                  paymentMethod: PaymentMethod.CREDITCARD,
                })
              );
            },
          },
        ]
      : []),
    //  Disabled Web3 Payment for Compute Services
    ...(currentApp === ICurrentApp.COMPUTE
      ? []
      : [
          {
            id: 2,
            label: 'Web3 Payment',
            value: 'Web3 Payment',
            leftIcon: <Web3PaymentIcon className="w-5 h-5 dark:text-white" />,
            rightIcon: <ArrowRight className="w-4 h-4" />,
            optionType: 'primary' as OptionType,
            handleClick: () => {
              setShowAddPaymentMethod(false);
              dispatchRtk(
                toggleModalShowRtk({
                  modalType: 'connectWalletParameters',
                  modalShow: true,
                })
              );
            },
          },
        ]),
  ];

  const dropdownOptions = [
    {
      id: 1,
      label: 'Make as Primary',
      handleClick: () =>
        dispatchRtk(
          updateDefaultPaymentThunk({
            organisationId: selectedOrganisation?._id || '',
            newPaymentMethodId: spheronWallet?._id || '',
          })
        ),
      optionType: 'primary' as OptionType,
    },
  ];

  return (
    <>
      <div className="flex flex-row items-center justify-between">
        <SectionHeading
          showRefresh
          showSwitch={false}
          subHeading=""
          heading="Current Plan Usage"
          handleIconClick={() => {}}
          handleRefreshClick={refreshCurrentPlanData}
          handleClick={() => {}}
          loading={refreshLoading}
          time=""
          refreshType={'default'}
          showText={false}
          toggleId=""
          isChecked
          handleChange={() => {}}
          switchDisable={false}
          handleSwitchClick={() => {}}
        />
      </div>
      <>
        <div className="flex flex-col gap-y-10">
          <ComputeUsage />
          <RecurringTopup isMember={isMember} />
        </div>
      </>

      <>
        <div className="mt-10">
          <div className="flex flex-col gap-y-5">
            <SectionHeading
              showRefresh={false}
              showSwitch={false}
              headingRightIcon={
                <Dropdown
                  dropdownType="button"
                  dropdownSize="default"
                  dropdownClassname="!left-full !right-auto !z-10 !w-[256px] !min-w-64 !mt-0"
                  bordersNone
                  disable={selectedOrganisationLoading || subscriptionLoading}
                  buttonImage={
                    <Button
                      buttonType="primary"
                      label=""
                      size="small"
                      classname="justify-center !w-6 !h-6"
                      onClick={() => {}}
                      leftIcon={<PlusIcon className="w-5 h-5 text-white" />}
                      disabled={
                        selectedOrganisationLoading || subscriptionLoading
                      }
                    />
                  }
                  options={paymentMethods}
                />
              }
              subHeading="Manage Your Preferred Payment Options Easily"
              heading="Payment Methods"
              handleHeadingRightIconClick={() =>
                setShowAddPaymentMethod(!showAddPaymentMethod)
              }
              handleIconClick={() => null}
              handleRefreshClick={() => null}
              handleSwitchClick={() => null}
              switchDisable={false}
              handleClick={() => null}
              loading={false}
              time=""
              refreshType="default"
              showText={false}
              toggleId={''}
              isChecked={false}
              handleChange={() => null}
            />
          </div>
          <div className="mt-6">
            {creditCards.length === 0 &&
            web3Wallets.length === 0 &&
            !spheronWallet &&
            !fetchingCreditCards &&
            !web3WalletsLoading ? (
              <NoCard options={paymentMethods} />
            ) : (
              <div
                className="grid grid-cols-1 md:grid-cols-2
              md:gap-2 lg:gap-4 xl:gap-6"
              >
                {fetchingCreditCards ? (
                  <Skeleton
                    height={102}
                    width="100%"
                    borderRadius={4}
                    duration={2}
                  />
                ) : (
                  <>
                    <AttachedPaymentMethodCard
                      id="spheron-wallet"
                      cardType="wallet"
                      icon={
                        <CreditIcon className="w-8 h-8 rounded-fulltext-base-text dark:text-dark-base-text" />
                      }
                      isPrimary={
                        selectedOrgSpheronWalletConfig?.primary || false
                      }
                      title="Spheron Credit"
                      subtitle={`Coupon Credit: $${Number(
                        creditsBalance?.toFixed(2)
                      )} | Referral Credit: $${Number(
                        referralBalance?.toFixed(2)
                      )} | Total Credit: $${Number(
                        (referralBalance + creditsBalance)?.toFixed(2)
                      )}`}
                      hasDropdown
                      dropdownOptions={isMember ? [] : dropdownOptions}
                      hasWalletSwitchDropdown={false}
                      walletDropdownOptions={[]}
                    />
                    {creditCards.map((creditCard) => (
                      <CardInfo
                        disabled={isMember}
                        key={creditCard._id}
                        creditCard={creditCard}
                      />
                    ))}
                  </>
                )}
                {web3WalletsLoading ? (
                  <Skeleton
                    height={102}
                    width="100%"
                    borderRadius={4}
                    duration={2}
                  />
                ) : (
                  <>
                    {web3Wallets.map((wallet) => (
                      <CryptoCardInfo
                        disabled={isMember}
                        key={wallet._id}
                        wallet={wallet}
                      />
                    ))}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        {reservedBonus?.length > 0 && selectedToken === 'SOL' && (
          <>
            <div className="flex justify-between items-center dark:border-gray-600 mt-10">
              {!activeIsDefaultPackage && !isCompute && (
                <SectionHeading
                  showRefresh={false}
                  showSwitch={false}
                  subHeading="Pending add-ons that need to be resolved"
                  heading="Unresolved Add-on"
                  handleIconClick={() => null}
                  handleRefreshClick={() => null}
                  handleSwitchClick={() => null}
                  switchDisable={false}
                  handleClick={() => null}
                  loading={false}
                  time=""
                  refreshType="default"
                  showText={false}
                  toggleId={''}
                  isChecked={false}
                  handleChange={() => null}
                />
              )}
            </div>
          </>
        )}

        {showAddMember() && (
          <div className="mt-3">
            <BonusMembers
              handleClick={handleAddMember}
              disableButton={handleAddMemberDisable}
              memberCount={subscriptionUsage.membersLimit || 0}
              memberPrice={activeSubscription?.subscriptionPackage.price || 0}
            />
          </div>
        )}
      </>
    </>
  );
};

export default CurrentPlan;
