import React, { useEffect } from 'react';
import { Radio, Tooltip } from '@spheron/ui-library';
import { ReactComponent as DollarIcon } from '@spheron/ui-library/dist/assets/dollar.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  ClusterType,
  IPLeaseType,
  LoadingType,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.interfaces';
// eslint-disable-next-line max-len
import { isAccelerateDeployment } from '../../../../../redux/compute/instance/instance-creation/instance-creation.utils';
import { AppDispatch, RootState } from '../../../../../redux/compute/store';
import {
  setIpLeaseType,
  setIsDedicatedClicked,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.slice';
import { IPorts } from '../../../../../redux/compute/cluster/cluster.interfaces';
import { calculatePriceThunk } from '../../../../../redux/compute/instance/instance-creation/instance-creation.thunks';

interface IProps {
  isUpdate: boolean;
  portMapping: IPorts[];
}

const IpLease = ({ isUpdate, portMapping }: IProps) => {
  const hasEmptyCheck = portMapping.every((item) => !item.isChecked);

  const dispatchRtk = useDispatch<AppDispatch>();
  const clusterType = useSelector(
    (state: RootState) => state.instanceCreation.clusterType
  );
  const deploymentType = useSelector(
    (state: RootState) => state.instanceCreation.deploymentType
  );
  const ipLeaseType = useSelector(
    (state: RootState) => state.instanceCreation.ipLeaseType
  );
  const selectedTemplate = useSelector(
    (state: RootState) => state.cluster.selectedTemplate
  );
  const stepNumber = isAccelerateDeployment(deploymentType) ? '8.' : '9.';
  const dockerStepNumber = isAccelerateDeployment(deploymentType) ? '6.' : '7.';
  const handleIpLeaseChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newIpLeaseType = e.target.value as IPLeaseType;
    dispatchRtk(setIpLeaseType(newIpLeaseType));
    dispatchRtk(calculatePriceThunk(LoadingType.IP_LEASE));
    if (newIpLeaseType === IPLeaseType.DEDICATED) {
      dispatchRtk(setIsDedicatedClicked(true));
    } else {
      dispatchRtk(setIsDedicatedClicked(false));
    }
  };
  const hasEndpoint = selectedTemplate?.serviceData.ports.some(
    (port) => port.endpoint !== undefined && port.isChecked
  );
  useEffect(() => {
    if (hasEmptyCheck) {
      dispatchRtk(setIpLeaseType(IPLeaseType.SHARED));
      dispatchRtk(calculatePriceThunk(LoadingType.IP_LEASE));
    }
  }, [hasEmptyCheck, dispatchRtk]);
  const ipLeaseDetails = [
    {
      id: 1,
      radioId: 'shared-ip',
      label: 'SHARED IP',
      value: IPLeaseType.SHARED,
      description:
        // eslint-disable-next-line max-len
        'Automatically assigns a port within the 30000-32676 range. For standard web traffic, ports 80 and 443 are mapped to the assigned port, making it suitable for web applications without specific port demands.',
      tooltip: false,
      tooltipText: '',
      groupName: 'shared-ip',
      disable: hasEndpoint,
    },
    {
      id: 2,
      radioId: 'dedicated-ip',
      label: 'DEDICATED IP',
      value: IPLeaseType.DEDICATED,
      description:
        // eslint-disable-next-line max-len
        'Offers complete port access, including 80 and 443, for customized network configurations. Ideal for applications requiring direct control over network traffic.',
      tooltip: true,
      tooltipText: 'Dedicated IP incurs additional cost',
      groupName: 'dedicated-ip',
      disable: false,
    },
  ];
  return (
    <div className="mt-14">
      <div className="flex lg:flex-row flex-col items-center">
        <div
          className="text-base-heading-text-color dark:text-dark-base-heading-text-color
          text-xl font-bold"
        >
          {!isUpdate
            ? `${
                clusterType === ClusterType.TEMPLATE
                  ? stepNumber
                  : dockerStepNumber
              }`
            : ''}
          <span className="ml-1">Enabling the IP on deployment</span>
        </div>
      </div>
      <div className="flex flex-col">
        {ipLeaseDetails.map((ip) => (
          <div key={ip.id} className="mt-5">
            <span className="flex flex-row items-center cursor-pointer">
              <Radio
                id={ip.radioId}
                groupName="ipLeasetype"
                value={ip.value}
                currVal={ipLeaseType}
                handleChange={handleIpLeaseChange}
                disable={ip.disable}
                label={
                  <div
                    className="flex item-center text-form-text dark:text-dark-form-text 
              text-[12px] font-semibold uppercase gap-x-2"
                  >
                    <div className="flex items-center">{ip.label}</div>
                    {ip.tooltip && (
                      <Tooltip
                        position="right"
                        text={ip.tooltipText}
                        type="arrow"
                      >
                        <DollarIcon
                          className="w-5 h-5 cursor-pointer text-base-icon dark:text-dark-base-icon
                                opacity-90 hover:opacity-100"
                        />
                      </Tooltip>
                    )}
                  </div>
                }
              />
            </span>
            <span className="text-base-sub-text-color dark:text-dark-base-sub-text-color text-sm font-normal mt-3">
              {ip.description}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default IpLease;
