/* eslint-disable import/no-cycle */
import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { IResponseError } from '../combined-reducer.interface';
import { toggleModalShowRtk } from '../modal/modal.slice';
import { NotificationType } from '../notification/notification.interfaces';
import { addNotificationRtk } from '../notification/notification.slice';
import { delay } from '../root-utils';
import {
  organisationOverdueService,
  payOverdueForOrganisationService,
} from '../subscription/subscription.services';
import {
  removeUserOrganisationRtk,
  updateUserOrganisationRtk,
} from '../user/user.slice';
import {
  getUserDetailsThunk,
  updateUserDefaultOrganisation,
  updateUserOrganisationThunk,
} from '../user/user.thunks';
import { IUserState } from '../user/user.interfaces';
import {
  IChangeUserRolePayloadDto,
  ICreateNewOrganisationResponse,
  ICurrentApp,
  IInvitationPayloadDto,
  IInviteUserPayloadDto,
  IMasterOrganisationPayloadDto,
  IOrgWallet,
  IOrganisationBillingDetailsPayloadDto,
  IOrganisationBillingDetailsResponsePayloadDto,
  IOrganisationCurrentAppPayloadDto,
  IOrganisationSettingsPayloadDto,
  IOrganisationState,
  IPayOrganisationOverduePayloadDto,
  IRegistryDetails,
  IUpdateInvitePayloadDto,
  IWalletDetailsCrypto,
} from './organisation.interfaces';
import {
  addBillingDetailsService,
  changeMemberRoleService,
  createMasterOrganisationService,
  createOrganisationService,
  deleteOrganisationService,
  fetchOrgNotificationConfigService,
  fetchOrganisationById,
  fetchOrganisationByUsername,
  fetchUsersRole,
  getBillingDetailsService,
  getOrganisationWallet,
  getRegistryDetailsFromContract,
  getSpheronCredits,
  payEnvironmentOverdueService,
  publicAccessOrgService,
  removeInvitedMemberService,
  removeMemberService,
  sendMemberInviteService,
  updateBillingDetailsService,
  updateDefaultPaymentMethodIdService,
  updateInviteService,
  updateMasterOrganisationService,
  updateOrgNotificationConfigService,
  updateOrganisationService,
} from './organisation.services';
import {
  getCurrentAppState,
  isUserInOrg,
  mapCurrentAppToSpecialization,
} from './organisation.utils';
import { getWalletUsageThunk } from '../cluster/cluster.thunks';
import {
  fetchAllProjectsThunk,
  fetchTotalProjectsThunk,
} from '../project/project.thunks';
import { ProjectType } from '../project/project.interfaces';
import {
  getActiveSubscriptionsThunk,
  getAllDefaultPackagesThunk,
  getAllowedBonusesThunk,
  // getInstancePlanThunk,
} from '../subscription/subscription.thunks';
import { getGatewaysThunk } from '../gateway/gateway.thunks';
import {
  ICreditCard,
  IOrganization,
  IUser,
  PaymentMethod,
  PreferredAppType,
} from '../combined-state.interface';
import {
  organisationsLoadedRtk,
  resetWalletRtk,
  setPublicAccessibleOrgRtk,
  setSelectedOrgRtk,
  setUserRoleCommunityRtk,
  setBillingDetailsRtk,
  toggleBillingDetailsLoadingRtk,
  updateDefaultPaymentMethodRtk,
  setCommunityOrgNotFoundRtk,
  addSpheronWalletRtk,
  updateSpheronWalletPrimaryPaymentRtk,
  resetSpheronCreditsRtk,
  setSelectedMasterOrg,
  setCurrentAppType,
} from './organisation.slice';
import {
  IOrganisationWallet,
  NonSubgraphsChainName,
} from '../web3/web3.interfaces';
import {
  getBalanceService,
  getTokenBalanceService,
} from '../web3/web3.services';
import {
  addWalletRtk,
  resetWeb3StateRtk,
  togglePaymentHistoryLoadingRtk,
  toggleWalletsLoadingRTK,
  updateCryptoWalletPrimaryPaymentRtk,
} from '../web3/web3.slice';
import {
  addCreditCardRTK,
  resetStripeStateRTK,
  toggleFetchingCreditCardRTK,
  updateCreditCardPrimaryPayment,
} from '../stripe/stripe.slice';
import { getSolTokenBalanceService } from '../solana/solana.services';
import { setSubscriptionToDefaultRtk } from '../subscription/subscription.slice';
import {
  getAllBucketsCountThunk,
  getAllBucketsThunk,
} from '../storage/storage.thunks';
import { BucketType } from '../storage/storage.interfaces';
import { resetClusterStateRtk } from '../cluster/cluster.slice';
import { setScalingTypeRtk } from '../instance/instance-creation/instance-creation.slice';
import { ScalingType } from '../instance/instance-creation/instance-creation.interfaces';
import { toggleBucketsLoading } from '../storage/storage.slice';
import config from '../../config';
import {
  getAllComputeProjectsThunk,
  getComputeProjectsCountThunk,
} from '../compute/project/project.thunks';
import { loadCdnRecordsThunk } from '../instance/instance.thunks';
import { resetProjectState } from '../compute/project/project.slice';

export const getSelectedOrgWalletThunk = createAsyncThunk(
  'organisation/getSelectedOrgWallet',
  async (payload: string, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(setScalingTypeRtk(ScalingType.MANUAL));
      dispatch(toggleFetchingCreditCardRTK(true));
      dispatch(toggleWalletsLoadingRTK(true));
      dispatch(togglePaymentHistoryLoadingRtk(true));

      const response = await getOrganisationWallet(payload);

      if (!(response as unknown as IResponseError).error) {
        const web3Wallets = response.wallets.filter(
          (wallet: any) => wallet.paymentMethod === PaymentMethod.CRYPTO
        );

        const creditCards = response.wallets.filter(
          (creditCard: any) =>
            creditCard.paymentMethod === PaymentMethod.CREDITCARD
        );

        const spheronWallet = response.wallets.filter(
          (wallet: any) => wallet.paymentMethod === PaymentMethod.SPHERONWALLET
        );

        const web3WalletsBalance = [];
        // eslint-disable-next-line no-unused-vars, no-restricted-syntax
        for (const wallet of web3Wallets) {
          if (
            (wallet.details as IWalletDetailsCrypto).networkDetails.name ===
            'solana'
          ) {
            try {
              // eslint-disable-next-line no-await-in-loop
              const balanceResponse = await getSolTokenBalanceService(
                (wallet.details as IWalletDetailsCrypto).address
              );
              web3WalletsBalance.push({
                ...wallet,
                balance: {
                  amount: Number(balanceResponse?.balance),
                  token: (wallet.details as IWalletDetailsCrypto).tokenDetails
                    .address,
                },
              });
            } catch (error) {
              // eslint-disable-next-line no-console
              console.log('Error in get Solana wallet balance ->', error);
            }
          } else if (
            (wallet.details as IWalletDetailsCrypto).networkDetails.name ===
              NonSubgraphsChainName.FILECOIN ||
            (wallet.details as IWalletDetailsCrypto).networkDetails.name ===
              NonSubgraphsChainName.MANTLE ||
            (wallet.details as IWalletDetailsCrypto).networkDetails.name ===
              NonSubgraphsChainName.LINEA
          ) {
            if ((window as any)?.ethereum?.selectedAddress) {
              try {
                // eslint-disable-next-line no-await-in-loop, no-unused-vars
                const balance = await getTokenBalanceService(
                  (wallet.details as IWalletDetailsCrypto).address,
                  {
                    address: (wallet.details as IWalletDetailsCrypto)
                      .tokenDetails.address,
                    decimals: (wallet.details as IWalletDetailsCrypto)
                      .tokenDetails.decimal,
                  },
                  String(
                    (wallet.details as IWalletDetailsCrypto).networkDetails
                      .chainId
                  )
                );
                if (balance.balance === null) {
                  web3WalletsBalance.push({
                    ...wallet,
                    balance: {
                      amount: '',
                      token: (wallet.details as IWalletDetailsCrypto)
                        .tokenDetails.address,
                    },
                  });
                } else {
                  web3WalletsBalance.push({
                    ...wallet,
                    balance: {
                      amount: balance.balance,
                      token: (wallet.details as IWalletDetailsCrypto)
                        .tokenDetails.address,
                    },
                  });
                }
              } catch (error) {
                web3WalletsBalance.push({
                  ...wallet,
                  balance: {
                    amount: '',
                    token: '',
                  },
                });
              }
            } else {
              web3WalletsBalance.push({
                ...wallet,
                balance: {
                  amount: '',
                  token: '',
                },
              });
            }
          } else {
            try {
              // eslint-disable-next-line no-await-in-loop, no-unused-vars
              const balance = await getBalanceService(
                (wallet.details as IWalletDetailsCrypto).address,
                (wallet.details as IWalletDetailsCrypto).tokenDetails.address,
                Number(
                  (wallet.details as IWalletDetailsCrypto).networkDetails
                    .chainId
                )
              );
              if (balance.data.balance === null) {
                web3WalletsBalance.push({
                  ...wallet,
                  balance: {
                    amount: '',
                    token: (wallet.details as IWalletDetailsCrypto).tokenDetails
                      .address,
                  },
                });
              } else {
                web3WalletsBalance.push({
                  ...wallet,
                  balance: {
                    amount: balance.data.balance.amount,
                    token: (wallet.details as IWalletDetailsCrypto).tokenDetails
                      .address,
                  },
                });
              }
            } catch (error) {
              web3WalletsBalance.push({
                ...wallet,
                balance: {
                  amount: '',
                  token: (wallet.details as IWalletDetailsCrypto).tokenDetails
                    .address,
                },
              });
            }
          }
        }
        dispatch(
          addWalletRtk(web3WalletsBalance as unknown as IOrganisationWallet[])
        );
        dispatch(addCreditCardRTK(creditCards as unknown as ICreditCard[]));
        dispatch(addSpheronWalletRtk(spheronWallet[0] as IOrgWallet));
        dispatch(toggleWalletsLoadingRTK(false));
        dispatch(toggleFetchingCreditCardRTK(false));
        return fulfillWithValue(response);
      }

      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getUserRoleThunk = createAsyncThunk(
  'organisation/getUserRole',
  async (payload: string, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await fetchUsersRole(payload);
      return fulfillWithValue(response.dto);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const switchCurrentAppThunk = createAsyncThunk(
  'organisation/switchCurrentApp',
  // eslint-disable-next-line no-unused-vars
  async (payload: ICurrentApp, { fulfillWithValue, dispatch }) => {
    // dispatch(toggleProjectsLoading(true));
    // dispatch(toggleClustersLoading(true));
    // dispatch(setSubscriptionToDefault());
    return fulfillWithValue(payload);
  }
);

export const getOrganisationOverdueThunk = createAsyncThunk(
  'organisation/getOrganisationOverdue',
  async (
    payload: IOrganisationCurrentAppPayloadDto,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const response = await organisationOverdueService(payload);
      if (!(response as IResponseError).error) {
        return fulfillWithValue(response);
      }
      dispatch(
        addNotificationRtk({
          message: (response as IResponseError).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const fetchRegistryDetailsThunk = createAsyncThunk(
  'organisation/fetchRegistryDetails',
  async (
    payload: string | void,
    { rejectWithValue, fulfillWithValue, getState }
  ) => {
    try {
      const { organisation } = getState() as {
        organisation: IOrganisationState;
      };
      if (payload) {
        await delay(5000);
        const registryDetailsRes: IRegistryDetails[] = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const registry of [
          ...(organisation.selectedOrganisation.registries || []),
          payload,
        ]) {
          // eslint-disable-next-line no-await-in-loop
          const singleRegistryResponse = await getRegistryDetailsFromContract(
            registry
          );
          registryDetailsRes.push(singleRegistryResponse as IRegistryDetails);
        }
        const registryDetails = registryDetailsRes.filter((r) => !!r);
        return fulfillWithValue(registryDetails);
      }
      if (organisation.selectedOrganisation.registries) {
        const registryDetailsRes: IRegistryDetails[] = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const registry of [
          ...organisation.selectedOrganisation.registries,
        ]) {
          // eslint-disable-next-line no-await-in-loop
          const singleRegistryResponse = await getRegistryDetailsFromContract(
            registry
          );
          registryDetailsRes.push(singleRegistryResponse as IRegistryDetails);
        }
        const registryDetails = registryDetailsRes.filter((r) => !!r);
        return fulfillWithValue(registryDetails);
      }
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getBillingDetailsThunk = createAsyncThunk(
  'organisation/getBillingDetails',
  async (payload: string, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await getBillingDetailsService(payload);
      if ((response as IResponseError).error) {
        dispatch(
          addNotificationRtk({
            message: (response as IResponseError).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      return fulfillWithValue(
        response as IOrganisationBillingDetailsResponsePayloadDto
      );
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getSelectedOrganisationThunk = createAsyncThunk(
  'organisation/getSelectedOrganisation',
  async (payload: string, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(resetWalletRtk());
      dispatch(resetStripeStateRTK());
      dispatch(resetWeb3StateRtk());
      dispatch(resetClusterStateRtk());
      dispatch(setSubscriptionToDefaultRtk());
      dispatch(toggleFetchingCreditCardRTK(true));
      dispatch(toggleWalletsLoadingRTK(true));
      dispatch(togglePaymentHistoryLoadingRtk(true));
      dispatch(toggleBillingDetailsLoadingRtk(true));
      dispatch(setBillingDetailsRtk(null));
      dispatch(toggleBucketsLoading(true));
      dispatch(resetSpheronCreditsRtk());
      dispatch(resetProjectState());
      const response = await fetchOrganisationById(payload);
      if (response?.profile) {
        dispatch(
          getAllowedBonusesThunk({
            organizationId: response._id,
            specialization: mapCurrentAppToSpecialization(
              getCurrentAppState(response.appType as string)
            ),
          })
        );
        (window as any).getUserRolePromise = dispatch(
          getUserRoleThunk(response._id as string)
        );
        dispatch(setCurrentAppType(getCurrentAppState(response.appType!)));
        dispatch(setSelectedOrgRtk(response));
        dispatch(getSelectedOrgWalletThunk(response._id as string));

        if (response.appType === ICurrentApp.COMPUTE) {
          dispatch(
            getAllComputeProjectsThunk({
              organisationId: payload,
              topupReport: 'n',
            })
          );

          dispatch(
            getAllComputeProjectsThunk({
              organisationId: payload,
              topupReport: 'y',
            })
          );

          dispatch(getComputeProjectsCountThunk(payload));

          dispatch(getWalletUsageThunk(payload));
        } else if (response.appType === ICurrentApp.STORAGE) {
          dispatch(
            getAllBucketsCountThunk({
              organisationId: response._id,
            })
          );
          dispatch(
            getAllBucketsThunk({
              organisationId: response._id,
            })
          );
          dispatch(
            getAllBucketsCountThunk({
              organisationId: response._id,
              state: BucketType.ARCHIVED,
            })
          );
          dispatch(
            getAllBucketsThunk({
              organisationId: response._id,
              state: BucketType.ARCHIVED,
            })
          );
          dispatch(getGatewaysThunk(response._id as string));
        } else {
          dispatch(
            fetchAllProjectsThunk({ id: payload, type: ProjectType.MAINTAINED })
          );
          dispatch(
            fetchAllProjectsThunk({ id: payload, type: ProjectType.ARCHIVED })
          );
          dispatch(
            fetchTotalProjectsThunk({
              id: payload,
              type: ProjectType.MAINTAINED,
            })
          );
          dispatch(
            fetchTotalProjectsThunk({ id: payload, type: ProjectType.ARCHIVED })
          );
          dispatch(getGatewaysThunk(response._id as string));
        }
        dispatch(fetchRegistryDetailsThunk());
        localStorage.setItem('selected-org', payload);

        dispatch(
          getAllDefaultPackagesThunk(
            getCurrentAppState(response.appType as string)
          )
        );
        dispatch(getActiveSubscriptionsThunk());
      } else {
        rejectWithValue({});
        dispatch(
          addNotificationRtk({
            message: "Can't find this organisation",
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
      }

      dispatch(
        getOrganisationOverdueThunk({
          organizationId: payload,
          currentApp: getCurrentAppState(response.appType || ''),
        })
      );

      return fulfillWithValue(response);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getSelectedOrganisationByNameThunk = createAsyncThunk(
  'organisation/getSelectedOrganisationByName',
  async (
    payload: string,
    { rejectWithValue, fulfillWithValue, dispatch, getState }
  ) => {
    try {
      sessionStorage.setItem('orgNotFound', 'false');
      dispatch(resetWalletRtk());
      dispatch(resetStripeStateRTK());
      dispatch(resetWeb3StateRtk());
      dispatch(setSubscriptionToDefaultRtk());
      dispatch(toggleFetchingCreditCardRTK(true));
      dispatch(toggleWalletsLoadingRTK(true));
      dispatch(togglePaymentHistoryLoadingRtk(true));

      const response = await fetchOrganisationByUsername(payload);
      let selectedOrganization: null | IOrganization = null;
      if (response?.profile) {
        dispatch(setSelectedMasterOrg(response as IOrganization));
        switch (response.preferedAppType) {
          case PreferredAppType.SITE:
          default:
            selectedOrganization = response.site;
            break;
          case PreferredAppType.COMPUTE:
            selectedOrganization = response.compute;
            break;
          case PreferredAppType.STORAGE:
            selectedOrganization = response.storage;
            break;
        }
        setPublicAccessibleOrgRtk(response.publiclyAccessible!);
        sessionStorage.setItem(
          'isPublicAccessible',
          String(response.publiclyAccessible)
        );
        sessionStorage.setItem('communityOrgName', response.profile.username);
        sessionStorage.setItem(
          'communityOrgAppType',
          response?.preferedAppType || ''
        );
        dispatch(
          getAllowedBonusesThunk({
            organizationId: selectedOrganization?._id,
            specialization: mapCurrentAppToSpecialization(
              getCurrentAppState(response.preferedAppType as string)
            ),
          })
        );
        dispatch(
          switchCurrentAppThunk(getCurrentAppState(response.preferedAppType!))
        ).then(() => {
          if (!localStorage.getItem('jwt-token')) {
            dispatch(organisationsLoadedRtk([response as IOrganization]));
          }
        });
        dispatch(setSelectedOrgRtk(selectedOrganization));
        const {
          user: { user },
        } = getState() as { user: IUserState };

        if (localStorage.getItem('jwt-token')) {
          if (isUserInOrg(user as IUser)) {
            (window as any).getUserRolePromise = dispatch(
              getUserRoleThunk(selectedOrganization?._id as string)
            );
            sessionStorage.setItem('communityUserAccess', 'true');
          } else {
            dispatch(setUserRoleCommunityRtk());
          }
        } else {
          dispatch(setUserRoleCommunityRtk());
        }
        dispatch(
          getSelectedOrgWalletThunk(selectedOrganization?._id as string)
        );

        if (response.preferedAppType === PreferredAppType.COMPUTE) {
          dispatch(
            getAllComputeProjectsThunk({
              organisationId: selectedOrganization?._id as string,
              topupReport: 'n',
            })
          );
          dispatch(loadCdnRecordsThunk(payload));

          dispatch(
            getComputeProjectsCountThunk(selectedOrganization?._id || '')
          );
        } else {
          dispatch(
            fetchAllProjectsThunk({
              id: selectedOrganization?._id as string,
              type: ProjectType.MAINTAINED,
            })
          );
          dispatch(
            fetchAllProjectsThunk({
              id: selectedOrganization?._id as string,
              type: ProjectType.ARCHIVED,
            })
          );
          dispatch(
            fetchTotalProjectsThunk({
              id: selectedOrganization?._id as string,
              type: ProjectType.MAINTAINED,
            })
          );
          dispatch(
            fetchTotalProjectsThunk({
              id: selectedOrganization?._id as string,
              type: ProjectType.ARCHIVED,
            })
          );
          dispatch(getGatewaysThunk(selectedOrganization?._id as string));
        }
        dispatch(fetchRegistryDetailsThunk());
        localStorage.setItem('app-type', response.preferedAppType!);
        localStorage.setItem('selected-org', selectedOrganization?._id!);

        dispatch(
          getAllDefaultPackagesThunk(
            getCurrentAppState(response.preferedAppType as string)
          )
        );
        dispatch(getActiveSubscriptionsThunk());
      } else {
        dispatch(setCommunityOrgNotFoundRtk(true));
        sessionStorage.setItem('orgNotFound', 'true');
        sessionStorage.setItem('communityOrgName', payload);
        window.open(`${window.location.origin}/${payload}`, '_self');
        rejectWithValue({});
      }
      if (selectedOrganization?._id) {
        dispatch(
          getOrganisationOverdueThunk({
            organizationId: selectedOrganization._id,
            currentApp: getCurrentAppState(response.preferedAppType || ''),
          })
        );
      }
      return fulfillWithValue(response);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const createOrganisationThunk = createAsyncThunk(
  'organisation/createOrganisation',
  async (
    payload: IOrganisationSettingsPayloadDto,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const response: any = await createOrganisationService(payload);
      if (response.success) {
        dispatch(updateUserOrganisationRtk(response.organization));
        dispatch(getSelectedOrganisationThunk(response.organization._id));
        window.open(
          `${window.location.origin}/${response.organization.appType}/dashboard`,
          '_self'
        );
        return fulfillWithValue(response);
      }
      if (response?.error) {
        dispatch(
          addNotificationRtk({
            message: response?.message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const createMasterOrganisationThunk = createAsyncThunk(
  'organisation/createMasterOrganisationThunk',
  async (
    payload: IMasterOrganisationPayloadDto,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      // promise to ensure the response is fetched after all the creation steps is loaded
      const stepTimeoutPromise = new Promise((resolve) => {
        setTimeout(resolve, config.masterOrg.LOADING_STEPS_DURATION);
      });
      const [response] = await Promise.all([
        createMasterOrganisationService(payload),
        stepTimeoutPromise,
      ]);

      if (response.success) {
        const {
          preferedAppType,
          site: siteId,
          compute: computeId,
          storage: storageId,
        } = (response as ICreateNewOrganisationResponse)
          .organization as IOrganization;
        localStorage.setItem(
          'selected-master-org',
          (response as ICreateNewOrganisationResponse).organization._id
        );
        dispatch(
          updateUserDefaultOrganisation(
            (response as ICreateNewOrganisationResponse).organization._id
          )
        );
        if (preferedAppType === PreferredAppType.SITE) {
          localStorage.setItem('selected-org', siteId);
        } else if (preferedAppType === PreferredAppType.COMPUTE) {
          localStorage.setItem('selected-org', computeId);
        } else if (preferedAppType === PreferredAppType.STORAGE) {
          localStorage.setItem('selected-org', storageId);
        } else {
          localStorage.setItem('selected-org', siteId);
        }
        dispatch(updateUserOrganisationThunk('')).then(() =>
          window.open(
            `${window.location.origin}/${preferedAppType}/dashboard`,
            '_self'
          )
        );

        return fulfillWithValue(response);
      }
      if ((response as IResponseError).error) {
        dispatch(
          addNotificationRtk({
            message: (response as IResponseError).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const updateOrganisationThunk = createAsyncThunk(
  'organisation/updateOrganisation',
  async (
    payload: { id: string; data: IOrganisationSettingsPayloadDto },
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const response = await updateOrganisationService(payload);
      if (response) {
        dispatch(
          addNotificationRtk({
            message: 'Organisation updated successfully',
            timestamp: Date.now(),
            type: NotificationType.Success,
          })
        );
        return fulfillWithValue(payload);
      }
      dispatch(
        addNotificationRtk({
          message: "Can't update the organisation",
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const updateMasterOrganisationThunk = createAsyncThunk(
  'organisation/updateMasterOrganisationThunk',
  async (
    payload: { id: string; data: IMasterOrganisationPayloadDto },
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const response = await updateMasterOrganisationService(payload);
      if (response) {
        dispatch(
          addNotificationRtk({
            message: 'Organisation updated successfully',
            timestamp: Date.now(),
            type: NotificationType.Success,
          })
        );
        return fulfillWithValue(payload);
      }
      dispatch(
        addNotificationRtk({
          message: "Can't update the organisation",
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getOrganisationMembersThunk = createAsyncThunk(
  'organisation/getOrganisationMembers',
  async (payload: string, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await fetchOrganisationById(payload);
      if (response?.profile) {
        (window as any).getUserRolePromise = dispatch(
          getUserRoleThunk(response._id as string)
        );
      } else {
        rejectWithValue({});
        dispatch(
          addNotificationRtk({
            message: "Can't find this organisation",
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
      }
      return fulfillWithValue(response);
    } catch (error) {
      dispatch(
        addNotificationRtk({
          message: (error as Error).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    }
  }
);

export const sendMemberInviteThunk = createAsyncThunk(
  'organisation/sendMemberInvite',
  async (
    payload: IInviteUserPayloadDto[],
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    // eslint-disable-next-line no-unused-vars

    try {
      const responses = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const invite of payload) {
        // eslint-disable-next-line no-await-in-loop
        const res = await sendMemberInviteService(invite);
        responses.push(res);
      }
      if (responses.every((invite: IResponseError) => invite.error)) {
        dispatch(
          addNotificationRtk({
            message: responses[0].message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
      } else {
        dispatch(toggleModalShowRtk({ modalShow: false }));
        dispatch(
          getOrganisationMembersThunk(
            localStorage.getItem('selected-org') as string
          )
        );
      }
      return fulfillWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const removeInvitedMemberThunk = createAsyncThunk(
  'organisation/removeInvitedMember',
  async (
    payload: IInvitationPayloadDto,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      await removeInvitedMemberService(payload);
      dispatch(toggleModalShowRtk({ modalShow: false }));
      dispatch(
        getOrganisationMembersThunk(
          localStorage.getItem('selected-org') as string
        )
      );
      return fulfillWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const removeOrganisationMemberThunk = createAsyncThunk(
  'organisation/removeOrganisationMember',
  async (
    payload: { members: string[] },
    { rejectWithValue, fulfillWithValue, dispatch, getState }
  ) => {
    try {
      const {
        organisation: { selectedOrganisation },
      } = getState() as { organisation: IOrganisationState };
      const { members }: { members: string[] } = payload;

      const responses = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const member of members) {
        // eslint-disable-next-line no-await-in-loop
        const res = await removeMemberService(member, selectedOrganisation._id);
        responses.push(res);
      }
      if (responses.every((invite: IResponseError) => !invite.error)) {
        dispatch(getSelectedOrganisationThunk(selectedOrganisation._id));
      } else {
        responses.forEach((invite: IResponseError) => {
          if (invite.error) {
            dispatch(
              addNotificationRtk({
                message: invite.message,
                timestamp: Date.now(),
                type: NotificationType.Error,
              })
            );
          }
        });
      }
      dispatch(
        toggleModalShowRtk({
          modalShow: false,
        })
      );
      return fulfillWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const changeMemberRoleThunk = createAsyncThunk(
  'organisation/changeMemberRole',
  async (
    payload: IChangeUserRolePayloadDto,
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      const response = await changeMemberRoleService(payload);
      if (!(response as IResponseError).error) {
        dispatch(toggleModalShowRtk({ modalShow: false }));
        (window as any).getUserRolePromise = dispatch(
          getUserRoleThunk(payload.organizationId)
        );
        return fulfillWithValue(payload);
      }
      dispatch(
        addNotificationRtk({
          message: (response as IResponseError).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const updateInvitationThunk = createAsyncThunk(
  'organisation/updateInvitation',
  async (
    payload: {
      inviteReply: IUpdateInvitePayloadDto;
    },
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      const response = await updateInviteService(payload.inviteReply);
      if (response) {
        if (!response.error) {
          localStorage.removeItem('inviteRef');
          localStorage.removeItem('orgName');
          window.open(`${window.location.origin}/compute/dashboard`, '_self');
          dispatch(getUserDetailsThunk(''));
          dispatch(
            addNotificationRtk({
              message: response.message,
              timestamp: Date.now(),
              type: NotificationType.Success,
            })
          );
          return fulfillWithValue(response.message);
        }
        dispatch(
          addNotificationRtk({
            message: response.message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
      }
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getOrganisationOverdueEnvironmentPaymentThunk = createAsyncThunk(
  'organisation/organisationOverdueEnvironmentPayment',
  async (
    payload: {
      walletId: string;
      organisationId: string;
      projectId: string;
    },
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const response = await payEnvironmentOverdueService(payload);
      if (response.error) {
        dispatch(
          addNotificationRtk({
            message: 'Please wait for the payment to complete',
            timestamp: Date.now(),
            type: NotificationType.Success,
          })
        );
      } else {
        dispatch(
          addNotificationRtk({
            message: response.message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
      }
      dispatch(toggleModalShowRtk({ modalShow: false }));
      return fulfillWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const organisationOverduePaymentThunk = createAsyncThunk(
  'organisation/organisationOverduePayment',
  async (
    payload: IPayOrganisationOverduePayloadDto,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const response = await payOverdueForOrganisationService(
        payload.walletId,
        payload.organisationId,
        payload.specialization
      );
      if ((response as IResponseError).error) {
        dispatch(
          addNotificationRtk({
            message: response.message,
            timestamp: Date.now(),
            type: NotificationType.Success,
          })
        );
        dispatch(toggleModalShowRtk({ modalShow: false }));
        return rejectWithValue({});
      }
      dispatch(
        addNotificationRtk({
          message: 'Please wait for payment to complete',
          timestamp: Date.now(),
          type: NotificationType.Warning,
        })
      );
      dispatch(toggleModalShowRtk({ modalShow: false }));
      return fulfillWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const deleteOrganisationThunk = createAsyncThunk(
  'organisation/deleteOrganisation',
  async (
    payload: string,
    { rejectWithValue, fulfillWithValue, dispatch, getState }
  ) => {
    const { organisation } = getState() as {
      organisation: IOrganisationState;
    };
    const currentApp = organisation.selectedOrganisation.appType;
    try {
      const response = await deleteOrganisationService(payload);
      if ((response as IResponseError)?.error) {
        dispatch(
          addNotificationRtk({
            message: response.message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        dispatch(toggleModalShowRtk({ modalShow: false }));
        return rejectWithValue({});
      }
      dispatch(toggleModalShowRtk({ modalShow: false }));
      localStorage.removeItem('selected-org');
      localStorage.removeItem('selected-master-org');
      dispatch(
        toggleModalShowRtk({
          modalShow: true,
          modalType: 'deletedResource',
          options: {
            resource: 'Organisation',
          },
        })
      );
      dispatch(removeUserOrganisationRtk(payload));
      const { user } = getState() as {
        user: IUserState;
      };
      if (user.user && user.user.organizations.length > 0) {
        const firstOrg = user.user.organizations[0];
        dispatch(updateUserDefaultOrganisation(firstOrg._id));

        localStorage.setItem('selected-master-org', firstOrg._id);
        dispatch(setSelectedMasterOrg(firstOrg!));
        localStorage.setItem('selected-org', firstOrg?.compute._id);
        dispatch(setCurrentAppType(ICurrentApp.COMPUTE));
        window.open(`${window.location.origin}/compute/dashboard`, '_self');
      }

      dispatch(getUserDetailsThunk(''));

      return fulfillWithValue({
        organisationId: payload,
        organisationType: currentApp as ICurrentApp,
      });
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const updateDefaultPaymentThunk = createAsyncThunk(
  'organisation/updateDefaultPayment',
  async (
    payload: {
      organisationId: string;
      newPaymentMethodId: string;
    },
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const response = await updateDefaultPaymentMethodIdService(payload);
      if ((response as IResponseError).error) {
        dispatch(
          addNotificationRtk({
            message: (response as IResponseError).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      dispatch(updateDefaultPaymentMethodRtk(response as IOrgWallet));
      dispatch(
        updateCryptoWalletPrimaryPaymentRtk({
          id: (response as any)._id,
        })
      );
      dispatch(
        updateCreditCardPrimaryPayment({
          id: (response as any)._id,
        })
      );
      dispatch(
        updateSpheronWalletPrimaryPaymentRtk({
          id: (response as any)._id,
        })
      );
      return fulfillWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const publicAccessOrgThunk = createAsyncThunk(
  'organisation/publicAccessOrg',
  async (
    payload: {
      organisationId: string;
      publiclyAccessible: boolean;
    },
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const response = await publicAccessOrgService(payload);
      if ((response as IResponseError).error) {
        dispatch(
          addNotificationRtk({
            message: (response as IResponseError).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        dispatch(toggleModalShowRtk({ modalShow: false }));
        return rejectWithValue({});
      }
      dispatch(toggleModalShowRtk({ modalShow: false }));

      return fulfillWithValue(response.organization);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const updateBillingDetailsThunk = createAsyncThunk(
  'organisation/updateBillingDetails',
  async (
    payload: {
      isEdit: boolean;
      organizationId: string;
      details: IOrganisationBillingDetailsPayloadDto;
    },
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      let response;
      if (payload.isEdit) {
        response = await updateBillingDetailsService(payload);
      } else {
        response = await addBillingDetailsService(payload);
      }

      if ((response as IResponseError).error) {
        dispatch(
          addNotificationRtk({
            message: (response as IResponseError).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }

      dispatch(
        addNotificationRtk({
          message: !payload.isEdit
            ? 'Invoice details saved'
            : 'Invoice details updated successfully',
          timestamp: Date.now(),
          type: NotificationType.Success,
        })
      );

      return fulfillWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getSpheronCreditsThunk = createAsyncThunk(
  'organisation/getSpheronCredits',
  async (payload: string, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getSpheronCredits(payload);

      if ((response as IResponseError).error) {
        return rejectWithValue({});
      }

      return fulfillWithValue(response);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const updatePreferredAppTypeThunk = createAsyncThunk(
  'organisation/updatePreferredAppType',
  async (
    payload: { id: string; data: IMasterOrganisationPayloadDto },
    { rejectWithValue }
  ) => {
    try {
      const response: any = await updateMasterOrganisationService(payload);
      if (response.error) return rejectWithValue({});

      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const fetchOrganisationNotificationConfigThunk = createAsyncThunk(
  'organisation/fetchOrganisationNotificationConfig',
  async (payload: string, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response:
        | {
            notificationCategories: { notificationCategory: string }[];
          }
        | IResponseError = await fetchOrgNotificationConfigService(payload);
      if ((response as IResponseError).error) return rejectWithValue({});
      return fulfillWithValue(
        (
          response as {
            notificationCategories: { notificationCategory: string }[];
          }
        ).notificationCategories
      );
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const updateOrganisationNotificationConfigThunk = createAsyncThunk(
  'organisation/updateOrganisationNotificationConfig',
  async (
    payload: { organisationId: string; newNotificationCategories: string[] },
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const response:
        | {
            config: {
              notificationCategories: { notificationCategory: string }[];
            };
          }
        | IResponseError = await updateOrgNotificationConfigService(payload);
      if ((response as IResponseError).error) return rejectWithValue({});
      dispatch(
        addNotificationRtk({
          message: 'Email Preference Updated Successfully!',
          timestamp: Date.now(),
          type: NotificationType.Success,
        })
      );
      return fulfillWithValue(
        (
          response as {
            config: {
              notificationCategories: { notificationCategory: string }[];
            };
          }
        ).config.notificationCategories
      );
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);
