import React, { useRef, useEffect, useReducer } from 'react';
import { TextInput, Dropdown, OptionType } from '@spheron/ui-library';
import { ReactComponent as RamIcon } from '@spheron/ui-library/dist/assets/compute/ram.svg';
import { ReactComponent as CpuIcon } from '../../../../../assets/compute/icons/old-computer.svg';
import {
  AutoscalingCooldownEnum,
  AutoscalingPlanEnum,
  AutoscalingTimeWindowEnum,
  IAutoscalingRequestPayload,
  IAutoscalingRulesThreshold,
} from '../../../../../redux/compute/instance/instance.interfaces';

interface IProps {
  // eslint-disable-next-line no-unused-vars
  setAutoScalingPolicy: (autoScaling: IAutoscalingRequestPayload) => void;
  autoScalingPolicy: IAutoscalingRequestPayload;
  showCustomAutoScalingPolicy: boolean;
  isUpdate: boolean;
}

const AutoScalingConfig = ({
  setAutoScalingPolicy,
  autoScalingPolicy,
  showCustomAutoScalingPolicy,
  isUpdate,
}: IProps) => {
  const mounted = useRef(false);
  const INITIAL_SCALE_UP_STATE = {
    memoryThreshold: {
      utilizationPercentage: '',
      step: '',
    },
    cpuThreshold: {
      utilizationPercentage: '',
      step: '',
    },
  };

  const INITIAL_SCALE_DOWN_STATE = {
    memoryThreshold: {
      utilizationPercentage: '',
      step: '',
    },
    cpuThreshold: {
      utilizationPercentage: '',
      step: '',
    },
  };

  const scaleUpreducer = (
    state: typeof INITIAL_SCALE_UP_STATE,
    action: { type: string; value: string; field: string; name: string }
  ) => {
    switch (action.type) {
      case 'HANDLE_INPUT_CHANGE':
        return {
          ...state,
          [action.field as keyof typeof INITIAL_SCALE_UP_STATE]: {
            ...state[action.field as keyof typeof INITIAL_SCALE_UP_STATE],
            [action.name]: action.value,
          },
        };
      case 'SET_OBJECT_VALUE':
        return {
          ...state,
          [action.field as keyof typeof INITIAL_SCALE_UP_STATE]: action.value,
        };
      case 'RESET_TO_DEFAULT':
        return INITIAL_SCALE_UP_STATE;
      default:
        return state;
    }
  };

  const scaleDownreducer = (
    state: typeof INITIAL_SCALE_DOWN_STATE,
    action: { type: string; value: string; field: string; name: string }
  ) => {
    switch (action.type) {
      case 'HANDLE_INPUT_CHANGE':
        return {
          ...state,
          [action.field as keyof typeof INITIAL_SCALE_DOWN_STATE]: {
            ...state[action.field as keyof typeof INITIAL_SCALE_DOWN_STATE],
            [action.name]: action.value,
          },
        };
      case 'SET_OBJECT_VALUE':
        return {
          ...state,
          [action.field as keyof typeof INITIAL_SCALE_DOWN_STATE]: action.value,
        };
      case 'RESET_TO_DEFAULT':
        return INITIAL_SCALE_DOWN_STATE;
      default:
        return state;
    }
  };

  const [scaleUp, setScaleUp] = useReducer(
    scaleUpreducer,
    INITIAL_SCALE_UP_STATE
  );

  const [scaleDown, setScaleDown] = useReducer(
    scaleDownreducer,
    INITIAL_SCALE_DOWN_STATE
  );

  const ramIcon = (
    <div className="flex items-center justify-start gap-x-3">
      <div
        className="flex items-center justify-center 
          w-6 h-6 rounded-full bg-base-bg"
      >
        <RamIcon className="text-base-icon dark:text-dark-base-icon" />
      </div>
      <span className="text-sm ">RAM</span>
    </div>
  );

  const cpuIcon = (
    <div className="flex items-center justify-start gap-x-3">
      <div
        className="flex items-center justify-center 
          w-6 h-6 rounded-full bg-base-bg"
      >
        <CpuIcon />
      </div>
      <span className="text-sm ">CPU</span>
    </div>
  );

  useEffect(() => {
    if (showCustomAutoScalingPolicy && mounted.current) {
      setAutoScalingPolicy({
        ...autoScalingPolicy,
        plan: 'custom',
        scaleUp: scaleUp as unknown as IAutoscalingRulesThreshold,
        scaleDown: scaleDown as unknown as IAutoscalingRulesThreshold,
      });
    } else {
      setAutoScalingPolicy({
        ...autoScalingPolicy,
        timeWindow: '',
        cooldown: '',
        scaleUp:
          INITIAL_SCALE_UP_STATE as unknown as IAutoscalingRulesThreshold,
        scaleDown:
          INITIAL_SCALE_DOWN_STATE as unknown as IAutoscalingRulesThreshold,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCustomAutoScalingPolicy, scaleDown, scaleUp]);

  useEffect(() => {
    if (autoScalingPolicy?.scaleDown && autoScalingPolicy?.scaleUp) {
      Object.entries(autoScalingPolicy.scaleDown).map(([key, value]) =>
        setScaleDown({
          type: 'SET_OBJECT_VALUE',
          field: key,
          value: value as any,
          name: '',
        })
      );
      Object.entries(autoScalingPolicy.scaleUp).map(([key, value]) =>
        setScaleUp({
          type: 'SET_OBJECT_VALUE',
          field: key,
          value: value as any,
          name: '',
        })
      );
    }
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scaleUpFields = [
    {
      icon: cpuIcon,
      id: 1,
      label: 'Utilization%',
      value: Number(scaleUp.cpuThreshold.utilizationPercentage) || '',
      placeholder: 'Utilization %',
      handleChange: (value: string) => {
        if (Number(value) > 0 && Number(value) <= 100)
          setScaleUp({
            type: 'HANDLE_INPUT_CHANGE',
            value,
            field: 'cpuThreshold',
            name: 'utilizationPercentage',
          });
      },
    },
    {
      icon: null,
      id: 2,
      label: 'STEPS*',
      value: Number(scaleUp.cpuThreshold.step) || '',
      placeholder: 'STEPS*',
      handleChange: (value: string) =>
        setScaleUp({
          type: 'HANDLE_INPUT_CHANGE',
          value,
          field: 'cpuThreshold',
          name: 'step',
        }),
    },
    {
      icon: ramIcon,
      id: 3,
      label: 'Utilization%',
      value: Number(scaleUp.memoryThreshold.utilizationPercentage) || '',
      placeholder: 'Utilization %',
      handleChange: (value: string) => {
        if (Number(value) > 0 && Number(value) <= 100)
          setScaleUp({
            type: 'HANDLE_INPUT_CHANGE',
            value,
            field: 'memoryThreshold',
            name: 'utilizationPercentage',
          });
      },
    },
    {
      icon: null,
      id: 4,
      label: 'STEPS*',
      value: Number(scaleUp.memoryThreshold.step) || '',
      placeholder: 'Utilization %',
      handleChange: (value: string) =>
        setScaleUp({
          type: 'HANDLE_INPUT_CHANGE',
          value,
          field: 'memoryThreshold',
          name: 'step',
        }),
    },
  ];

  const scaleDownFields = [
    {
      icon: cpuIcon,
      id: 1,
      label: 'Utilization%',
      value: Number(scaleDown.cpuThreshold.utilizationPercentage) || '',
      placeholder: 'Utilization %',
      handleChange: (value: string) => {
        if (Number(value) > 0 && Number(value) <= 100)
          setScaleDown({
            type: 'HANDLE_INPUT_CHANGE',
            value,
            field: 'cpuThreshold',
            name: 'utilizationPercentage',
          });
      },
    },
    {
      icon: null,
      id: 2,
      label: 'STEPS*',
      value: Number(scaleDown.cpuThreshold.step) || '',
      placeholder: 'STEPS*',
      handleChange: (value: string) =>
        setScaleDown({
          type: 'HANDLE_INPUT_CHANGE',
          value,
          field: 'cpuThreshold',
          name: 'step',
        }),
    },
    {
      icon: ramIcon,
      id: 3,
      label: 'Utilization%',
      value: Number(scaleDown.memoryThreshold.utilizationPercentage) || '',
      placeholder: 'Utilization %',
      handleChange: (value: string) => {
        if (Number(value) > 0 && Number(value) <= 100) {
          setScaleDown({
            type: 'HANDLE_INPUT_CHANGE',
            value,
            field: 'memoryThreshold',
            name: 'utilizationPercentage',
          });
        }
      },
    },
    {
      icon: null,
      id: 4,
      label: 'STEPS*',
      value: Number(scaleDown.memoryThreshold.step) || '',
      placeholder: 'Utilization %',
      handleChange: (value: string) =>
        setScaleDown({
          type: 'HANDLE_INPUT_CHANGE',
          value,
          field: 'memoryThreshold',
          name: 'step',
        }),
    },
  ];

  const maxMinReplicaField = [
    {
      id: 1,
      label: 'Max Replica',
      value: autoScalingPolicy.maximumInstances,
      handleChange: (value: string) => {
        setAutoScalingPolicy({
          ...autoScalingPolicy,
          maximumInstances: Number(value),
        });
      },
    },
    {
      id: 2,
      label: 'Min Replica',
      value: autoScalingPolicy.minimumInstances,
      handleChange: (value: string) => {
        setAutoScalingPolicy({
          ...autoScalingPolicy,
          minimumInstances: Number(value),
        });
      },
    },
  ];

  const timeWindowField = [
    {
      id: 1,
      label: '1 min',
      value: AutoscalingTimeWindowEnum.WINDOW_60,
      optionType: 'primary' as OptionType,
    },
    {
      id: 2,
      label: '2 mins',
      value: AutoscalingTimeWindowEnum.WINDOW_120,
      optionType: 'primary' as OptionType,
    },
    {
      id: 3,
      label: '5 mins',
      value: AutoscalingTimeWindowEnum.WINDOW_300,
      optionType: 'primary' as OptionType,
    },
    {
      id: 3,
      label: '10 mins',
      value: AutoscalingTimeWindowEnum.WINDOW_600,
      optionType: 'primary' as OptionType,
    },
  ];

  const autoScalingPlans = [
    {
      id: 1,
      label: 'Relaxed',
      value: AutoscalingPlanEnum.RELAX,
      optionType: 'primary' as OptionType,
    },
    {
      id: 2,
      label: 'Aggressive',
      value: AutoscalingPlanEnum.AGGRESSIVE,
      optionType: 'primary' as OptionType,
    },
    {
      id: 3,
      label: 'Standard',
      value: AutoscalingPlanEnum.STANDARD,
      optionType: 'primary' as OptionType,
    },
  ];

  const cooldownTimeField = [
    {
      id: 1,
      label: '5 mins',
      value: AutoscalingCooldownEnum.MINUTE_5,
      optionType: 'primary' as OptionType,
    },
    {
      id: 2,
      label: '10 mins',
      value: AutoscalingCooldownEnum.MINUTE_10,
      optionType: 'primary' as OptionType,
    },
    {
      id: 3,
      label: '15 mins',
      value: AutoscalingCooldownEnum.MINUTE_15,
      optionType: 'primary' as OptionType,
    },
    {
      id: 4,
      label: '20 mins',
      value: AutoscalingCooldownEnum.MINUTE_20,
      optionType: 'primary' as OptionType,
    },
  ];
  return (
    <div className="pt-8">
      <div className="flex flex-col gap-y-6">
        <div>
          {!showCustomAutoScalingPolicy && (
            <div
              className={`${
                !isUpdate
                  ? 'w-6/12 ll:w-[450px] flex gap-6 flex-col ll:flex-row flex-wrap'
                  : 'w-full gap-6 grid ll:grid-cols-2 grid-cols-1'
              }`}
            >
              <div
                className={`mb-6 ${
                  !isUpdate ? 'w-6/12 ll:w-[450px]' : 'w-full'
                }`}
              >
                <Dropdown
                  dropdownSize="compact"
                  dropdownType="default"
                  filled
                  onSelected={(value) => {
                    setAutoScalingPolicy({
                      ...autoScalingPolicy,
                      plan: value.value as AutoscalingPlanEnum,
                    });
                  }}
                  defaultSelected={autoScalingPolicy.plan}
                  options={autoScalingPlans}
                  label="PLAN"
                  placeholder="Choose Plan"
                  loading={false}
                />
              </div>
            </div>
          )}
          <div
            className={`${
              !isUpdate
                ? 'flex gap-6 flex-col ll:flex-row flex-wrap'
                : 'gap-6 grid ll:grid-cols-2 grid-cols-1'
            }`}
          >
            {maxMinReplicaField.map((field) => (
              <div
                key={field.id}
                className={`${!isUpdate ? 'w-6/12 ll:w-[450px]' : 'w-full'}`}
              >
                <TextInput
                  value={String(field.value)}
                  label={field.label}
                  onChange={(value) => field.handleChange(value as string)}
                />
              </div>
            ))}

            {showCustomAutoScalingPolicy && (
              <>
                <div
                  className={`${!isUpdate ? 'w-6/12 ll:w-[450px]' : 'w-full'}`}
                >
                  <Dropdown
                    dropdownSize="compact"
                    dropdownType="default"
                    filled
                    onSelected={(value) => {
                      setAutoScalingPolicy({
                        ...autoScalingPolicy,
                        timeWindow: value.value as AutoscalingTimeWindowEnum,
                      });
                    }}
                    defaultSelected={autoScalingPolicy.timeWindow || ''}
                    options={timeWindowField}
                    label="ENTER TIME WINDOW"
                    placeholder="Choose Time window"
                    loading={false}
                  />
                </div>
                <div
                  className={`${!isUpdate ? 'w-6/12 ll:w-[450px]' : 'w-full'}`}
                >
                  <Dropdown
                    dropdownSize="compact"
                    dropdownType="default"
                    filled
                    onSelected={(value) => {
                      setAutoScalingPolicy({
                        ...autoScalingPolicy,
                        cooldown: value.value as AutoscalingCooldownEnum,
                      });
                    }}
                    defaultSelected={autoScalingPolicy.cooldown || ''}
                    options={cooldownTimeField}
                    label="COOLDOWN"
                    placeholder="Cooldown time"
                    loading={false}
                  />
                </div>

                <div
                  className={`${!isUpdate ? 'w-6/12 ll:w-[450px]' : 'w-full'}`}
                >
                  <TextInput
                    value="5"
                    label="No of Windows (Before scaling evaluation)*"
                    disabled
                    onChange={() => {}}
                  />
                </div>
                <div
                  className={`${!isUpdate ? 'w-6/12 ll:w-[450px]' : 'w-full'}`}
                >
                  <TextInput
                    value="75%"
                    label="Treshold % No of Checks In Alarm*"
                    disabled
                    onChange={() => {}}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        {showCustomAutoScalingPolicy && (
          <>
            <div>
              <h3 className="font-bold text-xl mb-4">Scale Up Policy</h3>
              <div
                className={`${
                  !isUpdate
                    ? 'flex gap-6 flex-col ll:flex-row flex-wrap'
                    : 'gap-6 grid ll:grid-cols-2 grid-cols-1'
                }`}
              >
                {scaleUpFields.map((field) => (
                  <div
                    key={field.id}
                    className={`${
                      !isUpdate ? 'w-6/12 ll:w-[450px] max-w-1/2' : 'w-full'
                    }`}
                  >
                    {field.icon ? (
                      <div className="mb-3">{field.icon}</div>
                    ) : (
                      <div className="mb-9" />
                    )}
                    <div>
                      <TextInput
                        value={String(field.value)}
                        type="number"
                        label={field.label}
                        max={10}
                        onChange={(value) =>
                          field.handleChange(value as string)
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <h3 className="font-bold text-xl mb-4">Scale Down Policy</h3>

              <div
                className={`${
                  !isUpdate
                    ? 'flex gap-6 flex-col ll:flex-row flex-wrap'
                    : 'gap-6 grid ll:grid-cols-2 grid-cols-1'
                }`}
              >
                {scaleDownFields.map((field) => (
                  <div
                    key={field.id}
                    className={`${
                      !isUpdate ? 'w-6/12 ll:w-[450px] max-w-1/2' : 'w-full'
                    }`}
                  >
                    {field.icon ? (
                      <div className="mb-3">{field.icon}</div>
                    ) : (
                      <div className="mb-9" />
                    )}
                    <TextInput
                      value={String(field.value)}
                      type="number"
                      label={field.label}
                      max={10}
                      onChange={(value) => field.handleChange(value as string)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AutoScalingConfig;
