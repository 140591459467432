export const getDateAhead = (type: string, number: number) => {
  const d = new Date();
  if (type === 'day') {
    d.setDate(d.getDate() + number);
    return new Date(d).toLocaleDateString('en-us', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  }
  d.setFullYear(d.getFullYear() + number);
  return new Date(d).toLocaleDateString('en-us', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const convertLocalDateToUTC = (type: string, number: number) => {
  const d = new Date();
  if (type === 'day') {
    d.setUTCDate(d.getUTCDate() + number);
    return new Date(d).toUTCString();
  }
  d.setUTCFullYear(d.getUTCFullYear() + number);
  return new Date(d).toUTCString();
};

export const mapBannerToModal = (banner: string): string => {
  switch (banner) {
    case 'NEW_COMPUTE_APP':
      return 'computeOnboarding';
    case 'MAINNET_MIGRATION':
      return 'mainnetMigration';
    case 'CREDIT_CARD_ONBOARDING':
      return 'creditCardOnboarding';
    case 'STORAGE_ONBOARDING':
      return 'storageOnboarding';
    default:
      return '';
  }
};

export const copyToClipboard = async (value: string) => {
  await navigator.clipboard.writeText(value || '');
};

export const openProviderAppAuth = async (provider: string) => {
  // eslint-disable-next-line max-len
  const providerSignInUrl = `${window.location.origin}/${provider}/new-login-connection`;
  window.open(providerSignInUrl, '_blank');
};
