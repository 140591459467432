import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CardInfo from './credit-card';
import CryptoCardInfo from './crypto-card';
import {
  INetworkType,
  IOrganisationWallet,
  ITokenType,
} from '../../redux/web3/web3.interfaces';
import {
  ICreditCard,
  PaymentMethod,
} from '../../redux/combined-state.interface';
import NoCard from '../Compute/EmptyList/no-card';
import {
  fetchBalanceThunk,
  fetchWalletBalanceThunk,
} from '../../redux/web3/web3.thunks';
import { fetchSolBalanceThunk } from '../../redux/solana/solana.thunks';
import SpheronWallet from './spheron-wallet';
import { IOrgWallet } from '../../redux/organisation/organisation.interfaces';
import { isNonSubgraphChainId } from '../../redux/web3/web3.utils';
import { resetBalanceRtk } from '../../redux/web3/web3.slice';
import { AppDispatch, RootState } from '../../redux/compute/store';

interface IProps {
  tempPaymentMethod: ICreditCard | IOrganisationWallet | IOrgWallet | null;
  heading: JSX.Element | null;
}

const SelectPaymentMethod = ({ tempPaymentMethod, heading }: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();

  const selectedOrgWalletConfig = useSelector(
    (state: RootState) => state.organisation.selectedOrgWalletConfig
  );

  const selectedOrgCreditCardConfig = useSelector(
    (state: RootState) => state.organisation.selectedOrgCreditCardConfig
  );

  const balance = useSelector((state: RootState) => state.web3.balance);

  const balanceLoading = useSelector(
    (state: RootState) => state.web3.escrowBalanceLoading
  );

  const web3Wallets = useSelector((state: RootState) => state.web3.wallets);

  const spheronWallet = useSelector(
    (state: RootState) => state.organisation.spheronWallet
  );

  const creditCards = useSelector(
    (state: RootState) => state.stripe.creditCards
  );

  const allUsers = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation.users
  );
  const user = useSelector((state: RootState) => state.user.user);

  const isMember =
    allUsers?.find((u) => u?._id === user?._id)?.platformProfile?.role ===
      'member' && user !== null;

  const ethereum = (window as any)?.ethereum;

  useEffect(() => {
    dispatchRtk(resetBalanceRtk());
    if (
      tempPaymentMethod &&
      (tempPaymentMethod as IOrganisationWallet).paymentMethod ===
        PaymentMethod.CRYPTO
    ) {
      const { _id: walletId } = tempPaymentMethod as IOrganisationWallet;
      const { networkDetails, tokenDetails, address } = (
        tempPaymentMethod as IOrganisationWallet
      ).details;
      if (networkDetails?.chainName !== 'solana') {
        if (!isNonSubgraphChainId(Number(networkDetails.chainId))) {
          dispatchRtk(
            fetchBalanceThunk({
              walletId,
              balance: {
                address,
                token: tokenDetails.address,
                chainId: Number(networkDetails.chainId),
              },
            })
          );
        } else if (ethereum?.selectedAddress) {
          dispatchRtk(
            fetchWalletBalanceThunk({
              walletId,
              address,
              tokenDetails,
              networkDetails,
            })
          );
        }
      } else {
        dispatchRtk(
          fetchSolBalanceThunk({
            address,
            token: tokenDetails.address,
            chainId: Number(networkDetails.chainId),
          })
        );
      }
    } else if (selectedOrgWalletConfig._id) {
      const {
        networkDetails,
        tokenDetails,
        address,
        _id: walletId,
      } = selectedOrgWalletConfig;
      if (networkDetails?.chainName === 'solana') {
        dispatchRtk(
          fetchSolBalanceThunk({
            address,
            token: tokenDetails!.address,
            chainId: Number(networkDetails!.chainId),
          })
        );
      } else if (tempPaymentMethod === null) {
        if (!isNonSubgraphChainId(Number(networkDetails?.chainId))) {
          dispatchRtk(
            fetchBalanceThunk({
              walletId: selectedOrgWalletConfig._id,
              balance: {
                address,
                token: tokenDetails!.address,
                chainId: Number(networkDetails!.chainId),
              },
            })
          );
        } else if (ethereum?.selectedAddress) {
          dispatchRtk(
            fetchWalletBalanceThunk({
              walletId,
              address,
              tokenDetails,
              networkDetails,
            })
          );
        }
      }
    }
  }, [tempPaymentMethod, selectedOrgWalletConfig, dispatchRtk, ethereum]);

  const showNoPaymentMethod =
    tempPaymentMethod === null &&
    selectedOrgWalletConfig._id === '' &&
    !spheronWallet &&
    selectedOrgCreditCardConfig === null;

  const shouldShowDropdown =
    web3Wallets.length !== 0 || creditCards.length !== 0;

  const showpaymentMethod = () => {
    const {
      brand,
      last4,
      expiration_month: expirationMonth,
      expiration_year: expirationYear,
    } = (tempPaymentMethod as ICreditCard).details.info || {};

    const { address, networkDetails, tokenDetails } =
      (tempPaymentMethod as IOrganisationWallet).details || {};

    switch (tempPaymentMethod?.paymentMethod) {
      case PaymentMethod.CREDITCARD:
        return (
          <CardInfo
            disabled={isMember}
            showDropdown
            loading={false}
            id={tempPaymentMethod._id}
            cardType={brand || ''}
            cardNumber={last4 || ''}
            expiry={`${expirationMonth}/${expirationYear}`}
            isPrimary={tempPaymentMethod.primary}
          />
        );
      case PaymentMethod.CRYPTO:
        return (
          <CryptoCardInfo
            disabled={isMember}
            showDropdown
            address={address}
            isSolana={(networkDetails as INetworkType)?.chainName === 'solana'}
            network={networkDetails as INetworkType}
            token={tokenDetails as ITokenType}
            balance={balance}
            balanceLoading={balanceLoading}
            disconnectWalletLoading={false}
            isPrimary={tempPaymentMethod.primary}
            id={(tempPaymentMethod as IOrganisationWallet)._id}
          />
        );
      case PaymentMethod.SPHERONWALLET:
        return (
          <SpheronWallet disabled={isMember} showDropdown loading={false} />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        {heading && heading}
      </div>
      <div className={heading ? 'mt-5' : 'mt-0'}>
        {showNoPaymentMethod ? (
          <NoCard options={[]} />
        ) : (
          <>
            {tempPaymentMethod ? (
              <>{showpaymentMethod()}</>
            ) : (
              <>
                {selectedOrgWalletConfig._id && (
                  <CryptoCardInfo
                    disabled={isMember}
                    showDropdown={shouldShowDropdown}
                    address={selectedOrgWalletConfig.address}
                    isSolana={
                      (selectedOrgWalletConfig.networkDetails as INetworkType)
                        ?.chainName === 'solana'
                    }
                    network={
                      selectedOrgWalletConfig.networkDetails as INetworkType
                    }
                    token={selectedOrgWalletConfig.tokenDetails as ITokenType}
                    balance={balance}
                    balanceLoading={balanceLoading}
                    disconnectWalletLoading={false}
                    isPrimary
                    id={selectedOrgWalletConfig._id}
                  />
                )}
                {selectedOrgCreditCardConfig?._id && (
                  <CardInfo
                    disabled={isMember}
                    showDropdown={shouldShowDropdown}
                    loading={false}
                    id={selectedOrgCreditCardConfig._id}
                    cardType={
                      selectedOrgCreditCardConfig.details.info?.brand || ''
                    }
                    cardNumber={
                      selectedOrgCreditCardConfig.details.info?.last4 || ''
                    }
                    // eslint-disable-next-line max-len
                    expiry={`${selectedOrgCreditCardConfig.details.info?.expiration_month}/${selectedOrgCreditCardConfig.details.info?.expiration_year}`}
                    isPrimary
                  />
                )}
                {spheronWallet?._id &&
                  !selectedOrgCreditCardConfig?._id &&
                  !selectedOrgWalletConfig._id && (
                    <SpheronWallet
                      disabled={isMember}
                      showDropdown={shouldShowDropdown}
                      loading={false}
                    />
                  )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SelectPaymentMethod;
