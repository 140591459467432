/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link, Tooltip } from '@spheron/ui-library';
import { ReactComponent as CopyIcon } from '@spheron/ui-library/dist/assets/copy.svg';
import React, { useEffect, useState } from 'react';
import { ReactComponent as ConnectedIcon } from '@spheron/ui-library/dist/assets/connected.svg';
import { copyToClipboard } from '../../../redux/compute/user/user.utils';

interface ITableRow {
  id: string;
  internalPort: number;
  externalPort: string;
  isPublic: boolean;
  exposedTo: string;
  connectionUrl: string;
}

interface IProps {
  tableRows: ITableRow[];
}

const PortPolicyTable = ({ tableRows }: IProps) => {
  const tableHeading = [
    { id: 1, label: 'Internal Port' },
    { id: 2, label: 'External Port' },
    { id: 3, label: 'Public' },
    { id: 4, label: 'Exposed To' },
    { id: 5, label: 'Connection URL' },
  ];
  const [codeCopied, setCodeCopied] = useState<string>('');

  const handleCodeCopyClick = (bucketId: string) => {
    copyToClipboard(bucketId);
    setCodeCopied(bucketId);
  };

  useEffect(() => {
    setTimeout(() => {
      setCodeCopied('');
    }, 1000);
  }, [codeCopied]);

  return (
    <div
      className="p-3 bg-base-bg dark:bg-dark-base-bg border
       border-base-border dark:border-dark-base-border
    rounded-lg"
    >
      <table className="w-full">
        <thead>
          <tr className="bg-cards-hover dark:bg-dark-cards-hover w-full">
            {tableHeading.map((heading) => (
              <th
                key={heading.id}
                className="text-cards-text dark:text-dark-cards-text
                 uppercase text-xs font-semibold w-1/5 px-2 py-1 text-left"
              >
                {heading.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableRows.map((row) => (
            <tr
              key={row.id}
              className="text-sm font-normal text-cards-text dark:text-dark-cards-text text-left"
            >
              <td className="w-1/5 p-2">{row.internalPort}</td>
              <td className="w-1/5 p-2">{row.externalPort}</td>
              <td className="w-1/5 p-2">{row.isPublic ? '✅ Yes' : '🚫 No'}</td>
              <td className="w-1/5 p-2">{row.exposedTo || 'Global'}</td>
              <td className="w-1/5 p-2">
                {row.connectionUrl ? (
                  <div className="flex items-center justify-start gap-x-2">
                    <Link
                      type="primary"
                      size="default"
                      text={row.connectionUrl}
                      isBold={false}
                      onClick={() => window.open(row.connectionUrl, '_target')}
                      leftIcon={
                        // eslint-disable-next-line max-len
                        <ConnectedIcon className="text-cards-icon dark:text-dark-cards-icon w-5 h-5" />
                      }
                    />
                    <Tooltip
                      position="top"
                      text={codeCopied ? 'Copied' : ''}
                      type="float"
                      wrapText
                    >
                      <CopyIcon
                        className="text-base-icon dark:text-dark-base-icon
                        w-5 h-5 cursor-pointer opacity-80 hover:opacity-100"
                        onClick={() => handleCodeCopyClick(row.connectionUrl)}
                      />
                    </Tooltip>
                  </div>
                ) : (
                  <></>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PortPolicyTable;
