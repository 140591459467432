/* eslint-disable import/no-cycle */
import { configureStore, Middleware, MiddlewareArray } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import rootReducer from './root-reducer';

const middlewares: Middleware[] = new MiddlewareArray().concat([]);

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(...middlewares),
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;

// RootState` and `AppDispatch` types
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
