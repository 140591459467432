import React from 'react';
import { ConfirmationModal, Modal } from '@spheron/ui-library';
import { useDispatch } from 'react-redux';
import Styles from '../../../styles/compute/modal.module.scss';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
// eslint-disable-next-line max-len
import { toggleConfirmRedeploySwitchRtk } from '../../../redux/compute/instance/instance-creation/instance-creation.slice';
// eslint-disable-next-line max-len
import { ConfirmRedeploySwitchType } from '../../../redux/compute/instance/instance-creation/instance-creation.interfaces';
import ModalWrapper from './ModalWrapper';
import { AppDispatch } from '../../../redux/compute/store';

interface IProps {
  isOpen: boolean;
  type: ConfirmRedeploySwitchType;
  confirmCallback: () => void;
}

function ConfirmRedeploySwitch({ isOpen, type, confirmCallback }: IProps) {
  const dispatchRtk = useDispatch<AppDispatch>();
  const closeModal = () => {
    dispatchRtk(
      toggleModalShowRtk({
        modalShow: false,
        type: ConfirmRedeploySwitchType.DEFAULT,
      })
    );
  };
  const handleConfirm = () => {
    confirmCallback();
    dispatchRtk(toggleConfirmRedeploySwitchRtk({ state: true, type }));
    dispatchRtk(
      toggleModalShowRtk({
        modalShow: false,
        type: ConfirmRedeploySwitchType.DEFAULT,
      })
    );
  };
  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={() => {
        dispatchRtk(
          toggleModalShowRtk({
            modalShow: false,
            type: ConfirmRedeploySwitchType.DEFAULT,
          })
        );
      }}
      classname={`${Styles.modal__outer__con} modal fixed inset-0 overflow-y-auto`}
    >
      <div className={Styles.modal__inner__content}>
        <Modal
          footer
          header
          subtitle=""
          modalContent={
            <span className="font-normal text-sm leading-5">
              Are you certain you wish to modify the{' '}
              {type === ConfirmRedeploySwitchType.CLUSTER
                ? 'instance type'
                : 'marketplace app'}
              ? Proceeding will deploy the instance within a new cluster.
            </span>
          }
          headerContent={<>Are you sure?</>}
          primaryButtonContent="Continue"
          secondaryButtonContent="Cancel"
          primaryButton
          primaryButtonLoading={false}
          secondaryButtonDisabled={false}
          secondaryButton
          onPrimaryButtonClick={handleConfirm}
          onSecondaryButtonClick={closeModal}
          confirmationStatus={ConfirmationModal.ERROR}
        />
      </div>
    </ModalWrapper>
  );
}

export default ConfirmRedeploySwitch;
