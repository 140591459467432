import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Checkbox, Dropdown, Modal, OptionType } from '@spheron/ui-library';
import { ReactComponent as CircularPlus } from '@spheron/ui-library/dist/assets/plus-circle.svg';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CloseIcon } from '../../../assets/compute/icons/close-cross.svg';
// eslint-disable-next-line max-len
import { ISelectedOrganisationWalletConfig } from '../../../redux/compute/organisation/organisation.interfaces';
import { getTokenLivePrice } from '../../../redux/compute/subscription/subscription.services';
import { bonusParse } from '../../../redux/compute/subscription/subscription.utils';
import SpheronLoader from '../Loaders/spheron-loader';
import {
  IOrganization,
  PaymentMethod,
} from '../../../redux/combined-state.interface';
import {
  IAllowedBonus,
  IBonusIterator,
  IProcessor,
  IProcessorType,
} from '../../../redux/compute/subscription/subscription.interfaces';
import {
  getCurrentAppState,
  mapCurrentAppToSpecialization,
} from '../../../redux/compute/organisation/organisation.utils';
import { ReservationType } from '../../../redux/solana/solana.utils';
import config from '../../../config';
import SelectPaymentMethod from '../Cards/select-payment-methods';
import { usePaymentMethod } from '../../../hooks/Compute/useSelectedPayment';
import { IOrganisationWallet } from '../../../redux/compute/web3/web3.interfaces';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import { createBonusSubscriptionThunk } from '../../../redux/compute/subscription/subscription.thunks';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import { createBonusReservationThunk } from '../../../redux/compute/solana/solana.thunks';
import NoCard from '../EmptyList/no-card';
import AddPaymentMethod from '../Dropdown/add-payment-method';
import {
  getDecimalFromTokenAddress,
  isNonSubgraphChainId,
} from '../../../redux/compute/web3/web3.utils';
import ModalWrapper from './ModalWrapper';
import Styles from '../../../styles/compute/modal.module.scss';

interface IProps {
  isOpen: boolean;
}
const BonusPackage = ({ isOpen }: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();
  const [active, setActive] = useState(1);
  const [showAddPaymentMethod, setShowAddPaymentMethod] =
    useState<boolean>(false);

  const subscriptionUpdating = useSelector(
    (state: RootState) => state.subscription.subscriptionUpdating
  );
  const selectedOrganisation: IOrganization | null = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation
  );
  const selectedOrgWalletConfig: ISelectedOrganisationWalletConfig =
    useSelector(
      (state: RootState) => state.organisation.selectedOrgWalletConfig
    );

  const activeBonusLoading: boolean = useSelector(
    (state: RootState) => state.subscription.activeBonusLoading
  );

  const allowedBonusesLoading: boolean = useSelector(
    (state: RootState) => state.subscription.allowedBonusesLoading
  );

  const allowedBonusIterator: IBonusIterator[] = useSelector(
    (state: RootState) => state.subscription.allowedBonusIterator
  );

  const allowedBonus: string[] = useSelector(
    (state: RootState) => state.subscription.allowedBonus
  );

  const tempPaymentMethodId = useSelector(
    (state: RootState) => state.organisation.tempPaymentMethodId
  );

  const selectedOrgCreditCardConfig = useSelector(
    (state: RootState) => state.organisation.selectedOrgCreditCardConfig
  );

  const primaryPaymentMethodId = useSelector(
    (state: RootState) => state.organisation.primaryPaymentMethodId
  );

  const purchasingBonus = useSelector(
    (state: RootState) => state.subscription.purchasingBonus
  );

  const balance = useSelector((state: RootState) => state.web3.balance);

  const [totalAmount, setTotalAmount] = useState(0);

  const bonusAmountIterator: IAllowedBonus =
    Object.fromEntries(
      allowedBonusIterator.map((bonus) => [bonus.name, bonus.value])
    ) || [];

  const currentApp = useSelector(
    (state: RootState) => state.organisation.currentApp
  );

  const [bonusInputs, setBonusInputs] = useState([
    {
      id: 1,
      name: '',
      value: '',
      renew: false,
    },
  ]);

  const setBonusInputRenew = (renew: boolean, id: number) => {
    const newInputs: any[] = [];
    bonusInputs.forEach((input: any) => {
      if (input.id === id) {
        newInputs.push({
          ...input,
          renew,
        });
      } else {
        newInputs.push(input);
      }
    });
    setBonusInputs(newInputs);
  };

  const setBonusInputName = (name: string, id: number) => {
    const newInputs: any[] = [];
    bonusInputs.forEach((input: any) => {
      if (input.id === id) {
        newInputs.push({
          ...input,
          name,
        });
      } else {
        newInputs.push(input);
      }
    });
    setBonusInputs(newInputs);
  };

  const setBonusInputValue = (value: string, id: number) => {
    const newInputs: {
      id: number;
      name: string;
      value: string;
      renew: boolean;
    }[] = [];
    bonusInputs.forEach((input) => {
      if (input.id === id) {
        newInputs.push({
          ...input,
          value,
        });
      } else {
        newInputs.push(input);
      }
    });
    setBonusInputs(newInputs);
  };

  // SOLANA PASSWORD PROTECTION FILTER

  const isSolana: boolean = useSelector(
    (state: RootState) =>
      state.organisation.selectedOrgWalletConfig.networkDetails?.chainName ===
      'solana'
  );

  const filteredBonus = isSolana
    ? allowedBonus.filter((bonus) => bonus !== 'BONUS_PASSWORD_PROTECTION')
    : allowedBonus;

  const bonusOptions = [
    {
      label: 'Select Add-on',
      value: '',
      optionType: 'primary' as OptionType,
    },
    ...filteredBonus
      .filter((b) => bonusInputs.map((i) => i.name).indexOf(b) === -1)
      .map((bonus: any) => {
        const newInfo: any = bonusParse(bonus);
        return {
          value: bonus,
          label: newInfo.params,
          optionType: 'primary' as OptionType,
        };
      }),
  ];

  const checkForEmptyValues = (arr: any[]) => {
    return !!arr.find(
      (p: { name: string; value: string }) =>
        p.name.length === 0 || p.value.length === 0
    );
  };
  const [liveTokenPrice, setLiveTokenPrice] = useState<number>(1);
  const [liveTokenPriceLoading, setLiveTokenPriceLoading] = useState(true);

  useEffect(() => {
    let total = 0;
    bonusInputs.forEach((b: any) => {
      const { livePrice }: any = bonusParse(b.name, Number(b.value));
      total += livePrice * Number(b.value);
    });
    setTotalAmount(total);
  }, [bonusInputs]);

  const generateAmountArray = (bonus: string) => {
    if (bonus) {
      const bonusOptions = [];
      if (bonus === 'BONUS_PASSWORD_PROTECTION')
        bonusOptions.push({
          label: `1`,
          value: `1`,
          optionType: 'primary' as OptionType,
        });
      else
        for (let i = 1; i < 11; i += 1) {
          bonusOptions.push({
            label: `${
              bonus === 'BONUS_CLUSTER_AKT'
                ? `${bonusAmountIterator[bonus as keyof IAllowedBonus]?.unit} ${
                    Number(
                      bonusAmountIterator[bonus as keyof IAllowedBonus]
                        ?.iterator
                    ) *
                    i *
                    Number(
                      bonusAmountIterator[bonus as keyof IAllowedBonus]?.base
                    )
                  }`
                : `${
                    Number(
                      bonusAmountIterator[bonus as keyof IAllowedBonus]
                        ?.iterator
                    ) *
                    i *
                    Number(
                      bonusAmountIterator[bonus as keyof IAllowedBonus]?.base
                    )
                  } ${bonusAmountIterator[bonus as keyof IAllowedBonus]?.unit}`
            }`,
            value:
              i === 0
                ? `${bonusAmountIterator[bonus as keyof IAllowedBonus]?.base}`
                : `${
                    Number(
                      bonusAmountIterator[bonus as keyof IAllowedBonus]
                        ?.iterator
                    ) *
                    i *
                    Number(
                      bonusAmountIterator[bonus as keyof IAllowedBonus]?.base
                    )
                  }`,
            optionType: 'primary' as OptionType,
          });
        }
      return bonusOptions;
    }
    return [];
  };

  const isBonusBuildExecution = (bonus: string) => {
    return (
      bonus === 'BONUS_BUILD_EXECUTION' ||
      bonus === 'BONUS_CLUSTER_BUILD_EXECUTION'
    );
  };

  const { tempPaymentMethod } = usePaymentMethod(tempPaymentMethodId);

  const createBonus = () => {
    if (
      (selectedOrgWalletConfig.networkDetails?.chainName === 'solana' &&
        tempPaymentMethod === null) ||
      (tempPaymentMethod?.paymentMethod === PaymentMethod.CRYPTO &&
        (tempPaymentMethod as IOrganisationWallet).details.networkDetails
          .chainName === 'solana')
    ) {
      const payload = {
        organizationId: selectedOrganisation?._id,
        bonusParameterNames: bonusInputs.map((bonus) => bonus.name),
        bonusParameterValues: bonusInputs.map((bonus) => bonus.value),
        specialization: mapCurrentAppToSpecialization(
          getCurrentAppState(currentApp)
        ),
        processorType: IProcessorType.SMART_CONTRACT,
        processor: IProcessor.SOLANA,
        details: {
          walletAddress:
            (tempPaymentMethod as IOrganisationWallet)?.details?.address ||
            selectedOrgWalletConfig.address,
          walletId: tempPaymentMethodId || primaryPaymentMethodId || '',
          amount: Number(
            (totalAmount / liveTokenPrice).toFixed(
              config.web3.solana.AMOUNT_DECIMAL_PRECISION
            )
          ),
        },
      };
      dispatchRtk(createBonusReservationThunk(payload));
      dispatchRtk(
        toggleModalShowRtk({
          modalShow: true,
          modalType: 'solanaPay',
          options: {
            walletAddress:
              (tempPaymentMethod as IOrganisationWallet)?.details?.address ||
              selectedOrgWalletConfig.address,
            amount: Number(
              (totalAmount / liveTokenPrice).toFixed(
                config.web3.solana.AMOUNT_DECIMAL_PRECISION
              )
            ),
            type: ReservationType.Bonus,
          },
        })
      );
    } else
      dispatchRtk(
        createBonusSubscriptionThunk({
          walletId: tempPaymentMethodId || primaryPaymentMethodId || '',
          organizationId: selectedOrganisation?._id,
          bonusParameterNames: bonusInputs.map((bonus) => bonus.name),
          bonusParameterValues: bonusInputs.map((bonus) => bonus.value),
          specialization: mapCurrentAppToSpecialization(
            getCurrentAppState(currentApp)
          ),
          renewValues: bonusInputs.map((bonus) => bonus.renew),
        })
      );
  };

  const isCreditCardPayment =
    (selectedOrgCreditCardConfig &&
      selectedOrgCreditCardConfig!._id!.length > 0 &&
      tempPaymentMethod === null) ||
    tempPaymentMethod?.paymentMethod === PaymentMethod.CREDITCARD;

  const isSpheronWalletPayment =
    (!selectedOrgCreditCardConfig &&
      selectedOrgWalletConfig?._id?.length === 0) ||
    tempPaymentMethod?.paymentMethod === PaymentMethod.SPHERONWALLET;

  useEffect(() => {
    setLiveTokenPriceLoading(true);
    if (
      tempPaymentMethod &&
      tempPaymentMethod.paymentMethod === PaymentMethod.CRYPTO
    ) {
      getTokenLivePrice(
        (tempPaymentMethod as IOrganisationWallet).details.tokenDetails
          .symbol || 'USDT'
      ).then((res) => {
        setLiveTokenPrice(Number(res.price));
        setLiveTokenPriceLoading(false);
      });
    } else if (selectedOrgWalletConfig._id) {
      getTokenLivePrice(
        selectedOrgWalletConfig?.tokenDetails?.symbol || 'USDT'
      ).then((res) => {
        setLiveTokenPrice(Number(res.price));
        setLiveTokenPriceLoading(false);
      });
    }
  }, [
    selectedOrgWalletConfig._id,
    selectedOrgWalletConfig?.tokenDetails?.symbol,
    tempPaymentMethod,
  ]);

  const isCryptoPayment = !isCreditCardPayment && !isSpheronWalletPayment;

  const token =
    tempPaymentMethod &&
    tempPaymentMethod.paymentMethod === PaymentMethod.CRYPTO
      ? (tempPaymentMethod as IOrganisationWallet).details.tokenDetails.symbol
      : selectedOrgWalletConfig.tokenDetails?.symbol;

  const paymentSummary = isCryptoPayment
    ? [
        {
          id: 1,
          heading: false,
          label: 'MONTHLY TOTAL',
          value: <>${totalAmount?.toFixed(2)}</>,
        },
        {
          id: 2,
          heading: false,
          label: 'CHOSEN TOKEN',
          value: (
            <>
              {tempPaymentMethod &&
              tempPaymentMethod.paymentMethod === PaymentMethod.CRYPTO
                ? (tempPaymentMethod as IOrganisationWallet).details
                    .tokenDetails.symbol
                : selectedOrgWalletConfig?.tokenDetails?.symbol || 'USDT'}
            </>
          ),
        },
        {
          id: 3,
          heading: false,
          label: 'MARKET PRICE',
          value: (
            <>
              {liveTokenPriceLoading ? (
                <Skeleton height={15} width={90} containerClassName="flex" />
              ) : (
                <>
                  {' '}
                  ${liveTokenPrice.toFixed(2)} USD / {token}
                </>
              )}
            </>
          ),
        },
        {
          id: 4,
          heading: true,
          label: 'TOTAL VALUE',
          value: (
            <>
              {(totalAmount / liveTokenPrice).toFixed(2)} {token}
            </>
          ),
        },
      ]
    : [
        {
          id: 5,
          heading: true,
          label: 'MONTHLY TOTAL',
          value: <>${totalAmount}</>,
        },
      ];

  const network =
    tempPaymentMethod &&
    tempPaymentMethod.paymentMethod === PaymentMethod.CRYPTO
      ? (tempPaymentMethod as IOrganisationWallet).details.networkDetails
      : selectedOrgWalletConfig.networkDetails;

  const normalizedBalance = !isNonSubgraphChainId(Number(network?.chainId))
    ? Number(balance.amount) / 10 ** getDecimalFromTokenAddress(balance.token)
    : Number(balance.amount);

  const priceCheckValidator = (balance: number): boolean => {
    return !!(balance < totalAmount / liveTokenPrice);
  };

  const isReviewPage = active > 1;

  const handleCancelClick = () =>
    isReviewPage
      ? setActive(1)
      : dispatchRtk(
          toggleModalShowRtk({
            modalShow: false,
          })
        );

  const paymentMethodExists = !!primaryPaymentMethodId || !!tempPaymentMethodId;

  const buttonText = () => {
    if (paymentMethodExists) {
      if (isReviewPage) {
        if (isCryptoPayment && priceCheckValidator(normalizedBalance)) {
          return 'Deposit';
        }
        return 'Buy Add-On';
      }
      return 'Review for Payment';
    }
    return 'Attach Payment Method';
  };

  const handleNextClick = () => {
    if (paymentMethodExists) {
      if (isReviewPage) {
        if (isCryptoPayment && priceCheckValidator(normalizedBalance)) {
          dispatchRtk(
            toggleModalShowRtk({
              modalShow: true,
              modalType: 'funds',
              options: {
                type: 'deposit',
                walletId: tempPaymentMethodId || primaryPaymentMethodId,
              },
            })
          );
        }
        createBonus();
      }
      setActive(2);
    } else setShowAddPaymentMethod(true);
  };

  return (
    <>
      <ModalWrapper
        isOpen={isOpen}
        onClose={() => {
          dispatchRtk(toggleModalShowRtk({ modalShow: false }));
        }}
        classname={`${Styles.modal__outer__con} modal fixed inset-0 overflow-y-auto`}
      >
        <div className="w-500">
          <Modal
            header
            headerContent={
              <>{isReviewPage ? 'Review for Payment' : 'Add Add-Ons'}</>
            }
            footer
            modalContent={
              <div className="text-center flex-col space-y-3">
                <div>
                  {subscriptionUpdating || purchasingBonus ? (
                    <div
                      className="w-full py-8 flex flex-col justify-center items-center border
                         border-gray-100 rounded-xl dark:bg-dark-base-fg
                          dark:border-dark-base-border"
                    >
                      <SpheronLoader height={90} width={90} />
                      <br />
                      <h1 className="font-semibold text-gray-600 text-2xl">
                        Purchasing Add-ons
                      </h1>
                    </div>
                  ) : (
                    <>
                      {active === 1 && (
                        <>
                          <div
                            style={{
                              paddingBottom: '3rem',
                            }}
                            className="flex flex-col gap-y-6"
                          >
                            {bonusInputs.map((inputItem) => (
                              <div className="flex flex-col items-start">
                                <div className="flex w-full items-center gap-x-2">
                                  <div className="flex-1 flex items-center gap-x-8 text-left">
                                    <div key={inputItem.id} className="flex-1">
                                      <Dropdown
                                        dropdownSize="default"
                                        dropdownType="default"
                                        filled
                                        onSelected={(option) => {
                                          setBonusInputName(
                                            option.value as string,
                                            inputItem.id
                                          );
                                        }}
                                        defaultSelected={inputItem.name}
                                        options={
                                          inputItem.name
                                            ? [
                                                ...bonusOptions,

                                                {
                                                  value: inputItem.name,
                                                  label: bonusParse(
                                                    inputItem.name
                                                  ).params,
                                                  optionType:
                                                    'primary' as OptionType,
                                                },
                                              ]
                                            : bonusOptions
                                        }
                                        loading={allowedBonusesLoading}
                                        label="ADD ON"
                                        placeholder="Choose Add on"
                                      />
                                    </div>
                                    <div className="flex-1">
                                      <Dropdown
                                        dropdownSize="default"
                                        dropdownType="default"
                                        filled
                                        onSelected={(option) => {
                                          setBonusInputValue(
                                            option.value as string,
                                            inputItem.id
                                          );
                                        }}
                                        defaultSelected={inputItem.value}
                                        options={[
                                          {
                                            label: 'Select Add-on Amount',
                                            value: '',
                                            optionType: 'primary' as OptionType,
                                          },

                                          ...generateAmountArray(
                                            inputItem.name
                                          ),
                                        ]}
                                        loading={allowedBonusesLoading}
                                        label="AMOUNT"
                                        placeholder="Choose Amount"
                                      />
                                    </div>
                                  </div>
                                  {bonusInputs.length > 1 && (
                                    <div
                                      role="presentation"
                                      className="col-span-2 mt-6
                                         justify-self-center cursor-pointer"
                                      onClick={() =>
                                        bonusInputs.length > 1 &&
                                        setBonusInputs(
                                          bonusInputs.filter(
                                            (i) => i.id !== inputItem.id
                                          )
                                        )
                                      }
                                    >
                                      <CloseIcon
                                        className="
                                           hover: h-3 w-3"
                                      />
                                    </div>
                                  )}
                                </div>
                                <div className="flex items-center justify-start gap-x-1 mt-2">
                                  <Checkbox
                                    checked={inputItem.renew}
                                    onClick={() =>
                                      setBonusInputRenew(
                                        !inputItem.renew,
                                        inputItem.id
                                      )
                                    }
                                    size="small"
                                    label="Recurring"
                                    id="bonus-package"
                                  />
                                </div>
                              </div>
                            ))}
                          </div>

                          <div
                            role="presentation"
                            className="flex items-center justify-start 
                                cursor-pointer font-bold text-action-primary-default"
                            onClick={() =>
                              setBonusInputs([
                                ...bonusInputs,
                                {
                                  id: bonusInputs.length + 1,
                                  name: '',
                                  value: '',
                                  renew: false,
                                },
                              ])
                            }
                          >
                            <CircularPlus className="text-action-primary-default w-5 h-5 mr-2" />
                            Add more
                          </div>
                        </>
                      )}
                      {active === 2 && (
                        <>
                          <div
                            className="py-2 border-t border-b border-base-border
                                dark:border-dark-base-border"
                          >
                            <div
                              className="grid grid-cols-4 w-[95%] mx-auto
                                gap-x-2 lg:gap-x-9 justify-center font-semibold text-sm"
                            >
                              <div className="justify-self-start">
                                <div>Item</div>
                              </div>
                              <div className="justify-self-end">
                                <div>Usage</div>
                              </div>
                              <div className="justify-self-end">
                                <div>Unit Price</div>
                              </div>
                              <div className="justify-self-end">
                                <div>Amount</div>
                              </div>
                            </div>
                          </div>
                          {!checkForEmptyValues(bonusInputs) &&
                            bonusInputs.map((bonus) => {
                              const { params, rate, val, livePrice }: any =
                                bonusParse(bonus.name, Number(bonus.value));

                              return (
                                <div
                                  key={bonus.id}
                                  className="py-4 border-b dark:border-dark-base-border
                                           border-base-border"
                                >
                                  <div
                                    className="grid grid-cols-4 
                                gap-x-2 md:gap-x-6 lg:gap-x-9 justify-center font-normal
                                w-[95%] mx-auto"
                                  >
                                    <div
                                      className="text-sm
                                           text-left
                                           whitespace-pre"
                                    >
                                      {params}
                                    </div>
                                    <div
                                      className="text-right text-sm
                                             font-semibold
                                      justify-self-end 
                                      whitespace-pre-line"
                                    >
                                      {isBonusBuildExecution(bonus.name) ? (
                                        <>
                                          {' '}
                                          {parseFloat(bonus.value).toFixed(
                                            2
                                          )}{' '}
                                          mins
                                        </>
                                      ) : (
                                        <> {val.split(',').join('\n')}</>
                                      )}
                                      {bonus.renew && (
                                        <span className="ml-1.5 font-normal text-xxs">
                                          (Recurring)
                                        </span>
                                      )}
                                    </div>
                                    <div
                                      className="text-sm font-semibold
                                              
                                      lg:justify-self-end whitespace-pre-line"
                                      style={{ whiteSpace: 'pre-line' }}
                                    >
                                      {rate.split(',').join('\n')}
                                    </div>
                                    <div
                                      className="text-sm
                                             font-semibold
                                       justify-self-end "
                                    >
                                      $
                                      {Number(
                                        livePrice * Number(bonus.value)
                                      ).toFixed(2)}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          {!isReviewPage && <hr className="my-4 bg-gray-300" />}
                          {(tempPaymentMethod || primaryPaymentMethodId) && (
                            <>
                              <div className="mt-4 space-y-2 text-xs font-semibold">
                                {paymentSummary.map((payment) => (
                                  <div
                                    key={payment.id}
                                    className="flex justify-between items-center"
                                  >
                                    <div
                                      className={`${
                                        payment.heading
                                          ? 'text-xl font-semibold  '
                                          : ' font-medium'
                                      }
                                             dark:text-gray-200`}
                                    >
                                      {payment.label}
                                    </div>
                                    <div
                                      className={`${
                                        payment.heading
                                          ? 'text-xl font-semibold  '
                                          : ' font-medium'
                                      }
                                            `}
                                    >
                                      {payment.value}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </>
                          )}
                        </>
                      )}
                      <hr
                        className="border border-base-border dark:border-dark-base-border
                            my-6"
                      />
                      {tempPaymentMethod || primaryPaymentMethodId ? (
                        <>
                          <SelectPaymentMethod
                            heading={
                              <h5
                                className="text-left mb-3
                                font-semibold"
                              >
                                Select Payment Method
                              </h5>
                            }
                            tempPaymentMethod={tempPaymentMethod}
                          />
                        </>
                      ) : (
                        <NoCard options={[]} />
                      )}
                      <div className="mt-8">
                        <div className="flex justify-end items-center gap-x-4">
                          {showAddPaymentMethod && (
                            <div className="absolute top-[90%] left-[90%] z-50">
                              <AddPaymentMethod
                                setShowAddPaymentMethod={
                                  setShowAddPaymentMethod
                                }
                              />{' '}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            }
            onPrimaryButtonClick={handleNextClick}
            primaryButton
            secondaryButton
            onSecondaryButtonClick={handleCancelClick}
            primaryButtonContent={buttonText()}
            primaryButtonDisabled={
              paymentMethodExists ? checkForEmptyValues(bonusInputs) : false
            }
            primaryButtonLoading={
              // eslint-disable-next-line no-nested-ternary
              paymentMethodExists
                ? isReviewPage
                  ? activeBonusLoading
                  : false
                : false
            }
            secondaryButtonContent="Cancel"
            subtitle={''}
          />
        </div>
      </ModalWrapper>
    </>
  );
};
export default BonusPackage;
