/* eslint-disable import/no-cycle */
import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import config from '../../config';
// import { bootIntercom } from '../../libs/intercom';
import { IResponseError } from '../combined-reducer.interface';
import {
  IOrganization,
  IPlatformProfile,
  IUser,
} from '../combined-state.interface';
import { toggleModalShowRtk } from '../modal/modal.slice';
import { NotificationType } from '../notification/notification.interfaces';
import { addNotificationRtk } from '../notification/notification.slice';
import { ICurrentApp } from '../organisation/organisation.interfaces';
import {
  disconnectLoginConnectionLoadingRtk,
  organisationsLoadedRtk,
  setCurrentAppType,
  setSelectedMasterOrg,
} from '../organisation/organisation.slice';
import {
  getSelectedOrganisationByNameThunk,
  getSelectedOrganisationThunk,
} from '../organisation/organisation.thunks';
import { RootState } from '../rtk-store';
import { mapStringToThemeEnum } from '../theme/theme.utils';
import {
  IAdditionalInfoPayloadDto,
  ICreateApiTokenPayloadDto,
  IReferralWithrawal,
  IRequestReferralWithdrawal,
} from './user.interfaces';
import {
  changeNotificationStatus,
  createTokenService,
  deleteTokensService,
  // fetchIntercomHash,
  fetchUser,
  getBannerService,
  getRaiseRequestLimitService,
  getReferralCodeService,
  getReferralCreditsService,
  getReferralDetailsService,
  getReferralWithdrawalsService,
  getTokensService,
  getUserNotifications,
  markAllNotificationsAsRead,
  removeProviderService,
  requestReferralWithdrawalService,
  seenBannerService,
  setReferralCodeService,
  updateUserDefaultOrganisationService,
  updateUserOnboardingDetailsService,
  updateUserProfileData,
} from './user.services';
// eslint-disable-next-line import/no-cycle
import { logoutUserRtk, setReferralEnabledRtk, setUserRtk } from './user.slice';
import { setThemeRtk } from '../theme/theme.slice';
import {
  connectWalletService,
  signMessageService,
} from '../web3/web3.services';
import { INetworkType } from '../web3/web3.interfaces';
import { getNetworknameForSetup } from '../web3/web3.utils';
import { getDeploymentProviders } from '../deployment/deployment.thunks';
import { toggleThemeThunk } from '../theme/theme.thunks';
import { ITheme } from '../theme/theme.interfaces';

export const getAPITokensThunk = createAsyncThunk(
  'user/getApiTokens',
  async (payload: void, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await getTokensService();
      if (!response.error) {
        return fulfillWithValue(response.apiKeys);
      }
      dispatch(
        addNotificationRtk({
          message: response.message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue([]);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error is getAPITokensGen -> ', error);
      Sentry.captureException(error);
      return rejectWithValue([]);
    }
  }
);

export const getUserNotificationsThunk = createAsyncThunk(
  'user/getNotifications',
  async (payload: void, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await getUserNotifications(
        0,
        config.notification.length
      );
      if (!(response as unknown as IResponseError).error) {
        return fulfillWithValue(response);
      }
      dispatch(
        addNotificationRtk({
          message: (response as unknown as IResponseError).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue([]);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error in getUserNotifications ->', error);
      Sentry.captureException(error);
      return rejectWithValue([]);
    }
  }
);

export const getReferralCodeThunk = createAsyncThunk(
  'user/getReferralCode',
  async (payload: void, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await getReferralCodeService();
      if (response.error) {
        dispatch(
          addNotificationRtk({
            message: response.message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      return fulfillWithValue(response.referralCode);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const setReferralCodeThunk = createAsyncThunk(
  'user/setReferralCode',
  async (payload: string, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await setReferralCodeService(payload);
      if (response.error) {
        dispatch(
          addNotificationRtk({
            message: response.message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      dispatch(getReferralCodeThunk());
      return fulfillWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getReferralDetailsThunk = createAsyncThunk(
  'user/getReferralDetails',
  async (payload: void, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await getReferralDetailsService();
      if (response.error) {
        dispatch(
          addNotificationRtk({
            message: response.message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      return fulfillWithValue(response.referrals);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getReferralCreditsThunk = createAsyncThunk(
  'user/getReferralCredits',
  async (payload: void, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await getReferralCreditsService();
      if (response.error) {
        dispatch(
          addNotificationRtk({
            message: response.message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      return fulfillWithValue(response);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getReferralWithdrawalsThunk = createAsyncThunk(
  'user/getReferralWithdrawals',
  async (payload: void, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await getReferralWithdrawalsService();
      if (response.error) {
        dispatch(
          addNotificationRtk({
            message: response.message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      return fulfillWithValue(response.withdrawals as IReferralWithrawal[]);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const requestReferralWithdrawalThunk = createAsyncThunk(
  'user/requestReferralWithdrawalThunk',
  async (
    payload: IRequestReferralWithdrawal,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const response = await requestReferralWithdrawalService(payload);
      if (response.error) {
        dispatch(
          addNotificationRtk({
            message: response.message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      dispatch(
        toggleModalShowRtk({
          modalShow: true,
          modalType: 'referralSuccess',
        })
      );
      dispatch(getReferralCreditsThunk());
      dispatch(getReferralWithdrawalsThunk());
      return fulfillWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getRaiseRequestLimitThunk = createAsyncThunk(
  'user/getRaiseRequestLimit',
  async (payload: void, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await getRaiseRequestLimitService();
      if (response.error) {
        dispatch(
          addNotificationRtk({
            message: response.message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      return fulfillWithValue(response);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getUserDetailsThunk = createAsyncThunk(
  'user/fetchUser',
  async (payload: string, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await fetchUser();
      dispatch(getDeploymentProviders());
      if (response.user) {
        const { user }: { user: IUser } = response;
        dispatch(getAPITokensThunk());
        dispatch(getUserNotificationsThunk());
        dispatch(setUserRtk(user));
        dispatch(
          setThemeRtk(
            mapStringToThemeEnum(user.preferences.theme.toLowerCase())
          )
        );
        dispatch(setReferralEnabledRtk(user.preferences.referralEnabled));
        if (user.preferences.referralEnabled) {
          dispatch(getReferralCodeThunk());
        }
        if (user.organizations.length !== 0) {
          dispatch(organisationsLoadedRtk(user.organizations));

          const webappOrganisations: any = [];
          const computeOrganisations: any = [];
          const storageOrganisations: any = [];
          user.organizations.forEach((org: any) => {
            webappOrganisations.push(org.site);
            computeOrganisations.push(org.compute);
            storageOrganisations.push(org.storage);
          });

          const selectedOrgId = localStorage.getItem('selected-org');
          if (!selectedOrgId && !sessionStorage.getItem('communityFlow')) {
            if (computeOrganisations.length !== 0) {
              localStorage.setItem('selected-org', computeOrganisations[0]._id);

              dispatch(
                getSelectedOrganisationThunk(computeOrganisations[0]._id)
              );
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (sessionStorage.getItem('communityFlow')) {
              dispatch(
                getSelectedOrganisationByNameThunk(
                  sessionStorage.getItem('communityOrgName') as string
                )
              );
            } else if (selectedOrgId) {
              dispatch(getSelectedOrganisationThunk(selectedOrgId as string));
            }
          }
        } else {
          dispatch(toggleThemeThunk(ITheme.DARK));
          window.open(`${window.location.origin}/onboarding`, '_self');
        }

        // try {
        //   const intercomRes = await fetchIntercomHash();
        //   const intercomSettings = {
        //     api_base: 'https://api-iam.intercom.io',
        //     app_id: config.intercom.APP_ID,
        //     name: user.platformProfile.name, // Full name
        //     email: user.platformProfile.email, // Email address
        //     user_hash: intercomRes.intercomHash,
        // eslint-disable-next-line max-len
        //     created_at: new Date(user.createdAt).getTime() / 1000, // Signup date as a Unix timestamp
        //   };
        //   bootIntercom(intercomSettings);
        // } catch (error) {
        //   console.log('Error is getUserDetails for intercom -> ', error);
        // }
      } else {
        // eslint-disable-next-line no-console
        console.log(
          'Error is getUserDetails -> ',
          (response as unknown as IResponseError).error
        );
        dispatch(logoutUserRtk());
        return rejectWithValue({});
      }
      return fulfillWithValue({});
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error is getUserDetails -> ', error);
      Sentry.captureException(error);
      dispatch(logoutUserRtk());
      return rejectWithValue({});
    }
  }
);

export const updateUserOrganisationThunk = createAsyncThunk(
  'user/fetchUser',
  async (payload: string, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await fetchUser();
      if (response.user) {
        const { user }: { user: IUser } = response;
        dispatch(setUserRtk(user));
        dispatch(
          setThemeRtk(
            mapStringToThemeEnum(user.preferences.theme.toLowerCase())
          )
        );
        dispatch(setReferralEnabledRtk(user.preferences.referralEnabled));
        if (user.organizations.length !== 0) {
          dispatch(organisationsLoadedRtk(user.organizations));

          const webappOrganisations: any = [];
          const computeOrganisations: any = [];
          const storageOrganisations: any = [];
          user.organizations.forEach((org: any) => {
            webappOrganisations.push(org.site);
            computeOrganisations.push(org.compute);
            storageOrganisations.push(org.storage);
          });

          const selectedOrgId = localStorage.getItem('selected-org');
          if (!selectedOrgId && !sessionStorage.getItem('communityFlow')) {
            if (computeOrganisations.length !== 0) {
              localStorage.setItem('selected-org', computeOrganisations[0]._id);

              dispatch(
                getSelectedOrganisationThunk(computeOrganisations[0]._id)
              );
            }
            /*  else if (window.location.pathname.split('/')[1] === 'user') {
              const selectedAppType = localStorage.getItem('app-type');
              if (selectedAppType === ICurrentApp.APP) {
                dispatch(
                  getSelectedOrganisationThunk(
                    webappOrganisations[0]?._id || ''
                  )
                );
              } else if (selectedAppType === ICurrentApp.COMPUTE) {
                dispatch(
                  getSelectedOrganisationThunk(
                    computeOrganisations[0]?._id || ''
                  )
                );
              } else if (selectedAppType === ICurrentApp.STORAGE) {
                dispatch(
                  getSelectedOrganisationThunk(
                    storageOrganisations[0]?._id || ''
                  )
                );
              }
            } */
          } else {
            // eslint-disable-next-line no-lonely-if
            if (sessionStorage.getItem('communityFlow')) {
              dispatch(
                getSelectedOrganisationByNameThunk(
                  sessionStorage.getItem('communityOrgName') as string
                )
              );
            } else if (selectedOrgId) {
              dispatch(getSelectedOrganisationThunk(selectedOrgId as string));
            }
          }
          if (!localStorage.getItem('selected-master-org')) {
            const defaultMasterOrg =
              user.organizations.find(
                (org) => org._id === user.defaultOrganization
              ) || user.organizations[0];
            localStorage.setItem('selected-master-org', defaultMasterOrg._id);
            dispatch(setSelectedMasterOrg(defaultMasterOrg!));
            if (
              (defaultMasterOrg!.preferedAppType as unknown as ICurrentApp) ===
              ICurrentApp.APP
            ) {
              localStorage.setItem('selected-org', defaultMasterOrg?.site._id);
              dispatch(setCurrentAppType(ICurrentApp.APP));
            } else {
              localStorage.setItem(
                'selected-org',
                defaultMasterOrg![defaultMasterOrg!.preferedAppType!]._id
              );
              dispatch(
                setCurrentAppType(
                  defaultMasterOrg!.preferedAppType as unknown as ICurrentApp
                )
              );
            }

            if (
              window.location.pathname === '/login' ||
              window.location.pathname === '/signup' ||
              window.location.pathname === '/post-login'
            )
              window.open(
                `${window.location.origin}/${
                  defaultMasterOrg!.preferedAppType
                }/dashboard`,
                '_self'
              );
          } else {
            const selectedMasterOrg = user.organizations.find(
              (org) => org._id === localStorage.getItem('selected-master-org')
            );
            dispatch(setSelectedMasterOrg(selectedMasterOrg as IOrganization));
            if (
              window.location.pathname === '/login' ||
              window.location.pathname === '/signup' ||
              window.location.pathname === '/post-login'
            )
              window.open(
                `${window.location.origin}/${
                  selectedMasterOrg!.preferedAppType
                }/dashboard`,
                '_self'
              );
          }
        } else {
          dispatch(toggleThemeThunk(ITheme.DARK));
          window.open(`${window.location.origin}/onboarding`, '_self');
        }

        // try {
        //   const intercomRes = await fetchIntercomHash();
        //   const intercomSettings = {
        //     api_base: 'https://api-iam.intercom.io',
        //     app_id: config.intercom.APP_ID,
        //     name: user.platformProfile.name, // Full name
        //     email: user.platformProfile.email, // Email address
        //     user_hash: intercomRes.intercomHash,
        // eslint-disable-next-line max-len
        //     created_at: new Date(user.createdAt).getTime() / 1000, // Signup date as a Unix timestamp
        //   };
        //   bootIntercom(intercomSettings);
        // } catch (error) {
        //   console.log('Error is getUserDetails for intercom -> ', error);
        // }
      } else {
        // eslint-disable-next-line no-console
        console.log(
          'Error is getUserDetails -> ',
          (response as unknown as IResponseError).error
        );
        return rejectWithValue({});
      }
      return fulfillWithValue({});
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error is getUserDetails -> ', error);
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const updateProfileThunk = createAsyncThunk(
  'user/updateProfile',
  async (
    payload: IPlatformProfile,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const response = await updateUserProfileData(payload);
      if (response.error) {
        dispatch(
          addNotificationRtk({
            message: response.message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      dispatch(
        addNotificationRtk({
          message: 'Profile updated successfully',
          timestamp: Date.now(),
          type: NotificationType.Success,
        })
      );
      return fulfillWithValue(payload as IPlatformProfile);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error is updateProfile -> ', error);
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const updateUserDefaultOrganisation = createAsyncThunk(
  'user/updateProfile',
  async (payload: string, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await updateUserDefaultOrganisationService(payload);
      if (response.error) {
        return rejectWithValue({});
      }
      return fulfillWithValue(payload);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error in updateUserDefaultOrganisation -> ', error);
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const deleteTokensThunk = createAsyncThunk(
  'user/deleteTokens',
  async (
    payload: string[],
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    try {
      const response = await deleteTokensService(payload);
      dispatch(toggleModalShowRtk({ modalShow: false }));
      if (!response.error) {
        return fulfillWithValue(payload);
      }
      dispatch(
        addNotificationRtk({
          message: response.message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error is getAPITokensGen -> ', error);
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const createTokensThunk = createAsyncThunk(
  'user/createTokens',
  async (
    payload: ICreateApiTokenPayloadDto,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const response = await createTokenService(payload);
      if (!(response as IResponseError).error) {
        dispatch(getAPITokensThunk());
        return fulfillWithValue(response);
      }
      dispatch(
        addNotificationRtk({
          message: (response as unknown as IResponseError).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      dispatch(toggleModalShowRtk({ modalShow: false }));
      return rejectWithValue({});
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error is createAPITokenGen -> ', error);
      Sentry.captureException(error);
      dispatch(toggleModalShowRtk({ modalShow: false }));
      return rejectWithValue({});
    }
  }
);

export const loadMoreUserNotificationsThunk = createAsyncThunk(
  'user/loadMoreNotifications',
  async (
    payload: void,
    { rejectWithValue, fulfillWithValue, dispatch, getState }
  ) => {
    const { user } = getState() as RootState;
    const limit = user.notificationPagination.numbersPerPage;
    const skip = (user.notificationPagination.currentPage + 1) * limit;
    try {
      const response = await getUserNotifications(skip, limit);
      if (!(response as unknown as IResponseError)?.error) {
        dispatch(
          addNotificationRtk({
            message: (response as unknown as IResponseError).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue([]);
      }
      return fulfillWithValue(response);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue([]);
    }
  }
);

export const updateUserNotificationsThunk = createAsyncThunk(
  'user/updateUserNotifications',
  async (payload: string, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await changeNotificationStatus(payload);
      if (response.error) {
        dispatch(
          addNotificationRtk({
            message: response.message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      return fulfillWithValue(payload);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error in update notification service ->', error);
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const removeProviderThunk = createAsyncThunk(
  'user/removeProvider',
  async (payload: string, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await removeProviderService({ provider: payload });
      dispatch(toggleModalShowRtk({ modalShow: false }));
      dispatch(disconnectLoginConnectionLoadingRtk(false));
      if (response.error) {
        dispatch(
          addNotificationRtk({
            message: response.message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      return fulfillWithValue(payload);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error in remove provider ->', error);
      dispatch(disconnectLoginConnectionLoadingRtk(false));
      toggleModalShowRtk({ modalShow: false });
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const readAllNotificationsThunk = createAsyncThunk(
  'user/readAllNotifications',
  async (payload: void, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await markAllNotificationsAsRead();
      if (response.error) {
        dispatch(
          addNotificationRtk({
            message: response.message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      return fulfillWithValue({});
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error in read all notifications ->', error);
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getBannersThunk = createAsyncThunk(
  'user/getBanners',
  async (payload: void, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await getBannerService();
      if (!response.success) {
        dispatch(
          addNotificationRtk({
            message: response.message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      return fulfillWithValue(response.banners);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error in get banners ->', error);
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const seenBannerThunk = createAsyncThunk(
  'user/seenBanner',
  async (payload: string, { fulfillWithValue, rejectWithValue }) => {
    try {
      await seenBannerService(payload);
      return fulfillWithValue(payload);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error is seen banner ->', error);
      Sentry.captureException(error);
      return rejectWithValue(payload);
    }
  }
);

export const verifySignatureWalletThunk = createAsyncThunk(
  'user/verifySignatureWalletThunk',
  async (
    payload: IRequestReferralWithdrawal,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const walletResponse = await connectWalletService();
      if (
        !walletResponse.error &&
        payload.walletAddress === walletResponse.account
      ) {
        // eslint-disable-next-line import/no-extraneous-dependencies
        const { Web3Provider } = await import('@ethersproject/providers');
        const provider = new Web3Provider((window as any).ethereum);
        const chainId = await provider
          .getNetwork()
          .then(({ chainId }: { chainId: any }) => chainId);
        const signResponse = await signMessageService(
          config.web3.WITHDRAWAL_VERIFYING_MESSAGE,
          { chainId: String(chainId) } as INetworkType
        );
        if (!signResponse.error) {
          dispatch(
            requestReferralWithdrawalThunk({
              ...payload,
              walletDetails: {
                address: walletResponse.account,
                chainId,
                signature: signResponse.signature || '',
                networkName: getNetworknameForSetup(chainId),
              },
            })
          );
          return fulfillWithValue({});
        }
        return rejectWithValue({});
      }
      dispatch(
        addNotificationRtk({
          message:
            walletResponse.errorMessage ||
            'Please connect the wallet entered in the wallet address field',
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const updateUserOnboardingDetailsThunk = createAsyncThunk(
  'user/updateUserOnboarding',
  async (payload: { additionalInfo: IAdditionalInfoPayloadDto }) => {
    try {
      await updateUserOnboardingDetailsService(payload);
      return;
    } catch (error) {
      Sentry.captureException(error);
    }
  }
);
