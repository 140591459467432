import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Dropdown, OptionType, TextInput } from '@spheron/ui-library';
// eslint-disable-next-line
import { CustomSelectOption } from '../../../pages/Compute/NewComputePricing/DropdownMenu/dropdown-menu';

interface IProps {
  valueFirst: string;
  valueSecond: string;
  typeFirst?: string;
  placeholderFirst?: string;
  disabledFirst?: boolean;
  loading?: boolean | undefined;
  dropdownOptions: { label: string; value: string; optionType: OptionType }[];
  // eslint-disable-next-line no-unused-vars
  onChangeFirst: (value: string) => void;
  // eslint-disable-next-line no-unused-vars
  onChangeSecond: (value: string) => void;
  disablePort: boolean;
  isChecked?: boolean;
  endpointPort?: string;
}

const MappingTextInput = ({
  valueFirst,
  valueSecond,
  placeholderFirst,
  typeFirst,
  loading,
  onChangeFirst,
  disabledFirst,
  dropdownOptions,
  onChangeSecond,
  disablePort,
  isChecked,
  endpointPort,
}: IProps) => {
  const [showInputError, setShowInputError] = useState<boolean>(true);
  const [showDropdownError, setShowDropdownError] = useState<boolean>(true);
  const handleDropdownChange = (selected: { name: string; value: string }) => {
    onChangeSecond(selected.value);
    if (selected.value === '') {
      setShowDropdownError(true);
    } else {
      setShowDropdownError(false);
    }
  };
  const handleInputChange = (val: string) => {
    onChangeSecond(val);
    if (val === '') {
      setShowInputError(true);
    } else {
      setShowInputError(false);
    }
  };
  return (
    <div className="flex flex-col ll:flex-row gap-6">
      {!loading ? (
        <>
          <div className="w-6/12 ll:w-[450px]">
            <TextInput
              inputSize="compact"
              type={typeFirst}
              value={valueFirst}
              onChange={(e) => onChangeFirst(e as string)}
              placeholder={placeholderFirst}
              disabled={disablePort ? true : disabledFirst}
              error={valueFirst === ''}
              helpText={valueFirst === '' ? 'This is a required field' : ''}
            />
          </div>
          <div className="w-6/12 ll:w-[450px]">
            {disablePort ? (
              <TextInput
                inputSize="compact"
                value={`${
                  valueSecond !== '80'
                    ? `${
                        typeof endpointPort !== 'undefined' && isChecked
                          ? endpointPort
                          : 'Random Port'
                      }`
                    : '80'
                }`}
                onChange={(e) => onChangeFirst(e as string)}
                placeholder=""
                disabled
                error={false}
                helpText=""
              />
            ) : (
              <>
                {isChecked ? (
                  <TextInput
                    inputSize="compact"
                    value={valueSecond}
                    onChange={(e) => handleInputChange(e as string)}
                    placeholder="Add Exposed Port"
                    disabled={false}
                    error={showInputError}
                    type="number"
                    helpText={`${
                      showInputError ? 'This is a required field' : ''
                    }`}
                  />
                ) : (
                  <div className="-mt-2">
                    <Dropdown
                      dropdownSize="compact"
                      dropdownSectionClassName="min-w-[94px]"
                      dropdownType="default"
                      label=""
                      placeholder={
                        valueSecond
                          ? dropdownOptions.find(
                              (option) => option.value === valueSecond
                            )?.label || dropdownOptions[0].label
                          : dropdownOptions[0].label
                      }
                      filled
                      onSelected={(selected: any) =>
                        handleDropdownChange(selected)
                      }
                      subText=""
                      options={dropdownOptions}
                      error={showDropdownError}
                      errorMessage={`${
                        showDropdownError ? 'This is a required field' : ''
                      }`}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </>
      ) : (
        <Skeleton height={30} containerClassName="flex" />
      )}
    </div>
  );
};

MappingTextInput.defaultProps = {
  placeholderFirst: '',
  typeFirst: 'text',
  loading: null,
  disabledFirst: false,
  isChecked: false,
  endpointPort: undefined,
};

export default MappingTextInput;
