import React, { useMemo, useState } from 'react';
import { Button, SectionHeading, Tabs } from '@spheron/ui-library';
import { ReactComponent as AddIcon } from '@spheron/ui-library/dist/assets/spheron-specific/add.svg';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/compute/store';
import { toggleModalShowRtk } from '../../../../redux/compute/modal/modal.slice';
import { getSpheronCreditsThunk } from '../../../../redux/compute/organisation/organisation.thunks';
import SpheronCreditsTableItem from '../../../../components/Compute/TableItems/spheron-credits-table-item';
import { SpheronCreditType } from '../../../../redux/compute/organisation/organisation.interfaces';
import ActiveSpheronCreditCard from '../../../../components/Compute/Cards/active-spheron-credit-card';

const SpheronCredit = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { selectedOrganisationLoading, selectedOrganisation } = useSelector(
    (state: RootState) => state.organisation
  );

  const spheronCredits = useSelector(
    (state: RootState) => state.organisation.spheronCredits
  );

  const spheronCreditsLoading = useSelector(
    (state: RootState) => state.organisation.spheronCreditsLoading
  );

  useMemo(() => {
    if (selectedOrganisation?._id)
      dispatch(getSpheronCreditsThunk(selectedOrganisation?._id));
  }, [dispatch, selectedOrganisation?._id]);

  const tabRoutes = [
    {
      id: 0,
      label: 'Active',
      item: <SpheronCreditsTableItem type={SpheronCreditType.ACTIVE} />,
    },
    {
      id: 1,
      label: 'Pending',
      item: <SpheronCreditsTableItem type={SpheronCreditType.PENDING} />,
    },
    {
      id: 2,
      label: 'Expired',
      item: <SpheronCreditsTableItem type={SpheronCreditType.EXPIRED} />,
    },
  ];

  const [selectedTab, setSelectedTab] = useState<number>(0);
  return (
    <div className="flex flex-col gap-6">
      <div
        className="flex w-full items-start gap-4
       justify-between flex-col sm:flex-row sm:items-center border-1"
      >
        <SectionHeading
          showRefresh={false}
          showSwitch={false}
          subHeading={''}
          heading="Coupon Credit"
          handleIconClick={() => {}}
          handleRefreshClick={() => {}}
          handleSwitchClick={() => {}}
          switchDisable={false}
          handleClick={() => {}}
          loading={false}
          time={''}
          refreshType={'default'}
          showText={false}
          toggleId={''}
          isChecked={false}
          handleChange={() => {}}
        />
        <Button
          buttonType="primary"
          label="Add Credit"
          size="medium"
          onClick={() => {
            if (!(spheronCreditsLoading || selectedOrganisationLoading)) {
              dispatch(
                toggleModalShowRtk({
                  modalShow: true,
                  modalType: 'addCredit',
                })
              );
            }
          }}
          leftIcon={<AddIcon className="w-5 h-5 text-white" />}
          disabled={spheronCreditsLoading || selectedOrganisationLoading}
        />
      </div>
      <>
        {spheronCredits
          ?.filter((credit) => credit.status === SpheronCreditType.ACTIVE)
          .map((activeCredit) => (
            <ActiveSpheronCreditCard credit={activeCredit} />
          ))}
      </>
      <Tabs
        tabList={tabRoutes}
        tabListPosition="left"
        defaultTab={0}
        selectedTab={selectedTab}
        tabListClassname="pb-4"
        onClick={(id: number) => setSelectedTab(id)}
      />
      {tabRoutes.map((tab) => (
        <div className={selectedTab === tab.id ? 'block' : 'hidden'}>
          {tab.item}
        </div>
      ))}
    </div>
  );
};

export default SpheronCredit;
