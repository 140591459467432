import React, { useEffect, useState } from 'react';
import { Modal } from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import Styles from '../../../styles/compute/modal.module.scss';
import { bonusParse } from '../../../redux/compute/subscription/subscription.utils';
import {
  getCurrentAppState,
  mapCurrentAppToSpecialization,
} from '../../../redux/compute/organisation/organisation.utils';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import SelectPaymentMethod from '../../Cards/select-payment-methods';
import { usePaymentMethod } from '../../../hooks/Compute/useSelectedPayment';
import { PaymentMethod } from '../../../redux/combined-state.interface';
import { IOrganisationWallet } from '../../../redux/compute/web3/web3.interfaces';
import { createPayOverdueReservationThunk } from '../../../redux/solana/solana.thunks';
import { getTokenLivePrice } from '../../../redux/compute/subscription/subscription.services';
import { ReservationType } from '../../../redux/solana/solana.utils';
import { setTempPaymentMethodIdRtk } from '../../../redux/compute/organisation/organisation.slice';
import SelectPaymentMethodHeading from '../../Headings/select-payment-method-heading';
import ModalWrapper from './ModalWrapper';
import { organisationOverduePaymentThunk } from '../../../redux/organisation/organisation.thunks';

interface IProps {
  isOpen: boolean;
  payDisable: boolean;
}

function PayOrganisationOverdue({ isOpen, payDisable }: IProps) {
  const dispatchRtk = useDispatch<AppDispatch>();

  const overduePaymentLoading = useSelector(
    (state: RootState) => state.organisation.overduePaymentLoading
  );
  const selectedOrganisation = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation
  );
  const overdues: any[] = [];
  let overdueEnvironments: number = 0;
  const overdueResponseStore = useSelector(
    (state: RootState) =>
      state.organisation.selectedOrganisation.overdue.overdueResponse
  );

  const tempPaymentMethodId = useSelector(
    (state: RootState) => state.organisation.tempPaymentMethodId
  );

  const { tempPaymentMethod } = usePaymentMethod(tempPaymentMethodId);

  const selectedOrgWalletConfig = useSelector(
    (state: RootState) => state.organisation.selectedOrgWalletConfig
  );

  const primaryPaymentMethodId = useSelector(
    (state: RootState) => state.organisation.primaryPaymentMethodId
  );

  const [liveTokenPrice, setLiveTokenPrice] = useState(1);

  const parseOverdueResponse = (overdueResponse: any[]) => {
    let overdueEnvs = 0;
    Object.keys(overdueResponse).forEach((key: any) => {
      if (
        key.substr(0, 8) === 'exceeded' &&
        key !== 'exceededEnvironmentsFpayOrganisationOverdueStartorProjects' &&
        overdueResponse[key] !== 0
      ) {
        overdues.push({ [key.substring(8, key.length)]: overdueResponse[key] });
      }
      if (key === 'exceededEnvironmentsForProjects') {
        overdueResponse[key].forEach((project: any) => {
          overdueEnvs += project.amount;
        });
      }
    });
    overdueEnvironments = overdueEnvs;
  };
  parseOverdueResponse(overdueResponseStore);

  useEffect(() => {
    if (tempPaymentMethod?.paymentMethod === PaymentMethod.CRYPTO) {
      getTokenLivePrice(
        (tempPaymentMethod as IOrganisationWallet).details.tokenDetails
          .symbol ||
          selectedOrgWalletConfig.tokenDetails?.symbol ||
          'USDT'
      ).then((res) => {
        setLiveTokenPrice(Number(res.price));
      });
    }
  }, [selectedOrgWalletConfig.tokenDetails?.symbol, tempPaymentMethod]);

  useEffect(() => {
    return () => {
      dispatchRtk(setTempPaymentMethodIdRtk(null));
    };
  });

  const handleClick = () => {
    if (
      (selectedOrgWalletConfig.networkDetails?.chainName === 'solana' &&
        tempPaymentMethod === null) ||
      (tempPaymentMethod?.paymentMethod === PaymentMethod.CRYPTO &&
        (tempPaymentMethod as IOrganisationWallet).details.networkDetails
          .chainName === 'solana')
    ) {
      dispatchRtk(
        createPayOverdueReservationThunk({
          organizationId: selectedOrganisation._id,
          specialization: mapCurrentAppToSpecialization(
            getCurrentAppState(selectedOrganisation.appType)
          ),
          liveTokenPrice,
          walletAddress:
            (tempPaymentMethod as IOrganisationWallet).details?.address || '',
        })
      );
      dispatchRtk(
        toggleModalShowRtk({
          modalShow: true,
          modalType: 'solanaPay',
          options: {
            walletAddress:
              (tempPaymentMethod as IOrganisationWallet).details?.address || '',
            // passing live token price for type PayOverdue
            amount: liveTokenPrice,
            type: ReservationType.PayOverdue,
          },
        })
      );
    } else {
      dispatchRtk(
        organisationOverduePaymentThunk({
          walletId: tempPaymentMethodId || primaryPaymentMethodId || '',
          organisationId: selectedOrganisation?._id,
          specialization: mapCurrentAppToSpecialization(
            getCurrentAppState(selectedOrganisation.appType)
          ),
        })
      );
    }
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={() => {
        dispatchRtk(toggleModalShowRtk({ modalShow: false }));
      }}
      classname={`${Styles.modal__outer__con} modal fixed inset-0 overflow-y-auto`}
    >
      <div className="w-500">
        <Modal
          header
          headerContent={<>Pay overdue amount</>}
          footer={!!tempPaymentMethod || !!primaryPaymentMethodId}
          modalContent={
            <div className="flex flex-col gap-y-3">
              <p className="text-sm font-normal leading-5 text-left">
                You will be paying for the following:
              </p>
              {overdues.map((overdue: any) => {
                const { rate, params } = bonusParse(
                  Object.keys(overdue)[0],
                  Number(Object.values(overdue)[0])
                );
                return (
                  <div
                    key={params}
                    className="mt-3 text-sm dark:text-gray-200
                    text-base-heading-text-color dark:text-dark-base-heading-text-color
                      font-semibold flex justify-between"
                  >
                    <div>{params}</div>
                    <div>{rate}</div>
                  </div>
                );
              })}
              {overdueEnvironments !== 0 && (
                <div
                  className="mt-3 dark:text-gray-200 
                 text-sm font-semibold flex justify-between"
                >
                  <div>Environments</div>
                  <div>{overdueEnvironments}</div>
                </div>
              )}
              {(tempPaymentMethod || primaryPaymentMethodId) && (
                <SelectPaymentMethod
                  heading={
                    <SelectPaymentMethodHeading headingClassName="text-lg font-bold" />
                  }
                  tempPaymentMethod={tempPaymentMethod}
                />
              )}
            </div>
          }
          onPrimaryButtonClick={handleClick}
          primaryButtonDisabled={payDisable}
          primaryButton
          secondaryButton
          onSecondaryButtonClick={() =>
            dispatchRtk(toggleModalShowRtk({ modalShow: false }))
          }
          primaryButtonContent="Pay"
          primaryButtonLoading={overduePaymentLoading}
          secondaryButtonContent="Cancel"
          subtitle={''}
        />
      </div>
    </ModalWrapper>
  );
}

export default PayOrganisationOverdue;
