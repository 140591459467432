// eslint-disable-next-line import/no-cycle
import { ConnectionState, ICreditCardInfo } from './stripe/stripe.interfaces';
import { ITheme } from './theme/theme.interfaces';

export interface IUser {
  _id: string;
  platformProfile: IPlatformProfile;
  provider: IProvider;
  createdAt: Date;
  updatedAt: Date;
  organizations: IOrganization[];
  providerProfiles: IProviderProfile[];
  preferences: { theme: ITheme; referralEnabled: boolean };
  defaultOrganization: string;
  referral: {
    code: string;
    type: string;
  };
  siteActive?: boolean;
  storageActive?: boolean;
}

export interface IProviderProfile {
  id: number;
  argoUsername: string;
  providerUsername: string;
  avatarUrl: string;
  name: string;
  email: string;
  url: string;
  // eslint-disable-next-line camelcase
  html_url: string;
  followersUrl: string;
  followingUrl: string;
  gistsUrl: string;
  starredUrl: string;
  subscriptionsUrl: string;
  organizationsUrl: string;
  reposUrl: string;
  eventsUrl: string;
  receivedEventsUrl: string;
  publicRepos: number;
  publicGists: number;
  followers: number;
  following: number;
  isActive: boolean;
  providerName: string;
  username: string;
  createdAt: Date;
}

export interface IPlatformProfile {
  username: string;
  avatar: string;
  isActive?: boolean;
  name: string;
  email: string;
  role?: string;
  roleId?: string;
}

export interface IProvider {
  name: string;
}

export interface IOrganization {
  _id: string;
  profile: {
    name: string;
    image: string;
    username: string;
  };
  projects: IProject[];
  users: IUser[];
  wallet: IWallet | null;
  payments: any[];
  registries: string[];
  invitedMembers: IMemberInvite[];
  overdue: IOverdue;
  appType: string;
  publiclyAccessible: boolean;
  preferedAppType?: PreferredAppType;
  site?: any;
  compute?: any;
  storage?: any;
}
export interface IMasterOrganization {
  _id: string;
  profile: {
    name: string;
    image: string;
    username: string;
  };
  publiclyAccessible: boolean;
  preferedAppType?: PreferredAppType;
  site: IOrganization;
  compute: IOrganization;
  storage: IOrganization;
  createdAt: Date;
  updatedAt: Date;
}

export interface IWallet {
  _id: string;
  address: string;
  organizationId: string;
  networkDetails: {
    name: string;
    chainId: number;
  };
  tokenDetails: {
    name: string;
    address: string;
    decimals: number;
  };
  updatedAt: Date;
  createdAt: Date;
  connectionSecret?: string;
}

export interface IMemberInvite {
  userEmail: string;
  status: string;
  link: string;
  _id: string;
  organization: IOrganization;
  createdAt: Date;
  updatedAt: Date;
}

export interface IOverdue {
  overdue: boolean;
  overdueReasons: string[];
  overdueResponse: any;
  message: string;
}
export interface IInstanceReport {
  active: number;
  starting: number;
  failedToStart: number;
  closed: number;
  total: number;
  lastTimeInstanceActivated: string;
  lastTimeInstanceClosed: string;
  lastTimeInstanceFailedToStart: string;
  lastTimeInstanceStarted: string;
}
export interface IProject {
  _id?: string;
  name: string;
  url: string;
  provider: string;
  latestDeployment: IDeployment;
  deployments: any;
  organization: string;
  domains: IDomain[];
  ensDomains: IDomain[];
  environmentVariables: IEnvironmentVariable[];
  updatedAt: Date;
  createdAt: Date;
  state: string;
  deploymentEnvironments: IDeploymentEnvironment[];
  configuration: IConfiguration;
  prCommentIds: IPRCommentIds[];
  passwordProtection: IPasswordProtection;
  instanceReport: IInstanceReport;
}

export interface IDomain {
  _id?: string;
  name: string;
  link: string;
  isLatest: boolean;
  verified: boolean;
  projectId: string;
  type: string;
  deploymentEnvironmentIds: IDeploymentEnvironment[];
  version: string;
}

export interface IEnvironmentVariable {
  name: string;
  value: string;
  _id: string;
  deploymentEnvironments: IDeploymentEnvironment[];
}

export interface IDeploymentEnvironment {
  branches: string[];
  createdAt: string;
  name: string;
  protocol: string;
  status: string;
  updatedAt: string;
  _id: string;
}

export interface IPRCommentIds {
  prId: string;
  commentId: string;
}

export interface IDeployment {
  _id?: string;
  sitePreview: string;
  commitId: string;
  commitMessage: string;
  log: string[];
  contentHash: string;
  topic: string;
  status: string;
  buildTime: number;
  configuration: IConfiguration;
  project: IProject;
  createdAt: Date;
  updatedAt: Date;
  screenshot: IScreenshot | undefined;
  protocol: string;
  branch: string;
  deploymentInitiator: IUser;
  externalRepositoryName: string;
  pullRequestId: string;
  deploymentEnvironmentName: string;
  cancellingDeployment?: boolean;
}

export interface IScreenshot {
  id: string;
  fee: string;
  url: string;
}

export enum NodeVersion {
  // eslint-disable-next-line no-unused-vars
  V_12 = 'V_12',
  // eslint-disable-next-line no-unused-vars
  V_14 = 'V_14',
  // eslint-disable-next-line no-unused-vars
  V_16 = 'V_16',
  // eslint-disable-next-line no-unused-vars
  V_18 = 'V_18',
}

export interface IConfiguration {
  installCommand: string;
  buildCommand: string;
  workspace: string;
  publishDir: string;
  framework: string;
  _id: string;
  nodeVersion: NodeVersion;
}

export interface IModalFooter {
  id: number;
  label: string;
  icon: JSX.Element;
  onClick: () => void;
}

export enum ISpecialization {
  // eslint-disable-next-line no-unused-vars
  waGlobal = 'wa-global',
  // eslint-disable-next-line no-unused-vars
  cAkash = 'c-akash',
  // eslint-disable-next-line no-unused-vars
  storage = 'storage',
}

export enum ClusterInstanceDomain {
  // eslint-disable-next-line no-unused-vars
  domain = 'domain',
  // eslint-disable-next-line no-unused-vars
  subdomain = 'subdomain',
}

export interface ICdnRecords {
  recordIpv4V2: string;
  recordCnameV2: string;
}

export interface IPasswordProtection {
  credentials: string[];
  enabled: boolean;
}

export enum PaymentMethod {
  // eslint-disable-next-line no-unused-vars
  CRYTPO_INTEGRATION = 'crypto_integration',
  // eslint-disable-next-line no-unused-vars
  SPHERONWALLET = 'spheron_wallet',
  // eslint-disable-next-line no-unused-vars
  CRYPTO = 'crypto',
  // eslint-disable-next-line no-unused-vars
  CREDITCARD = 'credit_card',
  // eslint-disable-next-line no-unused-vars
  COUPON = 'coupon',
}

export enum PreferredAppType {
  // eslint-disable-next-line no-unused-vars
  SITE = 'site',
  // eslint-disable-next-line no-unused-vars
  COMPUTE = 'compute',
  // eslint-disable-next-line no-unused-vars
  STORAGE = 'storage',
}

export interface ICreditCard {
  details: {
    cardService: string;
    cardCustomerId: string;
    connectedId: string;
    cardPaymentMethodType: string;
    info?: ICreditCardInfo;
  };
  connectionState: ConnectionState;
  primary: boolean;
  _id: string;
  organizationId: string;
  paymentMethod: PaymentMethod;
  updatedAt: Date;
  createdAt: Date;
}

export interface IAddCreditCardResponsePayloadDto extends ICreditCard {
  connectionSecret: string;
}

export enum ComputeDashboard {
  // eslint-disable-next-line no-unused-vars
  PROJECT = 'project',
  // eslint-disable-next-line no-unused-vars
  INSTANCE = 'instance',
}
export interface IDiscount {
  global: boolean;
  resourceId: string;
  _id: string;
  name: string;
  type: string;
  activationDate: string;
  expirationDate: string;
  discountPercent: number;
  organizationId: string;
  createdAt: string;
  updatedAt: string;
}

export enum ProviderType {
  // eslint-disable-next-line no-unused-vars
  GOOGLE = 'google',
  // eslint-disable-next-line no-unused-vars
  GITHUB = 'github',
  // eslint-disable-next-line no-unused-vars
  GITLAB = 'gitlab',
  // eslint-disable-next-line no-unused-vars
  BITBUCKET = 'bitbucket',
  // eslint-disable-next-line no-unused-vars
  METAMASK = 'metamask',
  // eslint-disable-next-line no-unused-vars
  PHANTOM = 'phantom',
  // eslint-disable-next-line no-unused-vars
  LENS = 'lens',
}

export enum ProviderName {
  // eslint-disable-next-line no-unused-vars
  WEB3 = 'web3',
  // eslint-disable-next-line no-unused-vars
  GITHUB = 'github',
  // eslint-disable-next-line no-unused-vars
  GITLAB = 'gitlab',
  // eslint-disable-next-line no-unused-vars
  BITBUCKET = 'bitbucket',
  // eslint-disable-next-line no-unused-vars
  GOOGLE = 'google',
}
