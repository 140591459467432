import React from 'react';
import dayjs from 'dayjs';
import { Badge, Table } from '@spheron/ui-library';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/compute/store';
import TableLoader from '../Loaders/table-loader';

const ReferralStatsItem = () => {
  const referrals = useSelector((state: RootState) => state.user.referrals);
  const referralLoading = useSelector(
    (state: RootState) => state.user.referralLoading
  );

  return (
    <>
      {referralLoading ? (
        <TableLoader
          headers={['REFERRED USER ID', 'AMOUNT', 'STATUS', 'DATE']}
        />
      ) : (
        <>
          {referrals.length > 0 && (
            <Table
              tableHeader={{
                tableRowType: 'record',
                tableRow: [
                  {
                    id: 1,
                    title: 'REFERRED USER ID',
                    tableCellType: 'header',
                  },
                  {
                    id: 2,
                    title: 'AMOUNT',
                    tableCellType: 'header',
                  },
                  {
                    id: 3,
                    title: 'STATUS',
                    tableCellType: 'header',
                  },
                  {
                    id: 4,
                    title: 'DATE',
                    tableCellType: 'header',
                  },
                ],
              }}
              tableRows={referrals.map((referral, i: number) => ({
                id: i,
                tableRowType: 'record',
                tableRow: [
                  {
                    id: 1,
                    title: referral.userId,
                    tableCellType: 'title',
                  },
                  {
                    id: 2,
                    title: `${referral.amount || 0}`,
                    tableCellType: 'title',
                  },
                  {
                    id: 3,
                    content: (
                      <Badge
                        badgeType={referral.paymentDone ? 'success' : 'default'}
                        text={referral.paymentDone ? 'Success' : 'Pending'}
                        size="large"
                        solid={false}
                      />
                    ),
                    tableCellType: 'default',
                  },
                  {
                    id: 4,
                    title: dayjs(referral.createdAt).format(
                      'MMMM DD, YYYY h:mm A'
                    ),
                    tableCellType: 'title',
                  },
                ],
              }))}
            />
          )}
        </>
      )}
    </>
  );
};

export default ReferralStatsItem;
