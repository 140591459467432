import React from 'react';
import Skeleton from 'react-loading-skeleton';

const DashboardCardsLoading = () => {
  return (
    <>
      <div
        className=" border w-[320px] dark:border-dark-cards-border
        border-cards-border dark:bg-dark-cards-bg bg-cards-bg rounded-lg p-5 h-min"
      >
        <div className="flex flex-col justify-center">
          <Skeleton height={148} width={280} duration={2} />

          <span className="text-sm text-base-para-text dark:text-dark-base-para-text font-semibold mt-4">
            <Skeleton height={40} width={280} duration={2} />
          </span>
          <span className="text-sm text-base-para-text dark:text-dark-base-para-text font-normal mt-3 mb-2">
            <Skeleton height={20} width={280} duration={2} />
          </span>
          <span className="text-sm text-base-para-text dark:text-dark-base-para-text font-normal mb-5">
            <Skeleton height={20} width={280} duration={2} />
          </span>
          <span className="uppercase text-sub-text-color dark:text-dark-sub-text-color flex justify-center">
            <Skeleton height={44} width={280} duration={2} />
          </span>
        </div>
      </div>
    </>
  );
};

export default DashboardCardsLoading;
