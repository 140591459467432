import {
  Button,
  Dropdown,
  EmptyState,
  InfiniteScroll,
  InstanceCard,
  InstanceCardState,
  InstanceCardType,
  InstanceDeploymentType,
  OptionType,
} from '@spheron/ui-library';
import React, { Fragment, useEffect, useMemo, useRef } from 'react';
// import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as PlayIcon } from '@spheron/ui-library/dist/assets/spheron-specific/play.svg';
import { ReactComponent as MoreIcon } from '@spheron/ui-library/dist/assets/more.svg';
import { ReactComponent as NoSearchFound } from '../../../../assets/compute/icons/no-search-found.svg';
import ComputeDashboardLoading from '../../../../components/Compute/Loaders/compute-dashboard-loading';
import LoadMore from '../../../../components/Compute/LoadMore/load-more';
import { withLoader } from '../../../../redux/compute/root-utils';
import { IInstance } from '../../../../redux/compute/project/project.interfaces';
import { AppDispatch, RootState } from '../../../../redux/compute/store';
import { toggleModalShowRtk } from '../../../../redux/compute/modal/modal.slice';
import {
  getInstanceScalingType,
  getInstanceState,
} from '../../../../redux/compute/project/project.utils';
import { IUser } from '../../../../redux/compute/combined-state.interface';
import InstanceCardLoading from '../../../../components/Compute/Loaders/instance-card-loading';
import { canDeleteInstance } from '../../../../redux/compute/instance/instance.utils';
import {
  closeMultipleInstancesThunk,
  deleteInstanceThunk,
  removeMultipleInstancesThunk,
} from '../../../../redux/compute/instance/instance.thunks';
import {
  setSelectedInstanceRtk,
  toggleShouldFetchDeploymentDetails,
  toggleShouldFetchInstanceDetails,
} from '../../../../redux/compute/instance/instance.slice';
import {
  getComputeProjectInstancesThunk,
  loadMoreComputeProjectInstancesThunk,
} from '../../../../redux/compute/project/project.thunks';
import { VIDEO_TUTORIAL } from '../../../../config';
import {
  setSelectedComputeProjectRtk,
  toggleSelectedComputeProjectLoading,
  toggleShouldFetchComputeProjectInstances,
} from '../../../../redux/compute/project/project.slice';

interface IProps {
  searchValue: string;
  // eslint-disable-next-line no-unused-vars
  setSelectedInstances: (instances: any) => void;
  selectedInstances: string[];
  dropdownOption: string;
  handleNewInstanceClick: () => void;
}

const InstanceDashboard = ({
  searchValue,
  setSelectedInstances,
  selectedInstances,
  dropdownOption,
  handleNewInstanceClick,
}: IProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { projectId, orgUsername } = useParams<{
    projectId: string;
    orgUsername: string;
  }>();
  // const uniqueTopicId = uuidv4();
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const currentUser = useSelector((state: RootState) => state.user.user);
  const projectsLoading = useSelector(
    (state: RootState) => state.computeProject.projectsLoading
  );
  const selectedComputeProject = useSelector(
    (state: RootState) => state.computeProject.selectedProject
  );
  const selectedOrganisation = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation
  );
  const selectedOrganisationLoading = useSelector(
    (state: RootState) => state.organisation.selectedOrganisationLoading
  );
  const instancesLoading = useSelector(
    (state: RootState) => state.computeProject.selectedProjectInstancesLoading
  );
  const projects = useSelector(
    (state: RootState) => state.computeProject.projects
  );
  const instances = useSelector(
    (state: RootState) => state.computeProject.selectedProjectInstances
  );
  const shouldFetchComputeProjectInstances = useSelector(
    (state: RootState) =>
      state.computeProject.shouldFetchComputeProjectInstances
  );
  const loadMoreInstancesLoading = useSelector(
    (state: RootState) =>
      state.computeProject.loadMoreSelectedProjectInstancesLoading
  );

  const allInstanceCount = useSelector(
    (state: RootState) => state.computeProject.selectedProjectInstanceCount
  );

  const allInstanceCountLoading = useSelector(
    (state: RootState) =>
      state.computeProject.selectedProjectInstanceCountLoading
  );

  const editNameLoading = useSelector(
    (state: RootState) => state.instance.editNameLoading
  );

  const instanceDropdownOptions = (
    name: string,
    id: string,
    state: InstanceCardState
  ) => {
    return [
      ...(canDeleteInstance(
        currentUser as IUser,
        selectedOrganisation.users,
        state,
        instancesLoading || selectedOrganisationLoading
      )
        ? [
            {
              label: 'Delete Instance',
              handleClick: () => {
                dispatch(
                  toggleModalShowRtk({
                    modalShow: true,
                    modalType: 'deleteResource',
                    options: {
                      handleClick: () => {
                        dispatch(deleteInstanceThunk(id));
                      },
                      resourceType: 'Instance',
                      resource: name,
                    },
                  })
                );
              },
              optionType: 'primary' as OptionType,
            },
          ]
        : []),
      ...(String(state) === 'Active'
        ? [
            {
              label: 'Close Instance',
              handleClick: () => {
                dispatch(
                  toggleModalShowRtk({
                    modalShow: true,
                    modalType: 'closeInstance',
                    options: {
                      instanceId: id,
                    },
                  })
                );
              },
              optionType: 'primary' as OptionType,
            },
          ]
        : []),
      ...(String(state) !== 'queued' && String(state) !== 'pending'
        ? [
            // {
            //   label: 'Restart Instance',
            //   handleClick: () => {
            //     dispatch(
            //       toggleModalShowRtk({
            //         modalShow: true,
            //         modalType: 'restartInstance',
            //         options: {
            //           instanceConfig: {
            //             instanceId: id,
            //             organizationId: selectedOrganisation?._id,
            //             uniqueTopicId,
            //           },
            //         },
            //       })
            //     );
            //   },
            //   optionType: 'primary' as OptionType,
            // },
          ]
        : []),
      {
        label: 'Rename Instance',
        handleClick: () => {
          dispatch(
            toggleModalShowRtk({
              modalShow: true,
              modalType: 'editName',
              options: {
                resource: 'Instance',
                id: id || '',
                loading: editNameLoading,
              },
            })
          );
        },
        optionType: 'primary' as OptionType,
      },
    ];
  };

  const handleInstanceCardClick = (id: string) => {
    const selectedInstance = instances.find((instance) => instance._id === id)!;
    const selectedProject = projects.find(
      (project: { _id: string }) => project._id === projectId
    )!;
    dispatch(
      setSelectedInstanceRtk({
        ...selectedInstance,
        computeProject: selectedProject,
      })
    );
    navigate(`/${orgUsername}/${projectId}/${id}/overview`);
  };

  const handleLoreMore = () => {
    dispatch(
      loadMoreComputeProjectInstancesThunk({
        computeProjectId: projectId || '',
        state: '',
      })
    ) as any;
  };

  const includesProvisionedInstances = useMemo(() => {
    let response = false;
    selectedInstances.forEach((instance) => {
      if (
        [...instances]
          .find((resource) => resource._id === instance)
          ?.state.toLowerCase() === 'active'
      ) {
        response = true;
      }
    });
    return response;
  }, [instances, selectedInstances]);

  const spheronVideoResource = [...JSON.parse(VIDEO_TUTORIAL || '')].map(
    (tutorial) => {
      return {
        ...tutorial,
        onClick: () =>
          dispatch(
            toggleModalShowRtk({
              modalShow: true,
              modalType: 'videoPopup',
              options: {
                iframe: (
                  <iframe
                    width="711"
                    height="400"
                    className="rounded-lg"
                    src={tutorial?.iframe}
                    title="YouTube video player"
                    // eslint-disable-next-line max-len
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  />
                ),
              },
            })
          ),
      };
    }
  );

  const emptyStateTitle = useMemo(() => {
    if (searchValue) {
      return 'Oops! No matching Instances';
    }
    if (dropdownOption !== 'all') {
      return `You don't have any instances which are in ${dropdownOption} state `;
    }
    return `You don't have any Instances yet!`;
  }, [searchValue, dropdownOption]);

  useEffect(() => {
    dispatch(toggleShouldFetchInstanceDetails(true));
    dispatch(toggleShouldFetchDeploymentDetails(true));
  }, [dispatch, projectId]);

  useEffect(() => {
    if (projectId && !selectedComputeProject && !projectsLoading) {
      dispatch(
        setSelectedComputeProjectRtk(
          projects.find(
            (project: { _id: string }) => project._id === projectId
          )!
        )
      );
      dispatch(toggleSelectedComputeProjectLoading(false));
    }
  }, [projectId, selectedComputeProject, dispatch, projectsLoading, projects]);

  useEffect(() => {
    if (
      projectId &&
      selectedComputeProject === null &&
      !selectedOrganisationLoading
    ) {
      dispatch(
        getComputeProjectInstancesThunk({
          computeProjectId: projectId,
          topupReport: 'n',
        })
      );
    }
  }, [
    dispatch,
    projectId,
    selectedComputeProject,
    selectedOrganisationLoading,
  ]);

  useEffect(() => {
    if (projectId && shouldFetchComputeProjectInstances) {
      dispatch(
        getComputeProjectInstancesThunk({
          computeProjectId: projectId,
          topupReport: 'n',
        })
      ).then(() => {
        dispatch(toggleShouldFetchComputeProjectInstances(false));
      });
    }
  }, [projectId, dispatch]);

  const instanceToShow = useMemo(() => {
    if (searchValue && dropdownOption === 'all') {
      return instances?.filter((resource: { name: string }) =>
        resource?.name?.toLowerCase()?.includes(searchValue?.toLowerCase())
      );
    }
    if (searchValue && dropdownOption !== 'all') {
      return instances
        ?.filter((resource: { name: string }) =>
          resource?.name?.toLowerCase()?.includes(searchValue?.toLowerCase())
        )
        .filter(
          (instance) => String(instance.state.toLowerCase()) === dropdownOption
        );
    }
    if (searchValue === '' && dropdownOption !== 'all') {
      return instances.filter(
        (instance) => String(instance.state.toLowerCase()) === dropdownOption
      );
    }
    return instances;
  }, [instances, searchValue, dropdownOption]);

  return (
    <div className="flex flex-col gap-y-5">
      <>
        {instances.length > 0 && selectedInstances.length > 0 && (
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start gap-x-4">
              <h4
                className="text-base-heading-text-color dark:text-dark-base-heading-text-color
                    text-5 leading-6 font-medium"
              >
                {selectedInstances.length > 0 && (
                  <>{selectedInstances.length} Selected</>
                )}
              </h4>
              {selectedInstances.length > 0 && (
                <div className="flex items-center justify-start gap-x-3">
                  <Button
                    buttonType="ghost"
                    onClick={() =>
                      dispatch(
                        toggleModalShowRtk({
                          modalShow: true,
                          modalType: 'bulkAction',
                          options: {
                            handleProceed: () =>
                              dispatch(
                                closeMultipleInstancesThunk(selectedInstances)
                              ).then(() => {
                                setSelectedInstances([]);
                                dispatch(
                                  toggleModalShowRtk({
                                    modalShow: false,
                                  })
                                );
                              }),

                            loadingText: 'Closing Instances',
                          },
                        })
                      )
                    }
                    label="Close"
                    size="small"
                  />
                  {!includesProvisionedInstances && (
                    <Button
                      buttonType="ghost"
                      onClick={() =>
                        dispatch(
                          toggleModalShowRtk({
                            modalShow: true,
                            modalType: 'bulkAction',
                            options: {
                              handleProceed: () =>
                                dispatch(
                                  removeMultipleInstancesThunk(
                                    selectedInstances
                                  )
                                ).then(() => {
                                  setSelectedInstances([]);
                                  dispatch(
                                    toggleModalShowRtk({
                                      modalShow: false,
                                    })
                                  );
                                }),

                              loadingText: 'Removing Instances',
                            },
                          })
                        )
                      }
                      label="Delete"
                      size="small"
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </>
      <>
        {instancesLoading ||
        selectedOrganisationLoading ||
        shouldFetchComputeProjectInstances ? (
          <div className="grid grid-cols-12 gap-x-5 max-w-[1400px] justify-between">
            <div className="flex flex-col gap-y-5 w-full col-span-12">
              {[0, 1, 2].map((key) => (
                <Fragment key={key}>
                  <InstanceCardLoading />
                </Fragment>
              ))}
            </div>
          </div>
        ) : (
          <>
            {instanceToShow.length > 0 ? (
              <InfiniteScroll
                fetchFn={handleLoreMore}
                loader={
                  <div className="mt-4">
                    <LoadMore loading handleClick={() => {}} />
                  </div>
                }
                hasMoreItems={
                  searchValue === '' &&
                  instances.length < Number(allInstanceCount) &&
                  !allInstanceCountLoading
                }
                loading={loadMoreInstancesLoading}
              >
                <div>
                  {' '}
                  <div className="flex flex-col gap-y-5 w-full">
                    {instanceToShow.map((card) => {
                      return (
                        <Fragment key={card._id}>
                          {withLoader(
                            instancesLoading,
                            <ComputeDashboardLoading />,
                            <div className="relative">
                              <InstanceCard
                                detailsPage={false}
                                onClick={() =>
                                  handleInstanceCardClick((card as any)._id)
                                }
                                name={(card as IInstance).name || ''}
                                updatedAt={dayjs(
                                  (card as IInstance)?.updateByUserAt ||
                                    (card as IInstance)?.updatedAt
                                ).format('DD MMM YYYY')}
                                domainName={
                                  (card as IInstance)?.latestUrlPreview ||
                                  '' ||
                                  ''
                                }
                                region={(card as IInstance).region || ''}
                                instanceCardType={
                                  InstanceCardType.DEFAULT || ''
                                }
                                id={card._id || ''}
                                scalingType={getInstanceScalingType(
                                  (card as IInstance).computeType
                                )}
                                instanceState={getInstanceState(
                                  (card as IInstance).state
                                )}
                                instanceType={
                                  ((card as IInstance).type // eslint-disable-next-line max-len
                                    ?.toLocaleLowerCase() as InstanceDeploymentType) ||
                                  ''
                                }
                                totalPrice={
                                  (card as IInstance).state.toLowerCase() ===
                                    'failed' ||
                                  (card as IInstance).state.toLowerCase() ===
                                    'failed-start' ||
                                  (card as IInstance).state.toLowerCase() ===
                                    'closed' ||
                                  (card as IInstance).state.toLowerCase() ===
                                    'deprecated'
                                    ? undefined
                                    : (card as IInstance).defaultDailyTopup * 30
                                }
                                priceUnit="/mo"
                                discountBadgeText={
                                  (card as IInstance)?.discount
                                    ? `${
                                        (card as IInstance).discount
                                          .discountPercent
                                      }% OFF`
                                    : ''
                                }
                                isSelected={
                                  !!selectedInstances.find(
                                    (instanceId) => instanceId === card._id
                                  )
                                }
                                onCheckboxClick={(isSelected) => {
                                  if (isSelected) {
                                    setSelectedInstances((prevState: any[]) =>
                                      prevState.filter(
                                        (instanceId: any) =>
                                          instanceId !== card._id
                                      )
                                    );
                                  } else
                                    setSelectedInstances((prevState: any[]) => [
                                      card._id,
                                      ...prevState,
                                    ]);
                                }}
                              />
                              <div
                                className="absolute top-0 right-0"
                                ref={dropdownRef}
                              >
                                <Dropdown
                                  dropdownType="button"
                                  dropdownSize="compact"
                                  bordersNone
                                  buttonImage={
                                    <MoreIcon
                                      className="rotate-90 w-4 h-4 
                                        text-base-icon dark:text-dark-base-icon"
                                    />
                                  }
                                  options={instanceDropdownOptions(
                                    card.name,
                                    card._id,
                                    card.state as any
                                  )}
                                />
                              </div>
                            </div>
                          )}
                        </Fragment>
                      );
                    })}
                  </div>
                </div>
              </InfiniteScroll>
            ) : (
              <>
                <div className="mt-0">
                  <div className="h-[530px]">
                    <div className="h-full">
                      <EmptyState
                        icon={<NoSearchFound className="w-56 h-56" />}
                        buttonDisabled={false}
                        entityTitle={emptyStateTitle}
                        showButton={
                          searchValue?.length === 0 && dropdownOption === 'all'
                        }
                        buttonText="Create New Instance"
                        onClick={handleNewInstanceClick}
                      />
                    </div>
                  </div>
                  {!selectedOrganisationLoading &&
                    !instancesLoading &&
                    instances.length === 0 &&
                    spheronVideoResource?.length > 0 && (
                      <div className="mt-8">
                        <h5
                          className="text-base font-semibold text-base-para-text-color
                                      dark:text-dark-base-para-text-color"
                        >
                          Tutorials
                        </h5>
                        <div className="mt-3 grid grid-cols-2 gap-x-3 gap-y-3">
                          {spheronVideoResource.map((video) => (
                            <div
                              key={video.heading}
                              role="presentation"
                              onClick={() => video.onClick()}
                              className="px-5 py-3 rounded-lg cursor-pointer
                                           bg-cards-bg dark:bg-dark-cards-bg border border-cards-border
                                           dark:border-dark-cards-border
                                           flex flex-row items-cemter justify-between
                                           hover:bg-cards-hover hover:dark:bg-dark-cards-hover"
                            >
                              <div className="flex flex-row gap-3">
                                <img
                                  src={video.thumbnail}
                                  alt={video.heading}
                                  className="w-[142px] h-20 rounded-lg"
                                />
                                <div className="text-cards-text dark:text-dark-cards-text">
                                  <p className="text-xl font-semibold">
                                    {video.heading}
                                  </p>
                                  <p className="mt-2 text-xs">
                                    {video.subHeading}
                                  </p>
                                </div>
                              </div>
                              <div className="flex items-center">
                                <PlayIcon className="text-action-primary-default" />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                </div>
              </>
            )}
          </>
        )}
      </>
    </div>
  );
};

export default InstanceDashboard;
