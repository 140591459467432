import React from 'react';
import Loader from '../../../../components/Compute/Loaders/loader';

const PostLogin = () => {
  return (
    <div className="h-screen w-screen">
      <Loader />
    </div>
  );
};

export default PostLogin;
