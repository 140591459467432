import ReactIcon from '../../assets/icons/react.svg';
import AngularIcon from '../../assets/icons/angular.svg';
import VueIcon from '../../assets/icons/vue.svg';
import JSIcon from '../../assets/icons/javascript.svg';
import NextIcon from '../../assets/icons/next.svg';
import NextWhiteIcon from '../../assets/icons/next-white.svg';
import PreactIcon from '../../assets/icons/preact.svg';
import NuxtIcon from '../../assets/icons/nuxt.svg';
import HugoIcon from '../../assets/icons/hugo.svg';
import HugoWhiteIcon from '../../assets/icons/hugo-white.svg';
import EleventyIcon from '../../assets/icons/eleventy.svg';
import EleventyWhiteIcon from '../../assets/icons/eleventy-white.svg';
import SvelteIcon from '../../assets/icons/svelte.svg';
import GatsbyIcon from '../../assets/icons/gatsby.svg';
import SanityIcon from '../../assets/icons/sanity.svg';
import DocusaurusIcon from '../../assets/icons/docusaurus.svg';
import IonicIcon from '../../assets/icons/ionic-react.svg';
import ViteIcon from '../../assets/icons/vite.svg';
import ScullyIcon from '../../assets/icons/scully.svg';
import StencilIcon from '../../assets/icons/stencil.svg';
import BrunchIcon from '../../assets/icons/brunch.svg';
import AstroIcon from '../../assets/icons/astro.svg';
import AstroWhiteIcon from '../../assets/icons/astro-white.svg';
import { isDarkTheme } from '../theme/theme.utils';
import { IDropdownOption, IProjectsPagination } from './project.interfaces';

export const toggleListLoading = (
  list: string[],
  payload: {
    loading: boolean;
    domainId: string;
  }
) => {
  return payload.loading === true
    ? [...list, payload.domainId]
    : list.filter((d) => d !== payload.domainId);
};

export const frameworkDetails = (theme: string) => {
  return [
    {
      label: 'No Framework - Simple JavaScript App',
      value: 'static',
      img: JSIcon,
    },
    {
      label: 'Create React App',
      value: 'react',
      img: ReactIcon,
    },
    { label: 'Vue', value: 'vue', img: VueIcon },
    {
      label: 'Angular',
      value: 'angular',
      img: AngularIcon,
    },
    {
      label: 'Next.js',
      value: 'next',
      img: isDarkTheme(theme) ? NextWhiteIcon : NextIcon,
    },
    {
      label: 'Preact',
      value: 'preact',
      img: PreactIcon,
    },
    { label: 'Nuxt 2', value: 'nuxt2', img: NuxtIcon },
    {
      label: 'Docusaurus',
      value: 'docusaurus',
      img: DocusaurusIcon,
    },
    {
      label: 'Hugo',
      value: 'hugo',
      img: isDarkTheme(theme) ? HugoWhiteIcon : HugoIcon,
    },
    {
      label: 'Eleventy',
      value: 'eleventy',
      img: isDarkTheme(theme) ? EleventyWhiteIcon : EleventyIcon,
    },
    {
      label: 'Svelte',
      value: 'svelte',
      img: SvelteIcon,
    },
    {
      label: 'Gatsby',
      value: 'gatsby',
      img: GatsbyIcon,
    },
    {
      label: 'Sanity',
      value: 'sanity',
      img: SanityIcon,
    },
    { label: 'Ionic-React', value: 'ionicreact', img: IonicIcon },
    { label: 'Vite', value: 'vite', img: ViteIcon },
    { label: 'Scully', value: 'scully', img: ScullyIcon },
    { label: 'Stencil', value: 'stencil', img: StencilIcon },
    { label: 'Brunch', value: 'brunch', img: BrunchIcon },
    { label: 'Ionic-Angular', value: 'ionicangular', img: IonicIcon },
    {
      label: 'Astro',
      value: 'astro',
      img: isDarkTheme(theme) ? AstroWhiteIcon : AstroIcon,
    },
    { label: 'VuePress', value: 'vuepress', img: VueIcon },
  ];
};

export const getPaginationDetails = (
  type: string,
  archivedPaginationDetails: IProjectsPagination,
  maintainPaginationDetails: IProjectsPagination
): IProjectsPagination => {
  switch (type.toLowerCase()) {
    case 'archived':
      return archivedPaginationDetails;
    case 'maintained':
      return maintainPaginationDetails;
    default:
      return maintainPaginationDetails;
  }
};

export const filterBasedOnSearch = (
  value: string,
  domainDropwownOptions: (
    | {
        label: string;
        value: string;
        environment: string;
      }
    | {
        label: string;
        value: string;
      }
  )[]
) => {
  return domainDropwownOptions?.filter((element: IDropdownOption) =>
    element?.environment?.toLowerCase().includes(value)
  );
};
