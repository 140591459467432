import dayjs from 'dayjs';
import { OptionType } from '@spheron/ui-library';
import {
  DeploymentStatus,
  FrameworkOptions,
  IProjectDeployLogs,
} from './deployment.interfaces';
import FilecoinIcon from '../../assets/icons/filecoin.svg';
import IpfsIcon from '../../assets/icons/ipfs.svg';

export const setStreamData = (
  prevLogs: IProjectDeployLogs[],
  data: string,
  topicId: string
): IProjectDeployLogs[] => {
  const logs: IProjectDeployLogs[] = [...prevLogs];
  data?.split('\n')?.forEach((line: string) => {
    // check for any empty lines in the logs
    if (line.trim()) {
      // check for any duplicate log present in the logs
      if (logs.map((l) => l.log).indexOf(line.trim()) === -1) {
        logs.push({
          log: line,
          time: dayjs().format('hh:mm:ss A'),
          id: topicId,
        });
      }
    }
  });
  return logs;
};

export const setProcessedData = (
  data: IProjectDeployLogs[],
  topicId: string
): IProjectDeployLogs[] => {
  const processedLogs: IProjectDeployLogs[] = [];
  data?.forEach((logItem: any) => {
    if (typeof logItem?.log === 'string')
      logItem.log.split('\n')?.forEach((line: string) => {
        if (line.trim()) {
          processedLogs.push({
            log: line,
            time: dayjs(logItem.time).format('hh:mm:ss A'),
            id: topicId,
          });
        }
      });
  });
  return processedLogs;
};

export const mapDeploymentStatusToEnum = (status?: string) => {
  switch ((status || 'queue').toLowerCase()) {
    case 'queued': {
      return DeploymentStatus.Queued;
    }
    case 'pending': {
      return DeploymentStatus.Pending;
    }
    case 'failed': {
      return DeploymentStatus.Failed;
    }
    case 'deployed': {
      return DeploymentStatus.Deployed;
    }
    case 'authorizationneeded': {
      return DeploymentStatus.AuthorizationNeeded;
    }
    case 'prequeue': {
      return DeploymentStatus.PreQueue;
    }
    case 'killing': {
      return DeploymentStatus.Killing;
    }
    case 'canceled': {
      return DeploymentStatus.Canceled;
    }
    case 'timedout': {
      return DeploymentStatus.TimedOut;
    }
    default: {
      return DeploymentStatus.Queued;
    }
  }
};

export const mapFrameworkConfig = (framework: string) => {
  switch (framework) {
    case FrameworkOptions.Static:
      return {
        installCommand: '',
        buildCommand: '',
        publishDirectory: '',
      };
    case FrameworkOptions.React:
    case FrameworkOptions.IonicReact:
    case FrameworkOptions.Preact:
    case FrameworkOptions.Docusaurus:
      return {
        installCommand: 'yarn install',
        buildCommand: 'yarn build',
        publishDirectory: 'build',
      };
    case FrameworkOptions.Vue:
    case FrameworkOptions.Sanity:
    case FrameworkOptions.Vite:
    case FrameworkOptions.Scully:
      return {
        installCommand: 'yarn install',
        buildCommand: 'yarn build',
        publishDirectory: 'dist',
      };
    case FrameworkOptions.Angular:
    case FrameworkOptions.IonicAngular:
      return {
        installCommand: 'yarn install',
        buildCommand: 'yarn build',
        publishDirectory: 'dist/{your-app-name}',
      };
    case FrameworkOptions.Next:
      return {
        installCommand: 'yarn install',
        buildCommand: 'next build && next export',
        publishDirectory: 'out',
      };
    case FrameworkOptions.Nuxt2:
      return {
        installCommand: 'yarn install',
        buildCommand: 'yarn generate',
        publishDirectory: 'dist',
      };
    case FrameworkOptions.Hugo:
      return {
        installCommand: '',
        buildCommand: 'hugo -D --gc',
        publishDirectory: 'public',
      };
    case FrameworkOptions.Eleventy:
      return {
        installCommand: 'yarn install',
        buildCommand: 'yarn build',
        publishDirectory: '_site',
      };
    case FrameworkOptions.Svelte:
    case FrameworkOptions.Gatsby:
      return {
        installCommand: 'yarn install',
        buildCommand: 'yarn build',
        publishDirectory: 'public',
      };

    case FrameworkOptions.Stencil:
      return {
        installCommand: 'yarn install',
        buildCommand: 'yarn build',
        publishDirectory: 'www',
      };

    case FrameworkOptions.Brunch:
      return {
        installCommand: 'yarn install',
        buildCommand: 'yarn build',
        publishDirectory: 'public',
      };

    case FrameworkOptions.Astro:
      return {
        installCommand: 'npm install',
        buildCommand: 'npm run build',
        publishDirectory: 'dist',
      };

    case FrameworkOptions.VuePress:
      return {
        installCommand: 'npm install',
        buildCommand: 'npm run build',
        publishDirectory: './src/.vuepress/dist',
      };

    default:
      return {
        installCommand: '',
        buildCommand: '',
        publishDirectory: '',
      };
  }
};

export const checkDeploymentStatus = (
  currentTopic: string,
  currentStatus: string,
  incomingTopic: string,
  incomingStatus: string
) => {
  if (currentTopic === incomingTopic) {
    return mapDeploymentStatusToEnum(incomingStatus);
  }
  return mapDeploymentStatusToEnum(currentStatus);
};

export const checkDeploymentBuildTime = (
  currentTopic: string,
  currentBuildTime: {
    min: number;
    sec: number;
  },
  incomingTopic: string,
  incomingBuildTime: {
    min: number;
    sec: number;
  }
) => {
  if (currentTopic === incomingTopic) {
    return incomingBuildTime;
  }
  return currentBuildTime;
};

export const checkDeploymentLink = (
  currentTopic: string,
  currentLink: string,
  incomingTopic: string,
  incomingLink: string
) => {
  if (currentTopic === incomingTopic) {
    return incomingLink;
  }
  return currentLink;
};

export const starterProviderProtocols = [
  {
    label: 'Filecoin',
    value: 'ipfs-filecoin',
    img: FilecoinIcon,
    optionType: 'primary' as OptionType,
  },
  {
    label: 'IPFS',
    value: 'ipfs',
    img: IpfsIcon,
    optionType: 'primary' as OptionType,
  },
];
