import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ComputeUsageCard,
  EmptyState,
  SectionHeading,
  TextInput,
} from '@spheron/ui-library';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as BlueCloudIcon } from '../../../../assets/compute/icons/blue-cloud.svg';
import { ReactComponent as SearchIcon } from '../../../../assets/compute/icons/search.svg';
import { ReactComponent as DockerIcon } from '../../../../assets/compute/icons/docker.svg';
import { LeftIcon } from '../../../../components/Compute/Icons';
import ClusterBillingTableCard from '../../../../components/Compute/Cards/cluster-billing-table-card';
import {
  IClusterDetails,
  ITopupReport,
} from '../../../../redux/cluster/cluster.interfaces';
import {
  getClusterDetailsThunk,
  getClusterFundUsageThunk,
} from '../../../../redux/cluster/cluster.thunks';
import { RootState } from '../../../../redux/rtk-store';
import { getComputeProjectInstancesThunk } from '../../../../redux/compute/project/project.thunks';
import { withLoader } from '../../../../redux/root-utils';
import config from '../../../../config';
import { AppDispatch } from '../../../../redux/compute/store';

const ClusterInstanceBilling = () => {
  const communityOrgName = sessionStorage.getItem('communityOrgName');

  const isPublicAccessible =
    sessionStorage.getItem('isPublicAccessible') === 'true';

  const dispatchRtk = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const params = useParams<{ clusterId: string }>();

  const clusterDetails: IClusterDetails | null = useSelector(
    (state: RootState) => state.cluster.clusterDetails
  );

  const clusterInstanceReportLoading: boolean = useSelector(
    (state: RootState) => state.cluster.clusterInstanceReportLoading
  );

  const instancesLoading: boolean = useSelector(
    (state: RootState) => state.computeProject.selectedProjectInstancesLoading
  );

  const instances = useSelector(
    (state: RootState) => state.computeProject.selectedProjectInstances
  );

  const clusterFundUsage: ITopupReport | null = useSelector(
    (state: RootState) => state.cluster.clusterFundUsage
  );

  const getProviderIcon = (provider: string) => {
    switch (provider) {
      case 'DOCKERHUB':
        return <DockerIcon className="w-8 h-8" />;
      default:
        return <BlueCloudIcon className="w-8 h-8" />;
    }
  };
  const clusterStartup = async () => {
    if (params.clusterId) {
      dispatchRtk(getClusterDetailsThunk(params.clusterId));
      dispatchRtk(getClusterFundUsageThunk(params.clusterId));
      const payload = {
        clusterId: params.clusterId,
        topupReport: 'y',
      };
      dispatchRtk(
        getComputeProjectInstancesThunk({
          computeProjectId: payload.clusterId,
          topupReport: 'y',
        })
      );
    }
  };

  useEffect(() => {
    clusterStartup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allClusters = () => {
    navigate(
      `/${
        isPublicAccessible ? communityOrgName : 'compute'
      }/billing/compute-usage`
    );
  };

  const [searchValue, setSearchValue] = useState<string>('');
  const [searchInstances, setSearchInstances] = useState<any>([]);

  const handleSearch = (searchValue: string): void => {
    if (searchValue) {
      setSearchValue(searchValue);
      return;
    }
    setSearchValue('');
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row gap-2">
          <div
            className="font-semibold text-md w-fit pl-1 mb-2 cursor-pointer flex"
            role="presentation"
            onClick={allClusters}
          >
            <LeftIcon className="dark:text-dark-gray-text h-5 w-5 mt-0.5" />
          </div>
          <SectionHeading
            showRefresh={false}
            showSwitch={false}
            subHeading={''}
            heading="All Compute Projects"
            handleIconClick={() => {}}
            handleRefreshClick={() => {}}
            handleSwitchClick={() => {}}
            switchDisable={false}
            handleClick={() => {}}
            loading={false}
            time={''}
            refreshType={'default'}
            showText={false}
            toggleId={''}
            isChecked={false}
            handleChange={() => {}}
          />
        </div>
        <div className="w-[287px]">
          <TextInput
            inputSize="compact"
            leftIcon={
              <SearchIcon
                className="text-base-para-text-color
                        dark:text-dark-base-para-text-color"
              />
            }
            placeholder="Search Instances"
            value={searchValue}
            onChange={(value) => handleSearch(value as string)}
          />
        </div>
      </div>

      {withLoader(
        clusterInstanceReportLoading || instancesLoading,
        <Skeleton height={106} duration={2} />,
        <ComputeUsageCard
          icon={getProviderIcon('DOCKERHUB')}
          title={clusterDetails?.name || 'N/A'}
          subtitle={`Updated ${dayjs().to(
            dayjs(new Date(`${clusterDetails?.updatedAt}`))
          )}`}
          totalSpent={
            clusterFundUsage?.usedTillNow!.toFixed(
              config.dollarDecimalRound.DOLLAR_DECIMAL_ROUND
            ) || '0'
          }
          usageCost={(clusterFundUsage?.dailyUsage! * 30)?.toFixed(
            config.dollarDecimalRound.DOLLAR_DECIMAL_ROUND
          )}
          cardType="horizontal"
          disabled={false}
          handleClick={() => {}}
        />
      )}
      {instances?.length === 0 &&
      !(clusterInstanceReportLoading || instancesLoading) ? (
        <div className="h-72">
          <EmptyState
            entityTitle="You don't have any instance yet."
            subText=""
            showButton={false}
            onClick={() => {}}
            buttonDisabled={false}
          />
        </div>
      ) : (
        <ClusterBillingTableCard
          searchInstances={searchInstances}
          setSearchInstances={setSearchInstances}
          searchValue={searchValue}
        />
      )}
    </div>
  );
};

export default ClusterInstanceBilling;
