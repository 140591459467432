import React from 'react';
import EthereumLoading from '../../Icons/ethereum-loading';

const SignatureLoading = () => {
  return (
    <div
      className="mt-8 border border-dark-base-border dark:border-dark-base-border rounded-2xl py-7
  min-w-370 justify-center items-center w-full flex flex-col
  ease-in"
    >
      <EthereumLoading />
      <span
        className="text-base font-normal text-base-heading-text-color mt-3
        dark:text-dark-base-para-text-color"
      >
        Sign the transaction in wallet
      </span>
      <p className="text-sm font-normal text-dark-feedback-default-cross mt-5 w-56 text-center">
        Make sure you&apos;re using correct Ethereum account!
      </p>
    </div>
  );
};

export default SignatureLoading;
