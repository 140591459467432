import React from 'react';

interface IItem {
  id: number;
  text: JSX.Element;
}
interface IProps {
  items: IItem[];
  className: string;
  handleClick: () => void;
}

const InvoiceTableItem = ({ items, className, handleClick }: IProps) => {
  return (
    <tr
      className={`border-base-border border-b dark:border-dark-base-border ${
        className || ''
      }`}
      onClick={handleClick}
    >
      {items.map((item, i) => (
        <td className={`${i === 0 ? 'pl-4' : ''}`} key={item.id}>
          {item.text}
        </td>
      ))}
    </tr>
  );
};

export default InvoiceTableItem;
