import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ButtonType,
  Feedback,
  FeedbackType,
  TextInput,
} from '@spheron/ui-library';
import Skeleton from 'react-loading-skeleton';
import { v4 as uuidv4 } from 'uuid';
import Sticky from 'react-stickynode';
import { ReactComponent as Headphone } from '@spheron/ui-library/dist/assets/headphone.svg';
import { ReactComponent as WalletIcon } from '@spheron/ui-library/dist/assets/wallet.svg';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../redux/compute/store';
import {
  ClusterType,
  IInstancePlan,
  InstanceDeploymentType,
  IPLeaseType,
  LoadingType,
  ResourcesAvailable,
  ScalingType,
  ScalingTypeName,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.interfaces';
import { ReactComponent as SearchIcon } from '../../../../../assets/compute/icons/search.svg';
import SelectedPlan from '../SelectedPlan';
import { useDebounce } from '../../../../../hooks/useDebounce';
import SelectStorage from '../SelectStorage';
import AdvanceConfigurationSection from '../AdvanceConfigurationSection';
import {
  IClusterTemplateVariable,
  IPorts,
} from '../../../../../redux/compute/cluster/cluster.interfaces';
import {
  IClusterTemplateConfig,
  IClusterConfig,
  IAutoscalingRequestPayload,
} from '../../../../../redux/compute/instance/instance.interfaces';
import { useQuery } from '../../../../../hooks/useQuery';
import SelectPlan from '../SelectPlan';
import PlanOne from '../PlanOne';
import PlanTwo from '../PlanTwo';
import { CustomStorageOption } from '../CustomStorage/custom-storage';
import { toggleModalShowRtk } from '../../../../../redux/compute/modal/modal.slice';
import SelectedPlanLoading from '../../../../../components/Compute/Loaders/selected-plan-loader';
import {
  calculatePriceThunk,
  getInstancePlanThunk,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.thunks';
import {
  resetPersistentValuesRtk,
  updateCustomCpuRtk,
  updateCustomNvmStorageRtk,
  updateCustomPersistentStorageRtk,
  updateCustomRamRtk,
  updateCustomStorageRtk,
  updateInstanceValueRtk,
  updateLoadMoreCurrentPageRtk,
  chooseClusterTypeRtk,
  selectClusterRegionRtk,
  setScalingNameRtk,
  setScalingTypeRtk,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.slice';
import { setIsCustomPlanOpenRtk } from '../../../../../redux/compute/instance/instance.slice';
import ChooseGpuPlan from '../ChooseGpuPlan';
import {
  startInstanceDeploymentThunk,
  startTemplateInstanceDeploymentThunk,
} from '../../../../../redux/compute/instance/instance.thunks';
import { sliceStringFromEnd } from '../../../../../redux/compute/subscription/subscription.utils';
import { ISpheronWallet } from '../../../../../redux/compute/organisation/organisation.interfaces';
import config from '../../../../../config';
import {
  createUsername,
  deepEqual,
} from '../../../../../redux/compute/root-utils';
import IpLease from '../IpLease';
import { isScalable } from '../../../../../redux/compute/instance/instance-creation/instance-creation.utils';

interface IProps {
  isUpdate: boolean;
  selectedInstancePlan: IInstancePlan;
  // eslint-disable-next-line no-unused-vars
  setSelectedInstancePlan: (selectedInstancePlan: IInstancePlan | null) => void;
  instanceName: string;
}

const PlanGrid = ({
  isUpdate,
  selectedInstancePlan,
  setSelectedInstancePlan,
  instanceName,
}: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const queryParams = useQuery();
  const searchParams = new URLSearchParams(queryParams);
  const tag = searchParams.get('tag');
  const params = useParams<{ computeProjectId: string }>();
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [templateEnv, setTemplateEnv] = useState<IClusterTemplateVariable[]>(
    []
  );
  const [healthUrl, setHealthUrl] = useState<string>('');
  const [healthPort, setHealthPort] = useState<string>('');
  const [portMapping, setPortMapping] = useState<IPorts[]>([
    {
      containerPort: '',
      exposedPort: '',
    },
  ]);
  const [buildEnv, setBuildEnv] = useState<
    { key: string; value: string; isSecret: boolean }[]
  >([]);
  const [secretBuildEnv, setSecretBuildEnv] = useState<
    { key: string; value: string; isSecret: boolean }[]
  >([]);
  const [buildArgs, setBuildArgs] = useState<string[]>([]);
  const [buildCommands, setBuildCommands] = useState<string[]>([]);
  const [toggleOn, setToggleOn] = useState<boolean>(false);
  const [isCancel, setIsCancel] = useState<boolean>(false);
  const [isConfirm, setIsConfirm] = useState<boolean>(false);
  const [persistentStorageClass, setPersistentStorageClass] =
    useState<string>('');
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const [imageTag, setImageTag] = useState<string>('');
  const [persistentToggleOn, setPersistentToggleOn] = useState<boolean>(false);
  const [showCustomAutoScaling, setShowCustomAutoScaling] =
    useState<boolean>(false);
  const [autoScalingPolicy, setAutoScalingPolicy] =
    useState<IAutoscalingRequestPayload>({
      maximumInstances: 5,
      minimumInstances: 1,
      plan: '',
      timeWindow: '',
      cooldown: '',
      scaleUp: null,
      scaleDown: null,
    });

  const [updateDisabled, setUpdateDisabled] = useState<boolean>(false);

  const handleToggleChange = () => {
    setToggleOn(!toggleOn);
    setIsCancel(true);
  };
  const selectedInstance = useSelector(
    (state: RootState) => state.instance.selectedInstance
  );
  const activeDeployment = useSelector(
    (state: RootState) => state.instance.activeDeployment
  );
  const activeDeploymentLoading = useSelector(
    (state: RootState) => state.instance.activeDeploymentLoading
  );
  const selectedOrganisationLoading = useSelector(
    (state: RootState) => state.organisation.selectedOrganisationLoading
  );
  const selectedTemplate = useSelector(
    (state: RootState) => state.cluster.selectedTemplate
  );
  const computeStep = useSelector(
    (state: RootState) => state.instanceCreation.computeStep
  );
  const selectedOrganisation = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation
  );
  const selectedRepository = useSelector(
    (state: RootState) => state.cluster.selectedCluster?.repository
  );
  const selectedTags = useSelector(
    (state: RootState) => state.cluster.selectedCluster?.tags
  );
  const clusterType = useSelector(
    (state: RootState) => state.instanceCreation.clusterType
  );
  const selectedClusterRegion = useSelector(
    (state: RootState) => state.instanceCreation.clusterRegion
  );
  const selectedCpu = useSelector(
    (state: RootState) => state.instanceCreation.customCpu
  );
  const selectedRam = useSelector(
    (state: RootState) => state.instanceCreation.customRam
  );
  const selectedStorage = useSelector(
    (state: RootState) => state.instanceCreation.customStorage
  );
  const updatePersistentStorage = useSelector(
    (state: RootState) => state.instanceCreation.customPersistentStorage
  );
  const mountPointValue = useSelector(
    (state: RootState) => state.instanceCreation.mountPointValue
  );
  const instanceValue = useSelector(
    (state: RootState) => state.instanceCreation.instanceValue
  );
  const updateHddStorage = useSelector(
    (state: RootState) => state.instanceCreation.customHddStorage
  );
  const updateSsdStorage = useSelector(
    (state: RootState) => state.instanceCreation.customSsdStorage
  );
  const updateNvmStorage = useSelector(
    (state: RootState) => state.instanceCreation.customNvmStorage
  );
  const instanceDeploymentLoading = useSelector(
    (state: RootState) => state.instance.deployingInstance
  );
  const instancePlans = useSelector(
    (state: RootState) => state.instanceCreation.instancePlans
  );
  const instancePlansLoading = useSelector(
    (state: RootState) => state.instanceCreation.instancePlanLoading
  );
  const pricingTargetsLoading = useSelector(
    (state: RootState) => state.instanceCreation.pricingTargetsLoading
  );
  const recommendedPlanLoading = useSelector(
    (state: RootState) => state.instanceCreation.recommendedPlanLoading
  );
  const clusterScaling = useSelector(
    (state: RootState) => state.instanceCreation.clusterScaling
  );

  const instanceTag: string | undefined = useSelector(
    (state: RootState) =>
      state.instance.selectedInstance?.clusterInstanceConfiguration?.tag
  );
  const credentialName = useSelector(
    (state: RootState) => state.instanceCreation.privateCredentialName
  );
  const credentialPassword = useSelector(
    (state: RootState) => state.instanceCreation.privateCredentialPassword
  );
  const credentialUrl = useSelector(
    (state: RootState) => state.instanceCreation.privateCredentialUrl
  );

  const deploymentType = useSelector(
    (state: RootState) => state.instanceCreation.deploymentType
  );

  const planSearch = useSelector(
    (state: RootState) => state.instanceCreation.planSearch
  );
  const clusterScalingName = useSelector(
    (state: RootState) => state.instanceCreation.clusterScalingName
  );
  const persistentStorageAvailable = useSelector(
    (state: RootState) => state.instanceCreation.persistentStorageAvailable
  );
  const storageAvailable = useSelector(
    (state: RootState) => state.instanceCreation.storageAvailable
  );
  const planAvailable = useSelector(
    (state: RootState) => state.instanceCreation.planAvailable
  );
  const clusterRegion = useSelector(
    (state: RootState) => state.instanceCreation.clusterRegion
  );
  const currentApp = useSelector(
    (state: RootState) => state.organisation.currentApp
  );
  const spheronWallet = useSelector(
    (state: RootState) => state.organisation.spheronWallet
  );
  const clusterWalletUsage = useSelector(
    (state: RootState) => state.cluster.clusterWalletUsage
  );
  const clusterWalletUsageLoading: boolean = useSelector(
    (state: RootState) => state.cluster.clusterWalletUsageLoading
  );
  const ipLeaseType = useSelector(
    (state: RootState) => state.instanceCreation.ipLeaseType
  );
  const recommendedPlan = useSelector(
    (state: RootState) => state.instanceCreation.recommendedPlan
  );
  const subscriptionUsage = useSelector(
    (state: RootState) => state.subscription.subscriptionUsage
  );
  const subscriptionUsageLoading = useSelector(
    (state: RootState) => state.subscription.subscriptionUsageLoading
  );
  const persistentStorage =
    (updateSsdStorage > 0 && updateSsdStorage) ||
    (updateHddStorage > 0 && updateHddStorage) ||
    (updateNvmStorage > 0 && updateNvmStorage);

  const debouncedSearchTerm = useDebounce(searchValue, 500);
  const mapBuildEnv = (
    buildEnv: { key: string; value: string; isSecret: boolean }[]
  ) => {
    const buildEnvObj: {
      value: string;
      isSecret: boolean;
    }[] = [];
    buildEnv.forEach((env) => {
      buildEnvObj.push({
        isSecret: env.isSecret,
        value: `${env.key}=${env.value.trim()}`,
      });
    });
    return buildEnvObj;
  };
  const mapPortToConfig = (): {
    containerPort: string;
    exposedPort: string;
  }[] => {
    return portMapping
      .map((item) => {
        const config = {
          containerPort: item.containerPort,
          exposedPort:
            item.exposedPort === 'random'
              ? item.containerPort
              : item.exposedPort,
          ...(item.isChecked && { endpoint: 'endpoint' }),
          ...(item.endpoint && item.isChecked && { endpoint: item.endpoint }),
        };
        return config;
      })
      .filter((item) => item.containerPort !== '' && item.exposedPort !== '');
  };
  useEffect(() => {
    if (updatePersistentStorage) {
      switch (updatePersistentStorage) {
        case CustomStorageOption.CUSTOMHDDSTORAGE:
          setPersistentStorageClass('beta1');
          break;
        case CustomStorageOption.CUSTOMSSDSTORAGE:
          setPersistentStorageClass('beta2');
          break;
        case CustomStorageOption.CUSTOMNVMSTORAGE:
          setPersistentStorageClass('beta3');
          break;
        default:
          break;
      }
    }
  }, [updatePersistentStorage]);

  const startInstanceDeployment = () => {
    const uniqueTopicId = uuidv4();
    const healthCheck = {
      healthCheckUrl: healthUrl,
      healthCheckPort: healthPort,
    };
    const envVars = templateEnv.map((env) => ({
      key: env.name,
      value: env.defaultValue.trim(),
      isSecret: false,
    }));
    const newBuildEnv = buildEnv.concat(secretBuildEnv).concat(envVars);

    const autoScalingRules =
      clusterScalingName === ScalingTypeName.AUTO && !showCustomAutoScaling
        ? {
            plan: autoScalingPolicy.plan,
            maximumInstances: autoScalingPolicy.maximumInstances,
            minimumInstances: autoScalingPolicy.minimumInstances,
          }
        : {
            ...autoScalingPolicy,
          };

    // template instance deploy configuration
    let clusterTemplateConfig: IClusterTemplateConfig = {
      name: instanceName || null,
      organizationId: selectedOrganisation._id,
      uniqueTopicId,
      computeProjectId: params.computeProjectId,
      region: selectedClusterRegion || 'any',
      scalable: isScalable(clusterScaling),

      services: [
        {
          name: createUsername(selectedTemplate?.services[0].dockerImage!),
          image: selectedTemplate?.services[0].dockerImage!,
          tag: selectedTemplate?.services[0].dockerImageTag!,
          serviceCount: instanceValue,
          ports: mapPortToConfig(),
          args:
            buildArgs.length !== 0 ? buildArgs.map((args) => args.trim()) : [],
          command:
            buildCommands.length !== 0
              ? buildCommands.map((build) => build.trim())
              : [],
          akashMachineImageName:
            selectedInstancePlan?._id! === 'custom-id'
              ? ''
              : selectedInstancePlan?.name!,
          templateId: selectedTemplate?.services[0]._id!,
          env: newBuildEnv.length !== 0 ? mapBuildEnv(newBuildEnv) : [],
          autoscalingRules:
            clusterScalingName === ScalingTypeName.AUTO
              ? (autoScalingRules as IAutoscalingRequestPayload)
              : null,
        },
      ],
    };
    // provider instance deploy configuration
    let clusterConfig: IClusterConfig = {
      projectId: params.computeProjectId,
      branch: '',
      folderName: '',
      protocol: 'akash',
      image: selectedRepository?.trim(),
      tag: selectedTags?.trim() || '',
      instanceCount: instanceValue,
      buildImage: false,
      ports: mapPortToConfig(),
      akashMachineImageName: selectedInstancePlan?.name,
      env: newBuildEnv.length !== 0 ? mapBuildEnv(newBuildEnv) : [],
      command:
        buildCommands.length !== 0
          ? buildCommands.map((build) => build.trim())
          : [],
      args: buildArgs.length !== 0 ? buildArgs.map((args) => args.trim()) : [],
      region: selectedClusterRegion || 'any',
      organizationId: selectedOrganisation._id,
      scalable: isScalable(clusterScaling),
    };

    if (credentialPassword) {
      clusterConfig.privateImageConfig = {
        dockerRegistryURL: credentialUrl,
        dockerUserName: credentialName,
        dockerPasswordOrToken: credentialPassword,
      };
    }

    const customInstanceSpecs: any = {
      cpu: selectedCpu && Number(selectedCpu),
      memory: selectedRam && `${selectedRam}Gi`,
      storage: `${selectedStorage.toString()}Gi`,
    };

    if (persistentStorage && updatePersistentStorage) {
      customInstanceSpecs.persistentStorage = {
        size: `${persistentStorage.toString()}Gi`,
        class: persistentStorageClass,
        mountPoint: mountPointValue.trim(),
      };
    }
    if (
      selectedInstancePlan._id === 'custom-id' &&
      selectedCpu &&
      selectedRam
    ) {
      clusterConfig = {
        ...clusterConfig,
        customInstanceSpecs,
      };

      const service = {
        ...clusterTemplateConfig.services[0],
        customServiceSpecs: customInstanceSpecs,
      };

      clusterTemplateConfig = {
        ...clusterTemplateConfig,
        services: [service],
      };
    } else {
      clusterConfig = {
        ...clusterConfig,
        customInstanceSpecs: {
          storage: customInstanceSpecs.storage,
          ...(persistentStorage &&
            updatePersistentStorage && {
              persistentStorage: {
                size: `${persistentStorage.toString()}Gi`,
                class: persistentStorageClass,
                mountPoint: mountPointValue,
              },
            }),
        },
      };
      const service = {
        ...clusterTemplateConfig.services[0],
        customServiceSpecs: {
          storage: customInstanceSpecs.storage,
          ...(persistentStorage &&
            updatePersistentStorage && {
              persistentStorage: {
                size: `${persistentStorage.toString()}Gi`,
                class: persistentStorageClass,
                mountPoint: mountPointValue,
              },
            }),
        },
      };
      clusterTemplateConfig = {
        ...clusterTemplateConfig,
        services: [service],
      };
    }

    const instanceDeployment =
      clusterScalingName === ScalingTypeName.AUTO
        ? {
            uniqueTopicId,
            ...healthCheck,
            autoscalingRules: autoScalingRules,
          }
        : {
            uniqueTopicId,
            ...healthCheck,
          };
    if (clusterType === ClusterType.TEMPLATE)
      dispatchRtk(
        startTemplateInstanceDeploymentThunk({
          clusterTemplateConfig,
        })
      );
    else
      dispatchRtk(
        startInstanceDeploymentThunk({
          projectId: params?.computeProjectId || '',
          clusterConfig,
          instanceDeployment,
        })
      );
  };

  useEffect(() => {
    if (debouncedSearchTerm !== null) {
      dispatchRtk(
        getInstancePlanThunk({
          search: debouncedSearchTerm as string,
        })
      );
    }
  }, [debouncedSearchTerm, dispatchRtk]);

  useEffect(() => {
    if (planSearch === '' && searchValue && searchValue.trim() !== '') {
      setSearchValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planSearch]);

  useEffect(() => {
    if (isUpdate && activeDeployment && !activeDeploymentLoading) {
      dispatchRtk(
        selectClusterRegionRtk(activeDeployment?.services[0]?.region || '')
      );
    }
  }, [
    isUpdate,
    dispatchRtk,
    activeDeployment,
    activeDeploymentLoading,
    instancePlans.akashMachineImages?.length,
  ]);

  const initEnvMap = useRef<any>(null);
  useEffect(() => {
    if (isUpdate && activeDeployment) {
      if (instanceTag) {
        setImageTag(instanceTag);
      }
      if (activeDeployment?.services[0]?.serviceCount) {
        dispatchRtk(
          updateInstanceValueRtk(activeDeployment?.services[0]?.serviceCount)
        );
      }
      if (activeDeployment?.services[0]?.tag) {
        setImageTag(activeDeployment?.services[0]?.tag);
      }
      if (activeDeployment?.services[0]?.template) {
        dispatchRtk(chooseClusterTypeRtk(ClusterType.TEMPLATE));
        // TODO: Need to define proper type here
        setTemplateEnv(
          (activeDeployment?.services[0]?.variables as any)?.map(
            (variable: any) => ({
              ...variable,
              name: variable.name,
              defaultValue:
                variable.regionMap && clusterRegion !== 'any' && clusterRegion
                  ? variable.regionMap[clusterRegion]
                  : variable.defaultValue,
            })
          )
        );
        if (activeDeployment?.services[0]?.ports?.length > 0) {
          setPortMapping(activeDeployment?.services[0]?.ports);
        }
        if (activeDeployment?.services[0]?.env) {
          const envMap = activeDeployment?.services[0]?.env.map(
            (env: { value: string; isSecret: boolean }) => {
              // regex to split only at the first occurrence of '='
              const envKeyValue = env?.value?.split(/=(.*)/s);
              return {
                key: envKeyValue[0],
                value: envKeyValue[1],
                isSecret: env.isSecret,
              };
            }
          );
          const secretEnv = envMap.filter((env) => env.isSecret);
          const nonSecretEnv = envMap.filter((env) => !env.isSecret);
          setSecretBuildEnv(secretEnv);
          setBuildEnv(nonSecretEnv);
        }
        if (activeDeployment?.services[0].command) {
          setBuildCommands(activeDeployment?.services[0].command);
        }
        if (activeDeployment?.services[0].args) {
          setBuildArgs(activeDeployment?.services[0].args);
        }
      } else {
        dispatchRtk(chooseClusterTypeRtk(ClusterType.DOCKER));
        if (activeDeployment?.services[0].ports?.length > 0) {
          setPortMapping(activeDeployment?.services[0].ports);
        }
        if (activeDeployment?.services[0].env) {
          initEnvMap.current = activeDeployment?.services[0].env?.map(
            (env: { value: string; isSecret: boolean }) => {
              // regex to split only at the first occurence of '='
              const envKeyValue = env?.value?.split(/=(.*)/s);
              return {
                key: envKeyValue[0],
                value: envKeyValue[1],
                isSecret: env.isSecret,
              };
            }
          );

          setBuildEnv(initEnvMap.current);
        }
        if (activeDeployment?.services[0].command) {
          setBuildCommands(activeDeployment?.services[0].command);
        }

        if (activeDeployment?.services[0].args) {
          setBuildArgs(activeDeployment?.services[0].args);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdate, activeDeployment]);

  useEffect(() => {
    if (!isUpdate && selectedTemplate && clusterType === ClusterType.TEMPLATE) {
      const { variables, args, commands } = selectedTemplate.serviceData;
      setTemplateEnv(
        variables.map((variable) => ({
          ...variable,
          defaultValue:
            variable.regionMap && clusterRegion !== 'any' && clusterRegion
              ? variable.regionMap[clusterRegion]
              : variable.defaultValue,
        }))
      );
      setBuildArgs(args);
      setBuildCommands(commands);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplate, clusterRegion]);

  useEffect(() => {
    dispatchRtk(updateLoadMoreCurrentPageRtk(0));
    return () => {
      dispatchRtk(updateCustomPersistentStorageRtk(''));
      dispatchRtk(updateCustomCpuRtk(''));
      dispatchRtk(updateCustomNvmStorageRtk(0));
      dispatchRtk(updateCustomRamRtk(''));
      dispatchRtk(updateCustomStorageRtk(0));
    };
  }, [dispatchRtk]);

  const configAvailable =
    planAvailable === ResourcesAvailable.NOT_AVAILABLE ||
    storageAvailable === ResourcesAvailable.NOT_AVAILABLE ||
    persistentStorageAvailable === ResourcesAvailable.NOT_AVAILABLE;

  useEffect(() => {
    let hasEmptyFields = false;
    if (persistentToggleOn) {
      if (
        !updatePersistentStorage ||
        mountPointValue === '' ||
        !persistentStorage
      ) {
        hasEmptyFields = true;
      }
    }
    const hasEmptyPort = portMapping.some(
      (item) => item.containerPort === '' || item.exposedPort === ''
    );
    const hasEmptyEndpointFields = portMapping.some(
      (item) =>
        typeof item.endpointExposedPort !== 'undefined' &&
        item.endpointExposedPort !== ''
    );
    const hasEmptyPortCheck = portMapping.every((item) => !item.isChecked);
    if (
      hasEmptyPortCheck &&
      ipLeaseType === IPLeaseType.DEDICATED &&
      !hasEmptyEndpointFields
    ) {
      hasEmptyFields = true;
    }
    hasEmptyFields = hasEmptyPort || hasEmptyFields;
    if (clusterType === ClusterType.TEMPLATE) {
      const hasEmptyDefaultValue = Object.values(templateEnv).some(
        (object) => object.required && object.defaultValue === ''
      );
      hasEmptyFields = hasEmptyDefaultValue || hasEmptyFields;
    } else if (selectedTags === undefined || selectedTags === '') {
      hasEmptyFields = true;
    }
    if (instanceDeploymentLoading) {
      hasEmptyFields = true;
    }
    if (clusterScalingName === 'auto') {
      if (
        !showCustomAutoScaling &&
        (autoScalingPolicy.maximumInstances <
          autoScalingPolicy.minimumInstances ||
          autoScalingPolicy.plan === '' ||
          autoScalingPolicy.plan === 'custom' ||
          !autoScalingPolicy.maximumInstances ||
          !autoScalingPolicy.minimumInstances)
      ) {
        hasEmptyFields = true;
      } else if (
        showCustomAutoScaling &&
        (autoScalingPolicy.timeWindow === '' ||
          autoScalingPolicy.cooldown === '' ||
          !autoScalingPolicy?.scaleUp?.cpuThreshold.utilizationPercentage ||
          !autoScalingPolicy?.scaleUp?.cpuThreshold.step ||
          !autoScalingPolicy?.scaleUp?.memoryThreshold.utilizationPercentage ||
          !autoScalingPolicy?.scaleUp?.memoryThreshold.step ||
          !autoScalingPolicy?.scaleDown?.cpuThreshold.utilizationPercentage ||
          !autoScalingPolicy?.scaleDown?.cpuThreshold.step ||
          !autoScalingPolicy?.scaleDown?.memoryThreshold
            .utilizationPercentage ||
          !autoScalingPolicy?.scaleDown?.memoryThreshold.step)
      ) {
        hasEmptyFields = true;
      }
    }
    setIsDisable(hasEmptyFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    templateEnv,
    clusterType,
    portMapping,
    mountPointValue,
    isDisable,
    instanceDeploymentLoading,
    selectedTags,
    persistentToggleOn,
    updatePersistentStorage,
    persistentStorage,
    autoScalingPolicy,
    clusterScalingName,
    showCustomAutoScaling,
  ]);

  useEffect(() => {
    const foundService = activeDeployment?.services[0];
    const foundSelectedPlan = selectedInstancePlan;

    if (isUpdate) {
      let updateDisabled = true;
      if (
        foundService?.agreedMachineImage.cpu !== foundSelectedPlan?.cpu ||
        foundService?.agreedMachineImage.memory !== foundSelectedPlan?.memory ||
        foundService?.agreedMachineImage.storage !== `${selectedStorage}Gi`
      )
        updateDisabled = false;

      if (foundService?.serviceCount !== instanceValue) updateDisabled = false;

      if (
        (foundService?.agreedMachineImage.persistentStorage?.mountPoint ||
          (updateSsdStorage !== 0 && mountPointValue.length > 0)) &&
        (foundService?.agreedMachineImage.persistentStorage?.mountPoint !==
          mountPointValue ||
          foundService?.agreedMachineImage.persistentStorage?.size !==
            `${updateSsdStorage}Gi`)
      )
        updateDisabled = false;
      if (!deepEqual(foundService?.variables, templateEnv))
        updateDisabled = false;

      if (imageTag !== foundService?.tag) updateDisabled = false;

      if (
        !deepEqual(
          [
            ...(buildEnv.length !== 0 ? mapBuildEnv(buildEnv) : []),
            ...(secretBuildEnv.length !== 0 ? mapBuildEnv(secretBuildEnv) : []),
          ],
          foundService?.env
        )
      )
        updateDisabled = false;

      if (!deepEqual(buildArgs, foundService?.args)) updateDisabled = false;

      if (!deepEqual(buildCommands, foundService?.command))
        updateDisabled = false;

      setUpdateDisabled(updateDisabled);
    }
  }, [
    activeDeployment?.services,
    buildArgs,
    buildCommands,
    buildEnv,
    imageTag,
    instanceValue,
    isUpdate,
    mountPointValue,
    secretBuildEnv,
    selectedInstancePlan,
    selectedStorage,
    templateEnv,
    updateSsdStorage,
  ]);

  const updateInstanceDeployment = () => {
    const customInstanceSpecs: any = {
      storage: selectedStorage && `${selectedStorage.toString()}Gi`,
    };

    if (Number(selectedCpu) && selectedInstancePlan._id === 'custom-id') {
      Object.assign(customInstanceSpecs, {
        ...customInstanceSpecs,
        cpu: selectedCpu && Number(selectedCpu),
        memory: selectedRam && `${selectedRam}Gi`,
      });
    }

    if (
      (persistentStorage && updatePersistentStorage) ||
      Object.keys(
        activeDeployment?.services[0].agreedMachineImage?.persistentStorage ||
          {}
      ).length !== 0
    ) {
      customInstanceSpecs.persistentStorage = {
        size: `${persistentStorage.toString()}Gi`,
        class: persistentStorageClass,
        mountPoint: mountPointValue,
      };
    }

    const uniqueTopicId = uuidv4();
    const instanceUpdateConfig: IClusterTemplateConfig = {
      computeProjectId: params.computeProjectId,
      organizationId: selectedOrganisation._id,
      uniqueTopicId,
      instanceId: activeDeployment?.computeInstance || '',

      region: selectedClusterRegion || 'any',
      scalable: isScalable(clusterScaling),
      services: [
        {
          name:
            clusterType === ClusterType.TEMPLATE
              ? createUsername(
                  activeDeployment?.services[0].template?.name || ''
                )
              : activeDeployment?.services[0].name || '',
          image: activeDeployment?.services[0].image || '',
          templateId: activeDeployment?.services[0].template?._id || '',
          tag: imageTag,
          serviceCount:
            instanceValue || activeDeployment?.services[0]?.serviceCount || 1,
          akashMachineImageName:
            selectedInstancePlan._id !== 'custom-id'
              ? selectedInstancePlan?.name
              : '',
          env: [
            ...(buildEnv.length !== 0 ? mapBuildEnv(buildEnv) : []),
            ...(secretBuildEnv.length !== 0 ? mapBuildEnv(secretBuildEnv) : []),
            ...(templateEnv.length !== 0
              ? mapBuildEnv(
                  templateEnv.map((variable) => ({
                    key: variable.name,
                    value: variable.defaultValue,
                    isSecret: false,
                  }))
                )
              : []),
          ],
          command:
            buildCommands.length !== 0
              ? buildCommands.map((build: string) => build.trim())
              : [],
          args:
            buildArgs.length !== 0
              ? buildArgs.map((args: string) => args.trim())
              : [],
          customServiceSpecs: customInstanceSpecs,
        },
      ],
    };

    dispatchRtk(
      toggleModalShowRtk({
        modalShow: true,
        modalType: 'saveInstanceConfig',
        options: {
          instanceConfig: instanceUpdateConfig,
        },
      })
    );
  };

  useEffect(() => {
    const selectedId = instancePlans.akashMachineImages.find(
      (plan) => plan._id === 'custom-id'
    );
    if (!toggleOn) {
      dispatchRtk(setIsCustomPlanOpenRtk(false));
      if (selectedId) {
        dispatchRtk(updateCustomCpuRtk(String(selectedInstancePlan?.cpu)));
        if (selectedInstancePlan?.memory) {
          dispatchRtk(
            updateCustomRamRtk(
              String(sliceStringFromEnd(selectedInstancePlan?.memory, 2))
            )
          );
        }
        dispatchRtk(calculatePriceThunk(LoadingType.CUSTOM_PLAN));
      }
    } else {
      dispatchRtk(setIsCustomPlanOpenRtk(true));
    }
    return () => {
      dispatchRtk(setIsCustomPlanOpenRtk(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchRtk, toggleOn]);

  const isManuallyScaled = isUpdate && selectedInstance?.scalable;

  const isGPUInstance = deploymentType === InstanceDeploymentType.ACCELERATE;

  const referralBalance = (spheronWallet?.details as ISpheronWallet)
    ?.referralBalance;

  const creditsBalance = (spheronWallet?.details as ISpheronWallet)
    ?.creditsBalance;
  const balance = clusterWalletUsage?.balance
    ? (clusterWalletUsage?.balance).toFixed(
        config.dollarDecimalRound.DOLLAR_DECIMAL_ROUND
      )
    : 0;

  const insufficientBalanceCheck =
    Object.keys(subscriptionUsage).length > 0 &&
    (subscriptionUsage.cpuLimit - subscriptionUsage.cpu <
      Number(selectedCpu || recommendedPlan?.cpu) ||
      subscriptionUsage.memoryLimit - subscriptionUsage.memory <
        Number(selectedRam || recommendedPlan?.memory.slice(0, -2)));

  const outOfStockMessage = [
    {
      id: 1,
      feedbackCardType: 'info',
      condition: configAvailable,
      heading: 'Out of Stock',
      subHeading:
        'Selected Compute Specifications Unavailable. Please Contact Support for Assistance.',
      buttonLabel: 'Contact Team',
      buttonIcon: <Headphone className="w-5 h-5" />,
      buttonType: 'primary' as ButtonType,
    },
    {
      id: 2,
      feedbackCardType: 'error',
      condition:
        !clusterWalletUsageLoading &&
        !subscriptionUsageLoading &&
        (Number(balance) <= 0 || insufficientBalanceCheck),
      heading: 'Insufficient Balance: Limited Range',
      // eslint-disable-next-line max-len
      subHeading: `Your balance might not cover plans ranging from ${
        selectedCpu || recommendedPlan?.cpu
      }CPU, ${
        selectedRam || recommendedPlan?.memory.slice(0, -2)
      }Gi RAM. Supercharge your plan by adding funds.`,
      buttonLabel: 'Go to Billing',
      buttonIcon: <WalletIcon className="text-white w-5 h-5" />,
      buttonType: 'error' as ButtonType,
      secondaryId: 2.1,
      secondaryButtonLabel: 'View all Range',
      secondaryButtonType: 'ghost' as ButtonType,
    },
    {
      id: 3,
      feedbackCardType: 'info',
      condition:
        clusterScaling === ScalingType.NO &&
        Number((referralBalance + creditsBalance).toFixed(2)) > 0,
      heading: 'Go to On-Demand Scaling',
      subHeading:
        // eslint-disable-next-line max-len
        'The organization has a payment method associated with Spheron Credits. Please note that the provisioning of SPOT compute deployments is restricted when utilizing this payment method.',
      buttonLabel: 'Try On-Demand',
      buttonIcon: <span>🚀</span>,
      buttonType: 'primary' as ButtonType,
    },
  ];
  const handleButtonClick = (id: number) => {
    switch (id) {
      case 1:
        window.open('https://b4t4v7fj3cd.typeform.com/to/kM6t7oRm');
        break;
      case 2:
        navigate(`/${currentApp}/billing/plan`);
        break;
      case 2.1:
        dispatchRtk(
          toggleModalShowRtk({
            modalShow: true,
            modalType: 'bracketSystemInfo',
          })
        );
        break;
      case 3:
        dispatchRtk(setScalingNameRtk(ScalingTypeName.MANUAL));
        dispatchRtk(setScalingTypeRtk(ScalingType.MANUAL));
        dispatchRtk(resetPersistentValuesRtk());
        break;
      default:
        break;
    }
  };

  return (
    <div>
      {(!isUpdate || isManuallyScaled) && (
        <>
          {!pricingTargetsLoading && (
            <SelectPlan
              isUpdate={isUpdate}
              toggleOn={toggleOn}
              handleChange={handleToggleChange}
              serviceId="0"
            />
          )}
          {!toggleOn && (
            <div className="mt-6 ml-1">
              {selectedOrganisationLoading ? (
                <Skeleton
                  height={45}
                  width={320}
                  duration={2}
                  containerClassName="flex"
                />
              ) : (
                <div className="w-3/12">
                  <TextInput
                    inputSize="compact"
                    leftIcon={
                      <SearchIcon
                        className="text-base-para-text-color
                        dark:text-dark-base-para-text-color"
                      />
                    }
                    placeholder="e.g. prime..."
                    value={searchValue || ''}
                    onChange={(value) => setSearchValue(value as string)}
                  />
                </div>
              )}
            </div>
          )}
        </>
      )}

      <div
        className={`grid gap-x-6 grid-cols-3 ${
          !isUpdate ? 'mt-6 ll:grid-cols-4' : 'grid-cols-3'
        } ll:w-full`}
      >
        <div
          className={`gap-y-4 gap-x-0 w-full ${
            !isUpdate ? 'col-span-2 ll:col-span-3' : 'col-span-2'
          }`}
        >
          {(!isUpdate || isManuallyScaled) && (
            <div>
              {isGPUInstance ? (
                <ChooseGpuPlan
                  isUpdate={isUpdate}
                  selectedInstancePlan={selectedInstancePlan}
                  setSelectedInstancePlan={setSelectedInstancePlan}
                />
              ) : (
                <>
                  {toggleOn ? (
                    <PlanOne
                      isUpdate={isUpdate}
                      setToggleOn={setToggleOn}
                      setIsCancel={setIsCancel}
                      setSelectedInstancePlan={setSelectedInstancePlan}
                      setIsConfirm={setIsConfirm}
                    />
                  ) : (
                    <PlanTwo
                      isUpdate={isUpdate}
                      isCancel={isCancel}
                      selectedInstancePlan={selectedInstancePlan}
                      setSelectedInstancePlan={setSelectedInstancePlan}
                      isConfirm={isConfirm}
                      setIsConfirm={setIsConfirm}
                    />
                  )}
                </>
              )}
            </div>
          )}

          {!toggleOn && (
            <>
              {(computeStep >= 3 || isUpdate) && (
                <>
                  {(!isUpdate || isManuallyScaled) && (
                    <SelectStorage
                      isUpdate={isUpdate}
                      persistentToggleOn={persistentToggleOn}
                      setPersistentToggleOn={setPersistentToggleOn}
                    />
                  )}
                  {!isUpdate && (
                    <IpLease isUpdate={isUpdate} portMapping={portMapping} />
                  )}
                  <AdvanceConfigurationSection
                    isUpdate={isUpdate}
                    templateEnv={templateEnv}
                    setTemplateEnv={setTemplateEnv}
                    healthUrl={healthUrl}
                    setHealthUrl={setHealthUrl}
                    healthPort={healthPort}
                    setHealthPort={setHealthPort}
                    portMapping={portMapping}
                    setPortMapping={setPortMapping}
                    buildEnv={buildEnv}
                    setBuildEnv={setBuildEnv}
                    secretBuildEnv={secretBuildEnv}
                    setSecretBuildEnv={setSecretBuildEnv}
                    buildArgs={buildArgs}
                    setBuildArgs={setBuildArgs}
                    buildCommands={buildCommands}
                    setBuildCommands={setBuildCommands}
                    setTag={isUpdate ? setImageTag : null}
                    tag={isUpdate ? imageTag : tag || ''}
                    autoScalingPolicy={autoScalingPolicy}
                    setAutoScalingPolicy={setAutoScalingPolicy}
                    showCustomAutoScaling={showCustomAutoScaling}
                    setShowCustomAutoScaling={setShowCustomAutoScaling}
                    isDisable={false} // template={template}
                  />
                </>
              )}
            </>
          )}
        </div>
        <div>
          {(!isUpdate || isManuallyScaled) && (
            <Sticky top={isUpdate ? 120 : 80}>
              {selectedInstancePlan &&
              !instancePlansLoading &&
              !pricingTargetsLoading &&
              !recommendedPlanLoading ? (
                <>
                  <SelectedPlan
                    isUpdate={isUpdate}
                    selectedInstancePlan={selectedInstancePlan}
                    startInstanceDeployment={
                      isUpdate
                        ? updateInstanceDeployment
                        : startInstanceDeployment
                    }
                    isDisable={isDisable}
                    updateDisabled={updateDisabled}
                    insufficientBalance={insufficientBalanceCheck}
                  />
                  {outOfStockMessage.map((message) => {
                    return (
                      <div key={message.id} className="mt-4 gap-y-4">
                        {message.condition && (
                          <>
                            <Feedback
                              title={message.heading}
                              subTitle={message.subHeading}
                              feedbackType={
                                message.feedbackCardType as FeedbackType
                              }
                              showClose={false}
                              ctaDetails={{
                                buttonType: message.buttonType,
                                size: 'small',
                                onClick: () => handleButtonClick(message.id),
                                label: message.buttonLabel,
                                leftIcon: message.buttonIcon,
                              }}
                              secondaryCtaDetails={
                                message.secondaryButtonType
                                  ? {
                                      buttonType: message.secondaryButtonType,
                                      size: 'small',
                                      onClick: () =>
                                        handleButtonClick(message.secondaryId),
                                      label: message.secondaryButtonLabel,
                                    }
                                  : undefined
                              }
                            />
                          </>
                        )}
                      </div>
                    );
                  })}
                </>
              ) : (
                <SelectedPlanLoading />
              )}
            </Sticky>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlanGrid;
