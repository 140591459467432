import React from 'react';

interface IProps {
  title: string;
  isSelected: boolean;
  onSelect: () => void;
}

const FilterBadge = ({ title, isSelected, onSelect }: IProps) => {
  const handleOnClick = () => {
    onSelect();
  };
  return (
    <>
      <button
        type="button"
        tabIndex={0}
        className={`border relative rounded px-4 py-2 w-32 flex 
        items-center justify-center cursor-pointer 
        ${
          isSelected
            ? 'bg-dark-form-selected border-base-border dark:border-dark-base-border'
            : `border-base-border hover:border-dark-form-selected 
               dark:bg-dark-base-bg dark:border-dark-base-border
               dark:hover:border-dark-form-selected`
        }`}
        onClick={handleOnClick}
      >
        <span
          className={`text-sm font-medium ${
            isSelected
              ? 'text-white dark:text-bg-lightGray'
              : 'text-text-filterBadge'
          }`}
        >
          {title || 'All'}
        </span>
      </button>
    </>
  );
};

export default FilterBadge;
