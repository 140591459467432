import React from 'react';
import GithubIcon from '@spheron/ui-library/dist/assets/crypto-social/GitHub.svg';
import { ReactComponent as RightArrow } from '@spheron/ui-library/dist/assets/arrow-right-1.svg';
import { useDispatch } from 'react-redux';
import BitbucketIcon from '../../../assets/compute/icons/bitbucket-icon.svg';
import GitlabIcon from '../../../assets/compute/icons/gitlab-icon.svg';
import GoogleIcon from '../../../assets/compute/icons/google-icon.svg';
import { toggleSignupCallbackLoadingRtk } from '../../../redux/compute/user/user.slice';
import { ProviderType } from '../../../redux/compute/combined-state.interface';
import { AppDispatch } from '../../../redux/compute/store';

interface IProps {
  className?: string;
  provider?: ProviderType;
  text?: string;
  type: string;
  disable: boolean;
}

const ProviderButton = ({
  className,
  provider,
  text,
  type,
  disable,
}: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();

  const signUpWithProvider = async () => {
    dispatchRtk(toggleSignupCallbackLoadingRtk(true));
    const githubSignInUrl = `${window.location.origin}/${type}/${provider}`;
    window.open(githubSignInUrl, '_blank');
  };

  const providerIcon = (provider: ProviderType | undefined) => {
    switch (provider) {
      case ProviderType.GITHUB:
        return GithubIcon;
      case ProviderType.BITBUCKET:
        return BitbucketIcon;
      case ProviderType.GITLAB:
        return GitlabIcon;
      case ProviderType.GOOGLE:
        return GoogleIcon;
      default:
        return undefined;
    }
  };

  return (
    <button
      className={`border
          border-base-border dark:border-dark-base-border flex font-medium items-center
          rounded-md py-3 min-w-370 transition justify-between w-[400px] h-14
          r ${className || ''} ${
        disable
          ? 'cursor-not-allowed '
          : 'cursor-pointer hover:bg-base-fg hover:dark:bg-dark-base-fg'
      }
       `}
      type="button"
      onClick={signUpWithProvider}
    >
      <div className="flex items-center ml-4">
        <img src={providerIcon(provider)} alt="Github" className="w-7 h-7" />
        <span
          className="ml-5 text-base font-semibold  text-para-text-color 
                    dark:text-dark-para-text-color"
        >
          {text}
        </span>
      </div>
      <RightArrow className="mr-4 text-light-text-color dark:text-dark-light-text-color w-6 h-6" />
    </button>
  );
};

ProviderButton.defaultProps = {
  className: '',
  provider: '',
  text: 'Sign up',
};

export default ProviderButton;
