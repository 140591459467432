import React, { useEffect, useRef, useState } from 'react';
import { Switch } from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import TemplateCard from '../TemplateCard';
import { CustomStorageOption } from '../CustomStorage/custom-storage';
import { StorageType } from '../SelectPersistentStorage/select-persistent-storage';
import {
  ClusterType,
  HardwareTypeEnum,
  LoadingType,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.interfaces';
import { RootState } from '../../../../../redux/rtk-store';
// eslint-disable-next-line max-len
import { multiserviceCalculatePriceThunk } from '../../../../../redux/compute/instance/instance-creation/instance-creation.thunks';
import {
  updateMultiServiceCustomStorage,
  updateMultiServiceCustomStorageInput,
  updateMultiServiceMountPointValue,
  updateMultiServiceCustomPersistentStorage,
  resetMultiservicePersistentValues,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.slice';
import StorageCardLoading from '../../../../../components/Compute/Loaders/storage-card-loader';
import { sliceStringFromEnd } from '../../../../../redux/subscription/subscription.utils';
// eslint-disable-next-line max-len
import { isAccelerateDeployment } from '../../../../../redux/compute/instance/instance-creation/instance-creation.utils';
import MultiServiceSelectPersistentStorage from '../MultiServiceSelectPersistentStorage';
import MultiServiceCustomStorage from '../MultiServiceCustomStorage';
import { AppDispatch } from '../../../../../redux/compute/store';

interface IProps {
  isUpdate: boolean;
  selectedService: any;
  persistentToggleOn: boolean;
  // eslint-disable-next-line no-unused-vars
  setPersistentToggleOn: (persistentToggleOn: boolean) => void;
}
const MultiServiceSelectStorage = ({
  isUpdate,
  selectedService,
  persistentToggleOn,
  setPersistentToggleOn,
}: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();
  const [selectedCard, setSelectedCard] = useState<any>(0); // infer better type
  const activeDeployment = useSelector(
    (state: RootState) => state.instance.activeDeployment
  );
  const activeDeploymentLoading = useSelector(
    (state: RootState) => state.instance.activeDeploymentLoading
  );
  const defaultCustomPlanValue = useSelector(
    (state: RootState) => state.instanceCreation.defaultCustomPlansValue
  );
  const storageValues = defaultCustomPlanValue?.find(
    (item: { type: HardwareTypeEnum }) => item.type === HardwareTypeEnum.STORAGE
  );
  const customStoragePrice = useSelector(
    (state: RootState) =>
      state.instanceCreation.multiserviceInstanceCreation![selectedService._id]
        ?.updatedStoragePrice || 0
  );
  const storageLoading = useSelector(
    (state: RootState) => state.instanceCreation.storageLoading
  );
  const scalingType = useSelector(
    (state: RootState) => state.instanceCreation.clusterScaling
  );
  const clusterRegion = useSelector(
    (state: RootState) => state.instanceCreation.clusterRegion
  );
  const clusterType = useSelector(
    (state: RootState) => state.instanceCreation.clusterType
  );
  const deploymentType = useSelector(
    (state: RootState) => state.instanceCreation.deploymentType
  );
  const persistentStorageTemplate =
    selectedService?.customTemplateSpecs.persistentStorage;

  const storageTemplate = selectedService?.customTemplateSpecs.storage;

  const hasPersistentStorage =
    Object.keys(persistentStorageTemplate || {}).length > 0;

  const hddValues = defaultCustomPlanValue?.find(
    (item: { type: HardwareTypeEnum }) =>
      item.type === HardwareTypeEnum.PERSISTENT_STORAGE_HDD
  );
  const ssdValues = defaultCustomPlanValue?.find(
    (item: { type: HardwareTypeEnum }) =>
      item.type === HardwareTypeEnum.PERSISTENT_STORAGE_SSD
  );
  const nvmValues = defaultCustomPlanValue?.find(
    (item: { type: HardwareTypeEnum }) =>
      item.type === HardwareTypeEnum.PERSISTENT_STORAGE_NVME
  );
  const handleToggleChange = () => {
    setPersistentToggleOn(!persistentToggleOn);
    dispatchRtk(resetMultiservicePersistentValues(selectedService._id));
  };

  const handleCardClick = (index: number) => {
    setSelectedCard(index);
    dispatchRtk(
      updateMultiServiceCustomStorageInput({
        serviceId: selectedService._id,
        value: 0,
      })
    );
    const selectedStorage = storageValues?.values[index];
    if (selectedStorage) {
      dispatchRtk(
        updateMultiServiceCustomStorage({
          serviceId: selectedService._id,
          value: selectedStorage.value,
        })
      );
    }
    dispatchRtk(
      multiserviceCalculatePriceThunk({
        serviceId: selectedService._id,
        loadingType: LoadingType.STORAGE,
      })
    );
  };

  const fetchCustomPlanRanRef = useRef(false);

  useEffect(() => {
    if (persistentStorageTemplate && hasPersistentStorage) {
      if (!hddValues?.values && !ssdValues?.values && !nvmValues?.values) {
        setPersistentToggleOn(false);
      } else {
        setPersistentToggleOn(true);
      }
      dispatchRtk(
        updateMultiServiceMountPointValue({
          serviceId: selectedService._id,
          value: persistentStorageTemplate.mountPoint,
        })
      );
    } else {
      setPersistentToggleOn(false);
      dispatchRtk(
        updateMultiServiceCustomPersistentStorage({
          serviceId: selectedService._id,
          value: '',
        })
      );
      dispatchRtk(
        updateMultiServiceMountPointValue({
          serviceId: selectedService._id,
          value: '',
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    persistentStorageTemplate,
    dispatchRtk,
    setPersistentToggleOn,
    hasPersistentStorage,
    isUpdate,
    hddValues,
    ssdValues,
    nvmValues,
  ]);
  useEffect(() => {
    if (storageTemplate && !isUpdate) {
      dispatchRtk(
        updateMultiServiceCustomStorageInput({
          serviceId: selectedService._id,
          value: Number(sliceStringFromEnd(storageTemplate, 2)),
        })
      );
      dispatchRtk(
        updateMultiServiceCustomStorage({
          serviceId: selectedService._id,
          value: Number(sliceStringFromEnd(storageTemplate, 2)),
        })
      );
    } else {
      dispatchRtk(
        updateMultiServiceCustomStorageInput({
          serviceId: selectedService._id,
          value: 0,
        })
      );
      dispatchRtk(
        updateMultiServiceCustomStorage({
          serviceId: selectedService._id,
          value: 0,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageTemplate, dispatchRtk, isUpdate]);

  useEffect(() => {
    let shouldRunFetchCustomPlan = true;
    let selectedStorageData: number | undefined;
    if (customStoragePrice > 0 && fetchCustomPlanRanRef.current) {
      shouldRunFetchCustomPlan = false;
    }
    if (!isUpdate) {
      if (storageValues && !storageLoading) {
        if (storageTemplate) {
          selectedStorageData = storageValues?.values.find(
            (storage: any) =>
              storage.value ===
              Number(sliceStringFromEnd(storageTemplate || '', 2))
          )?.value;
          if (selectedStorageData) {
            dispatchRtk(
              updateMultiServiceCustomStorage({
                serviceId: selectedService._id,
                value:
                  storageValues?.values.find(
                    (storage: any) => storage.value === selectedStorageData
                  )?.value || 0,
              })
            );
            dispatchRtk(
              updateMultiServiceCustomStorageInput({
                serviceId: selectedService._id,
                value: 0,
              })
            );
            setSelectedCard(
              storageValues!.values.findIndex(
                (storage: { value: number }) =>
                  storage.value === (selectedStorageData as number)
              )
            );
          } else {
            dispatchRtk(
              updateMultiServiceCustomStorageInput({
                serviceId: selectedService._id,
                value: Number(sliceStringFromEnd(storageTemplate || '', 2)),
              })
            );
            setSelectedCard(StorageType.CUSTOM);
          }
          dispatchRtk(
            multiserviceCalculatePriceThunk({
              serviceId: selectedService._id,
              loadingType: LoadingType.STORAGE,
            })
          );
        } else {
          const defaultSelectedStorage = storageValues?.values.find(
            (value: { value: number }) => value.value === 20
          );
          if (defaultSelectedStorage) {
            const selectedIndex = storageValues?.values.findIndex(
              (value: { value: number }) => value.value === 20
            );
            if (selectedIndex !== -1) {
              setSelectedCard(selectedIndex);

              dispatchRtk(
                updateMultiServiceCustomStorage({
                  serviceId: selectedService._id,
                  value: defaultSelectedStorage.value,
                })
              );
            }
          }
          if (shouldRunFetchCustomPlan) {
            dispatchRtk(
              multiserviceCalculatePriceThunk({
                serviceId: selectedService._id,
                loadingType: LoadingType.STORAGE,
              })
            );
            fetchCustomPlanRanRef.current = true;
          }
        }
      }
    } else if (
      storageValues &&
      !storageLoading &&
      !activeDeploymentLoading &&
      activeDeployment
    ) {
      const foundService = activeDeployment.services.find(
        (service) => service.template?._id === selectedService._id
      );

      if (
        Object.keys(foundService?.agreedMachineImage.persistentStorage || {})
          .length > 0
      )
        setPersistentToggleOn(true);
      const selectedStorage = storageValues?.values.find(
        (storage: any) =>
          storage.value ===
          Number(
            sliceStringFromEnd(
              foundService?.agreedMachineImage?.storage || '',
              2
            )
          )
      )?.value;
      if (selectedStorage) {
        dispatchRtk(
          updateMultiServiceCustomStorage({
            serviceId: selectedService._id,
            value:
              storageValues?.values.find(
                (storage: any) => storage.value === selectedStorage
              )?.value || 0,
          })
        );
      } else {
        dispatchRtk(
          updateMultiServiceCustomStorageInput({
            serviceId: selectedService._id,
            value: Number(
              sliceStringFromEnd(
                foundService?.agreedMachineImage?.storage || '',
                2
              )
            ),
          })
        );
      }
      setSelectedCard(
        storageValues!.values.findIndex(
          (storage: { value: number }) =>
            storage.value === (selectedStorage as number)
        )
      );
      if (shouldRunFetchCustomPlan) {
        dispatchRtk(
          multiserviceCalculatePriceThunk({
            serviceId: selectedService._id,
            loadingType: LoadingType.STORAGE,
          })
        );
        fetchCustomPlanRanRef.current = true;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeDeployment,
    storageValues,
    storageLoading,
    scalingType,
    clusterRegion,
    hasPersistentStorage,
  ]);

  useEffect(() => {
    if (clusterRegion !== null) {
      dispatchRtk(
        multiserviceCalculatePriceThunk({
          serviceId: selectedService._id,
          loadingType: LoadingType.STORAGE,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clusterRegion]);

  const persistentStorageRef = useRef<HTMLDivElement>(null);

  const scrollToPersistentStorage = () => {
    persistentStorageRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  const stepNumber = isAccelerateDeployment(deploymentType) ? '7.' : '8.';
  const dockerStepNumber = isAccelerateDeployment(deploymentType) ? '5.' : '6.';
  return (
    <div className="mt-14">
      <div className="flex lg:flex-row flex-col items-center">
        <div
          className="text-base-heading-text-color dark:text-dark-base-heading-text-color
          text-xl font-bold"
        >
          {!isUpdate
            ? `${
                (clusterType as any) === ClusterType.TEMPLATE
                  ? stepNumber
                  : dockerStepNumber
              }`
            : ''}
          <span className="ml-1">Configure Storage</span>
        </div>
        <div className="text-text-darkGray text-sm font-normal flex flex-1 ml-5 items-center">
          <div className="mt-0.5"> Add Persistent Storage</div>
          <div className="ml-3">
            <Switch
              toggleId={`toggle-storage-${selectedService?._id}`}
              isChecked={persistentToggleOn}
              handleChange={() => {
                handleToggleChange();
                if (!persistentToggleOn) {
                  scrollToPersistentStorage();
                }
              }}
            />
          </div>
        </div>
      </div>
      <p className="text-text-darkGray text-sm font-normal leading-5 mt-2">
        The storage is volatile, it will be erased when the instance is
        restarted, redeployed or shut down.
      </p>
      {!storageLoading && defaultCustomPlanValue.length > 0 ? (
        <div className="mt-5">
          <div
            className={`grid gap-6 ${
              isUpdate
                ? 'grid-cols-2'
                : 'grid-cols-1 md:grid-cols-2 xl:grid-cols-3'
            }`}
          >
            {storageValues?.values.map((data: any, index: number) => (
              <div>
                <TemplateCard
                  title={`Storage ${data.value} GB`}
                  imageShow={false}
                  image=""
                  badgeContent=""
                  subtitle={`${data.price}/${
                    isAccelerateDeployment(deploymentType) ? 'hour' : 'month'
                  }`}
                  isSelected={selectedCard === index}
                  onSelect={() => handleCardClick(index)}
                  isSelectedTemplate={false}
                />
              </div>
            ))}
          </div>
          <div
            className={`grid grid-cols-1 md:grid-cols-2 ${
              isUpdate ? 'xl:grid-cols-2' : 'xl:grid-cols-3'
            } gap-6 mt-8`}
          >
            <MultiServiceCustomStorage
              serviceId={selectedService._id}
              isUpdate={isUpdate}
              isSelected={
                isUpdate
                  ? selectedCard === -1
                  : selectedCard === StorageType.CUSTOM
              }
              value={CustomStorageOption.CUSTOMSTORAGE}
              onSelect={() => {
                if (isUpdate) {
                  setSelectedCard(-1);
                } else {
                  setSelectedCard(StorageType.CUSTOM);
                }
              }}
            />
          </div>
        </div>
      ) : (
        <div
          className={`grid gap-6 mt-5
        mr-8 ${
          isUpdate ? 'grid-cols-2' : 'grid-cols-1 md:grid-cols-2 ll:grid-cols-3'
        }`}
        >
          {Array.from(Array(6).keys()).map((item) => (
            <StorageCardLoading key={item} />
          ))}
        </div>
      )}
      <div ref={persistentStorageRef}>
        {persistentToggleOn && (
          <MultiServiceSelectPersistentStorage
            selectedService={selectedService}
            isUpdate={isUpdate}
          />
        )}
      </div>
    </div>
  );
};

export default MultiServiceSelectStorage;
