import { IResponseError } from '../../combined-reducer.interface';
import { requestPipeline } from '../../root-utils';
import {
  ICreateComputeProjectPayloadDto,
  ICreateNewProjectResponseDto,
  IGetAllComputeProjectsResponseDto,
  IGetComputeProjectInstancesResponseDto,
} from './project.interfaces';

export const getComputeProjectDetailsService = async (
  computeProjectId: string
): Promise<any> => {
  return requestPipeline({
    url: `compute-project/${computeProjectId}`,
    method: 'GET',
    isPublic: true,
  });
};

export const getComputeProjectInstancesCountService = async (
  computeProjectId: string
): Promise<any> => {
  return requestPipeline({
    url: `compute-project/${computeProjectId}/instances/count`,
    method: 'GET',
    isPublic: true,
  });
};

export const getClusterFundUsageService = async (
  computeProjectId: string
): Promise<any> => {
  return requestPipeline({
    url: `compute-project/${computeProjectId}/funds-usage`,
    method: 'GET',
    isPublic: true,
  });
};

export const getAllComputeProjectsService = async (
  organizationId: string,
  topupReport: string,
  skip: number,
  limit: number
): Promise<IGetAllComputeProjectsResponseDto | IResponseError> => {
  return requestPipeline({
    // eslint-disable-next-line max-len
    url: `organization/${organizationId}/compute-projects?skip=${skip}&limit=${limit}&topupReport=${topupReport}&instanceCountReport=y`,
    method: 'GET',
  });
};

export const getComputeProjectsCountService = async (
  orgId: string
): Promise<any> => {
  return requestPipeline({
    url: `organization/${orgId}/compute-projects/count`,
    method: 'GET',
  });
};

export const createComputeProjectService = async (
  formBody: ICreateComputeProjectPayloadDto
): Promise<any> => {
  return requestPipeline({
    url: `compute-projects/create`,
    method: 'POST',
    body: JSON.stringify(formBody),
  });
};

export const getComputeProjectInstancesCount = (
  computeProjectId: string
): Promise<any> => {
  return requestPipeline({
    url: `compute-project/${computeProjectId}/instances/count`,
    method: 'GET',
    isPublic: true,
  });
};

export const getComputeProjectsFundUsageService = async (
  computeProjectId: string
): Promise<any> => {
  return requestPipeline({
    url: `compute-project/${computeProjectId}/funds-usage`,
    method: 'GET',
    isPublic: true,
  });
};

export const deleteComputeProjectService = async (
  computeProjectId: string
): Promise<any> => {
  return requestPipeline({
    url: `compute-project/${computeProjectId}`,
    method: 'DELETE',
  });
};

export const getComputeProjectInstancesService = async (
  computeProjectId: string,
  topupReport: string,
  skip: number,
  limit: number,
  state: string
): Promise<IGetComputeProjectInstancesResponseDto | IResponseError> => {
  return requestPipeline({
    // eslint-disable-next-line max-len
    url: `compute-project/${computeProjectId}/instances?skip=${skip}&limit=${limit}&topupReport=${topupReport}&state=${state}`,
    method: 'GET',
  });
};

export const createNewProjectService = async (
  payload: ICreateComputeProjectPayloadDto
): Promise<ICreateNewProjectResponseDto | IResponseError> => {
  return requestPipeline({
    url: `compute-project/create`,
    method: 'POST',
    body: JSON.stringify(payload),
  });
};
