import React from 'react';
import dayjs from 'dayjs';
import { ReactComponent as LinkIcon } from '@spheron/ui-library/dist/assets/spheron-specific/external-link.svg';
import { useDispatch } from 'react-redux';
import Style from '../../../styles/compute/notification.module.scss';
import NotificationIcon from '../../../assets/compute/global/announcement.svg';
import Read from '../../../assets/compute/global/read.svg';
import { updateUserNotificationsThunk } from '../../../redux/compute/user/user.thunks';
import { AppDispatch } from '../../../redux/compute/store';
// import { updateUserNotificationsThunk } from '../../redux/user/user.thunks';

interface IProps {
  title: string;
  externalLink?: string;
  description: string;
  read?: boolean;
  createdAt?: string;
  id?: string;
  noNotification: boolean;
}

function NotificationItem({
  title,
  externalLink,
  description,
  read,
  createdAt,
  id,
  noNotification,
}: IProps) {
  const dispatchRtk = useDispatch<AppDispatch>();

  return (
    <div className={`${Style.notification_con} relative`}>
      <div
        className={Style.notification}
        role="presentation"
        onKeyDown={() => {}}
        onClick={() => {
          if (noNotification) {
            return;
          }
          if (externalLink !== '') {
            window.open(externalLink, '_blank');
          }
          dispatchRtk(updateUserNotificationsThunk(id as string));
        }}
      >
        <img src={NotificationIcon} alt="notification icon" />
        <div style={{ minWidth: '95%' }}>
          <div>
            <h1 className="break-words">{title}</h1>
            <h5 className="break-words">{dayjs(createdAt).format('DD MMM')}</h5>
          </div>
          <p className="break-words">{description}</p>
          <div
            className={`flex justify-between items-center ${Style.overflow__break}`}
          >
            {externalLink !== '' && (
              <a
                href={externalLink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-base-link dark:text-dark-base-link"
                onClick={() => {
                  if (noNotification) {
                    return;
                  }
                  dispatchRtk(updateUserNotificationsThunk(id as string));
                }}
              >
                Learn more&nbsp;
                <LinkIcon
                  height={12}
                  width={12}
                  className="text-base-link dark:text-dark-base-link"
                />
              </a>
            )}

            {read && (
              <img src={Read} alt="read" className="mb-8 absolute right-0" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

NotificationItem.defaultProps = {
  read: false,
  createdAt: Date.now(),
  id: '',
  externalLink: '',
};

export default NotificationItem;
