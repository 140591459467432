/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
// eslint-disable-next-line   import/no-extraneous-dependencies
import MonacoEditor, { useMonaco } from '@monaco-editor/react';

const Editor = ({ ...props }) => {
  const rules = [{ token: 'custom-success', foreground: '009438' }];
  const monaco = useMonaco();

  monaco?.languages.register({ id: 'terminal' });

  monaco?.languages.setMonarchTokensProvider('terminal', {
    tokenizer: {
      root: [
        [
          // eslint-disable-next-line max-len
          /^(?:.*\b(?:Deployment URL|successful|successfully|Successfully|Successful|Deployment URL:|Confirming)\b.*)$/i,
          'custom-success',
        ],
      ],
    },
  });

  monaco?.editor.defineTheme('terminalColour', {
    base: 'vs',
    inherit: false,
    rules,
    colors: {
      'editor.foreground': '#757575',
      'editor.background': '#f7fafc',
      'editorLineNumber.foreground': '#717987',
    },
  });

  return (
    <>
      {monaco && <MonacoEditor className="monaco__editor__custom" {...props} />}
    </>
  );
};

export default Editor;
