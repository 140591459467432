import React, { useState } from 'react';
import {
  ConfirmationModal,
  Feedback,
  Modal,
  TextInput,
} from '@spheron/ui-library';
import { ReactComponent as InfoIcon } from '@spheron/ui-library/dist/assets/spheron-specific/Info-filler.svg';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import Styles from '../../../styles/compute/modal.module.scss';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import ModalWrapper from './ModalWrapper';

interface IProps {
  isOpen: boolean;
  resource: string;
  resourceType: string;
  handleClick: () => void;
}

const DeleteResource = ({
  isOpen,
  resource,
  handleClick,
  resourceType,
}: IProps) => {
  const deleteComputeProjectLoading = useSelector(
    (state: RootState) => state.computeProject.deleteProjectLoading
  );

  const deletingOrganisation = useSelector(
    (state: RootState) => state.organisation.deleteOrganisationLoading
  );

  const deleteInstanceLoading = useSelector(
    (state: RootState) => state.instance.deleteInstanceLoading
  );
  const [resourceName, setResourceName] = useState<string>('');

  const dispatchRtk = useDispatch<AppDispatch>();

  const closeModal = () => {
    dispatchRtk(toggleModalShowRtk({ modalShow: false }));
  };

  const removingResource = (resourceType: string): boolean => {
    switch (resourceType.toLowerCase()) {
      case 'organisation':
        return deletingOrganisation;
      case 'instance':
        return deleteInstanceLoading;
      case 'compute project':
        return deleteComputeProjectLoading;
      default:
        return false;
    }
  };

  return (
    <>
      <ModalWrapper
        isOpen={isOpen}
        onClose={closeModal}
        classname={`${Styles.modal__outer__con} w-430 modal fixed inset-0 overflow-y-auto`}
      >
        <div className="w-[600px]">
          <Modal
            footer
            header
            title={`Remove ${resourceType}`}
            requiredMessage
            requiredMessageContent={<span>Please fill in all the fields</span>}
            subtitle=""
            modalContent={
              <div className="flex flex-col gap-y-5">
                <div className="text-base-para-text-color dark:text-dark-base-para-text-color font-normal text-sm">
                  <p className="text-sm font-normal">
                    If you are sure you want to proceed with the deletion of “
                    <b>{resource}</b>”, please continue below.
                  </p>
                  <p className="text-sm font-normal">
                    Keep in mind this operation is irreversible and will result
                    in a complete deletion of all the data associated with the{' '}
                    {resourceType}{' '}
                    {resourceType === 'Organisation'
                      ? 'including any funds added for compute credits'
                      : ''}
                    .
                  </p>
                </div>
                <Feedback
                  size="compact"
                  icon={<InfoIcon className="mt-0.5 w-5 h-5" />}
                  feedbackType="error"
                  showClose={false}
                  title="Please be sure, this action is irreversible"
                />
                <TextInput
                  label={`Enter the ${resourceType} name`}
                  value={resourceName}
                  onChange={(value) => setResourceName(value as string)}
                  helpText={`Input the ${resourceType?.toLowerCase()} name you want to remove`}
                />
              </div>
            }
            headerContent={
              <div className="flex flex-col gap-y-3">
                <span>Removing {resourceType}</span>
              </div>
            }
            primaryButtonContent={`Remove ${resourceType?.toLowerCase()}`}
            secondaryButtonContent="Cancel"
            primaryButton
            primaryButtonLoading={removingResource(resourceType)}
            primaryButtonDisabled={
              typeof resource !== 'undefined' && resourceName !== resource
            }
            onPrimaryButtonClick={handleClick}
            onSecondaryButtonClick={closeModal}
            secondaryButton
            confirmationStatus={ConfirmationModal.ERROR}
          />
        </div>
      </ModalWrapper>
    </>
  );
};

export default DeleteResource;
