import React, { useEffect, useState } from 'react';
import { ConfirmationModal, Modal } from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import Styles from '../../../styles/compute/modal.module.scss';
import {
  INetworkType,
  IOrganisationWallet,
  ITokenType,
} from '../../../redux/compute/web3/web3.interfaces';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import { disconnectWalletThunk } from '../../../redux/compute/web3/web3.thunks';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import ModalWrapper from './ModalWrapper';

interface IProps {
  isOpen: boolean;
  id: string;
}

const DisconnectWallet = ({ isOpen, id }: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();
  const disconnectWalletLoading: boolean = useSelector(
    (state: RootState) => state.web3.disconnectWalletLoading
  );

  const web3Wallets = useSelector((state: RootState) => state.web3.wallets);

  const [wallet, setWallet] = useState<IOrganisationWallet>();

  useEffect(() => {
    if (id) {
      setWallet(web3Wallets.find((wallet) => wallet._id === id));
    }
  }, [wallet, id, web3Wallets]);

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={() => {
        dispatchRtk(toggleModalShowRtk({ modalShow: false }));
      }}
      classname={`${Styles.modal__outer__con} w-430 modal fixed inset-0 overflow-y-auto`}
    >
      <div className={Styles.modal__inner__content}>
        <Modal
          footer
          header
          // eslint-disable-next-line max-len
          subtitle="Removal of wallet will stop the subscription from upcoming month and stop all the platform services."
          modalContent={
            <span>
              Do you really want to remove <b>{wallet?.details.address}</b> from
              your organization?
            </span>
          }
          headerContent={<>Disconnect Wallet</>}
          primaryButtonContent="Disconnect"
          secondaryButtonContent="Cancel"
          primaryButton
          primaryButtonLoading={disconnectWalletLoading}
          primaryButtonDisabled={false}
          secondaryButtonDisabled={disconnectWalletLoading}
          secondaryButton
          onPrimaryButtonClick={() => {
            dispatchRtk(
              disconnectWalletThunk({
                id,
                address: wallet?.details.address || '',
                networkDetails: wallet?.details.networkDetails as INetworkType,
                tokenDetails: wallet?.details.tokenDetails as ITokenType,
              })
            );
          }}
          onSecondaryButtonClick={() => {
            dispatchRtk(toggleModalShowRtk({ modalShow: false }));
          }}
          confirmationStatus={ConfirmationModal.ERROR}
        />
      </div>
    </ModalWrapper>
  );
};

export default DisconnectWallet;
