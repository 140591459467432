import React from 'react';
import { ConfirmationModal, Modal } from '@spheron/ui-library';
import { useDispatch } from 'react-redux';
import Styles from '../../../styles/compute/modal.module.scss';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import ModalWrapper from './ModalWrapper';
import {
  setIpLeaseType,
  setMultiServicesIpLeaseType,
} from '../../../redux/compute/instance/instance-creation/instance-creation.slice';
import {
  IPLeaseType,
  LoadingType,
} from '../../../redux/compute/instance/instance-creation/instance-creation.interfaces';
import {
  calculatePriceThunk,
  multiserviceCalculatePriceThunk,
} from '../../../redux/compute/instance/instance-creation/instance-creation.thunks';
import { AppDispatch } from '../../../redux/rtk-store';

interface IProps {
  isOpen: boolean;
  serviceId: string;
  multiservice: boolean;
}

const DedicatedIpConfirmationModal = ({
  isOpen,
  multiservice,
  serviceId,
}: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();
  const closeModal = () => {
    if (multiservice) {
      dispatchRtk(
        setMultiServicesIpLeaseType({
          serviceId,
          value: IPLeaseType.SHARED,
        })
      );
      dispatchRtk(
        multiserviceCalculatePriceThunk({
          serviceId,
          loadingType: LoadingType.IP_LEASE,
        })
      );
    } else {
      dispatchRtk(setIpLeaseType(IPLeaseType.SHARED));
      dispatchRtk(calculatePriceThunk(LoadingType.IP_LEASE));
    }
    dispatchRtk(toggleModalShowRtk({ modalShow: false }));
  };

  const confirmDedicatedIp = () => {
    if (multiservice) {
      dispatchRtk(
        setMultiServicesIpLeaseType({
          serviceId,
          value: IPLeaseType.DEDICATED,
        })
      );
      dispatchRtk(
        multiserviceCalculatePriceThunk({
          serviceId,
          loadingType: LoadingType.IP_LEASE,
        })
      );
    } else {
      dispatchRtk(setIpLeaseType(IPLeaseType.DEDICATED));
      dispatchRtk(calculatePriceThunk(LoadingType.IP_LEASE));
    }
    dispatchRtk(toggleModalShowRtk({ modalShow: false }));
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={closeModal}
      classname={`${Styles.modal__outer__con} w-430 modal fixed inset-0 overflow-y-auto`}
    >
      <div className="w-[600px]">
        <Modal
          footer
          header
          crossIcon
          subtitle=""
          modalContent={
            <div className="text-base-para-text-color dark:text-dark-base-para-text-color font-normal text-sm">
              It looks like you&apos;ve selected a{' '}
              <span className="font-semibold">&apos;Shared IP&apos;</span> but
              also checked the{' '}
              <span className="font-semibold">&apos;Dedicated Port&apos;</span>{' '}
              option. Choosing a dedicated port automatically switches your IP
              instance to{' '}
              <span className="font-semibold">&apos;Dedicated&apos;</span> .{' '}
              <br />
              <br /> Are you sure you want to proceed with a dedicated IP?
            </div>
          }
          headerContent={<span>Confirmation Required</span>}
          primaryButtonContent="Proceed with Dedicated IP"
          secondaryButtonContent="Stay with Shared IP"
          primaryButton
          onPrimaryButtonClick={confirmDedicatedIp}
          onSecondaryButtonClick={closeModal}
          secondaryButton
          confirmationStatus={ConfirmationModal.INFO}
        />
      </div>
    </ModalWrapper>
  );
};

export default DedicatedIpConfirmationModal;
