import React, { useState } from 'react';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import { Button } from '@spheron/ui-library';
import SpheronLogo from '@spheron/ui-library/dist/assets/spheron-specific/spheron-logo-2.svg';
import SpheronDarkLogo from '@spheron/ui-library/dist/assets/spheron-specific/spheron-logo-dark.svg';
import { useDispatch, useSelector } from 'react-redux';
import StripeTextInput from '../InputFields/stripe-text-input';
import { addNotificationRtk } from '../../../redux/compute/notification/notification.slice';
import { NotificationType } from '../../../redux/compute/notification/notification.interfaces';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import { isDarkTheme } from '../../../redux/compute/theme/theme.utils';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import { getSelectedOrgWalletThunk } from '../../../redux/compute/organisation/organisation.thunks';

interface IProps {
  stripePromise: Stripe;
}

const StripeCheckout = ({ stripePromise }: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();
  const stripe = useStripe();
  const elements = useElements();
  const selectedOrganisation = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation
  );
  const theme = useSelector((state: RootState) => state.theme.theme);
  const [name, setName] = useState<string>('');
  const [isProcessing, setIsProcessing] = useState(false);

  const handlePay = async () => {
    if (!stripe || !elements || !name) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            name,
          },
        },
        return_url: `${localStorage.getItem('redirectUrl')}`,
      },
      redirect: 'if_required',
    });

    if (error) {
      dispatchRtk(
        addNotificationRtk({
          message: error.message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
    } else {
      localStorage.removeItem('client-secret');
      localStorage.removeItem('redirectUrl');
      dispatchRtk(getSelectedOrgWalletThunk(selectedOrganisation._id));
    }
    setIsProcessing(false);
    dispatchRtk(toggleModalShowRtk({ modalShow: false }));
  };

  return (
    <div className="dark:bg-dark-base-bg bg-white rounded-xl pt-8">
      <img
        src={!isDarkTheme(theme) ? SpheronDarkLogo : SpheronLogo}
        alt="stripe"
        className="h-16 w-44 mx-auto"
      />
      {stripePromise && (
        <div className="px-8 min-w-500 pt-6 flex flex-col mb-1">
          <StripeTextInput
            type="text"
            className=""
            value={name}
            setValue={setName}
          />
        </div>
      )}
      <PaymentElement id="payment-element" className="px-8 min-w-500" />

      <div className="px-8 pb-8">
        <Button
          buttonType="primary"
          label="Attach payment method"
          size="medium"
          classname="w-full mt-8 flex justify-center"
          onClick={handlePay}
          disabled={isProcessing || !stripe || !elements || !name}
          loading={isProcessing}
        />
      </div>
    </div>
  );
};

export default StripeCheckout;
