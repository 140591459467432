import React, { useEffect, useState } from 'react';
import { Tooltip } from '@spheron/ui-library';
import { ReactComponent as InfoIcon } from '@spheron/ui-library/dist/assets/info-circle.svg';
import { useDispatch, useSelector } from 'react-redux';
import TemplateCard from '../TemplateCard';
import CustomStorage from '../CustomStorage';
import { CustomStorageOption } from '../CustomStorage/custom-storage';
import DropdownMenu from '../DropdownMenu';
import { CustomSelectOption } from '../DropdownMenu/dropdown-menu';
import {
  ClusterType,
  HardwareTypeEnum,
  LoadingType,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.interfaces';
import ComputeInput from '../../../../../components/Compute/InputFields/newUI/text-input';
import { AppDispatch, RootState } from '../../../../../redux/compute/store';
import {
  updateCustomHddStorageRtk,
  updateCustomNvmStorageRtk,
  updateCustomSsdStorageRtk,
  updateMountPointValueRtk,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.slice';
import { calculatePriceThunk } from '../../../../../redux/compute/instance/instance-creation/instance-creation.thunks';
import { sliceStringFromEnd } from '../../../../../redux/compute/subscription/subscription.utils';
import StorageCardLoading from '../../../../../components/Compute/Loaders/storage-card-loader';
import {
  getPersistentStorageName,
  isAccelerateDeployment,
} from '../../../../../redux/instance/instance-creation/instance-creation.utils';

interface IProps {
  isUpdate: boolean;
}

export enum StorageType {
  // eslint-disable-next-line no-unused-vars
  TEMPLATE = 'template',
  // eslint-disable-next-line no-unused-vars
  CUSTOM = 'custom',
  // eslint-disable-next-line no-unused-vars
  PERSISTENT = 'persistent',
  // eslint-disable-next-line no-unused-vars
  PERSISTENTTEMPLATE = 'persistentTemplate',
}

const SelectPersistentStorage = ({ isUpdate }: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();

  const [selectedCard, setSelectedCard] = useState<number | string | null>(
    null
  );
  const [selectedValue, setSelectedValue] = useState<any>();
  const [mountError, setMountError] = useState<boolean>(false);
  const updateHddStorage = useSelector(
    (state: RootState) => state.instanceCreation.customHddStorage
  );
  const updateSsdStorage = useSelector(
    (state: RootState) => state.instanceCreation.customSsdStorage
  );
  const updateNvmStorage = useSelector(
    (state: RootState) => state.instanceCreation.customNvmStorage
  );
  const updatePersistentStorage = useSelector(
    (state: RootState) => state.instanceCreation.customPersistentStorage
  );
  const defaultCustomPlanValue = useSelector(
    (state: RootState) => state.instanceCreation.defaultCustomPlansValue
  );
  const mountPointValue = useSelector(
    (state: RootState) => state.instanceCreation.mountPointValue
  );

  const activeDeployment = useSelector(
    (state: RootState) => state.instance.activeDeployment
  );

  const activeDeploymentLoading = useSelector(
    (state: RootState) => state.instance.activeDeploymentLoading
  );
  const clusterType = useSelector(
    (state: RootState) => state.instanceCreation.clusterType
  );
  const selectedTemplate = useSelector(
    (state: RootState) => state.cluster.selectedTemplate
  );
  const hddValues = defaultCustomPlanValue?.find(
    (item: { type: HardwareTypeEnum }) =>
      item.type === HardwareTypeEnum.PERSISTENT_STORAGE_HDD
  );
  const ssdValues = defaultCustomPlanValue?.find(
    (item: { type: HardwareTypeEnum }) =>
      item.type === HardwareTypeEnum.PERSISTENT_STORAGE_SSD
  );
  const nvmValues = defaultCustomPlanValue?.find(
    (item: { type: HardwareTypeEnum }) =>
      item.type === HardwareTypeEnum.PERSISTENT_STORAGE_NVME
  );
  const storageLoading = useSelector(
    (state: RootState) => state.instanceCreation.storageLoading
  );
  const instancePlanLoading = useSelector(
    (state: RootState) => state.instanceCreation.instancePlanLoading
  );
  const deploymentType = useSelector(
    (state: RootState) => state.instanceCreation.deploymentType
  );

  const storageOptions = [
    {
      key: 1,
      name: 'HDD',
      suffix: '',
      subtitle: '',
      disable: !hddValues?.values,
    },
    {
      key: 2,
      name: 'SSD',
      suffix: '',
      subtitle: '',
      disable: !ssdValues?.values,
    },
    {
      key: 3,
      name: 'NVMe',
      suffix: '',
      subtitle: '',
      disable: !nvmValues?.values,
    },
  ];

  const selectedInstanceStorage =
    activeDeployment?.services[0].agreedMachineImage;
  const persistentStorageTemplate =
    selectedTemplate?.serviceData.customTemplateSpecs.persistentStorage;
  const hasPersistentStorage =
    Object.keys(persistentStorageTemplate || {}).length > 0;
  useEffect(() => {
    if (isUpdate && !activeDeploymentLoading) {
      dispatchRtk(
        updateMountPointValueRtk(
          selectedInstanceStorage?.persistentStorage?.mountPoint || ''
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchRtk, isUpdate, activeDeployment, activeDeploymentLoading]);

  useEffect(() => {
    let selectedStorageData;
    const updatedTestPersistentStorage = Number(
      sliceStringFromEnd(
        activeDeployment?.services[0].agreedMachineImage?.persistentStorage
          ?.size || '',
        2
      )
    );
    switch (updatePersistentStorage) {
      case CustomStorageOption.CUSTOMHDDSTORAGE:
        setSelectedValue(hddValues);
        if (
          isUpdate &&
          !activeDeploymentLoading &&
          activeDeployment &&
          activeDeployment.services[0].agreedMachineImage?.persistentStorage
        ) {
          selectedStorageData = hddValues?.values.findIndex(
            (v) => v.value === updatedTestPersistentStorage
          ) as number;
          dispatchRtk(updateCustomHddStorageRtk(updatedTestPersistentStorage));
        }
        break;
      case CustomStorageOption.CUSTOMSSDSTORAGE:
        setSelectedValue(ssdValues);
        if (
          isUpdate &&
          !activeDeploymentLoading &&
          activeDeployment &&
          activeDeployment.services[0].agreedMachineImage?.persistentStorage
        ) {
          selectedStorageData = ssdValues?.values.findIndex(
            (v) => v.value === updatedTestPersistentStorage
          ) as number;
          dispatchRtk(updateCustomSsdStorageRtk(updatedTestPersistentStorage));
        }
        break;
      case CustomStorageOption.CUSTOMNVMSTORAGE:
        setSelectedValue(nvmValues);
        if (
          isUpdate &&
          !activeDeploymentLoading &&
          activeDeployment &&
          activeDeployment.services[0].agreedMachineImage?.persistentStorage
        ) {
          selectedStorageData = nvmValues?.values?.findIndex(
            (v) => v.value === updatedTestPersistentStorage
          ) as number;
          dispatchRtk(updateCustomNvmStorageRtk(updatedTestPersistentStorage));
        }
        break;
      default:
        break;
    }
    if (updatedTestPersistentStorage > 0) {
      if (typeof selectedStorageData !== 'undefined') {
        if (selectedStorageData !== -1) {
          setSelectedCard(selectedStorageData);
        } else {
          setSelectedCard(StorageType.PERSISTENT);
        }
      }
      dispatchRtk(calculatePriceThunk(LoadingType.PERSISTENT_STORAGE));
    }
  }, [
    updatePersistentStorage,
    hddValues,
    ssdValues,
    nvmValues,
    isUpdate,
    activeDeploymentLoading,
    activeDeployment,
    dispatchRtk,
  ]);
  useEffect(() => {
    let selectedStorageData: any;
    const recommendedPersistentStorage = Number(
      sliceStringFromEnd(persistentStorageTemplate?.size || '', 2)
    );
    if (
      (ssdValues || hddValues || nvmValues) &&
      !isUpdate &&
      recommendedPersistentStorage
    ) {
      switch (updatePersistentStorage) {
        case CustomStorageOption.CUSTOMHDDSTORAGE:
          setSelectedValue(hddValues);
          if (hasPersistentStorage && !isUpdate) {
            selectedStorageData = hddValues?.values.findIndex(
              (v) => v.value === recommendedPersistentStorage
            ) as number;
            dispatchRtk(
              updateCustomHddStorageRtk(recommendedPersistentStorage)
            );
          }
          break;
        case CustomStorageOption.CUSTOMSSDSTORAGE:
          setSelectedValue(ssdValues);
          if (hasPersistentStorage && !isUpdate) {
            selectedStorageData = ssdValues?.values.findIndex(
              (v) => v.value === recommendedPersistentStorage
            ) as number;
            dispatchRtk(
              updateCustomSsdStorageRtk(recommendedPersistentStorage)
            );
          }
          break;
        case CustomStorageOption.CUSTOMNVMSTORAGE:
          setSelectedValue(nvmValues);
          if (hasPersistentStorage && !isUpdate) {
            selectedStorageData = nvmValues?.values?.findIndex(
              (v) => v.value === recommendedPersistentStorage
            ) as number;
            dispatchRtk(
              updateCustomNvmStorageRtk(recommendedPersistentStorage)
            );
          }
          break;
        default:
          break;
      }
      if (selectedStorageData && selectedStorageData !== -1) {
        setSelectedCard(selectedStorageData);
      } else {
        setSelectedCard(StorageType.PERSISTENT);
      }
      dispatchRtk(calculatePriceThunk(LoadingType.PERSISTENT_STORAGE));
    }
  }, [
    updatePersistentStorage,
    hddValues,
    ssdValues,
    nvmValues,
    persistentStorageTemplate,
    hasPersistentStorage,
    isUpdate,
    dispatchRtk,
  ]);
  const persistentStorage =
    updateSsdStorage > 0 || updateHddStorage > 0 || updateNvmStorage > 0;
  useEffect(() => {
    if (!persistentStorage && !isUpdate) {
      setSelectedCard(null);
    }
  }, [persistentStorage, isUpdate]);

  const handleCardClick = (index: number) => {
    setSelectedCard(index);
    const selectedStorage = selectedValue?.values[index];
    if (updatePersistentStorage === CustomStorageOption.CUSTOMHDDSTORAGE) {
      dispatchRtk(updateCustomHddStorageRtk(selectedStorage.value));
    } else if (
      updatePersistentStorage === CustomStorageOption.CUSTOMSSDSTORAGE
    ) {
      dispatchRtk(updateCustomSsdStorageRtk(selectedStorage.value));
    } else if (
      updatePersistentStorage === CustomStorageOption.CUSTOMNVMSTORAGE
    ) {
      dispatchRtk(updateCustomNvmStorageRtk(selectedStorage.value));
    }
    dispatchRtk(calculatePriceThunk(LoadingType.PERSISTENT_STORAGE));
  };

  const handleMountInput = (e: string) => {
    dispatchRtk(updateMountPointValueRtk(e));
    setMountError(false);
  };
  const handleBlur = () => {
    if (mountPointValue === '') {
      setMountError(true);
    } else {
      setMountError(false);
    }
  };

  const stepNumber = isAccelerateDeployment(deploymentType) ? '7.1' : '8.1';
  const dockerStepNumber = isAccelerateDeployment(deploymentType)
    ? '5.1'
    : '6.1';

  let defaultOption = 'Select Storage';

  if (isUpdate && selectedInstanceStorage?.persistentStorage) {
    defaultOption = getPersistentStorageName(
      selectedInstanceStorage.persistentStorage?.class || ''
    );
  } else if (persistentStorageTemplate && hasPersistentStorage && !isUpdate) {
    const storageName = getPersistentStorageName(
      persistentStorageTemplate.class || ''
    );
    const disableOption = storageOptions.find(
      (option) => storageName === option.name
    );
    if (!disableOption?.disable) {
      defaultOption = storageName;
    }
  }
  return (
    <div className="mt-14">
      <span
        className="text-base-heading-text-color dark:text-dark-base-heading-text-color
        text-xl font-bold"
      >
        {!isUpdate
          ? `${
              clusterType === ClusterType.TEMPLATE
                ? stepNumber
                : dockerStepNumber
            } `
          : ''}
        Select Persistent Storage
      </span>
      <p className="text-text-darkGray text-sm font-normal leading-5 mt-2">
        The storage is persistent, will not be erased when instance is restarted
        or updated, only when it is closed.
      </p>
      <div className="flex ll:flex-row flex-col mt-4">
        <div>
          <div
            className="text-text-filterBadge text-sm font-medium ml-1
          flex items-center"
          >
            <span className="mr-1">Mount Point *</span>

            <Tooltip
              position="top"
              text="The location in a file system where a persistent volume is
                    attached and accessed."
              type="float"
              wrapText
            >
              <InfoIcon
                className="w-5 h-5 text-text-filterBadge transition
                               duration-300 ease-in-out dark:hover:text-gray-200 
                               hover:text-gray-900 ml-2"
              />
            </Tooltip>
          </div>
          <div className="ml-px w-320">
            <ComputeInput
              type="text"
              value={mountPointValue}
              onChange={(e) => handleMountInput(e)}
              placeholder="/etc/data"
              error={mountError}
              errorMessage="This is a required field"
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="ll:ml-8 ll:mt-0 mt-4 ml-0">
          <DropdownMenu
            isUpdate={isUpdate}
            label="Type of Storage *"
            options={storageOptions}
            value={CustomSelectOption.SELECTEDSTORAGE}
            widthClassName="w-320"
            defaultOption={defaultOption}
          />
        </div>
      </div>
      {updatePersistentStorage && (
        <>
          <div
            className={`mt-8 ${
              updatePersistentStorage
                ? 'pointer-events-auto'
                : 'pointer-events-none'
            }`}
          >
            {!storageLoading ||
            (!instancePlanLoading && defaultCustomPlanValue.length > 0) ? (
              <>
                <div
                  className={`grid gap-6 ${
                    isUpdate
                      ? 'grid-cols-2'
                      : 'md:grid-cols-2 grid-cols-1 xl:grid-cols-3'
                  }`}
                >
                  {selectedValue?.values?.map((data: any, index: number) => (
                    <div key={data.value}>
                      <TemplateCard
                        title={`Storage ${data.value} GB`}
                        imageShow={false}
                        image=""
                        badgeContent=""
                        subtitle={`${data.price}/${
                          isAccelerateDeployment(deploymentType)
                            ? 'hour'
                            : 'month'
                        }`}
                        isSelected={selectedCard === index}
                        onSelect={() => handleCardClick(index)}
                        isSelectedTemplate={false}
                      />
                    </div>
                  ))}
                </div>{' '}
                <div
                  className={`mt-8 ${
                    updatePersistentStorage
                      ? 'pointer-events-auto'
                      : 'pointer-events-none'
                  }`}
                >
                  <div
                    className={`grid md:grid-cols-2 grid-cols-1 ${
                      isUpdate ? 'xl:grid-cols-2' : 'xl:grid-cols-3'
                    } gap-6`}
                  >
                    <CustomStorage
                      isUpdate={isUpdate}
                      isSelected={selectedCard === StorageType.PERSISTENT}
                      value={CustomStorageOption.CUSTOMPERSISTENTSTORAGE}
                      onSelect={() => setSelectedCard(StorageType.PERSISTENT)}
                    />
                  </div>
                </div>{' '}
              </>
            ) : (
              <div
                className={`grid  gap-6 mt-5
        mr-8  ${
          isUpdate ? 'grid-cols-2' : 'md:grid-cols-2 grid-cols-1 xl:grid-cols-3'
        }`}
              >
                {Array.from(Array(6).keys()).map((item) => (
                  <StorageCardLoading key={item} />
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default SelectPersistentStorage;
