import config from '../../config';

export const truncateText = (
  text: string,
  firstHalfLength?: number,
  lastHalfLength?: number
) => {
  if (text?.length <= 20) {
    return text;
  }
  return `${text?.slice(0, firstHalfLength || 20)}....${text?.slice(
    -(lastHalfLength || 4)
  )}`;
};

export const getUploadCid = (protocolLink: string) => {
  const regexPattern = `https?://?(?:([^*]{59}))?.ipfs.${config.ipfs.GATEWAY_URL}`;
  const sep = new RegExp(regexPattern);
  const cid = protocolLink.split(sep)[1];
  return cid;
};

export const toggleListLoading = (
  list: string[],
  payload: {
    loading: boolean;
    domainId: string;
  }
) => {
  return payload.loading === true
    ? [...list, payload.domainId]
    : list.filter((d) => d !== payload.domainId);
};

export const filterBasedOnSearch = (
  value: string,
  domainDropwownOptions: { label: string; value: string }[]
) => {
  return domainDropwownOptions?.filter((element) =>
    element?.value?.toLowerCase().includes(value)
  );
};
