import { requestPipeline } from '../root-utils';
import {
  IAddEnvPayloadDto,
  IAddUpdateDeleteProjectDeploymentEnvDtoPayload,
  ICdnRecordsResponse,
  ICreateProjectDeploymentEnvResponse,
  IDomainDtoPayload,
  IDomainResponse,
  IGetAllProjectsResponse,
  IProjectCountResponse,
  IProjectDeploymentEnvResponse,
  IProjectResponse,
  IProjectStatusDeploymentEnvDtoPayload,
  IRemoveEnvPayloadDto,
  IUpdateEnvironmentVariableResponse,
  IUpdateEnvPayloadDto,
} from './project.interfaces';
import { IResponseError } from '../combined-reducer.interface';
import {
  IConfiguration,
  IDeploymentEnvironment,
} from '../combined-state.interface';

export const getAllProjects = async (
  id: string,
  skip: number,
  limit: number,
  type: string
): Promise<IGetAllProjectsResponse | IResponseError> => {
  return requestPipeline({
    url: `organization/${id}/projects?state=${type}&skip=${skip}&limit=${limit}`,
    method: 'GET',
  });
};

export const getTotalMaintainedProjectsService = async (
  id: string,
  type: string
): Promise<IProjectCountResponse | IResponseError> => {
  return requestPipeline({
    url: `organization/${id}/projects/count?state=${type}`,
    method: 'GET',
  });
};

export const getSelectedProject = async (
  id: string
): Promise<Partial<IProjectResponse> | IResponseError> => {
  return requestPipeline({
    url: `project/${id}`,
    method: 'GET',
    isPublic: true,
  });
};

export const postDomain = async (
  payload: Partial<IDomainDtoPayload>
): Promise<IDomainResponse | IResponseError> => {
  return requestPipeline({
    url: `project/${payload.projectId}/domains`,
    method: 'POST',
    body: JSON.stringify(payload),
  });
};

export const confirmDomain = async (
  id: string,
  payload: Partial<IDomainDtoPayload>
): Promise<IDomainResponse> => {
  return requestPipeline({
    url: `project/${payload.projectId}/domains/${id}/verify`,
    method: 'PATCH',
    body: JSON.stringify(payload),
  });
};

export const reviseDomain = async (
  domainDetails: Partial<IDomainDtoPayload>
): Promise<Partial<IDomainResponse>> => {
  return requestPipeline({
    url: `project/${domainDetails.projectId}/domains/${domainDetails.domainId}`,
    method: 'PATCH',
    body: JSON.stringify(domainDetails),
  });
};

export const removeDomain = async (
  domainDetails: Partial<IDomainDtoPayload>
): Promise<Partial<IDomainResponse>> => {
  return requestPipeline({
    url: `project/${domainDetails.projectId}/domains/${domainDetails.domainId}`,
    method: 'DELETE',
    body: JSON.stringify(domainDetails),
  });
};

export const fetchCdnRecords = async (
  projectId: string
): Promise<ICdnRecordsResponse> => {
  return requestPipeline({
    url: `project/${projectId}/domains/cdn-records`,
    method: 'GET',
  });
};

export const addProjectEnv = async (
  payload: IAddEnvPayloadDto
): Promise<IDeploymentEnvironment | IResponseError> => {
  return requestPipeline({
    url: `project/${payload.projectId}/environment-variables`,
    method: 'POST',
    body: JSON.stringify({
      environmentVariables: payload.environmentVariables,
    }),
  });
};

export const updateProjectEnv = async (
  payload: IUpdateEnvPayloadDto
): Promise<Partial<IUpdateEnvironmentVariableResponse> | IResponseError> => {
  return requestPipeline({
    url: `project/${payload.projectId}/environment-variables/${payload.envId}`,
    method: 'PUT',
    body: JSON.stringify(payload.updateEnvBody),
  });
};

export const removeProjectEnv = async (
  payload: IRemoveEnvPayloadDto
): Promise<Partial<IResponseError>> => {
  return requestPipeline({
    url: `project/${payload.projectId}/environment-variables/${payload.envId}`,
    method: 'DELETE',
  });
};

export const createConfiguration = async (configuration: any): Promise<any> => {
  return requestPipeline({
    url: `configuration`,
    method: 'POST',
    body: JSON.stringify(configuration),
  });
};

export const connectWebhook = async (webhook: any): Promise<any> => {
  return requestPipeline({
    url: `webhook/connect`,
    method: 'POST',
    body: JSON.stringify(webhook),
  });
};

export const createWebhook = async (webhook: any): Promise<any> => {
  return requestPipeline({
    url: `webhook/create`,
    method: 'POST',
    body: JSON.stringify(webhook),
  });
};

export const editWebhook = async (
  id: string,
  webhookDetails: any
): Promise<any> => {
  return requestPipeline({
    url: `webhook/${id}`,
    method: 'PUT',
    body: JSON.stringify(webhookDetails),
  });
};

export const removeWebhook = async (id: any, body: any): Promise<any> => {
  return requestPipeline({
    url: `webhook/${id}`,
    method: 'DELETE',
    body: JSON.stringify(body),
  });
};

export const archiveProject = async (payload: any): Promise<any> => {
  return requestPipeline({
    url: `project/changeStateToArchived/${payload.id}`,
    method: 'DELETE',
    body: JSON.stringify(payload.body),
  });
};

export const maintainProject = async (payload: any): Promise<any> => {
  return requestPipeline({
    url: `project/changeStateToMaintained/${payload.id}`,
    method: 'PUT',
    body: JSON.stringify(payload.body),
  });
};

export const getArchivedProjectService = async (
  id: string,
  skip: number,
  limit: number
): Promise<any> => {
  return requestPipeline({
    url: `organization/${id}/projects/archived/pagination?skip=${skip}&limit=${limit}`,
    method: 'GET',
  });
};

export const updateBuildConfigService = async (
  projectId: string,
  configuration: IConfiguration
): Promise<IConfiguration | IResponseError> => {
  return requestPipeline({
    url: `project/${projectId}/configuration`,
    method: 'PUT',
    body: JSON.stringify(configuration),
  });
};

export const createDeploymentEnvService = async (
  payload: Partial<IAddUpdateDeleteProjectDeploymentEnvDtoPayload>
): Promise<ICreateProjectDeploymentEnvResponse | IResponseError> => {
  return requestPipeline({
    url: `project/${payload.projectId}/deployment-environments`,
    method: 'POST',
    body: JSON.stringify(payload.config),
  });
};

export const updateDeploymentEnvService = async (
  payload: IAddUpdateDeleteProjectDeploymentEnvDtoPayload
): Promise<IProjectDeploymentEnvResponse | IResponseError> => {
  return requestPipeline({
    url: `project/${payload.projectId}/deployment-environments/${payload.id}`,
    method: 'PUT',
    body: JSON.stringify(payload.config),
  });
};

export const deleteDeploymentEnvService = async (
  payload: Partial<IAddUpdateDeleteProjectDeploymentEnvDtoPayload>
): Promise<Partial<IResponseError>> => {
  return requestPipeline({
    url: `project/${payload.projectId}/deployment-environments/${payload.id}`,
    method: 'DELETE',
  });
};

export const deactivateDeploymentEnvService = async (
  payload: IProjectStatusDeploymentEnvDtoPayload
): Promise<IProjectDeploymentEnvResponse | IResponseError> => {
  return requestPipeline({
    url: `project/${payload.projectId}/deployment-environments/${payload.id}/deactivate`,
    method: 'PATCH',
  });
};

export const activateDeploymentEnvService = async (
  payload: IProjectStatusDeploymentEnvDtoPayload
): Promise<IProjectDeploymentEnvResponse | IResponseError> => {
  return requestPipeline({
    url: `project/${payload.projectId}/deployment-environments/${payload.id}/activate`,
    method: 'PATCH',
  });
};

export const updateProjectStatusService = async (
  payload: {
    id: string;
  },
  state: string
): Promise<Partial<IResponseError>> => {
  return requestPipeline({
    url: `project/${payload.id}/state`,
    method: 'PATCH',
    body: JSON.stringify({
      state,
    }),
  });
};

export const deleteProjectService = async (id: string): Promise<any> => {
  return requestPipeline({
    url: `project/${id}`,
    method: 'DELETE',
  });
};

export const saveProjectSecurity = async (payload: any): Promise<any> => {
  return requestPipeline({
    url: `project/${payload.projectId}/credentials`,
    method: 'POST',
    body: JSON.stringify(payload.body),
  });
};

export const removeProjectSecurity = async (payload: any): Promise<any> => {
  return requestPipeline({
    url: `project/${payload.projectId}/credentials`,
    method: 'DELETE',
  });
};
