import React, { useState, useEffect } from 'react';
import { ConnectButton, useConnectModal } from '@rainbow-me/rainbowkit';
import { useWalletClient, useAccount, useNetwork } from 'wagmi';
import { mainnet } from 'wagmi/chains';
import { ReactComponent as RightArrow } from '@spheron/ui-library/dist/assets/arrow-right-1.svg';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import LensDarkIcon from '../../../assets/compute/icons/lens-dark.svg';
import LensIcon from '../../../assets/compute/icons/lens-icon.svg';
import EthereumIcon from '../../../assets/compute/icons/ethereum-icon.svg';
import PhantomIcon from '../../../assets/compute/icons/web3/phantom.svg';
import { authenticateWithWeb3 } from '../../../redux/compute/web3/web3.thunks';
import { isDarkTheme } from '../../../redux/compute/theme/theme.utils';

interface IProps {
  className?: string;
  provider?: string;
  text?: string;
  type: string;
  disable: boolean;
}

const CustomConnectButton = ({
  className,
  provider,
  text,
  type,
  disable,
}: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();

  const theme = useSelector((state: RootState) => state.theme.theme);

  const [isAccount, setIsAccount] = useState<string>('');
  const [showConnectModal, setShowConnectModal] = useState<boolean>(false);
  const { data: walletClient } = useWalletClient();
  const { isConnected, address } = useAccount();
  const { openConnectModal } = useConnectModal();
  const { chain } = useNetwork();
  const signUpWithProvider = async () => {
    const chainId = chain?.id;
    dispatchRtk(
      authenticateWithWeb3({
        isAccount,
        walletClient,
        mainnet,
        type: type.toUpperCase(),
        chainId,
      })
    );
  };

  useEffect(() => {
    if (isConnected && showConnectModal && address) {
      setIsAccount(address);
      if (isAccount !== '' && typeof walletClient !== 'undefined') {
        signUpWithProvider();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, showConnectModal, address, isAccount, walletClient]);

  const providerIcon = (provider: string | undefined) => {
    switch (provider) {
      case 'metamask':
        return EthereumIcon;
      case 'phantom':
        return PhantomIcon;
      case 'lens':
        return isDarkTheme(theme) ? LensDarkIcon : LensIcon;
      default:
        return undefined;
    }
  };

  const openConnectModalByDefault = () => {
    setShowConnectModal(true);
    if (openConnectModal) {
      openConnectModal();
    }
  };

  return (
    <ConnectButton.Custom>
      {() => {
        return (
          <>
            {(() => {
              return (
                <button
                  onClick={() => openConnectModalByDefault()}
                  type="button"
                  disabled={disable}
                  className={`border border-base-border dark:border-dark-base-border flex 
                  font-medium items-center relative rounded-md py-3 min-w-370 transition 
                  justify-between w-[400px] h-14
                        ${className || ''} ${
                    disable
                      ? 'cursor-not-allowed '
                      : 'cursor-pointer hover:bg-base-fg hover:dark:bg-dark-base-fg'
                  }`}
                >
                  <div className="flex items-center ml-4">
                    <img
                      className="w-7 h-[29px]"
                      src={providerIcon(provider)}
                      alt="web3-icons"
                    />
                    <span
                      className="ml-5 text-base font-semibold text-para-text-color 
                    dark:text-dark-para-text-color"
                    >
                      {text}
                    </span>
                  </div>
                  {!disable && (
                    <RightArrow className="mr-4 text-light-text-color dark:text-dark-light-text-color w-6 h-6" />
                  )}

                  {disable && (
                    <span
                      className="bg-feedback-default-bg dark:bg-dark-feedback-default-bg 
                      text-feedback-default-text dark:text-dark-feedback-default-text 
                      font-medium text-xs px-1 py-0.5 rounded 
                      absolute right-2 flex justify-center"
                    >
                      Coming Soon
                    </span>
                  )}
                </button>
              );
            })()}
          </>
        );
      }}
    </ConnectButton.Custom>
  );
};

CustomConnectButton.defaultProps = {
  className: '',
  provider: '',
  text: 'Sign up',
};

export default CustomConnectButton;
