import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import {
  ActivityCard,
  ActivityStickyCard,
  ActivityStickyCardStatus,
} from '@spheron/ui-library';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import InstanceLogCard from '../../../../components/Compute/Cards/instance-log-card';
import { LeftIcon } from '../../../../components/Compute/Icons';
import { RootState } from '../../../../redux/compute/store';
import { IActivityDetails } from '../../../../redux/compute/instance/instance.interfaces';
import { ReactComponent as DockerCredentialIcon } from '../../../../assets/compute/icons/docker-credential-icon.svg';
import { sliceStringFromEnd } from '../../../../redux/compute/subscription/subscription.utils';

const InstanceActivityDetails = () => {
  const navigate = useNavigate();
  const params = useParams<{
    projectId: string;
    instanceId: string;
    deploymentId: string;
    serviceName: string;
  }>();

  const selectedInstanceActivity: IActivityDetails[] = useSelector(
    (state: RootState) => state.instance.selectedInstanceActivity
  );
  const activeDeployment = useSelector(
    (state: RootState) => state.instance.activeDeployment
  );
  const handleBack = useCallback(() => {
    navigate(`/compute/${params.projectId}/${params.instanceId}/activity`);
  }, [navigate, params.instanceId, params.projectId]);

  const selectedActivity = selectedInstanceActivity?.find(
    (activity) => activity._id === params.deploymentId
  );
  const service = selectedActivity?.services.find(
    (service) => service.name === params.serviceName
  );

  useEffect(() => {
    if (!selectedActivity) {
      handleBack();
    }
  }, [handleBack, selectedActivity]);

  const iconsSet = new Set<string>();
  selectedActivity?.services.forEach((service) => {
    if (
      activeDeployment?.services.find(
        (activeService) =>
          activeService.name?.toLowerCase() === service.name?.toLowerCase()
      )?.template
    ) {
      iconsSet.add(
        activeDeployment?.services.find(
          (activeService) =>
            activeService.name?.toLowerCase() === service.name?.toLowerCase()
        )?.template?.metadata.icon || ''
      );
    } else {
      iconsSet.add('docker');
    }
  });

  return (
    <div>
      <div className="flex flex-col gap-y-5">
        <div
          className="font-medium cursor-pointer flex flex-row gap-x-2"
          role="presentation"
          onClick={handleBack}
        >
          <LeftIcon className="text-base-icon dark:text-dark-base-icon h-6 w-6 mt-0.5" />
          <span
            className="text-base-heading-text-color
           dark:text-dark-base-heading-text-color text-5 leading-6"
          >
            All Activity
          </span>
        </div>
        <div className="flex flex-col gap-y-4 relative">
          <div
            className="w-[1.5px] bg-base-border dark:bg-dark-base-border
          absolute mt-4.5 left-0 transform translate-x-[5px]"
            style={{ height: 'calc(100% - 36px)' }}
          />
          {selectedActivity && (
            <>
              <Disclosure defaultOpen>
                {({ open }) => (
                  <>
                    <div className="flex items-center justify-start gap-x-3">
                      <span
                        className={`sticky z-30 ${
                          open
                            ? 'bg-action-neutral-default dark:bg-dark-action-neutral-default'
                            : 'bg-base-bg dark:bg-dark-base-border'
                        }
                     border-2 bg-base-bg border-base-border 
                      dark:border-dark-base-border w-3 h-3 flex rounded-xl`}
                      />

                      <Disclosure.Button
                        className="w-full"
                        onClick={() => handleBack()}
                      >
                        <ActivityStickyCard
                          status={
                            selectedActivity!.status as ActivityStickyCardStatus
                          }
                          timestamp={dayjs(
                            String(selectedActivity!.updatedAt)
                          ).format('DD MMM YYYY [at] hh:mm')}
                          services={selectedActivity!.services.map(
                            (service) => service.name
                          )}
                          isOpen={open}
                          onClick={() => {}}
                          icons={Array.from(iconsSet).map((icon) => {
                            if (icon !== 'docker') {
                              return (
                                <img
                                  src={icon}
                                  alt="service"
                                  className="max-h-6 max-w-6 rounded-full"
                                />
                              );
                            }
                            return (
                              <DockerCredentialIcon className="rounded-full" />
                            );
                          })}
                          id={''}
                        />
                      </Disclosure.Button>
                    </div>

                    <Disclosure.Panel
                      className="ml-1.5"
                      onClick={() => handleBack()}
                    >
                      <div className="flex items-center justify-start gap-x-3 ">
                        <div
                          className="w-6 h-0.5 bg-base-border
                       dark:bg-dark-base-border rounded-full"
                        />
                        <ActivityCard
                          name={service?.name || ''}
                          imageTag={service?.tag || ''}
                          cpu={Number(service?.agreedMachineImage.cpu)}
                          ram={Number(
                            sliceStringFromEnd(
                              service?.agreedMachineImage.storage || '',
                              2
                            )
                          )}
                          storage={Number(
                            sliceStringFromEnd(
                              service?.agreedMachineImage.storage || '',
                              2
                            )
                          )}
                          replica={1}
                          onClick={() => {}}
                          id={''}
                        />
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </>
          )}
        </div>
      </div>
      {selectedActivity && (
        <div className="mt-8">
          <InstanceLogCard deploymentId={selectedActivity?._id} />
        </div>
      )}
    </div>
  );
};

export default InstanceActivityDetails;
