import {
  EventSourceMessage,
  EventStreamContentType,
  fetchEventSource,
} from './fetchEventSource';

import config from '../../config';

interface ISseArgs {
  msgCb: Function;
  errCb?: Function;
  closeCb?: Function;
  apiPath?: string;
  sessionId?: string;
  signal?: AbortSignal;
}

export const callSSE = ({
  msgCb,
  errCb,
  closeCb,
  apiPath = '',
  sessionId = '',
  signal,
}: ISseArgs) => {
  fetchEventSource(
    `${config.urls.API_SSE_URL}${apiPath}${
      sessionId ? `?sessionId=${sessionId}` : ''
    }`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt-token')}`,
      },
      signal,
      async onopen(response: Response) {
        if (
          response.ok &&
          response.headers.get('content-type') === EventStreamContentType
        ) {
          // eslint-disable-next-line no-useless-return
          return;
        }
      },
      onmessage(msg: EventSourceMessage) {
        // eslint-disable-next-line no-console
        console.log('SSE Logs -> ', JSON.parse(msg?.data));
        msgCb?.(msg);
      },
      onclose() {
        // eslint-disable-next-line no-console
        console.log('SSE connection closed');
        closeCb?.();
      },
      onerror(err: any) {
        // eslint-disable-next-line no-console
        console.log('SSE error ', err);
        errCb?.(err);
      },
    }
  );
};
