import React, { useState } from 'react';
import { Modal, TextInput } from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Styles from '../../../styles/compute/modal.module.scss';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import ModalWrapper from './ModalWrapper';
import { createNewProjectThunk } from '../../../redux/compute/project/project.thunks';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import { ICreateNewProjectResponseDto } from '../../../redux/compute/project/project.interfaces';

interface IProps {
  isOpen: boolean;
  // eslint-disable-next-line no-unused-vars
  followUpFn?: (res: any) => void;
}

const CreateNewComputeProject = ({ isOpen, followUpFn }: IProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { orgUsername } = useParams<{ orgUsername: string }>();
  const organizationId = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation._id
  );
  const createNewProjectLoading = useSelector(
    (state: RootState) => state.computeProject.createProjectLoading
  );
  const [projectTitle, setProjectTitle] = useState<string>('');
  const [projectDescription, setProjectDescription] = useState<string>('');

  const closeModal = () => {
    dispatch(toggleModalShowRtk({ modalShow: false }));
  };

  const createNewProject = () => {
    if (followUpFn) {
      dispatch(
        createNewProjectThunk({
          organizationId,
          name: projectTitle,
          provider: '',
          description: projectDescription,
        })
      ).then((res) => followUpFn(res));
    } else {
      dispatch(
        createNewProjectThunk({
          organizationId,
          name: projectTitle,
          provider: '',
          description: projectDescription,
        })
      ).then((response) => {
        navigate(
          `/${orgUsername}/${
            (response.payload as unknown as ICreateNewProjectResponseDto)._id
          }/new-instance`
        );
      });
    }
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={closeModal}
      classname={`${Styles.modal__outer__con} w-430 modal fixed inset-0 overflow-y-auto`}
    >
      <div className={Styles.modal__inner__content}>
        <Modal
          footer
          header
          subtitle=""
          modalContent={
            <div className="flex flex-col gap-y-6 w-full mb-6">
              <TextInput
                label="Project Title"
                placeholder="Enter project title"
                value={projectTitle}
                onChange={(value) => setProjectTitle(value as string)}
              />
              <TextInput
                label="Project Description"
                placeholder="Enter project description"
                value={projectDescription}
                onChange={(value) => setProjectDescription(value as string)}
              />
            </div>
          }
          headerContent={<>Create New Project</>}
          primaryButtonContent="Create"
          secondaryButtonContent="Cancel"
          primaryButton
          primaryButtonDisabled={createNewProjectLoading}
          primaryButtonLoading={createNewProjectLoading}
          onPrimaryButtonClick={createNewProject}
          onSecondaryButtonClick={closeModal}
          secondaryButton
        />
      </div>
    </ModalWrapper>
  );
};

CreateNewComputeProject.defaultProps = {
  followUpFn: null,
};

export default CreateNewComputeProject;
