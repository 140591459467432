import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { BroadcastChannel } from 'broadcast-channel';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../../components/Compute/Loaders/loader';
import { isDarkTheme } from '../../../../redux/theme/theme.utils';
import { getUserDetailsThunk } from '../../../../redux/user/user.thunks';
import { RootState } from '../../../../redux/rtk-store';
import { AppDispatch } from '../../../../redux/compute/store';

const ProviderCallback = () => {
  const location = useLocation();
  const { provider } = useParams<{ provider: string }>();
  const dispatchRtk = useDispatch<AppDispatch>();

  const theme = useSelector((state: RootState) => state.theme.theme);
  const user = useSelector((state: RootState) => state.user.user);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    const error = query.get('error');
    const bc = new BroadcastChannel('signin_channel');
    if (error) {
      bc.postMessage(error);
      window.close();
    } else {
      localStorage.setItem('jwt-token', token || '');
      if (localStorage.getItem('clone')) {
        dispatchRtk(getUserDetailsThunk(''));
      } else {
        bc.postMessage('signedup');
        window.close();
      }
    }

    return () => {
      bc.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user && provider) {
      const providerSignInUrl = `${window.location.origin}/${provider}/auth/${user?._id}`;
      window.open(providerSignInUrl, '_self');
    }
  }, [provider, user]);

  return (
    <div
      className={`h-screen w-screen ${
        isDarkTheme(theme) && 'bg-feedback-default-bg'
      }`}
    >
      <Loader />
    </div>
  );
};

export default ProviderCallback;
