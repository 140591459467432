import { ConfirmationModal, Modal } from '@spheron/ui-library';
import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Styles from '../../../styles/compute/modal.module.scss';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import ModalWrapper from './ModalWrapper';
import { AppDispatch, RootState } from '../../../redux/compute/store';

interface IProps {
  isOpen: boolean;
  handleProceed: () => void;
}

export const BulkAction = ({ isOpen, handleProceed }: IProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const removeAllInstanceLoading = useSelector(
    (state: RootState) => state.instance.removeMultipleInstancesLoading
  );
  const closeAllInstanceLoading = useSelector(
    (state: RootState) => state.instance.closeMultipleInstanceLoading
  );

  const loading = removeAllInstanceLoading || closeAllInstanceLoading;
  const handleCloseModal = () => {
    dispatch(toggleModalShowRtk({ modalShow: false }));
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={handleCloseModal}
      classname={`${Styles.modal__outer__con} w-430 modal fixed inset-0 overflow-y-auto`}
    >
      <div className={Styles.modal__inner__content}>
        <Modal
          footer
          header
          subtitle=""
          modalContent={
            <span className="font-normal text-sm leading-5">
              You are about to perform a bulk action. Please be sure that you
              are willing to perform this action.
            </span>
          }
          headerContent={<>Performing bulk action</>}
          primaryButtonContent="Proceed"
          secondaryButtonContent="Cancel"
          primaryButton
          primaryButtonLoading={loading}
          secondaryButtonDisabled={false}
          secondaryButton
          onPrimaryButtonClick={handleProceed}
          onSecondaryButtonClick={handleCloseModal}
          confirmationStatus={ConfirmationModal.ERROR}
        />
      </div>
    </ModalWrapper>
  );
};
