/* eslint-disable import/no-cycle */
import dayjs from 'dayjs';
import { InstanceCardScalingType } from '@spheron/ui-library';
import {
  HealthCheckStatus,
  IPorts,
  InstanceState,
} from '../cluster/cluster.interfaces';
import {
  IInstanceDeployLogs,
  IService,
  InstanceStatus,
} from './instance.interfaces';
import { IInstance } from '../compute/project/project.interfaces';
import { IUser } from '../combined-state.interface';
import { getUserRole } from '../organisation/organisation.utils';

export const mapInstanceState = (state: string) => {
  switch (state) {
    case InstanceState.STARTING:
      return 'Starting';

    case InstanceState.FAILED_START:
      return 'Failed';

    case InstanceState.ACTIVE:
      return 'Active';

    case InstanceState.CLOSED:
      return 'Closed';

    default:
      return '';
  }
};

export const mapInstanceStatusToEnum = (status: string) => {
  switch (status) {
    case 'queued':
      return InstanceStatus.QUEUED;
    case 'deprecated':
      return InstanceStatus.DEPRECATED;
    case 'deployed':
      return InstanceStatus.DEPLOYED;
    case 'deprecated-provider':
      return InstanceStatus.DEPRECATED_PROVIDER;
    case 'pending':
      return InstanceStatus.PENDING;
    case 'failed':
      return InstanceStatus.FAILED;
    default:
      return InstanceStatus.PENDING;
  }
};

export const setInstanceStreamLog = (
  logs: {
    log: string[];
    time: string;
    _id: string;
  }[]
) => {
  const mappedLogs: IInstanceDeployLogs[] = [];
  logs[0]?.log?.forEach((log: string) => {
    mappedLogs.push({
      log,
      time: dayjs().format('HH:mm:ss'),
      id: logs[0]?._id,
    });
  });
  return mappedLogs;
};

export const setInstanceDeployLog = (log: string[]) => {
  const mappedLogs: IInstanceDeployLogs[] = [];
  log?.forEach((log: string) => {
    mappedLogs.push({
      log,
      time: null,
    });
  });
  return mappedLogs;
};

export const setInstanceStreamData = (
  prevLogs: IInstanceDeployLogs[],
  data: string,
  topicId: string
): IInstanceDeployLogs[] => {
  const logs: IInstanceDeployLogs[] = [...prevLogs];
  data.split('\n').forEach((line: string) => {
    // check for any empty lines in the logs
    if (line.trim()) {
      // check for any duplicate log present in the logs
      if (logs.map((l) => l.log).indexOf(line.trim()) === -1) {
        logs.push({
          log: line,
          time: dayjs().format('hh:mm:ss A'),
          id: topicId,
        });
      }
    }
  });
  return logs;
};

export const setInstanceProcessedData = (
  data: IInstanceDeployLogs[],
  topicId: string
): IInstanceDeployLogs[] => {
  const processedLogs: IInstanceDeployLogs[] = [];
  data.forEach((logItem: IInstanceDeployLogs) => {
    if (typeof logItem?.log === 'string')
      logItem.log.split('\n').forEach((line: string) => {
        if (line.trim()) {
          processedLogs.push({
            log: line,
            time: dayjs(logItem.time).format('hh:mm:ss A'),
            id: topicId,
          });
        }
      });
  });
  return processedLogs;
};

export const getHealthCheckStatus = (status: HealthCheckStatus): string => {
  switch (status.toLowerCase()) {
    case HealthCheckStatus.ACTIVE:
      return 'Healthy';
    case HealthCheckStatus.UNKNOWN:
      return 'Unknown';
    case HealthCheckStatus.INACTIVE:
      return 'Unhealthy';
    default:
      return 'Unhealthy';
  }
};

export const isGpu = (imageName: string): boolean =>
  imageName.toLowerCase().includes('nvidia');

export const getScalingType = (service: IService, instance: IInstance) => {
  if (service?.autoscalingRules) {
    return InstanceCardScalingType.AUTO;
  }
  if (instance.scalable) {
    return InstanceCardScalingType.ONDEMAND;
  }
  return InstanceCardScalingType.SPOT;
};

export const mapPortToConfig = (portMapping: IPorts[]) => {
  return portMapping
    .map((item) => {
      return {
        containerPort: item.containerPort,
        exposedPort:
          item.exposedPort === 'random' ? item.containerPort : item.exposedPort,
        ...(item.isChecked && { endpoint: 'endpoint' }),
        ...(item.endpoint && item.isChecked && { endpoint: item.endpoint }),
      };
    })
    .filter((item) => item.containerPort !== '' && item.exposedPort !== '');
};

export const mapBuildEnv = (
  buildEnv: { key: string; value: string; isSecret: boolean }[]
) => {
  const buildEnvObj: {
    value: string;
    isSecret: boolean;
  }[] = [];
  buildEnv.forEach((env) => {
    buildEnvObj.push({
      isSecret: env.isSecret,
      value: `${env.key}=${env.value.trim()}`,
    });
  });
  return buildEnvObj;
};

export const canDeleteInstance = (
  currentUser: IUser,
  selectedOrganisationMembers: IUser[],
  status: string,
  instanceLoading: boolean
): boolean => {
  if (currentUser)
    return (
      (getUserRole(selectedOrganisationMembers, currentUser as IUser) ===
        'admin' ||
        getUserRole(selectedOrganisationMembers, currentUser as IUser) ===
          'owner') &&
      !instanceLoading &&
      status?.toLowerCase() !== 'pending' &&
      status?.toLowerCase() !== 'queued' &&
      status?.toLowerCase() !== 'deployed' &&
      status?.toLowerCase() !== 'active' &&
      status?.toLowerCase() !== 'provisioned' &&
      status?.toLowerCase() !== 'starting'
    );
  return false;
};
