import * as Sentry from '@sentry/react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createGatewayService,
  deleteGatewayService,
  getGatewaysService,
} from './gateway.services';
import { IGatewayDetails } from './gateway.interfaces';
import { toggleModalShowRtk } from '../modal/modal.slice';
import { addNotificationRtk } from '../notification/notification.slice';
import { NotificationType } from '../notification/notification.interfaces';

export const createGatewayThunk = createAsyncThunk(
  'gateway/createGateway',
  async (
    payload: IGatewayDetails,
    { dispatch, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const res = await createGatewayService(payload);
      if (res.error) {
        dispatch(
          addNotificationRtk({
            message: (res as Error).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      dispatch(
        toggleModalShowRtk({ modalShow: true, modalType: 'successGateway' })
      );
      return fulfillWithValue(res.domain);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error is createGatewayGen -> ', error);
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getGatewaysThunk = createAsyncThunk(
  'gateway/getGateways',
  async (payload: string, { fulfillWithValue, rejectWithValue }) => {
    try {
      const res = await getGatewaysService(payload);
      if (res.error) {
        return rejectWithValue({});
      }
      return fulfillWithValue(res.domains);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error is getGatewaysGen -> ', error);
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const removeGatewayThunk = createAsyncThunk(
  'gateway/removeGateway',
  async (payload: any, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const res = await deleteGatewayService(payload);
      if (res.error) {
        dispatch(
          addNotificationRtk({
            message: (res as Error).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      dispatch(toggleModalShowRtk({ modalShow: false }));
      return fulfillWithValue(payload.id);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error is deleteGatewayGen -> ', error);
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);
