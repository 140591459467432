import React from 'react';
import Skeleton from 'react-loading-skeleton';

const ClusterTopupCardLoading = () => {
  return (
    <div
      className="flex flex-col justify-between items-center bg-bg-computeCard
      border border-base-border rounded-lg w-full h-48 p-6 cursor-not-allowed
      dark:border-dark-base-border"
    >
      <div className="w-full flex justify-between">
        <div className="w-1/2">
          <Skeleton height={30} duration={2} />
        </div>
        <div className="w-1/6">
          <Skeleton height={30} borderRadius={50} duration={2} />
        </div>
      </div>
      <div className="w-full">
        <Skeleton height={80} duration={2} />
      </div>
    </div>
  );
};

export default ClusterTopupCardLoading;
