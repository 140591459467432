import React from 'react';
import { ConfirmationModal, Modal } from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import Styles from '../../../styles/compute/modal.module.scss';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import ModalWrapper from './ModalWrapper';
import { IRequestForRestartInstance } from '../../../redux/compute/instance/instance.interfaces';
import { restartInstanceThunk } from '../../../redux/compute/instance/instance.thunks';

interface IProps {
  isOpen: boolean;
  instanceConfig: IRequestForRestartInstance;
}

const RestartInstance = ({ isOpen, instanceConfig }: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();
  const instanceDeploymentLoading: boolean = useSelector(
    (state: RootState) => state.instance.deployingInstance
  );

  const handleRestart = () => {
    dispatchRtk(restartInstanceThunk(instanceConfig));
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={() => dispatchRtk(toggleModalShowRtk({ modalShow: false }))}
      classname={`${Styles.modal__outer__con} w-430 modal fixed inset-0 overflow-y-auto`}
    >
      <div className={Styles.modal__inner__content}>
        <Modal
          footer
          header
          subtitle=""
          modalContent={
            <p className="text-sm font-medium">
              Restarting the instance configuration will initiate the creation
              of a new instance with your saved configurations. Are you sure you
              want to proceed with the restart?
            </p>
          }
          headerContent={<>Restarting Instance Configuration</>}
          primaryButtonContent="Restart"
          secondaryButtonContent="Cancel"
          primaryButton
          primaryButtonLoading={instanceDeploymentLoading}
          secondaryButtonDisabled={false}
          secondaryButton
          onPrimaryButtonClick={handleRestart}
          onSecondaryButtonClick={() => {
            dispatchRtk(toggleModalShowRtk({ modalShow: false }));
          }}
          confirmationStatus={ConfirmationModal.INFO}
        />
      </div>
    </ModalWrapper>
  );
};

export default RestartInstance;
