/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  Button,
  EmptyState,
  Link,
  SectionHeading,
  Table,
  TableCellProps,
} from '@spheron/ui-library';
import { ReactComponent as ExternalLinkIcon } from '@spheron/ui-library/dist/assets/spheron-specific/external-link.svg';
import { ReactComponent as OutlineAddIcon } from '@spheron/ui-library/dist/assets/spheron-specific/outline-add.svg';
import { ReactComponent as TrashIcon } from '@spheron/ui-library/dist/assets/trash-2.svg';
import { useDispatch, useSelector } from 'react-redux';
import { IAPIToken } from '../../../../redux/compute/user/user.interfaces';
import { toggleModalShowRtk } from '../../../../redux/compute/modal/modal.slice';
import { ICurrentApp } from '../../../../redux/compute/organisation/organisation.interfaces';
import { RootState } from '../../../../redux/compute/store';
import TableLoader from '../../../../components/Compute/Loaders/table-loader';

const UserSettingTokens = () => {
  const dispatchRtk = useDispatch();

  const tokens = useSelector((state: RootState) => state.user.tokens);
  const tokensLoading = useSelector(
    (state: RootState) => state.user.tokensLoading
  );

  const [selectedTokens, setSelectedTokens] = useState<IAPIToken[]>([]);
  const [count, setCount] = useState<number>(0);
  const [sortingType, setSortingType] = useState<string>('oldest');
  const [allTokens, setAllTokens] = useState<IAPIToken[]>([]);

  const handleAllChange = () => {
    let counter;

    if (selectedTokens.length === 0) {
      setSelectedTokens(tokens);
      counter = tokens.length;
    } else {
      setSelectedTokens([]);
      counter = 0;
    }
    setCount(counter);
  };

  const handleChange = (token: IAPIToken) => {
    let count = selectedTokens.length;

    if (selectedTokens.map((t) => t.id).includes(token.id)) {
      setSelectedTokens(
        selectedTokens.filter(
          (selectedToken: IAPIToken) => selectedToken.id !== token.id
        )
      );
      count -= 1;
    } else {
      setSelectedTokens([...selectedTokens, token]);
      count += 1;
    }
    setCount(count);
  };

  const handleSorting = () => {
    const newSortingType = sortingType === 'oldest' ? 'newest' : 'oldest';
    setSortingType(newSortingType);
    if (newSortingType === 'newest') {
      setAllTokens(
        [...allTokens].sort((a: IAPIToken, b: IAPIToken) => {
          return (
            new Date(b.expiresAt).getTime() - new Date(a.expiresAt).getTime()
          );
        })
      );
    } else if (newSortingType === 'oldest') {
      setAllTokens(
        [...allTokens].sort((a: IAPIToken, b: IAPIToken) => {
          return (
            new Date(a.expiresAt).getTime() - new Date(b.expiresAt).getTime()
          );
        })
      );
    }
  };

  const handleDelete = (tokens: IAPIToken[] | IAPIToken) => {
    dispatchRtk(
      toggleModalShowRtk({
        modalShow: true,
        modalType: 'deleteToken',
        options: {
          tokens,
        },
      })
    );
    setSelectedTokens([]);
    setCount(0);
  };

  useEffect(() => {
    setAllTokens(tokens);
  }, [tokens]);

  const showTokenType = (tokenType: string) => {
    switch (tokenType) {
      case ICurrentApp.APP:
        return 'Static Site';

      case ICurrentApp.COMPUTE:
        return 'Compute';

      case ICurrentApp.STORAGE:
        return 'Storage';

      default:
        return '';
    }
  };

  const checkCheckboxSelected = (id: string) => {
    return selectedTokens.map((t) => t.id).includes(id);
  };

  const tableHeaders: TableCellProps[] = [
    {
      id: 0,
      title: 'Name',
      hasCheckbox: true,
      isSelected:
        tokens?.length !== 0 && selectedTokens?.length === tokens?.length,
      onCheckboxClick: handleAllChange,
      tableCellType: 'header',
    },
    {
      id: 1,
      title: 'Type',
      tableCellType: 'header',
    },
    {
      id: 2,
      title: 'Scope',
      tableCellType: 'header',
    },
    {
      id: 3,
      title: 'Expiration',
      hasSort: true,
      onSortClick: handleSorting,
      tableCellType: 'header',
    },
    {
      id: 4,
      title: 'Action',
      tableCellType: 'header',
    },
  ];

  return (
    <div className="flex flex-col gap-3">
      <div className="mt-9 flex items-center justify-between">
        <div className="flex flex-col gap-x-2 items-start">
          <SectionHeading
            heading="Tokens"
            // eslint-disable-next-line max-len
            subHeading="These tokens allow other apps to control your organization and project. Do not expose the token anywhere."
            showRefresh={false}
            showSwitch={false}
            handleIconClick={() => {}}
            handleRefreshClick={() => {}}
            handleSwitchClick={() => {}}
            switchDisable={false}
            handleClick={() => {}}
            loading={false}
            time={''}
            refreshType={'default'}
            showText={false}
            toggleId={''}
            isChecked={false}
            handleChange={() => {}}
          />
          <Link
            type="primary"
            size="default"
            text="Learn more"
            isBold={false}
            onClick={() =>
              window.open(
                'https://docs.spheron.network/rest-api#creating-an-access-token',
                '_blank'
              )
            }
            rightIcon={
              <ExternalLinkIcon className="w-5 h-5 text-base-link dark:text-dark-base-link" />
            }
          />
        </div>

        <Button
          buttonType="primary"
          label="Create Token"
          size="medium"
          leftIcon={<OutlineAddIcon className="w-5 h-5 text-white" />}
          onClick={() => {
            dispatchRtk(
              toggleModalShowRtk({
                modalShow: true,
                modalType: 'createToken',
              })
            );
          }}
          disabled={tokensLoading}
        />
      </div>

      {tokens?.length === 0 && !tokensLoading ? (
        <div className="h-56">
          <EmptyState
            entityTitle="No Tokens"
            showButton={false}
            buttonDisabled={false}
            onClick={() => {}}
          />
        </div>
      ) : (
        <>
          <div className="flex items-center h-9">
            {!!count && (
              <div className="flex items-center gap-x-2">
                <span className="text-base-link dark:text-dark-base-link text-sm font-medium">
                  {count} Tokens Selected
                </span>
                <Button
                  buttonType="error"
                  leftIcon={<TrashIcon className="w-5 h-5 text-white" />}
                  size="small"
                  onClick={() => handleDelete(selectedTokens)}
                />
              </div>
            )}
          </div>
          {tokensLoading ? (
            <TableLoader
              headers={tableHeaders.map((header) => header.title || '')}
            />
          ) : (
            <Table
              tableHeader={{
                tableRowType: 'record',
                tableRow: tableHeaders,
              }}
              tableRows={allTokens.map((token, i: number) => ({
                id: i,
                tableRowType: 'record',
                tableRow: [
                  {
                    id: 0,
                    title: token.name,
                    hasCheckbox: true,
                    isSelected: checkCheckboxSelected(token.id),
                    onCheckboxClick: () => handleChange(token),
                    tableCellType: 'title',
                  },
                  {
                    id: 1,
                    title: showTokenType(token.scope.appType),
                    tableCellType: 'title',
                  },
                  {
                    id: 2,
                    title: token.scope.profile?.name,
                    tableCellType: 'title',
                  },
                  {
                    id: 3,
                    title:
                      token.expiresAt !== null
                        ? dayjs(`${token.expiresAt}`).format('MMMM DD, YYYY')
                        : 'Never',
                    tableCellType: 'title',
                  },
                  {
                    id: 4,
                    tableCellType: 'action',
                    actionButtons: [
                      {
                        actionButtonId: 0,
                        actionType: 'delete',
                        onClick: () => handleDelete([token]),
                      },
                    ],
                  },
                ],
              }))}
            />
          )}
        </>
      )}
    </div>
  );
};

export default UserSettingTokens;
