/* eslint-disable react/react-in-jsx-scope */
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import UserDropdownStyle from '../../../styles/compute/user-dropdown.module.scss';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface option {
  label: string;
  handleClick: () => void;
  id?: number;
  disabled?: boolean;
  icon?: JSX.Element;
}

interface IProps {
  label: JSX.Element;
  listItems: Array<option>;
}

export default function TailwindDropdown({ label, listItems }: IProps) {
  return (
    <Menu
      as="div"
      className="relative overflow-visible inline-block text-left w-min"
    >
      <div>
        <Menu.Button>{label}</Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className="z-30 origin-top-right absolute mt-2 max-w-52
         rounded-md shadow-lg dark:border-dark-base-border w-min
         border-base-border bg-base-bg dark:bg-dark-base-bg
         ring-1 ring-black ring-opacity-5 focus:outline-none right-0"
        >
          {listItems.map((item, index) => (
            <Menu.Item key={item.label}>
              {({ active }) => (
                <div
                  key={item?.id}
                  onKeyDown={() => {}}
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    if (!item.disabled) item.handleClick();
                  }}
                  className={classNames(
                    active && !item.disabled
                      ? // eslint-disable-next-line max-len
                        `dark:font-normal bg-base-fg dark:bg-dark-base-fg border-l border-r border-base-border dark:border-dark-base-border 
                         ${index === 0 ? 'rounded-t-md border-t' : ''} ${
                          index === listItems.length - 1
                            ? 'rounded-b-md border-b'
                            : ''
                        }`
                      : `font-normal bg-base-bg dark:bg-dark-base-bg  ${
                          index === 0 ? 'rounded-t-md border-t' : ''
                        } ${
                          index === listItems.length - 1
                            ? 'rounded-b-md border-b'
                            : ''
                        }
                      border-base-border dark:border-dark-base-border border-l border-r`,
                    `${
                      item.icon ? 'flex items-center gap-x-2' : 'block'
                    } px-4 py-2 text-sm cursor-pointer dark:bg-dark-base-bg w-52`,
                    `${
                      item.disabled
                        ? '!cursor-not-allowed'
                        : `${UserDropdownStyle.dropdown}`
                    }`
                  )}
                >
                  {item.label} {!!item.icon && item.icon}
                </div>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
