/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import {
  Button,
  Dropdown,
  Feedback,
  IOptions,
  Navbar,
  OptionType,
  SpheronApp,
} from '@spheron/ui-library';
import { ReactComponent as ComputeIcon } from '@spheron/ui-library/dist/assets/spheron-specific/compute.svg';
import { ReactComponent as SettingsIcon } from '@spheron/ui-library/dist/assets/setting.svg';
import { ReactComponent as MemberIcon } from '@spheron/ui-library/dist/assets/users.svg';
// import { ReactComponent as MaintainanceIcon } from '@spheron/ui-library/dist/assets/spheron-specific/maintainance.svg';
import { ReactComponent as InfoIcon } from '@spheron/ui-library/dist/assets/info-circle.svg';
import { ReactComponent as Console } from '@spheron/ui-library/dist/assets/compute/console.svg';
import { ReactComponent as FizzIcon } from '@spheron/ui-library/dist/assets/compute/multi-service.svg';
import { ReactComponent as ProviderEarning } from '@spheron/ui-library/dist/assets/compute/provider-earning.svg';
import { ReactComponent as SpheronMenu } from '@spheron/ui-library/dist/assets/spheron-menu.svg';
import {
  ReactComponent as SpheronLogo,
  ReactComponent as SpheronIcon,
} from '@spheron/ui-library/dist/assets/spheron-specific/spheron-logo.svg';
import { useDispatch, useSelector } from 'react-redux';
import { callSSE } from '../../../libs/Compute/sse';
import { EventSourceMessage } from '../../../libs/fetchEventSource';
import { AppDispatch, RootState } from '../../../redux/compute/store';
// import Logo from '../../../assets/compute/global/spheron-logo.svg';
import GlobalStyle from '../../../styles/compute/global.module.scss';
import {
  addUserNotificationRtk,
  logoutUserRtk,
} from '../../../redux/compute/user/user.slice';
import { ICurrentApp } from '../../../redux/compute/organisation/organisation.interfaces';
import {
  hasNoOrganisations,
  removeCommunityLocalVariables,
  mapCurrentAppToSpecialization,
  isCommunityOrgRoute,
} from '../../../redux/compute/organisation/organisation.utils';
import { ReactComponent as OrganizationIcon } from '../../../assets/compute/global/organization.svg';
import { addNotificationRtk } from '../../../redux/compute/notification/notification.slice';
import { NotificationType } from '../../../redux/compute/notification/notification.interfaces';
import Notifications from '../Notification/notifications';
import { getSelectedOrganisationThunk } from '../../../redux/compute/organisation/organisation.thunks';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import { setSelectedMasterOrg } from '../../../redux/compute/organisation/organisation.slice';
import { resetInstanceState } from '../../../redux/compute/instance/instance.slice';
import { getWalletUsageThunk } from '../../../redux/compute/cluster/cluster.thunks';
import { updateUserDefaultOrganisationThunk } from '../../../redux/compute/user/user.thunks';
import { ANNOUNCEMENT_DATA, DISABLED_APPS } from '../../../config';
import { getSubscriptionUsageThunk } from '../../../redux/compute/subscription/subscription.thunks';

interface IProps {
  children?: JSX.Element;
}

interface IMenuDropdownItem {
  label: string;
  id: number;
  handleClick: () => void;
  optionType: OptionType;
  borderBottom?: boolean;
}

interface INavItem {
  icon?: JSX.Element;
  name: string;
  type: string;
  onClick: () => void;
  active: boolean;
}

export enum NavItemType {
  // eslint-disable-next-line no-unused-vars
  COMPUTE = 'compute',
  // eslint-disable-next-line no-unused-vars
  STORAGE = 'storage',
  // eslint-disable-next-line no-unused-vars
  STATIC = 'static',
  // eslint-disable-next-line no-unused-vars
  TEAM = 'team',
  // eslint-disable-next-line no-unused-vars
  SETTINGS = 'settings',
}

const NavWithLogin = ({ children }: IProps) => {
  const navigate = useNavigate();
  const dispatchRtk = useDispatch<AppDispatch>();
  const location = useLocation();
  const params = useParams<{ orgUsername: string; instanceId: string }>();
  const [updatedOptions, setUpdatedOptions] = useState<IOptions[]>([]);

  const userLoading = useSelector((state: RootState) => state.user.userLoading);

  const user = useSelector((state: RootState) => state.user.user);

  const organisations = user?.organizations || [];

  const currentApp = useSelector(
    (state: RootState) => state.organisation.currentApp
  );
  const selectedMasterOrganisation = useSelector(
    (state: RootState) => state.organisation.selectedMasterOrganisation
  );

  const webappOrganisations = useSelector(
    (state: RootState) => state.organisation.webappOrganisations
  );

  const computeOrganisations = useSelector(
    (state: RootState) => state.organisation.computeOrganisations
  );
  const organisationsLoading = useSelector(
    (state: RootState) => state.organisation.organisationsLoading
  );

  const storageOrganisations = useSelector(
    (state: RootState) => state.organisation.storageOrganisations
  );

  const hasNoCurrentAppOrganisations: boolean = hasNoOrganisations(
    currentApp,
    webappOrganisations.length,
    computeOrganisations.length,
    storageOrganisations.length
  );
  const isNewDeploymentPage =
    location.pathname.includes('/new-deployment') ||
    location.pathname.includes('/new-clone');

  useEffect(() => {
    if (user) {
      const updatedOptionsOrg: IOptions[] = organisations.map((orgs) => {
        return {
          label: orgs?.profile?.name,
          value: orgs?.compute.profile?.username,
          optionType: 'primary' as OptionType,
          rightIcon: orgs?.overdue && (
            <span className="px-2 py-0.5 rounded flex justify-center items-center bg-error-6 text-feedback-error-text text-xs font-semibold">
              overdue
            </span>
          ),
          dropdownRowClassname: orgs?.profile?.username.includes(
            'supernoderz-org'
          )
            ? 'hidden'
            : '',
        };
      });
      const newOrgOption: IOptions = {
        label: (
          <div className="flex items-center">
            <div
              className="w-5 h-5 bg-action-primary-default rounded-full
                flex items-center justify-center pb-0.5 text-md
                text-white flex-shrink-0 border-t-0 mr-2"
            >
              +
            </div>
            <span className="text-xs tracking-wide flex items-center">
              CREATE NEW ORGANISATION
            </span>
          </div>
        ),
        handleClick: () => {
          navigate(`/create-new-organisation`);
        },
        optionType: 'sticky' as OptionType,
      };
      const updatedOrgOptions = [...updatedOptionsOrg, newOrgOption];
      setUpdatedOptions(updatedOrgOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisations]);

  useEffect(() => {
    if (!params.instanceId) {
      dispatchRtk(resetInstanceState());
    }
  }, [dispatchRtk, params.instanceId]);

  useEffect(() => {
    if (
      !userLoading &&
      user &&
      user.organizations.length === 0 &&
      !user.platformProfile?.email
    ) {
      navigate('/onboarding');
    }
  }, [currentApp, navigate, user, userLoading]);

  const navItems: INavItem[] = [
    {
      icon: (
        <ComputeIcon className="w-5 h-5 text-base-icon dark:text-dark-base-icon" />
      ),
      name: 'Compute',
      type: 'compute',
      onClick: () =>
        navigate(
          `/${
            params.orgUsername ||
            selectedMasterOrganisation?.compute.profile.username
          }/dashboard`
        ),
      active: true,
    },
    {
      icon: (
        <MemberIcon className="w-5 h-5 text-base-icon dark:text-dark-base-icon" />
      ),
      name: 'Team',
      type: 'team',
      onClick: () =>
        navigate(
          `/${
            params.orgUsername ||
            selectedMasterOrganisation?.compute.profile.username
          }/members`
        ),
      active: true,
    },
    {
      icon: (
        <SettingsIcon className="w-5 h-5 text-base-icon dark:text-dark-base-icon" />
      ),
      name: 'Settings',
      type: 'settings',
      onClick: () =>
        navigate(
          `/${
            params.orgUsername ||
            selectedMasterOrganisation?.compute.profile.username
          }/settings/general`
        ),
      active: true,
    },
  ];

  const userMenuDropdownItems: IMenuDropdownItem[] = [
    {
      id: 0,
      label: 'Products',
      handleClick: () => navigate(`/choose-spheron-app`),
      optionType: 'primary' as OptionType,
    },
    // {
    //   id: 1,
    //   label: 'Dashboard',
    //   handleClick: () => navigate(`/${params.orgUsername}/dashboard`),
    //   optionType: 'primary' as OptionType,
    // },
    {
      id: 2,
      label: 'Settings',
      handleClick: () => navigate('/user/settings'),
      optionType: 'primary' as OptionType,
      borderBottom: true,
    },
    {
      id: 4,
      label: 'Docs',
      handleClick: () => window.open('https://docs.spheron.network'),
      optionType: 'primary' as OptionType,
    },
    {
      id: 5,
      label: 'Report Bugs',
      handleClick: () =>
        window.open('https://github.com/spheronFdn/beta-testing'),
      optionType: 'primary' as OptionType,
    },
    {
      id: 6,
      label: 'Blogs',
      handleClick: () => window.open('https://spheron.medium.com/'),
      optionType: 'primary' as OptionType,
    },
    {
      id: 7,
      label: 'Contact Us',
      handleClick: () => window.open('https://discord.com/invite/ahxuCtm'),
      optionType: 'primary' as OptionType,
      borderBottom: true,
    },
    {
      id: 8,
      label: 'Logout',
      handleClick: async () => {
        await dispatchRtk(logoutUserRtk());
        navigate('/login');
      },
      optionType: 'primary' as OptionType,
    },
  ];

  useEffect(() => {
    if (!organisationsLoading && computeOrganisations) {
      if (params.orgUsername) {
        if (isCommunityOrgRoute(location.pathname.split('/')[1])) {
          const computeOrgUsernameList = computeOrganisations.map(
            (org) => org.profile.username
          );
          const orgIndex = computeOrgUsernameList.findIndex(
            (username) => username === params.orgUsername
          );

          if (orgIndex === -1) {
            const segments = location.pathname.split('/');
            [segments[1]] = computeOrgUsernameList;
            const newPathname = segments.join('/');

            navigate(newPathname);
            dispatchRtk(setSelectedMasterOrg(user?.organizations[0]!));
            dispatchRtk(
              getSelectedOrganisationThunk(computeOrganisations[0]._id)
            );
          } else {
            dispatchRtk(setSelectedMasterOrg(user?.organizations[orgIndex]!));
            dispatchRtk(
              getSelectedOrganisationThunk(computeOrganisations[orgIndex]._id)
            );
          }
        }
      } else if (location.pathname.split('/')[1] === 'user') {
        const localMasterOrg = localStorage.getItem('selected-master-org');
        const foundMasterOrg = user?.organizations.find(
          (org) => org._id === localMasterOrg
        );
        if (foundMasterOrg) {
          dispatchRtk(setSelectedMasterOrg(foundMasterOrg));
          dispatchRtk(getSelectedOrganisationThunk(foundMasterOrg.compute._id));
        } else {
          dispatchRtk(setSelectedMasterOrg(user?.organizations[0]!));
          dispatchRtk(
            getSelectedOrganisationThunk(computeOrganisations[0]._id)
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [computeOrganisations, params.orgUsername]);

  const isJwtPresent = useMemo(() => {
    return !!localStorage.getItem('jwt-token');
  }, []);
  useEffect(() => {
    const notificationSseController: AbortController = new AbortController();
    const { signal }: { signal: AbortSignal } = notificationSseController;

    if (!userLoading) {
      callSSE({
        apiPath: '/user-notification',
        msgCb: (msg: EventSourceMessage) => {
          const notification = JSON.parse(msg?.data);
          if (notification?.session) {
            return;
          }
          if (window.Notification?.permission === 'granted') {
            const notif = new window.Notification('Spheron', {
              body: notification.description,
              icon: '/favicon.ico',
              timestamp: Date.now(),
              lang: 'en',
            });
            notif.onclick = () => {
              window.focus();
            };
          }
          dispatchRtk(addUserNotificationRtk(notification));
          dispatchRtk(
            addNotificationRtk({
              title: notification.title,
              message: notification.description,
              timestamp: notification.createdAt,
              type: NotificationType.Info,
            })
          );

          // Added for handling SSE event for Fetcch payment
          if (
            notification?.additionalData?.paymentProcesor ===
            'FETCCH_PAYMENT_COMPLETED'
          ) {
            dispatchRtk(
              getWalletUsageThunk(
                localStorage.getItem('selected-org') as string
              )
            );
            dispatchRtk(
              getSubscriptionUsageThunk({
                organizationId: localStorage.getItem('selected-org') as string,
                specialization: mapCurrentAppToSpecialization(currentApp),
              })
            );
            dispatchRtk(
              toggleModalShowRtk({
                modalShow: false,
                // modalType: 'topUpBalance',
              })
            );
          } else if (
            notification?.additionalData?.paymentProcesor ===
            'FETCCH_PAYMENT_FAILED'
          ) {
            dispatchRtk(
              toggleModalShowRtk({
                modalShow: false,
                // modalType: 'topUpBalance',
              })
            );
            dispatchRtk(
              addNotificationRtk({
                title: 'Payment Failed',
                message: 'Payment processing encountered an error',
                timestamp: Date.now(),
                type: NotificationType.Error,
              })
            );
          }
        },
        errCb: (error: any) => {
          console.error(
            'Error in notifications SSE -> ',
            (error as Error).message
          );
        },
        signal,
      });
    }

    return () => {
      if (notificationSseController) {
        notificationSseController.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoading]);

  const [selectedNavItemType, setSelectedNavItemType] =
    useState<NavItemType | null>();

  useMemo(() => {
    const firstPath = location.pathname.split('/')[1];
    const secondPath = location.pathname.split('/')[2];
    if (secondPath === 'members') setSelectedNavItemType(NavItemType.TEAM);
    else if (firstPath === 'user') setSelectedNavItemType(null);
    else if (secondPath === 'settings')
      setSelectedNavItemType(NavItemType.SETTINGS);
    else {
      switch (firstPath) {
        case ICurrentApp.COMPUTE:
        default:
          setSelectedNavItemType(NavItemType.COMPUTE);
          break;
      }
    }
  }, [location.pathname]);

  const handleOrgClick = (value: string) => {
    const org = organisations.find(
      (org) => org.compute.profile.username === value
    );
    if ((window as any).invoicePromise) {
      (window as any).invoicePromise.abort();
      delete (window as any).invoicePromise;
    }
    if ((window as any).getUserRolePromise) {
      (window as any).getUserRolePromise.abort();
      delete (window as any).getUserRolePromise;
    }

    if (sessionStorage.getItem('communityFlow')) {
      removeCommunityLocalVariables();
    }
    if (org) {
      dispatchRtk(setSelectedMasterOrg(org));
      dispatchRtk(updateUserDefaultOrganisationThunk(org._id));
      dispatchRtk(getSelectedOrganisationThunk(org?.compute._id));
      if (window.location.pathname.split('/')[1] !== 'user') {
        navigate(`/${value}/dashboard`);
      }
    }
  };

  const handleNavButtonChange = (navItem: INavItem) => {
    setSelectedNavItemType(navItem.type as NavItemType);
    navItem.onClick();
  };

  const dropdownItems = [
    {
      id: 1,
      label: 'CONSOLE',
      handleClick: () => window.open('https://console.spheron.network'),
      icon: <Console className="w-8 h-8" />,
      disable: JSON.parse(DISABLED_APPS).apps.find(
        (app: string) => app === 'console'
      ),
    },
    {
      id: 2,
      label: 'PROVIDER',
      handleClick: () => window.open('https://provider.spheron.network'),
      icon: <ProviderEarning className="w-8 h-8" />,
      disable: JSON.parse(DISABLED_APPS).apps.find(
        (app: string) => app === 'provider'
      ),
    },
    {
      id: 3,
      label: 'FIZZ NODE',
      handleClick: () => window.open('https://fizz.spheron.network'),
      icon: <FizzIcon className="w-8 h-8" />,
      disable: JSON.parse(DISABLED_APPS).apps.find(
        (app: string) => app === 'fizz'
      ),
    },
  ];

  const announcementData = JSON.parse(ANNOUNCEMENT_DATA);

  const showAnnouncementBar =
    new Date(announcementData.expiry).getTime() > new Date().getTime();

  const announcementText = announcementData.text;

  const announcementLink = '';

  return (
    <>
      <div className="sticky top-0 z-[999]">
        <div className="w-full">
          {showAnnouncementBar && (
            <Feedback
              feedbackType={announcementData.type}
              icon={<InfoIcon />}
              subTitle={
                <div className="text-[11px]">
                  {announcementText}{' '}
                  {announcementLink && (
                    <a
                      href={announcementLink}
                      target="_blank"
                      className="font-600 hover:cursor-pointer hover:underline"
                      rel="noreferrer"
                    >
                      View Details
                    </a>
                  )}
                </div>
              }
              title=""
              showClose={false}
              withShadow={false}
              position="center"
              size="compact"
            />
          )}
        </div>
        <div className={`${GlobalStyle.nav__outer_con}`}>
          <Navbar
            logoIcon={
              <div className="flex items-center justify-start gap-x-2">
                <Dropdown
                  dropdownType="button"
                  className="w-fit"
                  dropdownSize="default"
                  bordersNone
                  dropdownClassname="w-fit !ml-1"
                  buttonImage={
                    <button
                      type="button"
                      className="p-0.5 rounded
                      transition hover:bg-action-ghost-press dark:hover:bg-dark-action-ghost-press"
                    >
                      <SpheronMenu className="w-8 h-8" />
                    </button>
                  }
                  options={dropdownItems.map((app) => {
                    return {
                      id: app.id,
                      optionType: 'primary',
                      dropdownRowClassname: `hover:bg-transparent dark:hover:bg-transparent ${
                        app.disable ? '!cursor-not-allowed' : ''
                      }`,
                      label: (
                        <div className="w-80">
                          <SpheronApp
                            heading={
                              <div className="flex items-center justify-start gap-x-2">
                                <SpheronIcon className="w-6 h-6" />
                                <span className="font-semibold dark:text-primary-sb10">
                                  {app.label}
                                </span>
                              </div>
                            }
                            helpText=""
                            icon={app.icon}
                          />
                        </div>
                      ),
                      handleClick: () => app.handleClick(),
                      disable: app.disable,
                    };
                  })}
                />
                <Link
                  to={`/${
                    params.orgUsername ||
                    selectedMasterOrganisation?.compute.profile.username
                  }/dashboard`}
                >
                  <div className="flex items-center justify-start gap-x-1">
                    <SpheronLogo className="w-10 h-10" />
                    <span className="text-base font-semibold dark:text-primary-sb10">
                      CLOUD
                    </span>
                  </div>
                </Link>
              </div>
            }
            // logoIcon={
            //   <Link
            //     to={`/${
            //       params.orgUsername ||
            //       selectedMasterOrganisation?.compute.profile.username
            //     }/dashboard`}
            //   >
            //     <img
            //       src={Logo}
            //       alt="logo"
            //       className={GlobalStyle.spheron__logo}
            //     />
            //   </Link>
            // }
            navButtons={
              <>
                {!hasNoCurrentAppOrganisations && !isNewDeploymentPage && (
                  <div
                    className="hidden lg:flex items-center lg:justify-center"
                    id="navbar-list"
                  >
                    <ul className="flex flex-row gap-6 items-center">
                      {navItems.map((navItem: INavItem) => {
                        if (!navItem.active) {
                          return <></>;
                        }
                        return (
                          <li
                            className={`${GlobalStyle.nav__item} nav-item`}
                            key={navItem.type}
                          >
                            <Button
                              buttonType="action"
                              classname={
                                selectedNavItemType === navItem.type
                                  ? '!bg-action-ghost-press dark:!bg-dark-action-ghost-hover'
                                  : ''
                              }
                              label={navItem.name}
                              size="small"
                              onClick={() => handleNavButtonChange(navItem)}
                              leftIcon={navItem.icon}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </>
            }
            orgDropdown={
              <>
                {!hasNoCurrentAppOrganisations && (
                  <>
                    <div>
                      <div className="flex items-center space-x-2">
                        <div className="text-sm font-semibold">
                          <div className="w-[200px]">
                            <Dropdown
                              leftIcon={
                                <OrganizationIcon className="h-6 w-6" />
                              }
                              dropdownType="default"
                              dropdownSize="compact"
                              filled
                              options={updatedOptions}
                              defaultSelected={
                                selectedMasterOrganisation?.profile?.name || ''
                              }
                              placeholder={
                                selectedMasterOrganisation?.profile?.name || ''
                              }
                              onSelected={(selected) =>
                                handleOrgClick(selected.value as string)
                              }
                              dropdownClassname="!w-[250px]"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            }
            infoElements={
              <div className="flex flex-1 justify-end items-center space-x-5">
                {isJwtPresent && <Notifications />}
                {isJwtPresent && (
                  <div className="lg:ml-6 hidden lg:flex">
                    {!userLoading && user ? (
                      <Dropdown
                        dropdownType="button"
                        dropdownSize="default"
                        buttonImage={
                          <img
                            src={user?.platformProfile.avatar}
                            alt="User"
                            className={`${GlobalStyle.profile__pic} rounded-full`}
                          />
                        }
                        bordersNone
                        options={userMenuDropdownItems}
                        dropdownClassname="!max-h-[350px]"
                      />
                    ) : (
                      <Skeleton
                        width={40}
                        height={40}
                        borderRadius={9999}
                        duration={2}
                        containerClassName="flex"
                      />
                    )}
                  </div>
                )}
              </div>
            }
          />
        </div>
      </div>
      {children}
    </>
  );
};
NavWithLogin.defaultProps = {
  children: <></>,
};
export default NavWithLogin;
