import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Arrow } from '../../../../../assets/compute/icons/arrow-drop-icon.svg';
import { ReactComponent as Check } from '../../../../../assets/compute/icons/check-icon.svg';
import Style from '../../../../../styles/compute/compute-pricing.module.scss';
import useOnClickOutside from '../../../../../hooks/useOnClickOutside';
import {
  updateCustomCpuRtk,
  updateCustomRamRtk,
  updateCustomPersistentStorageRtk,
  resetPersistentValuesRtk,
  selectClusterRegionRtk,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.slice';
import { AppDispatch, RootState } from '../../../../../redux/compute/store';
// eslint-disable-next-line max-len
import { getPersistentStorageName } from '../../../../../redux/compute/instance/instance-creation/instance-creation.utils';
// eslint-disable-next-line max-len
import { HardwareTypeEnum } from '../../../../../redux/compute/instance/instance-creation/instance-creation.interfaces';

// eslint-disable-next-line no-unused-vars
export enum CustomSelectOption {
  // eslint-disable-next-line no-unused-vars
  SELECTEDCPU = 'selectedCpu',
  // eslint-disable-next-line no-unused-vars
  SELECTEDRAM = 'selectedRam',
  // eslint-disable-next-line no-unused-vars
  SELECTEDSTORAGE = 'selectedStorage',
  // eslint-disable-next-line no-unused-vars
  SELECTEDREGION = 'selectedRegion',
  // eslint-disable-next-line no-unused-vars
  SELECTEDPORT = 'selectedPort',
}

interface IProps {
  isUpdate: boolean;
  label: string;
  options: any; // infer better type from usage
  value: CustomSelectOption;
  widthClassName: string;
  paddingClassName?: string;
  heightClassName?: string;
  defaultOption: string;
  // eslint-disable-next-line no-unused-vars
  onSelected?: (value: any) => void;
  error?: boolean;
  errorMessage?: string;
}

const DropdownMenu = ({
  isUpdate,
  label,
  options,
  value,
  widthClassName,
  paddingClassName,
  heightClassName,
  defaultOption,
  onSelected,
  error,
  errorMessage,
}: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSuffix, setIsSuffix] = useState<string>('');
  const [isSubtitle, setIsSubtitle] = useState<string>('');
  const [selectedOption, setSelectedOption] = useState<string>(defaultOption);

  const activeDeployment = useSelector(
    (state: RootState) => state.instance.activeDeployment
  );
  const selectedTemplate = useSelector(
    (state: RootState) => state.cluster.selectedTemplate
  );
  const scalingType = useSelector(
    (state: RootState) => state.instanceCreation.clusterScaling
  );
  const defaultCustomPlanValue = useSelector(
    (state: RootState) => state.instanceCreation.defaultCustomPlansValue
  );
  const hddValues = defaultCustomPlanValue?.find(
    (item: { type: HardwareTypeEnum }) =>
      item.type === HardwareTypeEnum.PERSISTENT_STORAGE_HDD
  );
  const ssdValues = defaultCustomPlanValue?.find(
    (item: { type: HardwareTypeEnum }) =>
      item.type === HardwareTypeEnum.PERSISTENT_STORAGE_SSD
  );
  const nvmValues = defaultCustomPlanValue?.find(
    (item: { type: HardwareTypeEnum }) =>
      item.type === HardwareTypeEnum.PERSISTENT_STORAGE_NVME
  );
  const squareBoxRef = useRef<HTMLDivElement>(null);
  const clickOutsidehandler = () => {
    setIsOpen(false);
  };
  useOnClickOutside(squareBoxRef, clickOutsidehandler);
  const handleSelectOption = (
    name: string,
    subtitle: string,
    valueName: string,
    price: string,
    option?: any // infer better type
  ) => {
    if (onSelected) {
      onSelected(option);
    }
    if (name) {
      setSelectedOption(name);
    } else {
      setSelectedOption(valueName);
    }
    if (subtitle) {
      setIsSubtitle(subtitle);
    } else {
      setIsSubtitle(price);
    }
    setIsOpen(false);
    switch (value) {
      case CustomSelectOption.SELECTEDCPU:
        dispatchRtk(updateCustomCpuRtk(valueName));
        break;
      case CustomSelectOption.SELECTEDRAM:
        dispatchRtk(updateCustomRamRtk(valueName));
        break;
      case CustomSelectOption.SELECTEDSTORAGE:
        dispatchRtk(resetPersistentValuesRtk());
        dispatchRtk(updateCustomPersistentStorageRtk(name));
        break;
      case CustomSelectOption.SELECTEDREGION:
        dispatchRtk(selectClusterRegionRtk(name));
        break;
      case CustomSelectOption.SELECTEDPORT:
      default:
        break;
    }
  };
  useEffect(() => {
    if (value === CustomSelectOption.SELECTEDCPU) {
      setIsSuffix('CPU');
    } else if (value === CustomSelectOption.SELECTEDRAM) {
      setIsSuffix('Gi RAM');
    }
  }, [value]);
  useEffect(() => {
    if (isUpdate && activeDeployment) {
      switch (value) {
        case CustomSelectOption.SELECTEDCPU:
          dispatchRtk(
            updateCustomCpuRtk(
              String(activeDeployment.services[0]?.agreedMachineImage.cpu)
            )
          );
          break;
        case CustomSelectOption.SELECTEDRAM:
          dispatchRtk(
            updateCustomRamRtk(
              activeDeployment.services[0]?.agreedMachineImage.memory
            )
          );
          break;
        case CustomSelectOption.SELECTEDSTORAGE:
          if (
            activeDeployment.services[0]?.agreedMachineImage?.persistentStorage
          ) {
            dispatchRtk(
              updateCustomPersistentStorageRtk(
                getPersistentStorageName(
                  activeDeployment.services[0]?.agreedMachineImage
                    .persistentStorage.class
                )
              )
            );
          }
          break;
        default:
          break;
      }
    }
  }, [dispatchRtk, isUpdate, activeDeployment, value]);

  const persistentStorageTemplate =
    selectedTemplate?.serviceData.customTemplateSpecs.persistentStorage;
  useEffect(() => {
    const hasPersistentStorage =
      Object.keys(persistentStorageTemplate || {}).length > 0;
    if (
      persistentStorageTemplate &&
      hasPersistentStorage &&
      !isUpdate &&
      value === CustomSelectOption.SELECTEDSTORAGE
    ) {
      const storageName = getPersistentStorageName(
        persistentStorageTemplate.class
      );
      // infer better type
      const disableOption = options.find(
        (option: any) => storageName === option.name
      );
      if (disableOption.disable) {
        dispatchRtk(resetPersistentValuesRtk());
        setSelectedOption(defaultOption);
        dispatchRtk(updateCustomPersistentStorageRtk(''));
      } else {
        dispatchRtk(resetPersistentValuesRtk());
        setSelectedOption(storageName);
        dispatchRtk(updateCustomPersistentStorageRtk(storageName));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatchRtk,
    value,
    persistentStorageTemplate,
    isUpdate,
    scalingType,
    hddValues,
    ssdValues,
    nvmValues,
  ]);
  const handleOpen = () => {
    setIsOpen(!isOpen);
  };
  const arrowClasses = `${Style.dropdown__icon} ${
    isOpen ? Style.rotate180 : Style.rotate0
  }`;
  return (
    <div className="relative" ref={squareBoxRef}>
      <div className="text-text-filterBadge text-sm font-medium ml-1">
        {label}
      </div>
      <button
        onClick={handleOpen}
        type="button"
        className={`bg-white border rounded-lg 
         flex items-center justify-between cursor-pointer mt-2 
         dark:bg-dark-base-bg ${widthClassName} ${paddingClassName} ${heightClassName}
          ${
            isOpen
              ? `border-border-form-selected shadow-darkDropdownMenu
              dark:bg-base-heading-text-color`
              : `${
                  error ? 'border-feedback-error-text' : 'border-searchBorder'
                } shadow-searchShadow 
              dark:border-dark-base-border`
          }`}
      >
        <span className="text-base text-text-darkGray font-normal">
          {selectedOption !== defaultOption ? (
            <div className="dark:text-white">
              <span>
                {selectedOption} {isSuffix && isSuffix}
              </span>
              {isSubtitle && (
                <span className="text-text-darkGray text-base font-medium ml-1">
                  - {isSubtitle}
                </span>
              )}
            </div>
          ) : (
            defaultOption
          )}
        </span>
        {options?.length > 0 && <Arrow className={arrowClasses} />}
      </button>
      {error && !isOpen && (
        <span className="text-feedback-error-text text-xs mt-2 ml-1">
          {errorMessage}
        </span>
      )}
      {options?.length > 0 && isOpen && (
        <div
          className={`mt-2 bg-white border border-bg-lightGray
        rounded-lg shadow-searchShadow absolute 
        dark:bg-base-heading-text-color dark:border-dark-base-border z-10 ${widthClassName}`}
        >
          {options.map((option: any, index: number) => (
            <button
              type="button"
              key={option.key}
              onClick={() =>
                handleSelectOption(
                  option.name,
                  option.subtitle,
                  option.value,
                  option.price,
                  option
                )
              }
              className={`h-11 px-4 w-full cursor-pointer 
              hover:bg-base-fg dark:hover:bg-dark-base-fg ${
                option?.disable
                  ? 'cursor-not-allowed pointer-events-none opacity-30'
                  : 'cursor-pointer pointer-events-auto opacity-100'
              } ${index === 0 ? 'rounded-t-lg' : 'rounded-none'}
              ${index === options.length - 1 ? 'rounded-b-lg' : 'rounded-none'} 
              ${
                selectedOption === option.name ||
                selectedOption === option.value
                  ? 'bg-base-fg dark:bg-dark-base-fg'
                  : 'bg-base-bg dark:bg-dark-base-bg'
              } `}
            >
              <div className="flex items-center justify-between">
                <div>
                  <span>
                    {option.name ? option.name : option.value}
                    {option.suffix ||
                      (isSuffix && (
                        <>
                          {option.suffix} {isSuffix} -{' '}
                        </>
                      ))}
                  </span>
                  <span className="text-text-darkGray text-base font-medium">
                    {option.subtitle} {option.price}
                  </span>
                </div>

                {selectedOption === option.name ||
                  (selectedOption === option.value && (
                    <Check className={Style.check__dropdown__icon} />
                  ))}
              </div>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

DropdownMenu.defaultProps = {
  onSelected: undefined,
  error: false,
  errorMessage: '',
  paddingClassName: 'px-4',
  heightClassName: 'h-11',
};
export default DropdownMenu;
