import React from 'react';
import { Modal } from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import Styles from '../../../styles/compute/modal.module.scss';
import { IUpdateHealthCheck } from '../../../redux/compute/instance/instance.interfaces';
import { updateHealthCheckThunk } from '../../../redux/compute/instance/instance.thunks';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import ModalWrapper from './ModalWrapper';

interface IProps {
  isOpen: boolean;
  healthCheckConfig: IUpdateHealthCheck;
}

function SaveHealthCheckConfig({ isOpen, healthCheckConfig }: IProps) {
  const dispatchRtk = useDispatch<AppDispatch>();

  const closeModal = () => {
    dispatchRtk(toggleModalShowRtk({ modalShow: false }));
  };
  // TODO - to be updated with dynamic loading
  const healthCheckUpdateLoading: boolean = useSelector(
    (state: RootState) => state.instance.healthCheckUpdateLoading
  );

  const handleUpdate = () => {
    dispatchRtk(updateHealthCheckThunk(healthCheckConfig));
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={closeModal}
      classname={`${Styles.modal__outer__con} modal fixed inset-0 overflow-y-auto`}
    >
      <div className="min-w-500">
        <Modal
          header
          headerContent={<>Update Health Check Settings</>}
          footer
          modalContent={
            <div className="flex flex-col gap-y-3">
              <p className="text-sm font-normal leading-5 text-left">
                If you change this, the health check settings will be updated
              </p>
            </div>
          }
          onPrimaryButtonClick={handleUpdate}
          primaryButton
          secondaryButton
          onSecondaryButtonClick={closeModal}
          primaryButtonContent="Confirm"
          primaryButtonLoading={healthCheckUpdateLoading}
          secondaryButtonContent="Cancel"
          subtitle={''}
        />
      </div>
    </ModalWrapper>
  );
}

export default SaveHealthCheckConfig;
