import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CardInfo from './credit-card';
import CryptoCardInfo from './crypto-card';
import {
  INetworkType,
  IOrganisationWallet,
  ITokenType,
} from '../../../redux/compute/web3/web3.interfaces';
import {
  ICreditCard,
  PaymentMethod,
} from '../../../redux/compute/combined-state.interface';
import NoCard from '../EmptyList/no-card';
import {
  fetchBalanceThunk,
  fetchWalletBalanceThunk,
} from '../../../redux/compute/web3/web3.thunks';
import { fetchSolBalanceThunk } from '../../../redux/compute/solana/solana.thunks';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import { IOrgWallet } from '../../../redux/compute/organisation/organisation.interfaces';
import { isNonSubgraphChainId } from '../../../redux/compute/web3/web3.utils';
import { resetBalanceRtk } from '../../../redux/compute/web3/web3.slice';
import SpheronWallet from './spheron-wallet';
import { ConnectionState } from '../../../redux/compute/stripe/stripe.interfaces';

interface IProps {
  tempPaymentMethod: ICreditCard | IOrganisationWallet | IOrgWallet | null;
  heading: JSX.Element | null;
}

const SelectPaymentMethod = ({ tempPaymentMethod, heading }: IProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const selectedOrgWalletConfig = useSelector(
    (state: RootState) => state.organisation.selectedOrgWalletConfig
  );

  const selectedOrgCreditCardConfig = useSelector(
    (state: RootState) => state.organisation.selectedOrgCreditCardConfig
  );

  const balance = useSelector((state: RootState) => state.web3.balance);

  const balanceLoading = useSelector(
    (state: RootState) => state.web3.escrowBalanceLoading
  );

  const web3Wallets = useSelector((state: RootState) => state.web3.wallets);

  const creditCards = useSelector(
    (state: RootState) => state.stripe.creditCards
  );

  const allUsers = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation.users
  );
  const user = useSelector((state: RootState) => state.user.user);

  const isMember =
    allUsers?.find((u) => u?._id === user?._id)?.platformProfile?.role ===
      'member' && user !== null;

  const selectedPaymentMethod = useSelector(
    (state: RootState) => state.subscription.computeTopupSelectedPaymentMethod
  );

  const ethereum = (window as any)?.ethereum;

  useEffect(() => {
    dispatch(resetBalanceRtk());
    if (
      tempPaymentMethod &&
      (tempPaymentMethod as IOrganisationWallet).paymentMethod ===
        PaymentMethod.CRYPTO
    ) {
      const { _id: walletId } = tempPaymentMethod as IOrganisationWallet;
      const { networkDetails, tokenDetails, address } = (
        tempPaymentMethod as IOrganisationWallet
      ).details;
      if (networkDetails?.chainName !== 'solana') {
        if (!isNonSubgraphChainId(Number(networkDetails.chainId))) {
          dispatch(
            fetchBalanceThunk({
              walletId,
              balance: {
                address,
                token: tokenDetails.address,
                chainId: Number(networkDetails.chainId),
              },
            })
          );
        } else if (ethereum?.selectedAddress) {
          dispatch(
            fetchWalletBalanceThunk({
              walletId,
              address,
              tokenDetails,
              networkDetails,
            })
          );
        }
      } else {
        dispatch(
          fetchSolBalanceThunk({
            address,
            token: tokenDetails.address,
            chainId: Number(networkDetails.chainId),
          })
        );
      }
    } else if (selectedOrgWalletConfig._id) {
      const {
        networkDetails,
        tokenDetails,
        address,
        _id: walletId,
      } = selectedOrgWalletConfig;
      if (networkDetails?.chainName === 'solana') {
        dispatch(
          fetchSolBalanceThunk({
            address,
            token: tokenDetails!.address,
            chainId: Number(networkDetails!.chainId),
          })
        );
      } else if (tempPaymentMethod === null) {
        if (!isNonSubgraphChainId(Number(networkDetails?.chainId))) {
          dispatch(
            fetchBalanceThunk({
              walletId: selectedOrgWalletConfig._id,
              balance: {
                address,
                token: tokenDetails!.address,
                chainId: Number(networkDetails!.chainId),
              },
            })
          );
        } else if (ethereum?.selectedAddress) {
          dispatch(
            fetchWalletBalanceThunk({
              walletId,
              address,
              tokenDetails,
              networkDetails,
            })
          );
        }
      }
    }
  }, [tempPaymentMethod, selectedOrgWalletConfig, dispatch, ethereum]);

  const showPaymentMethod = () => {
    switch (selectedPaymentMethod) {
      case PaymentMethod.CREDITCARD: {
        const filteredCreditCards = creditCards.filter(
          (card) => card.connectionState === ConnectionState.CONNECTED
        );
        if (filteredCreditCards.length > 0) {
          const listedCreditCard =
            filteredCreditCards.find(
              (card) => card._id === selectedOrgCreditCardConfig?._id
            ) || filteredCreditCards[0];

          const {
            brand,
            last4,
            expiration_month: expirationMonth,
            expiration_year: expirationYear,
          } = (listedCreditCard as ICreditCard).details.info || {};

          return (
            <CardInfo
              disabled={isMember}
              showDropdown={filteredCreditCards.length > 1}
              loading={false}
              id={listedCreditCard._id}
              cardType={brand || ''}
              cardNumber={last4 || ''}
              expiry={`${expirationMonth}/${expirationYear}`}
              isPrimary={listedCreditCard.primary}
            />
          );
        }
        return null;
      }
      case PaymentMethod.CRYPTO: {
        const filteredWallet = web3Wallets.filter(
          (wallet) => wallet.connectionState === ConnectionState.CONNECTED
        );
        if (filteredWallet.length > 0) {
          const listedWallet =
            filteredWallet.find(
              (wallet) => wallet._id === selectedOrgWalletConfig?._id
            ) || filteredWallet[0];

          const { address, networkDetails, tokenDetails } =
            (listedWallet as IOrganisationWallet).details || {};
          return (
            <CryptoCardInfo
              disabled={isMember}
              showDropdown={filteredWallet.length > 1}
              address={address}
              isSolana={
                (networkDetails as INetworkType)?.chainName === 'solana'
              }
              network={networkDetails as INetworkType}
              token={tokenDetails as ITokenType}
              balance={balance}
              balanceLoading={balanceLoading}
              disconnectWalletLoading={false}
              isPrimary={listedWallet.primary}
              id={(listedWallet as IOrganisationWallet)._id}
            />
          );
        }
        return null;
      }
      case PaymentMethod.SPHERONWALLET:
        return (
          <SpheronWallet
            disabled={isMember}
            showDropdown={false}
            loading={false}
          />
        );
      case PaymentMethod.CRYTPO_INTEGRATION:
        return <></>;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="flex items-center justify-between">
        {heading && heading}
      </div>
      <div className={heading ? 'mt-5' : 'mt-0'}>
        {showPaymentMethod() || <NoCard options={[]} />}
      </div>
    </>
  );
};

export default SelectPaymentMethod;
