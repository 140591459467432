import { useEffect, useState } from 'react';

export const largeCutText = (text: string) =>
  `${text.substring(0, 6)}...${text.substring(text.length - 4)}`;

export const smallCutText = (text: string) =>
  `${text.substring(0, 3)}...${text.substring(text.length - 2)}`;

function useShortAddress(address: string) {
  const [shortForm, setShortForm] = useState('*****');
  if (!address) {
    // eslint-disable-next-line no-param-reassign
    address = shortForm;
  }
  useEffect(() => {
    if (window.innerWidth >= 768) setShortForm(largeCutText(address));
    if (window.innerWidth < 768) setShortForm(smallCutText(address));
    if (window.innerWidth <= 480) setShortForm(largeCutText(address));
  }, [address]);

  return shortForm;
}

export { useShortAddress };
