import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Dropdown, IOptions, OptionType } from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Check } from '../../../../../assets/compute/icons/checkbox-template-card.svg';
import Styles from '../../../../../styles/compute/compute-pricing.module.scss';
import { RootState } from '../../../../../redux/rtk-store';
import {
  updateCustomHddStorageInputRtk,
  updateCustomHddStorageRtk,
  updateCustomNvmStorageInputRtk,
  updateCustomNvmStorageRtk,
  updateCustomSsdStorageInputRtk,
  updateCustomSsdStorageRtk,
  updateCustomStorageInputRtk,
  updateCustomStorageRtk,
  updateCustomStorageInputPriceRtk,
  updateCustomHddStorageInputPriceRtk,
  updateCustomSsdStorageInputPriceRtk,
  updateCustomNvmStorageInputPriceRtk,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.slice';
// eslint-disable-next-line max-len
import { calculatePriceThunk } from '../../../../../redux/compute/instance/instance-creation/instance-creation.thunks';
import {
  LoadingType,
  PersistentStorageOption,
  ScalingType,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.interfaces';
import { sliceStringFromEnd } from '../../../../../redux/compute/subscription/subscription.utils';
import { useDebounce } from '../../../../../hooks/useDebounce';
import { ICustomTemplateSpecs } from '../../../../../redux/compute/cluster/cluster.interfaces';
import {
  getPersistentStorageName,
  isAccelerateDeployment,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.utils';
import { AppDispatch } from '../../../../../redux/compute/store';

// custom hook for getting previous prop/state
export const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

// eslint-disable-next-line no-unused-vars
export enum CustomStorageOption {
  // eslint-disable-next-line no-unused-vars
  CUSTOMSTORAGE = 'customStorage',
  // eslint-disable-next-line no-unused-vars
  CUSTOMPERSISTENTSTORAGE = 'customPersistentStorage',
  // eslint-disable-next-line no-unused-vars
  CUSTOMHDDSTORAGE = 'HDD',
  // eslint-disable-next-line no-unused-vars
  CUSTOMSSDSTORAGE = 'SSD',
  // eslint-disable-next-line no-unused-vars
  CUSTOMNVMSTORAGE = 'NVMe',
}

interface IProps {
  value: CustomStorageOption;
  isSelected: boolean;
  onSelect: () => void;
  isUpdate: boolean;
}

const CustomStorage = ({ isUpdate, value, isSelected, onSelect }: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();
  const selectedInstance = useSelector(
    (state: RootState) => state.instance.selectedInstance
  );
  const activeDeployment = useSelector(
    (state: RootState) => state.instance.activeDeployment
  );
  // eslint-disable-next-line no-unused-vars
  const selectedInstanceLoading = useSelector(
    (state: RootState) => state.instance.selectedInstanceLoading
  );
  const customStorageInput = useSelector(
    (state: RootState) => state.instanceCreation.customStorageInput
  );
  const customHddStorageInput = useSelector(
    (state: RootState) => state.instanceCreation.customHddStorageInput
  );
  const customSsdStorageInput = useSelector(
    (state: RootState) => state.instanceCreation.customSsdStorageInput
  );
  const customNvmStorageInput = useSelector(
    (state: RootState) => state.instanceCreation.customNvmStorageInput
  );
  const updatePersistentStorage = useSelector(
    (state: RootState) => state.instanceCreation.customPersistentStorage
  );
  const customStoragePrice = useSelector(
    (state: RootState) => state.instanceCreation.updatedStoragePrice
  );
  const customPersistentStoragePrice = useSelector(
    (state: RootState) => state.instanceCreation.updatedPersistentStoragePrice
  );
  const selectedTemplate = useSelector(
    (state: RootState) => state.cluster.selectedTemplate
  );
  const scalingType = useSelector(
    (state: RootState) => state.instanceCreation.clusterScaling
  );
  const deploymentType = useSelector(
    (state: RootState) => state.instanceCreation.deploymentType
  );
  const { persistentStorage, storage: recommendedStorage } =
    (selectedTemplate?.serviceData
      .customTemplateSpecs as ICustomTemplateSpecs) || {};
  const hasPersistentStorage = Object.keys(persistentStorage || {}).length > 0;
  const prevUpdatePersistentStorage = usePrevious(updatePersistentStorage);
  const updatedCustomPersistentStoragePriceHourly = (
    customPersistentStoragePrice / 24
  ).toFixed(4);
  const updatedCustomPersistentStoragePriceMonthly = (
    customPersistentStoragePrice * 30
  ).toFixed(2);
  let selectedStorage = customStorageInput;
  let selectedAvgCost: string | number | null = '--';
  const updatedStoragePriceHourly: string = customStoragePrice
    ? (customStoragePrice / 24).toFixed(4)
    : '0';
  const updatedStoragePriceMonthly: string = customStoragePrice
    ? (customStoragePrice * 30).toFixed(2)
    : '0';
  let updatedStoragePrice: string;
  let updatedCustomPersistentStoragePrice: string;
  let costUnit: string;
  if (isAccelerateDeployment(deploymentType)) {
    updatedStoragePrice = updatedStoragePriceHourly;
    updatedCustomPersistentStoragePrice =
      updatedCustomPersistentStoragePriceHourly;
    costUnit = 'hour';
  } else {
    updatedStoragePrice = updatedStoragePriceMonthly;
    updatedCustomPersistentStoragePrice =
      updatedCustomPersistentStoragePriceMonthly;
    costUnit = 'month';
  }
  if (value === CustomStorageOption.CUSTOMSTORAGE) {
    selectedStorage = customStorageInput;
    selectedAvgCost = updatedStoragePrice;
  } else if (value === CustomStorageOption.CUSTOMPERSISTENTSTORAGE) {
    if (updatePersistentStorage === CustomStorageOption.CUSTOMHDDSTORAGE) {
      selectedStorage = customHddStorageInput;
      selectedAvgCost = updatedCustomPersistentStoragePrice;
    } else if (
      updatePersistentStorage === CustomStorageOption.CUSTOMSSDSTORAGE
    ) {
      selectedStorage = customSsdStorageInput;
      selectedAvgCost = updatedCustomPersistentStoragePrice;
    } else if (
      updatePersistentStorage === CustomStorageOption.CUSTOMNVMSTORAGE
    ) {
      selectedStorage = customNvmStorageInput;
      selectedAvgCost = updatedCustomPersistentStoragePrice;
    }
  }

  const [inputValue, setInputValue] = useState<number | ''>(selectedStorage);
  const avgCostPerMonth = isSelected ? `${selectedAvgCost}` : '--';
  const [showError, setShowError] = useState<boolean>(false);

  const storageUnitOptions = [
    {
      id: 1,
      optionType: 'primary' as OptionType,
      label: 'GB',
      value: '1',
    },
    {
      id: 2,
      optionType: 'primary' as OptionType,
      label: 'TB',
      value: '1000',
    },
  ];

  const [selectedStorageUnit, setSelectedStorageUnit] = useState<IOptions>(
    storageUnitOptions[0]
  );

  useEffect(() => {
    if (isSelected && selectedStorage !== inputValue) {
      if (
        value === CustomStorageOption.CUSTOMSTORAGE &&
        selectedStorage &&
        customStoragePrice
      ) {
        dispatchRtk(
          updateCustomStorageInputPriceRtk(
            `$${(customStoragePrice / 24).toFixed(2)}`
          )
        );
      } else if (
        value === CustomStorageOption.CUSTOMPERSISTENTSTORAGE &&
        selectedStorage &&
        customPersistentStoragePrice &&
        prevUpdatePersistentStorage === updatePersistentStorage
      ) {
        if (updatePersistentStorage === CustomStorageOption.CUSTOMHDDSTORAGE) {
          dispatchRtk(
            updateCustomHddStorageInputPriceRtk(
              `$${updatedCustomPersistentStoragePrice}`
            )
          );
        } else if (
          updatePersistentStorage === CustomStorageOption.CUSTOMSSDSTORAGE
        ) {
          dispatchRtk(
            updateCustomSsdStorageInputPriceRtk(
              `$${updatedCustomPersistentStoragePrice}`
            )
          );
        } else if (
          updatePersistentStorage === CustomStorageOption.CUSTOMNVMSTORAGE
        ) {
          dispatchRtk(
            updateCustomNvmStorageInputPriceRtk(
              `$${updatedCustomPersistentStoragePrice}`
            )
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedStorage,
    inputValue,
    isSelected,
    dispatchRtk,
    value,
    customStoragePrice,
    customPersistentStoragePrice,
    updatePersistentStorage,
    prevUpdatePersistentStorage,
  ]);

  useEffect(() => {
    if (
      value === CustomStorageOption.CUSTOMPERSISTENTSTORAGE &&
      selectedStorage &&
      prevUpdatePersistentStorage !== updatePersistentStorage
    ) {
      setInputValue(selectedStorage);
    }
  }, [
    selectedStorage,
    updatePersistentStorage,
    prevUpdatePersistentStorage,
    value,
  ]);

  const setValueForUpdate = useCallback(() => {
    let persistentStorageData;
    let storageData;
    if (isUpdate && activeDeployment) {
      persistentStorageData =
        activeDeployment.services[0].agreedMachineImage?.persistentStorage;
      storageData = activeDeployment.services[0].agreedMachineImage.storage;
    } else if (
      (persistentStorage && hasPersistentStorage) ||
      recommendedStorage
    ) {
      persistentStorageData = persistentStorage;
      storageData = recommendedStorage;
    }
    if (value === CustomStorageOption.CUSTOMSTORAGE) {
      const customInputStorage = sliceStringFromEnd(storageData || '', 2);
      dispatchRtk(calculatePriceThunk(LoadingType.STORAGE)).then(() => {
        dispatchRtk(updateCustomStorageInputRtk(Number(customInputStorage)));
      });
      return Number(customInputStorage);
    }
    if (
      value === CustomStorageOption.CUSTOMPERSISTENTSTORAGE &&
      persistentStorageData
    ) {
      const customPersistentStorage = Number(
        sliceStringFromEnd(persistentStorageData.size || '', 2)
      );
      switch (getPersistentStorageName(persistentStorageData.class || '')) {
        case PersistentStorageOption.HDD:
          dispatchRtk(updateCustomHddStorageRtk(customPersistentStorage));
          break;
        case PersistentStorageOption.SSD:
          dispatchRtk(updateCustomSsdStorageRtk(customPersistentStorage));
          break;
        case PersistentStorageOption.NVM:
          dispatchRtk(updateCustomNvmStorageRtk(customPersistentStorage));
          break;
        default:
          break;
      }
      return Number(sliceStringFromEnd(persistentStorageData?.size || '', 2));
    }
    return '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatchRtk,
    selectedInstance,
    selectedTemplate?.serviceData.customTemplateSpecs,
    hasPersistentStorage,
  ]);

  useEffect(() => {
    if (
      (isUpdate && selectedInstance) ||
      (persistentStorage &&
        hasPersistentStorage &&
        value === CustomStorageOption.CUSTOMPERSISTENTSTORAGE) ||
      (recommendedStorage && value === CustomStorageOption.CUSTOMSTORAGE)
    ) {
      setInputValue(setValueForUpdate());
      dispatchRtk(calculatePriceThunk(LoadingType.STORAGE));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isUpdate,
    selectedInstance,
    selectedTemplate?.serviceData.customTemplateSpecs,
    hasPersistentStorage,
  ]);
  const debouncedSearchTerm = useDebounce(inputValue.toString(), 200);
  const handleInputChange = (targetValue: number) => {
    onSelect();
    const updateValue = targetValue;
    setInputValue(updateValue);
  };
  useEffect(() => {
    if (debouncedSearchTerm && isSelected) {
      const updateValue =
        Number(selectedStorageUnit.value) * Number(debouncedSearchTerm);
      if (value === CustomStorageOption.CUSTOMSTORAGE) {
        dispatchRtk(updateCustomStorageRtk(updateValue));
        dispatchRtk(calculatePriceThunk(LoadingType.STORAGE)).then(() => {
          dispatchRtk(updateCustomStorageInputRtk(updateValue));
        });
      } else if (value === CustomStorageOption.CUSTOMPERSISTENTSTORAGE) {
        if (updatePersistentStorage === CustomStorageOption.CUSTOMHDDSTORAGE) {
          dispatchRtk(updateCustomHddStorageRtk(updateValue));
        } else if (
          updatePersistentStorage === CustomStorageOption.CUSTOMSSDSTORAGE
        ) {
          dispatchRtk(updateCustomSsdStorageRtk(updateValue));
        } else if (
          updatePersistentStorage === CustomStorageOption.CUSTOMNVMSTORAGE
        ) {
          dispatchRtk(updateCustomNvmStorageRtk(updateValue));
        }
        dispatchRtk(calculatePriceThunk(LoadingType.PERSISTENT_STORAGE)).then(
          () => {
            if (
              updatePersistentStorage === CustomStorageOption.CUSTOMHDDSTORAGE
            ) {
              dispatchRtk(updateCustomHddStorageInputRtk(updateValue));
            } else if (
              updatePersistentStorage === CustomStorageOption.CUSTOMSSDSTORAGE
            ) {
              dispatchRtk(updateCustomSsdStorageInputRtk(updateValue));
            } else if (
              updatePersistentStorage === CustomStorageOption.CUSTOMNVMSTORAGE
            ) {
              dispatchRtk(updateCustomNvmStorageInputRtk(updateValue));
            }
          }
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedSearchTerm,
    dispatchRtk,
    value,
    isSelected,
    selectedStorageUnit.value,
  ]);
  const handleClick = () => {
    if (selectedStorage < 0.1 || Number.isNaN(selectedStorage)) {
      setShowError(true);
      if (value === CustomStorageOption.CUSTOMSTORAGE) {
        handleInputChange(20);
        setShowError(false);
      } else if (value === CustomStorageOption.CUSTOMPERSISTENTSTORAGE) {
        handleInputChange(4);
        setShowError(false);
      }
    }
    if (selectedStorage >= 0.1 && !isSelected) {
      if (value === CustomStorageOption.CUSTOMSTORAGE) {
        dispatchRtk(updateCustomStorageRtk(selectedStorage));
        dispatchRtk(calculatePriceThunk(LoadingType.STORAGE));
      } else if (value === CustomStorageOption.CUSTOMPERSISTENTSTORAGE) {
        if (updatePersistentStorage === CustomStorageOption.CUSTOMHDDSTORAGE) {
          dispatchRtk(updateCustomHddStorageRtk(selectedStorage));
        } else if (
          updatePersistentStorage === CustomStorageOption.CUSTOMSSDSTORAGE
        ) {
          dispatchRtk(updateCustomSsdStorageRtk(selectedStorage));
        } else if (
          updatePersistentStorage === CustomStorageOption.CUSTOMNVMSTORAGE
        ) {
          dispatchRtk(updateCustomNvmStorageRtk(selectedStorage));
        }
        dispatchRtk(calculatePriceThunk(LoadingType.PERSISTENT_STORAGE));
      }
      onSelect();
    }
  };

  return (
    <>
      <button
        type="button"
        tabIndex={0}
        className={`border relative px-4 py-4 rounded-lg min-h-12 
      cursor-pointer dark:bg-dark-base-bg 
      border-base-border dark:border-dark-base-border
      hover:border-border-form-selected
      dark:hover:border-dark-form-selected ease-in duration-75
       ${
         isSelected
           ? 'border-border-form-selected dark:border-dark-form-selected'
           : 'border-base-border dark:border-dark-base-border'
       }`}
        onClick={handleClick}
      >
        <span
          className="text-base-heading-text-color dark:text-dark-base-heading-text-color
          text-base font-semibold text-left flex justify-start"
        >
          Custom Storage
        </span>
        <div className="flex items-center justify-between gap-x-2 ll:gap-x-3 mt-2">
          <Dropdown
            dropdownSize="compact"
            classname="w-[30%]"
            dropdownType="default"
            label=""
            placeholder="Storage Unit"
            filled
            onSelected={(value) => {
              setSelectedStorageUnit(value);
            }}
            subText=""
            defaultSelected={selectedStorageUnit?.value as string}
            options={storageUnitOptions}
            disable={!isSelected}
          />
          <input
            className={`flex justify-start bg-white border border-searchBorder 
            shadow-searchShadow pl-4 pr-2 h-10 w-[40%]
            rounded-lg text-text-filterBadge text-base font-normal 
            dark:bg-dark-base-bg max-w-[50%]
            dark:border-dark-base-border
            ${Styles.custom__input} ${showError ? '!border-red-400' : ''}`}
            placeholder="e.g 40 GB"
            value={`${selectedAvgCost && isSelected ? inputValue : 0}`}
            onChange={(e) => handleInputChange(Number(e.target.value))}
            min={0}
            type="number"
            onBlur={() => {
              if (selectedStorage < 0.1 || Number.isNaN(selectedStorage)) {
                if (value === CustomStorageOption.CUSTOMSTORAGE) {
                  handleInputChange(20);
                } else if (
                  value === CustomStorageOption.CUSTOMPERSISTENTSTORAGE
                ) {
                  handleInputChange(4);
                }
              }
            }}
          />
          <div className="w-[30%] flex flex-col text-left">
            <span className="text-text-instanceLightGray text-tiny ll:text-sm font-normal">
              {scalingType === ScalingType.MANUAL ? '' : 'Avg '}Cost/{costUnit}
            </span>
            <span className="text-text-filterBadge text-sm font-bold">
              ${avgCostPerMonth}
            </span>
          </div>
        </div>
        <>
          {isSelected && (
            <span className="absolute right-2 top-2">
              <Check className={Styles.check__icon} />
            </span>
          )}
        </>
      </button>
    </>
  );
};

export default React.memo(
  CustomStorage,
  (prevProps: IProps, nextProps: IProps) => {
    if (
      prevProps.value === nextProps.value &&
      prevProps.isSelected === nextProps.isSelected
    ) {
      return true;
    }
    return false;
  }
);
