/* eslint-disable import/no-cycle */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { bootIntercom, closeIntercom } from '../../libs/intercom';
import {
  createTokensThunk,
  deleteTokensThunk,
  getAPITokensThunk,
  getBannersThunk,
  getRaiseRequestLimitThunk,
  getReferralCodeThunk,
  getReferralCreditsThunk,
  getReferralDetailsThunk,
  getReferralWithdrawalsThunk,
  getUserDetailsThunk,
  getUserNotificationsThunk,
  loadMoreUserNotificationsThunk,
  readAllNotificationsThunk,
  removeProviderThunk,
  requestReferralWithdrawalThunk,
  seenBannerThunk,
  setReferralCodeThunk,
  updateProfileThunk,
  updateUserDefaultOrganisationThunk,
  updateUserNotificationsThunk,
  verifySignatureWalletThunk,
} from './user.thunks';
import { IOrganization, IUser } from '../combined-state.interface';
import {
  IAPIToken,
  IBanner,
  IReferrals,
  IReferralWithrawal,
  IRequestReferralWithdrawal,
  IUserNotification,
  IUserState,
} from './user.interfaces';
import { IResponseError } from '../combined-reducer.interface';
import { removeCommunityLocalVariables } from '../organisation/organisation.utils';
import { updateMasterOrganisationThunk } from '../organisation/organisation.thunks';
import config from '../../../config';
import { createUsername } from '../root-utils';

export const initialState: IUserState = {
  user: null,
  userLoading: true,
  userPlatformProfile: null,
  signupCallbackLoading: false,
  updateUserDataLoading: false,
  notifications: [],
  tokens: [],
  tokensLoading: true,
  creatingToken: false,
  deletingToken: false,
  tokenCreated: {
    created: false,
    value: '',
  },
  banner: [],
  notificationPagination: {
    currentPage: 0,
    numbersPerPage: config.notification.length,
  },
  referralEnabled: false,
  referralCode: '',
  referralCodeLoading: false,
  setReferralCodeLoading: false,
  referrals: [],
  referralLoading: false,
  referralCredits: { total: 0, platform: 0, withdrawable: 0 },
  referralCreditsLoading: false,
  withdrawalDetails: {
    userDetails: {
      firstName: '',
      lastName: '',
    },
    amount: 0,
    walletAddress: '',
  },
  withdrawals: [],
  withdrawalLoading: false,
  raiseWithdrawalRequestLoading: false,
  raiseRequestLimit: config.referral.MINIMUM_WITHDRAWAL_AMOUNT_IN_DOLLAR,
  raiseRequestLimitLoading: false,
  canDeploy: true,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<IUser>) {
      state.user = action.payload;
      state.userPlatformProfile = action.payload.platformProfile;
      if (action?.payload?.platformProfile?.email) {
        state.canDeploy = true;
      } else {
        state.canDeploy = false;
      }
    },

    logoutUser(state) {
      // closeIntercom();
      localStorage.removeItem('jwt-token');
      localStorage.setItem('logout', 'true');
      removeCommunityLocalVariables();
      window.open(`${window.location.origin}/login`, '_self');
      // bootIntercom();
      state.user = null;
    },

    toggleSignupCallbackLoading(state, action: PayloadAction<boolean>) {
      state.signupCallbackLoading = action.payload;
    },

    addUserNotification(state, action: PayloadAction<IUserNotification>) {
      state.notifications = [...state.notifications, action.payload];
    },

    toggleUpdateUserLoading(state, action: PayloadAction<boolean>) {
      state.updateUserDataLoading = action.payload;
    },

    updateUserOrganisation(state, action: PayloadAction<IOrganization>) {
      state.user!.organizations = [
        ...(state.user?.organizations || []),
        action.payload,
      ];
    },

    removeUserOrganisation(state, action: PayloadAction<string>) {
      state.user = {
        ...state.user!,
        organizations: state.user!.organizations.filter(
          (organization) => organization._id !== action.payload
        ),
      };
    },

    setTokenCreationState(state) {
      state.tokenCreated = {
        created: false,
        value: '',
      };
    },

    updateBannerStatus(
      state,
      action: PayloadAction<{
        bannerId: string;
        status: boolean;
      }>
    ) {
      const previousBanners = state.banner.filter(
        (banner) => banner._id !== action.payload.bannerId
      );
      const updatedBanner = state.banner.find(
        (banner) => banner._id === action.payload.bannerId
      );
      state.banner = previousBanners.concat([
        { ...updatedBanner!, active: action.payload.status },
      ]);
    },
    userSessionExpired(state) {
      state.user = null;
    },

    resetWithdrawalDetails(state) {
      state.withdrawalDetails = initialState.withdrawalDetails;
    },

    setWithdrawalDetails(
      state,
      action: PayloadAction<IRequestReferralWithdrawal>
    ) {
      state.withdrawalDetails = action.payload;
    },

    setReferralEnabled(state, action: PayloadAction<boolean>) {
      state.referralEnabled = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserDetailsThunk.pending, (state) => {
      state.userLoading = true;
    });
    builder.addCase(getUserDetailsThunk.fulfilled, (state) => {
      state.userLoading = false;
    });
    builder.addCase(getUserDetailsThunk.rejected, (state = initialState) => {
      return state;
    });

    builder.addCase(
      updateMasterOrganisationThunk.fulfilled,
      (state, action) => {
        const organisations = [...state.user?.organizations!].map((org) => {
          if (org._id === action.payload.id) {
            return {
              ...org,
              preferedAppType: action.payload.data.preferedAppType,
              profile: {
                ...org?.profile,
                name: action.payload.data.name || '',
                username: createUsername(String(action.payload.data.name)),
                image: action.payload.data.avatar,
              },
            };
          }
          return org;
        });

        state.user!.organizations = organisations as unknown as IOrganization[];
      }
    );

    builder.addCase(updateProfileThunk.pending, (state) => {
      state.updateUserDataLoading = true;
    });

    builder.addCase(updateProfileThunk.fulfilled, (state, action) => {
      state.updateUserDataLoading = false;
      state.userPlatformProfile = action.payload;
    });

    builder.addCase(updateProfileThunk.rejected, (state) => {
      state.updateUserDataLoading = false;
    });

    builder.addCase(
      updateUserDefaultOrganisationThunk.fulfilled,
      (state, action) => {
        if (state.user)
          state.user = {
            ...state.user,
            defaultOrganization: action.payload,
          };
      }
    );

    builder.addCase(getAPITokensThunk.pending, (state) => {
      state.tokensLoading = true;
    });

    builder.addCase(
      getAPITokensThunk.fulfilled,
      (state, action: PayloadAction<IAPIToken[]>) => {
        state.tokensLoading = false;
        state.tokens = action.payload;
      }
    );
    builder.addCase(getAPITokensThunk.rejected, (state) => {
      state.tokensLoading = false;
      state.tokens = [];
    });

    builder.addCase(deleteTokensThunk.pending, (state) => {
      state.deletingToken = true;
    });

    builder.addCase(
      deleteTokensThunk.fulfilled,
      (state, action: PayloadAction<string[]>) => {
        state.deletingToken = false;
        state.tokens = state.tokens.filter((token) => {
          return !action.payload.includes(token.id);
        });
      }
    );

    builder.addCase(deleteTokensThunk.rejected, (state) => {
      state.deletingToken = false;
    });

    builder.addCase(createTokensThunk.pending, (state) => {
      state.creatingToken = true;
    });

    builder.addCase(
      createTokensThunk.fulfilled,
      (state, action: PayloadAction<IAPIToken | IResponseError>) => {
        state.creatingToken = false;
        state.tokenCreated = {
          created: true,
          value: (action.payload as IAPIToken).value || '',
        };
      }
    );

    builder.addCase(createTokensThunk.rejected, (state) => {
      state.creatingToken = false;
    });

    builder.addCase(
      getUserNotificationsThunk.fulfilled,
      (state, action: PayloadAction<IUserNotification[]>) => {
        state.notifications = action.payload;
      }
    );

    builder.addCase(getUserNotificationsThunk.rejected, (state) => {
      state.notifications = [];
    });

    builder.addCase(
      loadMoreUserNotificationsThunk.fulfilled,
      (state, action: PayloadAction<IUserNotification[]>) => {
        state.notifications = [...state.notifications, ...action.payload];
        state.notificationPagination = {
          ...state.notificationPagination,
          currentPage: state.notificationPagination.currentPage + 1,
        };
      }
    );

    builder.addCase(
      updateUserNotificationsThunk.fulfilled,
      (state, action: PayloadAction<string>) => {
        state.notifications = [
          ...state.notifications.filter(
            (notification) => notification._id !== action.payload
          ),
          {
            ...(state.notifications.find(
              (notification) => notification._id !== action.payload
            ) as IUserNotification),
            read: true,
          },
        ];
      }
    );

    builder.addCase(
      removeProviderThunk.fulfilled,
      (state, action: PayloadAction<string>) => {
        state.user!.providerProfiles =
          state.user!.providerProfiles.filter(
            (profile) => profile.providerName !== action.payload
          ) || [];
      }
    );

    builder.addCase(readAllNotificationsThunk.fulfilled, (state) => {
      state.notifications = state.notifications.map((notification) => {
        return {
          ...notification,
          read: true,
        };
      });
    });

    builder.addCase(
      getBannersThunk.fulfilled,
      (state, action: PayloadAction<IBanner[]>) => {
        state.banner = action.payload;
      }
    );

    builder.addCase(seenBannerThunk.pending, (state, action) => {
      state.banner = state.banner.map((banner) => ({
        ...banner,
        active: banner._id === action.meta.arg ? false : banner.active,
      }));
    });

    builder.addCase(getReferralCodeThunk.pending, (state) => {
      state.referralCodeLoading = true;
    });

    builder.addCase(
      getReferralCodeThunk.fulfilled,
      (state, action: PayloadAction<string>) => {
        state.referralCode = action.payload;
        state.referralCodeLoading = false;
      }
    );

    builder.addCase(getReferralCodeThunk.rejected, (state) => {
      state.referralCodeLoading = false;
    });

    builder.addCase(getReferralDetailsThunk.pending, (state) => {
      state.referralLoading = true;
    });

    builder.addCase(
      getReferralDetailsThunk.fulfilled,
      (state, action: PayloadAction<IReferrals[]>) => {
        state.referrals = action.payload;
        state.referralLoading = false;
      }
    );

    builder.addCase(getReferralDetailsThunk.rejected, (state) => {
      state.referralLoading = false;
    });

    builder.addCase(getReferralCreditsThunk.pending, (state) => {
      state.referralCreditsLoading = true;
    });

    builder.addCase(
      getReferralCreditsThunk.fulfilled,
      (
        state,
        action: PayloadAction<{
          referralCredits: number;
          withdrawableCredits: number;
        }>
      ) => {
        state.referralCredits.total = action.payload.referralCredits;
        state.referralCredits.withdrawable = action.payload.withdrawableCredits;
        state.referralCredits.platform =
          action.payload.referralCredits - action.payload.withdrawableCredits;
        state.referralCreditsLoading = false;
      }
    );

    builder.addCase(getReferralCreditsThunk.rejected, (state) => {
      state.referralCreditsLoading = false;
    });

    builder.addCase(getReferralWithdrawalsThunk.pending, (state) => {
      state.withdrawalLoading = true;
    });

    builder.addCase(
      getReferralWithdrawalsThunk.fulfilled,
      (state, action: PayloadAction<IReferralWithrawal[]>) => {
        state.withdrawals = action.payload;
        state.withdrawalLoading = false;
      }
    );

    builder.addCase(getReferralWithdrawalsThunk.rejected, (state) => {
      state.withdrawalLoading = false;
    });

    builder.addCase(requestReferralWithdrawalThunk.pending, (state) => {
      state.raiseWithdrawalRequestLoading = true;
    });

    builder.addCase(requestReferralWithdrawalThunk.fulfilled, (state) => {
      state.raiseWithdrawalRequestLoading = false;
    });

    builder.addCase(requestReferralWithdrawalThunk.rejected, (state) => {
      state.raiseWithdrawalRequestLoading = false;
    });

    builder.addCase(verifySignatureWalletThunk.pending, (state) => {
      state.raiseWithdrawalRequestLoading = true;
    });

    builder.addCase(verifySignatureWalletThunk.rejected, (state) => {
      state.raiseWithdrawalRequestLoading = false;
    });

    builder.addCase(getRaiseRequestLimitThunk.pending, (state) => {
      state.raiseRequestLimitLoading = true;
    });

    builder.addCase(setReferralCodeThunk.pending, (state) => {
      state.setReferralCodeLoading = true;
    });

    builder.addCase(setReferralCodeThunk.fulfilled, (state) => {
      state.setReferralCodeLoading = false;
    });

    builder.addCase(setReferralCodeThunk.rejected, (state) => {
      state.setReferralCodeLoading = false;
    });

    builder.addCase(
      getRaiseRequestLimitThunk.fulfilled,
      (state, action: PayloadAction<{ limit: number }>) => {
        state.raiseRequestLimitLoading = false;
        state.raiseRequestLimit = action.payload.limit;
      }
    );

    builder.addCase(getRaiseRequestLimitThunk.rejected, (state) => {
      state.raiseRequestLimitLoading = false;
    });
  },
});

export const { reducer: userRTKReducer } = userSlice;

export const {
  setUser: setUserRtk,
  logoutUser: logoutUserRtk,
  toggleSignupCallbackLoading: toggleSignupCallbackLoadingRtk,
  addUserNotification: addUserNotificationRtk,
  toggleUpdateUserLoading: toggleUpdateUserLoadingRtk,
  updateUserOrganisation: updateUserOrganisationRtk,
  removeUserOrganisation: removeUserOrganisationRtk,
  setTokenCreationState: setTokenCreationStateRtk,
  updateBannerStatus: updateBannerStatusRtk,
  userSessionExpired: userSessionExpiredRtk,
  setWithdrawalDetails: setWithdrawalDetailsRtk,
  resetWithdrawalDetails: resetWithdrawalDetailsRtk,
  setReferralEnabled: setReferralEnabledRtk,
} = userSlice.actions;
