/* eslint-disable consistent-return */
import React from 'react';
import { PlanUsageCard as PlanUsageCardSection } from '@spheron/ui-library';
import {
  bytesForHuman,
  usageDataParse,
} from '../../../redux/compute/subscription/subscription.utils';
import config from '../../../config';

interface IProps {
  name: string;
  total: string;
  date: string;
  completed: string;
}

const PlanUsageCard = ({ name, date, total, completed }: IProps) => {
  let completeVal = '0';
  let totalVal = '0';
  const tooltipText: { [key: string]: string } = {
    BUILDEXECUTION:
      'This shows how long you can use a Spheron deployer to deploy your app.',
    CONCURRENTBUILD:
      'This shows the number of deployments you can run concurrently.',
    DEPLOYMENTSPERDAY:
      'This shows the number of deployments you can try every day.',
    DOMAINS: 'Shows the cumulative amounts of domains the organization has.',
    HNSDOMAINS:
      'Shows the cumulative amounts of handshake domains the organization has.',
    ENSDOMAINS:
      'Shows the cumulative amounts of ENS domains the organization has.',
    STORAGEIPFS:
      'This shows the amount of IPFS & Filecoin storage used to upload your deployments.',
    STORAGEFILECOIN:
      'This shows the amount of Filecoin storage used from total IPFS Storage.',
    STORAGEARWEAVE:
      'This shows the amount of Arweave storage used to upload your deployments.',
    CLUSTERAKT:
      // eslint-disable-next-line max-len
      "This shows the amount left in your organization's wallet for deploying compute instances.",
    IPFSBANDWIDTH:
      'This shows the IPFS bandwidth of all the IPFS links of your organization.',
    IPFSREQUESTS:
      'This shows the number of IPFS requests for all the IPFS links of your organization.',
    BANDWIDTH:
      'This shows the website bandwidth of all the domains inside your organization.',
    CLUSTERBUILDEXECUTION:
      'This shows how long you can use a Spheron deployer to deploy your instances.',
    REQUESTS:
      'This shows the number of website requests for all the domains inside your organization.',
    MEMBERS:
      'This shows the number of members that can be added to your organization.',
    IMAGEOPTIMIZATIONS: 'Shows the number of image optimizations done.',
    PARALLELUPLOADS:
      'This shows the number of uploads that can be done in parallel.',
    IPFSGATEWAYBANDWIDTH:
      'This shows the bandwidth of all the Gateways of your organization.',
    IPFSGATEWAYREQUESTS:
      'This shows the number of requests for all Gateways of your organization.',
  };

  const setUnit = (completed: string, name: string) => {
    switch (name) {
      case 'STORAGEARWEAVE':
      case 'STORAGEIPFS':
        completeVal = `${bytesForHuman(completed)}`;

        totalVal = `${bytesForHuman(total)}`;
        break;
      case 'STORAGEFILECOIN':
        completeVal = `${bytesForHuman(completed)}`;
        break;
      case 'BUILDEXECUTION':
        completeVal = `${(Number(completed) / 60).toFixed(1)}`;
        totalVal = `${(Number(total) / 60).toFixed(1)} min`;
        break;
      case 'CLUSTERAKT':
        completeVal = completed
          ? `${Number(
              parseFloat(completed).toFixed(
                config.dollarDecimalRound.DOLLAR_DECIMAL_ROUND
              )
            )}`
          : '0';

        totalVal =
          total === null
            ? '$0'
            : `$${Number(parseFloat(total)).toFixed(
                config.dollarDecimalRound.DOLLAR_DECIMAL_ROUND
              )}`;
        break;
      case 'CLUSTERBUILDEXECUTION':
        completeVal = completed
          ? `${(Number(completed) / 60).toFixed(1)}`
          : '0';
        totalVal =
          total === null ? '0 min' : `${(Number(total) / 60).toFixed(1)} min`;
        break;
      case 'BANDWIDTH':
      case 'IPFSBANDWIDTH':
      case 'IPFSGATEWAYBANDWIDTH':
        completeVal = completed ? `${bytesForHuman(completed)}` : '0';
        totalVal = total === null ? '0 ' : `${bytesForHuman(total)}`;
        break;
      case 'MEMBERS':
        completeVal = completed;
        totalVal = total;
        break;
      case 'IMAGEOPTIMIZATIONS':
        completeVal = completed;
        totalVal = total;
        break;
      default:
        completeVal = completed;
        totalVal = total;
    }
  };

  let overdue = false;
  setUnit(completed, name);
  if (completed >= total) {
    overdue = true;
  }

  const isUnlimited =
    name === 'DOMAINS' ||
    name === 'REQUESTS' ||
    name === 'IPFSREQUESTS' ||
    name === 'STORAGEFILECOIN' ||
    name === 'IPFSGATEWAYREQUESTS';

  return (
    <PlanUsageCardSection
      date={date}
      title={usageDataParse(name) || ''}
      total={total}
      completed={completed}
      displayTotal={totalVal}
      displayCompleted={completeVal}
      isUnlimited={isUnlimited}
      overdue={overdue}
      tooltipText={tooltipText[name]}
    />
  );
};

export default PlanUsageCard;
