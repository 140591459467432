import React, { Ref, useEffect, useRef } from 'react';
import { FitAddon } from 'xterm-addon-fit';
import 'xterm/css/xterm.css';
import { Terminal, ITerminalOptions, ITerminalAddon } from 'xterm';
import { useSelector } from 'react-redux';
import { isDarkTheme } from '../../redux/compute/theme/theme.utils';
import { RootState } from '../../redux/compute/store';
import { copyToClipboard } from '../../redux/compute/user/user.utils';

// TODO: Import xterm dynamically - SPH-4566
export interface IProps {
  className?: string;
  options?: ITerminalOptions;
  addons?: Array<ITerminalAddon>;
  // eslint-disable-next-line no-unused-vars
  onKey?(event: { key: string; domEvent: KeyboardEvent }): void;
  // eslint-disable-next-line no-unused-vars
  customKeyEventHandler?(event: KeyboardEvent): boolean;
  customRef?: Ref<unknown>;
  // eslint-disable-next-line no-unused-vars
  onTerminalPaste?: (value: string) => void;
  /**
   * Adds an event listener for when a binary event fires. This is used to
   * enable non UTF-8 conformant binary messages to be sent to the backend.
   * Currently this is only used for a certain type of mouse reports that
   * happen to be not UTF-8 compatible.
   * The event value is a JS string, pass it to the underlying pty as
   * binary data, e.g. `pty.write(Buffer.from(data, 'binary'))`.
   */
  // eslint-disable-next-line no-unused-vars
  onBinary?(data: string): void;

  /**
   * Adds an event listener for the cursor moves.
   */
  onCursorMove?(): void;

  /**
   * Adds an event listener for when a data event fires. This happens for
   * example when the user types or pastes into the terminal. The event value
   * is whatever `string` results, in a typical setup, this should be passed
   * on to the backing pty.
   */
  // eslint-disable-next-line no-unused-vars
  onData?(data: string): void;
  /**
   * Adds an event listener for when a line feed is added.
   */
  // eslint-disable-next-line no-unused-vars
  onLineFeed?(): void;

  /**
   * Adds an event listener for when a scroll occurs. The event value is the
   * new position of the viewport.
   * @returns an `IDisposable` to stop listening.
   */
  // eslint-disable-next-line no-unused-vars
  onScroll?(newPosition: number): void;

  /**
   * Adds an event listener for when a selection change occurs.
   */
  onSelectionChange?(): void;

  /**
   * Adds an event listener for when rows are rendered. The event value
   * contains the start row and end rows of the rendered area (ranges from `0`
   * to `Terminal.rows - 1`).
   */
  // eslint-disable-next-line no-unused-vars
  onRender?(event: { start: number; end: number }): void;

  /**
   * Adds an event listener for when the terminal is resized. The event value
   * contains the new size.
   */
  // eslint-disable-next-line no-unused-vars
  onResize?(event: { cols: number; rows: number }): void;

  /**
   * Adds an event listener for when an OSC 0 or OSC 2 title change occurs.
   * The event value is the new title.
   */
  // eslint-disable-next-line no-unused-vars
  onTitleChange?(newTitle: string): void;

  /**
   * Attaches a custom key event handler which is run before keys are
   * processed, giving consumers of xterm.js ultimate control as to what keys
   * should be processed by the terminal and what keys should not.
   *
   * @param event The custom KeyboardEvent handler to attach.
   * This is a function that takes a KeyboardEvent, allowing consumers to stop
   * propagation and/or prevent the default action. The function returns
   * whether the event should be processed by xterm.js.
   */
  // eslint-disable-next-line no-unused-vars
  customKeyEventHandler?(event: KeyboardEvent): boolean;
}

export type XTermRefType = {
  // eslint-disable-next-line no-unused-vars
  write: (data: string | Uint8Array, callback?: () => void) => void;
  // eslint-disable-next-line no-unused-vars
  loadAddon: (addon: ITerminalAddon) => void;
  clear: () => void;
  reset: () => void;
  focus: () => void;
  // eslint-disable-next-line no-unused-vars
  paste: (data: string) => void;
};

export const XTerm: React.FunctionComponent<IProps> = ({
  className = '',
  options = {} as ITerminalOptions,
  addons = [],
  customKeyEventHandler,
  customRef,
  onTerminalPaste,
  onKey,
  onBinary,
  onCursorMove,
  onData,
  onLineFeed,
  onScroll,
  onSelectionChange,
  onRender,
  onResize,
  onTitleChange,
}) => {
  const bgTheme = useSelector((state: RootState) => state.theme.theme);
  const terminalEleRef = useRef<HTMLDivElement>(null);
  // infer better type from usage
  const terminalRef: any = useRef<{ current: Terminal }>(null);
  React.useImperativeHandle(customRef, () => ({
    write: (data: string | Uint8Array, callback?: () => void) =>
      terminalRef.current?.write(data, callback),
    loadAddon: (addon: ITerminalAddon) => terminalRef.current?.loadAddon(addon),
    clear: () => terminalRef.current?.clear(),
    reset: () => terminalRef.current?.reset(),
    focus: () => terminalRef.current?.focus(),
    paste: (data: string) => terminalRef.current?.paste(data),
  }));
  const onBinaryCommand = (data: string) => {
    if (onBinary) onBinary(data);
  };

  const onCursorMoveCommand = () => {
    if (onCursorMove) onCursorMove();
  };

  const onDataCommand = (data: string) => {
    if (onData) onData(data);
  };

  const onKeyCommand = (event: { key: string; domEvent: KeyboardEvent }) => {
    if (onKey) onKey(event);
  };

  const onLineFeedCommand = () => {
    if (onLineFeed) onLineFeed();
  };

  const onScrollCommand = (newPosition: number) => {
    if (onScroll) onScroll(newPosition);
  };

  const onSelectionChangeCommand = () => {
    if (onSelectionChange) onSelectionChange();
  };

  const onRenderCommand = (event: { start: number; end: number }) => {
    if (onRender) onRender(event);
  };

  const onResizeCommand = (event: { cols: number; rows: number }) => {
    if (onResize) onResize(event);
  };

  const onTitleChangeCommand = (newTitle: string) => {
    if (onTitleChange) onTitleChange(newTitle);
  };

  useEffect(() => {
    terminalRef.current = new Terminal({
      ...options,
      allowProposedApi: true,
      theme: {
        background: isDarkTheme(bgTheme) ? '#2C2C30FF' : '#FAFAFAFF',
        foreground: isDarkTheme(bgTheme) ? '#F8FAFC' : 'black',
        cursor: 'black',
        cursorAccent: '#F8FAFC',
        selectionBackground: 'black',
        selectionForeground: '#F8FAFC',
        selectionInactiveBackground: 'black',
      },
      cursorBlink: true,
    });

    terminalRef.current.attachCustomKeyEventHandler(
      (keyEvent: KeyboardEvent) => {
        // Handle pasting with ctrl or cmd + v
        if (
          (keyEvent.ctrlKey || keyEvent.metaKey) &&
          keyEvent.code === 'KeyV' &&
          keyEvent.type === 'keydown'
        ) {
          if (onTerminalPaste) {
            navigator.clipboard.readText().then(
              (value) => {
                if (onTerminalPaste) {
                  onTerminalPaste(value);
                }
              },
              (err) => {
                console.error(
                  'Async: Could not read text from clipboard: ',
                  err
                );
              }
            );
          }
        }

        // Handle pasting with ctrl or cmd + c
        if (
          (keyEvent.ctrlKey || keyEvent.metaKey) &&
          keyEvent.code === 'KeyC' &&
          keyEvent.type === 'keydown'
        ) {
          const selection = terminalRef.current?.getSelection();
          if (selection) {
            copyToClipboard(selection);
            return false;
          }
        }
        return true;
      }
    );

    const fitAddon = new FitAddon();
    terminalRef.current.loadAddon(fitAddon);

    // Load addons if the prop exists.
    if (addons) {
      addons.forEach((addon) => {
        terminalRef.current?.loadAddon(addon);
      });
    }

    // Create Listeners
    terminalRef.current.onBinary(onBinaryCommand);
    terminalRef.current.onCursorMove(onCursorMoveCommand);
    terminalRef.current.onData(onDataCommand);
    terminalRef.current.onKey(onKeyCommand);
    terminalRef.current.onLineFeed(onLineFeedCommand);
    terminalRef.current.onScroll(onScrollCommand);
    terminalRef.current.onSelectionChange(onSelectionChangeCommand);
    terminalRef.current.onRender(onRenderCommand);
    terminalRef.current.onResize(onResizeCommand);
    terminalRef.current.onTitleChange(onTitleChangeCommand);

    // Add Custom Key Event Handler
    if (customKeyEventHandler) {
      terminalRef.current.attachCustomKeyEventHandler(customKeyEventHandler);
    }

    // Open terminal
    terminalRef.current.open(terminalEleRef.current);

    fitAddon.fit();

    return () => {
      // When the component unmounts dispose of the terminal and all of its listeners.
      terminalRef.current.dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (terminalRef && terminalRef.current) {
      terminalRef.current.options.theme = {
        background: isDarkTheme(bgTheme) ? '#2C2C30FF' : '#FAFAFAFF',
        foreground: isDarkTheme(bgTheme) ? '#F8FAFC' : 'black',
        cursor: 'black',
        cursorAccent: '#F8FAFC',
        selectionBackground: 'black',
        selectionForeground: '#F8FAFC',
        selectionInactiveBackground: 'black',
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bgTheme, terminalRef, terminalRef.current]);

  return <div className={className} ref={terminalEleRef} />;
};
XTerm.defaultProps = {
  className: '',
  options: {} as ITerminalOptions,
  addons: [],
  onKey: undefined,
  onBinary: undefined,
  onCursorMove: undefined,
  onData: undefined,
  onLineFeed: undefined,
  onScroll: undefined,
  onSelectionChange: undefined,
  onRender: undefined,
  onResize: undefined,
  onTitleChange: undefined,
  customKeyEventHandler: undefined,
  customRef: undefined,
  onTerminalPaste: undefined,
};
