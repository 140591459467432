import dayjs from 'dayjs';
import { PaymentMethod } from '../combined-state.interface';
import {
  IBonus,
  IBonusPaymentInvoice,
  INewMember,
  INewMemberPaymentInvoice,
  ISubscriptionInvoice,
  ISubscriptionPaymentInvoice,
  IBonusPayments,
  ISubscriptionPlan,
} from './subscription.interfaces';

export const bonusParse = (parameter: string, value?: number) => {
  let params;
  let rate;
  let val;
  let livePrice;
  switch (parameter) {
    // SCOPE: MIGHT BE INCLUDED IN FUTURE
    // case 'BONUS_BUILD_EXECUTION':
    //   params = 'Build Execution';
    //   rate = '$6 / 500 mins';
    //   val =
    //     value === 1 ? `${value / 60} min` : `${(value as number) / 60} mins`;
    //   livePrice = 6 / 500;
    //   break;
    case 'BONUS_BANDWIDTH':
    case 'bandwidth':
      params = 'CDN Bandwidth';
      rate = '$40 / 100 GB';
      val = `${value} GB`;
      livePrice = 40 / 100;
      break;
    case 'BONUS_IPFS_BANDWIDTH':
      params = 'IPFS Bandwidth';
      rate = '$40 / 100 GB';
      val = `${value} GB`;
      livePrice = 40 / 100;
      break;
    case 'BONUS_GATEWAY_BANDWIDTH':
      params = 'Gateway Bandwidth';
      rate = '$40 / 100 GB';
      val = `${value} GB`;
      livePrice = 40 / 100;
      break;
    case 'BONUS_CONCURRENT_BUILD':
      params = 'Concurrent Builds';
      rate = '$30 / build';
      val = `${value} Build${value !== 1 ? 's' : ''}`;
      livePrice = 30 / 1;
      break;
    case 'BONUS_PARALLEL_UPLOADS':
      params = 'Parallel Uploads';
      rate = '$2 / upload';
      val = `${value} Upload${value !== 1 ? 's' : ''}`;
      livePrice = 2 / 1;
      break;
    // WILL REMOVE THIS AFTER DB MIGRATION
    case 'BONUS_STORAGE':
    case 'Storage':
      params = 'Storage';
      rate = '1 Package / 1MB Arweave, 20MB Skynet, 10 MB IPFS, 10 MB Filecoin';
      val = `${value} MB Arweave, ${(value || 1) * 20} MB Skynet, ${
        (value || 1) * 10
      } MB IPFS, ${(value || 1) * 10} MB Filecoin`;
      livePrice = 0.1;

      break;
    case 'BONUS_DEPLOYMENT_PER_DAY':
      params = 'Deployments Per Day';
      rate = '$10 / 1000 deployments';
      val = `${value} Deployment${value !== 1 ? 's' : ''}`;
      livePrice = 10 / 1000;
      break;
    case 'BONUS_DOMAIN_LIMIT':
    case 'Domains':
      params = 'Domain Limit';
      rate = '$10 / 50 domains';
      val = `${value} Domain${value !== 1 ? 's' : ''}`;
      livePrice = 10 / 50;
      break;
    case 'BONS_HNS_DOMAIN_LIMIT':
    case 'HnsDomains':
      params = 'HNS Domain Limit';
      rate = '$1 / domain';
      val = `${value} Domain${value !== 1 ? 's' : ''}`;
      livePrice = 1;
      break;
    case 'BONUS_ENS_DOMAIN_LIMIT':
    case 'EnsDomains':
      params = 'ENS Domain Limit';
      rate = '$1 / domain';
      val = `${value} Domain${value !== 1 ? 's' : ''}`;
      livePrice = 1;
      break;
    case 'BONUS_ENVIRONMENTS':
    case 'Environments':
      params = 'Environments';
      rate = '$5 / environment';
      val = `${value} Env${value !== 1 ? 's' : ''}`;
      livePrice = 5;
      break;
    case 'Members':
      rate = '1';
      params = 'Members';
      break;
    case 'BONUS_STORAGE_IPFS':
      params = 'IPFS Storage';
      rate = '$0.3 / 3 GB';
      val = `${value} GB`;
      livePrice = 0.3 / 3;
      break;
    case 'BONUS_STORAGE_ARWEAVE':
      params = 'Arweave Storage';
      rate = `$10 / 1.5 GB`;
      val = `${value} GB`;
      livePrice = 10 / 1.5;
      break;
    case 'BONUS_CLUSTER_BUILD_EXECUTION':
      params = 'Cluster Build Execution';
      rate = '$6 / 500 mins';
      val =
        value === 1 ? `${value / 60} min` : `${(value as number) / 60} mins`;
      livePrice = 6 / 500;
      break;
    case 'BONUS_CLUSTER_AKT':
      params = 'Compute Credits';
      rate = '$1 / 1 unit';
      val = `${value}`;
      livePrice = 1;
      break;
    case 'SOLANA_MEMBERS':
      params = 'Member';
      rate = '1';
      break;
    case 'BONUS_PASSWORD_PROTECTION':
      params = 'Password Protection';
      rate = '$120 per organisation';
      val = `${value}`;
      livePrice = 120;
      break;
    case 'BONUS_IMAGE_OPTIMIZATION':
      params = 'Image Optimization';
      rate = '$4 / 1000 images';
      val = `${value}`;
      livePrice = 4 / 1000;
      break;
    default:
      params = '';
      rate = '';
      val = '';
      livePrice = 0;
      break;
  }
  // eslint-disable-next-line consistent-return
  return { params, rate, val, livePrice };
};

export const usageDataParse = (parameter: string) => {
  let params;

  switch (parameter) {
    case 'BUILDEXECUTION':
      params = 'Build Execution';
      break;
    case 'IPFSBANDWIDTH':
      params = 'IPFS Bandwidth';
      break;
    case 'IPFSREQUESTS':
      params = 'IPFS Requests';
      break;
    case 'BANDWIDTH':
      params = 'CDN Bandwidth';
      break;
    case 'REQUEST':
      params = 'Request';
      break;
    case 'CONCURRENTBUILD':
      params = 'Concurrent Builds';
      break;
    case 'DEPLOYMENTSPERDAY':
      params = 'Deployments Per Day';
      break;
    case 'DOMAINS':
      params = 'Domain';
      break;
    case 'HNSDOMAINS':
      params = 'HNS Domain';
      break;
    case 'ENSDOMAINS':
      params = 'ENS Domain';
      break;
    case 'ENVIRONMENTS':
      params = 'Environments';
      break;
    case 'STORAGEARWEAVE':
      params = 'Arweave Storage';
      break;
    case 'STORAGEIPFS':
      params = 'IPFS & Filecoin Storage';
      break;
    case 'STORAGEFILECOIN':
      params = 'Filecoin Storage';
      break;
    case 'CLUSTERAKT':
      params = 'Wallet Usage';
      break;
    case 'CLUSTERBUILDEXECUTION':
      params = 'Cluster Build Execution';
      break;
    case 'REQUESTS':
      params = 'Requests';
      break;
    case 'MEMBERS':
      params = 'Members';
      break;
    case 'IMAGEOPTIMIZATIONS':
      params = 'Image Optimization';
      break;
    case 'PARALLELUPLOADS':
      params = 'Parallel Uploads';
      break;
    case 'IPFSGATEWAYBANDWIDTH':
      params = 'Dedicated Gateway Bandwidth';
      break;
    case 'IPFSGATEWAYREQUESTS':
      params = 'Dedicated Gateway Requests';
      break;

    default:
      return;
  }
  // eslint-disable-next-line consistent-return
  return params;
};

export const mapTokenToId = (token: string) => {
  const tokens = new Map<string, number>();
  tokens.set('USDT', 825);
  tokens.set('WMATIC', 3890);
  tokens.set('WETH', 2396);
  tokens.set('DAI', 4943);
  tokens.set('USDC', 3408);
  tokens.set('AKT', 7431);
  tokens.set('MATIC', 3890);
  tokens.set('SOL', 5426);
  tokens.set('WAVAX', 5805);
  tokens.set('WBNB', 1839);
  tokens.set('tFIL', 2280);
  tokens.set('FIL', 2280);
  tokens.set('USDbC', 3408);
  tokens.set('WMNT', 27614);
  tokens.set('WXDAI', 9021);
  return tokens.get(token);
};

export const invoicesParser = (invoice: ISubscriptionInvoice) => {
  const subscriptionInvoice: ISubscriptionPaymentInvoice | null =
    invoice.packagePayment
      ? {
          id: invoice.packagePayment._id,
          network: invoice.packagePayment.network,
          liveTokenPrice: Number(invoice.packagePayment.tokenLivePrice),
          transactionHash: invoice.packagePayment.transactionHash,
          token: invoice.packagePayment.tokenAddress,
          name: invoice.packageName,
          units: invoice.packagePayment.numberOfUnits,
          unitPrice: invoice.packagePayment.unitPrice,
          chainId: invoice.packagePayment.chainId,
          totalDollarPrice:
            (invoice.packagePayment.paymentMethod ===
              PaymentMethod.CREDITCARD ||
            invoice.packagePayment.paymentMethod === PaymentMethod.SPHERONWALLET
              ? Number(invoice.packagePayment.fee)
              : Number(invoice.packagePayment.fee) *
                Number(invoice.packagePayment.tokenLivePrice)) || 0,
          totalTokenPrice: Number(invoice.packagePayment.fee),
          coupon: invoice.packagePayment.coupon,
          numberOfUnitsUnderLimit:
            invoice.packagePayment.numberOfUnitsUnderLimit,
          unitPriceUnderLimit: invoice.packagePayment.unitPriceUnderLimit,
          paymentMethod: invoice.packagePayment.paymentMethod,
          walletDetails: invoice.packagePayment?.walletDetails,
          invoices: invoice.subscription.invoices,
          includedMembers: (
            invoice.subscription.subscriptionPackageId as ISubscriptionPlan
          ).includedMembers,
        }
      : null;
  const bonusInvoices: IBonusPaymentInvoice[] = [];
  invoice.bonusesPayments.forEach((bonusPayment: IBonusPayments) => {
    bonusPayment.bonuses.forEach((b) => {
      const correspondingBonus = invoice.bonuses.find((_bonus: IBonus) => {
        return _bonus._id === b;
      });
      bonusInvoices.push({
        id: correspondingBonus?._id || '',
        network: bonusPayment.network,
        liveTokenPrice: Number(bonusPayment.tokenLivePrice),
        transactionHash: bonusPayment.transactionHash,
        token: bonusPayment.tokenAddress,
        livePrice: Number(correspondingBonus?.tokenLivePrice),
        chainId: bonusPayment.chainId,
        coupon: bonusPayment.coupon,
        value: correspondingBonus?.value || 0,
        rate: bonusParse(
          correspondingBonus?.paymentParameterName || '',
          correspondingBonus?.value
        ).rate,
        usage:
          bonusParse(
            correspondingBonus?.paymentParameterName || '',
            correspondingBonus?.value
          ).val || '',
        name: bonusParse(
          correspondingBonus?.paymentParameterName || '',
          correspondingBonus?.value
        ).params,
        totalDollarPrice:
          (bonusPayment.paymentMethod === PaymentMethod.CREDITCARD ||
          bonusPayment.paymentMethod === PaymentMethod.SPHERONWALLET
            ? Number(correspondingBonus?.fee)
            : Number(correspondingBonus?.fee) *
              Number(correspondingBonus?.tokenLivePrice)) || 0,
        totalTokenPrice: Number(bonusPayment.fee),
        paymentMethod: bonusPayment.paymentMethod,
        walletDetails: bonusPayment.walletDetails,
        redirectUrl: bonusPayment?.additionalInformation?.redirectUrl,
        paymentProvider: bonusPayment?.paymentProvider,
      });
    });
  });

  const newMemberInvoices: INewMemberPaymentInvoice[] =
    invoice.newMemberPayments.map((newMember: INewMember) => ({
      name: newMember.username,
      id: newMember.payment._id,
      network: newMember.payment.network,
      units: newMember.payment.numberOfUnits,
      unitPrice: newMember.payment.unitPrice,
      chainId: newMember.payment.chainId,
      liveTokenPrice: Number(newMember.payment.tokenLivePrice),
      transactionHash: newMember.payment.transactionHash,
      token: newMember.payment.tokenAddress,
      totalDollarPrice:
        (invoice.packagePayment.paymentMethod === PaymentMethod.CREDITCARD ||
        invoice.packagePayment.paymentMethod === PaymentMethod.SPHERONWALLET
          ? Number(newMember.payment.fee)
          : Number(newMember.payment.fee) *
            Number(newMember.payment.tokenLivePrice)) || 0,
      totalTokenPrice: Number(newMember.payment.fee),
      coupon: newMember.payment.coupon,
      paymentMethod: newMember.payment.paymentMethod,
      walletDetails: newMember.payment?.walletDetails || null,
    }));

  const pendingBonusInvoice = invoice.pendingBonuses.map((bonus: IBonus) => ({
    id: bonus._id,
    rate: bonusParse(bonus?.paymentParameterName || '', bonus?.value).rate,
    usage:
      bonusParse(bonus?.paymentParameterName || '', bonus?.value).val || '',
    name: bonusParse(bonus?.paymentParameterName || '', bonus?.value).params,
    value: bonus?.value,
  }));
  return {
    subscriptionInvoice,
    bonusInvoices,
    newMemberInvoices,
    pendingBonusInvoice,
  };
};

export const planDetails = (plan: string | undefined) => {
  switch (plan) {
    case 'Nano':
      return { name: 'Nano Plan', cost: 49 };
    case 'Medium':
      return { name: 'Medium Plan', cost: 99 };
    case 'Premium':
      return { name: 'Premium Plan', cost: 149 };
    default:
      return { name: 'N/A', cost: 0 };
  }
};

export const formatStorage = (storage: string) => {
  if (storage)
    return `${storage.toString().substring(0, storage.length - 2)} GB`;
  return '';
};

export const convertPlanText = (plan: string): string => {
  if (plan === 'ENSDomainLimit' || plan === 'HNSDomainLimit') {
    return `${plan.split('Domain')[0]} Domain ${plan.split('Domain')[1]}`;
  }
  if (plan === 'IpfsStorage') {
    return 'IPFS Storage';
  }
  const result = plan.replace(/([A-Z])/g, ' $1');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

export const bytesForHuman = (bytes: string) => {
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  let l = 0;
  let n = parseFloat(bytes) || 0;

  // eslint-disable-next-line no-plusplus
  while (n >= 1024 && ++l) {
    n /= 1024;
  }

  return `${n.toFixed(n < 10 && l > 0 ? 1 : 0)} ${units[l]}`;
};

// SOLANA BONUS PARSE
export const solanaBonusParse = (parameter: string, value?: number) => {
  let params;
  let rate;
  let val;
  let livePrice;
  switch (parameter) {
    case 'BONUS_BANDWIDTH':
    case 'bandwidth':
      params = 'CDN Bandwidth';
      rate = '$40 / 100 GB';
      val = `${value} GB`;
      livePrice = 40 / 100;
      break;
    case 'BONUS_IPFS_BANDWIDTH':
      params = 'IPFS Bandwidth';
      rate = '$40 / 100 GB';
      val = `${value} GB`;
      livePrice = 40 / 100;
      break;
    case 'BONUS_GATEWAY_BANDWIDTH':
      params = 'Gateway Bandwidth';
      rate = '$40 / 100 GB';
      val = `${value} GB`;
      livePrice = 40 / 100;
      break;
    case 'BONUS_CONCURRENT_BUILD':
      params = 'Concurrent Builds';
      rate = '$30 / build';
      val = `${value} Build${value !== 1 ? 's' : ''}`;
      livePrice = 30 / 1;
      break;
    case 'BONUS_PARALLEL_UPLOADS':
      params = 'Parallel Uploads';
      rate = '$2 / parallel upload';
      val = `${value} Uploads${value !== 1 ? 's' : ''}`;
      livePrice = 2 / 1;
      break;
    case 'BONUS_STORAGE':
    case 'Storage':
      params = 'Storage';
      rate =
        '1 Package / 1MB Arweave, 20MB Skynet, 10 MB Pinata, 10 MB Filecoin';
      val = `${value} MB Arweave, ${(value || 1) * 20} MB Skynet, ${
        (value || 1) * 10
      } MB Pinata, ${(value || 1) * 10} MB Filecoin`;
      livePrice = 0.1;

      break;
    case 'BONUS_DEPLOYMENT_PER_DAY':
      params = 'Deployments Per Day';
      rate = '$10 / 1000 deployments';
      val = `${value} Deployment${value !== 1 ? 's' : ''}`;
      livePrice = 10 / 1000;
      break;
    case 'BONUS_DOMAIN_LIMIT':
    case 'Domains':
      params = 'Domain Limit';
      rate = '$10 / 50 domains';
      val = `${value} Domain${value !== 1 ? 's' : ''}`;
      livePrice = 10 / 50;
      break;
    case 'BONS_HNS_DOMAIN_LIMIT':
    case 'HnsDomains':
      params = 'HNS Domain Limit';
      rate = '$1 / domain';
      val = `${value} Domain${value !== 1 ? 's' : ''}`;
      livePrice = 1;
      break;
    case 'BONUS_ENS_DOMAIN_LIMIT':
    case 'EnsDomains':
      params = 'ENS Domain Limit';
      rate = '$1 / domain';
      val = `${value} Domain${value !== 1 ? 's' : ''}`;
      livePrice = 1;
      break;
    case 'BONUS_ENVIRONMENTS':
    case 'Environments':
      params = 'Environments';
      rate = '$5 / environment';
      val = `${value} Env${value !== 1 ? 's' : ''}`;
      livePrice = 5;
      break;
    case 'Members':
      rate = '1';
      params = 'Members';
      break;
    case 'BONUS_STORAGE_IPFS':
      params = 'IPFS Storage';
      rate = '$0.3 / 3 GB';
      val = `${value} GB`;
      livePrice = 0.3 / 3;
      break;
    case 'BONUS_STORAGE_ARWEAVE':
      params = 'Arweave Storage';
      rate = `$10 / 1.5 GB`;
      val = `${value} GB`;
      livePrice = 10 / 1.5;
      break;
    case 'BONUS_CLUSTER_BUILD_EXECUTION':
      params = 'Cluster Build Execution';
      rate = '$6 / 500 mins';
      val =
        value === 1 ? `${value / 60} min` : `${(value as number) / 60} mins`;
      livePrice = 6 / 500;
      break;
    case 'BONUS_CLUSTER_AKT':
      params = 'AKT Tokens in Dollars';
      rate = '$1 / 1 unit';
      val = `${value}`;
      livePrice = 1;
      break;
    default:
      params = '';
      rate = '';
      val = '';
      livePrice = 0;
      break;
  }
  // eslint-disable-next-line consistent-return
  return { params, rate, val, livePrice };
};

export const calculateAmount = (
  subscriptionInvoice: ISubscriptionPaymentInvoice | null,
  bonusInvoices: IBonusPaymentInvoice[],
  newMemberInvoices: INewMemberPaymentInvoice[]
) => {
  const bonusAmount = bonusInvoices.reduce(
    (amount: number, bonus: IBonusPaymentInvoice) => {
      return amount + bonus.totalDollarPrice;
    },
    0
  );
  const newMemberAmount = newMemberInvoices.reduce(
    (amount: number, newMember: INewMemberPaymentInvoice) => {
      return amount + newMember.totalDollarPrice;
    },
    0
  );

  return (
    (subscriptionInvoice?.totalDollarPrice || 0) + bonusAmount + newMemberAmount
  );
};

export const sliceStringFromEnd = (
  text: string,
  sliceCharacterCount: number
): string => {
  return text?.slice(0, text?.length - sliceCharacterCount);
};

export const getPlanIcon = (planName: string) => {
  switch (planName) {
    case 'STORAGE_LITE': {
      return '🚀';
    }
    case 'STORAGE_PRO': {
      return '⭐️';
    }
    case 'STORAGE_WAGMI': {
      return '🏢️';
    }
    default: {
      return '⭐️';
    }
  }
};

export const unixTimestampToMonthYearDate = (unixTimestamp: number) => {
  const formattedDate = dayjs(unixTimestamp).format('DD MMM YY');
  return formattedDate;
};
