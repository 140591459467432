import React from 'react';
import Skeleton from 'react-loading-skeleton';

const ScalingLoading = () => {
  return (
    <div
      className="bg-base-bg dark:bg-dark-base-bg border-base-border dark:border-dark-base-border
      border rounded-lg w-full h-[108px] p-3 relative"
    >
      <div className="flex justify-between">
        <div className="flex flex-col items-start">
          <div className="flex items-center justify-start">
            <Skeleton height={16} width={100} duration={2} />
          </div>
          <Skeleton height={36} width={160} duration={2} />
        </div>
        <Skeleton
          height={16}
          width={80}
          duration={2}
          className="absolute right-3 bottom-3"
        />
      </div>
    </div>
  );
};

export default ScalingLoading;
