import { EmptyState, SectionHeading, ServiceCard } from '@spheron/ui-library';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/compute/store';
// eslint-disable-next-line max-len
import { ReactComponent as DockerCredentialIcon } from '../../../../assets/compute/icons/docker-credential-icon.svg';
import { IService } from '../../../../redux/compute/instance/instance.interfaces';
import { setSelectedServiceRtk } from '../../../../redux/compute/service/service.slice';
import { withLoader } from '../../../../redux/compute/root-utils';
import ServiceCardLoader from '../../../../components/Compute/Loaders/service-card-loader';
import {
  getComputeInstanceDetailsThunk,
  getDeploymentDetailsThunk,
} from '../../../../redux/compute/instance/instance.thunks';
import { IInstance } from '../../../../redux/compute/project/project.interfaces';
import GlobalStyles from '../../../../styles/compute/global.module.scss';
import { isHyperlink } from '../../../../redux/compute/service/service.utils';

const InstanceOverview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const params = useParams();
  const selectedOrganisationLoading = useSelector(
    (state: RootState) => state.organisation.selectedOrganisationLoading
  );
  const selectedInstance = useSelector(
    (state: RootState) => state.instance.selectedInstance
  );
  const selectedInstanceLoading = useSelector(
    (state: RootState) => state.instance.selectedInstanceLoading
  );
  const activeDeploymentLoading = useSelector(
    (state: RootState) => state.instance.activeDeploymentLoading
  );
  const activeDeployment = useSelector(
    (state: RootState) => state.instance.activeDeployment
  );
  const services = activeDeploymentLoading
    ? ([] as IService[])
    : activeDeployment?.services;

  const handleServiceButtonClick = (name: string) => {
    if (selectedInstance?.state?.toLowerCase() !== 'starting') {
      dispatch(
        setSelectedServiceRtk(
          services?.find((service: any) => service.name === name)! as any
        )
      );
      navigate(
        `/${params.orgUsername}/${params.projectId}/${params.instanceId}/service/${name}/overview`
      );
    }
  };

  const isRefreshLoading =
    activeDeploymentLoading ||
    selectedInstanceLoading ||
    selectedOrganisationLoading;

  const handleRefresh = () => {
    dispatch(getComputeInstanceDetailsThunk(params?.instanceId || '')).then(
      () =>
        dispatch(
          getDeploymentDetailsThunk(
            (selectedInstance as IInstance)?.activeDeployment ||
              (selectedInstance as IInstance).deployments[
                selectedInstance.deployments.length - 1
              ]
          )
        )
    );
  };

  return (
    <div className="flex flex-col gap-y-5">
      {withLoader(
        selectedOrganisationLoading,
        <Skeleton height={24} width="10ch" duration={2} />,
        <>
          <SectionHeading
            showRefresh={false}
            showSwitch={false}
            subHeading=""
            heading={`Services ${
              !isRefreshLoading ? `(${services?.length})` : ''
            }`}
            handleIconClick={() => {}}
            handleRefreshClick={handleRefresh}
            handleSwitchClick={() => {}}
            switchDisable={false}
            handleClick={() => {}}
            loading={isRefreshLoading}
            time={''}
            refreshType="default"
            showText
            toggleId={''}
            isChecked={false}
            handleChange={() => {}}
            customText="Refresh"
          />
        </>
      )}

      {withLoader(
        activeDeploymentLoading || isRefreshLoading,
        <>
          {' '}
          <div className="grid grid-cols-2 gap-x-4 gap-y-2">
            {[0, 1].map((service) => (
              <ServiceCardLoader key={service} />
            ))}
          </div>
        </>,
        <>
          {(services?.length || 0) > 0 ? (
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-2">
              {services?.map((service) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <div
                  className={
                    selectedInstance?.state?.toLowerCase() === 'starting'
                      ? GlobalStyles.service__card__disabled
                      : ''
                  }
                >
                  <ServiceCard
                    key={service.viewName}
                    onClick={() => handleServiceButtonClick(service.name)}
                    name={service.viewName}
                    updatedAt={dayjs(
                      (selectedInstance as IInstance).updatedAt
                    ).format('DD MMM YYYY')}
                    planDetails={[
                      {
                        label: 'CPU',
                        value: String(service.agreedMachineImage.cpu),
                      },
                      {
                        label: 'Memory',
                        value: service.agreedMachineImage.memory,
                      },
                      {
                        label: 'Storage',
                        value: service.agreedMachineImage.storage,
                      },
                      ...(Object.keys(
                        service.agreedMachineImage.persistentStorage || {}
                      ).length
                        ? [
                            {
                              label: 'Persistent Storage',
                              value:
                                service.agreedMachineImage.persistentStorage
                                  ?.size || '',
                            },
                          ]
                        : []),
                    ]}
                    tag={service.tag}
                    replica={service.serviceCount}
                    icon={
                      service.template ? (
                        <img
                          className="rounded-full w-8 h-8"
                          src={service.template.metadata.icon}
                          alt={service.template.name}
                        />
                      ) : (
                        <DockerCredentialIcon className="rounded-full w-8 h-8" />
                      )
                    }
                    planName={service.agreedMachineImage.machineType}
                    planPrice={`$${(
                      service.agreedMachineImage.defaultDailyTopUp * 30
                    ).toFixed(2)}/month`}
                    planPriceSecond={`$${(
                      service.agreedMachineImage.defaultDailyTopUp / 24
                    ).toFixed(2)}/hour`}
                    id={service.name}
                    discountBadgeText={
                      service?.discount
                        ? `${service.discount.discountPercent}% OFF`
                        : ''
                    }
                    handleIconClick={() => {
                      if (isHyperlink(service.image)) {
                        window.open(`https://${service.image}`, '_blank');
                      } else {
                        window.open(
                          `https://hub.docker.com/r/${service.image}`,
                          '_blank'
                        );
                      }
                    }}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="h-72">
              <EmptyState
                entityTitle="You don't have any services yet!"
                showButton={false}
                buttonDisabled={false}
                onClick={() => {}}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default InstanceOverview;
