import React from 'react';
import { ReactComponent as Check } from '../../../assets/compute/icons/checkbox-template-card.svg';
import Styles from '../../../styles/compute/compute-pricing.module.scss';

interface IProps {
  heading: string;
  subHeading: string;
  icon: JSX.Element | null;
  isSelected: boolean;
  handleClick: () => void;
  disabled: boolean;
}

const CheckboxButton = ({
  heading,
  subHeading,
  icon,
  isSelected,
  disabled,
  handleClick,
}: IProps) => {
  return (
    <button
      type="button"
      onClick={handleClick}
      disabled={disabled}
      className={`px-3 py-2 border rounded-lg min-w-360 relative ${
        isSelected
          ? 'border-form-selected dark:border-dark-form-selected'
          : `border-base-border dark:border-dark-base-border
        dark:bg-dark-base-bg bg-base-bg
        hover:border-form-selected dark:hover:border-dark-form-selected`
      } ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
    >
      <div className="flex items-center justify-start gap-x-4">
        {icon && (
          <div
            className="bg-base-fg dark:bg-dark-base-fg
          w-11 h-11 rounded-full items-center flex justify-center"
          >
            {icon}
          </div>
        )}
        <div className="flex items-start justify-between">
          <div className="flex flex-col items-start">
            <h3 className="  font-semibold text-base leading-6">{heading}</h3>
            <p className="  font-normal text-sm">{subHeading}</p>
          </div>
        </div>
      </div>
      {isSelected && (
        <span className="absolute right-1 top-1">
          <Check className={Styles.check__icon} />
        </span>
      )}
    </button>
  );
};

export default CheckboxButton;
