/* eslint-disable no-unused-vars */
import { IDiscount } from '../../combined-state.interface';
// eslint-disable-next-line import/no-cycle
import { IGPU, IGPUSpecs } from '../instance.interfaces';

export interface IInstanceCreationState {
  deploymentType: InstanceDeploymentType;
  clusterRegions: {
    region: string;
    scalingMode: ScalingType;
    isPrimaryForScaling: boolean;
  }[];
  getClusterRegionsLoading: boolean;
  clusterType: ClusterType;
  clusterRegion: string | null;
  clusterScaling: ScalingType;
  clusterScalingName: string;
  instancePlanLoading: boolean;
  instancePlans: IInstancePlans;
  instancePlanPagination: IInstancePlanPagination;
  instancePlanPaginationLoading: boolean;
  customPlan: IInstancePlan;
  updatedCustomPrice: number;
  updatedStoragePrice: number;
  updatedPersistentStoragePrice: number;
  customCpu: string;
  customRam: string;
  customStorage: number;
  customStorageInput: number;
  customHddStorage: number;
  customSsdStorage: number;
  customNvmStorage: number;
  customHddStorageInput: number;
  customSsdStorageInput: number;
  customNvmStorageInput: number;
  customPersistentStorage: string | null;
  customStorageInputPrice: string | number | null;
  customHddInputPrice: string | number | null;
  customSsdInputPrice: string | number | null;
  customNvmInputPrice: string | number | null;
  computeStep: number;
  defaultCustomPlansValue: IDefaultCustomValue[];
  priceLoading: boolean;
  priceLoadingType: LoadingType;
  mountPointValue: string;
  instanceValue: number;
  confirmRedeploySwitch: boolean;
  confirmRedeploySwitchType: ConfirmRedeploySwitchType;
  storageLoading: boolean;
  recommendedPlan: IInstancePlan | null;
  recommendedPlanLoading: boolean;
  privateCredentialName: string;
  privateCredentialPassword: string;
  privateCredentialUrl: string;
  privateCredentialIcon: string | null;
  planSearch: string | null;
  planAvailable: ResourcesAvailable;
  storageAvailable: ResourcesAvailable;
  persistentStorageAvailable: ResourcesAvailable;
  discountCpuMemory: IDiscount | null;
  discountStorage: IDiscount | null;
  discountPersistentStorage: IDiscount | null;
  pricingTargetsLoading: boolean;
  availableInventoryLoading: boolean;
  unitPrices: IInstanceUnitPriceDetails[];
  availableInventory: IAvailableInventory[];
  multiserviceInstanceCreation: Record<
    string,
    IMultiserviceInstancePlan
  > | null;
  selectedService: number | string | null;
  ipLeaseType: IPLeaseType;
  ipLeasePrice: number;
  isDedicatedClicked: boolean;
}

export interface IMultiserviceInstancePlan {
  id: string;
  instancePlans: IInstancePlans;
  customPlan: IInstancePlan;
  updatedCustomPrice: number;
  updatedStoragePrice: number;
  updatedPersistentStoragePrice: number;
  customCpu: string;
  customRam: string;
  customStorage: number;
  customStorageInput: number;
  customHddStorage: number;
  customSsdStorage: number;
  customNvmStorage: number;
  customHddStorageInput: number;
  customSsdStorageInput: number;
  customNvmStorageInput: number;
  customPersistentStorage: string | null;
  customStorageInputPrice: string | number | null;
  customHddInputPrice: string | number | null;
  customSsdInputPrice: string | number | null;
  customNvmInputPrice: string | number | null;
  defaultCustomPlansValue?: IDefaultCustomValue[];
  mountPointValue: string;
  recommendedPlan: IInstancePlan | null;
  recommendedPlanLoading: boolean;
  discountCpuRam: IDiscount | null;
  discountStorage: IDiscount | null;
  discountPersistentStorage: IDiscount | null;
  planSearch: string | null;
  priceLoading: boolean;
  priceLoadingType?: LoadingType;
  serviceCount: number;
  ipLeaseType: IPLeaseType;
  ipLeasePrice: number;
  isDedicatedClicked: boolean;
}

export enum ConfirmRedeploySwitchType {
  TEMPLATE = 'template',
  CLUSTER = 'cluster',
  DEFAULT = '',
}

export enum InstanceDeploymentType {
  // eslint-disable-next-line no-unused-vars
  COMPUTE = 'compute',
  // eslint-disable-next-line no-unused-vars
  ACCELERATE = 'accelerate',
  // eslint-disable-next-line no-unused-vars
  MULTISERVICE = 'multiservice',
}

export const enum ClusterType {
  DEFAULT = 'Default',
  TEMPLATE = 'template',
  DOCKER = 'DOCKERHUB',
}

export enum ScalingType {
  MANUAL = 'SCALING_ALLOWED',
  AUTO = 'AUTO_SCALING',
  NO = 'NO_SCALING',
}

export enum ScalingTypeName {
  AUTO = 'auto',
  MANUAL = 'manual',
  NO = 'no',
}

export interface IClusterRegionResponse {
  regions: {
    region: string;
    scalingMode: ScalingType;
    isPrimaryForScaling: boolean;
  }[];
}

export enum ResourcesAvailable {
  // eslint-disable-next-line no-unused-vars
  AVAILABLE = 'available',
  // eslint-disable-next-line no-unused-vars
  NOT_AVAILABLE = 'not_available',
  // eslint-disable-next-line no-unused-vars
  UNKNOWN = 'unknown',
}

export interface IInstancePlans {
  totalCount: number;
  akashMachineImages: IInstancePlan[];
}
export interface IInstancePlan {
  _id: string;
  name: string;
  cpu: number;
  storage: string;
  memory: string;
  maxPricePerBlock: number;
  defaultDailyTopUp: number;
  topupThreashold: number;
  price: number;
  gpu?: IGPU | IGPUSpecs;
  recommendedPlan: boolean;
}

export interface IInstancePlanPagination {
  searchParams: string;
  currentPage: number;
  numbersPerPage: number;
}

// eslint-disable-next-line no-unused-vars
export enum HardwareTypeEnum {
  // eslint-disable-next-line no-unused-vars
  STORAGE = 'storage', // Ephemeral storage
  // eslint-disable-next-line no-unused-vars
  PERSISTENT_STORAGE_HDD = 'persistent-storage-hdd', // Persistent storage
  // eslint-disable-next-line no-unused-vars
  PERSISTENT_STORAGE_SSD = 'persistent-storage-ssd', // Persistent storage
  // eslint-disable-next-line no-unused-vars
  PERSISTENT_STORAGE_NVME = 'persistent-storage-nvme', // Persistent storage
  // eslint-disable-next-line no-unused-vars
  CPU = 'cpu',
  // eslint-disable-next-line no-unused-vars
  MEMORY = 'memory',
}

export interface IDefaultValue {
  value: number;
  price: string;
}

export interface IDefaultCustomValue {
  type: HardwareTypeEnum;
  values: IDefaultValue[];
}

// eslint-disable-next-line no-unused-vars
export enum LoadingType {
  // eslint-disable-next-line no-unused-vars
  STORAGE = 'storage',
  // eslint-disable-next-line no-unused-vars
  PERSISTENT_STORAGE = 'persistent-storage',
  // eslint-disable-next-line no-unused-vars
  CUSTOM_PLAN = 'custom-plan',
  // eslint-disable-next-line no-unused-vars
  IP_LEASE = 'ip-lease',
}

export enum PersistentStorageOption {
  // eslint-disable-next-line no-unused-vars
  HDD = 'HDD',
  // eslint-disable-next-line no-unused-vars
  SSD = 'SSD',
  // eslint-disable-next-line no-unused-vars
  NVM = 'NVMe',
}

export enum IPersistentStorageClass {
  // eslint-disable-next-line no-unused-vars
  BETA1 = 'beta1',
  // eslint-disable-next-line no-unused-vars
  BETA2 = 'beta2',
  // eslint-disable-next-line no-unused-vars
  BETA3 = 'beta3',
}

export enum IPLeaseType {
  // eslint-disable-next-line no-unused-vars
  SHARED = 'shared-ip',
  // eslint-disable-next-line no-unused-vars
  DEDICATED = 'dedicated-ip',
  // eslint-disable-next-line no-unused-vars
  STATIC = 'static',
}

export interface IPlanByIdService {
  id: string;
}

export interface IInstanceUnitPriceDetails {
  cpu: number;
  memory: number;
  hdEphemeral: number;
  persistentStorageHDD: number;
  persistentStorageNVME: number;
  persistentStorageSSD: number;
  ip: number;
  region: string;
  scalingMode: string;
  gpu?: IGpuPriceDetails[];
}

export interface IGpuPriceDetails {
  price: number;
  vendor: string;
  model: string;
}

export interface IAvailableInventory {
  region: string;
  resources: IResourceInfo[];
  // eslint-disable-next-line camelcase
  ip_supported: boolean;
}

export interface IResourceInfo {
  cpu: number;
  gpu: number;
  memory: number;
  storageEphemeral: number;
  storage?: IResourceStorageInfo[];
  provider: string;
}

interface IResourceStorageInfo {
  class: string;
  size: number;
}

export interface IPricingTargetsResponse {
  unitPrices: IInstanceUnitPriceDetails[];
}

export interface IAvailableInventoryResponse {
  availableInventory: IAvailableInventory[];
}
