/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { ReactComponent as InfoCircle } from '@spheron/ui-library/dist/assets/info-circle.svg';
import { ReactComponent as RecurringIcon } from '@spheron/ui-library/dist/assets/spheron-specific/recurring.svg';
import { Button, SectionHeading, TextInput } from '@spheron/ui-library';
import { ReactComponent as PencilIcon } from '@spheron/ui-library/dist/assets/pencil.svg';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import {
  removeActiveBonusThunk,
  scheduleBonusThunk,
  updateBonusAmountThunk,
} from '../../../redux/compute/subscription/subscription.thunks';
import config from '../../../config';
import { ConnectionState } from '../../../redux/compute/stripe/stripe.interfaces';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';

interface IProps {
  isMember: boolean;
}

const RecurringTopup = ({ isMember }: IProps) => {
  const isPublicAccessible =
    sessionStorage.getItem('isPublicAccessible') === 'true';
  const communityUserAccess =
    sessionStorage.getItem('communityUserAccess') === 'true';
  const dispatchRtk = useDispatch<AppDispatch>();
  const activeBonus = useSelector(
    (state: RootState) => state.subscription.activeBonus
  );
  const activeBonusLoading = useSelector(
    (state: RootState) => state.subscription.activeBonusLoading
  );
  const selectedOrganisation = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation
  );
  const updateBonusAmountLoading = useSelector(
    (state: RootState) => state.subscription.updatingBonusAmountLoading
  );
  const schedulingBonus = useSelector(
    (state: RootState) => state.subscription.scheduleBonusLoading
  );
  const subscriptionLoading = useSelector(
    (state: RootState) => state.subscription.subscriptionUpdating
  );
  const selectedOrganisationLoading = useSelector(
    (state: RootState) => state.organisation.selectedOrganisationLoading
  );
  const primaryPaymentMethodId = useSelector(
    (state: RootState) => state.organisation.primaryPaymentMethodId
  );
  // const primaryPaymentMethodLoading = useSelector(
  //   (state: RootState) => state.organisation.primaryPaymentMethodLoading
  // );
  const creditCards = useSelector(
    (state: RootState) => state.stripe.creditCards
  ).filter((card) => card.connectionState === ConnectionState.CONNECTED);
  const web3Wallets = useSelector((state: RootState) => state.web3.wallets);
  const spheronWallet = useSelector(
    (state: RootState) => state.organisation.spheronWallet
  );
  const fetchingCreditCards = useSelector(
    (state: RootState) => state.stripe.fetchingCreditCards
  );
  const web3WalletsLoading = useSelector(
    (state: RootState) => state.web3.walletLoading
  );

  const hasNoWallet =
    creditCards.length === 0 &&
    web3Wallets.length === 0 &&
    !spheronWallet &&
    !fetchingCreditCards &&
    !web3WalletsLoading;

  const [amount, setAmount] = useState<number>();
  const [toggleAutotopup, setToggleAutotopup] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const minAmount = config.compute.MINIMUM_DEPOSIT;

  const recurringComputeCreditBonus = useMemo(() => {
    if (!activeBonusLoading && activeBonus?.length > 0) {
      return activeBonus.find(
        (bonus) =>
          bonus.paymentParameterName === 'BONUS_CLUSTER_AKT' &&
          bonus.renew &&
          (bonus.state === 'SCHEDULED' || bonus.state === 'RESOLVED')
      );
    }
    return null;
  }, [activeBonus, activeBonusLoading]);

  const handleToggleChange = () => {
    if (recurringComputeCreditBonus && toggleAutotopup) {
      dispatchRtk(
        toggleModalShowRtk({
          modalShow: true,
          modalType: 'confirmation',
          options: {
            handleProceed: () => {
              toggleModalShowRtk({ modalShow: false });
              dispatchRtk(
                removeActiveBonusThunk(recurringComputeCreditBonus._id)
              ).then(() => {
                setToggleAutotopup(!toggleAutotopup);
              });
            },
            loading: subscriptionLoading,
            text: `Toggling this will remove the recurring
            bonus and you won't be charged upcoming month`,
          },
        })
      );
    } else {
      setToggleAutotopup(!toggleAutotopup);
    }
  };

  const handleDeposit = () => {
    if (!recurringComputeCreditBonus) {
      dispatchRtk(
        scheduleBonusThunk({
          organizationId: selectedOrganisation._id,
          bonusParameterName: 'BONUS_CLUSTER_AKT',
          bonusParameterValue: amount || 0,
          renew: true,
        })
      ).then((res) => {
        if (res.meta.requestStatus === 'rejected') {
          setIsEditMode(true);
        } else setIsEditMode(false);
      });
    } else {
      dispatchRtk(
        updateBonusAmountThunk({
          organizationId: selectedOrganisation._id,
          bonusId: recurringComputeCreditBonus._id,
          value: Number(amount),
        })
      ).then(() => {
        setIsEditMode(false);
      });
    }
  };

  useEffect(() => {
    if (recurringComputeCreditBonus) {
      setAmount(Number(recurringComputeCreditBonus.fee));
      setToggleAutotopup(true);
      setIsEditMode(false);
    } else {
      setToggleAutotopup(false);
      setIsEditMode(true);
    }
  }, [activeBonus, activeBonusLoading, recurringComputeCreditBonus]);

  useEffect(() => {
    if (selectedOrganisationLoading) {
      setIsEditMode(false);
      setToggleAutotopup(false);
    }
  }, [selectedOrganisationLoading]);

  const disable =
    isMember || (isPublicAccessible && !communityUserAccess) || hasNoWallet;

  // Infer better type from usage
  const handleAmountChange = (value: any) => {
    const parsedValue = Number(
      String(Math.floor(value)).replace(/[^0-9]/g, '')
    );
    setAmount(parsedValue);
  };

  return (
    <div
      className="p-5 bg-base-fg dark:bg-dark-base-fg rounded-lg
      border-base-border dark:border-dark-base-border"
    >
      <div className="flex flex-col items-start gap-y-5">
        <SectionHeading
          showRefresh={false}
          showSwitch
          heading="Auto Topup"
          subHeading="Enjoy uninterrupted service with auto top-ups. Activate this feature with a simple toggle for a hassle-free experience."
          handleIconClick={() => null}
          handleRefreshClick={() => null}
          handleSwitchClick={handleToggleChange}
          switchDisable={disable || activeBonusLoading}
          handleClick={() => null}
          loading={false}
          time=""
          refreshType="default"
          showText={false}
          toggleId="1"
          isChecked={toggleAutotopup}
          handleChange={() => null}
        />
        {toggleAutotopup && (
          <div className="flex-col w-full">
            {isEditMode ? (
              <div className="flex items-center gap-x-5">
                <div className="w-[30%]">
                  <TextInput
                    type="number"
                    value={String(amount)}
                    disabled={disable}
                    placeholder="e.g. $15"
                    helpText={`Require minimum $${minAmount}, non-decimal values only`}
                    onChange={(value) => handleAmountChange(value)}
                    error={
                      typeof amount !== 'undefined' &&
                      Number(amount) < minAmount &&
                      amount > 0
                    }
                    label="Enter Recurring Amount (USD)"
                  />
                </div>
                <div className="flex items-center justify-start gap-x-3.5">
                  <Button
                    buttonType="ghost"
                    label="Cancel"
                    onClick={() =>
                      recurringComputeCreditBonus
                        ? setIsEditMode(false)
                        : setToggleAutotopup(false)
                    }
                    className="min-w-[50px]"
                  />
                  <Button
                    buttonType="primary"
                    label="Schedule"
                    onClick={handleDeposit}
                    className=""
                    disabled={
                      isMember ||
                      (isPublicAccessible && !communityUserAccess) ||
                      Number.isNaN(Number(amount)) ||
                      Number(amount) < minAmount
                    }
                    loading={
                      recurringComputeCreditBonus
                        ? updateBonusAmountLoading
                        : schedulingBonus
                    }
                  />
                </div>
              </div>
            ) : (
              <div className="flex items-center justify-start gap-x-5">
                <div
                  className="p-5 rounded-lg border border-base-border dark:border-dark-base-border
                bg-feedback-info-bg  dark:bg-dark-feedback-info-bg"
                >
                  <div className="flex items-center justify-center gap-2">
                    <div className="flex items-center justify-start gap-x-3">
                      <RecurringIcon className="w-6 h-6" />
                      <span className="font-600 text-3 leading-3">
                        RECURRING TOP UP
                      </span>
                    </div>
                    <div className="w-[1px] h-6 bg-base-sub-text-color mx-4.5" />
                    <span className=" text-6 leading-6 font-500">
                      ${recurringComputeCreditBonus?.value}
                    </span>
                  </div>
                </div>
                <Button
                  buttonType="neutral"
                  label="Update"
                  leftIcon={
                    <PencilIcon className="w-5 h-5 text-action-neutral-text" />
                  }
                  onClick={() => setIsEditMode(!isEditMode)}
                  disabled={isMember}
                />
              </div>
            )}
            {!isEditMode && (
              <div className="text-sm font-normal mt-3 flex items-center gap-1">
                <InfoCircle
                  className={`w-4 h-4 ${
                    primaryPaymentMethodId ? '' : 'text-feedback-error-text'
                  }`}
                />
                {primaryPaymentMethodId ? (
                  <>
                    Your primary payment method will be billed for recurring
                    top-up charges on the scheduled billing date, which is
                    <span className="font-bold ml-1">
                      {dayjs(
                        recurringComputeCreditBonus?.expirationDate
                      ).format('DD MMM YYYY')}
                    </span>
                  </>
                ) : (
                  <span className="text-feedback-error-text">
                    Please add a primary payment method to avoid failure of
                    payments
                  </span>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default RecurringTopup;
