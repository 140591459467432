import React from 'react';
import { ConfirmationModal, Feedback, Modal } from '@spheron/ui-library';
import { useDispatch } from 'react-redux';
import Styles from '../../../styles/compute/modal.module.scss';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import { ReactComponent as CreditIcon } from '../../../assets/compute/icons/credit-icon.svg';
import ModalWrapper from './ModalWrapper';
import { AppDispatch } from '../../../redux/compute/store';

interface IProps {
  isOpen: boolean;
  code: string;
  state: string;
  message: string;
}

const RedeemSpheronCredit = ({ isOpen, code, state, message }: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();

  const showStateResponse = () => {
    switch (state) {
      case 'Pending':
        return {
          modalType: ConfirmationModal.WARNING,
          heading: 'Promotion code has been added in your Pending list.',
          title: message,
          subtitle: 'credit code has been added.',
        };

      case 'Error':
      default:
        return {
          modalType: ConfirmationModal.ERROR,
          heading: 'Not applicable!',
          title: message,
          subtitle: 'credit code is not applicable.',
        };

      case 'Expired':
        return {
          modalType: ConfirmationModal.ERROR,
          heading: 'Applied Coupon Expired!',
          title: message,
          subtitle: 'credit code has been expired.',
        };

      case 'Success':
        return {
          modalType: ConfirmationModal.INFO,
          heading: 'Promotion code applied successfully!',
          title:
            'Hurray, you have successfully applied the promotion code, enjoy it’s benefits.',
          subtitle: 'credit code has been applied.',
        };
    }
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={() => {
        dispatchRtk(toggleModalShowRtk({ modalShow: false }));
      }}
      classname={`${Styles.modal__outer__con} modal fixed inset-0 overflow-y-auto`}
    >
      <div className={`${Styles.modal__inner__content} !max-w-[400px]`}>
        <Modal
          footer
          header
          title="Add Credit"
          subtitle=""
          modalContent={
            <div className="flex flex-col gap-6">
              <span className="text-base-para-text-color dark:text-dark-base-para-text-color font-400 text-sm">
                {showStateResponse().title}
              </span>
              <Feedback
                feedbackType="info"
                title={
                  <>
                    <b>{code}</b> {showStateResponse().subtitle}
                  </>
                }
                position="center"
                showClose={false}
                size="default"
                icon={
                  <CreditIcon className="w-6 h-6 text-base-text dark:text-dark-base-text" />
                }
              />
            </div>
          }
          headerContent={<>{showStateResponse().heading}</>}
          primaryButtonContent="Okay"
          secondaryButtonContent=""
          primaryButton
          primaryButtonLoading={false}
          primaryButtonDisabled={false}
          secondaryButtonDisabled={false}
          secondaryButton={false}
          onPrimaryButtonClick={() =>
            dispatchRtk(toggleModalShowRtk({ modalShow: false }))
          }
          onSecondaryButtonClick={() => null}
          confirmationStatus={showStateResponse().modalType}
        />
      </div>
    </ModalWrapper>
  );
};

export default RedeemSpheronCredit;
