/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Badge, Button, Link, Tooltip } from '@spheron/ui-library';
import { ReactComponent as Check } from '../../../assets/compute/icons/checkbox-template-card.svg';
import Styles from '../../../styles/compute/compute-pricing.module.scss';
import { ScalingType } from '../../../redux/compute/instance/instance-creation/instance-creation.interfaces';
import { capitalizeFirstLetter } from '../../../redux/compute/root-utils';

export type ScalingCardTag = 'recommended' | 'coming soon' | '';

interface IProps {
  type: ScalingType;
  cardTitle: string;
  cardDescription: string;
  cardTag: ScalingCardTag;
  isSelected: boolean;
  handleClick: () => void;
  disabled: boolean;
  link: string;
  linkText: string;
  tooltipMessage: string;
}

const InstanceStorageCard = ({
  type,
  cardTitle,
  cardDescription,
  cardTag,
  isSelected,
  handleClick,
  disabled,
  link,
  linkText,
  tooltipMessage,
}: IProps) => {
  const scalingCard = (
    <button
      onClick={handleClick}
      type="button"
      disabled={disabled}
      className={`${
        isSelected
          ? `border-form-selected dark:border-dark-form-selected`
          : `bg-base-bg border-base-border dark:bg-dark-base-bg
          dark:border-dark-base-border dark:border-opacity-10`
      }
    border rounded-lg w-full ${
      disabled
        ? 'cursor-not-allowed'
        : 'cursor-pointer hover:border-form-selected dark:hover:border-dark-form-selected'
    }`}
    >
      <div className="p-3">
        <div className="flex justify-between">
          <div className="flex flex-col items-start">
            <div className="flex items-center justify-start">
              <h5
                className={`text-base font-semibold  ${
                  disabled
                    ? ''
                    : 'text-base-heading-text-color dark:text-dark-base-heading-text-color'
                }`}
              >
                {cardTitle}
              </h5>
            </div>
            <p className="text-xs ll:text-sm text-left">
              {cardDescription}
              <Link
                type="secondary"
                size="default"
                text={linkText}
                isBold={false}
                onClick={() => window.open(link, '_blank')}
              />
            </p>
          </div>

          <div className="flex flex-col justify-between items-end">
            {isSelected ? (
              <span className="-mr-2 -mt-2">
                <Check className={Styles.check__icon} />
              </span>
            ) : (
              <span />
            )}
            {(type === ScalingType.MANUAL || type === ScalingType.AUTO) &&
              !!cardTag.length && (
                <Badge
                  text={capitalizeFirstLetter(cardTag)}
                  badgeType="info"
                  solid={false}
                  size="medium"
                />
              )}
            {type === ScalingType.NO && (
              <a
                href="mailto:info@spheron.network"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  buttonType="primary"
                  label="Get Access"
                  size="small"
                  classname="text-xs p-2 whitespace-nowrap
                  font-medium tracking-tight"
                  onClick={() => {}}
                />
              </a>
            )}
          </div>
        </div>
      </div>
    </button>
  );
  if (disabled && tooltipMessage) {
    return (
      <Tooltip position="right" text={tooltipMessage} type="arrow" wrapText>
        {scalingCard}
      </Tooltip>
    );
  }
  return scalingCard;
};

export default InstanceStorageCard;
