import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Badge,
  Dropdown,
  OptionType,
  SectionHeading,
} from '@spheron/ui-library';
import { ReactComponent as RamIcon } from '@spheron/ui-library/dist/assets/compute/ram.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/rtk-store';
import { sliceStringFromEnd } from '../../../../redux/subscription/subscription.utils';
import GlobalStyles from '../../../../styles/compute/global.module.scss';
import { ReactComponent as ComputerIcon } from '../../../../assets/compute/icons/old-computer.svg';
import {
  IInstanceMetricsChart,
  MonitoringTime,
} from '../../../../redux/instance/instance.interfaces';
import { getInstanceMetricsChartThunk } from '../../../../redux/instance/instance.thunks';
import MonitoringChartCard from '../../../../components/Compute/Cards/chart-card';
import { AppDispatch } from '../../../../redux/compute/store';

interface IProps {
  showHealthCheck: boolean;
}

// eslint-disable-next-line no-unused-vars
const InstanceMonitoring = ({ showHealthCheck }: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();
  const params = useParams<{
    orgId: string;
    clusterId: string;
    slug2: string;
    serviceId: string;
  }>();

  type TimeUnit = 'hourly' | 'weekly' | 'monthly' | 'daily';

  const baseValues: Record<TimeUnit, number> = {
    hourly: 60,
    weekly: 10080,
    monthly: 40320,
    daily: 1440,
  };

  const generateOptions = (unit: TimeUnit, numOptions: number) => {
    const options = [];
    for (let i: number = 1; i <= numOptions; i += 1) {
      const value = baseValues[unit] * i;
      const label = `${i}${unit.charAt(0)}`;
      options.push({ index: i, value, label });
    }
    return options;
  };

  const hourlyOptions = generateOptions(MonitoringTime.HOURLY, 6);
  const [showTimeFilter, setShowTimeFilter] = useState<
    {
      index: number;
      value: number;
      label: string;
    }[]
  >(hourlyOptions);
  const [updatedTime, setUpdatedTime] = useState<{
    index: number;
    value: number;
    label: string;
  }>(showTimeFilter[0]);
  const monitoringFilterOptions = [
    {
      label: 'Average',
      value: 'average',
    },
    {
      label: 'Max',
      value: 'max',
    },
  ];
  const timeSpanOptions = [
    {
      label: '5min',
      value: '5m',
    },
    {
      label: '15min',
      value: '15m',
    },
    {
      label: '30min',
      value: '30m',
    },
    {
      label: '60min',
      value: '60m',
      disable: updatedTime.label === '1h',
    },
  ];

  const [monitoringFilter, setMonitoringFilter] = useState<string>(
    monitoringFilterOptions[0].value
  );
  const [monitoringTimeSpan, setMonitoringTimeSpan] = useState<string>(
    timeSpanOptions[0].value
  );
  const [monitoringTimeList, setMonitoringTimeList] = useState<string>(
    MonitoringTime.HOURLY
  );
  const selectedInstance = useSelector(
    (state: RootState) => state.instance.selectedInstance
  );

  const selectedService = useSelector(
    (state: RootState) => state.computeService.selectedService
  );
  const selectedServiceLoading = useSelector(
    (state: RootState) => state.computeService.selectedServiceLoading
  );
  const activeDeploymentLoading = useSelector(
    (state: RootState) => state.instance.activeDeploymentLoading
  );
  const metricsLoading = useSelector(
    (state: RootState) => state.instance.metricsLoading
  );
  const metricsChartLoading = useSelector(
    (state: RootState) => state.instance.metricsChartLoading
  );
  const instanceMetricsChart = useSelector(
    (state: RootState) => state.instance.metricsChart
  );

  const timeFilterOptions = [
    {
      label: 'Hourly',
      value: MonitoringTime.HOURLY,
    },
    {
      label: 'Daily',
      value: MonitoringTime.DAILY,
    },
    {
      label: 'Weekly',
      value: MonitoringTime.WEEKLY,
    },
    {
      label: 'Monthly',
      value: MonitoringTime.MONTHLY,
    },
  ];

  const handleFilterClick = (time: {
    index: number;
    value: number;
    label: string;
  }) => {
    setUpdatedTime(time);
    if (selectedInstance?._id) {
      dispatchRtk(
        getInstanceMetricsChartThunk({
          serviceName: params?.serviceId || '',
          instanceId: selectedInstance?._id,
          monitoringPointTime: monitoringTimeSpan,
          monitoringTime: time.value,
          monitoringType: monitoringFilter,
        })
      );
    }
  };
  const metricsStartup = async () => {
    if (selectedInstance?._id) {
      dispatchRtk(
        getInstanceMetricsChartThunk({
          serviceName: params?.serviceId || '',
          instanceId: selectedInstance?._id,
          monitoringPointTime: monitoringTimeSpan,
          monitoringTime: updatedTime.value,
          monitoringType: monitoringFilter,
        })
      );
    }
  };

  const metricTimes = instanceMetricsChart.map(
    (metric: IInstanceMetricsChart) => metric.time
  );
  const totalCpu = instanceMetricsChart.map(
    (metric: IInstanceMetricsChart) => metric.totalCpu
  );
  const totalRam = instanceMetricsChart.map(
    (metric: IInstanceMetricsChart) => metric.totalMemory
  );
  const handleMonitoringTimeList = (monitoringList: MonitoringTime) => {
    const timeFilterOptions = generateOptions(monitoringList, 6);
    setShowTimeFilter(timeFilterOptions);
    setUpdatedTime(timeFilterOptions[0]);
    setMonitoringTimeList(monitoringList);
    if (selectedInstance?._id) {
      dispatchRtk(
        getInstanceMetricsChartThunk({
          serviceName: params?.serviceId || '',
          instanceId: selectedInstance?._id,
          monitoringPointTime: monitoringTimeSpan,
          monitoringTime: timeFilterOptions[0].value,
          monitoringType: monitoringFilter,
        })
      );
    }
  };

  const handleMonitoringPointTime = (pointTime: string) => {
    setMonitoringTimeSpan(pointTime);
    if (selectedInstance?._id) {
      dispatchRtk(
        getInstanceMetricsChartThunk({
          serviceName: params?.serviceId || '',
          instanceId: selectedInstance?._id,
          monitoringPointTime: pointTime,
          monitoringTime: updatedTime.value,
          monitoringType: monitoringFilter,
        })
      );
    }
  };
  const handleMonitoringFilter = (monitoringFilter: string) => {
    setMonitoringFilter(monitoringFilter);
    if (selectedInstance?._id) {
      dispatchRtk(
        getInstanceMetricsChartThunk({
          serviceName: params?.serviceId || '',
          instanceId: selectedInstance?._id,
          monitoringPointTime: monitoringTimeSpan,
          monitoringTime: updatedTime.value,
          monitoringType: monitoringFilter,
        })
      );
    }
  };
  useEffect(() => {
    if (selectedInstance?._id) {
      dispatchRtk(
        getInstanceMetricsChartThunk({
          serviceName: params?.serviceId || '',
          instanceId: selectedInstance?._id,
          monitoringPointTime: monitoringTimeSpan,
          monitoringTime: updatedTime.value,
          monitoringType: monitoringFilter,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchRtk, selectedInstance?._id]);

  return (
    <div
      className={`${GlobalStyles.screen__width} !mt-0 !p-0 ${GlobalStyles.nav__fix} ml-0`}
    >
      <div className="flex items-center">
        <div className="flex flex-1 gap-x-2 items-center">
          <SectionHeading
            showRefresh
            showSwitch={false}
            subHeading=""
            heading="Instance Metrics"
            handleIconClick={() => {}}
            handleRefreshClick={metricsStartup}
            handleSwitchClick={() => {}}
            switchDisable={false}
            handleClick={() => {}}
            loading={metricsLoading || metricsChartLoading}
            time=""
            refreshType="default"
            showText={false}
            toggleId=""
            isChecked={false}
            handleChange={() => {}}
          />
          <div>
            <Badge badgeType="error" size="medium" solid={false} text="BETA" />
          </div>
        </div>
        <div className="flex items-center">
          <div
            className={`shadow-cancelButton h-10 flex items-center  ${
              metricsLoading && metricsChartLoading
                ? 'cursor-not-allowed'
                : 'cursor-pointer'
            }`}
          >
            {showTimeFilter.map((timeFilter) => (
              <span
                key={timeFilter.index}
                role="presentation"
                className={`text-sm font-medium px-3 py-2.5
                   dark:bg-dark-templateCard ${
                     metricsLoading && metricsChartLoading
                       ? 'pointer-events-none'
                       : 'pointer-events-auto'
                   } ${
                  updatedTime.label === timeFilter.label
                    ? 'text-base-link border dark:text-dark-base-link border-form-border dark:border-dark-form-border'
                    : // eslint-disable-next-line max-len
                      'opacity-50 dark:text-neutral-0 border-base-border dark:border-dark-base-border dark:opacity-100 border-l border-b border-t'
                } ${timeFilter.index === 1 ? 'rounded-l' : ''} ${
                  timeFilter.index === showTimeFilter.length
                    ? 'rounded-r border-r'
                    : ''
                }`}
                onClick={() => handleFilterClick(timeFilter)}
              >
                {timeFilter.label}
              </span>
            ))}
          </div>
          <div className="ml-4">
            <Dropdown
              dropdownSize="compact"
              dropdownType="button"
              label=""
              filled
              empty
              onSelected={(value) =>
                handleMonitoringTimeList(value.value as MonitoringTime)
              }
              disable={metricsLoading && metricsChartLoading}
              defaultSelected={monitoringTimeList || timeFilterOptions[0].label}
              options={timeFilterOptions.map((time) => ({
                label: time.label,
                value: time.value,
                optionType: 'primary' as OptionType,
              }))}
            />
          </div>
          <div className="ml-2">
            <Dropdown
              dropdownSize="compact"
              dropdownType="button"
              filled
              onSelected={(value) =>
                handleMonitoringPointTime(value.value as string)
              }
              disable={metricsLoading && metricsChartLoading}
              defaultSelected={monitoringTimeSpan || timeSpanOptions[0].label}
              options={timeSpanOptions.map((time) => ({
                label: time.label,
                value: time.value,
                disable: time.disable,
                optionType: 'primary' as OptionType,
              }))}
              placeholder="Loading..."
            />
          </div>
          <div className="ml-2">
            <Dropdown
              dropdownSize="compact"
              dropdownType="button"
              filled
              onSelected={(value) =>
                handleMonitoringFilter(value.value as string)
              }
              disable={metricsLoading && metricsChartLoading}
              defaultSelected={
                monitoringFilter || monitoringFilterOptions[0].label
              }
              options={monitoringFilterOptions.map((time) => ({
                label: time.label,
                value: time.value,
                optionType: 'primary' as OptionType,
              }))}
              placeholder={monitoringFilter || monitoringFilterOptions[0].label}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-6 mt-5">
        <MonitoringChartCard
          icon={<ComputerIcon />}
          text="CPU"
          content={
            <>
              {instanceMetricsChart.length > 0
                ? instanceMetricsChart[instanceMetricsChart.length - 1].totalCpu
                : 'N.A.'}{' '}
              / {selectedService?.agreedMachineImage.cpu} CPU
            </>
          }
          borderColor="#0057FF"
          label={metricTimes}
          dataOptions={totalCpu}
          contentLoading={
            metricsLoading || activeDeploymentLoading || selectedServiceLoading
          }
          chartLoading={
            metricsChartLoading ||
            activeDeploymentLoading ||
            selectedServiceLoading
          }
          dataSuffix="CPU"
        />
        <MonitoringChartCard
          icon={<RamIcon />}
          text="RAM"
          content={
            <>
              {instanceMetricsChart.length > 0
                ? instanceMetricsChart[
                    instanceMetricsChart.length - 1
                  ].totalMemory.toFixed(3)
                : 'N.A.'}{' '}
              /{' '}
              {sliceStringFromEnd(
                selectedService?.agreedMachineImage.memory || '',
                2
              )}{' '}
              GB
            </>
          }
          borderColor="#01ABFF"
          label={metricTimes}
          dataOptions={totalRam}
          contentLoading={
            metricsLoading || activeDeploymentLoading || selectedServiceLoading
          }
          chartLoading={
            metricsChartLoading ||
            activeDeploymentLoading ||
            selectedServiceLoading
          }
          dataSuffix="GB"
        />
      </div>
    </div>
  );
};

export default InstanceMonitoring;
