import React from 'react';
import { Button } from '@spheron/ui-library';
import { ReactComponent as PlayIcon } from '@spheron/ui-library/dist/assets/spheron-specific/play.svg';
import { useDispatch } from 'react-redux';
import EmptyVideoBg from '../../../assets/compute/backgrounds/empty-video.svg';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import { AppDispatch } from '../../../redux/compute/store';

interface IProps {
  image: string;
  title: string;
  description: string;
  onClick: () => void;
  iframe: JSX.Element | null;
  buttonText: string;
}

const SideMarketingCard = ({
  image,
  title,
  description,
  onClick,
  iframe,
  buttonText,
}: IProps) => {
  const dispatch = useDispatch<AppDispatch>();
  return (
    <div
      className="rounded-lg border-base-border dark:border-dark-base-border 
            bg-base-fg dark:bg-dark-base-fg p-5 max-w-[350px] h-min"
    >
      <div className="flex flex-col justify-center gap-y-4">
        {iframe && image && (
          <span
            className="relative w-full cursor-pointer"
            role="presentation"
            onClick={() => {
              if (iframe) {
                dispatch(
                  toggleModalShowRtk({
                    modalShow: true,
                    modalType: 'videoPopup',
                    options: {
                      iframe,
                    },
                  })
                );
              }
            }}
          >
            {image && (
              <>
                <div
                  className="cursor-pointer
           absolute z-40 w-full h-full rounded-lg bg-black bg-opacity-40 hover:bg-opacity-70"
                />
                <img
                  className="w-full rounded-lg"
                  alt="video"
                  src={image || EmptyVideoBg}
                />

                <span className="absolute h-full items-center w-full justify-center flex top-0 pointer-events-none">
                  <PlayIcon className="absolute z-[45] text-white h-10 w-10" />
                </span>
              </>
            )}
          </span>
        )}
        {title && (
          <span className="text-base text-base-para-text dark:text-dark-base-para-text font-semibold">
            {title}
          </span>
        )}
        {description && (
          <span className="text-sm text-base-para-text dark:text-dark-base-para-text font-normal">
            {description}
          </span>
        )}
        {buttonText && onClick && (
          <Button
            fillWidth
            buttonType="primary"
            size="medium"
            onClick={() => onClick()}
            label={buttonText}
          />
        )}
      </div>
    </div>
  );
};

export default SideMarketingCard;
