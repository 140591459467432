import React from 'react';
import { Button } from '@spheron/ui-library';
import LinkedinIcon from '../../../../assets/compute/icons/linkedin.svg';
import EmailIcon from '../../../../assets/compute/icons/email.svg';
import TwitterIcon from '../../../../assets/compute/icons/twitter.svg';

interface IProps {
  title: string;
  referralLink: string;
  type: string;
  disabled: boolean;
}

const SocialShareButton = ({ title, referralLink, type, disabled }: IProps) => {
  // eslint-disable-next-line max-len
  const shareText = `Loving Spheron Network's game-changing Node Deployment Experience! 🚀 \nJoin me with my referral code: ${referralLink} for an exclusive experience. \nLet's go hunters!!!`;

  const linkedinShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
    referralLink
  )}`;

  const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
    shareText
  )}`;

  const emailShareUrl = `mailto:?subject=${encodeURIComponent(
    'Check out this website'
  )}&body=${encodeURIComponent(`${shareText}`)}`;

  const socialType = () => {
    switch (type) {
      case 'linked':
        return {
          icon: LinkedinIcon,
          shareUrl: linkedinShareUrl,
          buttonClassname: '!bg-[#0066C8]',
        };
      case 'email':
        return {
          icon: EmailIcon,
          shareUrl: emailShareUrl,
          buttonClassname: '',
        };
      case 'twitter':
        return {
          icon: TwitterIcon,
          shareUrl: twitterShareUrl,
          buttonClassname: '!bg-[#1D9BF0]',
        };

      default:
        return {
          icon: LinkedinIcon,
          shareUrl: linkedinShareUrl,
          buttonClassname: '!bg-[#0066C8]',
        };
    }
  };
  return (
    <Button
      buttonType="primary"
      label={title}
      size="small"
      onClick={() => window.open(socialType().shareUrl)}
      loading={false}
      classname={socialType().buttonClassname}
      disabled={disabled}
      leftIcon={
        <img src={socialType().icon} alt="icon" className="mr-2 w-5 h-5" />
      }
    />
  );
};

export default SocialShareButton;
