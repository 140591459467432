import React from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ComputeUsageCard } from '@spheron/ui-library';
import { useDispatch } from 'react-redux';
import { ReactComponent as BlueCloudIcon } from '../../../assets/compute/icons/blue-cloud.svg';
import { ReactComponent as DockerIcon } from '../../../assets/compute/icons/docker.svg';
import config from '../../../config';
import { IComputeProject } from '../../../redux/compute/project/project.interfaces';
import { getComputeProjectInstancesThunk } from '../../../redux/compute/project/project.thunks';
import { AppDispatch } from '../../../redux/compute/store';

dayjs.extend(relativeTime);

interface IProps {
  cluster: IComputeProject;
}

const ClusterTopupCard = ({ cluster }: IProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const communityOrgName = sessionStorage.getItem('communityOrgName');
  const isPublicAccessible =
    sessionStorage.getItem('isPublicAccessible') === 'true';

  const navigate = useNavigate();

  const openCluster = () => {
    dispatch(
      getComputeProjectInstancesThunk({
        computeProjectId: cluster._id,
        topupReport: 'y',
      })
    );
    navigate(
      `/${
        isPublicAccessible ? communityOrgName : 'compute'
      }/billing/compute-usage/${cluster?._id}`
    );
  };

  const getProviderIcon = (provider: string) => {
    switch (provider) {
      case 'DOCKERHUB':
        return <DockerIcon className="w-8 h-8" />;
      default:
        return <BlueCloudIcon className="w-8 h-8" />;
    }
  };

  const getTotalSpent = (value: number) =>
    value.toFixed(config.dollarDecimalRound.DOLLAR_DECIMAL_ROUND);
  const getMonthlyUsage = (value: number) =>
    (value * 30).toFixed(config.dollarDecimalRound.DOLLAR_DECIMAL_ROUND);

  return (
    <ComputeUsageCard
      key={cluster._id}
      icon={getProviderIcon(cluster?.provider)}
      title={cluster.name || 'N/A'}
      subtitle={`Updated ${dayjs().to(
        dayjs(new Date(`${cluster?.updatedAt}`))
      )}`}
      totalSpent={getTotalSpent(cluster?.topupReport?.usedTillNow || 0)}
      usageCost={getMonthlyUsage(cluster?.topupReport?.dailyUsage || 0)}
      branchNumber={cluster.instanceReport.total}
      cardType="vertical"
      disabled={false}
      handleClick={openCluster}
    />
  );
};

export default ClusterTopupCard;
