import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import {
  injectedWallet,
  rainbowWallet,
  walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { createConfig } from 'wagmi';
import { chains, publicClient } from './chains';
import config from '.';

const connectors = connectorsForWallets([
  {
    groupName: 'Wallet',

    wallets: [
      injectedWallet({ chains }),
      rainbowWallet({
        projectId: config.web3.PROJECT_ID,
        chains,
      }),
      walletConnectWallet({
        projectId: config.web3.PROJECT_ID,
        chains,
      }),
    ],
  },
]);

export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});
