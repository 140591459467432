import * as Sentry from '@sentry/react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { InstanceLogs } from '../../instance/instance.interfaces';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../../rtk-store';
import { getDeploymentLogsService } from '../../instance/instance.services';
import { setLogsLastUpdated } from './service.slice';

export const loadMoreServiceLogsThunk = createAsyncThunk(
  'service/loadMoreServiceLogs',
  async (
    payload: {
      deploymentId: string;
      type: InstanceLogs;
      search: string;
      serviceName: string;
    },
    { rejectWithValue, fulfillWithValue, getState, dispatch }
  ) => {
    try {
      const { computeService } = getState() as RootState;
      const serviceLogsPagination =
        computeService.selectedServiceComputeLogsPagination.currentPage;
      const response = await getDeploymentLogsService(
        payload.deploymentId,
        payload.type,
        serviceLogsPagination + 1,
        payload?.search || '',
        payload.serviceName
      );
      if (response.success) {
        dispatch(setLogsLastUpdated(response.deployment.updatedAt));
        return fulfillWithValue(response.deployment.logs);
      }
      Sentry.captureMessage(response.message);
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);
