import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { TextInput, EmptyState } from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Arrow } from '../../../../../assets/compute/icons/arrow-drop-icon.svg';
import { ReactComponent as NoSearchFound } from '../../../../../assets/compute/icons/no-search-found.svg';
import { useDebounce } from '../../../../../hooks/useDebounce';
import {
  IClusterTemplate,
  ProcessingType,
} from '../../../../../redux/compute/cluster/cluster.interfaces';
import Styles from '../../../../../styles/compute/compute-pricing.module.scss';
import { ReactComponent as Search } from '../../../../../assets/compute/icons/search-icon.svg';
import FilterBadge from '../FilterBadge';
import TemplateCard from '../TemplateCard';
import { AppDispatch, RootState } from '../../../../../redux/compute/store';
import {
  getClusterTemplateCategoriesThunk,
  getClusterTemplatesThunk,
} from '../../../../../redux/compute/cluster/cluster.thunks';
import {
  setSelectedClusterRtk,
  setClusterDeploymentProgressRtk,
  setSelectedTemplateCategoryRtk,
  setSelectedTemplateRtk,
} from '../../../../../redux/compute/cluster/cluster.slice';
import {
  chooseClusterTypeRtk,
  getInstancePlansEndRtk,
  resetPersistentValuesRtk,
  selectClusterRegionRtk,
  setInitialMultiserviceState,
  setIpLeaseType,
  setUpdatedCustomPriceRtk,
  toggleConfirmRedeploySwitchRtk,
  updateComputeStepRtk,
  updateCustomPlanRtk,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.slice';
import { useQuery } from '../../../../../hooks/useQuery';
import TemplateCardLoading from '../../../../../components/Compute/Loaders/template-card-loader';
import { toggleModalShowRtk } from '../../../../../redux/compute/modal/modal.slice';
import {
  ClusterType,
  ConfirmRedeploySwitchType,
  InstanceDeploymentType,
  IPLeaseType,
  LoadingType,
  ScalingType,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.interfaces';
import {
  calculatePriceThunk,
  getInstancePlanThunk,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.thunks';
import {
  generateInitialMultiserviceMap,
  isAccelerateDeployment,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.utils';

// eslint-disable-next-line no-unused-vars
enum FilterEnum {
  // eslint-disable-next-line no-unused-vars
  ALL = '',
  // eslint-disable-next-line no-unused-vars
  DATABASE = 'Database',
  // eslint-disable-next-line no-unused-vars
  NODE = 'Node',
  //   eslint-disable-next-line no-unused-vars
  TOOLS = 'Tools',
  //   eslint-disable-next-line no-unused-vars
  AI = 'AI',
}

interface IProps {
  setSelectedInstancePlan: any;
  // eslint-disable-next-line no-unused-vars
  setCardClicked: (cardClicked: boolean) => void;
  updatedTemplate: IClusterTemplate | null;
  // eslint-disable-next-line no-unused-vars
  setUpdatedTemplate: (updatedTemplate: IClusterTemplate | null) => void;
}
const SelectTemplateSection = ({
  setSelectedInstancePlan,
  setCardClicked,
  updatedTemplate,
  setUpdatedTemplate,
}: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const queryParams = useQuery();
  const { computeProjectId } = useParams<{ computeProjectId: string }>();
  const provider = queryParams.get('provider');
  const type = queryParams.get('type');
  const templateParam = queryParams.get('template');
  const deploymentTypeParam = queryParams.get('deploymentType');
  const [selectedCard, setSelectedCard] = useState<string | null>(null);
  const [selectedBadge, setSelectedBadge] = useState<number | null>(null);
  const [showTemplate, setShowTemplate] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [showAllTemplate, setShowAllTemplate] = useState<boolean>(false);

  const clusterTemplateCategories: string[] | undefined = useSelector(
    (state: RootState) => state.cluster.clusterTemplateCategories
  );

  const clusterTemplateCategoriesLoading: boolean = useSelector(
    (state: RootState) => state.cluster.clusterTemplateCategoriesLoading
  );

  const selectedClusterTemplateCategory: string = useSelector(
    (state: RootState) => state.cluster.selectedClusterTemplateCategory
  );

  const cpuClusterTemplates: IClusterTemplate[] = useSelector(
    (state: RootState) => state.cluster.cpuClusterTemplates
  );
  const gpuClusterTemplates: IClusterTemplate[] = useSelector(
    (state: RootState) => state.cluster.gpuClusterTemplates
  );
  const clusterTemplatesLoading: boolean = useSelector(
    (state: RootState) => state.cluster.clusterTemplatesLoading
  );
  const selectedTemplate = useSelector(
    (state: RootState) => state.cluster.selectedTemplate
  );
  const selectedOrganisation = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation
  );

  const selectedOrganisationLoading: boolean = useSelector(
    (state: RootState) => state.organisation.selectedOrganisationLoading
  );

  const clusterType = useSelector(
    (state: RootState) => state.instanceCreation.clusterType
  );
  const confirmRedeploySwitch = useSelector(
    (state: RootState) => state.instanceCreation.confirmRedeploySwitch
  );
  const confirmRedeploySwitchType = useSelector(
    (state: RootState) => state.instanceCreation.confirmRedeploySwitchType
  );
  const deploymentType = useSelector(
    (state: RootState) => state.instanceCreation.deploymentType
  );
  const instancePlans = useSelector(
    (state: RootState) => state.instanceCreation.instancePlans
  );
  const clusterRegions = useSelector(
    (state: RootState) => state.instanceCreation.clusterRegions
  );
  const scalingType = useSelector(
    (state: RootState) => state.instanceCreation.clusterScaling
  );
  const [allClusterTemplate, setAllClusterTemplate] =
    useState<IClusterTemplate[]>(cpuClusterTemplates);
  const [filteredClusterTemplates, setFilteredClusterTemplates] =
    useState<IClusterTemplate[]>(allClusterTemplate);
  const loading =
    clusterTemplateCategoriesLoading &&
    clusterTemplatesLoading &&
    selectedOrganisationLoading;

  const filterBadgeContent = useMemo(
    () => [
      {
        key: 1,
        category: FilterEnum.ALL,
      },
      {
        key: 2,
        category: FilterEnum.DATABASE,
      },
      // {
      //   key: 3,
      //   category: FilterEnum.NODE,
      // },
      {
        key: 4,
        category: FilterEnum.TOOLS,
      },
      // {
      //   key: 5,
      //   category: FilterEnum.AI,
      // },
    ],
    []
  );

  const handleCardClick = (template: IClusterTemplate) => {
    dispatchRtk(resetPersistentValuesRtk());
    dispatchRtk(setUpdatedCustomPriceRtk(0));
    const hasEndpoint = template.serviceData.ports.some(
      (port) => port.endpoint !== undefined
    );
    if (hasEndpoint) {
      dispatchRtk(setIpLeaseType(IPLeaseType.DEDICATED));
      dispatchRtk(calculatePriceThunk(LoadingType.IP_LEASE));
    } else {
      dispatchRtk(setIpLeaseType(IPLeaseType.SHARED));
      dispatchRtk(calculatePriceThunk(LoadingType.IP_LEASE));
    }
    const defaultRegion = clusterRegions
      .filter((region) => region.scalingMode === scalingType)
      .find((region) => region.isPrimaryForScaling);
    dispatchRtk(selectClusterRegionRtk(defaultRegion?.region || ''));
    dispatchRtk(getInstancePlanThunk({ search: '' }));
    dispatchRtk(updateCustomPlanRtk({}));
    if (instancePlans.akashMachineImages.length > 0) {
      const updatedAkashMachineImages = instancePlans.akashMachineImages.filter(
        (plan) => plan._id !== 'custom-id'
      );
      const updatedInstancePlans = {
        ...instancePlans,
        akashMachineImages: updatedAkashMachineImages,
      };
      dispatchRtk(getInstancePlansEndRtk(updatedInstancePlans));
    }

    const templateServiceIds = template.services.map((service) => service._id);

    const generatedMap = generateInitialMultiserviceMap(templateServiceIds);

    dispatchRtk(setInitialMultiserviceState(generatedMap));

    if (type === 'redeploy' && template.name !== templateParam) {
      dispatchRtk(
        toggleModalShowRtk({
          modalShow: true,
          modalType: 'confirmRedeploySwitch',
          options: {
            redeploySwitchType: ConfirmRedeploySwitchType.TEMPLATE,
            index: ClusterType.TEMPLATE,
            confirmCallback: () => {
              dispatchRtk(chooseClusterTypeRtk(ClusterType.TEMPLATE));
            },
          },
        })
      );
      setUpdatedTemplate(template);
    } else {
      setSelectedInstancePlan(null);
      setSelectedCard(template._id);
      dispatchRtk(setSelectedTemplateRtk(template));
      if (clusterType === ClusterType.TEMPLATE) {
        dispatchRtk(updateComputeStepRtk(3));
      }
      setCardClicked(true);
    }
  };

  useEffect(() => {
    if (
      deploymentType === InstanceDeploymentType.ACCELERATE &&
      filterBadgeContent
    ) {
      setSelectedBadge(5);
      dispatchRtk(setSelectedTemplateCategoryRtk('AI'));
    } else {
      setSelectedBadge(0);
      dispatchRtk(setSelectedTemplateCategoryRtk(''));
    }
  }, [deploymentType, dispatchRtk, filterBadgeContent]);

  useEffect(() => {
    setSelectedCard(null);
  }, [selectedOrganisation]);

  const handleFilterClick = (index: number, template: string) => {
    setSelectedBadge(index);
    if (template === 'All') {
      dispatchRtk(setSelectedTemplateCategoryRtk(''));
    } else {
      dispatchRtk(setSelectedTemplateCategoryRtk(template));
    }
  };
  useEffect(() => {
    if (
      confirmRedeploySwitch &&
      type === 'redeploy' &&
      confirmRedeploySwitchType === ConfirmRedeploySwitchType.TEMPLATE
    ) {
      dispatchRtk(chooseClusterTypeRtk(ClusterType.TEMPLATE));
      navigate(`/compute/${computeProjectId}/new-instance/`);
      dispatchRtk(
        toggleConfirmRedeploySwitchRtk({
          state: false,
          type: ConfirmRedeploySwitchType.DEFAULT,
        })
      );
      if (updatedTemplate && selectedCard && selectedTemplate) {
        dispatchRtk(setSelectedTemplateRtk(updatedTemplate));
        setSelectedCard(updatedTemplate._id);
        dispatchRtk(updateComputeStepRtk(3));
        setSelectedInstancePlan(null);
        setCardClicked(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    confirmRedeploySwitch,
    type,
    confirmRedeploySwitchType,
    updatedTemplate,
    selectedCard,
    dispatchRtk,
    navigate,
    selectedTemplate,
  ]);

  useEffect(() => {
    const selectedFilterBadge = filterBadgeContent.find(
      (filter) =>
        filter.category.toLowerCase() ===
        selectedClusterTemplateCategory.toLowerCase()
    );
    setSelectedBadge(selectedFilterBadge?.key || 1);
  }, [filterBadgeContent, selectedClusterTemplateCategory]);

  useEffect(() => {
    if (deploymentType === InstanceDeploymentType.ACCELERATE) {
      setAllClusterTemplate(gpuClusterTemplates);
      setFilteredClusterTemplates(gpuClusterTemplates);
    } else {
      setAllClusterTemplate(cpuClusterTemplates);
      setFilteredClusterTemplates(cpuClusterTemplates);
    }
  }, [
    cpuClusterTemplates,
    allClusterTemplate,
    deploymentType,
    gpuClusterTemplates,
  ]);

  const handleShowTemplates = () => {
    setShowTemplate(!showTemplate);
  };

  const debouncedSearchTerm = useDebounce(searchValue, 500);

  useEffect(() => {
    if (
      cpuClusterTemplates.length < 1 ||
      clusterTemplateCategories.length === 0
    ) {
      dispatchRtk(getClusterTemplateCategoriesThunk());
      if (!selectedOrganisationLoading) {
        dispatchRtk(
          getClusterTemplatesThunk({
            search: '',
            category: '',
            isUrl: false,
            processingType: ProcessingType.CPU,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganisation, selectedOrganisationLoading]);

  useEffect(() => {
    const filteredTemplates = [...allClusterTemplate].filter((template) => {
      return (
        template.name
          .toLowerCase()
          .includes((debouncedSearchTerm as string).toLowerCase()) &&
        template.metadata.category
          .toLowerCase()
          .includes(selectedClusterTemplateCategory.toLowerCase())
      );
    });
    setFilteredClusterTemplates(filteredTemplates);
  }, [
    debouncedSearchTerm,
    allClusterTemplate,
    selectedClusterTemplateCategory,
  ]);

  useEffect(() => {
    dispatchRtk(
      setSelectedClusterRtk({
        name: '',
        repository: '',
        provider: '',
        tags: '',
      })
    );
    dispatchRtk(setClusterDeploymentProgressRtk(1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      provider === ClusterType.TEMPLATE &&
      templateParam &&
      deploymentTypeParam
    ) {
      let redeploySelectedTemplate;
      let selectedTemplateIndex = 0;
      if (deploymentTypeParam === InstanceDeploymentType.ACCELERATE) {
        dispatchRtk(
          getClusterTemplatesThunk({
            search: '',
            category: '',
            isUrl: false,
            processingType: ProcessingType.GPU,
          })
        );
        redeploySelectedTemplate = gpuClusterTemplates.find(
          (t) => t.name === templateParam
        );
      } else {
        redeploySelectedTemplate = cpuClusterTemplates.find(
          (t) => t.name === templateParam
        );
        selectedTemplateIndex = cpuClusterTemplates.findIndex(
          (t) => t.name === templateParam
        );
      }
      if (redeploySelectedTemplate) {
        handleCardClick(redeploySelectedTemplate);
        setSelectedCard(redeploySelectedTemplate._id);
        dispatchRtk(updateComputeStepRtk(3));
        if (selectedTemplateIndex > 16) {
          setShowAllTemplate(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    provider,
    templateParam,
    deploymentTypeParam,
    cpuClusterTemplates,
    gpuClusterTemplates,
    dispatchRtk,
  ]);

  const showUpdatedTemplate = showAllTemplate
    ? filteredClusterTemplates
    : filteredClusterTemplates?.slice(
        0,
        showTemplate ? filteredClusterTemplates.length : 16
      );

  const arrowClasses = `${Styles.arrow__icon} ${
    showTemplate ? Styles.rotate180 : Styles.rotate0
  }`;

  return (
    <div>
      <span
        className="text-base-heading-text-color dark:text-dark-base-heading-text-color
        text-xl font-bold"
      >
        {isAccelerateDeployment(deploymentType) ? '3.' : '4.'}
        Start from Marketplace App
      </span>
      <div className="flex ll:flex-row lg:gap-0 gap-5 flex-col ll:items-center w-full mt-6">
        {!isAccelerateDeployment(deploymentType) && (
          <div className="md:gap-0 gap-5 md:flex flex-1 grid grid-cols-2 md:space-x-4 space-x-0">
            {filterBadgeContent.map((content) => (
              <div key={content.key}>
                <FilterBadge
                  title={content.category}
                  isSelected={
                    content.key === 1
                      ? selectedBadge === null ||
                        selectedBadge === filterBadgeContent[0].key
                      : selectedBadge === content.key
                  }
                  onSelect={() =>
                    handleFilterClick(content.key, content.category)
                  }
                />
              </div>
            ))}
          </div>
        )}
        <div className="ll:mt-0 mt-10">
          {!loading ? (
            <div className="w-56">
              <TextInput
                leftIcon={
                  <Search className="text-form-text dark:text-dark-form-text" />
                }
                value={searchValue}
                placeholder="Eg. MongoDB"
                onChange={(value) => setSearchValue(value as string)}
              />
            </div>
          ) : (
            <Skeleton
              height={45}
              width={320}
              duration={2}
              containerClassName="flex"
            />
          )}
        </div>
      </div>

      {!clusterTemplatesLoading && !selectedOrganisationLoading ? (
        <>
          {filteredClusterTemplates?.length === 0 ? (
            <div className="mt-10 h-96">
              <EmptyState
                icon={<NoSearchFound className="w-40 h-40 py-8" />}
                buttonDisabled={false}
                entityTitle="Sorry! No match found!"
                // eslint-disable-next-line max-len
                subText="Please select another template that suits your needs or contact the Spheron team."
                showButton={false}
                onClick={() => null}
              />
            </div>
          ) : (
            <>
              <div
                className="grid grid-cols-1 lg:grid-cols-3 ll:grid-cols-4 
        gap-6 mt-10"
              >
                {showUpdatedTemplate?.map((template: IClusterTemplate) => (
                  <TemplateCard
                    key={template._id}
                    id={template._id || template.serviceData._id}
                    title={template.name}
                    imageShow
                    image={template.metadata.icon}
                    badgeContent={template.metadata.category}
                    subtitle=""
                    isSelected={selectedTemplate?.name === template.name}
                    onSelect={() => handleCardClick(template)}
                    isSelectedTemplate
                    discount={
                      scalingType === ScalingType.NO ? null : template.discount
                    }
                    computeProjectId={computeProjectId}
                  />
                ))}
              </div>
              {filteredClusterTemplates?.length > 16 && !showAllTemplate && (
                <button
                  type="button"
                  className="cursor-pointer border border-lightGray rounded px-4 py-2 
                  flex items-center text-sm font-medium mt-6 
                  dark:bg-dark-base-bg bg-base-bg
                  dark:border-dark-base-border border-base-border
                  hover:border-form-selected dark:hover:border-dark-form-selected
                  ease-in duration-75"
                  onClick={handleShowTemplates}
                >
                  {showTemplate ? 'Less Templates' : 'More Templates'}
                  <Arrow className={`ml-4 ${arrowClasses}`} />
                </button>
              )}
            </>
          )}
        </>
      ) : (
        <div
          className="grid grid-cols-1 lg:grid-cols-3 ll:grid-cols-4 
          gap-x-6 gap-y-6 mt-10"
        >
          {[...Array(16)].map((item) => (
            <TemplateCardLoading showBadge key={item} />
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectTemplateSection;
