import { Table } from '@spheron/ui-library';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

interface IProps {
  headers: string[];
}

const TableLoader = ({ headers }: IProps) => {
  return (
    <Table
      tableHeader={{
        tableRowType: 'record',
        tableRow: headers.map((header: string, i) => ({
          id: i,
          title: header,
          tableCellType: 'header',
        })),
      }}
      tableRows={Array.from(Array(4)).map((i: number) => ({
        id: i,
        tableRowType: 'record',
        tableRow: headers.map(() => ({
          id: 1,
          content: <Skeleton height={25} width={100} duration={2} />,
          tableCellType: 'default',
        })),
      }))}
    />
  );
};

export default TableLoader;
