import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@spheron/ui-library';
import SuccessIcon from '../../assets/compute/icons/solana-step-success.svg';
import FailedIcon from '../../assets/compute/icons/solana-step-failed.svg';
import LoadingIcon from '../../assets/compute/icons/solana-step-loading.svg';
import { ISolanaStepInfo } from '../../redux/solana/solana.interfaces';
import { ISubscriptionReservation } from '../../redux/subscription/subscription.interfaces';
import TextInput from '../InputFields/text-input';
import { ReservationType } from '../../redux/solana/solana.utils';
import config from '../../config';
import { RootState } from '../../redux/rtk-store';
import {
  useAppDispatch as useDispatchRtk,
  useAppSelector as useSelectorRtk,
} from '../../redux/rtk-context';
import {
  setSolanaPayStepInfoRtk,
  setSolanaReservationRtk,
} from '../../redux/solana/solana.slice';
import {
  cancelReservationThunk,
  createTransactionThunk,
  createActivationThunk,
} from '../../redux/solana/solana.thunks';

interface IProps {
  text: string;
  stepInfo: ISolanaStepInfo;
  step: number;
  amount: number;
  type: ReservationType;
  walletAddress: string;
}

const SolanaPayStep = ({
  text,
  stepInfo,
  step,
  amount,
  type,
  walletAddress,
}: IProps) => {
  const dispatchRtk = useDispatchRtk();
  const navigate = useNavigate();

  const reservedSubscription: ISubscriptionReservation | null = useSelectorRtk(
    (state: RootState) => state.subscription.reservedSubscription
  );
  const reservationId: string = useSelectorRtk(
    (state: RootState) => state.solana.reservationId
  );
  const description: string = useSelectorRtk(
    (state: RootState) => state.solana.description
  );

  const solanaTransactionHash: string = useSelectorRtk(
    (state: RootState) => state.solana.transactionHash
  );
  const payOverdueBill = useSelectorRtk(
    (state: RootState) => state.solana.payOverdueBill
  );

  const [transactionHash, setTransactionHash] = useState<string>('');
  const [showTransactionHash, setShowTransactionHash] =
    useState<boolean>(false);

  const statusIcon = () => {
    if (stepInfo.success) return SuccessIcon;
    if (stepInfo.error) return FailedIcon;
    return LoadingIcon;
  };

  const passHistory = type === ReservationType.Subscription ? navigate : null;

  useEffect(() => {
    if (solanaTransactionHash.length !== 0) {
      setShowTransactionHash(false);
      setTransactionHash(solanaTransactionHash);
    } else setShowTransactionHash(true);
  }, [solanaTransactionHash]);

  const handlePaymentDone = () => {
    dispatchRtk(
      setSolanaPayStepInfoRtk({
        step: 1,
        value: {
          error: false,
          success: true,
          loading: false,
          message: '',
        },
      })
    );
    dispatchRtk(
      setSolanaPayStepInfoRtk({
        step: 2,
        value: {
          error: false,
          success: true,
          loading: false,
          message: '',
        },
      })
    );
    dispatchRtk(
      setSolanaPayStepInfoRtk({
        step: 3,
        value: {
          error: true,
          success: false,
          loading: false,
          message: '',
        },
      })
    );
  };

  const errorhandle = () => {
    switch (step) {
      case 1:
        return (
          <>
            {reservedSubscription && (
              <div className="flex flex-col gap-4">
                <Button
                  buttonType="ghost"
                  label="Cancel Reservation"
                  size="small"
                  onClick={() => {
                    if (reservedSubscription)
                      dispatchRtk(
                        cancelReservationThunk(reservedSubscription._id)
                      );
                  }}
                  disabled={reservedSubscription === null}
                />
                <div
                  className="text-sm font-medium text-gray-700 dark:text-gray-300
                cursor-pointer underline"
                  role="presentation"
                  onClick={() => {
                    dispatchRtk(
                      setSolanaReservationRtk({
                        reservationId: reservedSubscription.reservationId,
                        description: '',
                      })
                    );
                    handlePaymentDone();
                  }}
                >
                  Already Paid?
                </div>
              </div>
            )}
          </>
        );
      case 2:
        return (
          <>
            <div className="flex flex-col gap-4">
              <Button
                buttonType="primary"
                label="Retry"
                size="small"
                onClick={() => {
                  if (type === ReservationType.PayOverdue) {
                    if (payOverdueBill >= 0) {
                      dispatchRtk(
                        createTransactionThunk({
                          walletAddress,
                          reservationId,
                          description,
                          // liveTokenPrice is passed as amount for type PayOverdue
                          amount: Number(
                            (payOverdueBill / amount).toFixed(
                              config.web3.solana.AMOUNT_DECIMAL_PRECISION
                            )
                          ),
                          history: passHistory,
                          type,
                        })
                      );
                    }
                  } else
                    dispatchRtk(
                      createTransactionThunk({
                        walletAddress,
                        reservationId,
                        description,
                        amount,
                        history: passHistory,
                        type,
                      })
                    );
                }}
              />
              <div
                className="text-sm font-medium text-gray-700 dark:text-gray-300
                cursor-pointer underline"
                role="presentation"
                onClick={handlePaymentDone}
              >
                Already Paid?
              </div>
            </div>
          </>
        );
      case 3:
        return (
          <div className="flex flex-row justify-between gap-4 items-center">
            <div className="flex flex-row gap-2">
              {showTransactionHash && (
                <TextInput
                  placeholder="Transaction Hash"
                  value={transactionHash}
                  onChange={setTransactionHash}
                />
              )}
              <Button
                buttonType="primary"
                label="Retry"
                size="small"
                onClick={() => {
                  dispatchRtk(
                    createActivationThunk({
                      walletAddress,
                      transactionHash,
                      reservationId,
                      history: passHistory,
                      type,
                    })
                  );
                }}
                disabled={
                  transactionHash?.length === 0 || reservationId?.length === 0
                }
              />
            </div>
            {!showTransactionHash && (
              <div
                className="text-sm text-gray-700 dark:text-gray-300 cursor-pointer hover:underline"
                role="presentation"
                onClick={() => setShowTransactionHash(true)}
              >
                Change transaction hash?
              </div>
            )}
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {(stepInfo.error || stepInfo.loading || stepInfo.success) && (
        <div className="flex flex-row gap-2 rounded-xl bg-bg-solanaPayStep dark:bg-gray-900 p-1">
          <div className="flex items-start justify-start">
            <img
              src={statusIcon()}
              className={`${stepInfo.loading ? 'animate-spin' : ''} p-3`}
              alt="success"
            />
          </div>
          <div className="flex flex-col gap-2 py-3 dark:text-dark-gray-text">
            <p className="font-semibold text-md">{text}</p>
            {stepInfo.error && (
              <>
                <p className="text-sm">{stepInfo.message}</p>
                {errorhandle()}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SolanaPayStep;
