import React, { useState, useEffect } from 'react';
import {
  Button,
  SectionHeading,
  TextInput,
  UserProfile,
} from '@spheron/ui-library';
import dayjs from 'dayjs';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfileThunk } from '../../../../redux/compute/user/user.thunks';
import { toggleUpdateUserLoadingRtk } from '../../../../redux/compute/user/user.slice';
import { AppDispatch, RootState } from '../../../../redux/compute/store';
import CopyIdCard from '../../../../components/Cards/copy-id-card';
import { ProviderName } from '../../../../redux/compute/combined-state.interface';
import {
  validateEmail,
  withLoader,
} from '../../../../redux/compute/root-utils';
import TextInputLoader from '../../../../components/Compute/Loaders/text-input-loader';

function UserGeneralSettings() {
  const [username, setUsername] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [avatar, setAvatar] = useState<string>('');
  const [dataChanged, setDataChanged] = useState(false);

  const user = useSelector((state: RootState) => state.user.user);
  const userPlatformProfile = useSelector(
    (state: RootState) => state.user.userPlatformProfile
  );
  const userLoading = useSelector((state: RootState) => state.user.userLoading);

  const updateUserLoading = useSelector(
    (state: RootState) => state.user.updateUserDataLoading
  );

  const webappOrganisations = useSelector(
    (state: RootState) => state.organisation.webappOrganisations
  );
  const computeOrganisations = useSelector(
    (state: RootState) => state.organisation.computeOrganisations
  );
  const storageOrganisations = useSelector(
    (state: RootState) => state.organisation.storageOrganisations
  );

  const totalOrganisations =
    webappOrganisations.length +
    computeOrganisations.length +
    storageOrganisations.length;

  const dispatchRtk = useDispatch<AppDispatch>();

  useEffect(() => {
    if (userPlatformProfile) {
      setUsername(userPlatformProfile?.username);
      setName(userPlatformProfile?.name);
      setEmail(userPlatformProfile?.email);
      setAvatar(userPlatformProfile?.avatar);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (userPlatformProfile) {
      if (
        userPlatformProfile?.username !== username ||
        userPlatformProfile?.name !== name ||
        userPlatformProfile?.email !== email ||
        userPlatformProfile?.avatar !== avatar
      ) {
        setDataChanged(true);
      } else {
        setDataChanged(false);
      }
    }
  }, [avatar, email, name, userPlatformProfile, username]);

  const handleSubmit = () => {
    dispatchRtk(toggleUpdateUserLoadingRtk(true));
    const updateUserData = {
      ...userPlatformProfile,
      username,
      name,
      avatar,
      email,
    };
    dispatchRtk(updateProfileThunk(updateUserData));
  };

  const updateTime = dayjs().to(
    dayjs(new Date(user?.createdAt ? `${user?.createdAt}` : new Date()))
  );

  return (
    <>
      <SectionHeading
        heading="Profile Details"
        subHeading=""
        showRefresh={false}
        showSwitch={false}
        handleIconClick={() => {}}
        handleRefreshClick={() => {}}
        handleSwitchClick={() => {}}
        switchDisable={false}
        handleClick={() => {}}
        loading={false}
        time={''}
        refreshType={'default'}
        showText={false}
        toggleId={''}
        isChecked={false}
        handleChange={() => {}}
      />
      <div className="mt-8 flex flex-row gap-6">
        <div className="shrink-0">
          {withLoader(
            userLoading,
            <Skeleton width={360} height={380} duration={2} />,
            <UserProfile
              profileIcon={<img src={userPlatformProfile?.avatar} alt="user" />}
              profileName={userPlatformProfile?.name || ''}
              profileUsername={userPlatformProfile?.username}
              email={
                userPlatformProfile?.email ||
                'Provide email so that we can send you notifications.'
              }
              profileInfo={[
                {
                  id: 1,
                  text: `Joined Spheron on ${dayjs(`${user?.createdAt}`).format(
                    'MMMM DD, YYYY'
                  )} (${updateTime})`,
                },
                {
                  id: 2,
                  text: ` Collaborates on ${totalOrganisations} organizations`,
                },
              ]}
              classname="!w-max"
              setAvatar={setAvatar}
            />
          )}
        </div>

        <div className="w-full flex flex-col gap-8">
          <div className="grid grid-cols-2 gap-x-6 gap-y-8">
            {userLoading ? (
              <>
                {Array.from(Array(4).keys()).map((i) => (
                  <TextInputLoader key={i} />
                ))}
              </>
            ) : (
              <>
                <TextInput
                  inputSize="compact"
                  type="string"
                  value={username}
                  disabled={userLoading}
                  placeholder="Username"
                  onChange={(value) => setUsername(value as string)}
                  label="YOUR USERNAME"
                />
                <TextInput
                  inputSize="compact"
                  type="string"
                  value={name}
                  disabled={userLoading}
                  placeholder="Name"
                  onChange={(value) => setName(value as string)}
                  label="FULL NAME"
                />
                <TextInput
                  inputSize="compact"
                  type="string"
                  value={email}
                  disabled={
                    user?.provider.name !== ProviderName.WEB3 ||
                    validateEmail(userPlatformProfile?.email || '')
                  }
                  placeholder="Email"
                  onChange={(value) => setEmail(value as string)}
                  label="YOUR EMAIL"
                />
                <CopyIdCard
                  key={0}
                  data={user?._id || ''}
                  message="User ID"
                  loading={userLoading}
                  title="User Id"
                  isCompact
                />
                {user?.providerProfiles.some(
                  (profile) => profile.providerName === 'web3'
                ) && (
                  <div className="col-span-2">
                    <CopyIdCard
                      key={1}
                      data={
                        String(
                          user?.providerProfiles.find(
                            (profile) => profile.providerName === 'web3'
                          )?.id
                        ) || ''
                      }
                      message="Wallet address"
                      loading={false}
                      title="Wallet"
                      isCompact
                    />
                  </div>
                )}
              </>
            )}
          </div>
          <Button
            buttonType="primary"
            label="Save"
            size="medium"
            classname="w-min"
            onClick={handleSubmit}
            disabled={!dataChanged || updateUserLoading || userLoading}
            loading={updateUserLoading}
          />
        </div>
      </div>
    </>
  );
}

export default UserGeneralSettings;
