import * as Sentry from '@sentry/react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { addNotificationRtk } from '../notification/notification.slice';
import { NotificationType } from '../notification/notification.interfaces';
import { updateUserPreferredTheme } from '../user/user.services';
// eslint-disable-next-line import/no-cycle
import { setThemeRtk } from './theme.slice';
import { ITheme } from './theme.interfaces';

export const toggleThemeThunk = createAsyncThunk(
  'themes/toggleTheme',
  async (payload: ITheme, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      dispatch(setThemeRtk(payload));
      const response: any = await updateUserPreferredTheme(payload);
      if (response?.error) {
        dispatch(
          addNotificationRtk({
            message: (response.error as Error).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }

      return fulfillWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);
