/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Button, Link, SectionHeading } from '@spheron/ui-library';
import { ReactComponent as ExternalLinkIcon } from '@spheron/ui-library/dist/assets/spheron-specific/external-link.svg';
import { useDispatch, useSelector } from 'react-redux';
import ReferralStyles from '../../../../styles/compute/referral.module.scss';
import { toggleModalShowRtk } from '../../../../redux/compute/modal/modal.slice';
import SocialShareButton from '../../../../components/Compute/Buttons/UserReferral/social-share';
import ReferralTextInput from '../../../../components/Compute/InputFields/referral-text-input';
import {
  getRaiseRequestLimitThunk,
  getReferralCodeThunk,
  getReferralCreditsThunk,
  getReferralDetailsThunk,
  getReferralWithdrawalsThunk,
} from '../../../../redux/compute/user/user.thunks';
import { AppDispatch, RootState } from '../../../../redux/compute/store';
import ReferralStatsItem from '../../../../components/Compute/TableItems/referral-stats-table-item';
import config from '../../../../config';
import { withLoader } from '../../../../redux/compute/root-utils';

const UserSettingReferrals = () => {
  const dispatchRtk = useDispatch<AppDispatch>();

  const referralEnabled = useSelector(
    (state: RootState) => state.user.referralEnabled
  );

  const referralCode = useSelector(
    (state: RootState) => state.user.referralCode
  );

  const userLoading = useSelector((state: RootState) => state.user.userLoading);

  const referralCodeLoading = useSelector(
    (state: RootState) => state.user.referralCodeLoading
  );

  const referrals = useSelector((state: RootState) => state.user.referrals);

  const referralCredits = useSelector(
    (state: RootState) => state.user.referralCredits
  );

  const referralLoading = useSelector(
    (state: RootState) => state.user.referralLoading
  );

  const referralCreditsLoading = useSelector(
    (state: RootState) => state.user.referralCreditsLoading
  );

  const setReferralCodeLoading = useSelector(
    (state: RootState) => state.user.setReferralCodeLoading
  );

  const referredUsers: { signedUp: number; pending: number } = useMemo(() => {
    return {
      signedUp: referrals.length,
      pending: referrals.filter((referral) => referral.paymentDone === false)
        .length,
    };
  }, [referrals]);

  const [referralEdit, setReferralEdit] = useState<string>('');
  const [referralLink, setReferralLink] = useState<string>('');

  useEffect(() => {
    if (referralCode.length === 0 && referralEnabled) {
      dispatchRtk(getReferralCodeThunk());
      dispatchRtk(getReferralDetailsThunk());
      dispatchRtk(getReferralCreditsThunk());
      dispatchRtk(getReferralWithdrawalsThunk());
      dispatchRtk(getRaiseRequestLimitThunk());
    }
  }, [dispatchRtk, referralCode.length, referralEnabled]);

  useMemo(() => {
    setReferralEdit(referralCode);
    setReferralLink(
      `${window.location.origin}/signup?referral=${referralCode}`
    );
  }, [referralCode]);

  // RAISE WITHDRAWAL REQUEST DISABLED, MIGHT GET ADDED LATER
  // const raiseRequestLimit = useSelectorRtk(
  //   (state: RootState) => state.user.raiseRequestLimit
  // );

  // const raiseRequestLimitLoading = useSelectorRtk(
  //   (state: RootState) => state.user.raiseRequestLimitLoading
  // );

  // const handleRaiseRequest = () => {
  //   dispatchRtk(
  //     toggleModalShowRtk({
  //       modalShow: true,
  //       modalType: 'raiseWithdrawRequest',
  //     })
  //   );
  //   dispatchRtk(resetWithdrawalDetailsRtk());
  // };

  const socialShareButtons = [
    { title: 'Email', type: 'email' },
    { title: 'LinkedIn', type: 'linkedin' },
    { title: 'Twitter', type: 'twitter' },
  ];

  return (
    <div className="flex flex-col gap-6 w-full">
      <h1 className="font-bold text-lg">Code, Refer, & Earn Credits!</h1>
      {!referralEnabled ? (
        <div className="md:w-3/4">
          {withLoader(
            userLoading,
            <Skeleton height={40} width="100%" duration={2} />,
            <>
              <h2 className="font-normal text-sm">
                <b>Join the Adventure!</b> Dive into our new Spheron Referral
                Program to earn credits. 🌟 Just fill out the form below. If you
                have already submitted, our{' '}
                <a
                  href="https://discord.com/invite/ahxuCtm"
                  target="_blank"
                  rel="noreferrer"
                  className="underline"
                >
                  support team
                </a>{' '}
                is ready to assist!
              </h2>
            </>
          )}
          {withLoader(
            userLoading,
            <Skeleton height={36} width={168} duration={2} className="mt-8" />,
            <Button
              buttonType="primary"
              label="Yes, I'm Interested"
              size="medium"
              classname="mt-8"
              onClick={() => window.open(config.referral.REGISTRATION_LINK)}
            />
          )}
        </div>
      ) : (
        <>
          <div
            className={`border p-5 w-full rounded-lg border-base-border dark:border-dark-base-border
        ${ReferralStyles.refer__container} dark:bg-dark-base-fg
       `}
          >
            <h2 className="font-medium text-md">
              Your personalised referral link & code
            </h2>
            <div className="flex flex-row gap-2 flex-wrap items-center mt-2">
              <h2 className="font-normal text-sm">
                Get 10% in Credits when your developer friends hop on board with
                your code and settle their first bill! Referring is rewarding 🚀
              </h2>
              <Link
                type="primary"
                size="default"
                text="View referral rules"
                isBold={false}
                onClick={() =>
                  dispatchRtk(
                    toggleModalShowRtk({
                      modalShow: true,
                      modalType: 'referralRules',
                    })
                  )
                }
                rightIcon={
                  <ExternalLinkIcon className="h-5 w-5 text-base-link dark:text-dark-base-link" />
                }
              />
            </div>
            <div className="my-6 grid grid-cols-6 gap-6">
              <div className="md:col-span-2 col-span-6">
                {withLoader(
                  referralCodeLoading,

                  <Skeleton height={38} width="100%" duration={2} />,
                  <ReferralTextInput
                    type="text"
                    value={referralEdit}
                    onChange={(e) => setReferralEdit(e)}
                    initialValue={referralCode}
                    showCopy={false}
                    editable
                    editLoading={setReferralCodeLoading}
                    maxLength={100}
                  />
                )}
              </div>
              <div className="md:col-span-4 col-span-6">
                {withLoader(
                  referralCodeLoading,

                  <Skeleton width="100%" height={38} duration={2} />,
                  <ReferralTextInput
                    type="text"
                    value={referralLink}
                    onChange={() => null}
                    initialValue=""
                    showCopy
                    editable={false}
                    editLoading={false}
                    maxLength={150}
                  />
                )}
              </div>
            </div>
            <div className="flex flex-row gap-2">
              {socialShareButtons.map((socialShare) => (
                <SocialShareButton
                  type={socialShare.type}
                  title={socialShare.title}
                  referralLink={referralLink}
                  disabled={referralCodeLoading}
                />
              ))}
            </div>
          </div>
          <div className="flex flex-col gap-2 mt-4">
            <SectionHeading
              showRefresh={false}
              showSwitch={false}
              subHeading="A brief overview displaying the credits you have earned through referrals"
              heading="Referral Stats"
              handleIconClick={() => {}}
              handleRefreshClick={() => {}}
              handleSwitchClick={() => {}}
              switchDisable={false}
              handleClick={() => {}}
              loading={false}
              time={''}
              refreshType={'default'}
              showText={false}
              toggleId={''}
              isChecked={false}
              handleChange={() => {}}
            />
            <div className="mt-4 grid grid-cols-3 grid-flow-row gap-5">
              <div
                className="col-span-1 border border-base-border dark:border-dark-base-border rounded-lg
            p-5 flex flex-col justify-between"
              >
                <>
                  <h2 className="font-medium text-[18px] leading-[22px]">
                    Signup
                  </h2>
                  <h3 className="text-sm leading-5 font-400">
                    Your link has been used by
                  </h3>
                </>
                <h1 className="mt-5 font-semibold text-4xl">
                  {withLoader(
                    referralLoading,
                    <Skeleton width={50} duration={2} />,
                    <>{referredUsers.signedUp}</>
                  )}
                </h1>
              </div>

              <div
                className="col-span-1 border border-base-border dark:border-dark-base-border rounded-lg
            p-5 flex flex-col justify-between"
              >
                <>
                  <h2 className="font-medium text-[18px] leading-[22px]">
                    Pending
                  </h2>
                  <h3 className="text-sm leading-5 font-400">
                    Not yet qualified for referral rewards
                  </h3>
                </>
                <h1 className="mt-5 font-semibold text-4xl">
                  {withLoader(
                    referralLoading,
                    <Skeleton width={50} duration={2} />,
                    <>{referredUsers.pending}</>
                  )}
                </h1>
              </div>

              <div
                className={`col-span-1 ${ReferralStyles.referral__credits__container}
            p-5 text-white flex flex-col justify-between`}
              >
                <>
                  <h2 className="font-medium text-[18px] leading-[22px]">
                    Credited
                  </h2>
                  <h3 className="text-sm leading-5 font-400">
                    Total credits earned so far
                  </h3>
                </>
                <div className="flex flex-row justify-between gap-4">
                  <div>
                    <h1 className="mt-5 font-semibold text-4xl">
                      {withLoader(
                        referralCreditsLoading,
                        <Skeleton width={100} duration={2} />,
                        <>${Number(referralCredits.total.toFixed(2))}</>
                      )}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ReferralStatsItem />
          {/* RAISE WITHDRAWAL REQUEST DISABLED, MIGHT GET ADDED LATER
          {withLoading(
            raiseRequestLimitLoading,
            <Skeleton height={88} width="100%" duration={2} />,
            <>
              {referralCredits.withdrawable >= raiseRequestLimit && (
                <div
                  className="flex flex-row gap-4 items-center justify-between
                  bg-base-fg dark:bg-dark-base-fg p-5 rounded-lg"
                >
                  <div className="flex flex-row gap-6 items-center">
                    <h1 className="text-5xl">🎉</h1>
                    <div className="flex flex-col">
                      <h2 className="font-medium">Congratulations!</h2>
                      <h3 className="text-sm font-light">
                        Now you can raise your $
                        {Number(referralCredits.withdrawable.toFixed(2))}{' '}
                        request.
                      </h3>
                    </div>
                  </div>
                  <Button
                    buttonType="primary"
                    label="Raise Request"
                    size="medium"
                    onClick={handleRaiseRequest}
                  />
                </div>
              )}
            </>,
            '100%',
            88
          )} */}
        </>
      )}
    </div>
  );
};

export default UserSettingReferrals;
