import { requestPipeline } from '../root-utils';
import { IResponseError } from '../combined-reducer.interface';
import {
  IBucket,
  IBucketCountResponse,
  IBucketDomain,
  ICdnRecordsResponse,
  IIpnsRecord,
  IUpload,
} from './storage.interfaces';

export const getSelectedBucket = async (
  bucketId: string
): Promise<IBucket | IResponseError> => {
  return requestPipeline({
    url: `bucket/${bucketId}`,
    method: 'GET',
    isPublic: true,
  });
};

export const updateBucketState = async (
  bucketId: string,
  state: string = 'ARCHIVED'
): Promise<{ bucket: IBucket } | IResponseError> => {
  return requestPipeline({
    url: `bucket/${bucketId}/state`,
    method: 'PATCH',
    body: JSON.stringify({
      state,
    }),
  });
};

export const deleteSelectedBucket = async (
  bucketId: string
): Promise<IBucket | IResponseError> => {
  return requestPipeline({
    url: `bucket/${bucketId}`,
    method: 'DELETE',
  });
};

export const getBucketUploads = async (
  bucketId: string,
  skip: number = 0,
  limit: number = 6,
  status: string = ''
): Promise<IBucket[] | IResponseError> => {
  return requestPipeline({
    url: `bucket/${bucketId}/uploads?skip=${skip}&limit=${limit}&status=${status}`,
    method: 'GET',
    isPublic: true,
  });
};

export const getBucketUploadsCount = async (
  bucketId: string,
  status: string = ''
): Promise<IBucketCountResponse[] | IResponseError> => {
  return requestPipeline({
    url: `bucket/${bucketId}/uploads/count?status=${status}`,
    method: 'GET',
    isPublic: true,
  });
};

export const getBucketUploadsCids = async (
  bucketId: string
): Promise<{ uploadCidMap: any } | IResponseError> => {
  return requestPipeline({
    url: `bucket/${bucketId}/cids`,
    method: 'GET',
    isPublic: true,
  });
};

export const getBucketUploadsLinks = async (
  bucketId: string
): Promise<{ uploadCidMap: any } | IResponseError> => {
  return requestPipeline({
    url: `bucket/${bucketId}/links`,
    method: 'GET',
    isPublic: true,
  });
};

export const getBucketIpnsRecords = async (
  bucketId: string
): Promise<IIpnsRecord[] | IResponseError> => {
  return requestPipeline({
    url: `bucket/${bucketId}/ipns-records`,
    method: 'GET',
    isPublic: true,
  });
};

export const publishBucketIpnsRecord = async (
  bucketId: string,
  uploadConfig: any
): Promise<IIpnsRecord | IResponseError> => {
  return requestPipeline({
    url: `bucket/${bucketId}/ipns-records`,
    method: 'POST',
    body: JSON.stringify(uploadConfig),
    isPublic: true,
  });
};

export const updateBucketIpnsRecord = async (
  bucketId: string,
  ipnsRecordId: string,
  uploadConfig: any
): Promise<IIpnsRecord | IResponseError> => {
  return requestPipeline({
    url: `bucket/${bucketId}/ipns-records/${ipnsRecordId}`,
    method: 'PATCH',
    body: JSON.stringify(uploadConfig),
  });
};

export const deleteBucketIpnsRecord = async (
  bucketId: string,
  ipnsRecordId: string
): Promise<IIpnsRecord | IResponseError> => {
  return requestPipeline({
    url: `bucket/${bucketId}/ipns-records/${ipnsRecordId}`,
    method: 'DELETE',
  });
};

export const initiateUpload = async (
  orgId: string,
  bucketName: string,
  protocol: string
): Promise<any> => {
  return requestPipeline({
    // eslint-disable-next-line max-len
    url: `v1/upload/initiate?organization=${orgId}&bucket=${bucketName}&protocol=${protocol}&create_single_use_token=true`,
    method: 'POST',
    isUploadApi: true,
  });
};

export const initiateCidUpload = async (
  orgId: string,
  bucketName: string,
  cid: string,
  inBackground: boolean = true
): Promise<any> => {
  return requestPipeline({
    // eslint-disable-next-line max-len
    url: `v2/ipfs/pin/${cid}?organization=${orgId}&bucket=${bucketName}&in_background=${inBackground}`,
    method: 'POST',
    isUploadApi: true,
  });
};

export const pinBucketUpload = async (
  uploadId: string
): Promise<{ upload: IUpload } | IResponseError> => {
  return requestPipeline({
    url: `upload/${uploadId}/pin`,
    method: 'PATCH',
    // isUploadApi: true,
  });
};

export const unpinBucketUpload = async (
  uploadId: string
): Promise<{ upload: IUpload } | IResponseError> => {
  return requestPipeline({
    url: `upload/${uploadId}/unpin`,
    method: 'PATCH',
    // isUploadApi: true,
  });
};

export const deleteBucketUpload = async (
  uploadId: string
): Promise<{ upload: IUpload } | IResponseError> => {
  return requestPipeline({
    url: `upload/${uploadId}`,
    method: 'DELETE',
    // isUploadApi: true,
  });
};

export const getBucketDomains = async (
  bucketId: string
): Promise<{ domains: IBucketDomain[] } | IResponseError> => {
  return requestPipeline({
    url: `bucket/${bucketId}/domains`,
    method: 'GET',
    isPublic: true,
  });
};

export const getBucketDomainsEnabled = async (
  bucketId: string
): Promise<{ enabled: boolean } | IResponseError> => {
  return requestPipeline({
    url: `bucket/${bucketId}/domains/enabled`,
    method: 'GET',
  });
};

export const addBucketDomains = async (
  bucketId: string,
  domainConfig: any
): Promise<{ domain: IBucketDomain } | IResponseError> => {
  return requestPipeline({
    url: `bucket/${bucketId}/domains`,
    method: 'POST',
    body: JSON.stringify(domainConfig),
  });
};

export const updateBucketDomains = async (
  bucketId: string,
  domainId: string,
  domainConfig: any
): Promise<{ domains: IBucketDomain[] } | IResponseError> => {
  return requestPipeline({
    url: `bucket/${bucketId}/domains/${domainId}`,
    method: 'PATCH',
    body: JSON.stringify(domainConfig),
  });
};

export const verifyBucketDomains = async (
  bucketId: string,
  domainId: string
): Promise<{ domain: IBucketDomain } & IResponseError> => {
  return requestPipeline({
    url: `bucket/${bucketId}/domains/${domainId}/verify`,
    method: 'PATCH',
  });
};

export const deleteBucketDomains = async (
  bucketId: string,
  domainId: string
): Promise<IResponseError> => {
  return requestPipeline({
    url: `bucket/${bucketId}/domains/${domainId}`,
    method: 'DELETE',
  });
};

export const fetchBucketCdnRecords = async (
  bucketId: string
): Promise<ICdnRecordsResponse> => {
  return requestPipeline({
    url: `bucket/${bucketId}/domains/cdn-records`,
    method: 'GET',
    isPublic: true,
  });
};
