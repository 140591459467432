import React from 'react';
import { ConfirmationModal, Modal } from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import Styles from '../../../styles/compute/modal.module.scss';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import { disconnectPhantomWalletThunk } from '../../../redux/solana/solana.thunks';
import ModalWrapper from './ModalWrapper';

interface IProps {
  isOpen: boolean;
  id: string;
  walletAddress: string;
}

const DisconnectPhantomWallet = ({ isOpen, walletAddress, id }: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();
  const disconnectWalletLoading: boolean = useSelector(
    (state: RootState) => state.web3.disconnectWalletLoading
  );

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={() => dispatchRtk(toggleModalShowRtk({ modalShow: false }))}
      classname={`${Styles.modal__outer__con} w-430 modal fixed inset-0 overflow-y-auto`}
    >
      <div className={Styles.modal__inner__content}>
        <Modal
          footer
          header
          subtitle=""
          modalContent={
            <span className="font-normal text-sm leading-5">
              Removal of wallet will stop the subscription from upcoming month
              and stop all the platform services.
            </span>
          }
          headerContent={<>Disconnect Wallet</>}
          primaryButtonContent="Disconnect"
          secondaryButtonContent="Cancel"
          primaryButton
          primaryButtonLoading={disconnectWalletLoading}
          secondaryButtonDisabled={false}
          secondaryButton
          onPrimaryButtonClick={() => {
            dispatchRtk(
              disconnectPhantomWalletThunk({
                id,
                address: walletAddress,
              })
            );
          }}
          confirmationStatus={ConfirmationModal.INFO}
        />
      </div>
    </ModalWrapper>
  );
};

export default DisconnectPhantomWallet;
