import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Slider from 'react-slick';
import { useSelector } from 'react-redux';
import LoginStyle from '../../../styles/compute/log-in.module.scss';
import AvailLogo from '../../../assets/compute/icons/avail.svg';
import AvailDarkLogo from '../../../assets/compute/icons/avail-dark.svg';
import DesoLogo from '../../../assets/compute/icons/deso-node.svg';
import DesoDarkLogo from '../../../assets/compute/icons/deso-dark.svg';
import KoiiLogo from '../../../assets/compute/icons/koii.svg';
import KoiiDarkLogo from '../../../assets/compute/icons/koii-dark.svg';
import ShardeumLogo from '../../../assets/compute/icons/shardeum.svg';
import ShardeumDarkLogo from '../../../assets/compute/icons/shardeum-dark.svg';
import TykheBlockLogo from '../../../assets/compute/icons/tykhe-block.svg';
import TykheDarkBlockLogo from '../../../assets/compute/icons/tykhe-block-dark.svg';
import Quotes from '../../../assets/compute/backgrounds/left-quote.svg';
import { RootState } from '../../../redux/compute/store';
import { isDarkTheme } from '../../../redux/compute/theme/theme.utils';

const RightTestimonialsComponent = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    customPaging: (i: number) => <div>{i + 1}</div>,
    dotsClass: 'slick-dots-pricing slick-thumb',
    centerMode: false,
  };
  const theme = useSelector((state: RootState) => state.theme.theme);
  const testimonials = [
    {
      id: 1,
      icon:
        theme === null || isDarkTheme(theme) ? ShardeumLogo : ShardeumDarkLogo,
      content: (
        <>
          In tandem with Spheron, we are excited to offer a{' '}
          <span className="font-bold">
            reliable, cost-effective decentralized cloud infrastructure
          </span>
          , enhancing our ecosystem and empowering validator node runners with a
          user-centric experience & 1-click deployment.
        </>
      ),
      personName: 'Nischal Shetty',
      personPosition: 'Co-Founder',
    },
    {
      id: 2,
      icon: theme === null || isDarkTheme(theme) ? AvailLogo : AvailDarkLogo,
      content: (
        <>
          Avail is delighted with{' '}
          <span className="font-bold">
            Spheron&apos;s user-friendly one-click full-node deployment setup
          </span>
          , personally revolutionizing our ecosystem&apos;s operations for a
          stronger, more scalable network. This collaboration feels like a big
          win for our community.
        </>
      ),
      personName: 'Prabal Banerjee',
      personPosition: 'Co-Founder',
    },
    {
      id: 3,
      icon: theme === null || isDarkTheme(theme) ? KoiiLogo : KoiiDarkLogo,
      content: (
        <>
          Spheron&apos;s SDK seamlessly integrates with Koii task nodes,{' '}
          <span className="font-bold">
            simplifying key generation and boosting decentralization
          </span>
          . Node runners now own their data, enhancing network integrity and
          performance.
        </>
      ),
      personName: 'Al Morris',
      personPosition: 'Co-Founder',
    },
    {
      id: 4,
      icon: theme === null || isDarkTheme(theme) ? DesoLogo : DesoDarkLogo,
      content: (
        <>
          Deso is thrilled with{' '}
          <span className="font-bold">
            Spheron&apos;s one-click node deployment
          </span>
          , making running Deso nodes seamless. We&apos;re energized to build a
          decentralized social network powered by decentralized compute, thanks
          to this collaboration.
        </>
      ),
      personName: 'Ed Moss',
      personPosition: 'Head Of Growth',
    },
    {
      id: 5,
      icon:
        theme === null || isDarkTheme(theme)
          ? TykheBlockLogo
          : TykheDarkBlockLogo,
      content: (
        <>
          Spheron Compute removes the entry barrier for operating nodes on
          multiple chains,{' '}
          <span className="font-bold">
            without coding, embodying Web3 ethos
          </span>{' '}
          through decentralized computing for all nodes.
        </>
      ),
      personName: 'Sanchit Arora',
      personPosition: 'Head Of Research',
    },
  ];
  return (
    <div className="relative">
      <div
        className={`${LoginStyle.gradient} ${
          window.innerWidth < 1026 ? 'none mt-20' : 'absolute'
        }`}
      />
      <div className="flex justify-center h-full mt-48">
        <div className="w-full">
          {/* eslint-disable react/jsx-props-no-spreading */}
          <Slider {...settings} className="ml-8 mr-9">
            {testimonials.map((testimonial) => (
              <div
                className="px-9 pb-9 pt-3.5 border border-primary-sb100 
                ll:h-[475px] h-[545px] flex flex-col justify-around 
                sm:rounded-3xl rounded-2xl z-10 
              bg-base-bg dark:bg-dark-base-bg"
                key={testimonial.id}
              >
                <div className="h-[100px] flex items-center">
                  <img src={testimonial.icon} alt="testimonial icon" />
                </div>
                <div
                  className="text-base-para-text-color 
                dark:text-dark-base-para-text-color text-2xl italic leading-[39px]"
                >
                  {testimonial.content}
                </div>
                <div className="absolute mt-7 bottom-9">
                  <p
                    className="text-3xl leading-[44px] font-bold text-base-heading-text-color 
                  dark:text-dark-base-heading-text-color"
                  >
                    {testimonial.personName}
                  </p>
                  <p
                    className="sm:text-xl text-sm font-medium text-base-para-text-color 
                  dark:text-dark-base-para-text-color"
                  >
                    {testimonial.personPosition}
                  </p>
                </div>
              </div>
            ))}
          </Slider>

          <div className="absolute right-5 top-[38.5rem]">
            <img
              src={Quotes}
              className="opacity-20 w-[170px] h-[230px]"
              alt="quotes"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightTestimonialsComponent;
