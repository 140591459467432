import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { ReactComponent as InfoIcon } from '@spheron/ui-library/dist/assets/info-circle.svg';
import { Tooltip } from '@spheron/ui-library';
import { withLoader } from '../../../redux/compute/root-utils';

interface IProps {
  title: string;
  price: string;
  loading: boolean;
  className: string;
  tooltiptext: string;
  badge: JSX.Element | null;
}

const ComputePriceCard = ({
  title,
  price,
  loading,
  badge,
  className,
  tooltiptext = '',
}: IProps) => {
  return (
    <div
      className={`bg-base-fg dark:bg-dark-base-fg rounded-lg p-3 w-full
      flex flex-col justify-between gap-2 relative ${className}`}
    >
      <div className="flex items-start justify-between gap-x-2 relative">
        <span
          className="text-3 leading-3 font-600 uppercase
        text-base-heading-text-color dark:text-dark-base-heading-text-color"
        >
          {title}
        </span>
        {badge && badge}
      </div>
      {withLoader(
        loading || price.indexOf('NaN') > -1,
        <Skeleton height={28} width="50%" duration={2} />,
        <span
          className="text-[28px] leading-8 font-500
          text-base-heading-text-color dark:text-dark-base-heading-text-color"
        >
          {price}
        </span>
      )}
      {tooltiptext.length > 0 && (
        <div className="absolute top-2 right-2 z-10">
          <Tooltip position="right" text={tooltiptext} type="arrow" wrapText>
            <InfoIcon
              className="cursor-pointer w-4 h-4
                  text-base-heading-text-color dark:text-dark-base-heading-text-color"
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default ComputePriceCard;
