/* eslint-disable import/no-cycle */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IBalance,
  INetworkType,
  IOrganisationWallet,
  IPaymentHistoryDto,
  ITokenType,
  IUpdateWalletBalance,
  IWalletConfiguredPayloadDto,
  IWeb3State,
} from './web3.interfaces';
import {
  addDomainToRegistryThunk,
  addUpdateToRegistryThunk,
  configureWalletThunk,
  connectWalletThunk,
  createDomainRegistryThunk,
  depositTokenThunk,
  disconnectWalletThunk,
  fetchBalanceThunk,
  fetchPaymentHistoryThunk,
  fetchWalletAllowanceThunk,
  fetchWalletAvailableBalanceThunk,
  fetchWalletBalanceThunk,
  removeUpdaterFromRegistryThunk,
  saveWalletThunk,
  setTokenAllowanceThunk,
  switchNetworkThunk,
  updateEnsContentHashThunk,
  withdrawTokenThunk,
} from './web3.thunks';
import {
  configurePhantomWalletThunk,
  disconnectPhantomWalletThunk,
  fetchSolBalanceThunk,
} from '../solana/solana.thunks';
import { mapNetworkToWallet, mapTokenToWallet } from './web3.utils';

export const initialState: IWeb3State = {
  currentAccount: '',
  selectedNetwork: null,
  selectedToken: null,
  selectedWallet: '',
  selectedTokenBalance: null,
  selectedTokenAllowance: 0,
  signature: '',
  walletLoading: true,
  escrowBalanceLoading: false,
  tokenBalanceLoaded: false,
  tokenAllowanceLoaded: false,
  tokenAllowanceLoading: false,
  switchNetworkLoading: false,
  approveLoading: false,
  connectWalletLoading: false,
  disconnectWalletLoading: false,
  ensContentHashLoading: false,
  toggleTokenLoading: false,
  balance: {
    amount: '',
    token: '',
    address: '',
  },
  transactionOngoing: false,
  toggleWalletApprovalLoaded: false,
  paymentHistory: {
    deposits: [],
    withdrawals: [],
  },
  paymentHistoryLoading: true,
  createRegistryLoading: false,
  addDomainRegistryLoading: false,
  addRegistryUpdaterLoading: false,
  removeRegistryUpdaterLoading: false,
  wallets: [],
  removeWalletsLoading: [],
  walletBalanceLoading: [],
  signatureLoading: false,
  selectedTokenAvailableBalance: null,
  selectedTokenAvailableBalanceLoading: false,
  hasDeposited: false,
  maxDepositedWallet: null,
};

const web3Slice = createSlice({
  name: 'web3',
  initialState,
  reducers: {
    toggleWalletApprovalLoading: (state, action: PayloadAction<boolean>) => {
      state.toggleWalletApprovalLoaded = action.payload;
    },
    updateCryptoWalletPrimaryPayment: (
      state,
      action: PayloadAction<{ id: string }>
    ) => {
      state.wallets = state.wallets.find(
        (wallet) => wallet._id === action.payload.id
      )
        ? [
            {
              ...state.wallets.find(
                (wallet) => wallet._id === action.payload.id
              )!!,
              primary: true,
            },
            ...state.wallets
              .filter((wallet) => wallet._id !== action.payload.id)
              .map((wallet) => {
                return {
                  ...wallet,
                  primary: false,
                };
              }),
          ]
        : state.wallets.map((wallet) => {
            return {
              ...wallet,
              primary: false,
            };
          });
    },

    addWallet: (state, action: PayloadAction<IOrganisationWallet[]>) => {
      state.wallets = [
        ...action.payload.map((wallet: IOrganisationWallet) => {
          return {
            ...wallet,
            details: {
              ...wallet.details,
              networkDetails: mapNetworkToWallet(
                parseInt(wallet.details.networkDetails.chainId, 10) || -1
              ) as INetworkType,
              tokenDetails: mapTokenToWallet(
                wallet?.details.networkDetails.name || '',
                wallet.details.tokenDetails.name || ''
              ) as unknown as ITokenType,
            },
          };
        }),
      ];
    },

    toggleWalletsLoading: (state, action: PayloadAction<boolean>) => {
      state.walletLoading = action.payload;
    },

    togglePaymentHistoryLoading: (state, action: PayloadAction<boolean>) => {
      state.paymentHistoryLoading = action.payload;
    },
    toggleSignatureLoading: (state, action: PayloadAction<boolean>) => {
      state.signatureLoading = action.payload;
    },
    removeWallet: (state, action: PayloadAction<string>) => {
      state.wallets = state.wallets.filter(
        (wallet) => wallet._id !== action.payload
      );
    },

    updateWalletBalance: (
      state,
      action: PayloadAction<IUpdateWalletBalance>
    ) => {
      state.wallets = [
        {
          ...state.wallets.find((wallet) => wallet._id === action.payload.id)!!,
          balance: action.payload.balance,
        },
        ...state.wallets.filter((wallet) => wallet._id !== action.payload.id),
      ];
    },

    toggleConnectWalletLoading: (state, action: PayloadAction<boolean>) => {
      state.connectWalletLoading = action.payload;
    },

    toggleDisconnectWalletLoading: (state, action: PayloadAction<boolean>) => {
      state.disconnectWalletLoading = action.payload;
    },

    resetBalance: (state) => {
      state.balance = initialState.balance;
    },

    resetWeb3State: (state) => {
      Object.assign(state, initialState);
    },

    toggleHasDeposited: (state, action: PayloadAction<boolean>) => {
      state.hasDeposited = action.payload;
    },
    setMaxDepositedWallet: (
      state,
      action: PayloadAction<{
        balance: string;
        network: INetworkType;
        token: ITokenType;
      }>
    ) => {
      const depositedWallet = state.maxDepositedWallet;
      if (Number(action.payload.balance) >= 15) {
        if (depositedWallet) {
          state.maxDepositedWallet =
            Number(action.payload.balance) > Number(depositedWallet.balance)
              ? action.payload
              : depositedWallet;
        } else state.maxDepositedWallet = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(configureWalletThunk.pending, (state) => {
      state.connectWalletLoading = true;
    });

    builder.addCase(
      configureWalletThunk.fulfilled,
      (state, action: PayloadAction<IWalletConfiguredPayloadDto>) => {
        state.currentAccount = action.payload.walletAddress;
        state.selectedTokenBalance = action.payload.tokenBalance;
        state.signature = action.payload.signature;
      }
    );

    builder.addCase(configureWalletThunk.rejected, (state) => {
      state.connectWalletLoading = false;
    });

    builder.addCase(fetchWalletBalanceThunk.pending, (state, action) => {
      state.tokenBalanceLoaded = false;
      state.escrowBalanceLoading = true;
      state.walletBalanceLoading = [
        ...state.walletBalanceLoading,
        action.meta.arg?.walletId || '',
      ];
    });

    builder.addCase(fetchWalletBalanceThunk.fulfilled, (state, action) => {
      state.tokenAllowanceLoaded = true;
      state.balance = action.payload.balance as IBalance;
      state.escrowBalanceLoading = false;
      state.wallets = [
        {
          ...state.wallets.find(
            (wallet) => wallet._id === action.payload.walletId
          )!,
          balance: action.payload.balance as IBalance,
        },
        ...state.wallets.filter(
          (wallet) => wallet._id !== action.payload.walletId
        ),
      ];
      state.walletBalanceLoading = state.walletBalanceLoading.filter(
        (wallet) => wallet !== action.payload.walletId
      );
    });

    builder.addCase(fetchWalletBalanceThunk.rejected, (state, action) => {
      state.selectedTokenBalance = null;
      state.tokenBalanceLoaded = true;
      state.escrowBalanceLoading = false;
      state.walletBalanceLoading = state.walletBalanceLoading.filter(
        (wallet) => wallet !== action.meta.arg.walletId
      );
    });

    builder.addCase(fetchWalletAllowanceThunk.pending, (state) => {
      state.tokenAllowanceLoading = true;
      state.tokenAllowanceLoaded = false;
    });

    builder.addCase(
      fetchWalletAllowanceThunk.fulfilled,
      (state, action: PayloadAction<number>) => {
        state.selectedTokenAllowance = action.payload;
        state.tokenAllowanceLoaded = true;
        state.tokenAllowanceLoading = false;
      }
    );

    builder.addCase(fetchWalletAllowanceThunk.rejected, (state) => {
      state.selectedTokenAllowance = 0;
      state.tokenAllowanceLoaded = true;
      state.tokenAllowanceLoading = false;
    });

    builder.addCase(switchNetworkThunk.pending, (state) => {
      state.switchNetworkLoading = true;
    });

    builder.addCase(switchNetworkThunk.fulfilled, (state) => {
      state.switchNetworkLoading = false;
    });

    builder.addCase(switchNetworkThunk.rejected, (state) => {
      state.switchNetworkLoading = false;
    });

    builder.addCase(setTokenAllowanceThunk.pending, (state) => {
      state.toggleWalletApprovalLoaded = true;
      state.approveLoading = true;
    });

    builder.addCase(setTokenAllowanceThunk.rejected, (state) => {
      state.toggleWalletApprovalLoaded = false;
      state.approveLoading = false;
      state.selectedTokenAllowance = 0;
    });

    builder.addCase(
      setTokenAllowanceThunk.fulfilled,
      (state, action: PayloadAction<string>) => {
        state.toggleWalletApprovalLoaded = false;
        state.approveLoading = false;
        state.selectedTokenAllowance = Number(action.payload);
      }
    );

    builder.addCase(depositTokenThunk.pending, (state) => {
      state.transactionOngoing = true;
    });

    builder.addCase(depositTokenThunk.fulfilled, (state) => {
      state.transactionOngoing = false;
      state.hasDeposited = true;
    });

    builder.addCase(depositTokenThunk.rejected, (state) => {
      state.transactionOngoing = false;
    });

    builder.addCase(withdrawTokenThunk.pending, (state) => {
      state.transactionOngoing = true;
    });

    builder.addCase(withdrawTokenThunk.fulfilled, (state) => {
      state.transactionOngoing = false;
    });

    builder.addCase(withdrawTokenThunk.rejected, (state) => {
      state.transactionOngoing = false;
    });

    builder.addCase(fetchPaymentHistoryThunk.pending, (state) => {
      state.paymentHistoryLoading = true;
    });

    builder.addCase(
      fetchPaymentHistoryThunk.fulfilled,
      (state, action: PayloadAction<IPaymentHistoryDto>) => {
        state.paymentHistoryLoading = false;
        state.paymentHistory = {
          deposits: action.payload.deposit,
          withdrawals: action.payload.withdraw,
        };
      }
    );

    builder.addCase(fetchPaymentHistoryThunk.rejected, (state) => {
      state.paymentHistoryLoading = false;
      state.paymentHistory = {
        deposits: [],
        withdrawals: [],
      };
    });

    builder.addCase(fetchBalanceThunk.pending, (state, action) => {
      state.walletBalanceLoading = [
        ...state.walletBalanceLoading,
        action.meta.arg.walletId,
      ];
      // eslint-disable-next-line no-unused-expressions
      state.balance = {
        ...state.balance,
        address: action.meta.arg.balance.address || '',
      } as IBalance;
      state.escrowBalanceLoading = true;
    });

    builder.addCase(fetchBalanceThunk.fulfilled, (state, action) => {
      state.escrowBalanceLoading = false;
      state.wallets = [
        {
          ...state.wallets.find((wallet) => wallet._id === action.payload.id)!,
          balance: action.payload.balance,
        },
        ...state.wallets.filter((wallet) => wallet._id !== action.payload.id),
      ];
      state.walletBalanceLoading = state.walletBalanceLoading.filter(
        (wallet) => wallet !== action.payload.id
      );
      state.balance = {
        ...action.payload.balance,
        address: action?.meta?.arg?.balance?.address,
      };
    });

    builder.addCase(fetchBalanceThunk.rejected, (state, action) => {
      state.escrowBalanceLoading = false;
      state.walletBalanceLoading = state.walletBalanceLoading.filter(
        (wallet) => wallet !== action.meta.arg.walletId
      );
      state.balance = {
        token: '',
        address: '',
        amount: '',
      };
    });

    builder.addCase(connectWalletThunk.pending, (state) => {
      state.connectWalletLoading = true;
    });

    builder.addCase(
      connectWalletThunk.fulfilled,
      (state, action: PayloadAction<string>) => {
        state.connectWalletLoading = false;
        state.currentAccount = action.payload;
      }
    );

    builder.addCase(connectWalletThunk.rejected, (state) => {
      state.connectWalletLoading = false;
    });

    builder.addCase(disconnectWalletThunk.pending, (state) => {
      state.disconnectWalletLoading = true;
    });

    builder.addCase(
      disconnectWalletThunk.fulfilled,
      (state, action: PayloadAction<string>) => {
        state.walletLoading = false;
        state.disconnectWalletLoading = false;
        state.removeWalletsLoading = state.removeWalletsLoading.filter(
          (id) => id !== action.payload
        );
        state.wallets = state.wallets.filter(
          (wallet) => wallet._id !== action.payload
        );
      }
    );

    builder.addCase(disconnectWalletThunk.rejected, (state, action) => {
      state.disconnectWalletLoading = false;
      state.removeWalletsLoading = state.removeWalletsLoading.filter(
        (id) => id !== action.meta.arg.id
      );
    });

    builder.addCase(saveWalletThunk.pending, (state) => {
      state.connectWalletLoading = true;
    });

    builder.addCase(saveWalletThunk.fulfilled, (state) => {
      state.connectWalletLoading = false;
    });

    builder.addCase(saveWalletThunk.rejected, (state) => {
      state.connectWalletLoading = false;
    });

    builder.addCase(createDomainRegistryThunk.pending, (state) => {
      state.createRegistryLoading = true;
    });

    builder.addCase(createDomainRegistryThunk.fulfilled, (state) => {
      state.createRegistryLoading = false;
    });

    builder.addCase(createDomainRegistryThunk.rejected, (state) => {
      state.createRegistryLoading = false;
    });

    builder.addCase(addDomainToRegistryThunk.pending, (state) => {
      state.createRegistryLoading = true;
    });

    builder.addCase(addDomainToRegistryThunk.fulfilled, (state) => {
      state.createRegistryLoading = false;
    });

    builder.addCase(addDomainToRegistryThunk.rejected, (state) => {
      state.createRegistryLoading = false;
    });

    builder.addCase(addUpdateToRegistryThunk.pending, (state) => {
      state.addRegistryUpdaterLoading = true;
    });

    builder.addCase(addUpdateToRegistryThunk.fulfilled, (state) => {
      state.addRegistryUpdaterLoading = false;
    });

    builder.addCase(addUpdateToRegistryThunk.rejected, (state) => {
      state.addRegistryUpdaterLoading = false;
    });

    builder.addCase(removeUpdaterFromRegistryThunk.pending, (state) => {
      state.removeRegistryUpdaterLoading = true;
    });

    builder.addCase(removeUpdaterFromRegistryThunk.fulfilled, (state) => {
      state.removeRegistryUpdaterLoading = false;
    });

    builder.addCase(removeUpdaterFromRegistryThunk.rejected, (state) => {
      state.removeRegistryUpdaterLoading = false;
    });

    builder.addCase(updateEnsContentHashThunk.pending, (state) => {
      state.ensContentHashLoading = true;
    });

    builder.addCase(updateEnsContentHashThunk.fulfilled, (state) => {
      state.ensContentHashLoading = false;
    });

    builder.addCase(updateEnsContentHashThunk.rejected, (state) => {
      state.ensContentHashLoading = false;
    });

    // CONFIGURE WEB3 WALLET WITH SOLANA
    builder.addCase(configurePhantomWalletThunk.pending, (state, action) => {
      state.connectWalletLoading = true;
      state.selectedNetwork = action.meta.arg.selectedNetwork;
      state.selectedToken = action.meta.arg.selectedToken;
      state.selectedWallet = action.meta.arg.selectedWallet;
    });

    builder.addCase(
      configurePhantomWalletThunk.fulfilled,
      (state, action: PayloadAction<IWalletConfiguredPayloadDto>) => {
        state.currentAccount = action.payload.walletAddress;
        state.selectedTokenBalance = action.payload.tokenBalance;
        state.signature = action.payload.signature;
      }
    );

    builder.addCase(configurePhantomWalletThunk.rejected, (state) => {
      state.connectWalletLoading = false;
    });

    builder.addCase(disconnectPhantomWalletThunk.pending, (state) => {
      state.disconnectWalletLoading = true;
    });

    builder.addCase(
      disconnectPhantomWalletThunk.fulfilled,
      (state, action: PayloadAction<string>) => {
        state.disconnectWalletLoading = false;
        state.wallets = state.wallets.filter(
          (wallet) => wallet._id !== action.payload
        );
      }
    );

    builder.addCase(disconnectPhantomWalletThunk.rejected, (state) => {
      state.disconnectWalletLoading = false;
    });

    builder.addCase(fetchSolBalanceThunk.pending, (state) => {
      state.escrowBalanceLoading = true;
    });

    builder.addCase(
      fetchSolBalanceThunk.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.escrowBalanceLoading = false;
        state.balance = action.payload!;
      }
    );

    builder.addCase(fetchSolBalanceThunk.rejected, (state) => {
      state.escrowBalanceLoading = false;
    });

    builder.addCase('organisation/resetWallet', (state) => {
      Object.assign(state, initialState);
    });

    builder.addCase(fetchWalletAvailableBalanceThunk.pending, (state) => {
      state.selectedTokenAvailableBalanceLoading = true;
      state.selectedTokenAvailableBalance = null;
    });

    builder.addCase(
      fetchWalletAvailableBalanceThunk.fulfilled,
      (state, action: PayloadAction<number>) => {
        state.selectedTokenAvailableBalanceLoading = false;
        state.selectedTokenAvailableBalance = action.payload;
      }
    );

    builder.addCase(fetchWalletAvailableBalanceThunk.rejected, (state) => {
      state.selectedTokenAvailableBalanceLoading = false;
      state.selectedTokenAvailableBalance = null;
    });
  },
});

export const { reducer: web3RTKReducer } = web3Slice;

export const {
  toggleWalletApprovalLoading: toggleWalletApprovalLoadingRtk,
  updateCryptoWalletPrimaryPayment: updateCryptoWalletPrimaryPaymentRtk,
  addWallet: addWalletRtk,
  toggleWalletsLoading: toggleWalletsLoadingRTK,
  togglePaymentHistoryLoading: togglePaymentHistoryLoadingRtk,
  resetWeb3State: resetWeb3StateRtk,
  removeWallet: removeWalletRtk,
  updateWalletBalance: updateWalletBalanceRtk,
  toggleConnectWalletLoading: toggleConnectWalletLoadingRtk,
  toggleDisconnectWalletLoading: toggleDisconnectWalletLoadingRtk,
  resetBalance: resetBalanceRtk,
  toggleSignatureLoading: toggleSignatureLoadingRtk,
  toggleHasDeposited,
  setMaxDepositedWallet,
} = web3Slice.actions;
