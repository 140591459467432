import React from 'react';
import Skeleton from 'react-loading-skeleton';

interface IProps {
  showBadge: boolean;
}

const TemplateCardLoading = ({ showBadge }: IProps) => {
  return (
    <div
      className="border relative rounded-[8px] px-4 py-4
         w-full flex items-center dark:bg-dark-base-bg 
        dark:border-dark-base-border min-h-12"
    >
      <div className="flex flex-row gap-3 items-center">
        <Skeleton
          width={32}
          height={32}
          borderRadius={24}
          duration={2}
          containerClassName="flex"
        />
        <Skeleton
          height={23}
          width={150}
          duration={2}
          containerClassName="flex"
        />
        {showBadge && (
          <span className="px-1 py-0.5 rounded absolute right-1.5 bottom-1">
            <Skeleton
              height={18}
              width={65}
              duration={2}
              containerClassName="flex"
            />
          </span>
        )}
      </div>
    </div>
  );
};

export default TemplateCardLoading;
