import React, { useEffect, useState } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import { SectionHeading } from '@spheron/ui-library';
import GlobalStyles from '../../../../styles/compute/global.module.scss';
import SidebarDropdownRow, {
  OptionType,
} from '../../../../components/Compute/Sidebar/sidebar-dropdown-row';

const UserSettings: React.FC = () => {
  const location = useLocation();
  const menuList = [
    {
      id: 0,
      label: 'General',
      value: 'General',
      url: '/user/settings/general',
      optionType: 'primary' as OptionType,
      slug: 'general',
    },
    {
      id: 1,
      label: 'Login Connections',
      value: 'Login Connections',
      url: '/user/settings/login-connections',
      optionType: 'primary' as OptionType,
      slug: 'login-connections',
    },
    {
      id: 2,
      label: 'Tokens',
      value: 'Tokens',
      url: '/user/settings/api-tokens',
      optionType: 'primary' as OptionType,
      slug: 'tokens',
    },
    {
      id: 3,
      label: 'Referrals',
      value: 'Referrals',
      url: '/user/settings/referrals',
      optionType: 'primary' as OptionType,
      slug: 'referrals',
    },
  ];

  const [defaultSidebar, setDefaultSidebar] = useState<string>('General');

  useEffect(() => {
    menuList.forEach((item) => {
      if (item.url.split('/')[3] === location.pathname.split('/')[3]) {
        setDefaultSidebar(item.value);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={GlobalStyles.screen__width}>
      <div className="mt-6">
        <SectionHeading
          heading="User Settings Center"
          subHeading="Customise Your Profile, Manage Logins, Tokens, and Referrals with Ease"
          showRefresh={false}
          showSwitch={false}
          handleIconClick={() => {}}
          handleRefreshClick={() => {}}
          handleSwitchClick={() => {}}
          switchDisable={false}
          handleClick={() => {}}
          loading={false}
          time={''}
          refreshType={'default'}
          showText={false}
          toggleId={''}
          isChecked={false}
          handleChange={() => {}}
        />
      </div>
      <div className={`${GlobalStyles.screen__inner__con} mt-8`}>
        <SidebarDropdownRow
          heading=""
          options={menuList}
          defaultSelected={defaultSidebar}
        />
        <div
          className={`${GlobalStyles.right__con} ${GlobalStyles.ignore__margin__top}`}
        >
          <>
            <Outlet />
          </>
        </div>
      </div>
    </div>
  );
};

export default UserSettings;
