import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { InstanceCardState } from '@spheron/ui-library';
import {
  IActivityDetails,
  IAutoscalingRequestPayload,
  IFetchInstanceServiceResponse,
  IInstanceDomains,
  IInstanceMetricsChart,
  // IInstanceSocketUpdate,
  IInstanceState,
  IService,
  ISetDomainLoading,
  ISetInstanceLogLoading,
  InstanceLogs,
  InstanceStatus,
} from './instance.interfaces';
// eslint-disable-next-line import/no-cycle
import {
  closeInstanceThunk,
  createDomainThunk,
  deleteDomainThunk,
  deleteInstanceThunk,
  fetchInstanceEventsThunk,
  getComputeInstanceDetailsThunk,
  getDeploymentDetailsThunk,
  getDomainsThunk,
  getInstanceActivityThunk,
  getInstanceMetricsChartThunk,
  getSuccessfullyDeployedClusterInstancesThunk,
  loadCdnRecordsThunk,
  restartInstanceThunk,
  shellCommandThunk,
  startInstanceDeploymentThunk,
  startTemplateInstanceDeploymentThunk,
  updateDomainThunk,
  updateHealthCheckThunk,
  updateInstanceAutoScalingRulesThunk,
  updateInstanceThunk,
  verifyDomainThunk,
  closeMultipleInstancesThunk,
  removeMultipleInstancesThunk,
  loadMoreDeploymentLogsThunk,
  editInstanceNameThunk,
} from './instance.thunks';
import { toggleListLoading } from '../project/project.utils';
import {
  setInstanceDeployLog,
  setInstanceStreamData,
  setInstanceStreamLog,
} from './instance.utils';
import {
  ClusterInstanceDomain,
  ICdnRecords,
} from '../combined-state.interface';
import { IInstance } from '../compute/project/project.interfaces';

export const initialState: IInstanceState = {
  deployingInstance: false,
  closingInstance: false,
  activeDeployment: null,
  activeDeploymentLoading: true,
  selectedInstance: null,
  selectedInstanceParent: null,
  selectedInstanceLoading: false,
  selectedInstanceComputeLogs: [],
  selectedInstanceComputeEvents: [],
  selectedInstanceActivity: [],
  selectedInstanceActivityLoading: false,
  selectedInstanceStreamLogs: [],
  selectedInstanceComputeLogsPagination: {
    currentPage: 1,
    total: 0,
  },
  selectedInstanceComputeEventsPagination: {
    currentPage: 1,
    total: 0,
  },
  selectedInstanceComputeLogsLoading: false,
  selectedInstanceLogsLoading: false,
  selectedInstanceComputeEventsLoading: false,
  moreSelectedInstanceComputeLogsLoading: false,
  moreSelectedInstanceComputeEventsLoading: false,
  deleteInstanceLoading: false,
  successfullyDeployedInstances: null,
  successfullyDeployedInstancesLoading: false,
  domains: { instanceId: '', domains: [], subDomains: [] },
  domainsLoading: true,
  verifyDomainLoading: [],
  createDomainLoading: false,
  deleteDomainLoading: [],
  updateDomainLoading: [],
  cdnRecords: {
    projectId: '',
    cdnRecord: {
      recordIpv4V2: '',
      recordCnameV2: '',
    },
  },
  fetchInstanceEventService: { topicId: '', message: '' },
  fetchInstanceEventServiceLoading: false,
  fetchInstanceHealthCheckLoading: false,
  healthCheckUpdateLoading: false,
  showLoadLogs: false,
  showInstanceUpdate: false,
  shellCommand: '',
  shellCommandLoading: false,
  shellCommandResponse: [],
  isCustomPlanOpen: false,
  metrics: [],
  metricsLoading: false,
  updatingAutoScalingRules: false,
  metricsChart: [],
  metricsChartLoading: false,
  shouldFetchInstanceDetails: true,
  shouldFetchDeploymentDetails: true,
  removeMultipleInstancesLoading: false,
  closeMultipleInstanceLoading: false,
  shouldFetchLogs: true,
  editNameLoading: false,
  closingInstanceId: '',
  fetchInstanceEventType: null,
};

const instanceSlice = createSlice({
  name: 'instance',
  initialState,
  reducers: {
    setSelectedInstance(state, action: PayloadAction<IInstance>) {
      state.selectedInstance = action.payload;
    },
    setSelectedInstanceLogsLoading(
      state,
      action: PayloadAction<ISetInstanceLogLoading>
    ) {
      switch (action.payload.type) {
        case InstanceLogs.LOGS:
          state.selectedInstanceLogsLoading = action.payload.loading;
          break;

        case InstanceLogs.COMPUTE_LOGS:
          state.selectedInstanceComputeLogsLoading = action.payload.loading;
          break;

        case InstanceLogs.COMPUTE_EVENTS:
          state.selectedInstanceComputeEventsLoading = action.payload.loading;
          break;

        default:
          break;
      }
    },
    setLoadMoreLogsLoading(
      state,
      action: PayloadAction<ISetInstanceLogLoading>
    ) {
      switch (action.payload.type) {
        case InstanceLogs.COMPUTE_LOGS:
          state.moreSelectedInstanceComputeLogsLoading = action.payload.loading;
          break;

        case InstanceLogs.COMPUTE_EVENTS:
          state.moreSelectedInstanceComputeEventsLoading =
            action.payload.loading;
          break;

        default:
          break;
      }
    },
    setInstanceEventsServiceLoading(
      state,
      action: PayloadAction<ISetInstanceLogLoading>
    ) {
      switch (action.payload.type) {
        case 'fetch-logs':
          state.fetchInstanceEventServiceLoading = action.payload.loading;
          break;

        case 'instance-health-check':
          state.fetchInstanceHealthCheckLoading = action.payload.loading;
          break;

        default:
          break;
      }
    },
    setUpdateDomainLoading(state, action: PayloadAction<ISetDomainLoading>) {
      state.updateDomainLoading = toggleListLoading(
        state.updateDomainLoading,
        action.payload
      );
    },
    setCloseInstance(state, action: PayloadAction<boolean>) {
      state.closingInstance = action.payload;
    },
    setVerifyDomainLoading(state, action: PayloadAction<ISetDomainLoading>) {
      state.verifyDomainLoading = toggleListLoading(
        state.verifyDomainLoading,
        action.payload
      );
    },
    setDeleteDomainLoading(state, action: PayloadAction<ISetDomainLoading>) {
      state.deleteDomainLoading = toggleListLoading(
        state.deleteDomainLoading,
        action.payload
      );
    },
    setShowLoadLogs(state, action: PayloadAction<boolean>) {
      state.showLoadLogs = action.payload;
    },
    setShellCommand(state, action: PayloadAction<string>) {
      state.shellCommand = action.payload;
    },
    setClosingInstanceId(state, action: PayloadAction<string>) {
      state.closingInstanceId = action.payload;
    },
    setInstanceDeploymentLogs(state, action: PayloadAction<any>) {
      switch (action.payload.type) {
        case InstanceLogs.LOGS: {
          // TODO - LOGS TO BE SENT IN logs instead of logs[0] from BE
          if (action.payload.deployment.logs[0]?.log)
            state.selectedInstanceStreamLogs = setInstanceStreamLog(
              action.payload.deployment.logs
            );
          else
            state.selectedInstanceStreamLogs = setInstanceDeployLog(
              action.payload.deployment.logs
            );
          break;
        }
        case InstanceLogs.COMPUTE_LOGS: {
          state.selectedInstanceComputeLogs = action.payload.deployment.logs;
          state.selectedInstanceComputeLogsPagination = {
            ...state.selectedInstanceComputeLogsPagination,
            total: action.payload.deployment.logsLength,
          };
          break;
        }
        case InstanceLogs.COMPUTE_EVENTS: {
          state.selectedInstanceComputeEvents = action.payload.deployment.logs;
          state.selectedInstanceComputeEventsPagination = {
            ...state.selectedInstanceComputeEventsPagination,
            total: action.payload.deployment.logsLength,
          };
          break;
        }
        default:
          break;
      }
    },
    setInstanceSocketDetails(state, action: PayloadAction<any>) {
      state.selectedInstance = {
        ...state.selectedInstance!,
        latestUrlPreview: action.payload.latestUrlPreview || '',
        state: action.payload.status,
      };
      if (action.payload.status !== InstanceStatus.FAILED) {
        const currServices = [...state.activeDeployment!.services];
        const updatedServices: IService[] = [];
        currServices.forEach((currService) => {
          Object.keys(action.payload.ports).forEach((key) => {
            if (key.toLowerCase() === currService.name.toLowerCase()) {
              updatedServices.push({
                ...currService,
                ports: action.payload.ports![key],
              });
            }
          });
        });
        state.activeDeployment = {
          ...state.activeDeployment!,
          status: action.payload.status,
          protocolData: {
            ...(state.activeDeployment as any)?.protocolData,
            providerHost: action.payload.providerHost,
          },
          services: updatedServices,
        };
      } else {
        state.activeDeployment = {
          ...state.activeDeployment!,
          status: action.payload.status,
        };
      }
    },
    setInstanceStreamLogs(
      state,
      action: PayloadAction<{
        streamData: string;
        topicId: string;
      }>
    ) {
      state.selectedInstanceStreamLogs = setInstanceStreamData(
        state.selectedInstanceStreamLogs,
        action.payload.streamData,
        action.payload.topicId
      );
    },
    resetInstanceDeployLogs(state) {
      state.selectedInstanceStreamLogs = [];
      state.selectedInstanceComputeEvents = [];
      state.selectedInstanceComputeLogs = [];
    },
    resetSelectedInstance(state) {
      state.selectedInstance = null;
    },

    setShowInstanceUpdate(state, action: PayloadAction<boolean>) {
      state.showInstanceUpdate = action.payload;
    },

    setIsCustomPlanOpen(state, action: PayloadAction<boolean>) {
      state.isCustomPlanOpen = action.payload;
    },

    toggleShouldFetchInstanceDetails(state, action: PayloadAction<boolean>) {
      state.shouldFetchInstanceDetails = action.payload;
    },

    toggleShouldFetchDeploymentDetails(state, action: PayloadAction<boolean>) {
      state.shouldFetchDeploymentDetails = action.payload;
    },

    resetInstanceState(state) {
      Object.assign(state, initialState);
    },

    toggleShouldFetchLogs(state, action: PayloadAction<boolean>) {
      state.shouldFetchLogs = action.payload;
    },

    setFetchInstanceEventType(
      state,
      action: PayloadAction<InstanceLogs | null>
    ) {
      state.fetchInstanceEventType = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(
      loadMoreDeploymentLogsThunk.fulfilled,
      (state, action: PayloadAction<any>) => {
        switch (action.payload.type) {
          case InstanceLogs.COMPUTE_LOGS: {
            state.selectedInstanceComputeLogs = [
              ...action.payload.deployment.logs,
              ...state.selectedInstanceComputeLogs,
            ];
            state.selectedInstanceComputeLogsPagination = {
              ...state.selectedInstanceComputeLogsPagination,
              currentPage:
                state.selectedInstanceComputeLogsPagination.currentPage + 1,
            };
            break;
          }
          case InstanceLogs.COMPUTE_EVENTS: {
            state.selectedInstanceComputeEvents = [
              ...action.payload.deployment.logs,
              ...state.selectedInstanceComputeEvents,
            ];
            state.selectedInstanceComputeEventsPagination = {
              ...state.selectedInstanceComputeEventsPagination,
              currentPage:
                state.selectedInstanceComputeEventsPagination.currentPage + 1,
            };
            break;
          }
          default:
            break;
        }
      }
    );

    // START INSTANCE DEPLOYMENT
    builder.addCase(startInstanceDeploymentThunk.pending, (state) => {
      state.deployingInstance = true;
    });
    builder.addCase(startInstanceDeploymentThunk.fulfilled, (state) => {
      state.deployingInstance = false;
    });
    builder.addCase(startInstanceDeploymentThunk.rejected, (state) => {
      state.deployingInstance = false;
    });

    // START TEMPLATE INSTANCE DEPLOYMENT
    builder.addCase(startTemplateInstanceDeploymentThunk.pending, (state) => {
      state.deployingInstance = true;
    });
    builder.addCase(startTemplateInstanceDeploymentThunk.fulfilled, (state) => {
      state.deployingInstance = false;
    });
    builder.addCase(startTemplateInstanceDeploymentThunk.rejected, (state) => {
      state.deployingInstance = false;
    });

    // UPDATE INSTANCE
    builder.addCase(updateInstanceThunk.pending, (state) => {
      state.deployingInstance = true;
    });
    builder.addCase(updateInstanceThunk.fulfilled, (state) => {
      state.deployingInstance = false;
    });
    builder.addCase(updateInstanceThunk.rejected, (state) => {
      state.deployingInstance = false;
    });

    // Restart INSTANCE
    builder.addCase(restartInstanceThunk.pending, (state) => {
      state.deployingInstance = true;
    });
    builder.addCase(restartInstanceThunk.fulfilled, (state) => {
      state.deployingInstance = false;
    });
    builder.addCase(restartInstanceThunk.rejected, (state) => {
      state.deployingInstance = false;
    });

    // UPDATE HEALTH CHECK
    builder.addCase(updateHealthCheckThunk.pending, (state) => {
      state.healthCheckUpdateLoading = true;
    });
    builder.addCase(updateHealthCheckThunk.fulfilled, (state) => {
      state.healthCheckUpdateLoading = false;
    });
    builder.addCase(updateHealthCheckThunk.rejected, (state) => {
      state.healthCheckUpdateLoading = false;
    });

    // GET INSTANCE ACTIVITY
    builder.addCase(getInstanceActivityThunk.pending, (state) => {
      state.selectedInstanceActivityLoading = true;
    });
    builder.addCase(
      getInstanceActivityThunk.fulfilled,
      (
        state,
        action: PayloadAction<{
          activityDetails: IActivityDetails[];
        }>
      ) => {
        state.selectedInstanceActivityLoading = false;
        state.selectedInstanceActivity = action.payload.activityDetails;
      }
    );
    builder.addCase(getInstanceActivityThunk.rejected, (state) => {
      state.selectedInstanceActivityLoading = false;
    });

    // CLOSE INSTANCE
    builder.addCase(closeInstanceThunk.pending, (state) => {
      state.closingInstance = true;
    });

    builder.addCase(
      closeInstanceThunk.fulfilled,
      (state, action: PayloadAction<string>) => {
        state.closingInstance = false;
        if (state.selectedInstance) {
          if (state.selectedInstance._id === action.payload) {
            state.selectedInstance = {
              ...state.selectedInstance!,
              state: InstanceCardState.CLOSED,
            };
            if (state.activeDeployment) {
              state.activeDeployment.status = InstanceStatus.DEPRECATED;
            }
          }
        }
      }
    );

    builder.addCase(closeInstanceThunk.rejected, (state) => {
      state.closingInstance = false;
    });

    // DELETE INSTANCE
    builder.addCase(deleteInstanceThunk.pending, (state) => {
      state.deleteInstanceLoading = true;
    });
    builder.addCase(deleteInstanceThunk.fulfilled, (state) => {
      state.deleteInstanceLoading = false;
      state.selectedInstance = null;
      state.selectedInstanceParent = null;
      state.selectedInstanceLoading = false;
      state.selectedInstanceComputeLogs = [];
      state.selectedInstanceComputeEvents = [];
      state.selectedInstanceActivity = [];
      state.selectedInstanceActivityLoading = false;
      state.selectedInstanceStreamLogs = [];
      state.selectedInstanceComputeLogsLoading = false;
      state.selectedInstanceLogsLoading = false;
      state.selectedInstanceComputeEventsLoading = false;
      state.moreSelectedInstanceComputeLogsLoading = false;
      state.moreSelectedInstanceComputeEventsLoading = false;
    });
    builder.addCase(deleteInstanceThunk.rejected, (state) => {
      state.deleteInstanceLoading = false;
    });

    // GET SUCCESSFULLY DEPLOYED CLUSTER INSTANCES
    builder.addCase(
      getSuccessfullyDeployedClusterInstancesThunk.pending,
      (state) => {
        state.successfullyDeployedInstancesLoading = true;
      }
    );
    builder.addCase(
      getSuccessfullyDeployedClusterInstancesThunk.fulfilled,
      (state, action: PayloadAction<{ clusterInstances: any[] }>) => {
        state.successfullyDeployedInstancesLoading = false;
        state.successfullyDeployedInstances = action.payload.clusterInstances;
      }
    );
    builder.addCase(
      getSuccessfullyDeployedClusterInstancesThunk.rejected,
      (state) => {
        state.successfullyDeployedInstancesLoading = false;
      }
    );

    // LOAD CDN RECORDS
    builder.addCase(
      loadCdnRecordsThunk.fulfilled,
      (
        state,
        action: PayloadAction<{ projectId: string; cdnRecord: ICdnRecords }>
      ) => {
        state.cdnRecords = action.payload;
      }
    );

    // FETCH INSTANCE EVENTS
    builder.addCase(
      fetchInstanceEventsThunk.fulfilled,
      (state, action: PayloadAction<IFetchInstanceServiceResponse>) => {
        state.fetchInstanceEventService = {
          topicId: action.payload.topicId,
          message: action.payload.message,
        };
      }
    );

    // GET DOMAINS
    builder.addCase(getDomainsThunk.pending, (state) => {
      state.domainsLoading = true;
    });
    builder.addCase(
      getDomainsThunk.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.domainsLoading = false;
        state.domains = {
          instanceId: action.payload.instanceId,
          domains: (action.payload.domain.domains as IInstanceDomains[]).filter(
            (d: IInstanceDomains) => d.type === ClusterInstanceDomain.domain
          ),
          subDomains: (
            action.payload.domain.domains as IInstanceDomains[]
          ).filter(
            (d: IInstanceDomains) => d.type === ClusterInstanceDomain.subdomain
          ),
        };
      }
    );
    builder.addCase(getDomainsThunk.rejected, (state) => {
      state.domainsLoading = false;
    });

    // CREATE DOMAIN
    builder.addCase(createDomainThunk.pending, (state) => {
      state.createDomainLoading = true;
    });
    builder.addCase(
      createDomainThunk.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.createDomainLoading = false;
        switch (action.payload.domain.type) {
          default:
          case ClusterInstanceDomain.domain: {
            state.domains = {
              ...state.domains,
              domains: [...state.domains.domains, action.payload.domain],
            };
            break;
          }
          case ClusterInstanceDomain.subdomain: {
            state.domains = {
              ...state.domains,
              subDomains: [...state.domains.subDomains, action.payload.domain],
            };
            break;
          }
        }
      }
    );
    builder.addCase(createDomainThunk.rejected, (state) => {
      state.createDomainLoading = false;
    });

    // UPDATE DOMAIN
    builder.addCase(
      updateDomainThunk.fulfilled,
      (state, action: PayloadAction<any>) => {
        switch (action.payload.type) {
          default:
          case ClusterInstanceDomain.domain: {
            state.domains = {
              ...state.domains,
              domains: [
                action.payload,
                ...state.domains.domains.filter(
                  (domain) => domain._id !== action.payload!._id
                ),
              ],
            };
            break;
          }

          case ClusterInstanceDomain.subdomain: {
            state.domains = {
              ...state.domains,
              subDomains: [
                action.payload,
                ...state.domains.subDomains.filter(
                  (subdomain) => subdomain._id !== action.payload!._id
                ),
              ],
            };
            break;
          }
        }
      }
    );

    // VERIFY DOMAIN
    builder.addCase(
      verifyDomainThunk.fulfilled,
      (state, action: PayloadAction<any>) => {
        switch (action.payload.type) {
          case ClusterInstanceDomain.domain: {
            const changedDomain = state.domains?.domains?.find(
              (domain) => domain._id === action.payload._id
            );
            changedDomain!.verified = true;

            state.domains = {
              ...state.domains,
              domains: [
                ...state.domains?.domains?.filter(
                  (domain) => domain._id !== changedDomain!._id
                ),
                changedDomain!,
              ],
            };
            break;
          }

          case ClusterInstanceDomain.subdomain: {
            const changedDomain = state.domains?.subDomains?.find(
              (domain) => domain._id === action.payload._id
            );
            changedDomain!.verified = true;
            state.domains = {
              ...state.domains,
              subDomains: [
                ...state.domains?.subDomains?.filter(
                  (subdomain) => subdomain._id !== changedDomain!._id
                ),
                changedDomain!,
              ],
            };
            break;
          }

          default:
            break;
        }
      }
    );

    // DELETE DOMAIN
    builder.addCase(
      deleteDomainThunk.fulfilled,
      (state, action: PayloadAction<any>) => {
        switch (action.payload.domainType) {
          case ClusterInstanceDomain.domain: {
            state.domains = {
              ...state.domains,
              domains: state.domains.domains.filter(
                (domain) => domain._id !== action.payload.domainId
              ),
            };
            break;
          }

          case ClusterInstanceDomain.subdomain: {
            state.domains = {
              ...state.domains,
              subDomains: state.domains.subDomains.filter(
                (subDomain) => subDomain._id !== action.payload.domainId
              ),
            };
            break;
          }

          default:
            break;
        }

        state.domains = {
          ...state.domains,
          domains: state.domains.domains.filter(
            (domain) => domain._id !== action.payload.domainId
          ),
        };
      }
    );

    // SHELL COMMAND
    builder.addCase(shellCommandThunk.pending, (state) => {
      state.shellCommandLoading = true;
    });
    builder.addCase(
      shellCommandThunk.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.shellCommandLoading = false;
        state.shellCommandResponse = action.payload;
      }
    );
    builder.addCase(shellCommandThunk.rejected, (state) => {
      state.shellCommandLoading = false;
    });

    builder.addCase(updateInstanceAutoScalingRulesThunk.pending, (state) => {
      state.updatingAutoScalingRules = true;
    });

    builder.addCase(
      updateInstanceAutoScalingRulesThunk.fulfilled,
      // eslint-disable-next-line no-unused-vars
      (state, action: PayloadAction<IAutoscalingRequestPayload | null>) => {
        state.updatingAutoScalingRules = false;
        state.activeDeployment = {
          ...state.activeDeployment!,
          services: [
            {
              ...(state.activeDeployment as any).services[0],
              ...(action.payload as IAutoscalingRequestPayload),
            },
          ],
        };
      }
    );

    builder.addCase(updateInstanceAutoScalingRulesThunk.rejected, (state) => {
      state.updatingAutoScalingRules = false;
    });

    builder.addCase(getInstanceMetricsChartThunk.pending, (state) => {
      state.metricsChartLoading = true;
    });
    builder.addCase(
      getInstanceMetricsChartThunk.fulfilled,
      (state, action: PayloadAction<IInstanceMetricsChart[]>) => {
        state.metricsChartLoading = false;
        state.metricsChart = action.payload;
      }
    );
    builder.addCase(getInstanceMetricsChartThunk.rejected, (state) => {
      state.metricsChartLoading = false;
      state.metricsChart = [];
    });

    builder.addCase(getDeploymentDetailsThunk.pending, (state) => {
      state.activeDeploymentLoading = true;
    });

    builder.addCase(
      getDeploymentDetailsThunk.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.activeDeploymentLoading = false;
        state.activeDeployment = action.payload.deployment;
      }
    );

    builder.addCase(getDeploymentDetailsThunk.rejected, (state) => {
      state.activeDeploymentLoading = false;
      state.activeDeployment = null;
    });

    builder.addCase(getComputeInstanceDetailsThunk.pending, (state) => {
      state.selectedInstanceLoading = true;
    });

    builder.addCase(
      getComputeInstanceDetailsThunk.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.selectedInstanceLoading = false;
        state.selectedInstance = action.payload.instance;
      }
    );

    builder.addCase(getComputeInstanceDetailsThunk.rejected, (state) => {
      state.selectedInstanceLoading = false;
      state.selectedInstance = null;
    });

    // CLOSE MULTIPLE INSTANCES
    builder.addCase(closeMultipleInstancesThunk.pending, (state) => {
      state.closeMultipleInstanceLoading = true;
    });

    builder.addCase(closeMultipleInstancesThunk.fulfilled, (state) => {
      state.closeMultipleInstanceLoading = false;
    });

    builder.addCase(closeMultipleInstancesThunk.rejected, (state) => {
      state.closeMultipleInstanceLoading = false;
    });

    // REMOVE MULTIPLE INSTANCES
    builder.addCase(removeMultipleInstancesThunk.pending, (state) => {
      state.removeMultipleInstancesLoading = true;
    });

    builder.addCase(removeMultipleInstancesThunk.fulfilled, (state) => {
      state.removeMultipleInstancesLoading = false;
    });

    builder.addCase(removeMultipleInstancesThunk.rejected, (state) => {
      state.removeMultipleInstancesLoading = false;
    });

    builder.addCase(editInstanceNameThunk.pending, (state) => {
      state.editNameLoading = true;
    });

    builder.addCase(editInstanceNameThunk.rejected, (state) => {
      state.editNameLoading = false;
    });

    builder.addCase(
      editInstanceNameThunk.fulfilled,
      (state, action: PayloadAction<{ name: string; instanceId: string }>) => {
        state.editNameLoading = false;
        if (state.selectedInstance) {
          if (state.selectedInstance._id === action.payload.instanceId) {
            state.selectedInstance = {
              ...state.selectedInstance!,
              name: action.payload.name,
            };
          }
        }
      }
    );
  },
});

export const { reducer: instanceRTKReducer } = instanceSlice;
export const {
  setSelectedInstanceLogsLoading: setSelectedInstanceLogsLoadingRtk,
  setLoadMoreLogsLoading: setLoadMoreLogsLoadingRtk,
  setInstanceEventsServiceLoading: setInstanceEventsServiceLoadingRtk,
  setUpdateDomainLoading: setUpdateDomainLoadingRtk,
  setVerifyDomainLoading: setVerifyDomainLoadingRtk,
  setDeleteDomainLoading: setDeleteDomainLoadingRtk,
  setInstanceDeploymentLogs: setInstanceDeploymentLogsRtk,
  setInstanceSocketDetails: setInstanceSocketDetailsRtk,
  setInstanceStreamLogs: setInstanceStreamLogsRtk,
  resetInstanceDeployLogs: resetInstanceDeployLogsRtk,
  setShowLoadLogs: setShowLoadLogsRtk,
  resetSelectedInstance: resetSelectedInstanceRtk,
  setShowInstanceUpdate: setShowInstanceUpdateRtk,
  setShellCommand: setShellCommandRtk,
  setIsCustomPlanOpen: setIsCustomPlanOpenRtk,
  setSelectedInstance: setSelectedInstanceRtk,
  toggleShouldFetchInstanceDetails,
  toggleShouldFetchDeploymentDetails,
  setCloseInstance: setCloseInstanceRtk,
  setClosingInstanceId: setClosingInstanceIdRtk,
  resetInstanceState,
  toggleShouldFetchLogs,
  setFetchInstanceEventType,
} = instanceSlice.actions;
