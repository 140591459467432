import React from 'react';
import Skeleton from 'react-loading-skeleton';
import GlobalStyle from '../../../styles/compute/global.module.scss';
import LoadMore from '../LoadMore/load-more';
import LogsCard from './logs-card';

interface IProps {
  isLogAvailable: boolean;
  displayLoadMore: boolean;
  handleLoadMoreClick: () => void;
  loadMoreLoading: boolean;
  logsLoading: boolean;
  placeholderText: string;
  logs: string;
}

const LogsContainer = ({
  isLogAvailable,
  displayLoadMore,
  handleLoadMoreClick,
  loadMoreLoading,
  logsLoading,
  placeholderText,
  logs,
}: IProps) => {
  const withLogsLoading = (
    loading: boolean,
    content: JSX.Element
  ): JSX.Element => {
    if (loading)
      return (
        <>
          {Array.from(Array(4).keys()).map((i) => {
            return (
              <tr
                className="py-1 px-6"
                key={`instance-logs-items-${i}`}
                id={`instance-logs-items-${i}`}
              >
                <Skeleton height={26} duration={2} />
              </tr>
            );
          })}
        </>
      );
    return content;
  };
  return (
    <>
      {isLogAvailable && displayLoadMore && (
        <LoadMore
          loading={loadMoreLoading}
          handleClick={() => handleLoadMoreClick()}
          title="LOAD OLDER LOGS"
          classname="my-4"
        />
      )}
      <div
        className={`relative ${GlobalStyle.bubble__box__min__height}
         bg-base-fg dark:bg-dark-base-fg`}
      >
        {isLogAvailable ? (
          <div className="max-h-96 static overflow-y-scroll pt-3">
            <div className="flex flex-col gap-3">
              {withLogsLoading(
                logsLoading,
                <>
                  <LogsCard logs={logs} />
                </>
              )}
            </div>
          </div>
        ) : (
          <div
            className="px-6 py-4 text-base-para-text-color 
          dark:text-dark-base-para-text-color text-sm"
          >
            {withLogsLoading(logsLoading, <>{placeholderText}</>)}
          </div>
        )}
      </div>
    </>
  );
};

export default LogsContainer;
