import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PlanTile from '../PlanTile';
// eslint-disable-next-line max-len
import {
  ClusterType,
  IInstancePlan,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.interfaces';
// eslint-disable-next-line max-len
import { loadMoreInstancePlanThunk } from '../../../../../redux/compute/instance/instance-creation/instance-creation.thunks';
import { getGpuIcon } from '../../../../../redux/compute/instance/instance-creation/instance-creation.utils';
import { AppDispatch, RootState } from '../../../../../redux/compute/store';
import PlanTileLoading from '../../../../../components/Compute/Loaders/plan-tile-loading';
import { IGPU } from '../../../../../redux/compute/instance/instance.interfaces';
import ShowMore from '../../../../../components/Compute/Buttons/show-more';

interface IProps {
  isUpdate: boolean;
  selectedInstancePlan: IInstancePlan;
  // eslint-disable-next-line no-unused-vars
  setSelectedInstancePlan: (selectedInstancePlan: IInstancePlan | null) => void;
}

const ChooseGpuPlan = ({
  isUpdate,
  selectedInstancePlan,
  setSelectedInstancePlan,
}: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();
  const [updatingPlans, setUpdatingPlans] = useState<boolean>(false);
  const clusterType = useSelector(
    (state: RootState) => state.instanceCreation.clusterType
  );
  const selectedTemplate = useSelector(
    (state: RootState) => state.cluster.selectedTemplate
  );
  const instancePlans = useSelector(
    (state: RootState) => state.instanceCreation.instancePlans
  );
  const instancePlansLoading = useSelector(
    (state: RootState) => state.instanceCreation.instancePlanLoading
  );
  const instancePlanPaginationLoading = useSelector(
    (state: RootState) => state.instanceCreation.instancePlanPaginationLoading
  );
  const regionsLoading = useSelector(
    (state: RootState) => state.instanceCreation.getClusterRegionsLoading
  );
  const activeDeployment = useSelector(
    (state: RootState) => state.instance.activeDeployment
  );
  const activeDeploymentLoading = useSelector(
    (state: RootState) => state.instance.activeDeploymentLoading
  );
  const recommendedPlanLoading = useSelector(
    (state: RootState) => state.instanceCreation.recommendedPlanLoading
  );
  const scalingType = useSelector(
    (state: RootState) => state.instanceCreation.clusterScaling
  );

  const monthlyCostUSD = (price: number) => {
    return (Number(price) * 30 || 0).toFixed(2);
  };

  const hourlyCostUSD = (price: number) => {
    return (Number(price) / 24 || 0).toFixed(2);
  };

  // CREATE INSTANCE USEEFFECT
  useEffect(() => {
    if (
      !regionsLoading &&
      !isUpdate &&
      !instancePlansLoading &&
      instancePlans?.akashMachineImages.length > 0 &&
      !recommendedPlanLoading
    ) {
      setUpdatingPlans(true);
      if (clusterType === ClusterType.TEMPLATE) {
        const recommededPlanId =
          selectedTemplate?.serviceData.defaultAkashMachineImageId;
        if (recommededPlanId) {
          const recommendedPlan = instancePlans.akashMachineImages.find(
            (instancePlan) => instancePlan._id === recommededPlanId
          );
          if ((recommendedPlan?.gpu as IGPU)?.availableUnits) {
            setSelectedInstancePlan(
              instancePlans.akashMachineImages.find(
                (instancePlan) => instancePlan._id === recommededPlanId
              )!!
            );
          } else {
            setSelectedInstancePlan(
              instancePlans.akashMachineImages.find(
                (instancePlan) =>
                  (instancePlan?.gpu as IGPU)?.availableUnits > 0
              )!!
            );
          }
        } else {
          setSelectedInstancePlan(instancePlans.akashMachineImages[0]);
        }
      } else {
        setSelectedInstancePlan(instancePlans.akashMachineImages[0]);
      }
      setUpdatingPlans(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    regionsLoading,
    isUpdate,
    instancePlansLoading,
    clusterType,
    selectedTemplate,
    recommendedPlanLoading,
  ]);

  // UPDATE INSTANCE USEEFFECT
  useEffect(() => {
    if (
      activeDeployment &&
      !activeDeploymentLoading &&
      !regionsLoading &&
      isUpdate
    ) {
      setSelectedInstancePlan(
        instancePlans.akashMachineImages.find(
          (instancePlan) =>
            instancePlan._id ===
            activeDeployment.services[0].agreedMachineImage.machineId
        )!!
      );
    }
  }, [
    regionsLoading,
    isUpdate,
    instancePlansLoading,
    instancePlans.akashMachineImages,
    activeDeployment,
    activeDeploymentLoading,
    clusterType,
    setSelectedInstancePlan,
    selectedTemplate?.serviceData.defaultAkashMachineImageId,
  ]);

  const loadMoreGpuPlans = () => {
    dispatchRtk(loadMoreInstancePlanThunk());
  };

  return (
    <div
      className={`mt-6 grid gap-6 col-span-3 ${
        isUpdate ? 'grid-cols-3' : 'grid-cols-4'
      }`}
    >
      {updatingPlans ||
      recommendedPlanLoading ||
      instancePlansLoading ||
      regionsLoading ||
      instancePlans?.akashMachineImages?.length === 0 ? (
        [...Array(8)].map((item) => <PlanTileLoading key={item} />)
      ) : (
        <>
          {[...instancePlans?.akashMachineImages]
            ?.sort(
              (a, b) =>
                (b?.gpu as IGPU).availableUnits -
                (a?.gpu as IGPU).availableUnits
            )
            ?.map((plan) => (
              <PlanTile
                icon={getGpuIcon(
                  (plan?.gpu as IGPU)?.vendor?.toLowerCase() || ''
                )}
                gpu={plan?.gpu as IGPU}
                key={plan._id}
                title={plan.name}
                monthPrice={monthlyCostUSD(plan.price)}
                hourlyPrice={hourlyCostUSD(plan.price)}
                ramGb={plan.memory}
                cpuQuantity={plan.cpu}
                disabled={!(plan?.gpu as IGPU)?.availableUnits}
                isSelected={selectedInstancePlan?._id === plan._id}
                onSelect={() => setSelectedInstancePlan(plan)}
                isCustomStyle={false}
                recommended={
                  plan.recommendedPlan && !!(plan?.gpu as IGPU)?.availableUnits
                }
                scalingType={scalingType}
              />
            ))}
          {instancePlans?.akashMachineImages?.length <
            instancePlans?.totalCount && (
            <ShowMore
              title="Show More"
              handleClick={() => loadMoreGpuPlans()}
              loading={instancePlanPaginationLoading}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ChooseGpuPlan;
