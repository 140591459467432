import React, { useEffect, useState } from 'react';
import { Tooltip } from '@spheron/ui-library';
import { ReactComponent as CopyIcon } from '@spheron/ui-library/dist/assets/copy.svg';
import { copyToClipboard } from '../../../redux/compute/user/user.utils';

interface IProps {
  text: string;
  showCopyIcon: boolean;
}

const CLI = ({ text, showCopyIcon }: IProps) => {
  const [codeCopied, setCodeCopied] = useState<string>('');

  const handleCodeCopyClick = (bucketId: string) => {
    copyToClipboard(bucketId);
    setCodeCopied(bucketId);
  };

  useEffect(() => {
    setTimeout(() => {
      setCodeCopied('');
    }, 1000);
  }, [codeCopied]);

  return (
    <div
      className="p-3 rounded-lg flex items-center justify-between 
    w-full bg-base-fg dark:bg-dark-base-fg border
    border-base-border dark:border-dark-base-border"
    >
      <p
        className="text-base-para-text-color dark:text-dark-base-para-text-color
       text-sm font-normal"
      >
        {text}
      </p>
      {showCopyIcon && (
        <Tooltip
          position="top"
          text={codeCopied ? 'Copied' : ''}
          type="float"
          wrapText
        >
          <CopyIcon
            className="text-base-icon dark:text-dark-base-icon
                        w-5 h-5 cursor-pointer opacity-80 hover:opacity-100"
            onClick={() => handleCodeCopyClick(text)}
          />
        </Tooltip>
      )}
    </div>
  );
};

export default CLI;
