import React, { useMemo } from 'react';
import Editor from '../Editor';

interface IProps {
  logs: string;
  showLines?: boolean;
}

const LogsCard = ({ logs, showLines }: IProps) => {
  // eslint-disable-next-line no-control-regex
  const regex = /\x1B\[\d+(;\d+)*m/g;

  const memoisedLogs = useMemo(() => {
    try {
      return decodeURIComponent(logs);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('error in decoding logs -> ', error);
      return unescape(logs);
    }
  }, [logs]);

  const removeAnsiCodes = (input: string) => {
    return input.replace(regex, '');
  };

  return (
    <Editor
      // Different themes can be passed here
      // Terminal Colour is the theme for the terminal that is used
      theme="terminalColour"
      language="terminal"
      height={400}
      defaultValue="No logs..."
      value={removeAnsiCodes(memoisedLogs)}
      options={{
        border: 'none',
        fontSize: '14px',
        fontColor: 'white',
        editorLineNumberColor: 'white',
        lineHeight: '24px',
        fontFamily: 'Roboto Mono',
        readOnly: true,
        wordWrap: 'on',
        scrollByPage: true,
        scrollBeyondLastLine: false,
        lineNumbers: showLines,
        scrollBeyondLastColumn: false,
        minimap: {
          enabled: false,
        },
        renderLineHighlight: 'none',
      }}
      width="100%"
    />
  );
};

LogsCard.defaultProps = {
  showLines: true,
};

export default LogsCard;
