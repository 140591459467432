/* eslint-disable import/no-cycle */
import * as Sentry from '@sentry/react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { SiweMessage } from 'siwe';
import config from '../../config';
import { IResponseError } from '../combined-reducer.interface';
import { IWallet, PaymentMethod } from '../combined-state.interface';
import { toggleModalShowRtk } from '../modal/modal.slice';
import { NotificationType } from '../notification/notification.interfaces';
import { addNotificationRtk } from '../notification/notification.slice';
import {
  IOrgWallet,
  IRemoveWalletPayloadDto,
  IRemoveWalletResponsePayloadDto,
  ISelectedOrganisationWalletConfig,
} from '../organisation/organisation.interfaces';
import {
  addWalletService,
  removeWalletService,
  updateOrgDomainRegistryService,
} from '../organisation/organisation.services';
import {
  setTempPaymentMethodIdRtk,
  updateDefaultPaymentMethodRtk,
} from '../organisation/organisation.slice';
import {
  fetchRegistryDetailsThunk,
  getSelectedOrgWalletThunk,
} from '../organisation/organisation.thunks';

import {
  IAddDomainRegistryPayload,
  IConfigureWalletPayloadDtoRtk,
  IGetBalance,
  INetworkType,
  ITokenType,
  IUpdaterPayload,
  IWeb3State,
} from './web3.interfaces';
import {
  addDomainToRegistry,
  addUpdaterToRegistry,
  changeNetworkEthService,
  changeNetworkService,
  connectWalletService,
  createDomainRegistry,
  depositTokenServices,
  getBalanceService,
  getPaymentHistory,
  getTokenAllowanceService,
  getTokenBalanceService,
  removeUpdaterFromRegistry,
  setTokenAllowanceService,
  signMessageService,
  updateEnsContentHash,
  web3NonceService,
  withdrawTokenServices,
  getUserAvailableBalance,
} from './web3.services';
import { getNetworkFromName, isNonSubgraphChainId } from './web3.utils';
import { RootState } from '../rtk-store';
import { updateCreditCardPrimaryPayment } from '../stripe/stripe.slice';
import {
  toggleSignatureLoadingRtk,
  updateCryptoWalletPrimaryPaymentRtk,
  initialState as web3InitialState,
} from './web3.slice';
import { toggleSignupCallbackLoadingRtk } from '../user/user.slice';

export const saveWalletThunk = createAsyncThunk(
  'web3/saveWallet',
  async (
    payload: {
      organisationId: string;
      wType?: string;
      web3State: IWeb3State;
    },
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const apiPayload = {
        organizationId: payload.organisationId,
        paymentMethod: PaymentMethod.CRYPTO,
        details: {
          address: payload.web3State.currentAccount,
          signature: payload.web3State.signature,
          wType: payload.wType || 'METAMASK',
          tokenDetails: {
            name: payload.web3State.selectedToken?.symbol,
            address: payload.web3State.selectedToken?.address,
            decimals: payload.web3State.selectedToken?.decimals,
          },
          networkDetails: {
            name: payload.web3State.selectedNetwork?.chainName,
            chainId: payload.web3State.selectedNetwork?.networkId,
          },
        },
      };
      const apiResponse = await addWalletService(apiPayload as any);
      if ((apiResponse as IResponseError).error) {
        dispatch(
          addNotificationRtk({
            message: (apiResponse as IResponseError).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      dispatch(toggleModalShowRtk({ modalShow: false }));
      dispatch(setTempPaymentMethodIdRtk((apiResponse as IWallet)._id));
      dispatch(getSelectedOrgWalletThunk(payload.organisationId));
      return fulfillWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const fetchBalanceThunk = createAsyncThunk(
  'web3/fetchBalance',
  async (
    payload: { walletId: string; balance: IGetBalance },
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const balanceResponse = await getBalanceService(
        payload.balance.address,
        payload.balance.token as string,
        payload.balance.chainId as number
      );
      if (!balanceResponse.error) {
        if (balanceResponse.data.balance === null) {
          return fulfillWithValue({
            id: payload.walletId,
            balance: {
              amount: '',
              token: payload.balance.token,
            },
          });
        }
        return fulfillWithValue({
          id: payload.walletId,
          balance: balanceResponse.data.balance,
        });
      }
      dispatch(
        addNotificationRtk({
          message: balanceResponse.errorMessage,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const fetchWalletBalanceThunk = createAsyncThunk(
  'web3/fetchWalletBalance',
  async (
    payload: ISelectedOrganisationWalletConfig,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const networkResponse = await changeNetworkService(
        payload.networkDetails?.networkId || -1
      );
      if (!networkResponse.error) {
        const balanceResponse = await getTokenBalanceService(
          payload.address,
          {
            address: payload.tokenDetails?.address!,
            decimals: payload.tokenDetails?.decimals!,
          },
          payload.networkDetails?.chainId!
        );
        if (!balanceResponse.error) {
          return fulfillWithValue({
            walletId: payload.walletId,
            balance: {
              amount: String(balanceResponse.balance),
              token: payload.tokenDetails?.address,
            },
          });
        }
        dispatch(
          addNotificationRtk({
            message: balanceResponse.errorMessage,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      dispatch(
        addNotificationRtk({
          message: networkResponse.errorMessage,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const fetchWalletAvailableBalanceThunk = createAsyncThunk(
  'web3/fetchWalletAvailableBalance',
  async (
    payload: ISelectedOrganisationWalletConfig,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const walletResponse = await connectWalletService();
      if (!walletResponse.error) {
        const networkResponse = await changeNetworkService(
          payload.networkDetails?.networkId || -1
        );
        if (!networkResponse.error) {
          const balanceResponse = await getUserAvailableBalance(
            payload.address,
            {
              address: payload.tokenDetails?.address!,
              decimals: payload.tokenDetails?.decimals!,
            },
            payload.networkDetails?.chainId!
          );

          if (!balanceResponse.error) {
            return fulfillWithValue(balanceResponse.balance);
          }
          dispatch(
            addNotificationRtk({
              message: balanceResponse.errorMessage,
              timestamp: Date.now(),
              type: NotificationType.Error,
            })
          );
          return rejectWithValue({});
        }
        dispatch(
          addNotificationRtk({
            message: networkResponse.errorMessage,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const configureWalletThunk = createAsyncThunk(
  'web3/configureWallet',
  async (
    payload: IConfigureWalletPayloadDtoRtk,
    { rejectWithValue, dispatch, fulfillWithValue }
  ) => {
    try {
      const walletResponse = await connectWalletService();
      if (!walletResponse.error) {
        const networkResponse = await changeNetworkService(
          payload.selectedNetwork.networkId!
        );
        if (!networkResponse.error) {
          const balanceResponse = await getTokenBalanceService(
            walletResponse.account,
            {
              address: payload.selectedToken.address,
              decimals: payload.selectedToken.decimals,
            },
            payload.selectedNetwork.chainId
          );
          if (!balanceResponse.error) {
            const signResponse = await signMessageService(
              config.web3.ENABLE_VERIFYING_MESSAGE,
              payload.selectedNetwork
            );
            if (!signResponse.error) {
              const res = {
                walletAddress: walletResponse.account,
                tokenBalance: balanceResponse.balance,
                signature: signResponse.signature as string,
              };
              dispatch(
                saveWalletThunk({
                  organisationId: payload.selectedOrganisationId,
                  web3State: {
                    ...web3InitialState,
                    selectedNetwork: payload.selectedNetwork,
                    selectedToken: payload.selectedToken,
                    currentAccount: res.walletAddress,
                    selectedTokenBalance: res.tokenBalance,
                    signature: res.signature,
                  },
                })
              );

              return fulfillWithValue(res);
            }
            dispatch(
              addNotificationRtk({
                message: signResponse.errorMessage,
                timestamp: Date.now(),
                type: NotificationType.Error,
              })
            );
            return rejectWithValue({});
          }
          dispatch(
            addNotificationRtk({
              message: balanceResponse.errorMessage,
              timestamp: Date.now(),
              type: NotificationType.Error,
            })
          );
          return rejectWithValue({});
        }
        dispatch(
          addNotificationRtk({
            message: networkResponse.errorMessage,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      dispatch(
        addNotificationRtk({
          message: walletResponse.errorMessage,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const fetchWalletAllowanceThunk = createAsyncThunk(
  'web3/fetchWalletAllowance',
  async (
    payload: {
      selectedOrgWalletConfig: ISelectedOrganisationWalletConfig;
    },
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const networkResponse = await changeNetworkService(
        payload.selectedOrgWalletConfig.networkDetails?.networkId || -1
      );
      if (!networkResponse.error) {
        const allowanceResponse = await getTokenAllowanceService(
          payload.selectedOrgWalletConfig.address,
          payload.selectedOrgWalletConfig.tokenDetails!,
          payload.selectedOrgWalletConfig.networkDetails!
        );
        if (!allowanceResponse.error) {
          return fulfillWithValue(allowanceResponse.allowance);
        }
        dispatch(
          addNotificationRtk({
            message: allowanceResponse.errorMessage,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      dispatch(
        addNotificationRtk({
          message: networkResponse.errorMessage,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const switchNetworkThunk = createAsyncThunk(
  'web3/switchNetwork',
  async (
    payload: INetworkType,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const networkResponse = await changeNetworkService(
        payload.networkId || -1
      );
      if (!networkResponse.error) {
        return fulfillWithValue({});
      }
      dispatch(
        addNotificationRtk({
          message: networkResponse.errorMessage,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const setTokenAllowanceThunk = createAsyncThunk(
  'web3/setTokenAllowance',
  async (
    payload: {
      address: string;
      transactionValue: string;
      networkDetails: INetworkType;
      tokenDetails: ITokenType;
    },
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const allowanceResponse = await setTokenAllowanceService(
        payload.transactionValue,
        payload.tokenDetails!,
        payload.networkDetails!
      );
      if (!allowanceResponse.error) {
        dispatch(
          addNotificationRtk({
            // eslint-disable-next-line max-len
            message: `You have successfully approved ${payload.transactionValue} ${payload.tokenDetails?.symbol}`,
            title: 'Approval Successful',
            timestamp: Date.now(),
            type: NotificationType.Success,
          })
        );
        return fulfillWithValue(payload.transactionValue);
      }
      dispatch(
        addNotificationRtk({
          message: allowanceResponse.errorMessage,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const fetchPaymentHistoryThunk = createAsyncThunk(
  'web3/fetchPaymentHistory',
  async (
    payload: {
      address: string;
      networkDetails: INetworkType;
      tokenDetails: ITokenType;
    },
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const paymentHistoryResponse = await getPaymentHistory(
        payload.address,
        payload.networkDetails.networkId!
      );
      if (!paymentHistoryResponse.error) {
        if (paymentHistoryResponse.data.user !== null) {
          paymentHistoryResponse.data.user.deposit =
            paymentHistoryResponse.data.user.deposit.map((payment: any) => {
              return {
                ...payment,
                wallet: payload,
              };
            });

          paymentHistoryResponse.data.user.withdraw =
            paymentHistoryResponse.data.user.withdraw.map((payment: any) => {
              return {
                ...payment,
                wallet: payload,
              };
            });
          return fulfillWithValue(paymentHistoryResponse.data.user);
        }
        return rejectWithValue({});
      }
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const depositTokenThunk = createAsyncThunk(
  'web3/depositToken',
  async (
    payload: {
      address: string;
      transactionValue: string;
      networkDetails: INetworkType;
      tokenDetails: ITokenType;
    },
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const depositResponse = await depositTokenServices(
        payload.transactionValue,
        payload.tokenDetails!,
        payload.networkDetails!
      );
      if (!depositResponse.error) {
        dispatch(toggleModalShowRtk({ modalShow: false }));
        dispatch(
          addNotificationRtk({
            message: 'Successfully Deposited',
            timestamp: Date.now(),
            type: NotificationType.Success,
          })
        );
        if (!isNonSubgraphChainId(Number(payload.networkDetails.chainId)))
          dispatch(
            fetchPaymentHistoryThunk({
              address: payload.address,
              networkDetails: payload.networkDetails,
              tokenDetails: payload.tokenDetails,
            })
          );

        return fulfillWithValue({});
      }

      dispatch(
        addNotificationRtk({
          message: depositResponse.errorMessage,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const withdrawTokenThunk = createAsyncThunk(
  'web3/withdrawToken',
  async (
    payload: {
      walletId: string;
      address: string;
      transactionValue: string;
      networkDetails: INetworkType;
      tokenDetails: ITokenType;
    },
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const withdrawResponse = await withdrawTokenServices(
        payload.transactionValue,
        payload.tokenDetails!,
        payload.networkDetails!
      );
      if (!withdrawResponse.error) {
        dispatch(toggleModalShowRtk({ modalShow: false }));
        dispatch(
          addNotificationRtk({
            message: 'Successfully Withdrawn',
            timestamp: Date.now(),
            type: NotificationType.Success,
          })
        );
        dispatch(
          fetchPaymentHistoryThunk({
            address: payload.address,
            networkDetails: payload.networkDetails as INetworkType,
            tokenDetails: payload.tokenDetails as ITokenType,
          })
        );

        dispatch(
          fetchBalanceThunk({
            walletId: payload.walletId,
            balance: {
              address: payload.address,
              token: payload.tokenDetails!.address,
              chainId: Number(payload.networkDetails!.chainId),
            },
          })
        );

        return fulfillWithValue({});
      }
      dispatch(
        addNotificationRtk({
          message: withdrawResponse.errorMessage,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );

      dispatch(
        fetchBalanceThunk({
          walletId: payload.walletId,
          balance: {
            address: payload.address,
            token: payload.tokenDetails!.name,
            chainId: Number(payload.networkDetails!.chainId),
          },
        })
      );

      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const connectWalletThunk = createAsyncThunk(
  'web3/connectWallet',
  async (payload: void, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const walletResponse = await connectWalletService();
      if (!walletResponse.error) {
        return fulfillWithValue(walletResponse.account);
      }
      dispatch(
        addNotificationRtk({
          message: walletResponse.errorMessage,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const updateEnsContentHashThunk = createAsyncThunk(
  'web3/updateEnsContentHash',
  async (
    payload: {
      domain: string;
      contentHash: string;
    },
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const walletResponse = await connectWalletService();
      if (!walletResponse.error) {
        const networkResponse = await changeNetworkEthService();
        if (!networkResponse.error) {
          const ensUpdateResponse = await updateEnsContentHash(
            payload.domain,
            payload.contentHash
          );
          if (!ensUpdateResponse.error) {
            dispatch(
              addNotificationRtk({
                message: 'ENS content hash updated successfully',
                timestamp: new Date(),
                type: NotificationType.Success,
              })
            );
            return fulfillWithValue({});
          }
          dispatch(
            addNotificationRtk({
              message: ensUpdateResponse.errorMessage,
              timestamp: Date.now(),
              type: NotificationType.Error,
            })
          );
          return rejectWithValue({});
        }
        dispatch(
          addNotificationRtk({
            message: networkResponse.errorMessage,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      dispatch(
        addNotificationRtk({
          message: walletResponse.errorMessage,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const disconnectWalletThunk = createAsyncThunk(
  'web3/disconnectWallet',
  async (
    payload: {
      id: string;
      address: string;
      networkDetails: INetworkType;
      tokenDetails: ITokenType;
    },
    { rejectWithValue, fulfillWithValue, dispatch, getState }
  ) => {
    const { organisation, web3, stripe } = getState() as RootState;

    try {
      const walletResponse = await connectWalletService();
      if (!walletResponse.error) {
        const networkResponse = await changeNetworkService(
          payload.networkDetails?.networkId || -1
        );
        if (!networkResponse.error) {
          const signResponse = await signMessageService(
            config.web3.REMOVAL_VERIFYING_MESSAGE,
            payload.networkDetails as INetworkType
          );
          if (!signResponse.error) {
            const apiPayload = {
              id: payload?.id,
              signature: signResponse.signature,
              organizationId: organisation.selectedOrganisation._id,
              network: getNetworkFromName(
                Number(payload.networkDetails?.chainId)
              ),
            };
            const apiResponse = await removeWalletService(
              apiPayload as IRemoveWalletPayloadDto
            );
            if (!(apiResponse as IResponseError).error) {
              dispatch(toggleModalShowRtk({ modalShow: false }));

              const primaryCreditCard = stripe.creditCards.find(
                (creditCard) =>
                  creditCard._id ===
                  (apiResponse as IRemoveWalletResponsePayloadDto).primaryWallet
              );

              const primaryCryptoWallet = web3.wallets.find(
                (wallet) =>
                  wallet._id ===
                  (apiResponse as IRemoveWalletResponsePayloadDto).primaryWallet
              );
              if (primaryCreditCard) {
                dispatch(
                  updateCreditCardPrimaryPayment({ id: primaryCreditCard._id })
                );
              } else if (primaryCryptoWallet) {
                dispatch(
                  updateCryptoWalletPrimaryPaymentRtk({
                    id: primaryCryptoWallet._id,
                  })
                );
              }
              dispatch(
                updateDefaultPaymentMethodRtk(
                  (primaryCryptoWallet ||
                    primaryCreditCard) as unknown as IOrgWallet
                )
              );

              return fulfillWithValue(payload!.id);
            }
            dispatch(
              addNotificationRtk({
                message: (apiResponse as IResponseError).message,
                timestamp: Date.now(),
                type: NotificationType.Error,
              })
            );
            return rejectWithValue(payload!.id);
          }
          dispatch(
            addNotificationRtk({
              message: signResponse.errorMessage,
              timestamp: Date.now(),
              type: NotificationType.Error,
            })
          );
          return rejectWithValue(payload!.id);
        }
        dispatch(
          addNotificationRtk({
            message: networkResponse.errorMessage,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue(payload!.id);
      }
      dispatch(
        addNotificationRtk({
          message: walletResponse.errorMessage,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue(payload!.id);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue(payload!.id);
    }
  }
);

export const createDomainRegistryThunk = createAsyncThunk(
  'web3/createDomainRegistry',
  async (
    payload: {
      name: string;
      organisationId: string;
    },
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const walletResponse = await connectWalletService();
      if (!walletResponse.error) {
        const networkResponse = await changeNetworkService(
          config.registry.REGISTRY_NETWORK
        );
        if (!networkResponse.error) {
          const createDomainRegistryResponse = await createDomainRegistry(
            payload.name
          );
          if (!createDomainRegistryResponse.error) {
            const { registryAddress } =
              createDomainRegistryResponse.tx.events[2].args;

            const updateDomainRegistryResponse =
              await updateOrgDomainRegistryService({
                id: payload.organisationId,
                registryAddress,
              });

            if (!updateDomainRegistryResponse.error) {
              dispatch(
                addNotificationRtk({
                  message: 'Domain registry created successfully',
                  timestamp: Date.now(),
                  type: NotificationType.Success,
                })
              );
              dispatch(fetchRegistryDetailsThunk(registryAddress));
              dispatch(toggleModalShowRtk({ modalShow: false }));
              return fulfillWithValue({});
            }
            dispatch(
              addNotificationRtk({
                message: updateDomainRegistryResponse.errorMessage,
                timestamp: Date.now(),
                type: NotificationType.Error,
              })
            );
            return rejectWithValue({});
          }
          dispatch(
            addNotificationRtk({
              message: createDomainRegistryResponse.errorMessage,
              timestamp: Date.now(),
              type: NotificationType.Error,
            })
          );
          return rejectWithValue({});
        }
        dispatch(
          addNotificationRtk({
            message: networkResponse.errorMessage,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      dispatch(
        addNotificationRtk({
          message: walletResponse.errorMessage,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const addDomainToRegistryThunk = createAsyncThunk(
  'web3/addDomainToRegistry',
  async (
    payload: IAddDomainRegistryPayload,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const walletResponse = await connectWalletService();
      if (!walletResponse.error) {
        const networkResponse = await changeNetworkService(
          config.registry.REGISTRY_NETWORK
        );
        if (!networkResponse.error) {
          const createDomainRegistryResponse = await addDomainToRegistry(
            payload.registry,
            payload.domain,
            payload.siteLink,
            payload.contentHash
          );
          if (!createDomainRegistryResponse.error) {
            dispatch(fetchRegistryDetailsThunk());
            dispatch(
              addNotificationRtk({
                message: 'Domain added successfully',
                timestamp: Date.now(),
                type: NotificationType.Success,
              })
            );
            dispatch(toggleModalShowRtk({ modalShow: false }));
            return fulfillWithValue({});
          }
          dispatch(
            addNotificationRtk({
              message: createDomainRegistryResponse.errorMessage,
              timestamp: Date.now(),
              type: NotificationType.Error,
            })
          );
          return rejectWithValue({});
        }
        dispatch(
          addNotificationRtk({
            message: networkResponse.errorMessage,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      dispatch(
        addNotificationRtk({
          message: walletResponse.errorMessage,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const addUpdateToRegistryThunk = createAsyncThunk(
  'web3/addUpdateToRegistryThunk',
  async (
    payload: IUpdaterPayload,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const walletResponse = await connectWalletService();
      if (!walletResponse.error) {
        const networkResponse = await changeNetworkService(
          config.registry.REGISTRY_NETWORK
        );
        if (!networkResponse.error) {
          const addUpdaterToRegistryResponse = await addUpdaterToRegistry(
            payload.registry,
            payload.address,
            payload.type
          );
          if (!addUpdaterToRegistryResponse.error) {
            dispatch(fetchRegistryDetailsThunk());
            dispatch(
              addNotificationRtk({
                message: `${payload.type} added successfully`,
                timestamp: Date.now(),
                type: NotificationType.Success,
              })
            );
            dispatch(toggleModalShowRtk({ modalShow: false }));
            return fulfillWithValue({});
          }
          dispatch(
            addNotificationRtk({
              message: addUpdaterToRegistryResponse.errorMessage,
              timestamp: Date.now(),
              type: NotificationType.Error,
            })
          );
          return rejectWithValue({});
        }
        dispatch(
          addNotificationRtk({
            message: networkResponse.errorMessage,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      dispatch(
        addNotificationRtk({
          message: walletResponse.errorMessage,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const removeUpdaterFromRegistryThunk = createAsyncThunk(
  'web3/removeUpdaterFromRegistry',
  async (
    payload: IUpdaterPayload,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const walletResponse = await connectWalletService();
      if (!walletResponse.error) {
        const networkResponse = await changeNetworkService(
          config.registry.REGISTRY_NETWORK
        );
        if (!networkResponse.error) {
          const removeUpdaterFromRegistryResponse =
            await removeUpdaterFromRegistry(
              payload.registry,
              payload.address,
              payload.type
            );
          if (!removeUpdaterFromRegistryResponse.error) {
            dispatch(fetchRegistryDetailsThunk());
            dispatch(
              addNotificationRtk({
                message: `${payload.type} removed successfully`,
                timestamp: Date.now(),
                type: NotificationType.Success,
              })
            );
            dispatch(toggleModalShowRtk({ modalShow: false }));
            return fulfillWithValue({});
          }
          dispatch(
            addNotificationRtk({
              message: removeUpdaterFromRegistryResponse.errorMessage,
              timestamp: Date.now(),
              type: NotificationType.Error,
            })
          );
          return rejectWithValue({});
        }
        dispatch(
          addNotificationRtk({
            message: networkResponse.errorMessage,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      dispatch(
        addNotificationRtk({
          message: walletResponse.errorMessage,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const authenticateWithWeb3 = createAsyncThunk(
  'auth/authenticateWithWeb3',
  async (
    payload: {
      isAccount: string;
      walletClient: any;
      mainnet: any;
      type: string;
      chainId: undefined | number;
    },
    { dispatch }
  ) => {
    try {
      const response = await web3NonceService();
      dispatch(toggleSignatureLoadingRtk(true));
      const message = new SiweMessage({
        domain: window.location.host,
        address: payload.isAccount,
        statement: 'Spheron Web3 Auth - Sign in with Ethereum to the app.',
        uri: config.urls.API_URL,
        version: '1',
        chainId: payload.chainId,
        nonce: response.nounce,
      });
      const preparedMessage = message.prepareMessage();
      if (payload.walletClient) {
        const signature = await payload.walletClient.signMessage({
          message: preparedMessage,
        });
        const referralCode = localStorage.getItem('referralCode') || '';
        dispatch(toggleSignatureLoadingRtk(false));
        dispatch(toggleSignupCallbackLoadingRtk(true));
        window.open(
          `${config.urls.API_URL}/auth/web3/callback?state=${
            payload.type
          }&address=${payload.isAccount}&message=${JSON.stringify(
            message
          )}&signature=${signature}&referralCode=${referralCode}`,
          '_blank'
        );
      }
      dispatch(toggleSignatureLoadingRtk(false));
      dispatch(
        addNotificationRtk({
          message: (response.message as Error)?.message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
    } catch (error) {
      dispatch(toggleSignatureLoadingRtk(false));
      Sentry.captureException(error);
      dispatch(
        addNotificationRtk({
          message: (error as Error)?.message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
    }
  }
);
