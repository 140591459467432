import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loader from '../../../../components/Compute/Loaders/loader';
import { isDarkTheme } from '../../../../redux/compute/theme/theme.utils';
import { RootState } from '../../../../redux/compute/store';

const ProviderLogin = () => {
  const params = useParams<{ provider: string }>();
  const theme = useSelector((state: RootState) => state.theme.theme);
  const signInWithProvider = async () => {
    import('../../../../config').then((config: any) => {
      const providerSignInUrl = `${config.default.urls.API_URL}/auth/${params.provider}/login`;
      window.open(providerSignInUrl, '_self');
    });
  };
  useEffect(() => {
    signInWithProvider();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`h-screen w-screen ${
        isDarkTheme(theme) && 'bg-feedback-default-bg'
      }`}
    >
      <Loader />
    </div>
  );
};

export default ProviderLogin;
