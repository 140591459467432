import React, { useEffect, useState } from 'react';
import { Switch, Tooltip, TextInput } from '@spheron/ui-library';
import { ReactComponent as InfoIcon } from '@spheron/ui-library/dist/assets/info-circle.svg';
import { ReactComponent as AddIcon } from '@spheron/ui-library/dist/assets/spheron-specific/add.svg';
import { ReactComponent as PlusCircleIcon } from '@spheron/ui-library/dist/assets/plus-circle.svg';
import { useDispatch, useSelector } from 'react-redux';
import { ISelectedCluster } from '../../../../../redux/compute/cluster/cluster.interfaces';
import { AppDispatch, RootState } from '../../../../../redux/compute/store';
import {
  setClusterDeploymentProgressRtk,
  setSelectedClusterRtk,
} from '../../../../../redux/compute/cluster/cluster.slice';
import {
  updateComputeStepRtk,
  updatePrivateCredentialNameRtk,
  updatePrivateCredentialPasswordRtk,
  updatePrivateCredentialUrlRtk,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.slice';
import { ScalingType } from '../../../../../redux/compute/instance/instance-creation/instance-creation.interfaces';
import { useQuery } from '../../../../../hooks/useQuery';
import { toggleModalShowRtk } from '../../../../../redux/compute/modal/modal.slice';

interface IProps {
  // eslint-disable-next-line no-unused-vars
  setCardClicked: (cardClicked: boolean) => void;
}

const ProvideImage = ({ setCardClicked }: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();
  const queryParams = useQuery();
  const preName = queryParams.get('name');
  const repository = queryParams.get('repository');
  const provider = queryParams.get('provider');
  const type = queryParams.get('type');
  const redeployCondition =
    provider === 'DOCKERHUB' && type === 'redeploy' && repository && preName;
  const [name, setName] = useState<string>(preName || '');
  const [image, setImage] = useState<string>(repository || '');
  const [tags, setTags] = useState<string>(redeployCondition ? '' : 'latest');
  const [isTagSave, setIsTagSave] = useState<boolean>(false);
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const [nameError, setNameError] = useState<boolean>(false);
  const [imageError, setImageError] = useState<boolean>(false);
  const [tagsError, setTagsError] = useState<boolean>(false);
  const [toggleOn, setToggleOn] = useState<boolean>(false);

  const selectedCluster: ISelectedCluster | undefined = useSelector(
    (state: RootState) => state.cluster.selectedCluster
  );
  const selectedTags = useSelector(
    (state: RootState) => state.cluster.selectedCluster?.tags
  );
  const selectedName = useSelector(
    (state: RootState) => state.cluster.selectedCluster?.name
  );
  const selectedRepository = useSelector(
    (state: RootState) => state.cluster.selectedCluster?.repository
  );
  const selectedOrganisation = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation
  );
  const credentialName = useSelector(
    (state: RootState) => state.instanceCreation.privateCredentialName
  );
  const credentialPassword = useSelector(
    (state: RootState) => state.instanceCreation.privateCredentialPassword
  );
  const selectedScalingType = useSelector(
    (state: RootState) => state.instanceCreation.clusterScaling
  );
  const credentialIcon = useSelector(
    (state: RootState) => state.instanceCreation.privateCredentialIcon
  );
  const deploymentType = useSelector(
    (state: RootState) => state.instanceCreation.deploymentType
  );
  const handleInputBlur = (
    field: string,
    // eslint-disable-next-line no-unused-vars
    setFieldError: (error: boolean) => void
  ) => {
    if (field === '') {
      setFieldError(true);
    } else {
      setFieldError(false);
    }
  };
  const handleInputChange = (fieldName: string, value: string) => {
    switch (fieldName) {
      case 'name':
        setName(value);
        setNameError(false);
        break;
      case 'image':
        setImage(value);
        setImageError(false);
        break;
      case 'tags':
        setTags(value);
        setTagsError(false);
        break;
      default:
        break;
    }
  };
  const inputContent = [
    {
      key: 1,
      value: name,
      handleChange: (e: string) => handleInputChange('name', e),
      placeholder: 'Cluster Name (ex: myCluster)',
      label: 'Name',
      disabled: !!redeployCondition,
      error: nameError,
      handleBlur: () => handleInputBlur(name, setNameError),
    },
    {
      key: 2,
      value: image,
      handleChange: (e: string) => handleInputChange('image', e),
      placeholder: 'Docker Image Name (ex: mongo)',
      label: ' Image',
      disabled: !!redeployCondition,
      error: imageError,
      handleBlur: () => handleInputBlur(image, setImageError),
    },
    {
      key: 3,
      value: tags,
      handleChange: (e: string) => handleInputChange('tags', e),
      placeholder: 'latest',
      label: 'Tag',
      disabled: false,
      error: tagsError,
      handleBlur: () => handleInputBlur(tags, setTagsError),
    },
  ];

  const handleSave = () => {
    if (name !== '' && image !== '' && tags !== '') {
      dispatchRtk(setClusterDeploymentProgressRtk(2));
      dispatchRtk(
        setSelectedClusterRtk({
          name,
          repository: image,
          tags,
          provider: 'DOCKERHUB',
        })
      );
      dispatchRtk(updateComputeStepRtk(3));
      setIsDisable(true);
      setCardClicked(true);
    }
  };

  useEffect(() => {
    if (redeployCondition) {
      setImage(repository);
      setName(preName);
      dispatchRtk(setClusterDeploymentProgressRtk(2));
      dispatchRtk(
        setSelectedClusterRtk({
          name: preName || '',
          repository,
          tags: '',
          provider,
        })
      );
      dispatchRtk(updateComputeStepRtk(3));
    }
  }, [
    provider,
    type,
    repository,
    preName,
    name,
    image,
    redeployCondition,
    dispatchRtk,
  ]);

  useEffect(() => {
    if (redeployCondition) {
      if (tags !== selectedTags || selectedTags === '') {
        setIsTagSave(true);
      } else {
        setIsTagSave(false);
      }
    }
  }, [tags, selectedTags, isTagSave, redeployCondition]);

  useEffect(() => {
    if (selectedName && selectedRepository && selectedTags) {
      if (
        name !== selectedName ||
        image !== selectedRepository ||
        tags !== selectedTags
      ) {
        setIsDisable(false);
      } else {
        setIsDisable(true);
      }
    }
  }, [name, image, tags, selectedName, selectedRepository, selectedTags]);

  useEffect(() => {
    setImage('');
    setName('');
    setTags('latest');
  }, [deploymentType]);

  useEffect(() => {
    if (selectedCluster && !redeployCondition) {
      const updatedTag =
        selectedCluster?.tags === '' ? 'latest' : selectedCluster?.tags;
      setImage(selectedCluster.repository);
      setName(selectedCluster.name);
      setTags(updatedTag);
    }
    return () => {
      setImage('');
      setName('');
      setTags('latest');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsDisable(false);
  }, [selectedOrganisation]);

  const isDisableCondition = redeployCondition
    ? name !== '' && image !== '' && tags !== '' && isTagSave && !isDisable
    : name !== '' && image !== '' && tags !== '' && !isDisable;

  const handleToggleChange = () => {
    setToggleOn(!toggleOn);
  };

  const handleRemoveCredential = () => {
    dispatchRtk(updatePrivateCredentialNameRtk(''));
    dispatchRtk(updatePrivateCredentialPasswordRtk(''));
    dispatchRtk(updatePrivateCredentialUrlRtk(''));
  };
  const handleCredentialClick = () => {
    if (credentialName === '') {
      dispatchRtk(
        toggleModalShowRtk({
          modalShow: true,
          modalType: 'privateDockerCredentials',
        })
      );
    }
  };

  const manualScalingCondition = selectedScalingType === ScalingType.MANUAL;
  return (
    <div>
      <div
        className="text-sm text-text-filterBadge flex items-center bg-bg-protocolHoverBg
         py-2 pl-3 pr-10 rounded-lg dark:bg-dark-base-bg w-fit
         dark:border dark:border-dark-base-border dark:text-text-darkGray"
      >
        <InfoIcon className="w-5 h-5 rotate-180" />
        <span className="ml-2 mr-1">
          {redeployCondition
            ? 'Please click on Next to save Tag Input'
            : 'Please click on Next to save the values in the fields below'}
        </span>
      </div>

      <div className="items-center flex mt-9">
        <span className="ml-1 text base font-normal">Private Registry</span>{' '}
        <div className="ml-3">
          <Switch
            toggleId="toggle-private"
            isChecked={toggleOn}
            handleChange={handleToggleChange}
          />
        </div>
      </div>

      <div className="flex sm:flex-row gap-6 flex-col mt-6">
        {inputContent.map((data) => (
          <div
            className={`ml-px mb-px transition-all duration-300 ${
              toggleOn ? 'w-1/5' : 'w-1/4'
            }`}
            key={data.key}
          >
            <TextInput
              inputSize="compact"
              label={data.label}
              type="text"
              placeholder={data.placeholder}
              value={data?.value}
              onChange={(value) => data.handleChange(value as string)}
              error={
                redeployCondition && data.key === 3 && tags === ''
                  ? true
                  : data.error
              }
              helpText={
                redeployCondition && data.key === 3 && tags === ''
                  ? 'This is a required field'
                  : ''
              }
              onBlur={
                redeployCondition && data.key === 3
                  ? undefined
                  : data.handleBlur
              }
            />
          </div>
        ))}
        {manualScalingCondition && (
          <>
            {toggleOn && (
              <div className="w-1/5 ml-px mb-px transition-all ease-in-out duration-300">
                <div
                  className="uppercase text-xs leading-3 font-semibold
                  text-form-text tracking-[1px] dark:text-dark-form-text
                  flex flex-row gap-1 items-center"
                >
                  CREDENTIALS
                  <Tooltip
                    position="top"
                    text="Use a credential to access private images."
                    type="float"
                    wrapText
                  >
                    <InfoIcon
                      className="w-4 h-4 cursor-pointer text-form-text dark:text-dark-form-text
                                opacity-80 hover:opacity-100"
                    />
                  </Tooltip>
                </div>
                <div
                  role="presentation"
                  onClick={handleCredentialClick}
                  className="flex items-center w-full px-4 py-2
                  cursor-pointer text-text-darkGray text-base font-normal 
                  shadow-searchShadow mt-1 border-searchBorder  
                  rounded-lg border-[1.5px] bg-base-bg dark:bg-dark-base-bg
                  dark:border-dark-form-border border-form-border
                  dark:hover:border-dark-form-hover hover:border-form-hover "
                >
                  {credentialName && credentialPassword && credentialIcon ? (
                    <div className="flex items-center w-full h-min">
                      <div className="flex flex-1 gap-2 items-center">
                        <img
                          src={credentialIcon}
                          alt="docker"
                          className="w-5 h-5"
                        />
                        <span
                          className="text-sm font-normal
                        text-base-heading-text-color dark:text-dark-base-heading-text-color"
                        >
                          {credentialName}
                        </span>
                      </div>
                      <PlusCircleIcon
                        className="w-5 h-5 rotate-45 hover:opacity-100 opacity-70"
                        onClick={handleRemoveCredential}
                      />
                    </div>
                  ) : (
                    <div className="flex items-center gap-2">
                      <AddIcon
                        className="text-action-primary-default dark:text-dark-action-primary-default
                        w-5 h-5"
                      />
                      <span
                        className="text-sm font-normal
                        text-base-heading-text-color dark:text-dark-base-heading-text-color"
                      >
                        Add Credentials
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        )}
        <div className="ml-0 mt-4">
          <button
            type="button"
            onClick={handleSave}
            disabled={isDisable || !isDisableCondition}
            className={`border border-form-selected bg-form-selected px-12 py-2 
                  shadow-searchShadow rounded-lg text-white text-base font-semibold leading-6
                  hover:bg-opacity-90 duration-75 ease-in" ${
                    isDisableCondition
                      ? 'cursor-pointer'
                      : 'opacity-50 cursor-not-allowed'
                  }`}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProvideImage;
