/* eslint-disable import/no-cycle */
import * as Sentry from '@sentry/react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  authorizeDeploymentService,
  cloneRepositoryService,
  deleteDeploymentService,
  getAllDeploymentProviders,
  getAllDeployments,
  getAllDeploymentsDetailsService,
  getAllSuccessfulSitePreviews,
  getDeployment,
  getRepositoryBranches,
  getThirdPartyRepoInfoService,
  startDeployment,
  stopDeploymentService,
  suggestFrameworkService,
  triggerSpecificDeploymentService,
} from './deployment.services';
import {
  DeploymentStatus,
  IDeployConfig,
  IDeploymentOrganisationRequestPayloadDto,
  IDeploymentsPagination,
  IFetchDeploymentRepoBranchPayloadDto,
  IGetRepoDetailsPayloadDto,
  ISetStartDeploymentPayloadDto,
  IStartCloneDeploymentPayloadDto,
  IStopDeploymentPayloadDto,
  ISuggestFrameworkPayloadDto,
} from './deployment.interfaces';
import { addNotificationRtk } from '../notification/notification.slice';
import { NotificationType } from '../notification/notification.interfaces';
import { mapDeploymentStatusToEnum } from './deployment.utils';
import { IDeployment } from '../combined-state.interface';
import config from '../../config';
import {
  fetchFilecoinPiningDetailsThunk,
  fetchIpfsPiningDetailsThunk,
} from '../pinning/pinning.thunks';
import { toggleModalShowRtk } from '../modal/modal.slice';
import { delay } from '../root-utils';
import {
  authorizedDeploymentRtk,
  loadDeploymentRtk,
  setCloneRepositoryTopicIdRtk,
  setClonedRepositoryDetailsRtk,
  setDeploymentBuildTimeRtk,
  setDeploymentConfigRtk,
  setDeploymentLinkRtk,
  setDeploymentStatusRtk,
  setProcessedLogsRtk,
  toggleDeploymentStateRtk,
} from './deployment.slice';

export const getDeploymentProviders = createAsyncThunk(
  'user/providers',
  async (payload: void, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await getAllDeploymentProviders();
      if (!response.error) {
        return fulfillWithValue(response?.providers || []);
      }
      return rejectWithValue([]);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue([]);
    }
  }
);

export const fetchAllDeploymentsThunk = createAsyncThunk(
  'deployments/fetchAllDeployments',
  async (payload: string, { getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const { deployments } = getState() as {
        deployments: { deploymentsPagination: IDeploymentsPagination };
      };
      const paginationDetails = deployments.deploymentsPagination;
      const limit = paginationDetails.numbersPerPage;
      const skip = 0;
      const response = await getAllDeployments(payload, skip, limit);
      return fulfillWithValue(response);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue([]);
    }
  }
);

export const fetchSuccessfulSitePreviewsThunk = createAsyncThunk(
  'deployments/fetchSuccessfulSitePreviews',
  async (payload: string, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getAllSuccessfulSitePreviews(payload);
      return fulfillWithValue(response);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue([]);
    }
  }
);

export const loadMoreDeploymentsThunk = createAsyncThunk(
  'deployments/loadMoreDeployments',
  async (
    payload: string | undefined,
    { getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const { project } = getState() as {
        project: { selectedProject: { _id: string } };
      };
      const { deployments } = getState() as {
        deployments: { deploymentsPagination: IDeploymentsPagination };
      };
      const projectId = project.selectedProject._id;
      const paginationDetails = deployments.deploymentsPagination;
      const limit = paginationDetails.numbersPerPage;
      const skip = limit * (paginationDetails.currentPage + 1);
      const response = await getAllDeployments(projectId, skip, limit);
      return fulfillWithValue(response);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue([]);
    }
  }
);

export const fetchAllDeploymentsDetailsThunk = createAsyncThunk(
  'deployments/fetchAllDeploymentsDetails',
  async (payload: string, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getAllDeploymentsDetailsService(payload);
      return fulfillWithValue(response);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({
        total: 0,
        successful: 0,
        failed: 0,
        pending: 0,
      });
    }
  }
);

export const fetchGithubRepoBranchesThunk = createAsyncThunk(
  'deployments/fetchGithubRepoBranches',
  async (
    payload: IFetchDeploymentRepoBranchPayloadDto,
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getRepositoryBranches(payload);
      if (response.success) {
        return fulfillWithValue(response);
      }
      return rejectWithValue({
        branches: [],
      });
    } catch (error) {
      return rejectWithValue({
        branches: [],
        error,
      });
    }
  }
);

export const setStartDeploymentThunk = createAsyncThunk(
  'deployments/setStartDeployment',
  async (
    payload: ISetStartDeploymentPayloadDto,
    { dispatch, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const deployment: any = {
        ...payload.deployment,
        configuration: payload.configuration,
      };
      const response: any = await startDeployment(deployment);

      if (response.success) {
        dispatch(setDeploymentConfigRtk(payload.deployment as IDeployConfig));
        window.open(
          // eslint-disable-next-line max-len
          `${window.location.origin}/app/org/${payload.orgId}/project/${response.projectId}/deployments/${response.deploymentId}`,
          '_self'
        );
        return fulfillWithValue(response);
      }
      dispatch(
        addNotificationRtk({
          message: response.message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const fetchDeploymentThunk = createAsyncThunk(
  'deployments/fetchDeployment',
  async (payload: any, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
      const result: any = await getDeployment(payload.deploymentId);

      dispatch(loadDeploymentRtk(result.deployment));

      const deploymentResult = result.deployment as unknown as IDeployment;
      const deployment: IDeployConfig = {
        url: deploymentResult.project.url,
        branch: deploymentResult.branch,
        createdAt: deploymentResult.createdAt,
        updatedAt: deploymentResult.updatedAt,
        protocol: deploymentResult.protocol,
        commitHash: deploymentResult.commitId,
        commitMessage: deploymentResult.commitMessage,
        provider: deploymentResult.project.provider,
        configuration: deploymentResult.configuration,
      };
      dispatch(setDeploymentConfigRtk(deployment));

      if (
        mapDeploymentStatusToEnum(result.deployment.status.toLowerCase()) ===
        DeploymentStatus.Pending
      ) {
        dispatch(
          setProcessedLogsRtk({
            logs: result?.liveLogs?.map((log: any) => JSON.parse(log)),
            topicId: result?.deployment.topic.trim(),
          })
        );
      } else {
        dispatch(
          setProcessedLogsRtk({
            logs: result?.deployment.logs,
            topicId: result?.deployment.topic.trim(),
          })
        );
      }

      const shouldSocketConnect =
        mapDeploymentStatusToEnum(result.deployment.status.toLowerCase()) ===
          DeploymentStatus.Pending ||
        mapDeploymentStatusToEnum(result.deployment.status.toLowerCase()) ===
          DeploymentStatus.Queued;

      dispatch(
        setDeploymentStatusRtk({
          status: mapDeploymentStatusToEnum(
            result.deployment.status.toLowerCase()
          ),
          topicId: result?.deployment.topic.trim(),
        })
      );
      if (!shouldSocketConnect) {
        dispatch(
          setDeploymentLinkRtk({
            link: result.deployment.sitePreview,
            topicId: result?.deployment.topic.trim(),
          })
        );
        // set the cid for ipfs deployments
        if (
          result.deployment.sitePreview &&
          (result.deployment.protocol === 'ipfs-filecoin' ||
            deployment?.protocol === 'ipfs')
        ) {
          const regexPattern = `https?://?(?:([^*]{59}))?.ipfs.${config.ipfs.GATEWAY_URL}`;
          const sep = new RegExp(regexPattern);

          const cid = result.deployment.sitePreview.split(sep)[1];

          if (result.deployment.protocol === 'ipfs-filecoin') {
            dispatch(fetchFilecoinPiningDetailsThunk(cid));
          } else if (deployment?.protocol === 'ipfs') {
            dispatch(fetchIpfsPiningDetailsThunk(cid));
          }
        }

        const buildMins = parseInt(`${result.deployment.buildTime / 60}`, 10);
        const buildSecs = parseInt(`${result.deployment.buildTime % 60}`, 10);

        dispatch(
          setDeploymentBuildTimeRtk({
            topicId: result?.deployment.topic.trim(),
            time: { min: buildMins, sec: buildSecs },
          })
        );
      }
      return fulfillWithValue(result.deployment);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const authorizeDeploymentThunk = createAsyncThunk(
  'deployments/authorizeDeployment',
  async (payload: string, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
      const result: any = await authorizeDeploymentService(payload);

      if (result.success) {
        dispatch(authorizedDeploymentRtk(true));
        dispatch(fetchDeploymentThunk({ deploymentId: payload }));
      } else if (result?.error) {
        dispatch(
          addNotificationRtk({
            message: result?.message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
      }
      return fulfillWithValue(result);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const cancelDeploymentThunk = createAsyncThunk(
  'deployments/cancelDeployment',
  async (
    payload: IStopDeploymentPayloadDto,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    const { organisation } = getState() as {
      organisation: { selectedOrganisation: { _id: string } };
    };
    const organizationId = organisation.selectedOrganisation._id;
    try {
      const result: any = await stopDeploymentService({
        deploymentId: payload.deploymentId,
        organizationId,
      });
      if (result.error) {
        dispatch(
          addNotificationRtk({
            message: (result as Error).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        dispatch(toggleModalShowRtk({ modalShow: false }));
      }
      if (result.canceled) {
        dispatch(
          toggleDeploymentStateRtk({
            state: false,
            deploymentId: payload.deploymentId,
            status: DeploymentStatus.Canceled,
          })
        );
        dispatch(toggleModalShowRtk({ modalShow: false }));
      }
      if (result.killing) {
        dispatch(
          toggleDeploymentStateRtk({
            state: false,
            deploymentId: payload.deploymentId,
            status: DeploymentStatus.Killing,
          })
        );
        dispatch(toggleModalShowRtk({ modalShow: false }));
      }
      if (!result.killing && !result.canceled) {
        dispatch(
          toggleDeploymentStateRtk({
            state: true,
            deploymentId: payload.deploymentId,
            status: DeploymentStatus.Killing,
          })
        );

        await delay(25000);
        dispatch(cancelDeploymentThunk(payload));
        dispatch(toggleModalShowRtk({ modalShow: false }));
      }
      dispatch(toggleModalShowRtk({ modalShow: false }));
      return fulfillWithValue(result);
    } catch (error) {
      Sentry.captureException(error);
      dispatch(toggleModalShowRtk({ modalShow: false }));
      return rejectWithValue([]);
    }
  }
);

export const frameworkSuggestionThunk = createAsyncThunk(
  'deployments/frameworkSuggestion',
  async (
    payload: ISuggestFrameworkPayloadDto,
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await suggestFrameworkService(payload);
      if (response) {
        return fulfillWithValue(response);
      }
      return rejectWithValue({
        suggestedFramework: '',
      });
    } catch (error) {
      return rejectWithValue({
        suggestedFramework: '',
        error,
      });
    }
  }
);

export const triggerSpecificDeploymentThunk = createAsyncThunk(
  'deployments/triggerSpecificDeployment',
  async (
    payload: IDeploymentOrganisationRequestPayloadDto,
    { dispatch, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const result: any = await triggerSpecificDeploymentService(payload);
      if (result.success) {
        window.open(
          // eslint-disable-next-line max-len
          `${window.location.origin}/app/org/${payload.organizationId}/project/${result.projectId}/deployments/${result.deploymentId}`,
          '_self'
        );
        dispatch(fetchDeploymentThunk({ deploymentId: result.deploymentId }));
      }
      if (!result.success) {
        dispatch(
          addNotificationRtk({
            message: (result as Error).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        dispatch(toggleModalShowRtk({ modalShow: false }));
        return rejectWithValue({});
      }
      dispatch(toggleModalShowRtk({ modalShow: false }));
      return fulfillWithValue({
        result,
        previousDeploymentId: payload.deploymentId,
      });
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const cloneRepositoryThunk = createAsyncThunk(
  'deployments/cloneRepository',
  async (
    payload: IStartCloneDeploymentPayloadDto,
    { dispatch, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const result: any = await cloneRepositoryService(payload.cloneConfig);
      if (result.success) {
        dispatch(setClonedRepositoryDetailsRtk(result.createdRepo));
        dispatch(
          addNotificationRtk({
            message: result.message,
            timestamp: Date.now(),
            type: NotificationType.Success,
          })
        );
        dispatch(setCloneRepositoryTopicIdRtk(result?.topicId));
        return fulfillWithValue(result);
      }
      if (result.error) {
        dispatch(
          addNotificationRtk({
            message: result?.message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        payload.callbackFn();
        return rejectWithValue(result);
      }
      return fulfillWithValue(result);
    } catch (error) {
      Sentry.captureException(error);
      payload.callbackFn();
      return rejectWithValue({});
    }
  }
);

export const getThirdPartyRepoInfoThunk = createAsyncThunk(
  'deployments/getThirdPartyRepoInfo',
  async (
    payload: IGetRepoDetailsPayloadDto,
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const result: any = await getThirdPartyRepoInfoService(payload);
      if (result.details) {
        return fulfillWithValue(result.details);
      }
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const deleteDeploymentThunk = createAsyncThunk(
  'deployments/deleteDeployment',
  async (
    payload: string,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    const { organisation } = getState() as {
      organisation: { selectedOrganisation: { _id: string } };
    };
    const selectedOrganisationId = organisation.selectedOrganisation._id;
    const { project } = getState() as {
      project: { selectedProject: { _id: string } };
    };
    const selectedProjectId = project.selectedProject._id;
    try {
      const response = await deleteDeploymentService(payload);
      if (response?.error) {
        dispatch(
          addNotificationRtk({
            message: response.message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue(response);
      }
      dispatch(fetchAllDeploymentsDetailsThunk(selectedProjectId));
      dispatch(toggleModalShowRtk({ modalShow: false }));
      dispatch(
        toggleModalShowRtk({
          modalShow: true,
          modalType: 'deletedResource',
          options: {
            resource: 'Deployment',
          },
        })
      );
      window.open(
        // eslint-disable-next-line max-len
        `${window.location.origin}/app/org/${selectedOrganisationId}/project/${selectedProjectId}/deployments`,
        '_self'
      );
      return fulfillWithValue(payload);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);
