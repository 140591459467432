/* eslint-disable import/no-cycle */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  ClusterType,
  ConfirmRedeploySwitchType,
  IAvailableInventoryResponse,
  IClusterRegionResponse,
  IInstanceCreationState,
  IInstancePlan,
  IInstancePlans,
  InstanceDeploymentType,
  IPLeaseType,
  IPricingTargetsResponse,
  LoadingType,
  ResourcesAvailable,
  ScalingType,
  ScalingTypeName,
} from './instance-creation.interfaces';
import {
  getAvailableInventoryThunk,
  getClusterRegionThunk,
  getCustomPlanThunk,
  getInstancePlanThunk,
  getInstanceUnitPricesThunk,
  getRecommendedPlanByIdThunk,
  loadMoreInstancePlanThunk,
} from './instance-creation.thunks';
import { IDiscount } from '../../combined-state.interface';

const initialState: IInstanceCreationState = {
  deploymentType: InstanceDeploymentType.COMPUTE,
  clusterRegions: [],
  getClusterRegionsLoading: false,
  clusterType: ClusterType.TEMPLATE,
  clusterRegion: null,
  clusterScaling: ScalingType.MANUAL,
  clusterScalingName: ScalingTypeName.MANUAL,
  instancePlanLoading: false,
  instancePlans: { totalCount: 0, akashMachineImages: [] },
  instancePlanPagination: {
    searchParams: '',
    currentPage: 0,
    numbersPerPage: 50,
  },
  instancePlanPaginationLoading: false,
  computeStep: 1,
  instanceValue: 1,
  customPlan: {} as IInstancePlan,
  updatedCustomPrice: 0,
  updatedStoragePrice: 0,
  updatedPersistentStoragePrice: 0,
  customCpu: '',
  customRam: '',
  customStorage: 20,
  customStorageInput: 20,
  customHddStorage: 0,
  customSsdStorage: 0,
  customNvmStorage: 0,
  customHddStorageInput: 0,
  customSsdStorageInput: 0,
  customNvmStorageInput: 0,
  customPersistentStorage: null,
  customStorageInputPrice: null,
  customHddInputPrice: null,
  customSsdInputPrice: null,
  customNvmInputPrice: null,
  defaultCustomPlansValue: [],
  priceLoading: false,
  mountPointValue: '',
  recommendedPlan: null,
  recommendedPlanLoading: false,
  priceLoadingType: LoadingType.CUSTOM_PLAN,
  confirmRedeploySwitch: false,
  confirmRedeploySwitchType: ConfirmRedeploySwitchType.DEFAULT,
  storageLoading: true,
  privateCredentialName: '',
  privateCredentialPassword: '',
  privateCredentialUrl: '',
  privateCredentialIcon: null,
  planSearch: null,
  planAvailable: ResourcesAvailable.UNKNOWN,
  storageAvailable: ResourcesAvailable.UNKNOWN,
  persistentStorageAvailable: ResourcesAvailable.UNKNOWN,
  discountCpuMemory: null,
  discountStorage: null,
  discountPersistentStorage: null,
  pricingTargetsLoading: false,
  availableInventoryLoading: false,
  unitPrices: [],
  availableInventory: [],
  multiserviceInstanceCreation: null,
  selectedService: null,
  ipLeaseType: IPLeaseType.SHARED,
  ipLeasePrice: 0,
  isDedicatedClicked: false,
};

const instanceCreationSlice = createSlice({
  name: 'instanceCreation',
  initialState,
  reducers: {
    setDeploymentType(state, action: PayloadAction<InstanceDeploymentType>) {
      state.deploymentType = action.payload;
    },
    chooseClusterType(state, action: PayloadAction<ClusterType>) {
      state.clusterType = action.payload;
    },
    selectClusterRegion(state, action: PayloadAction<string>) {
      state.clusterRegion = action.payload;
    },
    setScalingType(state, action: PayloadAction<ScalingType>) {
      state.clusterScaling = action.payload;
    },
    setScalingName(state, action: PayloadAction<ScalingTypeName>) {
      state.clusterScalingName = action.payload;
    },
    getInstancePlansEnd(state, action: PayloadAction<IInstancePlans>) {
      state.instancePlans = action.payload;
    },
    getMultiServiceInstancePlansEnd(
      state,
      action: PayloadAction<{ serviceId: string; value: IInstancePlans }>
    ) {
      const serviceState =
        state.multiserviceInstanceCreation![action.payload.serviceId];
      serviceState.instancePlans = action.payload.value;
      state.multiserviceInstanceCreation![action.payload.serviceId] = {
        ...serviceState!,
      };
    },
    setInstancePlanSearchParams(state, action: PayloadAction<string>) {
      state.instancePlanPagination = {
        ...state.instancePlanPagination,
        currentPage: 0,
        searchParams: action.payload,
      };
    },
    toggleInstancePlanLoading(state, action: PayloadAction<boolean>) {
      state.instancePlanLoading = action.payload;
    },
    updateComputeStep(state, action: PayloadAction<number>) {
      state.computeStep = action.payload;
    },
    updateCustomPlan(state, action: PayloadAction<IInstancePlan | {}>) {
      state.customPlan = action.payload as IInstancePlan;
    },
    updateMultiServiceCustomPlan(
      state,
      action: PayloadAction<{ serviceId: string; value: IInstancePlan | {} }>
    ) {
      const serviceState =
        state.multiserviceInstanceCreation![action.payload.serviceId];
      serviceState!.customPlan = action.payload.value as IInstancePlan;
      state.multiserviceInstanceCreation![action.payload.serviceId] = {
        ...serviceState!,
      };
    },
    setUpdatedCustomPrice(state, action: PayloadAction<number>) {
      state.updatedCustomPrice = action.payload;
    },
    setMultiServiceUpdatedCustomPrice(
      state,
      action: PayloadAction<{ serviceId: string; value: number }>
    ) {
      const serviceState =
        state.multiserviceInstanceCreation![action.payload.serviceId];
      serviceState!.updatedCustomPrice = action.payload.value;
      state.multiserviceInstanceCreation![action.payload.serviceId] = {
        ...serviceState!,
      };
    },
    updateCustomCpu(state, action: PayloadAction<string>) {
      state.customCpu = action.payload;
    },
    updateMultiServiceCustomCpu(
      state,
      action: PayloadAction<{ serviceId: string; value: string }>
    ) {
      const serviceState =
        state.multiserviceInstanceCreation![action.payload.serviceId];
      serviceState!.customCpu = action.payload.value;
      state.multiserviceInstanceCreation![action.payload.serviceId] = {
        ...serviceState!,
      };
    },
    updateCustomRam(state, action: PayloadAction<string>) {
      state.customRam = action.payload;
    },
    updateMultiServiceCustomRam(
      state,
      action: PayloadAction<{ serviceId: string; value: string }>
    ) {
      const serviceState =
        state.multiserviceInstanceCreation![action.payload.serviceId];
      serviceState!.customRam = action.payload.value;
      state.multiserviceInstanceCreation![action.payload.serviceId] = {
        ...serviceState!,
      };
    },
    setUpdatedStoragePrice(state, action: PayloadAction<number>) {
      state.updatedStoragePrice = action.payload;
    },
    setMultiServiceUpdatedStoragePrice(
      state,
      action: PayloadAction<{ serviceId: string; value: number }>
    ) {
      const serviceState =
        state.multiserviceInstanceCreation![action.payload.serviceId];
      serviceState!.updatedStoragePrice = action.payload.value;
      state.multiserviceInstanceCreation![action.payload.serviceId] = {
        ...serviceState!,
      };
    },
    setUpdatedPersistentPrice(state, action: PayloadAction<number>) {
      state.updatedPersistentStoragePrice = action.payload;
    },
    setMultiServiceUpdatedPersistentPrice(
      state,
      action: PayloadAction<{ serviceId: string; value: number }>
    ) {
      const serviceState =
        state.multiserviceInstanceCreation![action.payload.serviceId];
      serviceState!.updatedPersistentStoragePrice = action.payload.value;
      state.multiserviceInstanceCreation![action.payload.serviceId] = {
        ...serviceState!,
      };
    },
    updateCustomStorage(state, action: PayloadAction<number>) {
      state.customStorage = action.payload;
    },
    updateMultiServiceCustomStorage(
      state,
      action: PayloadAction<{ serviceId: string; value: number }>
    ) {
      const serviceState =
        state.multiserviceInstanceCreation![action.payload.serviceId];
      serviceState!.customStorage = action.payload.value;
      state.multiserviceInstanceCreation![action.payload.serviceId] = {
        ...serviceState!,
      };
    },
    updateCustomStorageInput(state, action: PayloadAction<number>) {
      state.customStorageInput = action.payload;
    },
    updateMultiServiceCustomStorageInput(
      state,
      action: PayloadAction<{ serviceId: string; value: number }>
    ) {
      const serviceState =
        state.multiserviceInstanceCreation![action.payload.serviceId];
      serviceState.customStorageInput = action.payload.value;
      state.multiserviceInstanceCreation![action.payload.serviceId] = {
        ...serviceState!,
      };
    },
    setInitialMultiserviceState(state, action: PayloadAction<any>) {
      state.multiserviceInstanceCreation = action.payload;
    },
    updateCustomHddStorage(state, action: PayloadAction<number>) {
      state.customHddStorage = action.payload;
    },
    updateMultiServiceCustomHddStorage(
      state,
      action: PayloadAction<{ serviceId: string; value: number }>
    ) {
      const serviceState =
        state.multiserviceInstanceCreation![action.payload.serviceId];
      serviceState.customHddStorage = action.payload.value;
      state.multiserviceInstanceCreation![action.payload.serviceId] = {
        ...serviceState!,
      };
    },
    updateCustomSsdStorage(state, action: PayloadAction<number>) {
      state.customSsdStorage = action.payload;
    },
    updateMultiServiceCustomSsdStorage(
      state,
      action: PayloadAction<{ serviceId: string; value: number }>
    ) {
      const serviceState =
        state.multiserviceInstanceCreation![action.payload.serviceId];
      serviceState.customSsdStorage = action.payload.value;
      state.multiserviceInstanceCreation![action.payload.serviceId] = {
        ...serviceState!,
      };
    },
    updateCustomNvmStorage(state, action: PayloadAction<number>) {
      state.customNvmStorage = action.payload;
    },
    updateMultiServiceCustomNvmStorage(
      state,
      action: PayloadAction<{ serviceId: string; value: number }>
    ) {
      const serviceState =
        state.multiserviceInstanceCreation![action.payload.serviceId];
      serviceState.customNvmStorage = action.payload.value;
      state.multiserviceInstanceCreation![action.payload.serviceId] = {
        ...serviceState!,
      };
    },
    updateCustomHddStorageInput(state, action: PayloadAction<number>) {
      state.customHddStorageInput = action.payload;
    },
    updateMultiServiceCustomHddStorageInput(
      state,
      action: PayloadAction<{ serviceId: string; value: number }>
    ) {
      const serviceState =
        state.multiserviceInstanceCreation![action.payload.serviceId];
      serviceState.customHddStorageInput = action.payload.value;
      state.multiserviceInstanceCreation![action.payload.serviceId] = {
        ...serviceState!,
      };
    },
    updateCustomSsdStorageInput(state, action: PayloadAction<number>) {
      state.customSsdStorageInput = action.payload;
    },
    updateMultiServiceCustomSsdStorageInput(
      state,
      action: PayloadAction<{ serviceId: string; value: number }>
    ) {
      const serviceState =
        state.multiserviceInstanceCreation![action.payload.serviceId];
      serviceState.customSsdStorageInput = action.payload.value;
      state.multiserviceInstanceCreation![action.payload.serviceId] = {
        ...serviceState!,
      };
    },
    updateCustomNvmStorageInput(state, action: PayloadAction<number>) {
      state.customNvmStorageInput = action.payload;
    },
    updateMultiServiceCustomNvmStorageInput(
      state,
      action: PayloadAction<{ serviceId: string; value: number }>
    ) {
      const serviceState =
        state.multiserviceInstanceCreation![action.payload.serviceId];
      serviceState.customNvmStorageInput = action.payload.value;
      state.multiserviceInstanceCreation![action.payload.serviceId] = {
        ...serviceState!,
      };
    },
    updateCustomPersistentStorage(state, action: PayloadAction<string>) {
      state.customPersistentStorage = action.payload;
    },
    updateMultiServiceCustomPersistentStorage(
      state,
      action: PayloadAction<{ serviceId: string; value: string }>
    ) {
      const serviceState =
        state.multiserviceInstanceCreation![action.payload.serviceId];
      serviceState.customPersistentStorage = action.payload.value;
      state.multiserviceInstanceCreation![action.payload.serviceId] = {
        ...serviceState!,
      };
    },
    updateCustomStorageInputPrice(state, action: PayloadAction<string>) {
      state.customStorageInputPrice = action.payload;
    },
    updateMultiServiceCustomStorageInputPrice(
      state,
      action: PayloadAction<{ serviceId: string; value: string }>
    ) {
      const serviceState =
        state.multiserviceInstanceCreation![action.payload.serviceId];
      serviceState.customStorageInputPrice = action.payload.value;
      state.multiserviceInstanceCreation![action.payload.serviceId] = {
        ...serviceState!,
      };
    },
    updateCustomHddStorageInputPrice(state, action: PayloadAction<string>) {
      state.customHddInputPrice = action.payload;
    },
    updateMultiServiceCustomHddStorageInputPrice(
      state,
      action: PayloadAction<{ serviceId: string; value: string }>
    ) {
      const serviceState =
        state.multiserviceInstanceCreation![action.payload.serviceId];
      serviceState.customHddInputPrice = action.payload.value;
      state.multiserviceInstanceCreation![action.payload.serviceId] = {
        ...serviceState!,
      };
    },
    updateCustomSsdStorageInputPrice(state, action: PayloadAction<string>) {
      state.customSsdInputPrice = action.payload;
    },
    updateMultiServiceCustomSsdStorageInputPrice(
      state,
      action: PayloadAction<{ serviceId: string; value: string }>
    ) {
      const serviceState =
        state.multiserviceInstanceCreation![action.payload.serviceId];
      serviceState.customSsdInputPrice = action.payload.value;
      state.multiserviceInstanceCreation![action.payload.serviceId] = {
        ...serviceState!,
      };
    },
    updateCustomNvmStorageInputPrice(state, action: PayloadAction<string>) {
      state.customNvmInputPrice = action.payload;
    },
    updateMultiServiceCustomNvmStorageInputPrice(
      state,
      action: PayloadAction<{ serviceId: string; value: string }>
    ) {
      const serviceState =
        state.multiserviceInstanceCreation![action.payload.serviceId];
      serviceState.customNvmInputPrice = action.payload.value;
      state.multiserviceInstanceCreation![action.payload.serviceId] = {
        ...serviceState!,
      };
    },
    updatePlanAvailability(state, action: PayloadAction<ResourcesAvailable>) {
      state.planAvailable = action.payload;
    },
    updateStorageAvailability(
      state,
      action: PayloadAction<ResourcesAvailable>
    ) {
      state.storageAvailable = action.payload;
    },
    updatePersistentStorageAvailability(
      state,
      action: PayloadAction<ResourcesAvailable>
    ) {
      state.persistentStorageAvailable = action.payload;
    },
    resetPersistentValues(state) {
      state.customPersistentStorage = null;
      state.customHddStorage = 0;
      state.customSsdStorage = 0;
      state.customNvmStorage = 0;
      state.customHddStorageInput = 0;
      state.customSsdStorageInput = 0;
      state.customNvmStorageInput = 0;
      state.customHddInputPrice = '';
      state.customSsdInputPrice = '';
      state.customNvmInputPrice = '';
      state.updatedPersistentStoragePrice = 0;
    },
    resetMultiservicePersistentValues(state, action: PayloadAction<string>) {
      const serviceState = state.multiserviceInstanceCreation![action.payload];
      serviceState.customPersistentStorage = null;
      serviceState.customHddStorage = 0;
      serviceState.customSsdStorage = 0;
      serviceState.customNvmStorage = 0;
      serviceState.customHddStorageInput = 0;
      serviceState.customSsdStorageInput = 0;
      serviceState.customNvmStorageInput = 0;
      serviceState.customHddInputPrice = '';
      serviceState.customSsdInputPrice = '';
      serviceState.customNvmInputPrice = '';
      serviceState.updatedPersistentStoragePrice = 0;
      state.multiserviceInstanceCreation![action.payload] = {
        ...serviceState!,
      };

      state.customPersistentStorage = null;
      state.customHddStorage = 0;
      state.customSsdStorage = 0;
      state.customNvmStorage = 0;
      state.customHddStorageInput = 0;
      state.customSsdStorageInput = 0;
      state.customNvmStorageInput = 0;
      state.customHddInputPrice = '';
      state.customSsdInputPrice = '';
      state.customNvmInputPrice = '';
      state.updatedPersistentStoragePrice = 0;
    },
    defaultCustomPlansValue(state, action: PayloadAction<any>) {
      state.defaultCustomPlansValue = action.payload;
    },
    togglePriceLoading(
      state,
      action: PayloadAction<{
        priceLoading: boolean;
        priceLoadingType: LoadingType;
      }>
    ) {
      state.priceLoading = action.payload.priceLoading;
      state.priceLoadingType = action.payload.priceLoadingType;
    },
    toggleMultiServicePriceLoading(
      state,
      action: PayloadAction<{
        serviceId: string;
        priceLoading: boolean;
        priceLoadingType: LoadingType;
      }>
    ) {
      const serviceState =
        state.multiserviceInstanceCreation![action.payload.serviceId];
      serviceState.priceLoading = action.payload.priceLoading;
      serviceState.priceLoadingType = action.payload.priceLoadingType;
      state.multiserviceInstanceCreation![action.payload.serviceId] = {
        ...serviceState!,
      };
    },
    updateMountPointValue(state, action: PayloadAction<string>) {
      state.mountPointValue = action.payload;
    },
    updateMultiServiceMountPointValue(
      state,
      action: PayloadAction<{ serviceId: string; value: string }>
    ) {
      const serviceState =
        state.multiserviceInstanceCreation![action.payload.serviceId];
      serviceState.mountPointValue = action.payload.value;
      state.multiserviceInstanceCreation![action.payload.serviceId] = {
        ...serviceState!,
      };
    },
    updateInstanceValue(state, action: PayloadAction<number>) {
      state.instanceValue = action.payload;
    },
    updateMultiServiceInstanceValue(
      state,
      action: PayloadAction<{ serviceId: string; value: number }>
    ) {
      const serviceState =
        state.multiserviceInstanceCreation![action.payload.serviceId];
      serviceState.serviceCount = action.payload.value;
      state.multiserviceInstanceCreation![action.payload.serviceId] = {
        ...serviceState!,
      };
    },
    toggleConfirmRedeploySwitch(
      state,
      action: PayloadAction<{ state: boolean; type: ConfirmRedeploySwitchType }>
    ) {
      state.confirmRedeploySwitch = action.payload.state;
      state.confirmRedeploySwitchType = action.payload.type;
    },
    setRecommendedPlan(state, action: PayloadAction<IInstancePlan | null>) {
      state.recommendedPlan = action.payload;
    },
    setMultiServiceRecommendedPlan(
      state,
      action: PayloadAction<{ serviceId: string; value: IInstancePlan | null }>
    ) {
      const serviceState =
        state.multiserviceInstanceCreation![action.payload.serviceId];
      serviceState.recommendedPlan = action.payload.value;
      state.multiserviceInstanceCreation![action.payload.serviceId] = {
        ...serviceState!,
      };
    },
    toggleRecommendedPlanLoading(state, action: PayloadAction<boolean>) {
      state.recommendedPlanLoading = action.payload;
    },
    toggleMultiServiceRecommendedPlanLoading(
      state,
      action: PayloadAction<{ serviceId: string; value: boolean }>
    ) {
      const serviceState =
        state.multiserviceInstanceCreation![action.payload.serviceId];
      serviceState.recommendedPlanLoading = action.payload.value;
      state.multiserviceInstanceCreation![action.payload.serviceId] = {
        ...serviceState!,
      };
    },
    updateLoadMoreCurrentPage(state, action: PayloadAction<number>) {
      state.instancePlanPagination.currentPage = action.payload;
    },
    updatePrivateCredentialName(state, action: PayloadAction<string>) {
      state.privateCredentialName = action.payload;
    },
    updatePrivateCredentialPassword(state, action: PayloadAction<string>) {
      state.privateCredentialPassword = action.payload;
    },
    updatePrivateCredentialUrl(state, action: PayloadAction<string>) {
      state.privateCredentialUrl = action.payload;
    },
    updatePrivateCredentialIcon(state, action: PayloadAction<string>) {
      state.privateCredentialIcon = action.payload;
    },
    updatePlanSearch(state, action: PayloadAction<string | null>) {
      state.planSearch = action.payload;
    },
    setSelectedService(state, action: PayloadAction<number | string>) {
      state.selectedService = action.payload;
    },
    resetInstanceValues: (state) => {
      state.customPlan = {} as IInstancePlan;
      state.updatedCustomPrice = 0;
      state.updatedStoragePrice = 0;
      state.updatedPersistentStoragePrice = 0;
      state.customCpu = '';
      state.customRam = '';
      state.customStorage = 20;
      state.customStorageInput = 20;
      state.customHddStorage = 0;
      state.customSsdStorage = 0;
      state.customNvmStorage = 0;
      state.customHddStorageInput = 0;
      state.customSsdStorageInput = 0;
      state.customNvmStorageInput = 0;
      state.customPersistentStorage = null;
      state.customStorageInputPrice = null;
      state.customHddInputPrice = null;
      state.customSsdInputPrice = null;
      state.customNvmInputPrice = null;
      state.mountPointValue = '';
      state.recommendedPlan = null;
      state.discountCpuMemory = null;
      state.discountStorage = null;
      state.discountPersistentStorage = null;
    },
    setInstanceDiscounts: (
      state,
      action: PayloadAction<{
        cpuMemory: IDiscount;
        storage: IDiscount;
        peristentStorage: IDiscount;
      }>
    ) => {
      state.discountCpuMemory = action.payload.cpuMemory;
      state.discountStorage = action.payload.storage;
      state.discountPersistentStorage = action.payload.peristentStorage;
    },
    setIpLeaseType(state, action: PayloadAction<IPLeaseType>) {
      state.ipLeaseType = action.payload;
    },
    setMultiServicesIpLeaseType(
      state,
      action: PayloadAction<{ serviceId: string; value: IPLeaseType }>
    ) {
      const serviceState =
        state.multiserviceInstanceCreation![action.payload.serviceId];
      serviceState.ipLeaseType = action.payload.value;
      state.multiserviceInstanceCreation![action.payload.serviceId] = {
        ...serviceState!,
      };
    },
    setIsDedicatedClicked(state, action: PayloadAction<boolean>) {
      state.isDedicatedClicked = action.payload;
    },
    setMultiServicesIsDedicatedClicked(
      state,
      action: PayloadAction<{ serviceId: string; value: boolean }>
    ) {
      const serviceState =
        state.multiserviceInstanceCreation![action.payload.serviceId];
      serviceState.isDedicatedClicked = action.payload.value;
      state.multiserviceInstanceCreation![action.payload.serviceId] = {
        ...serviceState!,
      };
    },
    setIpLeasePrice(state, action: PayloadAction<number>) {
      state.ipLeasePrice = action.payload;
    },
    setMultiServiceIpLeasePrice(
      state,
      action: PayloadAction<{ serviceId: string; value: number }>
    ) {
      const serviceState =
        state.multiserviceInstanceCreation![action.payload.serviceId];
      serviceState!.ipLeasePrice = action.payload.value;
      state.multiserviceInstanceCreation![action.payload.serviceId] = {
        ...serviceState!,
      };
    },
  },
  extraReducers: (builder) => {
    // GET CLUSTER REGION
    builder.addCase(getClusterRegionThunk.pending, (state) => {
      state.getClusterRegionsLoading = true;
    });
    builder.addCase(
      getClusterRegionThunk.fulfilled,
      (state, action: PayloadAction<IClusterRegionResponse>) => {
        state.getClusterRegionsLoading = false;
        state.clusterRegions = action.payload.regions;
      }
    );
    builder.addCase(getClusterRegionThunk.rejected, (state) => {
      state.getClusterRegionsLoading = false;
    });

    builder.addCase(getInstancePlanThunk.pending, (state) => {
      state.instancePlanLoading = true;
      state.instancePlans.totalCount = 0;
      state.instancePlans.akashMachineImages = [];
    });
    builder.addCase(
      getInstancePlanThunk.fulfilled,
      (state, action: PayloadAction<IInstancePlans>) => {
        state.instancePlans = action.payload;
        state.instancePlanLoading = false;
      }
    );

    builder.addCase(loadMoreInstancePlanThunk.pending, (state) => {
      state.instancePlanPaginationLoading = true;
    });
    builder.addCase(
      loadMoreInstancePlanThunk.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.instancePlans = {
          totalCount: action.payload.totalCount,
          akashMachineImages: [
            ...state.instancePlans.akashMachineImages,
            ...action.payload.akashMachineImages,
          ],
        };
        state.instancePlanPagination = {
          ...state.instancePlanPagination,
          currentPage: state.instancePlanPagination.currentPage + 1,
        };
        state.instancePlanPaginationLoading = false;
      }
    );

    builder.addCase(getCustomPlanThunk.pending, (state) => {
      state.storageLoading = true;
    });
    builder.addCase(getCustomPlanThunk.fulfilled, (state) => {
      state.storageLoading = false;
    });
    builder.addCase(getCustomPlanThunk.rejected, (state) => {
      state.storageLoading = false;
    });

    builder.addCase(getRecommendedPlanByIdThunk.pending, (state) => {
      state.recommendedPlanLoading = true;
    });
    builder.addCase(
      getRecommendedPlanByIdThunk.fulfilled,
      (state, action: PayloadAction<IInstancePlan>) => {
        state.recommendedPlan = action.payload;
        state.recommendedPlanLoading = false;
      }
    );
    builder.addCase(getRecommendedPlanByIdThunk.rejected, (state) => {
      state.recommendedPlanLoading = false;
    });

    builder.addCase(getInstanceUnitPricesThunk.pending, (state) => {
      state.pricingTargetsLoading = true;
    });
    builder.addCase(
      getInstanceUnitPricesThunk.fulfilled,
      (state, action: PayloadAction<IPricingTargetsResponse>) => {
        state.unitPrices = action.payload.unitPrices;
        state.pricingTargetsLoading = false;
      }
    );
    builder.addCase(getInstanceUnitPricesThunk.rejected, (state) => {
      state.pricingTargetsLoading = false;
    });

    builder.addCase(getAvailableInventoryThunk.pending, (state) => {
      state.availableInventoryLoading = true;
    });
    builder.addCase(
      getAvailableInventoryThunk.fulfilled,
      (state, action: PayloadAction<IAvailableInventoryResponse>) => {
        state.availableInventory = action.payload.availableInventory;
        state.availableInventoryLoading = false;
      }
    );
    builder.addCase(getAvailableInventoryThunk.rejected, (state) => {
      state.availableInventoryLoading = false;
    });
  },
});

export const {
  setDeploymentType: setDeploymentTypeRtk,
  chooseClusterType: chooseClusterTypeRtk,
  selectClusterRegion: selectClusterRegionRtk,
  setScalingType: setScalingTypeRtk,
  setScalingName: setScalingNameRtk,
  getInstancePlansEnd: getInstancePlansEndRtk,
  getMultiServiceInstancePlansEnd,
  setInstancePlanSearchParams: setInstancePlanSearchParamsRtk,
  toggleInstancePlanLoading: toggleInstancePlanLoadingRtk,
  updateComputeStep: updateComputeStepRtk,
  updateCustomPlan: updateCustomPlanRtk,
  updateMultiServiceCustomPlan,
  setUpdatedCustomPrice: setUpdatedCustomPriceRtk,
  setMultiServiceUpdatedCustomPrice,
  updateCustomCpu: updateCustomCpuRtk,
  updateMultiServiceCustomCpu,
  updateCustomRam: updateCustomRamRtk,
  updateMultiServiceCustomRam,
  setUpdatedStoragePrice: setUpdatedStoragePriceRtk,
  setMultiServiceUpdatedStoragePrice,
  setUpdatedPersistentPrice: setUpdatedPersistentPriceRtk,
  setMultiServiceUpdatedPersistentPrice,
  updateCustomStorageInput: updateCustomStorageInputRtk,
  updateMultiServiceCustomStorageInput,
  updateCustomStorage: updateCustomStorageRtk,
  updateMultiServiceCustomStorage,
  updateCustomHddStorage: updateCustomHddStorageRtk,
  updateMultiServiceCustomHddStorage,
  updateCustomSsdStorage: updateCustomSsdStorageRtk,
  updateMultiServiceCustomSsdStorage,
  updateCustomNvmStorage: updateCustomNvmStorageRtk,
  updateMultiServiceCustomNvmStorage,
  updateCustomHddStorageInput: updateCustomHddStorageInputRtk,
  updateMultiServiceCustomHddStorageInput,
  updateCustomSsdStorageInput: updateCustomSsdStorageInputRtk,
  updateMultiServiceCustomSsdStorageInput,
  updateCustomNvmStorageInput: updateCustomNvmStorageInputRtk,
  updateMultiServiceCustomNvmStorageInput,
  updateCustomPersistentStorage: updateCustomPersistentStorageRtk,
  updateMultiServiceCustomPersistentStorage,
  updateCustomStorageInputPrice: updateCustomStorageInputPriceRtk,
  updateMultiServiceCustomStorageInputPrice,
  updateCustomHddStorageInputPrice: updateCustomHddStorageInputPriceRtk,
  updateMultiServiceCustomHddStorageInputPrice,
  updateCustomSsdStorageInputPrice: updateCustomSsdStorageInputPriceRtk,
  updateMultiServiceCustomSsdStorageInputPrice,
  updateCustomNvmStorageInputPrice: updateCustomNvmStorageInputPriceRtk,
  updateMultiServiceCustomNvmStorageInputPrice,
  defaultCustomPlansValue: defaultCustomPlansValueRtk,
  togglePriceLoading: togglePriceLoadingRtk,
  toggleMultiServicePriceLoading,
  updateMountPointValue: updateMountPointValueRtk,
  updateMultiServiceMountPointValue,
  updateInstanceValue: updateInstanceValueRtk,
  updateMultiServiceInstanceValue,
  resetPersistentValues: resetPersistentValuesRtk,
  resetMultiservicePersistentValues,
  toggleConfirmRedeploySwitch: toggleConfirmRedeploySwitchRtk,
  toggleRecommendedPlanLoading: toggleRecommendedPlanLoadingRtk,
  toggleMultiServiceRecommendedPlanLoading,
  updateLoadMoreCurrentPage: updateLoadMoreCurrentPageRtk,
  updatePrivateCredentialName: updatePrivateCredentialNameRtk,
  updatePrivateCredentialPassword: updatePrivateCredentialPasswordRtk,
  updatePrivateCredentialUrl: updatePrivateCredentialUrlRtk,
  updatePrivateCredentialIcon: updatePrivateCredentialIconRtk,
  updatePlanSearch: updatePlanSearchRtk,
  setRecommendedPlan: setRecommendedPlanRtk,
  setMultiServiceRecommendedPlan,
  updatePlanAvailability: updatePlanAvailabilityRtk,
  updateStorageAvailability: updateStorageAvailabilityRtk,
  updatePersistentStorageAvailability: updatePersistentStorageAvailabilityRtk,
  resetInstanceValues,
  setInstanceDiscounts,
  setSelectedService,
  setInitialMultiserviceState,
  setMultiServicesIpLeaseType,
  setIpLeaseType,
  setIpLeasePrice,
  setMultiServiceIpLeasePrice,
  setMultiServicesIsDedicatedClicked,
  setIsDedicatedClicked,
} = instanceCreationSlice.actions;
export const { reducer: instanceCreationRTKReducer } = instanceCreationSlice;
