import React from 'react';
import { BounceLoader } from 'react-spinners';

interface IProps {
  title: string;
  icon?: JSX.Element;
  className?: string;
  loading?: boolean;
  handleClick?: () => void;
  ref?: React.RefObject<HTMLButtonElement>;
  disabled?: boolean;
}

const OutlinedPrimaryButton = ({
  title,
  icon,
  className,
  handleClick,
  loading,
  ref,
  disabled,
}: IProps) => (
  <button
    className={`flex justify-around items-center  transition
    text-action-primary-default border-action-primary-default border
    dark:border-dark-base-border dark:bg-opacity-10 border-solid font-semibold rounded-md 
    
    ${!!className && className}`}
    type="button"
    disabled={disabled}
    onClick={handleClick}
    ref={ref}
  >
    {icon && <div className="flex items-center mr-2">{icon}</div>}
    {title}
    {loading && (
      <span style={{ marginLeft: 8, display: 'flex' }}>
        <BounceLoader size={20} color="#2B66F7" loading />
      </span>
    )}
  </button>
);

OutlinedPrimaryButton.defaultProps = {
  className: 'bg-transparent',
  icon: null,
  handleClick: () => {},
  ref: React.createRef(),
  loading: false,
  disabled: false,
};

export default OutlinedPrimaryButton;
