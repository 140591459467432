import React from 'react';
import Skeleton from 'react-loading-skeleton';

function PlanTileLoading() {
  return (
    <div
      role="button"
      className="rounded-lg px-4 py-3 w-full min-w-[232px] cursor-pointer relative
      border border-base-border dark:border-dark-base-border
      bg-base-bg dark:bg-dark-base-bg"
    >
      <Skeleton
        height={23}
        width={200}
        duration={2}
        containerClassName="flex"
      />
      <div className="mt-2 flex items-center">
        <Skeleton
          height={28}
          width={100}
          duration={2}
          containerClassName="flex"
        />
        <span className="ml-3">
          <Skeleton
            height={28}
            width={65}
            duration={2}
            containerClassName="flex"
          />
        </span>
      </div>
      <div className="mt-3">
        <Skeleton
          height={20}
          width={140}
          duration={2}
          containerClassName="flex"
        />
      </div>
      <ul className="mt-3">
        <li>
          <Skeleton
            height={16}
            width={100}
            duration={2}
            containerClassName="flex"
          />
        </li>
        <li className="mt-2">
          <Skeleton
            height={16}
            width={100}
            duration={2}
            containerClassName="flex"
          />
        </li>
      </ul>
    </div>
  );
}

export default PlanTileLoading;
