import React from 'react';
import { OptionType, AttachedPaymentMethodCard } from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import { updateDefaultPaymentThunk } from '../../../redux/compute/organisation/organisation.thunks';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import { removeCreditCardThunk } from '../../../redux/compute/stripe/stripe.thunks';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import { getPaymentLogo } from '../../../redux/stripe/stripe.utils';
import { ICreditCard } from '../../../redux/combined-state.interface';

interface IProps {
  disabled: boolean;
  creditCard: ICreditCard;
}

const CardInfo = ({ disabled, creditCard }: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();
  const selectedOrganisationId = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation._id
  );

  const dropdownOptions = [
    {
      id: 2,
      label: 'Remove',
      value: 'Remove',
      optionType: 'primary' as OptionType,
      handleClick: () =>
        dispatchRtk(
          toggleModalShowRtk({
            modalShow: true,
            modalType: 'deleteResourceConfirm',
            options: {
              resource: 'Credit Card',
              handleClick: () =>
                dispatchRtk(
                  removeCreditCardThunk({
                    id: creditCard._id,
                    organizationId: selectedOrganisationId,
                  })
                ),
            },
          })
        ),
    },
  ];

  const dropdownOptionWithDisable = !creditCard.primary
    ? [
        ...dropdownOptions,
        {
          id: 5,
          label: 'Make as Primary',
          value: 'Make as Primary',
          handleClick: () =>
            dispatchRtk(
              updateDefaultPaymentThunk({
                organisationId: selectedOrganisationId,
                newPaymentMethodId: creditCard._id,
              })
            ),
          optionType: 'primary' as OptionType,
        },
      ]
    : dropdownOptions;

  return (
    <AttachedPaymentMethodCard
      id={creditCard._id}
      cardType="card"
      icon={
        <img
          src={getPaymentLogo(creditCard.details?.info?.brand || '')}
          alt="Visa icon"
          className="w-9 h-6"
        />
      }
      isPrimary={creditCard.primary}
      title={`${creditCard.details?.info?.brand || ''} **** ${
        creditCard.details?.info?.last4 || ''
      }`}
      subtitle={`Expiry ${creditCard.details?.info?.expiration_month}/${creditCard.details?.info?.expiration_year}`}
      hasDropdown
      dropdownOptions={disabled ? [] : dropdownOptionWithDisable}
      hasWalletSwitchDropdown={false}
      walletDropdownOptions={[]}
    />
  );
};

export default CardInfo;
