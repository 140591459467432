import React, { useRef, useState } from 'react';
import { Badge, Dropdown, OptionType } from '@spheron/ui-library';
import Skeleton from 'react-loading-skeleton';
import { ReactComponent as Chevron } from '@spheron/ui-library/dist/assets/arrow-right-3.svg';
import {
  useAppSelector as useSelectorRtk,
  useAppDispatch as useDispatchRtk,
} from '../../redux/rtk-context';
import { ReactComponent as TripleDot } from '../../assets/compute/icons/triple-light-dot.svg';
import { ReactComponent as CreditIcon } from '../../assets/compute/icons/credit-icon.svg';
import { withLoader } from '../../redux/root-utils';
import SelectPaymentMethodCard from '../Compute/Dropdown/select-payment-method-card';
import { updateDefaultPaymentThunk } from '../../redux/organisation/organisation.thunks';
import { RootState } from '../../redux/rtk-store';
import { ISpheronWallet } from '../../redux/organisation/organisation.interfaces';
import UseOnClickOutside from '../../hooks/useOnClickOutside';

interface IProps {
  loading: boolean;
  showDropdown: boolean;
  disabled: boolean;
}

const SpheronWallet = ({ loading, showDropdown, disabled }: IProps) => {
  const dispatchRtk = useDispatchRtk();
  const selectedOrganisationId = useSelectorRtk(
    (state: RootState) => state.organisation.selectedOrganisation._id
  );

  const spheronWallet = useSelectorRtk(
    (state: RootState) => state.organisation.spheronWallet
  );

  const selectedOrgSpheronWalletConfig = useSelectorRtk(
    (state: RootState) => state.organisation.selectedOrgSpheronWalletConfig
  );

  const referralBalance = (spheronWallet?.details as ISpheronWallet)
    ?.referralBalance;

  const creditsBalance = (spheronWallet?.details as ISpheronWallet)
    ?.creditsBalance;

  const [showSwitchDropdown, setShowSwitchDropdown] = useState<boolean>(false);

  const dropdownRef = useRef(null);
  UseOnClickOutside(dropdownRef, () => setShowSwitchDropdown(false));

  const dropdownOptions = [
    {
      id: 1,
      label: 'Make as Primary',
      handleClick: () =>
        dispatchRtk(
          updateDefaultPaymentThunk({
            organisationId: selectedOrganisationId,
            newPaymentMethodId: spheronWallet?._id || '',
          })
        ),
      optionType: 'primary' as OptionType,
    },
  ];

  return (
    <>
      {spheronWallet && (
        <div
          className={`p-3 border border-base-border rounded-lg dark:border-dark-base-border
          min-w-325 flex flex-col h-full ${
            disabled
              ? `bg-action-primary-disable dark:bg-dark-action-primary-disable
            text-action-primary-text-disable dark:text-dark-action-primary-text-disable
            cursor-not-allowed`
              : 'dark:bg-dark-base-bg'
          }`}
        >
          <div className="flex justify-between items-start">
            <div className="flex items-start justify-start space-x-3">
              {withLoader(
                loading,
                <Skeleton
                  duration={2}
                  height={40}
                  width={40}
                  borderRadius={50}
                />,
                <CreditIcon className="text-base-text dark:text-dark-base-text" />
              )}

              <div className="flex flex-col">
                <div className="flex flex-col ">
                  <h3
                    className="text-base
                    text-base-heading-text-color dark:text-dark-base-heading-text-color
                    tracking-tight font-semibold leading-6"
                  >
                    {withLoader(
                      loading,
                      <Skeleton duration={2} height={24} width={120} />,
                      <div className="flex items-start justify-start pb-1 text-left">
                        <div>
                          Spheron Credit
                          <p className="font-medium text-sm text-left">
                            Coupon Credit: ${Number(creditsBalance.toFixed(2))}{' '}
                            | Referral Credit: $
                            {Number(referralBalance.toFixed(2))} | Total Credit:
                            $
                            {Number(
                              (referralBalance + creditsBalance).toFixed(2)
                            )}
                          </p>
                        </div>
                        {showDropdown && !disabled && (
                          <div className="relative" ref={dropdownRef}>
                            <div
                              role="presentation"
                              className="ml-1 relative flex items-center justify-between
                       bg-base-fg dark:bg-dark-base-fg text-feedback-info-text 
                       dark:text-dark-feedback-info-text text-xs font-semibold px-1.5
                       py-0.5 rounded gap-x-1 whitespace-nowrap cursor-pointer"
                              onClick={() =>
                                setShowSwitchDropdown((prevState) => !prevState)
                              }
                            >
                              Change wallet
                              <Chevron
                                className={`transform cursor-pointer w-5 h-5
                       text-feedback-info-text dark:text-dark-feedback-info-text
                        dark:hover: ${
                          showSwitchDropdown ? '-rotate-90' : 'rotate-90'
                        }`}
                              />
                            </div>
                            {showSwitchDropdown && (
                              <div className="absolute top-[70%] left-3/4">
                                <SelectPaymentMethodCard
                                  setShowDropdown={setShowSwitchDropdown}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </h3>
                </div>
              </div>
            </div>
            {withLoader(
              loading,
              <Skeleton duration={2} height={24} width={24} />,
              <>
                {showDropdown || selectedOrgSpheronWalletConfig?.primary ? (
                  <></>
                ) : (
                  <div className="-mt-4 -mr-4">
                    <Dropdown
                      dropdownSize="compact"
                      dropdownType="button"
                      buttonImage={<TripleDot />}
                      filled
                      options={disabled ? [] : dropdownOptions}
                      bordersNone
                    />
                  </div>
                )}
              </>
            )}
          </div>
          <div className="flex items-end justify-end w-full h-full -mb-1">
            {withLoader(
              loading,
              <Skeleton duration={2} height={20} width={80} />,
              <Badge
                badgeType={
                  selectedOrgSpheronWalletConfig?.primary || false
                    ? 'info'
                    : 'default'
                }
                solid={false}
                isBold
                size="large"
                text={
                  selectedOrgSpheronWalletConfig?.primary || false
                    ? 'Primary'
                    : 'Backup'
                }
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SpheronWallet;
