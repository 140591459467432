import React from 'react';
import Skeleton from 'react-loading-skeleton';

function StorageCardLoading() {
  return (
    <>
      <div
        className="border relative rounded-[8px] px-4 py-3 rounded-lg 
        w-full flex items-center dark:bg-dark-base-bg 
        dark:border-dark-base-border min-h-12"
      >
        <div className="flex flex-col gap-2 items-start">
          <Skeleton
            height={20}
            width={250}
            duration={2}
            containerClassName="flex"
          />
          <Skeleton
            height={18}
            width={180}
            duration={2}
            containerClassName="flex"
          />
        </div>
      </div>
    </>
  );
}

export default StorageCardLoading;
