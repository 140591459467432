import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Button } from '@spheron/ui-library';
import { ReactComponent as WalletIcon } from '@spheron/ui-library/dist/assets/spheron-specific/billing.svg';
import { useDispatch, useSelector } from 'react-redux';
import config from '../../../config';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import { RootState } from '../../../redux/compute/store';
import { withLoader } from '../../../redux/compute/root-utils';
import { setComputeTopupSelectedPaymentMethod } from '../../../redux/compute/subscription/subscription.slice';

interface IProps {
  total: number;
  loading: boolean;
}

const ComputeBalanceCard = ({ total, loading }: IProps) => {
  const isPublicAccessible =
    sessionStorage.getItem('isPublicAccessible') === 'true';
  const communityUserAccess =
    sessionStorage.getItem('communityUserAccess') === 'true';

  const dispatchRtk = useDispatch();

  const selectedOrganisationLoading = useSelector(
    (state: RootState) => state.organisation.selectedOrganisationLoading
  );
  const fetchingCreditCards = useSelector(
    (state: RootState) => state.stripe.fetchingCreditCards
  );

  const web3WalletsLoading = useSelector(
    (state: RootState) => state.web3.walletLoading
  );

  const balance = total
    ? total.toFixed(config.dollarDecimalRound.DOLLAR_DECIMAL_ROUND)
    : 0;

  const user = useSelector((state: RootState) => state.user.user);

  const allUsers = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation.users
  );

  const isMember =
    allUsers?.find((u) => u?._id === user?._id)?.platformProfile?.role ===
      'member' && user !== null;

  const handleClick = () => {
    dispatchRtk(
      toggleModalShowRtk({
        modalShow: true,
        modalType: 'topUpBalance',
      })
    );
    dispatchRtk(setComputeTopupSelectedPaymentMethod(null));
  };

  return (
    <div
      className={`min-w-[230px] 
     rounded-lg p-3 bg-bse-bg dark:bg-dark-base-bg border border-[#551BF9]
     text-base-heading-text-color dark:text-dark-base-heading-text-color`}
    >
      <div className="flex flex-col justify-between h-full gap-y-2">
        <div className="flex flex-col gap-y-2">
          <div className="flex items-center justify-between gap-x-2">
            <span
              className="text-3 leading-3 font-600 uppercase
               text-base-heading-text-color dark:text-dark-base-heading-text-color"
            >
              BALANCE
            </span>
          </div>
          {withLoader(
            loading || typeof total === 'undefined',
            <Skeleton height={28} width="50%" duration={2} />,
            <span
              className="text-[28px] leading-8 font-500
          text-base-heading-text-color dark:text-dark-base-heading-text-color"
            >
              ${balance}
            </span>
          )}
        </div>
        {withLoader(
          selectedOrganisationLoading,
          <Skeleton height={36} width="100%" borderRadius={8} duration={2} />,
          <Button
            buttonType="primary"
            label="Top Up"
            size="small"
            classname="justify-center"
            onClick={handleClick}
            leftIcon={<WalletIcon className="h-5 w-5" />}
            disabled={
              (isPublicAccessible && !communityUserAccess) ||
              selectedOrganisationLoading ||
              web3WalletsLoading ||
              fetchingCreditCards ||
              isMember
            }
          />
        )}
      </div>
    </div>
  );
};

export default ComputeBalanceCard;
