import { ISpecialization, PaymentMethod } from '../combined-state.interface';
import {
  ICurrentApp,
  IWalletDetailsCrypto,
} from '../organisation/organisation.interfaces';
import { IProjectSaveSecurityPayload } from '../project/project.interfaces';

export interface ISubscriptionState {
  purchasingSubscription: boolean;
  removingSubscription: boolean;
  subscriptionsLoading: boolean;
  couponLoading: boolean;
  defaultPackagesLoading: boolean;
  activeIsDefaultPackage: boolean;
  allowedBonusesLoading: boolean;
  subscriptionInvoicesLoading: boolean;
  activeSubscription: ISubscription | null;
  activeBonus: IBonus[];
  demandedSubscription: ISubscription | null;
  pendingSubscription: ISubscription | null;
  reservedSubscription: ISubscriptionReservation | null;
  defaultSubscription: ISubscriptionPlan[];
  subscriptionInvoices: ISubscriptionInvoice[];
  selectedSubscription: ISubscriptionPlan | null;
  subscriptionUsageLoading: boolean;
  subscriptionUsage: ISubscriptionUsage;
  subscriptionUpdating: boolean;
  subscriptionPurchaseProgress: number;
  purchasingBonus: boolean;
  subscriptionPlanHistory: ISubscription[] | null;
  subscriptionPlanHistoryLoading: boolean;
  scheduleBonusLoading: boolean;
  updatingBonusAmountLoading: boolean;
  isCouponValid: boolean;
  couponValidationLoading: boolean;
  activeBonusLoading: boolean;
  allowedBonusIterator: IBonusIterator[];
  allowedBonus: string[];
  poll: boolean;
  computeTopupSelectedPaymentMethod: PaymentMethod | null;
}

export interface ISubscriptionPlan extends IDefaultPlan {
  bandwidth: number;
  buildExecution: number;
  concurrentBuild: number;
  default: boolean;
  deploymentsPerDay: number;
  domainLimit: number;
  ensDomainLimit: number;
  environments: number;
  name: string;
  hnsDomainLimit: number;
  price: number;
  priceFirst: number;
  span: number;
  storage: number;
  createdAt: Date;
  updatedAt: Date;
  _id: string;
  storageArweave: number;
  storageIPFS: number;
  storageFilecoin: number;
  storageIpfs: number;
  clusterAkt: number;
  clusterBuildExecution: number;
  numberOfAllowedDeployments?: number;
  numberOfAllowedWebHooks?: number;
  allowedBuildTime?: number;
}

export interface ISubscription {
  createdAt: Date;
  couponId: string;
  dateOfExpiration: number;
  dateOfIssue: number;
  organizationId: string;
  paymentTransactionHash: string;
  renew: boolean;
  state: string;
  subscriptionPackageId: ISubscriptionPlan | string | ISubscriptionHistory;
  subscriptionPackage: ISubscriptionPlan;
  updatedAt: Date;
  _id: string;
  includedMembers: number;
  invoices: string[];
}
export interface ISubscriptionHistory {
  span: number;
  default: boolean;
  priority: number;
  includedMembers: number;
  allowedBonuses: string[];
  maxOrganizationMemberCount?: null;
  _id: string;
  name: string;
  params: ISubscriptionParams;
  template: string;
  createdAt: string;
  updatedAt: string;
  priceOffers: PriceOffers;
  displayName: string;
  allowBonuses: boolean;
}
export interface ISubscriptionParams {
  bandwidth: number;
  ipfsBandwidth: number;
  buildExecution: number;
  concurrentBuild: number;
  storageArweave: number;
  storageIPFS: number;
  storageSkynet: number;
  deploymentsPerDay: number;
  domains: number;
  ensDomains: number;
  hnsDomains: number;
  environments: number;
  numberOfRequests: number;
  notificationEmailCount: number;
  optimizedImages: number;
  parallelUploads: number;
  ipfsNumberOfRequests: number;
  storageFilecoin: number;
}
export interface PriceOffers {
  firstPrice: string;
  originalPrice: string;
}

export interface ICardDetails {
  brand: string;
  country: string;
  // eslint-disable-next-line camelcase
  expiration_month: number;
  // eslint-disable-next-line camelcase
  expiration_year: number;
  last4: string;
}
export interface IBonusPayments {
  bonuses: string[];
  reservationId: string;
  _id: string;
  walletId: string;
  walletAddress: string;
  tokenAddress: string;
  chainId: number;
  network: string;
  name: string;
  subscription: string;
  fee: string;
  status: string;
  token: string;
  coupon: ICoupon;
  paymentMethod: PaymentMethod;
  transactionHash: string;
  tokenLivePrice: string;
  walletDetails: IWalletDetailsInvoiceResponseDto;
  createdAt: Date;
  updatedAt: Date;
  paymentProvider: string;
  additionalInformation: IBonusAdditionalInfo;
}

export interface IBonusAdditionalInfo {
  id: string;
  status: string;
  paymentStatus: string;
  createdAt: Date;
  updatedAt: Date;
  redirectUrl: string;
}
export interface ISubscriptionInvoice {
  bonuses: IBonus[];
  packageName: string;
  subscription: ISubscription;
  bonusesPayments: IBonusPayments[];
  newMemberPayments: INewMember[];
  packagePayment: IPackagePayment;
  pendingBonuses: IBonus[];
}
interface IPackagePayment {
  createdAt: Date;
  updatedAt: Date;
  _id: string;
  walletId: string;
  status: string;
  subscription: string;
  fee: string;
  tokenAddress: string;
  tokenLivePrice: string;
  message: string;
  organizationId: string;
  userId: string;
  transactionHash: string;
  type: string;
  chainId: number;
  network: string;
  couponId: string;
  unitPrice: number;
  unitCurrency: string;
  numberOfUnits: number;
  coupon: ICoupon;
  numberOfUnitsUnderLimit: number;
  unitPriceUnderLimit: number;
  paymentMethod: PaymentMethod;
  walletDetails: IWalletDetailsInvoiceResponseDto;
}
export interface ISubscriptionUsage {
  bandwidthLimit: number;
  buildExecutionLimit: number;
  clusterAktLimit: number;
  clusterBuildExecutionLimit: number;
  cpu: number;
  cpuLimit: number;
  concurrentBuildLimit: number;
  deploymentsPerDayLimit: number;
  domainsLimit: number;
  ensDomainsLimit: number;
  environmentsLimit: number;
  hnsDomainLimit: number;
  ipfsGatewayBandwidthLimit: number;
  lastDeploymentDate: Date;
  membersLimit: number | null;
  memory: number;
  memoryLimit: number;
  newMembers: number;
  parallelUploadsLimit: number;
  storage: number;
  storageLimit: number;
  usedBandwidth: number;
  usedBuildExecution: number;
  usedClusterAkt: number;
  usedClusterBuildExecution: number;
  usedConcurrentBuild: number;
  usedDeploymentsPerDay: number;
  usedDomains: number;
  usedEnvironments: number;
  usedHnsDomain: number;
  usedIpfsGatewayBandwidththe: number;
  usedIpfsGatewayNumberOfRequests: number;
  userMembers: number;
  usedNumberOfRequests: number;
  usedParallelUploads: number;
  usedPasswordProtections: number;
  usedStorage: number;
}

export interface IBonus {
  createdAt: Date;
  deploymentEnvironmentId: string | null;
  fee: string;
  free: boolean;
  livePrice: number;
  paymentParameterName: string;
  paymentTransactionHash: string;
  projectId: string | null;
  renew: boolean;
  state: string;
  subscriptionId: string;
  tokenAddress: string;
  tokenLivePrice: string;
  updatedAt: Date;
  value: number;
  _id: string;
  network: string;
  chainId: number;
  coupon: ICoupon;
  reservationId?: string;
  paymentMethod: PaymentMethod;
  expirationDate: string;
  activationDate: string;
}

export interface IPendingBonus {
  id: string;
  rate: string;
  usage: string;
  value?: number;
  name: string;
}

export interface INewMember {
  payment: IPackagePayment;
  username: string;
}

export interface INewMemberPaymentDetails {}

export interface ISubscriptionPaymentInvoice {
  id: string;
  network: string;
  liveTokenPrice: number;
  transactionHash: string;
  token: string;
  name: string;
  units: number;
  unitPrice: number;
  chainId: number;
  totalDollarPrice: number;
  totalTokenPrice: number;
  coupon: ICoupon;
  numberOfUnitsUnderLimit: number;
  unitPriceUnderLimit: number;
  paymentMethod: PaymentMethod;
  walletDetails: IWalletDetailsInvoiceResponseDto;
  invoices: string[];
  includedMembers: number;
}

export interface IBonusPaymentInvoice {
  id: string;
  network: string;
  liveTokenPrice: number;
  transactionHash: string;
  token: string;
  livePrice: number;
  value: number;
  chainId: number;
  rate: string;
  usage: string;
  name: string;
  totalDollarPrice: number;
  totalTokenPrice: number;
  coupon: ICoupon;
  paymentMethod: PaymentMethod;
  walletDetails: IWalletDetailsInvoiceResponseDto;
  redirectUrl: string;
  paymentProvider: string;
}

export interface INewMemberPaymentInvoice {
  name: string;
  id: string;
  network: string;
  liveTokenPrice: number;
  transactionHash: string;
  token: string;
  units: number;
  unitPrice: number;
  chainId: number;
  totalDollarPrice: number;
  totalTokenPrice: number;
  coupon: ICoupon;
  paymentMethod: PaymentMethod;
  walletDetails?: IWalletDetailsCrypto;
  cardDetails?: ICardDetails;
}

export interface ICoupon {
  id: string;
  couponName: string;
  activationCode: string;
  activatedAt: Date;
  expiresAt: Date;
  state: any;
  registeredAt: Date;
  totalDays: number;
  daysRemaning: number;
  daysUntilActivation: number;
}

export interface ICouponValidityResponse {
  valid: boolean;
  message: string;
}

export interface ICreateSubscription {
  walletId?: string;
  organizationId: string;
  renew: boolean;
  subscriptionPackageId: string;
  couponActivationCode?: string;
  history?: any;
}

export interface ICalculateBonusPrice {
  bonusNumberOfDeployments: string | number;
  bonusBuildTime: string | number;
}

export interface ICreateBonusForOrganization {
  walletId: string;
  organizationId: string;
  bonusParameterNames: string[] | number[];
  bonusParameterValues: string[] | number[];
  specialization: ISpecialization;
  renew: boolean;
}

export interface ICreateSecurityBonusForOrganization {
  bonusPayload: ICreateBonusForOrganization;
  credentialsPayload: IProjectSaveSecurityPayload;
}

export interface IGetPriceOfCustomPackage {
  packageName: string;
  numberOfAllowedDeployments: string;
  numberOfAllowedWebHooks: string;
  allowedBuildTime: string;
  name: string;
  members: string;
}

export interface ISubscriptionPayments {
  subscriptionPayments: [];
}

export interface IExpireCoupon {
  couponCode: string;
  organizationId: string;
}

export interface ISubscriptionResponse {
  createdAt: Date;
  couponId: string;
  dateOfExpiration: number;
  dateOfIssue: number;
  organizationId: string;
  paymentTransactionHash: string;
  renew: boolean;
  state: string;
  subscriptionPackageId: ISubscriptionPlan;
  updatedAt: Date;
  _id: string;
}

export interface ITokenPriceResponse {
  message: string;
  price: number;
  error?: boolean;
}

export interface ICreateBonusPayloadDto {
  walletId?: string;
  organizationId: string;
  bonusParameterNames: string[];
  bonusParameterValues: number[];
  specialization: ISpecialization;
  renewValues: boolean[];
}

export interface IOrganisationSpecializationPayloadDto {
  organizationId: string;
  specialization: ISpecialization;
}
export interface IIteratorValue {
  base: number;
  iterator: number;
  unit: string;
}

export interface IBonusIterator {
  name: string;
  value: IIteratorValue;
}

export interface IAllowedBonusesResponse {
  allowedBonuses: IBonusIterator[];
  subscriptionPackageName: string;
}

export interface IAllowedBonus {
  [key: string]: IIteratorValue;
}

// TODO: REMOVE OPTIONAL ? FROM KEYS ONCE THE DB IS MIGRATED
export interface IBillingInvoice {
  id: string;
  name: string;
  unitPrice?: number;
  units?: number;
  rate?: string;
  chainId: number;
  liveTokenPrice: number;
  totalDollarPrice: number;
  totalTokenPrice: number;
  transactionHash: string;
  token: string;
  usage?: string;
  value?: number;
  coupon?: ICoupon;
  numberOfUnitsUnderLimit?: number;
  unitPriceUnderLimit?: number;
}

export interface IPlanPriceResponse {
  bill: 0;
  price: { name: string; value: number };
  priceFirst: { name: string; value: number; enabled: boolean };
}

export interface IDefaultPlan {
  name: string;
  displayName: string;
  description: string;
  price: number;
  bandwidth: number;
  buildExecution: number;
  domains: number;
  ensDomains: number;
  hnsDomains: number;
  ipfsBandwidth: number;
  clusterAkt: number;
  clusterBuildExecution: number;
  concurrentBuild: number;
  priceFirst: number;
  storageArweave: number;
  storageIPFS: number;
  _id?: string;
  priority: number;
  includedMembers: number;
}

export interface IDefaultPlanResponse {
  createdAt: Date;
  default: boolean;
  name: string;
  params: Partial<IDefaultPlan>;
  priceFirst: number;
  price: number;
  span: number;
  template: {
    activeMinimum: number;
    allowedBonuses: string[];
    appType: ICurrentApp;
    bonusEnabled: boolean;
    bonusOnFreePackageEnabled: boolean;
    defaultPackage: boolean;
    packageParams: string[];
    paymentModel: string;
    specialization: ISpecialization;
    usageReportCreation: boolean;
    _id: string;
  };
  updatedAt: Date;
  _id: string;
  priority: number;
  displayName: string;
  description: string;
}

export interface IActiveSubscriptionResponse {
  type: ISpecialization;
  activeIsDefaultPackage: boolean;
  activeSubscription: ISubscription | null;
  demandedSubscription: ISubscription | null;
  pendingSubscription: ISubscription | null;
}

// SOLANA SUBSCRIPTION RESERVATION

export interface ISubscriptionReservation {
  createdAt: Date;
  couponId: string;
  dateOfExpiration: number;
  dateOfIssue: number;
  organizationId: string;
  paymentTransactionHash: string;
  renew: boolean;
  state: string;
  subscriptionPackageId: string;
  subscriptionPackage: ISubscriptionPlan;
  updatedAt: Date;
  _id: string;
  reservationId: string;
}
export interface ISubscriptionPollPayload {
  subscriptionPayload: {
    organizationId: string;
    specialization: ISpecialization;
  };
  credentialsPayload: IProjectSaveSecurityPayload;
}

export enum PaymentType {
  // eslint-disable-next-line no-unused-vars
  PRIMARY = 'primary',
  // eslint-disable-next-line no-unused-vars
  BACKUP = 'backup',
}

export enum TransactionState {
  // eslint-disable-next-line no-unused-vars
  SUCCEEDED = 'succeeded',
  // eslint-disable-next-line no-unused-vars
  PENDING = 'pending',
  // eslint-disable-next-line no-unused-vars
  DECLINED = 'declined',
}

export interface IServicePlan {
  value: string;
  label: string;
  color: string;
}

export enum SubscriptionState {
  // eslint-disable-next-line no-unused-vars
  ACTIVE = 'ACTIVE',
  // eslint-disable-next-line no-unused-vars
  EXPIRED = 'EXPIRED',
  // eslint-disable-next-line no-unused-vars
  PENDING = 'PENDING',
  // eslint-disable-next-line no-unused-vars
  DEMANDED = 'DEMANDED',
  // eslint-disable-next-line no-unused-vars
  ERROR = 'ERROR',
  // eslint-disable-next-line no-unused-vars
  REJECTED = 'REJECTED',
  // eslint-disable-next-line no-unused-vars
  CANCELED = 'CANCELED',
  // eslint-disable-next-line no-unused-vars
  RESERVED = 'RESERVED',
}

export interface IWalletDetailsInvoiceResponseDto extends IWalletDetailsCrypto {
  cardService: string;
  cardCustomerId: string;
  connectionId: string;
  cardPaymentMethodType: string;
  info: ICardDetails;
}

export interface IPlanByIdService {
  id: string;
}

export interface IScheduleBonusPayloadDto {
  organizationId: string;
  bonusParameterName: string;
  bonusParameterValue: number;
  renew: boolean;
  specialization: ISpecialization;
  activationDate: Date;
}

export interface IScheduleBonus {
  state: string;
  paymentTransactionHash: string;
  projectId: null;
  deploymentEnvironmentId: null;
  reservationId: null;
  expired: boolean;
  renewUntil: null;
  _id: string;
  paymentParameterName: string;
  value: number;
  free: boolean;
  renew: boolean;
  subscriptionId: string;
  organization: string;
  specialization: ISpecialization;
  activationDate: string;
  expirationDate: string;
  createdAt: string;
  updatedAt: string;
}

export interface IScheduleBonusResponseDto {
  message: string;
  bonus: IScheduleBonus;
}

export interface ICreateBonusReservationDto {
  organizationId: string;
  specialization: string;
  bonusParameterNames: string[] | number[];
  bonusParameterValues: string[] | number[];
  processorType: IProcessorType;
  processor: IProcessor;
  details: Record<string, any>;
}

export enum IProcessorType {
  // eslint-disable-next-line no-unused-vars
  SMART_CONTRACT = 'smart_contract',
  // eslint-disable-next-line no-unused-vars
  CRYTPO_INTEGRATION = 'crypto_integration',
}

export enum IProcessor {
  // eslint-disable-next-line no-unused-vars
  FETCCH = 'fetcch',
  // eslint-disable-next-line no-unused-vars
  SOLANA = 'solana',
  // eslint-disable-next-line no-unused-vars
  COPPERX = 'copperx',
}
