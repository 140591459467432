import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Dropdown, OptionType, TextInput } from '@spheron/ui-library';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as GoogleIcon } from '../../../../assets/compute/icons/google-icon.svg';
import { updateUserOnboardingDetailsThunk } from '../../../../redux/compute/user/user.thunks';
import { AppDispatch, RootState } from '../../../../redux/compute/store';
import { openProviderAppAuth } from '../../../../redux/compute/user/user.utils';
import { withLoader } from '../../../../redux/compute/root-utils';

const OnboardingComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.user.user);
  const userLoading = useSelector((state: RootState) => state.user.userLoading);
  const usageOptions = [
    {
      label: 'Clients',
      value: 'clients',
      optionType: 'primary' as OptionType,
    },
    {
      label: 'My Company',
      value: 'company',
      optionType: 'primary' as OptionType,
    },
    {
      label: 'Personal Use',
      value: 'personal',
      optionType: 'primary' as OptionType,
    },
    {
      label: 'School Projects',
      value: 'projects',
      optionType: 'primary' as OptionType,
    },
  ];
  const roleOptions = [
    {
      label: 'Product Engineers',
      value: 'engineer',
      optionType: 'primary' as OptionType,
    },
    {
      label: 'DevOps',
      value: 'devops',
      optionType: 'primary' as OptionType,
    },
    {
      label: 'Product Manager',
      value: 'manager',
      optionType: 'primary' as OptionType,
    },
    {
      label: 'Designer',
      value: 'designer',
      optionType: 'primary' as OptionType,
    },
    {
      label: 'Others',
      value: 'others',
      optionType: 'primary' as OptionType,
    },
  ];
  const sizeOptions = [
    {
      label: '1-10',
      value: '1-10',
      optionType: 'primary' as OptionType,
    },
    {
      label: '11-50',
      value: '11-50',
      optionType: 'primary' as OptionType,
    },
    {
      label: '51-200',
      value: '51-200',
      optionType: 'primary' as OptionType,
    },
    {
      label: '201-500',
      value: '500+',
      optionType: 'primary' as OptionType,
    },
  ];
  // const projectOptions = [
  //   {
  //     label: 'Multi-services Infra',
  //     value: 'multi-service',
  //     optionType: 'primary' as OptionType,
  //   },
  //   {
  //     label: 'Static Website',
  //     value: 'static',
  //     optionType: 'primary' as OptionType,
  //   },
  //   {
  //     label: 'Web App',
  //     value: 'webapp',
  //     optionType: 'primary' as OptionType,
  //   },
  //   {
  //     label: 'API / Mobile backend',
  //     value: 'backend',
  //     optionType: 'primary' as OptionType,
  //   },
  //   {
  //     label: 'Others',
  //     value: 'others',
  //     optionType: 'primary' as OptionType,
  //   },
  // ];
  const [usage, setUsage] = useState<string>(usageOptions[0].label);
  const [role, setRole] = useState<string>(roleOptions[0].label);
  const [teamSize, setTeamSize] = useState<string>(sizeOptions[0].label);
  // const [projectType, setProjectType] = useState<string>(
  //   projectOptions[0].label
  // );
  const dropdownOptions = [
    {
      id: 1,
      label: 'How will you primarily use Spheron?',
      placeholder: usage,
      selected: setUsage,
      options: usageOptions,
    },
    {
      id: 2,
      label: 'What best describe your role?',
      placeholder: role,
      selected: setRole,
      options: roleOptions,
    },
    {
      id: 3,
      label: 'How big your team is?',
      placeholder: teamSize,
      selected: setTeamSize,
      options: sizeOptions,
    },
    // {
    //   id: 4,
    //   label: 'Which type of project are you building?',
    //   placeholder: projectType,
    //   selected: setProjectType,
    //   options: projectOptions,
    // },
  ];
  const handleSubmit = () => {
    dispatch(
      updateUserOnboardingDetailsThunk({
        additionalInfo: {
          primarilyUsage: usage,
          roleInTeam: role,
          teamSize,
          projectType: 'Multi-services Infra',
        },
      })
    );
    navigate(`/create-new-organisation`);
  };

  useEffect(() => {
    if (!userLoading && user && user.organizations.length > 0) {
      navigate(`/compute/dashboard`);
    }
  }, [navigate, user, userLoading]);

  return (
    <div className="w-full h-[calc(100vh-72px)] flex flex-col justify-center items-center">
      <div className="text-center flex flex-col items-center">
        <span
          className="text-[32px]
            text-base-heading-text-color dark:text-dark-base-heading-text-color
            leading-[36px] font-bold"
        >
          Buckle Up for Your Spheronaut Adventure! 🚀
        </span>
        <span
          className="text-base-para-text-color dark:text-dark-base-para-text-color 
          text-base font-semibold mt-2"
        >
          Prepare to Dive into the Depths of Spheronaut Wisdom
        </span>
      </div>
      <div
        className="mt-6 p-6 rounded-[20px] w-[415px]
          border border-base-border dark:border-dark-base-border"
      >
        <div className="flex items-center justify-center">
          <div className="w-[360px]">
            {withLoader(
              userLoading,
              <Skeleton height={44} width="100%" duration={2} />,
              <>
                {user?.platformProfile?.email ? (
                  <>
                    <div>
                      <TextInput
                        label="Email"
                        onChange={() => {}}
                        value={user?.platformProfile?.email}
                        placeholder="xyz@gmail.com"
                        required
                        disabled
                        inputSize="compact"
                      />
                    </div>
                  </>
                ) : (
                  <div className="flex flex-col gap-y-2">
                    <Button
                      label="Connect"
                      type="button"
                      buttonType="ghost"
                      fillWidth
                      size="medium"
                      rightIcon={<GoogleIcon className="w-5 h-5" />}
                      onClick={() => {
                        openProviderAppAuth('google');
                      }}
                    />
                    <span className="text-form-error text-[11px] leading-3 capitalize">
                      * Please connect your google account to proceed
                    </span>
                  </div>
                )}
              </>
            )}

            <span
              className="border-dashed border-t border-base-border 
            dark:border-dark-base-border mt-6 w-full flex
            gap-y-3"
            />

            {dropdownOptions.map((dropdown) => (
              <div key={dropdown.id}>
                <Dropdown
                  dropdownType="default"
                  label={dropdown.label}
                  placeholder={dropdown.placeholder}
                  filled
                  onSelected={(value: any) => {
                    dropdown.selected(value.value);
                  }}
                  options={dropdown.options}
                  dropdownSize="compact"
                />
              </div>
            ))}

            <div className="mt-4">
              <Button
                buttonType="primary"
                label="Continue"
                size="medium"
                disabled={userLoading || !user?.platformProfile?.email}
                fillWidth
                onClick={() => handleSubmit()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingComponent;
