import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import {
  addCouponService,
  calculatePackagePrice,
  cancelSubscription,
  checkCouponValidityService,
  createBonusForOrganization,
  createBonusReservationService,
  createSubscription,
  getActiveBonusPackage,
  getActiveSubscription,
  getAllowedBonusesService,
  getDefaultPlans,
  getSubscriptionHistoryService,
  getSubscriptionInvoices as getSubscriptionInvoicesService,
  removeActiveBonus as removeActiveBonusService,
  scheduleBonusService,
  updateBonusAmountService,
} from './subscription.services';
import { NotificationType } from '../notification/notification.interfaces';
import { addNotificationRtk } from '../notification/notification.slice';
import {
  ICreateBonusReservationDto,
  IDefaultPlanResponse,
  IOrganisationSpecializationPayloadDto,
  IScheduleBonusResponseDto,
  ISubscription,
  ISubscriptionState,
} from './subscription.interfaces';
import {
  ICurrentApp,
  IOrganisationState,
} from '../organisation/organisation.interfaces';
import {
  getCurrentAppState,
  mapCurrentAppToSpecialization,
} from '../organisation/organisation.utils';
import { toggleModalShowRtk } from '../modal/modal.slice';
import {
  deleteSpheronCredits,
  getSpecificSubscriptionUsageService,
  getSubscriptionUsageService,
} from '../organisation/organisation.services';
import { delay } from '../root-utils';
// eslint-disable-next-line import/no-cycle
import {
  getSubscriptionUsageEndRtk,
  setPollRtk,
  toggleSubscriptionLoadingRtk,
} from './subscription.slice';
// eslint-disable-next-line import/no-cycle
import {
  getSelectedOrgWalletThunk,
  getSpheronCreditsThunk,
} from '../organisation/organisation.thunks';
import { projectSecurityThunk } from '../project/project.thunks';
import { IResponseError } from '../combined-reducer.interface';
import { RootState } from '../rtk-store';

// TO GET DEFAULT PACKAGES
export const getAllDefaultPackagesThunk: any = createAsyncThunk(
  'subscription/getAllDefaultPackages',
  async (
    payload: ICurrentApp,
    { dispatch, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      let res: any = await getDefaultPlans(payload);
      if (res?.error) {
        dispatch(
          addNotificationRtk({
            message: res.message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      const prices: any = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const packageItem of res) {
        // eslint-disable-next-line no-await-in-loop
        const response = await calculatePackagePrice('1', packageItem.name);
        prices.push(response);
      }

      // Mapping price to each plan
      res = res.map((response: IDefaultPlanResponse, index: number) => {
        return {
          ...response,
          price: prices[index].price.value,
          priceFirst: prices[index].priceFirst.value,
        };
      });
      return fulfillWithValue(res);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getSubscriptionInvoicesThunk = createAsyncThunk(
  'subscription/getSubscriptionInvoices',
  async (payload: any, { fulfillWithValue, rejectWithValue }) => {
    try {
      const res: any = await getSubscriptionInvoicesService(payload);
      if (res?.error) {
        return rejectWithValue({});
      }

      return fulfillWithValue(res.subscriptionPayments);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getActiveBonusThunk = createAsyncThunk(
  'subscription/getActiveBonus',
  async (payload: any, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const res: any = await getActiveBonusPackage(payload);
      if (res?.error) {
        dispatch(
          addNotificationRtk({
            message: res.message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      return fulfillWithValue(res.bonuses);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getSubscriptionUsageThunk = createAsyncThunk(
  'subscription/getSubscriptionUsage',
  async (payload: any, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      let response;
      if (payload.subscriptionId)
        response = await getSpecificSubscriptionUsageService(payload);
      else response = await getSubscriptionUsageService(payload);
      if ((response as IResponseError).error) {
        dispatch(
          addNotificationRtk({
            message: (response as IResponseError).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      return fulfillWithValue((response as any).usage);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

// TO GET THE ACTIVE / ONGOING SUBSCRIPTION
export const getActiveSubscriptionsThunk: any = createAsyncThunk(
  'subscription/getActiveSubscriptions',
  async (_, { dispatch, getState, fulfillWithValue, rejectWithValue }) => {
    const {
      organisation: { selectedOrganisation },
    } = getState() as { organisation: IOrganisationState };
    try {
      (window as any).invoicePromise = dispatch(
        getSubscriptionInvoicesThunk(selectedOrganisation._id)
      );
      dispatch(
        getActiveBonusThunk({
          organizationId: selectedOrganisation._id,
          specialization: mapCurrentAppToSpecialization(
            getCurrentAppState(selectedOrganisation.appType)
          ),
        })
      );
      const res: any = await getActiveSubscription(selectedOrganisation._id);
      if (res?.error) {
        dispatch(
          addNotificationRtk({
            message: res.message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        dispatch(toggleSubscriptionLoadingRtk(false));
        return rejectWithValue({});
      }

      // Getting Number of members in the organization for price calculation of the package

      if (res[0].activeSubscription) {
        dispatch(
          getSubscriptionUsageThunk({
            organizationId: selectedOrganisation._id,
            specialization: mapCurrentAppToSpecialization(
              getCurrentAppState(selectedOrganisation.appType)
            ),
          })
        );

        const members = selectedOrganisation!.users;

        const price = await calculatePackagePrice(
          String(members.length || 1),
          res[0].activeSubscription.subscriptionPackage.name
        );

        // Parsing Response to add Price field

        res[0] = {
          ...res[0],
          activeSubscription: {
            ...res[0].activeSubscription,
            subscriptionPackage: {
              ...res[0].activeSubscription.subscriptionPackage.params,
              name: res[0].activeSubscription.subscriptionPackage.name,
              displayName:
                res[0].activeSubscription.subscriptionPackage.displayName,
              priority: res[0].activeSubscription.subscriptionPackage.priority,
              priceFirst: price.priceFirst.value || 0,
              price: price.price.value || 0,
            },
          },
        };
      }

      return fulfillWithValue(res);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const removePendingSubscriptionThunk = createAsyncThunk(
  'subscription/removePendingSubscription',
  async (payload: any, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const res: any = await cancelSubscription(
        payload.organizationId,
        payload.specialization
      );
      if (res?.error) {
        dispatch(
          addNotificationRtk({
            message: res.message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }

      dispatch(
        addNotificationRtk({
          message: res.message,
          timestamp: Date.now(),
          type: NotificationType.Success,
        })
      );
      dispatch(toggleModalShowRtk({ modalShow: false }));
      return fulfillWithValue(payload);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const createBonusSubscriptionThunk = createAsyncThunk(
  'subscription/createBonusSubscription',
  async (payload: any, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const res = await createBonusForOrganization(payload);
      if (res?.error) {
        dispatch(
          addNotificationRtk({
            message: res.message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        dispatch(getSubscriptionInvoicesThunk(payload.organizationId));
        dispatch(toggleModalShowRtk({ modalShow: false }));
        return rejectWithValue({});
      }
      dispatch(
        addNotificationRtk({
          message: res.message,
          timestamp: Date.now(),
          type: NotificationType.Success,
        })
      );
      dispatch(toggleModalShowRtk({ modalShow: false }));
      return fulfillWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

// Only used in Password Protection feature
export const subscriptionUsagePollThunk = createAsyncThunk(
  'subscription/subscriptionUsagePoll',
  async (payload: any, { dispatch, getState }) => {
    dispatch(setPollRtk(true));
    const {
      subscription: { poll },
    } = getState() as { subscription: ISubscriptionState };
    /* eslint-disable no-constant-condition */
    while (poll) {
      try {
        /* eslint-disable no-await-in-loop */
        const res: any = await getSubscriptionUsageService(
          payload.subscriptionPayload
        );
        if (res?.error) {
          dispatch(setPollRtk(false));
        }
        dispatch(getSubscriptionUsageEndRtk(res.usage));
        if (res.usage.usedPasswordProtections) {
          dispatch(projectSecurityThunk(payload.credentialsPayload));
          dispatch(setPollRtk(false));
        } else {
          /* eslint-disable no-await-in-loop */
          await delay(3000);
        }
      } catch (error) {
        dispatch(setPollRtk(false));
        Sentry.captureException(error);
      }
    }
  }
);

export const createSecurityBonusSubscriptionThunk = createAsyncThunk(
  'subscription/createSecurityBonusSubscription',
  async (payload: any, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const res = await createBonusForOrganization(payload.bonusPayload);
      if (res?.error) {
        dispatch(
          addNotificationRtk({
            message: res.message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        dispatch(
          getSubscriptionInvoicesThunk(payload.bonusPayload.organizationId)
        );
        dispatch(toggleModalShowRtk({ modalShow: false }));
        return rejectWithValue({});
      }
      dispatch(
        addNotificationRtk({
          message: res.message,
          timestamp: Date.now(),
          type: NotificationType.Success,
        })
      );
      dispatch(toggleModalShowRtk({ modalShow: false }));
      dispatch(
        subscriptionUsagePollThunk({
          subscriptionPayload: {
            organizationId: payload.bonusPayload.organizationId,
            specialization: payload.bonusPayload.specialization,
          },
          credentialsPayload: payload.credentialsPayload,
        })
      );
      return fulfillWithValue({});
    } catch (error) {
      dispatch(toggleModalShowRtk({ modalShow: false }));
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const createSubscriptionsThunk = createAsyncThunk(
  'subscription/createSubscriptions',
  async (
    payload: {
      walletId?: string;
      subscriptionPackageId: string;
      renew: boolean;
      couponActivationCode: string;
      organizationId: string;
      history: any;
    },
    { dispatch, getState, fulfillWithValue, rejectWithValue }
  ) => {
    const {
      organisation: { currentApp },
    } = getState() as { organisation: IOrganisationState };

    try {
      const res: any = await createSubscription({
        walletId: payload?.walletId || '',
        organizationId: payload.organizationId,
        renew: payload.renew,
        subscriptionPackageId: payload.subscriptionPackageId,
        couponActivationCode: payload.couponActivationCode,
      });
      if (!res.success) {
        dispatch(
          addNotificationRtk({
            message: res.message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }

      payload.history({
        pathname: `/${currentApp}/plan-pending`,
        state: {
          date: Date.now(),
        },
      });
      return fulfillWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const removeActiveBonusThunk = createAsyncThunk(
  'subscription/removeActiveBonus',
  async (
    payload: any,
    { dispatch, getState, fulfillWithValue, rejectWithValue }
  ) => {
    const {
      organisation: { selectedOrganisation },
    } = getState() as { organisation: IOrganisationState };

    try {
      const res = await removeActiveBonusService(
        payload,
        selectedOrganisation._id
      );
      if (res?.error) {
        dispatch(
          addNotificationRtk({
            message: res.message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
      } else {
        dispatch(
          addNotificationRtk({
            message: res.message,
            timestamp: Date.now(),
            type: NotificationType.Success,
          })
        );
      }
      dispatch(toggleModalShowRtk({ modalShow: false }));
      return fulfillWithValue(payload);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const deleteSpheronCreditThunk = createAsyncThunk(
  'subscription/deleteSpheronCredit',
  async (
    payload: { couponCode: string; organizationId: string },
    { dispatch, getState, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const {
        organisation: { selectedOrganisation },
      } = getState() as { organisation: IOrganisationState };
      const res: any = await deleteSpheronCredits(payload);
      dispatch(toggleSubscriptionLoadingRtk(true));
      if (res?.error) {
        dispatch(
          addNotificationRtk({
            message: res.message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
      } else {
        dispatch(getSelectedOrgWalletThunk(selectedOrganisation._id));
        dispatch(getSpheronCreditsThunk(selectedOrganisation._id));
      }
      dispatch(toggleModalShowRtk({ modalShow: false }));
      return fulfillWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const addSpheronCreditThunk = createAsyncThunk(
  'subscription/addSpheronCredit',
  async (
    payload: {
      couponCode: string;
      organizationId: string;
      captchaToken: string;
    },
    { dispatch, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const res: any = await addCouponService(payload);
      if (res.message === 'Invalid captcha') {
        dispatch(
          addNotificationRtk({
            message: res.message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
      } else {
        dispatch(
          toggleModalShowRtk({
            modalShow: true,
            modalType: 'redeemSpheronCredit',
            options: {
              code: payload.couponCode,
              state: res.activationState,
              message: res.message,
            },
          })
        );
        if (!res?.error) {
          dispatch(getSelectedOrgWalletThunk(payload.organizationId));
          dispatch(getSpheronCreditsThunk(payload.organizationId));
        }
        return fulfillWithValue({});
      }
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getCouponValidityThunk = createAsyncThunk(
  'subscription/getCouponValidity',
  async (
    payload: any,
    { dispatch, getState, fulfillWithValue, rejectWithValue }
  ) => {
    const {
      organisation: { selectedOrganisation },
    } = getState() as { organisation: IOrganisationState };

    try {
      const res = await checkCouponValidityService(
        payload,
        selectedOrganisation._id
      );
      if (!res.valid) {
        dispatch(
          toggleModalShowRtk({
            modalShow: true,
            modalType: 'invalidCoupon',
            options: { message: res.message },
          })
        );
      }
      return fulfillWithValue(res.valid);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getAllowedBonusesThunk: any = createAsyncThunk(
  'subscription/getAllowedBonuses',
  async (
    payload: IOrganisationSpecializationPayloadDto,
    { dispatch, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const res: any = await getAllowedBonusesService(
        payload.organizationId,
        payload.specialization
      );
      if (res?.error) {
        dispatch(
          addNotificationRtk({
            message: res.message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return fulfillWithValue([]);
      }
      return fulfillWithValue(res.allowedBonuses);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getSubscriptionHistoryThunk = createAsyncThunk(
  'subscription/getSubscriptionHistory',
  async (payload: string, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await getSubscriptionHistoryService(payload);
      if ((response as IResponseError)?.error) {
        dispatch(
          addNotificationRtk({
            message: (response as IResponseError).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
      }
      return fulfillWithValue(
        (response as { subscriptions: ISubscription[] }).subscriptions
      );
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const scheduleBonusThunk = createAsyncThunk(
  'subscription/scheduleBonus',
  async (
    payload: {
      organizationId: string;
      bonusParameterName: string;
      bonusParameterValue: number;
      renew: boolean;
    },
    { getState, dispatch, rejectWithValue, fulfillWithValue }
  ) => {
    const { currentApp } = (getState() as RootState).organisation;
    try {
      const payloadDto = {
        ...payload,
        specialization: mapCurrentAppToSpecialization(currentApp),
        activationDate: new Date(),
      };
      const response = await scheduleBonusService(payloadDto);
      if ((response as IResponseError)?.error) {
        dispatch(
          addNotificationRtk({
            message: (response as IResponseError).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      dispatch(
        addNotificationRtk({
          message: 'Topup Scheduled successfully',
          timestamp: Date.now(),
          type: NotificationType.Success,
        })
      );
      return fulfillWithValue(response as IScheduleBonusResponseDto);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const updateBonusAmountThunk = createAsyncThunk(
  'subscription/updateBonusValue',
  async (
    payload: {
      organizationId: string;
      bonusId: string;
      value: number;
    },
    { dispatch, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await updateBonusAmountService({ ...payload });
      if ((response as IResponseError)?.error) {
        dispatch(
          addNotificationRtk({
            message: (response as IResponseError).message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }
      dispatch(
        addNotificationRtk({
          message: 'Amount updated successfully',
          timestamp: Date.now(),
          type: NotificationType.Success,
        })
      );
      return fulfillWithValue({
        ...payload,
      });
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const createCryptoBonusReservationThunk = createAsyncThunk(
  'subscription/createCryptoBonusReservation',
  async (
    payload: ICreateBonusReservationDto,
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await createBonusReservationService(payload);
      if (response.success) {
        return fulfillWithValue(response);
      }
      return rejectWithValue(response);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);
