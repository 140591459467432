import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ITheme, IThemeState } from './theme.interfaces';

const root = window.document.documentElement;

// TODO: Set theme to dark by default
export const initialState: IThemeState = {
  theme: null,
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme(state: IThemeState, action: PayloadAction<ITheme>) {
      root.classList.remove(String(ITheme.DARK));
      root.classList.remove(String(ITheme.LIGHT));
      const themeMain = localStorage.getItem('theme');

      if (themeMain) {
        root.classList.add(themeMain);
      } else {
        root.classList.add(action.payload);
      }
      root.setAttribute('data-theme', action.payload);
      state.theme = action.payload;
    },
  },
});

export const { setTheme: setThemeRtk } = themeSlice.actions;
export const { reducer: themeRTKReducer } = themeSlice;
