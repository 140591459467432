/* eslint-disable import/no-cycle */
import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import {
  ClusterType,
  HardwareTypeEnum,
  IAvailableInventory,
  IAvailableInventoryResponse,
  IInstanceCreationState,
  IInstancePlan,
  IInstancePlans,
  InstanceDeploymentType,
  IPLeaseType,
  IPricingTargetsResponse,
  LoadingType,
  PersistentStorageOption,
  ResourcesAvailable,
  ScalingType,
} from './instance-creation.interfaces';
import {
  defaultCustomPlansValueRtk,
  getInstancePlansEndRtk,
  selectClusterRegionRtk,
  setInstanceDiscounts,
  setIpLeasePrice,
  setMultiServiceIpLeasePrice,
  setMultiServiceUpdatedCustomPrice,
  setMultiServiceUpdatedPersistentPrice,
  setMultiServiceUpdatedStoragePrice,
  setUpdatedCustomPriceRtk,
  setUpdatedPersistentPriceRtk,
  setUpdatedStoragePriceRtk,
  toggleInstancePlanLoadingRtk,
  togglePriceLoadingRtk,
  updateCustomHddStorageInputPriceRtk,
  updateCustomHddStorageInputRtk,
  updateCustomNvmStorageInputPriceRtk,
  updateCustomNvmStorageInputRtk,
  updateCustomPlanRtk,
  updateCustomSsdStorageInputPriceRtk,
  updateCustomSsdStorageInputRtk,
  updateCustomStorageInputPriceRtk,
  updateMultiServiceCustomPlan,
  updatePersistentStorageAvailabilityRtk,
  updatePlanAvailabilityRtk,
  updatePlanSearchRtk,
  updateStorageAvailabilityRtk,
} from './instance-creation.slice';
import {
  calculateInstancePrice,
  checkIfDemandFulfilled,
  machineTypeMap,
} from './instance-creation.utils';
import {
  customPriceService,
  getAvailableInventory,
  getClusterRegionService,
  getDefaultCustomPlans,
  getInstancePlansService,
  getInstanceUnitPrices,
  getPlanByIdService,
} from './instance-creation.services';
import { IClusterState } from '../../cluster/cluster.interfaces';
import { addNotificationRtk } from '../../notification/notification.slice';
import { NotificationType } from '../../notification/notification.interfaces';
import { IOrganisationState } from '../../organisation/organisation.interfaces';
import { IResponseError } from '../../combined-reducer.interface';
import { DISABLED_REGIONS } from '../../../../config';

export const getClusterRegionThunk = createAsyncThunk(
  'cluster/getClusterRegion',
  async (
    payload: void,
    { rejectWithValue, fulfillWithValue, dispatch, getState }
  ) => {
    const {
      instanceCreation: { clusterScaling, deploymentType },
    } = getState() as { instanceCreation: IInstanceCreationState };
    try {
      const response = await getClusterRegionService(
        machineTypeMap.get(deploymentType) || 0
      );
      if (!(response as unknown as IResponseError).error) {
        const { regions: disabledRegions } = JSON.parse(DISABLED_REGIONS);
        const enabledRegions = response.regions.filter(
          (region) => !disabledRegions.includes(region.region)
        );
        dispatch(
          selectClusterRegionRtk(
            enabledRegions
              .filter((region) => region.scalingMode === clusterScaling)
              .find((region) => region.isPrimaryForScaling)?.region || ''
          )
        );
        return fulfillWithValue({ regions: enabledRegions });
      }
      Sentry.captureMessage((response as unknown as IResponseError).message);
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getRecommendedPlanByIdThunk = createAsyncThunk(
  'instanceCreation/getRecommendedPlanById',
  async (
    payload: IInstancePlans,
    { dispatch, fulfillWithValue, rejectWithValue, getState }
  ) => {
    const { cluster } = getState() as { cluster: IClusterState };
    const { instanceCreation } = getState() as {
      instanceCreation: IInstanceCreationState;
    };
    try {
      const { totalCount, akashMachineImages } = payload;
      let res: any; // infer better type from usage
      const resId =
        cluster.selectedTemplate?.serviceData.defaultAkashMachineImageId;
      if (resId) {
        res = await getPlanByIdService(resId, {
          region: instanceCreation.clusterRegion || '',
          scalingType: instanceCreation.clusterScaling,
          provider: '',
          machineType: machineTypeMap.get(instanceCreation.deploymentType) || 0,
        });
      }
      if (res?.error) {
        dispatch(
          addNotificationRtk({
            message: res.message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        return rejectWithValue({});
      }

      const updatedRecommendedPlan = {
        ...res,
        price: Number(res.defaultDailyTopUp),
        recommendedPlan: true,
      };
      if (totalCount > 0) {
        const initialInstancePlans = [...akashMachineImages];
        const updatedInstancePlans = initialInstancePlans.map((plan) => {
          return { ...plan, recommendedPlan: false };
        });
        const uniqueAkashMachineImages = updatedInstancePlans.filter(
          (plan) => plan._id !== updatedRecommendedPlan._id
        );
        const updatedAkashMachineImages = [
          updatedRecommendedPlan,
          ...uniqueAkashMachineImages,
        ];
        let updatedPlans;
        const { instanceCreation } = getState() as {
          instanceCreation: IInstanceCreationState;
        };
        const customPlanId =
          instanceCreation.instancePlans.akashMachineImages.find(
            (plan) => plan._id === 'custom-id'
          );
        if (customPlanId) {
          const uniqueAkashMachineImages = updatedAkashMachineImages.filter(
            (plan) => plan._id !== 'custom-id'
          );
          updatedPlans = {
            ...payload,
            totalCount: totalCount + 1,
            akashMachineImages: [customPlanId, ...uniqueAkashMachineImages],
          };
        } else {
          updatedPlans = {
            ...payload,
            akashMachineImages: updatedAkashMachineImages,
          };
        }

        dispatch(getInstancePlansEndRtk(updatedPlans));
      }
      return fulfillWithValue(updatedRecommendedPlan);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);
export const getInstancePlanThunk: any = createAsyncThunk(
  'instanceCreation/getInstancePlan',
  async (
    payload: { search: string },
    { dispatch, getState, fulfillWithValue, rejectWithValue }
  ) => {
    dispatch(toggleInstancePlanLoadingRtk(true));
    const { instanceCreation } = getState() as {
      instanceCreation: IInstanceCreationState;
    };
    const limit = instanceCreation.instancePlanPagination.numbersPerPage;
    const skip = 0;
    dispatch(updatePlanSearchRtk(payload.search));
    // infer better type from usage
    const res: any = await getInstancePlansService(
      skip,
      limit,
      payload.search,
      {
        region: instanceCreation.clusterRegion || '',
        scalingType: instanceCreation.clusterScaling,
        provider: '',
        machineType: machineTypeMap.get(instanceCreation.deploymentType) || 0,
      }
    );
    if (res?.error) {
      dispatch(
        addNotificationRtk({
          message: res.message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      dispatch(toggleInstancePlanLoadingRtk(false));
      return rejectWithValue({});
    }
    const instancePlans = res.akashMachineImages?.map(
      (instancePlan: IInstancePlan) => {
        return {
          ...instancePlan,
          price: Number(instancePlan.defaultDailyTopUp),
          recommendedPlan: false,
        };
      }
    );
    dispatch(
      getInstancePlansEndRtk({
        totalCount: res.totalCount,
        akashMachineImages: instancePlans,
      })
    );
    let updatedAkashMachineImages: IInstancePlan[] = instancePlans;
    if (
      instanceCreation.clusterType === ClusterType.TEMPLATE &&
      payload.search === ''
    ) {
      const { cluster } = getState() as {
        cluster: IClusterState;
      };
      if (cluster.selectedTemplate) {
        dispatch(
          getRecommendedPlanByIdThunk({
            totalCount: res.totalCount,
            akashMachineImages: instancePlans,
          })
        );
      }
      const { instanceCreation } = getState() as {
        instanceCreation: IInstanceCreationState;
      };
      if (instanceCreation.recommendedPlan !== null) {
        const updatedRecommendedPlan = instanceCreation.recommendedPlan;
        const uniqueAkashMachineImages = instancePlans.filter(
          (plan: IInstancePlan) => plan._id !== updatedRecommendedPlan._id
        );
        updatedAkashMachineImages = [
          updatedRecommendedPlan,
          ...uniqueAkashMachineImages,
        ];
      }
    }
    const updatedPlans =
      instanceCreation.clusterType === ClusterType.TEMPLATE &&
      payload.search === ''
        ? updatedAkashMachineImages
        : instancePlans;
    return fulfillWithValue({
      totalCount: res.totalCount,
      akashMachineImages: updatedPlans,
    });
  }
);

export const loadMoreInstancePlanThunk = createAsyncThunk(
  'instanceCreation/loadMoreInstancePlan',
  async (_, { dispatch, getState, fulfillWithValue, rejectWithValue }) => {
    const {
      instanceCreation: {
        instancePlanPagination,
        clusterRegion,
        clusterScaling,
        deploymentType,
      },
    } = getState() as { instanceCreation: IInstanceCreationState };
    const limit = instancePlanPagination.numbersPerPage;
    const skip = limit * (instancePlanPagination.currentPage + 1);
    // infer better type from usage
    const res: any = await getInstancePlansService(
      skip,
      limit,
      instancePlanPagination.searchParams,
      {
        region: clusterRegion || '',
        scalingType: clusterScaling,
        provider: '',
        machineType: machineTypeMap.get(deploymentType) || 0,
      }
    );
    if (res?.error) {
      dispatch(
        addNotificationRtk({
          message: res.message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    }
    const instancePlans = res.akashMachineImages.map(
      (instancePlan: IInstancePlan) => {
        return {
          ...instancePlan,
          price: Number(instancePlan.defaultDailyTopUp),
          recommendedPlan: false,
        };
      }
    );
    const { instanceCreation } = getState() as {
      instanceCreation: IInstanceCreationState;
    };
    let uniqueAkashMachineImages;
    if (instanceCreation.clusterType === ClusterType.TEMPLATE) {
      if (instanceCreation.recommendedPlan !== null) {
        const updatedRecommendedPlan = instanceCreation.recommendedPlan;
        uniqueAkashMachineImages = instancePlans.filter(
          (plan: IInstancePlan) => plan._id !== updatedRecommendedPlan._id
        );
      }
    }
    const updatedPlans =
      instanceCreation.clusterType === ClusterType.TEMPLATE
        ? uniqueAkashMachineImages
        : instancePlans;
    return fulfillWithValue({
      totalCount: res.totalCount,
      akashMachineImages: updatedPlans,
    });
  }
);

export const updateDefaultCustomStoragePricesThunk = createAsyncThunk(
  'instanceCreation/updateDefaultCustomStoragePrices',
  async (payload: any, { dispatch, getState }) => {
    const { instanceCreation } = getState() as {
      instanceCreation: IInstanceCreationState;
    };
    const storageDefaultPlans = payload.find(
      (plan: any) => plan.type === 'storage'
    );
    const hddDefaultPlans = payload.find(
      (plan: any) => plan.type === 'persistent-storage-hdd'
    );
    const ssdDefaultPlans = payload.find(
      (plan: any) => plan.type === 'persistent-storage-ssd'
    );
    const nvmDefaultPlans = payload.find(
      (plan: any) => plan.type === 'persistent-storage-nvme'
    );
    dispatch(
      updateCustomStorageInputPriceRtk(
        storageDefaultPlans.values.find(
          (_: any) => _.value === instanceCreation.customStorageInput
        )?.price
      )
    );
    dispatch(
      updateCustomHddStorageInputPriceRtk(hddDefaultPlans.values[0].price)
    );
    dispatch(
      updateCustomSsdStorageInputPriceRtk(ssdDefaultPlans.values[0].price)
    );
    dispatch(
      updateCustomNvmStorageInputPriceRtk(nvmDefaultPlans.values[0].price)
    );
    dispatch(updateCustomHddStorageInputRtk(hddDefaultPlans.values[0].value));
    dispatch(updateCustomSsdStorageInputRtk(ssdDefaultPlans.values[0].value));
    dispatch(updateCustomNvmStorageInputRtk(nvmDefaultPlans.values[0].value));
  }
);

export const getCustomPlanThunk = createAsyncThunk(
  'instanceCreation/getCustomPlan',
  async (
    payload: { region: string; scalingMode: ScalingType },
    { dispatch, getState }
  ) => {
    const {
      instanceCreation: { deploymentType },
    } = getState() as { instanceCreation: IInstanceCreationState };
    try {
      const res = await getDefaultCustomPlans({
        region: payload.region,
        scalingMode: payload.scalingMode,
        provider: '',
      });
      if (res?.error) {
        dispatch(
          addNotificationRtk({
            message: res.message,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
      } else {
        const updatedRes = res.map(
          (item: { type: HardwareTypeEnum; values: any[] }) => {
            const updatedValues = item?.values?.map((valueItem) => {
              const formattedPrice = valueItem.price;
              const hourlyFormattedPrice = formattedPrice / 24;
              const monthlyFormattedPrice = formattedPrice * 30;
              const finalFormattedPrice =
                deploymentType === InstanceDeploymentType.ACCELERATE
                  ? hourlyFormattedPrice.toFixed(4)
                  : monthlyFormattedPrice.toFixed(2);
              const formattedPriceWithSign = `$${finalFormattedPrice}`;
              return {
                value: valueItem.value,
                price: formattedPriceWithSign,
              };
            });
            return {
              type: item.type,
              values: updatedValues,
            };
          }
        );
        dispatch(defaultCustomPlansValueRtk(updatedRes));
        dispatch(updateDefaultCustomStoragePricesThunk(updatedRes));
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }
);

export const fetchCustomPlanThunk = createAsyncThunk(
  'instanceCreation/fetchCustomPlan',
  async (payload: LoadingType, { dispatch, getState }) => {
    dispatch(
      togglePriceLoadingRtk({ priceLoading: true, priceLoadingType: payload })
    );
    try {
      const {
        organisation: { selectedOrganisation },
      } = getState() as { organisation: IOrganisationState };
      const { instanceCreation } = getState() as {
        instanceCreation: IInstanceCreationState;
      };
      const { cluster } = getState() as { cluster: IClusterState };
      const customStorage: number = instanceCreation.customStorage || 20;
      const [customPrice, storagePrice, persistentStoragePrice] =
        await Promise.all([
          customPriceService({
            scalingMode: instanceCreation.clusterScaling,
            region: instanceCreation.clusterRegion || '',
            customCpu: instanceCreation.customCpu,
            customRam: instanceCreation.customRam,
            customStorage: 0,
            customHddStorage: 0,
            customSddStorage: 0,
            customNvmStorage: 0,
            organisationId: selectedOrganisation._id,
            templateId: cluster.selectedTemplate?._id || '',
          }),
          customPriceService({
            scalingMode: instanceCreation.clusterScaling,
            region: instanceCreation.clusterRegion || '',
            customCpu: '',
            customRam: '',
            customStorage,
            customHddStorage: 0,
            customSddStorage: 0,
            customNvmStorage: 0,
            organisationId: selectedOrganisation._id,
            templateId: cluster.selectedTemplate?._id || '',
          }),
          customPriceService({
            scalingMode: instanceCreation.clusterScaling,
            region: instanceCreation.clusterRegion || '',
            customCpu: '',
            customRam: '',
            customStorage: 0,
            customHddStorage:
              instanceCreation.customPersistentStorage ===
              PersistentStorageOption.HDD
                ? instanceCreation.customHddStorage
                : 0,
            customSddStorage:
              instanceCreation.customPersistentStorage ===
              PersistentStorageOption.SSD
                ? instanceCreation.customSsdStorage
                : 0,
            customNvmStorage:
              instanceCreation.customPersistentStorage ===
              PersistentStorageOption.NVM
                ? instanceCreation.customNvmStorage
                : 0,
            organisationId: selectedOrganisation._id,
            templateId: cluster.selectedTemplate?._id || '',
          }),
        ]);
      const formattedCustomPrice = Number(customPrice?.resourceDailyCost);
      const formattedStoragePrice = Number(storagePrice?.resourceDailyCost);
      const formattedPersistentPrice = Number(
        persistentStoragePrice?.resourceDailyCost
      );
      if (
        (instanceCreation.customCpu &&
          instanceCreation.customRam &&
          instanceCreation.customCpu !== '' &&
          instanceCreation.customRam !== '' &&
          (formattedCustomPrice ||
            formattedStoragePrice ||
            formattedPersistentPrice)) ||
        instanceCreation.customStorage
      ) {
        if (
          instanceCreation.customCpu !== '' &&
          instanceCreation.customRam !== ''
        ) {
          const newCustomPlan = {
            _id: 'custom-id',
            name: '',
            cpu: Number(instanceCreation.customCpu),
            storage: `${instanceCreation.customStorage.toString()}Gi`,
            memory: `${instanceCreation.customRam}Gi`,
            maxPricePerBlock: 16,
            defaultDailyTopUp: 230400,
            topupThreashold: 460800,
            price:
              formattedCustomPrice ||
              formattedStoragePrice ||
              formattedPersistentPrice,
            recommendedPlan: false,
          };
          dispatch(updateCustomPlanRtk(newCustomPlan));
        }
      }
      // TODO: Update the state using fulfilled state
      dispatch(setUpdatedCustomPriceRtk(formattedCustomPrice));
      dispatch(setUpdatedStoragePriceRtk(formattedStoragePrice));
      dispatch(setUpdatedPersistentPriceRtk(formattedPersistentPrice));
      if (customPrice?.resourceDailyCost !== null) {
        dispatch(updatePlanAvailabilityRtk(customPrice.available));
      }
      dispatch(updateStorageAvailabilityRtk(storagePrice.available));
      dispatch(
        updatePersistentStorageAvailabilityRtk(persistentStoragePrice.available)
      );
      dispatch(
        setInstanceDiscounts({
          cpuMemory:
            customPrice.discount &&
            new Date(customPrice.discount?.expirationDate).getTime() -
              new Date().getTime()
              ? customPrice.discount
              : null,
          storage:
            storagePrice.discount &&
            new Date(storagePrice.discount?.expirationDate).getTime() -
              new Date().getTime()
              ? storagePrice.discount
              : null,
          peristentStorage:
            persistentStoragePrice.discount &&
            new Date(
              persistentStoragePrice.discount?.expirationDate
            ).getTime() - new Date().getTime()
              ? persistentStoragePrice.discount
              : null,
        })
      );
      dispatch(
        togglePriceLoadingRtk({
          priceLoading: false,
          priceLoadingType: payload,
        })
      );
    } catch (error) {
      dispatch(
        togglePriceLoadingRtk({
          priceLoading: false,
          priceLoadingType: payload,
        })
      );
      Sentry.captureException(error);
    }
  }
  // {
  //   condition: (payload: LoadingType, { getState }) => {
  //     const { subscription } = getState() as {
  //       subscription: ISubscriptionState;
  //     };
  //     if (payload === LoadingType.STORAGE && ) {
  //       return false;
  //     }
  //     return true;
  //   },
  // }
);

export const getInstanceUnitPricesThunk = createAsyncThunk(
  'instanceCreation/fetchUnitPrices',
  async (_: void, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await getInstanceUnitPrices();
      return fulfillWithValue(response as IPricingTargetsResponse);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const getAvailableInventoryThunk = createAsyncThunk(
  'instanceCreation/availableInventory',
  async (_: void, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await getAvailableInventory();
      return fulfillWithValue(response as IAvailableInventoryResponse);
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const calculatePriceThunk = createAsyncThunk(
  'instanceCreation/calculateInstancePrice',
  async (payload: LoadingType, { dispatch, getState }) => {
    dispatch(
      togglePriceLoadingRtk({ priceLoading: true, priceLoadingType: payload })
    );
    try {
      const { instanceCreation } = getState() as {
        instanceCreation: IInstanceCreationState;
      };
      const {
        customCpu,
        customRam,
        customStorage,
        customHddStorage,
        customNvmStorage,
        customSsdStorage,
        unitPrices,
        availableInventory,
      } = instanceCreation;
      const {
        cpu,
        memory,
        hdEphemeral,
        persistentStorageHDD,
        persistentStorageNVME,
        persistentStorageSSD,
        ip,
      } = unitPrices?.find((unitPrice) => {
        return (
          unitPrice.region === instanceCreation.clusterRegion &&
          unitPrice.scalingMode === instanceCreation.clusterScaling
        );
      })!;
      const cpuMemoryPrice = calculateInstancePrice({
        cpu: cpu / 30,
        numCpu: Number(customCpu),
        memory: memory / 30,
        numMemory: Number(customRam),
      });
      const storagePrice = calculateInstancePrice({
        hdEphemeral: hdEphemeral / 30,
        numHdEphemeral: Number(customStorage),
      });
      const persistentStoragePrice = calculateInstancePrice({
        persistentStorageHDD:
          instanceCreation.customPersistentStorage ===
          PersistentStorageOption.HDD
            ? persistentStorageHDD / 30
            : 0,
        persistentStorageSSD:
          instanceCreation.customPersistentStorage ===
          PersistentStorageOption.SSD
            ? persistentStorageSSD / 30
            : 0,
        persistentStorageNVME:
          instanceCreation.customPersistentStorage ===
          PersistentStorageOption.NVM
            ? persistentStorageNVME / 30
            : 0,
        numPersistentStorageHDD: customHddStorage,
        numPersistentStorageNVME: customNvmStorage,
        numPersistentStorageSSD: customSsdStorage,
      });
      const ipPrice = calculateInstancePrice({
        ip: ip / 30,
      });
      if (
        (customCpu &&
          customRam &&
          customCpu !== '' &&
          customRam !== '' &&
          (cpuMemoryPrice || storagePrice || persistentStoragePrice)) ||
        instanceCreation.customStorage
      ) {
        if (
          instanceCreation.customCpu !== '' &&
          instanceCreation.customRam !== ''
        ) {
          const newCustomPlan = {
            _id: 'custom-id',
            name: '',
            cpu: Number(instanceCreation.customCpu),
            storage: `${instanceCreation.customStorage.toString()}Gi`,
            memory: `${instanceCreation.customRam}Gi`,
            maxPricePerBlock: 16,
            defaultDailyTopUp: 230400,
            topupThreashold: 460800,
            price: cpuMemoryPrice || storagePrice || persistentStoragePrice,
            recommendedPlan: false,
          };
          dispatch(updateCustomPlanRtk(newCustomPlan));
        }
      }

      dispatch(setUpdatedCustomPriceRtk(cpuMemoryPrice));
      dispatch(setUpdatedStoragePriceRtk(storagePrice));
      dispatch(setUpdatedPersistentPriceRtk(persistentStoragePrice));
      dispatch(setIpLeasePrice(ipPrice));

      const demandRequest = {
        cpuRequested: Number(customCpu),
        memoryRequested: Number(customRam),
        ephemeralStorageRequested: customStorage,
        hddPersStorageRequested: customHddStorage,
        ssdPersStorageRequested: customNvmStorage,
        nvmePersStorageRequested: customSsdStorage,
      };

      let resourceAvailability: ResourcesAvailable =
        ResourcesAvailable.AVAILABLE;
      if (
        instanceCreation.clusterScaling !== ScalingType.NO &&
        availableInventory?.length > 0
      ) {
        const selectedResource: IAvailableInventory = availableInventory.find(
          (inventory) => inventory.region === instanceCreation.clusterRegion
        )!;
        if (
          !checkIfDemandFulfilled(demandRequest, selectedResource.resources)
        ) {
          resourceAvailability = ResourcesAvailable.NOT_AVAILABLE;
        }
        if (
          instanceCreation.ipLeaseType === IPLeaseType.DEDICATED &&
          !selectedResource.ip_supported
        ) {
          resourceAvailability = ResourcesAvailable.NOT_AVAILABLE;
        }
      }
      dispatch(updatePlanAvailabilityRtk(resourceAvailability));
      dispatch(updateStorageAvailabilityRtk(resourceAvailability));
      dispatch(updatePersistentStorageAvailabilityRtk(resourceAvailability));

      dispatch(
        togglePriceLoadingRtk({
          priceLoading: false,
          priceLoadingType: payload,
        })
      );
    } catch (error) {
      dispatch(
        togglePriceLoadingRtk({
          priceLoading: false,
          priceLoadingType: payload,
        })
      );
      Sentry.captureException(error);
    }
  }
);

export const multiserviceCalculatePriceThunk = createAsyncThunk(
  'instanceCreation/multiserviceCalculateInstancePrice',
  async (
    payload: { serviceId: string; loadingType: LoadingType },
    { dispatch, getState }
  ) => {
    dispatch(
      togglePriceLoadingRtk({
        priceLoading: true,
        priceLoadingType: payload.loadingType,
      })
    );
    try {
      const { instanceCreation } = getState() as {
        instanceCreation: IInstanceCreationState;
      };
      const { unitPrices, availableInventory, multiserviceInstanceCreation } =
        instanceCreation;
      const {
        customCpu,
        customRam,
        customStorage,
        customHddStorage,
        customNvmStorage,
        customSsdStorage,
        customPersistentStorage,
      } = multiserviceInstanceCreation![payload.serviceId];
      const {
        cpu,
        memory,
        hdEphemeral,
        persistentStorageHDD,
        persistentStorageNVME,
        persistentStorageSSD,
        ip,
      } = unitPrices?.find((unitPrice) => {
        return (
          unitPrice.region === instanceCreation.clusterRegion &&
          unitPrice.scalingMode === instanceCreation.clusterScaling
        );
      })!;
      const cpuMemoryPrice = calculateInstancePrice({
        cpu: cpu / 30,
        numCpu: Number(customCpu),
        memory: memory / 30,
        numMemory: Number(customRam),
      });
      const storagePrice = calculateInstancePrice({
        hdEphemeral: hdEphemeral / 30,
        numHdEphemeral: Number(customStorage),
      });
      const persistentStoragePrice = calculateInstancePrice({
        persistentStorageHDD:
          customPersistentStorage === PersistentStorageOption.HDD
            ? persistentStorageHDD / 30
            : 0,
        persistentStorageSSD:
          customPersistentStorage === PersistentStorageOption.SSD
            ? persistentStorageSSD / 30
            : 0,
        persistentStorageNVME:
          customPersistentStorage === PersistentStorageOption.NVM
            ? persistentStorageNVME / 30
            : 0,
        numPersistentStorageHDD: customHddStorage,
        numPersistentStorageNVME: customNvmStorage,
        numPersistentStorageSSD: customSsdStorage,
      });
      const ipPrice = calculateInstancePrice({
        ip: ip / 30,
      });
      if (
        (customCpu &&
          customRam &&
          customCpu !== '' &&
          customRam !== '' &&
          (cpuMemoryPrice || storagePrice || persistentStoragePrice)) ||
        customStorage
      ) {
        if (customCpu !== '' && customRam !== '') {
          const newCustomPlan = {
            _id: `custom-id-${payload.serviceId}`,
            name: '',
            cpu: Number(customCpu),
            storage: `${customStorage.toString()}Gi`,
            memory: `${customRam}Gi`,
            maxPricePerBlock: 16,
            defaultDailyTopUp: 230400,
            topupThreashold: 460800,
            price: cpuMemoryPrice || storagePrice || persistentStoragePrice,
            recommendedPlan: false,
          };
          dispatch(
            updateMultiServiceCustomPlan({
              serviceId: payload.serviceId,
              value: newCustomPlan,
            })
          );
        }
      }

      dispatch(
        setMultiServiceUpdatedCustomPrice({
          serviceId: payload.serviceId,
          value: cpuMemoryPrice,
        })
      );
      dispatch(
        setMultiServiceUpdatedStoragePrice({
          serviceId: payload.serviceId,
          value: storagePrice,
        })
      );
      dispatch(
        setMultiServiceUpdatedPersistentPrice({
          serviceId: payload.serviceId,
          value: persistentStoragePrice,
        })
      );
      dispatch(
        setMultiServiceIpLeasePrice({
          serviceId: payload.serviceId,
          value: ipPrice,
        })
      );
      const demandRequest = {
        cpuRequested: Number(customCpu),
        memoryRequested: Number(customRam),
        ephemeralStorageRequested: customStorage,
        hddPersStorageRequested: customHddStorage,
        ssdPersStorageRequested: customNvmStorage,
        nvmePersStorageRequested: customSsdStorage,
      };

      let resourceAvailability: ResourcesAvailable =
        ResourcesAvailable.AVAILABLE;
      if (
        instanceCreation.clusterScaling !== ScalingType.NO &&
        availableInventory?.length > 0
      ) {
        const selectedResource: IAvailableInventory = availableInventory.find(
          (inventory) => inventory.region === instanceCreation.clusterRegion
        )!;
        if (
          !checkIfDemandFulfilled(demandRequest, selectedResource.resources)
        ) {
          resourceAvailability = ResourcesAvailable.NOT_AVAILABLE;
        }
        if (
          instanceCreation.ipLeaseType === IPLeaseType.DEDICATED &&
          !selectedResource.ip_supported
        ) {
          resourceAvailability = ResourcesAvailable.NOT_AVAILABLE;
        }
      }
      dispatch(updatePlanAvailabilityRtk(resourceAvailability));
      dispatch(updateStorageAvailabilityRtk(resourceAvailability));
      dispatch(updatePersistentStorageAvailabilityRtk(resourceAvailability));

      dispatch(
        togglePriceLoadingRtk({
          priceLoading: false,
          priceLoadingType: payload.loadingType,
        })
      );
    } catch (error) {
      dispatch(
        togglePriceLoadingRtk({
          priceLoading: false,
          priceLoadingType: payload.loadingType,
        })
      );
      Sentry.captureException(error);
    }
  }
);
