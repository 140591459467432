import VisaIcon from '../../assets/compute/icons/visa-white-bg.svg';
import MastercardIcon from '../../assets/compute/icons/mastercard.svg';
import AmericanExpress from '../../assets/compute/icons/american-express.svg';
import Discover from '../../assets/compute/icons/discover.svg';
import JCB from '../../assets/compute/icons/jcb.svg';

export const getPaymentLogo = (brand: string) => {
  switch (brand) {
    case 'visa':
      return VisaIcon;
    case 'mastercard':
      return MastercardIcon;
    case 'amex':
      return AmericanExpress;
    case 'discover':
      return Discover;
    case 'jcb':
      return JCB;
    default:
      return VisaIcon;
  }
};
