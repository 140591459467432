import React from 'react';
import { Button } from '@spheron/ui-library';

interface IProps {
  loading: boolean;
  // eslint-disable-next-line no-unused-vars
  handleClick: () => void;
  classname?: string;
  disabled?: boolean;
  title?: string;
}

const LoadMore = ({
  loading,
  handleClick,
  classname,
  disabled,
  title,
}: IProps) => (
  <Button
    buttonType="ghost"
    label={loading ? 'LOADING...' : title}
    loading={loading}
    disabled={disabled}
    size="small"
    onClick={handleClick}
    classname={`justify-center !w-full ${classname}`}
  />
);

LoadMore.defaultProps = {
  classname: '',
  disabled: false,
  title: 'LOAD MORE',
};

export default LoadMore;
