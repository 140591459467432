import React from 'react';
import { useSelector } from 'react-redux';
import ChangeMemberRole from './change-member-role';
import ConnectWalletParameterModal from './connect-wallet-parameters';
import InviteMembersModal from './invite-members';
import RemoveActiveBonus from './remove-active-bonus';
import RemoveInvitedMember from './remove-invited-member';
import DisconnectWallet from './disconnect-wallet';
import DisconnectPhantomWallet from './disconnect-phantom-wallet';
import ViewWallet from './view-wallet';
import Overdue from './overdue';
import DisconnectLoginConnection from './disconnect-login-connection';
import PayOrganisationOverdue from './pay-overdue';
import SaveInstanceConfig from './save-instance-config';
import FundsModal from './funds';
import CreateTokenModal from './create-token';
import DeleteToken from './delete-token';
import CloseInstance from './close-instance';
import DeleteResource from './delete-resource';
import DeleteResourceConfirm from './delete-resource-confirm';
import DeletedResource from './deleted-resource';
import ViewPhantomWallet from './view-phantom-wallet';
import SolanaPay from './solana-pay';
import BonusPackage from './bonus-package';
import SaveHealthCheckConfig from './save-health-check-config';
import Invoice from './invoice';
import { RootState } from '../../../redux/compute/store';
import ConfirmRedeploySwitch from './confirm-redeploy-switch';
import ReferralRules from './referral-rules';
import AddCredit from './add-credit';
import RedeemSpheronCredit from './redeeem-spheron-credit';
import SpheronCreditApplicableList from './spheron-credit-applicable-list';
import DeleteSpheronCredit from './delete-spheron-credit';
import TopUpBalance from './top-up-balance';
import Confirmation from './confirmation';
import CreateNewComputeProject from './create-new-compute-project';
import { BulkAction } from './bulk-action';
import RestartInstance from './restart-instance';
import { EditName } from './edit-name';
import DedicatedIpConfirmationModal from './dedicated-ip-confirmation';
import BracketSystemInfo from './bracket-system-info';
import VideoPopup from './video-popup';
import OrgVisibilityToggle from './org-visibility-toggle';
import ChangedOrgVisibilityConfirmed from './change-org-visibility-confirmed';
import StripePaymentWrapper from './stripe-payment-wrapper';
import PrivateDockerCredentials from './private-docker-credentials';

const Modals = () => {
  const modal = useSelector((state: RootState) => state.modal.modals)?.at(-1);

  if (modal) {
    const { options, modalType } = modal;

    if (modalType === 'removeActiveBonus') {
      return (
        <RemoveActiveBonus
          isOpen
          bonusId={options.bonus_id}
          bonusName={options.bonus_name}
        />
      );
    }
    if (
      modalType === 'payOrganisationOverdue' ||
      modalType === 'solanaPayOrganisationOverdue'
    ) {
      return <PayOrganisationOverdue isOpen payDisable={options.payDisable} />;
    }

    if (modalType === 'disconnectWallet') {
      return <DisconnectWallet isOpen id={options.id} />;
    }
    if (modalType === 'disconnectPhantomWallet') {
      return (
        <DisconnectPhantomWallet
          id={options.id}
          isOpen
          walletAddress={options.walletAddress}
        />
      );
    }

    if (modalType === 'connectWalletParameters') {
      return <ConnectWalletParameterModal isOpen />;
    }
    if (modalType === 'funds') {
      return (
        <FundsModal isOpen type={options.type} walletId={options.walletId} />
      );
    }
    if (modalType === 'viewWallet') {
      return <ViewWallet isOpen walletId={options.walletId} />;
    }
    if (modalType === 'deleteToken') {
      return <DeleteToken isOpen tokens={options.tokens} />;
    }
    if (modalType === 'createToken') {
      return <CreateTokenModal isOpen isOrgSelected={options?.isOrgSelected} />;
    }
    if (modalType === 'privateDockerCredentials') {
      return <PrivateDockerCredentials isOpen />;
    }
    if (modalType === 'inviteMembers') {
      return (
        <InviteMembersModal
          isOpen
          organisationName={options.organisationName}
          organisationId={options.organisationId}
        />
      );
    }
    if (modalType === 'bonusPackage') {
      return <BonusPackage isOpen />;
    }
    if (modalType === 'removeInvitedMember') {
      return (
        <RemoveInvitedMember
          isOpen
          memberEmail={options.memberEmail}
          organisationId={options.organisation_id}
          memberId={options.member_id}
        />
      );
    }
    if (modalType === 'changeMemberRole') {
      return (
        <ChangeMemberRole
          isOpen
          memberId={options.member_Id}
          memberName={options.member_name}
          currentRole={options.currentRole}
          role={options.role}
          setDropdownRole={options.setDropdownRole}
        />
      );
    }
    if (modalType === 'overdue') {
      return <Overdue isOpen />;
    }
    if (modalType === 'disconnectLoginConnection') {
      return <DisconnectLoginConnection isOpen provider={options.provider} />;
    }
    if (modalType === 'saveInstanceConfig') {
      return (
        <SaveInstanceConfig isOpen instanceConfig={options.instanceConfig} />
      );
    }
    if (modalType === 'restartInstance') {
      return <RestartInstance isOpen instanceConfig={options.instanceConfig} />;
    }
    if (modalType === 'saveHealthCheckConfig') {
      return <SaveHealthCheckConfig isOpen healthCheckConfig={options} />;
    }
    if (modalType === 'closeInstance') {
      return <CloseInstance instanceId={options.instanceId} isOpen />;
    }

    if (modalType === 'deleteResource') {
      return (
        <DeleteResource
          isOpen
          resourceType={options.resourceType}
          resource={options.resource}
          handleClick={options.handleClick}
        />
      );
    }

    if (modalType === 'deleteResourceConfirm') {
      return (
        <DeleteResourceConfirm
          isOpen
          handleClick={options.handleClick}
          resource={options.resource}
        />
      );
    }

    if (modalType === 'deletedResource') {
      return <DeletedResource isOpen resource={options.resource} />;
    }
    if (modalType === 'solanaPay') {
      return (
        <SolanaPay
          isOpen
          amount={options.amount}
          type={options.type}
          walletAddress={options.walletAddress}
        />
      );
    }
    if (modalType === 'viewPhantomWallet') {
      return <ViewPhantomWallet id={options.id} isOpen />;
    }

    if (modalType === 'invoices') {
      return <Invoice isOpen invoiceId={options.invoiceId} />;
    }

    if (modalType === 'confirmRedeploySwitch') {
      return (
        <ConfirmRedeploySwitch
          isOpen
          type={options.redeploySwitchType}
          confirmCallback={options.confirmCallback}
        />
      );
    }

    if (modalType === 'referralRules') {
      return <ReferralRules isOpen />;
    }

    if (modalType === 'addCredit') {
      return <AddCredit isOpen />;
    }

    if (modalType === 'redeemSpheronCredit') {
      return (
        <RedeemSpheronCredit
          isOpen
          code={options.code}
          message={options.message}
          state={options.state}
        />
      );
    }

    if (modalType === 'spheronCreditApplicableList') {
      return (
        <SpheronCreditApplicableList
          isOpen
          applicableFor={options.applicableFor}
        />
      );
    }

    if (modalType === 'deleteSpheronCredit') {
      return (
        <DeleteSpheronCredit
          isOpen
          creditCoupon={options.creditCoupon}
          couponName={options.couponName}
        />
      );
    }
    if (modalType === 'topUpBalance') {
      return <TopUpBalance isOpen />;
    }

    if (modalType === 'confirmation') {
      return (
        <Confirmation
          isOpen
          handleProceed={options.handleProceed}
          loading={options.loading}
          text={options.text}
        />
      );
    }

    if (modalType === 'createNewComputeProject') {
      return (
        <CreateNewComputeProject isOpen followUpFn={options?.followUpFn} />
      );
    }
    if (modalType === 'dedicatedIpConfirmation') {
      return (
        <DedicatedIpConfirmationModal
          isOpen
          serviceId={options.serviceId}
          multiservice={options.multiservice}
        />
      );
    }
    if (modalType === 'bulkAction') {
      return <BulkAction isOpen handleProceed={options.handleProceed} />;
    }

    if (modalType === 'editName') {
      return (
        <EditName
          id={options.id}
          loading={options.loading}
          resource={options.resource}
          isOpen
        />
      );
    }

    if (modalType === 'bracketSystemInfo') {
      return <BracketSystemInfo isOpen />;
    }

    if (modalType === 'videoPopup') {
      return <VideoPopup iFrame={options.iframe} isOpen />;
    }

    if (modalType === 'toggleOrgVisibility') {
      return (
        <OrgVisibilityToggle
          isOpen
          visibilityAction={options.visibilityAction}
          handleClick={options.handleClick}
          organisationName={options.organisationName}
        />
      );
    }

    if (modalType === 'changeOrgVisibilityConfirm') {
      return (
        <ChangedOrgVisibilityConfirmed
          isOpen
          visibilityAction={options.visibilityAction}
          link={options.link}
          organisationName={options.organisationName}
        />
      );
    }

    if (modalType === 'stripePayment') {
      return (
        <>
          <StripePaymentWrapper isOpen />
        </>
      );
    }

    return <></>;
  }
  return <></>;
};

export default Modals;
