/* eslint-disable import/no-cycle */
import * as Sentry from '@sentry/react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import * as bs58 from 'bs58';
import { NotificationType } from '../notification/notification.interfaces';
import { addNotificationRtk } from '../notification/notification.slice';
import {
  activatePayOverdueReservationService,
  activateReservationService,
  cancelReservationService,
  createReservationService,
  getSolTokenBalanceService,
  phantomConnectWalletService,
  phantomSignAndSendTransactionService,
  phantomSignMessageService,
} from './solana.services';
import config from '../../../config';
import { saveWalletThunk } from '../web3/web3.thunks';
import {
  IConfigureWalletPayloadDtoRtk,
  IGetBalance,
  IWalletConfiguredPayloadDto,
} from '../web3/web3.interfaces';
import { RootState } from '../store';
import { getNetworkFromName } from '../web3/web3.utils';
import { removeWalletService } from '../organisation/organisation.services';
import {
  IOrgWallet,
  IRemoveWalletPayloadDto,
  IRemoveWalletResponsePayloadDto,
} from '../organisation/organisation.interfaces';
import { toggleModalShowRtk } from '../modal/modal.slice';
import { IResponseError } from '../combined-reducer.interface';
import {
  ISolanaCreateActivationDto,
  ISolanaCreateReservationDto,
  ISolanaCreateTransactionDto,
  ISolanaReserveResponse,
} from './solana.interfaces';
import { setSolanaPayStepInfoRtk } from './solana.slice';
import { ReservationType } from './solana.utils';
import {
  removeWalletRtk,
  toggleConnectWalletLoadingRtk,
  toggleDisconnectWalletLoadingRtk,
  updateCryptoWalletPrimaryPaymentRtk,
  initialState as WEB3_INITIAL_STATE,
} from '../web3/web3.slice';
import { updateCreditCardPrimaryPayment } from '../stripe/stripe.slice';
import { updateDefaultPaymentMethodRtk } from '../organisation/organisation.slice';
import { createBonusReservationService } from '../subscription/subscription.services';
import {
  ICreateBonusReservationDto,
  IProcessor,
  IProcessorType,
} from '../subscription/subscription.interfaces';

export const configurePhantomWalletThunk = createAsyncThunk(
  'solana/configurePhantomWallet',
  async (
    payload: IConfigureWalletPayloadDtoRtk,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      dispatch(toggleConnectWalletLoadingRtk(true));
      const walletResponse = await phantomConnectWalletService();
      if (!walletResponse.error) {
        const balanceResponse = await getSolTokenBalanceService(
          walletResponse.account
        );
        if (!balanceResponse.error) {
          const signResponse = await phantomSignMessageService(
            config.web3.ENABLE_VERIFYING_MESSAGE
          );
          if (!signResponse.error) {
            const res: IWalletConfiguredPayloadDto = {
              walletAddress: walletResponse.account,
              tokenBalance: Number(balanceResponse.balance),
              signature: bs58.encode(signResponse.signedMessage.signature),
            };
            dispatch(
              saveWalletThunk({
                organisationId: payload.selectedOrganisationId,
                wType: 'PHANTOM',
                web3State: {
                  ...WEB3_INITIAL_STATE,
                  selectedNetwork: payload.selectedNetwork,
                  selectedToken: payload.selectedToken,
                  currentAccount: res.walletAddress,
                  selectedTokenBalance: Number(res.tokenBalance),
                  signature: res.signature,
                },
              })
            );
            return fulfillWithValue(res);
          }
          dispatch(
            addNotificationRtk({
              message: signResponse.errorMessage,
              timestamp: Date.now(),
              type: NotificationType.Error,
            })
          );
          dispatch(toggleConnectWalletLoadingRtk(false));
          return rejectWithValue({});
        }
        dispatch(
          addNotificationRtk({
            message: balanceResponse.errorMessage,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
        dispatch(toggleConnectWalletLoadingRtk(false));
        return rejectWithValue({});
      }
      dispatch(
        addNotificationRtk({
          message: walletResponse.errorMessage,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      dispatch(toggleConnectWalletLoadingRtk(false));
      return rejectWithValue([]);
    } catch (error) {
      Sentry.captureException(error);
      dispatch(toggleConnectWalletLoadingRtk(false));
      return rejectWithValue({});
    }
  }
);

export const disconnectPhantomWalletThunk = createAsyncThunk(
  'solana/disconnectPhantomWallet',
  async (
    payload: {
      id: string;
      address: string;
    },
    { rejectWithValue, fulfillWithValue, dispatch, getState }
  ) => {
    const { organisation, stripe, web3 } = getState() as RootState;
    dispatch(toggleDisconnectWalletLoadingRtk(true));
    try {
      const walletResponse = await phantomConnectWalletService();
      if (!walletResponse.error) {
        const signResponse = await phantomSignMessageService(
          config.web3.REMOVAL_VERIFYING_MESSAGE
        );
        if (!signResponse.error) {
          const apiPayload = {
            id: payload.id as string,
            signature: bs58.encode(signResponse.signedMessage.signature),
            organizationId: organisation.selectedOrganisation._id,
            network: getNetworkFromName(
              Number(
                organisation.selectedOrgWalletConfig.networkDetails?.chainId
              )
            ),
          };
          const apiResponse = await removeWalletService(
            apiPayload as IRemoveWalletPayloadDto
          );
          if (!(apiResponse as IResponseError).error) {
            dispatch(toggleModalShowRtk({ modalShow: false }));
            const primaryCreditCard = stripe.creditCards.find(
              (creditCard) =>
                creditCard._id ===
                (apiResponse as IRemoveWalletResponsePayloadDto).primaryWallet
            );

            const primaryCryptoWallet = web3.wallets.find(
              (wallet) =>
                wallet._id ===
                (apiResponse as IRemoveWalletResponsePayloadDto).primaryWallet
            );
            if (primaryCreditCard) {
              dispatch(
                updateCreditCardPrimaryPayment({ id: primaryCreditCard._id })
              );
            } else if (primaryCryptoWallet) {
              dispatch(
                updateCryptoWalletPrimaryPaymentRtk({
                  id: primaryCryptoWallet._id,
                })
              );
            }
            dispatch(
              updateDefaultPaymentMethodRtk(
                (primaryCryptoWallet ||
                  primaryCreditCard) as unknown as IOrgWallet
              )
            );
            dispatch(removeWalletRtk(payload.id));
            dispatch(toggleDisconnectWalletLoadingRtk(false));
            return fulfillWithValue(payload.id);
          }
          dispatch(
            addNotificationRtk({
              message: (apiResponse as IResponseError).message,
              timestamp: Date.now(),
              type: NotificationType.Error,
            })
          );

          dispatch(toggleDisconnectWalletLoadingRtk(false));
          return rejectWithValue({});
        }
        dispatch(
          addNotificationRtk({
            message: signResponse.errorMessage,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );

        dispatch(toggleDisconnectWalletLoadingRtk(false));
        return rejectWithValue({});
      }
      dispatch(
        addNotificationRtk({
          message: walletResponse.errorMessage,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );

      dispatch(toggleDisconnectWalletLoadingRtk(false));
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);

      dispatch(toggleDisconnectWalletLoadingRtk(false));
      return rejectWithValue({});
    }
  }
);

export const fetchSolBalanceThunk = createAsyncThunk(
  'solana/fetchSolBalance',
  async (
    payload: IGetBalance,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const walletResponse = await phantomConnectWalletService();
      if (!walletResponse.error) {
        const balanceResponse = await getSolTokenBalanceService(
          payload.address
        );
        if (!balanceResponse.error) {
          if (balanceResponse.balance === null) {
            return fulfillWithValue({ amount: '', token: payload.token });
          }
          return fulfillWithValue({
            amount: String(balanceResponse.balance),
            token: payload.token,
          });
        }
        dispatch(
          addNotificationRtk({
            message: balanceResponse.errorMessage,
            timestamp: Date.now(),
            type: NotificationType.Error,
          })
        );
      }
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const createActivationThunk = createAsyncThunk(
  'solana/createActivation',
  async (
    payload: ISolanaCreateActivationDto,
    { rejectWithValue, fulfillWithValue, dispatch, getState }
  ) => {
    const { organisation } = getState() as RootState;
    dispatch(
      setSolanaPayStepInfoRtk({
        step: 3,
        value: { error: false, success: false, loading: true, message: '' },
      })
    );
    try {
      let activateResBody;
      const {
        walletAddress,
        transactionHash,
        reservationId,
        subscriptionType,
      } = payload;

      if (payload.subscriptionType === 'NEW_MEMBER' && payload.subscriptionType)
        activateResBody = {
          organizationId: organisation.selectedOrganisation._id,
          type: subscriptionType,
          processorType: IProcessorType.SMART_CONTRACT,
          processor: IProcessor.SOLANA,
          details: {
            tokenAddress: config.web3.solana.TOKEN_ADDRESS,
            walletAddress,
            transactionHash,
            reservationId,
          },
        };
      else
        activateResBody = {
          organizationId: organisation.selectedOrganisation._id,
          processorType: IProcessorType.SMART_CONTRACT,
          processor: IProcessor.SOLANA,
          details: {
            reservationId,
            transactionHash,
            walletAddress,
            tokenAddress: config.web3.solana.TOKEN_ADDRESS,
          },
        };

      await new Promise((resolve) =>
        setTimeout(resolve, config.web3.solana.VERIFY_TRANSACTION_DELAY)
      );

      // TO CALL SERVICE ACCORDING TO RESERVATION TYPE, e.g PAYOVERDUE
      let activateResponse;

      if (payload.type === ReservationType.PayOverdue) {
        activateResponse = await activatePayOverdueReservationService(
          activateResBody
        );
      } else {
        activateResponse = await activateReservationService(
          activateResBody,
          payload.type
        );
      }

      if (activateResponse.success) {
        dispatch(
          setSolanaPayStepInfoRtk({
            step: 3,
            value: { error: false, success: true, loading: false, message: '' },
          })
        );
        dispatch(toggleModalShowRtk({ modalShow: false }));
        if (payload.history) {
          payload.history({
            pathname: `/${organisation.currentApp}/billing/plan`,
            state: {
              date: Date.now(),
            },
          });
        }
        dispatch(setSolanaPayStepInfoRtk({ step: 0 }));
        return fulfillWithValue({});
      }
      dispatch(
        setSolanaPayStepInfoRtk({
          step: 3,
          value: {
            error: true,
            success: false,
            loading: false,
            message: activateResponse.message,
          },
        })
      );
      dispatch(
        addNotificationRtk({
          message: (activateResponse as IResponseError).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const createTransactionThunk = createAsyncThunk(
  'solana/createTransaction',
  async (
    payload: ISolanaCreateTransactionDto,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    dispatch(
      setSolanaPayStepInfoRtk({
        step: 1,
        value: { error: false, success: true, loading: false, message: '' },
      })
    );
    dispatch(
      setSolanaPayStepInfoRtk({
        step: 2,
        value: { error: false, success: false, loading: true, message: '' },
      })
    );
    try {
      const {
        reservationId,
        history,
        type,
        description,
        amount,
        subscriptionType,
      } = payload;

      const transResponse = await phantomSignAndSendTransactionService(
        reservationId,
        description,
        config.web3.solana.SET_HARDCODE_AMOUNT
          ? amount * config.web3.solana.HARDCODE_AMOUNT_MULTIPLIER
          : amount
      );
      if (!transResponse.error) {
        dispatch(
          setSolanaPayStepInfoRtk({
            step: 2,
            value: { error: false, success: true, loading: false, message: '' },
          })
        );
        dispatch(
          createActivationThunk({
            walletAddress: payload.walletAddress || '',
            transactionHash: transResponse.signature,
            reservationId,
            history,
            type,
            subscriptionType,
          })
        );
        return fulfillWithValue(transResponse.signature);
      }
      dispatch(
        setSolanaPayStepInfoRtk({
          step: 2,
          value: { error: true, success: false, loading: false, message: '' },
        })
      );
      dispatch(
        addNotificationRtk({
          message: transResponse.errorMessage,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const createReservationThunk = createAsyncThunk(
  'solana/createReservation',
  async (
    payload: ISolanaCreateReservationDto,
    { rejectWithValue, fulfillWithValue, dispatch, getState }
  ) => {
    dispatch(
      setSolanaPayStepInfoRtk({
        step: 1,
        value: { error: false, success: false, loading: true, message: '' },
      })
    );
    try {
      const { organisation } = getState() as RootState;
      const { planId, numberOfSeats, type, amount, history } = payload;
      const reserveResponse = await createReservationService({
        subscriptionPackageId: planId,
        organizationId: organisation.selectedOrganisation._id,
        numberOfSeats,
        type,
      });
      if ((reserveResponse as ISolanaReserveResponse).success) {
        dispatch(
          setSolanaPayStepInfoRtk({
            step: 1,
            value: { error: false, success: true, loading: false, message: '' },
          })
        );
        // DISPATCH CREATE SOLANA TRANSACTION
        dispatch(
          createTransactionThunk({
            reservationId: (reserveResponse as ISolanaReserveResponse)
              .reservationId,
            description: (reserveResponse as ISolanaReserveResponse)
              .description,
            amount,
            history,
            type: ReservationType.Subscription,
            subscriptionType: type,
            walletAddress: payload.walletAddress || '',
          })
        );
        return fulfillWithValue({
          reservationId: (reserveResponse as ISolanaReserveResponse)
            .reservationId,
          description: (reserveResponse as ISolanaReserveResponse).description,
        });
      }
      dispatch(
        setSolanaPayStepInfoRtk({
          step: 1,
          value: {
            error: true,
            success: false,
            loading: false,
            message: reserveResponse.message,
          },
        })
      );
      dispatch(
        addNotificationRtk({
          message: (reserveResponse as IResponseError).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const createBonusReservationThunk = createAsyncThunk(
  'solana/createBonusReservation',
  async (
    payload: ICreateBonusReservationDto,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      dispatch(setSolanaPayStepInfoRtk({ step: 0 }));
      const { bonusParameterNames, bonusParameterValues, details } = payload;
      const { amount } = details;
      const indexOfBuildExecution = (bonusParameterNames as string[]).indexOf(
        'BONUS_BUILD_EXECUTION'
      );
      const indexOfComputeBuildExecution = (
        bonusParameterNames as string[]
      ).indexOf('BONUS_CLUSTER_BUILD_EXECUTION');
      const indexOfArweaveStorage = (bonusParameterNames as string[]).indexOf(
        'BONUS_STORAGE_ARWEAVE'
      );
      const indexOfIPFSStorage = (bonusParameterNames as string[]).indexOf(
        'BONUS_STORAGE_IPFS'
      );

      bonusParameterValues[indexOfBuildExecution] =
        Number(bonusParameterValues[indexOfBuildExecution]) * 60;
      bonusParameterValues[indexOfComputeBuildExecution] =
        Number(bonusParameterValues[indexOfComputeBuildExecution]) * 60;
      bonusParameterValues[indexOfArweaveStorage] =
        Number(bonusParameterValues[indexOfArweaveStorage]) * 1024;
      bonusParameterValues[indexOfIPFSStorage] =
        Number(bonusParameterValues[indexOfIPFSStorage]) * 1024;

      const reserveResponse = await createBonusReservationService(payload);
      if ((reserveResponse as ISolanaReserveResponse).success) {
        dispatch(
          setSolanaPayStepInfoRtk({
            step: 1,
            value: { error: false, success: true, loading: false, message: '' },
          })
        );
        dispatch(
          createTransactionThunk({
            reservationId: (reserveResponse as ISolanaReserveResponse)
              .reservationId,
            description: (reserveResponse as ISolanaReserveResponse)
              .description,
            amount,
            history: null,
            type: ReservationType.Bonus,
            walletAddress: payload?.details?.walletAddress || '',
          })
        );
        return fulfillWithValue({
          reservationId: (reserveResponse as ISolanaReserveResponse)
            .reservationId,
          description: (reserveResponse as ISolanaReserveResponse).description,
        });
      }
      dispatch(
        setSolanaPayStepInfoRtk({
          step: 1,
          value: {
            error: true,
            success: false,
            loading: false,
            message: (reserveResponse as IResponseError).message,
          },
        })
      );
      dispatch(
        addNotificationRtk({
          message: (reserveResponse as IResponseError).message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);

export const cancelReservationThunk = createAsyncThunk(
  'solana/cancelReservationThunk',
  async (
    payload: string,
    { rejectWithValue, fulfillWithValue, dispatch, getState }
  ) => {
    try {
      const { organisation } = getState() as RootState;
      const cancelResponse = await cancelReservationService({
        subscriptionId: payload,
        organizationId: organisation.selectedOrganisation._id,
      });
      if (cancelResponse.success) {
        dispatch(
          addNotificationRtk({
            message: cancelResponse.message,
            timestamp: Date.now(),
            type: NotificationType.Success,
          })
        );
        return fulfillWithValue({});
      }
      dispatch(
        addNotificationRtk({
          message: cancelResponse.message,
          timestamp: Date.now(),
          type: NotificationType.Error,
        })
      );
      return rejectWithValue({});
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue({});
    }
  }
);
