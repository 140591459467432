import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  Button,
  TextInput,
  SectionHeading,
  Table,
  Badge,
  CellType,
  RowType,
  ActionItemType,
} from '@spheron/ui-library';
import dayjs from 'dayjs';
import { ReactComponent as InviteIcon } from '@spheron/ui-library/dist/assets/add-user.svg';
import { useDispatch, useSelector } from 'react-redux';
import MembersStyle from '../../../../styles/compute/members.module.scss';
import { ReactComponent as SearchIcon } from '../../../../assets/compute/icons/search.svg';
import GlobalStyles from '../../../../styles/compute/global.module.scss';
import {
  IOrganization,
  IUser,
} from '../../../../redux/compute/combined-state.interface';
import { toggleModalShowRtk } from '../../../../redux/compute/modal/modal.slice';
import { RootState } from '../../../../redux/rtk-store';
import { getOrganisationMembersThunk } from '../../../../redux/compute/organisation/organisation.thunks';
import Role from '../../../../components/Compute/Role/role';
import { withLoader } from '../../../../redux/compute/root-utils';
import { copyToClipboard } from '../../../../redux/compute/user/user.utils';
import { AppDispatch } from '../../../../redux/compute/store';

const Members = () => {
  const isPublicAccessible =
    sessionStorage.getItem('isPublicAccessible') === 'true';
  const communityUserAccess =
    sessionStorage.getItem('communityUserAccess') === 'true';

  const dispatchRtk = useDispatch<AppDispatch>();
  const [searchValue, setSearchValue] = useState<string>('');
  const [users, setUsers] = useState<IUser[]>([]);
  const [nameSorting, setNameSorting] = useState<string>('a-z');
  const [sortingType, setSortingType] = useState<string>('oldest');
  const [currentUserRole, setCurrentUserRole] = useState<string>();
  const [linkCopied, setLinkCopied] = useState<boolean>(false);
  const selectedOrganisation: IOrganization | null = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation
  );
  const selectedOrganisationLoading: boolean = useSelector(
    (state: RootState) => state.organisation.selectedOrganisationLoading
  );
  const userRoleLoading: boolean = useSelector(
    (state: RootState) => state.organisation.userRoleLoading
  );
  const user = useSelector((state: RootState) => state.user.user);
  const allUsers: IUser[] = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation.users
  );
  const getMembersLoading: boolean = useSelector(
    (state: RootState) => state.organisation.getMembersLoading
  );
  const invitedMembers = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation.invitedMembers
  );

  const inviteMembersLoading = useSelector(
    (state: RootState) => state.organisation.inviteMemberLoading
  );

  const membersLoading = getMembersLoading || selectedOrganisationLoading;

  useEffect(() => {
    if (searchValue.length === 0 && selectedOrganisation) {
      setUsers(allUsers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, allUsers, membersLoading]);

  useEffect(() => {
    if (users) {
      users.forEach((u: any) => {
        if (u._id === user?._id) {
          setCurrentUserRole(u?.platformProfile?.role);
        }
      });
    }
  }, [currentUserRole, user?._id, users]);

  const isMember =
    allUsers?.find((u) => u?._id === user?._id)?.platformProfile?.role ===
      'member' && user !== null;

  const refreshMembers = () => {
    dispatchRtk(getOrganisationMembersThunk(selectedOrganisation!._id));
  };

  const canUserNotRemoved = (person: IUser) =>
    person?.platformProfile?.role === 'owner' ||
    person?.platformProfile?.username === user?.platformProfile?.username ||
    currentUserRole === 'member' ||
    (currentUserRole === 'admin' &&
      person?.platformProfile?.role === 'owner') ||
    (currentUserRole === 'admin' && person?.platformProfile?.role === 'admin');

  const handleSorting = (e: string) => {
    setSortingType(e);
    if (e === 'newest') {
      setUsers(
        [...users].sort((a: IUser, b: IUser) => {
          return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
        })
      );
    } else if (e === 'oldest') {
      setUsers(
        [...users].sort((a: IUser, b: IUser) => {
          return (
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          );
        })
      );
    }
  };

  const handleSortByName = (sorting: string) => {
    setNameSorting(sorting);
    if (nameSorting === 'a-z') {
      setUsers(
        [...users].sort((a: IUser, b: IUser) => {
          return a.platformProfile.name.localeCompare(b.platformProfile.name);
        })
      );
    } else if (nameSorting === 'z-a') {
      setUsers(
        [...users].sort((a: IUser, b: IUser) => {
          return b.platformProfile.name.localeCompare(a.platformProfile.name);
        })
      );
    }
  };

  const handleUserSearch = (value: string) => {
    setUsers(
      allUsers?.filter(
        (element: IUser) =>
          element.platformProfile.username?.toLowerCase().includes(value) ||
          element.platformProfile.name?.toLowerCase().includes(value) ||
          element.platformProfile.email?.toLowerCase().includes(value)
      )
    );
  };

  const tableHeaders = [
    {
      id: 0,
      title: 'Name',
      hasSort: true,
      onSortClick: () => {
        handleSortByName(nameSorting === 'a-z' ? 'z-a' : 'a-z');
      },
      tableCellType: 'header' as CellType,
    },
    {
      id: 1,
      title: 'Username',
      tableCellType: 'header' as CellType,
    },
    {
      id: 2,
      title: 'Email',
      tableCellType: 'header' as CellType,
    },
    {
      id: 3,
      title: 'Role',
      tableCellType: 'header' as CellType,
    },
    {
      id: 4,
      title: 'Created On',
      hasSort: true,
      onSortClick: () =>
        handleSorting(sortingType === 'newest' ? 'oldest' : 'newest'),
      tableCellType: 'header' as CellType,
    },
    {
      id: 5,
      title: 'Status',
      tableCellType: 'header' as CellType,
    },
  ];

  const removeInvitedMember = (memberId: string) => {
    if (!isMember) {
      dispatchRtk(
        toggleModalShowRtk({
          modalShow: true,
          modalType: 'removeInvitedMember',
          options: {
            organisation_id: selectedOrganisation?._id,
            member_id: memberId,
          },
        })
      );
    }
  };

  return (
    <div className={GlobalStyles.screen__width}>
      <div className="top-section-content mx-auto mt-4 flex sm:flex-row flex-col gap-4 justify-between">
        <div className="flex flex-row gap-4 items-center justify-between w-full">
          <SectionHeading
            showRefresh
            showSwitch={false}
            subHeading="Effortlessly Invite, Manage Roles, and Enhance Collaboration within Your Organization"
            heading="Organisation's Members"
            handleIconClick={() => {}}
            handleRefreshClick={refreshMembers}
            handleSwitchClick={() => {}}
            switchDisable={false}
            handleClick={() => {}}
            loading={membersLoading}
            time=""
            refreshType="default"
            showText={false}
            toggleId=""
            isChecked={false}
            handleChange={() => {}}
          />
          <div className="flex items-center justify-start gap-x-3">
            <TextInput
              inputSize="compact"
              leftIcon={
                <SearchIcon
                  className="text-base-para-text-color
                        dark:text-dark-base-para-text-color"
                />
              }
              value={searchValue}
              placeholder="Search Users"
              onChange={(value) => {
                handleUserSearch(value as string);
                setSearchValue(value as string);
              }}
            />
            <div className="top-right min-w-max">
              {membersLoading ? (
                <Skeleton width={168} height={36} containerClassName="flex" />
              ) : (
                <Button
                  buttonType="primary"
                  label="Add Members"
                  size="medium"
                  leftIcon={<InviteIcon className="w-5 h-5" />}
                  onClick={() => {
                    dispatchRtk(
                      toggleModalShowRtk({
                        modalShow: true,
                        modalType: 'inviteMembers',
                        options: {
                          organisationName: selectedOrganisation!.profile.name,
                          organisationId: selectedOrganisation!._id!,
                        },
                      })
                    );
                  }}
                  disabled={
                    isMember || (isPublicAccessible && !communityUserAccess)
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className={`${
          isMember
            ? MembersStyle.table__container__disabled
            : MembersStyle.table__container
        } dark:bg-dark-base-bg`}
      >
        <Table
          tableHeader={{
            tableRowType: 'header',
            tableRow: [
              ...tableHeaders,
              {
                id: 6,
                title: '',
                tableCellType: 'header' as CellType,
              },
            ],
          }}
          tableRows={
            users?.length > 0 && !membersLoading && !inviteMembersLoading
              ? [
                  ...users?.map((currentUser) => ({
                    id: parseInt(currentUser._id, 10),
                    tableRowType: 'record' as RowType,
                    tableRow: [
                      {
                        id: 0,
                        tableCellType: 'default' as CellType,
                        content: (
                          <div className="flex items-center justify-start gap-x-3">
                            <img
                              src={currentUser?.platformProfile?.avatar}
                              alt=""
                              className="mr-3 h-8 w-8 rounded-full"
                            />
                            <span>{currentUser.platformProfile.name}</span>
                          </div>
                        ),
                      },
                      {
                        id: 1,
                        tableCellType: 'default' as CellType,
                        content: (
                          <Badge
                            isBold
                            badgeType="info"
                            solid={false}
                            size="medium"
                            text={currentUser?.platformProfile?.username || ''}
                          />
                        ),
                      },
                      {
                        id: 2,
                        title: currentUser?.platformProfile?.email || 'N.A',
                        tableCellType: 'title' as CellType,
                      },
                      {
                        id: 3,
                        tableCellType: 'default' as CellType,
                        content: (
                          <>
                            {withLoader(
                              userRoleLoading ||
                                !currentUser?.platformProfile?.role,
                              <Skeleton
                                height={26}
                                width={100}
                                duration={2}
                                containerClassName="flex"
                              />,
                              <Role
                                currentUserRole={currentUserRole || ''}
                                role={
                                  currentUser?.platformProfile?.role || 'member'
                                }
                                modalOption={{
                                  member_Id: currentUser._id,
                                  member_name:
                                    currentUser?.platformProfile?.name,
                                }}
                                isCurrentUser={user?._id === currentUser._id}
                              />
                            )}
                          </>
                        ),
                      },
                      {
                        id: 4,
                        title: dayjs(currentUser?.createdAt).format(
                          'MMM DD YYYY'
                        ),
                        tableCellType: 'title' as CellType,
                      },
                      {
                        id: 5,
                        tableCellType: 'default' as CellType,
                        content: (
                          <Badge
                            isBold
                            badgeType="success"
                            solid={false}
                            size="medium"
                            text="Active"
                          />
                        ),
                      },
                      {
                        id: 6,
                        tableCellType: 'action' as CellType,
                        actionButtons:
                          canUserNotRemoved(currentUser) ||
                          userRoleLoading ||
                          membersLoading
                            ? []
                            : [
                                {
                                  actionButtonId: 1,
                                  actionType: 'delete' as ActionItemType,
                                  onClick: () =>
                                    dispatchRtk(
                                      toggleModalShowRtk({
                                        modalShow: true,
                                        modalType: 'removeMember',
                                        options: {
                                          members: [
                                            {
                                              memberId: currentUser._id,
                                              memberName:
                                                currentUser.platformProfile
                                                  .username,
                                            },
                                          ],
                                        },
                                      })
                                    ),
                                },
                              ],
                      },
                    ],
                  })),
                  ...invitedMembers?.map((member) => ({
                    id: parseInt(member._id, 10),
                    tableRowType: 'record' as RowType,
                    tableRow: [
                      {
                        id: 0,
                        tableCellType: 'title' as CellType,
                        title: 'N.A',
                      },
                      {
                        id: 1,
                        tableCellType: 'title' as CellType,
                        title: 'N.A',
                      },
                      {
                        id: 2,
                        tableCellType: 'title' as CellType,
                        title: member.userEmail,
                      },
                      {
                        id: 3,
                        tableCellType: 'title' as CellType,
                        title: '',
                      },
                      {
                        id: 4,
                        tableCellType: 'title' as CellType,
                        title: dayjs(member?.createdAt).format('MMM DD YYYY'),
                      },
                      {
                        id: 5,
                        tableCellType: 'default' as CellType,
                        content: (
                          <Badge
                            isBold
                            badgeType="warning"
                            solid={false}
                            size="medium"
                            text="Awaiting Response"
                          />
                        ),
                      },
                      {
                        id: 6,
                        tableCellType: 'action' as CellType,
                        actionButtons: !isMember
                          ? [
                              {
                                actionButtonId: 1,
                                actionType: 'copy' as ActionItemType,
                                hasTooltip: true,
                                tooltipText: linkCopied
                                  ? 'Copied'
                                  : 'Copy invite link',
                                onClick: () => {
                                  copyToClipboard(member.link);
                                  setLinkCopied(true);
                                  setTimeout(() => {
                                    setLinkCopied(false);
                                  }, 3000);
                                },
                              },
                              {
                                actionButtonId: 2,
                                actionType: 'delete' as ActionItemType,
                                onClick: () => removeInvitedMember(member._id),
                              },
                            ]
                          : [],
                      },
                    ],
                  })),
                ]
              : [
                  ...[0, 1, 2, 3]?.map((currentUser) => ({
                    id: Number(currentUser),
                    tableRowType: 'record' as RowType,
                    tableRow: [
                      {
                        id: 0,
                        tableCellType: 'default' as CellType,
                        content: (
                          <div className="flex items-center justify-start gap-x-3">
                            <Skeleton
                              height={32}
                              width={32}
                              duration={2}
                              borderRadius={32}
                            />
                            <Skeleton height={24} width={120} duration={2} />
                          </div>
                        ),
                      },
                      {
                        id: 1,
                        tableCellType: 'default' as CellType,
                        content: (
                          <Skeleton height={20} width={80} duration={2} />
                        ),
                      },
                      {
                        id: 2,
                        tableCellType: 'default' as CellType,
                        content: (
                          <Skeleton height={20} width={180} duration={2} />
                        ),
                      },
                      {
                        id: 3,
                        tableCellType: 'default' as CellType,
                        content: (
                          <Skeleton height={36} width={100} duration={2} />
                        ),
                      },
                      {
                        id: 4,
                        tableCellType: 'default' as CellType,
                        content: (
                          <Skeleton height={20} width={90} duration={2} />
                        ),
                      },
                      {
                        id: 6,
                        tableCellType: 'default' as CellType,
                        content: (
                          <Skeleton height={20} width={38} duration={2} />
                        ),
                      },
                    ],
                  })),
                ]
          }
        />

        {users?.length === 0 && (
          <div
            className="flex items-center justify-center 
              text-center w-full py-4 text-text-memberTitle"
          >
            <span>No User Found</span>
          </div>
        )}
        {isMember && (
          <div
            className="bg-gray-100 border-gray-200 border-t cursor-default
                     py-4 px-6 rounded-b-lg flex text-sm font-medium w-full
                     dark:bg-dark-base-fg dark:border-dark-base-border"
          >
            You need additional permissions to manage your organization&apos;s
            members. Please check with your organization&apos;s owner or admins.
          </div>
        )}
      </div>
    </div>
  );
};

export default Members;
