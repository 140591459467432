import React, { Fragment } from 'react';
import { ReactComponent as ExclamationIcon } from '@spheron/ui-library/dist/assets/exclamation-circle.svg';
import { ConfirmationModal, Feedback, Modal } from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import { IOverdue } from '../../../redux/combined-state.interface';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import ModalWrapper from './ModalWrapper';
import Styles from '../../../styles/compute/modal.module.scss';

interface IProps {
  isOpen: boolean;
}
const Overdue = ({ isOpen }: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();
  const overdue: IOverdue = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation.overdue
  );

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={() => dispatchRtk(toggleModalShowRtk({ modalShow: false }))}
      classname={`${Styles.modal__outer__con} w-430 modal fixed inset-0 overflow-y-auto`}
    >
      <div className={Styles.modal__inner__content}>
        <Modal
          footer
          header
          subtitle=""
          modalContent={
            <div className="flex flex-col gap-4">
              <Feedback
                title="Your organisation is in overdue due:"
                subTitle={
                  <div className="mt-3">
                    {overdue?.overdueReasons?.map((overdue) => (
                      <div
                        className="flex gap-2 items-center mt-2 font-medium text-xs"
                        key={overdue}
                      >
                        {overdue}
                      </div>
                    ))}
                  </div>
                }
                feedbackType="error"
                icon={
                  <ExclamationIcon className=" text-base-icon dark:text-dark-base-icon w-5 h-5" />
                }
                showClose={false}
              />
              <span className="font-normal text-sm">
                To exit overdue state you need to satisfy constraint limits.
                This can be done through: <br />
                1. Buying a subscription that will increase your resources
                <br />
                2. Buying a bonus for specific resource that is exceeding <br />{' '}
                3. Removing specific resource manually Taking one of this
                actions may help with exiting overdue state, and afterwards you
                will be able to deploy again
              </span>
            </div>
          }
          headerContent={<>Overdue Alert!</>}
          primaryButtonContent="Okay"
          secondaryButtonContent=""
          primaryButton
          primaryButtonLoading={false}
          secondaryButtonDisabled={false}
          secondaryButton={false}
          onPrimaryButtonClick={() =>
            dispatchRtk(toggleModalShowRtk({ modalShow: false }))
          }
          confirmationStatus={ConfirmationModal.ERROR}
        />
      </div>
    </ModalWrapper>
  );
};

export default Overdue;
