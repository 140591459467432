import React, { useEffect, useState } from 'react';
import { EmptyState } from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../redux/compute/store';
import {
  ClusterType,
  IInstancePlan,
  IInstancePlans,
  LoadingType,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.interfaces';
import PlanTile from '../PlanTile';
import {
  getInstancePlansEndRtk,
  updateComputeStepRtk,
  updateCustomCpuRtk,
  updateCustomPlanRtk,
  updateCustomRamRtk,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.slice';
import PlanTileLoading from '../../../../../components/Compute/Loaders/plan-tile-loading';
import { useQuery } from '../../../../../hooks/useQuery';
import { calculatePriceThunk } from '../../../../../redux/compute/instance/instance-creation/instance-creation.thunks';
import { sliceStringFromEnd } from '../../../../../redux/compute/subscription/subscription.utils';
import ShowMore from '../../../../../components/Compute/Buttons/show-more';
import { ReactComponent as Empty } from '../../../../../assets/compute/global/no-invite.svg';

interface IProps {
  isCancel: boolean;
  isUpdate: boolean;
  isConfirm: boolean;
  selectedInstancePlan: IInstancePlan;
  // eslint-disable-next-line no-unused-vars
  setSelectedInstancePlan: (selectedInstancePlan: IInstancePlan | null) => void;
  // eslint-disable-next-line no-unused-vars
  setIsConfirm: (isConfirm: boolean) => void;
}

const PlanTwo = ({
  isCancel,
  isUpdate,
  selectedInstancePlan,
  setSelectedInstancePlan,
  isConfirm,
  setIsConfirm,
}: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();
  const queryParams = useQuery();
  const type = queryParams.get('type');
  const [recommendedPlan, setRecommendedPlan] = useState<IInstancePlan | null>(
    null
  );

  const selectedOrganisationLoading = useSelector(
    (state: RootState) => state.organisation.selectedOrganisationLoading
  );
  const customPlan = useSelector(
    (state: RootState) => state.instanceCreation.customPlan
  );
  const instancePlans: IInstancePlans = useSelector(
    (state: RootState) => state.instanceCreation.instancePlans
  );
  const instancePlanLoading = useSelector(
    (state: RootState) => state.instanceCreation.instancePlanLoading
  );
  const selectedTemplate = useSelector(
    (state: RootState) => state.cluster.selectedTemplate
  );
  const updateCustomPlan = useSelector(
    (state: RootState) => state.instanceCreation.customPlan
  );
  const selectedCpu = useSelector(
    (state: RootState) => state.instanceCreation.customCpu
  );
  const selectedRam = useSelector(
    (state: RootState) => state.instanceCreation.customRam
  );
  const clusterType = useSelector(
    (state: RootState) => state.instanceCreation.clusterType
  );
  const activeDeployment = useSelector(
    (state: RootState) => state.instance.activeDeployment
  );
  const clusterRegion = useSelector(
    (state: RootState) => state.instanceCreation.clusterRegion
  );

  const scalingType = useSelector(
    (state: RootState) => state.instanceCreation.clusterScaling
  );

  const activeBonusLoading = useSelector(
    (state: RootState) => state.subscription.activeBonusLoading
  );

  const recommendedPlanById = useSelector(
    (state: RootState) => state.instanceCreation.recommendedPlan
  );
  const recommendedPlanLoading = useSelector(
    (state: RootState) => state.instanceCreation.recommendedPlanLoading
  );
  const instancePlanPaginationLoading = useSelector(
    (state: RootState) => state.instanceCreation.instancePlanPaginationLoading
  );

  const [shownNoOfPlans, setShownNoOfPlans] = useState<number>(7);

  const loadMoreInstances = () => {
    setShownNoOfPlans(shownNoOfPlans + 7);
    // Temporarily handling load more instances statically
    // dispatchRtk(loadMoreInstancePlanThunk());
  };

  const monthlyCostUSD = (price: number) => {
    return (Number(price) * 30 || 0).toFixed(2);
  };

  const hourlyCostUSD = (price: number) => {
    return (Number(price) / 24 || 0).toFixed(2);
  };

  const handleTileClick = (plan: IInstancePlan) => {
    setSelectedInstancePlan(plan);
    dispatchRtk(updateCustomCpuRtk(String(plan.cpu)));
    dispatchRtk(
      updateCustomRamRtk(String(sliceStringFromEnd(plan.memory as string, 2)))
    );
    dispatchRtk(calculatePriceThunk(LoadingType.CUSTOM_PLAN));
    dispatchRtk(updateComputeStepRtk(4));
  };
  const allPricesNotZero = instancePlans?.akashMachineImages?.every(
    (plan) => plan.price !== 0
  );
  useEffect(() => {
    if (
      !activeDeployment &&
      clusterType === ClusterType.TEMPLATE &&
      !isCancel
    ) {
      setSelectedInstancePlan(instancePlans.akashMachineImages[0]);
      dispatchRtk(
        updateCustomCpuRtk(String(instancePlans.akashMachineImages[0]?.cpu))
      );
      if (instancePlans.akashMachineImages[0]?.memory) {
        dispatchRtk(
          updateCustomRamRtk(
            String(
              sliceStringFromEnd(
                instancePlans.akashMachineImages[0]?.memory as string,
                2
              )
            )
          )
        );
      }
      dispatchRtk(calculatePriceThunk(LoadingType.CUSTOM_PLAN));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clusterType, recommendedPlanById]);
  useEffect(() => {
    if (isCancel) {
      // NEED TO TEST OUT THIS CASE
      // setSelectedInstancePlan(null);
      setRecommendedPlan(null);
    }
    if (
      !isUpdate &&
      !instancePlanLoading &&
      !isCancel &&
      !activeBonusLoading &&
      !recommendedPlanLoading
    ) {
      if (
        selectedCpu === '' &&
        selectedRam === '' &&
        updateCustomPlan &&
        updateCustomPlan.price &&
        updateCustomPlan.price.toFixed(2) > '0.00' &&
        updateCustomPlan.memory !== '' &&
        !isCancel &&
        isConfirm
      ) {
        setSelectedInstancePlan(updateCustomPlan);
        setRecommendedPlan(updateCustomPlan);
        setIsConfirm(false);
      }
      if (
        !selectedInstancePlan &&
        !instancePlanLoading &&
        !activeBonusLoading &&
        !recommendedPlanLoading &&
        clusterType === ClusterType.DOCKER
      ) {
        if (instancePlans.akashMachineImages[0]?.price !== 0) {
          setSelectedInstancePlan(instancePlans.akashMachineImages[0]);
          dispatchRtk(
            updateCustomCpuRtk(String(instancePlans.akashMachineImages[0]?.cpu))
          );
          if (instancePlans.akashMachineImages[0]?.memory) {
            dispatchRtk(
              updateCustomRamRtk(
                String(
                  sliceStringFromEnd(
                    instancePlans.akashMachineImages[0]?.memory as string,
                    2
                  )
                )
              )
            );
          }
          dispatchRtk(calculatePriceThunk(LoadingType.CUSTOM_PLAN));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeBonusLoading,
    instancePlanLoading,
    recommendedPlanLoading,
    selectedTemplate,
    selectedCpu,
    selectedRam,
    updateCustomPlan,
    instancePlans,
    isCancel,
    clusterType,
    allPricesNotZero,
    clusterRegion,
    scalingType,
    recommendedPlanById,
    isConfirm,
  ]);

  useEffect(() => {
    if (
      !isUpdate &&
      !instancePlanLoading &&
      !recommendedPlanLoading &&
      !selectedInstancePlan
    ) {
      if (clusterType === ClusterType.TEMPLATE) {
        const id = selectedTemplate?.serviceData.defaultAkashMachineImageId;
        const foundPlan = instancePlans.akashMachineImages.find(
          (plan) => plan._id === id
        );
        setSelectedInstancePlan(foundPlan || null);
      } else {
        setSelectedInstancePlan(instancePlans.akashMachineImages[0]);
      }
    }
  }, [
    clusterType,
    instancePlanLoading,
    recommendedPlanLoading,
    instancePlans.akashMachineImages,
    isUpdate,
    recommendedPlan,
    selectedInstancePlan,
    selectedTemplate?.serviceData.defaultAkashMachineImageId,
    setSelectedInstancePlan,
  ]);

  useEffect(() => {
    if (
      isUpdate &&
      !isCancel &&
      !instancePlanLoading &&
      !recommendedPlanLoading &&
      instancePlans.akashMachineImages.find(
        (plan) => plan._id === 'custom-id'
      ) &&
      activeDeployment === null
    ) {
      setSelectedInstancePlan(instancePlans.akashMachineImages[0]);
    }

    return () => {
      dispatchRtk(updateCustomPlanRtk({}));
    };
  }, [
    dispatchRtk,
    activeDeployment,
    instancePlans,
    isUpdate,
    isCancel,
    instancePlanLoading,
    recommendedPlanLoading,
    setSelectedInstancePlan,
  ]);

  const sortedInstancePlans = [...instancePlans.akashMachineImages];

  const customPlanIndex = sortedInstancePlans.findIndex(
    (plan) => plan._id === 'custom-id'
  );
  if (
    updateCustomPlan &&
    selectedCpu === '' &&
    selectedRam === '' &&
    updateCustomPlan.price !== undefined &&
    updateCustomPlan.price.toFixed(2) > '0.00' &&
    updateCustomPlan.memory !== '' &&
    !isCancel &&
    isConfirm
  ) {
    if (customPlanIndex !== -1) {
      sortedInstancePlans.splice(customPlanIndex, 1);
    } else {
      sortedInstancePlans.unshift(updateCustomPlan);
    }
    setIsConfirm(false);
  }
  if (
    updateCustomPlan &&
    selectedCpu !== '' &&
    selectedRam !== '' &&
    updateCustomPlan.price !== undefined &&
    updateCustomPlan.price.toFixed(2) > '0.00' &&
    updateCustomPlan.memory !== '' &&
    !isCancel &&
    isConfirm
  ) {
    sortedInstancePlans.splice(customPlanIndex, 1);
    sortedInstancePlans.unshift(updateCustomPlan);
    setIsConfirm(false);
  }

  const isLoadingCondition =
    type === 'redeploy'
      ? instancePlanLoading || instancePlans.totalCount === 0
      : instancePlanLoading && instancePlans.totalCount === 0;

  useEffect(() => {
    if (isUpdate && !isCancel) {
      const addedCustomPlan = instancePlans.akashMachineImages.find(
        (plan: IInstancePlan) => plan._id === 'custom-id'
      );
      if (activeDeployment?.updatedAt) {
        if (
          !activeDeployment.services[0].agreedMachineImage.machineId &&
          !instancePlanLoading &&
          !addedCustomPlan
        ) {
          dispatchRtk(
            updateCustomCpuRtk(
              String(activeDeployment.services[0].agreedMachineImage.cpu)
            )
          );
          dispatchRtk(
            updateCustomRamRtk(
              String(
                sliceStringFromEnd(
                  activeDeployment.services[0].agreedMachineImage
                    .memory as string,
                  2
                )
              )
            )
          );

          const customPlan: IInstancePlan = {
            cpu: activeDeployment.services[0].agreedMachineImage.cpu,
            defaultDailyTopUp:
              activeDeployment.services[0].agreedMachineImage.defaultDailyTopUp,
            maxPricePerBlock:
              activeDeployment.services[0].agreedMachineImage.maxPricePerBlock,
            memory: activeDeployment.services[0].agreedMachineImage.memory,
            name: 'Custom Plan',
            _id: 'custom-id',
            price:
              activeDeployment.services[0].agreedMachineImage.defaultDailyTopUp,
            storage: activeDeployment.services[0].agreedMachineImage.storage,
            topupThreashold:
              activeDeployment.services[0].agreedMachineImage.topupThreashold,
            recommendedPlan: false,
          };
          const updatedAkashMachineImages = [
            customPlan,
            ...instancePlans.akashMachineImages,
          ];
          const updatedInstancePlans = {
            totalCount: instancePlans.totalCount + 1,
            akashMachineImages: updatedAkashMachineImages,
          };
          const updatedCustomPlan =
            updatedInstancePlans?.akashMachineImages.find(
              (plan) => plan._id === 'custom-id'
            );
          setSelectedInstancePlan(updatedCustomPlan as IInstancePlan);
          dispatchRtk(getInstancePlansEndRtk(updatedInstancePlans));
        } else if (
          !instancePlanLoading &&
          !recommendedPlanLoading &&
          !Object.keys(customPlan).length
        ) {
          const findPlan = instancePlans.akashMachineImages.find(
            (image) =>
              image._id ===
              activeDeployment.services[0].agreedMachineImage.machineId
          );
          setSelectedInstancePlan(
            findPlan || instancePlans.akashMachineImages[0]
          );
          const planIndex = sortedInstancePlans.findIndex(
            (plan) => plan._id === findPlan?._id
          );
          if (shownNoOfPlans < planIndex) {
            setShownNoOfPlans((prev) => prev + 7);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clusterRegion,
    activeDeployment,
    instancePlanLoading,
    recommendedPlan,
    recommendedPlanLoading,
  ]);

  useEffect(() => {
    const addedCustomPlan = instancePlans.akashMachineImages.find(
      (plan: IInstancePlan) => plan._id === 'custom-id'
    );
    if (isUpdate && addedCustomPlan?.price && customPlan?.price) {
      if (customPlan?.price !== addedCustomPlan?.price) {
        const newCustomPlan = { ...addedCustomPlan };
        newCustomPlan.price = customPlan.price;
        const filteredAkashMachineImages =
          instancePlans.akashMachineImages.filter(
            (plan) => plan._id !== 'custom-id'
          );
        const updatedAkashMachineImages = [
          newCustomPlan,
          ...filteredAkashMachineImages,
        ];
        const updatedInstancePlans = {
          totalCount: instancePlans.totalCount,
          akashMachineImages: updatedAkashMachineImages,
        };
        dispatchRtk(getInstancePlansEndRtk(updatedInstancePlans));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customPlan, dispatchRtk]);

  return (
    <div>
      {selectedOrganisationLoading ||
      isLoadingCondition ||
      instancePlanLoading ||
      recommendedPlanLoading ||
      activeBonusLoading ||
      recommendedPlanLoading ? (
        <div
          className={`grid
           gap-6 mt-6 mr-8  ${
             isUpdate
               ? 'grid-cols-2'
               : 'xl:grid-cols-3 ll:grid-cols-4 md:grid-cols-2 col-span-3'
           }`}
        >
          {[...Array(8)].map((item) => (
            <PlanTileLoading key={item} />
          ))}
        </div>
      ) : (
        <>
          {!instancePlanLoading && instancePlans.totalCount === 0 ? (
            <EmptyState
              entityTitle="Sorry No Match Found!"
              // eslint-disable-next-line max-len
              subText="Please select another plan that suits your organization's needs or contact the Spheron team."
              showButton={false}
              buttonDisabled={false}
              onClick={() => {}}
              icon={<Empty />}
            />
          ) : (
            <div
              className={`mt-6 grid gap-6 ${
                isUpdate
                  ? 'grid-cols-2'
                  : 'll:grid-cols-4 xl:grid-cols-3 md:grid-cols-2 col-span-3'
              }`}
            >
              {sortedInstancePlans?.slice(0, shownNoOfPlans).map((plan) => {
                if (Object.keys(plan).length > 0) {
                  return (
                    <PlanTile
                      gpu={null}
                      icon={null}
                      key={plan._id}
                      title={`${
                        plan._id === 'custom-id' ? 'Custom Plan' : plan.name
                      }`}
                      monthPrice={monthlyCostUSD(plan.price)}
                      hourlyPrice={hourlyCostUSD(plan.price)}
                      ramGb={plan.memory}
                      cpuQuantity={plan.cpu}
                      isSelected={plan._id === selectedInstancePlan?._id}
                      onSelect={() => handleTileClick(plan)}
                      isCustomStyle={plan._id === 'custom-id'}
                      recommended={plan?.recommendedPlan}
                      disabled={false}
                      scalingType={scalingType}
                    />
                  );
                }
                return null;
              })}
            </div>
          )}
        </>
      )}
      {shownNoOfPlans < instancePlans.totalCount && (
        <ShowMore
          title="Show More"
          handleClick={() => loadMoreInstances()}
          loading={instancePlanPaginationLoading}
        />
      )}
    </div>
  );
};

export default PlanTwo;
