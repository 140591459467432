/* eslint-disable no-unused-vars */
export interface INotificationState {
  notificationArray: Array<INotification>;
}

export interface INotification {
  message: any;
  timestamp: number | Date;
  type: NotificationType;
  title?: string;
}
export const enum NotificationType {
  Error = 'error',
  Warning = 'warning',
  Success = 'success',
  Info = 'info',
}
