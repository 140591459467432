import { Link } from '@spheron/ui-library';
import React from 'react';

interface IProps {
  icon: JSX.Element;
  heading: string;
  subHeading: string;
  onClick: () => void;
}

const SpheronResourceCard = ({
  icon,
  heading,
  subHeading,
  onClick,
}: IProps) => {
  const withThumb = !!heading;
  return (
    <div
      role="presentation"
      onClick={onClick}
      className={`flex items-start justify-start bg-cards-bg dark:bg-dark-cards-bg
                 dark:hover:bg-dark-cards-hover ${
                   withThumb ? 'items-start' : 'items-center'
                 }
                gap-x-2 p-2 hover:bg-cards-hover cursor-pointer w-full
                 hover:text-primary-1`}
    >
      {withThumb && <div className="w-8 h-8 rounded">{icon}</div>}
      <div className="flex flex-col items-start justify-center gap-y-1 h-full">
        {heading && (
          <h6
            className="text-cards-text dark:text-dark-cards-text
                    text-sm font-semibold"
          >
            {heading}
          </h6>
        )}
        {withThumb ? (
          <p
            className="text-cards-text dark:text-dark-cards-text 
                    text-[11px] font-normal leading-3"
          >
            {subHeading}
          </p>
        ) : (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            type="secondary"
            size="default"
            text={subHeading}
            isBold={false}
            leftIcon={<div className="text-inherit">{icon}</div>}
            onClick={() => {}}
          />
        )}
      </div>
    </div>
  );
};

export default SpheronResourceCard;
