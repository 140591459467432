import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  Dropdown,
  EmptyState,
  OptionType,
  SectionHeading,
  TextInput,
} from '@spheron/ui-library';
import { ReactComponent as MemoryIcon } from '@spheron/ui-library/dist/assets/compute/ram.svg';
import { ReactComponent as CPUIcon } from '@spheron/ui-library/dist/assets/compute/cpu.svg';
import { useDispatch, useSelector } from 'react-redux';
import ClusterTopupCardLoading from '../../../../components/Compute/Loaders/cluster-topup-card-loading';
import LoadMore from '../../../../components/Compute/LoadMore/load-more';
import { ReactComponent as NoProjectIcon } from '../../../../assets/compute/global/no-project.svg';
import { IOrganization } from '../../../../redux/compute/combined-state.interface';
import { ICurrentApp } from '../../../../redux/compute/organisation/organisation.interfaces';
import { ISubscription } from '../../../../redux/compute/subscription/subscription.interfaces';
import { AppDispatch, RootState } from '../../../../redux/compute/store';
import { getWalletUsageThunk } from '../../../../redux/compute/cluster/cluster.thunks';
import { IComputeProject } from '../../../../redux/compute/project/project.interfaces';
import {
  getAllComputeProjectsThunk,
  getComputeProjectsCountThunk,
  loadMoreComputeProjectsThunk,
} from '../../../../redux/compute/project/project.thunks';
import { withLoader } from '../../../../redux/compute/root-utils';
import MetricsLoading from '../../../../components/Compute/Loaders/metrics-loading';
import Metrics from '../../../../components/Compute/Cards/metrics';
import { mapCurrentAppToSpecialization } from '../../../../redux/compute/organisation/organisation.utils';
import { getSubscriptionUsageThunk } from '../../../../redux/compute/subscription/subscription.thunks';
import ClusterTopupCard from '../../../../components/Compute/Cards/cluster-topup-card';
import { ReactComponent as SearchIcon } from '../../../../assets/compute/icons/search.svg';

const ClusterBilling = () => {
  const dispatchRtk = useDispatch<AppDispatch>();

  const selectedOrganisation: IOrganization | null = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation
  );

  const selectedOrganisationLoading: boolean = useSelector(
    (state: RootState) => state.organisation.selectedOrganisationLoading
  );

  const computeProjectsLoading: boolean = useSelector(
    (state: RootState) => state.computeProject.billingProjectsLoading
  );

  const allComputeProjects = useSelector(
    (state: RootState) => state.computeProject.billingProjects
  );

  const totalBillingProjects = useSelector(
    (state: RootState) => state.computeProject.allBillingProjectsCount
  );

  const loadMoreBillingProjectsLoading: boolean = useSelector(
    (state: RootState) => state.computeProject.loadMoreBillingProjectsLoading
  );

  const billingProjectsLoading: boolean = useSelector(
    (state: RootState) => state.computeProject.billingProjectsLoading
  );

  const clusterWalletUsageLoading: boolean = useSelector(
    (state: RootState) => state.cluster.clusterWalletUsageLoading
  );

  const currentApp: ICurrentApp = useSelector(
    (state: RootState) => state.organisation.currentApp
  );

  const activeSubscription: ISubscription | null = useSelector(
    (state: RootState) => state.subscription.activeSubscription
  );

  const computeProjectPagination = useSelector(
    (state: RootState) => state.computeProject.billingPagination
  );
  const subscriptionUsage = useSelector(
    (state: RootState) => state.subscription.subscriptionUsage
  );

  const subscriptionUsageLoading = useSelector(
    (state: RootState) => state.subscription.subscriptionUsageLoading
  );

  const resourceUsage = [
    {
      id: 0,
      resource: 'CPU',
      unit: 'CPU',
      used: subscriptionUsage.cpu,
      limit: subscriptionUsage.cpuLimit,
    },
    {
      id: 1,
      resource: 'RAM',
      unit: 'GB',
      used: subscriptionUsage.memory,
      limit: subscriptionUsage.memoryLimit,
    },
    {
      id: 2,
      resource: 'Storage',
      unit: 'GB',
      used: subscriptionUsage.storage,
      limit: subscriptionUsage.storageLimit,
    },
  ];

  const [computeProjects, setComputeProjects] = useState<IComputeProject[]>([]);
  const [sortingType, setSortingType] = useState<string>('newest');
  const [searchValue, setSearchValue] = useState<string>('');
  const [activeResources, setActiveResources] = useState<IComputeProject[]>([]);

  useEffect(() => {
    return () => {
      setSearchValue('');
    };
  }, []);

  useEffect(() => {
    if (searchValue.length === 0) {
      setComputeProjects(allComputeProjects);
    }
  }, [searchValue, allComputeProjects]);

  const handleSorting = (e: string) => {
    setSortingType(e);
    if (e === 'newest') {
      setActiveResources(
        [...activeResources].sort((a: IComputeProject, b: IComputeProject) => {
          return (
            new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
          );
        })
      );
    } else if (e === 'oldest') {
      setActiveResources(
        [...activeResources].sort((a: IComputeProject, b: IComputeProject) => {
          return (
            new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
          );
        })
      );
    }
  };

  const refreshWalletUsage = () => {
    if (
      selectedOrganisation &&
      currentApp === ICurrentApp.COMPUTE &&
      activeSubscription
    ) {
      dispatchRtk(getComputeProjectsCountThunk(selectedOrganisation?._id));

      dispatchRtk(getWalletUsageThunk(selectedOrganisation?._id));
      dispatchRtk(
        getSubscriptionUsageThunk({
          organizationId: selectedOrganisation?._id,
          specialization: mapCurrentAppToSpecialization(currentApp),
        })
      );
      const payload = {
        organisationId: selectedOrganisation._id,
        topupReport: 'y',
        computeProjectPagination,
      };
      dispatchRtk(getAllComputeProjectsThunk(payload));
    }
  };

  useEffect(() => {
    if (allComputeProjects) {
      setActiveResources(allComputeProjects);
    }
  }, [allComputeProjects]);

  const handleSearch = (searchValue: string): void => {
    if (searchValue) {
      setSearchValue(searchValue);
      setActiveResources(
        (activeResources as any)?.filter((resource: { name: string }) =>
          resource?.name?.toLowerCase()?.includes(searchValue?.toLowerCase())
        ) as any
      );
      return;
    }
    setActiveResources(computeProjects);
    setSearchValue('');
  };

  return (
    <>
      <div className="flex flex-row justify-between items-center">
        <SectionHeading
          showRefresh
          showSwitch={false}
          subHeading=""
          heading="Compute Plan Usage"
          handleIconClick={() => {}}
          handleRefreshClick={refreshWalletUsage}
          handleSwitchClick={() => {}}
          switchDisable={false}
          handleClick={() => {}}
          loading={clusterWalletUsageLoading || billingProjectsLoading}
          time=""
          refreshType={'default'}
          showText={false}
          toggleId=""
          isChecked={false}
          handleChange={() => {}}
        />
        <div className="flex flex-row items-center gap-x-[14px]">
          {withLoader(
            subscriptionUsageLoading || selectedOrganisationLoading,
            <Skeleton width={200} height={40} duration={2} />,
            <>
              {allComputeProjects?.length !== 0 && (
                <div className="min-w-[250px]">
                  <TextInput
                    inputSize="compact"
                    leftIcon={
                      <SearchIcon
                        className="text-base-para-text-color
                        dark:text-dark-base-para-text-color"
                      />
                    }
                    placeholder="Search Compute Projects"
                    value={searchValue}
                    onChange={(value) => handleSearch(value as string)}
                  />
                </div>
              )}
            </>
          )}
          {withLoader(
            subscriptionUsageLoading || selectedOrganisationLoading,
            <Skeleton width={150} height={40} duration={2} />,
            <>
              {activeResources?.length !== 0 && (
                <div className="text-xs flex items-center w-full">
                  <span className="text-gray-400 text-xs mr-3">Sort By</span>
                  <Dropdown
                    dropdownSize="compact"
                    dropdownType="default"
                    filled
                    onSelected={(selected) =>
                      handleSorting(selected.value as string)
                    }
                    classname="!w-[100px] !-mt-0.5"
                    defaultSelected={sortingType}
                    options={[
                      {
                        label: 'Newest',
                        value: 'newest',
                        optionType: 'primary' as OptionType,
                      },
                      {
                        label: 'Oldest',
                        value: 'oldest',
                        optionType: 'primary' as OptionType,
                      },
                    ]}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div className="mt-8 flex flex-wrap justify-between items-center">
        <div className="flex justify-between w-full gap-x-5 gap-y-4">
          {withLoader(
            subscriptionUsageLoading || selectedOrganisationLoading,
            <>
              {Array.from(Array(3).keys()).map((i) => (
                <MetricsLoading key={i} />
              ))}
            </>,
            <>
              {resourceUsage.map((resource) => (
                <div className="w-full" key={resource.id}>
                  <Metrics
                    resource={resource.resource}
                    text=""
                    maxValue={resource.limit}
                    usedValue={resource.used}
                    icon={
                      resource.resource === 'CPU' ? (
                        <CPUIcon className="text-base-icon dark:text-dark-base-icon" />
                      ) : (
                        <MemoryIcon className="text-base-icon dark:text-dark-base-icon" />
                      )
                    }
                    unit={resource.unit}
                  />
                </div>
              ))}
            </>
          )}
        </div>
      </div>
      <div className="mt-4 grid gap-4 justify-items-center md:grid-cols-2 lg:grid-cols-3">
        {!computeProjectsLoading && !selectedOrganisationLoading ? (
          <>
            {activeResources?.length === 0 ? (
              <div className="w-full h-72 col-span-3">
                <EmptyState
                  icon={<NoProjectIcon className="w-[150px] h-[150px]" />}
                  entityTitle="Looks like you don't have any cluster yet."
                  showButton={false}
                  buttonDisabled={false}
                  onClick={() => {}}
                />
              </div>
            ) : (
              activeResources?.map((cluster: IComputeProject) => (
                <ClusterTopupCard key={cluster._id} cluster={cluster} />
              ))
            )}
          </>
        ) : (
          <>
            {Array.from(Array(3).keys()).map((i) => (
              <ClusterTopupCardLoading key={i} />
            ))}
          </>
        )}
      </div>

      {!computeProjectsLoading &&
      !selectedOrganisationLoading &&
      allComputeProjects?.length > 0 &&
      allComputeProjects?.length !== totalBillingProjects &&
      computeProjects?.length > 0 ? (
        <LoadMore
          classname="mt-4"
          loading={loadMoreBillingProjectsLoading}
          handleClick={() => {
            dispatchRtk(
              loadMoreComputeProjectsThunk({
                organisationId: selectedOrganisation._id,
                topupReport: 'y',
              })
            );
          }}
        />
      ) : null}
    </>
  );
};

export default ClusterBilling;
