/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  Footer as FooterComponent,
  StatusType,
  ThemeMode,
} from '@spheron/ui-library';
import { ReactComponent as DiscordIcon } from '@spheron/ui-library/dist/assets/spheron-specific/discord-icon.svg';
import { ReactComponent as GithubIcon } from '@spheron/ui-library/dist/assets/spheron-specific/git-icon.svg';
import { ReactComponent as TwitterIcon } from '@spheron/ui-library/dist/assets/spheron-specific/twitter-icon.svg';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../../../redux/compute/store';
import { toggleThemeThunk } from '../../../redux/compute/theme/theme.thunks';
import { ITheme } from '../../../redux/compute/theme/theme.interfaces';
import { IStatusIndicator } from '../../../redux/compute/status/status.interfaces';
import { getSystemStatusThunk } from '../../../redux/compute/status/status.thunks';

const Footer = () => {
  const dispatchRtk = useDispatch<AppDispatch>();
  const jwtToken = localStorage.getItem('jwt-token');
  const theme = useSelector((state: RootState) => state.theme.theme);
  const status = useSelector((state: RootState) => state.status.status);

  const [selectedTheme, setSelectedTheme] = useState<ThemeMode>(
    theme as unknown as ThemeMode
  );
  const themeMain = localStorage.getItem('theme');
  useEffect(() => {
    dispatchRtk(getSystemStatusThunk());
  }, [dispatchRtk]);

  useEffect(() => {
    setSelectedTheme(theme as unknown as ThemeMode);
  }, [theme]);

  useEffect(() => {
    if (themeMain === null && theme === null) {
      setSelectedTheme(ThemeMode.DARK);
      dispatchRtk(toggleThemeThunk(ITheme.DARK));
      localStorage.setItem('theme', ThemeMode.DARK);
    } else if (themeMain === null && theme !== null) {
      localStorage.removeItem('theme');
      setSelectedTheme(theme as unknown as ThemeMode);
      dispatchRtk(toggleThemeThunk(theme));
      localStorage.setItem('theme', theme);
    } else if (themeMain !== null) {
      setSelectedTheme(themeMain as ThemeMode);
      dispatchRtk(toggleThemeThunk(themeMain as ITheme));
      localStorage.setItem('theme', themeMain);
    }
  }, [themeMain, theme, dispatchRtk]);
  const statusIndicatorMapping = {
    [IStatusIndicator.NONE]: 'normal',
    [IStatusIndicator.MINOR]: 'partial',
    [IStatusIndicator.MAJOR]: 'outage',
    [IStatusIndicator.CRITICAL]: 'outage',
  };

  const footerProps = {
    copyrightText: '© 2024',
    showStatus: false,
    status: statusIndicatorMapping[
      status?.indicator || IStatusIndicator.NONE
    ] as StatusType,
    statusText: status?.description || 'All Systems Operational',
    statusUrl: 'https://status.spheron.network/',
    handleThemeClick: (theme: ThemeMode) => {
      let themeMode = theme;
      if (theme === ThemeMode.DEFAULT) {
        if (
          window.matchMedia &&
          window.matchMedia('(prefers-color-scheme: dark)').matches
        ) {
          themeMode = ThemeMode.DARK;
        } else {
          themeMode = ThemeMode.LIGHT;
        }
      }
      setSelectedTheme(theme as unknown as ThemeMode);
      localStorage.setItem('theme', theme);
      dispatchRtk(toggleThemeThunk(themeMode as unknown as ITheme));
    },
    selectedTheme,
    iconButtons: [
      {
        id: 1,
        icon: (
          <GithubIcon className="w-5 h-5 text-base-icon dark:text-dark-base-icon" />
        ),
        handleClick: () => window.open('https://github.com/spheronFdn/'),
      },
      {
        id: 2,
        icon: (
          <TwitterIcon className="w-5 h-5 text-base-icon dark:text-dark-base-icon" />
        ),
        handleClick: () => window.open('https://sphn.wiki/x'),
      },
      {
        id: 3,
        icon: (
          <DiscordIcon className="w-5 h-5 text-base-icon dark:text-dark-base-icon" />
        ),
        handleClick: () => window.open('https://sphn.wiki/discord'),
      },
    ],
    links: [
      {
        id: 1,
        text: 'Documentation',
        handleClick: () => window.open('https://docs.spheron.network/'),
      },
      {
        id: 2,
        text: 'Join Community',
        handleClick: () => window.open('https://community.spheron.network/'),
      },
      {
        id: 3,
        text: 'Changelog',
        handleClick: () => window.open('https://spheron.network/changelog/'),
      },
    ],
  };
  return (
    <div className={`${jwtToken && theme ? 'mt-[100px]' : 'mt-0'}`}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FooterComponent {...footerProps} />
    </div>
  );
};

export default Footer;
