import React, { useEffect, useState } from 'react';
import { Button, Feedback } from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import {
  ClusterType,
  IInstancePlan,
  IPLeaseType,
  LoadingType,
  ResourcesAvailable,
  ScalingType,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.interfaces';
import TemplateCard from '../TemplateCard';
import { AppDispatch, RootState } from '../../../../../redux/compute/store';
import { togglePriceLoadingRtk } from '../../../../../redux/compute/instance/instance-creation/instance-creation.slice';
import { useQuery } from '../../../../../hooks/useQuery';
import { setSelectedTemplateRtk } from '../../../../../redux/compute/cluster/cluster.slice';
// eslint-disable-next-line max-len
import { isAccelerateDeployment } from '../../../../../redux/compute/instance/instance-creation/instance-creation.utils';
import { ISpheronWallet } from '../../../../../redux/compute/organisation/organisation.interfaces';
import config from '../../../../../config';
import { NotificationType } from '../../../../../redux/compute/notification/notification.interfaces';
import { addNotificationRtk } from '../../../../../redux/compute/notification/notification.slice';

interface IProps {
  isUpdate: boolean;
  selectedInstancePlan:
    | {
        serviceId: string;
        instancePlan: IInstancePlan;
      }[]
    | null;
  startInstanceDeployment: any;
  disabledRecord: Record<string, { disabled: boolean; name: string }>;
  updateDisabled: boolean;
  insufficientBalance: boolean;
}

const MultiServicePriceCard = ({
  isUpdate,
  selectedInstancePlan,
  startInstanceDeployment,
  disabledRecord,
  updateDisabled = false,
  insufficientBalance,
}: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();
  const queryParams = useQuery();
  const provider = queryParams.get('provider');
  const type = queryParams.get('type');
  const selectedTemplate = useSelector(
    (state: RootState) => state.cluster.selectedTemplate
  );
  const multiserviceInstanceCreation = useSelector(
    (state: RootState) => state.instanceCreation.multiserviceInstanceCreation
  );
  const customStoragePrice = useSelector(
    (state: RootState) => state.instanceCreation?.updatedStoragePrice
  );
  const clusterType = useSelector(
    (state: RootState) => state.instanceCreation.clusterType
  );
  const instanceDeploymentLoading = useSelector(
    (state: RootState) => state.instance.deployingInstance
  );
  const priceLoading = useSelector(
    (state: RootState) => state.instanceCreation.priceLoading
  );
  const deploymentType = useSelector(
    (state: RootState) => state.instanceCreation.deploymentType
  );
  const persistentStorageAvailable = useSelector(
    (state: RootState) => state.instanceCreation.persistentStorageAvailable
  );
  const storageAvailable = useSelector(
    (state: RootState) => state.instanceCreation.storageAvailable
  );
  const planAvailable = useSelector(
    (state: RootState) => state.instanceCreation.planAvailable
  );
  const spheronWallet = useSelector(
    (state: RootState) => state.organisation.spheronWallet
  );
  const clusterWalletUsage = useSelector(
    (state: RootState) => state.cluster.clusterWalletUsage
  );
  const clusterScaling = useSelector(
    (state: RootState) => state.instanceCreation.clusterScaling
  );
  const activeDeployment = useSelector(
    (state: RootState) => state.instance.activeDeployment
  );
  const activeService = activeDeployment?.services.find((service) =>
    service.ports.some(
      (port) => port.endpoint || port.type === IPLeaseType.STATIC
    )
  );
  const canDeploy = useSelector((state: RootState) => state.user.canDeploy);

  const discount = selectedTemplate?.discount;

  const [deployDisabled, setDeployDisabled] = useState<boolean>(false);

  useEffect(() => {
    setDeployDisabled(false);
    selectedTemplate?.services.forEach((service) => {
      if (disabledRecord[service._id]?.disabled) {
        setDeployDisabled(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Object.values(disabledRecord), selectedTemplate]);

  const configAvailable =
    planAvailable === ResourcesAvailable.NOT_AVAILABLE ||
    storageAvailable === ResourcesAvailable.NOT_AVAILABLE ||
    persistentStorageAvailable === ResourcesAvailable.NOT_AVAILABLE;

  const updatedStoragePriceHourly: string = customStoragePrice
    ? (customStoragePrice / 24).toFixed(4)
    : '0';

  const updatedStoragePriceMonthly: string = customStoragePrice
    ? (customStoragePrice * 30).toFixed(2)
    : '0';

  const monthlyCostUSD = selectedInstancePlan?.map((plan) => {
    const selectedService = multiserviceInstanceCreation![plan.serviceId];
    const instancePlanPrice = plan.instancePlan?.price;
    const storagePrice = selectedService.updatedStoragePrice;
    const persistentStoragePrice =
      selectedService.updatedPersistentStoragePrice;
    const IpLeasePrice =
      selectedService.ipLeaseType === IPLeaseType.DEDICATED ||
      (isUpdate && plan.serviceId === activeService?.template?._id)
        ? selectedService.ipLeasePrice
        : 0;
    return (
      (instancePlanPrice +
        storagePrice +
        persistentStoragePrice +
        IpLeasePrice) *
      selectedService.serviceCount *
      30
    ).toFixed(2);
  });

  let finalCostUSD: string[] = [];
  let updatedStoragePrice: string;
  let costUnit: string = 'monthly';
  if (isAccelerateDeployment(deploymentType)) {
    updatedStoragePrice = updatedStoragePriceHourly;
  } else {
    finalCostUSD = monthlyCostUSD!;
    updatedStoragePrice = updatedStoragePriceMonthly;

    costUnit = 'monthly';
  }

  const sumArrayElements = (monthlyCostUSD: string[]) => {
    let sum = 0;

    monthlyCostUSD.forEach((element) => {
      sum += Number(element);
    });

    return Number(sum.toFixed(2));
  };

  useEffect(() => {
    if (updatedStoragePrice === '0') {
      dispatchRtk(
        togglePriceLoadingRtk({
          priceLoading: true,
          priceLoadingType: LoadingType.STORAGE,
        })
      );
    }
  }, [priceLoading, updatedStoragePrice, dispatchRtk]);

  useEffect(() => {
    if (provider !== 'template' && type === 'redeploy') {
      dispatchRtk(setSelectedTemplateRtk(null));
    }
  }, [dispatchRtk, provider, type]);
  const referralBalance = (spheronWallet?.details as ISpheronWallet)
    ?.referralBalance;

  const creditsBalance = (spheronWallet?.details as ISpheronWallet)
    ?.creditsBalance;
  const balance = clusterWalletUsage?.balance
    ? (clusterWalletUsage?.balance).toFixed(
        config.dollarDecimalRound.DOLLAR_DECIMAL_ROUND
      )
    : 0;

  const isDisableConditions =
    insufficientBalance ||
    deployDisabled ||
    configAvailable ||
    updateDisabled ||
    (clusterScaling === ScalingType.NO &&
      Number((referralBalance + creditsBalance).toFixed(2)) > 0) ||
    Number(balance) <= 0;

  return (
    <div className="mt-5 space-y-5">
      <div
        className="bg-base-bg dark:bg-dark-base-bg border-base-border
        shadow-selectedCard rounded-lg 
        px-4 py-5 border dark:border-dark-base-border"
      >
        <div
          className="text-base-heading-text-color dark:text-dark-base-heading-text-color
          text-xl font-bold"
        >
          Your Plan Details
        </div>
        {clusterType === ClusterType.TEMPLATE && (
          <>
            {selectedTemplate && (
              <div className="mt-4">
                <TemplateCard
                  title={selectedTemplate.name}
                  imageShow
                  image={selectedTemplate.metadata.icon}
                  badgeContent={selectedTemplate.metadata.category}
                  subtitle=""
                  isSelected
                  onSelect={() => null}
                  isSelectedTemplate
                  discount={
                    clusterScaling === ScalingType.NO
                      ? null
                      : selectedTemplate.discount
                  }
                />
              </div>
            )}
          </>
        )}
        {selectedInstancePlan?.map((plan, index) => (
          <div
            className="flex items-center mt-4 border-b border-bg-lighGray 
        pb-3 justify-between dark:border-dark-base-border"
          >
            <span className="text-text-darkGray text-sm font-normal dark:text-text-darkGray">
              Service {index + 1}
            </span>
            <div className="flex flex-col gap-0.5">
              {discount && (
                <span
                  className="text-feedback-error-text
                text-base font-bold"
                >
                  {plan?.instancePlan?.price &&
                    `$${(
                      (Number(finalCostUSD[index]) *
                        (100 - discount.discountPercent)) /
                      100
                    ).toFixed(2)}`}
                </span>
              )}
              <span
                className={`${discount ? 'line-through' : ''}
              text-base-heading-text-color dark:text-dark-base-heading-text-color
              text-base font-bold`}
              >
                {plan.instancePlan?.price && `$${finalCostUSD[index]}`}{' '}
                <span
                  className="ml-1 text-base font-bold
                    text-base-sub-text-color dark:text-dark-base-sub-text-color"
                >
                  x{multiserviceInstanceCreation![plan.serviceId].serviceCount}
                </span>
              </span>
            </div>
          </div>
        ))}
        <div className="flex items-center mt-3 justify-between">
          <span
            className="text-base-heading-text-color dark:text-dark-base-heading-text-color
            text-sm font-bold"
          >
            Total
          </span>
          <div className="flex flex-col gap-0.5">
            {discount && (
              <div
                className="text-feedback-error-text
                text-xl font-bold
                flex flex-row gap-2 items-center"
              >
                $
                {(
                  (sumArrayElements(finalCostUSD!) *
                    (100 - discount.discountPercent)) /
                  100
                ).toFixed(2)}
                <span
                  className="text-base-sub-text-color dark:text-dark-base-sub-text-color
                  text-xs font-normal"
                >
                  {costUnit}
                </span>
              </div>
            )}
            <div
              className={`${discount ? 'line-through' : ''}
              text-base-heading-text-color dark:text-dark-base-heading-text-color
              text-xl font-bold
              flex flex-row gap-2 items-center`}
            >
              ${sumArrayElements(finalCostUSD!) || 0}
              <span
                className="text-base-sub-text-color dark:text-dark-base-sub-text-color
                text-xs font-normal"
              >
                {costUnit}
              </span>
            </div>
          </div>
        </div>
        {isUpdate ? (
          <div className="flex items-center justify-center gap-x-4 my-4">
            <Button
              buttonType="primary"
              label="UPDATE"
              size="medium"
              fillWidth
              disabled={isDisableConditions || true}
              onClick={startInstanceDeployment}
            />
          </div>
        ) : (
          <>
            <Button
              buttonType="primary"
              label="Deploy"
              size="medium"
              classname="my-4 w-full flex justify-center"
              onClick={() => {
                if (canDeploy) {
                  startInstanceDeployment();
                } else {
                  dispatchRtk(
                    addNotificationRtk({
                      message:
                        'Please verify your email using Google to continue',
                      timestamp: Date.now(),
                      type: NotificationType.Error,
                    })
                  );
                }
              }}
              disabled={isDisableConditions || true}
              loading={instanceDeploymentLoading}
            />
          </>
        )}
        {!isUpdate && (
          <p
            className="text-text-darkGray mt-3 text-sm font-normal leading-5
          dark:text-text-darkGray"
          >
            {/* The prices here are just estimates, they will be different past
            deployment */}
          </p>
        )}
      </div>
      {deployDisabled && !instanceDeploymentLoading && (
        <Feedback
          actionDetails={{
            isBold: false,
            type: 'primary',
            size: 'default',
            text: '',
            onClick: () => null,
          }}
          title="Required Input!"
          subTitle={`${Object.values(disabledRecord)
            .map((value, index) =>
              value.disabled ? value.name || `Service ${index + 1}` : ''
            )
            .filter((value) => value.length > 0)
            .join(
              ', '
            )} needs your attention. Please provide mandatory details to start deploying.`}
          feedbackType="error"
          showClose={false}
        />
      )}
    </div>
  );
};

export default MultiServicePriceCard;
