import {
  IMemberInvite,
  IOrganization,
  IProject,
  IUser,
  IWallet,
  ISpecialization,
  PaymentMethod,
  ICreditCard,
} from '../combined-state.interface';
import { ConnectionState, ICreditCardInfo } from '../stripe/stripe.interfaces';
// eslint-disable-next-line import/no-cycle
import { INetworkType, ITokenType } from '../web3/web3.interfaces';

export interface IOrganisation {
  overduePaymentLoading: boolean;
  overdueLoading: boolean;
  selectedOrganisationLoading: boolean;
  deleteOrganisationLoading: boolean;
  selectedOrganisation: IOrganization;
  // TODO add a type to master organization - IMasterOrganization
  selectedMasterOrganisation: any;
  userRoleLoading: boolean;
  removingInvitedUserFromOrganisation: boolean;
  removedInvitedUserFromOrganisation: boolean;
  removingUserFromOrganisation: boolean;
  creatingOrganisation: boolean;
  creatingMasterOrganisation: boolean;
  createMasterOrganisationFailed: boolean;
  removedUserFromOrganisation: boolean;
  changingMemberRole: boolean;
  changedMemberRole: boolean;
  inviteMemberLoading: boolean;
  selectedOrgWalletConfig: ISelectedOrganisationWalletConfig;
  inviteCallbackLoading: boolean;
  inviteCallbackStatus: string;
  removeLoginConnectionLoading: boolean;
  updateOrganizationLoading: boolean;
  updateMasterOrganizationLoading: boolean;
  registryDetailsLoading: boolean;
  registryDetailList: IRegistryDetails[];
  getMembersLoading: boolean;
  primaryPaymentMethodLoading: boolean;
  primaryPaymentMethodId: string | null;
  tempPaymentMethodId: string | null;
  selectedOrgCreditCardConfig: ICreditCard | null;
  selectedOrgSpheronWalletConfig: IOrgWallet | null;
  addingPaymentMethod: boolean;
  isPublicAccessible: boolean;
  billingDetails: IOrganisationBillingDetails | null;
  billingDetailsLoading: boolean;
  billingDetailsFetched: boolean;
  communityOrgNotFound: boolean;
  spheronWallet: IOrgWallet | null;
  spheronCredits: ISpheronCredits[] | null;
  spheronCreditsLoading: boolean;
  preferredAppType: ICurrentApp;
  orgNotificationConfigCategory: Record<string, boolean>;
  orgNotificationConfigCategoryLoading: boolean;
  updateOrgNotificationConfigCategoryLoading: boolean;
}

export interface IOrganisationState extends IOrganisation {
  organisationsLoading: boolean;
  currentApp: ICurrentApp;
  computeOrganisations: IOrganization[];
  webappOrganisations: IOrganization[];
  storageOrganisations: IOrganization[];
}
export interface IRegistryDetails {
  id: string;
  address: string;
  name: string;
  createdOn: string;
  domains: IRegistryDomain[];
  admins: IRegistryUpdater[];
  managers: IRegistryUpdater[];
}

export interface IRegistryUpdater {
  id: string;
  addedTimestamp: string;
  address: string;
  roleGranter: string;
}

export interface IRegistryDomain {
  id: string;
  domain: string;
  contentHash: string;
  deployedLink: string;
  lastedUpdated: string;
}

export interface IPaymentPagination {
  currentPage: number;
  numbersPerPage: number;
}

export interface ISelectedOrganisationCreditCardConfig {
  id: string;
  cardNumber: number;
  cardAddress: string;
  cardExpiry: number;
  isPrimary: boolean;
}

export interface ISelectedOrganisationWalletConfig {
  _id?: string;
  walletId?: string;
  address: string;
  networkDetails: INetworkType | null;
  tokenDetails: ITokenType | null;
}

export const enum ICurrentApp {
  // eslint-disable-next-line no-unused-vars
  APP = 'app',
  // eslint-disable-next-line no-unused-vars
  COMPUTE = 'compute',
  // eslint-disable-next-line no-unused-vars
  STORAGE = 'storage',
}
export interface IOrganizationResponse {
  createdAt?: Date;
  updatedAt?: Date;
  _id: string;
  profile: {
    name: string;
    image: string;
    username: string;
  };
  projects: IProject[];
  users: IUser[];
  wallet: IWallet | null;
  registries: string[];
  invitedMembers: IMemberInvite[];
  overdue: boolean;
}

export interface IPayOverdueResponse {
  message: string;
}

export interface ICreateNewOrganisationResponse {
  organization: IOrganization;
  success: boolean;
}

export interface IOrganisationSettingsPayloadDto {
  name: string;
  image?: string;
  username: string;
  appType: ICurrentApp;
  avatar?: string;
}

export interface IMasterOrganisationPayloadDto {
  name?: string;
  username?: string;
  preferedAppType: ICurrentApp;
  image?: string;
  avatar?: string;
}

export interface IInviteUserPayloadDto {
  orgId: string;
  orgName: string;
  organization: string;
  organizationId: string;
  userEmail: string;
}

export interface IRemoveWalletPayloadDto {
  id: string;
  network: string;
  organizationId: string;
  signature: string;
}

export interface IAddCryptoWalletPayloadDto {
  organizationId: string;
  paymentMethod: PaymentMethod;
  details: {
    address: string;
    signature: string;
    wType: string;
    tokenDetails: {
      name: string;
      address: string;
      decimals: number | undefined;
    };
    networkDetails: {
      name: string;
      chainId: number | undefined;
    };
  };
}

export interface IAddCreditCardPayloadDto {
  organizationId: string;
  details: {
    cardService: string;
    cardPaymentMethodType: string;
  };
  paymentMethod: PaymentMethod;
}

export interface IChangeUserRolePayloadDto {
  organizationId: string;
  roleGroupName: string;
  userId: string;
}

export interface IFetchUserRolesResponse {
  message: string;
  dto: {
    roleId: string;
    roleGroup: string;
    userId: string;
    username: string;
  }[];
}

export interface IRemoveInvitedMemberResponse {
  createdAt: Date;
  link: string;
  organization: string;
  status: string;
  updatedAt: Date;
  userEmail: string;
  _id: string;
}

export interface IChangeUserRoleResponse {
  createdAt: Date;
  organizationPermissions: {
    issuerId: string | null;
    organizationId: string;
    roleGroupId: string;
    _id: string;
  }[];
  updatedAt: Date;
  userId: string;
  _id: string;
}

interface IAllTypesOverdue {
  specialization: ISpecialization;
  overdueResponse: {
    allowedDomains: number;
    allowedEnsDomains: number;
    allowedHnsDomains: number;
    allowedMembers: number | null;
    exceededDomains: number;
    exceededEnsDomains: number;
    exceededEnvironmentsForProjects: {
      project: IProject;
      amount: number;
    }[];
    exceededHnsDomains: number;
    exceededMembers: number;
    usedDomains: number;
    usedEnsDomains: number;
    usedHnsDomains: number;
  };
  overdueReasons: string[];
}

export interface IOrganizationOverdueResponse {
  message: string;
  overdue: boolean;
  allTypes?: IAllTypesOverdue[];
}

export interface IInvitationPayloadDto {
  organisationId: string;
  inviteId: string;
}

export interface IUpdateInvitePayloadDto {
  inviteId: string;
  status: string;
  organizationId: string;
}

export interface IChangeUserRoleResponseDto {
  roleGroup: string;
  roleId: string;
  userId: string;
  username: string;
}

export interface IOrganisationCurrentAppPayloadDto {
  organizationId: string;
  currentApp: ICurrentApp;
}

export interface IPayOrganisationOverduePayloadDto {
  walletId: string;
  organisationId: string;
  specialization: ISpecialization;
}

export interface IDeleteOrganisationEndPayloadDto {
  organisationId: string;
  organisationType: ICurrentApp;
}

export interface IRemoveWalletResponsePayloadDto {
  success: boolean;
  primaryWallet: string | null;
}

export interface IWalletDetailsCreditCard {
  cardCustomerId: string;
  cardPaymentMethodType: string;
  cardService: string;
  connectionId: string;
  info: ICreditCardInfo;
}

export interface IWalletDetailsCrypto {
  address: string;
  signature: string;
  networkDetails: IWalletDetailsNetwork;
  tokenDetails: IWalletDetailsToken;
}

export interface IWalletDetailsNetwork {
  chainId: number;
  name: string;
  chainName?: string;
  networkId?: string;
}

export interface IWalletDetailsToken {
  address: string;
  decimal: number;
  symbol: string;
  name: string;
}

export interface IOrgWallet {
  connectionState: ConnectionState;
  createdAt: Date;
  organizationId: string;
  paymentMethod: PaymentMethod;
  primary: boolean;
  updatedAt: Date;
  _id: string;
  details: IWalletDetailsCrypto | IWalletDetailsCreditCard | ISpheronWallet;
}

export interface IOrgWalletsResponsePayloadDto {
  totalCount: number;
  wallets: IOrgWallet[];
}
export interface IOrganisationBillingDetails {
  companyName: string;
  taxId?: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  postalCode: string;
  country: string;
  invoiceEmailRecipient: string;
}

export interface IOrganisationBillingDetailsPayloadDto {
  companyName: string;
  taxId?: string;
  billingAddress: IBillingAddress;
  invoiceEmailRecipient: string;
}

export interface IOrganisationBillingDetailsResponsePayloadDto
  extends IOrganisationBillingDetailsPayloadDto {
  _id: string;
  organization: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface IBillingAddress {
  addressLine1: string;
  addressLine2?: string;
  city: string;
  postalCode: string;
  country: string;
  state: string;
}

export interface ISpheronWallet {
  referralBalance: number;
  creditsBalance: number;
  spheronWalletId: string;
}

export interface ISpheronCredits {
  remainingAmount: number;
  totalAmount: number;
  status: string;
  activatedOn: Date;
  expiresOn: Date;
  creditCoupon: string;
  couponName: string;
  activationCode: string;
  appType: string;
  organizationsInScope: string[];
}

export enum SpheronCreditType {
  // eslint-disable-next-line no-unused-vars
  ACTIVE = 'ACTIVE',
  // eslint-disable-next-line no-unused-vars
  PENDING = 'PENDING',
  // eslint-disable-next-line no-unused-vars
  EXPIRED = 'EXPIRED',
}
