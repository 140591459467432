import React, { useEffect, useRef, useState, useMemo } from 'react';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import {
  Button,
  Dropdown,
  OptionType,
  Switch,
  TextInput,
} from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import {
  AutoscalingPlanEnum,
  IAutoscalingRequestPayload,
  IAutoscalingRulesThreshold,
} from '../../../redux/compute/instance/instance.interfaces';
import { HealthCheckStatus as HealthCheckStatusEnum } from '../../../redux/compute/cluster/cluster.interfaces';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import { RootState, AppDispatch } from '../../../redux/compute/store';
import {
  calculatePriceThunk,
  getAvailableInventoryThunk,
  getCustomPlanThunk,
  getInstancePlanThunk,
  getInstanceUnitPricesThunk,
} from '../../../redux/compute/instance/instance-creation/instance-creation.thunks';
import PlanGrid from '../../../pages/ComputeNew/Compute/NewComputePricing/PlanGrid';
import { setShowInstanceUpdateRtk } from '../../../redux/compute/instance/instance.slice';
import {
  selectClusterRegionRtk,
  setDeploymentTypeRtk,
  setScalingTypeRtk,
} from '../../../redux/compute/instance/instance-creation/instance-creation.slice';
import {
  IInstancePlan,
  InstanceDeploymentType,
  LoadingType,
  ScalingType,
} from '../../../redux/compute/instance/instance-creation/instance-creation.interfaces';
import AutoScalingConfig from '../../../pages/ComputeNew/Compute/NewComputePricing/AutoScalingConfiguration';
import {
  deleteInstanceThunk,
  updateInstanceAutoScalingRulesThunk,
} from '../../../redux/compute/instance/instance.thunks';
import { canDeleteInstance } from '../../../redux/compute/instance/instance.utils';
import useScrollTo from '../../../hooks/useSrollTo';
import ShowMoreSwitch from '../Misc/show-more-switch';
// import InstanceMultiserviceSettingsCard from './instance-multiservice-settings-card';
import { IInstance } from '../../../redux/compute/project/project.interfaces';
import { IUser } from '../../../redux/compute/combined-state.interface';
import MultiServiceUpdateSection from '../../../pages/ComputeNew/Compute/NewComputePricing/MultiServiceUpdateSection';

const InstanceSettingsCard = () => {
  const uniqueTopicId = uuidv4();
  const { scrollRef, scrollToElement } = useScrollTo();
  const isPublicAccessible =
    sessionStorage.getItem('isPublicAccessible') === 'true';
  const communityUserAccess =
    sessionStorage.getItem('communityUserAccess') === 'true';

  const dispatchRtk = useDispatch<AppDispatch>();

  const scrollContainerRef = useRef<any>();
  const [activeTab, setActiveTab] = useState<string>('configureSettings');
  const [buildEnv, setBuildEnv] = useState<
    { key: string; value: string; isSecret: boolean }[]
  >([]);
  const [buildArgs, setBuildArgs] = useState<string[]>([]);
  const [buildCommands, setBuildCommands] = useState<string[]>([]);
  const [healthUrl, setHealthUrl] = useState<string>('');
  const [healthPort, setHealthPort] = useState<string>('');
  const [toggleHealthCheck, setToggleHealthCheck] = useState<boolean>(false);
  const [toggleAutoScaling, setToggleAutoScaling] = useState<boolean>(false);
  const [selectedService, setSelectedService] = useState<{
    id: number;
    optionType: OptionType;
    label: string;
    value: string;
  }>({
    id: 0,
    optionType: 'primary' as OptionType,
    label: 'Select Service',
    value: '',
  });
  const AUTOSCALING_CONFIG = useMemo(() => {
    return {
      maximumInstances: 5,
      minimumInstances: 1,
      plan: '',
      timeWindow: '',
      cooldown: '',
      scaleUp: {
        cpuThreshold: {
          utilizationPercentage: 0,
          step: 0,
        },
        memoryThreshold: {
          utilizationPercentage: 0,
          step: 0,
        },
      },
      scaleDown: {
        cpuThreshold: {
          utilizationPercentage: 0,
          step: 0,
        },
        memoryThreshold: {
          utilizationPercentage: 0,
          step: 0,
        },
      },
    };
  }, []);
  const [autoScalingPolicy, setAutoScalingPolicy] =
    useState<IAutoscalingRequestPayload>(AUTOSCALING_CONFIG);

  const [imageTag, setImageTag] = useState<string>('latest');
  // eslint-disable-next-line no-unused-vars
  const [instanceCount, setInstanceCount] = useState<string>('1');
  const [selectedInstancePlan, setSelectedInstancePlan] =
    useState<IInstancePlan | null>();
  // eslint-disable-next-line no-unused-vars
  const [instanceVariables, setInstanceVariables] = useState<
    {
      label: string;
      defaultValue: string;
      required: boolean;
    }[]
  >([]);

  const [showCustomAutoScalingPolicy, setShowCustomAutoScalingPolicy] =
    useState<boolean>(false);

  const activeDeployment = useSelector(
    (state: RootState) => state.instance.activeDeployment
  );

  const multiserviceInstanceCreation = useSelector(
    (state: RootState) => state.instanceCreation.multiserviceInstanceCreation
  );

  const activeDeploymentLoading: boolean = useSelector(
    (state: RootState) => state.instance.activeDeploymentLoading
  );

  const instanceEnvs:
    | {
        value: string;
        isSecret: boolean;
      }[]
    | undefined = useSelector(
    (state: RootState) => state.instance.activeDeployment?.services[0].env
  );

  const instanceArguments: string[] | undefined = useSelector(
    (state: RootState) => state.instance.activeDeployment?.services[0].args
  );

  const instanceTag: string | undefined = useSelector(
    (state: RootState) => state.instance.activeDeployment?.services[0].tag
  );

  const instanceCommands: string[] | undefined = useSelector(
    (state: RootState) => state.instance.activeDeployment?.services[0].command
  );

  const instanceDeploymentLoading: boolean = useSelector(
    (state: RootState) => state.instance.deployingInstance
  );

  const selectedInstance = useSelector(
    (state: RootState) => state.instance.selectedInstance
  );

  const organizationId = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation._id
  );

  const showInstanceUpdate = useSelector(
    (state: RootState) => state.instance.showInstanceUpdate
  );

  const clusterType = useSelector(
    (state: RootState) => state.instanceCreation.clusterType
  );

  const autoScalingRulesUpdating = useSelector(
    (state: RootState) => state.instance.updatingAutoScalingRules
  );

  const unitPrices = useSelector(
    (state: RootState) => state.instanceCreation.unitPrices
  );
  const availableInventory = useSelector(
    (state: RootState) => state.instanceCreation.availableInventory
  );
  const pricingTargetsLoading = useSelector(
    (state: RootState) => state.instanceCreation.pricingTargetsLoading
  );

  const currentUser = useSelector((state: RootState) => state.user.user);
  const selectedOrganisation = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation
  );
  const selectedTemplate = useSelector(
    (state: RootState) => state.cluster.selectedClusterTemplateDetails
  );
  const editNameLoading = useSelector(
    (state: RootState) => state.instance.editNameLoading
  );

  const saveHealthCheck = () => {
    if (activeDeployment) {
      const healthCheckConfig = {
        instanceId: activeDeployment.computeInstance,
        healthCheckUrl: toggleHealthCheck ? healthUrl : '',
        healthCheckPort: toggleHealthCheck ? healthPort : '',
        organizationId,
        serviceName: selectedService.value,
      };
      dispatchRtk(
        toggleModalShowRtk({
          modalShow: true,
          modalType: 'saveHealthCheckConfig',
          options: {
            healthCheckConfig,
          },
        })
      );
    }
  };

  const scrollToInstancePlan = () => {
    const hasCommentAnchor = window.location.href.includes('#');
    if (hasCommentAnchor) {
      const sectionHeight = scrollContainerRef.current.offsetTop;
      window.scrollTo({
        top: sectionHeight ? sectionHeight - 150 : 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    setTimeout(() => scrollToInstancePlan(), 2);
  }, []);

  const initSwitchState = useRef<boolean>(false);

  useEffect(() => {
    if (activeDeployment?.services) {
      const activeService =
        activeDeployment.services.find(
          (service) => service.name === selectedService.value
        ) || activeDeployment.services[0];
      if (activeService?.healthCheck) {
        initSwitchState.current =
          !!activeService?.healthCheck?.path &&
          !!activeService?.healthCheck?.port?.containerPort &&
          activeService?.healthCheck?.status !== HealthCheckStatusEnum.UNKNOWN;
        setToggleHealthCheck(initSwitchState.current);
      }
    }
  }, [activeDeployment?.services, selectedService]);

  useEffect(() => {
    if (activeDeployment) {
      if (activeDeployment.services[0]?.autoscalingRules) {
        setToggleAutoScaling(true);
        const {
          autoscalingRules: { plan, maximumInstances, minimumInstances },
        } = activeDeployment.services[0];
        if (
          activeDeployment.services[0].autoscalingRules.plan ===
          AutoscalingPlanEnum.CUSTOM
        ) {
          const {
            autoscalingRules: {
              scaleDown: {
                cpuThreshold: scaleDownCpuThreshold,
                memoryThreshold: scaleDownMemoryThreshold,
              },
              scaleUp: {
                cpuThreshold: scaleUpCpuThreshold,
                memoryThreshold: scaleUpMemoryThreshold,
              },
              timeWindow,
              cooldown,
            },
          } = activeDeployment.services[0] as {
            autoscalingRules: {
              scaleDown: IAutoscalingRulesThreshold;
              scaleUp: IAutoscalingRulesThreshold;
              timeWindow: string;
              cooldown: string;
            };
          };
          setAutoScalingPolicy({
            plan,
            maximumInstances,
            minimumInstances,
            scaleDown: {
              cpuThreshold: {
                step: Number(scaleDownCpuThreshold.step),
                utilizationPercentage: Number(
                  scaleDownCpuThreshold.utilizationPercentage
                ),
              },
              memoryThreshold: {
                step: Number(scaleDownMemoryThreshold.step),
                utilizationPercentage: Number(
                  scaleDownMemoryThreshold.utilizationPercentage
                ),
              },
            },
            scaleUp: {
              cpuThreshold: {
                step: Number(scaleUpCpuThreshold.step),
                utilizationPercentage: Number(
                  scaleUpCpuThreshold.utilizationPercentage
                ),
              },
              memoryThreshold: {
                step: Number(scaleUpMemoryThreshold.step),
                utilizationPercentage: Number(
                  scaleUpMemoryThreshold.utilizationPercentage
                ),
              },
            },
            timeWindow: String(timeWindow),
            cooldown: String(cooldown),
          });
          setShowCustomAutoScalingPolicy(true);
        } else {
          setAutoScalingPolicy({
            plan,
            maximumInstances,
            minimumInstances,
            scaleDown: AUTOSCALING_CONFIG.scaleDown,
            scaleUp: AUTOSCALING_CONFIG.scaleUp,
            timeWindow: '',
            cooldown: '',
          });
          setShowCustomAutoScalingPolicy(false);
        }
      } else {
        setToggleAutoScaling(false);
        setAutoScalingPolicy(AUTOSCALING_CONFIG);
      }
    }
  }, [AUTOSCALING_CONFIG, activeDeployment]);

  const saveHealthCheckDisabled = () => {
    const activeService =
      activeDeployment?.services.find(
        (service) => service.name === selectedService.value
      ) || activeDeployment?.services[0];

    if (!healthUrl || !healthPort) return true;

    if (initSwitchState.current === toggleHealthCheck) {
      if (!toggleHealthCheck) return true;
      return (
        activeService?.healthCheck?.path === healthUrl &&
        String(activeService?.healthCheck?.port?.containerPort) === healthPort
      );
    }
    return false;
  };

  const disableAutoScalingSaveBtn = () => {
    let disable = false;
    const { maximumInstances, minimumInstances, plan } = autoScalingPolicy;
    if (toggleAutoScaling) {
      if (
        !showCustomAutoScalingPolicy &&
        (maximumInstances < minimumInstances ||
          plan === '' ||
          plan === 'custom' ||
          !maximumInstances ||
          !minimumInstances)
      ) {
        disable = true;
      } else if (showCustomAutoScalingPolicy) {
        const {
          timeWindow,
          cooldown,
          scaleUp: {
            cpuThreshold: scaleDownCpuThreshold,
            memoryThreshold: scaleDownMemoryThreshold,
          },
          scaleDown: {
            cpuThreshold: scaleUpCpuThreshold,
            memoryThreshold: scaleUpMemoryThreshold,
          },
        } = autoScalingPolicy as {
          timeWindow: string;
          cooldown: string;
          scaleUp: IAutoscalingRulesThreshold;
          scaleDown: IAutoscalingRulesThreshold;
        };
        if (
          timeWindow === '' ||
          cooldown === '' ||
          !scaleDownCpuThreshold?.utilizationPercentage ||
          !scaleDownCpuThreshold?.step ||
          !scaleDownMemoryThreshold.utilizationPercentage ||
          !scaleDownMemoryThreshold.step ||
          !scaleUpCpuThreshold.utilizationPercentage ||
          !scaleUpCpuThreshold.step ||
          !scaleUpMemoryThreshold.utilizationPercentage ||
          !scaleUpMemoryThreshold.step
        ) {
          disable = true;
        }
      }
    }
    return disable;
  };

  const initEnvMap = useRef<any>(null);
  const fetchInstancePlan = useRef<any>(null);
  const isDeployed: boolean =
    String(activeDeployment?.status)?.toLowerCase() === 'deployed' ||
    String(activeDeployment?.status)?.toLowerCase() === 'active';
  const isClosedOrFailed: boolean = activeDeployment
    ? String(activeDeployment?.status)?.toLowerCase() === 'closed' ||
      String(activeDeployment?.status)?.toLowerCase() === 'failed' ||
      String(activeDeployment?.status)?.toLowerCase() === 'deprecated'
    : String(selectedInstance?.state)?.toLowerCase() === 'closed';

  useEffect(() => {
    if (!unitPrices?.length) {
      dispatchRtk(getInstanceUnitPricesThunk());
    }
    if (!availableInventory?.length) {
      dispatchRtk(getAvailableInventoryThunk());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchRtk]);

  useEffect(() => {
    if (
      selectedInstance &&
      activeDeployment &&
      !activeDeploymentLoading &&
      isDeployed &&
      fetchInstancePlan.current === null &&
      !pricingTargetsLoading
    ) {
      const isGpu =
        Object.keys(activeDeployment?.services[0].agreedMachineImage?.gpu || {})
          ?.length > 0;
      dispatchRtk(
        setDeploymentTypeRtk(
          isGpu
            ? InstanceDeploymentType.ACCELERATE
            : InstanceDeploymentType.COMPUTE
        )
      );
      dispatchRtk(
        selectClusterRegionRtk(activeDeployment?.services[0]?.region)
      );
      dispatchRtk(
        setScalingTypeRtk(
          selectedInstance.scalable ? ScalingType.MANUAL : ScalingType.NO
        )
      );
      dispatchRtk(getInstancePlanThunk({ search: '' }));
      fetchInstancePlan.current = false;

      dispatchRtk(
        getCustomPlanThunk({
          region: activeDeployment?.services[0]?.region,
          scalingMode: selectedInstance?.scalable
            ? ScalingType.MANUAL
            : ScalingType.NO,
        })
      );
      dispatchRtk(calculatePriceThunk(LoadingType.STORAGE));
      if (instanceEnvs) {
        initEnvMap.current = instanceEnvs?.map(
          (env: { value: string; isSecret: boolean }) => {
            // regex to split only at the first occurence of '='
            const envKeyValue = env?.value?.split(/=(.*)/s);
            return {
              key: envKeyValue[0],
              value: envKeyValue[1],
              isSecret: env.isSecret,
            };
          }
        );

        setBuildEnv(initEnvMap.current);
      }

      if (instanceCommands) {
        setBuildCommands(instanceCommands);
      }

      if (instanceArguments) {
        setBuildArgs(instanceArguments);
      }

      if (activeDeployment?.services[0].serviceCount) {
        setInstanceCount(
          String(activeDeployment?.services[0].serviceCount) || '1'
        );
      }

      if (activeDeployment?.services[0].tag)
        setImageTag(activeDeployment?.services[0].tag);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedInstance,
    clusterType,
    pricingTargetsLoading,
    activeDeployment,
    selectedTemplate,
  ]);

  useEffect(() => {
    if (activeDeployment?.services) {
      const activeService =
        activeDeployment.services.find(
          (service) => service.name === selectedService.value
        ) || activeDeployment.services[0];

      if (activeService?.healthCheck) {
        setToggleHealthCheck(true);
        setHealthUrl(activeService?.healthCheck?.path || '');
      } else {
        setToggleHealthCheck(false);
        setHealthUrl('');
      }
      if (activeService?.healthCheck?.port?.containerPort) {
        setToggleHealthCheck(true);
        setHealthPort(
          String(
            !activeService?.healthCheck.port.containerPort
              ? ''
              : activeService?.healthCheck.port.containerPort
          ) || ''
        );
      } else {
        setToggleHealthCheck(false);
        setHealthPort('');
      }
    }
  }, [activeDeployment?.services, selectedService.value]);

  useEffect(() => {
    fetchInstancePlan.current = null;
  }, [selectedInstance]);

  const defaultInstanceSettingsTabs = [
    {
      title: 'Instance update & Configure Settings',
      value: 'configureSettings',
    },
    {
      title: 'Health Check',
      value: 'healthCheck',
    },
  ];

  const instanceSettingsTabs = activeDeployment?.services[0]?.autoscalingRules
    ? [
        ...defaultInstanceSettingsTabs,
        ...(selectedInstance && currentUser && selectedOrganisation
          ? [
              {
                title: 'Instance Management',
                value: 'instanceManagement',
              },
            ]
          : []),
        {
          title: 'Auto Scaling',
          value: 'autoScaling',
        },
      ]
    : [
        ...defaultInstanceSettingsTabs,
        ...(selectedInstance && currentUser && selectedOrganisation
          ? [
              {
                title: 'Instance Management',
                value: 'instanceManagement',
              },
            ]
          : []),
      ];

  const finalTabs = isDeployed
    ? instanceSettingsTabs
    : [
        {
          title: 'Instance Settings',
          value: 'configureSettings',
        },
        ...(selectedInstance && currentUser && selectedOrganisation
          ? [
              {
                title: 'Instance Management',
                value: 'instanceManagement',
              },
            ]
          : []),
      ];

  const compareArrays = (
    arr1:
      | string[]
      | { key: string; value: string; isSecret: boolean }[]
      | undefined,
    arr2:
      | string[]
      | { key: string; value: string; isSecret: boolean }[]
      | undefined
  ) => {
    if (arr2 && arr2) {
      return JSON.stringify(arr1) === JSON.stringify(arr2);
    }

    return false;
  };

  const checkIfEmpty = (arr: string[]) =>
    arr.findIndex((element) => element.trim() === '') !== -1;

  // eslint-disable-next-line no-unused-vars
  const isDisabled = (): boolean => {
    return (
      !!buildEnv.find(
        (env) =>
          env.key.trim() === '' || (env.value.trim() === '' && !env.isSecret)
      ) ||
      (compareArrays(buildArgs, instanceArguments) &&
        compareArrays(buildCommands, instanceCommands) &&
        instanceTag === imageTag &&
        compareArrays(buildEnv, initEnvMap.current)) ||
      checkIfEmpty([...buildArgs, ...buildCommands]) ||
      instanceDeploymentLoading ||
      activeDeploymentLoading ||
      !imageTag
    );
  };

  useEffect(() => {
    const sectionHeight = document.getElementById(
      'instance-update-list'
    )?.offsetTop;
    window.scrollTo({
      top: sectionHeight ? sectionHeight - 150 : 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [showInstanceUpdate]);

  useEffect(() => {
    if (toggleAutoScaling) {
      scrollToElement();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleAutoScaling]);

  useEffect(() => {
    return () => {
      dispatchRtk(setShowInstanceUpdateRtk(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateAutoScalingRules = () => {
    if (!toggleAutoScaling) {
      setAutoScalingPolicy({
        maximumInstances: 5,
        minimumInstances: 1,
        plan: '',
        timeWindow: '',
        cooldown: '',
        scaleUp: null,
        scaleDown: null,
      });
    }
    dispatchRtk(
      updateInstanceAutoScalingRulesThunk({
        autoScalingRules: toggleAutoScaling
          ? autoScalingPolicy
          : { ...autoScalingPolicy, cancelAutoscaling: true },
        instanceId: activeDeployment?.computeInstance || '',
      })
    );
  };

  const isSameAutoScalingConfig = () => {
    if (activeDeployment?.services[0]?.autoscalingRules) {
      const {
        maximumInstances,
        minimumInstances,
        plan,
        timeWindow,
        cooldown,
        scaleDown,
        scaleUp,
      } = activeDeployment?.services[0].autoscalingRules;

      return toggleAutoScaling
        ? maximumInstances === autoScalingPolicy?.maximumInstances &&
            minimumInstances === autoScalingPolicy?.minimumInstances &&
            plan === autoScalingPolicy?.plan
        : autoScalingPolicy?.timeWindow === timeWindow &&
            autoScalingPolicy?.cooldown === cooldown &&
            autoScalingPolicy?.scaleDown === scaleDown &&
            autoScalingPolicy?.scaleUp === scaleUp;
    }
    return (
      toggleAutoScaling === !!activeDeployment?.services[0]?.autoscalingRules
    );
  };

  const serviceOptions =
    activeDeployment?.services.map((service, index) => ({
      id: index,
      optionType: 'primary' as OptionType,
      label: service.name,
      value: service.name,
    })) || [];

  useEffect(() => {
    if (activeDeployment?.services && !activeDeploymentLoading) {
      setSelectedService({
        id: 0,
        optionType: 'primary' as OptionType,
        label: activeDeployment?.services[0].name,
        value: activeDeployment?.services[0].name,
      });
    }
  }, [activeDeployment?.services, activeDeploymentLoading]);

  return (
    <div className="flex flex-col" ref={scrollContainerRef}>
      {(!isPublicAccessible || communityUserAccess) && (
        <>
          <div className="w-full">
            <div>
              {
                <div>
                  <div
                    id="instance-update-list"
                    className="flex
                        gap-x-8 items-center justify-start"
                  >
                    {finalTabs.map((tab) => (
                      <div
                        role="presentation"
                        onClick={() => setActiveTab(tab.value)}
                        key={tab.value}
                        className={classNames(
                          // eslint-disable-next-line max-len
                          'w-min text-sm font-semibold whitespace-nowrap leading-6 focus:outline-none cursor-pointer',
                          tab.value === activeTab
                            ? `border-b-2 border-form-selected text-base-para-text-color
                    dark:text-dark-base-para-text-color`
                            : `text-base-sub-text-color
                                      dark:text-dark-base-sub-text-color`
                        )}
                      >
                        {tab.title}
                      </div>
                    ))}
                  </div>
                  <div className="mt-10">
                    {activeTab === 'configureSettings' && (
                      <div className="">
                        {!isClosedOrFailed ? (
                          <>
                            {isDeployed ? (
                              <>
                                {activeDeployment &&
                                activeDeployment?.services?.length > 1 &&
                                multiserviceInstanceCreation ? (
                                  <>
                                    <MultiServiceUpdateSection isUpdate />
                                  </>
                                ) : (
                                  <>
                                    <div className="grid lg:grid-cols-4 gap-x-0">
                                      <div className="gap-y-4 gap-x-0 col-span-4 w-full">
                                        <div className="flex flex-col gap-y-9">
                                          <PlanGrid
                                            instanceName=""
                                            isUpdate
                                            selectedInstancePlan={
                                              selectedInstancePlan as IInstancePlan
                                            }
                                            setSelectedInstancePlan={
                                              setSelectedInstancePlan
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            ) : (
                              <div className="flex flex-col gap-y-5 w-full">
                                <p
                                  className="text-base-sub-text-color
                         dark:text-dark-base-sub-text-color text-sm font-400"
                                >
                                  Please wait for the instance to deploy
                                  successfully
                                </p>
                                <div
                                  className="bg-base-border dark:bg-dark-base-border
                               h-px w-full"
                                />
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="flex flex-col gap-y-5 w-full">
                            <p
                              className="text-base-sub-text-color
                         dark:text-dark-base-sub-text-color text-sm font-400"
                            >
                              This instance has been marked as{' '}
                              {activeDeployment?.status}, and no further state
                              updates are allowed.
                            </p>
                            <div className="bg-base-border dark:bg-dark-base-border h-px w-full" />
                          </div>
                        )}
                      </div>
                    )}
                    {activeTab === 'healthCheck' && (
                      <div>
                        <div className="flex flex-col gap-y-2">
                          <div className="flex items-center justify-start gap-x-6">
                            <h3
                              className="text-5 leading-6 dark:text-dark-base-heading-text-color
                           font-semibold text-base-heading-text-color"
                            >
                              Health Check Settings
                            </h3>
                            {activeDeployment?.services?.length === 1 && (
                              <Switch
                                toggleId="healthcheck-toggle"
                                isChecked={toggleHealthCheck}
                                label="Health Check"
                                handleChange={() =>
                                  setToggleHealthCheck(!toggleHealthCheck)
                                }
                              />
                            )}
                          </div>
                          <p
                            className="text-sm font-normal text-base-para-text-color
                         dark:text-dark-base-para-text-color w-8/12"
                          >
                            Enable the health check feature to monitor the
                            status of the instance in realtime. When enabled,
                            Spheron will regularly assess the instance&apos;s
                            health, providing real-time insights into its
                            functionality.
                          </p>
                        </div>
                        {activeDeployment &&
                          activeDeployment?.services?.length > 1 && (
                            <div className="grid grid-cols-2 items-center gap-x-6 mt-8">
                              <Dropdown
                                dropdownSize="default"
                                className="w-full"
                                dropdownType="default"
                                label="SELECT SERVICE"
                                placeholder="Select Service"
                                filled
                                onSelected={(value: any) => {
                                  setSelectedService(value);
                                }}
                                subText=""
                                defaultSelected={selectedService.label}
                                options={serviceOptions}
                              />
                              <div className="mt-5">
                                <Switch
                                  toggleId="healthcheck-toggle"
                                  isChecked={toggleHealthCheck}
                                  label="Health Check"
                                  handleChange={() =>
                                    setToggleHealthCheck(!toggleHealthCheck)
                                  }
                                />
                              </div>
                            </div>
                          )}
                        <div className="flex items-center justify-between gap-x-6 mt-10">
                          <div className="w-full">
                            <TextInput
                              value={healthUrl}
                              label="Health Check Path"
                              onChange={(value) =>
                                setHealthUrl(value as string)
                              }
                              disabled={!toggleHealthCheck}
                            />
                          </div>

                          <div className="w-full">
                            <TextInput
                              value={healthPort}
                              label="Health Check Port"
                              onChange={(value) =>
                                setHealthPort(value as string)
                              }
                              disabled={!toggleHealthCheck}
                              type="number"
                              min={0}
                            />
                          </div>
                        </div>
                        <div className="buttons my-4 flex justify-start items-center">
                          <Button
                            buttonType="primary"
                            label="Save Settings"
                            size="medium"
                            onClick={saveHealthCheck}
                            disabled={saveHealthCheckDisabled()}
                            loading={instanceDeploymentLoading}
                          />
                        </div>
                      </div>
                    )}{' '}
                    {activeTab === 'instanceManagement' && (
                      <div>
                        <div className="flex flex-col gap-y-5">
                          <h3
                            className="text-5 leading-6 dark:text-dark-base-heading-text-color
                           font-semibold text-base-heading-text-color"
                          >
                            Instance Management
                          </h3>
                          <p
                            className="text-sm font-normal text-base-para-text-color
                         dark:text-dark-base-para-text-color w-8/12"
                          >
                            Enable automatic calling for this instance. When
                            activated, this feature allows automated calls for
                            specific functionalities or tasks within the
                            instance.
                          </p>
                          <div className="flex items-center justify-start gap-x-4">
                            <Button
                              label="Restart Instance"
                              size="small"
                              buttonType="primary"
                              disabled={
                                (String(
                                  (selectedInstance as IInstance)?.state
                                ).toLowerCase() === 'queued' &&
                                  String(
                                    (selectedInstance as IInstance)?.state
                                  ).toLowerCase() === 'pending') ||
                                true
                              }
                              onClick={() => {
                                dispatchRtk(
                                  toggleModalShowRtk({
                                    modalShow: true,
                                    modalType: 'restartInstance',
                                    options: {
                                      instanceConfig: {
                                        instanceId: (
                                          selectedInstance as IInstance
                                        )?._id,
                                        organizationId,
                                        uniqueTopicId,
                                      },
                                    },
                                  })
                                );
                              }}
                            />
                            <Button
                              label="Rename Instance"
                              size="small"
                              buttonType="primary"
                              onClick={() => {
                                dispatchRtk(
                                  toggleModalShowRtk({
                                    modalShow: true,
                                    modalType: 'editName',
                                    options: {
                                      id: selectedInstance?._id || '',
                                      loading: editNameLoading,
                                      resource: 'Instance',
                                    },
                                  })
                                );
                              }}
                            />
                            <Button
                              label="Close Instance"
                              size="small"
                              buttonType="primary"
                              disabled={
                                String(
                                  (selectedInstance as IInstance)?.state
                                ).toLowerCase() !== 'active' &&
                                String(
                                  (selectedInstance as IInstance)?.state
                                ).toLowerCase() !== 'provisioned' &&
                                String(
                                  (selectedInstance as IInstance)?.state
                                ).toLowerCase() !== 'deployed'
                              }
                              onClick={() => {
                                dispatchRtk(
                                  toggleModalShowRtk({
                                    modalShow: true,
                                    modalType: 'closeInstance',
                                    options: {
                                      instanceId: selectedInstance?._id,
                                    },
                                  })
                                );
                              }}
                            />
                            <Button
                              label="Delete Instance"
                              size="small"
                              buttonType="error"
                              disabled={
                                !canDeleteInstance(
                                  currentUser as IUser,
                                  selectedOrganisation.users,
                                  selectedInstance.state,
                                  !selectedInstance
                                )
                              }
                              onClick={() =>
                                dispatchRtk(
                                  toggleModalShowRtk({
                                    modalShow: true,
                                    modalType: 'deleteResource',
                                    options: {
                                      handleClick: () => {
                                        dispatchRtk(
                                          deleteInstanceThunk(
                                            selectedInstance?._id
                                          )
                                        );
                                      },
                                      resourceType: 'Instance',
                                      resource: (selectedInstance as IInstance)
                                        .name,
                                    },
                                  })
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {activeTab === 'autoScaling' && (
                      <div>
                        <div className="flex flex-col gap-y-2">
                          <div className="flex items-center justify-start gap-x-6">
                            <h3
                              className="text-5 leading-6 font-semibold
                            dark:text-dark-base-heading-text-color
                           text-base-heading-text-color"
                            >
                              Auto Scaling Settings
                            </h3>
                            <Switch
                              toggleId="advance-scaling-toggle"
                              isChecked={toggleAutoScaling}
                              label="Auto Scaling"
                              handleChange={() =>
                                setToggleAutoScaling(!toggleAutoScaling)
                              }
                            />
                          </div>
                          <p
                            className="text-sm font-normal text-base-para-text-color
                         dark:text-dark-base-para-text-color w-8/12"
                          >
                            Enable automatic calling for this instance. When
                            activated, this feature allows automated calls for
                            specific functionalities or tasks within the
                            instance.
                          </p>
                        </div>

                        {toggleAutoScaling && (
                          <div
                            className="p-5 bg-base-fg dark:bg-dark-base-fg rounded-lg
                          border-base-border mt-6 dark:border-dark-base-border"
                          >
                            <ShowMoreSwitch
                              state={showCustomAutoScalingPolicy}
                              id="custom-autoscaling"
                              buttonText="Configure Autoscaling"
                              setState={setShowCustomAutoScalingPolicy}
                              subHeading="Create custom plan"
                            />
                            <div ref={scrollRef}>
                              <AutoScalingConfig
                                setAutoScalingPolicy={setAutoScalingPolicy}
                                autoScalingPolicy={autoScalingPolicy}
                                showCustomAutoScalingPolicy={
                                  showCustomAutoScalingPolicy
                                }
                                isUpdate
                              />
                            </div>
                          </div>
                        )}
                        <div className="buttons my-4 flex justify-end items-center">
                          <Button
                            buttonType="primary"
                            label="Save"
                            size="medium"
                            onClick={handleUpdateAutoScalingRules}
                            disabled={
                              disableAutoScalingSaveBtn() ||
                              autoScalingRulesUpdating ||
                              isSameAutoScalingConfig() ||
                              activeDeploymentLoading
                            }
                            loading={autoScalingRulesUpdating}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              }
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default InstanceSettingsCard;
