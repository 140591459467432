import React, { useEffect, useMemo, useState } from 'react';
import { Dropdown, OptionType } from '@spheron/ui-library';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../redux/compute/store';
import {
  getCustomPlanThunk,
  getInstancePlanThunk,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.thunks';
import {
  IInstancePlan,
  ClusterType,
  ScalingType,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.interfaces';
// eslint-disable-next-line max-len
import {
  resetPersistentValuesRtk,
  updateComputeStepRtk,
  selectClusterRegionRtk,
} from '../../../../../redux/compute/instance/instance-creation/instance-creation.slice';
// eslint-disable-next-line max-len
import { isAccelerateDeployment } from '../../../../../redux/compute/instance/instance-creation/instance-creation.utils';

interface IProps {
  isUpdate: boolean;
  // eslint-disable-next-line no-unused-vars
  setSelectedInstancePlan: (plan: IInstancePlan | null) => void;
}

const ChooseRegion = ({
  isUpdate,
  // eslint-disable-next-line no-unused-vars
  setSelectedInstancePlan,
}: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();
  const selectedOrganisationLoading = useSelector(
    (state: RootState) => state.organisation.selectedOrganisationLoading
  );
  const activeBonusLoading = useSelector(
    (state: RootState) => state.subscription.activeBonusLoading
  );
  const clusterRegions = useSelector(
    (state: RootState) => state.instanceCreation.clusterRegions
  );
  const clusterRegionsLoading = useSelector(
    (state: RootState) => state.instanceCreation.getClusterRegionsLoading
  );
  const scalingMode = useSelector(
    (state: RootState) => state.instanceCreation.clusterScaling
  );
  const selectedClusterRegion = useSelector(
    (state: RootState) => state.instanceCreation.clusterRegion
  );
  const storageLoading = useSelector(
    (state: RootState) => state.instanceCreation.storageLoading
  );
  const isCustomPlanOpen = useSelector(
    (state: RootState) => state.instance.isCustomPlanOpen
  );
  const clusterType = useSelector(
    (state: RootState) => state.instanceCreation.clusterType
  );
  const instancePlanLoading = useSelector(
    (state: RootState) => state.instanceCreation.instancePlanLoading
  );
  const deploymentType = useSelector(
    (state: RootState) => state.instanceCreation.deploymentType
  );

  const [defaultRegionLoading, setDefaultRegionLoading] =
    useState<boolean>(true);

  const memoisedClusterRegions = useMemo(() => {
    const regions =
      scalingMode === ScalingType.NO
        ? clusterRegions.filter((region) => region.scalingMode === scalingMode)
        : clusterRegions.filter(
            (region) =>
              region.scalingMode === scalingMode && region.region !== ''
          );

    return regions.map((region) => ({
      name: region.region || 'any',
    }));
  }, [clusterRegions, scalingMode]);

  useEffect(() => {
    if (clusterRegions.length > 0 && scalingMode && !activeBonusLoading) {
      const defaultRegion = clusterRegions
        .filter((region) => region.scalingMode === scalingMode)
        .find((region) => region.isPrimaryForScaling);
      setDefaultRegionLoading(false);
      dispatchRtk(selectClusterRegionRtk(defaultRegion?.region || 'any'));
      if (!instancePlanLoading) {
        dispatchRtk(getInstancePlanThunk({ search: '', limit: 999, skip: 0 }));
      }
      dispatchRtk(
        getCustomPlanThunk({
          region: defaultRegion?.region || 'any',
          scalingMode: defaultRegion?.scalingMode || ('' as ScalingType),
        })
      );
    }
    dispatchRtk(updateComputeStepRtk(4));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clusterRegions, dispatchRtk, scalingMode, activeBonusLoading]);

  useEffect(() => {
    if (
      selectedClusterRegion !== null &&
      !storageLoading &&
      !clusterRegionsLoading &&
      !defaultRegionLoading &&
      !activeBonusLoading &&
      !instancePlanLoading
    ) {
      dispatchRtk(getInstancePlanThunk({ search: '' }));
      dispatchRtk(
        getCustomPlanThunk({
          region: selectedClusterRegion,
          scalingMode,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    defaultRegionLoading,
    activeBonusLoading,
    clusterRegionsLoading,
    selectedClusterRegion,
    dispatchRtk,
  ]);

  useEffect(() => {
    setSelectedInstancePlan(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scalingMode, selectedClusterRegion]);

  const stepNumber = isAccelerateDeployment(deploymentType) ? '5.' : '6.';
  const dockerStepNumber = isAccelerateDeployment(deploymentType) ? '3.' : '4.';

  return (
    <div>
      <div className="flex flex-col items-start gap-y-6">
        <div className="flex items-center justify-start">
          <h3
            className="text-base-heading-text-color dark:text-dark-base-heading-text-color
            font-bold text-xl"
          >
            {!isUpdate
              ? `${
                  clusterType === ClusterType.TEMPLATE
                    ? stepNumber
                    : dockerStepNumber
                } `
              : ''}
            Region
          </h3>
        </div>
        <div className="w-1/4">
          <Dropdown
            dropdownSize="default"
            dropdownType="default"
            filled
            onSelected={(value) => {
              dispatchRtk(selectClusterRegionRtk(value.value as string));
              dispatchRtk(resetPersistentValuesRtk());
            }}
            defaultSelected={
              clusterRegionsLoading ||
              activeBonusLoading ||
              selectedOrganisationLoading
                ? 'Loading...'
                : selectedClusterRegion || 'any'
            }
            options={memoisedClusterRegions.map((region) => ({
              label: region.name,
              value: region.name,
              optionType: 'primary' as OptionType,
            }))}
            disable={isCustomPlanOpen || instancePlanLoading}
            loading={
              clusterRegionsLoading ||
              activeBonusLoading ||
              selectedOrganisationLoading ||
              instancePlanLoading
            }
            placeholder="Loading..."
          />
        </div>
      </div>
    </div>
  );
};

export default ChooseRegion;
