/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from 'react';
import { ReactComponent as BinIcon } from '@spheron/ui-library/dist/assets/trash.svg';
import { ReactComponent as InternetIcon } from '@spheron/ui-library/dist/assets/internet.svg';
import {
  Button,
  SectionHeading,
  TextInput,
  UserProfile,
} from '@spheron/ui-library';
import Skeleton from 'react-loading-skeleton';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import CopyIdCard from '../../../../components/Cards/copy-id-card';
import {
  IOrganization,
  IUser,
} from '../../../../redux/compute/combined-state.interface';
import { ICurrentApp } from '../../../../redux/compute/organisation/organisation.interfaces';
import { getUserRole } from '../../../../redux/compute/organisation/organisation.utils';
import { toggleModalShowRtk } from '../../../../redux/compute/modal/modal.slice';
import {
  deleteOrganisationThunk,
  publicAccessOrgThunk,
  updateMasterOrganisationThunk,
} from '../../../../redux/compute/organisation/organisation.thunks';
import { AppDispatch, RootState } from '../../../../redux/compute/store';
import {
  createUsername,
  withLoader,
} from '../../../../redux/compute/root-utils';

const OrganisationGeneralSettings = () => {
  const isPublicAccessible =
    sessionStorage.getItem('isPublicAccessible') === 'true';
  const communityUserAccess =
    sessionStorage.getItem('communityUserAccess') === 'true';

  const dispatchRtk = useDispatch<AppDispatch>();
  const [username, setUsername] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(true);
  const [name, setName] = useState<string>('');
  const [avatar, setAvatar] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  const selectedOrganisation: IOrganization | null = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation
  );
  const selectedOrganisationLoading = useSelector(
    (state: RootState) => state.organisation.selectedOrganisationLoading
  );
  const selectedMasterOrganisation = useSelector(
    (state: RootState) => state.organisation.selectedMasterOrganisation
  );

  const communityOrgName = sessionStorage.getItem('communityOrgName')
    ? sessionStorage.getItem('communityOrgName')
    : selectedMasterOrganisation?.profile?.username;

  const publicAccessible: boolean = useSelector(
    (state: RootState) =>
      state.organisation.selectedMasterOrganisation?.publiclyAccessible || false
  );

  const updateMasterOrganizationLoading: boolean = useSelector(
    (state: RootState) => state.organisation.updateMasterOrganizationLoading
  );
  const allUsers = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation.users
  );
  const user = useSelector((state: RootState) => state.user.user);
  const userLoading = useSelector((state: RootState) => state.user.userLoading);
  const userRoleLoading = useSelector(
    (state: RootState) => state.organisation.userRoleLoading
  );

  const currentApp: ICurrentApp = useSelector(
    (state: RootState) => state.organisation.currentApp
  );

  const isMember =
    allUsers?.find((u: any) => u?._id === user?._id)?.platformProfile?.role ===
      'member' && user !== null;

  const [selectedPreferredAppType, setSelectedPreferredAppType] =
    useState<string>(
      selectedMasterOrganisation?.preferedAppType || currentApp.toString()
    );

  // const appTypeList = [
  //   {
  //     label: 'Compute',
  //     value: ICurrentApp.COMPUTE,
  //     optionType: 'primary' as OptionType,
  //   },
  //   {
  //     label: 'Storage',
  //     value: ICurrentApp.STORAGE,
  //     optionType: 'primary' as OptionType,
  //   },
  //   {
  //     label: 'Static Site',
  //     value: ICurrentApp.APP,
  //     optionType: 'primary' as OptionType,
  //   },
  // ];

  useEffect(() => {
    if (selectedMasterOrganisation) {
      if (
        name?.trim() &&
        (selectedMasterOrganisation.profile?.username !== username ||
          selectedMasterOrganisation.profile?.name?.trim() !== name.trim() ||
          selectedMasterOrganisation.profile?.image !== avatar ||
          selectedMasterOrganisation.preferedAppType !==
            selectedPreferredAppType)
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
  }, [
    username,
    name,
    avatar,
    selectedMasterOrganisation,
    selectedPreferredAppType,
  ]);

  useEffect(() => {
    if (selectedMasterOrganisation) {
      setName(selectedMasterOrganisation.profile?.name);
      setUsername(selectedMasterOrganisation.profile?.username);
      setSelectedPreferredAppType(selectedMasterOrganisation?.preferedAppType);
      setAvatar(selectedMasterOrganisation.profile?.image);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMasterOrganisation]);

  const handleSubmit = () => {
    dispatchRtk(
      updateMasterOrganisationThunk({
        id: selectedMasterOrganisation!._id!,
        data: {
          name: name?.trim(),
          username,
          image: avatar,
          preferedAppType: selectedPreferredAppType as ICurrentApp,
        },
      })
    );
  };

  const isOrganisationOwner =
    getUserRole(selectedOrganisation.users, user as IUser) === 'owner';

  const handleDeleteOrganisation = () => {
    dispatchRtk(
      toggleModalShowRtk({
        modalShow: true,
        modalType: 'deleteResource',
        options: {
          resourceType: 'Organisation',
          resource: selectedMasterOrganisation?.profile.name,
          handleClick: () =>
            dispatchRtk(
              deleteOrganisationThunk(selectedMasterOrganisation?._id as string)
            ),
        },
      })
    );
  };

  const communityPageLink = `${window.location.origin}/${communityOrgName}`;

  const handleCommunityOrganisation = () => {
    dispatchRtk(
      toggleModalShowRtk({
        modalShow: true,
        modalType: 'toggleOrgVisibility',
        options: {
          visibilityAction: publicAccessible ? 'Private' : 'Public',
          organisationName: selectedMasterOrganisation?.profile.name,
          link: '',
          handleClick: () =>
            dispatchRtk(
              publicAccessOrgThunk({
                organisationId: selectedMasterOrganisation?._id!,
                publiclyAccessible: !publicAccessible,
              })
            ).then(() => {
              dispatchRtk(
                toggleModalShowRtk({
                  modalShow: true,
                  modalType: 'changeOrgVisibilityConfirm',
                  options: {
                    visibilityAction: publicAccessible ? 'Private' : 'Public',
                    organisationName: selectedMasterOrganisation?.profile.name,
                    link: communityPageLink,
                  },
                })
              );
            }),
        },
      })
    );
  };

  const communityOrganisationSubtext = publicAccessible
    ? // eslint-disable-next-line max-len
      ' Making the organization private revokes visitors read-only access to its projects and does not allow the community to link an EVM-based wallet for payment.'
    : // eslint-disable-next-line max-len
      ' Making the organization public grants visitors read-only access to its projects and allows the community to link an EVM-based wallet for payment.';

  const communtiySectionLoading = useMemo(() => {
    return (
      loading || selectedOrganisationLoading || userLoading || userRoleLoading
    );
  }, [loading, selectedOrganisationLoading, userLoading, userRoleLoading]);

  return (
    <>
      <SectionHeading
        showRefresh={false}
        showSwitch={false}
        subHeading="Personalize your organization's details by updating its name, 
        logo, and visibility settings. Additionally, view your unique organization ID for easy reference"
        heading="Organization Details"
        handleIconClick={() => {}}
        handleRefreshClick={() => {}}
        handleSwitchClick={() => {}}
        switchDisable={false}
        handleClick={() => {}}
        loading={false}
        time=""
        refreshType="default"
        showText={false}
        toggleId=""
        isChecked={false}
        handleChange={() => {}}
      />

      {isMember && !communtiySectionLoading && (
        <div className="text-sm mt-1 font-semibold">
          You need additional permissions to manage your organization&apos;s
          details. Please check with your organization&apos;s owner or admins.
        </div>
      )}

      <div className="flex items-start space-between gap-x-8 mt-6 w-full">
        <div className="w-[30%]">
          <div className="w-full">
            <div className="flex flex-col items-center gap-y-8">
              {withLoader(
                loading,
                <div
                  className="p-6 bg-base-fg dark:bg-dark-base-fg border border-base-border 
            dark:border-dark-base-border rounded-lg w-[378px]"
                >
                  <div className="flex flex-col items-center justify-center">
                    <Skeleton
                      height={152}
                      width={152}
                      duration={2}
                      borderRadius={152}
                    />
                    <div className="gap-y-6 flex flex-col mt-8 items-center">
                      <div className="flex flex-col gap-y-0.5">
                        <Skeleton height={32} width={260} duration={2} />
                        <Skeleton height={24} width={260} duration={2} />
                      </div>
                      <Skeleton height={18} width={260} duration={2} />
                    </div>
                  </div>
                </div>,
                <UserProfile
                  profileIcon={
                    selectedMasterOrganisation?.profile?.avatar ? (
                      <img
                        src={selectedMasterOrganisation?.profile?.avatar}
                        alt="user"
                      />
                    ) : (
                      <p className="text-12 flex items-center justify-center capitalize">
                        {selectedMasterOrganisation?.profile?.name[0]}
                      </p>
                    )
                  }
                  profileName={selectedMasterOrganisation?.profile?.name || ''}
                  profileUsername=""
                  uploadEnabled={
                    !(
                      isMember ||
                      (isPublicAccessible && !communityUserAccess)
                    ) || userRoleLoading
                  }
                  email=""
                  profileInfo={[
                    {
                      id: 1,
                      text: `Created on ${dayjs(
                        `${selectedMasterOrganisation?.createdAt}`
                      ).format('MMMM DD, YYYY')}`,
                    },
                  ]}
                  classname="!w-full"
                  setAvatar={(e) => {
                    if (
                      isMember ||
                      (isPublicAccessible && !communityUserAccess)
                    ) {
                      return;
                    }
                    setAvatar(e);
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start gap-y-8 w-[70%]">
          <div className="grid grid-cols-2 gap-x-6 gap-y-8 w-full">
            {withLoader(
              loading,
              <Skeleton height={48} width={466} duration={2} />,
              <TextInput
                label="Name"
                value={name}
                type="text"
                onChange={(value) => {
                  setName(value as string);
                  setUsername(createUsername((value as string)?.trim()));
                }}
                placeholder="eg. Spheron's Org"
                max={120}
                disabled={
                  isMember || (isPublicAccessible && !communityUserAccess)
                }
              />
            )}
            {withLoader(
              loading,
              <Skeleton height={48} width={466} duration={2} />,
              <div className="form-group">
                <div
                  className="mb-2 text-xs dark:border-dark-form-border border-form-border
           text-form-text dark:text-dark-form-text font-semibold leading-3 tracking-[1px] uppercase"
                >
                  Organisation ID
                </div>
                <CopyIdCard
                  data={selectedMasterOrganisation?._id || ''}
                  message=""
                  loading={loading}
                  title={''}
                  isCompact={false}
                />
              </div>
            )}
            {/* {withLoader(
              loading,
              <Skeleton height={48} width={466} duration={2} />,
              <div>
                <div
                  className="text-xs dark:border-dark-form-border border-form-border
           text-form-text dark:text-dark-form-text font-semibold leading-3 tracking-[1px] uppercase"
                >
                  Preferred App Type
                </div>
                <Dropdown
                  dropdownSize="default"
                  dropdownType="default"
                  filled
                  onSelected={(selected) => {
                    setSelectedPreferredAppType(selected.value as string);
                  }}
                  defaultSelected={selectedPreferredAppType}
                  options={appTypeList}
                  placeholder="Preferred App Type"
                  disable={
                    isMember || (isPublicAccessible && !communityUserAccess)
                  }
                />
              </div>
            )} */}
          </div>
          <Button
            buttonType="primary"
            fillWidth={false}
            label="Save"
            size="medium"
            onClick={handleSubmit}
            disabled={
              disabled ||
              isMember ||
              updateMasterOrganizationLoading ||
              (isPublicAccessible && !communityUserAccess)
            }
            loading={updateMasterOrganizationLoading}
          />
          {/* {withLoader(
            communtiySectionLoading,
            <>
              <div className="h-[0.5px] w-full dark:bg-dark-base-border bg-base-border" />
              <div className="flex flex-col gap-y-5 items-start">
                <Skeleton duration={2} height={24} width={220} />
                <Skeleton duration={2} height={20} width={700} />
                <Skeleton duration={2} height={44} width={154} />
              </div>
            </>,
            <>
              {isOrganisationOwner && (
                <>
                  <div className="h-[0.5px] w-full dark:bg-dark-base-border bg-base-border" />
                  <div className="flex flex-col gap-y-5 items-start">
                    <SectionHeading
                      showRefresh={false}
                      showSwitch={false}
                      subHeading=""
                      heading="Community Organisation"
                      handleIconClick={() => {}}
                      handleRefreshClick={() => {}}
                      handleSwitchClick={() => {}}
                      switchDisable={false}
                      handleClick={() => {}}
                      loading={false}
                      time=""
                      refreshType="default"
                      showText={false}
                      toggleId=""
                      isChecked={false}
                      handleChange={() => {}}
                    />
                    {publicAccessible && (
                      <div className="w-5/12">
                        <div
                          className="mb-2 text-xs dark:border-dark-form-border border-form-border
           text-form-text dark:text-dark-form-text font-semibold leading-3 tracking-[1px] uppercase"
                        >
                          Public link for your organisation
                        </div>
                        <CopyIdCard
                          data={communityPageLink || ''}
                          message=""
                          loading={loading}
                          title={''}
                          isCompact={false}
                        />
                      </div>
                    )}

                    <p
                      className="text-sm text-base-heading-text-color dark:text-dark-base-heading-text-color
            font-normal"
                    >
                      {communityOrganisationSubtext}
                    </p>
                    <Button
                      leftIcon={
                        <InternetIcon className="text-base-bg -mt-0.5" />
                      }
                      buttonType="error"
                      label={publicAccessible ? 'Make Private' : 'Make Public'}
                      size="medium"
                      onClick={handleCommunityOrganisation}
                      disabled={updateMasterOrganizationLoading}
                    />
                  </div>
                </>
              )}
            </>
          )} */}

          {isOrganisationOwner &&
            (user?.organizations?.filter(
              (org) => !org.profile.username.includes('supernoderz-org')
            )?.length || 1) > 1 && (
              <>
                <div className="h-[0.5px] w-full dark:bg-dark-base-border bg-base-border" />
                <div className="flex flex-col gap-y-5 items-start">
                  <SectionHeading
                    showRefresh={false}
                    showSwitch={false}
                    subHeading=""
                    heading="Delete Organisation"
                    handleIconClick={() => {}}
                    handleRefreshClick={() => {}}
                    handleSwitchClick={() => {}}
                    switchDisable={false}
                    handleClick={() => {}}
                    loading={false}
                    time=""
                    refreshType="default"
                    showText={false}
                    toggleId=""
                    isChecked={false}
                    handleChange={() => {}}
                  />

                  <p
                    className="text-sm text-base-heading-text-color dark:text-dark-base-heading-text-color
                font-normal"
                  >
                    Deleting the organisation will remove all the
                    {
                      currentApp === ICurrentApp.APP ? 'projects' : 'instances'
                      // eslint-disable-next-line max-len
                    }
                    , active subscription and bonuses related to the
                    organisation.
                  </p>
                  <Button
                    leftIcon={<BinIcon className="text-base-bg -mt-0.5" />}
                    buttonType="error"
                    label="Delete Organisation"
                    size="medium"
                    onClick={handleDeleteOrganisation}
                    disabled={updateMasterOrganizationLoading}
                  />
                </div>
              </>
            )}
        </div>
      </div>
    </>
  );
};

export default OrganisationGeneralSettings;
